import { IJobCostCode } from 'types/JobCostCode';
import { DevelopmentCategory } from '@/shared/types/reconcile/Invoice';
import { IReconcileDevelopmentCategory } from 'bundles/Construction/types';

export const jCCToOption = (
  jcc?: Pick<IJobCostCode, 'id' | 'code' | 'name'>,
) => (jcc ? { value: jcc.id, label: `${jcc.code} - ${jcc.name}` } : null);

export const findJCC = (
  value: string,
  collection: DevelopmentCategory[] = [],
  field = 'id',
) => collection.find((c) => c[field] === value);

export const treeJCCToOption = (
  categories: IReconcileDevelopmentCategory[],
): ({
  label: string;
  value: string;
} & IReconcileDevelopmentCategory)[] =>
  categories.map((category) => ({
    ...category,
    label: `${category.code} - ${category.name}`,
    value: category.id,
  }));

import {
  currentUserAllowedTo,
  currentUserIsInternal,
  currentUserIsSreAdmin,
} from '@/lib/permissions';

export const currentUserCanSeeComparisonPage = (): boolean => {
  switch (true) {
    case currentUserIsSreAdmin():
    case currentUserIsInternal() && currentUserAllowedTo('view', 'report'): {
      return true;
    }

    default: {
      return false;
    }
  }
};

import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiReportRentRollDelinquencySummariesSnapshot: build.query<
      GetApiReportRentRollDelinquencySummariesSnapshotApiResponse,
      GetApiReportRentRollDelinquencySummariesSnapshotApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/delinquency/summaries/snapshot`,
        params: { legal_entity_ids: queryArg.legalEntityIds },
      }),
    }),
    postApiReportRentRollSourceChargeCodeMappings: build.mutation<
      PostApiReportRentRollSourceChargeCodeMappingsApiResponse,
      PostApiReportRentRollSourceChargeCodeMappingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_charge_code_mappings`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiReportRentRollSourceChargeCodeMappingsById: build.mutation<
      PutApiReportRentRollSourceChargeCodeMappingsByIdApiResponse,
      PutApiReportRentRollSourceChargeCodeMappingsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_charge_code_mappings/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReportRentRollSourceChargeCodeMappingsById: build.mutation<
      DeleteApiReportRentRollSourceChargeCodeMappingsByIdApiResponse,
      DeleteApiReportRentRollSourceChargeCodeMappingsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_charge_code_mappings/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReportRentRollSourceChargeCodes: build.query<
      GetApiReportRentRollSourceChargeCodesApiResponse,
      GetApiReportRentRollSourceChargeCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_charge_codes`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          with_fires: queryArg.withFires,
        },
      }),
    }),
    postApiReportRentRollSourceStatusMappings: build.mutation<
      PostApiReportRentRollSourceStatusMappingsApiResponse,
      PostApiReportRentRollSourceStatusMappingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_status_mappings`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiReportRentRollSourceStatusMappingsById: build.mutation<
      PutApiReportRentRollSourceStatusMappingsByIdApiResponse,
      PutApiReportRentRollSourceStatusMappingsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_status_mappings/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReportRentRollSourceStatusMappingsById: build.mutation<
      DeleteApiReportRentRollSourceStatusMappingsByIdApiResponse,
      DeleteApiReportRentRollSourceStatusMappingsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_status_mappings/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReportRentRollSourceStatuses: build.query<
      GetApiReportRentRollSourceStatusesApiResponse,
      GetApiReportRentRollSourceStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_statuses`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          with_fires: queryArg.withFires,
        },
      }),
    }),
    getApiReportRentRollSourceUnitTypes: build.query<
      GetApiReportRentRollSourceUnitTypesApiResponse,
      GetApiReportRentRollSourceUnitTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/source_unit_types`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          without_unit_type: queryArg.withoutUnitType,
          asset_ids: queryArg.assetIds,
        },
      }),
    }),
    getApiReportRentRollSummary: build.query<
      GetApiReportRentRollSummaryApiResponse,
      GetApiReportRentRollSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/summary`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          as_of_month: queryArg.asOfMonth,
        },
      }),
    }),
    getApiReportRentRollSummaryMeta: build.query<
      GetApiReportRentRollSummaryMetaApiResponse,
      GetApiReportRentRollSummaryMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/summary/meta`,
        params: { legal_entity_ids: queryArg.legalEntityIds },
      }),
    }),
    getApiReportRentRollSummarySourceFloorPlans: build.query<
      GetApiReportRentRollSummarySourceFloorPlansApiResponse,
      GetApiReportRentRollSummarySourceFloorPlansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/summary/source_floor_plans`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          as_of_month: queryArg.asOfMonth,
          without_unit_type: queryArg.withoutUnitType,
          legal_entity_ids: queryArg.legalEntityIds,
          asset_ids: queryArg.assetIds,
        },
      }),
    }),
    getApiReportRentRollSummaryUnitTypeGroups: build.query<
      GetApiReportRentRollSummaryUnitTypeGroupsApiResponse,
      GetApiReportRentRollSummaryUnitTypeGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/summary/unit_type_groups`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          as_of_month: queryArg.asOfMonth,
          sort: queryArg.sort,
          page: queryArg.page,
        },
      }),
    }),
    postApiReportRentRollSummaryUnitTypeGroupsExportXlsx: build.mutation<
      PostApiReportRentRollSummaryUnitTypeGroupsExportXlsxApiResponse,
      PostApiReportRentRollSummaryUnitTypeGroupsExportXlsxApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/summary/unit_type_groups_export.xlsx`,
        method: 'POST',
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          as_of_month: queryArg.asOfMonth,
        },
      }),
    }),
    getApiReportRentRollSummarySnapshots: build.query<
      GetApiReportRentRollSummarySnapshotsApiResponse,
      GetApiReportRentRollSummarySnapshotsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/summary/snapshots`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          from_date: queryArg.fromDate,
          to_date: queryArg.toDate,
        },
      }),
    }),
    getApiReportRentRollSymmetreChargeCodes: build.query<
      GetApiReportRentRollSymmetreChargeCodesApiResponse,
      GetApiReportRentRollSymmetreChargeCodesApiArg
    >({
      query: () => ({ url: `/api/report/rent_roll/symmetre_charge_codes` }),
    }),
    getApiReportRentRollSymmetreStatuses: build.query<
      GetApiReportRentRollSymmetreStatusesApiResponse,
      GetApiReportRentRollSymmetreStatusesApiArg
    >({
      query: () => ({ url: `/api/report/rent_roll/symmetre_statuses` }),
    }),
    getApiReportRentRollUnitLeaseHistory: build.query<
      GetApiReportRentRollUnitLeaseHistoryApiResponse,
      GetApiReportRentRollUnitLeaseHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_lease_history`,
        params: {
          unit_id: queryArg.unitId,
          max_term_variance: queryArg.maxTermVariance,
          min_lease_term: queryArg.minLeaseTerm,
        },
      }),
    }),
    postApiReportRentRollUnitTypeMappings: build.mutation<
      PostApiReportRentRollUnitTypeMappingsApiResponse,
      PostApiReportRentRollUnitTypeMappingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_type_mappings`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiReportRentRollUnitTypeMappingsById: build.mutation<
      PutApiReportRentRollUnitTypeMappingsByIdApiResponse,
      PutApiReportRentRollUnitTypeMappingsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_type_mappings/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReportRentRollUnitTypeMappingsById: build.mutation<
      DeleteApiReportRentRollUnitTypeMappingsByIdApiResponse,
      DeleteApiReportRentRollUnitTypeMappingsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_type_mappings/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReportRentRollUnitTypes: build.query<
      GetApiReportRentRollUnitTypesApiResponse,
      GetApiReportRentRollUnitTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_types`,
        params: { page: queryArg.page, per_page: queryArg.perPage },
      }),
    }),
    postApiReportRentRollUnitTypes: build.mutation<
      PostApiReportRentRollUnitTypesApiResponse,
      PostApiReportRentRollUnitTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_types`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiReportRentRollUnitTypesById: build.mutation<
      PutApiReportRentRollUnitTypesByIdApiResponse,
      PutApiReportRentRollUnitTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_types/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReportRentRollUnitTypesById: build.mutation<
      DeleteApiReportRentRollUnitTypesByIdApiResponse,
      DeleteApiReportRentRollUnitTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/unit_types/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReportRentRollUnits: build.query<
      GetApiReportRentRollUnitsApiResponse,
      GetApiReportRentRollUnitsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/units`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          as_of_month: queryArg.asOfMonth,
          query: queryArg.query,
          sort: queryArg.sort,
          page: queryArg.page,
          per_page: queryArg.perPage,
          floor_plans: queryArg.floorPlans,
          statuses: queryArg.statuses,
          with_positive_balance: queryArg.withPositiveBalance,
          with_expiring_lease: queryArg.withExpiringLease,
        },
      }),
    }),
    getApiReportRentRollUnitsMeta: build.query<
      GetApiReportRentRollUnitsMetaApiResponse,
      GetApiReportRentRollUnitsMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/rent_roll/units/meta`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          as_of_month: queryArg.asOfMonth,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportOperationalApi };
export type GetApiReportRentRollDelinquencySummariesSnapshotApiResponse =
  /** status 200 success */ RentRollDelinquencySnapshotDto;
export type GetApiReportRentRollDelinquencySummariesSnapshotApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
};
export type PostApiReportRentRollSourceChargeCodeMappingsApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PostApiReportRentRollSourceChargeCodeMappingsApiArg = {
  body: {
    symmetre_charge_code: string;
    source_charge_code: string;
  };
};
export type PutApiReportRentRollSourceChargeCodeMappingsByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PutApiReportRentRollSourceChargeCodeMappingsByIdApiArg = {
  id: string;
  body: {
    symmetre_charge_code: string;
  };
};
export type DeleteApiReportRentRollSourceChargeCodeMappingsByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type DeleteApiReportRentRollSourceChargeCodeMappingsByIdApiArg = {
  id: string;
};
export type GetApiReportRentRollSourceChargeCodesApiResponse =
  /** status 200 success */ {
    items: {
      name: string;
      mappingId: string | null;
      symmetreChargeCode: {
        key: string;
        name: string;
      } | null;
    }[];
    meta: {
      perPage: number;
      totalSize: number;
      totalFiresSize: number;
    };
  };
export type GetApiReportRentRollSourceChargeCodesApiArg = {
  page: number;
  perPage?: number;
  withFires?: boolean;
};
export type PostApiReportRentRollSourceStatusMappingsApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PostApiReportRentRollSourceStatusMappingsApiArg = {
  body: {
    symmetre_status: string;
    source_status: string;
  };
};
export type PutApiReportRentRollSourceStatusMappingsByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PutApiReportRentRollSourceStatusMappingsByIdApiArg = {
  id: string;
  body: {
    symmetre_status: string;
  };
};
export type DeleteApiReportRentRollSourceStatusMappingsByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type DeleteApiReportRentRollSourceStatusMappingsByIdApiArg = {
  id: string;
};
export type GetApiReportRentRollSourceStatusesApiResponse =
  /** status 200 success */ {
    items: {
      name: string;
      mappingId: string | null;
      symmetreStatus: {
        key: string;
        name: string;
      } | null;
    }[];
    meta: {
      perPage: number;
      totalSize: number;
      totalFiresSize: number;
    };
  };
export type GetApiReportRentRollSourceStatusesApiArg = {
  page: number;
  perPage?: number;
  withFires?: boolean;
};
export type GetApiReportRentRollSourceUnitTypesApiResponse =
  /** status 200 success */ {
    items: RentRollSourceUnitTypeDto[];
    meta: PaginationDto;
  };
export type GetApiReportRentRollSourceUnitTypesApiArg = {
  page?: number;
  perPage?: number;
  withoutUnitType?: boolean;
  assetIds?: {
    ''?: number[];
  };
};
export type GetApiReportRentRollSummaryApiResponse =
  /** status 200 success */ RentRollSummaryDto;
export type GetApiReportRentRollSummaryApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
  asOfMonth: string;
};
export type GetApiReportRentRollSummaryMetaApiResponse =
  /** status 200 success */ RentRollSummaryMetaDto;
export type GetApiReportRentRollSummaryMetaApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
};
export type GetApiReportRentRollSummarySourceFloorPlansApiResponse =
  /** status 200 success */ {
    items: {
      assets: {
        name: string;
      }[];
      floorPlan: string;
      unitType: {
        id: number;
        lastUnitTypeMappingId: number | null;
        name: string;
      } | null;
      unitTypeMappingId: string | null;
    }[];
    meta: PaginationDto;
  };
export type GetApiReportRentRollSummarySourceFloorPlansApiArg = {
  page: number;
  perPage?: number;
  asOfMonth?: string;
  withoutUnitType?: boolean;
  legalEntityIds?: {
    ''?: string[];
  };
  assetIds?: {
    ''?: number[];
  };
};
export type GetApiReportRentRollSummaryUnitTypeGroupsApiResponse =
  /** status 200 success */ {
    items: RentRollSummaryFloorPlanDto[];
    meta: PaginationDto;
  };
export type GetApiReportRentRollSummaryUnitTypeGroupsApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
  asOfMonth: string;
  sort?: {
    field?:
      | 'floor_plan'
      | 'units_count'
      | 'vacant_units_count'
      | 'vacancy_rate'
      | 'occupied_units_count'
      | 'occupancy_rate'
      | 'average_sqft'
      | 'average_market_rent'
      | 'average_actual_rent';
    order?: 'asc' | 'desc';
  };
  page?: number;
};
export type PostApiReportRentRollSummaryUnitTypeGroupsExportXlsxApiResponse =
  unknown;
export type PostApiReportRentRollSummaryUnitTypeGroupsExportXlsxApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
  asOfMonth: string;
};
export type GetApiReportRentRollSummarySnapshotsApiResponse =
  /** status 200 success */ {
    items: RentRollSummarySnapshotDto[];
  };
export type GetApiReportRentRollSummarySnapshotsApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
  fromDate: string;
  toDate: string;
};
export type GetApiReportRentRollSymmetreChargeCodesApiResponse =
  /** status 200 success */ {
    items: {
      key: string;
      label: string;
    }[];
  };
export type GetApiReportRentRollSymmetreChargeCodesApiArg = void;
export type GetApiReportRentRollSymmetreStatusesApiResponse =
  /** status 200 success */ {
    items: {
      key: string;
      label: string;
    }[];
  };
export type GetApiReportRentRollSymmetreStatusesApiArg = void;
export type GetApiReportRentRollUnitLeaseHistoryApiResponse =
  /** status 200 success */ ReportUnitLeaseDto[];
export type GetApiReportRentRollUnitLeaseHistoryApiArg = {
  unitId: number;
  maxTermVariance?: number;
  minLeaseTerm?: number;
};
export type PostApiReportRentRollUnitTypeMappingsApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PostApiReportRentRollUnitTypeMappingsApiArg = {
  body: {
    unit_type_id: number;
    original_unit_type: string;
  };
};
export type PutApiReportRentRollUnitTypeMappingsByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PutApiReportRentRollUnitTypeMappingsByIdApiArg = {
  id: string;
  body: {
    unit_type_id: number;
  };
};
export type DeleteApiReportRentRollUnitTypeMappingsByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type DeleteApiReportRentRollUnitTypeMappingsByIdApiArg = {
  id: string;
};
export type GetApiReportRentRollUnitTypesApiResponse =
  /** status 200 success */ {
    items: {
      id: number;
      name: string;
      label: string;
      lastUnitTypeMappingId: number | null;
    }[];
  };
export type GetApiReportRentRollUnitTypesApiArg = {
  page: number;
  perPage?: number;
};
export type PostApiReportRentRollUnitTypesApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PostApiReportRentRollUnitTypesApiArg = {
  body: {
    name: string;
    label: string;
  };
};
export type PutApiReportRentRollUnitTypesByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PutApiReportRentRollUnitTypesByIdApiArg = {
  id: string;
  body: {
    name?: string;
    label?: string;
  };
};
export type DeleteApiReportRentRollUnitTypesByIdApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type DeleteApiReportRentRollUnitTypesByIdApiArg = {
  id: string;
};
export type GetApiReportRentRollUnitsApiResponse = /** status 200 success */ {
  items: UnitDto[];
  meta: UnitPaginationDto;
};
export type GetApiReportRentRollUnitsApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
  asOfMonth: string;
  query?: string;
  sort?: {
    field?:
      | 'label'
      | 'market_rent'
      | 'actual_rent'
      | 'status'
      | 'resident'
      | 'lease_contract_move_in_date'
      | 'deposit_amount';
    order?: 'asc' | 'desc';
  };
  page: number;
  perPage?: number;
  floorPlans?: {
    ''?: string[];
  };
  statuses?: (
    | 'applicant'
    | 'vacant'
    | 'vacant_leased'
    | 'occupied'
    | 'occupied_ntv'
    | 'occupied_ntvl'
  )[];
  withPositiveBalance?: boolean;
  withExpiringLease?: boolean;
};
export type GetApiReportRentRollUnitsMetaApiResponse =
  /** status 200 success */ UnitsMetaDto;
export type GetApiReportRentRollUnitsMetaApiArg = {
  legalEntityIds?: {
    ''?: string[];
  };
  asOfMonth: string;
};
export type RentRollDelinquencySnapshotDto = {
  delinquentBalanceCents: number;
  currentBalanceCents: number;
  currentToTotalPercentage: number;
  delinquentTenantsCount: number;
  averageDelinquentBalanceCents: number;
  date: string;
}[];
export type RentRollSourceUnitTypeDto = {
  name: string;
  unitTypeMappingId: number | null;
  unitType: {
    id: number;
    name: string;
    label: string;
  } | null;
  assets: {
    name: string;
  }[];
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};
export type RentRollSummaryDto = {
  unitsCount: number;
  vacantUnitsCount: number;
  applicantUnitsCount: number;
  occupiedUnitsCount: number;
  noticeToVacateUnitsCount: number;
  physicalOccupancy: number | null;
  economicOccupancy: number | null;
  averageMarketRent: number | null;
  averageActualRent: number | null;
  cashSecurityDeposits: number | null;
  otherSecurityDeposits: number | null;
  totalOneTimeFees: number | null;
  totalRecurringFees: number | null;
  totalConcessions: number | null;
  lastParsingDate?: string | null;
};
export type RentRollSummaryMetaDto = {
  availableMonths: string[];
  unitTypes: {
    id: number;
  }[];
};
export type RentRollSummaryFloorPlanDto = {
  floorPlan: string;
  unitsCount: number;
  vacantUnitsCount: number;
  vacancyRate: number;
  occupiedUnitsCount: number;
  occupancyRate: number;
  averageSqft: number;
  averageMarketRent: number;
  averageActualRent: number;
};
export type RentRollSummarySnapshotDto =
  | {
      slug: 'lease-waterfall';
      data: (
        | {
            axisSlug: 'expiring-rent';
            period: string;
            value: number;
          }
        | {
            axisSlug: 'expiring-count';
            period: string;
            value: number;
          }
      )[];
    }
  | {
      slug: 'market-rent-vs-actual-rent';
      data: (
        | {
            axisSlug: 'actual-rent';
            period: string;
            value: number;
          }
        | {
            axisSlug: 'market-rent';
            period: string;
            value: number;
          }
        | {
            axisSlug: 'loss-gain-to-lease';
            period: string;
            value: number;
          }
      )[];
    }
  | {
      slug: 'average-effective-actual-rent';
      data: (
        | {
            axisSlug: 'actual-rent';
            floorPlan?: string;
            period: string;
            value: number;
          }
        | {
            axisSlug: 'average-actual-rent';
            period: string;
            value: number;
          }
      )[];
    };
export type ReportUnitLeaseDto = {
  id?: string;
  startDate: string;
  endDate: string | null;
  moveInDate: string | null;
  moveOutDate: string | null;
  actualRentCents: number | null;
  residentName?: string;
};
export type UnitDto = {
  id: string;
  label: string;
  floorPlan: string;
  sqft: number;
  marketRent: number;
  marketRentPerSqft: number;
  actualRent: number;
  actualRentPerSqft: number;
  status:
    | 'vacant'
    | 'applicant'
    | 'occupied'
    | 'vacant_leased'
    | 'occupied_ntv'
    | 'occupied_ntvl';
  residentName: string | null;
  balance: number;
  leaseContractBeginningDate: string;
  leaseContractEndingDate: string;
  leaseContractMoveInDate: string;
  leaseContractMoveOutDate: string | null;
  leaseContractDays: number;
  leaseContractDaysLeft: number;
  expiringLease: boolean;
  cashDeposit: number;
  otherDeposit: number;
  leaseCharges: {
    label: string;
    value: number;
  }[];
  concessions: number | null;
};
export type UnitPaginationDto = {
  perPage: number;
  totalSize: number;
  positiveBalanceCount: number;
  expiringLeaseCount: number;
};
export type UnitsMetaDto = {
  availableMonths: string[];
  floorPlans: string[];
};

import { DistributionKind } from 'bundles/REturn/actions/types';
import http from 'lib/http';

export type TBulkCreateManualDistributionsRequest = {
  investment_id: number;
  amount: number;
  date: DateString;
  kind: DistributionKind;
  quarter: DateString;
}[];

// TODO: postApiCapitalInvestmentDistributionsBulk
export const bulkCreateManualDistributions = async (
  data: TBulkCreateManualDistributionsRequest,
) => {
  const res = await http.post('/capital/investment/distributions/bulk', data);

  if (res.status === 200) {
    toastr.success('Successfully updated');
  } else {
    toastr.error('Something went wrong');
  }

  return res.json();
};

import React, { FC, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'stories';
import {
  useAddPayableInvoiceMutation,
  useGetInvoiceQuery,
  useGetRequisitionsQuery,
  useRemovePayableInvoiceMutation,
} from 'bundles/Construction/api/invoices';
import { formatAmount } from '@/shared/lib/formatting/number';
import FormSelect from 'bundles/Shared/components/GroupForm/FormItems/FormSelect';
import { sum } from 'lodash-es';
import { LegalEntity } from '@/entities/core/legalEntity';
import { useModal } from '@/shared/lib/hooks/useModal';
import { fetchDevelopmentBudgetVariance } from 'bundles/REconcile/reducers/developmentBudgetVarianceSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import TableRequisitionsLineItems from './TableRequisitionsLineItems';
import styles from '../invoice/Invoice.module.scss';

interface IInvoiceRequisitionModalProps {
  onClose: (status: boolean) => void;
  params: {
    legalEntityCode: LegalEntity['code'];
    id: string;
  };
  type: 'add' | 'remove';
  returnFunc?: () => void;
}

const InvoiceRequisitionModal: FC<IInvoiceRequisitionModalProps> = ({
  params,
  onClose,
  type,
  returnFunc,
}) => {
  const [formData, setFormData] = useState({
    requisitionId: '',
  });
  const { data: invoice } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.id,
  });

  const { data: requisitions, refetch: refetchRequisitions } =
    useGetRequisitionsQuery({
      legalEntityCode: params.legalEntityCode,
      not_funded: true,
    });

  const [addPayableInvoice] = useAddPayableInvoiceMutation();
  const [removePayableInvoice] = useRemovePayableInvoiceMutation();

  const inRequisition = invoice?.requisition;

  const { confirm } = useModal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inRequisition) {
      setFormData({
        requisitionId: invoice.requisition.id,
      });
    }
  }, []);

  const calcTotalRequisitions = () => {
    const requisition = requisitions?.items.find(
      (item) => item.id === formData.requisitionId,
    );
    const total = sum(
      requisition?.lineItems.map((item) => Number(item.amount)),
    );
    return total || 0;
  };
  const CONFIG = {
    add: {
      title: 'Add to Requisition',
      changingValue: formatAmount(
        Number(invoice?.amount) + calcTotalRequisitions(),
      ),
      submitFunc: addPayableInvoice,
      typeBtn: 'success',
    },
    remove: {
      title: 'Remove from Requisition',
      changingValue: formatAmount(
        calcTotalRequisitions() - Number(invoice?.amount),
      ),
      submitFunc: removePayableInvoice,
      typeBtn: 'danger',
    },
  };

  const submit = async () => {
    const sendData = {
      legalEntityCode: params.legalEntityCode,
      invoiceId: params.id,
      body: {
        requisition_id: formData.requisitionId,
      },
    };
    const res = await CONFIG[type].submitFunc(sendData);
    if (res?.error) return false;
    onClose(false);

    await refetchRequisitions();
    await dispatch(fetchDevelopmentBudgetVariance(params.legalEntityCode));

    if (returnFunc) returnFunc();
  };

  return (
    <div>
      <Modal
        header={
          <div>
            <div className="dark header6-bold">{CONFIG[type].title}</div>
          </div>
        }
        size="600"
        classes={{
          body: 'reconcile-common-modal-create',
          footerAdditionalActions: 'bg-white p-m',
        }}
        toggle={onClose}
        additionalActions={
          <div className="flex justify-between w-full">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={CONFIG[type].typeBtn}
              onClick={submit}
              disabled={!formData.requisitionId}
            >
              {CONFIG[type].title}
            </Button>
          </div>
        }
        bodyPadding="0"
      >
        <div>
          <div className="bg-white px-l py-m">
            <div>
              <div className="light-60 body-regular mb-xxs">
                Invoice #{invoice?.number}
              </div>
              <div className="dark-60 header3-regular">
                {formatAmount(invoice?.amount)}
              </div>
            </div>
            {!inRequisition && (
              <div className="mt-m">
                <FormSelect
                  fieldName="requisitionId"
                  title="Select Requisition"
                  required
                  formData={formData}
                  setFormData={setFormData}
                  options={
                    requisitions?.items.map((item) => ({
                      label: `Requisition (${item.beginningDate} - ${item.endingDate})`,
                      value: item.id,
                    })) || []
                  }
                />
              </div>
            )}
          </div>
          <div className={styles.invoiceRequisitionInfo}>
            {formData.requisitionId && (
              <div>
                <div className="dark-60 inline-semibold mb-[6px]">
                  Requisition
                </div>
                <div className="items-center flex">
                  <div className="light-60 header4-regular mr-s">
                    {formatAmount(calcTotalRequisitions())}
                  </div>
                  <Icon iconName="arrowRightAlt" />
                  <div className="dark-60 header4-regular ml-s">
                    {CONFIG[type].changingValue}
                  </div>
                </div>
                <TableRequisitionsLineItems
                  requisition={requisitions?.items.find(
                    (item) => item.id === formData.requisitionId,
                  )}
                  invoice={invoice}
                  type={type}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceRequisitionModal;

import React, { RefObject, useRef } from 'react';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './CheckpointsSectionItem.module.scss';

interface ICheckpointsSectionItemProps {
  title: string;
  onClick: (itemRef: RefObject<HTMLDivElement>, value: string) => void;
  value: string;
  check?: boolean;
  customStyles?: {
    wrapper?: string;
  };
}

export function CheckpointsSectionItem({
  title,
  value,
  check,
  customStyles,
  onClick,
}: ICheckpointsSectionItemProps) {
  const itemRef = useRef();
  return (
    <div
      className={cn(styles['sre-checkpoint-item'], customStyles?.wrapper)}
      ref={itemRef}
      onClick={() => onClick(itemRef, value)}
    >
      <Icon
        iconName="checkSmall"
        className={cn(
          styles['sre-checkpoint-item__icon'],
          check && styles['sre-checkpoint-item__icon_check'],
        )}
      />
      <div className="label-semibold">{title}</div>
    </div>
  );
}

import { cn } from '@/shared/lib/css/cn';
import { Link } from '@reach/router';
import {
  IReturnInvestmentObject,
  IReturnInvestmentObjectScreenData,
} from 'bundles/REturn/types';
import { IFormatterProps } from 'bundles/Shared/components/Table/types';
import { ROUTES_ROOT, replacePathParams } from '@/shared/lib/hooks/useNavigation';
import { TProductNames, currentUserAllowedTo } from 'lib/permissions';
import { capitalize } from 'lodash-es';

type Props = IFormatterProps<IReturnInvestmentObject>;

const PRODUCT_NAME_MAP = {
  asset: TProductNames.ASSET_PORTAL,
  fund: TProductNames.FUND_PORTAL,
} as const satisfies Record<
  IReturnInvestmentObjectScreenData['object']['objectableType'],
  TProductNames
>;

function OwnershipsCell({ row }: Props) {
  const count = row.totalInvestments;
  const { id, objectableType: objectType } = row.object;
  const userCanManage =
    currentUserAllowedTo('manage', TProductNames.RETURN) &&
    currentUserAllowedTo(
      'manage',
      PRODUCT_NAME_MAP[objectType],
      capitalize(objectType) as Capitalize<typeof objectType>,
      id,
    );
  return (
    <div
      className="rounded-left-0 p-m flex h-full flex-col items-start
                    justify-center gap-s rounded-2xl bg-white"
    >
      <div className="flex flex-col gap-xs">
        <span className="light-60 label-semibold">Ownership</span>
        <span
          className={cn('inline-semibold', count === 0 ? 'dark-60' : 'blue')}
        >
          {count === 0 ? 'No ownership yet' : `${count} Investment Entities`}
        </span>
      </div>
      {userCanManage && (
        <Link
          className="blue rounded-[0.5rem] secondary-semibold border border-light-10 bg-white px-s py-xs"
          to={replacePathParams(ROUTES_ROOT.return.object.fullPath, {
            objectId: row.id,
          })}
        >
          Manage
        </Link>
      )}
    </div>
  );
}

export default OwnershipsCell;

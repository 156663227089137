import React, { forwardRef } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Link, LinkProps } from '@reach/router';
import { Icon } from 'stories';
import { IconsId } from 'types/sre-icons';
import useMediaQuery from '@/shared/lib/hooks/useMediaQuery';
import { isActiveOnHomepage } from '../../../../../pages';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

interface Props
  extends Omit<React.ComponentProps<typeof Link<any>>, 'to' | 'ref'> {
  label: React.ReactNode;
  icon?: React.ReactNode;
  iconName?: IconsId;
  path?: string;
  children?: React.ReactNode | React.ReactNode[];
  rightIcon?: React.ReactNode;
  onClick?: () => void;
  isActive?: LinkProps<any>['getProps'];
  classes?: {
    label?: string;
  };
  disabled?: boolean;
}

const MainMenuItem = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      icon,
      label,
      children,
      path,
      rightIcon,
      classes,
      onClick,
      iconName,
      className,
      isActive: externalIsActive,
      disabled,
      ...props
    },
    ref,
  ) => {
    const isMobile = useMediaQuery('(pointer:coarse)');
    const isActive: LinkProps<any>['getProps'] = (linkGetProps) => ({
      className: cn('left-sidebar__main-menu-item w-full', {
        active:
          (externalIsActive
            ? externalIsActive(linkGetProps)
            : linkGetProps.isPartiallyCurrent) ||
          (linkGetProps.location.pathname === '/' && isActiveOnHomepage(path)),
        'disabled-hover': disabled,
      }),
    });

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
      // todo refactor this terrible condition
      if (!path || onClick || children || isMobile) {
        e.preventDefault();
        if (isMobile || path === ROUTES_ROOT.documents.fullPath || path === ROUTES_ROOT.documentation.fullPath) {
          onClick?.();
        }
      }
    };

    return (
      <>
        <Link
          to={Array.isArray(path) || path == null ? '#' : path}
          onClick={handleClick}
          getProps={isActive}
          ref={ref}
          {...props}
        >
          <div
            className={cn(
              'flex justify-between items-center left-sidebar__main-menu-item p-[0.5rem] w-full position-relative',
              className,
            )}
          >
            <div className="flex items-center w-full">
              <div className="left-sidebar__icon-container">
                {iconName && <Icon padding="xs" iconName={iconName} />}
                {icon}
              </div>
              <div
                className={cn(
                  'inline-regular ml-[0.5rem] left-sidebar__menu-item-label',
                  classes?.label,
                )}
              >
                {label}
              </div>
            </div>
            <div className="items-center left-sidebar__menu-item-expand">
              {rightIcon}
            </div>
          </div>
        </Link>
        {children}
      </>
    );
  },
);

export default MainMenuItem;

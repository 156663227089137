import React, { useEffect } from 'react';
import GroupCellRenderer from 'ag-grid-react/lib/reactUi/cellRenderer/groupCellRenderer';
import { GroupCellRendererParams } from 'ag-grid-community';
import { groupHasDivider } from 'bundles/Settings/components/REport/GeneralLedgers/Table/GeneralLedgersTable';

type Props = GroupCellRendererParams;

function GroupRowRenderer(props: Props) {
  const { eGridCell, node } = props;
  useEffect(() => {
    if (groupHasDivider(node)) {
      const divider = document.createElement('div');
      const dividerBg = document.createElement('div');

      divider.className = 'row-group-divider';
      dividerBg.className = 'row-group-divider__bg';

      divider.appendChild(dividerBg);
      eGridCell.appendChild(divider);
    }
  }, []);

  return <GroupCellRenderer {...props} />;
}

export default GroupRowRenderer;

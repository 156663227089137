import React from 'react';
import { cn } from '@/shared/lib/css/cn';

export const GapBlock = ({
  children,
  className,
  ...props
}: React.AllHTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col gap-tw-2 px-tw-6', className)} {...props}>
    {children}
  </div>
);

import React from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { cn } from '@/shared/lib/css/cn';
import {
  changeInvoiceDocumentLink,
  selectInvoiceESignData,
  setESingData,
  TESignPlacement,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { useParams } from '@reach/router';
import { cloneDeep } from 'lodash-es';
import styles from './Placement.module.scss';

const DEFAULT_PLACEMENT: TESignPlacement[] = [
  'left_top',
  'center_top',
  'right_top',
  'left_center',
  'center_center',
  'right_center',
  'left_bottom',
  'center_bottom',
  'right_bottom',
];

const PlacementPreviewArea = () => {
  const eSignData = useAppSelector(selectInvoiceESignData);
  const { placement } = eSignData;

  const dispatch = useAppDispatch();
  const params = useParams<{
    legalEntityCode: string;
    invoiceId: string;
  }>();

  const changePlacementESignData = (newPlacement: TESignPlacement) => {
    const newESignData = cloneDeep(eSignData);

    newESignData.placement = newPlacement;
    dispatch(setESingData(newESignData));
    dispatch(
      changeInvoiceDocumentLink({
        eSignData: newESignData,
        params,
      }),
    );
  };

  return (
    <div className="flex items-center justify-center py-tw-4">
      <div className={styles.wrapper}>
        {DEFAULT_PLACEMENT.map((placementItem) => (
          <div
            key={placementItem}
            className={cn(
              'group flex h-[32px] w-[32px] basis-1/3 cursor-pointer items-center justify-center',
            )}
            onClick={() => changePlacementESignData(placementItem)}
          >
            <div
              className={cn(
                'h-[8px] w-[8px] rounded-[2px] bg-tw-light-20 group-hover:bg-blue-light-2',
                {
                  ['h-[24px] w-[24px] bg-blue-dark-2']:
                    placementItem === placement,
                },
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlacementPreviewArea;

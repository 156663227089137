import {
  GRANULARITIES,
  GRANULARITY_LABELS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { Control, Controller } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';

export function GranularityField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name }: { control: Control<TFieldValues>; name: TName }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup
          options={GRANULARITIES.map((g) => ({
            label: GRANULARITY_LABELS[g],
            value: g,
          }))}
          value={field.value}
          onChange={(o) => field.onChange(o.value as string)}
        />
      )}
    />
  );
}

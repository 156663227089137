import { transformPeriodShiftDtoToForm } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  ADJUSTMENT_EXPRESSION_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
  TOTAL_CALCULATION_STRATEGIES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  KpiTablePeriodType,
  KpiTableWidgetConfigColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { ListOption } from 'stories/Checkbox/CheckList';
import * as yup from 'yup';
import { KPI_TABLE_PERIOD_TYPES } from '../model';

export const KPI_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA =
  COLUMN_SETTINGS_FORM_SCHEMA.shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
    total_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
  });

export type KpiTableWidgetConfigColumnForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const DEFAULT_KPI_TABLE_WIDGET_CONFIG_COLUMN: Partial<KpiTableWidgetConfigColumnForm> =
  {
    value_display_options: {
      kilo_formatting: false,
      type: 'number',
      precision: 2,
    },
    adjustment: null,
    period_shift: {
      key: 'weeks',
      value: 0,
    },
  };

// month and custom
export const DATE_PICKER_SETTINGS_OPTIONS = [
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'week',
    label: 'Week',
  },
] as const satisfies readonly ListOption[];

export const transformColumnConfigToForm = (
  columnConfig: KpiTableWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): KpiTableWidgetConfigColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    expression: columnConfig.expression,
    adjustment: columnConfig.adjustment_expression,
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    total_calculation_strategy: columnConfig.total_calculation_strategy,
    ...transformColumnSettingsToForm(columnSettings),
  };
};

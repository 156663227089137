/**
 * generates spacing from 1px to 99px
 * 100 stands for < w-100: 100%; > need to refactor it
 * very confusing
 */
const extendedSpacing = Object.fromEntries(
  Array.from({ length: 99 }, (_, idx) => [idx + 1, idx + 1]),
);

export const THEME_SPACING = {
  0: '0',
  DEFAULT: '1px',
  xxs: '0.125rem',
  xs: '0.25rem',
  s: '0.5rem',
  m: '1rem',
  l: '1.5rem',
  xl: '2rem',
  xxl: '2.5rem',
  ...extendedSpacing,

  // New spacing css variables! Further on use only them
  'tw-px': '1px',
  'tw-0.5': '0.125rem',
  'tw-1': '0.25rem',
  'tw-1.5': '0.375rem',
  'tw-2': '0.5rem',
  'tw-2.5': '0.625rem',
  'tw-3': '0.75rem',
  'tw-3.5': '0.875rem',
  'tw-4': '1rem',
  'tw-4.5': '1.125rem',
  'tw-5': '1.25rem',
  'tw-6': '1.5rem',
  'tw-7': '1.75rem',
  'tw-8': '2rem',
  'tw-9': '2.25rem',
  'tw-9.5': '2.375rem',
  'tw-10': '2.5rem',
  'tw-11': '2.75rem',
  'tw-12': '3rem',
  'tw-14': '3.5rem',
  'tw-16': '4rem',
  'tw-18': '4.5rem',
  'tw-20': '5rem',
};

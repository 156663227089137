import React from 'react';
import { Icon } from 'stories';
import { Link } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';

interface Props {
  object: (IAsset | IFund) & {
    objectDashboards: {
      id: string;
      name: string;
    }[];
  };
}

// todo copy paste ObjectFinancialTabs.tsx
export function ObjectReportTabs({ object }: Props) {
  return (
    <div className="nav-item dropdown object-menu-item flex max-h-[32px] w-max items-center px-tw-4 pb-tw-0.5 pt-tw-1.5">
      <p className="inline-semibold flex items-center gap-tw-2 border-none border-transparent bg-transparent leading-none text-info-055">
        Operational
        <Icon iconName="bottom" className="text-neutral-450" />
      </p>
      <div
        className="dropdown-menu"
        data-dropdown-in="fadeIn"
        data-dropdown-out="fadeOut"
      >
        {object?.objectDashboards.map((dashboard) => (
          <Link
            key={dashboard.id}
            to={generateUrl(ROUTES_ROOT.report.dashboards.fullPath, {
              pathParams: {
                dashboardId: dashboard.id,
              },
              queryParams: {
                [`${[object.objectType]}Id`]: object.id,
              },
            })}
            className="dropdown-item cursor-pointer"
          >
            {dashboard.name}
          </Link>
        ))}
      </div>
    </div>
  );
}

import React from 'react';
import { Icon, Popover, PseudoLink, TagWithIcon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { IUser } from 'types/User';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';
import { PhoneLink } from 'bundles/Shared/shared/PhoneLink';

export const fullNameColumnContent = (userRow: IUser) => (
  <Popover
    placement="left-end"
    maxWidth="26rem"
    arrowPosition="end"
    className="p-075rem"
    offset={[0, 10]}
    template={
      <div className="text-left">
        <h6 className="dark-60">{userRow.fullName}</h6>
        {userRow?.role?.name && (
          <div className="light-60">{userRow.role.name}</div>
        )}
        <div className="mt-s flex align-middle">
          <div className="advisee-card__details-badge mr-s h-[1.5rem] w-[1.5rem] flex-[0_0_24px] bg-light-10">
            <Icon iconName="email" />
          </div>
          <EmailLink
            email={userRow.email}
            className="inline-regular text-ellipsis"
          >
            {userRow.email}
          </EmailLink>
        </div>
        {userRow.phone && (
          <div className="mt-s flex align-middle">
            <div className="advisee-card__details-badge mr-s h-[1.5rem] w-[1.5rem] flex-[0_0_24px] bg-light-10">
              <Icon iconName="phone" />
            </div>
            <PhoneLink phone={userRow.phone} className="inline-regular">
              {userRow.phone}
            </PhoneLink>
          </div>
        )}
      </div>
    }
  >
    <PseudoLink className={cn({ 'line-through': userRow.excluded })}>
      {userRow.fullName}
    </PseudoLink>
  </Popover>
);

export const tagsColumnContent = (userRow: IUser) => (
  <div className="flex flex-wrap gap-xs">
    {/* TODO: remove one of tags field */}
    {(userRow?.tags || userRow?.userTags)?.map((tag) => (
      <TagWithIcon
        disabled
        startIcon={<Icon iconName="tag" />}
        key={tag.id}
        label={tag.name}
      />
    ))}
  </div>
);

export const roleColumnContent = (userRow: IUser) => (
  <div>{userRow?.role?.name}</div>
);

import React, { useState } from 'react';
import { Button, Modal } from 'stories';
import {
  AssetClassSelect,
  LeClassificationSelectField,
  findAssetClassOptionByValue,
  LE_CLASSIFICATION_OPTIONS,
  LeClassification,
} from 'bundles/Shared/entities/leClasssification';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { isEqual } from 'lodash-es';

type Props<T extends boolean = false> = Pick<
  React.ComponentProps<typeof Modal>,
  'header'
> & {
  isMulti?: T;
  defaultClass?: LeClassification | LeClassification[];
  availableClasses?: LeClassification[];
  isDefaultClassesClearable?: boolean;
} & DialogProps<T extends true ? LeClassification[] : LeClassification>;

export function LeClassificationModal<T extends boolean = false>({
  onSubmit,
  onClose,
  header,
  defaultClass,
  availableClasses,
  isDefaultClassesClearable,
  isMulti,
}: Props<T>) {
  const [leClass, setLeClass] = useState<
    LeClassification | null | LeClassification[]
  >(null);
  return (
    <Modal
      toggle={onClose}
      header={header ?? 'Set Class'}
      classes={{
        body: 'bg-neutral-100',
      }}
      actions={
        <>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              onSubmit?.(leClass);
            }}
            disabled={!leClass || isEqual(defaultClass, leClass)}
          >
            Set Class
          </Button>
        </>
      }
      bodyPadding="py-tw-4 px-tw-6"
      size="500"
    >
      <LeClassificationSelectField>
        <AssetClassSelect
          defaultValue={
            defaultClass &&
            (Array.isArray(defaultClass)
              ? defaultClass.map((c) => ({
                  ...findAssetClassOptionByValue(c),
                  isClearable: isDefaultClassesClearable,
                }))
              : findAssetClassOptionByValue(defaultClass))
          }
          isClearable={isDefaultClassesClearable}
          options={LE_CLASSIFICATION_OPTIONS.map((o) => ({
            ...o,
            isDisabled: availableClasses?.includes(o.value) === false,
          }))}
          isMulti={isMulti}
          onChange={(value) => setLeClass(value as LeClassification)}
        />
      </LeClassificationSelectField>
    </Modal>
  );
}

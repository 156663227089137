import {
  FieldType,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';

const getItemsAppModal = (options): IGroupFormItem[] => [
  {
    activated: true,
    formItems: [
      {
        menuPosition: 'fixed',
        fieldName: 'propertyType',
        type: FieldType.SELECT,
        placeholder: 'Select Map property types',
        isMulti: true,
        options,
      },
    ],
  },
];
export default getItemsAppModal;

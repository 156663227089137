import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'lib/http';
import {
  IDevelopmentLegalEntityRequisitionTransaction,
  ILineItem,
} from 'bundles/REconcile/types/ILineItem';
import {
  IPaginationMeta,
  IPaginationParams,
  IQueryParams,
} from 'types/Pagination';
import { IGeneralLedger } from 'types/GeneralLedger';
import { ISortParamsField } from 'types/Sort';
import { TDevelopmentCategorizableType } from 'bundles/REconcile/actions/developmentBudgetVariance';
import { stringify } from 'qs';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import {
  DEFAULT_STRINGIFY_OPTIONS,
  getOptionsForEmptyArray,
} from 'lib/stringify';
import { IManualLineItemCreate } from 'bundles/REconcile/components/development/lineItems/types';

interface IPathParams {
  legalEntityCode: string;
  requisitionId: string;
}

type TSortParamsField =
  | 'id'
  | 'date'
  | 'balance'
  | 'transaction_value'
  | 'period'
  | 'legal_entity_code'
  | 'vendor_entity_code'
  | 'vendor_entity_name';

export interface IGetTransactionsRequest
  extends IPaginationParams,
    IQueryParams,
    ISortParamsField<TSortParamsField> {
  category: {
    id: number;
    type: TDevelopmentCategorizableType;
  };
  amount?: {
    to?: number;
    from?: number;
  };
  date_range?: {
    from?: string;
    to?: string;
  };
  general_ledger_ids?: number[];
  transaction_type?: 'debit' | 'credit';
  vendor_ids?: number[];
}

interface IGetTransactionResponse {
  items: IDevelopmentLegalEntityRequisitionTransaction[];
  meta: IPaginationMeta & {
    generalLedgers: Pick<IGeneralLedger, 'id'> &
      { number: number; title: string }[];
    categoryType: TDevelopmentCategorizableType;
    categoryName: string;
    requisitionNumber: number;
    vendors: VendorEntity[];
    requisition: {
      startDate: DateString;
      endDate: DateString;
    };
  };
}

export const reconcileRequisitionTransactionsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/development/legal_entities`,
  }),
  tagTypes: ['Transaction'],
  reducerPath: 'reconcileRequisitionTransactions',
  endpoints: (build) => ({
    getTransactions: build.query<
      IGetTransactionResponse,
      IGetTransactionsRequest & IPathParams
    >({
      query({ requisitionId, legalEntityCode, ...request }) {
        const params = stringify(request, {
          ...DEFAULT_STRINGIFY_OPTIONS,
          ...getOptionsForEmptyArray(),
        });
        return `/${legalEntityCode}/requisitions/${requisitionId}/transactions${params}`;
      },
      providesTags: ['Transaction'],
    }),
    createTransactionSplit: build.mutation<
      ILineItem[],
      {
        legalEntityCode: string;
        requisitionId: string;
        id: number;
        body: {
          existing_line_item_ids: string[];
          new_line_items: IManualLineItemCreate[];
        };
      }
    >({
      query({ legalEntityCode, requisitionId, id, body }) {
        return {
          url: `/${legalEntityCode}/requisitions/${requisitionId}/transactions/${id}/split`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Transaction'],
      transformResponse: (response) => {
        toastr.success('Transaction split successfully executed');
        return response.data;
      },
    }),
    linkTransactionToCategories: build.mutation<
      ILineItem[],
      {
        legalEntityCode: string;
        requisitionId: string;
        body: {
          transactions_ids: number[];
          category: { id: string; type: string };
        };
      }
    >({
      query({ legalEntityCode, requisitionId, body }) {
        return {
          url: `/${legalEntityCode}/requisitions/${requisitionId}/transactions/link_to_category`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Transaction'],
      transformResponse: (response) => {
        toastr.success('Transaction successfully linked to category');
        return response.data;
      },
    }),
    linkTransactionToLineItem: build.mutation<
      ILineItem[],
      {
        legalEntityCode: string;
        requisitionId: string;
        id: number;
        body: {
          development_line_item_id: string;
        };
      }
    >({
      query({ legalEntityCode, requisitionId, id, body }) {
        return {
          url: `/${legalEntityCode}/requisitions/${requisitionId}/transactions/${id}/link_to_line_item`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Transaction'],
      transformResponse: (response) => {
        toastr.success('Transaction successfully linked to line item');
        return response.data;
      },
    }),
    unlinkTransaction: build.mutation<
      ILineItem[],
      {
        legalEntityCode: string;
        requisitionId: string;
        id: number;
      }
    >({
      query({ legalEntityCode, requisitionId, id }) {
        return {
          url: `/${legalEntityCode}/requisitions/${requisitionId}/transactions/${id}/unlink`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Transaction'],
      transformResponse: (response) => {
        toastr.success('Transaction unlinked successfully');
        return response.data;
      },
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useCreateTransactionSplitMutation,
  useLinkTransactionToCategoriesMutation,
  useLinkTransactionToLineItemMutation,
  useUnlinkTransactionMutation,
} = reconcileRequisitionTransactionsApi;

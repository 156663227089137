/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { cn } from '@/shared/lib/css/cn';
import pluralize from 'pluralize';
import { Badge, Label } from 'stories';
import { CssVar } from '@/shared/config/cssVar';
import type { IUserRole } from 'types/User';
import { userRoleNameTruncate } from './constants';
import styles from './styles.module.scss';

interface Props {
  active: boolean;
  role: IUserRole;
  onClick: (id: IUserRole['id']) => void;
}

const resolveTextColor = (users = 0) =>
  users > 0 ? CssVar.blue : CssVar.light60;
const resolveBgColor = (users = 0) =>
  users > 0 ? CssVar.blueLight5 : CssVar.light;

const GROUP_COLORS = {
  portal: CssVar.light60,
  financial: CssVar.green,
  construction: CssVar.pumpkin,
  lender: CssVar.primary,
  vendor: CssVar.violet,
  'Equity Partner': CssVar.blue,
} as const;

const resolveGroupColor = (group: keyof typeof GROUP_COLORS) =>
  GROUP_COLORS[group] ?? CssVar.blue;

export function RoleItem({ active, role, onClick }: Props) {
  return (
    <li
      onClick={() => onClick(role.id)}
      key={role.id}
      className={cn(
        'grid h-[4rem] cursor-pointer bg-white pl-tw-2 pr-tw-6',
        styles.userListItem,
        {
          [styles.noLabel]: !role.group,
          'text-dark-60': active,
        },
      )}
    >
      <div className="inline-semibold flex items-center gap-tw-3">
        <span
          className={cn(
            'inline-flex h-[40px] w-[4px] rounded-[8px]',
            styles.indicator,
            {
              'bg-blue': active,
            },
          )}
        />
        <span>{userRoleNameTruncate(role.name)}</span>
      </div>
      {role.group && (
        <Label color={resolveGroupColor(role.group.toLowerCase())}>
          {role.group}
        </Label>
      )}
      <Badge
        textColor={resolveTextColor(role.users?.length)}
        backgroundColor={resolveBgColor(role.users?.length)}
      >
        {role.users ? role.users?.length : '0'}{' '}
        {pluralize('user', role.users?.length)}
      </Badge>
    </li>
  );
}

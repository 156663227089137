import { yupResolver } from '@hookform/resolvers/yup';
import {
  getLegalEntitiesFromAssets,
  ReportDashboardType,
  usePostApiSettingsReportComparisonDashboardsMutation,
  usePostApiSettingsReportEagleEyeDashboardsMutation,
  usePostApiSettingsReportObjectDashboardsMutation,
  useReportEagleEyeDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import {
  DASHBOARD_CREATION_SCHEMA,
  DashboardCreationFormModel,
} from './config';
import { transformPermissionsToRequest } from '@/bundles/Shared/entities/permissions';

export const useCreateDashboardForm = (
  form?: Partial<DashboardCreationFormModel>,
) =>
  useForm<DashboardCreationFormModel>({
    defaultValues: form,
    resolver: yupResolver(DASHBOARD_CREATION_SCHEMA),
    mode: 'all',
  });

export const useCreateDashboard = () => {
  const [createObjectDashboard, objectOptions] =
    usePostApiSettingsReportObjectDashboardsMutation();
  const [createEagleEyeDashboard, eagleOptions] =
    usePostApiSettingsReportEagleEyeDashboardsMutation();
  const [createComparisonDashboard, comparisonOptions] =
    usePostApiSettingsReportComparisonDashboardsMutation();

  return [
    useCallback(async (form: DashboardCreationFormModel) => {
      if (form.dashboard_type === ReportDashboardType.OBJECT) {
        return await createObjectDashboard({
          body: {
            name: form.name,
            status: 'draft',
            asset_ids: form.asset_ids,
            excluded_legal_entity_ids: form.excluded_legal_entity_ids,
            ...transformPermissionsToRequest(form.permitted),
          },
        });
      }
      if (form.dashboard_type === ReportDashboardType.COMPARISON_MODE) {
        return await createComparisonDashboard({
          body: {
            name: form.name,
            asset_ids: form.asset_ids,
            excluded_legal_entity_ids: form.excluded_legal_entity_ids,
            ...transformPermissionsToRequest(form.permitted),
          },
        });
      }
      return await createEagleEyeDashboard({
        body: {
          name: form.name,
          status: 'draft',
          sections_attributes: [],
          asset_ids: form.asset_ids ?? [],
          segment_ids: form.segment_ids ?? [],
          excluded_legal_entity_ids: form.excluded_legal_entity_ids ?? [],
          ...transformPermissionsToRequest(form.permitted),
        },
      });
    }, []),
    {
      ...objectOptions,
      isLoading:
        objectOptions.isLoading ||
        eagleOptions.isLoading ||
        comparisonOptions.isLoading,
    },
  ] as const;
};

export function useDashboardEagleEyeItems({
  selectedAssetIds,
  excludedLegalEntityIds,
}: {
  selectedAssetIds: number[];
  excludedLegalEntityIds: string[];
}) {
  const { assets: assetsWithLEs, legalEntities } =
    useReportEagleEyeDashboardAssetsQuery();

  const selectedAssetsWithLEs = useMemo(() => {
    return assetsWithLEs.filter(
      (asset) => selectedAssetIds?.includes(asset.id),
    );
  }, [selectedAssetIds, assetsWithLEs]);

  const includedLegalEntities = useMemo(() => {
    const legalEntitiesFromSelectedAssets = getLegalEntitiesFromAssets(
      selectedAssetsWithLEs,
    );
    return legalEntitiesFromSelectedAssets.filter(
      (le) => !(excludedLegalEntityIds?.includes(le.id) ?? false),
    );
  }, [selectedAssetsWithLEs, excludedLegalEntityIds]);

  return {
    selectedAssetsWithLEs,
    includedLegalEntities,
    allLegalEntities: legalEntities,
  };
}

export const useSetDashboardObjectsEffect = (
  {
    assetIds,
    segmentIds,
    isNew,
  }: {
    assetIds: number[];
    segmentIds: number[];
    isNew?: boolean;
  },
  methods?: UseFormReturn,
) => {
  const { setValue, trigger } =
    useFormContext<DashboardCreationFormModel>() ?? methods;

  useEffect(() => {
    if (!isNew) {
      return;
    }
    setValue('asset_ids', assetIds);
    setValue('excluded_legal_entity_ids', []);
    setValue('segment_ids', segmentIds);
    trigger();
  }, [assetIds, segmentIds, isNew]);
};

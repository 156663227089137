import { IQueryParams } from 'types/Pagination';
import { useCallback } from 'react';

export function usePageParamsSearch<T extends IQueryParams>(
  pageParams: T,
  setPageParams: React.Dispatch<React.SetStateAction<T>>,
) {
  const setQueryText = useCallback((query: string) => {
    setPageParams((prevState) => ({
      ...prevState,
      query,
    }));
  }, []);

  return {
    setQuery: setQueryText,
    query: pageParams.query,
  };
}

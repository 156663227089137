import { settingsReportThresholdsApi } from './settingsReportThresholdsGeneratedApi';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Threshold');

export const settingsReportThresholdsEnhancedApi =
  settingsReportThresholdsApi.enhanceEndpoints({
    addTagTypes: ['SettingsReportThresholds'],
    endpoints: {
      getApiSettingsReportThresholds: {
        providesTags: ['SettingsReportThresholds'],
      },
      putApiSettingsReportThresholdsByKind: {
        invalidatesTags: ['SettingsReportThresholds'],
        transformResponse: (response) => {
          toastr.success(t.update().toString());
          return response;
        },
      },
      deleteApiSettingsReportThresholdsByKind: {
        invalidatesTags: ['SettingsReportThresholds'],
        transformResponse: (response) => {
          toastr.success(t.delete().toString());
          return response;
        },
      },
    },
  });

export const {
  useGetApiSettingsReportThresholdsQuery,
  usePutApiSettingsReportThresholdsByKindMutation,
  useDeleteApiSettingsReportThresholdsByKindMutation,
} = settingsReportThresholdsEnhancedApi;

import { emptySplitApi as api } from "../../../app/stores/api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    putApiCapitalLegalEntitiesByLegalEntityIdPublishToggle: build.mutation<
      PutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleApiResponse,
      PutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/legal_entities/${queryArg.legalEntityId}/publish_toggle`,
        method: "PUT",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as capitalLegalEntitiesApi };
export type PutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleApiResponse =
  /** status 200 success */ {
    legal_entity_id?: string;
    is_published?: boolean;
  };
export type PutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleApiArg = {
  legalEntityId: string;
  capitalInvestmentObjectId: string;
};

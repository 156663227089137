import { cn } from '@/shared/lib/css/cn';
import selectIEimage from 'images/return/createTransferFlow/select-investment-entity-row.png';
import React, { useEffect, useState } from 'react';
import {
  StepIdx,
  useWorkflowSteps,
} from '../createEntry/hooks/useWorkflowSteps';
import HeaderForStepperModal from '../../Ownership/modals/HeaderForStepperModal/HeaderForStepperModal';
import { CREATE_TRANSFER_WORKFLOW_STEPS } from './consts';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { createTransferFlowMap } from './utils';
import {
  Button,
  CurrencyFormatter,
  Icon,
  IconButton,
  RadioButton,
  Tooltip,
} from '@/stories';
import { createWorkflowFlags } from './createFlowFlags';
import {
  AMOUNT_TO_TRANSFER_JSX,
  CONTRIBUTION_DATE_JSX,
  FIRST_STEP_HINT_INFO_JSX,
  HINT_IMG_ALT_TEXT,
  SELECT_CLASS_TO_TRANSFER_JSX,
  SELECT_INVESTMENT_TEXT_JSX,
  TRANSFER_DATE_JSX,
} from './dumbJSX';
import { SelectInvestmentEntityStep } from './steps/SelectInvestmentEntityStep';
import { InvestmentEntityClassesBadges } from '../../Ownership/ownershipTable/formatters/InvestmentEntityColumn';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';
import CurrencyInputNumber from '@/bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import ReactDatePicker from 'react-datepicker';
import { STATIC_DATE_PICKER_PROPS } from '../../Ownership/modals/consts';
import { SelectedInvEntities } from '../createEntry/selectedItems/SelectedInvEntities';
import { useModal } from '@/shared/lib/hooks/useModal';
import { SetReceiversModal } from './SetReceiversModal';
import { TransferReviewStep } from './TransferReviewStep';
import { usePostApiCapitalInvestmentContributionsTransferMutation } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { useCapitalUnitPricesByInvestmentClass } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { getClosestUnitPrice } from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import { UnitsInputNumber } from '@/bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/TotalWithUnits';
import UnitQuantity from '@/bundles/REturn/components/Home/formatters/UnitQuantity';

export const SelectedCapitalInvestment = ({
  capitalInvestment,
  resetSelectedCapitalInvestment,
  amountToTransfer,
  unitsToTransfer,
}: {
  capitalInvestment: ICapitalInvestment;
  amountToTransfer?: Integer;
  unitsToTransfer?: Integer;
  resetSelectedCapitalInvestment?: () => void;
}) => {
  return (
    <div className="flex flex-col gap-s">
      {resetSelectedCapitalInvestment && (
        <span className="label-regular text-neutral-550">
          Investment Entity
        </span>
      )}
      <div
        className={`flex rounded-lg border border-neutral-150 p-m ${
          resetSelectedCapitalInvestment ? '' : 'bg-neutral-050'
        }`}
      >
        <div className="flex flex-grow flex-col gap-s">
          <div className="label-regular text-neutral-550">
            {capitalInvestment.legalEntity.name}
          </div>
          <div className="inline-regular text-neutral-900">
            {capitalInvestment.investmentEntity.name}
          </div>
          <div className="align-center secondary-regular flex gap-xs text-neutral-550">
            <Tooltip
              placement="bottom"
              mainText={capitalInvestment.investmentEntity.users
                .map((u) => u.fullName)
                .join(', ')}
              disabled={capitalInvestment.investmentEntity.users.length === 0}
            >
              <div className="flex items-center gap-tw-1 text-neutral-500">
                <Icon iconName="members" />
                <p className="secondary-regular">
                  {capitalInvestment.investmentEntity.users.length}
                </p>
              </div>
            </Tooltip>
            <div className="ml-s flex gap-s">
              <InvestmentEntityClassesBadges
                capitalInvestmentClasses={capitalInvestment.investmentClasses}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-m">
          <div className="self-center">
            <div className="flex flex-col gap-xs text-right">
              <CurrencyFormatter
                classes={{ value: 'text-dark-60' }}
                value={
                  amountToTransfer ??
                  capitalInvestment.totalContributionsDollars
                }
              />
              {(unitsToTransfer &&
                unitsToTransfer > 0 &&
                capitalInvestment.totalContributionUnits) > 0 && (
                <div className="secondary-regular text-neutral-550">
                  <UnitQuantity
                    units={
                      unitsToTransfer ??
                      capitalInvestment.totalContributionUnits
                    }
                    withBrackets={false}
                  />
                </div>
              )}
            </div>
          </div>
          {resetSelectedCapitalInvestment && (
            <div>
              <IconButton
                iconName="closeSmall"
                onClick={resetSelectedCapitalInvestment}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const CreateTransferFlow = ({ objectId }: { objectId: number }) => {
  const { stepIdx, onBackward, onForward } = useWorkflowSteps();
  const [fullWidth, setFullWidth] = useState(false);
  const [selectedSourceInvestmentEntity, setSelectedSourceInvestmentEntity] =
    useState();
  const [selectedClass, setSelectedClass] = useState();
  const [amountToTransfer, setAmountToTransfer] = useState();
  const [unitsToTransfer, setUnitsToTransfer] = useState();
  const [transferDate, setTransferDate] = useState();
  const [dateForUnitPrice, setDateForUnitPrice] = useState();
  const [receivers, setReceivers] = useState([]);
  const { capitalUnitPricesPartitionByInvestmentClass } =
    useCapitalUnitPricesByInvestmentClass({
      investmentClass: selectedClass,
    });
  const [unitPrices] = capitalUnitPricesPartitionByInvestmentClass;
  const hasUnitPrices = unitPrices.length > 0;

  const fetchCurrentUnitPrice = () => {
    if (hasUnitPrices && dateForUnitPrice) {
      return getClosestUnitPrice({
        date: dateForUnitPrice,
        unitPrices,
      });
    }

    return undefined;
  };

  const setReceiversWithDate = (items) => {
    setReceivers(
      items.map((item) => ({
        ...item,
        date: transferDate,
      })),
    );
  };

  useEffect(() => {
    setSelectedClass(
      selectedSourceInvestmentEntity?.investmentClasses.length > 1
        ? null
        : selectedSourceInvestmentEntity?.investmentClasses[0],
    );
    setAmountToTransfer(null);
    setTransferDate(null);
    setReceivers([]);
    setDateForUnitPrice(undefined);
  }, [selectedSourceInvestmentEntity]);

  useEffect(() => {
    setUnitsToTransfer(
      convertDollarsToCents(amountToTransfer) /
        fetchCurrentUnitPrice()?.priceCents,
    );

    if (fetchCurrentUnitPrice()?.priceCents === 0) {
      setAmountToTransfer(0);
    }
  }, [dateForUnitPrice]);

  useEffect(() => {
    if (
      hasUnitPrices &&
      selectedClass &&
      selectedSourceInvestmentEntity?.firstContributionDate
    ) {
      setDateForUnitPrice(
        new Date(selectedSourceInvestmentEntity.firstContributionDate),
      );
    } else {
      setDateForUnitPrice(undefined);
    }
  }, [selectedClass]);

  const { navigateBack } = useNavigateBack({
    fallbackUrl: generateUrl(ROUTES_ROOT.return.object.fullPath, {
      queryParams: {},
      pathParams: { objectId },
    }),
  });

  const confirmClosingWorkflow = () => {
    navigateBack();
  };

  const workflowFlags = createWorkflowFlags({
    selectedSourceInvestmentEntity,
    selectedClass,
    amountToTransfer,
    transferDate,
    receivers,
    stepIdx,
    hasUnitPrices,
    dateForUnitPrice,
    currentUnitPrice: fetchCurrentUnitPrice(),
    unitsToTransfer,
  });

  const [transferContribution] =
    usePostApiCapitalInvestmentContributionsTransferMutation();

  const { openModal, modalList } = useModal();

  const resolveGoForward = () => {
    if (workflowFlags.steps.transferSource.isCurrent) {
      return () => onForward();
    }

    if (workflowFlags.steps.receivers.isCurrent) {
      return async () => {
        const data = await openModal(
          SetReceiversModal,
          {
            selectedCapitalInvestment: selectedSourceInvestmentEntity,
            amountToTransfer,
            unitsToTransfer,
            receivers,
            transferDate,
            dateForUnitPrice,
            selectedClass,
            unitPrice: fetchCurrentUnitPrice(),
          },
          {
            topLevelRender: false,
          },
        );

        if (data) {
          setReceivers(data);
          onForward();
          setFullWidth(true);
        }
      };
    }

    if (workflowFlags.steps.review.isCurrent) {
      return async () => {
        const res = await transferContribution({
          body: {
            source_investment_id: selectedSourceInvestmentEntity.id,
            investment_class: selectedClass,
            transfer_date: formatToDateStringForRequest(transferDate),
            date: formatToDateStringForRequest(
              dateForUnitPrice ?? transferDate,
            ),
            target_investments_with_amount_cents: receivers.map((r) => ({
              investment_id: r.id,
              amount_cents: convertDollarsToCents(r.amount),
              date: formatToDateStringForRequest(r.date),
              units_quantity: r.unitsQuantity,
            })),
          },
        });

        if (!('error' in res)) {
          confirmClosingWorkflow();
        }
      };
    }
  };

  const resolveGoForwardDisabled = () => {
    if (workflowFlags.steps.transferSource.isCurrent) {
      return workflowFlags.steps.transferSource.isDisabledToGoForward;
    }

    if (workflowFlags.steps.receivers.isCurrent) {
      return workflowFlags.steps.receivers.isDisabledToGoForward;
    }

    if (workflowFlags.steps.review.isCurrent) {
      return false;
    }

    return true;
  };

  let classes = selectedSourceInvestmentEntity?.investmentClasses
    ? [...selectedSourceInvestmentEntity.investmentClasses]
    : [];
  classes = classes.sort();

  const workflowMap = createTransferFlowMap({ stepIdx });
  const rightPanelStepsComponentMap: Record<StepIdx, JSX.Element> = {
    0: (
      <div className="p-tw-6">
        {!selectedSourceInvestmentEntity && FIRST_STEP_HINT_INFO_JSX}

        {selectedSourceInvestmentEntity && (
          <div className="flex flex-col gap-l">
            <div>
              {SELECT_INVESTMENT_TEXT_JSX}

              <SelectedCapitalInvestment
                capitalInvestment={selectedSourceInvestmentEntity}
                resetSelectedCapitalInvestment={() => {
                  setSelectedSourceInvestmentEntity(null);
                }}
              />
            </div>

            {selectedSourceInvestmentEntity.investmentClasses.length > 1 && (
              <div className="flex flex-col gap-s">
                {SELECT_CLASS_TO_TRANSFER_JSX}
                {classes.map((ic) => (
                  <div className="flex justify-between" key={ic}>
                    <RadioButton
                      checked={selectedClass === ic}
                      onChange={() => {
                        setSelectedClass(ic);
                      }}
                    >
                      Class {ic.toUpperCase()}
                    </RadioButton>
                    <div>
                      <CurrencyFormatter
                        classes={{ value: 'text-dark-60' }}
                        value={convertCentsToDollars(
                          selectedSourceInvestmentEntity[
                            `totalClass${ic.toUpperCase()}ContributionsAmountCents`
                          ],
                        )}
                      />
                      {selectedSourceInvestmentEntity[
                        `totalClass${ic.toUpperCase()}ContributionsUnits`
                      ] > 0 && (
                        <span className="secondary-regular text-neutral-550">
                          &nbsp;(
                          {
                            selectedSourceInvestmentEntity[
                              `totalClass${ic.toUpperCase()}ContributionsUnits`
                            ]
                          }{' '}
                          units)
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex gap-s">
              <div>
                {TRANSFER_DATE_JSX}

                <div className="mt-s">
                  <ReactDatePicker
                    {...STATIC_DATE_PICKER_PROPS}
                    selected={transferDate}
                    onChange={(date) => {
                      setTransferDate(date);
                    }}
                  />
                </div>
              </div>

              {hasUnitPrices && (
                <div>
                  {CONTRIBUTION_DATE_JSX}

                  <div className="mt-s">
                    <ReactDatePicker
                      {...STATIC_DATE_PICKER_PROPS}
                      selected={dateForUnitPrice}
                      onChange={(date) => {
                        setDateForUnitPrice(date);
                      }}
                    />
                  </div>
                  {fetchCurrentUnitPrice() != undefined && (
                    <div className="label-regular pt-xs">
                      One unit is{' '}
                      <CurrencyFormatter
                        value={convertCentsToDollars(
                          fetchCurrentUnitPrice()?.priceCents,
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div>
              {AMOUNT_TO_TRANSFER_JSX}
              <div className="flex flex-row gap-s">
                <div>
                  <div className="flex">
                    <div>
                      <div className="label-regular mb-s text-neutral-850">
                        Contribution Amount
                      </div>
                      <CurrencyInputNumber
                        disabled={fetchCurrentUnitPrice()?.priceCents === 0}
                        allowNegative={false}
                        value={amountToTransfer}
                        onValueChange={(value) => {
                          setAmountToTransfer(value ?? null);
                          if (!fetchCurrentUnitPrice()) return;

                          setUnitsToTransfer(
                            convertDollarsToCents(value) /
                              fetchCurrentUnitPrice().priceCents,
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  {hasUnitPrices && (
                    <>
                      <div className="label-regular mb-s text-neutral-850">
                        Units
                      </div>
                      <UnitsInputNumber
                        selectOnFocus
                        value={fetchCurrentUnitPrice() ? unitsToTransfer : ''}
                        placeholder="0"
                        disabled={!fetchCurrentUnitPrice()}
                        onChange={(e) => {
                          setUnitsToTransfer(Number(e.target.value));
                          setAmountToTransfer(
                            convertCentsToDollars(
                              e.target.value *
                                fetchCurrentUnitPrice().priceCents,
                            ),
                          );
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ),
    1: (
      <div className="flex flex-col gap-l p-tw-6">
        <div className="flex flex-col gap-s">
          <p className="body-semibold text-dark-60">Source Investment Entity</p>
          <SelectedCapitalInvestment
            capitalInvestment={selectedSourceInvestmentEntity}
            amountToTransfer={amountToTransfer}
            unitsToTransfer={unitsToTransfer}
          />
        </div>

        <div className="flex flex-col gap-s">
          <p className="body-semibold text-dark-60">
            Receivers: <span className="inline-semibold text-red">*</span>
          </p>
          {receivers.length === 0 && (
            <>
              <p className="inline-regular text-neutral-550">
                Select Investment Entity
              </p>
              <div className="flex justify-center p-tw-4">
                <img src={selectIEimage} alt={HINT_IMG_ALT_TEXT} width={188} />
              </div>
            </>
          )}

          {receivers.length > 0 && (
            <SelectedInvEntities
              totalCountLabel="Investment entity"
              invEntities={receivers}
              onRemoveItem={(id) => {
                setReceivers((prev) => prev.filter((r) => r.id !== id));
              }}
            />
          )}
        </div>
      </div>
    ),
    2: (
      <>
        <TransferReviewStep
          sourceInvestment={selectedSourceInvestmentEntity}
          transferDate={transferDate}
          amountToTransfer={amountToTransfer}
          receivers={receivers}
        />
      </>
    ),
  } as const;

  const currentRightPanelComponent = rightPanelStepsComponentMap[stepIdx];

  const resolveGoBackward = () => {
    switch (true) {
      case workflowFlags.steps.receivers.isCurrent: {
        return () => {
          onBackward();
        };
      }

      case workflowFlags.steps.review.isCurrent: {
        return () => {
          onBackward();
          setFullWidth(false);
        };
      }
      default: {
        throw new Error(
          'Should not happen. Step index should be in range of 1-2',
        );
      }
    }
  };

  const loading = false;

  return (
    <div className="relative flex min-h-screen">
      {modalList}
      {workflowFlags.steps.transferSource.isCurrent && (
        <SelectInvestmentEntityStep
          selectedSourceInvestmentEntity={selectedSourceInvestmentEntity}
          setSelectedSourceInvestmentEntity={setSelectedSourceInvestmentEntity}
        />
      )}

      {workflowFlags.steps.receivers.isCurrent && (
        <SelectInvestmentEntityStep
          selectedSourceInvestmentEntity={selectedSourceInvestmentEntity}
          selectedRows={receivers}
          setSelectedRows={setReceiversWithDate}
          excludedIds={
            selectedSourceInvestmentEntity
              ? [selectedSourceInvestmentEntity.id]
              : []
          }
        />
      )}

      <div
        className={cn(
          'fixed bottom-[0] right-0 top-0 z-10 flex h-max min-h-screen flex-col bg-tw-white transition-all',
          {
            'w-[480px]': !fullWidth,
            'w-full': fullWidth,
          },
        )}
      >
        <HeaderForStepperModal
          currentIdx={stepIdx}
          steps={CREATE_TRANSFER_WORKFLOW_STEPS}
          onClose={confirmClosingWorkflow}
          heading={workflowMap.entryCreationText}
          // disableCloseBtn={creatingEntries}
        />

        {currentRightPanelComponent}

        <div
          className={cn('mt-auto flex flex-col gap-tw-2.5 bg-tw-white p-tw-6', {
            'pt-tw-4': fullWidth,
            'pt-tw-2': !fullWidth,
          })}
        >
          {workflowFlags.steps.transferSource.isCurrent && (
            <span className="label-regular text-center text-neutral-550">
              Select Source to go Forward
            </span>
          )}
          {workflowFlags.steps.receivers.isCurrent && (
            <span className="label-regular text-center text-neutral-550">
              Select Receivers to go Forward
            </span>
          )}
          <div
            className={cn('grid max-w-[440px] items-center gap-tw-3', {
              'grid-cols-2': !workflowFlags.steps.transferSource.isCurrent,
              'w-[466px] self-center': fullWidth,
            })}
          >
            {!workflowFlags.steps.transferSource.isCurrent && (
              <Button
                onClick={resolveGoBackward()}
                disabled={loading}
                variant="secondary"
              >
                {workflowMap.goBackwardButtonTextJSX}
              </Button>
            )}
            <Button
              onClick={resolveGoForward()}
              disabled={resolveGoForwardDisabled()}
              variant="success"
            >
              {loading ? (
                <Icon iconName="sync" className="animate-spin" />
              ) : (
                workflowMap.goForwardButtonText
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { RentRollChargeCode } from '@/bundles/REport/components/operational/Rentroll/types';
import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import { BOTTOM_LEVEL_POPOVER_Z_INDEX } from '@/bundles/Shared/constants';
import { Button, IconButton, Popover } from '@/stories';
import { useState } from 'react';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';

export function SreChargeCode({
  value,
  onSubmit,
  onReset,
  initialValue = null,
  showEditButton = false,
  disabled,
  sreChargeCodes,
}: {
  value: string | null | undefined;
  onSubmit: (code: RentRollChargeCode) => void;
  sreChargeCodes: {
    label: string;
    value: RentRollChargeCode;
  }[];
  initialValue?: RentRollChargeCode | null;
  showEditButton?: boolean;
  onReset?: () => void;
  disabled?: boolean;
}) {
  const [checkedChargeCode, setCheckedChargeCode] =
    useState<RentRollChargeCode | null>(initialValue);

  return (
    <div className="flex items-center gap-tw-1">
      {value == null || showEditButton ? (
        <Popover
          onHidden={() => {
            if (checkedChargeCode == null) return;

            if (initialValue === checkedChargeCode) return;

            onSubmit(checkedChargeCode);
          }}
          zIndex={BOTTOM_LEVEL_POPOVER_Z_INDEX}
          appendToBody
          placement="bottom-end"
          trigger="click"
          maxWidth={280}
          hiddenArrow
          template={
            <div className="flex flex-col gap-tw-2">
              <div className="flex w-[248px] items-center justify-between">
                <p className="secondary-semibold text-neutral-500">
                  sRE{' '}
                  {
                    SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes
                      .pluralForm
                  }
                </p>
                {initialValue && (
                  <Button variant="secondary" size="xs" onClick={onReset}>
                    Reset
                  </Button>
                )}
              </div>
              <RadioGroup
                options={sreChargeCodes}
                value={checkedChargeCode}
                onChange={(o) =>
                  setCheckedChargeCode(o.value as RentRollChargeCode)
                }
              />
            </div>
          }
        >
          {showEditButton ? (
            <IconButton size="s" iconName="edit" />
          ) : (
            <Button
              altIconName="arrowBottom"
              altIconPosition="right"
              iconName="link"
              variant="secondary"
              size="xs"
              disabled={disabled}
              tooltipProps={{
                disabled: !disabled,
                mainText: 'Legal Entity classification is required',
              }}
            >
              {initialValue != null ? null : (
                <>
                  Set{' '}
                  {
                    SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes
                      .singularForm
                  }
                </>
              )}
            </Button>
          )}
        </Popover>
      ) : (
        sreChargeCodes.find((cc) => cc.value === value)?.label
      )}
    </div>
  );
}

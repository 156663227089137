import * as React from 'react';
import { ComponentProps } from 'react';
import FilterItem from '../common/filterItem/FilterItem';
import EntitiesCounter from './entitiesCounter/EntitiesCounter';

interface Props extends Omit<ComponentProps<typeof FilterItem>, 'rightIcon'> {
  legalEntitiesCount: number;
  selectedLegalEntitiesCount: number;
}

function AssetFilterItem({
  legalEntitiesCount,
  selectedLegalEntitiesCount,
  ...props
}: Props) {
  return (
    <FilterItem
      rightIcon={
        legalEntitiesCount > 0 && (
          <EntitiesCounter
            disabled={props.hidden}
            totalCount={legalEntitiesCount}
            selectedCount={selectedLegalEntitiesCount}
          />
        )
      }
      {...props}
    />
  );
}

export default AssetFilterItem;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from './GroupContainer.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const GroupContainer = ({ title, children }: Props) => (
  <div>
    <div className={cn(styles.title, 'secondary-regular')}>{title}</div>
    {children}
  </div>
);

import React, { FC, ReactNode } from 'react';
import { UnitNum } from 'stories';
import './unit-for-step.scss';
import { cn } from '@/shared/lib/css/cn';

interface IUnitForStepByStepReviewProps {
  title: string;
  onEdit: () => void;
  number: number;
  children: ReactNode;
  disabled?: boolean;
}

export const UnitForStepByStepReview: FC<IUnitForStepByStepReviewProps> = ({
  children,
  number,
  title,
  onEdit,
  disabled,
}) => (
  <div className="flex flex-col gap-tw-4">
    {number && (
      <div className="flex justify-between">
        <div className="flex items-center">
          <UnitNum
            className={cn(
              'sre-unitstep-number',
              disabled && 'sre-unitstep-number_disabled',
            )}
          >
            {number}
          </UnitNum>
          <div className={cn('body-semibold', !disabled && 'text-dark-60')}>
            {title}
          </div>
        </div>
        {onEdit && (
          <div onClick={onEdit} className="sre-unitstep-button inline-regular">
            Edit
          </div>
        )}
      </div>
    )}
    {children}
  </div>
);

import http from 'lib/http';
import pluralize from 'pluralize';
import { REMOVE_SHARED_FILES } from './SharedFiles';

export default function removeAssetMedias(data) {
  const { assetId, ids } = data;

  return async (dispatch) => {
    const res = await http.del(`/assets/${assetId}/medias/bulk_destroy`, data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success(`${pluralize('File', ids.length)} ${pluralize('have', ids.length)} been removed`);
      dispatch({
        type: REMOVE_SHARED_FILES,
        payload: data,
      });
    }
  };
}

import React from 'react';
import {
  ObjectsSettingsNavigationSection,
  GeneralSettingsNavigationSection,
} from 'stories';
import { TRouteParams, useNavigation } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';

function ReconcileDevelopmentSettingsNavigation() {
  const navigation = useNavigation();

  const { tab: currentTab } =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_SETTINGS']>();

  const getNavigationProps = (
    tab: TRouteParams['RECONCILE_DEVELOPMENT_SETTINGS']['tab'],
  ) => ({
    active: currentTab === tab,
    link: navigation.getUrl('RECONCILE_DEVELOPMENT_SETTINGS', {
      tab,
    }),
  });

  return (
    <div className="flex gap-tw-4">
      <GeneralSettingsNavigationSection
        title="Companies"
        subtitle="Collection of companies"
        {...getNavigationProps('companies')}
      />
      <GeneralSettingsNavigationSection
        title="Contacts"
        subtitle="Collection of contacts"
        {...getNavigationProps('contacts')}
      />
      <ObjectsSettingsNavigationSection
        title="Configs"
        tabs={[
          {
            title: 'Change Order Reasons',
            ...getNavigationProps('cors'),
          },
          {
            title: 'Job Cost Code',
            ...getNavigationProps('jccs'),
          },
          {
            title: 'Schedule of Value Codes',
            ...getNavigationProps('sovcs'),
          },
        ]}
      />
    </div>
  );
}

export default ReconcileDevelopmentSettingsNavigation;

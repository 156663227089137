import { fetchCustomerData, fetchGenericData } from '@/app/reducers/actions';
import { createSlice } from '@reduxjs/toolkit';
import { IGenericData } from 'bundles/Shared/GenericContext';
import { TRootState } from '@/app/stores';

const initialState: {
  customer: IGenericData['customer'] | null;
  genericData: IGenericData | null;
} = { genericData: null, customer: null };

export const genericSlice = createSlice({
  name: 'generic',
  reducers: {
    resetGenericData: (state) => {
      state.genericData = null;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchGenericData.fulfilled, (state, action) => {
      state.genericData = action.payload;
    });
    builder.addCase(fetchCustomerData.fulfilled, (state, action) => {
      state.customer = action.payload;
    });
  },
});

export const { resetGenericData } = genericSlice.actions;

export const selectCustomer = (state: TRootState) => state.generic.customer;
export const selectGenericData = (state: TRootState) =>
  state.generic.genericData;

import React, { FC, useEffect, useState } from 'react';
import { fetchVendorsEntities } from 'bundles/Settings/actions/vendor_entities';
import { useCustomer } from 'lib/customers';
import {
  REPORT_PRODUCT_NAME,
  TProductNames,
  currentUserAllowedTo,
  currentUserIsSreAdmin,
} from 'lib/permissions';
import investmentEntityColumns from '../../../../UserManagement/components/helpers/investmentEntityColumns';
import InvestmentEntityModal from './InvestmentEntityModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import EditUserModal from 'bundles/UserManagement/components/EditUserModal';
import {
  useCreateInvestmentEntityMutation,
  useDestroyInvestmentEntityMutation,
  useGetInvestmentEntitiesQuery,
  useUpdateInvestmentEntityMutation,
} from 'bundles/Settings/components/Portal/InvestmentEntities/api/investmentEntitiesApi';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { IPaginationParams, IQueryParams } from 'types/Pagination';
import { SortType } from 'types/Sort';
import { IUser } from 'types/User';
import { Button } from 'stories/index';
import Table from 'bundles/Shared/components/Table/Table';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import { RouteComponentProps } from '@reach/router';
import { coreFiltersUsersSettingsApi } from 'bundles/UserManagement/api/settingsCoreLegalEntitiesApi';
import { ExportREturnButton } from '@/bundles/Shared/features/return/import/ui/ImportREturnButton';

export type InvestmentEntitiesPageParams = Required<IQueryParams> &
  Required<IPaginationParams> & {
    sortField: 'updated_at';
    sortOrder: SortType;
    filters: {
      vendorEntities: VendorEntity[];
      users: IUser[];
    };
  };

const EntitiesTracker: FC<RouteComponentProps> = () => {
  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);

  const { openModal } = useModal();

  const [pageParams, setPageParams] = useState<InvestmentEntitiesPageParams>({
    page: 1,
    perPage: 20,
    query: '',
    sortField: 'updated_at',
    sortOrder: 'desc',
    filters: {
      vendorEntities: [],
      users: [],
    },
  });
  const { data, isFetching, refetch } =
    useGetInvestmentEntitiesQuery(pageParams);
  const [destroyInvestmentEntity] = useDestroyInvestmentEntityMutation();
  const [updateInvestmentEntity] = useUpdateInvestmentEntityMutation();
  const [createInvestmentEntity] = useCreateInvestmentEntityMutation();

  const [allVendorEntities, setAllVendorEntities] = useState<VendorEntity[]>(
    [],
  );

  useEffect(() => {
    if (!currentCustomerHasReportProduct) return;

    fetchVendorsEntities().then((res) =>
      setAllVendorEntities(
        (
          res as {
            meta: {
              perPage: number;
              totalSize: number;
            };
            vendors: VendorEntity[];
          }
        ).vendors,
      ),
    );
  }, []);

  const { data: coreFiltersUsers, isLoading: isCoreFiltersUsersLoading } =
    coreFiltersUsersSettingsApi.useGetApiSettingsCoreFiltersUsersQuery();

  const columns = investmentEntityColumns({
    actions: {
      onUserClick: (user) => {
        openModal(EditUserModal, {
          user,
          afterUpdateCallback: refetch,
        });
      },
      edit: async (item) => {
        const res = await openModal(InvestmentEntityModal, {
          currentItem: item,
          allUsers: coreFiltersUsers ?? [],
          allVendorEntities,
        });

        if (!res) return;
        await updateInvestmentEntity(res);
      },
      destroy: destroyInvestmentEntity,
    },
    userOptions: {
      users: coreFiltersUsers ?? [],
      isLoading: isCoreFiltersUsersLoading,
    },
    vendorOptions: {
      vendorEntities: allVendorEntities,
    },
  });

  return (
    <div className="flex flex-col gap-tw-4">
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div className="mt-tw-4 flex items-center justify-between">
          <PageParamsPagination
            loading={isFetching}
            sizePerPage={pageParams.perPage ?? 0}
            totalSize={data?.meta?.totalSize ?? 0}
          />
          <div className="ml-auto flex items-center gap-m">
            {currentUserIsSreAdmin() &&
              currentUserAllowedTo('manage', TProductNames.RETURN) && (
                <ExportREturnButton refetch={refetch} />
              )}
            <PageParamsSearch size="m" />
            <Button
              className="font-medium"
              onClick={async () => {
                const res = await openModal(InvestmentEntityModal, {
                  allUsers: coreFiltersUsers ?? [],
                  allVendorEntities,
                });

                if (!res) return;
                await createInvestmentEntity(res);
              }}
              variant="primary"
            >
              Add Investment Entity
            </Button>
          </div>
        </div>
        <Table
          items={data?.items ?? []}
          columns={columns}
          settings={pageParams}
          setSettings={setPageParams}
          loading={isFetching}
        />
      </PageParamsProvider>
    </div>
  );
};

export default EntitiesTracker;

import { Contribution, Distribution } from '@/entities/return';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { IGeneralLedger } from 'types/GeneralLedger';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { IInvestmentObject } from 'types/IInvestmentObject';
import { LegalEntity } from '@/entities/core/legalEntity';
import { IReportLineItem } from 'types/ReportLineItem';
import { TSort } from 'types/Sort';
import { PropertiesCamelToSnakeCase } from 'types/StringUtilityTypes';
import { IUser } from 'types/User';
import { ICapitalInvestment } from '../types';

export const DISTRIBUTION_KINDS = [
  'preferred',
  'excess_cash',
  'refinance',
  'sale',
  'liquidation',
  'return_of_capital',
] as const;
export type DistributionKind = (typeof DISTRIBUTION_KINDS)[number];

interface MappedCapitalInvestmentTransactions
  extends Pick<
    CapitalInvestmentEntryBase,
    'id' | 'amount' | 'createdAt' | 'createdBy'
  > {
  capitalInvestmentId: ICapitalInvestment['id'];
  investmentEntity: Pick<IInvestmentEntity, 'id' | 'name'>;
  kind?: DistributionKind;
}

export type TransactionReportLineItem = Pick<IReportLineItem, 'id' | 'date'> & {
  type: ReportLineItemType;
  amount: number;
  mappedTransactions: MappedCapitalInvestmentTransactions[];
  generalLedger: Pick<IGeneralLedger, 'id' | 'accNumber' | 'accTitle'> & {
    legalEntity: Pick<LegalEntity, 'source'>;
  };
};

export const ENTRY_TYPES = ['contributions', 'distributions'] as const;

export const [CONTRIBUTION_ENTRY_TYPE, DISTRIBUTION_ENTRY_TYPE] = ENTRY_TYPES;

export type CapitalInvestmentEntryType = (typeof ENTRY_TYPES)[number];

export interface CapitalInvestmentEntryBase {
  id: number;
  amount: RawFloat;
  date: IPeriodItem['period'];
  createdBy: Pick<IUser, 'fullName'>;
  createdAt: UnixTime;
  updatedBy: Pick<IUser, 'fullName'>;
  updatedAt: UnixTime;
  investmentEntity: Pick<IInvestmentEntity, 'id' | 'name' | 'users'>;
  reportLineItem: TransactionReportLineItem | null;
  kind?: DistributionKind;
}

export type CapitalEntry = Contribution | Distribution;

export type CapitalDistribution = Distribution;

export type CapitalContribution = Contribution;

export type ItemsWithMetaResponse<Item, Meta> = {
  items: Item[];
  meta: Meta;
};

export type EntryResponse<TX extends CapitalInvestmentEntryBase> =
  ItemsWithMetaResponse<
    TX,
    {
      notMappedCount: number;
    }
  >;

export type ReportLineItem = {
  id: number;
  date: string;
  period: string;
  type: ReportLineItemType;
  generalLedgerTitle: string;
  amount: RawFloat;
  note: string;
  legalEntity: LegalEntity;
  vendorEntity: {
    name: string;
    code: string;
  } | null;
  remark?: string;
};

export type ReportLineItemsResponse = ItemsWithMetaResponse<
  ReportLineItem,
  {
    perPage: string;
    totalSize: number;
  }
>;

export type FetchCapitalInvestmentParams = {
  investmentId?: ICapitalInvestment['id'];
  fromDate?: string;
  investmentObjectId?: IInvestmentObject['id'];
  maxAmount?: number;
  notMapped?: boolean;
  toDate?: string;
};

export type FetchCapitalInvestmentReqParams =
  PropertiesCamelToSnakeCase<FetchCapitalInvestmentParams>;

export type ReportLineItemType = 'debit' | 'credit';

export type FetchReportLineItemsParams = {
  fromDate: string;
  toDate: string;
  query: string;
  sort: {
    order: TSort;
    field: 'date' | 'amount';
  };
  vendorEntityIds: string[];
  legalEntityIds: LegalEntity['id'][];
  generalLedgerIds: IGeneralLedger['id'][];
  type?: ReportLineItemType;
  exactAmountCents?: RawFloat;
  amountCents?: {
    from?: number;
    to?: number;
  };
};

export type FetchReportLineItemsReqParams =
  PropertiesCamelToSnakeCase<FetchReportLineItemsParams>;

import React from 'react';
import { useNavigate } from '@reach/router';

function withAccess<C extends React.FC<any>>(
  Component: C,
  {
    hasAccess,
    redirectTo = '/',
  }: {
    hasAccess: () => boolean;
    redirectTo?: string;
  },
) {
  return (props: React.ComponentProps<C>) => {
    const navigate = useNavigate();

    if (!hasAccess()) {
      navigate(redirectTo);
    }
    return <Component {...props} />;
  };
}

export default withAccess;

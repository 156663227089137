import { convertToSeconds } from '@/shared/lib/converters';
import { useState } from 'react';

interface HookOptions {
  fileMode?: 'attach' | 'replace';
  multiple?: boolean;
  accept?: string;
}

const DEFAULT_OPTIONS: HookOptions = {
  multiple: true,
  accept: '*',
};

export default function useFileDialog(options?: HookOptions) {
  const [files, setFiles] = useState<File[]>([]);

  const openFileDialog = (localOptions?: HookOptions) =>
    new Promise<FileList | null>((resolve) => {
      const _options: HookOptions = {
        ...DEFAULT_OPTIONS,
        ...options,
        ...localOptions,
      };

      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = _options.multiple!;
      input.accept = _options.accept!;

      input.onchange = (event: Event) => {
        const { files: eventFiles }: { files: FileList | null } =
          event.target as HTMLInputElement;

        const getDroppedAt = () =>
          convertToSeconds(new Date().getTime() as UnixTime);

        [...(eventFiles ?? [])].forEach((file, idx) => {
          // eslint-disable-next-line no-param-reassign
          file.droppedAt = getDroppedAt() + idx;
        });

        setFiles(
          _options.fileMode === 'attach'
            ? Array.from(eventFiles ?? [])
            : [...files, ...(eventFiles ?? [])],
        );

        resolve(eventFiles);
      };

      input.click();
    });

  return { files, openFileDialog, setFiles };
}

import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';

export const generateUrlSettingsUnitTypes = (mapped = true) =>
  generateUrl(ROUTES_ROOT.settings.report.operational.unitTypes.fullPath, {
    queryParams: {
      mapped,
    },
  });

export function isSreUnitTypeNameValid(name: string) {
  return /^[a-z_0-9]+$/g.test(name);
}

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import './icon.css';
import { IconsId } from 'types/sre-icons';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  iconName: IconsId;
  className?: string;
  backgroundColor?: string;
  padding?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
}

export const Icon = ({
  className,
  iconName,
  onClick,
  backgroundColor,
  padding,
  color,
  style,
  ...props
}: Props) => {
  const isClickable = typeof onClick === 'function';
  return (
    <i
      aria-hidden="true"
      className={cn('sre-icon', `sre-icon-${iconName}`, className, {
        'sre-icon_clickable': isClickable,
        [`p-${padding}`]: padding,
      })}
      onClick={onClick}
      style={{ backgroundColor, color, borderRadius: '0.5rem', ...style }}
      {...props}
    />
  );
};

export default Icon;

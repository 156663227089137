import { IconsId } from 'types/sre-icons';
import { TItemType } from './types';

export const ICON_MAP: Record<TItemType, IconsId> = {
  fund: 'funds',
  asset: 'asset',
  public: 'public',
  role: 'role',
  tag: 'tags',
  member: 'members',
  investmentEntity: 'entities',
} as const;

export const LABEL_MAP: Record<TItemType, string> = {
  fund: 'Fund',
  asset: 'Asset',
  public: 'Public',
  role: 'Role',
  tag: 'Tag',
  member: 'Member',
  investmentEntity: 'Investment Entity',
} as const;

export const EDIT_PERMISSIONS_SIDE_TABS = {
  directRoles: 'Roles',
  directTags: 'Tags',
  directUsers: 'Members',
  directInvestmentEntities: 'Entities',
} as const;

import { IFireStationStats } from 'types/FireStationStats';
import { capitalize } from 'lodash-es';

export const MODULES = {
  core: {
    color: 'primary',
  },
  reconcile: {
    color: 'violet',
  },
  report: {
    color: 'green',
  },
  pipeline: {
    color: 'blue',
  },
};

export const PRIORITIES = ['high', 'medium', 'low'];

export function normalizeModuleTagLabel(label: string) {
  const labelToLowerCase = label.toLowerCase();
  return labelToLowerCase.includes('re')
    ? labelToLowerCase.replace('re', 'RE')
    : capitalize(label);
}

export const order = {
  high: 1,
  medium: 2,
  low: 3,
};

type FireStatsArray = {
  priority: 'high' | 'medium' | 'low';
  count: number;
}[];

export function mapFireStatsForSorting(
  fireStats: IFireStationStats,
): FireStatsArray {
  const PRIORITY_SEPARATOR = '_';
  const modifiedFireStationStats = [];
  Object.entries(fireStats).forEach(([key, value]) => {
    if (value) {
      modifiedFireStationStats.push({
        priority: key.split(PRIORITY_SEPARATOR)[0],
        count: value,
      });
    }
  });
  return modifiedFireStationStats;
}

export function sortFireStatsByPriority(fireStationStats) {
  return fireStationStats.sort((a, b) => order[a.priority] - order[b.priority]);
}

export function generatePopoverContent(data) {
  return {
    autoSelectedUsers: [],
    investmentEntities: [],
    roles: [],
    tags: [],
    users: [...data],
  };
}

import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Control } from 'react-hook-form';
import { TumblerController } from 'stories/Tumbler/Tumbler';
import { Field } from '@/stories';
import { SectionField } from 'stories/Field/FieldsWrappers';

export function HideTotalField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  fieldType,
}: {
  control: Control<TFieldValues>;
  name: TName;
  fieldType: 'row' | 'column';
}) {
  if (fieldType === 'column') {
    return (
      <SectionField
        labelText="Hide Total Calculation"
        note="You can turn off the Total calculation for the column"
        button={<TumblerController control={control} name={name} />}
      />
    );
  }
  return (
    <Field
      labelText="Display Total"
      note="You can turn off the Total row for the widget"
    >
      <TumblerController control={control} name={name}>
        Hide Total Calculation
      </TumblerController>
    </Field>
  );
}

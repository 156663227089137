import React from 'react';

export const creatableSelectMessage = (entity: string) => (input: string) => (
  <div>
    <strong>"{input}"</strong> {entity} was not found.
    <div className="blue pt-tw-2">Add as new</div>
  </div>
);

export const creatableSelectCreateVendorMessage =
  creatableSelectMessage('vendor');

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { FireIcon } from '../FireIcon/FireIcon';

interface Props {
  priority: 'low' | 'medium' | 'high';
  className?: string;
}

export const FireEventCover = ({ priority, className, ...props }: Props) => (
  <div
    className={cn(
      'rounded-[0.5rem] d-inline-flex items-center justify-center h-xl w-xl bg-light',
      className,
    )}
    {...props}
  >
    <FireIcon priority={priority} />
  </div>
);

export default FireEventCover;

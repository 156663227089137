import { useEffect, useState } from 'react';
import { ICellRendererParams, RowGroupOpenedEvent } from 'ag-grid-community';

export const useAutoGroupExpand = (params: ICellRendererParams) => {
  const { node, api } = params;
  const [isExpanded, setIsExpanded] = useState(node.expanded);

  useEffect(() => {
    const handleExpandEvent = (e: RowGroupOpenedEvent) => {
      if (e.node.id === node.id && !node.footer) {
        setIsExpanded(node.expanded);
      }
    };

    api.addEventListener('rowGroupOpened', handleExpandEvent);

    return () => {
      api.removeEventListener('rowGroupOpened', handleExpandEvent);
    };
  }, []);

  return [isExpanded, setIsExpanded] as const;
};

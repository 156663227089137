import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import {
  isVarianceJccRow,
  isVarianceSovcRow,
  IVarianceRow,
} from 'bundles/REconcile/types/VarianceRows';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LegalEntity } from '@/entities/core/legalEntity';
import developmentBudgetVarianceCommentsReducer, {
  addRequisitionComment,
} from 'bundles/REconcile/reducers/developmentBudgetVarianceCommentSlice';
import { combineReducers } from 'redux';
import { treeDFS } from 'lib/treeHelpers';
import {
  IBudgetActivityLogEvent,
  TBudgetActivityLogEvents,
} from 'bundles/REconcile/types/ActivityLog';
import * as varianceApi from '../actions/developmentBudgetVariance';
import * as developmentBudgetApi from '../actions/developmentBudgets';

interface DevelopmentBudgetVarianceState {
  status: 'idle' | 'loading' | 'succeeded';
  requisitions: Array<IRequisition>;
  rows: Array<IVarianceRow>;
  activityLog: TBudgetActivityLogEvents[];
  invoicesWithoutRequisitionCount: number;
}

const initialState: DevelopmentBudgetVarianceState = {
  status: 'idle',
  requisitions: [],
  rows: [],
  activityLog: [],
  invoicesWithoutRequisitionCount: 0,
};

export const fetchDevelopmentBudgetVariance = createAsyncThunk(
  'developmentBudgetVariance/fetchVariance',
  async (legalEntityCode: LegalEntity['code']) => {
    const { rows, requisitions, invoicesWithoutRequisitionCount } =
      await varianceApi.fetchDevelopmentBudgetVariance({
        legalEntityCode,
      });
    const activityLog = await varianceApi.fetchBudgetVarianceActivityLog({
      legalEntityCode,
    });

    return {
      rows,
      requisitions,
      activityLog,
      invoicesWithoutRequisitionCount,
    };
  },
);

export const addDevelopmentBudgetRequisition = createAsyncThunk(
  'developmentBudgetVariance/addRequisition',
  async (request: varianceApi.IDevelopmentRequisitionAddRequest) =>
    varianceApi.addDevelopmentBudgetRequisition(request),
);

export const deleteDevelopmentBudgetRequisition = createAsyncThunk(
  'developmentBudgetVariance/deleteRequisition',
  async (request: varianceApi.IDevelopmentRequisitionDeleteRequest) => {
    await varianceApi.deleteDevelopmentBudgetRequisition(request);
    return request.requisitionId;
  },
);

export const updateDevelopmentBudgetRequisition = createAsyncThunk(
  'developmentBudgetVariance/updateRequisition',
  async (request: varianceApi.IDevelopmentRequisitionUpdateRequest) => {
    const res = await varianceApi.updateDevelopmentBudgetRequisition(request);
    return res;
  },
);

export const developmentBudgetVarianceSlice = createSlice({
  name: 'developmentBudgetVariance',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDevelopmentBudgetVariance.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDevelopmentBudgetVariance.fulfilled, (state, action) => {
        const {
          rows,
          requisitions,
          activityLog,
          invoicesWithoutRequisitionCount,
        } = action.payload;
        return {
          status: 'succeeded',
          comments: [],
          rows,
          requisitions,
          activityLog,
          invoicesWithoutRequisitionCount,
        };
      });

    builder.addCase(
      addDevelopmentBudgetRequisition.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.requisitions.push(action.payload);
        }
      },
    );

    builder.addCase(
      deleteDevelopmentBudgetRequisition.fulfilled,
      (state, action) => {
        state.requisitions = state.requisitions.filter(
          (r) => r.id !== action.payload,
        );
      },
    );

    builder.addCase(
      updateDevelopmentBudgetRequisition.fulfilled,
      (state, action) => {
        state.requisitions = state.requisitions.map((r) => {
          if (r.id === action.payload.id) {
            return action.payload;
          }
          return r;
        });
      },
    );

    builder.addCase(addRequisitionComment.fulfilled, (state, action) => {
      const {
        development_categorizable_id,
        development_categorizable_type,
        requisitionId,
        id: commentId,
      } = action.payload;
      const rowPath = treeDFS(state.rows, (row) => {
        if (
          isVarianceJccRow(row) &&
          development_categorizable_type === 'JobCostCode' &&
          row.jobCostCode.id === development_categorizable_id
        ) {
          return true;
        }
        return (
          isVarianceSovcRow(row) &&
          development_categorizable_type === 'ScheduleOfValueCode' &&
          row.scheduleOfValueCode.id === development_categorizable_id
        );
      });
      if (rowPath == null) {
        return;
      }
      const row = rowPath.at(-1);
      if (isVarianceJccRow(row) || isVarianceSovcRow(row)) {
        row.comments.push({
          requisitionId,
          commentId,
        });
      }
    });
  },
});

export default combineReducers({
  data: developmentBudgetVarianceSlice.reducer,
  comments: developmentBudgetVarianceCommentsReducer,
});

import * as yup from 'yup';

export const EDIT_DOCUMENTATION_PAGE_SCHEMA = yup.object().shape({
  title: yup.string().required().trim().min(1),
  content: yup.object().required(),
  reference: yup.string(),
});

export type EditDocumentationPageForm = yup.InferType<
  typeof EDIT_DOCUMENTATION_PAGE_SCHEMA
>;

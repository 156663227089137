import { TUserInviteStatus } from 'types/User';

const STATUS_BADGE_COLORS: Record<TUserInviteStatus | 'active', string> = {
  [TUserInviteStatus.INVITED_LONG_TIME_AGO]: 'var(--yellow-dark-4)',
  [TUserInviteStatus.CREATED]: 'var(--light-90)',
  [TUserInviteStatus.DEACTIVATED]: 'var(--red)',
  [TUserInviteStatus.INVITED]: 'var(--violet)',
  [TUserInviteStatus.ACTIVE]: 'var(--green-dark-2)',
};

const getBadgeColor = (inviteStatus: TUserInviteStatus | null | undefined) =>
  inviteStatus != null ? STATUS_BADGE_COLORS[inviteStatus] : 'lightgreen';

export default getBadgeColor;

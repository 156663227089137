/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavigateFn, Router, useLocation, useParams } from '@reach/router';
import { cn } from '@/shared/lib/css/cn';
import Table from 'bundles/Shared/components/Table/Table';
import { changeOrderEventStatuses } from 'lib/dictionaries';
import { AnimationLoader, Badge, Button, Icon, Tumbler } from 'stories';
import { snakeCase } from 'lodash-es';
import pluralize from 'pluralize';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import { generateXLSX } from '@/shared/lib/deprecated/constructionReport';
import  {
  ChangeOrderEventWorkflow
} from 'bundles/Construction/components/ChangeOrderEventWorkflow';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import BadgesBar from './components/ChangeOrderEvent/BadgesBar/BadgesBar';
import ExportDropdownButton from './components/ExportDropdownButton';
import styles from './styles.module.scss';
import { coeAmountFormatter } from './components/helpers';
import QuickFilterByCategory from './components/Filters/QuickFilterByCategory';
import QuickFilterByReasons from './components/Filters/QuickFilterByReasons';
import QuickFilterByStatus from './components/Filters/QuickFilterByStatus';
import statusColor from './constants/statusColor';
import DocumentsPreview from './components/DocumentsPreview';

import { createChangeOrderEvent, fetchChangeOrderEvents } from './actions';
import type {
  CESortFieldType,
  IChangeOrderEvent,
  IFetchChangeOrderEventsParams,
  IPageParams
} from 'bundles/Construction/types';
import ChangeOrderEvent from 'bundles/Construction/components/ChangeOrderEvent';
import { FETCH_CHANGE_ORDER_EVENT } from 'bundles/Construction/actions';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import InfoBarByAmount from './components/Filters/InfoBarByAmount';
import { IColumn } from 'bundles/Shared/components/Table/types';
import QuickFilterAmount from 'bundles/Shared/components/Table/filters/QuickFilterAmount';
import { selectReasons } from 'bundles/Construction/reducers/ReconcileSlice';
import { saveCELogAsPDF } from 'bundles/Construction/utils';

const initialPageParams: IPageParams = {
  sortField: 'category_number',
  sortOrder: 'desc',
  page: 1,
  per_page: 1000,
  categoryList: [],
  statusList: [],
  reasonList: [],
  amount: {
    from: '',
    to: '',
  },
};

interface Props {
  navigate: NavigateFn;
}

const ChangeEventLogs = ({
  navigate,
}: Props) => {
  const location = useLocation();
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const [pageParams, setPageParams] = useState<IPageParams>(initialPageParams);
  const params = useParams();
  const [idleChecked, setIdleChecked] = useState(location.search.includes('idle_only=t'));
  const legalEntity = useAppSelector((state) => state.developmentBudget.legalEntity);
  const asset = useAppSelector((state) => state.currentAsset);
  const reasons = useAppSelector(selectReasons);
  const changeOrderEvents: IChangeOrderEvent[] = useAppSelector((state) => (
    state.changeOrderManagement.changeOrderEvents
  ));
  const dispatch = useAppDispatch();

  const filteredReasons = () => (
    reasons.filter(r => r.active)
  );

  const resetFilterListByName = (name: string) => {
    setPageParams((prev) => ({ ...prev, [name]: [] }));
  };

  const setFilterListByName = (name: string, value: string[]) => {
    setPageParams((prev) => ({ ...prev, [name]: value }));
  };

  const setCategoryFilter = (value: IPageParams['categoryList']) => setFilterListByName('categoryList', value);
  const resetCategoryFilter = () => resetFilterListByName('categoryList');

  const setReasonFilter = (value: string[]) => setFilterListByName('reasonList', value);
  const resetReasonFilter = () => resetFilterListByName('reasonList');

  const setStatusFilter = (value: IPageParams['statusList']) => setFilterListByName('statusList', value);
  const resetStatusFilter = () => resetFilterListByName('statusList');

  const loadCOE = () => {
    setIsLoading(true);
    const { page, per_page, statusList, categoryList, reasonList, amount } = pageParams;
    const preparedParams: IFetchChangeOrderEventsParams = {
      page,
      sort: {
        order: pageParams.sortOrder,
        field: snakeCase(pageParams.sortField) as CESortFieldType,
      },
      per_page,
      statuses: statusList,
      categories: categoryList,
      change_order_reasons: reasonList.map((item) => item.id),
    };
    if (!Object.values(amount).every((val) => val === '')) {
      preparedParams.values = {};
      if (amount.from) preparedParams.values.from = amount.from;
      if (amount.to) preparedParams.values.to = amount.to;
    }
    dispatch(fetchChangeOrderEvents(
      params?.legalEntityCode || asset?.legalEntities[0].code || legalEntity.code, preparedParams
    ))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadCOE();
  }, [pageParams]);

  const idleEvents = () => changeOrderEvents ? changeOrderEvents.filter((co) => co.idle) : [];

  const items = useMemo(() => {
    let newItems = changeOrderEvents || [];

    if (idleChecked) {
      return newItems.filter((co) => co.idle);
    }
    return newItems;
  }, [idleChecked, changeOrderEvents]);

  const columns = useMemo<IColumn<IChangeOrderEvent>[]>(
    () => [
      {
        sortable: true,
        text: '#',
        dataField: 'number',
        formatter: ({ row: object }) => (
          <>
            <span className={cn(styles.category, styles[object.category])}>
              {object.category}
            </span>{' '}
            <span className="font-weight-600">
              {object.displayNumber}
            </span>
          </>
        ),
        classes: styles.stickyColFst,
        headerClasses: styles.stickyColFst,
        headerStyle: {
          width: 165,
          padding: '1rem',
        },
      },
      {
        text: 'Title & Reasons',
        dataField: 'title_&_reasons',
        formatter: ({ row: object }) => {
          const countReasons = object?.changeOrderReasons?.length;
          return (
            <>
              <strong>{object.title}</strong>
              <p className={styles.reasons}>
                {countReasons} {pluralize('Reason', countReasons)}
              </p>
            </>
          );
        },
        classes: styles.stickyColSnd,
        headerClasses: styles.stickyColSnd,
        headerStyle: {
          minWidth: 280,
        },
      },
      {
        sortable: true,
        text: 'Company',
        dataField: 'reconcileCompany',
        formatter: ({ row: object }) => (
          <div className={styles.company}>
            <Icon iconName="outcome" />
            <span>{object?.reconcileCompany?.name}</span>
          </div>
        ),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 200,
        },
      },
      {
        sortable: true,
        text: 'Contact',
        dataField: 'reconcileContact',
        formatter: ({ row: object }) => (
          <div className={styles.contact}>
            <Icon iconName="role" />
            <span>{object?.reconcileContact?.name}</span>
          </div>
        ),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 200,
        },
      },
      {
        sortable: true,
        text: 'Amount',
        dataField: 'value',
        formatter: ({ row: object }) => (
          <div className={styles.amount}>{coeAmountFormatter(object)}</div>
        ),
        filterComponent: QuickFilterAmount,
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 202,
        },
      },
      {
        text: 'Status',
        dataField: 'status',
        formatter: ({ row: object }) => (
          <Badge
            className="white-space-nowrap"
            backgroundColor={statusColor[object.status]}
            textColor="var(--light)"
          >
            {object.status}
          </Badge>
        ),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        quickFilter: (
          <div className="ml-tw-1.5">
            <QuickFilterByStatus
              items={[...changeOrderEventStatuses, { value: 'COMPLETED', description: '' }]}
              selectedItems={pageParams.statusList}
              setSelectedItems={setStatusFilter}
              resetFilter={resetStatusFilter}
            />
          </div>
        ),
        headerStyle: {
          minWidth: 210,
        },
      },
      {
        sortable: true,
        text: 'Received Date',
        dataField: 'receivedDate',
        formatter: ({ row: object }) => formatUnixDate(object.receivedDate, 'L'),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 160,
        },
      },
      {
        sortable: true,
        text: 'Approved Date',
        dataField: 'approvedDate',
        formatter: ({ row: object }) => formatUnixDate(object.approvedDate, 'L'),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 160,
        },
      },
      {
        sortable: true,
        text: 'CO #',
        dataField: 'changeOrder',
        formatter: ({ row: object }) =>
          object?.changeOrder?.number ? (
            <Link
              to={(
                navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER', {
                  legalEntityCode: params?.legalEntityCode || legalEntity?.code,
                  changeOrderId: object.changeOrder.id,
                })
              )}
              className={cn('second-link-color', styles.link)}
            >
              CO {object.changeOrder.number}
            </Link>
          ) : (
            ''
          ),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 97,
        },
      },
      {
        sortable: true,
        text: 'CO APPROVED date',
        dataField: 'changeOrder.approvedDate',
        formatter: ({ row: object }) => formatDate(object.changeOrder?.approvedDate, 'L'),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 182,
        },
      },
      {
        sortable: true,
        text: 'Temporary REallocation',
        dataField: 'reallocation',
        formatter: ({ row: object }) => object?.reallocation ? (
          <Link
            to={(
              navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATION', {
                legalEntityCode: params?.legalEntityCode || legalEntity?.code,
                reallocationId: object.reallocation.id,
              })
            )}
            className={cn('second-link-color', styles.link)}
          >
            P-{object.reallocation.number}
          </Link>
        ) : (
          ''
        ),
        classes: styles.innerCols,
        headerClasses: styles.innerCols,
        headerStyle: {
          minWidth: 227,
        },
      },
      {
        sortable: true,
        text: 'Files',
        dataField: 'documents',
        formatter: ({ row: object }) =>
          object.documents?.length ? (
            <DocumentsPreview sharedFiles={object.documents} />
          ) : (
            ''
          ),
        classes: styles.stickyColLst,
        headerClasses: styles.stickyColLst,
        headerStyle: {
          minWidth: 93,
        },
      },
    ],
    [changeOrderEvents, pageParams],
  );

  const handleClick = (e, row: IChangeOrderEvent) => {
    if (e.target.tagName !== 'A' && e.target.parentNode.tagName !== 'A') {
      dispatch({ type: FETCH_CHANGE_ORDER_EVENT, payload: row });
      navigate(row.id.toString());
    }
  };

  const filtersIsActive = (): boolean => Boolean(
    pageParams?.reasonList?.length ||
    pageParams?.categoryList?.length ||
    pageParams?.statusList?.length ||
    pageParams?.amount?.from ||
    pageParams?.amount?.to
  );

  if (!changeOrderEvents) return <AnimationLoader />;

  return (
    <div className="row">
      <div className="col-12">
        <BadgesBar
          setParams={setPageParams}
          pageParams={pageParams}
        />
        {filtersIsActive() && (
          <InfoBarByAmount />
        )}
      </div>
      <div className="flex items-center col-12 mb-m">
        {items.length > 0 && (
          <span className={styles.itemsInfo}>
            {' '}
            <strong>1 - {items.length}</strong> of {items.length}
          </span>
        )}
        <div className={styles.checkbox}>
          <Tumbler checked={idleChecked} onChange={(e) => setIdleChecked(e.target.checked)}>
            Show Idle
            <Badge
              backgroundColor="var(--light-20)"
              textColor="var(--dark-60)"
              className="ml-tw-1.5"
            >
              {idleEvents().length}
            </Badge>
          </Tumbler>
        </div>
        <ExportDropdownButton
          onClickPDF={() =>
            saveCELogAsPDF({
              assetName: legalEntity!.assetName,
              changeOrderEvents: items,
            })
          }
          onClickExcel={() =>
            generateXLSX(legalEntity.code, {
              change_order_event_ids: items.map((ce) => ce.id),
              visibility: 'change-events',
            })
          }
        />

        <Button
          className="ml-tw-4"
          variant="primary"
          onClick={() => {
            navigate('new');
          }}
        >
          Create Change Event
        </Button>
      </div>
      <div className="col-12">
        <div className={styles.fixedFilterByCategory}>
          <QuickFilterByCategory
            items={['COR', 'PCO', 'IRC']}
            value={pageParams.categoryList}
            onChange={setCategoryFilter}
            resetFilter={resetCategoryFilter}
          />
        </div>

        <div className={styles.fixedFilterByReasons}>
          <QuickFilterByReasons
            items={filteredReasons()}
            value={pageParams.reasonList}
            onChange={setReasonFilter}
            resetFilter={resetReasonFilter}
          />
        </div>
        <Table
          classes={{
            container: styles.container,
            table: styles.table,
          }}
          onFilterModelChange={(newFilterModel) => setPageParams(prevState => ({
            ...prevState,
            amount: newFilterModel.value
          }))}
          onRowClick={handleClick}
          loading={isLoading}
          columns={columns}
          items={items || []}
          settings={pageParams}
          setSettings={setPageParams}
        />
      </div>
      <Router primary={false}>
        <ChangeOrderEventWorkflow
          path="new"
          onSubmit={(data) => (
            dispatch(createChangeOrderEvent(legalEntity!.code, data))
              .then(() => navigate('.'))
          )}
        />
        <ChangeOrderEvent
          legalEntityCode={legalEntity!.code}
          containerClassName="reconcile-entity-modal"
          path=":id"
        />
      </Router>
    </div>
  );
};

export default ChangeEventLogs;

import http from 'lib/http';
import { IInvestmentObject } from 'types/IInvestmentObject';
import { stringify } from 'qs';
import {
  IFetchLegalEntitiesResponse,
  IFetchSegmentsResponse,
} from 'types/ReportTableSegment';
import { ISortParamsField } from 'types/Sort';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';

export async function fetchReportTableSegments(data) {
  const res = await http.get(
    `/report_table_segments${stringify(data, { addQueryPrefix: true })}`,
  );
  return res.json();
}

export async function destroyReportTableSegment(id: number) {
  const res = await http.del(`/report_table_segments/${id}`);
  if (res.ok) {
    toastr.success('Segment has been deleted');
  }
}

export async function fetchInvestmentObjects() {
  const res = await http.get('/settings/report/segments/investment_objects');
  return res.json();
}

export async function createReportTableSegment(data: IInvestmentObject) {
  try {
    const res = await http.post('/report_table_segments', data);
    const payload = await res.json();

    if (payload.errors) {
      toastr.error(`${payload.errors}\n`);
      return payload;
    }

    toastr.success('Segment was successfully created');
    return payload;
  } catch {
    toastr.error('Something went wrong');
  }

  return undefined;
}

export async function updateReportTableSegment(
  id: number,
  data: IInvestmentObject,
) {
  try {
    const res = await http.put(`/report_table_segments/${id}`, data);
    const payload = await res.json();

    if (payload.errors) {
      toastr.error(`${payload.errors}\n`);
      return payload;
    }

    toastr.success('Segment was successfully updated');
    return payload;
  } catch {
    toastr.error('Something went wrong');
  }

  return undefined;
}

export interface IFetchReportTableSegmentsParams extends ISortParamsField {
  page: number;
  per_page: number;
  query?: string;
  state?: 'draft' | 'published';
}

export async function plainFetchSegments(
  params: IFetchReportTableSegmentsParams,
) {
  const path = `/settings/report/table_segments${stringify(params, {
    addQueryPrefix: true,
  })}`;
  const res = await http.get(path);
  const data: IFetchSegmentsResponse = await res.json();
  return data;
}

export async function plainDestroyReportTableSegment(id: number) {
  const path = `/settings/report/table_segments/${id}`;
  const res = await http.del(path);
  if (res.ok) {
    window.toastr.success('Segment has been deleted');
    return;
  }
  window.toastr.error('Something went wrong');
}

export interface IMutateTableSegmentPayload {
  legal_entity_ids?: string[];
  title?: string;
  reason?: string;
  published?: boolean;
  directly_permitted_user_ids?: string[];
  directly_permitted_user_role_ids?: string[];
  restrict_report_table_segment_public_permission_attributes?: {
    _destroy?: boolean;
  };
}

export async function plainCreateReportTableSegment(
  payload: IMutateTableSegmentPayload,
) {
  try {
    const res = await http.post('/settings/report/table_segments/', payload);
    const data = await res.json();

    if (data.errors) throw new Error(data.errors);

    window.toastr.success('Segment was successfully created');
    return data as ReportSegment;
  } catch (err) {
    window.toastr.error(err ? `${err}\n` : 'Something went wrong');
    return undefined;
  }
}

export async function plainUpdateReportTableSegment(
  id: number,
  payload: IMutateTableSegmentPayload,
) {
  try {
    const path = `/settings/report/table_segments/${id}`;
    const res = await http.put(path, payload);
    const data = await res.json();

    if (data.errors) throw new Error(data.errors);

    window.toastr.success('Segment was successfully updated');
    return data as ReportSegment;
  } catch (err) {
    window.toastr.error(err ? `${err}\n` : 'Something went wrong');
    return undefined;
  }
}

export async function plainFetchLegalEntities() {
  const path = '/settings/report/table_segments/legal_entities';
  const res = await http.get(path);
  const data: IFetchLegalEntitiesResponse = await res.json();
  return data;
}

import React from 'react';
import LoadingPanel from '../../../Shared/components/LoadingPanel';
import HistoryItem from './HistoryItem';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';

function History() {
  const changeEvent = useAppSelector(selectChangeEvent);

  if (!changeEvent) return <LoadingPanel />;
  return (
    <div>
      <div className="change-event-scroll change-event-scroll_history">
        {changeEvent?.historyEvents?.map((item, idx) => (
          <HistoryItem
            firstItem={idx === changeEvent?.historyEvents.length - 1}
            key={item.id}
            logItem={item}
            currentObjectState={changeEvent}
            id={changeEvent?.id}
          />
        ))}
      </div>
    </div>
  );
}

export default History;

import React from 'react';
import { Button } from 'stories';

interface Props {
  onReject: () => void;
  onApprove: () => void;
}

const Actions = ({ onReject, onApprove }: Props) => (
  <>
    <Button size="l" variant="danger" onClick={onReject} fluid>
      Reject
    </Button>
    <Button size="l" variant="success" onClick={onApprove} fluid>
      Approve
    </Button>
  </>
);

export default Actions;

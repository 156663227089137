import React, { FC } from 'react';
import { IconButton } from 'stories';
import { AgGridReact } from 'ag-grid-react';
import { useModal } from '@/shared/lib/hooks/useModal';

interface Props {
  values?: object;
  onDestroyAction: (values: number) => void;
  gridRef: AgGridReact;
  data: {
    grandTotals?: boolean;
    recalculated?: boolean;
  };
}

const GridRowActions: FC<Props> = ({
  values,
  onDestroyAction,
  gridRef,
  data,
}) => {
  const { confirm } = useModal();

  const onRemove = async () => {
    const result = await confirm({
      title: 'Do you want to delete this item?',
      subtitle: '',
      actions: {
        primaryButton: {
          text: 'Yes',
          variant: 'success',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });
    if (!result) return;
    // todo TYPING
    const rowData = await onDestroyAction(values);
    if (rowData) gridRef.current.api!.applyTransaction({ remove: [data] });
  };
  return (
    <div className="flex justify-center items-center h-full">
      {!data.grandTotals && <IconButton iconName="trash" onClick={onRemove} />}
    </div>
  );
};

export default GridRowActions;

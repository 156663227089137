import { Field, InlineAlert, Tumbler } from '@/stories';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { COMPARE_TO_COLOR_RULE_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import * as yup from 'yup';

export const COMPARE_TO_SCHEMA = yup.object().shape({
  rule: yup.string().required(),
  compareTo: yup.string().required(),
});

export type CompareToForm = yup.InferType<typeof COMPARE_TO_SCHEMA>;

export function CompareToField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  columnsConfig,
  disabled,
  disabledNote,
}: {
  name: TName;
  control: Control<TFieldValues>;
  columnsConfig: { key: string; label: string }[];
  disabled?: boolean;
  disabledNote?: string;
}) {
  const { setValue, watch } = useFormContext<{
    [T in TName]: CompareToForm;
  }>();
  const getFieldName = (fieldName: keyof CompareToForm) =>
    `${name}.${fieldName}` as FieldPath<TFieldValues>;

  const key = watch('key');
  const comparison = watch(name);

  const options = columnsConfig
    .filter((c) => c.key.toString() !== key?.toString())
    .map((c) => ({
      label: c.label,
      value: c.key,
    }));
  const hasNoColumns = columnsConfig.length === 0 || options.length === 0;
  return (
    <SectionFieldsContainer>
      <SectionField
        button={
          <Tumbler
            disabled={disabled || hasNoColumns}
            onChange={(e) => {
              if (e.target.checked) {
                setValue(
                  name,
                  {
                    rule: null,
                    compareTo: null,
                  } as unknown as CompareToForm,
                  {
                    shouldValidate: true,
                  },
                );
              } else {
                setValue(name, null, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }
            }}
            checked={comparison != null}
          />
        }
        labelText="Comparison"
        note={'Here you can set the comparison rules for the column'}
      >
        {hasNoColumns && (
          <InlineAlert
            status="secondary"
            message="No columns available for comparison"
          />
        )}
        {disabled && disabledNote && (
          <InlineAlert status="secondary" message={disabledNote} />
        )}
        {comparison != null && (
          <Field
            labelText="Compare with"
            note="Which column you would like to compare the result to?"
          >
            <Controller
              control={control}
              name={getFieldName('compareTo')}
              render={({ field: compareToField }) => (
                <SharedSelect
                  placeholder="Select column"
                  menuPortalTarget={document.body}
                  value={options.find(
                    (o) => o.value.toString() === compareToField.value,
                  )}
                  onChange={(e) => compareToField.onChange(e.value)}
                  options={options}
                />
              )}
            />
          </Field>
        )}
      </SectionField>
      {comparison != null && (
        <SectionField labelText="Color Rule">
          <RadioGroupController
            control={control}
            name={getFieldName('rule')}
            options={COMPARE_TO_COLOR_RULE_OPTIONS}
          />
        </SectionField>
      )}
    </SectionFieldsContainer>
  );
}

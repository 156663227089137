import React from 'react';
import {
  IGetDevelopmentCategoryParams,
  useGetDevelopmentCategoryQuery,
} from 'bundles/Construction/api/core';
import TreeSelect from 'bundles/Shared/components/GroupForm/FormItems/TreeSelect';
import { transformCategoriesForTree } from 'bundles/DrawPackage/InvoicesFlow/Invoices/utils';
import { treeJCCToOption } from 'bundles/Shared/helpers/job_cost_code';

interface Props
  extends Omit<
    React.ComponentProps<typeof TreeSelect>,
    'options' | 'onChange'
  > {
  queryParams: IGetDevelopmentCategoryParams;
  onChange: (key: string) => void;
}

function JccTreeSelect({ queryParams, ...props }: Props) {
  const { data: JCCTree } = useGetDevelopmentCategoryQuery({
    legalEntityCode: queryParams.legalEntityCode,
    without_sovcs: queryParams.without_sovcs ?? 'true',
  });

  return (
    <TreeSelect
      {...props}
      inputProps={{
        size: 'm',
        placeholder: 'Select...',
        ...props.inputProps,
      }}
      options={
        JCCTree
          ? transformCategoriesForTree({
              categories: treeJCCToOption(JCCTree),
            })
          : []
      }
    />
  );
}

export default JccTreeSelect;

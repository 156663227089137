/* eslint-disable react/no-unstable-nested-components */
import pluralize from 'pluralize';
import { Button, Modal, ModalHeaderWithIcon, SearchInput } from 'stories';
import { ReplyRecipient } from 'bundles/Settings/components/EmailSettings/editor/types';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import React, { useMemo, useState } from 'react';
import { uniqBy } from 'lodash-es';
import { useLocalTableSorting } from '@/shared/lib/hooks/useLocalTableSorting';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import styles from './RecipientsModal.module.scss';
import { IColumn, ISortSettings } from '../Table/types';
import Table from '../Table/Table';
import QuickFilterCheckList, {
  getDefaultMappingToOption,
} from '../Table/filters/QuickFilterCheckList';

interface Props extends DialogProps<ReplyRecipient[]> {
  replyRecipientOptions: ReplyRecipient[];
  initSelectedRecipients: ReplyRecipient[];
}

function RecipientsModal({
  replyRecipientOptions,
  initSelectedRecipients,
  onClose,
  onSubmit,
}: Props) {
  const [selectedItems, setSelectedItems] = useState(initSelectedRecipients);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterModel, setFilterModel] = useState<{
    role?: ReplyRecipient['role'][];
  }>({});
  const selectedRoles = filterModel.role ?? [];
  const [sortSettings, setSortSettings] = useState<
    ISortSettings<'replyRecipient'>
  >({});

  const sortedItems = useLocalTableSorting(
    replyRecipientOptions,
    sortSettings,
    {
      replyRecipient: (recipient) => recipient.fullName,
    },
  );

  const filteredItems = useMemo(
    () =>
      sortedItems.filter(
        (recipient) =>
          (selectedRoles.length === 0 ||
            selectedRoles.map((role) => role.id).includes(recipient.role.id)) &&
          (includesInLowerCase(recipient.fullName, searchQuery) ||
            includesInLowerCase(recipient.role.name, searchQuery)),
      ),
    [sortedItems, selectedRoles, searchQuery],
  );

  const columns = useMemo<IColumn<ReplyRecipient>[]>(() => {
    const roleOptions = uniqBy(
      replyRecipientOptions.map((recipient) => recipient.role),
      (role) => role.id,
    );

    return [
      {
        text: 'Name',
        dataField: 'replyRecipient',
        headerStyle: {
          width: '50%',
        },
        formatter: ({ row: item }) => (
          <span className="inline-regular text-dark-60">{item.fullName}</span>
        ),
        sortable: true,
      },
      {
        text: 'Role',
        dataField: 'role',
        headerStyle: {
          width: '50%',
        },
        formatter: ({ row: item }) => (
          <span className="inline-regular text-dark-60">{item.role.name}</span>
        ),
        filterComponent: QuickFilterCheckList,
        filterComponentParams: {
          ...getDefaultMappingToOption<ReplyRecipient['role']>(
            (role) => role.name,
          ),
          items: roleOptions,
        },
      },
    ];
  }, [replyRecipientOptions]);

  const handleSubmit = () => {
    onSubmit?.(selectedItems);
  };

  return (
    <Modal
      toggle={() => onClose()}
      size="900"
      bodyHeight={600}
      header={<ModalHeaderWithIcon title="Set Recipients for replies" />}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={selectedItems.length === 0}
          >
            Set Recipients
          </Button>
        </>
      }
    >
      <div className="secondary-semibold text-light-60">
        {replyRecipientOptions.length} Internal{' '}
        {pluralize('Members', replyRecipientOptions.length)}
      </div>
      <SearchInput
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search"
        value={searchQuery}
        resetValue={() => setSearchQuery('')}
        size="s"
        className="mb-tw-4 mt-tw-2"
      />
      <Table
        classes={{
          table: styles.table,
        }}
        items={filteredItems}
        settings={sortSettings}
        setSettings={setSortSettings}
        selectedRows={selectedItems}
        setSelectedRows={setSelectedItems}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        columns={columns}
      />
    </Modal>
  );
}

export default RecipientsModal;

import { Modal } from '@/stories/Modals/Modal/Modal';
import { DASHBOARD_ENTITY_NAME } from 'bundles/Shared/entities/dashboard';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { Button, Field, Input } from 'stories';
import { FieldsContainer } from 'stories/Field/Field';

type ReportDashboard = {
  name: string;
};

type Props = DialogProps<ReportDashboard> & {
  type: 'create' | 'edit';
  dashboard?: ReportDashboard;
  submitText?: string;
};

export function BasicDashboardModal({
  onClose,
  onSubmit,
  dashboard,
  type,
  submitText,
}: Props) {
  const {
    register,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useForm<ReportDashboard>({
    mode: 'all',
    defaultValues: {
      ...dashboard,
    },
  });

  const onSubmitHandler = handleSubmit((data) => {
    onSubmit?.(data);
  });

  return (
    <Modal
      toggle={onClose}
      header={`${capitalize(type)} ${DASHBOARD_ENTITY_NAME}`}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={!isValid || (type === 'edit' && !isDirty)}
            onClick={onSubmitHandler}
          >
            {submitText ?? type === 'edit' ? 'Save Updates' : 'Create'}
          </Button>
        </>
      }
    >
      <FieldsContainer>
        <Field labelText="Title" required>
          <Input
            {...register('name', {
              required: true,
            })}
          />
        </Field>
      </FieldsContainer>
    </Modal>
  );
}

import * as React from 'react';
import { useMemo, useState } from 'react';

import GroupedCheckboxes from 'bundles/Shared/components/groupedSelection/GroupedCheckboxes';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import useSelected from '@/shared/lib/hooks/useSelected';
import { exists } from 'lib/typeHelpers';
import { range, xor } from 'lodash-es';
import pluralize from 'pluralize';
import { Button, Field, Modal, ModalActions, Tumbler } from 'stories';
import Select, { ISelectOption } from 'stories/FormControls/Select/Select';
import { BudgetYear, BudgetYearMetaLegalEntity } from '../operational/types';
import { dateToYear } from '@/shared/lib/formatting/dates';

interface Props
  extends DialogProps<{
    year: BudgetYear['year'];
    legalEntities: BudgetYearMetaLegalEntity[];
  }> {
  legalEntities: BudgetYearMetaLegalEntity[];
  years: BudgetYear['year'][];
  selectProps?: Partial<React.ComponentProps<typeof Select>>;
}

function CreateBudgetsForFutureYearsModal({
  onClose,
  onSubmit,
  legalEntities,
  years,
  selectProps,
}: Props) {
  const [selectedYearOption, setSelectedYearOption] =
    useState<ISelectOption<BudgetYear['year']>>();
  const {
    selectedItems: selectedLEs,
    selectAll,
    deselectAll,
    setSelectedItems,
    allSelected,
  } = useSelected({
    items: legalEntities,
    initialSelected: legalEntities,
  });

  const yearsOptions = useMemo<ISelectOption<number>[]>(() => {
    if (years.length === 0) {
      const nowYear = new Date();
      return [
        {
          id: nowYear,
          label: dateToYear(nowYear).toString(),
        },
      ];
    }
    const maxYear = Math.max(...years);
    const minYear = Math.min(...years);
    const rangeOfYear = range(minYear, maxYear + 2);
    const xorOfYears = xor(rangeOfYear, years);
    const options: ISelectOption<number>[] = xorOfYears.map((y) => ({
      id: y,
      label: y.toString(),
    }));

    return options;
  }, []);

  const canSubmit = () => selectedLEs.length > 0 && selectedYearOption != null;

  const handleSubmit = () => {
    if (!exists(selectedYearOption)) return;

    onSubmit?.({
      year: selectedYearOption.id,
      legalEntities: selectedLEs,
    });
  };

  return (
    <Modal
      header="Create Budgets"
      toggle={onClose}
      size="900"
      classes={{
        body: 'h-[60vh] grid',
      }}
      actions={
        <ModalActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={!canSubmit()}
            onClick={handleSubmit}
            variant="success"
          >
            Create Budgets for {!allSelected && selectedLEs.length}{' '}
            {allSelected && 'All'} Legal{' '}
            {pluralize('Entities', selectedLEs.length)}
          </Button>
        </ModalActions>
      }
    >
      <div className="-m-tw-6 grid grid-cols-[320px_1fr]">
        <div className="sticky -top-tw-2 flex h-max flex-col gap-tw-4 px-tw-6">
          <Field labelText="Select year" required className="flex flex-col">
            <Select
              classes={{
                input: 'w-full',
              }}
              placeholder={'Search/Create Year'}
              selected={selectedYearOption}
              onSelectedChange={(selected) => setSelectedYearOption(selected)}
              options={yearsOptions}
              {...selectProps}
            />
          </Field>
          <Field
            labelText="Select Legal Entities"
            required
            note="Which Legal Entities do you want to include for budgeting for selected year?"
          >
            <Tumbler
              onChange={() => (allSelected ? deselectAll() : selectAll())}
              checked={allSelected}
            >
              All Legal Entites
            </Tumbler>
          </Field>
        </div>
        <div className="border-left w-full bg-neutral-050 p-tw-6">
          <GroupedCheckboxes
            withSearch
            searchProps={{
              size: 'm',
            }}
            items={legalEntities}
            getLabelFromItem={(le) => le.name}
            selectedItems={selectedLEs}
            setSelectedItems={setSelectedItems}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CreateBudgetsForFutureYearsModal;

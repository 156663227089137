import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import React from 'react';
import Popover, { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';

interface Props {
  row: InvestmentIndex;
}

const MAX_DISPLAYED_USERS = 3;

function UsersColumn({ row }: Props) {
  const { users } = row.investmentEntity;
  return (
    <div className="dark-60 secondary-regular">
      {users.length > 0 && (
        <>
          {users.slice(0, MAX_DISPLAYED_USERS).map((user, i, arr) => (
            <React.Fragment key={user.id}>
              {user.fullName}
              {i !== arr.length - 1 && <span className="light-60">, </span>}
            </React.Fragment>
          ))}
          {users.length > MAX_DISPLAYED_USERS && (
            <>
              <span className="light-60"> and </span>
              <Popover
                offset={DEFAULT_DROPDOWN_OFFSET}
                placement="bottom-start"
                hiddenArrow
                template={
                  <span className="dark-60 secondary-regular">
                    {users
                      .slice(MAX_DISPLAYED_USERS, users.length)
                      .map((u) => u.fullName)
                      .join(', ')}
                  </span>
                }
              >
                <span className="blue">
                  {users.length - MAX_DISPLAYED_USERS} more
                </span>
              </Popover>
            </>
          )}
        </>
      )}
      {users.length === 0 && 'No Users'}
    </div>
  );
}

export default UsersColumn;

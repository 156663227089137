import React from 'react';
import { SnapshotItem } from './SnapshotItem';
import { formatAmount, percentNumberFormat } from '@/shared/lib/formatting/number';
import { ISnapshots } from '..//types';

interface Props {
  snapshots: ISnapshots;
}

function Snapshots({ snapshots }: Props) {
  return (
    <div className="grid gap-m md:grid-cols-2">
      <SnapshotItem
        mainValue={formatAmount(snapshots.contributed.total)}
        type="contributions"
        secondary={[
          {
            value: snapshots.contributed.activeInvestmentsCount,
            name: 'Active Investments',
          },
        ]}
      />
      <SnapshotItem
        mainValue={formatAmount(snapshots.distributed.total)}
        type="distributions"
        secondary={[
          {
            value: snapshots.distributed.totalDistributionsCount,
            name: 'Total Distributions',
          },
          {
            value: snapshots.distributed.cashOnCashPercent,
            name: 'Cash Yield',
            formatter: percentNumberFormat,
          },
        ]}
      />
    </div>
  );
}

export default Snapshots;

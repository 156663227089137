import { PERIOD_SHIFT_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { HistoricalReviewTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { transformPeriodShiftDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common';
import * as yup from 'yup';

export const HISTORICAL_TABLE_WIDGET_CONFIG_SCHEMA = yup.object().shape({
  period_shift: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
});

export type HistoricalTableWidgetConfigForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_SCHEMA
>;

export const transformHistoricalTableWidgetConfigToForm = (
  config: HistoricalReviewTableWidgetConfigDto,
): HistoricalTableWidgetConfigForm => {
  return {
    period_shift: {
      ...transformPeriodShiftDtoToForm(
        config.default_options?.date?.period_shift,
      ),
    },
  };
};

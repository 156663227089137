import {
  GetApiSettingsSreColorsApiResponse,
  PostApiSettingsSreColorsBulkApiArg,
} from '@/entities/sreColors/model/sreColorsGeneratedApi';
import { Color } from '@/shared/widgets/colorPicker/model';
import { uniqBy } from 'lodash-es';

const MIN_COLORS_IN_ROW = 10;

export const getColorBoxesCount = (n: number, s = MIN_COLORS_IN_ROW): number =>
  n / s <= 1 ? s : getColorBoxesCount(n, s + MIN_COLORS_IN_ROW);

export const GRADIENT_SATURATION_TAB_SWITCHER_BG_CLASSNAME =
  'bg-[radial-gradient(50%_50%_at_50%_50%,_#FFF_0%,_rgba(255,_255,_255,_0.00)_100%),_conic-gradient(from_180deg_at_50%_50%,_#EA3824_0deg,_#FEFE55_60.00000178813934deg,_#79FA4E_120.00000357627869deg,_#76F8FD_180deg,_#0D1DF5_240.00000715255737deg,_#E63BF7_299.9999928474426deg,_#E9363C_360deg)]';

export const sreColorsTransformer = {
  dtoToColorOptions(dto: GetApiSettingsSreColorsApiResponse): Color[] {
    return dto.map((c, idx) => ({
      ...c,
      entityId: c.id,
      id: String(idx),
    }));
  },

  getColorOptionsToDtoTransformer(
    initialColorOptions: Color[],
  ): (
    updatedColorOptionss: Color[],
  ) => PostApiSettingsSreColorsBulkApiArg['body']['sre_colors'] {
    return (updatedColors: Color[]) => {
      return uniqBy(
        [
          ...updatedColors.map((c) => ({
            ...c,
            value: c.value!,
            uniqId: c.entityId ?? c.id,
          })),
          ...initialColorOptions.map((c) => ({
            ...c,
            value: c.value!,
            uniqId: c.entityId,
            _destroy: 'true',
          })),
        ],
        'uniqId',
      ).map((c) => ({ ...c, id: c.entityId }));
    };
  },
};

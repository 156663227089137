import { useElementSizeContext } from '@/shared/lib/hooks/useElementSize';

export const MEDIUM_BREAKPOINT = 372;

export const useProjectCardResponsive = () => {
  const { width } = useElementSizeContext();
  const mediumWidth = width > 0 && width <= MEDIUM_BREAKPOINT;
  return {
    mediumWidth,
  };
};

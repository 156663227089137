import React from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';

const ViewMediaModal = ({
  opened, onClose, currentIndex, views,
}) => (
  <ModalGateway>
    {opened && (
      <Modal onClose={onClose} allowFullscreen={false}>
        <Carousel
          currentIndex={currentIndex}
          views={views}
        />
      </Modal>
    )}
  </ModalGateway>
);

export default ViewMediaModal;

ViewMediaModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  views: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

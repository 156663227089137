import { Distribution } from '@/entities/return';
import { useMemo } from 'react';
import { IRowsEntity } from '../types';
import { convertCentsToDollars } from '@/shared/lib/converters';

export function useCapitalDetailRows(initialRows: IRowsEntity[]) {
  const rows = useMemo(() => {
    return initialRows.map((row) => {
      const groupedDistributionsMap = new Map<
        Distribution['kind'],
        { items: Distribution[]; totalAmount: number }
      >();

      row.distributions.forEach((distr) => {
        if (distr.kind === 'split_items') {
          distr.splitItems.forEach((splitDistr) => {
            const splitDistrWithDate = {
              ...splitDistr,
              amount: convertCentsToDollars(splitDistr.amountCents),
              date: distr.date,
            } as unknown as Distribution;

            let items = [splitDistrWithDate];
            let totalAmount = splitDistrWithDate.amount;

            if (groupedDistributionsMap.has(splitDistr.kind)) {
              const oldSplitDistr = groupedDistributionsMap.get(
                splitDistrWithDate.kind,
              )!;

              items = [...oldSplitDistr.items, splitDistrWithDate];
              totalAmount =
                oldSplitDistr.totalAmount + splitDistrWithDate.amount;
            }

            groupedDistributionsMap.set(splitDistr.kind, {
              items,
              totalAmount,
            });
          });
          return;
        }

        let items = [distr];
        let totalAmount = distr.amount;

        if (groupedDistributionsMap.has(distr.kind)) {
          const oldSplitDistr = groupedDistributionsMap.get(distr.kind)!;
          items = [...oldSplitDistr.items, distr];
          totalAmount = oldSplitDistr.totalAmount + distr.amount;
        }

        groupedDistributionsMap.set(distr.kind, {
          items,
          totalAmount,
        });
      });

      return {
        ...row,
        capitalBalance: convertCentsToDollars(row.capitalBalanceCents),
        groupedDistributions: Object.fromEntries(
          groupedDistributionsMap.entries(),
        ),
      };
    });
  }, [initialRows]);

  return rows;
}

import { Button, IconButton } from '@/stories';
import { ComponentProps } from 'react';

export const ImportButton = (
  props: Omit<
    ComponentProps<typeof Button>,
    'variant' | 'iconName' | 'iconPosition'
  >,
) => {
  return (
    <Button
      iconName="importUpload"
      variant="secondary"
      size="s"
      children="Import"
      {...props}
    />
  );
};

export const ImportIconButton = (
  props: Omit<ComponentProps<typeof Button>, 'variant' | 'iconName'>,
) => {
  return (
    <IconButton
      iconName="importUpload"
      variant="secondary"
      size="m"
      {...props}
    />
  );
};

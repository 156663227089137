import React from 'react';
import {
  CrudPagination,
  CrudSearch,
  CrudCreateButton,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { useAppSelector } from '@/shared/lib/hooks/redux';

export function CrudTablePanel({
  paginationProps,
}: {
  paginationProps?: React.ComponentProps<typeof CrudPagination>;
}) {
  const selectedChangeOrderConfig = useAppSelector(
    (state) => state.developmentLegalEntity.selectedChangeOrderConfig,
  );

  return (
    <div className="flex justify-between items-center mb-l">
      <div>
        <CrudPagination {...paginationProps} />
      </div>
      <div className="flex items-center gap-s">
        <CrudSearch />
        {!selectedChangeOrderConfig?.default && <CrudCreateButton />}
      </div>
    </div>
  );
}

export default CrudTablePanel;

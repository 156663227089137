import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { SmallCell } from 'bundles/Shared/components/PDFLibrary/ui/table/SmallCell';
import {
  DEFAULT_VAR_PDF,
  uiPDFStyles,
} from 'bundles/Shared/components/PDFLibrary/ui/uiPDFStyles';
import { formatAmount } from '@/shared/lib/formatting/number';
import { IDrawPreviewRequisitionItem } from '../../../DrawPageSummary';

interface IBodyTableRLProps {
  data: {
    requisitionsList: IDrawPreviewRequisitionItem[];
  };
}

const BodyTableRL: FC<IBodyTableRLProps> = ({ data }) => {
  const styles = StyleSheet.create({
    body: {
      flexDirection: 'row',
      width: '100%',
    },
    valueCol: {
      flex: 1,
      textAlign: 'right',
      alignItems: 'flex-end',
      borderLeftWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      paddingHorizontal: 2,
      paddingTop: 2,
    },
    valueColCenter: {
      flex: 1,
      alignItems: 'center',
      borderLeftWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      paddingHorizontal: 2,
      paddingTop: 2,
    },
    bigValueCol: {
      width: '14%',
      borderLeftWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      padding: 2,
    },
  });

  if (!data) return <View />;

  return (
    <View style={styles.body}>
      <View
        style={{
          width: '100%',
        }}
      >
        {data.requisitionsList?.map((reqItem, idx) => {
          return (
            <View
              key={`req${idx}`}
              style={{
                width: '100%',
                flexDirection: 'row',
                borderRightWidth: 0.5,
                borderColor: DEFAULT_VAR_PDF.borderDarkColor,
                borderStyle: 'solid',
              }}
            >
              <SmallCell
                styles={{
                  wrapper: styles.valueColCenter,
                }}
              >
                {reqItem.requisition?.number}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.valueColCenter,
                }}
              >
                {reqItem.requisition.date}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.bigValueCol,
                }}
              >
                {reqItem.lineItemName}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.bigValueCol,
                }}
              >
                {reqItem.budgetSubcategory}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.bigValueCol,
                }}
              >
                {reqItem.description}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.valueCol,
                }}
              >
                {formatAmount(reqItem.amount)}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.valueColCenter,
                }}
              >
                {reqItem.invoice.number}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.valueColCenter,
                }}
              >
                {reqItem.invoice.date}
              </SmallCell>
              <SmallCell
                styles={{
                  wrapper: styles.bigValueCol,
                }}
              >
                {reqItem.invoice.vendor}
              </SmallCell>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default BodyTableRL;

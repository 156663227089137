import React, { ComponentProps } from 'react';
import { Popover } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './CommentFlyFormPopover.module.scss';

type Props = ComponentProps<typeof Popover>;

function CommentFlyFormPopover(
  { children, visible, onClickOutside, ...props }: Props,
  ref,
) {
  const handleClickOutside: Props['onClickOutside'] = (_a, e) => {
    const whiteListedClasses = ['ck-button', 'ck-button__label'];
    const classList = [...e.target?.classList];

    // NOTE: ck dropdown renders outside of component so we have to
    // ignore outside click if we click on ck buttons

    if (!whiteListedClasses.some((cn) => classList?.includes(cn))) {
      onClickOutside?.(_a, e);
    }
  };

  return (
    <Popover
      trigger="click"
      ref={ref}
      visible={visible}
      onHidden={onClickOutside}
      onClickOutside={handleClickOutside}
      className={cn(
        styles.popover,
        props.theme === 'light' && styles.popover_light,
        'shadow-m-light',
      )}
      maxWidth="20rem"
      hiddenArrow
      {...props}
    />
  );
}

export default React.forwardRef(CommentFlyFormPopover);

import { LegalEntityHeader } from '@/bundles/Settings/components/REport/GeneralLedgers/Table/ColumnHeaderComponents/LegalEntityHeader';
import { GENERAL_LEDGERS_COLUMN_IDS } from '@/bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import { ListOption } from 'stories/Checkbox/CheckList';
import { ILegalEntity } from '@/types/LegalEntity';
import { ColDef } from 'ag-grid-community';
import { ComponentProps } from 'react';

export function getLegalEntityColumn(params: {
  allLEOptions: ListOption<ILegalEntity['id']>[];
  setLegalEntityIds: (legalEntityIds: ILegalEntity['id'][]) => void;
  selectedLEOptions: ListOption<ILegalEntity['id']>[];
}): ColDef {
  return {
    flex: 1,
    sortable: true,
    colId: GENERAL_LEDGERS_COLUMN_IDS.legalEntityName,
    headerName: 'Legal Entity',
    field: 'legalEntity.name',
    headerComponent: params?.allLEOptions.length ? LegalEntityHeader : null,
    headerComponentParams: params satisfies Pick<
      ComponentProps<typeof LegalEntityHeader>,
      'allLEOptions' | 'selectedLEOptions' | 'setLegalEntityIds'
    >,
  };
}

import { IProduct } from 'types/User';
import { differenceWith, isEqual } from 'lodash-es';
import { camelKeysToSnake, sortByName } from '../utils';

export const genChangedProducts = (
  updatedProducts: IProduct[],
  initialProducts: IProduct[],
) => {
  const sortedInitialProducts = sortByName(initialProducts);
  const sortedUpdatedProducts = sortByName(updatedProducts);
  if (isEqual(sortedUpdatedProducts, sortedInitialProducts)) {
    return [];
  }
  const createEntriesFromObj = (obj: object | undefined) => Object.entries(obj ?? {});

  const initialTouchedProducts = differenceWith(
    initialProducts,
    updatedProducts,
    isEqual,
  ) as IProduct[];

  const newProducts = initialTouchedProducts.map((initialProduct) => {
    const updatedProductItem = updatedProducts.find(
      (pr) => pr.id === initialProduct.id,
    );

    const initialProductEntries = createEntriesFromObj(initialProduct);
    const updatedProductItemEntries = createEntriesFromObj(updatedProductItem);

    const updatedPermissionsEntries = differenceWith(
      updatedProductItemEntries,
      initialProductEntries,
      isEqual,
    );

    const updatedPermissions = Object.fromEntries(updatedPermissionsEntries);

    return {
      id: initialProduct.id,
      ...updatedPermissions,
    };
  });
  const snakeCasedProducts = newProducts.map((pr) => camelKeysToSnake(pr));
  return snakeCasedProducts;
};

import React, { useState } from 'react';
import ScreenWithListNavigationLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { ThinTabGroup } from 'stories';
import {
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { IGetProjectsRequest } from 'bundles/REconcile/components/development/api/projects';
import { useNavigate } from '@reach/router';
import {
  TRouteParams,
  useNavigation,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import ReconcileDevelopmentDashboardProject from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentDashboardProject';
import ReconcileDevelopmentDashboardCardList from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentDashboardCardList';
import SecondaryDropdown from 'bundles/Shared/components/SecondaryDropdown';
import IconButton from 'stories/IconButton/IconButton';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';

const TABS = [
  {
    label: 'Projects',
    id: 'projects',
  },
  {
    label: 'Budgets',
    id: 'budgets',
    disabled: true,
    tooltipProps: {
      mainText: 'Not Implemented',
    },
  },
] as const satisfies readonly IThinTabItem[];

function ReconcileDevelopmentDashboard() {
  const navigate = useNavigate();
  const { getUrl } = useNavigation();
  const { projectId } = useQueryParams<TRouteParams['RECONCILE_DEVELOPMENT']>();
  const [pageParams, setPageParams] = useState<IGetProjectsRequest>({});

  return (
    <ScreenWithListNavigationLayout>
      <ScreenWithListNavigationLayout.Navigation>
        <ScreenWithListNavigationLayout.NavigationHeaderGroup>
          <ScreenWithListNavigationLayout.NavigationHeader
            subtitle="REconcile"
            title="Development"
          />
          <ThinTabGroup
            classes={{
              itemContainer: 'flex-grow',
              item: 'w-full justify-center',
            }}
            selectedItem={TABS[0]}
            items={TABS}
          />
        </ScreenWithListNavigationLayout.NavigationHeaderGroup>
        <PageParamsProvider
          pageParams={pageParams}
          setPageParams={setPageParams}
        >
          <div className="flex items-center justify-end gap-tw-2">
            <PageParamsSearch />
            <SecondaryDropdown
              className="sre-dropdown-v2"
              renderButton={() => <IconButton size="l" iconName="more" />}
              items={[
                {
                  id: `${getUrl('RECONCILE_DEVELOPMENT')}#new`,
                  label: 'Create New',
                  iconName: 'addSmall',
                  disabled: true,
                },
                {
                  id: getUrl('RECONCILE_DEVELOPMENT_SETTINGS', {
                    tab: 'contacts',
                  }),
                  label: 'Settings',
                  iconName: 'settings',
                },
              ]}
              onSelectedChange={(id) => {
                navigate(id);
              }}
            />
          </div>
          <ReconcileDevelopmentDashboardCardList />
        </PageParamsProvider>
      </ScreenWithListNavigationLayout.Navigation>
      <ScreenWithListNavigationLayout.Content>
        {projectId && <ReconcileDevelopmentDashboardProject />}
      </ScreenWithListNavigationLayout.Content>
    </ScreenWithListNavigationLayout>
  );
}

export default ReconcileDevelopmentDashboard;

import { useMatch } from '@reach/router';
import { allSubPathMatches } from '@/shared/lib/hooks/useNavigation';
import { ILegalEntitiable } from 'types/LegalEntitiable';

const URL_DELIMETER = '/';

export function useGenerateUrlForCard<T extends string>(path: T) {
  const match = useMatch(allSubPathMatches(path));

  const generateUrlForCard = (
    slug: ILegalEntitiable['slug'],
    slugPathId: ':assetSlug' | ':fundSlug',
  ): string => {
    const relativeUrl = match?.['*'] ?? '';
    const urlParts = relativeUrl
      .split(URL_DELIMETER)
      .slice(0, 2)
      .join(URL_DELIMETER);
    const url = (match?.path ?? '')
      .replace(slugPathId, slug)
      .replace('*', urlParts);

    if (url.endsWith(URL_DELIMETER)) return url.slice(0, -1);

    return url;
  };

  return generateUrlForCard;
}

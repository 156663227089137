import { useContextChangeHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { selectWidgetById } from '@/entities/report/reportBuilder';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { WidgetStateDate } from './WidgetStateDate';

export const WidgetContextDateConfigCard = (props: WidgetConfigCardProps) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange = useContextChangeHandler({
    widgetId: widget?.id ?? '',
    widgetGroupId: widget?.group.id ?? '',
  });

  return (
    <BaseWidgetConfigCard
      labelSlot={
        widget && (
          <WidgetStateDate
            closeOnDateUpdate
            state={widget.context}
            onStateChange={handleContextChange}
          />
        )
      }
      hasData={widgetHasData(props.data)}
      {...props}
    />
  );
};

/* eslint-disable new-cap */
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import React, { Fragment } from 'react';
import { IconButton } from 'stories';

const DraggableContainer = SortableContainer(({ children, className }) => (
  <tbody className={className}>{children}</tbody>
));

export const DraggableItem = SortableElement(({ children }) => (
  <Fragment>{children}</Fragment>
));

export const DraggableHeaderColumn = () => <th />;

export const DraggableColumn = SortableHandle(() => (
  <td className="w-[40px]">
    <IconButton variant="secondary" iconName="move" />
  </td>
));

export default DraggableContainer;

import { useMemo } from 'react';
import { TReconcileBudgetSettingsSoVC } from 'bundles/REconcile/actions/sovcConfig';
import {
  DEFAULT_CODE_COLUMN,
  DEFAULT_NAME_COLUMN,
  getJccColumn,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';

export const useSovcTableColumns = (legalEntityCode: string) => {
  const { data: jobCostCodes, isLoading } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode,
    withoutSovc: 'true',
  });

  return useMemo<IColumn<TReconcileBudgetSettingsSoVC>[]>(
    () => [
      DEFAULT_NAME_COLUMN,
      DEFAULT_CODE_COLUMN,
      getJccColumn({
        jobCostCodes: isLoading ? [] : jobCostCodes,
      }),
    ],
    [jobCostCodes],
  );
};

import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';

export const getWorkCost = (optionsReasons, optionsCostCode) => {
  const formItems = [
    {
      fieldName: 'changeOrderReason',
      type: FieldType.SELECT,
      title: 'Reasons',
      placeholder: 'Add reason',
      required: true,
      options: optionsReasons,
      isGroupSelect: true,
      styles: {
        wrapper: 'w-50 pr-s',
      },
    },
    {
      fieldName: 'scheduleOfValueCode',
      fieldNameSnap: 'reasons',
      type: FieldType.SELECT,
      title: 'Cost Code',
      titleView: 'Reasons',
      placeholder: 'Select Cost Code',
      required: true,
      options: optionsCostCode,
      styles: {
        wrapper: 'w-50 pl-s',
      },
    },
    {
      fieldName: 'amount',
      fieldNameSnap: 'value',
      type: FieldType.INPUT_NUMBER,
      title: 'Amount',
      leftIcon: 'paid',
      placeholder: '0',
      required: true,
      styles: {
        wrapper: 'w-25 form-item_white',
        size: 'm',
      },
      thousandSeparator: true,
    },
    {
      fieldName: 'comment',
      fieldNameSnap: 'comment',
      type: FieldType.INPUT_TEXTAREA,
      title: 'Detailed Description',
      placeholder: 'Enter detailed description or other relevant data',
      styles: {
        wrapper: 'w-full form-item_white',
      },
    },
  ];
  return formItems;
};

export const getMarkupModal = (optionsMarkups) => {
  const formItems = [
    {
      fieldName: 'changeOrderMarkup',
      fieldNameSnap: 'reasons',
      type: FieldType.SELECT,
      title: 'Cost Code',
      placeholder: 'Select Cost Code',
      required: true,
      options: optionsMarkups,
      styles: {
        wrapper: 'w-50 pr-s',
      },
    },
    {
      fieldName: 'amount',
      fieldNameSnap: 'value',
      type: FieldType.INPUT_NUMBER,
      title: 'Amount',
      leftIcon: 'paid',
      placeholder: '0',
      required: true,
      styles: {
        wrapper: 'w-25 form-item_white',
        size: 'm',
      },
      thousandSeparator: true,
    },
    {
      fieldName: 'comment',
      fieldNameSnap: 'comment',
      type: FieldType.INPUT_TEXTAREA,
      title: 'Comment',
      placeholder: 'Enter detailed description or other relevant data',
      styles: {
        wrapper: 'w-full form-item_white',
      },
    },
  ];
  return formItems;
};

export const getFees = (optionsMarkups) => {
  const formItems = [
    {
      fieldName: 'changeOrderFee',
      fieldNameSnap: 'reasons',
      type: FieldType.SELECT,
      title: 'Cost Code',
      placeholder: 'Select Cost Code',
      required: true,
      options: optionsMarkups,
      styles: {
        wrapper: 'w-50 pr-s',
      },
    },
    {
      fieldName: 'amount',
      fieldNameSnap: 'value',
      type: FieldType.INPUT_NUMBER,
      title: 'Amount',
      leftIcon: 'paid',
      placeholder: '0',
      required: true,
      styles: {
        wrapper: 'w-25 form-item_white',
        size: 'm',
      },
      thousandSeparator: true,
    },
    {
      fieldName: 'comment',
      fieldNameSnap: 'comment',
      type: FieldType.INPUT_TEXTAREA,
      title: 'Comment',
      placeholder: 'Enter detailed description or other relevant data',
      styles: {
        wrapper: 'w-full form-item_white',
      },
    },
  ];
  return formItems;
};

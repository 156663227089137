import React from 'react';

const SreToggle = ({ label, onToggle, checked = false }) => {
  const id = Math.random().toString(20).substr(2, 6);
  return (
    <div className="custom-control custom-switch">
      <input type="checkbox" className="custom-control-input" id={id} onChange={(e) => onToggle(e)} checked={checked} />
      <label className="custom-control-label secondary-regular dark-60 font-weight-500" htmlFor={id}>{label}</label>
    </div>
  );
};

export default SreToggle;

import { PERIOD_TYPE_UNAVAILABLE_FOR_DEFAULT_PERIOD_TYPE } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { mapItemToOption } from '@/shared/lib/listHelpers';
import { SharedSelectController } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { Control, useFormContext } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { CheckListController, ListOption } from 'stories/Checkbox/CheckList';
import { Field } from 'stories/Field/Field';

export function PeriodTypesField<
  TFieldValues extends Record<string, unknown> = Record<string, unknown>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  options,
  defaultPeriodTypeFieldName = 'defaultPeriodType' as TName,
  availablePeriodTypesFieldName = 'availablePeriodTypes' as TName,
}: {
  control: Control<TFieldValues>;
  options: ListOption[];
  availablePeriodTypesFieldName?: TName;
  defaultPeriodTypeFieldName?: TName;
}) {
  const { watch } = useFormContext();
  const availablePeriodTypesField = watch(availablePeriodTypesFieldName);
  const defaultPeriodTypeField = watch(defaultPeriodTypeFieldName);

  const optionValuesUnavailableForDefaultPeriodType = new Set(
    PERIOD_TYPE_UNAVAILABLE_FOR_DEFAULT_PERIOD_TYPE,
  );
  return (
    <>
      <Field labelText="Period" required>
        <CheckListController
          name={availablePeriodTypesFieldName}
          control={control}
          unselectable={mapItemToOption(defaultPeriodTypeField)}
          items={options}
          getItemProps={(option) => ({
            disabled: defaultPeriodTypeField === option.value,
            tooltipProps: {
              arrowPosition: 'start',
              disabled: defaultPeriodTypeField !== option.value,
              titleText: "Can't remove the default period type",
            },
          })}
        />
      </Field>
      <Field labelText="Default Period Type" required>
        <SharedSelectController
          control={control}
          name={defaultPeriodTypeFieldName}
          options={options.filter((o) => {
            if (optionValuesUnavailableForDefaultPeriodType?.has(o.value))
              return false;

            return availablePeriodTypesField.includes(o.value);
          })}
        />
      </Field>
    </>
  );
}

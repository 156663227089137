import { VariableValueType } from '@/bundles/Shared/entities/formula';
import { IconsId } from '@/types/sre-icons';
import { PERIOD_AGGREGATION_STRATEGIES } from 'bundles/Shared/entities/periodAggregationStrategy';
import { EntityTag, NewEntityTag } from 'bundles/Shared/entities/tag';
import * as yup from 'yup';

export const VALUE_TYPE_ICON = {
  currency: undefined,
  metric: undefined,
  number: undefined,
  percentage: 'percent',
  sqft: undefined,
  date: undefined,
} as const satisfies Record<VariableValueType, IconsId | undefined>;

export const MANUAL_VARIABLE_REFERENCE_PREFIX = 'manual';

export const MANUAL_VARIABLE_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  reference: yup
    .string()
    .required()
    .matches(/^[a-z_0-9]+$/g),
  periodAggregationStrategy: yup
    .string()
    .oneOf(Object.values(PERIOD_AGGREGATION_STRATEGIES))
    .required(),
  valueType: yup.string().required(),
  description: yup.string().optional().nullable().default(undefined),
});

export type ReportManualVariableForm = yup.InferType<
  typeof MANUAL_VARIABLE_SCHEMA
> & {
  tags: readonly (EntityTag | NewEntityTag)[];
};

export const DEFAULT_MANUAL_VARIABLE_FORM = {
  name: '',
  reference: '',
  valueType: undefined,
  periodAggregationStrategy: undefined,
  description: '',
  tags: [],
} as const satisfies Partial<ReportManualVariableForm>;

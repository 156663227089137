import React, { useMemo } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import {
  createChangeOrderConfigInReconcileSettings,
  deleteChangeOrderConfigInReconcileSettings,
  IReconcileBudgetsSettingsChangeOrderConfig,
  updateChangeOrderConfigInReconcileSettings,
} from 'bundles/REconcile/actions/changeOrderConfig';
import { nameOf } from 'types/utils';
import { getCrudActionsColumn } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import ChangeOrderConfigModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/changeOrderConfigs/ChangeOrderConfigModal';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  fetchChangeOrderConfigs,
  updateSelectedChangeOrderConfig,
} from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import useEvent from '@/shared/lib/hooks/useEvent';
import { Button, Modal } from 'stories';
import { IColumn } from 'bundles/Shared/components/Table/types';

interface Props extends DialogProps {
  legalEntityId: string;
}

function ChangeOrderConfigsModal({ legalEntityId, onClose }: Props) {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const changeOrderConfigs = useAppSelector(
    (state) => state.developmentLegalEntity.changeOrderConfigs,
  );

  const handleCreate = async () => {
    const res = await openModal(ChangeOrderConfigModal, {
      changeOrderConfig: {
        name: '',
      },
      type: 'create',
    });
    if (res) {
      const config = await createChangeOrderConfigInReconcileSettings(
        { legalEntityId },
        res,
      );
      if (config) {
        await dispatch(fetchChangeOrderConfigs(legalEntityId));
        await dispatch(
          updateSelectedChangeOrderConfig({
            legalEntityId,
            configId: config.id,
          }),
        );
        onClose();
      }
    }
  };
  const handleEdit = useEvent(
    async (row: IReconcileBudgetsSettingsChangeOrderConfig) => {
      const res = await openModal(ChangeOrderConfigModal, {
        changeOrderConfig: {
          name: row.name,
        },
        type: 'edit',
      });
      if (res) {
        await updateChangeOrderConfigInReconcileSettings(
          {
            legalEntityId,
            entityId: row.id,
          },
          {
            name: res.name,
          },
        );
        dispatch(fetchChangeOrderConfigs(legalEntityId));
      }
    },
  );
  const handleRemove = useEvent(
    async (row: IReconcileBudgetsSettingsChangeOrderConfig) => {
      await deleteChangeOrderConfigInReconcileSettings({
        legalEntityId,
        entityId: row.id,
      });
      dispatch(fetchChangeOrderConfigs(legalEntityId));
    },
  );

  const columns = useMemo<
    IColumn<IReconcileBudgetsSettingsChangeOrderConfig>[]
  >(() => {
    const actionsColumn = getCrudActionsColumn({
      onEdit: handleEdit,
      onRemove: handleRemove,
    });
    return [
      {
        text: 'Name',
        dataField: nameOf<IReconcileBudgetsSettingsChangeOrderConfig>('name'),
      },
      {
        ...actionsColumn,
        formatter: ({ row }) =>
          !row.default && actionsColumn.formatter!({ row }),
      },
    ];
  }, []);

  return (
    <Modal toggle={onClose} header="Edit Config List">
      <Button
        variant="primary"
        className="d-block mb-m ml-auto"
        onClick={handleCreate}
      >
        Add Change Order Config
      </Button>
      <Table columns={columns} items={changeOrderConfigs} />
    </Modal>
  );
}

export default ChangeOrderConfigsModal;

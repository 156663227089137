import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import {
  fetchWorkflowReconcileSettings,
  fetchWorkflowThresholds,
} from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import ThresholdsCruds from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/cruds/ThresholdsCruds';
import ChangeEventWorkflowSettings from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/ChangeEventWorkflowSettings';
import ChangeOrderWorkflowSettings from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/ChangeOrderWorkflowSettings';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { ThinTabGroup } from 'stories';

interface Props {
  legalEntityId: string;
}

const TABS: IThinTabItem[] = [
  { id: 'CO', label: 'Change Orders' },
  { id: 'COR', label: 'Change Order Events' },
];

function WorkFlowSettings({ legalEntityId }: Props) {
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchWorkflowReconcileSettings({
        legalEntityId,
      }),
    );
    dispatch(
      fetchWorkflowThresholds({
        legalEntityId,
      }),
    );
  }, []);

  return (
    <div>
      <div className="mb-l">
        <ThinTabGroup
          items={TABS}
          selectedItem={selectedTab}
          onSelectedItemChange={setSelectedTab}
        />
      </div>
      {selectedTab.id === 'CO' && (
        <ChangeOrderWorkflowSettings legalEntityId={legalEntityId} />
      )}
      {selectedTab.id === 'COR' && (
        <>
          <ChangeEventWorkflowSettings legalEntityId={legalEntityId} />
          <ThresholdsCruds legalEntityId={legalEntityId} />
        </>
      )}
    </div>
  );
}

export default WorkFlowSettings;

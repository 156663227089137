import { treeDFS } from 'lib/treeHelpers';
import { useGetApiReportFinancialCategoriesTreeQuery } from 'bundles/Shared/shared/api/reportFinancialCategoriesApiEnhanced';
import { useMemo } from 'react';
import { FinancialCategory } from 'bundles/Shared/entities/finanicalCategory/model';

export const getCategoryPathFromTreeById = (
  id: number,
  tree: FinancialCategory[],
): FinancialCategory[] => treeDFS(tree, (c) => c.id === id) ?? [];

export const useCategoryPathById = (
  id: number | null,
  tree: FinancialCategory[],
) =>
  useMemo<FinancialCategory[]>(
    () => (id ? getCategoryPathFromTreeById(id, tree) : []),
    [tree, id],
  );

export const useCategoryPathFromFinancialCategoriesById = (
  id: number | null,
) => {
  const { data } = useGetApiReportFinancialCategoriesTreeQuery();
  const categories = data?.tree ?? [];
  return useCategoryPathById(id, categories);
};

export const mapCategoryPathToCodes = (path: FinancialCategory[]): string[] =>
  path.map((c) => c.code);

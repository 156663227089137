import { useParams } from '@reach/router';
import { useModal } from '@/shared/lib/hooks/useModal';
import React from 'react';
import { CurrencyFormatter, IconButton } from 'stories';
import { useGetInvoiceQuery } from 'bundles/Construction/api/invoices';
import { InvoiceLinkedTransaction } from '@/shared/types/reconcile/Invoice';
import { formatDate } from '@/shared/lib/formatting/dates';
import LinkedTransactionModal from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/payInvoice/LinkedTransactionModal';

const LinkedTransaction = () => {
  const params = useParams();

  const { data: invoice } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  // waiting refactoring from BE - can only one transaction
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [transaction]: InvoiceLinkedTransaction[] =
    invoice?.linkedTransactions;

  const { openModal } = useModal();

  const openLinkedTransactionModal = () => {
    openModal(LinkedTransactionModal, {
      transaction,
      classes: {
        wrapper: '!my-0 !ml-auto !mr-tw-4',
      },
    });
  };

  return (
    <div className="flex flex-col gap-tw-2">
      <div className="body-semibold text-dark-60">Linked Transaction</div>
      <div className="flex justify-between rounded-lg border border-solid border-light-10 bg-light p-tw-4">
        <div>{formatDate(transaction?.date, 'MMM DD, YYYY')}</div>
        <div className="flex items-center gap-tw-4">
          <CurrencyFormatter
            value={transaction?.value ?? 0}
            classes={{
              value: 'inline-semibold text-dark-60',
              fractions: 'text-light-60',
            }}
          />
          <IconButton
            variant="white"
            iconName="infoAlt"
            onClick={openLinkedTransactionModal}
          />
        </div>
      </div>
    </div>
  );
};

export default LinkedTransaction;

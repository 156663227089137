import React, { useEffect, useMemo } from 'react';
import {
  AmountCard,
  AmountNoDataCard,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/legacy/AmountCard/AmountCard';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  constructionBudgetFields,
  costsFields,
  mainBudgetTypeCodeFields,
  mapSettingsToCardProperties,
  mapSettingsToRequest,
  mapSettingsToSelectProperties,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/legacy/utils';
import { cn } from '@/shared/lib/css/cn';
import { useModal } from '@/shared/lib/hooks/useModal';
import EditFieldModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/legacy/EditFieldModal';
import InitialEstimateDateField from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/legacy/fields/InitialEstimateDateField';
import ContractorRetaingleField from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/legacy/fields/ContractorRetaingleField';
import {
  fetchJccForReconcileSettings,
  fetchLegacySettings,
  updateLegacySettings,
} from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import { AnimationLoader, Field } from 'stories';
import { TUpdateLegalEntityLegacySettingsRequest } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntitiesSettings';
import styles from './LegacySettings.module.scss';
import FormTreeSelect from 'bundles/Shared/components/GroupForm/FormItems/FormTreeSelect';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import {
  useGetDevelopmentCategoryFlatQuery,
  useGetDevelopmentCategoryQuery,
} from 'bundles/Construction/api/core';
import { treeJCCToOption } from 'bundles/Shared/helpers/job_cost_code';
import { useParams } from '@reach/router';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { transformCategoriesForTree } from 'bundles/DrawPackage/InvoicesFlow/Invoices/utils';

interface Props {
  legalEntityId: string;
}

const CardSection = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => (
  <div
    className={cn(
      'flex flex-col rounded-[1rem] p-l gap-m bg-light bg-white',
      className,
    )}
  >
    {children}
  </div>
);

const CardSectionHeader = ({ children }) => (
  <h5 className="dark-60 header5-bold">{children}</h5>
);

const CardSectionCardsList = ({ children }) => (
  <div className="grid grid-cols-3 gap-tw-6">{children}</div>
);

function LegacySettings({ legalEntityId }: Props) {
  const { legalEntityCode } =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']>();
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const { data: JCCTree } = useGetDevelopmentCategoryQuery({
    legalEntityCode,
    without_sovcs: 'true',
  });

  const { data: JCCForReallocations } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode,
    without_sovcs: 'true',
  });
  const settings = useAppSelector(
    (state) => state.developmentLegalEntity.settings,
  );

  const jccOptions = useMemo(() => {
    if (JCCTree == null) {
      return [];
    }
    return transformCategoriesForTree({
      categories: treeJCCToOption(JCCTree),
    });
  }, [JCCTree]);

  useEffect(() => {
    dispatch(fetchLegacySettings(legalEntityId));
  }, [legalEntityId, legalEntityCode]);

  if (settings == null) {
    return <AnimationLoader />;
  }

  const cardProperties = mapSettingsToCardProperties(settings);
  const selectProperties = mapSettingsToSelectProperties(settings);

  const updateConfig = (
    newSettings: TUpdateLegalEntityLegacySettingsRequest,
  ) => {
    dispatch(
      updateLegacySettings({
        legalEntityId,
        settings: newSettings,
      }),
    );
  };

  const updateFieldWithModal = async (field: keyof typeof cardProperties) => {
    const res = await openModal(EditFieldModal, {
      field,
      fieldLabel: cardProperties[field].label,
      value: cardProperties[field].value!,
    });
    if (res !== undefined) {
      updateConfig({
        ...mapSettingsToRequest(settings),
        [cardProperties[field].key]: res,
      });
    }
  };

  const getCardPropsFromField = (
    field: keyof typeof cardProperties,
  ): React.ComponentProps<typeof AmountCard> => ({
    amount: cardProperties[field].value!,
    label: cardProperties[field].label,
    date: cardProperties[field].lastChangedAt!,
    source: cardProperties[field].lastChangedBy!,
    onEdit: () => updateFieldWithModal(field),
  });

  const mapFieldsToCards = (
    fieldsKeys: typeof costsFields | typeof constructionBudgetFields,
  ) =>
    fieldsKeys.map((field, i) =>
      cardProperties[field].value == null ? (
        <AmountNoDataCard
          key={field}
          onAdd={() => updateFieldWithModal(field)}
          label={cardProperties[field].label}
          className="flex-grow"
        />
      ) : (
        <AmountCard
          key={field}
          className="flex-grow"
          {...getCardPropsFromField(field)}
        />
      ),
    );

  return (
    <div className="flex w-full gap-m">
      <div className="flex flex-col flex-grow gap-m">
        <CardSection>
          <CardSectionHeader>Costs</CardSectionHeader>
          <CardSectionCardsList>
            {mapFieldsToCards(costsFields)}
          </CardSectionCardsList>
        </CardSection>
        <CardSection>
          <CardSectionHeader>Construction Budget</CardSectionHeader>
          <CardSectionCardsList>
            {mapFieldsToCards(constructionBudgetFields)}
          </CardSectionCardsList>
        </CardSection>
      </div>
      <CardSection className="w-[30%]">
        {mainBudgetTypeCodeFields.map((field) => (
          <FormTreeSelect
            key={field}
            placeholder=""
            fieldName="value"
            type={FieldType.TREE_SELECT}
            title={selectProperties[field].label}
            required
            formData={selectProperties[field]}
            options={jccOptions}
            styles={{
              wrapper: 'w-full',
              size: 'm',
              treeWrapper: '!z-[1]',
            }}
            value={
              JCCForReallocations?.find(
                (jcc) => jcc.id === selectProperties[field].value,
              )?.key
            }
            setFormData={(newData) =>
              updateConfig({
                ...mapSettingsToRequest(settings),
                [selectProperties[field].key]: JCCForReallocations?.find(
                  (jcc) => jcc.key === newData.value,
                )?.id,
              })
            }
          />
        ))}
        <Field
          className={styles.field}
          labelText="Initial Estimated Completion Date"
        >
          <InitialEstimateDateField
            onChange={(value) =>
              updateConfig(
                mapSettingsToRequest({
                  ...settings,
                  initialEstimatedCompletionDate: value,
                }),
              )
            }
          />
        </Field>
        <Field labelText="Contractor Retainage Value">
          <ContractorRetaingleField
            onChange={(value) =>
              updateConfig(
                mapSettingsToRequest({
                  ...settings,
                  contractorRetainageValue: Number(value),
                }),
              )
            }
          />
        </Field>
      </CardSection>
    </div>
  );
}

export default LegacySettings;

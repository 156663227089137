import React, { useState } from 'react';
import { LinkButton, Badge } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { CssVar } from '@/shared/config/cssVar';
import FeedMessageAttachmentsList from './FeedMessageAttachmentsList';
import AttachModal from '../Posts/AttachModal';
import YesOrNo from '../../../../Shared/components/YesOrNo';

const AttachmentsStep = ({
  attachedMedia,
  attachedDocuments,
  setAttachedMedia,
  setAttachedDocuments,
  onMediaRemove,
  onDocumentRemove,
  attachingMode,
  setAttachingMode,
  setPermissions,
  permissions,
}) => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div className="flex flex-col flex-grow items-start">
      <h6
        className={cn(
          'vertical-align-middle feed-message-step-title header6-regular',
          { 'mb-xxl': !attachingMode },
        )}
      >
        Would you like to attach docs or media?{' '}
        <span className="red">*</span>
        <YesOrNo
          onYesClick={() => setAttachingMode(true)}
          onNoClick={() => {
            setPermissions(permissions);
            setAttachingMode(false);
          }}
          active={attachingMode}
        />
      </h6>
      {attachingMode && (
        <>
          <LinkButton
            className="mt-s mb-s"
            onClick={() => setModalOpened(true)}
          >
            <Badge
              className="mr-tw-2 border border-solid border-light-20"
              textColor={CssVar.light60}
              backgroundColor={CssVar.white}
            >
              <span className="sre-icon-attach light-60 inline-regular" />
            </Badge>
            Attach
          </LinkButton>
          {(attachedMedia.length > 0 || attachedDocuments.length > 0) && (
            <FeedMessageAttachmentsList
              attachedMedia={attachedMedia}
              attachedDocuments={attachedDocuments}
              onMediaRemove={onMediaRemove}
              onDocumentRemove={onDocumentRemove}
            />
          )}

          {modalOpened && (
            <AttachModal
              attachedMedia={attachedMedia}
              setAttachedMedia={setAttachedMedia}
              attachedDocuments={attachedDocuments}
              setAttachedDocuments={setAttachedDocuments}
              onClose={() => setModalOpened(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AttachmentsStep;

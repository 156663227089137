import { useCallback, useState } from 'react';
import { PopoverProps } from 'stories/Popover/Popover';

export const usePopoverTriggered = (
  { disabled } = {
    disabled: false,
  },
) => {
  const [triggered, setTriggered] = useState(false);
  const onTrigger = useCallback<NonNullable<PopoverProps['onTrigger']>>(() => {
    !disabled && setTriggered(true);
  }, []);
  const onUntrigger = useCallback<
    NonNullable<PopoverProps['onUntrigger']>
  >(() => {
    !disabled && setTriggered(false);
  }, []);
  const onHidden = useCallback<NonNullable<PopoverProps['onHidden']>>(() => {
    !disabled && setTriggered(false);
  }, []);

  return {
    triggered,
    onTrigger,
    onUntrigger,
    onHidden,
  };
};

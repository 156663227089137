import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { Button } from '@/stories';
import type { LayoutProps } from '@/types/Props';
import type React from 'react';

export const JsonEditorUI = ({
  className,
  isCodeEditorOpen,
  ...props
}: LayoutProps & {
  editor: React.ReactNode;
  isCodeEditorOpen: boolean;
}) => (
  <div
    className={cn(
      'flex h-full w-[70%] items-center justify-center gap-tw-4 pt-tw-6',
      isCodeEditorOpen && 'w-full justify-start',
    )}
  >
    {isCodeEditorOpen && props.editor}
    {props.children}
  </div>
);

export const useJsonEditorButtonFeature = () => {
  const { value: isCodeEditorOpen, toggle: toggleCodeEditorOpen } =
    useBoolean(false);

  return {
    isCodeEditorOpen,
    toggleCodeEditorOpen,
    Button: () => (
      <Button
        onClick={toggleCodeEditorOpen}
        iconClasses={cn(isCodeEditorOpen && 'text-info-055')}
        iconName={isCodeEditorOpen ? 'panelLeft' : 'panelRight'}
        size="s"
        variant="secondary"
      >
        JSON Editor
      </Button>
    ),
    ButtonWrapper: (props: LayoutProps) => (
      <div
        className={cn(
          'absolute top-tw-6 flex items-center gap-tw-2',
          isCodeEditorOpen && 'left-[540px]',
        )}
      >
        {props.children}
      </div>
    ),
  };
};

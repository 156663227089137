import {
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation,
} from 'bundles/Shared/shared/api/dashboardSettingsEnhancedApi';
import { useCallback, useMemo } from 'react';
import {
  EagleEyeBoard,
  ReportDashboardType,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';

export const useMoveBoards = () => {
  const { dashboardId, dashboardType } = useDashboardContext();

  const objectMutation = usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation();
  const eagleEyeMutation = usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation();
  const comparisonMutation = usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation();

  const [updateBoard] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return objectMutation
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation
      }
    }
  }, [dashboardType])

  const moveBoards = useCallback(
    async ({ boards }: { boards: Pick<EagleEyeBoard, 'id'>[] }) => {
      await Promise.all(
        boards.map((board, index) => {
          return updateBoard({
            eagleEyeDashboardId: dashboardId,
            objectDashboardId: dashboardId,
            comparisonDashboardId: dashboardId,
            boardId: board.id,
            body: {
              order: index,
            },
          });
        }),
      );
      toastr.success('Boards reordered successfully');
    },
    [updateBoard],
  );

  return moveBoards;
};

import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/reportBuilderTemplatesGeneratedApi';
import type { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button, Input, Modal } from '@/stories';
import Field, { FieldsContainer } from '@/stories/Field/Field';
import { useForm } from 'react-hook-form';

type Form = Pick<ReportBuilderTemplateDto, 'name'>;

export function CreateReportBuilderTemplateModal({
  onClose,
  onSubmit,
  template,
}: DialogProps<Form> & {
  template?: Form;
}) {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      ...(template ?? {
        name: '',
      }),
    },
  });

  const onSubmitHandler = handleSubmit((data) => {
    onSubmit?.(data);
  });

  return (
    <Modal
      toggle={onClose}
      header={`Create template`}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={!isValid}
            onClick={onSubmitHandler}
          >
            Create
          </Button>
        </>
      }
    >
      <FieldsContainer>
        <Field labelText="Title" required>
          <Input
            {...register('name', {
              required: true,
            })}
          />
        </Field>
      </FieldsContainer>
    </Modal>
  );
}

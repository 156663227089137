import FullWhiteTable from 'bundles/Shared/components/Table/variants/FullWhiteTable';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { useModal } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import {
  Button,
  CurrencyFormatter,
  Icon,
  ModalHeaderWithSubtitle,
  StatisticLine,
} from 'stories/index';
import { CssVar } from '@/shared/config/cssVar';
import ReviewAction from '../../manageCapitalInvestmentModal/ReviewAction';
import { SharedProps } from './SplitTxModal';
import { ConfirmTransactionLink } from './components/ConfirmTransactionLink';
import { TransactionHeaderInfo } from './components/TransactionHeaderInfo';
import { CURRENCY_FORMATTER_CLASSES } from './consts';
import { useEntryColumns } from './hooks/tableColumns/useEntryColumns';

import { convertCentsToDollars } from '@/shared/lib/converters';

export function LinkTxWithExactMatch({
  onClose,
  onSubmit,
  accountingTransaction,
  entry,
  type,
}: SharedProps) {
  const entriesToLink = 1;
  const { openModal } = useModal();

  const handleConfirm = async () => {
    const res = await openModal(
      ReviewAction.Modal,
      {
        children: (
          <ConfirmTransactionLink
            entries={[entry]}
            transaction={accountingTransaction}
          />
        ),
      },
      {
        topLevelRender: false,
      },
    );

    if (res == null) return;

    onSubmit?.([entry]);
  };

  const columns = useEntryColumns({
    entries: [entry],
    type,
  });

  return (
    <SidePanel
      toggle={onClose}
      maxHeight
      zIndex={HUGE_MODAL_Z_INDEX + 1}
      header={<ModalHeaderWithSubtitle title="Link Transaction" />}
      classes={{
        header: 'bg-white',
        body: 'bg-light flex flex-col gap-tw-4',
      }}
    >
      <TransactionHeaderInfo transaction={accountingTransaction} />
      <div className="flex flex-col gap-tw-2">
        <StatisticLine
          statistics={{
            succes: {
              value: 100,
              color: CssVar.green,
            },
          }}
        />
        <span className="flex items-center justify-center gap-tw-1">
          <Icon iconName="checkSmall" />
          <span className="inline-regular">
            <CurrencyFormatter
              classes={CURRENCY_FORMATTER_CLASSES}
              value={convertCentsToDollars(accountingTransaction.amountCents)}
            />{' '}
            is fully declared
          </span>
        </span>
      </div>
      <span className="inline-semibold text-dark-60">
        {entriesToLink} {pluralize(capitalize(type), entriesToLink)}
      </span>
      <FullWhiteTable columns={columns} items={[entry]} />
      <Button onClick={handleConfirm} className="mt-auto" variant="success">
        Link Transaction
      </Button>
    </SidePanel>
  );
}

import React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import useHeaderSorting from 'lib/ag-grid/useHeaderSorting';
import SortButton from 'bundles/Shared/components/Filters/buttons/sortButton/SortButton';

type Props = IHeaderParams;

function Header(props: Props) {
  const { onSortRequested, activeSort } = useHeaderSorting(props);

  const sort = props.enableSorting && (
    <SortButton
      className="ml-s"
      sort={activeSort}
      onClick={(e) => {
        onSortRequested(e);
      }}
    />
  );

  return (
    <div className="flex items-center">
      {props.displayName}
      {sort}
    </div>
  );
}

export default Header;

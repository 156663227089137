import React from 'react';
import { useCrudContext } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';

type Props = Partial<
  Omit<
    React.ComponentProps<typeof TablePagination>,
    'currentPage' | 'loading' | 'sizePerPage'
  >
>;

export function CrudPagination({ ...props }: Props) {
  const { pageParams, entities, setPageParams } = useCrudContext();
  return (
    <TablePagination
      setCurrentPage={(page) => setPageParams({ ...pageParams, page })}
      currentPage={pageParams.page ?? 1}
      loading={false}
      totalSize={props.totalSize ?? entities.length}
      sizePerPage={pageParams.per_page ?? entities.length}
      {...props}
    />
  );
}

export default CrudPagination;

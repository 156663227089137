import { GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';
import { useSettingsReportUnitStatusesExport } from '@/features/report/unitStatuses/settingsExport/lib';
import { ExportButton } from '@/shared/ui/ExportButton';

export const SettingsReportUnitStatusesExportButton = ({
  params,
}: {
  params: GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg;
}) => {
  const [getFile, options] = useSettingsReportUnitStatusesExport();

  return (
    <ExportButton
      onClick={() => getFile(params)}
      disabled={options.isLoading}
      loading={options.isLoading}
    />
  );
};

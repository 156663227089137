import { cn } from '@/shared/lib/css/cn';
import { currentUserAllowedTo } from 'lib/permissions';
import { groupBy, sortBy, uniqBy } from 'lodash-es';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import {
  Button,
  Icon,
  IconButton,
  LinkButton,
  Popover,
  PseudoLink,
  SreAvatar,
  Tooltip,
} from 'stories';
import { PopoverProps } from 'stories/Popover/Popover';
import { IPermissions } from 'types/Permissions';
import { ICON_MAP, LABEL_MAP } from './Permissions/const';
import { TItemType } from './Permissions/types';
import PermissionsModal from './PermissionsModal';
import PermissionsModalDocsHeader from './PermissionsModalDocsHeader';
import { SharedWithModal } from './SharedWithModal';
import { ProductNameFlat } from 'lib/permissions';
import { Permitted } from '@/bundles/Shared/entities/permissions';

type PopoverPropsForPermissionList = Pick<
  PopoverProps,
  'arrowPosition' | 'placement'
>;

interface Props {
  permissions: IPermissions & Pick<Permitted, 'isPublic'>;
  fileItem: File;
  locked: boolean;
  tabsIsHidden: boolean;
  canEdit: boolean;
  defaultText?: string;
  type?: string;
  productName?: ProductNameFlat;
  investmentObject?: any;
  hideActions?: boolean;
  hideIcon?: boolean;
  onSubmit?: (permissions: IPermissions) => void;
  className?: string;
  mainListClasses?: string;
  objectableId?: number | string;
  objectableType?: string;
  withoutSharedModal?: boolean;
  isEmail?: boolean;
  modalTitle?: string;
  whiteListedTabs?: string[];
  placeholderRenderer?: React.ReactNode;
  popoverOptions?: PopoverPropsForPermissionList;
  classes?: {
    popoverTrigger?: string;
  };
  editIconButtonProps?: Partial<React.ComponentProps<typeof IconButton>>;
  sharedWithModalProps?: Partial<React.ComponentProps<typeof SharedWithModal>>;
  itemType?: string;
}

interface IAccessListItem {
  value: string;
  type: string;
  role?: string;
}

const DISPLAY_MEMBERS_COUNT = 3;

/**
 * @deprecated use this version instead `src/bundles/Shared/components/Permissions/PermissionList.tsx`
 */
const PermissionList = ({
  permissions,
  hideActions = true,
  hideIcon,
  onSubmit,
  type,
  productName,
  investmentObject,
  className,
  defaultText,
  mainListClasses,
  fileItem,
  objectableId,
  objectableType,
  whiteListedTabs,
  locked = false,
  tabsIsHidden = false,
  withoutSharedModal = false,
  canEdit = false,
  isEmail = false,
  modalTitle,
  placeholderRenderer = '—',
  popoverOptions,
  classes,
  editIconButtonProps,
  sharedWithModalProps,
  itemType,
}: Props) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [viewPermissionsModalOpened, setViewPermissionsModalOpened] =
    useState(false);

  const {
    id,
    assets = [],
    funds = [],
    roles = [],
    tags = [],
    users = [],
    investmentEntities = [],
    // @ts-ignore
    autoSelectedUsers = [],
    allUsers = [],
  } = permissions;
  // NOTE: we need to refactor it
  const isPublic =
    permissions.type === 'public' || permissions.public || permissions.isPublic;
  const getAllUsers = isPublic ? allUsers : [];

  const userList = uniqBy(
    [...users, ...autoSelectedUsers, ...getAllUsers],
    'id',
  );

  const accessList = useMemo<IAccessListItem[]>(() => {
    const list = [
      ...assets.map((asset) => ({ value: asset.name, type: 'asset' })),
      ...funds.map((asset) => ({ value: asset.name, type: 'fund' })),
      ...roles.map((entry) => ({ value: entry.name, type: 'role' })),
      ...tags.map((entry) => ({ value: entry.name, type: 'tag' })),
      ...investmentEntities.map((entry) => ({
        value: entry.name,
        type: 'investmentEntity',
      })),
      ...userList.map((user) => ({
        value: user.fullName,
        type: 'member',
        role: user.role?.name,
      })),
    ];
    return sortBy(list, (item) => item.value.toLowerCase());
  }, [permissions]);

  const resolveLabel = (itemsType: TItemType, count = 2) =>
    pluralize(LABEL_MAP[itemsType], count);

  const buildPermissionsList = ([itemsType, items]: [
    TItemType,
    IAccessListItem[],
  ]) => {
    if (isPublic) return 'All Users';

    return items.length > 0
      ? `${items.length} ${resolveLabel(itemsType, items.length)}`
      : null;
  };

  const renderPermissionsPopover = (onClick: () => void) => {
    const permissionTypesOrder = ['member', 'investmentEntity', 'role', 'tag'];
    let value = '';
    if (isPublic) {
      value = 'All Users';
    } else {
      value = Object.entries(groupBy(accessList, 'type'))
        .sort((a, b) => {
          if (a[0] === b[0]) {
            // [0] is index of permission type here.
            return a[0].localeCompare(b[0]);
          }
          return (
            permissionTypesOrder.indexOf(a[0]) -
            permissionTypesOrder.indexOf(b[0])
          );
        })
        .map(buildPermissionsList)
        .filter(Boolean)
        .join(', ');
    }

    let content;

    if (accessList.length === 1 && accessList[0].type === 'member') {
      content = (
        <div className="documents-member-preview my-xs">
          <div className="flex w-full justify-center">
            <SreAvatar
              text={accessList[0].value}
              width="2.5rem"
              height="2.5rem"
            />
          </div>
          <div className="dark-60 weight-500 mt-xs">{accessList[0].value}</div>
          <div className="muted">{accessList[0].role}</div>
        </div>
      );
    } else if (accessList.length >= 1) {
      content = (
        <div className="view-permissions-popover">
          {Object.entries(groupBy(accessList, 'type')).map(
            ([itemsType, items]: [TItemType, IAccessListItem[]]) => {
              const list =
                itemsType === 'member'
                  ? items.slice(0, DISPLAY_MEMBERS_COUNT)
                  : items;

              return (
                <div
                  className="view-permission-type mnw-200p p-s"
                  key={itemsType}
                >
                  <div className="dark-60 font-weight-500 mb-xs flex items-center gap-tw-1">
                    <Icon iconName={ICON_MAP[itemsType]} />
                    <div className="text-[1rem]">{resolveLabel(itemsType)}</div>
                  </div>
                  <div className="light-90 secondary-regular">
                    {list.map((item) => item.value).join(', ')}
                  </div>
                  {itemsType === 'member' &&
                    items.length > DISPLAY_MEMBERS_COUNT && (
                      <LinkButton
                        onClick={onClick}
                        className="secondary-regular"
                      >
                        +{items.length - DISPLAY_MEMBERS_COUNT} more
                      </LinkButton>
                    )}
                </div>
              );
            },
          )}
        </div>
      );
    }

    return (
      <Popover
        template={content}
        maxWidth="400px"
        trigger="mouseenter"
        touch={false}
        hideOnExpandedAreaClick
        appendTo={() => document.body}
        placement="left-end"
        arrowPosition="end"
        classes={{
          spanContainer: 'd-inline-flex mnw-0',
        }}
        {...popoverOptions}
      >
        <PseudoLink
          className={cn('text-ellipsis', classes?.popoverTrigger)}
          onClick={onClick ? () => onClick() : undefined}
        >
          {value}
        </PseudoLink>
      </Popover>
    );
  };

  return (
    <div className={cn('mnw-0 inline-regular flex justify-between', className)}>
      <div
        className={cn('permission-content mnw-0 mr-[0.5rem]', mainListClasses)}
      >
        {isPublic && (
          <>
            {!hideIcon && (
              <span className="permission-type-icon">
                <Icon iconName={ICON_MAP.public} />
              </span>
            )}
            {renderPermissionsPopover(() =>
              setViewPermissionsModalOpened(true),
            )}
          </>
        )}
        {!isPublic &&
          accessList.length === 0 &&
          !defaultText &&
          placeholderRenderer}
        {!isPublic && accessList.length >= 1 && (
          <>
            {renderPermissionsPopover(() =>
              setViewPermissionsModalOpened(true),
            )}
          </>
        )}
      </div>
      {locked && (
        <Tooltip
          mainText="Permissions are locked and cannot be edited"
          arrowPosition="center"
        >
          <IconButton iconName="lock" disabled />
        </Tooltip>
      )}
      {!locked &&
        ((productName && currentUserAllowedTo('manage', productName)) ||
          canEdit) &&
        !hideActions &&
        onSubmit && (
          <>
            {defaultText && accessList.length === 0 && !isPublic && (
              <Button
                variant="secondary"
                onClick={() => setModalOpened(true)}
                iconName="edit"
              >
                {defaultText}
              </Button>
            )}
            {((defaultText && accessList.length > 0) ||
              isPublic ||
              !defaultText) && (
              <IconButton
                className="self-start"
                iconName="edit"
                onClick={() => setModalOpened(true)}
                {...editIconButtonProps}
              />
            )}
          </>
        )}
      {modalOpened && (
        <PermissionsModal
          customHeader={
            type === 'document' ? (
              <PermissionsModalDocsHeader fileItems={[fileItem]} />
            ) : undefined
          }
          title={
            type && type !== 'document'
              ? `Who can see your ${type}?`
              : modalTitle ?? undefined
          }
          onClose={() => setModalOpened(false)}
          onSubmit={onSubmit!}
          initialState={{
            type: isPublic ? 'public' : 'restricted',
            public: isPublic,
            users,
            roles,
            tags,
            investmentEntities,
            id,
          }}
          initialTab={isPublic ? 'public' : 'restricted'}
          productName={productName}
          investmentObject={investmentObject}
          objectableId={objectableId}
          objectableType={objectableType}
          tabsIsHidden={tabsIsHidden}
          whiteListedTabs={whiteListedTabs}
          isEmail={isEmail}
          itemType={itemType}
        />
      )}

      {viewPermissionsModalOpened && !withoutSharedModal && (
        <SharedWithModal
          toggle={() =>
            setViewPermissionsModalOpened(!viewPermissionsModalOpened)
          }
          allUsers={isPublic ? userList : [...users, ...autoSelectedUsers]}
          setModalOpened={setModalOpened}
          hideActions={hideActions}
          {...sharedWithModalProps}
        />
      )}
    </div>
  );
};

PermissionList.displayName = 'PermissionList';

export default PermissionList;

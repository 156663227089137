import { cn } from '@/shared/lib/css/cn';
import { useIntersectionObserver } from '@/shared/lib/hooks/useIntersectionObserver';
import { debounce } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ComponentProps, useCallback, useEffect, useRef } from 'react';
import { AnimationLoader, OverlaySpinner, SearchInput } from 'stories';
import styles from './ItemFilterList.module.scss';

export interface ItemFilterListWithInfiniteScrollProps<T = unknown> {
  items: T[];
  onSelectedItemChange: (item: T) => void;
  getMainFieldTitle: (item: T) => string;
  getSecondaryFieldTitle?: (item: T) => string;
  maxHeight?: number | string;
  mask?: string;
  selectedItems?: T[];
  searchInputProps?: Pick<
    ComponentProps<typeof SearchInput>,
    'placeholder' | 'size'
  >;
  classes?: {
    itemTitle?: string;
    item?: string;
  };
  className?: string;
  loading?: boolean;
  query?: string;
  onQueryChange?: (text: string) => void;
  loadingMoreActive?: boolean;
  onIntersectionLastItem?: () => void;
}

function ItemFilterListWithInfiniteScroll<T = unknown>({
  items,
  selectedItems,
  onSelectedItemChange,
  maxHeight,
  mask = 'items',
  getMainFieldTitle,
  getSecondaryFieldTitle,
  searchInputProps,
  classes,
  className,
  loading,
  query = '',
  onQueryChange,
  loadingMoreActive,
  onIntersectionLastItem,
}: ItemFilterListWithInfiniteScrollProps<T>) {
  const loadingElemRef = useRef(null);
  const entry = useIntersectionObserver(loadingElemRef, {});
  const isIntersecting = Boolean(entry?.isIntersecting);

  useEffect(() => {
    if (loadingMoreActive && isIntersecting && onIntersectionLastItem != null) {
      onIntersectionLastItem();
    }
  }, [isIntersecting]);

  const debouncedChangeHandler = useCallback(
    debounce((text) => onQueryChange?.(text), 500),
    [],
  );

  const handleFilterTextChange = (text: string) => {
    debouncedChangeHandler(text);
  };
  const isItemSelected = (item: T) =>
    selectedItems?.map(({ id }) => id).includes(item.id);

  return (
    <div style={{ maxHeight }} className={cn('flex flex-col', className)}>
      <SearchInput
        value={query}
        onChange={(e) => handleFilterTextChange(e.target.value)}
        placeholder="Search..."
        className="m-tw-2"
        {...searchInputProps}
        onReset={() => handleFilterTextChange('')}
      />
      {loading ? (
        <div style={{ minHeight: maxHeight ?? '20rem', minWidth: '17.5rem' }}>
          <AnimationLoader />
        </div>
      ) : (
        <OverlayScrollbarsComponent>
          {items.map((item) => (
            <div
              key={item.id}
              onClick={() => onSelectedItemChange(item)}
              className={cn(
                'cursor-pointer px-tw-4 py-tw-2',
                [styles.itemFilterList],
                {
                  [styles.itemFilterList_active]: isItemSelected(item),
                },
                classes?.item,
              )}
            >
              <div
                className={cn(
                  'inline-semibold',
                  styles.itemFilterListTitle,
                  classes?.itemTitle,
                )}
              >
                {getMainFieldTitle(item)}
              </div>

              {getSecondaryFieldTitle?.(item) && (
                <span className={cn('secondary-regular')}>
                  {getSecondaryFieldTitle?.(item)}
                </span>
              )}
            </div>
          ))}
          <div
            className={cn(
              'relative hidden min-h-[48px] items-center justify-center gap-tw-4',
              {
                flex: loadingMoreActive && items.length > 0,
              },
            )}
            ref={loadingElemRef}
          >
            <OverlaySpinner
              size="small"
              overlayClasses="bg-transparent"
              inline
            />
            Loading...
          </div>
          {items.length === 0 && !loading && (
            <div className="my-s text-center">No {mask} found</div>
          )}
        </OverlayScrollbarsComponent>
      )}
    </div>
  );
}

export default ItemFilterListWithInfiniteScroll;

import { getClosestUnitPrice } from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import { useUnitPriceFieldFlags } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useUnitPriceFieldFlags';
import { resolveUnitsInputTooltipText } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/utils';
import {
  Commitment,
  InvestmentIndex,
  InvestmentObjectUnitPrice,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { formatDate } from '@/shared/lib/formatting/dates';
import { cn } from '@/shared/lib/css/cn';
import { assertIsSelectOption } from '@/stories/FormControls/Select/utils';
import { UnitsInputNumber } from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/TotalWithUnits';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import dayjs from 'dayjs';
import useEvent from '@/shared/lib/hooks/useEvent';
import { parseNumberFromCurrencyInput } from '@/shared/lib/formatting/number';
import { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Select } from 'stories';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { createPeriodFromDate } from 'stories/FlexibleFilterByPeriods/utils';
import { STATIC_DATE_PICKER_PROPS } from '../consts';
import styles from './CapitaInvestmentEditor.module.scss';
import { convertCentsToDollars } from '@/shared/lib/converters';
import InvestmentEntityColumn from '@/bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';

export type TEditableCapitalInvestments = Pick<
  InvestmentIndex,
  'id' | 'investmentEntity' | 'legalEntity' | 'totalContributions'
> & {
  investmentClass?: InvestmentObjectUnitPrice['investmentClass'];
  signedDate?: DateString;
  commitmentId?: Commitment['id'];
  date?: IPeriodItem['period'];
  amount?: number;
  unitsQuantity?: number;
  _basedUnitPrice?: number;
};

type Props = {
  capitalInvestments: TEditableCapitalInvestments[];
  unitPrices?: InvestmentObjectUnitPrice[];
  onCapitalInvestmentsChange?: (
    capitalInvestments: TEditableCapitalInvestments[],
  ) => void;
  withDateColumn?: boolean;
  disableEditDateColumn?: boolean;
  investmentClass?: InvestmentObjectUnitPrice['investmentClass'];
  groupedCommitments?: Record<string, Commitment[]>;
  commitmentsMode?: boolean;
  defaultDate?: Date;
  selectedUnitPrice?: InvestmentObjectUnitPrice;
};

function CapitalInvestmentsEditor({
  capitalInvestments,
  onCapitalInvestmentsChange,
  withDateColumn,
  disableEditDateColumn,
  unitPrices,
  investmentClass,
  groupedCommitments,
  commitmentsMode,
  selectedUnitPrice,
}: Props) {
  const resolveUnitPriceFieldFlags = useUnitPriceFieldFlags();

  const handleAmountChange = useEvent(
    (id: number, value: number | undefined) => {
      onCapitalInvestmentsChange?.(
        capitalInvestments.map((ci) =>
          ci.id === id
            ? {
                ...ci,
                amount: value,
              }
            : ci,
        ),
      );
    },
  );

  {
    /* Recalculate in next iterations */
  }
  // const totalUnits = useMemo<number>(() => {
  //   if (!capitalInvestments.length) return 0;

  //   const result = capitalInvestments.reduce((acc, curr) => {
  //     const currentAmount = curr.amount ?? 0;
  //     const unitPrice = convertCentsToDollars(curr.closeUnitPrice?.priceCents ?? 0);
  //     const currentUnits = getUnitsQty(currentAmount, unitPrice);

  //     return acc + currentUnits;
  //   }, 0);

  //   return result;
  // }, [capitalInvestments]);

  const hasUnitPrices = unitPrices != null && unitPrices.length > 0;

  const handleDateChange = useEvent(
    (id: number, value: IPeriodItem['period'] | undefined) => {
      onCapitalInvestmentsChange?.(
        capitalInvestments.map((ci) =>
          ci.id === id
            ? {
                ...ci,
                date: value,
                unitsQuantity: undefined,
                _basedUnitPrice: undefined,
              }
            : ci,
        ),
      );
    },
  );

  const handleSignedDateChange = useEvent(
    (id: number, signedData: DateString, commitmentId: string) => {
      onCapitalInvestmentsChange?.(
        capitalInvestments.map((ci) =>
          ci.id === id
            ? {
                ...ci,
                signedDate: signedData,
                commitmentId,
                unitsQuantity: undefined,
                _basedUnitPrice: undefined,
              }
            : ci,
        ),
      );
    },
  );

  const handleUnitChange = useEvent(
    (id: number, units: number | undefined = 0, unitPrice: number) => {
      onCapitalInvestmentsChange?.(
        capitalInvestments.map((ci) =>
          ci.id === id
            ? {
                ...ci,
                amount: units * unitPrice,
                _basedUnitPrice: unitPrice,
              }
            : ci,
        ),
      );
    },
  );

  const handleUnitQuantityChange = useEvent(
    (id: number, unitsQuantity: number | undefined, unitPrice: number) => {
      onCapitalInvestmentsChange?.(
        capitalInvestments.map((ci) =>
          ci.id === id
            ? {
                ...ci,
                unitsQuantity,
                amount: 0,
                _basedUnitPrice: unitPrice,
              }
            : ci,
        ),
      );
    },
  );

  const columns = useMemo<IColumn<TEditableCapitalInvestments>[]>(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        formatter: ({ row }) => (
          <InvestmentEntityColumn
            capitalInvestment={row}
            withIcon={false}
            withUsers={false}
          />
        ),
      },
      {
        text: 'Legal Entity',
        dataField: 'legalEntity',
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            {item.legalEntity.name}
          </span>
        ),
      },
      {
        text: 'Signed Date',
        dataField: 'signed_date',
        hidden: groupedCommitments == null || investmentClass == 'a',
        headerStyle: {
          width: 200,
        },
        formatter({ row }) {
          const currentGroupedCommitments = groupedCommitments?.[row.id] ?? [];
          const commitmentSignedDateOptions =
            currentGroupedCommitments
              .filter((commit) => commit.signedDate != null)
              .map((commit) => ({
                id: commit.signedDate as DateString,
                label: formatDate(
                  commit.signedDate as DateString,
                  'MMM DD, YYYY',
                ),
                value: commit.id,
              })) ?? [];
          const hasNoOptions = commitmentSignedDateOptions.length === 0;

          return (
            <Select<DateString>
              placeholder={hasNoOptions ? 'No Options' : 'Select Signed Date'}
              size="m"
              options={commitmentSignedDateOptions}
              disabled={hasNoOptions}
              selected={commitmentSignedDateOptions.find(
                (o) => o.id === row.signedDate,
              )}
              onSelectedChange={(selected) => {
                assertIsSelectOption(selected);
                handleSignedDateChange(row.id, selected.id, selected.value);
              }}
              inputProps={{
                leftIcon: 'calendar',
              }}
            />
          );
        },
      },
      {
        text: commitmentsMode ? 'Signed Date' : 'Date',
        dataField: 'date',
        hidden: !withDateColumn,
        headerStyle: {
          width: 180,
        },
        formatter: ({ row: item }) => (
          <ReactDatePicker
            {...STATIC_DATE_PICKER_PROPS}
            disabled={withDateColumn && disableEditDateColumn}
            selected={item.date && dayjs(item.date).toDate()}
            onChange={(date) =>
              handleDateChange(
                item.id,
                date ? createPeriodFromDate(date) : undefined,
              )
            }
          />
        ),
      },
      {
        text: 'Amount',
        dataField: 'amount',
        headerStyle: {
          width: hasUnitPrices ? 300 : 200,
        },
        formatter: ({ row }) => {
          const currentGroupedCommitments = groupedCommitments?.[row.id] ?? [];
          const hasNoOptions = currentGroupedCommitments.length === 0;

          const {
            closestUnitPrice: closestUnitPriceByClass,
            unitsDisabledToChange,
            useSignedDateForUnitPriceDate,
            isClosestUnitPriceZero,
            value,
            noAvailableUnitPricesByInvestmentClass,
          } = resolveUnitPriceFieldFlags({
            form: {
              amount: row.amount,
              date: row.date,
              investmentClass,
              signedDate: row.signedDate,
              unitsQuantity: row.unitsQuantity,
              selectedUnitPrice,
            },
            hasNoCommitmentsOptions: hasNoOptions,
          });

          const resolveUnitPrice = () => {
            if (selectedUnitPrice)
              return convertCentsToDollars(selectedUnitPrice.priceCents);
            if (groupedCommitments != null) {
              return closestUnitPriceByClass;
            }

            if (unitPrices != null && unitPrices.length > 0) {
              return convertCentsToDollars(
                getClosestUnitPrice({ date: row.date!, unitPrices }).priceCents,
              );
            }

            return 0;
          };
          const unitPrice = resolveUnitPrice();

          const resolveDisabled = () => {
            if (groupedCommitments != null) {
              return (
                unitsDisabledToChange || noAvailableUnitPricesByInvestmentClass
              );
            }

            return (unitPrices?.length ?? 0) > 1 && row.date == null;
          };

          return (
            <div
              className={cn(
                hasUnitPrices &&
                  !isClosestUnitPriceZero &&
                  'grid grid-cols-[1fr_120px] gap-tw-2',
              )}
            >
              {!isClosestUnitPriceZero && (
                <CurrencyInputNumber
                  selectOnFocus
                  value={row.amount}
                  allowNegative={false}
                  onChange={(e) => {
                    handleAmountChange(
                      row.id,
                      parseNumberFromCurrencyInput(e.target.value),
                    );
                  }}
                />
              )}
              {hasUnitPrices && (
                <UnitsInputNumber
                  tooltipMainText={resolveUnitsInputTooltipText({
                    investmentClass,
                    noAvailableUnitPricesByInvestmentClass,
                    useSignedDateForUnitPriceDate,
                  })}
                  selectOnFocus
                  value={value ?? ''}
                  placeholder="0"
                  disabled={resolveDisabled()}
                  onChange={(e) => {
                    if (isClosestUnitPriceZero) {
                      handleUnitQuantityChange(
                        row.id,
                        Number(e.target.value) || undefined,
                        unitPrice,
                      );
                      return;
                    }
                    handleUnitChange(
                      row.id,
                      e.target.value
                        ? parseNumberFromCurrencyInput(e.target.value)
                        : undefined,
                      unitPrice,
                    );
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [investmentClass],
  );

  return (
    <>
      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-tw-2 light-60',
        }}
        items={capitalInvestments}
        columns={columns}
      />
      {/* Recalculate in next iterations */}
      {/* {hasUnitPrices && (
        <span className="flex gap-tw-1 secondary-regular text-dark-60 justify-end pr-tw-4">
          <span className="text-neutral-500">Units</span>
          <span className={cn('secondary-regular inline-flex text-neutral-900', totalUnits === 0 && 'text-neutral-500')}>
            {totalUnits}
          </span>
        </span>
      )} */}
    </>
  );
}

export default CapitalInvestmentsEditor;

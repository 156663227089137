import {
  ReportManualVariable,
  ReportManualVariableLegalEntity,
  ReportManualVariableLegalEntityValue,
  usePostApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesMutation,
} from '@/entities/report/manualVariable';
import { getDefaultValueForValueType } from '@/entities/valueType/lib';

export const useCreateReportManualVariableValue = () => {
  const [create, options] =
    usePostApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesMutation();
  return [
    ({
      legalEntityId,
      manualVariableId,
      valueType,
      ...args
    }: {
      manualVariableId: ReportManualVariable['id'];
      legalEntityId: ReportManualVariableLegalEntity['id'];
    } & Partial<
      Pick<
        ReportManualVariableLegalEntityValue,
        'dateFrom' | 'dateTo' | 'value' | 'valueType'
      >
    >) =>
      create({
        body: {
          date_from: args.dateFrom ?? undefined,
          date_to: args.dateTo ?? undefined,
          value: getDefaultValueForValueType(valueType),
        },
        legalEntityId,
        manualVariableId,
      }),
    options,
  ] as const;
};

import { TooltipProps } from '@/stories/Tooltip/Tooltip';
import { Modifier } from '@popperjs/core/lib/types';
import { ArrowPositions } from 'types/Tippy';

interface Args extends Pick<TooltipProps, 'arrowPosition'> {
  offsetByBorderRadius?: number;
  arrowWidth?: number;
  placement: string;
}

function detectTippyFlips(state) {
  const PLACEMENT_SEPARATOR = '-';
  const splittedConfigPlacement =
    state.options.placement.split(PLACEMENT_SEPARATOR);
  const isSidePlacement = splittedConfigPlacement.length === 2;
  const actualSecondaryPlacement =
    state.placement.split(PLACEMENT_SEPARATOR)[1];
  const isFlippedToOppositeAngle =
    isSidePlacement &&
    actualSecondaryPlacement &&
    splittedConfigPlacement[1] !== actualSecondaryPlacement;
  return {
    isFlippedToOppositeAngle,
    actualSecondaryPlacement,
  };
}

export function ModifyArrowPosition({
  arrowPosition,
  arrowWidth = 13,
  offsetByBorderRadius = 4,
  placement,
}: Args) {
  const measurement = /left|right/.test(placement) ? 'height' : 'width';
  const axis = measurement === 'height' ? 'y' : 'x';
  const arrowPositionModifier: Modifier<any, any> = {
    enabled: true,
    phase: 'afterMain',
    name: 'arrowOffset',
    fn: ({ state }) => {
      const { isFlippedToOppositeAngle, actualSecondaryPlacement } =
        detectTippyFlips(state);
      const actualArrowPos = isFlippedToOppositeAngle
        ? actualSecondaryPlacement
        : arrowPosition;
      const isActualArrPosOnStart = actualArrowPos === ArrowPositions.START;
      const centerPosition = state.rects.popper[measurement] / 2;
      const startPosition =
        isActualArrPosOnStart || actualArrowPos === ArrowPositions.CENTER
          ? 0
          : state.rects.popper[measurement];
      const edgeOffset =
        (isActualArrPosOnStart ? 1 : -1) *
        ((isActualArrPosOnStart ? 0 : arrowWidth) + offsetByBorderRadius);
      const calculatedArrowPos =
        actualArrowPos === ArrowPositions.CENTER
          ? centerPosition - arrowWidth / 2
          : startPosition + edgeOffset;
      return {
        ...state,
        modifiersData: {
          ...state.modifiersData,
          arrow: {
            ...state.modifiersData.arrow,
            [axis]: calculatedArrowPos,
          },
        },
      };
    },
  };
  return arrowPositionModifier;
}

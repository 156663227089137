export enum TRentrollUnitStatus {
  ADMIN = 'admin',
  VACANT = 'vacant',
  APPLICANT = 'applicant',
  OCCUPIED = 'occupied',
  OCCUPIED_NTV = 'occupied_ntv',
  OCCUPIED_NO_NTV = 'occupied_no_ntv',
  OCCUPIED_NTVL = 'occupied_ntvl',
  VACANT_LEASED = 'vacant_leased',
}

import { ReportManualVariableLegalEntityValue } from '@/entities/report/manualVariable';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export function useNextValueDateFrom({
  items,
}: {
  items: ReportManualVariableLegalEntityValue[];
}) {
  const nextDateFrom = useMemo(() => {
    if (items.length === 0) return null;

    const latestDateTo = items.reduce((acc, v) => {
      if (acc == null) return v.dateTo;

      if (v.dateTo == null) return acc;

      if (dayjs(v.dateTo).isAfter(acc)) {
        return v.dateTo;
      }
      return acc;
    }, null);

    return latestDateTo
      ? formatToDateStringForRequest(dayjs(latestDateTo).add(1, 'day'))
      : null;
  }, [items]);

  return nextDateFrom;
}

import React, { useRef, useState } from 'react';

interface Props {
  templatePreview: any;
  subject: string;
}

function TemplatePreview({ templatePreview, subject }: Props) {
  const [frameHeight, setFrameHeight] = useState(0);
  const frameRef = useRef();

  const onLoad = () => {
    setFrameHeight(frameRef.current.contentWindow.document.body.scrollHeight);
  };

  return (
    <div className="flex flex-col items-center">
      <span className="secondary-regular font-weight-500 light-60 mb-[0.5rem]">
        Subject
      </span>
      <div className="text-[1.5rem]-25rem font-weight-500 light-40 mb-[1.5rem]">
        {subject}
      </div>
      <iframe
        style={{ pointerEvents: 'none' }}
        ref={frameRef}
        onLoad={onLoad}
        className="w-full"
        seamless="seamless"
        scrolling="no"
        height={`${frameHeight}px`}
        title={subject}
        srcDoc={templatePreview}
      />
    </div>
  );
}

export default TemplatePreview;

import { apiClient, requireAuthorizationHeaders } from '@/lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from '@/lib/stringify';
import { stringify } from 'qs';
export * from './helpers';

export const plainPreviewReportPdf = ({
  reportId,
  assetId,
  renderPdfHost: render_pdf_host,
}: {
  assetId: string;
  reportId: string;
  renderPdfHost: string;
}) => {
  return apiClient.get<Blob>(
    `/api/core/assets/${assetId}/report_builder/reports/${reportId}/preview_pdf${stringify(
      {
        render_pdf_host,
      },
      DEFAULT_STRINGIFY_OPTIONS,
    )}`,
    {
      responseType: 'blob',
      headers: requireAuthorizationHeaders({}),
    },
  );
};

import React from 'react';
import {
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { IUser } from 'types/User';

interface Props {
  user: IUser & { advisorIds: number[] };
}

function AccessCell({ user }: Props) {
  return (
    <>
      {user.role.name !== 'Advisor' && (
        <>
          <div className="light-60">Assets</div>
          {currentUserAllowedTo('view', FUND_PORTAL_PRODUCT_NAME) && (
            <div className="light-60">Funds</div>
          )}
        </>
      )}
      {user.role.name === 'Advisor' && (
        <div style={{ whiteSpace: 'nowrap' }} className="light-60">
          Advisees
        </div>
      )}

      {user?.advisorIds.length > 0 && (
        <div className="light-60">
          {user?.advisorIds.length > 1 ? 'Advisors' : 'Advisor'}
        </div>
      )}
    </>
  );
}

export default AccessCell;

import { Icon, Tooltip } from 'stories/index';

export default function ExternalUsersIcon({
  externalUsersLen,
}: {
  externalUsersLen: number;
}) {
  return (
    <Tooltip mainText={`This asset has ${externalUsersLen} external users`}>
      <div className="flex items-center gap-tw-1 text-neutral-450">
        <Icon iconName="members" />
        <p className="secondary-regular">{externalUsersLen}</p>
      </div>
    </Tooltip>
  );
}

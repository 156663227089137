import { cn } from '@/shared/lib/css/cn';
import { UserInfo } from '@/entities/impersonation/ui/Impersonation';
import { LEFT_SIDEBAR_Z_INDEX } from 'bundles/Shared/constants';
import pluralize from 'pluralize';
import { IconButton, LinkButton, Popover, SreAvatar } from 'stories';
import { IUser } from 'types/User';
import { IUserTableColumnsActions } from '../userTableColumns';

interface Props {
  user: IUser;
  actions: IUserTableColumnsActions;
  onToggleExpand: (userId: IUser['id']) => void;
  isUserExpanded: (userId: IUser['id']) => boolean;
}

function BasicInfoCell({
  user,
  actions,
  isUserExpanded,
  onToggleExpand,
}: Props) {
  const isExpanded = isUserExpanded(user.id);

  const userTagsList = user.tags.length !== 0 && (
    <div className="flex flex-wrap">
      {user.tags.map(({ name }) => (
        <div className="mb-[0.25rem] mr-xs items-center" key={name}>
          <div className="document-type-badge-outline for-tag">
            <span className="sre-icon-tag mr-03125rem" />
            {name}
          </div>
        </div>
      ))}
    </div>
  );

  const mainInfo = (
    <div className="flex items-center justify-between gap-tw-4">
      <div className="flex items-center gap-tw-2">
        <SreAvatar
          className="custom-green avatar-xs"
          width="2.625rem"
          height="2.625rem"
          text={user.initials}
        />
        <LinkButton onClick={() => actions.edit(user, 'info')}>
          {user.fullName}
        </LinkButton>
      </div>
      <IconButton
        onClick={() => onToggleExpand(user.id)}
        variant="secondary"
        iconName={isExpanded ? 'top' : 'bottom'}
      />
    </div>
  );

  const investmentEntitiesInfo = user.investmentEntities != null &&
    user.investmentEntities.length > 0 && (
      <Popover
        classes={{
          spanContainer: 'self-start',
        }}
        placement="top-start"
        template={
          <>
            <div className="label-regular text-neutral-500">
              <span className="sre-icon-businessman" />
              <span> Entities: </span>
            </div>
            <div className="label-regular mt-tw-2 text-neutral-850">
              {user.investmentEntities.map((ie) => ie.name).join(', ')}
            </div>
          </>
        }
      >
        <span className="label-regular cursor-pointer text-neutral-850 underline decoration-dashed">
          {`${user.investmentEntities.length} ${pluralize(
            'Entity',
            user.investmentEntities.length,
          )}`}
        </span>
      </Popover>
    );

  return (
    <>
      <div className="flex flex-col gap-tw-4">
        {mainInfo}
        {investmentEntitiesInfo}
        {userTagsList}
      </div>

      <div
        className={cn('expanded-table-column', {
          hidden: !isExpanded,
        })}
        style={{ zIndex: LEFT_SIDEBAR_Z_INDEX - 1 }}
      >
        <div className="flex w-full flex-col gap-tw-4">
          {mainInfo}
          <div className="flex flex-col gap-tw-2">
            <UserInfo user={user} />
          </div>
          {investmentEntitiesInfo}
          {userTagsList}
        </div>
      </div>
    </>
  );
}

export default BasicInfoCell;

import { REFERENCE_FIELD_NOTE } from 'bundles/Shared/entities/formula/config';
import {
  isSreUnitTypeNameValid,
  SRE_UNIT_TYPE_LABEL_FIELD_NOTE,
} from 'bundles/Shared/entities/sreUnitType';
import { LabelFieldController } from '@/shared/ui/LabelFieldController';
import { Field, Input } from '@/stories';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { FieldsContainer } from 'stories/Field/Field';

export const UNIT_TYPE_SCHEMA = yup.object().shape({
  reference: yup.string().required().test(isSreUnitTypeNameValid),
  name: yup.string().required(),
});

export type UnitTypeForm = yup.InferType<typeof UNIT_TYPE_SCHEMA>;

export const UnitTypeFields = () => {
  const { control, register } = useFormContext<UnitTypeForm>();
  return (
    <FieldsContainer>
      <Field labelText="Label" note={SRE_UNIT_TYPE_LABEL_FIELD_NOTE}>
        <LabelFieldController
          name="name"
          control={control}
          referenceFieldName="reference"
        />
      </Field>
      <Field labelText="Reference" note={REFERENCE_FIELD_NOTE}>
        <Input type="text" placeholder="" {...register('reference')} />
      </Field>
    </FieldsContainer>
  );
};

import { Icon } from 'stories';

export const NO_INVOICES_NOTIFICATION = (
  <>
    <div className="header4-regular">No Invoices yet</div>
    <div className="body-regular mb-tw-4 w-1/2 text-center">
      Click the
      <span className="body-semibold"> “Upload Invoice”</span>
      button to upload invoices, you can also receive invoices automatically by
      enabling
      <span className="body-semibold"> Auto-uploading</span>
    </div>
  </>
);

export const NO_REQUITSION_IN_VERIFY = (
  <div className="flex justify-between px-tw-4 pb-tw-4 pt-tw-2 ">
    <div className="flex flex-1 flex-col gap-tw-1">
      <div className="label-regular text-light-90">Select from Requisition</div>
      <div>No Requisitions Available</div>
    </div>
    <Icon iconName="lock" />
  </div>
);

import { CapitalInvestment } from '@/entities/return';
import { ObjectType } from 'bundles/UserManagement/components/EditUserModalComponents/types';
import { IGeneralLedger } from 'types/GeneralLedger';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { LegalEntity } from '@/entities/core/legalEntity';
import { IUser } from 'types/User';

export enum TReturnInvestmentTransactionDirection {
  CONTRIBUTION = 'contribution',
  DISTRIBUTION = 'distribution',
  ACCRUED = 'accrued',
}

export enum TReturnInvestmentLineItemCategory {
  PREFERRED = 'preferred',
  EXCESS_CASH = 'excess_cash',
  REFINANCE = 'refinance',
  SALE = 'sale',
  RETURN_OF_CAPITAL = 'return_of_capital',
  LIQUIDATION = 'liquidation',
}

interface IReturnInvestmentObjectData {
  id: number;
  name: string;
  objectableType: ObjectType;
  slug: string;
  pictureUrl: string | null;
  smallPictureUrl: string | null;
}

export interface IReturnInvestmentObject {
  id: string;
  object: IReturnInvestmentObjectData;
  totalInvestments: number;
  legalEntities: Pick<LegalEntity, 'id' | 'code' | 'name'>[];
  totalCommitments: number; // deprecated
  totalCommitmentsAmountCents: number;
  totalContributions: number; // deprecated
  totalContributionsAmountCents: number;
  totalDistributions: number;
  yield: number;
}

export interface IReturnInvestmentEntity
  extends Omit<IInvestmentEntity, 'code'> {
  createdAt: number | null;
  updatedAt: UnixTime;
  createdBy: Partial<IUser> | null;
  userIds: IUser['id'][];
  linkedMembersCount: number;
}

export type TReturnLegalEntity = Pick<
  LegalEntity,
  'id' | 'code' | 'name' | 'source'
>;

export interface ICapitalTransactionSource {
  id: number;
  generalLedger: Pick<
    IGeneralLedger,
    | 'id'
    | 'accNumber'
    | 'accTitle'
    | 'excluded'
    | 'lineItemsCount'
    | 'reportFinancialCategoryId'
  >;
  kind: TReturnInvestmentTransactionDirection;
  legalEntity: LegalEntity;
}

export interface IGeneralLedgerTransactionSource extends IGeneralLedger {
  legalEntity: LegalEntity;
}

export interface IReturnInvestmentObjectScreenData {
  id: string;
  object: IReturnInvestmentObjectData;
  capitalInvestments: CapitalInvestment[];
  legalEntities: (Pick<LegalEntity, 'id' | 'name'> & {
    isPublished: boolean;
  })[];
  capitalTransactionSources: ICapitalTransactionSource[];
}

export interface ITransactionDirectionData {
  id: number;
  amount: string;
  createdAt: number;
  createdBy: Pick<IUser, 'id' | 'email' | 'fullName'>;
  date: string;
  generalLedger: IGeneralLedger;
  investmentEntity: IReturnInvestmentEntity;
  legalEntity: LegalEntity;
  type: string;
}

export interface IReturnInvestmentObjectDetails {
  id: string;
  capitalInvestments: CapitalInvestment[];
  capitalTransactionSources: unknown[];
  contributions: ITransactionDirectionData[];
  distributions: (ITransactionDirectionData & { kind: string })[];
  investmentEntities: IReturnInvestmentEntity[];
  legalEntities: LegalEntity[];
  object: IReturnInvestmentObjectData;
  totalCommitments: number;
  totalContributions: number;
  totalDistributions: number;
  yield: number;
}

export type TPublishableLegalEntity = Pick<LegalEntity, 'id'> & {
  isPublished?: boolean;
};

import { downloadSharedFile } from '@/lib/sharedFile';
import { ISharedDocument } from '@/types/SharedDocument';

export const generateLinkBySharedFileId = (
  id: string,
  extension: string,
  isMobile: boolean,
) => {
  return `/shared-files/${id}?disposition=${
    isMobile && extension == 'pdf' ? 'attachment' : 'inline'
  }`;
};

export const generateLinkPropsBySharedFile = (
  row: ISharedDocument,
  isMobile: boolean,
) => {
  const { id, extension } = row;
  const downloadable = isMobile && extension == 'pdf';

  if (downloadable) {
    return {
      href: '#',
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        downloadSharedFile(row)
          .then(() => toastr.success('File has been downloaded'))
          .catch(() => toastr.error('Failed to download file'));
      },
    };
  }

  return {
    href: `/shared-files/${id}?disposition=inline`,
    target: '_blank',
    rel: 'noreferrer',
  };
};

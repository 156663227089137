import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories';
import { SortableHandle } from 'react-sortable-hoc';
import { PipelineField } from 'types/PipelineField';
import styles from './fieldCard.module.scss';

interface Props {
  field: PipelineField;
  className?: string;
  onDelete: (field: PipelineField) => void;
  onEdit: (field: PipelineField) => void;
}

export const FieldCard = ({ field, className, onDelete, onEdit }: Props) => {
  const { label, main, value } = field;

  const DragHandle = SortableHandle(() => (
    <IconButton
      className={cn(styles.icon_move, 'mr-s')}
      variant="white"
      iconName="move"
      aria-hidden="true"
    />
  ));

  return (
    <div className={cn(main ? styles.fieldWrapperMain : styles.fieldWrapper)}>
      <div className={cn(styles.field, className)}>
        <div className={cn('text-[1rem] text-center', styles.fieldTitle)}>
          {main ? (
            <h1 className="dark-60 font-44 text-center text-green">{value}</h1>
          ) : (
            value
          )}
        </div>
        <div className={cn('text-center', styles.description)}>{label}</div>
        <div className={styles.actions}>
          <div className="semi-transparent-items w-full flex justify-between">
            <DragHandle />
            <div>
              <IconButton
                className="mr-s"
                iconName="edit"
                variant="white"
                onClick={() => {
                  onEdit(field);
                }}
              />
              <IconButton
                iconName="trash"
                variant="white"
                onClick={() => {
                  onDelete(field);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldCard;

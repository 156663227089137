import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { cn } from '@/shared/lib/css/cn';
import { emailPattern } from 'lib/regexpPatterns';
import SreToggle from 'bundles/Shared/components/SreToggle';
import http from 'lib/http';
import userFormDefaultValues from 'bundles/UserManagement/components/helpers/userFormDefaultValues';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { IExtendedUser } from 'bundles/UserManagement/components/EditUserModalComponents/types';
import { clipboardWriteText } from '@/shared/lib/browser/clipboardApi';
import { Icon } from 'stories/index';

type Fields = ReturnType<typeof userFormDefaultValues>;

export const ReadOnlyBasicInfo = ({ user }: { user: IExtendedUser }) => {
  const handleCopy = (value: string) => {
    clipboardWriteText(value);
  };
  return (
    <div className="flex flex-col gap-tw-4">
      <div className="flex flex-col gap-tw-2 text-neutral-850">
        <span className="label-regular">First Name</span>
        <p className="inline-regular rounded-[8px] border-2 border-solid border-neutral-150 bg-neutral-150 px-tw-4 py-tw-3 shadow-z-020">
          {user.firstName}
        </p>
      </div>

      <div className="flex flex-col gap-tw-2 text-neutral-850">
        <span className="label-regular">Last Name</span>
        <p className="inline-regular rounded-[8px] border-2 border-solid border-neutral-150 bg-neutral-150 px-tw-4 py-tw-3 shadow-z-020">
          {user.lastName}
        </p>
      </div>

      <div className="flex flex-col gap-tw-2 text-neutral-850">
        <span className="label-regular">E-Mail</span>
        <div className="inline-regular flex items-center justify-between rounded-[8px] border-2 border-solid border-neutral-150 bg-neutral-150 px-tw-4 py-tw-3 shadow-z-020">
          <p className="inline-regular">{user.email}</p>
          <Icon
            className="text-neutral-500 transition-colors hover:text-neutral-600"
            iconName="copyAlt"
            onClick={() => handleCopy(user.email)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-tw-2 text-neutral-850">
        <span className="label-regular">Phone number</span>
        <p className="inline-regular rounded-[8px] border-2 border-solid border-neutral-150 bg-neutral-150 px-tw-4 py-tw-3 shadow-z-020">
          {user.phone ?? '-'}
        </p>
      </div>

      <div className="flex flex-col gap-tw-2 text-neutral-850">
        <span className="label-regular">Memo</span>
        <p className="inline-regular min-h-[56px] rounded-[8px] border-2 border-solid border-neutral-150 bg-neutral-150 px-tw-4 py-tw-3 shadow-z-020">
          {user.info ?? '-'}
        </p>
      </div>
    </div>
  );
};

const BasicInfo = ({
  fields,
  setValue,
  register,
  errors,
}: {
  fields: Fields;
  setValue: (name: keyof Fields, value: keyof Fields[keyof Fields]) => void;
  register: UseFormRegister<Fields>;
  errors: FieldErrors<Fields>;
}) => {
  const [phoneNumberExtension, togglePhoneNumberExtension] = useState(false);
  const [existUserEmails, setExistUserEmails] = useState<string[]>();

  useEffect(() => {
    http
      .get('/users/emails')
      .then((data) => data.json() as Promise<string[]>)
      .then((responseData) => setExistUserEmails(responseData));
  }, []);

  const setPhoneNumberExtension = () => {
    togglePhoneNumberExtension(!phoneNumberExtension);
  };

  useEffect(() => {
    if (fields.phone?.includes('x-')) {
      setPhoneNumberExtension();
    }
  }, []);

  return (
    <div className="flex flex-col gap-tw-4">
      <label className="flex flex-col gap-tw-2">
        <span>First Name</span>
        <input
          placeholder="Enter First Name"
          {...register('firstName', { required: 'Name is required' })}
          className={cn(
            'inline-regular rounded-[8px] border-2 border-solid border-neutral-250 px-tw-4 py-tw-3 shadow-z-020',
            {
              'is-invalid': errors.firstName,
            },
          )}
        />
        <div className="invalid-feedback">{errors.firstName?.message}</div>
      </label>

      <label className="flex flex-col gap-tw-2">
        <span>Last Name</span>
        <input
          placeholder="Enter Last Name"
          {...register('lastName', { required: 'Name is required' })}
          className={cn(
            'inline-regular rounded-[8px] border-2 border-solid border-neutral-250 px-tw-4 py-tw-3 shadow-z-020',
            {
              'is-invalid': errors.lastName,
            },
          )}
        />
        <div className="invalid-feedback">{errors.lastName?.message}</div>
      </label>

      <label className="flex flex-col gap-tw-2">
        <span>E-Mail</span>
        <input
          placeholder="Enter E-Mail Address"
          {...register('email', {
            required: 'Email is required',
            pattern: { value: emailPattern, message: 'Invalid email' },
            validate: (value) =>
              existUserEmails?.length === 0 ||
              !existUserEmails
                ?.map((email) => email.toLowerCase())
                .includes(value.toLowerCase()) ||
              'This email address is already in use. Please choose another or contact your administrator.',
          })}
          className={cn(
            'inline-regular rounded-[8px] border-2 border-solid border-neutral-250 px-tw-4 py-tw-3 shadow-z-020',
            {
              'is-invalid': errors.email,
            },
          )}
        />
        <div className="invalid-feedback">{errors.email?.message}</div>
      </label>

      <label className="flex flex-col gap-tw-2">
        <span>Phone Number</span>
        <NumberFormat
          format={`(###) ###-####${phoneNumberExtension ? ' x-####' : ''}`}
          mask="_"
          value={fields.phone}
          onChange={(e) => setValue('phone', e.target.value)}
          className="inline-regular rounded-[8px] border-2 border-solid border-neutral-250 px-tw-4 py-tw-3 shadow-z-020"
          placeholder="Enter Phone Number"
        />

        {fields.phone?.length > 0 && (
          <SreToggle
            label="Add Extension"
            onToggle={setPhoneNumberExtension}
            checked={phoneNumberExtension}
          />
        )}
      </label>

      <label className="flex flex-col gap-tw-2">
        <span>Memo</span>
        <textarea
          maxLength={256}
          placeholder="Enter ..."
          {...register('info')}
          className={cn(
            'inline-regular max-h-[240px] min-h-[56px] rounded-[8px] border-2 border-solid border-neutral-250 px-tw-4 py-tw-3 shadow-z-020',
            { 'is-invalid': errors.info },
          )}
        />
        <div className="invalid-feedback">{errors.info?.message}</div>
      </label>
    </div>
  );
};

export default BasicInfo;

import { useCapitalUnitPricesByInvestmentClass } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import {
  InvestmentObjectShow,
  InvestmentObjectUnitPrice,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { formatDate } from '@/shared/lib/formatting/dates';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import {
  Button,
  CurrencyFormatter,
  IconButton,
  Modal,
  ModalHeaderWithSubtitle,
  PercentFormatter,
} from 'stories/index';
import { convertCentsToDollars } from '@/shared/lib/converters';

export function EditUnitPricesModal({
  onClose,
  capitalInvestmentObject,
  onAdjustments,
  onUpdateUnitPrice,
  onDeleteUnitPrice,
  onAddUnitPrice,
  investmentClass,
  preferredReturn,
  onPreferredReturnUpdate,
  disabledButtons,
}: {
  capitalInvestmentObject: InvestmentObjectShow;
  onAdjustments: VoidAsyncFunction;
  onUpdateUnitPrice: (
    id: InvestmentObjectUnitPrice['id'],
    prevId: InvestmentObjectUnitPrice['id'] | null,
  ) => void;
  onDeleteUnitPrice: (id: InvestmentObjectUnitPrice['id']) => void;
  onAddUnitPrice: VoidAsyncFunction;
  investmentClass: InvestmentObjectUnitPrice['investmentClass'];
  preferredReturn?: number;
  onPreferredReturnUpdate?: () => void;
  disabledButtons?: boolean;
} & DialogProps) {
  const { capitalUnitPricesPartitionByInvestmentClass } =
    useCapitalUnitPricesByInvestmentClass({
      investmentClass,
    });

  const [unitPrices] = capitalUnitPricesPartitionByInvestmentClass;

  const [baseUnitPrice] = unitPrices;
  const hasOneThanOneUnitPrices = unitPrices.length > 1;

  const handleUpdateAdjustmentDate = (
    unitPriceId: InvestmentObjectUnitPrice['id'],
    idx: number,
  ) => {
    const prevUnitPriceId =
      idx === 0 ? baseUnitPrice.id : unitPrices[idx - 1].id;
    onUpdateUnitPrice(unitPriceId, prevUnitPriceId);
  };

  return (
    <Modal
      size="500"
      toggle={onClose}
      classes={{
        body: 'flex flex-col gap-tw-4',
      }}
      header={
        <ModalHeaderWithSubtitle
          title={`Class ${capitalize(investmentClass)}`}
          subtitle={capitalInvestmentObject.object.name}
          order="subtitle-title"
        />
      }
    >
      <div className="flex flex-col gap-tw-2">
        <p className="inline-semibold text-neutral-900">
          Preferred Return Class {capitalize(investmentClass)}
        </p>
        <div className="flex justify-between rounded-[16px] bg-neutral-000 p-tw-4">
          <div className="flex items-center justify-between gap-tw-4">
            <div className="flex w-[140px] flex-col gap-tw-2">
              <PercentFormatter
                classes={{ value: 'text-neutral-900' }}
                value={preferredReturn}
              />
            </div>
          </div>

          <IconButton
            disabled={disabledButtons}
            variant="secondary"
            onClick={onPreferredReturnUpdate}
            iconName="penSmall"
          />
        </div>
      </div>
      {baseUnitPrice && (
        <div className="flex flex-col gap-tw-2">
          <p className="inline-semibold text-neutral-900">Base Price</p>
          <div className="flex justify-between rounded-[16px] bg-neutral-000 p-tw-4">
            <div className="flex items-center justify-between gap-tw-4">
              <div className="flex w-[140px] flex-col gap-tw-2">
                <p className="inline-regular text-neutral-500">Price</p>
                <CurrencyFormatter
                  classes={{ value: 'text-neutral-900' }}
                  value={convertCentsToDollars(baseUnitPrice.priceCents)}
                />
              </div>
              <div className="flex w-[140px] flex-col gap-tw-2">
                <p className="inline-regular text-neutral-500">
                  Start of investment
                </p>
                <p className="text-neutral-900">
                  {formatDate(baseUnitPrice.date as DateString, 'MMM DD, YYYY')}
                </p>
              </div>
            </div>

            <IconButton
              disabled={baseUnitPrice.locked || disabledButtons}
              variant="secondary"
              onClick={() => onUpdateUnitPrice(baseUnitPrice.id, null)}
              iconName="penSmall"
            />
          </div>
        </div>
      )}
      {hasOneThanOneUnitPrices && (
        <div className="flex flex-col gap-tw-2">
          <p className="inline-semibold text-neutral-900">Adjustments</p>
          <div className="flex flex-col gap overflow-hidden rounded-[16px]">
            {unitPrices.slice(1).map((unitPrice, idx) => (
              <div className="flex flex-col gap-tw-2" key={idx}>
                <div className="flex items-center justify-between bg-neutral-000 p-tw-4">
                  <div className="flex items-center justify-between gap-tw-4">
                    <div className="flex w-[140px] flex-col gap-tw-2">
                      <CurrencyFormatter
                        classes={{ value: 'text-neutral-900 ' }}
                        value={unitPrice.priceCents / 100}
                      />
                    </div>
                    <div className="flex w-[140px] flex-col gap-tw-2">
                      <p className="text-neutral-500">
                        {formatDate(
                          unitPrice.date as DateString,
                          'MMM DD, YYYY',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-tw-2">
                    <IconButton
                      disabled={unitPrice.locked || disabledButtons}
                      variant="secondary"
                      onClick={() => onDeleteUnitPrice(unitPrice.id)}
                      iconName="trash"
                    />
                    <IconButton
                      disabled={unitPrice.locked || disabledButtons}
                      variant="secondary"
                      onClick={() =>
                        handleUpdateAdjustmentDate(unitPrice.id, idx)
                      }
                      iconName="penSmall"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {unitPrices.length === 0 ? (
        <Button
          size="xs"
          disabled={disabledButtons}
          onClick={onAddUnitPrice}
          className="w-max"
          iconPosition="left"
          variant="secondary"
        >
          Add Unit Price
        </Button>
      ) : (
        <Button
          size="xs"
          disabled={disabledButtons}
          onClick={onAdjustments}
          className="w-max"
          iconPosition="left"
          variant="secondary"
        >
          Add Adjustments
        </Button>
      )}
    </Modal>
  );
}

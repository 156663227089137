import React from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { ISovcRowUpdate } from 'bundles/REconcile/actions/sovcConfig';
import { TReconcileSettingsJCC } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntitiesSettings';
import {
  DEFAULT_CODE_FIELD,
  DEFAULT_NAME_FIELD,
  getJccsSelectField,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IFieldsProps } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

interface Props extends IFieldsProps<ISovcRowUpdate> {
  jccList: TReconcileSettingsJCC[];
  validate: Partial<ISovcRowUpdate>;
  setValidate: React.Dispatch<React.SetStateAction<Partial<ISovcRowUpdate>>>;
}

function SovcFields({
  jccList,
  setFormData,
  formData,
  validate,
  setValidate,
}: Props) {
  return (
    <FormComponent
      styles={{
        wrapper__item: 'mb-0',
      }}
      formItems={[
        DEFAULT_CODE_FIELD,
        DEFAULT_NAME_FIELD,
        getJccsSelectField(jccList),
      ]}
      formData={formData}
      setFormData={setFormData}
      validate={validate}
      setValidate={setValidate}
    />
  );
}

export default SovcFields;

import { SettingsFormModal } from 'bundles/Shared/components/SettingsModal/SettingsModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  UnitTypeFields,
  UnitTypeForm,
} from 'bundles/Shared/entities/sreUnitType/ui/UnityTypeModal';

export const useSreUnitTypeSettingsModal = () => {
  const { openModal } = useModal();

  return (
    args: UnitTypeForm = {
      name: '',
      reference: '',
    },
  ) =>
    openModal(SettingsFormModal<UnitTypeForm>, {
      formProps: {
        defaultValues: args,
      },
      fields: <UnitTypeFields />,
    });
};

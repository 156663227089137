import React from 'react';
import { Button, Icon, IconButton, Popover, SearchInput } from '@/stories';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import { mapItemToOption } from '@/shared/lib/listHelpers';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { cn } from '@/shared/lib/css/cn';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ManageTagsModal } from '@/widgets/report/manageTags';
import {
  UNTAGGED_FORMULA_TAG,
  UntaggedFormulaTag,
} from 'bundles/Shared/entities/formula/config';
import { orderBy } from 'lodash-es';
import {
  EntityTag,
  TagEntity,
  useEntityTagsQuery,
} from 'bundles/Shared/entities/tag';

interface Props {
  onChange: (tags: (EntityTag | UntaggedFormulaTag)[]) => void;
  value: (EntityTag | UntaggedFormulaTag)[];
  entity: TagEntity;
  panel?: React.ReactNode;
}

export function EntityTagFilterDropdown({
  value,
  onChange,
  entity,
  panel,
}: Props) {
  const { tags } = useEntityTagsQuery(entity);
  const tagsWithUntagged = [...tags, UNTAGGED_FORMULA_TAG];
  const { inputProps, filteredItems: filteredTags } = useItemsFilterByText(
    tags,
    'name',
  );
  const tagsOptions = [
    UNTAGGED_FORMULA_TAG,
    ...orderBy(filteredTags, 'name'),
  ].map((tag) => mapItemToOption(tag, 'name'));

  return (
    <Popover
      trigger="click"
      offset={DEFAULT_DROPDOWN_OFFSET}
      placement="bottom-start"
      hiddenArrow
      className="min-w-[150px] p-tw-4"
      maxWidth="max-content"
      template={
        <div className="flex flex-col gap-tw-4">
          <div className="flex items-center gap-tw-2">
            <SearchInput
              placeholder="Search by Tag Name"
              size="s"
              {...inputProps}
            />
            {panel}
          </div>
          <SplittedCheckList
            onChange={(options) => {
              onChange(
                options.map(
                  (tag) => tagsWithUntagged.find((t) => t.id === tag.value)!,
                ),
              );
            }}
            items={tagsOptions}
            value={value.map((tag) => mapItemToOption(tag, 'name'))}
          />
        </div>
      }
    >
      {({ triggered }) => (
        <Button
          variant="secondary"
          size="s"
          iconName="bottom"
          iconPosition="right"
          iconClasses={cn(triggered && 'text-neutral-700')}
          className={cn(triggered && 'bg-neutral-200 text-neutral-800')}
        >
          <Icon iconName="tags" />
          Tags{' '}
          {value.length > 0 && (
            <span
              className={cn(
                'secondary-regular rounded bg-neutral-150 px-tw-1 text-neutral-650',
                triggered && 'bg-neutral-300',
              )}
            >
              {value.length}
            </span>
          )}
        </Button>
      )}
    </Popover>
  );
}

import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { uniqBy, orderBy } from 'lodash-es';
import { configChartColors } from 'lib/amcharts/utils';

interface IDeal {
  name: string;
  category: string;
  start_date: number;
  end_date: number;
}

interface Props {
  deals: IDeal[];
}

const BAR_HEIGHT = 50; // pixels

export function GanttChart({ deals }: Props) {
  const colors = {
    Pursuit: '#003f5c',
    Underwriting: '#20617c',
    Negotiation: '#60acb9',
    'Pre-Contract': '#37ac94',
    'Pre-Dev': '#1b976c',
    Construction: '#128141',
    Operational: '#3f869b',
  };
  useLayoutEffect(() => {
    const root = am5.Root.new('gantt-chart');
    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX', 'openValueX'],
    });
    root.setThemes([am5themesAnimated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    configChartColors(chart, Object.values(colors));

    const buildSettings = (category) => ({
      columnSettings: {
        fill: am5.color(colors[category]),
      },
    });

    const data = deals.map((deal) => ({
      ...deal,
      ...buildSettings(deal.category),
    }));

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'name',
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );
    const sortedData = orderBy(deals, 'end_date', 'desc');
    const uniqSortedData = uniqBy(
      sortedData.map((deal) => ({ name: deal.name })),
      'name',
    );

    // Reversing data as there conflicts with ascending order
    yAxis.data.setAll(uniqSortedData.reverse());

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        openValueXField: 'start_date',
        valueXField: 'end_date',
        categoryYField: 'name',
        sequencedInterpolation: true,
      }),
    );

    series.columns.template.setAll({
      templateField: 'columnSettings',
      strokeOpacity: 0,
      tooltipText:
        '[bold]{category}[/]: {name}\n[bold]{openValueX}[/] - [bold]{valueX}[/]',
    });

    series.data.setAll(data);
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        useDefaultMarker: true,
        x: am5.p50,
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
      }),
    );
    legend.data.setAll(
      Object.entries(colors).map(([name, color]) => ({
        name,
        color: am5.color(color),
      })),
    );

    // Add scrollbars
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, { orientation: 'horizontal' }),
    );

    series.appear();
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="panel-body">
      <div
        id="gantt-chart"
        style={{ width: '100%', height: `${deals.length * BAR_HEIGHT}px` }}
      />
    </div>
  );
}

export default GanttChart;

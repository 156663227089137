import { cn } from '@/shared/lib/css/cn';
import { createMonthPeriodItem } from '@/stories/FlexibleFilterByPeriods/utils';
import { useDefaultPeriodItemsFromPeriodType } from 'bundles/REport/components/financials/hooks';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import { ComponentProps, useMemo } from 'react';
import {
  IPeriodItem,
  PossiblePeriodsGroupedByYear,
} from 'stories/FlexibleFilterByPeriods/types';
import { FlexibleFilterByPeriods } from 'stories/index';
import { resolveDefaultPeriodItems } from '../utils';
import * as Util from './utils';

interface Props
  extends Pick<
      ComponentProps<typeof FlexibleFilterByPeriods>,
      | 'filterByPeriodsType'
      | 'onUpdatePeriodItems'
      | 'onUpdateFilterByPeriodsType'
      | 'updatedStyles'
      | 'periodItems'
      | 'noPossiblePeriodsCase'
      | 'buttonProps'
    >,
    Required<
      Pick<ComponentProps<typeof FlexibleFilterByPeriods>, 'possiblePeriods'>
    > {
  initialFrom?: IPeriodItem[];
  initialTo?: IPeriodItem[];
}

function FromToFilter({
  periodItems,
  possiblePeriods,
  filterByPeriodsType,
  onUpdatePeriodItems,
  onUpdateFilterByPeriodsType,
  initialFrom,
  initialTo,
  updatedStyles,
  noPossiblePeriodsCase,
  buttonProps,
}: Props) {
  const fromPeriodItem = periodItems?.[0];
  const toPeriodItem = periodItems.at(-1);

  const possibleFromPeriods = useMemo(() => {
    if (periodItems.length > 1) {
      const idxToSlice = Util.findIndexByPeriod(
        possiblePeriods,
        toPeriodItem?.period ?? '',
      );
      return Util.slicePossiblePeriodsTo(possiblePeriods, idxToSlice);
    }
    return possiblePeriods;
  }, [possiblePeriods, periodItems]);

  const defaultFromPeriodItems =
    initialFrom ?? useDefaultPeriodItemsFromPeriodType(possibleFromPeriods);

  const possibleToPeriods = useMemo(() => {
    if (periodItems.length >= 1) {
      const idxToSlice = Util.findIndexByPeriod(
        possiblePeriods,
        fromPeriodItem.period,
      );
      return Util.slicePossiblePeriodsFrom(possiblePeriods, idxToSlice);
    }
    return possiblePeriods;
  }, [possiblePeriods, periodItems]);

  const defaultToPeriodItems =
    initialTo ?? useDefaultPeriodItemsFromPeriodType(possibleFromPeriods);

  const handleSetFromPeriod = (newPeriodItems: IPeriodItem[]) => {
    const [newFromPeriodItem] = newPeriodItems;
    const idxTo = Util.findIndexByPeriod(
      possiblePeriods,
      toPeriodItem?.period ?? '',
    );
    const idxFrom = Util.findIndexByPeriod(
      possiblePeriods,
      newFromPeriodItem.period,
    );
    const updatedPeriods = Util.slicePossiblePeriodsFromAndTo(
      possiblePeriods,
      idxFrom,
      idxTo,
    );
    const updatedPeriodItems = updatedPeriods.map(createMonthPeriodItem);
    onUpdatePeriodItems(updatedPeriodItems);
  };

  const handleSetToPeriod = (newPeriodItems: IPeriodItem[]) => {
    const latestToPeriodItem = newPeriodItems.at(-1);
    const idxFrom = Util.findIndexByPeriod(
      possiblePeriods,
      fromPeriodItem.period,
    );
    const idxTo = Util.findIndexByPeriod(
      possiblePeriods,
      latestToPeriodItem?.period ?? '',
    );
    const updatedPeriods = Util.slicePossiblePeriodsFromAndTo(
      possiblePeriods,
      idxFrom,
      idxTo,
    );
    const updatedPeriodItems = updatedPeriods.map(createMonthPeriodItem);
    onUpdatePeriodItems(updatedPeriodItems);
  };

  const handleSetDefaultPeriodItems = () => {
    const groupedPossiblePeriods = groupBy(possiblePeriods, (period) =>
      dayjs(period).year(),
    ) as PossiblePeriodsGroupedByYear;

    const defaultPeriodItemsForTotalTable = resolveDefaultPeriodItems({
      periodsType: 'total',
      groupedPeriods: groupedPossiblePeriods,
    });

    onUpdatePeriodItems(defaultPeriodItemsForTotalTable);
  };

  const sharedProps: Pick<
    ComponentProps<typeof FlexibleFilterByPeriods>,
    | 'isSingleSelection'
    | 'filterByPeriodsType'
    | 'onUpdateFilterByPeriodsType'
    | 'customSetDefaultPeriodItemsHandler'
    | 'updatedStyles'
    | 'noPossiblePeriodsCase'
    | 'buttonProps'
  > = {
    updatedStyles,
    isSingleSelection: true,
    filterByPeriodsType,
    onUpdateFilterByPeriodsType,
    customSetDefaultPeriodItemsHandler: handleSetDefaultPeriodItems,
    noPossiblePeriodsCase,
    buttonProps,
  };

  return (
    <div className="flex items-center justify-center gap-tw-2">
      <span
        className={cn(
          updatedStyles ? 'text-[14px] font-medium' : 'font-semibold',
          'text-neutral-500',
        )}
      >
        From
      </span>
      <FlexibleFilterByPeriods
        periodItems={periodItems.length ? [fromPeriodItem] : []}
        possiblePeriods={possibleFromPeriods}
        onUpdatePeriodItems={handleSetFromPeriod}
        defaultPeriodItems={defaultFromPeriodItems}
        {...sharedProps}
      />
      <span
        className={cn(
          updatedStyles ? 'text-[14px] font-medium' : 'font-semibold',
          'text-neutral-500',
        )}
      >
        To
      </span>
      <FlexibleFilterByPeriods
        periodItems={periodItems.length > 1 ? [periodItems.at(-1)!] : []}
        possiblePeriods={possibleToPeriods}
        onUpdatePeriodItems={handleSetToPeriod}
        defaultPeriodItems={defaultToPeriodItems}
        {...sharedProps}
      />
    </div>
  );
}

export default FromToFilter;

import {
  DEFAULT_MANUAL_VARIABLE_FORM,
  MANUAL_VARIABLE_SCHEMA,
  ReportManualVariableForm,
} from '@/entities/report/manualVariable';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { LabelFieldController } from '@/shared/ui/LabelFieldController';
import {
  Button,
  Field,
  Input,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
  Textarea,
} from '@/stories';
import { yupResolver } from '@hookform/resolvers/yup';
import { SharedSelectController } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import {
  REFERENCE_FIELD_NOTE,
  VARIABLE_VALUE_TYPE_OPTIONS,
} from 'bundles/Shared/entities/formula/config';
import { PERIOD_AGGREGATION_STRATEGIES_OPTIONS } from 'bundles/Shared/entities/periodAggregationStrategy';
import { EntityTagField, TAG_ENTITIES } from 'bundles/Shared/entities/tag';
import { isEmpty } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';

type Props = DialogProps<ReportManualVariableForm> & {
  defaultValues?: Partial<ReportManualVariableForm>;
};

export function ManualVariableSettingsModal({
  onClose,
  onSubmit,
  defaultValues,
}: Props) {
  const methods = useForm<ReportManualVariableForm>({
    defaultValues: {
      ...DEFAULT_MANUAL_VARIABLE_FORM,
      ...defaultValues,
    },
    resolver: yupResolver(MANUAL_VARIABLE_SCHEMA),
  });
  const {
    control,
    register,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;
  const isVariableRefReadonly = !isEmpty(defaultValues?.reference);
  const handleSubmit = methods.handleSubmit((values) => onSubmit?.(values));

  return (
    <Modal
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle title="Settings" order="subtitle-title" />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!isValid || isSubmitting || !isDirty}
          >
            Submit
          </Button>
        </ModalActions>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field labelText="Variable Name" required>
            <LabelFieldController
              control={control}
              name="name"
              referenceFieldName="reference"
              syncReference={!isVariableRefReadonly}
            />
          </Field>
          <Field labelText="Reference" required note={REFERENCE_FIELD_NOTE}>
            <Input
              disabled={isVariableRefReadonly}
              {...register('reference')}
            />
          </Field>
          <Field labelText="Value Type" required>
            <RadioGroupController
              control={control}
              name="valueType"
              options={VARIABLE_VALUE_TYPE_OPTIONS}
            />
          </Field>
          <Field labelText="Period Aggregation Strategy" required>
            <SharedSelectController
              control={control}
              name="periodAggregationStrategy"
              options={PERIOD_AGGREGATION_STRATEGIES_OPTIONS}
            />
          </Field>
          <Field labelText="Description">
            <Textarea {...methods.register('description')} />
          </Field>
          <EntityTagField
            entity={TAG_ENTITIES.MANUAL_VARIABLE}
            control={control}
            name="tags"
          />
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
}

import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { cn } from '@/shared/lib/css/cn';
import {
  GroupCellRendererParams,
  isRowGl,
} from 'bundles/Shared/components/ReportTable/types';
import GroupWithConnector from 'bundles/Shared/components/AgGrid/GroupWithConnector/GroupWithConnector';
import SourceTypeImage from 'bundles/Shared/components/SourceType/SourceTypeImage';
import styles from '../GroupRenderer.module.scss';

type Props = ICellRendererParams & GroupCellRendererParams;

export function GroupRenderer(props: Props) {
  const { data, node } = props;
  node.setExpanded(true);

  return (
    <GroupWithConnector {...props}>
      {isRowGl(data) && (
        <SourceTypeImage
          className={styles.sourceTypeImage}
          sourceType={data.generalLedger.source}
        />
      )}
    </GroupWithConnector>
  );
}

import http from 'lib/http';

export const FETCH_CUSTOMER_CONFIG = 'symmetre-client-api/FETCH_CUSTOMER_CONFIG';

export function fetchCustomerConfig() {
  return async function (dispatch) {
    const res = await http.get('/customer_config');
    const responseData = await res.json();

    dispatch({
      type: FETCH_CUSTOMER_CONFIG,
      payload: responseData,
    });
  };
}

export async function plainFetchCustomerConfig() {
  const res = await http.get('/customer_config');
  const responseData = await res.json();
  return responseData;
}

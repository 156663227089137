import React, { useState } from 'react';
import {
  CrudEntityModalProps,
  CrudModal,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import SovcFields from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/sovc/SovcFields';
import {
  ISovcRowUpdate,
  SOVC_ENTITY_NAME,
} from 'bundles/REconcile/actions/sovcConfig';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';

interface Props extends CrudEntityModalProps<ISovcRowUpdate> {
  legalEntityCode: string;
}

function SovcCrudModal({ entity, legalEntityCode, onSubmit, ...props }: Props) {
  const [formData, setFormData] = useState(entity);

  const { data: jobCostCodes, isLoading } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode,
    withoutSovc: 'true',
  });

  return (
    <CrudModal
      onSubmit={() => onSubmit?.(formData)}
      entityName={SOVC_ENTITY_NAME}
      {...props}
    >
      {!isLoading && (
        <SovcFields
          jccList={jobCostCodes}
          setFormData={setFormData}
          formData={formData}
        />
      )}
    </CrudModal>
  );
}

export default SovcCrudModal;

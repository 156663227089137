import { DEFAULT_AMCHART_DATE_FORMATS } from '@/shared/lib/formatting/charts';
import { getAmchartRef } from 'lib/amcharts/utils';

export const DEFAULT_TEMPLATE_SERIES_REF_KEY = 'templateSeries';
export const DEFAULT_SERIES_REF_KEY = 'series';
export const DEFAULT_X_AXIS_REF_KEY = 'xAxis';
export const DEFAULT_Y_AXIS_REF_KEY = 'yAxis';

const DEFAULT_DATE_X_AXIS_SETTINGS = {
  cellEndLocation: 0.9,
  minGridDistance: 40,
  cellStartLocation: 0.1,
} as const;

const DEFAULT_DATE_CATEGORY_FIELD = 'dateFrom';

const DEFAULT_CURSOR_SETTINGS = {
  type: 'XYCursor',
  settings: {
    behavior: 'zoomX',
  },
  properties: {
    lineY: {
      settings: {
        visible: false,
      },
    },
  },
} as const;

const DEFAULT_SCROLLBAR_X_SETTINGS = {
  type: 'Scrollbar',
  settings: {
    orientation: 'horizontal',
    marginBottom: 20,
  },
} as const;

const DEFAULT_LEGEND_SETTINGS = {
  type: 'Legend',
  settings: {
    x: {
      type: 'Percent',
      value: 50,
    },
    layout: 'horizontal',
    centerX: {
      type: 'Percent',
      value: 50,
    },
  },
} as const;

const DEFAULT_AXIS_LABEL = {
  type: 'Label',
  settings: {
    y: {
      type: 'Percent',
      value: 50,
    },
    fill: {
      type: 'Color',
      value: '#848d91',
    },
    text: 'Right Y Axis',
    centerX: {
      type: 'Percent',
      value: 50,
    },
    rotation: -90,
    fontWeight: '600',
  },
} as const;

export const DEFAULT_LINE_SERIES_TEMPLATE = {
  type: 'LineSeries',
  settings: {
    name: 'Line Series',
    xAxis: getAmchartRef(DEFAULT_X_AXIS_REF_KEY),
    yAxis: getAmchartRef(DEFAULT_Y_AXIS_REF_KEY),
    fill: {
      type: 'Color',
      value: '#0d1a3f',
    },
    stroke: {
      type: 'Color',
      value: '#0d1a3f',
    },
    tooltip: {
      type: 'Tooltip',
      settings: {
        labelText: '{valueY}',
      },
    },
    valueXField: 'dateFrom',
  },
  properties: {
    data: getAmchartRef('data'),
    bullets: [
      {
        type: 'Bullet',
        settings: {
          sprite: {
            type: 'Circle',
            settings: {
              fill: "@series.get('fill')",
              radius: 4,
            },
          },
        },
      },
    ],
  },
} as const;

export const DEFAULT_DATE_X_AXIS_TEMPLATE = {
  type: 'DateAxis',
  settings: {
    renderer: {
      type: 'AxisRendererX',
      settings: DEFAULT_DATE_X_AXIS_SETTINGS,
    },
    dateFormats: DEFAULT_AMCHART_DATE_FORMATS,
    baseInterval: {
      count: 1,
      timeUnit: 'month',
    },
    maxDeviation: 0.2,
    categoryField: DEFAULT_DATE_CATEGORY_FIELD,
  },
  properties: {
    data: '#data',
  },
} as const;

export const DEFAULT_VALUE_Y_AXIS_TEMPLATE = {
  type: 'ValueAxis',
  settings: {
    renderer: {
      type: 'AxisRendererY',
    },
  },
};

export const DEFAULT_Y_AXES_REFS = [getAmchartRef(DEFAULT_Y_AXIS_REF_KEY)];

export const DEFAULT_X_AXES_REFS = [getAmchartRef(DEFAULT_X_AXIS_REF_KEY)];

export const DEFAULT_SERIES_REF = getAmchartRef(DEFAULT_SERIES_REF_KEY);

export const DEFAULT_AMCHART_CONFIG_TEMPLATE = {
  type: 'XYChart',
  refs: [
    {
      [DEFAULT_X_AXIS_REF_KEY]: DEFAULT_DATE_X_AXIS_TEMPLATE,
      [DEFAULT_Y_AXIS_REF_KEY]: DEFAULT_VALUE_Y_AXIS_TEMPLATE,
    },
    {
      [DEFAULT_SERIES_REF_KEY]: [],
    },
  ],
  children: [
    {
      ...DEFAULT_LEGEND_SETTINGS,
      properties: {
        data: getAmchartRef('series'),
      },
    },
  ],
  properties: {
    xAxes: DEFAULT_X_AXES_REFS,
    yAxes: DEFAULT_Y_AXES_REFS,
    series: DEFAULT_SERIES_REF,
  },
  settings: {
    panY: false,
    cursor: DEFAULT_CURSOR_SETTINGS,
    layout: 'vertical',
    scrollbarX: DEFAULT_SCROLLBAR_X_SETTINGS,
  },
} as const;

export const DEFAULT_EAGLE_EYE_AMCHART_CONFIG_TEMPLATE = {
  refs: [
    {
      xAxis: DEFAULT_DATE_X_AXIS_TEMPLATE,
      sumYAxis: {
        type: 'ValueAxis',
        children: [
          {
            ...DEFAULT_AXIS_LABEL,
            index: 0,
            settings: {
              ...DEFAULT_AXIS_LABEL.settings,
              text: '#selectedKpi.label',
            },
          },
        ],
        settings: {
          renderer: {
            type: 'AxisRendererY',
          },
        },
      },
    },
    {
      [DEFAULT_TEMPLATE_SERIES_REF_KEY]: [
        {
          type: 'LineSeries',
          settings: {
            name: 'Kpi Name',
            xAxis: '#xAxis',
            yAxis: '#sumYAxis',
            tooltip: {
              type: 'Tooltip',
              settings: {
                labelText: '{name}: {valueY}',
              },
            },
            valueXField: 'dateFrom',
            valueYField: 'kpiValue',
          },
          properties: {
            data: '#data',
            bullets: [
              {
                type: 'Bullet',
                settings: {
                  sprite: {
                    type: 'Circle',
                    settings: {
                      fill: "@series.get('fill')",
                      radius: 4,
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
  ],
  type: 'XYChart',
  children: [
    {
      ...DEFAULT_LEGEND_SETTINGS,
      type: 'Legend',
      settings: {
        ...DEFAULT_LEGEND_SETTINGS.settings,
        maxHeight: 300,
        layout: {
          type: 'GridLayout',
          settings: {
            fixedWidthGrid: true,
          },
        },
      },
      properties: {
        data: '#templateSeries',
      },
    },
  ],
  settings: {
    panY: false,
    cursor: DEFAULT_CURSOR_SETTINGS,
    layout: 'vertical',
    scrollbarX: DEFAULT_SCROLLBAR_X_SETTINGS,
  },
  properties: {
    xAxes: ['#xAxis'],
    yAxes: ['#sumYAxis'],
    series: '#templateSeries',
  },
};

export const EAGLE_EYE_CATEGORICAL_AMCHART_CONFIG_TEMPLATE = {
  refs: [
    {
      xAxis: {
        type: 'CategoryAxis',
        settings: {
          renderer: {
            type: 'AxisRendererX',
            settings: DEFAULT_DATE_X_AXIS_SETTINGS,
            properties: {
              labels: {
                properties: {
                  template: {
                    settings: {
                      rotation: -45,
                      centerX: {
                        type: 'Percent',
                        value: 100,
                      },
                      paddingRight: 15,
                    },
                  },
                },
              },
            },
          },
          baseInterval: {
            count: 1,
            timeUnit: 'day',
          },
          maxDeviation: 0.2,
          categoryField: 'objectName',
        },
        properties: {
          data: '#0',
        },
      },
      leftYAxis: {
        type: 'ValueAxis',
        children: [
          {
            ...DEFAULT_AXIS_LABEL,
            settings: {
              ...DEFAULT_AXIS_LABEL.settings,
              text: 'Left Y Axis',
            },
          },
        ],
        settings: {
          renderer: {
            type: 'AxisRendererY',
          },
          numberFormat: " #,###%|'('#,###s% ')'|0",
        },
      },
    },
    {
      rightYAxis: {
        type: 'ValueAxis',
        children: [
          {
            ...DEFAULT_AXIS_LABEL,
            settings: {
              ...DEFAULT_AXIS_LABEL.settings,
              text: 'Right Y Axis',
            },
          },
        ],
        settings: {
          renderer: {
            type: 'AxisRendererY',
            settings: {
              opposite: true,
            },
          },
          numberFormat: " #,###%|'('#,###s% ')'|0",
          syncWithAxis: '#leftYAxis',
        },
      },
    },
    {
      series: [
        {
          type: 'ColumnSeries',
          settings: {
            name: 'Kpi Name',
            xAxis: '#xAxis',
            yAxis: '#leftYAxis',
            tooltip: {
              type: 'Tooltip',
              settings: {
                labelText: '{valueY}',
              },
            },
            valueYField: 'kpiValue',
            categoryXField: 'objectName',
          },
          properties: {
            data: '#0',
            bullets: [
              {
                type: 'Bullet',
                settings: {
                  sprite: {
                    type: 'Label',
                    settings: {
                      fill: {
                        type: 'Color',
                        value: '#000',
                      },
                      text: "{valueY.formatNumber(\" #,###.00%|'('#,###.00%s')'|0\")}",
                      centerX: {
                        type: 'Percent',
                        value: 50,
                      },
                      centerY: {
                        type: 'Percent',
                        value: 100,
                      },
                      populateText: true,
                      paddingBottom: 10,
                    },
                  },
                  locationX: 0.5,
                  locationY: 1,
                },
                properties: {},
              },
            ],
            columns: {
              properties: {
                template: {
                  settings: {
                    adapters: [
                      {
                        value: '#02BBBA',
                        property: 'fill',
                        condition: {
                          key: 'categoryX',
                          value: 'Multifamily - Midwest',
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
        },
        {
          type: 'LineSeries',
          settings: {
            name: 'Kpi Name 2',
            xAxis: '#xAxis',
            yAxis: '#rightYAxis',
            connect: false,
            tooltip: {
              type: 'Tooltip',
              settings: {
                labelText:
                  "{name}:{valueY.formatNumber(\" #,###.00%|'('#,###.00%s')'|0\")}",
              },
            },
            valueYField: 'kpiValue',
            categoryXField: 'objectName',
          },
          properties: {
            data: '#0',
            strokes: {
              properties: {
                template: {
                  settings: {
                    opacity: 0,
                  },
                },
              },
            },
            bullets: [
              {
                type: 'Bullet',
                settings: {
                  sprite: {
                    type: 'Circle',
                    settings: {
                      fill: "@series.get('fill')",
                      radius: 4,
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
  ],
  type: 'XYChart',
  children: [
    {
      type: 'Legend',
      settings: {
        x: {
          type: 'Percent',
          value: 50,
        },
        layout: {
          type: 'GridLayout',
          settings: {
            fixedWidthGrid: true,
          },
        },
        centerX: {
          type: 'Percent',
          value: 50,
        },
        maxHeight: 300,
      },
      properties: {
        data: '#series',
      },
    },
  ],
  settings: {
    panY: false,
    cursor: {
      type: 'XYCursor',
      settings: {
        behavior: 'zoomX',
      },
      properties: {
        lineY: {
          settings: {
            visible: false,
          },
        },
      },
    },
    layout: 'vertical',
    scrollbarX: {
      type: 'Scrollbar',
      settings: {
        orientation: 'horizontal',
        marginBottom: 20,
      },
    },
  },
  properties: {
    xAxes: ['#xAxis'],
    yAxes: ['#leftYAxis', '#rightYAxis'],
    series: '#series',
  },
};

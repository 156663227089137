import { TConfirmFuncProps } from '@/shared/lib/hooks/useModal';
import React, { ComponentProps } from 'react';
import TemplateEditor from '../editor/TemplateEditor';
import * as Type from '../editor/types';

export const initInvitationTemplateVars: Type.InvitationTemplateVars = {
  body: [],
  greeting: [],
  acceptInvitation: [],
  additionalInfo: [],
};

export const initFeedMessageTemplateVars: Type.FeedMessageTemplateVars = {
  greeting: [],
  additionalInfo: [],
  callToAction: [],
};

export const initForgotPasswordTemplateVars: Type.ForgotPasswordTemplateVars = {
  greeting: [],
  body: [],
  callToAction: [],
  additionalInfo: [],
};

export const TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS: ComponentProps<
  typeof TemplateEditor
>['toolbarItems'] = [
  'alignment:left',
  'alignment:center',
  'alignment:right',
  'variable',
];

export const confirmResetingReplyRecipients: TConfirmFuncProps = {
  title: 'Reset Access for replies',
  subtitle: (
    <p className="-mb-tw-4">
      Are you sure you want to reset access
      <br />
      for all members?
    </p>
  ),
  actions: {
    primaryButton: {
      text: 'Reset',
      variant: 'danger',
    },
    secondaryButton: {
      text: 'Cancel',
      variant: 'secondary',
    },
  },
};

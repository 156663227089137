import { cn } from '@/shared/lib/css/cn';
import { ReactComponent as CornerArrowSvg } from 'images/corner-arrow.svg';
import { PropsWithChildren } from 'react';

export function CornerArrow({
  hidden,
  children,
  ...props
}: PropsWithChildren & {
  hidden?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'group relative h-full w-full',
        !hidden && 'cursor-pointer',
      )}
      {...props}
    >
      <CornerArrow.Svg className={cn(hidden && 'hidden')} />
      {children}
    </div>
  );
}

CornerArrow.Svg = ({
  className,
  ...props
}: React.ComponentProps<typeof CornerArrowSvg>) => (
  <CornerArrowSvg
    className={cn(
      'absolute right-[3px] top-[2.5px] z-10 text-info-055 group-hover:text-info-070',
      className,
    )}
    {...props}
  />
);

import { EditPermissionsModal } from 'bundles/Shared/components/Permissions/EditPermissionsModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import {
  ReportDashboardType,
  ReportEagleEyeDashboard,
  useLazyGetApiSettingsReportComparisonDashboardsByIdSubjectablesQuery,
  useLazyGetApiSettingsReportEagleEyeDashboardsByIdSubjectablesQuery,
  useLazyGetApiSettingsReportObjectDashboardsByIdSubjectablesQuery,
  usePutApiSettingsReportComparisonDashboardsByIdMutation,
  usePutApiSettingsReportEagleEyeDashboardsByIdMutation,
  usePutApiSettingsReportObjectDashboardsByIdMutation,
} from 'bundles/Shared/entities/dashboard';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useCallback } from 'react';

export const useLazyDashboardsByIdSubjectablesQuery = (
  type: ReportDashboardType,
) => {
  switch (type) {
    case ReportDashboardType.OBJECT: {
      return useLazyGetApiSettingsReportObjectDashboardsByIdSubjectablesQuery();
    }
    case ReportDashboardType.EAGLE_EYE: {
      return useLazyGetApiSettingsReportEagleEyeDashboardsByIdSubjectablesQuery();
    }
    case ReportDashboardType.COMPARISON_MODE: {
      return useLazyGetApiSettingsReportComparisonDashboardsByIdSubjectablesQuery();
    }
  }
};

const useUpdateDashboard = (type: ReportDashboardType) => {
  switch (type) {
    case ReportDashboardType.OBJECT: {
      return usePutApiSettingsReportObjectDashboardsByIdMutation();
    }
    case ReportDashboardType.EAGLE_EYE: {
      return usePutApiSettingsReportEagleEyeDashboardsByIdMutation();
    }
    case ReportDashboardType.COMPARISON_MODE: {
      return usePutApiSettingsReportComparisonDashboardsByIdMutation();
    }
  }
};

export const useUpdateDashboardPermissions = (type: ReportDashboardType) => {
  const { openModal } = useModal();
  const [updateDashboard] = useUpdateDashboard(type);
  const [loadSubjectables] = useLazyDashboardsByIdSubjectablesQuery(type);

  return useCallback(async (dashboardId: ReportEagleEyeDashboard['id']) => {
    const res = await openModal(EditPermissionsModal, {
      fetchSubjectables: async () => {
        const { data: subjectables } = await loadSubjectables({
          id: dashboardId,
        });

        return subjectables;
      },
      objectableName: 'scoreboard',
    });

    if (res) {
      const permissions = res.isPublic
        ? {
            directly_permitted_user_ids: [],
            directly_permitted_user_role_ids: [],
            directly_permitted_user_tag_ids: [],
            directly_permitted_investment_entity_ids: [],
            public_permission_attributes: {},
          }
        : {
            directly_permitted_user_ids: mapListToIds(res.directUsers),
            directly_permitted_user_role_ids: mapListToIds(res.directRoles),
            directly_permitted_user_tag_ids: mapListToIds(res.directTags),
            directly_permitted_investment_entity_ids: mapListToIds(
              res.directInvestmentEntities,
            ),
            public_permission_attributes: { _destroy: true },
          };
      await updateDashboard({
        id: dashboardId,
        body: {
          ...permissions,
        },
      });
    }
  }, []);
};

import pluralize from 'pluralize';
import React from 'react';
import { Icon, LinkButton, Popover, PseudoLink } from 'stories';
import * as Type from './editor/types';

interface Props {
  users: Type.ReplyRecipient[];
  onSet: () => void;
}

const DISPLAY_USERS_COUNT = 3;

export function RecipientsListPopover({ users, onSet }: Props) {
  const template = (
    <div className="view-permission-type min-w-[200px] p-tw-2">
      <div className="flex items-center gap-tw-2 text-dark-60">
        <Icon iconName="members" className="leading-4" />
        {users.length} {pluralize('User', users.length)}
      </div>
      <div className="secondary-regular text-light-90">
        {users
          .slice(0, DISPLAY_USERS_COUNT)
          .map((re) => re.fullName)
          .join(', ')}
      </div>
      {users.length > DISPLAY_USERS_COUNT && (
        <LinkButton onClick={onSet} className="secondary-regular">
          +{users.length - DISPLAY_USERS_COUNT} more
        </LinkButton>
      )}
    </div>
  );

  return (
    <Popover
      template={template}
      maxWidth="400px"
      trigger="mouseenter"
      touch={false}
      hideOnExpandedAreaClick
      appendToBody
      placement="left-end"
      arrowPosition="end"
      classes={{
        spanContainer: 'inline-flex min-w-0',
      }}
    >
      <PseudoLink className="inline-regular border-dashed border-light-70 text-light-90">
        {users.length} {pluralize('User', users.length)}
      </PseudoLink>
    </Popover>
  );
}

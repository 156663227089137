import React, { SetStateAction } from 'react';
import {
  FieldType,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { optionsReconcileFor } from 'lib/helpers';
import { corNumberRegEx } from 'lib/dictionaries';
import { IChangeEvent, ICompany, IContact } from 'bundles/Construction/types';
import { categoryTransformer } from '../FormComponents/transformer';
import CODORDate from '../FormComponents/CODORDate';
import COReallocation from '../FormComponents/COReallocation';
import COProbability from '../FormComponents/COProbability';

const getItemsCORModalEdit = (
  formData: IChangeEvent,
  setFormData: any,
  companies: ICompany[],
  contacts: IContact[],
  filteredReasons: any,
  multiReasons: () => void,
  isVerified: boolean,
  canEditAllAttr: boolean,
): IGroupFormItem[] => {
  const { category } = formData;
  const COR = {
    details: [
      {
        fieldName: 'reconcileCompany',
        fieldNameSnap: 'reconcile_company_id',
        type: FieldType.SELECT,
        title: 'Submitted by Company',
        placeholder: 'Select company',
        required: true,
        options: optionsReconcileFor(companies),
        styles: {
          wrapper: 'w-50',
        },
        disabled: true,
      },
      {
        fieldName: 'reconcileContact',
        fieldNameSnap: 'reconcile_contact_id',
        type: FieldType.SELECT,
        title: 'Submitted by Contact',
        placeholder: 'Enter or select the name',
        required: true,
        options: optionsReconcileFor(contacts),
        styles: {
          wrapper: 'w-50',
        },
        disabled: true,
      },
      {
        fieldName: 'number',
        fieldNameSnap: 'number',
        type: FieldType.INPUT_TEXT,
        title: 'Number',
        placeholder: '0000-A',
        required: true,
        validation: {
          message: `Please enter 1 to 4 digits and an optional letter (Examples: 123, 4325-A, 332G)`,
          func: (e) => corNumberRegEx.test(e),
        },
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        disabled: canEditAllAttr,
      },
      {
        fieldName: 'contractorDate',
        fieldNameSnap: 'contractor_date',
        type: FieldType.INPUT_DATE,
        title: 'Contractor date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
        disabled: canEditAllAttr,
      },
      {
        fieldName: 'receivedDate',
        fieldNameSnap: 'received_date',
        type: FieldType.INPUT_DATE,
        title: 'Received date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
        disabled: canEditAllAttr,
      },
      {
        fieldName: 'sentToDesignerOfRecordDate',
        fieldNameSnap: 'sent_to_designer_of_record_date',
        type: FieldType.OPTIONAL_SECTION,
        title: 'DOR date',
        optional: false,
        content: (
          <CODORDate
            formData={formData}
            setFormData={setFormData}
            canEditAllAttr={canEditAllAttr}
          />
        ),
        styles: {
          nomb: true,
        },
      },
    ],
  };
  const PCO = {
    details: [
      {
        fieldName: 'reconcileCompany',
        fieldNameSnap: 'reconcile_company_id',
        type: FieldType.SELECT,
        title: 'Submitted by Company',
        placeholder: 'Select company',
        required: true,
        disabled: true,
        options: optionsReconcileFor(companies),
        styles: {
          wrapper: 'w-50',
        },
      },
      {
        fieldName: 'reconcileContact',
        fieldNameSnap: 'reconcile_contact_id',
        type: FieldType.SELECT,
        title: 'Submitted by Contact',
        placeholder: 'Enter or select the name',
        required: true,
        disabled: true,
        options: optionsReconcileFor(contacts),
        styles: {
          wrapper: 'w-50',
        },
      },
      {
        fieldName: 'number',
        fieldNameSnap: 'number',
        type: FieldType.INPUT_TEXT,
        title: 'Number',
        placeholder: '0000-A',
        required: true,
        validation: {
          message: `Please enter 1 to 4 digits and an optional letter (Examples: 123, 4325-A, 332G)`,
          func: (e) => corNumberRegEx.test(e),
        },
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
      },
      {
        fieldName: 'contractorDate',
        fieldNameSnap: 'contractor_date',
        type: FieldType.INPUT_DATE,
        title: 'Contractor date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'receivedDate',
        fieldNameSnap: 'received_date',
        type: FieldType.INPUT_DATE,
        title: 'Received date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
    ],
  };

  const IRC = {
    details: [
      {
        fieldName: 'reconcileContact',
        fieldNameSnap: 'reconcile_contact_id',
        type: FieldType.SELECT,
        title: 'Submitted by Contact',
        placeholder: 'Enter or select the name',
        required: true,
        disabled: true,
        options: optionsReconcileFor(contacts),
        styles: {
          wrapper: 'w-50',
        },
      },
    ],
  };

  const changeData = { COR, PCO, IRC };

  const groupFormItems = [
    {
      customTitle: 'Key Info',
      required: true,
      activated: true,
      open: true,
      formItems: [
        {
          fieldName: 'title',
          fieldNameSnap: 'title',
          type: FieldType.INPUT_TEXT,
          title: 'Name or Title',
          placeholder: 'Enter summary description of change event',
          required: true,

          styles: {
            wrapper: 'w-full form-item_white',
            size: 'm',
            back_layer: 'bg-white',
          },
        },
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          title: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,

          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
        ...(category !== 'COR'
          ? [
              {
                fieldName: 'changeOrderReasons',
                fieldNameSnap: 'changeOrderReasons',
                type: FieldType.LIST_SELECT,
                title: 'Reasons',
                placeholder: 'Select Reasons',
                required: true,
                options: filteredReasons,
                isMulti: multiReasons,
                styles: {
                  wrapper: 'w-75 form-item_white',
                  input: 'w-60',
                  size: 'm',
                },
              },
            ]
          : []),
        {
          fieldName: 'category',
          fieldNameSnap: 'category',
          type: FieldType.SELECTED_BUTTONS,
          title: 'Select Event category',
          transformerView: categoryTransformer,
          placeholder: 'Enter detailed description or other relevant data',
          buttons: [
            {
              title: 'COR',
              text: 'Change Order Request',
              active: formData.category === 'COR',
              value: 'COR',
            },
            {
              title: 'PCO',
              text: 'Potential Change Order',
              active: formData.category === 'PCO',
              value: 'PCO',
            },
            {
              title: 'IRC',
              text: 'Internal Risk of Change',
              active: formData.category === 'IRC',
              value: 'IRC',
            },
          ],
          required: true,
          styles: {
            wrapper: 'w-full',
            nomb: true,
          },
          disabled: true,
        },
      ],
    },
    {
      customTitle: 'Additional Details',
      required: true,
      activated: true,
      open: false,
      formItems: category ? changeData[category].details : [],
    },
    {
      customTitle: 'Financial Impact',
      required: true,
      activated: true,
      open: false,
      formItems: [],
    },
  ];
  if (category) {
    groupFormItems[2].formItems.push(
      {
        fieldName: 'amount',
        fieldNameSnap: 'amount',
        type: FieldType.INPUT_NUMBER,
        title: 'Amount',
        leftIcon: 'paid',
        placeholder: '0',
        required: true,
        disabled: isVerified,
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        thousandSeparator: true,
      },
      {
        fieldName: 'probability',
        fieldNameSnap: 'probability',
        type: FieldType.OPTIONAL_SECTION,
        title: 'Probability',
        optional: false,
        defaultValue: '100',
        expanded: formData.probability,
        content: (
          <COProbability
            formData={formData}
            setFormData={setFormData}
            isVerified={isVerified}
          />
        ),
        viewComponent: [
          {
            fieldName: 'probability_comment',
          },
        ],
      },
    );
  }
  if (category !== 'IRC') {
    groupFormItems[2].formItems.push({
      fieldName: 'reallocationJccId',
      fieldNameSnap: 'reallocation_jcc_id',
      type: FieldType.OPTIONAL_SECTION,
      title: 'REallocation',
      optional: false,
      content: (
        <COReallocation
          formData={formData}
          setFormData={setFormData}
          fieldNameAmount="amount"
          menuPlacement="top"
          canEditAllAttr={canEditAllAttr}
        />
      ),
      styles: {
        nomb: true,
      },
    });
  }
  return groupFormItems;
};
export default getItemsCORModalEdit;

import React, { useMemo, useState } from 'react';
import {
  Badge,
  ChangeableStatusBadge,
  Icon,
  IconButton,
  Popover,
  SmallAddingButton,
  Tooltip,
} from 'stories';
import { capitalize } from 'lodash-es';
import { navigate } from '@reach/router';
import Label from 'stories/Label/Label';
import RowsAction from 'stories/RowsAction/RowsAction';
import PermissionList from 'bundles/Shared/components/PermissionList';
import PlaceholderLine from 'bundles/Shared/components/PlaceholderLine';
import { RRule } from 'rrule';
import styles from './EmailTemplateCard.module.scss';

const MAP_FREQ_TO_LABEL = {
  3: 'Daily',
  2: 'Weekly',
  1: 'Monthly',
  0: 'Annually',
};

const MAP_FREQ_TO_COLOR = {
  3: 'var(--green)',
  2: 'var(--violet)',
  1: 'var(--yellow)',
  0: 'var(--pumpkin)',
};

const EmailTemplateCard = ({
  id,
  isActiveDefault,
  isDefault,
  name,
  roles = [],
  users = [],
  editPath,
  onDelete,
  onToggle,
  withoutStatusToggle = false,
  schedule,
}) => {
  const [isActive, setIsActive] = useState(isActiveDefault);

  const updateStatus = (opt) => {
    if (
      (opt.key === 'published' && !isActive) ||
      (opt.key === 'archived' && isActive)
    ) {
      onToggle({ id, isActive }).then((templateJson) => {
        setIsActive(templateJson.active);
        if (templateJson.status === 500) {
          toastr.error('Status has not been updated');
        } else {
          toastr.success('Status has been successfully updated');
        }
      });
    }
  };

  const statusOptions = [
    {
      label: 'Published',
      key: 'published',
      color: 'green',
    },
    {
      label: 'Archived',
      key: 'archived',
      color: 'light-30',
    },
  ];

  const actionItems = useMemo(
    () => [
      {
        label: 'Edit',
        onClick: () => navigate(`./${editPath}`),
      },
      {
        label: 'Delete',
        onClick: () => onDelete(id),
      },
    ],
    [],
  );

  const rrule = useMemo(() => {
    if (schedule) {
      return RRule.fromString(schedule);
    }
    return null;
  }, [schedule]);

  return (
    <div className="rounded-[1rem] email-template-card__container position-relative overflow-hidden bg-light">
      <div className="flex flex-col align-items-stretch flex-grow email-template-card__content">
        <div className={styles.placeholderBlock}>
          <PlaceholderLine
            width="52px"
            height="4px"
            backgroundColor="var(--light-10)"
            borderRadius=".5rem"
            className="mb-xs"
          />
          <PlaceholderLine
            width="39px"
            height="4px"
            backgroundColor="var(--light-10)"
            borderRadius=".5rem"
            className="mb-xs"
          />
          <div className={styles.lastPlaceholderLineContainer}>
            <PlaceholderLine
              width="90px"
              height="10px"
              backgroundColor="var(--light-10)"
              borderRadius=".5rem"
              className="mb-xs"
            />
          </div>
        </div>
        <div
          className={`${styles.topContainerOverlay} flex flex-col align-items-stretch flex-grow`}
        >
          <div className="flex-1" />
        </div>
        <div className={styles.topContainer}>
          {!isDefault && !withoutStatusToggle && (
            <div className={styles.changeStatusContainer}>
              <ChangeableStatusBadge
                selected={statusOptions.find(
                  (opt) => opt.key === (isActive ? 'published' : 'archived'),
                )}
                options={statusOptions}
                handleItemClick={updateStatus}
                badgeLabel={null}
              />
            </div>
          )}
          <div className={styles.defaultOrCustomLabelContainer}>
            <Label
              outline
              color={isDefault ? 'var(--light-60)' : 'var(--violet)'}
            >
              {isDefault ? 'Default' : 'Custom'}
            </Label>
          </div>
          <div className={styles.detailsContainer}>
            {rrule && (
              <Tooltip
                arrowPosition="center"
                mainText={capitalize(rrule.toText())}
              >
                <Badge backgroundColor={MAP_FREQ_TO_COLOR[rrule.options.freq]}>
                  <div className="flex items-center">
                    <Icon iconName="clock" className="mr-xs" />
                    {MAP_FREQ_TO_LABEL[rrule.options.freq]}
                  </div>
                </Badge>
              </Tooltip>
            )}
            {(roles.length > 0 || users.length > 0) && (
              <div className="flex mt-s">
                <span className="light-60 secondary-regular">
                  For Whom:&nbsp;
                </span>
                <PermissionList
                  hideActions
                  permissions={{
                    roles,
                    users,
                    tags: [],
                    investmentEntities: [],
                  }}
                  className="secondary-regular"
                  classes={{ popoverTrigger: 'secondary-regular' }}
                  hideIcon
                  popoverOptions={{ placement: 'right-end' }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={`flex flex-col px-m ${styles.footer}`}>
          <h6
            className={`dark-60 header6-regular ${styles.truncatedText}`}
            title={name}
          >
            {name}
          </h6>
          <div className="flex items-center mt-m">
            <SmallAddingButton
              withoutIcon
              onClick={() => navigate(`${editPath}`)}
            >
              Open
            </SmallAddingButton>
            {!isDefault && (
              <Popover
                template={<RowsAction items={actionItems} />}
                placement="bottom-start"
                hiddenArrow
                trigger="click"
                appendTo={() => document.body}
                style={{ padding: '2px' }}
                classes={{ spanContainer: 'ml-s' }}
              >
                <IconButton iconName="more" />
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateCard;

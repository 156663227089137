import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { LinkButton, IconButton } from 'stories';

const BulkUploaderFileColumn = ({
  uploadingState,
  sameValue,
  currentValue,
  currentFileValue,
  notCompletedLabel,
  completedLabel,
  onAssign,
  className,
}) => (
  <td
    className={cn(
      'inline-regular light-60 weight-500 w-14rem',
      { 'td-disabled': sameValue },
      className,
    )}
  >
    <div className="flex justify-between items-center">
      {sameValue && !currentValue && notCompletedLabel}
      {sameValue && currentValue && completedLabel}

      {!sameValue && !currentFileValue && (
        <LinkButton onClick={onAssign}>Assign</LinkButton>
      )}

      {!sameValue && currentFileValue && (
        <div className="flex justify-between items-center w-full">
          {completedLabel}
          {uploadingState === 'notStarted' && (
            <IconButton iconName="edit" onClick={onAssign} />
          )}
        </div>
      )}
    </div>
  </td>
);

export default BulkUploaderFileColumn;

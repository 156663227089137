import React, { useEffect, useState } from 'react';
import {
  Button,
  LinkButton,
  Modal,
  ModalActions,
} from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { isEqual, orderBy } from 'lodash-es';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { fetchUserManagementFilters } from 'bundles/UserManagement/actions/Filters';
import {
  AssetsTab,
  RolesTab,
  TagsTab,
  InvestmentEntitiesTab,
  FundsTab,
} from './ModalWithSideTabs/Tabs';

import LoadingPanel from './LoadingPanel';
import {
  AssetsList,
  RolesList,
  TagsList,
  InvestmentEntitiesList,
  FundsList,
} from './ModalWithSideTabs/Lists';

const EMPTY_STATE = {
  assets: [],
  roles: [],
  tags: [],
  investmentEntities: [],
};

const UsersFilterModal = ({
  onClose,
  onSubmit,
  initialState,
  tabs,
}) => {
  const dispatch = useAppDispatch();
  const userRoles = useAppSelector((state) => state.users.userRoles);
  const allAssets = useAppSelector((state) => (
    orderBy(state.userManagement?.filters?.assets || [], [(item) => item.name.toLowerCase()])
  ));
  const allFunds = useAppSelector((state) => (
    orderBy(state.userManagement?.filters?.funds || [], [(item) => item.name.toLowerCase()])
  ));
  const userTags = useAppSelector((state) => (
    orderBy(state.userManagement?.filters?.tags || [], [(item) => item.name.toLowerCase()])
  ));
  const entities = useAppSelector((state) => (
    orderBy(state.userManagement?.filters?.entities || [], [(item) => item.name.toLowerCase()])
  ));

  const all = {
    assets: allAssets,
    tags: userTags,
    roles: userRoles,
    investmentEntities: entities,
    funds: allFunds,
  };

  const sideTabs = tabs || {
    assets: 'Assets',
    roles: 'Roles',
    tags: 'Tags',
    investmentEntities: 'Entities',
    funds: 'Funds',
  };
  const [sideTab, setSideTab] = useState(Object.keys(sideTabs)[0]);
  const [state, setState] = useState(initialState || EMPTY_STATE);
  const {
    assets, roles, tags, investmentEntities, funds,
  } = state;

  useEffect(() => {
    dispatch(fetchUserManagementFilters());
  }, []);

  const update = (key, value) => {
    let newValues;
    const values = state[key];
    if (Array.isArray(value)) {
      newValues = value;
    } else {
      newValues = values.map(({ id }) => id).includes(value.id)
        ? values.filter((v) => !isEqual(v, value))
        : [...values, value];
    }
    setState({ ...state, [key]: newValues });
  };

  if (!all.assets || !all.tags || !all.roles || !all.investmentEntities || !all.funds) {
    return (
      <Modal
        header="Filters"
        toggle={onClose}
        size="xl"
        classes={{
          header: 'bg-light',
          body: 'bg-light',
        }}
      >
        <LoadingPanel />
      </Modal>
    );
  }

  const currentTab = () => {
    if (sideTab === 'assets') {
      return (
        <AssetsTab
          records={all.assets}
          selectedIds={assets.map(({ id }) => id)}
          onChange={(value) => update('assets', value)}
        />
      );
    }

    if (sideTab === 'funds') {
      return (
        <FundsTab
          records={all.funds}
          selectedIds={funds.map(({ id }) => id)}
          onChange={(value) => update('funds', value)}
        />
      );
    }

    if (sideTab === 'roles') {
      return (
        <RolesTab
          records={all.roles.filter(({ category }) => category !== 'sre_admin')}
          selectedIds={roles.map(({ id }) => id)}
          onChange={(value) => update('roles', value)}
        />
      );
    }
    if (sideTab === 'tags') {
      return (
        <TagsTab
          records={all.tags}
          selectedIds={tags.map(({ id }) => id)}
          onChange={(value) => update('tags', value)}
        />
      );
    }
    if (sideTab === 'investmentEntities') {
      return (
        <InvestmentEntitiesTab
          records={all.investmentEntities}
          selectedIds={investmentEntities.map(({ id }) => id)}
          onChange={(value) => update('investmentEntities', value)}
        />
      );
    }
    return null;
  };

  const onTabChange = (t) => {
    setSideTab(t);
    document.getElementById('currentTab').scrollTop = 0;
  };

  const filterSelectedObjects = (collection, selected) => (
    collection.filter((o) => (selected || []).map(({ id }) => id).includes(o.id))
  );

  const assetsList = filterSelectedObjects(all.assets, assets);
  const fundsList = filterSelectedObjects(all.funds, funds);
  const rolesList = filterSelectedObjects(all.roles, roles);
  const tagsList = filterSelectedObjects(all.tags, tags);
  const investmentEntitiesList = filterSelectedObjects(all.investmentEntities, investmentEntities);

  const isEmpty = [
    assets, roles, tags, investmentEntities,
  ].every((arr) => arr?.length === 0);

  const handleSubmit = () => {
    onSubmit({
      ids: state,
      assets: assetsList,
      roles: rolesList,
      tags: tagsList,
      investmentEntities: investmentEntitiesList,
      funds: fundsList,
    });
    onClose();
  };

  return (
    <Modal
      header="Filters"
      toggle={onClose}
      size="xl"
      actions={(
        <ModalActions>
          <Button onClick={onClose} variant="secondary">Cancel</Button>
          <Button onClick={handleSubmit} variant="success">
            Apply Filters
          </Button>
        </ModalActions>
      )}
      classes={{
        header: 'bg-light',
        body: 'bg-light py-0',
        footer: 'bg-light flex flex-row-reverse',
      }}
    >
      <div className="modal-with-side-tabs overflow-hidden h-100">
        <div className="flex overflow-hidden h-100">
          <section className="border-right flex">
            <aside className="flex flex-col overflow-x-hidden overflow-y-auto">
              <ul>
                {Object.entries(sideTabs)
                  .filter(([tab]) => all[tab].length > 0)
                  .map(([tab, title]) => (
                    <li key={tab} className={cn({ active: tab === sideTab })}>
                      <LinkButton
                        className="h-[80px] d-block dark-60"
                        onClick={() => onTabChange(tab)}
                      >
                        {state[tab].length > 0 && <div className="mb-tw-2">{state[tab].length}</div>}
                        {title}
                      </LinkButton>
                    </li>
                  ))}
              </ul>
            </aside>
            <div
              className="border-left border-right p-m overflow-auto"
              id="currentTab"
              style={{ width: 320 }}
            >
              {currentTab()}
            </div>
          </section>
          <section
            className="p-l w-100 h-100 overflow-auto"
          >
            <h5>Filter By</h5>
            {isEmpty && <p>You haven&apos;t chosen anything yet</p>}
            {!isEmpty && (
              <>
                {assetsList.length > 0 && (
                  <AssetsList
                    items={assetsList}
                    onClose={(id) => update('assets', id)}
                    closable
                  />
                )}
                {fundsList.length > 0 && (
                  <FundsList
                    items={fundsList}
                    onClose={(id) => update('funds', id)}
                    closable
                  />
                )}
                {rolesList.length > 0 && (
                  <RolesList
                    items={rolesList}
                    onClose={(id) => update('roles', id)}
                    closable
                  />
                )}
                {tagsList.length > 0 && (
                  <TagsList
                    items={tagsList}
                    onClose={(id) => update('tags', id)}
                    closable
                  />
                )}
                {investmentEntitiesList.length > 0 && (
                  <InvestmentEntitiesList
                    items={investmentEntitiesList}
                    onClose={(id) => update('investmentEntities', id)}
                    closable
                  />
                )}
              </>
            )}
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default UsersFilterModal;

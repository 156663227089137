import { AnyAction } from 'redux';
import { IBuckets } from 'types/Buckets';
import { IEmployeeUtilization } from 'types/EmployeeUtilization';
import {
  FETCH_DASHBOARD,
  FETCH_EMPLOYEE_UTILIZATION,
  FETCH_PIPELINE_CARD,
  FETCH_PIPELINE_CARDS,
  FETCH_PIPELINE_GOALS_SUMMARY,
  FETCH_PIPELINE_VELOCITY,
  FETCH_PROPERTY_TYPES,
  FETCH_SNAPSHOT,
  UPDATE_DASHBOARD,
  UPDATE_PIPELINE_CARD,
} from 'bundles/Scoreboard/Pipeline/actions';
import { updatePipelineCards } from 'bundles/Scoreboard/Pipeline/helpers/utils';
import { PipelineCard } from 'bundles/Scoreboard/Pipeline/types';

interface IDealState {
  velocity: any[] | null;
  goalsSummary: any[] | null;
  dealsData: any[];
  dashboard: IBuckets[] | null;
  dashboardConnected: boolean;
  dashboardUpdating: boolean;
  employeeUtilization: IEmployeeUtilization | null;
  propertyTypes: string[];
  pipelineCards: PipelineCard[] | null;
}

const initialState: IDealState = {
  velocity: null,
  goalsSummary: null,
  dealsData: [],
  dashboard: null,
  dashboardConnected: false,
  dashboardUpdating: false,
  employeeUtilization: null,
  propertyTypes: [],
  pipelineCards: null,
};

export default function (
  state = initialState,
  { type, payload }: AnyAction,
): IDealState {
  switch (type) {
    case FETCH_PROPERTY_TYPES:
      return { ...state, propertyTypes: payload };

    case FETCH_SNAPSHOT:
      return { ...state, snapshots: { ...state.snapshots, ...payload } };

    case FETCH_PIPELINE_CARDS:
      return { ...state, pipelineCards: payload };

    case FETCH_PIPELINE_CARD:
      return {
        ...state,
        pipelineCards: state.pipelineCards.map((c) =>
          c.id === payload.id ? payload : c,
        ),
      };

    case UPDATE_PIPELINE_CARD:
      return {
        ...state,
        pipelineCards: updatePipelineCards(payload, state.pipelineCards),
      };

    case FETCH_EMPLOYEE_UTILIZATION:
      return { ...state, employeeUtilization: payload };

    case FETCH_PIPELINE_VELOCITY:
      return { ...state, velocity: payload };

    case FETCH_PIPELINE_GOALS_SUMMARY:
      return { ...state, goalsSummary: payload };

    case FETCH_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
        dashboardConnected: true,
        dashboardUpdating: false,
      };

    case UPDATE_DASHBOARD:
      return { ...state, dashboardUpdating: true };

    default:
      return state;
  }
}

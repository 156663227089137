import { BudgetLegalEntityState } from './types';

export const BUDGET_STATUSES: BudgetLegalEntityState[] = [
  'published',
  'draft',
  'in_progress',
  'no_budget',
];

export const STATUS_BADGE_COLORS = {
  published: 'bg-success-055',
  draft: 'bg-neutral-300 text-neutral-650',
  in_progress: 'bg-info-055',
  no_budget: 'bg-red',
} as const satisfies Record<BudgetLegalEntityState, string>;

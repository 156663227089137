import http from 'lib/http';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';

export async function exportGeneralLedgers() {
  const res = await http.post('/settings/report/general_ledgers/export.xlsx');
  const file = await res.blob();
  saveFileOnUserDevice(
    file,
    `${window.symmetreConfig.customer.name}_general_ledgers.xlsx`,
  );
}

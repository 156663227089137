import useDebounce from '@/shared/lib/hooks/useDebounce';
import { useCallback, useState } from 'react';

export function useDebouncedQuery(initialState = '', delay = 400) {
  const [query, setQuery] = useState(initialState);

  const debouncedQuery = useDebounce(query, delay);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setQuery(e.target.value);
    },
    [],
  );

  const resetQuery = useCallback(() => {
    setQuery('');
  }, []);

  return {
    query,
    debouncedQuery,
    setQuery,
    handleChange,
    resetQuery,
  };
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'lib/http';
import { IReconcileDevelopmentLegalEnitity } from 'bundles/REconcile/types/IReconcileDevelopmentLegalEnitity';

export const reconcileDevelopmentLegalEntitiesApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/reconcile/development/legal_entities`,
  }),
  reducerPath: 'reconcileDevelopmentLegalEntities',
  endpoints: (build) => ({
    getLegalEntity: build.query<
      IReconcileDevelopmentLegalEnitity,
      {
        legalEntityCode: string;
      }
    >({
      query: ({ legalEntityCode }) => `/${legalEntityCode}`,
    }),
  }),
});

export const { useGetLegalEntityQuery } = reconcileDevelopmentLegalEntitiesApi;

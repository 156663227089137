import React from 'react';
import pluralize from 'pluralize';
import { CurrencyFormatter, IconButton } from 'stories/index';
import UnitQuantity from 'bundles/REturn/components/Home/formatters/UnitQuantity';
import { formatDate } from '@/shared/lib/formatting/dates';

interface Props {
  value: number;
  onEdit: () => void;
  kind?: string;
  totalCount?: number;
  iconButtonProps?: React.ComponentProps<typeof IconButton>;
  units?: number | null;
  date?: string | null;
}

function NumberValueColumn({
  value,
  totalCount,
  onEdit,
  iconButtonProps,
  units,
  date,
}: Props) {
  return (
    <>
      <div className="flex justify-between whitespace-nowrap">
        <span className="inline-regular text-dark-60">
          <CurrencyFormatter value={value} />
          <UnitQuantity units={units} />
        </span>
        <IconButton
          variant="secondary"
          iconName="settings"
          onClick={onEdit}
          {...iconButtonProps}
        />
      </div>
      <div className="mt-xs flex gap-xs">
        {date && (
          <span className="secondary-regular text-xs text-neutral-900">
            {formatDate(date, 'MMM DD, YYYY')}
          </span>
        )}
        {totalCount != null && totalCount > 0 && (
          <div className="label-regular italic text-light-60">
            ({totalCount} {pluralize('txn', totalCount)})
          </div>
        )}
      </div>
    </>
  );
}

export default NumberValueColumn;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cn } from '@/shared/lib/css/cn';
import treeStyles from 'bundles/Shared/components/GroupForm/FormItems/tree-select/TreeSelect.module.scss';
import { Icon, Input, Popover } from 'stories';
import {
  SearchData,
  SortableTreeWithoutDndContext as SortableTree,
} from 'react-sortable-tree';
import TreeSelectItem from 'bundles/Shared/components/GroupForm/FormItems/tree-select/TreeSelectItem';
import TreeNodeRenderer from 'stories/TreeFolderNavigation/TreeNodeRenderer';
import { debounce, isEmpty } from 'lodash-es';
import { treeDFM } from 'lib/treeHelpers';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { TOptionTreeItem } from 'bundles/Shared/components/GroupForm/types/typesFormItem';
import { DEFAULT_DROPDOWN_OFFSET, PopoverRef } from 'stories/Popover/Popover';
import useElementSize from '@/shared/lib/hooks/useElementSize';

interface Props {
  options: TOptionTreeItem[];
  value?: string | number;
  initialValue?: string | number;
  onChange: (value: string | number) => void;
  treeProps?: Pick<React.ComponentProps<typeof SortableTree>, 'searchMethod'>;
  inputProps?: Omit<
    React.ComponentProps<typeof Input>,
    'value' | 'onClick' | 'onChange'
  >;
}

function TreeSelect({
  options,
  value: externalValue,
  initialValue,
  onChange,
  treeProps,
  inputProps,
}: Props) {
  const popoverRef = useRef<PopoverRef>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { width } = useElementSize(inputRef);
  const [localValue, setLocalValue] = useState(initialValue);
  const [treeData, setTreeData] = useState(options);
  const [prevOptions, setPrevOptions] = useState(options);
  const [searchValue, setSearchValue] = useState('');
  const [treeSearchQuery, setTreeSearchQuery] = useState('');

  const value = externalValue === undefined ? localValue : externalValue;
  const handleChange = (newValue: number | string) => {
    if (externalValue === undefined) {
      setLocalValue(newValue);
    }
    onChange?.(newValue);
  };

  const handleTreeSearchQuery = debounce((text: string) => {
    setTreeSearchQuery(text);
  }, 500);

  const flatOptions = useMemo(
    () =>
      treeDFM(options, (o) => ({
        value: o.id,
        label: o.title,
      })),
    [options],
  );

  if (prevOptions !== options) {
    setPrevOptions(options);
    setTreeData(options);
  }

  const syncSearchWithValue = () => {
    setSearchValue(
      value == null
        ? ''
        : flatOptions.find((item) => item.value === value)?.label ?? '',
    );
    setTreeSearchQuery('');
  };

  const searchHandle = (e) => {
    setSearchValue(e.target.value);
    handleTreeSearchQuery(e.target.value);
  };

  const handleOpen = () => {
    popoverRef.current?.show();
    setSearchValue('');
    setTreeSearchQuery('');
  };

  const handleClose = () => {
    popoverRef.current?.hide();
    syncSearchWithValue();
  };

  useEffect(() => {
    if (value !== undefined) {
      syncSearchWithValue();
    }
  }, [options, value]);

  const onNodeClick = (elem) => {
    popoverRef.current?.hide();
    handleChange(elem.id);
  };

  const generateNodeProps = useCallback(
    (data) => ({
      ...data,
      selectable: !data.node.isCategory && !data.node.isSubCategory,
      isSelected: value === data.node.id,
      onNodeClick,
    }),
    [value, onNodeClick],
  );

  const customSearchMethod = (searchData: SearchData<any>) => {
    const { node, searchQuery } = searchData;

    if (isEmpty(searchQuery)) {
      return true;
    }

    if (treeProps?.searchMethod == null) {
      return includesInLowerCase(node.title, searchQuery);
    }

    return treeProps.searchMethod(searchData);
  };

  return (
    <>
      <div className={cn(treeStyles.treeWrapper)}>
        <Input
          ref={inputRef}
          value={searchValue}
          onChange={searchHandle}
          onFocus={handleOpen}
          size={'l'}
          type={'text'}
          {...inputProps}
        />
        <div className="form-item-date__icon">
          <Icon iconName="arrowBottom" />
        </div>
      </div>
      <Popover
        trigger="focus"
        ref={popoverRef}
        offset={DEFAULT_DROPDOWN_OFFSET}
        onUntrigger={handleClose}
        reference={inputRef}
        hiddenArrow
        placement="bottom-start"
        appendToBody
        className="max-h-[200px] overflow-auto"
        maxWidth="none"
        style={{
          width,
        }}
        template={
          <SortableTree
            className="w-full"
            canDrag={false}
            isVirtualized={false}
            generateNodeProps={generateNodeProps}
            theme={{
              nodeContentRenderer: TreeSelectItem,
              treeNodeRenderer: TreeNodeRenderer,
            }}
            rowHeight="auto"
            treeData={treeData}
            searchQuery={treeSearchQuery}
            searchMethod={customSearchMethod}
            searchFocusOffset={0}
            onChange={(newTreeData) => setTreeData(newTreeData)}
            onlyExpandSearchedNodes
            {...treeProps}
          />
        }
      />
    </>
  );
}

export default TreeSelect;

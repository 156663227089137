import React from 'react';
import { Tumbler } from '@/stories';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { Control, useController } from 'react-hook-form';
import { SORTING_OPTIONS } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';

export function SortingField<
  TFieldValues extends Record<string, unknown> = Record<string, unknown>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name }: { control: Control<TFieldValues>; name: TName }) {
  const { field } = useController({ control, name });
  return (
    <SectionField
      labelText="Sorting"
      button={
        <Tumbler
          classes={{
            input: 'align-self-center',
          }}
          checked={field.value !== undefined && field.value !== null}
          onChange={(e) => field.onChange(e.target.checked ? 'asc' : null)}
        />
      }
    >
      <RadioGroup
        options={SORTING_OPTIONS}
        value={field.value}
        onChange={(o) => field.onChange(o.value)}
      />
    </SectionField>
  );
}

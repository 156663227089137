import { FONT_STYLES_OPTIONS } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { Field } from '@/stories';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { ResetButton } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { Control, useController } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { SectionField } from 'stories/Field/FieldsWrappers';

export function FontField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name }: { name: TName; control: Control<TFieldValues> }) {
  const { field } = useController({ name, control });
  return (
    <SectionField
      labelText={'Font'}
      button={<ResetButton name={name} />}
      note="Select the font style for the column"
    >
      <Field labelText="Font Styles">
        <SharedSelect
          menuPortalTarget={document.body}
          value={
            field.value &&
            FONT_STYLES_OPTIONS.find((f) => f.value === field.value)
          }
          onChange={(e) => field.onChange(e.value)}
          options={FONT_STYLES_OPTIONS}
        />
      </Field>
    </SectionField>
  );
}

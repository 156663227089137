/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { CSSProperties, ReactNode, useRef } from 'react';
import Tippy from '@tippyjs/react/headless';
import { animated, useSpring } from 'react-spring';
import { TippyProps } from '@tippyjs/react';
import { cn } from '@/shared/lib/css/cn';
import { Instance } from 'tippy.js';
import { ArrowPositions } from 'types/Tippy';
import TooltipArrow from './TooltipArrow';
import { ModifyArrowPosition } from '../utils/tippyUtils';
import './tooltip.css';

type ExcludedTippyProps = Omit<
  TippyProps,
  'render' | 'onCreate' | 'animation' | 'onMount' | 'appendTo'
>;

export interface TooltipProps extends ExcludedTippyProps {
  variant?: 'default' | 'explanation';
  mainText?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  titleText?: ReactNode | string;
  actionText?: ReactNode;
  actionHandler?: () => void;
  maxWidth?: CSSProperties['maxWidth'];
  showOnCreate?: boolean;
  arrowPosition?: ArrowPositions | `${ArrowPositions}`;
  style?: React.CSSProperties;
  offsetByBorderRadius?: number;
  classes?: {
    spanContainer?: string;
  };
  theme?: 'light' | 'dark';
}

export const DEFAULT_TOOLTIP_OFFSET: [number, number] = [0, 7];

export const Tooltip = ({
  variant = 'default',
  placement = 'top',
  children,
  mainText,
  titleText,
  actionText = 'Got it',
  actionHandler,
  style,
  arrowPosition = 'center',
  offsetByBorderRadius,
  content,
  singleton,
  maxWidth = '12.5rem',
  classes,
  theme = 'dark',
  ...props
}: React.PropsWithChildren<TooltipProps>) => {
  const config = { duration: 150 };
  const initialStyles = { opacity: 0 };
  const instance = useRef<Instance | null>(null);
  const [styles, setSpring] = useSpring(() => initialStyles);

  const defaultTippyRender: TippyProps['render'] = (
    attrs,
    singletonContent,
  ) => (
    <animated.div style={styles}>
      <div
        className={cn('sre-tooltip', `sre-tooltip_${theme}`)}
        tabIndex={-1}
        style={{ ...style, maxWidth }}
        {...attrs}
      >
        <TooltipArrow theme={theme} />
        {titleText && (
          <>
            <div className="sre-tooltip__title">{titleText}</div>
            <p className="sre-tooltip__text">{mainText}</p>
          </>
        )}
        {!titleText && (singletonContent ?? mainText)}
      </div>
    </animated.div>
  );
  const onMount = () => {
    setSpring({
      opacity: 1,
      config,
    });
  };
  const onHide = ({ unmount }) => {
    setSpring({
      ...initialStyles,
      onRest: unmount,
      config: { ...config, clamp: true },
    });
  };

  if (variant === 'explanation') {
    return (
      <Tippy
        placement={placement}
        interactive
        offset={DEFAULT_TOOLTIP_OFFSET}
        onCreate={(tippy) => (instance.current = tippy)}
        appendTo={() => document.body}
        render={(attrs) => (
          <animated.div style={{ ...styles, maxWidth }}>
            <div
              className={cn(
                'sre-tooltip sre-tooltip_explanation',
                `sre-tooltip_${theme}`,
              )}
              tabIndex={-1}
              {...attrs}
            >
              <TooltipArrow theme={theme} />
              <div className="inline-semibold mb-xs">{titleText}</div>
              <p className="font-weight-400 secondary-regular mb-xs">
                {mainText}
              </p>
              <span
                className="sre-tooltip__close inline-semibold"
                aria-hidden
                onClick={() => {
                  if (actionHandler) {
                    actionHandler();
                  } else {
                    instance.current?.hide();
                  }
                }}
              >
                {actionText}
              </span>
            </div>
          </animated.div>
        )}
        animation
        onMount={onMount}
        onHide={onHide}
        popperOptions={{
          modifiers: [
            ModifyArrowPosition({
              arrowPosition,
              offsetByBorderRadius,
              placement,
            }),
          ],
        }}
        {...props}
      >
        {children != null ? (
          <span
            className={cn(classes?.spanContainer ?? 'd-inline-flex')}
            tabIndex={0}
          >
            {children}
          </span>
        ) : (
          <></>
        )}
      </Tippy>
    );
  }

  return (
    <Tippy
      placement={placement}
      offset={DEFAULT_TOOLTIP_OFFSET}
      render={defaultTippyRender}
      animation
      onMount={onMount}
      onHide={onHide}
      singleton={singleton}
      content={content}
      popperOptions={{
        modifiers: [
          ModifyArrowPosition({
            arrowPosition,
            offsetByBorderRadius,
            placement,
          }),
        ],
      }}
      {...props}
    >
      {children != null ? (
        <span
          className={cn(classes?.spanContainer ?? 'd-inline-flex')}
          tabIndex={0}
        >
          {children}
        </span>
      ) : (
        <></>
      )}
    </Tippy>
  );
};

export default Tooltip;

/* eslint-disable linebreak-style */
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import {
  AnimationLoader,
  DocumentCard,
  Icon,
  IconButton,
  InlineAlert,
  SmallAddingButton,
  UploadContainer,
} from 'stories';
import printDocument from '@/shared/lib/browser/printDocument';
import { useModal } from '@/shared/lib/hooks/useModal';
import { downloadSharedFile } from 'lib/sharedFile';
import { ISharedDocument } from 'types/SharedDocument';
import { ClassNameProps } from 'types/Props';
import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';

interface IDocumentsUploadProps {
  id: number;
  legalEntityCode: string;
  uploadFiles: (
    legalEntityCode: string,
    id: number,
    { files: File },
  ) => Promise<void>;
  refresh: (legalEntityCode?: string, id?: number) => Promise<unknown>;
  selectHandle?: (
    legalEntityCode?: string,
    id?: number | object,
    idFile?: string,
  ) => Promise<unknown>;
  documents: ISharedDocument[];
  removeItems: (
    ids: string[],
    legalEntityCode?: string,
    id?: string,
  ) => Promise<void>;
  isReadOnly: boolean;
  finalFile: string;
  finalFileStatus: 'Verified' | 'Approved';
  supportSelect?: boolean;
  definePlacementButton?: ReactNode;
  canUpload?: boolean;
}

export const ViewFileIconButton = ({
  fileId,
  ...props
}: Omit<React.ComponentProps<typeof IconButton>, 'iconName' | 'onClick'> & {
  fileId: string;
}) => (
  <IconButton
    iconName="eye"
    onClick={() =>
      window.open(`/shared-files/${fileId}?disposition=inline`, '_blank')
    }
    {...props}
  />
);

export const DownloadFileIconButton = ({
  title,
  url,
  ...props
}: Omit<React.ComponentProps<typeof IconButton>, 'iconName' | 'onClick'> &
  Parameters<typeof downloadSharedFile>[0]) => (
  <IconButton
    iconName="download"
    onClick={() => downloadSharedFile({ title, url })}
    {...props}
  />
);

export const PrintFileIconButton = ({
  file,
  onLoadingEnd,
  onLoadingStart,
  ...props
}: Omit<React.ComponentProps<typeof IconButton>, 'iconName' | 'onClick'> &
  Parameters<typeof printDocument>[0]) => (
  <IconButton
    iconName="print"
    onClick={() => printDocument({ file, onLoadingEnd, onLoadingStart })}
    {...props}
  />
);

export const FileStatusBadge = ({
  status,
  className,
}: { status: string } & ClassNameProps) => (
  <div className={cn('flex items-center gap-tw-2', className)}>
    <Icon
      iconName="checkSmall"
      backgroundColor={CssVar.greenLight4}
      padding="xxs"
    />
    <div className="badge-verify whitespace-nowrap">{status}</div>
  </div>
);

const CustomActions = ({
  onRemoveItem,
  file,
  loading,
  isReadOnly,
  finalFile,
  finalFileStatus,
}) => (
  <div className="flex items-center">
    {loading && <AnimationLoader className="relative" />}
    {isReadOnly && finalFile == file?.id && (
      <FileStatusBadge className="mr-m" status={finalFileStatus} />
    )}
    {!loading && (
      <div className="flex gap-tw-2">
        <ViewFileIconButton fileId={file.id} />
        <DownloadFileIconButton {...file} />
        {file?.extension.toLowerCase() === 'pdf' && (
          <PrintFileIconButton file={file} />
        )}
        {finalFile !== file?.id && (
          <IconButton
            iconName="closeSmall"
            onClick={() => onRemoveItem(file.id)}
          />
        )}
      </div>
    )}
  </div>
);

const DocumentsUpload: FC<IDocumentsUploadProps> = ({
  documents = [],
  uploadFiles,
  removeItems,
  refresh,
  selectHandle,
  id,
  legalEntityCode,
  isReadOnly,
  finalFile,
  finalFileStatus = 'Approved',
  supportSelect,
  definePlacementButton,
  canUpload = true,
}) => {
  const { openFileDialog } = useFileDialog();
  const prepareWithFinal = (documents: ISharedDocument[]) =>
    documents.map((sf) => ({ ...sf, isFinal: sf.id === finalFile }));
  const [uploading, setUploading] = useState([]);
  const [sharedFiles, setSharedFiles] = useState(prepareWithFinal(documents));

  useEffect(() => {
    setSharedFiles(prepareWithFinal(documents));
  }, [documents]);

  const { confirm } = useModal();

  const uploadNewFiles = async (filesToUpload: File[]) => {
    const destructureFiles = [...filesToUpload];
    setUploading(destructureFiles);
    const presignedFiles = await presignFiles(destructureFiles);
    await Promise.all(presignedFiles.map((file) => directUpload(file)));
    const files = presignedFiles.map((f) => ({
      key: f.signedData.fields.key,
      filename: f.file.name,
      content_type: f.file.type,
      size: f.file.size,
    }));
    const uploadingFiles = await uploadFiles(legalEntityCode, id, { files });
    setUploading([]);
    setSharedFiles([...sharedFiles, ...uploadingFiles]);
    await refresh(legalEntityCode, id);
  };

  const selectFile = async (idFile: string) => {
    await selectHandle(legalEntityCode, id, idFile);
    await refresh(legalEntityCode, id);
  };

  const handleRemove = async (idFileRemoved: number) => {
    const result = await confirm({
      title: 'Remove file?',
      subtitle: 'Are you sure you want to remove file?',
    });
    if (result) {
      const newSharedFiles = sharedFiles.filter(
        (file) => file.id !== idFileRemoved,
      );
      await removeItems(
        {
          ids: sharedFiles
            .filter((file) => file.id === idFileRemoved)
            .map((item) => item.id),
        },
        legalEntityCode,
        id,
      );
      setSharedFiles([...newSharedFiles]);
      await refresh(legalEntityCode, id);
    }
  };

  const message = useMemo(() => {
    if (sharedFiles.length < 1) {
      return 'Add Supporting Documents';
    }
    if (sharedFiles.length > 0 && !finalFile) {
      return ' Upload & Select the file to approve';
    }
    return false;
  }, [handleRemove, selectFile]);

  return (
    <div className="mt-m px-l pb-l">
      {selectHandle && !message && !isReadOnly && (
        <div className="dark-60 secondary-regular mb-m">
          Select Final Version of Supporting Documentation:
        </div>
      )}
      {selectHandle && message && supportSelect && (
        <InlineAlert
          message={message}
          status="attention"
          className="w-full mb-s"
        />
      )}
      {sharedFiles.length > 0 &&
        sharedFiles.map((file: ISharedDocument, idx) => (
          <DocumentCard
            key={`doc${idx}`}
            filename={file.name}
            fileExtension={file.extension}
            date={file.createdAt}
            {...file}
            checked={file?.id === finalFile}
            radioSelect={supportSelect}
            actions={
              <CustomActions
                onRemoveItem={handleRemove}
                file={file}
                finalFile={finalFile}
                isReadOnly={isReadOnly}
                finalFileStatus={finalFileStatus}
              />
            }
            onSelect={() => selectFile(file.id)}
            disabled={isReadOnly}
            className="mb-s"
          />
        ))}
      {uploading.length > 0 &&
        uploading.map((file, idx) => (
          <DocumentCard
            key={`upload-doc${idx}`}
            filename={file.name}
            fileSize={file.size}
            fileExtension={file.type}
            checked={file?.isFinal}
            actions={<CustomActions loading />}
            radioSelect
            onSelect={() => selectFile(file.id)}
            disabled
            className="mb-s"
          />
        ))}
      {sharedFiles.length > 0 && (
        <div className="flex items-start">
          {canUpload && (
            <SmallAddingButton
              onClick={async () => {
                const res = await openFileDialog();
                uploadNewFiles(res);
              }}
            >
              Add Document
            </SmallAddingButton>
          )}
          {definePlacementButton}
        </div>
      )}
      {canUpload && sharedFiles.length < 1 && uploading.length < 1 && (
        <div className="h-full">
          <UploadContainer
            onChange={(files) => {
              uploadNewFiles(files);
            }}
            enabled
            multiple
          />
        </div>
      )}
    </div>
  );
};

export default DocumentsUpload;

import { capitalize } from 'lodash-es';
import React from 'react';
import { IAllRules } from 'types/ReportTableConfig';
import styles from './styles.module.scss';

type Props = IAllRules;

export function PopoverContent({ roleRules, userRules }: Props) {
  const hasRoleRules = Boolean(roleRules.length);
  const hasUserRules = Boolean(userRules.length);

  return (
    <div className="flex flex-col gap-s">
      {hasRoleRules && (
        <>
          <p className="light-60 secondary-semibold">User Role Rules</p>
          {roleRules.map((rule) => (
            <div className={styles.popoverItem} key={rule.categoryPath}>
              <p className="light-60 secondary-regular">Role:</p>
              <p className="dark-60 secondary-semibold">{rule.userRole.name}</p>
              <p className="light-60 secondary-regular">Rule:</p>
              <p className="dark-60 secondary-regular">
                {capitalize(rule.accessType)}
              </p>
            </div>
          ))}
        </>
      )}
      {hasRoleRules && hasUserRules && (
        <span className={styles.popoverDivider} />
      )}
      {hasUserRules && (
        <>
          <p className="light-60 secondary-semibold">User Rules</p>
          {userRules.map((rule) => (
            <div className={styles.popoverItem} key={rule.categoryPath}>
              <p className="light-60 secondary-regular">User:</p>
              <p className="dark-60 secondary-semibold">{rule.user.fullName}</p>
              <p className="light-60 secondary-regular">Rule:</p>
              <p className="dark-60 secondary-regular">
                {capitalize(rule.accessType)}
              </p>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export const IMPORT_RETURN_TEMPLATES = [
  {
    href: '/importTemplates/REturn/CommitmentTemplate.xlsx',
    download: 'Commitment Template.xlsx',
    label: 'Download a commitment template',
  },
  {
    href: '/importTemplates/REturn/ContributionTemplate.xlsx',
    download: 'Contribution Template.xlsx',
    label: 'Download a contribution template',
  },
  {
    href: '/importTemplates/REturn/DistributionTemplate.xlsx',
    download: 'Distribution Template.xlsx',
    label: 'Download a distribution template',
  },
];

export const IMPORT_RETURN_BUTTONS = [
  {
    label: 'Import Commitments',
    type: 'commitments',
  },
  {
    label: 'Import Contributions',
    type: 'contributions',
  },
  {
    label: 'Import Distributions',
    type: 'distributions',
  },
];

import { useGetApiSettingsReportBuilderTemplatesMetaQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { useNavigate, useParams } from '@reach/router';
import {
  DashboardWidgetSectionDto,
  EagleEyeBoard,
  ReportDashboardAsset,
  useDashboardContext,
  useGetApiSettingsReportDashboardsQuery,
  useReportComparisonDashboardAssetsQuery,
  useReportEagleEyeDashboardSettingsById,
  useReportObjectDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';
import { orderBy } from 'lodash-es';
import { useCallback, useEffect } from 'react';

export const useReportSettingsDashboardScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath
    >
  >();
export const useReportBuilderTemplateScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.fullPath
    >
  >();

export const useReportSettingsDashboardScreenQueryParams = () => {
  const [searchParams] = useSearchParams();
  return {
    boardId: searchParams.get('boardId') ?? undefined,
  };
};

export const useReportDashboardSettingsScreenQueryParams = () => {
  const [searchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');
  const boardId = searchParams.get('boardId');
  return {
    assetId: assetId && Number(assetId),
    boardId,
  };
};

export const useNavigateToFirstAssetEffect = ({
  assets,
  path,
  pathParams,
}: {
  assets: Pick<ReportDashboardAsset, 'id'>[];
  path: string;
  pathParams: Record<string, string>;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');

  useEffect(() => {
    if (assetId != null) {
      return;
    }
    navigate(
      generateUrl(path, {
        pathParams,
        queryParams: {
          assetId: assets?.[0]?.id,
        },
      }),
      {
        replace: true,
      },
    );
  }, [assets]);
};

const useSettingsReportDashboardRedirectToFirstAssetEffect = ({
  assets,
}: {
  assets: ReportDashboardAsset[];
}) => {
  const pathParams = useReportSettingsDashboardScreenParams();

  useNavigateToFirstAssetEffect({
    assets,
    path: ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath,
    pathParams,
  });
};

export const useReportBuilderTemplateRedirectToFirstAssetEffect = () => {
  const pathParams = useReportBuilderTemplateScreenParams();
  const { data: metaData } = useGetApiSettingsReportBuilderTemplatesMetaQuery();

  useNavigateToFirstAssetEffect({
    assets: metaData?.assets ?? [],
    path: ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.fullPath,
    pathParams,
  });
};

export const useSettingsReportObjectDashboardRedirectToFirstAssetEffect =
  () => {
    const { assets } = useReportObjectDashboardAssetsQuery();

    useSettingsReportDashboardRedirectToFirstAssetEffect({
      assets,
    });
  };

export const useSettingsReportComparisonDashboardRedirectToFirstAssetEffect =
  () => {
    const { assets } = useReportComparisonDashboardAssetsQuery();

    useSettingsReportDashboardRedirectToFirstAssetEffect({
      assets,
    });
  };

export const NO_BOARD_ID = 'board-none';

export const useNavigateToWidget = () => {
  const navigate = useNavigate();
  const { dashboardId } = useReportSettingsDashboardScreenParams();

  const { assetId: selectedAssetId } =
    useReportDashboardSettingsScreenQueryParams();
  return ({
    widgetId,
    assetId,
    boardId,
  }: {
    widgetId: string;
    boardId: string;
    assetId?: number | string;
  }) =>
    navigate(
      generateUrl(
        ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath,
        {
          pathParams: {
            dashboardId,
            widgetId,
            boardId,
          },
          queryParams: {
            assetId: assetId ?? selectedAssetId,
          },
        },
      ),
    );
};

export const useNavigateToBoard = () => {
  const navigate = useNavigate();
  const { dashboardId } = useDashboardContext();
  const { assetId } = useReportDashboardSettingsScreenQueryParams();

  return useCallback(
    (newBoardId: string) => {
      navigate(
        generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
          pathParams: {
            dashboardId,
          },
          queryParams: {
            assetId,
            boardId: newBoardId,
          },
        }),
        {
          replace: true,
        },
      );
    },
    [assetId, dashboardId],
  );
};

export const useNavigateToFirstBoardEffect = (
  boards: Pick<EagleEyeBoard, 'id'>[] | undefined,
) => {
  const navigateToBoard = useNavigateToBoard();
  const { boardId } = useReportSettingsDashboardScreenQueryParams();

  useEffect(() => {
    if (boards == null || boards.length === 0 || boardId) {
      return;
    }
    navigateToBoard(boards[0].id);
  }, [boards, boardId]);
};

export const useReportSettingsDashboardById = (dashboardId: string) => {
  const { data, isLoading } = useGetApiSettingsReportDashboardsQuery();
  return {
    data: data?.find((d) => d.id === dashboardId),
    isLoading,
  };
};

export const useGetApiSettingsReportDashboardsByIdWithScreenParams = () => {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  return useReportEagleEyeDashboardSettingsById({
    dashboardId,
  });
};

export const orderDashboardSections = <
  S extends Pick<DashboardWidgetSectionDto, 'position'>,
>(
  sections: S[],
) => {
  return orderBy(
    sections,
    [(section) => section.position.y, (section) => section.position.x],
    ['asc', 'asc'],
  );
};

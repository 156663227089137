import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { DelinquencyTrackerWidget } from './ui/DelinquencyTrackerWidget';
import { WidgetContextDateConfigCard } from '../common/ui/state/WidgetContextDateConfigCard';

export const DELINQUENCY_TRACKER_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 6,
    maxH: 8,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Delinquency Tracker',
  icon: 'table',
  Component: DelinquencyTrackerWidget,
  CardConfigComponent: WidgetContextDateConfigCard,
} as const satisfies WidgetConfiguration;

import React, { FC } from 'react';
import { AnimationLoader } from 'stories';
import { navigate, useParams } from '@reach/router';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { useGetDrawInvoicesQuery } from 'bundles/Construction/api/invoices';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import pluralize from 'pluralize';
import Table from 'bundles/Shared/components/Table/Table';
import { drawInvoicesColumns } from './components/useColumns';
import drawStyles from './components/drawStyles.module.scss';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface IDrawPageInvoicesProps {
  backRoute: string;
}

const DrawPageInvoices: FC<IDrawPageInvoicesProps> = ({ backRoute }) => {
  const params = useParams();
  const { data: invoices, isLoading } = useGetDrawInvoicesQuery({
    drawId: params?.drawId,
  });

  const { getUrl } = useNavigation();
  const closeInvoice = () => {
    navigate(
      getUrl(backRoute, {
        legalEntityCode: params?.legalEntityCode,
        requisitionId: params?.requisitionId,
        categoryId: params?.categoryId,
        type: params?.type,
      }),
    );
  };

  const showDetails = (id: string) => {
    navigate(
      getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES_ITEM', {
        legalEntityCode: params.legalEntityCode,
        drawId: params.drawId,
        invoiceId: id,
      }),
    );
  };

  if (isLoading) return <AnimationLoader />;
  return (
    <div>
      <SidePanel
        toggle={closeInvoice}
        header={
          <div>
            <div className="header6-bold text-dark-60">
              {`${invoices?.items.length} ${pluralize(
                'Invoice',
                invoices?.items.length,
              )}`}
            </div>
            <div className="label-regular text-light-60">Draw Request #4</div>
          </div>
        }
        contentClassName={styles.modalLarge}
        classes={{
          header: styles.header,
        }}
        bodyPadding="0"
      >
        {isLoading ? (
          <AnimationLoader />
        ) : (
          <div className={drawStyles.drawInvoicesTable}>
            <Table
              loading={isLoading}
              columns={drawInvoicesColumns(showDetails)}
              items={invoices?.items || []}
              borderLessColumns
              borderLessOutside
              classes={{
                th: 'bg-light',
              }}
            />
          </div>
        )}
      </SidePanel>
    </div>
  );
};

export default DrawPageInvoices;

import { FinancialTableSinglePeriodWidgetConfigDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { financialTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import {
  FinancialTableSinglePeriodWidgetConfigColumnForm,
  transformFinancialTableSinglePeriodWidgetAdjustmentFormToConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/column.form';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { produce } from 'immer';

const upsertColumn = (
  {
    columnId,
    columnForm: column,
    groupId,
  }: {
    groupId: string | null;
    columnForm: FinancialTableSinglePeriodWidgetConfigColumnForm;
    columnId?: string;
  },
  config: FinancialTableSinglePeriodWidgetSection['widgetConfig'],
): FinancialTableSinglePeriodWidgetSection['widgetConfig'] => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex(
      (c) => c.key.toString() === columnId,
    );
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }
    const columnKey = String(
      column.key ?? maxIdGenerator(draft.columns, 'key'),
    );

    draft.columns[columnIndex] = {
      key: Number(columnKey),
      label: column.label,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      ...transformFinancialTableSinglePeriodWidgetAdjustmentFormToConfig(
        column,
      ),
    };

    let vizConfigColumnIndex = draft.viz_config.columns.findIndex(
      (c) => c.key.toString() === columnId,
    );
    if (vizConfigColumnIndex === -1) {
      vizConfigColumnIndex = draft.viz_config.columns.length;
    }

    draft.viz_config.columns[vizConfigColumnIndex] = {
      key: columnKey,
      col_id: columnKey,
      group_id: groupId ?? null,
      order: vizConfigColumnIndex,
      // @ts-expect-error "yup" is not smart enough to infer "precision" type from schema
      value_display_options: column.value_display_options ?? undefined,
    };
  });
};

const updateDefaultOptions = (
  values: FinancialTableSinglePeriodWidgetConfigForm,
  widgetConfig: FinancialTableSinglePeriodWidgetConfigDto,
): FinancialTableSinglePeriodWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    draft.period_types = values.availablePeriodTypes!;
    draft.default_options.period_type = values.defaultPeriodType!;
    draft.default_options.date.period_shift = transformPeriodShiftFormToDto(
      values.period_shift,
    );
  });
};

export const financialTableSinglePeriodUpdaters = {
  ...financialTableUpdaters,
  upsertColumn,
  updateDefaultOptions,
};

import React, { FC, useState } from 'react';
import { Field, Input } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from '../types/types';
import CheckList from 'stories/Checkbox/CheckList';
import FilterBar from '../../Filters/common/filterBar/FilterBar';
import FilterItem from '../../Filters/common/filterItem/FilterItem';
import { includesInLowerCase } from '@/shared/lib/listHelpers';

interface IFormSelectProps extends IFormItem {
  options: {
    value: string;
    description: string;
  }[];
  menuPlacement: 'top' | 'bottom' | 'auto';
}

const FormListSelect: FC<IFormSelectProps> = ({
  title,
  placeholder,
  value,
  formData,
  setFormData,
  fieldName,
  required,
  hint,
  loading,
  options,
  styles,
  isMulti,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchHandle = (e) => {
    setSearchValue(e.target.value);
  };
  const handleChange = (event: { value: string; label: string }): void => {
    setFormData?.({
      ...formData,
      [fieldName]: isMulti ? event : event.value,
    });
  };
  const clearFunc = () => {
    setFormData?.({
      ...formData,
      [fieldName]: null,
    });
  };

  const filteredOptions = options.filter((o) =>
    o.values.some((value) => includesInLowerCase(value.label, searchValue)),
  );

  return (
    <div
      className={cn(
        'form-item',
        'form-item__select',
        isMulti ? 'form-item__select_multi' : '',
        styles?.wrapper,
        styles?.nomb && 'form-item_nomb',
      )}
    >
      <Field labelText={title} required={required} noLabel={!title} hint={hint}>
        <Input
          placeholder={placeholder}
          value={searchValue}
          onChange={searchHandle}
          onClick={() => setOpen(!open)}
          disabled={loading || disabled}
          className={styles?.input}
          size={styles?.size ?? 'l'}
          type={styles?.type ?? 'text'}
        />
        <div className={cn('form-item__list-select_wrapper', styles?.input)}>
          {open && (
            <div>
              <div
                onClick={() => setOpen(!open)}
                className="form-item__list-select_layer"
              />
              <div className="form-item__list-select">
                <div className="form-item__list-select_scrollbar">
                  {filteredOptions.length === 0 && (
                    <div className="my-s text-center">No items found</div>
                  )}
                  {filteredOptions.map((option, idx) => (
                    <div
                      key={`optionList${idx}`}
                      className="form-item__list-select-item"
                    >
                      <div className="label-regular mb-s">
                        {option.category}
                      </div>
                      <CheckList
                        items={option.values.map((item) => ({
                          value: item.id,
                          label: item.label,
                        }))}
                        value={
                          formData[fieldName]?.map((item) => ({
                            value: item.id,
                            label: item.label,
                          })) ?? []
                        }
                        onChange={(items) =>
                          handleChange(
                            items.map((item) => ({
                              label: item.label,
                              id: item.value,
                            })),
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {value && (
          <div className="mt-m">
            <FilterBar
              title={title}
              onClose={clearFunc}
              className="form-item__filter"
            >
              {value.map((item) => (
                <FilterItem key={item.label} label={item.label} />
              ))}
            </FilterBar>
          </div>
        )}
      </Field>
    </div>
  );
};

export default FormListSelect;

import FullWhiteTable from '@/bundles/Shared/components/Table/variants/FullWhiteTable';
import { CapitalInvestment, Distribution } from '@/entities/return';
import { AccruedPreferredReturn } from '@/entities/return/model/types/accruedPreferredReturn';
import {
  formatDate,
  formatDateToQuarterWithPrefix,
  formatUnixDate,
} from '@/shared/lib/formatting/dates';
import { CurrencyFormatter, IconButton, InlineObject } from '@/stories';
import React from 'react';
import MainColumnFormatter from './Columns/MainColumnFormatter';
import { AccruedEntryDto } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { useRemoveEntry } from './hooks/useRemoveEntry';
import { useEditManualEntry } from './hooks/useEditManualEntry';
import { useDeleteApiCapitalInvestmentDistributionsByIdMutation } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { capitalize } from 'lodash-es';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EditAccruedEntryModal } from './modals/EditAccruedEntryModal';

export const AccruedPreferredReturnModalBody = ({
  preferredDistributions,
  items,
  capitalInvestments,
  capitalInvestment,
}: {
  preferredDistributions: Distribution[];
  items: (AccruedPreferredReturn | Distribution)[];
  capitalInvestments: CapitalInvestment[];
  capitalInvestment: CapitalInvestment;
}) => {
  const [deleteDistribution] =
    useDeleteApiCapitalInvestmentDistributionsByIdMutation();
  const removeDistribution = async (id: Distribution['id']) => {
    await deleteDistribution({ id });
  };

  const onRemoveDistribution = useRemoveEntry({
    items: preferredDistributions,
    type: 'distribution',
    removeAction: removeDistribution,
    capitalInvestments,
  });

  const onEditManualDistribution = useEditManualEntry({
    capitalInvestment,
    type: 'distribution',
  });

  const createdBy = (row: AccruedEntryDto) =>
    row.createdBy ? row.createdBy.fullName : 'system';

  const { openModal } = useModal();
  const onEditManualAccruedEntry = async (entry: AccruedEntryDto) => {
    await openModal(EditAccruedEntryModal, {
      row: entry,
      investmentEntityName: capitalInvestment.name,
    });
  };

  const accruedEntryColumn = (row: AccruedEntryDto) => (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-xs font-semibold text-neutral-850">
          {capitalize(row.periodType)}
        </div>
        <IconButton
          variant="secondary"
          iconName="editAlt"
          onClick={() => onEditManualAccruedEntry(row)}
        />
      </div>
      <div className="pt-8 text-xs text-neutral-500">
        Entry created by {createdBy(row)} on&nbsp;
        <span className="text-neutral-850">
          {formatUnixDate(row.createdAt as DateString, 'MMM DD, YYYY hh:mm A')}
        </span>
      </div>
    </div>
  );

  const columns = [
    {
      dataField: 'entries',
      headerClasses: 'w-[300px]',
      text: 'Entries',
      formatter: ({ row }) => {
        const isAccrued = row._type !== 'distribution';

        if (isAccrued) {
          return accruedEntryColumn(row);
        }

        return (
          <MainColumnFormatter
            type={row._type}
            entry={row}
            actions={{
              onRemoveEntry: onRemoveDistribution,
              onEditManualEntry: onEditManualDistribution,
            }}
          />
        );
      },
    },
    {
      dataField: 'date',
      headerClasses: 'w-[110px]',
      text: 'Date',
      formatter: ({ row }: { row: AccruedPreferredReturn | Distribution }) => (
        <div className="flex flex-col gap-tw-0.5">
          <span className="secondary-regular text-dark-60">
            {formatDate(row.date as DateString, 'MMM DD, YYYY')}
          </span>
          {row.periodType === 'quarterly' && (
            <span className="secondary-regular text-neutral-500">
              {formatDateToQuarterWithPrefix(
                (row.period ?? row.date) as DateString,
              )}
            </span>
          )}
          {row.periodType === 'monthly' && (
            <span className="secondary-regular text-neutral-500">
              {formatDate(row.period ?? (row.date as DateString), 'MMM-YYYY')}
            </span>
          )}
        </div>
      ),
    },
    {
      dataField: 'amount',
      headerClasses: 'w-[98px]',
      text: 'Amount',
      formatter: ({ row }) => (
        <CurrencyFormatter value={row.amount} classes={{ value: 'text-xs' }} />
      ),
    },
    {
      dataField: 'type',
      headerClasses: 'w-[141px]',
      text: 'Type',
      formatter: ({ row }) => {
        const isAccrued = row._type !== 'distribution';

        return (
          <InlineObject
            object={isAccrued ? 'Accrued Pref.' : 'Preferred'}
            className={
              isAccrued
                ? 'bg-violet-000 text-violet-055 hover:bg-violet-light-4'
                : ''
            }
          />
        );
      },
    },
  ];

  const totalEndingBalance = items.reduce((acc, item) => acc + item.amount, 0);

  return (
    <div>
      <div className="flex justify-between text-xs font-semibold text-neutral-550">
        <div className="text-xs font-semibold text-neutral-550">TOTAL:</div>
        <div>
          <CurrencyFormatter value={totalEndingBalance} />
        </div>
      </div>

      <div className="flex w-full flex-col gap-tw-2 pt-8">
        <FullWhiteTable items={items} columns={columns} />
      </div>
    </div>
  );
};

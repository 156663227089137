import { cn } from '@/shared/lib/css/cn';
import { Link } from '@reach/router';
import React from 'react';
import styles from './GeneralSettingsNavigationSection.module.scss';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';

interface Props extends Omit<React.ComponentProps<typeof Link>, 'to'> {
  title: string;
  active: boolean;
  subtitle?: React.ReactNode;
  link?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export const GeneralSettingsNavigationSection = ({
  title,
  subtitle,
  link,
  active,
  onClick,
  disabled,
  isLoading,
  ...props
}: Props) => {
  if (isLoading) {
    return <SkeletonBlock className="w-[166px]" />;
  }
  return (
    <Link
      className={cn(styles.container, {
        [styles.active]: active,
        'cursor-default bg-neutral-150 hover:bg-neutral-150': disabled,
      })}
      to={link ?? ''}
      onClick={(e) => {
        if (disabled) return;
        if (link == null) {
          e.preventDefault();
        }
        onClick?.();
      }}
      {...props}
    >
      <div>
        <div className={cn(styles.title, 'inline-semibold')}>{title}</div>
        <div className={cn(styles.subtitle, 'secondary-regular')}>
          {subtitle}
        </div>
      </div>
    </Link>
  );
};

export default GeneralSettingsNavigationSection;

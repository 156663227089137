import { SearchInput } from '@/stories';
import {
  PaginationWidgetState,
  QueryWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';

export function WidgetStateSearchInput({
  state,
  onStateChange,
  ...props
}: WidgetStateProps<QueryWidgetState & PaginationWidgetState> &
  Omit<React.ComponentProps<typeof SearchInput>, 'value' | 'onChange'>) {
  return (
    <SearchInput
      placeholder="Search"
      size="s"
      className="w-[300px]"
      value={state.query}
      onChange={(e) =>
        onStateChange({ ...state, query: e.target.value, page: 1 })
      }
      {...props}
    />
  );
}

import React, { FC } from 'react';
import { Tooltip } from 'stories/index';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import BoardCard from './BoardCard';

const CreatedInvoiceCard: FC<Invoice> = ({ title, uploadedAt, source }) => (
  <BoardCard>
    <div className="flex flex-col gap-tw-4">
      <Tooltip titleText={title} maxWidth="20rem">
        <div className="inline-regular text-ellipsis">{title}</div>
      </Tooltip>
      <div className="flex gap-tw-1">
        <span className="light-90 label-regular text-ellipsis">
          {source.email || source.name}
        </span>
        <span className="light-60 secondary-regular">on</span>
        <span className="light-90 label-regular">{uploadedAt}</span>
      </div>
    </div>
  </BoardCard>
);

export default CreatedInvoiceCard;

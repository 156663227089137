import React, { useLayoutEffect, useState } from 'react';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { configChartColors, createXYChart } from 'lib/amcharts/utils';
import { useChartSeriesData } from '../../chartUtils';

export function StackedBarV5(props) {
  const { employeeUtilization } = props;
  const [chartItemsToUpdate, setChartItemsToUpdate] = useState([]);

  const seriesData = useChartSeriesData(employeeUtilization, ['employee', 'weight']);

  const addInChartItemsToUpdate = (item) => {
    setChartItemsToUpdate((prev) => [...prev, item]);
  };

  useLayoutEffect(() => {
    const root = am5.Root.new('employeeUtilizationStackedBar');

    root.setThemes([am5themesAnimated.new(root)]);

    const chart = createXYChart(root);

    configChartColors(chart, [
      '#2fa07d',
      '#46af98',
      '#61bdb1',
      '#66a3b1',
      '#4c899c',
      '#346f87',
      '#1c5771',
      '#6f8292',
    ]);

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.3,

        renderer: am5xy.AxisRendererY.new(root, {
          strokeWidth: 3,
          strokeOpacity: 1,
          opposite: true,
        }),
      }),
    );

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Deal Count',
        y: am5.p50,
        centerX: am5.p50,
      }),
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
        }),
        categoryField: 'employee',
      }),
    );

    addInChartItemsToUpdate(xAxis);
    xAxis.data.setAll(employeeUtilization);

    // Create series
    seriesData.map(({ name, valueYField }) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name,
          xAxis,
          yAxis,
          stacked: true,
          valueYField,
          categoryXField: 'employee',
          tooltip: am5.Tooltip.new(root, {
            labelText: `${name}: [bold][/][bold]{valueY}[/]`,
          }),
        }),
      );
      series.data.setAll(props.employeeUtilization);
      series.columns.template.setAll({
        width: am5.percent(95),
        height: am5.percent(100),
      });

      series.bullets.push((r) => am5.Bullet.new(r, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(r, {
          text: '{valueY}',
          fill: am5.color('#fff'),
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          populateText: true,
        }),
      }));

      addInChartItemsToUpdate(series);
    });

    // Create Y-axis

    const yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    yAxis2.children.unshift(
      am5.Label.new(root, {
        rotation: 90,
        text: 'Utilization (%)',
        y: am5.p50,
        centerX: am5.p50,
      }),
    );

    const seriesLines = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Utilization (%)',
        xAxis,
        yAxis: yAxis2,
        valueYField: 'weight',
        categoryXField: 'employee',
      }),
    );
    seriesLines.set(
      'numberFormatter',
      am5.NumberFormatter.new(root, {
        numberFormat: "#.0'%'",
      }),
    );

    seriesLines.bullets.push((r) => am5.Bullet.new(r, {
      locationX: 0.5,
      locationY: 0.5,
      sprite: am5.Circle.new(root, {
        radius: 30,
        strokeWidth: 3,
        stroke: am5.color('#febe46'),
        fill: am5.color('#0d1c3c'),
      }),
    }));

    seriesLines.bullets.push((r) => am5.Bullet.new(r, {
      locationX: 0.5,
      locationY: 0.5,
      sprite: am5.Label.new(r, {
        text: "{valueY.formatNumber('#.')}%",
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        populateText: true,
        fill: am5.color('#fff'),
      }),
    }));

    addInChartItemsToUpdate(seriesLines);
    seriesLines.data.setAll(props.employeeUtilization);

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        useDefaultMarker: true,
        x: am5.p50,
      }),
    );
    legend.data.setAll(chart.series.values);

    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis,
      }),
    );

    return () => {
      root.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    chartItemsToUpdate.forEach((item) => {
      item.data.setAll(employeeUtilization);
    });
  }, [employeeUtilization]);

  return (
    <div className="panel-body">
      <div id="employeeUtilizationStackedBar" style={{ width: '100%', height: '500px' }} />
    </div>
  );
}

import LegalEntitiesFilter from 'bundles/REport/components/financials/filter/LegalEntitiesFilter';
import {
  ReportEagleEyeDashboard,
  selectReportDashboardSelectedObjectLegalEntityIds,
  updateObjectLegalEntityIds,
} from 'bundles/Shared/entities/dashboard';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { mustFind } from 'lib/typeHelpers';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props {
  dashboardId: ReportEagleEyeDashboard['id'];
  objectId: number;
  legalEntities: Pick<LegalEntity, 'id' | 'name'>[];
}

export function ObjectDashboardLegalEntitiesFilter({
  dashboardId,
  objectId,
  legalEntities,
}: Props) {
  const dispatch = useAppDispatch();
  const objectLegalEntityIds = useAppSelector((state) =>
    selectReportDashboardSelectedObjectLegalEntityIds(state, {
      dashboardId,
      selectedObjectId: objectId,
    }),
  );

  return (
    <LegalEntitiesFilter
      onSelectedChange={(newLegalEntities) => {
        dispatch(
          updateObjectLegalEntityIds({
            legalEntityIds: mapListToIds(newLegalEntities),
            objectId,
            dashboardId,
          }),
        );
      }}
      selectedLegalEntities={objectLegalEntityIds.map((id) =>
        mustFind(legalEntities, (le) => le.id === id),
      )}
      legalEntities={legalEntities}
    />
  );
}

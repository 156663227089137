import React, { useState } from 'react';
import ContributionOverviewCard from './cards/ContributionOverviewCard';
import CapitalBreakdownCard from './cards/CapitalBreakdownCard';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import CapitalHistoryCard from './cards/CapitalHistoryCard';
import Snapshots from './snapshots/Snapshots';
import InvestmentEntitiesFilter from './investmentEntitiesFilter/InvestmentEntitiesFilter';
import { EMPTY_PORTFOLIO } from './constants';
import { IInvestmentEntity } from './types';
import { CapitalDetailsTable } from './cards/CapitalDetailsTable';
import styles from './Dashboard.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { useGetApiPortfolioQuery } from 'bundles/Shared/shared/api/portfolioEnhancedApi';
import { AnimationLoader } from '@/stories';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';

const CUSTOMERS_WITHOUT_CONTRIBUTION_OVERVIEW = ['magma'];

const SectionHeader = ({ children }: React.PropsWithChildren) => (
  <h5 className="light-60 header5-regular">{children}</h5>
);

export function Dashboard() {
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const [activeInvestmentEntitiesIds, setActiveInvestmentEntitiesIds] =
    useState<IInvestmentEntity['id'][]>([]);
  const { data: portfolio = EMPTY_PORTFOLIO, isLoading } =
    useGetApiPortfolioQuery({
      investmentEntityIds: activeInvestmentEntitiesIds,
    });

  if (isLoading) {
    return <AnimationLoader />;
  }

  const {
    capitalDetails,
    contributionOverview,
    capitalBreakdown,
    capitalHistory,
  } = portfolio;

  const hideContributionOverviewCard =
    CUSTOMERS_WITHOUT_CONTRIBUTION_OVERVIEW.includes(
      window.symmetreConfig.customer.subdomain,
    );

  return (
    <div className={cn('flex gap-tw-8', { 'flex-col': !isMediumWidth })}>
      <div className={styles.investmentEntitiesFilterContainer}>
        <InvestmentEntitiesFilter
          activeInvestmentEntitiesIds={activeInvestmentEntitiesIds}
          onActiveInvestmentEntitiesChange={setActiveInvestmentEntitiesIds}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="mb-tw-8 flex flex-col gap-tw-4">
          <SectionHeader>Snapshots</SectionHeader>
          <Snapshots snapshots={portfolio.snapshots} />
        </div>

        <div className="mb-xl flex flex-col gap-m">
          <SectionHeader>Snapshots</SectionHeader>
          <div className="flex flex-col gap-tw-4 md:flex-row">
            {!hideContributionOverviewCard && (
              <ContributionOverviewCard data={contributionOverview} />
            )}
            <CapitalBreakdownCard data={capitalBreakdown} />
          </div>

          <DashboardWidgetCard className="p-0">
            <div className="flex flex-col">
              <CapitalHistoryCard data={capitalHistory} />
              {isMediumWidth && (
                <CapitalDetailsTable
                  capitalDetails={capitalDetails}
                  capitalHistory={capitalHistory}
                />
              )}
            </div>
          </DashboardWidgetCard>
        </div>
      </div>
    </div>
  );
}

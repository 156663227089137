import React, { useEffect } from 'react';
import { NavigateFn, Router, useParams } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  AnimationLoader,
  Button,
  SearchInput,
  StreamlinedContainer,
} from 'stories';
import { currentUserAllowedTo, TProductNames } from 'lib/permissions';
import PayAppSummaryComp from 'bundles/Construction/components/PayApp/PayAppSummary';
import PayAppDetail from 'bundles/Construction/components/PayApp/PayAppDetail/PayAppDetail';
import ContinuationSheet from 'bundles/Construction/components/PayApp/ContinuationSheet';
import {
  createPayApp,
  fetchLastPayApp,
  fetchPayApp,
  searchPayApps,
  updatePayAppSummary,
} from './actions';
import PayAppForm from './components/PayApp/PayAppForm';
import PayAppSummaryForm from './components/PayApp/PayAppSummary/PayAppSummaryForm';
import SearchPayApp from './components/PayApp/SearchPayApp/SearchPayApp';

interface Props {
  navigate: NavigateFn;
}

const PayAppsContainer = ({ navigate }: Props) => {
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const payApp = useAppSelector((state) => state.payApps);
  const asset = useAppSelector((state) => state.currentAsset);
  const params = useParams();
  const dispatch = useAppDispatch();
  const legalEntityCode =
    params?.legalEntityCode ||
    asset?.legalEntities[0].code ||
    legalEntity?.code;

  useEffect(() => {
    dispatch(fetchLastPayApp(legalEntityCode));
  }, []);

  const handleSubmitPayApp = (data) => {
    dispatch(createPayApp(legalEntityCode, data))
      .then(() => navigate('.'))
      .then(() => {
        // long polling for heavy operation (checking ending every 15 seconds)
        const loadingLastPayAppData = setInterval(async () => {
          const payload = await dispatch(fetchLastPayApp(legalEntityCode));
          if (
            Object.keys(payload?.payAppSummary).length &&
            payload?.continuationSheet?.items.length
          ) {
            clearInterval(loadingLastPayAppData);
          }
        }, 15000);
      });
  };

  const handleSubmitPayAppSummary = (legalEntityCode, payAppId, id, data) => {
    dispatch(updatePayAppSummary(legalEntityCode, payAppId, id, data)).then(
      () => navigate('.'),
    );
  };

  const handleSubmitPayAppSearch = (e, sort_order, sort_field) => {
    dispatch(
      searchPayApps(
        legalEntityCode,
        e?.target?.value ?? '',
        sort_order,
        sort_field,
      ),
    );
  };

  const handleChoosePayApp = (id) => {
    dispatch(fetchPayApp(legalEntityCode, id));
    navigate('.');
  };

  const onClose = () => navigate('.');

  if (!payApp) return <AnimationLoader />;

  return (
    <div className="custom-container">
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center justify-between mb-l mt-xl">
          <h2 className="font-weight-700">Pay Apps</h2>
        </div>
        <div className="flex items-center">
          <div className="pay-app-search__badge">
            <div>Chosen</div>
            <div className="pay-app-search__number">
              Pay App {payApp?.number}
            </div>
          </div>
          <div
            onClick={() => navigate('search')}
            className="pay-app-search ml-s"
          >
            <SearchInput placeholder="Search Pay App" onChange={() => {}} />
          </div>
          {currentUserAllowedTo(
            'configure',
            TProductNames.CHANGE_MANAGEMENT,
          ) && (
            <div className="py-15 text-right">
              <Button
                onClick={() => navigate('new')}
                variant="primary"
                className="ml-10"
                iconName="documentAlt"
                iconPosition="right"
              >
                Create Pay App
              </Button>
            </div>
          )}
        </div>
      </div>
      {payApp && payApp?.id ? (
        <div>
          <div className="pay-apps-header">
            <div className="pay-apps-header__summary">
              <PayAppSummaryComp
                payAppSummary={payApp.payAppSummary}
                navigate={navigate}
              />
            </div>
            <div className="pay-apps-header__detail">
              <PayAppDetail payApp={payApp} />
            </div>
          </div>
          <ContinuationSheet payApp={payApp} />
        </div>
      ) : (
        <StreamlinedContainer>
          <div className="text-center">
            There is no pay apps for this legal entity.
          </div>
        </StreamlinedContainer>
      )}
      <Router primary={false}>
        <PayAppForm
          path="new"
          title="Create PayApp"
          onSubmit={handleSubmitPayApp}
          onClose={onClose}
        />
        <SearchPayApp
          path="search"
          title="Pay Apps"
          onSubmit={handleSubmitPayAppSearch}
          onClose={onClose}
          sPayApps={payApp?.sPayApps}
          handleChoosePayApp={handleChoosePayApp}
          countPayApp={payApp?.countPayApp}
        />
        <PayAppSummaryForm
          payApp={payApp}
          payAppSummary={payApp?.payAppSummary}
          onSubmit={handleSubmitPayAppSummary}
          path="edit"
          title="Create PayApp"
          onClose={onClose}
        />
      </Router>
    </div>
  );
};

export default PayAppsContainer;

import {
  CapitalInvestmentEntryType,
  CONTRIBUTION_ENTRY_TYPE,
  DISTRIBUTION_ENTRY_TYPE,
} from 'bundles/REturn/actions/types';
import { ICapitalInvestment } from 'bundles/REturn/types';
import Table from 'bundles/Shared/components/Table/Table';
import { IRowBase } from 'bundles/Shared/components/Table/types';
import { TConfirmFuncProps } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { ComponentProps } from 'react';
import {
  entryCreationConfirmModalProps,
  entryCreationConfirmWorkIsDoneModalProps,
  MIN_ITEMS_COUNT_FOR_SPLIT,
} from './consts';
import { THIRD_STEP_GO_BACKWARD_BUTTON_TEXT_JSX } from './dumbJSX';
import { EntryAmountAndKind } from './splitModal/SplitTxBetweenKinds';
import { SelectInvEntProps } from './steps/SelectInvestmentEntityStep';

export const firstStepGoForwardHintText = (withKind: boolean) => (
  <p className="label-regular text-center text-light-60">
    Select Transaction{withKind ? ' and Category' : ''} to continue
  </p>
);

export const secondStepGoForwardHintText = (withKind: boolean) => (
  <p className="label-regular text-center text-light-60">
    Select Investment Entity{withKind ? ' and Category' : ''} to continue
  </p>
);

export const createEntryWorkflowMap = ({
  stepIdx,
  entryType,
}: {
  stepIdx: number;
  entryType: CapitalInvestmentEntryType;
}) => {
  const singleEntryTypeName = pluralize(entryType, 1);
  const sharedProperties = {
    entryCreationText: `Create ${capitalize(singleEntryTypeName)}`,
  } as const;

  switch (stepIdx) {
    case 0: {
      return {
        ...sharedProperties,
        goBackwardButtonTextJSX: null,
        goForwardButtonText: 'Accept',
        goForwardHintTextJSX: firstStepGoForwardHintText,
      } as const;
    }

    case 1: {
      return {
        ...sharedProperties,
        goBackwardButtonTextJSX: 'Back',
        goForwardButtonText: 'Accept',
        goForwardHintTextJSX: secondStepGoForwardHintText,
      } as const;
    }

    default: {
      return {
        ...sharedProperties,
        goBackwardButtonTextJSX: THIRD_STEP_GO_BACKWARD_BUTTON_TEXT_JSX,
        goForwardButtonText: sharedProperties.entryCreationText,
        goForwardHintTextJSX: () => null,
      } as const;
    }
  }
};

export const createReviewInfoMap = ({
  transactionsLen,
}: {
  transactionsLen: number;
}) => {
  const isSplit = transactionsLen >= MIN_ITEMS_COUNT_FOR_SPLIT;
  return {
    workflowActionType: isSplit ? 'splitting' : 'creating',
    reviewEndingText: isSplit ? 'amount as following' : 'following amounts',
  } as const;
};

type TableProps<R extends IRowBase> = ComponentProps<typeof Table<R>>;
type TableSelectionProps<R extends IRowBase> =
  | Pick<TableProps<R>, 'singleSelectedRow' | 'setSingleSelectedRow'>
  | Pick<TableProps<R>, 'selectedRows' | 'setSelectedRows'>;

export function createTableSelectionProps({
  singleSelection,
  selectedRows,
  setSelectedRows,
}: Omit<SelectInvEntProps, 'entryType'>) {
  const singleSelectedRow =
    selectedRows.length > 0 ? selectedRows[0] : undefined;

  const setSingleSelectedRow = (row: ICapitalInvestment) =>
    setSelectedRows(row ? [row] : []);

  const tablePropsForSelectionRows: TableSelectionProps<ICapitalInvestment> =
    singleSelection
      ? {
          singleSelectedRow,
          setSingleSelectedRow,
        }
      : {
          selectedRows,
          setSelectedRows,
        };

  return tablePropsForSelectionRows;
}

function assertIsEntryType(
  type: string,
): asserts type is CapitalInvestmentEntryType {
  if (type !== CONTRIBUTION_ENTRY_TYPE && type !== DISTRIBUTION_ENTRY_TYPE) {
    throw new Error(
      'Entry type in search params should be either "contributions" or "distributions"',
    );
  }
}

export function getEntryTypeFromSearchParams(
  entryTypeFromSearchParams: string | undefined,
): CapitalInvestmentEntryType {
  const entryType = entryTypeFromSearchParams ?? CONTRIBUTION_ENTRY_TYPE;

  try {
    assertIsEntryType(entryType);
    return entryType;
  } catch (error) {
    console.error(error);
    return CONTRIBUTION_ENTRY_TYPE;
  }
}

export function createConfirmSuccessModalProps({
  entryType,
  areUnfilledTxLeft,
  unfilledTxsLen,
  itemsText,
}: {
  entryType: CapitalInvestmentEntryType;
  areUnfilledTxLeft: boolean;
  unfilledTxsLen: number;
  itemsText: string;
}): TConfirmFuncProps {
  const restProps = areUnfilledTxLeft
    ? entryCreationConfirmModalProps
    : entryCreationConfirmWorkIsDoneModalProps;

  return {
    subtitle: (
      <>
        <p className="inline-semibold">
          {areUnfilledTxLeft ? (
            <>
              You have {unfilledTxsLen} unfilled {itemsText}
            </>
          ) : (
            <>You have no unfilled {itemsText} left.</>
          )}
        </p>
        {areUnfilledTxLeft && (
          <p className="inline-regular">
            Would you like to continue creating {entryType}?
          </p>
        )}
      </>
    ),
    ...restProps,
  };
}

export function hasNotNulls<T>(arr: (T | null)[]): arr is T[] {
  return !arr.some((item) => item === null);
}

export const createEmptyEntryAmountAndKind = (
  id: EntryAmountAndKind['id'],
): EntryAmountAndKind => ({
  id,
  amount: 0,
  kind: undefined,
});

import React from 'react';

export const SegmentFormModalTopJSX = (
  <>
    <h6 className="font-weight-600">Basic</h6>
    <label className="mb-xs mt-s">
      Segment Name<span className="red">*</span>
    </label>
  </>
);

export const EntitiablesLabelJSX = (
  <h6 className="font-weight-600 mb-m">
    Related Assets & Funds<span className="red">*</span>
  </h6>
);

export const SegmentFormModalBottomJSX = (
  <div className="secondary-regular mt-m">
    <p>Here you can choose which assets and funds</p>
    <p>you want to include in the segment</p>
  </div>
);

import { useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import {
  Formula,
  formulaHasInvalidReference,
  addPrefixToFormulaReference,
  transformFormulaLabelToReference,
} from 'bundles/Shared/entities/formula';
import { EditFormulaForm, FORMULA_SCHEMA } from './config';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PostApiSettingsReportFormulasApiArg,
  PutApiSettingsReportFormulasByIdApiArg,
} from 'bundles/Shared/shared/api/settingsReportFormulasGeneratedApi';
import { UseFormProps } from 'react-hook-form/dist/types';
import { isEqual, pick } from 'lodash-es';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { useEffect } from 'react';
import {
  isSystemFormulaAndNotUniform,
  isTagNew,
} from 'bundles/Shared/entities/tag';

export const useFormulaFields = (
  props: Omit<UseFormProps<EditFormulaForm>, 'resolver'>,
) =>
  useForm<EditFormulaForm>({
    resolver: yupResolver(FORMULA_SCHEMA),
    ...props,
  });

export const prepareFormulaFormValuesForRequest = <
  T extends 'create' | 'update',
>(
  values: EditFormulaForm,
  id?: Formula['id'] | null,
): T extends 'create'
  ? PostApiSettingsReportFormulasApiArg
  : PutApiSettingsReportFormulasByIdApiArg =>
  ({
    id,
    body: {
      label: values.label,
      description: values.description,
      expression: values.expression,
      reference: addPrefixToFormulaReference(values.reference),
      tags:
        values.tags?.map((tag) => ({
          name: isTagNew(tag) ? tag.name : undefined,
          id: isTagNew(tag) ? undefined : tag.id,
        })) ?? [],
    },
  }) as T extends 'create'
    ? PostApiSettingsReportFormulasApiArg
    : PutApiSettingsReportFormulasByIdApiArg;

export const isFormulaFormEquals = (a: EditFormulaForm, b: EditFormulaForm) => {
  const propsToCompare: (keyof EditFormulaForm)[] = [
    'label',
    'description',
    'reference',
    'tags',
    'expression',
  ];
  if (a.id == null || b.id == null) {
    return false;
  }
  return isEqual(pick(a, propsToCompare), pick(b, propsToCompare));
};

export const useFormulaLabelToReferenceEffect = (
  methods: UseFormReturn<EditFormulaForm>,
) => {
  const { watch, setValue, getFieldState, getValues } =
    useFormContext<EditFormulaForm>() ?? methods;

  const getFieldName = <T extends FieldPath<EditFormulaForm>>(name: T) => name;

  useEffect(() => {
    const subscription = watch((_, { name, type: eventType }) => {
      const { isTouched: isReferenceTouched } = getFieldState(
        getFieldName('reference'),
      );

      if (
        eventType === 'change' &&
        name === getFieldName('label') &&
        !isReferenceTouched &&
        getValues(getFieldName('id')) == null
      ) {
        const label = getValues(getFieldName('label'));

        setValue(
          getFieldName('reference'),
          transformFormulaLabelToReference(label),
        );
      }
    });
    return () => subscription.unsubscribe();
  }, []);
};

export const isNewFormula = (formula: Pick<EditFormulaForm, 'id'>): boolean =>
  formula.id == null;

export const cloneFormula = <T extends EditFormulaForm>(formula: T): T => ({
  ...formula,
  id: undefined,
});

export const canCloneFormula = (
  formula: Pick<Formula, 'invalidReferences'> & {
    id: undefined | null | string;
  },
): boolean => !formulaHasInvalidReference(formula);

export const canUpdateFormula = (formula: Formula) => {
  return !isSystemFormulaAndNotUniform(formula);
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import { InvoiceDocument } from '@/shared/types/reconcile/Invoice';
import { stringify } from 'qs';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import {
  IReconcileCategorizedEntity,
  IReconcileDevelopmentCategory,
} from 'bundles/Construction/types/DevelopmentCategory';
import { getReconcileCOforRE } from 'bundles/Construction/actions/reallocation';

export interface IGetDevelopmentCategoryParams {
  legalEntityCode: string;
  without_sovcs: 'true' | 'false';
}

export const reconcileCoreApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
  }),
  reducerPath: 'reconcileCore',
  endpoints: (build) => ({
    getDevelopmentCategory: build.query<
      IReconcileDevelopmentCategory[],
      IGetDevelopmentCategoryParams
    >({
      query: ({ legalEntityCode, ...params }) =>
        `reconcile/development/legal_entities/${legalEntityCode}/development_categories${stringify(
          params,
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
    }),
    getDevelopmentCategoryFlat: build.query<
      IReconcileCategorizedEntity[],
      IGetDevelopmentCategoryParams
    >({
      query: ({ legalEntityCode, ...params }) =>
        `reconcile/development/legal_entities/${legalEntityCode}/development_categories/flat${stringify(
          params,
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
    }),
    getReconcileCOForCE: build.query<
      ReturnType<typeof getReconcileCOforRE>,
      {
        legalEntityCode: string;
        changeOrderId: number;
      }
    >({
      query: ({ legalEntityCode, changeOrderId }) =>
        getReconcileCOforRE(legalEntityCode, changeOrderId),
    }),
    getInvoiceDocument: build.query<
      InvoiceDocument,
      {
        legalEntityCode: string;
        invoiceId: string;
      }
    >({
      query: (params) =>
        `reconcile/development/invoice_documents/${params.invoiceId}`,
    }),
  }),
});

export const {
  useGetDevelopmentCategoryQuery,
  useGetDevelopmentCategoryFlatQuery,
  useGetInvoiceDocumentQuery,
  useGetReconcileCOForCEQuery,
} = reconcileCoreApi;

import * as yup from 'yup';
import {
  ADJUSTMENT_EXPRESSION_SCHEMA,
  FONT_STYLES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { HistoricalReviewTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { TableVizConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { transformRowSettingsToForm } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { VALUE_DISPLAY_OPTIONS_SCHEMA } from '@/shared/lib/formatting/displayOptions';
import { HIGHLITHING_RULES_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HighlightingRulesField';

export const HISTORICAL_TABLE_WIDGET_CONFIG_ROW_SCHEMA = yup.object().shape({
  key: yup.number(),
  label: yup.string().required(),
  expression: yup.string().required(),
  value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
  adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
  font_weight: yup.string().oneOf(FONT_STYLES).optional().default(undefined),
  highlighting_rules: HIGHLITHING_RULES_SCHEMA.optional()
    .nullable()
    .default(undefined),
});

export type HistoricalTableWidgetRowForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_ROW_SCHEMA
>;
export type HistoricalReviewTableWidgetConfigRow =
  HistoricalReviewTableWidgetConfigDto['rows'][number];

export const transformRowConfigToForm = (
  rowConfig: HistoricalReviewTableWidgetConfigRow,
  rowSettings: TableVizConfigRow,
): HistoricalTableWidgetRowForm => {
  return {
    key: rowConfig.key,
    label: rowConfig.label,
    expression: rowConfig.expression,
    adjustment: rowConfig.adjustment_expression,
    ...transformRowSettingsToForm(rowSettings),
  };
};

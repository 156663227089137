import { useHovered } from '@/shared/lib/hooks/useHovered';
import React from 'react';
import { Button, SmallDocumentCard } from 'stories';
import BudgetAvatar from 'stories/BudgetAvatar/BudgetAvatar';
import { ISharedDocument } from 'types/SharedDocument';
import dayjs from 'dayjs';
import { getIconParamsByExtension } from 'stories/FileIcon/FileIcon';
import { TMentionedUser } from 'bundles/REconcile/types/Comments';
import CommentContent from 'bundles/REconcile/components/comments/CommentContent';
import { downloadSharedFile } from 'lib/sharedFile';
import styles from './commentPreviewSection.module.scss';

interface Props {
  message: string;
  createdAt: number;
  attachments: ISharedDocument[];
  fullName: string;
  code?: string;
  repliesCount: number;
  onRepliesClick: () => void;
  mentionedUsers?: TMentionedUser[];
}

export const CommentPreviewSection = ({
  fullName,
  code,
  createdAt,
  message,
  attachments,
  repliesCount,
  mentionedUsers = [],
  onRepliesClick,
}: Props) => {
  const { onMouseLeave, onMouseOver, hovered } = useHovered();

  return (
    <div
      className={styles.card}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex justify-between">
        <div className="flex">
          <div className="mr-s">
            <BudgetAvatar fullName={fullName} />
          </div>
          <div>
            <div className="dark-60 secondary-semibold">{fullName}</div>
            <div className="light-60 secondary-regular">
              {dayjs(createdAt).fromNow()}
            </div>
          </div>
        </div>
      </div>
      {code && (
        <div className="flex mt-s">
          <div className="light-60 secondary-regular mr-xs">Row:</div>
          <div className="dark-60 secondary-semibold">{code}</div>
        </div>
      )}
      <CommentContent
        className="dark-60 mt-s"
        mentionedUsers={mentionedUsers}
        message={message}
      />
      <div className="mt-s">
        {attachments.map((a) => (
          <SmallDocumentCard
            key={a.id}
            className="mw-full flex justify-start text-ellipsis"
            iconName={getIconParamsByExtension(a.extension).iconName}
            iconColorClassName={
              getIconParamsByExtension(a.extension).iconColorClassName
            }
            label={a.name}
            onClick={() => downloadSharedFile(a)}
          />
        ))}
      </div>
      <div className="mt-s">
        <Button
          onClick={onRepliesClick}
          size="s"
          className="w-90p"
          variant="secondary"
        >
          {hovered ? 'Reply' : `replies ${repliesCount}`}
        </Button>
      </div>
    </div>
  );
};

export default CommentPreviewSection;

export enum TSort {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortType = 'asc' | 'desc';

export interface ISortParams<T = string> {
  field: T;
  order: SortType | TSort;
}

export interface ISortParamsField<T = string> {
  sort?: ISortParams<T>;
}

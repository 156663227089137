import React, { PropsWithChildren } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { uiPDFStyles } from 'bundles/Shared/components/PDFLibrary/ui/uiPDFStyles';

type SmallCellProps = {
  styles: {
    wrapper: object;
    text?: object;
  };
} & PropsWithChildren;

export const SmallCell = ({ styles, children }: SmallCellProps) => (
  <View style={styles.wrapper}>
    <Text style={styles.text ?? uiPDFStyles.smallText}>{children}</Text>
  </View>
);

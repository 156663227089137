import { VariableValueType } from '@/bundles/Shared/entities/formula';
import { getNumberFormatterByDisplayType } from '@/shared/lib/formatting/table';

export const ThresholdGlobalValue = ({
  value,
  valueType,
}: {
  value: number;
  valueType: VariableValueType;
}) => {
  const Component = getNumberFormatterByDisplayType({
    displayType: valueType,
    precision: 0,
  });
  return <Component value={value} />;
};

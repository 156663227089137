import {
  FieldType,
  FieldTypeView,
  IFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { optionsReconcileFor, viewNameReconcileOption } from 'lib/helpers';
import { creatableSelectMessage } from 'bundles/REconcile/components/development/lineItems/utils';
import HINTS from 'bundles/Construction/components/ChangeOrderEvent/hints';
import { ICompany, IContact } from 'bundles/Construction/types';
import { ICreatableSelectOption } from 'bundles/Shared/components/GroupForm/FormItems/FormCreatableSelect';

export const useReconcileSubmittedByCompanyField = (
  companies: ICompany[],
  options?: Partial<IFormItem>,
): IFormItem => ({
  fieldName: 'reconcileCompany',
  type: FieldType.CREATABLE_SELECT,
  typeView: FieldTypeView.VIEW_TEXT,
  title: 'Submitted by Company',
  titleView: 'Submitted by Company',
  transformerView: (option: ICreatableSelectOption<number | string>) =>
    option.__isNew__
      ? option.label
      : viewNameReconcileOption(option.value, companies),
  formatCreateLabel: creatableSelectMessage('company'),
  placeholder: 'Select company',
  hint: HINTS.submittedByCompany,
  required: true,
  optionalView: true,
  options: optionsReconcileFor(companies),
  ...options,
});

export const useReconcileSubmittedByContactField = (
  contacts: IContact[],
  options?: Partial<IFormItem>,
): IFormItem => ({
  fieldName: 'reconcileContact',
  type: FieldType.CREATABLE_SELECT,
  typeView: FieldTypeView.VIEW_TEXT,
  title: 'Submitted by Contact',
  titleView: 'Submitted by Contact',
  transformerView: (option: ICreatableSelectOption<number | string>) =>
    option.__isNew__
      ? option.label
      : viewNameReconcileOption(option.value, contacts),
  formatCreateLabel: creatableSelectMessage('contact'),
  placeholder: 'Enter or select the name',
  hint: HINTS.submittedByContact,
  required: true,
  optionalView: true,
  options: optionsReconcileFor(contacts),
  ...options,
});

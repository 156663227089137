import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import CampaignEmailCardInfo from './CampaignEmailCard/CampaignEmailCardInfo';
import CampaignEmailCardContent from './CampaignEmailCard/CampaignEmailCardContent';
import CampaignEmailCardStats from './CampaignEmailCard/CampaignEmailCardStats';

const CampaignEmailCard = ({
  size,
  title,
  date,
  time,
  iconClass,
  color,
  bgColorClassName = 'bg-light',
  recipients,
  subject,
  emailText,
  totalRecipientsCount,
  emailMessages,
  className,
  type,
  linkedWithHref,
  linkedWithTitle,
  investmentObjectHref,
  investmentObjectTitle,
}) => {
  const CardInfo = () => (
    <CampaignEmailCardInfo
      title={title}
      iconClass={iconClass}
      color={color}
      date={date}
      time={time}
      recipients={recipients}
    />
  );

  const CardStats = () => (
    <CampaignEmailCardStats
      createdAtDate={date}
      createdAtTime={time}
      campaignTypeTitle={title}
      campaignTypeIcon={iconClass}
      campaignTypeColor={color}
      totalRecipientsCount={totalRecipientsCount}
      emailMessages={emailMessages}
      type={type}
    />
  );

  const CardContent = () => (
    <CampaignEmailCardContent
      subject={subject}
      content={emailText}
      linkedWithHref={linkedWithHref}
      linkedWithTitle={linkedWithTitle}
      investmentObjectHref={investmentObjectHref}
      investmentObjectTitle={investmentObjectTitle}
    />
  );

  return (
    <div className={cn('email-campaign-card', bgColorClassName, className)}>
      {size === 'lg' && (
        <div className="email-campaign-card-lg flex-lg-row flex-col justify-between">
          <div className="w-full w-lg-25">
            <CardInfo />
          </div>
          <div className="w-full w-lg-60">
            <CardContent />
          </div>
          <div className="w-full w-lg-15">
            <CardStats />
          </div>
        </div>
      )}
      {size === 'md' && (
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div className="w-full w-lg-50">
              <CardInfo />
            </div>
            <div className="w-full w-lg-30">
              <CardStats />
            </div>
          </div>
          <div className="w-full mt-[1.5rem]">
            <CardContent />
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignEmailCard;

import { useEffect, useState } from 'react';

/**
 * @param value a value that needs to be debounced
 * @param delay represented in milliseconds
 */
export default function useDebounce<T = unknown>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

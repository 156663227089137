import http from 'lib/http';
import { stringify } from 'qs';
import { ISortParamsField } from 'types/Sort';
import { IPaginationParams, IQueryParams } from 'types/Pagination';
import {
  IReturnInvestmentObjectScreenData,
  IReturnInvestmentObjectDetails,
  TPublishableLegalEntity,
} from 'bundles/REturn/types';
import { LegalEntity } from '@/entities/core/legalEntity';

interface IFetchCapitalLegalEntitiesRequestParams {
  published?: boolean;
}
export interface IFetchCapitalLegalEntitiesRequest
  extends ISortParamsField,
    IQueryParams,
    IFetchCapitalLegalEntitiesRequestParams,
    IPaginationParams {}

export interface IFetchInvestmentObjectRequest
  extends ISortParamsField<'legal_entity_id'> {
  legal_entity_ids?: string[];
}

// TODO: remove and use src/bundles/Shared/entities/return/api/capitalInvestmentObjectsGeneratedApi.ts
export const fetchInvestmentObject = async (
  id: string,
  params: IFetchInvestmentObjectRequest,
) => {
  const res = await http.get(
    `/capital/investment_objects/${id}?${stringify(params, {
      arrayFormat: 'brackets',
    })}`,
  );
  const legalEntity = await res.json();

  return legalEntity as IReturnInvestmentObjectScreenData;
};

export const fetchInvestmentObjectDetails = async (id: string) => {
  const res = await http.get(`/capital/investment_objects/${id}/details`);
  const legalEntity = await res.json();

  return legalEntity as IReturnInvestmentObjectDetails;
};

// TODO: remove and use src/bundles/Shared/entities/return/api/capitalLegalEntitiesGeneratedApi.ts
export const publishLegalEntity = async (id: string) => {
  const res = await http.put(
    `/capital/legal_entities/${id}/publish_toggle`,
    {},
  );
  const legalEntity = await res.json();

  return legalEntity as Pick<LegalEntity, 'name'> & TPublishableLegalEntity;
};

import React, { useCallback, useEffect, useState } from 'react';
import {
  AnimationLoader,
  Button,
  Modal,
  ModalHeaderWithSubtitle,
  MultilineAlert,
} from 'stories';
import {
  CrudContextProvider,
  CrudPagination,
  CrudSearch,
  CrudTable,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useEvent from '@/shared/lib/hooks/useEvent';
import { developmentLineItemsColumns } from 'bundles/REconcile/components/development/lineItems/useColumns';
import { fetchCompanies } from 'bundles/Construction/reducers/ReconcileSlice';
import {
  useCreateLineItemMutation,
  useDeleteLineItemMutation,
  useGetLineItemsQuery,
  useUpdateLineItemMutation,
} from 'bundles/REconcile/components/development/api/lineItems';
import {
  ROUTES_ROOT,
  TRouteQueryParams,
  useNavigation,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import CreateLineItemDropdown from 'bundles/REconcile/components/development/lineItems/CreateLineItemDropdown';
import { IPageParams } from 'bundles/Construction/types';
import ActionsCell from 'bundles/REconcile/components/development/lineItems/ActionsCell';
import TransactionLinkTableModal from 'bundles/REconcile/components/development/requisitions/modals/createTransactionsFromSource/TransactionLinkTableModal';
import TransactionUnlinkModal from 'bundles/REconcile/components/development/requisitions/modals/TransactionUnlinkModal';
import { ILineItemTransactionDetails } from 'bundles/REconcile/types/ILineItem';
import { useUnlinkTransactionMutation } from 'bundles/REconcile/components/development/api/transactions';
import { navigate, useParams } from '@reach/router';
import { fetchDevelopmentBudgetVariance } from 'bundles/REconcile/reducers/developmentBudgetVarianceSlice';
import { TFilterModel } from 'bundles/Shared/components/Table/types';
import LineItemModal from 'bundles/REconcile/components/LineItemModal';
import { isRequisitionFunded } from 'bundles/Shared/entities/requisition/lib';

import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface Props {
  requisitionId: string;
  legalEntityCode: string;
  onClose: (dataChanged?: boolean) => void;
}

export interface IRouteParamsLineItems {
  legalEntityCode: string;
  requisitionId: string;
}

// eslint-disable-next-line max-len
const PATH =
  ROUTES_ROOT.reconcile.development.legalEntity.requisitions.requisition
    .invoices.fullPath;

function LineItemsTable({ requisitionId, legalEntityCode, onClose }: Props) {
  const params = useParams<IRouteParamsLineItems>();
  const { openModal, confirm } = useModal();
  const requisitions = useAppSelector(
    (state) => state.developmentBudgetVariance.data.requisitions,
  );
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const { categoryId, categoryType, generalLedgers } =
    useQueryParams<TRouteQueryParams[typeof PATH]>();

  const hasGeneralLedger = generalLedgers === 'true';

  const [pageParams, setPageParams] = useState<IPageParams>({
    per_page: 10,
  });

  const { data, isLoading, refetch } = useGetLineItemsQuery({
    requisitionId,
    legalEntityCode,
    pageParams,
    categoryId,
    categoryType,
  });

  const [createLineItem] = useCreateLineItemMutation();
  const [updateLineItem] = useUpdateLineItemMutation();
  const [destroyLineItem] = useDeleteLineItemMutation();
  const [unlinkTransaction] = useUnlinkTransactionMutation();

  const requisition = requisitions?.find((req) => req.id === requisitionId);

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModel) =>
      setPageParams((prevParams) => ({
        ...prevParams,
        without_invoice: filterModel.invoice_fire,
        without_source: filterModel.source_fire,
        page: 1,
      })),
    [],
  );

  const handleCreate = useEvent(async () => {
    const res = await openModal(LineItemModal, {
      lineItem: {
        amount: 0,
        category_id: Number(categoryId),
      },
      categoryType,
      fieldSettings: {
        category_id: {
          disabled: true,
        },
      },
      legalEntityCode: params.legalEntityCode,
      header: (
        <span className="dark-60 header6-bold">
          Create New Development Line Item
        </span>
      ),
      submitText: 'Create',
    });

    if (res) {
      await createLineItem({
        requisitionId,
        legalEntityCode,
        body: {
          amount: res.amount,
          description: res.remark,
          vendor_name: res.vendor,
          transaction_date: formatToDateStringForRequest(res.date),
          category_id: Number(categoryId),
          category_type: categoryType,
        },
      });
      await dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
    }
  });

  const handleRowEdit = useEvent(async (row) => {
    const res = await openModal(LineItemModal, {
      lineItem: {
        amount: row.amount,
        date: new Date(row.date),
        remark: row.remark,
        vendor: row.vendor.name,
        category_id: Number(categoryId),
      },
      categoryType,
      fieldSettings: {
        category_id: {
          disabled: true,
        },
      },
      legalEntityCode,
      header: (
        <span className="dark-60 header6-bold">
          Update Development Line Item
        </span>
      ),
      submitText: 'Update',
    });
    if (res) {
      await updateLineItem({
        requisitionId,
        legalEntityCode,
        lineItemId: row.id,
        body: {
          description: res.remark,
          transaction_date: formatToDateStringForRequest(res.date),
          vendor_name: res.vendor,
          amount: res.amount,
        },
      });

      await dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
    }
  });

  const handleRowRemove = useEvent(async (row) => {
    const confirmed = await confirm({
      title: 'Delete Line Item',
      subtitle: 'Are you sure you want to delete Development Line Item?',
      actions: {
        primaryButton: {
          text: 'Delete',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });

    if (confirmed) {
      await destroyLineItem({
        requisitionId,
        legalEntityCode,
        lineItemId: row.id,
      });

      await dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
    }
  });

  const handleCreateFromSource = async () => {
    await openModal(TransactionLinkTableModal, {
      legalEntityCode,
      requisitionId,
      bulkAvailable: true,
      meta: data.meta,
      headerTitle: 'Create Development Line Item from Source',
      createFromSource: true,
      params,
    });
  };

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  const linkTx = async (row: ILineItemTransactionDetails) => {
    await openModal(TransactionLinkTableModal, {
      legalEntityCode,
      requisitionId,
      lineItem: row,
      meta: data.meta,
      headerTitle: 'Transaction Link with Development Line Item',
      params,
    });
  };

  const unlinkTx = async (row: ILineItemTransactionDetails) => {
    const res = await openModal(TransactionUnlinkModal, {
      vendor: row.vendor,
      ...row.transactionDetails,
    });

    if (res) {
      await unlinkTransaction({
        legalEntityCode,
        requisitionId,
        id: row.transactionDetails.id,
      });
      await refetch();
      await dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
    }
  };
  const onSourceLinkClick = (row: ILineItemTransactionDetails) =>
    row.transactionDetails ? unlinkTx(row) : linkTx(row);

  const columns = developmentLineItemsColumns({
    withoutInvoicesCount: data?.meta.noInvoiceCount,
    withoutSourceCount: data?.meta.noSourceCount,
    onSourceLinkClick,
    hasGeneralLedger,
    onInvoiceClick: () =>
      navigate(
        navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES', {
          legalEntityCode,
        }),
      ),
  });
  if (isLoading) return <AnimationLoader />;

  const subtitle = `${categoryType === 'JobCostCode' ? 'JCC' : 'SOVC'}: ${
    data!.meta.categoryName
  }`;

  const navigateToSettingJCC = () => {
    navigate(
      navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS', {
        legalEntityCode,
        tab: 'jcc',
      }),
    );
  };

  return (
    <Modal
      toggle={onClose}
      size="huge"
      header={
        <ModalHeaderWithSubtitle
          title="Requisition"
          subtitle={subtitle}
          order="title-subtitle"
        />
      }
      classes={{ body: 'bg-light-10 p-0' }}
    >
      <h3 className="header3-bold p-tw-6 pb-tw-4">Development Line Items</h3>
      {!hasGeneralLedger && !isRequisitionFunded(requisition) && (
        <div className="px-tw-6 pb-tw-4 pt-tw-2">
          <MultilineAlert
            status="danger"
            title="No Available Source"
            message="You should set General Ledger for this JCC to unlock an ability to create line items from source"
            actions={
              <Button variant="danger" size="s" onClick={navigateToSettingJCC}>
                Set General Ledger
              </Button>
            }
          />
        </div>
      )}
      <CrudContextProvider
        pageParams={pageParams}
        setPageParams={setPageParams}
        entities={data!.items}
        entityName="Line Item"
      >
        <div className="mb-tw-8 px-tw-6">
          <div className="flex justify-between items-center mb-l">
            <div>
              <CrudPagination totalSize={data!.meta.totalSize} />
            </div>
            <div className="flex items-center gap-s">
              <CrudSearch />
              {!isRequisitionFunded(requisition) && (
                <CreateLineItemDropdown
                  actions={[
                    {
                      label: 'Manually',
                      handler: handleCreate,
                      locked: false,
                    },
                    {
                      label: 'From Source',
                      handler: handleCreateFromSource,
                      locked: !hasGeneralLedger,
                      lockedText:
                        'You should set General Ledger for this JCC to unlock an ability to create line items from source',
                    },
                  ]}
                  navigateToSettingJCC={navigateToSettingJCC}
                />
              )}
            </div>
          </div>
          <CrudTable
            columns={columns}
            onFilterModelChange={handleFilterModelChange}
            actionsColumn={{
              text: 'Actions',
              dataField: 'actions',
              headerStyle: {
                width: 70,
              },
              classes: 'text-center',
              formatter: ({ row }) =>
                !row.invoice && (
                  <ActionsCell
                    actions={[
                      {
                        label: 'Edit',
                        handler: () => handleRowEdit(row),
                        icon: 'edit',
                      },
                      {
                        label: 'Delete',
                        handler: () => handleRowRemove(row),
                        icon: 'trash',
                      },
                    ]}
                  />
                ),
            }}
          />
        </div>
      </CrudContextProvider>
    </Modal>
  );
}

export default LineItemsTable;

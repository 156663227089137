import { CapitalInvestment } from '@/entities/return';
import { ReportLineItem } from 'bundles/REturn/actions/types';
import { uniqueId } from 'lodash-es';
import { EntyTxWithReviewInfo, SingleDistributionKind } from '../../types';
import { createWorkflowFlags } from '../createWorkflowFlags';

import { convertCentsToDollars } from '@/shared/lib/converters';

export const BULK_LINKING_ERROR_MSG =
  'There should be only 1 selected Capital Investment in Bulk Linking Workflow!';

export function bulkLinkingReviewEntryTxs({
  selectedCapitalInvestments,
  selectedTxs,
  workflowFlags,
  singleDistributionKind,
  totalEntryAmountKeyName,
}: {
  selectedTxs: ReportLineItem[];
  selectedCapitalInvestments: CapitalInvestment[];
  workflowFlags: ReturnType<typeof createWorkflowFlags>;
  singleDistributionKind: SingleDistributionKind;
  totalEntryAmountKeyName: 'totalContributionsDollars' | 'totalDistributions';
}): EntyTxWithReviewInfo[] {
  if (selectedCapitalInvestments.length !== 1) {
    throw new Error(BULK_LINKING_ERROR_MSG);
  }

  const [{ investmentEntity, ...capitalInvestment }] =
    selectedCapitalInvestments;

  const entryTxs: EntyTxWithReviewInfo[] = selectedTxs.map(
    ({ amountCents, id: accountingTransactionId }) => {
      let entryTx: EntyTxWithReviewInfo = {
        amount: convertCentsToDollars(amountCents),
        accountingTransactionId,
        totalAmount: capitalInvestment[totalEntryAmountKeyName],
        investmentId: capitalInvestment.id,
        investmentName: investmentEntity.name,
      };

      if (
        workflowFlags.shouldSelectOneKindForMultipleTx &&
        workflowFlags.selectedOneKind(singleDistributionKind)
      ) {
        entryTx = {
          ...entryTx,
          kind: singleDistributionKind,
          kindReviewInfo: [
            {
              id: uniqueId(),
              amountCents,
              kind: singleDistributionKind,
            },
          ],
        };
      }

      return entryTx;
    },
  );

  return entryTxs;
}

import { cn } from '@/shared/lib/css/cn';
import styles from './Dashboard.module.scss';
import { GetApiPortfolioApiResponse } from 'bundles/Shared/shared/api/portfolioGeneratedApi';

export const EMPTY_PORTFOLIO: GetApiPortfolioApiResponse = {
  capitalDetails: {
    asOfDate: Date.now(),
    rows: [],
  },
  capitalHistory: [],
  capitalBreakdown: [],
  contributionOverview: [],
  snapshots: {
    contributed: {
      total: 0,
      contributedPercent: 0,
      activeInvestmentsCount: 0,
    },
    distributed: {
      totalDistributionsCount: 0,
      cashOnCashPercent: 0,
      total: 0,
    },
  },
};

export const TABLE_HEAD_COLUMNS = [
  {
    title: (
      <>
        Asset <span className="dark">/</span> Fund
      </>
    ),
    rowField: 'investmentObjectName',
  },
  {
    title: 'Investment Entity',
    rowField: 'investmentEntityName',
  },
  {
    title: 'Year',
    rowField: 'yearInvested',
  },
  {
    title: 'Capital Invested',
    rowField: 'capitalInvested',
    itemsPath: 'contributions',
  },
  {
    title: 'Capital Returned',
    totalAmountPath: 'groupedDistributions.return_of_capital.totalAmount',
    itemsPath: 'groupedDistributions.return_of_capital.items',
    rowField: 'return_of_capital',
  },
  {
    title: 'Capital Balance',
    rowField: 'capitalBalance',
  },
  {
    title: 'Pref. Paid',
    totalAmountPath: 'groupedDistributions.preferred.totalAmount',
    itemsPath: 'groupedDistributions.preferred.items',
    rowField: 'preferred',
  },
  {
    title: 'Excess Cash',
    totalAmountPath: 'groupedDistributions.excess_cash.totalAmount',
    itemsPath: 'groupedDistributions.excess_cash.items',
    rowField: 'excess_cash',
  },
  {
    title: 'Total Returned',
    rowField: 'capitalDistributed',
    itemsPath: 'distributions',
  },
];

export const resolveEmptyValueJSX = (total?: boolean) => (
  <div
    className={cn(styles.emptyValue, {
      [styles.total]: total,
    })}
  >
    <span />
  </div>
);

import { investmentIncludesClass } from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import { useCapitalInvestments } from '@/bundles/REturn/hooks/useCapitalInvestments';
import { CapitalInvestment, CapitalInvestmentClass } from '@/entities/return';
import { CapitalInvestmentEntryType } from 'bundles/REturn/actions/types';
import Table from 'bundles/Shared/components/Table/Table';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import { useMemo, useState } from 'react';
import { InlineAlert, SearchInput } from 'stories/index';
import { INLINE_ALERT_TEXT_MAP_FOR_INVESTMENT_ENTITY } from '../consts';
import { useInvEntitiesColumns } from '../hooks/columns/useInvEntitiesColumns';
import { useComputedInvEntities } from '../hooks/useComputedInvEntities';
import { createTableSelectionProps } from '../utils';

export interface SelectInvEntProps {
  entryType: CapitalInvestmentEntryType;
  singleSelection: boolean;
  selectedRows: CapitalInvestment[];
  setSelectedRows: (rows: CapitalInvestment[]) => void;
}

export function SelectInvestmentEntityStep({
  entryType,
  ...restProps
}: SelectInvEntProps) {
  const [query, setQuery] = useState('');
  const { data: capitalInvestmentsData } = useCapitalInvestments();
  const capitalInvestments = capitalInvestmentsData ?? [];

  const computedCapitalInvestments = useComputedInvEntities({
    capitalInvestments,
    query,
  });

  const [filterModel, setFilterModel] = useState<{
    investmentEntity?: CapitalInvestmentClass | 'all';
  }>({});

  const investmentClass = filterModel.investmentEntity;

  const filteredItems = useMemo(() => {
    if (investmentClass == null || investmentClass === 'all')
      return computedCapitalInvestments;

    return computedCapitalInvestments.filter((ci) =>
      investmentIncludesClass(ci, investmentClass),
    );
  }, [filterModel, computedCapitalInvestments]);

  const columns = useInvEntitiesColumns({
    capitalInvestments: filteredItems,
    entryType,
    filterModel,
    setFilterModel,
  });

  const tablePropsForSelectionRows = createTableSelectionProps(restProps);

  return (
    <div className="mr-[480px] flex w-full flex-col gap-tw-4 overflow-x-auto bg-light-10 p-tw-6">
      <div className="flex flex-col gap-tw-2.5">
        <h6 className="header6-regular">Investment Entities</h6>
        <InlineAlert
          status="info"
          className="w-full"
          message={
            INLINE_ALERT_TEXT_MAP_FOR_INVESTMENT_ENTITY[
              restProps.singleSelection ? 'single' : 'multiple'
            ]
          }
        />
      </div>

      <div className="flex flex-col gap-tw-2">
        <div className="flex items-center justify-end gap-tw-2">
          <TablePagination
            className="mr-auto"
            loading={false}
            currentPage={1}
            totalSize={capitalInvestments.length}
            sizePerPage={capitalInvestments.length}
          />
          <SearchInput
            placeholder="Search"
            value={query}
            suggestions={['Investment Entity Name']}
            size="s"
            resetValue={() => setQuery('')}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <Table
          {...tablePropsForSelectionRows}
          items={filteredItems}
          columns={columns}
        />
      </div>
    </div>
  );
}

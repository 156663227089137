import {
  IReconcileCategorizedEntity,
  IReconcileDevelopmentCategory,
} from 'bundles/Construction/types';

interface ICategory extends IReconcileDevelopmentCategory {
  categoryPath: string[];
  children: ICategory[];
  code: string;
  key: string;
  title: string;
  expanded?: boolean;
  isCategory?: boolean;
  isSubCategory?: boolean;
}

interface ITreeItem {
  children: ITreeItem[];
  id: string;
  title: string;
  expanded?: boolean;
  isCategory?: boolean;
  isSubCategory?: boolean;
}

const getNodeTitle = (entity: IReconcileCategorizedEntity) =>
  `${entity.code} - ${entity.name}`;

export const transformCategoriesForTree = ({
  categories,
  includeSoVcs,
}: {
  categories: IReconcileDevelopmentCategory[];
  categorizeableEntities?: IReconcileCategorizedEntity[];
  includeSoVcs?: boolean;
}) => {
  const categoriesTree: ITreeItem[] = [];

  categories.forEach((category: ICategory) => {
    const generateJccSovc = (categoryWithJCC: ICategory) =>
      categoryWithJCC.jccs &&
      categoryWithJCC.jccs.map((jccItem) => {
        return {
          ...jccItem,
          originalId: jccItem.id,
          id: jccItem.key,
          title: jccItem.name ? getNodeTitle(jccItem) : 'Custom JCC',
          children: ((includeSoVcs && jccItem.sovcs) || []).map((sovc) => {
            return {
              originalId: sovc.id,
              id: sovc.key,
              title: sovc.name ? getNodeTitle(sovc) : sovc.name,
              children: [],
            };
          }),
          expanded: true,
        };
      });

    const generateSubCategories = (
      categoryChildren: ICategory[],
    ): ITreeItem[] =>
      categoryChildren.map((subCategory: ICategory) => ({
        ...subCategory,
        id: subCategory.key,
        title: subCategory.categoryName || 'Custom JCC',
        isSubCategory: true,
        children: [
          ...generateSubCategories(subCategory.children),
          ...(generateJccSovc(subCategory) ?? []),
        ],
        expanded: true,
      }));

    const jccSovc = generateJccSovc(category);

    const subCategories = category.children.map((subCategory) => ({
      ...subCategory,
      id: subCategory.key,
      title: subCategory.categoryName || 'Custom SubCost',
      children: [
        ...generateSubCategories(subCategory.children),
        ...(generateJccSovc(subCategory) ?? []),
      ],
      isSubCategory: true,
      expanded: true,
    }));

    categoriesTree.push({
      id: category.key,
      title: category.categoryName || 'Custom Costs',
      isCategory: true,
      expanded: true,
      children: [...subCategories, ...(jccSovc ?? [])],
    });
  });

  return categoriesTree;
};

import http from 'lib/http';
import {
  checkErrors,
  replacePathParams,
  toastErrors,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/crudApi';

export interface IWorkflowConfig {
  eSignatureCoDocsEnabled: boolean;
  eSignatureCorDocsEnabled: boolean;
  coApprovalRequired: boolean;
  corApprovalRequired: boolean;
  individualCorAmountThresholdEnabled: boolean;
  contingencyThresholdEnabled: boolean;
  timingImpactThresholdEnabled: boolean;
  corsAwaitingApprovalCount: number;
  corsAwaitingApproval: {
    individualAmount: {
      id: number;
      displayNumber: string;
    }[];
    aggregateContingency: {
      id: number;
      displayNumber: string;
    }[];
    estimatedTimingImpact: {
      id: number;
      displayNumber: string;
    }[];
  };
}

type TPathParameters = {
  legalEntityId: string;
};

const WORKFLOW_SETTINGS_BASE_PATH =
  '/settings/reconcile/legal_entities/:legalEntityId/workflow';

export const fetchWorkflowReconcileSettings = async (
  pathParams: TPathParameters,
) => {
  const result = await http.get(
    replacePathParams(WORKFLOW_SETTINGS_BASE_PATH, pathParams),
  );
  const json = await result.json();

  return json as IWorkflowConfig;
};

export interface IUpdateWorkflowReconcileSettings {
  enable_co_esignature_placement?: boolean;
  enable_cor_esignature_placement?: boolean;
  enable_co_approval_requirement?: boolean;
  enable_cor_approval_requirement?: boolean;
  enable_individual_cor_amount_threshold?: boolean;
  enable_aggregate_contingency_threshold?: boolean;
  enable_estimated_timing_impact_threshold?: boolean;
  enable_notify_ce_creator_after_approve?: boolean;
}

export const updateWorkflowReconcileSettings = async (
  pathParams: TPathParameters,
  request: IUpdateWorkflowReconcileSettings,
) => {
  const response = await http.put(
    replacePathParams(WORKFLOW_SETTINGS_BASE_PATH, pathParams),
    request,
  );
  const json = await response.json();

  const success = !checkErrors(json);

  toastErrors(success, {
    successMessage: 'Workflow Settings has been updated',
    errorMessage: json.errors,
  });

  if (success) {
    return json as IWorkflowConfig;
  }

  return undefined;
};

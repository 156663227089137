import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { Link, useLocation } from '@reach/router';
import { IVisibleReportTableConfigsData } from 'bundles/REport/actions';
import { Icon } from 'stories/index';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';

function ObjectFinancialTabs({
  object,
  visibleReportTableConfigs,
  isFetching,
}: {
  object: IAsset | IFund;
  visibleReportTableConfigs: IVisibleReportTableConfigsData | undefined;
  isFetching: boolean;
}) {
  const { pathname } = useLocation();

  return (
    <div className="nav-item dropdown object-menu-item flex max-h-[32px] w-max items-center px-tw-4 pb-tw-0.5 pt-tw-1.5">
      <p className="inline-semibold flex items-center gap-tw-2 border-none border-transparent bg-transparent leading-none text-info-055">
        Financials
        {isFetching ? (
          <Icon iconName="loader" className="animate-spin text-neutral-450" />
        ) : (
          <Icon iconName="bottom" className="text-neutral-450" />
        )}
      </p>
      {!isFetching &&
        (visibleReportTableConfigs?.reportTableConfigs?.length ?? 0) > 0 && (
          <div
            className="dropdown-menu"
            data-dropdown-in="fadeIn"
            data-dropdown-out="fadeOut"
          >
            {visibleReportTableConfigs?.reportTableConfigs.map((config) => (
              <Link
                key={config.id}
                to={generateUrl(ROUTES_ROOT.report.financials.fullPath, {
                  pathParams: {
                    reportName: config.slug,
                  },
                  queryParams: {
                    [object.objectType]: object.id,
                  },
                })}
                className="dropdown-item cursor-pointer"
                state={{ from: pathname }}
              >
                {config.name}
              </Link>
            ))}
          </div>
        )}
    </div>
  );
}

export default ObjectFinancialTabs;

export const TriangleSvg = () => (
  <svg width="13" height="4" viewBox="0 0 13 4" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.0846491 3.67163H2H11H12.9154C12.3091 3.67163 11.7068 3.57389 11.1316 3.38217C10.7165 3.24379 10.3392 3.01069 10.0297 2.70133L10.0292 2.70085L7.91421 0.585845C7.13316 -0.195204 5.86684 -0.195205 5.08579 0.585843L2.97078 2.70085C2.66118 3.01045 2.28375 3.24371 1.86838 3.38217C1.29322 3.57389 0.690918 3.67163 0.0846491 3.67163Z"
      fill="white"
    />
  </svg>
);

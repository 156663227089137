import React, { useMemo } from 'react';
import { IconButton, MultilineAlert, SmallAddingButton } from 'stories';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { getWorkCost } from './initData';
import {
  IFee,
  IMarkup,
} from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';

const WorkCostModal = ({
  scheduleOfValueCodes,
  recommendedReasonNames,
  allReasons,
  workCostData,
  setWorkCostData,
  validCostBreakDown,
  markupData,
  setMarkupData,
  feeData,
  setFeeData,
}) => {
  const toOption = (record) =>
    record
      ? {
          value: record.id,
          label: record.codeName || record.categoryWithName || record.name,
        }
      : null;
  const recommendedReasonsOptions = allReasons
    .filter((r) => recommendedReasonNames.includes(r.categoryWithName))
    .map(toOption);
  const otherReasonsOptions = allReasons
    .filter((r) => !recommendedReasonNames.includes(r.categoryWithName))
    .map(toOption);

  const formItems = useMemo(
    () =>
      getWorkCost(
        [
          { label: 'Recommended', options: recommendedReasonsOptions },
          { label: 'Other Reasons', options: otherReasonsOptions },
        ],
        scheduleOfValueCodes.map(toOption),
      ),
    [],
  );

  const setFormDataFunc = (newData, idx) => {
    const newGroupFormData = workCostData.map((i, idGroup) => {
      if (idx === idGroup) {
        if (i.amount !== newData.amount) {
          const newMarkupData = markupData.map((markup: IMarkup) => ({
            ...markup,
            comment: '',
          }));
          const newFeeData = feeData.map((fee: IFee) => ({
            ...fee,
            comment: '',
          }));
          setMarkupData(newMarkupData);
          setFeeData(newFeeData);
        }
        return newData;
      }
      return i;
    });
    if (workCostData) {
      setWorkCostData(newGroupFormData);
    }
  };

  const removeItem = (idx) => {
    const newGroupFormData = workCostData.filter(
      (item, idItem) => idItem !== idx,
    );
    setWorkCostData(newGroupFormData);
  };

  return (
    <div>
      {!workCostData.length && (
        <MultilineAlert
          status="danger"
          icon="infoAlt"
          message={
            <div className="dark-60 inline-semibold">
              Please add Cost of Work or remove Markups & Fees
            </div>
          }
          className="mb-m"
        />
      )}
      {workCostData.map((formItem, idx) => {
        return (
          <div key={`workCost${idx}`}>
            {Boolean(markupData?.length || feeData?.length) &&
              Boolean(formItem.amount) &&
              !validCostBreakDown && (
                <MultilineAlert
                  status="danger"
                  icon="infoAlt"
                  message={
                    <div className="dark-60 inline-semibold">
                      Allowable Markup & Fee exceeded. You need to adjust these
                      values or leave a comment under each tab
                    </div>
                  }
                  className="mb-m"
                />
              )}
            <FormComponent
              formData={formItem}
              setFormData={(newData) => setFormDataFunc(newData, idx)}
              formItems={formItems}
              styles={{
                wrapper: 'group-form__step-item',
              }}
              unitNum={idx + 1}
              actions={
                <IconButton
                  iconName="closeSmall"
                  onClick={() => removeItem(idx)}
                />
              }
            />
          </div>
        );
      })}
      <SmallAddingButton
        onClick={() => {
          setWorkCostData([
            ...workCostData,
            {
              changeOrderReason: '',
              scheduleOfValueCode: '',
              amount: '',
              description: '',
            },
          ]);
        }}
      >
        Add Cost
      </SmallAddingButton>
    </div>
  );
};

export default WorkCostModal;

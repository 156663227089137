import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';
import { fetchRowsConfigSourceTables } from 'bundles/Settings/actions/report/tableConfigs';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';
import { PermissionList } from 'bundles/Shared/components/Permissions/PermissionList';
import { PERIODS_TYPES } from 'bundles/Shared/entities/periodsType/lib';
import { ReportPeriodsType } from 'bundles/Shared/entities/periodsType/model';
import { TableFormatPeriodsSelect } from 'bundles/Shared/entities/periodsType/ui/TableFormatPeriodsSelect';
import { TableSourceSelect } from 'bundles/Shared/entities/tableReportConfig/ui/TableSourceSelect';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { sortBy } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Field, Modal } from 'stories';
import { FieldsContainer } from 'stories/Field/Field';
import {
  IMutateReportTableConfigPayload,
  IPeriodsLimitations,
  IReportTableConfig,
  RowsConfigSourceTable,
} from 'types/ReportTableConfig';
import { PeriodLimitations } from './PeriodLimitation/PeriodLimitation';
import { PUBLIC_PERMISSION_ATTRIBUTES } from './helpers/constants';
import { createRestrictedPermissionAttributes } from './helpers/utils';
import { initialReportTableConfigPermitted } from './hooks/useReportTableConfigReducer';

interface Props extends DialogProps<IMutateReportTableConfigPayload> {
  tableConfig?: IReportTableConfig | null;
}

type EditableReportTableConfig = {
  name: IReportTableConfig['name'];
  periodsType: ReportPeriodsType;
  rowsConfigSourceTableId: number;
};

const ReportTableConfigModal = ({ tableConfig, onClose, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [rowsConfigSourceTables, setRowsConfigSourceTables] = useState<
    RowsConfigSourceTable[]
  >([]);
  const title = tableConfig ? 'Edit Table' : 'New Table';
  const buttonTitle = tableConfig ? 'Update Table' : 'Create Table';

  const [permitted, setPermitted] = useState<IReportTableConfig['permitted']>(
    tableConfig?.permitted ?? initialReportTableConfigPermitted,
  );
  const [periodsLimitations, setPeriodsLimitations] =
    useState<IPeriodsLimitations>(tableConfig?.periodsLimitations || {});

  useEffect(() => {
    fetchRowsConfigSourceTables().then((data) => {
      setRowsConfigSourceTables(data.items);
    });
  }, []);

  const resolver = useCallback((values: EditableReportTableConfig) => {
    const errors: Partial<EditableReportTableConfig> = {};

    if (!values.name) {
      errors.name = 'Field is required';
    }

    if (!values.periodsType) {
      errors.name = 'Field is required';
    }

    return {
      values,
      errors,
    };
  }, []);

  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm<EditableReportTableConfig>({
    mode: 'onChange',
    defaultValues: {
      name: tableConfig?.name,
      periodsType: tableConfig?.periodsType,
      rowsConfigSourceTableId: tableConfig?.rowsConfigSourceTable?.id,
    },
    resolver,
  });

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setLoading(true);
    e.preventDefault();
    const values = getValues();

    const userIds = permitted.directUsers.map((u) => u.id);
    const roleIds = permitted.directRoles?.map((r) => r.id) ?? [];
    const tagIds = permitted.directTags.map((t) => t.id);

    const permissionsReq = permitted.public
      ? PUBLIC_PERMISSION_ATTRIBUTES
      : createRestrictedPermissionAttributes({
          userIds,
          roleIds,
          tagIds,
        });

    const requestBody = {
      ...{
        periods_type: values.periodsType,
        name: values.name,
        rows_config_source_table_id: values.rowsConfigSourceTableId,
      },
      ...permissionsReq,
      periods_limitations: periodsLimitations,
    };

    onSubmit?.(requestBody);
  };

  return (
    <Modal
      header={title}
      toggle={onClose}
      classes={{
        body: 'bg-neutral-100',
      }}
      actions={
        <div className="flex w-full justify-between">
          <Button variant="secondary" onClick={onClose} size="s">
            Cancel
          </Button>
          <SendDataButton
            variant="success"
            size="s"
            onClick={handleSubmit}
            isLoading={loading}
            disabled={!isValid || loading}
          >
            {buttonTitle}
          </SendDataButton>
        </div>
      }
    >
      <FieldsContainer>
        <Field labelText="Table name" required newGen>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <SharedInput<IReportTableConfig['name']>
                value={field.value}
                onChange={field.onChange}
                placeholder="Please enter the table name"
                newGen
              />
            )}
          />
        </Field>
        <Field labelText="Access the table" newGen>
          <Controller
            control={control}
            name="name"
            render={() => (
              <div className="flex flex-col gap-tw-1.5">
                <p className="secondary-regular text-neutral-500">
                  Select which users, roles can have access to the table
                </p>
                <PermissionList
                  type="table"
                  hideActions={false}
                  whiteListedTabs={['users', 'roles', 'tags']}
                  permitted={permitted}
                  onSubmit={setPermitted}
                  productName={REPORT_PRODUCT_NAME}
                  placeholderRenderer="Access"
                />
              </div>
            )}
          />
        </Field>
        <Field labelText="Period limitations" newGen>
          <Controller
            control={control}
            name="name"
            render={() => (
              <div className="flex flex-col gap-tw-1.5">
                <p className="secondary-regular text-neutral-500">
                  Specify role-based periods display limitations
                </p>
                <PeriodLimitations
                  periodLimitations={periodsLimitations}
                  onChange={setPeriodsLimitations}
                />
              </div>
            )}
          />
        </Field>
        <Field labelText="Periods display format" required newGen>
          <Controller
            control={control}
            name="periodsType"
            render={({ field }) => (
              <div className="flex flex-col gap-tw-1.5">
                <div className="label-regular">
                  Select type of periods display
                </div>
                <TableFormatPeriodsSelect
                  placeholder="Select Periods Format"
                  options={PERIODS_TYPES}
                  onChange={(e) => field.onChange(e.value)}
                  value={field.value}
                  newGen
                />
              </div>
            )}
          />
        </Field>
        <Field labelText="Source" newGen>
          <Controller
            control={control}
            name="rowsConfigSourceTableId"
            render={({ field }) => (
              <div className="flex flex-col gap-tw-1.5">
                <div className="label-regular">
                  Copy layout from another table
                </div>
                <TableSourceSelect
                  placeholder="Select Periods Format"
                  options={mapItemsToListOption(
                    sortBy(rowsConfigSourceTables, 'name'),
                    'name',
                  )}
                  onChange={(e) => field.onChange(e.id)}
                  value={field.value}
                  newGen
                />
              </div>
            )}
          />
        </Field>
      </FieldsContainer>
    </Modal>
  );
};

export default ReportTableConfigModal;

import { find } from 'react-sortable-tree';

const findNodeById = (treeData, id) => {
  const { matches } = find({
    treeData,
    getNodeKey: ({ treeIndex }) => treeIndex,
    searchMethod: (result) => result.node.id === Number(id),
    expandAllMatchPaths: true,
    expandFocusMatchPaths: true,
  });
  if (matches.length > 0) return { node: matches[0].node, path: matches[0].path };
  return null;
};

export default findNodeById;

import { useLocation, useNavigate } from '@reach/router';
import { useEffect } from 'react';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { DocumentationPageModel } from '@/entities/docPage';
import { TreeItem } from 'react-sortable-tree';

export const useDocumentationPageParams = () => {
  const location = useLocation();
  // workaround for reach-router not receiving params
  const params = {
    id: location.pathname.split('/').pop(),
  };
  return params;
};

export const useRedirectToFirstPageEffect = (
  pages: DocumentationPageModel[],
) => {
  const navigate = useNavigate();
  const params = useDocumentationPageParams();
  useEffect(() => {
    if (params.id != null) {
      return;
    }
    navigate(
      generateUrl(ROUTES_ROOT.documentation.doc.fullPath, {
        pathParams: {
          id: pages?.[0]?.slug,
        },
      }),
      {
        replace: true,
      },
    );
  }, [pages]);
};

export function findNodeIndexes(
  data: TreeItem<DocumentationPageModel>[],
  nodeId: string,
  parentIndex: number[] = [],
): { parentIndex: number[]; nodeIndex: number } | null {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === nodeId) return { parentIndex, nodeIndex: i };

    if (data[i].children) {
      const result = findNodeIndexes(
        (data[i].children ?? []) as TreeItem<Node>[],
        nodeId,
        [...parentIndex, i],
      );
      if (result) return result;
    }
  }
  return null;
}

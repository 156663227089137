export const feedMessagePermissions = (permissions) =>
  permissions.public === true
    ? {
        directly_permitted_user_ids: [],
        directly_permitted_user_role_ids: [],
        directly_permitted_user_tag_ids: [],
        directly_permitted_investment_entity_ids: [],
        public_permission_attributes: {},
      }
    : {
        directly_permitted_user_ids: permissions.users.map((user) => user.id),
        directly_permitted_user_role_ids: permissions.roles.map(
          (role) => role.id,
        ),
        directly_permitted_user_tag_ids: permissions.tags.map((tag) => tag.id),
        directly_permitted_investment_entity_ids:
          permissions.investmentEntities.map((ie) => ie.id),
        public_permission_attributes: { _destroy: true },
      };

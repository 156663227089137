import React from 'react';

/**
 * @deprecated
 */

const PercentInput = (props) => (
  <div className="percent-input-wrapper" style={{ width: props.width || 70 }}>
    <input type="number" {...props} className="form-control" />
  </div>
);

export default PercentInput;

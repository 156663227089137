import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';
import styles from '../Dashboard.module.scss';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';

interface SnapshotItemProps {
  mainValue: string;
  type: 'contributions' | 'distributions';
  secondary: {
    value: number;
    name: string;
    formatter?: (s: number) => string;
  }[];
}
const HEADINGS = {
  contributions: 'Contributed',
  distributions: 'Distributed',
};

const ICONS = {
  contributions: 'contributions',
  distributions: 'distribution',
} as const;

export function SnapshotItem({
  mainValue,
  type,
  secondary,
}: SnapshotItemProps) {
  return (
    <DashboardWidgetCard className="p-tw-4 md:pr-xxl">
      <div className="flex flex-col justify-between gap-m md:flex-row md:gap-0">
        <div className={styles.snapshotMainInfo}>
          <div className={cn(styles.snapshotMainInfoIconWrapper, styles[type])}>
            <Icon
              iconName={ICONS[type]}
              className={cn(styles.snapshotMainInfoIcon, styles[type])}
            />
          </div>
          <div>
            <p className="light-80 inline-regular">Capital</p>
            <p className="dark-80 body-semibold">{HEADINGS[type]}</p>
          </div>
          <p className={cn(styles.snapshotMainInfoValue, 'header3-bold')}>
            {mainValue}
          </p>
        </div>

        <div
          className={cn(
            styles.snapshotSecondaryInfo,
            'grid-cols-2 md:grid-cols-[initial]',
          )}
        >
          {secondary.map(({ value, name, formatter }) => (
            <div key={name}>
              <p className="dark-60 header6-bold">
                {formatter ? formatter(value) : value}
              </p>
              <p className="inline-regular">{name}</p>
            </div>
          ))}
        </div>
      </div>
    </DashboardWidgetCard>
  );
}

import { useGetApiCapitalInvestmentObjectsByIdPreferredReturnQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';

export function useLoadedCapitalPreferredReturn() {
  const params = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();
  const { objectId } = params;

  if (!objectId) throw new Error('"objectId" must exist either in params');

  const res = useGetApiCapitalInvestmentObjectsByIdPreferredReturnQuery({
    id: objectId,
  });

  return {
    ...res,
    data: res.data,
  };
}

import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useCallback, useState } from 'react';

/**
 * A custom hook that syncs state with a URL search parameter.
 * Supports string, number, boolean, and object values.
 * @param key The search parameter key to sync with.
 * @param defaultValue The default value for the state.
 * @returns A stateful value, and a function to update it.
 */
function useSearchParamsState<T extends string | number | boolean | object>(
  key: string,
  defaultValue: T,
): [T, (newValue: Partial<T> | T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const [state, setState] = useState<T>(() => {
    if (paramValue === null) {
      return defaultValue;
    }
    try {
      return JSON.parse(paramValue) as T;
    } catch {
      return paramValue as T;
    }
  });

  const setParamState = useCallback(
    (newValue: Partial<T> | T) => {
      const updatedValue =
        typeof newValue === 'object' && !Array.isArray(newValue)
          ? { ...state, ...newValue }
          : newValue;

      setState(updatedValue as T);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, JSON.stringify(updatedValue));
      setSearchParams(newSearchParams);
    },
    [key, searchParams, setSearchParams, state],
  );

  return [state, setParamState];
}

export default useSearchParamsState;

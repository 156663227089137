import {
  usePostApiCapitalInvestmentContributionTransactionMappingsBulkMutation,
  usePostApiCapitalInvestmentDistributionTransactionMappingsBulkMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import FromToFilter from 'bundles/REport/components/financials/filter/FromToFilter';
import { CapitalEntry, ReportLineItem } from 'bundles/REturn/actions/types';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import GeneralLedgersFilterBar from 'bundles/Shared/components/Filters/GeneralLedgersFilterBar';
import Table from 'bundles/Shared/components/Table/Table';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import dayjs from 'dayjs';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import pluralize from 'pluralize';
import { useState } from 'react';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { createMonthPeriodItem } from 'stories/FlexibleFilterByPeriods/utils';
import { Modal, ModalHeaderWithSubtitle, SearchInput } from 'stories/index';
import { TSort } from 'types/Sort';
import { LinkTxWithExactMatch } from './LinkTxWithExactMatch';
import { SplitTxModal } from './SplitTxModal';
import { CustomClose } from './components/CustomClose';
import { useGeneralLedgersAsSources } from './hooks/useGeneralLedgersAsSources';
import { useAccountingTransactionColumns } from './hooks/useAccountingTransactionColumns';
import { useTransactions } from './hooks/useTransactions';
import { FilterModel, PageParams } from './types';
import { createPossiblePeriods } from './utils';

import { convertCentsToDollars, DATE_STRING_FORMAT } from '@/shared/lib/converters';

type Props = DialogProps & {
  entry: CapitalEntry;
  type: TransactionSource['kind'];
};

function LinkTxToManuallyAddedModal({ onClose, entry, type }: Props) {
  const entryAmount = entry.amount;
  const date = dayjs(entry.date).startOf('month').format(DATE_STRING_FORMAT);
  const possiblePeriods = createPossiblePeriods(date, 36);
  const { data: investmentObjData } = useLoadedInvestmentObject();
  const [createBulkMappingsContributionTransaction] =
    usePostApiCapitalInvestmentContributionTransactionMappingsBulkMutation();
  const [createBulkMappingsDistributionTransaction] =
    usePostApiCapitalInvestmentDistributionTransactionMappingsBulkMutation();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [pageParams, setPageParams] = useState<PageParams>({
    page: 1,
    per_page: 50,
    vendorEntityIds: [],
    sort: {
      order: TSort.ASC,
      field: 'date',
    },
    amount: {
      from: entryAmount,
    },
  });
  const [periods, setPeriods] =
    useState<IPeriodItem['period'][]>(possiblePeriods);
  const debouncedPeriods = useDebounce(periods, 1000);
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const generalLedgers = useGeneralLedgersAsSources({
    kind: type,
  });
  const [selectedGLs, setSelectedGL] = useState(generalLedgers);
  const { openModal } = useModal();

  const { loading, paginationMeta, transactions, loadTransactions } =
    useTransactions({
      periods: debouncedPeriods,
      query: debouncedQuery,
      pageParams,
      selectedGLs,
      selectedLegalEntities: investmentObjData?.legalEntities ?? [],
    });

  const resolveIsExactMatch = (transactionAmount: ReportLineItem['amount']) =>
    Number(transactionAmount) === entryAmount;

  const handleLinkTransaction = async (accountingTransaction: any) => {
    const CurrentModal = resolveIsExactMatch(
      convertCentsToDollars(accountingTransaction.amountCents),
    )
      ? LinkTxWithExactMatch
      : SplitTxModal;

    const res = await openModal(
      CurrentModal,
      {
        accountingTransaction,
        entry,
        type,
      },
      {
        topLevelRender: false,
      },
    );

    if (res == null) return;

    const entryIds = res.map(({ id }) => id);

    if (type === 'contribution') {
      await createBulkMappingsContributionTransaction({
        body: {
          accounting_transaction_id: accountingTransaction.id,
          contribution_transaction_ids: entryIds,
        },
      });
    } else {
      await createBulkMappingsDistributionTransaction({
        body: {
          accounting_transaction_id: accountingTransaction.id,
          distribution_transaction_ids: entryIds,
        },
      });
    }

    await loadTransactions();
    onClose();
  };

  const columns = useAccountingTransactionColumns({
    entryAmount,
    transactions,
    resolveIsExactMatch,
    actions: {
      onLinkTransaction: handleLinkTransaction,
    },
  });

  return (
    <Modal
      size="huge"
      classes={{ body: 'bg-light-10 flex flex-col gap-tw-4' }}
      toggle={onClose}
      customClose={
        <CustomClose {...entry} amount={entryAmount} onClose={onClose} />
      }
      header={
        <ModalHeaderWithSubtitle
          subtitle="Investment Entity"
          title={entry.investmentEntity.name}
          order="subtitle-title"
        />
      }
    >
      <GeneralLedgersFilterBar
        generalLedgers={generalLedgers ?? []}
        selectedGeneralLedgers={selectedGLs}
        selectedChange={setSelectedGL}
        selectionMode="exclude"
      />
      <div className="flex flex-col gap-tw-2">
        <div className="header6-regular text-dark-60">
          {pluralize('Transaction', transactions.length)}
        </div>
        <div className="flex items-center justify-end gap-tw-2">
          <TablePagination
            className="mr-auto"
            loading={loading}
            setCurrentPage={(page) => {
              setPageParams((prevParams) => ({
                ...prevParams,
                page,
              }));
            }}
            currentPage={pageParams.page}
            totalSize={paginationMeta.totalSize}
            sizePerPage={paginationMeta.perPage}
          />
          <FromToFilter
            filterByPeriodsType="mtd"
            periodItems={periods.map(createMonthPeriodItem)}
            possiblePeriods={possiblePeriods}
            initialFrom={[createMonthPeriodItem(periods[0] as DateString)]}
            initialTo={[createMonthPeriodItem(periods.at(-1)! as DateString)]}
            onUpdatePeriodItems={(newPeriodItems) => {
              const newPeriods = newPeriodItems.map(({ period }) => period);
              setPeriods(newPeriods);
            }}
          />
          <VerticalSeparator />
          <SearchInput
            placeholder="Search"
            value={query}
            size="s"
            resetValue={() => setQuery('')}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>
      <Table
        loading={loading}
        items={transactions}
        onFilterModelChange={(filterModel: FilterModel) => {
          setPageParams((prevParams) => ({
            ...prevParams,
            page: 1,
            exactAmountCents: filterModel.amount,
            type: filterModel.type,
            vendorEntityIds:
              filterModel.vendorEntities?.map(({ code }) => code) ?? [],
          }));
        }}
        settings={settings}
        setSettings={setSettings}
        disableSelectAll
        columns={columns}
      />
    </Modal>
  );
}

export default LinkTxToManuallyAddedModal;

import React from 'react';
import {
  GeneralSettingsNavigationSection,
  ObjectsSettingsNavigationSection,
} from 'stories';
import { TRouteParams, useNavigation } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { first } from 'lodash-es';

const GROUPED_TABS: {
  title: string;
  linkObjects: {
    link: TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']['tab'];
    title: string;
  }[];
}[] = [
  {
    title: 'Legacy',
    linkObjects: [{ link: 'legacy', title: '' }],
  },
  {
    title: 'Budget',
    linkObjects: [
      { link: 'jcc', title: 'JCC' },
      { link: 'sovc', title: 'SoVC' },
    ],
  },
  {
    title: 'Change Management',
    linkObjects: [
      { link: 'markups', title: 'Markups' },
      { link: 'fees', title: 'Fees' },
      { link: 'workflow', title: 'Workflow' },
    ],
  },
  {
    title: 'Funding Sources',
    linkObjects: [{ link: 'funding_sources', title: '' }],
  },
  {
    title: 'Snapshot',
    linkObjects: [{ link: 'snapshot', title: '' }],
  },
];

function LegalEntityReconcileSettingsNavigation() {
  const navigation = useNavigation();

  const { tab: currentTab, legalEntityCode } =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']>();

  const getNavigationProps = (
    tab: TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']['tab'],
  ) => ({
    link: navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS', {
      legalEntityCode,
      tab,
    }),
    active: currentTab === tab,
  });

  return (
    <div className="flex gap-tw-4">
      {GROUPED_TABS.map((tab) =>
        tab.linkObjects.length === 1 ? (
          <GeneralSettingsNavigationSection
            title={tab.title}
            subtitle={first(tab.linkObjects)?.title}
            replace
            {...getNavigationProps(first(tab.linkObjects)!.link)}
          />
        ) : (
          <ObjectsSettingsNavigationSection
            title={tab.title}
            tabs={tab.linkObjects.map((subTab) => ({
              title: subTab.title,
              replace: true,
              ...getNavigationProps(subTab.link),
            }))}
          />
        ),
      )}
    </div>
  );
}

export default LegalEntityReconcileSettingsNavigation;

import React from 'react';
import { Button, ConfirmationModal, Modal } from 'stories';
import { CONFIRMATION_MODAL_CLASSES } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Formula } from 'bundles/Shared/entities/formula';
import { cn } from '@/shared/lib/css/cn';

type Props = DialogProps<'all' | 'current'> & {
  referenceSource: Formula['reference'];
  referenceTarget: Formula['reference'];
};

const BigButton = ({
  label,
  description,
  onClick,
}: {
  label: string;
  description: string;
  onClick?: () => void;
}) => (
  <div
    onClick={onClick}
    className="border-1 flex cursor-pointer flex-col gap-tw-2 !rounded-lg border-solid border-neutral-100 bg-neutral-000 p-tw-4"
  >
    <span className="inline-semibold text-info-055">{label}</span>
    <span className="secondary-regular text-neutral-500">{description}</span>
  </div>
);

export function ReplaceWithConfirmationChoiseModal({
  referenceSource,
  referenceTarget,
  onSubmit,
  onClose,
}: Props) {
  return (
    <Modal
      toggle={onClose}
      actions={
        <Button variant="secondary" fluid onClick={onClose}>
          Cancel
        </Button>
      }
      showDivider={false}
      classes={{
        ...CONFIRMATION_MODAL_CLASSES,
        body: cn(CONFIRMATION_MODAL_CLASSES.body, 'bg-neutral-000'),
      }}
    >
      <div className="flex flex-col gap-tw-8">
        <div className="flex flex-col justify-center gap-tw-2">
          <ConfirmationModal.Title>Please Confirm</ConfirmationModal.Title>
          <ConfirmationModal.Subtitle>
            How would you like to replace{' '}
            <span className="inline-semibold  text-neutral-800">
              {referenceSource}
            </span>{' '}
            with{' '}
            <span className="inline-semibold  text-neutral-800">
              {referenceTarget}
            </span>
            ?
          </ConfirmationModal.Subtitle>
        </div>
        <BigButton
          onClick={() => onSubmit?.('current')}
          label="In the current Formula"
          description="Update will only be applied to the current formula"
        />
        <BigButton
          onClick={() => onSubmit?.('all')}
          label="In all related formulas"
          description="Update will be applied to every formula with the same missing expression"
        />
      </div>
    </Modal>
  );
}

import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import {
  DEFAULT_DATE_RANGE_OPTIONS,
  RANGE_TO_PERIOD_SHIFT_MAP,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import React from 'react';
import { Control, useController, useFormContext } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { ListOption } from 'stories/Checkbox/CheckList';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';

type SelectProps = Partial<React.ComponentProps<typeof SharedSelect>>;

type RadioProps = Partial<React.ComponentProps<typeof RadioGroup>>;

export function DefaultDateRangeField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  Component extends 'radio' | 'select' = 'radio',
>({
  name,
  control,
  periodShiftName,
  component = 'radio',
  componentProps,
}: {
  control: Control<TFieldValues>;
  name: TName;
  periodShiftName: TName;
  component?: Component;
  componentProps?: Component extends 'select' ? SelectProps : RadioProps;
}) {
  const { field } = useController({
    name,
    control,
  });
  const { setValue } = useFormContext();
  const handleChange = (o: ListOption) => {
    field.onChange(o.value);
    const periodShift = RANGE_TO_PERIOD_SHIFT_MAP[o.value];
    setValue(periodShiftName, periodShift === 'none' ? null : periodShift);
  };
  return component === 'radio' ? (
    <RadioGroup
      options={DEFAULT_DATE_RANGE_OPTIONS}
      value={field.value}
      onChange={handleChange}
      {...(componentProps as RadioProps)}
    />
  ) : (
    <SharedSelect
      options={DEFAULT_DATE_RANGE_OPTIONS}
      value={DEFAULT_DATE_RANGE_OPTIONS.find((o) => o.value === field.value)!}
      onChange={handleChange}
      {...(componentProps as SelectProps)}
    />
  );
}

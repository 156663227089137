import React from 'react';
import { fetchAssetPermissionedUsers } from 'bundles/Assets/actions/fetchAssetPermissionedUsers';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { ViewPermissionedUsersModal } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IAsset } from 'types/Asset';

interface Props {
  asset: IAsset;
}

function AccessCell({ asset }: Props) {
  const { openModal } = useModal();

  const handleViewPermissions = async () => {
    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: () =>
        fetchAssetPermissionedUsers({ assetId: asset.id }),
    });
  };

  return (
    <PermissionListLine
      onClick={handleViewPermissions}
      permissions={{
        directUsers: asset.users,
        directInvestmentEntities: [],
        directRoles: [],
        directTags: [],
        isPublic: false,
        indirectUsers: [],
      }}
    />
  );
}

export default AccessCell;

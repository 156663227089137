import React, { useState } from 'react';
import {
  CrudEntityModalProps,
  CrudModal,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import {
  IReconcileSettingsMarkupUpdate,
  MARKUP_ENTITY_NAME,
} from 'bundles/REconcile/actions/markupsConfig';
import MarkupFields from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/markups/MarkupFields';

function MarkupCrudModal({
  entity,
  onSubmit,
  ...props
}: CrudEntityModalProps<IReconcileSettingsMarkupUpdate>) {
  const [formData, setFormData] = useState(entity);
  return (
    <CrudModal
      entityName={MARKUP_ENTITY_NAME}
      onSubmit={() => onSubmit?.(formData)}
      {...props}
    >
      <MarkupFields formData={formData} setFormData={setFormData} />
    </CrudModal>
  );
}

export default MarkupCrudModal;

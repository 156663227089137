import React from 'react';
import PropTypes from 'prop-types';

import { SreAvatar } from 'stories/index';

const UpdatedByCell = ({ user }) => {
  if (!user) return <p>Unknown</p>;

  return (
    <div className="flex">
      <div className="mr-tw-2">
        <SreAvatar
          className="custom-green avatar-xs"
          width="2.625rem"
          height="2.625rem"
          text={user.initials}
        />
      </div>
      <div className="flex flex-col">
        <div className="text-[1rem]">{user.fullName}</div>
        <div className="text-light-60 mt-tw-2">{user.email}</div>
      </div>
    </div>
  );
};
UpdatedByCell.propTypes = {
  user: PropTypes.shape(),
};
UpdatedByCell.defaultProps = {
  user: undefined,
};

export default UpdatedByCell;

import React, { useMemo, useRef } from 'react';
import { IDetailsItem } from 'bundles/REturn/components/Home/investmentObjectDetailsModal/DetailsTable';
import { useAmchart } from 'lib/amcharts/useAmchart';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { createXYChart, getReturnDashboardTheme } from 'lib/amcharts/utils';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { uniq } from 'lodash-es';
import { getCurrencyTooltipFormat } from '@/shared/lib/formatting/charts';

interface Props {
  items: IDetailsItem[];
}

function DetailsChart({ items }: Props) {
  const ref = useRef(null);
  const data = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        amount: parseFloat(item.amount.toFixed(2)),
        investmentEntityName: item.investmentEntity.name,
      })),
    [items],
  );

  useAmchart(
    ref,
    (root) => {
      // todo replace with more properly way generate colors
      const categoriesSet = uniq(
        items.map((item) => item.investmentEntity.name),
      );
      root.setThemes([
        am5themesAnimated.new(root),
        getReturnDashboardTheme(root),
      ]);

      const chart = createXYChart(root, {});

      const yRenderer = am5xy.AxisRendererY.new(root, {});

      yRenderer.labels.template.setAll({
        oversizedBehavior: 'truncate',
        tooltipText: '{category}',
        textAlign: 'right',
        maxWidth: 140,
        paddingRight: 8,
        fontSize: 12,
      });

      // workaround for empty tooltips
      yRenderer.labels.template.events.on('pointerover', (evt) => {
        evt.target.set('tooltipText', evt.target.get('text'));
      });

      yRenderer.labels.template.setup = (target) => {
        target.set(
          'background',
          am5.Rectangle.new(root, {
            fill: am5.color(0x000000),
            fillOpacity: 0,
          }),
        );
      };

      const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: yRenderer,
          categoryField: 'investmentEntityName',
        }),
      );

      yAxis.data.setAll(data);

      const xAxisRenderer = am5xy.AxisRendererX.new(root, {});
      xAxisRenderer.labels.template.setAll({
        minPosition: 0,
        maxPosition: 0.95,
      });
      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xAxisRenderer,
        }),
      );

      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Amount',
          xAxis,
          yAxis,
          valueXField: 'amount',
          categoryYField: 'investmentEntityName',
          tooltip: am5.Tooltip.new(root, {
            labelText: getCurrencyTooltipFormat('valueX'),
          }),
        }),
      );

      series.columns.template.adapters.add('fill', (fill, target) => {
        const index = categoriesSet.findIndex(
          (value) => value === target.dataItem?.get('categoryY'),
        );
        return am5.ColorSet.new(root, {}).getIndex(index);
      });
      series.data.setAll(data);

      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          xAxis,
        }),
      );
      cursor.lineY.set('visible', false);
    },
    [data],
  );
  return <div className="min-h-[300px]" ref={ref} />;
}

export default DetailsChart;

import NavigationCardList from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import useElementSize from '@/shared/lib/hooks/useElementSize';
import { ManageTagsButton } from '@/widgets/report/manageTags';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import {
  Formula,
  FormulaCard,
  filterFormulaBySearch,
  filterFormulaOrVariableByInUse,
  filterFormulaOrVariableByTags,
  formulaHasInvalidReference,
  openFormulas,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { UntaggedFormulaTag } from 'bundles/Shared/entities/formula/config';
import { EntityTag, TAG_ENTITIES } from 'bundles/Shared/entities/tag';
import { EntityTagFilterDropdown } from 'bundles/Shared/features/tag/filter';
import { orderBy } from 'lodash-es';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Button, Icon, SearchInput, Tumbler } from 'stories';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';

interface Props {
  formulaCardProps?: (
    formula: Formula,
  ) => Partial<React.ComponentProps<typeof FormulaCard>>;
  searchPanel?: React.ReactNode;
  filterInvalidFormulas?: boolean;
}

export function FormulaList({
  formulaCardProps,
  searchPanel,
  filterInvalidFormulas,
}: Props) {
  const dispatch = useAppDispatch();
  const [scroller, setScroller] = useState(null);
  const [initializeOs, osInstance] = useOverlayScrollbars({ defer: true });
  const rootRef = useRef<HTMLDivElement>(null);
  const { height } = useElementSize(rootRef);
  useEffect(() => {
    const { current: root } = rootRef;

    if (scroller && root) {
      initializeOs({
        target: root,
        elements: {
          viewport: scroller,
        },
      });
    }

    return () => osInstance()?.destroy();
  }, [scroller, initializeOs, osInstance]);
  const { formulas, formulasWithErrors, isLoading } = useReportFormulasQuery();
  const [selectedTags, setSelectedTags] = useState<
    (EntityTag | UntaggedFormulaTag)[]
  >([]);
  const { value: onlyInUse, toggle: toggleOnlyInUse } = useBoolean(false);
  const { value: onlyWithErrors, toggle: toggleOnlyWithErrors } =
    useBoolean(false);

  const [searchText, setSearchText] = useState('');

  const filteredFormulas = useMemo(() => {
    const filtered = formulas.filter(
      (formula) =>
        filterFormulaBySearch(formula, searchText) &&
        (selectedTags.length === 0 ||
          filterFormulaOrVariableByTags(formula, selectedTags)) &&
        (!onlyInUse || filterFormulaOrVariableByInUse(formula)) &&
        (!onlyWithErrors || formulaHasInvalidReference(formula)) &&
        (!filterInvalidFormulas || !formulaHasInvalidReference(formula)),
    );
    return orderBy(filtered, (formula) => formula.label.toLowerCase());
  }, [
    formulas,
    searchText,
    onlyInUse,
    selectedTags,
    onlyWithErrors,
    filterInvalidFormulas,
  ]);

  const renderFormulaItem = (index: number) => {
    const formula = filteredFormulas[index];
    return (
      <FormulaCard
        className={cn(
          'group w-full border-x-0 border-b border-t-0 border-solid border-neutral-200',
          index === filteredFormulas.length - 1 && 'border-b-0',
        )}
        key={formula.id}
        label={formula.label}
        description={formula.description}
        reference={formula.reference}
        tags={formula.tags}
        {...formulaCardProps?.(formula)}
      />
    );
  };

  return (
    <>
      <div className="flex gap-tw-2">
        <SearchInput
          className="grow"
          placeholder="Search By Formula Name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {searchPanel}
      </div>
      <div className="flex items-center gap-tw-4">
        <Tumbler checked={onlyInUse} onChange={toggleOnlyInUse}>
          Only In Use
        </Tumbler>
        <EntityTagFilterDropdown
          onChange={setSelectedTags}
          value={selectedTags}
          entity={TAG_ENTITIES.FORMULA}
          panel={<ManageTagsButton entity={TAG_ENTITIES.FORMULA} />}
        />
        <div className="grow" />
        {!filterInvalidFormulas && (
          <div className="border-1 flex items-center gap-tw-3 !rounded-lg border-solid border-neutral-200 bg-neutral-100 pl-tw-2">
            <Icon
              onClick={() => {
                dispatch(openFormulas(formulasWithErrors));
              }}
              className="cursor-pointer text-info-055"
              iconName="externalLinkSquare"
            />
            <Button
              offHoverStyles={onlyWithErrors}
              className={cn(
                'flex items-center gap-tw-2 px-tw-2',
                onlyWithErrors && 'bg-danger-080 text-neutral-000',
              )}
              variant="secondary"
              size="m"
              onClick={toggleOnlyWithErrors}
            >
              <FireIcon priority="high" />
              {formulasWithErrors.length}
            </Button>
          </div>
        )}
      </div>
      {filteredFormulas.length > 0 && (
        <div className="grow" ref={rootRef}>
          <Virtuoso
            style={{ height }}
            totalCount={filteredFormulas.length}
            scrollerRef={setScroller}
            itemContent={renderFormulaItem}
          />
        </div>
      )}
      <NavigationCardList>
        {!isLoading && filteredFormulas.length === 0 && (
          <NoDataOverlay title="No Formulas Found" />
        )}
        {isLoading &&
          Array.from({ length: 5 }).map((_, i) => (
            <SkeletonBlock className="h-[50px] w-full" key={i} />
          ))}
      </NavigationCardList>
    </>
  );
}

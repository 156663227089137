import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import React from 'react';
import { IUser } from 'types/User';

interface Props
  extends Omit<
    ISharedSelectProps<
      {
        value: IUser['id'];
        label: IUser['fullName'];
      },
      false,
      any
    >,
    'value'
  > {
  value?: IUser['id'];
  disabled?: boolean;
}

export const UserSelect = ({ onChange, disabled, options, value }: Props) => (
  <SharedSelect
    placeholder="Select User"
    options={options}
    onChange={onChange}
    value={options?.filter((opt) => opt.value === value)}
    disabled={disabled}
  />
);

import { CapitalInvestmentClass } from '@/entities/return';
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { CAPITAL_INVESTMENT_CLASS_COLOR_MAP } from 'bundles/REturn/components/Ownership/modals/consts';
import { capitalize, sortBy } from 'lodash-es';
import { Icon, Label, Tooltip } from 'stories';

interface Props {
  capitalInvestment: InvestmentIndex;
  withIcon?: boolean;
  withUsers?: boolean;
  withLegalEntity?: boolean;
}

export function InvestmentEntityClassesBadges({
  capitalInvestmentClasses,
}: {
  capitalInvestmentClasses: CapitalInvestmentClass[];
}) {
  return sortBy(capitalInvestmentClasses).map((investmentClass) => (
    <Label
      outline
      size="s"
      className="secondary-regular"
      color={CAPITAL_INVESTMENT_CLASS_COLOR_MAP[investmentClass]}
      key={investmentClass}
    >
      {capitalize(investmentClass)}
    </Label>
  ));
}

function InvestmentEntityColumn({
  capitalInvestment,
  withIcon = true,
  withUsers = true,
  withLegalEntity = false,
}: Props) {
  const { investmentEntity } = capitalInvestment;
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-tw-4">
          {withIcon && (
            <div className="light-60 flex items-center rounded-lg bg-neutral-150 p-tw-2">
              <Icon iconName="bag" className="header4-regular leading-none" />
            </div>
          )}
          <span className="inline-regular">{investmentEntity?.name ?? ''}</span>
        </div>
        <div className="flex items-center gap-tw-2">
          {withUsers && (
            <Tooltip
              placement="bottom"
              mainText={capitalInvestment.investmentEntity.users
                .map((u) => u.fullName)
                .join(', ')}
              disabled={capitalInvestment.investmentEntity.users.length === 0}
            >
              <div className="flex items-center gap-tw-1 text-neutral-500">
                <Icon iconName="members" />
                <p className="secondary-regular">
                  {capitalInvestment.investmentEntity.users.length}
                </p>
              </div>
            </Tooltip>
          )}
          <InvestmentEntityClassesBadges
            capitalInvestmentClasses={capitalInvestment.investmentClasses}
          />
        </div>
      </div>
      {withLegalEntity && (
        <div className="inline-regular flex gap-xs pt-s text-sm text-neutral-550">
          <Icon iconName="entities" />
          {capitalInvestment.legalEntity.name}
        </div>
      )}
    </div>
  );
}

export default InvestmentEntityColumn;

import { Label } from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import { PARSER_STATUS_UI_CONFIG } from 'bundles/Shared/entities/connection/config';
import { IConnection } from 'bundles/Shared/entities/connection/model';

export const ConnectionParseStatusLabel = ({
  parserStatus,
  className,
}: {
  parserStatus?: IConnection['parserStatus'];
  className?: string;
}) => (
  <Label
    outline
    color={PARSER_STATUS_UI_CONFIG[parserStatus ?? 'active'].color}
    className={cn('label-regular', className)}
  >
    {parserStatus}
  </Label>
);

/* eslint-disable new-cap */
import AlbumsList, {
  IFolder,
} from '@/bundles/Assets/components/Folders/AlbumsList';
import {
  useGetObjectFoldersQuery,
  useGetSharedFilesQuery,
} from '@/bundles/Assets/components/Media/api/objectFoldersApi';
import MediaItem from '@/bundles/Assets/components/SharedFiles/MediaItem';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotMutation } from '@/entities/report/reportBuilder';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { cn } from '@/shared/lib/css/cn';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import {
  Button,
  IconButton,
  ImageCard,
  Modal,
  ModalHeaderWithSubtitle,
} from '@/stories';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { IAsset } from '@/types/Asset';
import { ISharedFolder } from '@/types/SharedFolder';
import { arrayMoveImmutable } from 'array-move';
import { isEqual } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const MediaModal = (
  props: DialogProps<{ id: string }[]> & {
    assetId: IAsset['id'];
    initialSelectedFiles?: { id: string }[];
  },
) => {
  const [currentFolder, setCurrentFolder] = useState<ISharedFolder | null>(
    null,
  );
  const [selectedFiles, setSelectedFiles] = useState<{ id: string }[]>(
    props.initialSelectedFiles ?? [],
  );
  const folderId = currentFolder?.id;

  const { data, isFetching: isFoldersDataLoading } = useGetObjectFoldersQuery({
    entityId: props.assetId,
    entityType: 'Asset',
    folderType: 'media',
    parentId: folderId,
  });

  const isSubmitDisabled = useMemo(
    () =>
      isEqual(
        mapListToIds(props.initialSelectedFiles ?? []),
        mapListToIds(selectedFiles),
      ),
    [props.initialSelectedFiles, selectedFiles],
  );

  const { data: sharedFilesData, isFetching: isSharedFilesLoading } =
    useGetSharedFilesQuery(
      {
        folder_id: folderId,
        entity_id: props.assetId,
        entity_type: 'Asset',
        document_types: 'media',
      },
      {
        skip: folderId == null,
        refetchOnMountOrArgChange: true,
      },
    );

  const folders = data ?? [];
  const sharedFiles = sharedFilesData?.sharedFiles ?? [];

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    newIndex: number;
    oldIndex: number;
  }) => {
    const visibleSorted = arrayMoveImmutable(selectedFiles, oldIndex, newIndex);
    document.body.style.cursor = 'default';
    setSelectedFiles([...visibleSorted]);
  };

  const DragHandle = SortableHandle(() => (
    <IconButton variant="secondary" iconName="move" aria-hidden="true" />
  ));

  const SortableItem = SortableElement(
    ({
      value,
    }: {
      value: (typeof sharedFiles)[number] & { dataUri: string };
    }) => (
      <div className="relative">
        <MediaItem
          media={{ ...value, smallImageUrl: value.url ?? value.dataUri }}
          actions={
            <div className="absolute bottom-tw-4 left-tw-4 flex gap-tw-2">
              <DragHandle />
              <IconButton
                iconName="trash"
                onClick={() =>
                  setSelectedFiles((prev) =>
                    prev.filter((item) => item.id !== value.id),
                  )
                }
              />
            </div>
          }
          hideActions
          selectedItems={[]}
          size="m"
        />
      </div>
    ),
  );

  const SortableList = SortableContainer(
    ({ items }: { items: typeof sharedFiles }) => (
      <div className="flex flex-wrap gap-tw-4">
        {items.map((value, index) => (
          // @ts-expect-error
          <SortableItem index={index} key={index} value={value} />
        ))}
      </div>
    ),
  );

  const hasFolders = folders.length > 0;
  const hasSharedFiles = sharedFiles.length > 0;
  const noFolders =
    !hasFolders &&
    !hasSharedFiles &&
    !isFoldersDataLoading &&
    !isSharedFilesLoading;

  const noSharedFilesInThisFolder = !hasSharedFiles && !isSharedFilesLoading;

  return (
    <Modal
      header={<ModalHeaderWithSubtitle title="Add Media" />}
      size="1400"
      toggle={props.onClose}
      classes={{
        body: 'flex flex-col gap-tw-4',
      }}
      actions={
        <div className="flex w-full justify-between">
          <Button variant="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={isSubmitDisabled}
            onClick={() => {
              props.onSubmit?.(selectedFiles);
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      {folderId != null && (
        <Button
          iconName="arrowLeft"
          className="max-w-max"
          onClick={() => {
            setCurrentFolder(null);
          }}
        >
          All Albums
        </Button>
      )}

      {!isFoldersDataLoading && hasFolders && (
        <div
          className={cn('flex flex-col gap-tw-2 pb-tw-4', {
            'border-b-[1px] border-solid border-neutral-150 ': noFolders,
          })}
        >
          <p className="inline-semibold text-neutral-550">
            {folders.length} {pluralize('Album', folders.length)}
          </p>

          <AlbumsList
            folders={folders as unknown as IFolder[]}
            onClick={(id) => {
              setCurrentFolder(folders.find((f) => f.id === id)!);
            }}
          />
        </div>
      )}
      {noFolders && (
        <NoDataOverlay title="There is no media and albums available, you can upload to the gallery" />
      )}
      {noSharedFilesInThisFolder && !noFolders && currentFolder != null && (
        <NoDataOverlay
          title="There is no media available in this album, you can upload to the
          gallery"
        />
      )}
      {isFoldersDataLoading && (
        <div className="flex flex-col gap-tw-2 border-b-[1px] border-solid border-neutral-150 pb-tw-4">
          <p className="inline-semibold text-neutral-550">Albums</p>

          <div className="flex gap-tw-6">
            <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
            <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
            <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
            <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
          </div>
        </div>
      )}

      {!isSharedFilesLoading && folderId != null && hasSharedFiles && (
        <>
          <p className="inline-semibold text-neutral-550">
            {sharedFiles.length} Media {pluralize('file', sharedFiles.length)}
          </p>

          <div className="grid gap-tw-6 border-b-[1px] border-solid border-neutral-150 pb-tw-4 md:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
            {sharedFiles.map((m) => (
              <div key={m.id}>
                <ImageCard
                  image={m.smallImageUrl ?? m.url}
                  subtitle={m.description ?? ''}
                  onSelect={() => {
                    setSelectedFiles((prev) => {
                      if (prev.find((i) => i.id === m.id)) {
                        return prev.filter((i) => i.id !== m.id);
                      }
                      return [...prev, m];
                    });
                  }}
                  selected={Boolean(selectedFiles.find((i) => i.id === m.id))}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {isSharedFilesLoading && (
        <>
          <p className="inline-semibold text-neutral-550">Media files</p>

          <div className="grid gap-tw-6 border-b-[1px] border-solid border-neutral-150 pb-tw-4 md:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
            <SkeletonBlock className="h-[227px] w-[316px] rounded-[16px]" />
            <SkeletonBlock className="h-[227px] w-[316px] rounded-[16px]" />
            <SkeletonBlock className="h-[227px] w-[316px] rounded-[16px]" />
            <SkeletonBlock className="h-[227px] w-[316px] rounded-[16px]" />
          </div>
        </>
      )}

      {selectedFiles.length > 0 && (
        <>
          <p className="inline-semibold text-neutral-550">
            {selectedFiles.length} Selected Media{' '}
            {pluralize('file', selectedFiles.length)}
          </p>

          <SortableList
            axis="xy"
            items={selectedFiles}
            onSortStart={() => {
              document.body.style.cursor = 'grabbing';
            }}
            onSortEnd={onSortEnd}
            helperClass="field_drag__active"
            useDragHandle
          >
            {selectedFiles.map((item, index) => (
              <SortableItem index={index} key={index} value={item} />
            ))}
          </SortableList>
        </>
      )}
    </Modal>
  );
};

export const MediaWidgetConfigCard = (props: WidgetConfigCardProps) => {
  const { openModal } = useModal();
  const [updateWidget] =
    usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotMutation();

  const items =
    props.data?.widget != null && 'items' in props.data.widget
      ? props.data?.widget?.items ?? []
      : [];

  return (
    <BaseWidgetConfigCard
      {...props}
      title={
        <div className="flex items-center gap-tw-2">
          <p>{props.title}</p>
          {(items.length ?? 0) > 0 && (
            <div className="secondary-semibold flex h-[24px] w-[24px] items-center justify-center rounded-[8px] bg-neutral-100 text-neutral-900">
              {items.length}
            </div>
          )}
        </div>
      }
      hasData={widgetHasData(props.data)}
      classes={{
        label: '',
      }}
      labelSlot={
        <BaseWidgetConfigCard.Actions>
          <IconButton
            disabled={props.disabled}
            onClick={async () => {
              const res = await openModal(MediaModal, {
                assetId: props.assetId,
                initialSelectedFiles: items,
              });

              if (res == null) return;

              updateWidget({
                id: props.widgetSection.id,
                assetId: Number(props.assetId),
                body: {
                  snapshot_data: {
                    items: res.map((i) => ({
                      id: i.id,
                      type: 'shared_file',
                    })),
                  },
                },
                reportId: props.reportId,
              });
            }}
            iconName="edit"
          />
        </BaseWidgetConfigCard.Actions>
      }
    />
  );
};

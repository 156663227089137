import React from 'react';
import {
  useCrudContext,
  getCrudActionsColumn,
  CrudActionsColumnProps,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import Table from 'bundles/Shared/components/Table/Table';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import { IColumn, ITableProps } from 'bundles/Shared/components/Table/types';

type Props<T> = Omit<ITableProps<T>, 'settings' | 'setSettings' | 'items'> &
  Pick<CrudActionsColumnProps<T>, 'editDisabled' | 'removeDisabled'> & {
    actionsColumn?: IColumn<T>;
  };

export function CrudTable<T>({
  columns,
  editDisabled,
  removeDisabled,
  actionsColumn,
  ...props
}: Props<T>) {
  const { setPageParams, pageParams, entities, entityName, handlers } =
    useCrudContext();
  const { setSettings, settings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  return (
    <Table
      {...props}
      items={entities}
      setSettings={setSettings}
      settings={settings}
      columns={[
        ...columns,
        actionsColumn ||
          getCrudActionsColumn({
            onEdit: handlers.update,
            onRemove: handlers.delete,
            editDisabled,
            removeDisabled,
            confirmProps: (row) => ({
              title: `Remove ${entityName}`,
              subtitle: (
                <>
                  Are you sure you want to remove{' '}
                  <span className="dark-60 inline-semibold">{row.name}</span>{' '}
                  {entityName}
                </>
              ),
            }),
          }),
      ]}
    />
  );
}

export default CrudTable;

import { cn } from '@/shared/lib/css/cn';
import { SettingsHugeTab } from './SettingsHugeTab';
import { IInvestmentEntity } from '../types';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import pluralize from 'pluralize';
import { useEffect, useMemo } from 'react';
import { IconButton, InlineObject, LinkButton, Popover } from 'stories';
import styles from './InvestmentEntitiesFilter.module.scss';
import MobileManageModal from './MobileManageModal';
import ObjectList from './ObjectList';
import { useNavigate } from '@reach/router';
import { useGetApiPortfolioInvestmentEntitiesQuery } from 'bundles/Shared/shared/api/portfolioEnhancedApi';

interface Props {
  activeInvestmentEntitiesIds: IInvestmentEntity['id'][];
  onActiveInvestmentEntitiesChange: (
    investmentEntities: IInvestmentEntity['id'][],
  ) => void;
}

export const objectsCount = (quantity: number, mask: string) => {
  if (quantity === 0) return undefined;
  return `${quantity} ${pluralize(mask, quantity)}`;
};

const OneEntityDesktop = ({ item }: { item: IInvestmentEntity }) => (
  <>
    <ObjectList type="fund" title="Funds" items={item.funds} />
    <ObjectList type="asset" title="Assets" items={item.assets} />
  </>
);

const OneEntityMobile = ({ item }: { item: IInvestmentEntity }) => (
  <div className="secondary-regular text-light-90">
    {[
      objectsCount(item.assets.length, 'Asset'),
      objectsCount(item.funds.length, 'Fund'),
    ]
      .filter(Boolean)
      .join(', ')}
  </div>
);

const MultipleEntitiesDesktop = ({
  isActive,
  entity,
  isAllSelected,
  activeInvestmentEntitiesIds,
}: {
  isActive: boolean;
  entity: IInvestmentEntity;
  isAllSelected: boolean;
  activeInvestmentEntitiesIds: IInvestmentEntity['id'][];
}) => {
  const navigate = useNavigate();
  const objectGroups = useMemo(
    () =>
      [
        {
          iconName: 'funds' as const,
          label: 'Funds',
          type: 'fund' as const,
          entities: entity.funds,
        },
        {
          iconName: 'asset' as const,
          label: 'Assets',
          type: 'asset' as const,
          entities: entity.assets,
        },
      ].filter(({ entities }) => entities.length > 0),
    [entity],
  );

  return (
    <div className="flex w-full flex-col gap-tw-2">
      <div
        className={cn('flex w-full content-center justify-between gap-tw-2')}
      >
        <p className="inline-semibold inline-flex">
          {/* {!isAllSelected && !isActive && (*/}
          {/*  <Icon iconName="eyeSlash" className="mr-1 mt-[2px]" />*/}
          {/* )}*/}
          {entity.name}
        </p>
        {
          <IconButton
            variant="white"
            iconName={isActive && !isAllSelected ? 'eyeSlash' : 'eye'}
            className={cn(
              styles.actionButton,
              ((activeInvestmentEntitiesIds.length > 1 && isActive) ||
                !isActive) &&
                'invisible',
            )}
          />
        }
      </div>

      <div className="secondary-regular flex items-center gap-tw-2">
        {objectGroups.map(({ iconName, label, entities, type }) => (
          <Popover
            key={type}
            placement="bottom-start"
            arrowPosition="start"
            offset={[0, 4]}
            maxWidth="max-content"
            className="min-w-[200px]"
            appendToBody
            template={<ObjectList items={entities} type={type} />}
          >
            <InlineObject
              key={iconName}
              hideTooltipWhenOverflow
              className="bg-light"
              iconName={iconName}
              object={
                <>
                  <span className="pr-tw-1 text-light-60">{label}</span>
                  {entities.length}
                </>
              }
            />
          </Popover>
        ))}
      </div>
    </div>
  );
};

const MultipleEntitiesMobile = ({
  investmentEntities,
  activeInvestmentEntitiesIds,
  openManageModal,
}: {
  investmentEntities: IInvestmentEntity[];
  activeInvestmentEntitiesIds: IInvestmentEntity['id'][];
  openManageModal: () => void;
}) => (
  <div className="flex flex-row items-center justify-between gap-tw-2 bg-white px-[12px] py-[8px]">
    <div className="inline-semibold text-dark-60">
      {investmentEntities.length === activeInvestmentEntitiesIds.length
        ? `${investmentEntities.length} Investment ${pluralize(
            'Entities',
            investmentEntities.length,
          )}`
        : `${activeInvestmentEntitiesIds.length} of ${
            investmentEntities.length
          } Investment ${pluralize('Entities', investmentEntities.length)}`}
    </div>
    <div>
      <LinkButton onClick={openManageModal} className="label-semibold">
        Manage
      </LinkButton>
    </div>
  </div>
);

function InvestmentEntitiesFilter({
  activeInvestmentEntitiesIds,
  onActiveInvestmentEntitiesChange,
}: Props) {
  const { data } = useGetApiPortfolioInvestmentEntitiesQuery({});
  const investmentEntities: IInvestmentEntity[] = data ?? [];

  useEffect(() => {
    if (investmentEntities == null) return;
    if (activeInvestmentEntitiesIds.length === 0) {
      onActiveInvestmentEntitiesChange(mapListToIds(investmentEntities));
    }
  }, [investmentEntities]);

  const { openModal } = useModal();
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);

  const openManageModal = async () => {
    if (investmentEntities) {
      const ids = await openModal(MobileManageModal, {
        investmentEntities,
        activeInvestmentEntitiesIds,
      });
      if (ids == null) return;
      onActiveInvestmentEntitiesChange(ids);
    }
  };

  const isAllSelected = useMemo(
    () => activeInvestmentEntitiesIds.length === investmentEntities.length,
    [activeInvestmentEntitiesIds, investmentEntities],
  );

  function handleClick(id: IInvestmentEntity['id']) {
    if (isAllSelected) {
      onActiveInvestmentEntitiesChange(
        activeInvestmentEntitiesIds.filter((item) => item === id),
      );
    } else {
      if (
        activeInvestmentEntitiesIds.length === 1 &&
        activeInvestmentEntitiesIds[0] === id
      )
        return;

      onActiveInvestmentEntitiesChange(
        activeInvestmentEntitiesIds.includes(id)
          ? activeInvestmentEntitiesIds.filter((item) => item !== id)
          : activeInvestmentEntitiesIds.concat(id),
      );
    }
  }

  const handleAllClick = () => {
    onActiveInvestmentEntitiesChange(mapListToIds(investmentEntities));
  };

  const resolveTitle = () => {
    if (investmentEntities.length === 1) return 'Investment Entity';
    if (isAllSelected && !isMediumWidth) return 'Shown';
    if (isAllSelected && investmentEntities.length > 1) {
      return `${investmentEntities.length} Investment ${pluralize(
        'Entities',
        investmentEntities.length,
      )}`;
    }

    return (
      <div className="flex content-center justify-between">
        {`Shown ${activeInvestmentEntitiesIds.length} of ${investmentEntities.length}`}
        <LinkButton onClick={handleAllClick} className="label-semibold">
          Show All
        </LinkButton>
      </div>
    );
  };

  const [firstInvestmentEntity] = investmentEntities;

  return (
    <div className={styles.container}>
      <div className={cn(styles.title, 'label-semibold')}>{resolveTitle()}</div>
      <div className={cn('flex flex-col gap-[1px]', styles.list)}>
        {investmentEntities.length === 1 && (
          <div className="flex flex-col gap-tw-4 bg-neutral-000 p-tw-2">
            <div className="inline-semibold px-tw-2 text-neutral-850">
              {firstInvestmentEntity.name}
            </div>
            {isMediumWidth ? (
              <OneEntityDesktop item={firstInvestmentEntity} />
            ) : (
              <OneEntityMobile item={firstInvestmentEntity} />
            )}
          </div>
        )}

        {investmentEntities.length > 1 &&
          isMediumWidth &&
          investmentEntities.map((ent) => {
            const isActive = activeInvestmentEntitiesIds.includes(ent.id);

            return (
              <div className={styles.item} key={ent.id}>
                <SettingsHugeTab
                  handleClick={() => handleClick(ent.id)}
                  inactive={!isActive}
                >
                  <MultipleEntitiesDesktop
                    isActive={isActive}
                    entity={ent}
                    isAllSelected={isAllSelected}
                    activeInvestmentEntitiesIds={activeInvestmentEntitiesIds}
                  />
                </SettingsHugeTab>
              </div>
            );
          })}

        {!isMediumWidth && investmentEntities.length > 1 && (
          <MultipleEntitiesMobile
            investmentEntities={investmentEntities}
            activeInvestmentEntitiesIds={activeInvestmentEntitiesIds}
            openManageModal={openManageModal}
          />
        )}
      </div>
    </div>
  );
}

export default InvestmentEntitiesFilter;

import * as React from 'react';
import dayjs from 'dayjs';
import { isDayMinMaxRestricted } from 'stories/Calendar/lib';

export interface UseCalendarDependencies
  extends Pick<
    {},
    | 'onHeaderChange'
    | 'onNextMonth'
    | 'onPrevMonth'
    | 'minDateTime'
    | 'maxDateTime'
    | 'shouldDisableDate'
    | 'disableFuture'
    | 'disablePast'
  > {
  value?: Array<Date | null> | Date;
}

export function useCalendar({
  value,
  disablePast,
  disableFuture,
  shouldDisableDate,
  onHeaderChange,
  onNextMonth,
  onPrevMonth,
  minDateTime,
  maxDateTime,
}: UseCalendarDependencies) {
  const [viewDate, setViewDate] = React.useState(
    (Array.isArray(value) ? value[0] : value) ?? new Date(),
  );
  const [focusedDay, setFocusedDay] = React.useState<Date>();

  const setPrevMonth = React.useCallback(() => {
    onPrevMonth?.();
    setViewDate(dayjs(viewDate).subtract(1, 'month').toDate());
  }, [viewDate, onPrevMonth]);
  const setNextMonth = React.useCallback(() => {
    onNextMonth?.();
    setViewDate(dayjs(viewDate).add(1, 'month').toDate());
  }, [viewDate, onNextMonth]);

  const handleSetViewDate = React.useCallback(
    (value: Date) => {
      onHeaderChange?.(value);
      setViewDate(value);
    },
    [onHeaderChange],
  );

  const isDayFocused = React.useCallback(
    (day: Date) =>
      Boolean(focusedDay && dayjs(day).isSame(dayjs(focusedDay), 'day')),
    [focusedDay],
  );

  const isDayDisabled = React.useCallback(
    (day: Date, withTime?: boolean) => {
      const now = new Date();
      if (shouldDisableDate) {
        return shouldDisableDate(day);
      }
      if (disableFuture) {
        return dayjs(day).startOf('day').isAfter(dayjs(now));
      }
      if (disablePast) {
        return dayjs(day).endOf('day').isBefore(dayjs(now));
      }
      if (minDateTime || maxDateTime) {
        return isDayMinMaxRestricted(day, {
          min: minDateTime,
          max: maxDateTime,
          withTime,
        });
      }

      return false;
    },
    [disableFuture, disablePast, shouldDisableDate, minDateTime, maxDateTime],
  );

  const resetSelectedDay = React.useCallback(() => {
    setFocusedDay(undefined);
  }, [setFocusedDay]);

  return {
    viewDate,
    setViewDate: handleSetViewDate,
    setPrevMonth,
    setNextMonth,
    focusedDay,
    setFocusedDay,
    isDayFocused,
    isDayDisabled,
    resetSelectedDay,
  };
}

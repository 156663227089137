import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import Checkbox from 'stories/Checkbox/Checkbox';
import IconButton from 'stories/IconButton/IconButton';
import { IconsId } from 'types/sre-icons';
import './albumCard.css';
import Icon from 'stories/Icon/Icon';

interface Props {
  imageCover: string;
  selected: boolean;
  title: string;
  description: string;
  onSelectToggle?: () => void;
  onClick: () => void;
  actions: {
    icon: IconsId;
    onClick: () => void;
    className?: string;
  }[];
  imagePlaceholder?: IconsId;
  alwaysShowActions?: boolean;
  checkboxDisabled?: boolean;
  items?: React.ReactNode;
}

export const AlbumCard = ({
  imageCover,
  selected,
  onSelectToggle,
  title,
  description,
  onClick,
  actions,
  imagePlaceholder,
  alwaysShowActions = false,
  checkboxDisabled,
  items,
}: Props) => {
  const onClickWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target !== e.currentTarget) return;
    onClick();
  };

  const checkboxIsVisible = alwaysShowActions || selected;

  return (
    <div className="album-card__wrapper">
      <div
        className={cn('album-card__container', {
          'album-card__container-selected': selected,
        })}
      >
        <div className="album-card__image-container">
          {imageCover && (
            <img
              src={imageCover}
              alt="image"
              className="album-card__image"
              onClick={onClickWrapper}
            />
          )}
          {!imageCover && imagePlaceholder && (
            <div onClick={onClickWrapper} className="album-card__default-icon">
              <Icon
                className="light-60"
                style={{ fontSize: '3.75rem' }}
                onClick={onClickWrapper}
                iconName={imagePlaceholder}
              />
            </div>
          )}
          <div className="p-s flex justify-between flex-col w-full">
            <div
              className={
                alwaysShowActions
                  ? 'flex justify-between'
                  : 'album-card__hovered'
              }
              onClick={onClickWrapper}
            >
              {onSelectToggle ? (
                <div
                  className={cn('album-card__checkbox-container', {
                    'album-card__checkbox-container_visible': checkboxIsVisible,
                  })}
                >
                  <Checkbox
                    onChange={onSelectToggle}
                    outline="true"
                    checked={selected}
                    disabled={checkboxDisabled}
                  />
                </div>
              ) : (
                <div className="justify-end" />
              )}
              <div className={cn('album-card__actions')}>
                {actions.map((action) => (
                  <IconButton
                    onClick={action.onClick}
                    size="l"
                    offHoverStyles
                    className={cn(action.className, 'position-relative ml-s')}
                    iconName={action.icon}
                  />
                ))}
              </div>
            </div>
            {items && (
              <div className="flex ml-auto">
                <div className="album-card__bottom-badge">{items}</div>
              </div>
            )}
          </div>
        </div>
        <div className="album-card__text">
          {title && (
            <div className="album-card__text_title" onClick={onClickWrapper}>
              {title}
            </div>
          )}
          {description && (
            <div className="album-card__text_subtitle" onClick={onClickWrapper}>
              {description}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlbumCard;

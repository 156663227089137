import React, { ReactNode } from 'react';

interface Props {
  content: ReactNode;
  actions: ReactNode;
  header?: ReactNode;
}

export const AssetInfoCard = ({ content, actions, header }: Props) => (
  <div>
    {header ? (
      <div className="radius008 rounded-2xl border-solid border-neutral-100 bg-neutral-050 p-tw-4">
        <div className="flex justify-between">
          {header}
          <div className="flex gap-tw-2">{actions}</div>
        </div>
        {content}
      </div>
    ) : (
      <div className="radius008 flex justify-between rounded-2xl border-solid border-neutral-100 bg-neutral-050 p-tw-4">
        <div>{content}</div>
        <div className="flex gap-tw-2">{actions}</div>
      </div>
    )}
  </div>
);

export default AssetInfoCard;

import React, { useEffect, useMemo, useState } from 'react';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { navigate } from '@reach/router';
import { ConfirmationModal, Modal } from 'stories';
import { IChangeOrder } from 'bundles/Construction/types';
import GroupForm from '../../Shared/components/GroupForm/GroupForm';
import getItemsCOModal from './ChangeOrder/initData';
import { plainFetchChangeOrderEvents } from '../actions/changeOrderEvents';

interface Props {
  onSubmit: (formData: IChangeOrder) => void;
}

const ChangeOrderWorkflow = ({ onSubmit }: Props) => {
  const [formData, setFormData] = useState<IChangeOrder>({});
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [disabledCloseIcon, setDisabledCloseIcon] = useState(false);
  const [changeOrderEvents, setChangeOrderEvents] = useState([]);
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );

  useEffect(() => {
    const params = { incomplete: true, per_page: 100 };
    plainFetchChangeOrderEvents(legalEntity.code, params)
      .then((res) => res.json())
      .then((json) =>
        setChangeOrderEvents(json.changeOrderEvents.filter((ce) => !ce.inCo)),
      );
  }, []);

  const initGroupFormItems = useMemo(
    () => getItemsCOModal(formData, setFormData, changeOrderEvents),
    [formData, setFormData, changeOrderEvents],
  );

  const submit = async () => {
    let presignedFiles = [];
    if (formData.sharedFiles) {
      presignedFiles = await presignFiles(
        formData.sharedFiles.length
          ? formData.sharedFiles
          : [formData.sharedFiles],
      );
    }
    await Promise.all(presignedFiles.map((file) => directUpload(file)));

    const formDataTransform = {};
    const COItems = [];
    Object.keys(formData).forEach((key) => {
      initGroupFormItems.forEach((item) => {
        const findItem = item.formItems.find(
          (p) => p.fieldName === key && formData[key],
        );
        if (findItem) COItems.push(findItem);
      });
    });
    COItems.forEach((formItem) => {
      formDataTransform[formItem.fieldNameSnap] = formData[formItem.fieldName];
    });
    return onSubmit({
      ...formDataTransform,
      change_order_event_ids: formData.changeOrderEvents.map((i) => i.id),
      files: presignedFiles.map((f) => ({
        key: f.signedData.fields.key,
        filename: f.file.name,
        content_type: f.file.type,
        size: f.file.size,
      })),
    });
  };

  const closeCOR = () => {
    setConfirmModal(true);
  };

  const confirmModalHandle = (status: boolean) => {
    if (status) {
      navigate('./');
    }
    setConfirmModal(false);
  };

  const disabledCloseIconFunc = () => {
    setDisabledCloseIcon(true);
  };

  return (
    <>
      <Modal
        header={
          <div>
            <div className="label-regular">{legalEntity.assetName}</div>
            <div className="dark header6-bold">Create Change Order</div>
          </div>
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={closeCOR}
        disabledClose={disabledCloseIcon}
      >
        <GroupForm
          groupFormItems={initGroupFormItems}
          onSubmit={submit}
          formData={formData}
          setFormData={setFormData}
          buttonConfig={{
            titleBtn: 'Review & Confirm',
            titleView: 'Create Change Order',
            titleLoading: 'Creating Change Order',
            width: '100%',
            iconName: 'scroll',
            withoutCancel: true,
            message: 'Complete all required steps to Review & Confirm',
            funcSendForm: disabledCloseIconFunc,
          }}
          groupFormConfig={{
            reviewFlow: true,
            steps: true,
          }}
        />
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure that you want to quit?"
          subtitle="The provided data will be lost."
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};
export default ChangeOrderWorkflow;

import { IWorkflowConfig } from 'bundles/REconcile/actions/workflowConfig';
import { TWorkflowThresholdType } from 'bundles/REconcile/actions/workflowThresholds';

const CATEGORY_TO_AWAITING_APPROVAL_MAPPING: Record<
  TWorkflowThresholdType,
  keyof IWorkflowConfig['corsAwaitingApproval']
> = {
  [TWorkflowThresholdType.TIM]: 'estimatedTimingImpact',
  [TWorkflowThresholdType.COR]: 'individualAmount',
  [TWorkflowThresholdType.AGG]: 'aggregateContingency',
};

export const hasCorsAwaitingApproval = (
  workflow: IWorkflowConfig | undefined,
  category: TWorkflowThresholdType,
) => {
  if (workflow === undefined) {
    return false;
  }

  const key = CATEGORY_TO_AWAITING_APPROVAL_MAPPING[category];

  return workflow.corsAwaitingApproval[key].length > 0;
};

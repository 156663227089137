import { cn } from '@/shared/lib/css/cn';
import { RolesFilterList } from 'bundles/Shared/features/user/filter/byRole';
import { camelCase, capitalize, isEqual, startCase } from 'lodash-es';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { ComponentProps, useState } from 'react';
import { Checkbox, Icon, SearchInput, SreAvatar, Tooltip } from 'stories';
import { IUserTag } from 'types/IUserTag';
import { IUser, IUserRole } from 'types/User';
import { TOOLITP_DICTIONARY } from './dictionary';

function ItemsList<T extends { id: number | string }>({
  term,
  records,
  onChange,
  renderItem,
  autoSelectedIds = [],
  filterable,
  title,
  selectedIds,
  tooltipTextResolver,
}: {
  records: T[];
  onChange: (val: T[] | T) => void;
  filterable: keyof T;
  renderItem: (item: T) => React.ReactNode;
  title: string;
  selectedIds: T['id'][];
  term?: string;
  toolTipText?: string;
  tooltipTextResolver?: (item: T) => string;
  autoSelectedIds?: T['id'][];
}) {
  const filteredRecords = term
    ? records.filter((item) =>
        item[filterable].toLowerCase().includes(term.toLowerCase()),
      )
    : records;
  const ableToCheckRecords = filteredRecords.filter(
    (r) => !autoSelectedIds.includes(r.id),
  );
  const allChecked =
    isEqual(
      selectedIds.sort(),
      ableToCheckRecords.map(({ id }) => id).sort(),
    ) && filteredRecords.length > 0;
  return (
    <ul className="flex flex-col gap-tw-2">
      <li>
        <div className="checkbox">
          <Checkbox
            id={`${camelCase(title)}-all`}
            checked={allChecked}
            onChange={() => onChange(allChecked ? [] : ableToCheckRecords)}
            disabled={filteredRecords.length === 0}
            indeterminate={selectedIds.length > 0}
          >
            <div className="font-weight-bold">
              Select All {startCase(pluralize(capitalize(title)))}
            </div>
          </Checkbox>
        </div>
      </li>
      {filteredRecords.map((item, idx) => (
        <li key={item.id}>
          <Tooltip
            mainText={tooltipTextResolver?.(item) ?? ''}
            disabled={!autoSelectedIds.includes(item.id)}
          >
            <div
              className={cn('checkbox', {
                flex: idx === filteredRecords.length - 1,
              })}
            >
              <Checkbox
                id={`${camelCase(title)}-${item.id}-checkbox`}
                disabled={autoSelectedIds.includes(item.id)}
                checked={[...selectedIds, ...autoSelectedIds].includes(item.id)}
                onChange={() => onChange(item)}
              >
                <div className="inline-regular">{renderItem(item)}</div>
              </Checkbox>
            </div>
          </Tooltip>
        </li>
      ))}
    </ul>
  );
}

const CommonTabWithSearchTerm = ({
  term,
  setTerm,
  itemsList,
  title,
}: {
  term: string;
  setTerm: (val: string) => void;
  itemsList: React.ReactNode;
  title: string;
}) => (
  <div className="py-tw-4">
    <SearchInput
      onChange={(e) => setTerm(e.target.value)}
      placeholder={`Search ${title}`}
      className="mb-m"
      value={term}
      resetValue={setTerm}
    />
    {itemsList}
  </div>
);

const CommonTabWithSearchTermWrapper = ({
  ItemsListComponent,
  title,
}: {
  title: string;
  ItemsListComponent: React.ReactElement;
}) => {
  const [term, setTerm] = useState('');
  return (
    <CommonTabWithSearchTerm
      setTerm={setTerm}
      title={title}
      term={term}
      itemsList={
        // eslint-disable-next-line react/jsx-pascal-case
        <ItemsListComponent.type {...ItemsListComponent.props} term={term} />
      }
    />
  );
};

export function RolesTab<
  R extends Pick<IUserRole, 'id' | 'group' | 'category' | 'name'>,
>(props: ComponentProps<typeof RolesFilterList<R>>) {
  return (
    <CommonTabWithSearchTermWrapper
      title="role"
      ItemsListComponent={<RolesFilterList {...props} />}
    />
  );
}

export function TagsTab<T extends Pick<IUserTag, 'id' | 'name'>>({
  records,
  selectedIds,
  onChange,
}: {
  records: T[];
  selectedIds: T['id'][];
  onChange: (val: T[] | T) => void;
}) {
  const title = 'tag';

  return (
    <CommonTabWithSearchTermWrapper
      title={title}
      ItemsListComponent={
        <ItemsList
          title={title}
          records={records}
          selectedIds={selectedIds}
          onChange={onChange}
          filterable="name"
          renderItem={(item) => (
            <span className="flex items-center gap-tw-1">
              <span className="sre-icon-tag inline-regular" /> {item.name}
            </span>
          )}
        />
      }
    />
  );
}

export function MembersTab<M extends IUser>({
  records,
  selectedIds,
  onChange,
  indirectUserIds,
}: {
  records: M[];
  selectedIds: M['id'][];
  onChange: (val: M[] | M) => void;
  indirectUserIds?: M['id'][];
}) {
  const title = 'member';
  return (
    <CommonTabWithSearchTermWrapper
      title={title}
      ItemsListComponent={
        <ItemsList
          title={title}
          records={records}
          selectedIds={selectedIds}
          autoSelectedIds={indirectUserIds}
          onChange={onChange}
          filterable="fullName"
          tooltipTextResolver={(item) =>
            item.undeselectable
              ? TOOLITP_DICTIONARY.UndeselectableUser
              : TOOLITP_DICTIONARY.IndirectUser
          }
          renderItem={(item) => (
            <div className="flex items-center gap-tw-2">
              <SreAvatar
                text={item.fullName}
                backgroundColor="#92D6A5"
                color="#1C8336"
                height="2rem"
                width="2rem"
              />
              <div>
                <div className="inline-regular break-all font-normal">
                  {item.fullName}
                </div>
                <div className="inline-regular font-normal text-light-60">
                  {item.role?.name}
                </div>
              </div>
            </div>
          )}
        />
      }
    />
  );
}

export const AssetsTab = ({ records, selectedIds, onChange }) => (
  <CommonTabWithSearchTermWrapper
    title="asset"
    ItemsListComponent={
      <ItemsList
        records={records}
        selectedIds={selectedIds}
        onChange={onChange}
        filterable="name"
        renderItem={(item) => <span className="mt-tw-2">{item.name}</span>}
      />
    }
  />
);
AssetsTab.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FundsTab = ({ records, selectedIds, onChange }) => (
  <CommonTabWithSearchTermWrapper
    records={records}
    selectedIds={selectedIds}
    onChange={onChange}
    title="fund"
    ItemsListComponent={
      <ItemsList
        records={records}
        selectedIds={selectedIds}
        onChange={onChange}
        filterable="name"
        renderItem={(item) => <span className="mt-tw-2">{item.name}</span>}
      />
    }
  />
);
FundsTab.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const DocumentTypesTab = ({ records, selectedIds, onChange }) => (
  <CommonTabWithSearchTermWrapper
    title="document type"
    ItemsListComponent={
      <ItemsList
        records={records}
        selectedIds={selectedIds}
        onChange={onChange}
        filterable="name"
        renderItem={(item) => <span className="mt-tw-2">{item.name}</span>}
      />
    }
  />
);
DocumentTypesTab.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const InvestmentEntitiesTab = ({ records, selectedIds, onChange }) => (
  <CommonTabWithSearchTermWrapper
    title="entities"
    ItemsListComponent={
      <ItemsList
        records={records}
        selectedIds={selectedIds}
        onChange={onChange}
        filterable="name"
        renderItem={(item) => (
          <div className="flex items-center gap-s">
            <Icon className="light-60" iconName="entities" />
            <span className="mt-tw-2">{item.name}</span>
          </div>
        )}
      />
    }
  />
);
InvestmentEntitiesTab.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

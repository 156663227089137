import { ComparisonDashboardDto } from '@/bundles/Shared/shared/api/dashboardsGeneratedApi';
import { resolveFinancialTableWidgetDefaultOptionsDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { FinancialTableSinglePeriodPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { FinancialTableSinglePeriodWidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/widget';
import { LegalEntity } from '@/entities/core/legalEntity';
import { UnknownRecord } from 'type-fest/source/internal';

type BaseState<R extends UnknownRecord> = R & {
  groupingType: 'assets';
  excludedLegalEntityIds?: LegalEntity['id'];
};

export const getInitialComparisonDashboardState = <
  WT extends
    ComparisonDashboardDto['boards'][number]['sections'][number]['widgetType'],
>(
  widgetType: WT,
  widgetSection: FinancialTableSinglePeriodWidgetProps,
):
  | BaseState<{
      date?: DateString;
      periodType?: 'week';
      granularity?: 'day';
    }>
  | BaseState<{
      dateFrom?: DateString;
      dateTo?: DateString;
      periodType?: 'week';
      granularity?: 'day';
    }>
  | BaseState<{
      date?: DateString;
      periodType?: FinancialTableSinglePeriodPeriodType;
    }> => {
  switch (widgetType) {
    case 'financial_table_single_date': {
      return {
        date: resolveFinancialTableWidgetDefaultOptionsDate(
          widgetSection.defaultOptions?.date,
        ),
        groupingType: 'assets',
      };
    }
    case 'financial_table_single_period': {
      const date = resolveFinancialTableWidgetDefaultOptionsDate(
        widgetSection.defaultOptions?.date,
      );
      return {
        date,
        period: getReckonerPeriodByPeriodTypeAndDate(
          widgetSection?.defaultOptions?.periodType ?? 't3',
          date,
        ),
        groupingType: 'assets',
      };
    }
    default: {
      return {
        groupingType: 'assets',
      };
    }
  }
};

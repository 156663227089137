import { LegalEntity } from '@/entities/core/legalEntity';
import Autocomplete from 'bundles/Shared/components/Filters/Autocomplete';
import FilterBar from 'bundles/Shared/components/Filters/common/filterBar/FilterBar';
import FilterItem from 'bundles/Shared/components/Filters/common/filterItem/FilterItem';

interface Props {
  legalEntities: LegalEntity[];
  selectedLegalEntities: LegalEntity[];
  onSelectedChange: (legalEntities: LegalEntity[]) => void;
}

function LegalEntitiesFilter({
  selectedLegalEntities,
  legalEntities,
  onSelectedChange,
}: Props) {
  return (
    <div className="flex flex-col gap-s">
      <div className="flex items-center justify-between gap-s">
        <span className="dark-60 inline-semibold">Summary</span>
        <Autocomplete
          items={legalEntities}
          selectedItems={selectedLegalEntities}
          onSelectedChange={onSelectedChange}
          getItemLabel={(le) => le.name}
          getItemId={(le) => le.id}
          inputProps={{
            placeholder: 'Legal Entities',
            style: {
              maxWidth: 240,
            },
          }}
        />
      </div>
      {selectedLegalEntities.length > 0 && (
        <FilterBar className="w-full" title="Legal Entities">
          {selectedLegalEntities.map((le) => (
            <FilterItem
              key={le.id}
              onClose={() =>
                onSelectedChange(
                  selectedLegalEntities.filter((lex) => le.id !== lex.id),
                )
              }
              label={le.name}
            />
          ))}
        </FilterBar>
      )}
    </div>
  );
}

export default LegalEntitiesFilter;

import { IJobCostCode } from 'types/JobCostCode';
import { ISortParamsField } from 'types/Sort';
import { IQueryParams } from 'types/Pagination';
import { IScheduleOfValueCode } from 'types/ScheduleOfValueCode';
import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

type TCrudPathParameters = {
  legalEntityId: string;
};

export interface IFetchSOVCsForReconcileBudgetSettingsRequest
  extends ISortParamsField<'jcc' | 'name' | 'code'>,
    IQueryParams {
  job_cost_codes_ids?: number[];
}

export type TReconcileBudgetSettingsSoVC = Pick<
  IScheduleOfValueCode,
  'id' | 'code' | 'name'
> & {
  changeOrderId: number;
  jobCostCode: Pick<IJobCostCode, 'id' | 'name'> | null;
};

export interface ISovcRowUpdate {
  code: string | null;
  name: string | null;
  job_cost_code_id: number | null;
}

export const {
  getEntities: fetchSOVCsForReconcileBudgetSettings,
  createEntity: createSovcInReconcileSettings,
  updateEntity: updateSovcInReconcileSettings,
  deleteEntity: deleteSovcInReconcileSettings,
  entityName: SOVC_ENTITY_NAME,
} = createCrudApi<
  TCrudPathParameters,
  IFetchSOVCsForReconcileBudgetSettingsRequest,
  TReconcileBudgetSettingsSoVC,
  ISovcRowUpdate
>(
  '/settings/reconcile/legal_entities/:legalEntityId/sovcs',
  'Schedule of Value Code',
);

import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import useElementSize from '@/shared/lib/hooks/useElementSize';
import { ClassNameProps } from 'types/Props';
import { cn } from '@/shared/lib/css/cn';

interface Props extends ClassNameProps {
  rows?: number;
  cols?: number;
  loadingActions?: boolean;
  rowHeight?: number;
  colWidth?: number;
}

function LoadingTable({
  rows,
  cols,
  loadingActions,
  colWidth,
  rowHeight = 30,
  className,
}: Props) {
  const tableRef = useRef<HTMLTableElement>(null);
  const { height } = useElementSize(tableRef);
  const [rowsInitialized, setRowsInitialized] = useState(rows != null);
  const [rowCount, setRowCount] = useState(rows);
  useLayoutEffect(() => {
    if (height === 0 || rowsInitialized) {
      return;
    }

    setRowCount(Math.floor(height / rowHeight));
    setRowsInitialized(true);
  }, [height, rowsInitialized]);

  const tableData = useMemo(() => {
    return Array(rowCount)
      .fill(null)
      .map((k, i) => (
        <tr key={i}>
          {Array(cols)
            .fill(null)
            .map((kx, ix) => (
              <td
                key={ix}
                className="loading-td"
                style={{
                  height: rowHeight,
                  width: colWidth,
                }}
              >
                <div />
              </td>
            ))}

          {loadingActions && (
            <td className="loading-td-actions" nowrap="">
              <div />
              <div />
              <div />
            </td>
          )}
        </tr>
      ));
  }, [rowCount]);

  return (
    <table
      ref={tableRef}
      className={cn('table-bordered loading-table', className)}
    >
      <tbody>{tableData}</tbody>
    </table>
  );
}

export default LoadingTable;

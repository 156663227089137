import { MainNavigation } from '@/app';
import { selectGenericData } from '@/app/reducers/genericSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { ICustomer } from 'types/Customer';
import { IUser } from 'types/User';

export interface IGenericData {
  mainNavigation: MainNavigation;
  currentUser: IUser;
  customer: ICustomer;
  impersonating: boolean;
  fireStationStats: any;
}

export const useGenericData = () => {
  const genericData = useAppSelector(selectGenericData);
  return {
    genericData,
  } as const;
};

import React from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { IReconcileSettingsCompanyUpdate } from 'bundles/REconcile/actions/companies';
import {
  DEFAULT_CODE_FIELD,
  DEFAULT_NAME_FIELD,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IFieldsProps } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

function CompanyFields({
  formData,
  setFormData,
}: IFieldsProps<IReconcileSettingsCompanyUpdate>) {
  return (
    <FormComponent
      styles={{
        wrapper__item: 'mb-0',
      }}
      setFormData={setFormData}
      formData={formData}
      formItems={[
        DEFAULT_CODE_FIELD,
        {
          ...DEFAULT_NAME_FIELD,
          styles: {
            nomb: true,
          },
        },
      ]}
    />
  );
}

export default CompanyFields;

import { cn } from '@/shared/lib/css/cn';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import HeaderQuickFilter from 'bundles/Shared/components/Table/filters/HeaderQuickFilter';
import { isEmpty } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useCallback, useState } from 'react';
import { Popover, SearchInput } from 'stories';
import { ListOption } from 'stories/Checkbox/CheckList';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';

interface Props<T = any>
  extends React.ComponentProps<typeof HeaderQuickFilter> {
  items: T[];
  getId: (item: T) => number | string;
  getLabel: (item: T) => string;
  popoverProps?: Pick<React.ComponentProps<typeof Popover>, 'placement'>;
  hideWhenItemsLessThanOne?: boolean;
  maxHeight?: number;
  search?: boolean;
}

export type TQuickFilterCheckListProps<T = any> = Omit<
  Props<T>,
  'filterModel' | 'setFilterModel' | 'children' | 'column' | 'isFiltered'
>;

export const getDefaultMappingToOption = <T extends { id: number | string }>(
  getLabel: (item: T) => string,
): Pick<Props<T>, 'getId' | 'getLabel'> => ({
  getId: (item) => item.id,
  getLabel,
});

function QuickFilterCheckList<T>({
  items,
  getId,
  getLabel,
  hideWhenItemsLessThanOne,
  maxHeight = 350,
  search,
  popoverProps,
  ...props
}: Props<T>) {
  const [filterText, setFilterText] = useState<string>('');
  const mapToOption = useCallback(
    (item: T): ListOption => ({
      value: getId(item),
      label: getLabel(item),
    }),
    [getId, getLabel],
  );

  if (hideWhenItemsLessThanOne && items.length <= 1) {
    return null;
  }

  const options = items
    .map(mapToOption)
    .filter((o) => includesInLowerCase(o.label, filterText));

  const value = (props.filterModel[props.column.dataField] ?? [])
    .map((id) => {
      const option = items.find((le) => le.id === id);
      return option ? mapToOption(option) : null;
    })
    .filter(Boolean);

  return (
    <HeaderQuickFilter
      isFiltered={() => !isEmpty(value)}
      popoverProps={{
        className: 'p-0',
        maxWidth: 350,
        ...popoverProps,
      }}
      {...props}
    >
      <div
        style={{ maxHeight }}
        className={cn('flex min-h-[100px] min-w-[150px] flex-col gap-tw-4')}
      >
        {search && (
          <SearchInput
            className="m-tw-2 mb-0"
            size="s"
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText}
            placeholder="Search..."
            resetValue={setFilterText}
          />
        )}
        <OverlayScrollbarsComponent
          options={{
            overflow: {
              x: 'hidden',
            },
          }}
        >
          {options.length === 0 && (
            <div className="p-tw-2 text-center">Noting found reset filters</div>
          )}
          {options.length > 0 && (
            <SplittedCheckList
              className={cn('p-tw-4', search && 'pt-0')}
              items={options}
              value={value}
              onChange={(newItems) =>
                props.setFilterModel({
                  ...props.filterModel,
                  [props.column.dataField]: newItems.map(
                    (item) =>
                      items.find((itemx) => getId(itemx) === item.value)!,
                  ),
                })
              }
            />
          )}
        </OverlayScrollbarsComponent>
      </div>
    </HeaderQuickFilter>
  );
}

export default QuickFilterCheckList;

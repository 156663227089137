import React from 'react';
import { useModal } from '@/shared/lib/hooks/useModal';
import { capitalize, groupBy } from 'lodash-es';
import pluralize from 'pluralize';
import PseudoLink from 'stories/PseudoLink/PseudoLink';
import SignatureFieldsModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/SignatureFieldsModal';
import {
  useGetSignatureFieldsQuery,
  useUpdateFieldsListMutation,
} from 'bundles/REconcile/components/development/api/signatureFields';
import { SIGNATURE_FIELDS } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/consts';
import COWorkflowTooltip from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/COWorkflowTooltip';
import { AnimationLoader, Button } from 'stories/index';
import { navigate, useParams } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversQuery } from './approvers/api/reconcileWorkflowApproversApiExtended';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props {
  legalEntityId: string;
}

function ChangeOrderWorkflowSettings({ legalEntityId }: Props) {
  const params = useParams<{
    legalEntityCode: LegalEntity['code'];
  }>();

  const approversPath = generateUrl(
    ROUTES_ROOT.reconcile.development.legalEntity.settings.approvers.fullPath,
    {
      pathParams: {
        legalEntityCode: params.legalEntityCode,
        tab: 'workflow',
      },
    },
  );
  const { openModal } = useModal();

  const { data: approvers, isLoading } =
    useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversQuery(
      { legalEntityId },
      { skip: legalEntityId == null },
    );

  const { data: signatureFields } = useGetSignatureFieldsQuery(
    { legalEntityId },
    { skip: legalEntityId == null },
  );
  const [updateFieldsList] = useUpdateFieldsListMutation();
  const handleApproversClick = () => {
    navigate(approversPath);
  };
  const handleFieldsClick = async () => {
    const res = await openModal(SignatureFieldsModal, {
      signatureFields: signatureFields!.items.reduce(
        (acc, field) => ({
          ...acc,
          [field.kind]: true,
        }),
        {},
      ),
    });

    if (!res) return;

    const data = SIGNATURE_FIELDS.map(({ kind }) => {
      const item = signatureFields!.items.find((f) => f.kind === kind) ?? {
        kind,
      };

      return { ...item, _destroy: !res[kind] };
    });
    updateFieldsList({
      legalEntityId,
      body: { signature_fields_attributes: data },
    });
  };

  if (isLoading || legalEntityId == null) return <AnimationLoader />;

  return (
    <div>
      <h5 className="light-90 header5-bold mb-tw-4">e-SignatuRE</h5>
      <div className="flex w-[400px] gap-[1px] overflow-hidden rounded-lg">
        <div className="flex-col flex h-[105px] flex-1 bg-white p-tw-4">
          <div className="flex flex-row items-center justify-between">
            <div className="light-60 inline-semibold">Approvers</div>
            <div>
              <Button
                variant="secondary"
                size="xs"
                onClick={handleApproversClick}
              >
                {approvers!.items.length > 0 ? 'Edit' : 'Add'}
              </Button>
            </div>
          </div>
          <div className="mt-auto flex">
            {approvers!.items.length === 0 && (
              <div className="light-60 secondary-regular">No Approvers yet</div>
            )}
            {approvers!.items.length > 0 && (
              <div className="flex gap-tw-6">
                {Object.entries(groupBy(approvers!.items, 'roleType')).map(
                  ([roleType, records]) => (
                    <div key={roleType}>
                      <div className="light-60 secondary-regular">
                        {capitalize(roleType)}
                      </div>
                      <div className="secondary-regular mt-tw-1 text-dark-60">
                        <COWorkflowTooltip
                          title={
                            <div className="secondary-semibold text-dark-60">
                              {capitalize(roleType)}
                            </div>
                          }
                          style={{
                            backgroundColor: 'white',
                            padding: '0.5rem',
                          }}
                          content={
                            <div className="flex flex-wrap gap-tw-1">
                              {records.map((approver) => (
                                <span
                                  key={approver.id}
                                  className="rounded-lg bg-light px-tw-2 py-tw-1"
                                >
                                  {approver.user.fullName}
                                </span>
                              ))}
                            </div>
                          }
                        >
                          <PseudoLink>
                            {records.length}{' '}
                            {pluralize('approver', records.length)}
                          </PseudoLink>
                        </COWorkflowTooltip>
                      </div>
                    </div>
                  ),
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex-col flex flex-1 bg-white p-tw-4">
          <div className="flex flex-row items-center justify-between">
            <div className="light-60 inline-semibold">Signature Fields</div>
            <div className="ml-auto">
              <Button variant="secondary" size="xs" onClick={handleFieldsClick}>
                Edit
              </Button>
            </div>
          </div>
          <div className="mt-auto flex">
            <div className="secondary-regular mr-tw-1 text-dark-60">
              <COWorkflowTooltip
                title={
                  <div className="body-semibold text-dark-60">
                    Enabled Signature Fields
                  </div>
                }
                maxWidth={280}
                style={{
                  backgroundColor: 'white',
                  padding: '0.75rem',
                }}
                content={
                  <ul className="list-disc pl-tw-4">
                    {signatureFields?.items.map((item) => (
                      <li
                        className="inline-regular text-light-90"
                        key={item.id}
                      >
                        {capitalize(item.kind)}
                      </li>
                    ))}
                  </ul>
                }
              >
                <PseudoLink>
                  {signatureFields?.items.length} of {SIGNATURE_FIELDS.length}
                </PseudoLink>
              </COWorkflowTooltip>
            </div>
            <div className="light-60 secondary-regular">required</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeOrderWorkflowSettings;

import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import React from 'react';

interface Props extends ISharedSelectProps {
  options: {
    value: string;
    label: string;
  }[];
  value?: string;
  disabled?: boolean;
}

export const TableFormatPeriodsSelect = ({
  onChange,
  disabled,
  options,
  value,
}: Props) => (
  <SharedSelect
    placeholder="Select Format"
    options={options}
    onChange={onChange}
    value={options?.filter((opt) => opt.value === value)}
    disabled={disabled}
    newGen
  />
);

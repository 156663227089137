import LineItemsCountCell from 'bundles/Settings/components/REport/GeneralLedgers/Table/columns/LineItemsCountCell';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { DialogProps } from '@/shared/lib/hooks/useModal';

export enum TColumnIdsGL {
  ACCOUNT_NUMBER = 'accNumber',
  ACCOUNT_TITLE = 'accTitle',
}

export const useGLTableModalColumns = (
  onClose: DialogProps['onClose'],
): {
  columns: IColumn[];
} => ({
  columns: [
    {
      dataField: TColumnIdsGL.ACCOUNT_NUMBER,
      text: 'Account #',
      headerClasses: 'min-w-[110px]',
    },
    {
      dataField: TColumnIdsGL.ACCOUNT_TITLE,
      text: 'Account Title',
      headerClasses: 'min-w-[110px]',
    },
    {
      dataField: TColumnIdsGL.ACCOUNT_TITLE,
      text: 'Items Count',
      formatter: (data) => (
        <div onClick={onClose}>
          <LineItemsCountCell value={data.row.lineItemsCount} data={data.row} />
        </div>
      ),
      headerClasses: 'min-w-[110px]',
    },
  ],
});

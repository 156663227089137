import { Contribution, Distribution } from '@/entities/return';
import { useMemo } from 'react';

interface FilterModel {
  notMapped: boolean;
  withoutSources: boolean;
}

type Params = {
  items: (Contribution | Distribution)[];
  filterModel: FilterModel;
};

export function useComputedItems({ items, filterModel }: Params) {
  const computedItems = useMemo(() => {
    if (filterModel.notMapped || filterModel.withoutSources) {
      return items.filter(({ accountingTransaction }) => !accountingTransaction);
    }
    return items;
  }, [filterModel, items]);

  return { computedItems };
}

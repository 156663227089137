import React, { useEffect, useState } from 'react';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { useNavigate } from '@reach/router';
import { plainFetchReplyRecipients } from 'bundles/Settings/actions/customTemplates';
import TemplateFormHeader from './TemplateFormHeader';
import {
  createFeedMessageTemplate,
  feedMessageTemplatePreviews,
  getFeedMessageTemplate,
  getFeedMessageTemplateVariables,
  IFeedMessageTemplateParams,
  sendFeedMessageTemplateEmail,
  updateFeedMessageTemplate,
} from '../../../actions/feedMessageTemplates';
import FeedMessageTemplateForm from '../forms/FeedMessageTemplateForm';
import TemplatePreview from '../TemplatePreview';
import TemplateEditorContainer from './TemplateEditorContainer';
import type * as Type from './types';
import { initFeedMessageTemplateVars } from '../utils/consts';
import { canSaveTemplate } from '../utils/canSaveTemplate';
import { AnimationLoader } from 'stories/index';

interface Props {
  feedMessageTemplateId?: number;
}

const FeedMessageTemplateEdit = ({ feedMessageTemplateId }: Props) => {
  const navigate = useNavigate();
  const [template, setTemplate] =
    useState<Type.FeedMessageTemplateState | null>(null);
  const [variables, setVariables] = useState(initFeedMessageTemplateVars);
  const [usedVariables, setUsedVariables] = useState(
    initFeedMessageTemplateVars,
  );
  const [replyRecipients, setReplyRecipients] = useState<Type.ReplyRecipient[]>(
    [],
  );
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [templatePreview, setTemplatePreview] = useState();
  const [isDefault, setIsDefault] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedTemplate = useDebounce(template, 500);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const [feedMessageTemplateJson, variablesJson, replyRecipientsJson] =
        await Promise.all([
          feedMessageTemplateId
            ? getFeedMessageTemplate(feedMessageTemplateId)
            : null,
          getFeedMessageTemplateVariables(),
          plainFetchReplyRecipients(),
        ]);

      setReplyRecipients(replyRecipientsJson);

      setTemplate({
        subject: feedMessageTemplateJson?.subject,
        greeting: feedMessageTemplateJson?.rawGreeting,
        callToAction: feedMessageTemplateJson?.rawCallToAction,
        additionalInfo: feedMessageTemplateJson?.rawAdditionalInfo,
        replyRecipients: feedMessageTemplateJson?.replyRecipients,
      });
      setUsedVariables({
        additionalInfo: feedMessageTemplateJson?.additionalInfoVariables ?? [],
        callToAction: feedMessageTemplateJson?.callToActionVariables ?? [],
        greeting: feedMessageTemplateJson?.greetingVariables ?? [],
      });
      setVariables({
        greeting: variablesJson.greeting,
        callToAction: variablesJson.call_to_action,
        additionalInfo: variablesJson.additional_info,
      });
      setIsDefault(Boolean(feedMessageTemplateJson?.default));

      setIsLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (template === null) return;
    const fetchPreview = async () => {
      setIsPreviewLoading(true);

      const previewJson = await feedMessageTemplatePreviews({
        feedMessageTemplateId,
        template,
        usedVariables,
      });

      setTemplatePreview(previewJson.body);

      setIsPreviewLoading(false);
    };
    fetchPreview();
  }, [debouncedTemplate]);

  const handleSubmit = async () => {
    if (template === null) return;
    const params: IFeedMessageTemplateParams = {
      template,
      feedMessageTemplateId,
      usedVariables,
    };
    const feedMessageTemplate = feedMessageTemplateId
      ? await updateFeedMessageTemplate(params)
      : await createFeedMessageTemplate(params);

    if (feedMessageTemplate.errors === undefined) {
      navigate(-1);
    }
  };

  const handleSendEmail = async () => {
    await sendFeedMessageTemplateEmail({
      feedMessageTemplateId,
      template,
      usedVariables,
    });
  };

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (feedMessageTemplateId) {
      return debouncedTemplate !== null;
    }

    return true;
  };

  const saveButtonEnabled = canSaveTemplate({
    isLoading,
    template,
    initialFieldsToCheck: ['callToAction', 'greeting', 'subject'],
  });

  return (
    <TemplateEditorContainer
      header={
        <TemplateFormHeader
          title="Feed Message"
          saveButtonEnabled={saveButtonEnabled}
          isDefault={isDefault}
          templateId={feedMessageTemplateId}
          onSave={handleSubmit}
          onSend={handleSendEmail}
        />
      }
      form={
        <FeedMessageTemplateForm
          template={template ?? {}}
          variables={variables}
          usedVariables={usedVariables}
          replyRecipients={replyRecipients}
          onTemplateChange={(newTemplate) => setTemplate(newTemplate)}
          onUsedVariablesChange={(newVariables) =>
            setUsedVariables(newVariables)
          }
          isLoading={isLoading}
        />
      }
      preview={
        isPreviewReady() ? (
          <TemplatePreview
            subject={debouncedTemplate?.subject ?? 'Feed Message'}
            templatePreview={templatePreview}
          />
        ) : (
          <AnimationLoader />
        )
      }
    />
  );
};

export default FeedMessageTemplateEdit;

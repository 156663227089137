import {
  CREATE_REALLOCATION,
  FETCH_REALLOCATIONS,
} from '../actions';

const initialState = null;

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_REALLOCATIONS:
      return payload;
    case CREATE_REALLOCATION:
      return [...payload, ...state];

    default:
      return state;
  }
}

/* eslint-disable new-cap */
import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader, Checkbox, RadioButton } from 'stories';
import { differenceBy, intersectionBy, isEmpty, uniqBy } from 'lodash-es';
import ColumnHeader from 'bundles/Shared/components/Table/ColumnHeader';
import {
  IColumn,
  IFormatterProps,
  IRowBase,
  ITableProps,
  TFilterModel,
} from 'bundles/Shared/components/Table/types';
import NoDataOverlay from '../NoDataOverlay';
import { CssVar } from '@/shared/config/cssVar';
import { ContextMenuCell } from './contextMenu/ContextMenuCell';
import DraggableContainer, {
  DraggableColumn,
  DraggableHeaderColumn,
  DraggableItem,
} from 'bundles/Shared/components/Table/DraggableComponents';

const Table = <T extends IRowBase = any>({
  columns: initialColumns,
  defaultColumn,
  items,
  loading = false,
  singleSelectedRow,
  setSingleSelectedRow,
  selectedRows = [],
  setSelectedRows,
  disableSelectAll,
  emptyDocumentsLabel = 'Nothing Found',
  emptyDocumentsSubLabel = '',
  customDocumentsComponent,
  settings,
  setSettings,
  borderLessOutside,
  borderLessColumns,
  tableStriped,
  classes,
  countRows,
  bottomRowColumns = [],
  onRowClick,
  openedRows = [],
  selectionColumn,
  bottomRows,
  onDragEnd,
  scrollHorizontally,
  ...props
}: ITableProps<T>) => {
  const [focusCell, setFocusCell] = useState<string | null>(null);

  const [localFilterModel, setLocalFilterModel] = useState({});
  const filterModel = props.filterModel ? props.filterModel : localFilterModel;
  const setFilterModel = useCallback(
    (newFilterModal: TFilterModel) => {
      if (props.filterModel && props.onFilterModelChange) {
        props.onFilterModelChange(newFilterModal, filterModel);
        return;
      }
      setLocalFilterModel(newFilterModal);
      props.onFilterModelChange?.(newFilterModal, filterModel);
    },
    [props.onFilterModelChange, filterModel],
  );

  const filteredItems = useMemo(
    () => items.filter((i) => !i.disabled),
    [items],
  );
  const allChecked = useMemo(
    () =>
      selectedRows.length && filteredItems.length
        ? intersectionBy(selectedRows, filteredItems, 'id').length ===
          filteredItems.length
        : false,
    [selectedRows, filteredItems],
  );

  const columns = useMemo(
    () =>
      initialColumns.map((column) => ({
        ...defaultColumn,
        ...column,
      })),
    [initialColumns, defaultColumn],
  );

  const onRowSelect = (item: T) => {
    setSelectedRows?.(
      selectedRows.some((r) => r.id === item.id)
        ? selectedRows.filter((r) => r.id !== item.id)
        : [...selectedRows, item],
    );
  };

  const onSingleRowSelect = (item: T) => {
    const row = item.id === singleSelectedRow?.id ? null : item;
    setSingleSelectedRow?.(row);
  };

  const onSelectAllRows = (checked: boolean) => {
    setSelectedRows?.(
      checked
        ? uniqBy([...selectedRows, ...filteredItems], 'id')
        : differenceBy(selectedRows, filteredItems, 'id'),
    );
  };

  const columnCellClassRules = (row: T, column: IColumn<T>) =>
    column.cellClassRules &&
    Object.entries(column.cellClassRules).reduce(
      (acc, [key, classRule]) => ({
        ...acc,
        [key]: classRule(row, column.dataField),
      }),
      {},
    );

  const isRowOpened = (rowId: number) => openedRows.includes(rowId);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      onDragEnd?.(newIndex + 1, items[oldIndex]);
    }
  };

  // This func is saving column width in drag&drop
  const handleSortStart = ({ node, helper }: any) => {
    node.childNodes.forEach((td: HTMLTableCellElement, index: number) => {
      helper.childNodes[index].style.width = `${td.offsetWidth}px`;
    });
  };

  return (
    <div
      className={cn(classes?.container, 'table-container', {
        'overflow-x-auto': scrollHorizontally,
      })}
    >
      <table
        className={cn(classes?.table, 'table-bordered mb-0 table', {
          'table-borderless-outside': borderLessOutside,
          'table-borderless-column': borderLessColumns,
          'table-striped': tableStriped,
          'table-draggable': Boolean(onDragEnd),
        })}
      >
        <thead>
          <tr>
            {Boolean(onDragEnd) && <DraggableHeaderColumn />}
            {setSingleSelectedRow && (
              <th className="!w-tw-8 !px-tw-2 !py-tw-4" />
            )}
            {setSelectedRows && (
              <th
                className={cn(
                  'selection-cell-header hidden lg:table-cell',
                  classes?.th,
                  selectionColumn?.classes?.th,
                )}
              >
                <Checkbox
                  indeterminate={selectedRows.length > 0}
                  labelClassName="align-middle"
                  onChange={() => onSelectAllRows(!allChecked)}
                  checked={allChecked}
                  disabled={items.length === 0 || disableSelectAll}
                />
              </th>
            )}
            {columns.map(
              (column, idx) =>
                !column.hidden && (
                  <ColumnHeader
                    key={`${column.dataField}-${idx}`}
                    column={column}
                    className={classes?.th}
                    sortSettings={settings}
                    setSortSettings={setSettings}
                    setFilterModel={setFilterModel}
                    filterModel={filterModel}
                  />
                ),
            )}
          </tr>
        </thead>
        <DraggableContainer
          className={classes?.body ?? 'relative'}
          onSortEnd={onSortEnd}
          onSortStart={handleSortStart}
          axis="y"
          lockAxis="y"
          lockToContainerEdges
          helperClass="dragging"
          useDragHandle
        >
          {loading && (
            <AnimationLoader className="min-h-[240px] rounded bg-light-10/40" />
          )}
          {items.map((item, rowIndex) => (
            <DraggableItem key={`tr-${rowIndex}`} index={rowIndex}>
              <tr
                className="group"
                style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                onClick={onRowClick ? (e) => onRowClick(e, item) : undefined}
              >
                {Boolean(onDragEnd) && <DraggableColumn id={item.id} />}
                {setSingleSelectedRow && (
                  <td
                    aria-hidden="true"
                    className="d-lg-table-cell hidden !w-tw-8 !px-tw-2 !py-tw-4"
                  >
                    <RadioButton
                      checked={singleSelectedRow?.id === item.id}
                      onChange={() => {
                        onSingleRowSelect(item);
                      }}
                    />
                  </td>
                )}
                {setSelectedRows && (
                  <td
                    aria-hidden="true"
                    className={cn(
                      'hidden lg:table-cell',
                      selectionColumn?.classes?.td,
                      classes?.td,
                    )}
                  >
                    <Checkbox
                      onChange={() =>
                        item.disabled ? undefined : onRowSelect(item)
                      }
                      labelId={`checked-item-${item.id}-cy`}
                      disabled={item.disabled}
                      checked={selectedRows.some(({ id }) => id === item.id)}
                    />
                  </td>
                )}
                {columns.map((column) => {
                  if (column.hidden) return null;
                  const Formatter = column.formatter!;
                  const keyCell = `${column.dataField}-${rowIndex}`;

                  const columnParams: IFormatterProps<T> = {
                    row: item,
                    selectedRows,
                    openedRows,
                    rows: items,
                    rowIndex,
                    ...column?.formatterParams,
                  };

                  return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <td
                      key={keyCell}
                      className={cn(
                        typeof column.classes === 'function'
                          ? column.classes(columnParams)
                          : column.classes,
                        columnCellClassRules(item, column),
                        {
                          ['focus-cell']: keyCell === focusCell,
                        },
                        classes?.td,
                      )}
                      onFocus={() => setFocusCell(keyCell)}
                    >
                      {column.contextMenu && (
                        <ContextMenuCell {...columnParams} column={column}>
                          {column.formatter ? (
                            <Formatter {...columnParams} />
                          ) : (
                            item[column.dataField]
                          )}
                        </ContextMenuCell>
                      )}
                      {!column.contextMenu &&
                        (column.formatter ? (
                          <Formatter {...columnParams} />
                        ) : (
                          item[column.dataField]
                        ))}
                    </td>
                  );
                })}
              </tr>
              {!isEmpty(item?.children) &&
                openedRows.length > 0 &&
                isRowOpened(
                  typeof item.id === 'number' ? item.id : parseInt(item.id, 10),
                ) &&
                item?.children?.map((child) => (
                  <tr key={`${Math.random()}-${child.id}`}>
                    {columns.map((column) => (
                      <td
                        style={{ background: CssVar.light }}
                        key={`${column.dataField}-${child.id}`}
                        className={cn(
                          column.classes,
                          columnCellClassRules(child, column),
                        )}
                      >
                        {column.formatter
                          ? column.formatter({ row: child, selectedRows })
                          : child[column.dataField]}
                      </td>
                    ))}
                  </tr>
                ))}
            </DraggableItem>
          ))}
          {(bottomRows?.length ?? 0) > 0 &&
            bottomRows?.map((cols, index) => (
              <tr key={index} className="sre-table__additional-rows">
                {setSelectedRows && <td />}
                {cols.map((col) => (
                  <td className={col.className} key={col.id}>
                    {col.text}
                  </td>
                ))}
              </tr>
            ))}
          {bottomRowColumns.length > 0 && (
            <tr className="sre-table__additional-rows">
              {setSelectedRows && <td />}
              {bottomRowColumns.map((row) => (
                <td className={row.className} key={row.id}>
                  {row.text}
                </td>
              ))}
            </tr>
          )}
        </DraggableContainer>
      </table>
      {countRows &&
        customDocumentsComponent &&
        items.length &&
        countRows > items.length &&
        customDocumentsComponent}
      {items.length === 0 && !loading && (
        <NoDataOverlay
          title={emptyDocumentsLabel}
          subTitle={emptyDocumentsSubLabel}
          customDocumentsComponent={customDocumentsComponent}
        />
      )}
    </div>
  );
};

export default Table;

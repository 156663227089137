import React, { useCallback, useState, useEffect } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import { useParams, navigate } from '@reach/router';
import { IDrawPackagePageParams } from 'bundles/Construction/types';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { HugeModalHeaderForCe, AnimationLoader } from 'stories';
import { TStatusInvoice } from '@/shared/types/reconcile/Invoice';
import { useGetPayableInvoicesQuery } from 'bundles/Construction/api/invoices';
import {
  fetchCompanies,
  selectCompanies,
} from 'bundles/Construction/reducers/ReconcileSlice';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import {
  PageParamsProvider,
  PageParamsPagination,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';
import {
  payableInvoicesColumns,
  TFilterModelPayableInvoices,
} from './components/useColumns';
import TotalInfoBlock from '../components/TotalInfoBlock.tsx/TotalInfoBlock';
import { getTotalData } from './components/utils';

export interface IInvoicesPageParams extends IDrawPackagePageParams {
  jcc_ids?: string[];
  sovc_ids?: string[];
  vendor_ids: string[];
  status: 'all' | TStatusInvoice;
}

const PayableInvoicesCategory = ({ children }) => {
  const companies = useAppSelector(selectCompanies);
  const legalEntity = useAppSelector(
    (store) => store.developmentBudget.legalEntity,
  );
  const dispatch = useAppDispatch();

  const params = useParams();
  const { getUrl } = useNavigation();

  const [pageParams, setPageParams] = useState<IInvoicesPageParams>({
    [params?.type === 'jcc' ? 'jcc_ids' : 'sovc_ids']: [params.categoryId],
    status: 'all',
    amount: {
      from: '',
      to: '',
    },
    vendor_ids: [],
    page: 1,
  });

  const { data, isLoading } = useGetPayableInvoicesQuery({
    legalEntityCode: params?.legalEntityCode || legalEntity.code,
    pageParams,
  });

  const { data: categories } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode: params?.legalEntityCode,
  });

  const category = categories?.find(
    (item) => item.id === Number(params.categoryId),
  );
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModelPayableInvoices) =>
      setPageParams((prevParams) => {
        return {
          ...prevParams,
          status: filterModel.status,
          vendor_ids: filterModel.vendor_ids?.map((item) => item.id),
          amount: filterModel.amount,
        };
      }),
    [],
  );

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  if (isLoading) return <AnimationLoader />;

  const onCloseModal = () => {
    navigate(
      getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS', {
        legalEntityCode: params.legalEntityCode,
      }),
    );
  };

  const showDetails = (id) => {
    navigate(
      getUrl(
        'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY_ITEM',
        {
          legalEntityCode: params.legalEntityCode,
          categoryId: params.categoryId,
          type: params.type,
          invoiceId: id,
        },
      ),
    );
  };
  return (
    <div>
      <div className="bg-white px-l py-m">
        <HugeModalHeaderForCe
          title="Accounts Payable"
          subtitle={
            <div className="label-regular">{`${category?.name} Invoices`}</div>
          }
          onClose={onCloseModal}
          content={
            <TotalInfoBlock
              metaData={getTotalData(data?.meta || [], category?.name)}
            />
          }
        />
      </div>
      <div className="custom-container">
        <h2 className="font-weight-700 mb-l mt-xl">
          {category?.name} Invoices
        </h2>
        <PageParamsProvider
          pageParams={pageParams}
          setPageParams={setPageParams}
        >
          <div>
            <div className="flex items-center justify-between mb-m">
              <div>
                <PageParamsPagination
                  loading={isLoading}
                  totalSize={data?.meta.totalSize || 1}
                  sizePerPage={data?.meta.perPage || 1}
                />
              </div>
              <PageParamsSearch />
            </div>
            <div>
              <Table
                loading={isLoading}
                columns={payableInvoicesColumns(companies, showDetails)}
                settings={settings}
                setSettings={setSettings}
                filterModel={{
                  ...pageParams,
                  vendor_ids: pageParams.vendor_ids?.map((id) => ({
                    id,
                    name: companies.find((company) => company.id === id)?.name,
                  })),
                }}
                onFilterModelChange={handleFilterModelChange}
                items={data?.items || []}
              />
            </div>
          </div>
        </PageParamsProvider>
      </div>
      {params['*'] && children}
    </div>
  );
};

export default PayableInvoicesCategory;

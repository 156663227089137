import { groupBy } from 'lodash-es';
import { IAccessListItem, TAccessItemType } from '../types';
import { resolveAccessItemLabel } from './resolveAccessItemLabel';
import { AccessItemType } from './constants';

const permissionTypesOrder = ['member', 'entity', 'role', 'tag'];

export const ACCESS_ITEM_LABEL_MAP = {
  ...AccessItemType,
  entity: 'Investment Entity',
} as const satisfies Record<TAccessItemType, string>;

export const resolveLinkLabelText = (
  isPublic: boolean,
  accessList: IAccessListItem[],
): string => {
  if (isPublic) return 'All Users';

  const groupedByTypeEntries = Object.entries(groupBy(accessList, 'type'));

  const value = groupedByTypeEntries
    .sort(([typeA], [typeB]) => {
      if (typeA === typeB) {
        return typeA.localeCompare(typeB);
      }
      return (
        permissionTypesOrder.indexOf(typeA) -
        permissionTypesOrder.indexOf(typeB)
      );
    })
    .map(([itemsType, items]: [TAccessItemType, IAccessListItem[]]) => {
      const itemCount = items.length;

      if (itemCount === 0) return null;

      const name = resolveAccessItemLabel(itemsType, itemCount);

      return `${itemCount} ${name}`;
    })
    .filter(Boolean)
    .join(', ');

  return value;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimationLoader, Popover } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import _ from 'lodash-es';

import { currentUserAllowedTo, USER_MANAGEMENT_PRODUCT_NAME } from 'lib/permissions';
import SelectRoleGroup from './SelectRoleGroup';
import { plainFetchUserRoles } from '../../actions/UserRole';

const SelectUserRole = ({ fields, setValue }) => {
  const [expanded, setExpanded] = useState(false);
  const [userRoles, setUserRoles] = useState();

  useEffect(() => {
    plainFetchUserRoles().then((data) => setUserRoles(data));
  }, []);

  if (!userRoles) return <AnimationLoader className="static min-h-[120px]" />;

  const categorySelectRoleGroup = (category) => ((
    <SelectRoleGroup
      userRoles={userRoles.filter((userRole) => userRole.category === category)}
      userCategory={category}
      fields={fields}
      setValue={setValue}
      setExpanded={setExpanded}
    />
  ));

  return (
    <div className="relative flex flex-col mb-tw-4 gap-tw-1">
      <span className="label-regular text-dark-60">Role</span>
      <Popover
        visible={expanded}
        style={{ boxShadow: 'none', padding: '0px', border: 'none' }}
        appendTo={() => document.body}
        template={(
          <div className="um-roles-dropdown-menu">
            {userRoles.filter((userRole) => userRole.category === 'external').length !== 0
            && categorySelectRoleGroup('external')}
            {currentUserAllowedTo('configure', USER_MANAGEMENT_PRODUCT_NAME) && userRoles.filter((userRole) => userRole.category === 'internal').length !== 0
              && categorySelectRoleGroup('internal')}
            { _.uniq(userRoles.map((userRole) => userRole.category)).includes('symmetre') && userRoles.filter((userRole) => userRole.category === 'internal').length !== 0
              && categorySelectRoleGroup('symmetre')}
          </div>
        )}
        maxWidth="40rem"
        placement="bottom-start"
        hiddenArrow
        trigger="click"
        offset={[0, 1]}
      >
        <div
          className={cn({ 'light-60': !fields.userRole, 'dark-60': fields.userRole }, 'um-selectbox w-100 inline-regular')}
          onClick={() => setExpanded(!expanded)}
        >
          {fields.userRole?.name || 'Select user role'}
          <span className={cn('ml-[0.5rem] light-60 text-[1.125rem]', { 'sre-icon-bottom': !expanded, 'sre-icon-top': expanded })} />
        </div>
      </Popover>
    </div>
  );
};

SelectUserRole.propTypes = {
  fields: PropTypes.shape().isRequired,
  setValue: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SelectUserRole;

import { cn } from '@/shared/lib/css/cn';
import { exists } from 'lib/typeHelpers';
import * as React from 'react';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Checkbox, Icon, Input, Popover } from 'stories';
import { isSelectedArray } from 'stories/FormControls/Select/utils';
import styles from './Select.module.scss';

type BaseID = string | number;

export interface ISelectOption<ID extends BaseID = BaseID> {
  id: ID;
  label: React.ReactNode;
  value?: string | number;
  disabled?: boolean;
}

interface OptionProps<ID extends BaseID = BaseID>
  extends Pick<ISelectOption<ID>, 'label' | 'disabled'> {
  selected?: boolean;
  withCheckbox?: boolean;
  onClick?: () => void;
}

function Option<ID extends BaseID = BaseID>({
  selected,
  disabled,
  label,
  onClick,
  withCheckbox,
}: OptionProps<ID>) {
  const content = withCheckbox ? (
    <Checkbox
      labelClassName={styles.checkbox}
      checked={selected}
      disabled={disabled}
    >
      {label}
    </Checkbox>
  ) : (
    label
  );

  return (
    <li
      className={cn(styles.option, {
        [styles.option_selected]: selected,
        [styles.option_disabled]: disabled,
      })}
      onClick={onClick}
    >
      {content}
    </li>
  );
}

interface Props<ID extends BaseID = BaseID> {
  options: ISelectOption<ID>[] | readonly ISelectOption<ID>[];
  notFoundFlow?: {
    selectButtonLabel: React.ReactNode;
    searchValueValidator: (searchText: string) => boolean;
    textResolver: (searchText: string) => React.ReactNode;
  };
  selected?: ISelectOption<ID> | ISelectOption<ID>[];
  onSelectedChange?: (
    selected: ISelectOption<ID> | ISelectOption<ID>[] | undefined,
  ) => void;
  inputProps?: Partial<
    Omit<
      ComponentProps<typeof Input>,
      | 'onFocus'
      | 'rightIcon'
      | 'onChange'
      | 'value'
      | 'type'
      | 'size'
      | 'ref'
      | 'placeholder'
    >
  >;
  popoverProps?: Omit<
    ComponentProps<typeof Popover>,
    'disabled' | 'visible' | 'classes' | 'template' | 'onClickOutside'
  >;
  size?: 's' | 'm' | 'l';
  spanContainer?: string;
  placeholder?: string;
  disabled?: boolean;
  classes?: {
    input?: string;
  };
  inputType?: ComponentProps<typeof Input>['type'];
}

export function Select<ID extends BaseID = BaseID>({
  options = [],
  notFoundFlow,
  onSelectedChange,
  selected,
  inputProps,
  popoverProps,
  size = 'm',
  spanContainer = '',
  placeholder = '',
  disabled = false,
  classes,
  inputType = 'search',
}: Props<ID>) {
  const isControlled = onSelectedChange != null || selected != null;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [popoverWidth, setPopoverWidth] = useState(175);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [internalSelected, setInternalSelected] = useState<
    ISelectOption<ID> | ISelectOption<ID>[]
  >();
  const handleSelectedChange = onSelectedChange ?? setInternalSelected;

  const filteredOptions = useMemo(
    () =>
      searchText === null
        ? options
        : options.filter((o) => o.label.toLowerCase().includes(searchText)),
    [options, searchText],
  );

  const measuredRef = useCallback((node: HTMLInputElement | null) => {
    if (node) {
      setPopoverWidth(node.getBoundingClientRect().width);
      inputRef.current = node;
    }
  }, []);

  const handleOptionClick = (o: ISelectOption<ID>) => {
    if (!o.disabled) {
      setSearchText(null);
      setOpen(false);
      if (Array.isArray(selected)) {
        handleSelectedChange(
          selected.find((ox) => o.id === ox.id) !== undefined
            ? selected.filter((ox) => ox.id !== o.id)
            : [...selected, o],
        );
      } else {
        handleSelectedChange(o);
      }
    }
  };

  const handleInputChange = (text: string) => {
    setSearchText(text);
    if (text === '') {
      handleSelectedChange(undefined);
    }
  };

  const getOptionSelected = (o: ISelectOption<ID>) => {
    const selectedObj = isControlled ? selected : internalSelected;

    if (!exists(selectedObj)) return;

    if (isSelectedArray(selectedObj)) {
      return selectedObj.find((ox) => o.id === ox.id) !== undefined;
    }
    return selectedObj?.id === o.id;
  };

  const getInputValue = () => {
    if (searchText !== null) {
      return searchText;
    }
    const selectedObj = isControlled ? selected : internalSelected;

    if (!exists(selectedObj)) return;

    return isSelectedArray(selectedObj) ? null : selectedObj.label;
  };

  const handleOutsideClick = () => {
    setSearchText(null);
    setOpen(false);
  };

  const handleDownClick = () => {
    setOpen(!open);
    inputRef.current?.focus();
  };

  const resolveNotFoundOption = () => {
    if (filteredOptions.length > 0) return null;

    const isValidated =
      searchText !== null && notFoundFlow?.searchValueValidator(searchText);

    if (notFoundFlow == null || !isValidated)
      return (
        <Option
          label={notFoundFlow?.textResolver?.(searchText ?? '') ?? 'No Options'}
        />
      );

    const option: ISelectOption = {
      id: searchText,
      label: searchText,
      value: searchText,
    };

    return (
      <li className="flex flex-col gap-tw-1 px-tw-4 py-tw-2">
        <p className="inline-regular text-neutral-500">
          "{searchText}" was not found.
        </p>
        <button
          onClick={() => handleOptionClick(option)}
          className="inline-regular m-0 border-none bg-transparent p-0 text-left text-info-055 hover:text-info-070"
        >
          {notFoundFlow.selectButtonLabel}
        </button>
      </li>
    );
  };

  return (
    <Popover
      disabled={disabled}
      hiddenArrow
      appendToBody
      visible={open}
      offset={[0, 2]}
      className="overflow-hidden p-0"
      placement="bottom-start"
      maxWidth="none"
      classes={{ spanContainer }}
      template={
        <ul
          style={{
            width: popoverWidth,
            maxHeight: 250,
          }}
          className={styles.list}
        >
          {filteredOptions.map((o) => (
            <Option
              key={o.id}
              selected={getOptionSelected(o)}
              onClick={() => handleOptionClick(o)}
              label={o.label}
              disabled={o.disabled}
              withCheckbox={Array.isArray(selected)}
            />
          ))}
          {resolveNotFoundOption()}
        </ul>
      }
      onClickOutside={handleOutsideClick}
      {...popoverProps}
    >
      <Input
        className={cn('w-full', classes?.input)}
        disabled={disabled}
        onFocus={() => setOpen(true)}
        value={getInputValue()}
        onChange={(e) => handleInputChange(e.target.value)}
        rightIcon={<Icon onClick={handleDownClick} iconName="bottom" />}
        ref={measuredRef}
        type={inputType}
        size={size}
        placeholder={placeholder}
        {...inputProps}
      />
    </Popover>
  );
}

Select.Option = Option;

export default Select;

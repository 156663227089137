import React from 'react';
import InvestmentObjectImage from 'bundles/InvestmentObjects/components/investmentObjectImage/InvestmentObjectImage';

interface Props {
  legalEntityName: string;
  assetName: string;
  smallPictureUrl: string;
}

function DevelopmentLegalEntityHeader({
  legalEntityName,
  assetName,
  smallPictureUrl,
}: Props) {
  return (
    <div className="flex items-center">
      <InvestmentObjectImage pictureUrl={smallPictureUrl} />

      <div className="flex flex-col ml-l">
        <span className="light-60 label-semibold">ASSET NAME</span>
        <h5 className="dark-60 header5-regular">{assetName}</h5>
      </div>
      <div className="flex flex-col ml-l">
        <span className="light-60 label-semibold">LEGAL ENTITY</span>
        <h5 className="dark-60 header5-regular">{legalEntityName}</h5>
      </div>
    </div>
  );
}

export default DevelopmentLegalEntityHeader;

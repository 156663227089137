import { useCallback, useState } from 'react';

type Matcher<T> = (selectedItem: T, item: T) => boolean;

interface Params<T> {
  items: T[];
  initialSelected?: T[];
  matcher?: Matcher<T>;
}

export default function useSelected<T = any>({
  items,
  initialSelected = [],
  matcher: initialMatcher,
}: Params<T>) {
  const predicate: Matcher<T> =
    initialMatcher ?? ((itemX, item) => itemX === item);

  const [selectedItems, setSelectedItems] = useState(initialSelected);
  const allSelected = items.length === selectedItems.length;

  const isSelected = (item) =>
    selectedItems.find((itemX) => predicate(itemX, item)) !== undefined;

  const selectItem = (item: T) => {
    setSelectedItems(
      isSelected(item)
        ? selectedItems.filter((itemX) => !predicate(itemX, item))
        : [...selectedItems, item],
    );
  };

  const selectAll = useCallback(() => {
    setSelectedItems(items);
  }, [items]);

  const deselectAll = useCallback(() => {
    setSelectedItems([]);
  }, []);

  return {
    selectedItems,
    setSelectedItems,
    selectItem,
    selectAll,
    deselectAll,
    allSelected,
    isSelected,
  };
}

import {
  fetchShapshot,
  selectReport,
} from 'bundles/Construction/reducers/ReconcileSlice';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  accountingNumberFormat,
  percentNumberFormat,
} from '@/shared/lib/formatting/number';
import { useEffect } from 'react';
import { Modal, OverlaySpinner } from 'stories';
import ProgressBar from '../Shared/ProgressBar';

interface Props {
  legalEntityCode: string;
  onClose: () => void;
}

const DEFAULT_MODAL_CLASSES = {
  header: 'bg-white',
  body: 'bg-light h-675p',
};

function SnapshotModal({ onClose, legalEntityCode }: Props) {
  const report = useAppSelector(selectReport);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchShapshot({
        legalEntityCode,
        view: 'contingency',
      }),
    );
  }, []);

  if (!report) {
    return (
      <Modal
        toggle={onClose}
        header={<h6 className="header6-bold">Change Events</h6>}
        classes={DEFAULT_MODAL_CLASSES}
      >
        <OverlaySpinner />
      </Modal>
    );
  }

  const totalChangeEvents =
    Number(report.approvedCo) + Number(report.approvedCor);

  const details = [
    {
      title: 'Change Event Details:',
      rows: [
        {
          label: 'Original GMP Contract Sum',
          value: accountingNumberFormat(report.hardConstructionCost, {
            maximumFractionDigits: 0,
          }),
        },
        {
          label: 'Net Change by Approved Change Orders',
          value: accountingNumberFormat(report.approvedCo, {
            maximumFractionDigits: 0,
          }),
        },
      ],
    },
    {
      title: 'Hard Cost Contingency Budget:',
      rows: [
        {
          label: 'Initial Hard Cost Contingency Budget',
          value: accountingNumberFormat(report.hardCostContingency),
        },
        {
          label: 'Approved OCOs',
          value: accountingNumberFormat(report.approvedCo, {
            maximumFractionDigits: 0,
          }),
        },
        {
          label: 'Approved OCOs & CORs',
          value: accountingNumberFormat(report.approvedCor, {
            maximumFractionDigits: 0,
          }),
        },
      ],
    },
  ];

  const header = (
    <>
      <h6 className="dark-60 header6-bold">Change Events</h6>
      <p className="light-60 label-regular">
        {report && (
          <>As of: {formatUnixDate(report.updatedAt, 'MMMM DD, YYYY')}</>
        )}
      </p>
    </>
  );

  return (
    <Modal toggle={onClose} header={header} classes={DEFAULT_MODAL_CLASSES}>
      <div>
        <div className="mb-m py-s text-center">
          <h2 className="header3-regular mb-s">
            {accountingNumberFormat(report.remainingContingency)}
          </h2>
          <p className="inline-regular">Hard Cost Contingency Budget</p>
        </div>

        <div className="mb-s rounded-[8px] bg-white p-3">
          <p className="dark-60 header5-regular">
            {percentNumberFormat(
              Math.abs(parseFloat(report.hcContingencyUsedPercent)),
            )}{' '}
            <span className="light-60 secondary-regular">Used</span>
          </p>
          <ProgressBar
            value={Math.abs(parseFloat(report.hcContingencyUsedPercent))}
            classes={{ wrapper: 'my-2' }}
          />
        </div>

        <div className="mb-m grid grid-cols-2 gap-s">
          <div className="rounded-[8px] bg-white p-3">
            <p className="dark-60 header-5">
              {accountingNumberFormat(totalChangeEvents)}
            </p>
            <p className="light-60 secondary-regular">Total Change Events</p>
          </div>

          <div className="rounded-[8px] bg-white p-3">
            <p className="dark-60 header-5">
              {accountingNumberFormat(report.hardCostContingency)}
            </p>
            <p className="light-60 secondary-regular">
              Initial Hard Cost Contingency Budget
            </p>
          </div>
        </div>

        <div className="dark-60 rounded-[8px] bg-white p-3">
          {details.map(({ title, rows }, idx) => (
            <div className={idx === 0 ? 'mb-l' : 'mb-m'}>
              <h5 className="inline-semibold mb-s">{title}</h5>
              <div className="line-height-m secondary-regular flex flex-col gap overflow-hidden rounded-[8px]">
                {rows.map(({ label, value }) => (
                  <div className="flex justify-between bg-light px-m py-s">
                    <p>{label}</p>
                    <span>{value}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <p className="light-60 secondary-regular">
            Hard Cost Contingency values shown above include reallocations from
            other budget line items
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default SnapshotModal;

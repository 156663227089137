import { useCallback, useState } from 'react';

export const useHovered = (initial = false) => {
  const [hovered, setHovered] = useState(initial);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);
  const onMouseOver = useCallback(() => {
    setHovered(true);
  }, []);

  return {
    onMouseLeave,
    onMouseOver,
    hovered,
    setHovered,
  };
};

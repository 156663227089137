import React, { SetStateAction } from 'react';
import { Link } from '@reach/router';
import {
  FieldType,
  FieldTypeView,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { formatAmount } from '@/shared/lib/formatting/number';
import { TReconcileStatus } from '../../Shared/ReconcileModalPage/components/InfoBadge';
import { IReallocation } from 'bundles/Construction/types';
import SubReallocationsEdit from '../Modals/components/SubReallocationsEdit';
import { ContingencyImpactsMoved } from 'bundles/Construction/components/Reallocation/Modals/types';

export const generateNumberRE = (status: TReconcileStatus, number: number) => {
  if (status === 'PENDING') return `P-${number}`;
  return number;
};

export const getDataREKeyinfo = (reallocation: IReallocation) => [
  {
    titleView: 'Number',
    fieldName: 'number',
    typeView: FieldTypeView.VIEW_TEXT,
    value: reallocation?.number,
    transformerView: (number: number) =>
      generateNumberRE(reallocation.status, number),
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    titleView: 'Description',
    fieldName: 'description',
    typeView: FieldTypeView.VIEW_TEXT,
    value: reallocation?.description,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  ...(!reallocation.changeOrder
    ? [
        {
          titleView: 'Contingency impact',
          fieldName: 'contingencyImpact',
          typeView: FieldTypeView.VIEW_TEXT,
          value: reallocation?.contingencyImpact,
          styles: {
            wrapper: 'w-50 pr-s',
          },
        },
      ]
    : [
        {
          titleView: 'Moved From',
          fieldName: 'movedFrom',
          typeView: FieldTypeView.VIEW_TEXT,
          transformerView: (val) => val.fullName,
          value: reallocation?.movedFrom,
          styles: {
            wrapper: 'w-50 pr-s',
          },
        },
        {
          titleView: 'Moved To',
          fieldName: 'movedTo',
          typeView: FieldTypeView.VIEW_TEXT,
          transformerView: (val) => val.fullName,
          value: reallocation?.movedTo,
          styles: {
            wrapper: 'w-50 pr-s',
          },
        },
        {
          fieldName: 'value',
          typeView: FieldTypeView.VIEW_TEXT,
          titleView: 'Final Amount',
          transformerView: formatAmount,
        },
      ]),
];

export const getDataREKeyinfoManual = (
  reallocation: IReallocation,
  navigation,
  params,
) => [
  {
    titleView: 'Moved From',
    fieldName: 'movedFrom',
    typeView: FieldTypeView.VIEW_TEXT,
    transformerView: (val) => val.fullName,
    value: reallocation?.movedFrom,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    titleView: 'Moved To',
    fieldName: 'movedTo',
    typeView: FieldTypeView.VIEW_TEXT,
    transformerView: (val) => val.fullName,
    value: reallocation?.movedTo,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    fieldName: 'value',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'Final Amount',
    transformerView: formatAmount,
  },
  ...(reallocation.changeOrderEvent
    ? [
        {
          fieldName: 'changeOrderEvent',
          typeView: FieldTypeView.VIEW_TEXT,
          titleView: 'Linked To COR',
          transformerView: () => (
            <Link
              to={navigation.getUrl(
                'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT',
                {
                  legalEntityCode: params.legalEntityCode,
                  id: reallocation?.changeOrderEvent?.id,
                },
              )}
              className="second-link-color"
            >
              {reallocation?.number}
            </Link>
          ),
        },
      ]
    : []),
];

export const getDataSubRE = (reallocation: IReallocation) => [
  {
    titleView: 'Number',
    fieldName: 'number',
    typeView: FieldTypeView.VIEW_TEXT,
    value: reallocation?.number,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    titleView: 'Moved From',
    fieldName: 'movedFrom',
    typeView: FieldTypeView.VIEW_TEXT,
    transformerView: (val) => val.fullName,
    value: reallocation?.movedFrom,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    titleView: 'Moved To',
    fieldName: 'movedTo',
    typeView: FieldTypeView.VIEW_TEXT,
    transformerView: (val) => val.fullName,
    value: reallocation?.movedTo,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    fieldName: 'value',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'Sub Amount',
    transformerView: formatAmount,
  },
];

export const getItemsReallocationModalEdit = (
  formData: IReallocation,
  setFormData: SetStateAction<IReallocation>,
): IGroupFormItem[] => {
  const movedFrom = [
    { label: formData.movedFrom.fullName, value: formData.movedFrom.id },
  ];
  const movedTo = [
    { label: formData.movedTo.fullName, value: formData.movedTo.id },
  ];
  const groupFormItems = [
    {
      customTitle: 'REallocation Details',
      required: true,
      activated: true,
      open: false,
      formItems: [
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Detailed Description',
          titleView: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
        {
          fieldName: 'moved_from_id',
          fieldNameSnap: 'moved_from_id',
          type: FieldType.SELECT,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Moved From',
          titleView: 'Moved From',
          placeholder: 'Select',
          required: true,
          optionalView: true,
          disabled: true,
          options: movedFrom,
          styles: {
            wrapper: 'w-50 pr-s',
          },
        },
        {
          fieldName: 'moved_to_id',
          fieldNameSnap: 'moved_to_id',
          type: FieldType.SELECT,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Moved To',
          titleView: 'Moved To',
          placeholder: 'Select',
          required: true,
          optionalView: true,
          disabled: true,
          options: movedTo,
          styles: {
            wrapper: 'w-50 pl-s',
          },
        },
        {
          fieldName: 'value',
          fieldNameSnap: 'value',
          type: FieldType.INPUT_NUMBER,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Final Amount',
          titleView: 'Final Amount',
          disabled: true,
          transformerView: formatAmount,
          leftIcon: 'paid',
          placeholder: '0',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-25 form-item_white',
            size: 'm',
          },
          thousandSeparator: true,
        },
        {
          fieldName: 'subReallocations',
          type: FieldType.CUSTOM,
          content: (
            <SubReallocationsEdit
              formData={formData}
              setFormData={setFormData}
            />
          ),
          styles: {
            wrapper: 'w-full form-item_nomb',
            size: 'm',
          },
        },
      ],
    },
  ];

  return groupFormItems;
};

export const getItemsSubReallocationEditModal = (formData: IReallocation) => {
  const optionsMovedFrom = formData.subReallocations.map((subReallocation) => ({
    label: subReallocation.movedFrom.fullName,
    value: subReallocation.movedFrom.id,
  }));
  const optionsMovedTo = formData.subReallocations.map((subReallocation) => ({
    label: subReallocation.movedTo.fullName,
    value: subReallocation.movedTo.id,
  }));
  return [
    {
      fieldName: 'moved_from_id',
      type: FieldType.SELECT,
      title: 'Moved From',
      placeholder: 'Select',
      required: true,
      disabled: true,
      options: optionsMovedFrom,
      styles: {
        wrapper: 'w-50 pr-s',
      },
    },
    {
      fieldName: 'moved_to_id',
      type: FieldType.SELECT,
      title: 'Moved To',
      placeholder: 'Select',
      required: true,
      disabled: true,
      options: optionsMovedTo,
      styles: {
        wrapper: 'w-50 pl-s',
      },
    },
    {
      fieldName: 'value',
      fieldNameSnap: 'value',
      type: FieldType.INPUT_NUMBER,
      title: 'Sub Amount',
      leftIcon: 'paid',
      placeholder: '0',
      required: true,
      styles: {
        wrapper: 'w-25 form-item_white',
        size: 'm',
      },
      thousandSeparator: true,
    },
  ];
};

export const getItemsReallocationModalEditManual = (
  formData: IReallocation,
  setFormData: SetStateAction<IReallocation>,
): IGroupFormItem[] => {
  const movedFrom = [
    { label: formData.movedFrom.fullName, value: formData.movedFrom.id },
  ];
  const movedTo = [
    { label: formData.movedTo.fullName, value: formData.movedTo.id },
  ];
  const groupFormItems = [
    {
      customTitle: 'Key Info',
      required: true,
      activated: true,
      open: false,
      formItems: [
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Detailed Description',
          titleView: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
        {
          fieldName: 'contingencyImpact',
          fieldNameSnap: 'contingency_impact',
          type: FieldType.SELECTED_BUTTONS,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Contingency impact',
          titleView: 'Contingency impact',
          disabled: true,
          buttons: [
            {
              title: 'Moved From',
              active:
                formData.contingencyImpact === ContingencyImpactsMoved.FROM,
              value: 'Moved from',
            },
            {
              title: 'Moved To',
              active: formData.contingencyImpact === ContingencyImpactsMoved.TO,
              value: 'Moved to',
            },
            {
              title: 'No Impact',
              active: formData.contingencyImpact === ContingencyImpactsMoved.NO,
              value: 'No impact',
            },
          ],
          required: true,
          optionalView: true,
          styles: {
            nomb: true,
            wrapper: 'w-full',
            button: 'flex flex-col items-center py-m',
          },
        },
        {
          fieldName: 'subReallocations',
          type: FieldType.CUSTOM,
          content: (
            <SubReallocationsEdit
              formData={formData}
              setFormData={setFormData}
            />
          ),
          styles: {
            wrapper: 'w-full form-item_nomb mt-m',
            size: 'm',
          },
        },
      ],
    },
    {
      customTitle: 'Details',
      required: true,
      activated: true,
      open: false,
      formItems: [
        {
          fieldName: 'moved_from_id',
          fieldNameSnap: 'moved_from_id',
          type: FieldType.SELECT,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Moved From',
          titleView: 'Moved From',
          placeholder: 'Select',
          required: true,
          optionalView: true,
          disabled: true,
          options: movedFrom,
          styles: {
            wrapper: 'w-50 pr-s',
          },
        },
        {
          fieldName: 'moved_to_id',
          fieldNameSnap: 'moved_to_id',
          type: FieldType.SELECT,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Moved To',
          titleView: 'Moved To',
          placeholder: 'Select',
          required: true,
          optionalView: true,
          disabled: true,
          options: movedTo,
          styles: {
            wrapper: 'w-50 pl-s',
          },
        },
        {
          fieldName: 'value',
          fieldNameSnap: 'value',
          type: FieldType.INPUT_NUMBER,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Final Amount',
          titleView: 'Final Amount',
          disabled: true,
          transformerView: formatAmount,
          leftIcon: 'paid',
          placeholder: '0',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-25 form-item_white',
            size: 'm',
          },
          thousandSeparator: true,
        },
        {
          fieldName: 'subReallocations',
          type: FieldType.CUSTOM,
          content: (
            <SubReallocationsEdit
              formData={formData}
              setFormData={setFormData}
            />
          ),
          styles: {
            wrapper: 'w-full form-item_nomb',
            size: 'm',
          },
        },
      ],
    },
  ];

  return groupFormItems;
};

import {
  reportFinancialCategoriesApiEnhanced,
  usePostApiReportFinancialCategoriesSyncWithSourceMutation,
} from 'bundles/Shared/shared/api/reportFinancialCategoriesApiEnhanced';
import {
  REPORT_GENERAL_LEDGERS_META_TAG,
  reportGeneralLedgersApiEnhanced,
  useGetApiSettingsReportGeneralLedgersMetaQuery,
} from 'bundles/Settings/actions/report/generalLedgersApiEnhanced';
import { listenerMiddleware } from '@/app/stores/listener';
import { isAnyOf } from '@reduxjs/toolkit';

export const useSyncWithUniform = () =>
  usePostApiReportFinancialCategoriesSyncWithSourceMutation();
export const useSyncWithUniformMeta = () => {
  const { data: initialData, ...rest } =
    useGetApiSettingsReportGeneralLedgersMetaQuery();

  return {
    ...rest,
    data: initialData?.financialCategorySync,
  };
};

listenerMiddleware.startListening({
  // eslint-disable-next-line max-len
  matcher: isAnyOf(
    reportFinancialCategoriesApiEnhanced.endpoints
      .postApiReportFinancialCategoriesSyncWithSource.matchFulfilled,
    reportFinancialCategoriesApiEnhanced.endpoints
      .postApiReportFinancialCategoriesSyncWithSource.matchRejected,
    reportFinancialCategoriesApiEnhanced.endpoints
      .postApiReportFinancialCategoriesSync.matchFulfilled,
    reportFinancialCategoriesApiEnhanced.endpoints
      .postApiReportFinancialCategoriesSync.matchRejected,
  ),
  effect: (action, listener) => {
    listener.dispatch(
      reportGeneralLedgersApiEnhanced.util.invalidateTags([
        REPORT_GENERAL_LEDGERS_META_TAG,
      ]),
    );
  },
});

import { ComparisonDashboardDto } from '@/bundles/Shared/entities/dashboard';
import { useFavoriteComparisonDashboardAssets } from '@/bundles/Shared/features/comparison/lib';
import { ReportComparisonDashboardObject } from '@/pages/comparisonDashboards/page';
import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { useNavigate, useParams } from '@reach/router';
import { intersectionBy, sortBy } from 'lodash-es';
import { useEffect } from 'react';

export const useNavigateToInitialStateComparisonDashboardEffect = ({
  compareObjs,
  comparisonDashboardData: data,
  callbackWithFirstObjects,
}: {
  compareObjs: ReportComparisonDashboardObject[];
  comparisonDashboardData: ComparisonDashboardDto | undefined;
  callbackWithFirstObjects: (objs: ReportComparisonDashboardObject[]) => void;
}) => {
  const { favouriteItems } = useFavoriteComparisonDashboardAssets();
  const navigate = useNavigate();

  const { dashboardId } =
    useParams<
      ExtractParams<typeof ROUTES_ROOT.report.comparisonDashboards.fullPath>
    >();

  const { boardId } = useQueryParams();

  useEffect(() => {
    if (
      compareObjs.length > 1 &&
      compareObjs.at(0)?._dashboardId === dashboardId
    )
      return;
    if (data == null || dashboardId == null || data.assets.length === 0) return;
    if (data.assets[0]?.id == null || data.boards[0]?.id == null) return;

    navigate(
      generateUrl(ROUTES_ROOT.report.comparisonDashboards.fullPath, {
        pathParams: {
          dashboardId,
        },
        queryParams: {
          boardId: boardId ?? data.boards[0].id,
        },
      }),
    );
    const START_LENGTH_OF_COMPARE_OBJS = 2;

    let firstObjects = intersectionBy(
      data.assets,
      favouriteItems
        .filter((favItem) => favItem.dashboardId === dashboardId)
        .map((favItem) => ({ id: favItem.assetId })),
      'id',
    ).slice(0, START_LENGTH_OF_COMPARE_OBJS);

    const sortedAssets = sortBy(data.assets, 'name');

    firstObjects = firstObjects.concat(
      sortedAssets.slice(0, START_LENGTH_OF_COMPARE_OBJS),
    );
    const firstObjectsWithType = firstObjects
      .slice(0, START_LENGTH_OF_COMPARE_OBJS)
      .map((a) => ({
        ...a,
        _type: 'asset',
        _dashboardId: dashboardId,
      })) satisfies ReportComparisonDashboardObject[];

    callbackWithFirstObjects(firstObjectsWithType);
  }, [data]);
};

import { type FinancialTableSingeDateWidgetNS } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { convertColorToHex } from '@/shared/lib/colors';
import { CellClassRules, ExcelStyle } from 'ag-grid-community';
import { useMemo } from 'react';

const HEADER_DEFAULT_BG_HEX = '#071938';
const HEADER_DEFAULT_TEXT_COLOR_HEX = '#ffffff';
const CELL_DEFAULT_BG_HEX = '#ffffff';
const CELL_DEFAULT_TEXT_COLOR_HEX = '#000000';

export const useFinancialTableWidgetsRowsDisplayOptionsExcelStyles = ({
  rows,
  headerBackground,
}: {
  rows: FinancialTableSingeDateWidgetNS.Row[];
  headerBackground: string | undefined;
}) => {
  return useMemo(() => {
    const rowsDisplayOptionsExcelStyles: ExcelStyle[] = rows
      .filter((r) => Object.values(r.displayOptions).some((o) => o != null))
      .map(
        (row) =>
          ({
            id: row.path,
            font: {
              color:
                convertColorToHex(
                  row.displayOptions.color ?? CELL_DEFAULT_TEXT_COLOR_HEX,
                ) ?? undefined,
              bold: row.displayOptions.fontWeight === '600',
            },
            interior: {
              pattern: 'Solid',
              color:
                convertColorToHex(
                  row.displayOptions.background ?? CELL_DEFAULT_BG_HEX,
                ) ?? undefined,
            },
          }) satisfies ExcelStyle,
      );

    const cellClassRules = Object.fromEntries(
      rowsDisplayOptionsExcelStyles.map((style) => [
        style.id,
        (params) => {
          const excelStyleKey = String(style.id);
          const thisRowKey = String(params.data?.key ?? '');

          return thisRowKey === excelStyleKey;
        },
      ]),
    ) satisfies CellClassRules;

    return {
      rowsDisplayOptionsExcelStyles,
      cellClassRules,
    };
  }, [rows, headerBackground]);
};

import { LEFT_POSITIONED_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import { cn } from '@/shared/lib/css/cn';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Modal, ModalActions } from '@/stories';
import { ClassNameProps } from '@/types/Props';
import { ReactComponent as ResizeHandleSvg } from 'bundles/Shared/widgets/formula/panel/ui/ResizeHandle.svg';
import React, {
  ComponentProps,
  PropsWithChildren,
  ReactNode,
  useCallback,
} from 'react';
import { ResizableBox, ResizableProps } from 'react-resizable';

const DEFAULT_SIZE = 500;
const DEFAULT_MAX_SIZE = 1500;
const LEFT_SIZEBAR_SIZE = 56;

export const ResizeHandle = React.forwardRef<
  HTMLDivElement,
  ClassNameProps & {
    position: 'top' | 'left' | 'right' | 'bottom';
  }
>(({ position = 'top', ...props }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        transform:
          position === 'right' || position === 'left'
            ? 'translateX(53%)'
            : 'translateY(-50%)',
      }}
      className={cn('group pointer-events-none absolute flex  items-center', {
        'bottom-0 left-[initial] right-[0] top-0': position === 'right',
        'bottom-0 left-0 right-[initial] top-0': position === 'left',
        'bottom-[initial] left-0 right-0 top-0': position === 'top',
        'bottom-0 left-0 right-0 top-[initial]': position === 'bottom',
        'w-[100px]': position === 'right' || position === 'left',
      })}
    >
      <ResizeHandleSvg
        {...props}
        style={{
          transform:
            position === 'right' || position === 'left' ? 'rotate(90deg)' : '',
        }}
        className={cn('pointer-events-auto grow', props.className, {
          'cursor-ew-resize': position === 'right' || position === 'left',
          'cursor-ns-resize': position === 'top' || position === 'bottom',
        })}
      />
    </div>
  );
});

export const ResizableSidePanel = (
  props: DialogProps &
    Pick<ComponentProps<typeof ResizeHandle>, 'position'> &
    Pick<
      ComponentProps<typeof ResizableBox>,
      | 'width'
      | 'resizeHandles'
      | 'minConstraints'
      | 'maxConstraints'
      | 'axis'
      | 'height'
    > &
    Pick<ComponentProps<typeof Modal>, 'zIndex' | 'header'> &
    PropsWithChildren & {
      actions: ReactNode[];
    },
) => {
  const resizeHandle = useCallback<
    Exclude<NonNullable<ResizableProps['handle']>, React.ReactNode>
  >((_, ref) => {
    return (
      <ResizeHandle
        position={props.position}
        className="opacity-0 transition-opacity group-hover:opacity-100"
        ref={ref}
      />
    );
  }, []);

  const resizableBoxSizes = () => {
    const sizes: Pick<
      ComponentProps<typeof ResizableBox>,
      'width' | 'height'
    > = {};
    if (props.height != null) {
      sizes.height = props.height;
    } else if (props.position === 'top' || props.position === 'bottom') {
      sizes.height = DEFAULT_SIZE;
    }
    if (props.width != null) {
      sizes.width = props.width;
    } else if (props.position === 'left' || props.position === 'right') {
      sizes.width = DEFAULT_SIZE;
    }
    return sizes;
  };

  return (
    <ResizableBox
      resizeHandles={props.resizeHandles ?? ['e']}
      {...resizableBoxSizes()}
      minConstraints={props.minConstraints ?? [DEFAULT_SIZE, DEFAULT_SIZE]}
      maxConstraints={
        props.maxConstraints ?? [DEFAULT_MAX_SIZE, DEFAULT_MAX_SIZE]
      }
      axis={props.axis ?? 'x'}
      handle={resizeHandle}
      style={{
        zIndex: LEFT_POSITIONED_MODAL_Z_INDEX,
        left: props.position === 'right' ? LEFT_SIZEBAR_SIZE : 'initial',
      }}
      className={cn(
        'fixed bottom-0 top-0 flex flex-col justify-between  bg-white',
        'border-r-4 border-solid border-neutral-850',
      )}
    >
      <div className={cn('modal-header')}>
        {props.header}
        <Modal.DefaultClose toggle={props.onClose} />
      </div>

      {props.children}

      <div className="flex px-tw-6 pb-tw-4">
        <ModalActions>{props.actions}</ModalActions>
      </div>
    </ResizableBox>
  );
};

export function objectEntries<Obj extends Parameters<typeof Object.entries>[0]>(
  object: Obj,
): [keyof Obj, Obj[keyof Obj]][] {
  const entries = Object.entries(object);

  type Keys = keyof typeof object;
  type Values = (typeof object)[Keys];

  return entries as [Keys, Values][];
}

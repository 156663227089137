import React, { FC } from 'react';
import Snapshot from 'bundles/Scoreboard/Pipeline/components/Snapshot';
import Pipeline from 'bundles/Scoreboard/Pipeline/components/Pipeline';
import ContainerWithAppBar from 'bundles/Shared/components/Containers/ContainerWithAppBar';
import AppBar from 'bundles/Shared/components/Containers/appBar/AppBar';
import { RouteComponentProps } from '@reach/router';

const PipelineDashboard: FC<RouteComponentProps> = () => (
  <ContainerWithAppBar appBar={<AppBar title="Pipeline" />}>
    <Snapshot />
    <Pipeline />
  </ContainerWithAppBar>
);

export default PipelineDashboard;

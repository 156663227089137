import * as React from 'react';
import { useState } from 'react';
import { Button, Modal } from 'stories/index';
import { traverse } from 'lib/treeHelpers';
import TreeViewWithNavigation from 'bundles/Settings/components/REport/CategoriesList/TreeViewWithNavigation/TreeViewWithNavigation';
import FlyBottomPanelContainer from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelContainer';
import PathBar from 'bundles/Settings/components/REport/CategoriesList/PathBar/PathBar';
import {
  mapCategoryPathToCodes,
  useCategoryPathById,
} from 'bundles/Shared/entities/finanicalCategory/lib';
import { FinancialCategory } from 'bundles/Shared/entities/finanicalCategory/model';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useEventListener } from '@/shared/lib/hooks/useEventListener';

interface Props extends DialogProps<FinancialCategory | null> {
  categories: FinancialCategory[];
  selectedCategory?: FinancialCategory | null;
  canResetCategory?: boolean;
  noSelectedPlaceholder?: string;
  header?: React.ReactNode;
}

interface IExtendedCategory extends FinancialCategory {
  name: string;
  title: string;
  expanded?: boolean;
}

function SetCategoryModal({
  selectedCategory: initialSelectedCategory,
  categories: initialCategories,
  onClose,
  onSubmit,
  canResetCategory,
  noSelectedPlaceholder,
  header,
}: Props) {
  const [categories, setCategories] = useState<IExtendedCategory[]>(
    initialCategories.map((c) =>
      traverse(c, (current) => ({
        ...current,
        name: current.code,
        title: current.code,
        expanded: true,
      })),
    ),
  );
  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory && {
      ...initialSelectedCategory,
      name: initialSelectedCategory?.code,
    },
  );
  const selectedCategoryPath = useCategoryPathById(
    selectedCategory?.id ?? null,
    categories,
  );

  useEventListener('keydown', (e) => {
    if (e.ctrlKey && e.key === 'Enter') {
      onSubmit?.(selectedCategory);
    }
  });

  const selectedCategoryPathCodes =
    mapCategoryPathToCodes(selectedCategoryPath);

  const isCategoryUpdated =
    selectedCategory?.id !== initialSelectedCategory?.id;

  return (
    <Modal
      header={header ?? 'Set Category'}
      bodyPadding="0"
      size="huge"
      toggle={onClose}
    >
      <div className="h-full w-full">
        <TreeViewWithNavigation
          data={categories}
          onSelectedChange={(category) => setSelectedCategory(category)}
          onChange={setCategories}
          selected={selectedCategory}
          showMultipleRootsParent
          multipleRootsParentName="Categories"
          expandOnSelection
        />
        <FlyBottomPanelContainer>
          <PathBar
            path={selectedCategoryPathCodes}
            noSelectedPlaceholder={noSelectedPlaceholder}
          />
          {selectedCategory != null && (
            <Button
              variant="secondary"
              size="s"
              onClick={() => setSelectedCategory(null)}
            >
              Reset Category
            </Button>
          )}
          {(canResetCategory || isCategoryUpdated) && (
            <Button
              tooltipProps={{
                mainText: 'Ctrl + Enter',
              }}
              variant="success"
              size="s"
              onClick={() => onSubmit(selectedCategory)}
            >
              {initialSelectedCategory == null
                ? 'Set Category'
                : 'Save Updates'}
            </Button>
          )}
        </FlyBottomPanelContainer>
      </div>
    </Modal>
  );
}

export default SetCategoryModal;

import React from 'react';
import LoadingPanel from '../../../Shared/components/LoadingPanel';
import HistoryItem from './HistoryItem';
import { IChangeOrder } from 'bundles/Construction/types';

interface Props {
  changeOrder: IChangeOrder;
  legalEntityCode: string;
  className?: string;
}

function History({
  changeOrder,
  legalEntityCode,
  className = 'change-event-scroll change-event-scroll_history',
}: Props) {
  if (!changeOrder) return <LoadingPanel />;

  return (
    <div>
      <div className={className}>
        {changeOrder.historyEvents?.map((item, idx) => (
          <HistoryItem
            firstItem={idx === changeOrder.historyEvents.length - 1}
            key={item.id}
            logItem={item}
            currentObjectState={changeOrder}
            id={changeOrder.id}
            legalEntityCode={legalEntityCode}
          />
        ))}
      </div>
    </div>
  );
}

export default History;

import * as React from 'react';
import { Icon, Button } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './ErrorMessage.module.scss';

interface Props {
  text: string;
  actionLabel: string;
  onActionClick: () => void;
  className?: string;
}

function ErrorMessage({ text, actionLabel, onActionClick, className }: Props) {
  return (
    <div className={cn(styles.message, className)}>
      <Icon className={styles.icon} iconName="fire" />
      <div className={styles.text}>
        <p className="dark-60 secondary-regular mb-s">{text}</p>
        <Button onClick={onActionClick} className={cn(styles.action)} size="s">
          {actionLabel}
        </Button>
      </div>
    </div>
  );
}

export default ErrorMessage;

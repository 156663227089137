import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { yesterdayDate } from '@/shared/lib/date';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  ExtractParams,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { reckonerPeriod } from '@/shared/lib/reckoner/period';
import { useParams } from '@reach/router';
import { FilterChangedEvent } from 'ag-grid-community';
import { useReportSettingsDashboardById } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import {
  buildLayoutsId,
  ObjectDashboardState,
  ReportDashboardType,
  selectReportDashboardSelectedFilterSet,
  selectReportEagleEyeDashboardSettingsById,
  selectReportObjectDashboardMetadataById,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
} from 'bundles/Shared/entities/dashboard';
import {
  PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg,
  PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg,
  PostApiSettingsReportObjectDashboardsByObjectDashboardIdWidgetSectionsPreviewApiArg,
} from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  FiltersWidgetState,
  PaginationWidgetState,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';

export const useWidgetScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath
    >
  >();

export const useWidgetPreviewData = () => {
  const { widgetId, dashboardId, boardId } = useWidgetScreenParams();

  const queryParams = useQueryParams<{ assetId: string }>();
  const assetId = queryParams.assetId ? Number(queryParams.assetId) : undefined;

  const { data: dashboard } = useReportSettingsDashboardById(dashboardId);

  const resolveStateSelector = () => {
    switch (dashboard?.type) {
      case ReportDashboardType.OBJECT: {
        return selectReportObjectDashboardMetadataById;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return selectReportEagleEyeDashboardSettingsById;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return selectReportComparisonDashboardMetadataById;
      }
    }
  };
  const stateSelector = resolveStateSelector()!;

  const boardState = useAppSelector((state) =>
    stateSelector(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  );
  const dashboardFilterSet = useAppSelector(
    selectReportDashboardSelectedFilterSet,
  );
  const dashboardState = useAppSelector((state) =>
    stateSelector(state, dashboardId),
  );
  const { objectLegalEntityIds } = (dashboardState ??
    {}) as ObjectDashboardState;

  const widgetState = boardState?.widgetsState![widgetId];
  const { data, widget } = useWidgetConfig();

  switch (dashboard?.type) {
    case ReportDashboardType.OBJECT: {
      return usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery(
        {
          objectDashboardId: dashboardId,
          boardId,
          body: {
            widget_type:
              widget?.widgetType as PostApiSettingsReportObjectDashboardsByObjectDashboardIdWidgetSectionsPreviewApiArg['body']['widget_type'],
            config:
              widget?.widgetConfig as PostApiSettingsReportObjectDashboardsByObjectDashboardIdWidgetSectionsPreviewApiArg['body']['config'],
            context: {
              date:
                widgetState?.date ?? formatToDateStringForRequest(new Date()),
              date_from: widgetState?.dateFrom,
              date_to: widgetState?.dateTo,
              granularity: widgetState?.granularity,
              legal_entity_ids: assetId ? objectLegalEntityIds?.[assetId] : [],
            },
          },
        },
        {
          skip: data == null || dashboard == null,
        },
      );
    }
    case ReportDashboardType.EAGLE_EYE: {
      return usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery(
        {
          eagleEyeDashboardId: dashboardId,
          boardId,
          body: {
            widget_type:
              widget?.widgetType as PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['widget_type'],
            config:
              widget?.widgetConfig as PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['config'],
            context: {
              date:
                widgetState?.date ?? formatToDateStringForRequest(new Date()),
              date_from: widgetState?.dateFrom,
              date_to: widgetState?.dateTo,
              period:
                widgetState?.period ??
                reckonerPeriod.t3(
                  formatToDateStringForRequest(yesterdayDate()),
                ),
              granularity: widgetState?.granularity,
              grouping_type: widgetState?.groupingType,
              legal_entity_ids: dashboardFilterSet?.legalEnities,
              assets: widgetState?.assets ?? [],
              segments: widgetState?.segments ?? [],
              period_type:
                widgetState?.periodType as unknown as PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['context']['period_type'],
            },
          },
        },
        {
          skip: data == null || dashboard == null,
        },
      );
    }
    case ReportDashboardType.COMPARISON_MODE: // NOTE this case by default will use `comparison mode` query
    default: {
      return usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery(
        {
          comparisonDashboardId: dashboardId,
          boardId,
          body: {
            widget_type:
              widget?.widgetType as PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['widget_type'],
            config:
              widget?.widgetConfig as PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['config'],
            context: {
              date:
                widgetState?.date ??
                formatToDateStringForRequest(yesterdayDate()),
              grouping_type: 'assets',
              period:
                widgetState?.period ??
                reckonerPeriod.t3(
                  formatToDateStringForRequest(yesterdayDate()),
                ),
              legal_entity_ids: assetId
                ? objectLegalEntityIds?.[Number(assetId)]
                : [],
              asset_ids: [assetId!],
            },
          },
        },
        {
          skip: data == null || dashboard == null || assetId == null,
        },
      );
    }
  }
};

export const getStateFromFilterChangedEvent = (
  e: FilterChangedEvent,
  columns: {
    label: string;
    key: number;
  }[],
): FiltersWidgetState & PaginationWidgetState => {
  const filters = Object.entries(e.api.getFilterModel()).map(
    ([key, values]) => {
      const { label } = columns.find((c) => c.key.toString() === key)!;

      return {
        column: label,
        values: values as string[],
      };
    },
  );

  return {
    filters,
    page: 1,
  };
};

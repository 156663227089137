import http from 'lib/http';
import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import { IVarianceRow } from 'bundles/REconcile/types/VarianceRows';
import { ILineItem } from 'bundles/REconcile/types/ILineItem';
import { stringify } from 'qs';
import { ICategoryNode } from 'bundles/REconcile/types/ICategoryNode';
import { IBudgetActivityLogEvent } from 'bundles/REconcile/types/ActivityLog';
import { LegalEntity } from '@/entities/core/legalEntity';

export const BUDGET_VARIANCE_ROUTE =
  '/development/legal_entities/:legalEntityId/budget_variance';

interface IDevelopmentBudgetVarianceRequest {
  legalEntityCode: LegalEntity['code'];
}

export interface IDevelopmentBudgetVarianceResponse {
  requisitions: Array<IRequisition>;
  rows: Array<IVarianceRow>;
}

export const fetchDevelopmentBudgetVariance = async ({
  legalEntityCode,
}: IDevelopmentBudgetVarianceRequest) => {
  const result = await http.get(
    BUDGET_VARIANCE_ROUTE.replace(':legalEntityId', legalEntityCode),
  );
  return (await result.json()) as IDevelopmentBudgetVarianceResponse;
};

export const BUDGET_REQUISITION_ROUTE =
  '/development/legal_entities/:legalEntityId/requisitions';

export interface IDevelopmentRequisitionAddRequest {
  beginning_date: string;
  ending_date: string;
  legalEntityId: LegalEntity['id'];
}

export const addDevelopmentBudgetRequisition = async ({
  legalEntityId,
  ending_date,
  beginning_date,
}: IDevelopmentRequisitionAddRequest) => {
  const result = await http.post(
    BUDGET_REQUISITION_ROUTE.replace(':legalEntityId', legalEntityId),
    {
      requisition: {
        ending_date,
        beginning_date,
      },
    },
  );

  const json = (await result.json()) as IRequisition;

  if (result.ok) {
    toastr.success('Requisition Created');
  }

  if (json.errors) {
    toastr.error(json.errors);
    return null;
  }
  return json;
};

export interface IDevelopmentRequisitionDeleteRequest {
  requisitionId: string;
  legalEntityId: LegalEntity['id'];
}

export interface IDevelopmentRequisitionUpdateRequest {
  requisitionId: string;
  legalEntityId: LegalEntity['id'];
  data: {
    funded: boolean;
  };
}

export const BUDGET_REQUISITION_ROUTE_DELETE = `${BUDGET_REQUISITION_ROUTE}/:requisitionId`;

export const deleteDevelopmentBudgetRequisition = async ({
  legalEntityId,
  requisitionId,
}: IDevelopmentRequisitionDeleteRequest) => {
  const route = BUDGET_REQUISITION_ROUTE_DELETE.replace(
    ':legalEntityId',
    legalEntityId,
  ).replace(':requisitionId', requisitionId);

  const result = await http.del(route);
  if (result.ok) {
    toastr.success('Requisition Deleted');
  }
};

export const updateDevelopmentBudgetRequisition = async ({
  legalEntityId,
  requisitionId,
  data,
}: IDevelopmentRequisitionUpdateRequest) => {
  const route = BUDGET_REQUISITION_ROUTE_DELETE.replace(
    ':legalEntityId',
    legalEntityId,
  ).replace(':requisitionId', requisitionId);

  const result = await http.put(route, data);

  const json = (await result.json()) as IRequisition;

  if (json.errors) {
    toastr.error(json.errors);
    return null;
  }
  return json;
};

// Fetch line items for linking modal
// GET /api/development/legal_entities/:id/requisitions/:requisitionId/line_items
// Request

export type TSortFields = 'date' | 'value';
export type TFilterFields = {
  transaction_type?: 'debit' | 'credit';
  development_status?: 'mapped' | 'not-mapped';
  general_ledger_id?: number[];
};

interface IRequisitionLineItemsRequest extends TFilterFields {
  sort: {
    field: TSortFields;
    order: 'asc' | 'desc';
  };
  query?: string;
  page: number;
  per_page: number;
}

// Response
interface IRequisitionLineItemsResponse {
  items: ILineItem[];
  meta: {
    perPage: number;
    totalSize: number;
  };
}

interface IRequisitionLineItemsRequestParams {
  requisitionId: string;
  legalEntityCode: LegalEntity['code'];
}

// Link line item to JCC/SOVC
// POST /api/development/legal_entities/:id/requisitions/:requisitionId/category_line_items
// Request
export type TDevelopmentCategorizableType =
  | 'JobCostCode'
  | 'ScheduleOfValueCode';

export interface IRequisitionLinkLineItemsRequest {
  development_categorizable_type: TDevelopmentCategorizableType;
  development_categorizable_id: number;
  report_line_item_id: number;
}

export const BUDGET_REQUISITION_LINK_LINE_ITEMS_ROUTE =
  '/development/legal_entities/:legalEntityId/requisitions/:requisitionId/category_line_items';
export const linkRequisitionLineItems = async (
  { legalEntityId, requisitionId }: IRequisitionLineItemsRequestParams,
  request: IRequisitionLinkLineItemsRequest,
) => {
  const route = BUDGET_REQUISITION_LINK_LINE_ITEMS_ROUTE.replace(
    ':legalEntityId',
    legalEntityId,
  ).replace(':requisitionId', requisitionId);

  const result = await http.post(`${route}`, request);
  const json = await result.json();

  if (json.errors) {
    toastr.error(json.errors);
    return null;
  }
  toastr.success('Transactions have been successfully linked');

  return (await json) as IRequisitionLineItemsResponse;
};

export interface IRequisitionUnlinkLineItemsRequestParams
  extends IRequisitionLineItemsRequestParams {
  categoryLineItemId: string;
}

export const BUDGET_REQUISITION_UNLINK_LINE_ITEMS_ROUTE =
  '/development/legal_entities/:legalEntityId/requisitions/:requisitionId/category_line_items/:categoryLineItemId';
export const unlinkRequisitionLineItems = async ({
  legalEntityId,
  requisitionId,
  categoryLineItemId,
}: IRequisitionUnlinkLineItemsRequestParams) => {
  const route = BUDGET_REQUISITION_UNLINK_LINE_ITEMS_ROUTE.replace(
    ':legalEntityId',
    legalEntityId,
  )
    .replace(':requisitionId', requisitionId)
    .replace(':categoryLineItemId', categoryLineItemId);

  const result = await http.del(route);
};

const BUDGET_CATEGORY_TREE_ROUTE =
  '/development/legal_entities/:legalEntityId/categories_tree';

interface IBudgetCategoryTreeResponse {
  tree: ICategoryNode[];
}

export const fetchBudgetCategoryTree = async ({
  legalEntityId,
}: {
  legalEntityId: string;
}) => {
  const route = BUDGET_CATEGORY_TREE_ROUTE.replace(
    ':legalEntityId',
    legalEntityId,
  );

  const result = await http.get(route);
  return (await result.json()) as IBudgetCategoryTreeResponse;
};

export interface IFetchBudgetVarianceActivityLogRequest {
  legalEntityCode: LegalEntity['code'];
}

export const fetchBudgetVarianceActivityLog = async ({
  legalEntityCode,
}: IFetchBudgetVarianceActivityLogRequest) => {
  const route = `/development/legal_entities/${legalEntityCode}/budget_variance/activity_log_events`;

  const result = await http.get(route);
  const json = await result.json();

  return json as IBudgetActivityLogEvent[];
};

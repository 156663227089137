import React, { FC, ReactNode } from 'react';
import { IconButton, Modal } from 'stories';
import {
  PageLayout,
  RenderPage,
  RenderPageProps,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import { cn } from '@/shared/lib/css/cn';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { requireAuthorizationHeaders } from 'lib/http';
import styles from './FilePreview.module.scss';
import FilePreviewPreloader from './FilePreviewPreloader';
import { PDF_WORKER_URL } from 'bundles/Shared/constants';

interface IFilePreviewProps {
  children: ReactNode;
  file: {
    url: Buffer | string | null;
    preSelectComponent?: ReactNode;
  };
  handleClose: (status: boolean) => void;
  withRequestHeader?: boolean;
  customRenderPage?: RenderPage;
  header?: ReactNode;
  classes?: {
    fileContainer: string;
    contentContainer: string;
  };
  withoutHeader?: boolean;
  loadingPdf?: boolean;
}

const MARGIN_BETWEEN_PAGE = 8;

const FilePreview: FC<IFilePreviewProps> = ({
  children,
  file,
  handleClose,
  header,
  withRequestHeader,
  customRenderPage,
  classes = {
    fileContainer: '',
    contentContainer: '',
  },
  loadingPdf,
  withoutHeader,
}) => {
  const pageLayout: PageLayout = {
    transformSize: ({ size }) => ({
      height: size.height + MARGIN_BETWEEN_PAGE,
    }),
    buildPageStyles: () => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }),
  };

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <div>
      {props.canvasLayer.children}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </div>
  );
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;

  return (
    <Modal
      toggle={handleClose}
      size="huge"
      bodyPadding="0"
      classes={{
        body: styles.modalBody,
      }}
    >
      <div className="flex">
        {loadingPdf && <FilePreviewPreloader />}
        <div className={classes.fileContainer || styles.fileContainer}>
          <div className={styles.panelControls}>
            <ZoomOutButton />
            <ZoomInButton />
          </div>
          {!file.url &&
            !loadingPdf &&
            file?.preSelectComponent &&
            file.preSelectComponent}
          <Worker workerUrl={PDF_WORKER_URL}>
            {!loadingPdf && file.url && (
              <Viewer
                pageLayout={pageLayout}
                fileUrl={file.url}
                renderPage={customRenderPage || renderPage}
                plugins={[zoomPluginInstance]}
                httpHeaders={
                  withRequestHeader ? requireAuthorizationHeaders() : undefined
                }
                renderLoader={() => <FilePreviewPreloader />}
              />
            )}
          </Worker>
        </div>
        <div className={classes.contentContainer || styles.contentContainer}>
          {!withoutHeader && (
            <>
              <div className={cn('modal-header', styles.modalHeader)}>
                <div>{header}</div>
                <IconButton
                  iconName="close"
                  size="l"
                  onClick={() => handleClose(false)}
                  variant="secondary"
                />
              </div>
              <hr className="m-0" />
            </>
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default FilePreview;

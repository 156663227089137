import React from 'react';
import { AnimationLoader, IconButton } from 'stories';

interface Props {
  title: React.ReactNode;
  description?: string;
  children: React.ReactNode;
  isLoading?: boolean;
  onClose?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

const EmailTemplateFormInputGroup = ({
  title,
  description,
  children,
  isLoading,
  onClose,
  onMoveUp,
  onMoveDown,
}: Props) => (
  <div className="rounded-[1rem] bg-light-10">
    <div className="px-[1rem] py-[0.5rem]">
      <div className="flex justify-between">
        <span className="text-uppercase font-weight-700 text-[1rem]">{title}</span>
        <div className="flex items-center">
          {onMoveDown && (
            <IconButton
              iconName="bottom"
              onClick={onMoveDown}
              variant="white"
            />
          )}
          {onMoveUp && (
            <IconButton iconName="top" onClick={onMoveUp} variant="white" />
          )}
          {(onMoveUp || onMoveDown) && onClose && (
            <div
              style={{
                height: '.5rem',
                width: '2px',
                backgroundColor: 'var(--light-30)',
                marginLeft: 'var(--spacing-s)',
                marginRight: 'var(--spacing-s)',
              }}
            />
          )}
          {onClose && (
            <IconButton
              iconName="closeSmall"
              onClick={onClose}
              variant="white"
            />
          )}
        </div>
      </div>
    </div>
    <div className="rounded-[1rem] p-[1rem] border border-light-20 bg-light">
      <div className="flex flex-col">
        {description && (
          <span className="font-weight-400 light-60 secondary-regular mb-tw-4 label-regular">
            {description}
          </span>
        )}
        {isLoading && <AnimationLoader className="static min-h-[200px]" />}
        {!isLoading && children}
      </div>
    </div>
  </div>
);

export default EmailTemplateFormInputGroup;

import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { debounce } from 'lodash-es';
import { Input } from 'stories';
import { useAppSelector } from '@/shared/lib/hooks/redux';

interface Props {
  onChange: (value: Date) => void;
}

function InitialEstimateDateField({ onChange }: Props) {
  const settings = useAppSelector(
    (state) => state.developmentLegalEntity.settings,
  );
  const initialDate = settings.initialEstimatedCompletionDate;
  const [date, setDate] = useState<Date | null>(
    initialDate ? new Date(initialDate) : new Date(),
  );

  useEffect(() => {
    setDate(initialDate ? new Date(initialDate) : new Date());
  }, [settings]);

  const submit = debounce((newDate) => {
    if (!Date.parse(newDate)) return;
    onChange(newDate);
  }, 500);

  return (
    <DatePicker
      placeholderText="Select Date"
      selected={date}
      minDate={new Date()}
      customInput={<Input leftIcon="calendar" />}
      onChange={(newDate) => {
        setDate(newDate);
        submit(newDate);
      }}
    />
  );
}

export default InitialEstimateDateField;

import { navigate } from '@reach/router';
import {
  useCreateBulkSharedFileMutation,
  useGetSharedFilesQuery,
  useUpdateBulkSharedFilesMutation,
  useUpdateSharedFileMutation,
} from 'bundles/Assets/components/Media/api/objectFoldersApi';
import BulkActionsPanel from 'bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import MoveItemsModal from 'bundles/Shared/components/MoveItemsModal';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import ViewMediaModal from 'bundles/Shared/components/ViewMediaModal';
import findNodeById from 'bundles/Shared/sortableTreeHelpers';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
} from 'lib/permissions';
import { bulkDownload } from 'lib/sharedFile';
import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { AnimationLoader, Breadcrumbs, Button } from 'stories';
import removeAssetMedias from '../../actions/assetMedias';
import { expandNodesWithChildren } from '../AssetDocuments';
import AlbumsList from '../Folders/AlbumsList';
import NewAlbumModal from '../Folders/NewAlbumModal';
import BulkUploaderModal from './BulkUploaderModal';
import EditMediaModal from './EditMediaModal';
import MediaList from './MediaList';

const Album = ({
  id,
  albumAssetId,
  folders,
  fetchAlbums,
  onFolderDelete,
  selectedFolders,
  setSelectedFolders,
  selectedMedia,
  setSelectedMedia,
}) => {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const [albumModalIsOpen, setAlbumModalIsOpen] = useState(false);
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const params = { folder_id: id, document_types: 'media' };
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentViewerId, setCurrentViewerId] = useState(null);
  const [uploadModalOpened, setUploadModalOpened] = useState(false);
  const [moveSharedFileModalOpened, setMoveSharedFileModalOpened] =
    useState(false);
  const [currentMediaEditing, setCurrentMediaEditing] = useState(null);
  const [disabledFolderIds, setDisabledFolderIds] = useState([]);
  const { confirm } = useModal();
  const album = folders?.find((a) => a.id === Number(id));
  const treeData = getTreeFromFlatData({ flatData: folders, rootKey: null });
  const openedAlbum = findNodeById(treeData, id);
  const subfolders = useMemo(
    () =>
      getTreeFromFlatData({
        flatData: folders,
        rootKey: id,
        getParentKey: ({ parentId }) => parentId,
      }),
    [folders, id],
  );

  const generateBreadcrumbs = (a) => {
    const breadcrumbs = [];
    if (!a) return breadcrumbs;
    let currentItem = a;
    while (currentItem) {
      breadcrumbs.push({
        label: currentItem.node.title,
        to: `../../albums/${currentItem.node.id}`,
      });
      currentItem = findNodeById(treeData, currentItem.node.parentId);
    }
    breadcrumbs[0].isCurrent = true;
    return breadcrumbs.reverse();
  };

  const breadcrumbs = useMemo(() => {
    const generatedBreadcrumbs = generateBreadcrumbs(openedAlbum);
    generatedBreadcrumbs.unshift({
      label: 'All Albums',
      to: '../../albums',
    });

    return generatedBreadcrumbs;
  }, [openedAlbum]);

  const { data: sharedFilesData, refetch: refetchSharedFiles } =
    useGetSharedFilesQuery(params);

  const sharedFiles = sharedFilesData?.sharedFiles ?? [];
  const [updateSharedFile] = useUpdateSharedFileMutation();
  const [createBulkSharedFiles] = useCreateBulkSharedFileMutation();
  const [updateBulkSharedFiles] = useUpdateBulkSharedFilesMutation();

  useEffect(() => {
    setSelectedFolders([]);
    setSelectedMedia([]);
  }, [id]);

  if (!folders || !sharedFiles)
    return <AnimationLoader className="static min-h-[50vh]" />;

  const toggleSelectItem = (item, selectedItems, setSelectedItems) => {
    if (selectedItems.find((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id),
      );
    } else {
      setSelectedItems(selectedItems.concat(item));
    }
  };

  const openEditModal = async (media: { description: string }) => {
    const res = await openModal(EditMediaModal, {
      media,
    });
    await updateSharedFile(res);
  };

  const openMoveModal = (media, opened) => {
    setCurrentMediaEditing(media);
    setDisabledFolderIds(media === null ? [] : [media.folder?.id]);
    setMoveSharedFileModalOpened(opened);
  };

  const openLightbox = (item) => {
    setCurrentViewerId(item);
    setViewerIsOpen(true);
  };

  const removeSelectedSharedFiles = async (ids: number[]) => {
    const result = await confirm({
      title: 'Remove file',
      subtitle: 'Are you sure you want to remove files?',
    });

    if (result) {
      await dispatch(removeAssetMedias({ assetId: albumAssetId, ids }));
      refetchSharedFiles();
      fetchAlbums();
      setSelectedMedia([]);
    }
  };

  const handleCreate = async (data) => {
    await createBulkSharedFiles(data);
    setUploadModalOpened(false);
  };

  const images = sharedFiles.filter((sharedFile) => !sharedFile.videoUrl);
  const currentUserCanManage = currentUserAllowedTo(
    'manage',
    ASSET_PORTAL_PRODUCT_NAME,
    'Asset',
    albumAssetId,
  );
  const compare = (a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  };

  const onMediaMove = async (selectedFolder) => {
    const data = {
      folder_id: selectedFolder.id,
      shared_files:
        selectedMedia.length > 0 ? selectedMedia : [currentMediaEditing],
      shared_file_ids: (selectedMedia.length > 0
        ? selectedMedia
        : [currentMediaEditing]
      ).map((item) => item.id),
    };

    await updateBulkSharedFiles(data);
    setMoveSharedFileModalOpened(false);
    setSelectedMedia([]);
  };

  return (
    <>
      <div className="flex w-full flex-col gap-l px-tw-6">
        <div className="light-10-background h-full">
          <div className="flex items-center justify-between gap-tw-4">
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              onBackClick={() => navigate('../albums')}
              onClick={() => navigate(`../../albums/${id}`)}
              size="medium"
              backgroundColor="light-15"
            />
            {currentUserCanManage && (
              <>
                {!album.parentId && (
                  <Button
                    variant="secondary"
                    onClick={() => setAlbumModalIsOpen(true)}
                    className="ml-auto"
                  >
                    Create Sub Album
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={() => setUploadModalOpened(true)}
                >
                  Upload Media
                </Button>
              </>
            )}
          </div>
        </div>

        {subfolders.length === 0 && sharedFiles.length === 0 && (
          <NoDataOverlay
            title={`No Media Files ${
              !album.parentId ? 'and Sub Albums' : ''
            } yet`}
          />
        )}
        {subfolders.length > 0 && (
          <h6 className="header6-regular text-light-90">
            {subfolders.length} Sub {pluralize('Album', subfolders.length)}
          </h6>
        )}
        {subfolders.length > 0 && (
          <AlbumsList
            folders={subfolders.sort(compare)}
            selectedFolders={selectedFolders}
            toggleSelected={(item) =>
              toggleSelectItem(item, selectedFolders, setSelectedFolders)
            }
            canManage={currentUserCanManage}
            onEdit={(editableAlbum) => {
              setCurrentAlbum(editableAlbum);
              setAlbumModalIsOpen(true);
            }}
            onDelete={onFolderDelete}
            onClick={(albumId) => navigate(`../albums/${albumId}`)}
            checkboxDisabled={selectedMedia.length > 0}
          />
        )}
        {subfolders.length > 0 && sharedFiles.length === 0 && (
          <NoDataOverlay title="No Media Files yet" />
        )}
        {subfolders.length > 0 && sharedFiles.length > 0 && (
          <div
            style={{
              height: '1px',
              width: '100%',
              backgroundColor: 'var(--light-30)',
            }}
          />
        )}
        {sharedFiles.length > 0 && (
          <h6 className="light-90 header6-regular">
            {sharedFiles.length} Media {pluralize('File', sharedFiles.length)}
          </h6>
        )}
        <MediaList
          items={sharedFiles}
          toggleSelectItem={(item) =>
            toggleSelectItem(item, selectedMedia, setSelectedMedia)
          }
          canManage={currentUserCanManage}
          checkboxDisabled={selectedFolders.length > 0}
          openMoveModal={openMoveModal}
          openEditModal={openEditModal}
          removeSelectedSharedFiles={removeSelectedSharedFiles}
          openLightbox={openLightbox}
          selectedMedia={selectedMedia}
          canSelect={currentUserCanManage}
        />

        {uploadModalOpened && (
          <BulkUploaderModal
            investmentObject={{
              entity: {
                id: albumAssetId,
              },
              type: 'Asset',
            }}
            defaultFolderId={album.id}
            folderType="media"
            setOpened={setUploadModalOpened}
            handleCreate={handleCreate}
          />
        )}
        {moveSharedFileModalOpened && (
          <MoveItemsModal
            close={() => openMoveModal(null, false)}
            items={
              selectedMedia.length > 0 ? selectedMedia : [currentMediaEditing]
            }
            itemType="media"
            onMove={onMediaMove}
            foldersTreeData={expandNodesWithChildren({
              tree: treeData,
              expanded: false,
            })}
            disabledFolderIds={disabledFolderIds}
          />
        )}

        <ViewMediaModal
          opened={viewerIsOpen}
          onClose={() => setViewerIsOpen(false)}
          currentIndex={images.findIndex((item) => item.id === currentViewerId)}
          views={images.map((x) => ({
            source: { regular: x.largeImageUrl },
            caption: x.description,
          }))}
        />
      </div>

      {selectedMedia.length > 0 && (
        <BulkActionsPanel
          selectedRows={selectedMedia}
          setSelectedRows={setSelectedMedia}
          actions={[
            {
              title: 'Move To',
              icon: 'transferFile',
              handleClick: () => openMoveModal(null, true),
            },
            {
              title: 'Download',
              icon: 'download',
              handleClick: () =>
                bulkDownload(selectedMedia).then(() => setSelectedMedia([])),
            },
            {
              title: 'Remove',
              icon: 'trash',
              handleClick: () => {
                removeSelectedSharedFiles(selectedMedia.map((item) => item.id));
                setSelectedMedia([]);
              },
            },
          ]}
        />
      )}

      {albumModalIsOpen && (
        <NewAlbumModal
          setOpened={setAlbumModalIsOpen}
          folder={currentAlbum}
          fetchAlbums={fetchAlbums}
          parentId={album.id}
        />
      )}
    </>
  );
};

export default Album;

import React, { FC, SetStateAction } from 'react';
import { CollapsedCard } from 'stories';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import { formatAmount } from '@/shared/lib/formatting/number';
import { IHardCostCode, IReallocation } from 'bundles/Construction/types';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { getCOforRE } from 'bundles/Construction/reducers/ReconcileReallocationSlice';
import { useParams } from '@reach/router';
import dayjs from 'dayjs';

interface ISelectCOItemProps {
  id: number;
  contractFileId: string;
  assetId: string;
  date: Date;
  approvedAt: Date;
  description: string;
  number: number;
  amount: number;
  formData: IReallocation;
  setFormData: SetStateAction<IReallocation>;
  selectable: boolean;
  hardConstructionCost: IHardCostCode;
  hardCostContingency: IHardCostCode;
  documents: File[];
}

const SelectCOItem: FC<ISelectCOItemProps> = ({
  id,
  contractFileId,
  date,
  number,
  approvedAt,
  description,
  formData,
  setFormData,
  amount,
  documents,
  selectable,
  hardConstructionCost,
  hardCostContingency,
}) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const checkHandle = async () => {
    const response = await dispatch(
      getCOforRE({
        legalEntityCode: params.legalEntityCode,
        changeOrderId: id,
      }),
    );
    const tempReallocations = response.payload.changeOrderEvents
      .filter((ce) => ce.reallocation)
      .map(({ reallocation: { jccId, value } }) => ({
        moved_from_id: `jcc-${jccId}`,
        moved_to_id: hardCostContingency.id,
        value,
      }));

    setFormData({
      ...formData,
      change_order_id: id,
      moved_from_id: Number(amount < 0)
        ? hardConstructionCost.id
        : hardCostContingency.id,
      moved_to_id: Number(amount < 0)
        ? hardCostContingency.id
        : hardConstructionCost.id,
      description: `Change Order #${number}`,
      sharedFiles: documents
        ?.filter((file) => contractFileId === file.id)
        .map((file) => ({
          ...file,
          approved: true,
        })),
      sharedFilesLoad: 'NO',
      value: amount,
      subReallocations: tempReallocations,
    });
  };

  return (
    <div className="select-change-order">
      <CollapsedCard
        checked={formData?.change_order_id === id}
        checkHandle={checkHandle}
        approvalStatus={{
          iconName: 'acceptAlt',
          text:
            dayjs(dayjs()).diff(approvedAt, 'h') > 24
              ? dayjs(approvedAt).fromNow()
              : 'today',
          tooltipText: `Approved: ${formatDate(approvedAt, 'MM-DD-YYYY')}`,
        }}
        selectable={selectable}
        title={`CO ${number} • ${formatAmount(amount)} • ${formatUnixDate(
          date,
          'MM-DD-YYYY',
        )}`}
        subtitle={description}
        radioSelect
      />
    </div>
  );
};

export default SelectCOItem;

import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import InputNumber from 'bundles/Shared/components/GroupForm/FormItems/InputNumber';
import {
  parseIntOrFloat,
  parseNumberFromCurrencyInput,
} from '@/shared/lib/formatting/number';
import { ComponentProps, useMemo } from 'react';
import { StepsFormStepv2, Tooltip } from 'stories/index';

interface Props {
  total: number | undefined;
  unitPrice: number | null;
  activated: boolean;
  setTotal: (value: number | undefined) => void;
  title: string;
  stepNumber: number;
}

export function UnitsInputNumber(
  props: ComponentProps<typeof InputNumber> & {
    tooltipMainText?: string;
  },
) {
  return (
    <Tooltip
      mainText={
        props.tooltipMainText ?? 'To enter unit amount, please set a date'
      }
      classes={{
        spanContainer: 'w-full',
      }}
      disabled={!props.disabled}
    >
      <InputNumber
        decimalScale={2}
        allowNegative={false}
        size="m"
        leftIcon="unit"
        placeholder="Units"
        {...props}
      />
    </Tooltip>
  );
}
export function TotalWithUnits({
  total,
  unitPrice,
  activated,
  setTotal,
  stepNumber,
  title,
}: Props) {
  const units = useMemo(
    () => (total && unitPrice ? parseIntOrFloat(total / unitPrice) : 0),
    [total],
  );

  return (
    <StepsFormStepv2
      title={title}
      required
      stepNumber={stepNumber}
      activated={activated}
      classes={{
        content: 'flex flex-row gap-tw-4',
      }}
    >
      <CurrencyInputNumber
        decimalScale={2}
        className="w-[284px]"
        value={total}
        placeholder="Enter Value"
        allowNegative={false}
        onChange={(e) => {
          e.target.value
            ? setTotal(parseNumberFromCurrencyInput(e.target.value))
            : undefined;
        }}
      />
      {unitPrice != null && (
        <UnitsInputNumber
          value={units}
          onChange={(e) => {
            setTotal(
              e.target.value
                ? Number(e.target.value) * (unitPrice ?? 0)
                : undefined,
            );
          }}
        />
      )}
    </StepsFormStepv2>
  );
}

/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMemo } from 'react';
import type { IChangeOrder, IPageParams } from 'bundles/Construction/types';

export function useItems(
  items: IChangeOrder[],
  setLoading,
  deps: [IPageParams, boolean],
) {
  const [pageParams, idleChecked] = deps;

  return useMemo(() => {
    setLoading(true);
    if (!items) return [];

    let newItems = [...items]
      .filter((co) => (idleChecked ? co.idle : true))
      .filter(({ status }) => {
        if (pageParams.statusList.length > 0) {
          return pageParams.statusList.includes(status);
        }
        return true;
      });

    if (pageParams.sortField !== 'updated_at') {
      const numberFields = [
        'id',
        'number',
        'amount',
        'approvedAt',
        'projectedPriorContractSum',
        'timingImpact',
        'coDate',
        'reallocationDate',
        'inReallocation',
      ];

      const comparator = (a: IChangeOrder, b: IChangeOrder) => {
        let aField = a[pageParams.sortField];
        let bField = b[pageParams.sortField];

        if (aField == null && bField != null) return -1;
        if (aField != null && bField == null) return 1;
        if (aField == null && bField == null) return 0;

        if (numberFields.includes(pageParams.sortField)) {
          const parseNums = (val: any) => parseInt(val ?? 0, 10);
          return parseNums(aField) - parseNums(bField);
        }

        if (pageParams.sortField === 'sharedFiles') {
          return aField.length - bField.length;
        }

        if (pageParams.sortField === 'description') {
          aField = aField?.trim()[0]?.toLowerCase();
          bField = bField?.trim()[0]?.toLowerCase();

          if (aField === undefined || bField === undefined) {
            return 1;
          }

          if (aField > bField) return 1;

          if (aField === bField) return 0;

          return -1;
        }

        if (aField > bField) return 1;

        if (aField === bField) return 0;

        return -1;
      };

      newItems.sort(comparator);

      if (pageParams.sortOrder === 'desc') {
        newItems = newItems.reverse();
      }
    }

    setLoading(false);
    return newItems;
  }, [...deps, items]);
}

// New Version of src/bundles/Shared/components/PermissionList.tsx
import { Permitted } from '@/bundles/Shared/entities/permissions';
import { cn } from '@/shared/lib/css/cn';
import {
  ProductNameFlat,
  currentUserAllowedTo,
  currentUserIsSuperAdmin,
} from 'lib/permissions';
import { sortBy } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { Button, Icon } from 'stories';
import PermissionsModalDocsHeader from '../PermissionsModalDocsHeader';
import { SharedWithModal } from '../SharedWithModal';
import { LockedTooltip } from './LockedTooltip';
import styles from './PermissionList.module.scss';
import { PermissionListModal } from './PermissionListModal';
import { PermissionListPopover } from './PermissionListPopover';
import { IAccessListItem } from './types';

type DeprecatedPermitted = Permitted & {
  allUsers: Permitted['directUsers'];
  public: boolean;
};

interface Props {
  permitted: DeprecatedPermitted;
  locked: boolean;
  tabsIsHidden: boolean;
  onSubmit?: (permitted: DeprecatedPermitted) => void;
  defaultText?: string;
  type?: string;
  productName?: ProductNameFlat;
  investmentObject?: {
    type: string;
    entity: {
      id: number;
    };
  };
  hideActions?: boolean;
  hideIcon?: boolean;
  className?: string;
  mainListClasses?: string;
  objectableId?: number;
  objectableType?: string;
  canEdit?: boolean;
  withoutSharedModal?: boolean;
  isEmail?: boolean;
  modalTitle?: string;
  whiteListedTabs?: ('tags' | 'users' | 'roles')[];
  placeholderRenderer?: React.ReactNode;
  fileItem?: unknown;
}

export function PermissionList({
  permitted,
  hideActions = true,
  hideIcon,
  onSubmit,
  type,
  productName,
  investmentObject,
  className,
  defaultText,
  mainListClasses,
  fileItem,
  objectableId,
  objectableType,
  whiteListedTabs,
  locked = false,
  tabsIsHidden = false,
  withoutSharedModal = false,
  canEdit = false,
  isEmail = false,
  modalTitle,
  placeholderRenderer = '—',
}: Props) {
  const [modalOpened, setModalOpened] = useState(false);
  const [viewPermissionsModalOpened, setViewPermissionsModalOpened] =
    useState(false);

  const {
    allUsers = [], // directUsers + autoSelectedUsers
    directRoles = [],
    directTags = [],
    directInvestmentEntities = [],
  } = permitted;

  const isPublic = permitted.public;

  const accessList = useMemo<IAccessListItem[]>(() => {
    // @ts-ignore
    const list: IAccessListItem[] = [
      ...allUsers.map((user) => ({
        value: user.fullName,
        type: 'member',
        role: user.role?.name,
        tags: user.tags,
      })),
      ...directRoles.map((entry) => ({ value: entry.name, type: 'role' })),
      ...directTags.map((entry) => ({ value: entry.name, type: 'tag' })),
      ...directInvestmentEntities.map((entry) => ({
        value: entry.name,
        type: 'entity',
      })),
    ];
    return sortBy(list, (item) => item.value.toLowerCase());
  }, [permitted]);

  return (
    <div
      className={cn(
        'secondary-regular flex h-[32px] w-fit items-center gap-tw-1.5 !rounded-lg bg-white px-tw-2 shadow-z-020',
        className,
      )}
    >
      {!hideIcon && <Icon iconName="access" className="text-neutral-500" />}
      <div
        className={cn(
          'permission-content inline-semibold',
          styles.content,
          mainListClasses,
        )}
      >
        {isPublic && (
          <PermissionListPopover
            isPublic={isPublic}
            accessList={accessList}
            onClick={() => setViewPermissionsModalOpened(true)}
            classes={{ pseudoLink: 'inline-regular' }}
          />
        )}
        {!isPublic && accessList.length === 0 && !defaultText && (
          <div className="text-info-055">{placeholderRenderer}</div>
        )}
        {!isPublic && accessList.length >= 1 && (
          <div className="flex items-center gap-tw-1.5">
            <PermissionListPopover
              isPublic={isPublic}
              accessList={accessList}
              onClick={() => setViewPermissionsModalOpened(true)}
            />
          </div>
        )}
      </div>
      {locked && <LockedTooltip canUnlock={currentUserIsSuperAdmin()} />}
      {!locked &&
        ((productName && currentUserAllowedTo('manage', productName)) ||
          canEdit) &&
        !hideActions &&
        onSubmit && (
          <>
            {defaultText && accessList.length === 0 && !isPublic && (
              <Button
                variant="secondary"
                onClick={() => setModalOpened(true)}
                iconName="edit"
              >
                {defaultText}
              </Button>
            )}
            {((defaultText && accessList.length > 0) ||
              isPublic ||
              !defaultText) && (
              <Icon
                className="cursor-pointer"
                iconName="edit"
                onClick={() => setModalOpened(true)}
              />
            )}
          </>
        )}
      {modalOpened && (
        <PermissionListModal
          customHeader={
            type === 'document' ? (
              <PermissionsModalDocsHeader fileItems={[fileItem]} />
            ) : undefined
          }
          title={
            type && type !== 'document'
              ? `Who can see your ${type}?`
              : modalTitle || undefined
          }
          onClose={() => setModalOpened(false)}
          // @ts-ignore
          onSubmit={onSubmit}
          initialState={permitted}
          productName={productName}
          investmentObject={investmentObject}
          objectableId={objectableId}
          objectableType={objectableType}
          tabsIsHidden={tabsIsHidden}
          whiteListedTabs={whiteListedTabs}
          isEmail={isEmail}
        />
      )}
      {viewPermissionsModalOpened && !withoutSharedModal && (
        <SharedWithModal
          toggle={() =>
            setViewPermissionsModalOpened(!viewPermissionsModalOpened)
          }
          // @ts-ignore
          roles={directRoles}
          tags={directTags}
          investmentEntities={directInvestmentEntities}
          // @ts-ignore
          allUsers={allUsers}
          setModalOpened={setModalOpened}
          hideActions={hideActions}
        />
      )}
    </div>
  );
}

/* eslint-disable @symmetre-inc/custom-rule/my-rule */
import { useGetCapitalGeneralLedgersQuery } from '@/entities/return/api/capitalGeneralLedgersApi';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { filtterSourceByType } from '@/entities/return/capital/lib';
import { cn } from '@/shared/lib/css/cn';
import { ICreateCapitalTransactionSourceRequest } from 'bundles/REturn/actions/capitalTransactionSources';
import { NO_SELECTED_SOURCES } from 'bundles/REturn/components/Ownership/ownershipsHeader/dumbJSX';
import GeneralLedgersTable from 'bundles/REturn/components/Ownership/ownershipsHeader/sourcesModal/GeneralLedgersTable';
import GeneralLedgersTagList from 'bundles/REturn/components/Ownership/ownershipsHeader/sourcesModal/GeneralLedgersTagList';
import {
  IGeneralLedgerTransactionSource,
  TReturnInvestmentTransactionDirection,
} from 'bundles/REturn/types';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import {
  AnimationLoader,
  Button,
  LinkButton,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from 'stories/index';

interface Props
  extends DialogProps<{
    contributions: ICreateCapitalTransactionSourceRequest['data'];
    distributions: ICreateCapitalTransactionSourceRequest['data'];
  }> {
  kind: `${TReturnInvestmentTransactionDirection}`;
  legalEntitiesIds: string[];
  capitalTransactionSources: TransactionSource[];
}

export type SelectedGeneralLedger = Pick<
  TransactionSource,
  'kind' | 'legalEntity'
> &
  TransactionSource['generalLedger'] & {
    disabled?: boolean;
  };

function resolveSelectedGeneralLedgers({
  sources,
  selectedGeneralLedgers,
}: {
  sources: IGeneralLedgerTransactionSource[];
  selectedGeneralLedgers: SelectedGeneralLedger[];
}) {
  if (selectedGeneralLedgers.length === 0) return sources;

  return sources.filter((source) => {
    return !selectedGeneralLedgers.some((gl) => gl.id === source.id);
  });
}

const SourcesModal = ({
  onClose,
  onSubmit,
  kind: initialKind,
  legalEntitiesIds,
  capitalTransactionSources,
}: Props) => {
  const [kind, setKind] = useState(initialKind);
  const { data, isFetching: isLoading } = useGetCapitalGeneralLedgersQuery({
    kind,
    legal_entity_ids: legalEntitiesIds,
  });

  const initialSources = useMemo<SelectedGeneralLedger[]>(() => {
    return capitalTransactionSources.map((item) => ({
      ...item.generalLedger,
      kind: item.kind,
      legalEntity: item.legalEntity,
    }));
  }, [capitalTransactionSources]);

  const [selectedContributions, setSelectedContributions] = useState(
    filtterSourceByType(initialSources, 'contribution'),
  );
  const [selectedDistributions, setSelectedDistributions] = useState(
    filtterSourceByType(initialSources, 'distribution'),
  );

  const generalLedgers = useMemo(() => {
    if (data?.items == null) return [];

    if (kind === TReturnInvestmentTransactionDirection.CONTRIBUTION) {
      return resolveSelectedGeneralLedgers({
        sources: data.items,
        selectedGeneralLedgers: selectedDistributions,
      });
    }

    return resolveSelectedGeneralLedgers({
      sources: data.items,
      selectedGeneralLedgers: selectedContributions,
    });
  }, [data?.items, selectedDistributions, selectedContributions]);

  const selectedSources = useMemo(() => {
    return kind === TReturnInvestmentTransactionDirection.CONTRIBUTION
      ? selectedContributions
      : selectedDistributions;
  }, [kind, selectedContributions, selectedDistributions]);

  const setSelectedSources =
    kind === TReturnInvestmentTransactionDirection.CONTRIBUTION
      ? setSelectedContributions
      : setSelectedDistributions;

  const handleSubmit = () => {
    onSubmit?.({
      contributions: selectedContributions.map((gl) => ({
        general_ledger_id: gl.id,
        legal_entity_id: gl.legalEntity.id,
      })),
      distributions: selectedDistributions.map((gl) => ({
        general_ledger_id: gl.id,
        legal_entity_id: gl.legalEntity.id,
      })),
    });
  };

  return (
    <Modal
      toggle={() => onClose()}
      scrollable={false}
      header={
        <ModalHeaderWithSubtitle
          title={`${capitalize(kind)} Sources`}
          subtitle="Selecting"
          order="subtitle-title"
        />
      }
      bodyPadding="0"
      size="1000"
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Set Sources
          </Button>
        </ModalActions>
      }
    >
      <div className="grid h-[70vh] bg-neutral-050">
        <div className="modal-with-side-tabs !mx-0 flex !flex-row gap-x overflow-auto bg-neutral-050">
          <section className="w-[100px] bg-neutral-050">
            <aside className="flex h-full flex-col">
              <ul>
                {[
                  TReturnInvestmentTransactionDirection.CONTRIBUTION,
                  TReturnInvestmentTransactionDirection.DISTRIBUTION,
                ].map((entryKind) => (
                  <li
                    key={entryKind}
                    className={cn(
                      kind === entryKind
                        ? 'active'
                        : 'border-r border-solid border-neutral-200',
                    )}
                  >
                    <LinkButton
                      onClick={() => {
                        setKind(entryKind);
                      }}
                      className="flex flex-col gap-tw-2 text-neutral-500"
                    >
                      {entryKind ===
                      TReturnInvestmentTransactionDirection.CONTRIBUTION
                        ? selectedContributions.length
                        : selectedDistributions.length}
                      <span className="text-neutral-900">
                        {capitalize(pluralize(entryKind, 1))}
                      </span>
                    </LinkButton>
                  </li>
                ))}
              </ul>
              <div className="w-full flex-grow border-r border-solid border-neutral-200" />
            </aside>
          </section>
          {isLoading && <AnimationLoader />}
          {!isLoading && (
            <>
              <div className="flex h-full min-w-[320px] max-w-[320px] flex-col gap-tw-4 overflow-auto border-r border-solid border-neutral-200 bg-neutral-050 px-tw-6 py-m">
                {selectedContributions.length === 0 &&
                  selectedDistributions.length === 0 &&
                  NO_SELECTED_SOURCES}
                {selectedContributions.length > 0 && (
                  <GeneralLedgersTagList
                    type="contribution"
                    generalLedgers={selectedContributions}
                    onSelectedChange={setSelectedContributions}
                  />
                )}
                {selectedDistributions.length > 0 && (
                  <GeneralLedgersTagList
                    type="distribution"
                    generalLedgers={selectedDistributions}
                    onSelectedChange={setSelectedDistributions}
                  />
                )}
              </div>
              <div className="h-full w-full overflow-auto bg-neutral-050 px-tw-6 py-m">
                <GeneralLedgersTable
                  selectedGeneralLedgers={selectedSources}
                  generalLedgers={generalLedgers}
                  onSelectedChange={setSelectedSources}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SourcesModal;

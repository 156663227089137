import React, { useLayoutEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { configChartColors, createXYChart } from 'lib/amcharts/utils';
import { useChartSeriesData } from '../../chartUtils';

export function GoalsSummaryChart({ pipelineChartsData }) {
  const [chartItemsToUpdate, setChartItemsToUpdate] = useState([]);

  const seriesData = useChartSeriesData(pipelineChartsData);

  const addInChartItemsToUpdate = (item) => {
    setChartItemsToUpdate((prev) => [...prev, item]);
  };

  useLayoutEffect(() => {
    const root = am5.Root.new('goalsSummaryChart');

    root.setThemes([am5themesAnimated.new(root)]);

    const chart = createXYChart(root);

    configChartColors(chart, ['#2fa07d', '#346f87', '#61bdb1']);

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Deal Starts',
        y: am5.p50,
        centerX: am5.p50,
      }),
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
        }),
        categoryField: 'date',
      }),
    );

    addInChartItemsToUpdate(xAxis);
    xAxis.data.setAll(pipelineChartsData);

    // Create series
    seriesData.forEach(({ name, valueYField }) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name,
          xAxis,
          yAxis,
          valueYField,
          categoryXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: '[bold]{name}[/]{valueX.formatDate()}: {valueY}',
          }),
        }),
      );
      series.columns.template.setAll({
        width: am5.percent(95),
      });

      series.bullets.push((r) => am5.Bullet.new(r, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(r, {
          text: '{valueY}',
          fill: am5.color('#fff'),
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          populateText: true,
        }),
      }));

      addInChartItemsToUpdate(series);
      series.data.setAll(pipelineChartsData);
    });

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    );
    legend.data.setAll(chart.series.values);

    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis,
      }),
    );

    return () => {
      root.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    chartItemsToUpdate.forEach((item) => {
      item.data.setAll(pipelineChartsData);
    });
  }, [pipelineChartsData]);

  return (
    <div className="panel-body">
      <div id="goalsSummaryChart" style={{ width: '100%', height: '500px' }} />
    </div>
  );
}

export default GoalsSummaryChart;

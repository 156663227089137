import {
  Formula,
  prepareFormulaReferenceForInput,
  referenceRegExpTester,
} from 'bundles/Shared/entities/formula';
import { ReadonlyDeep } from 'type-fest';
import * as yup from 'yup';
import { EntityTag, NewEntityTag } from 'bundles/Shared/entities/tag';

export const FORMULA_SCHEMA = yup.object().shape({
  id: yup.string().optional().nullable(),
  label: yup.string().required(),
  description: yup.string().optional().nullable().default(undefined),
  reference: yup
    .string()
    .required()
    .test({
      test: (ref) => {
        return referenceRegExpTester(prepareFormulaReferenceForInput(ref));
      },
    }),
  expression: yup.string().required(),
});

export type EditFormulaForm = yup.InferType<typeof FORMULA_SCHEMA> & {
  tags: (EntityTag | NewEntityTag)[];
};

export const DEFAULT_NEW_FORMULA_VALUES = {
  label: 'New Formula',
  description: '',
  reference: 'new_formula',
  tags: [],
  expression: '',
  referencedInEntities: [],
} as const satisfies ReadonlyDeep<EditFormulaForm> &
  ReadonlyDeep<Pick<Formula, 'referencedInEntities'>>;

import type { InvestmentIndexDto } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { isEmpty, lowerCase, trim } from 'lodash-es';
import { useMemo } from 'react';

export function useComputedInvEntities({
  query,
  capitalInvestments,
}: {
  query: string;
  capitalInvestments: InvestmentIndexDto[];
}) {
  const computedCapitalInvestments = useMemo(() => {
    const preparedQuery = lowerCase(trim(query));

    if (isEmpty(preparedQuery)) return capitalInvestments;

    return capitalInvestments.filter((invEnt) => {
      return includesInLowerCase(invEnt.investmentEntity.name, preparedQuery);
    });
  }, [capitalInvestments, query]);

  return computedCapitalInvestments;
}

import * as yup from 'yup';

export const REPORT_BUILDER_TEMPLATE_SETTINGS_BASIC_SCHEMA = yup
  .object()
  .shape({
    name: yup.string().required(),
    displayDate: yup.boolean().required(),
    displayPageNumber: yup.boolean().required(),
    tags: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
    ),
  });

export type ReportBuilderTemplateSettingsBasicSchema = yup.InferType<
  typeof REPORT_BUILDER_TEMPLATE_SETTINGS_BASIC_SCHEMA
>;

import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'stories';

export const WizardPage = ({ children }) => children;

const WizardForm = ({
  onSubmit,
  children,
  customSteps,
}: {
  onSubmit: () => Promise<void>;
  children: React.ReactNode;
  customSteps?: string[];
}) => {
  const [step, setStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const filteredChildren = React.Children.toArray(children).filter(Boolean);
  const activePage = filteredChildren[step];
  const titles: string[] = filteredChildren.map((s) => s.props.title);
  const isLastPage =
    step === filteredChildren.length - 1 || activePage.props.isLastPage;

  const onNext = async (skipValidation = false) => {
    if (!skipValidation && !activePage.props.isValid) return null;
    if (isLastPage) {
      setSubmitting(true);
      await onSubmit();
      if (activePage.props.isLastPage)
        setStep(Math.min(step + 1, children.length - 1));
      setSubmitting(false);
    } else {
      setStep(Math.min(step + 1, children.length - 1));
    }
  };
  const onPrev = () => setStep(Math.max(step - 1, 0));

  useEffect(() => {
    if (customSteps?.includes('next')) onNext();
    if (customSteps?.includes('prev')) onPrev();
  }, [customSteps]);

  const continueButton = document.getElementById('wizard-step-continue');
  if (activePage.props.isValid) {
    continueButton?.removeAttribute('disabled');
  } else {
    continueButton?.setAttribute('disabled', true);
  }

  const backButton = document.getElementById('wizard-step-back');
  const cancelButton = document.getElementById('wizard-step-cancel');
  if (step === 0) {
    backButton?.classList?.add('d-none');
    cancelButton?.classList?.remove('d-none');
  } else {
    backButton?.classList?.remove('d-none');
    cancelButton?.classList?.add('d-none');
  }

  const progressStepClasses = (i: number) => {
    if (step === i) return 'is-active';
    if (step > i) return 'is-complete';
    return '';
  };

  const stepClasses = (i: number) =>
    i === step ? 'text-dark-60 font-weight-500' : 'text-light-60';

  return (
    <div className="flex h-full flex-col">
      <ul className="progress-tracker progress-tracker--text progress-tracker--center my-tw-4 flex w-full justify-between">
        {titles.map((title, i) => (
          <li
            key={`${title}-${i}`}
            className={`progress-step ${progressStepClasses(i)}`}
          >
            <div className="progress-marker" data-text={i + 1} />
            {title && (
              <div className="progress-text">
                <span className={`d-none d-sm-block ${stepClasses(i)}`}>
                  {title}
                </span>
                <div className={`progress-dash ${progressStepClasses(i)}`} />
              </div>
            )}
          </li>
        ))}
      </ul>
      <form className="flex-grow mb-tw-4 flex flex-col">
        <div className="flex-grow mb-tw-4 overflow-auto">{activePage}</div>
        <div>
          {!activePage.props.withoutActions && (
            <div className="flex justify-between py-tw-6">
              <div>
                {step > 0 && !submitting && (
                  <Button
                    variant="secondary"
                    iconName="arrowLeft"
                    onClick={onPrev}
                  >
                    {' '}
                    Back
                  </Button>
                )}
              </div>
              <div>
                {activePage.props.skippable && !isLastPage && (
                  <>
                    <Button
                      variant="secondary"
                      className="mr-10"
                      onClick={() => onNext(true)}
                    >
                      Skip
                      <Icon iconName="arrowRight" className="ml-tw-1.5" />
                      <Icon iconName="arrowRight" className="-m-tw-1" />
                    </Button>
                    <span>OR</span>
                  </>
                )}
                <Button
                  variant="primary"
                  className="ml-10"
                  onClick={() => onNext()}
                  disabled={!activePage.props.isValid || submitting}
                  iconName="arrowRight"
                  iconPosition="right"
                >
                  {!isLastPage && 'Continue'}
                  {isLastPage && !submitting && 'Submit'}
                  {isLastPage && submitting && 'Submitting...'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default WizardForm;

import React from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { IReconcileSettingsChangeOrderConfigUpdate } from 'bundles/REconcile/actions/changeOrderConfig';
import { DEFAULT_NAME_FIELD } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IFieldsProps } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

function ChangeOrderConfigFields({
  formData,
  setFormData,
}: IFieldsProps<IReconcileSettingsChangeOrderConfigUpdate>) {
  return (
    <FormComponent
      formItems={[DEFAULT_NAME_FIELD]}
      formData={formData}
      setFormData={setFormData}
    />
  );
}

export default ChangeOrderConfigFields;

import React, { ComponentProps, useMemo, useState } from 'react';
import { Button, Dropdown, DropdownItem } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { upperCase } from 'lodash-es';
import {
  StatusIcon,
  StatusIconsContainer,
} from 'bundles/Shared/components/Filters/common/dropdown/StatusIcon';
import styles from './ColorOptionsDropdown.module.scss';

export type ColorOptionsDropdownProps<T extends string> = Omit<
  ComponentProps<typeof Dropdown>,
  'items' | 'children'
> & {
  options: IColorOption<T>[];
  selected: T | 'all';
  onSelectedChange: (selected: T | 'all') => void;
};

export interface IColorOption<T extends string = string> {
  label: string;
  key: T | 'all';
  color: string;
}

export const ALL_OPTION: IColorOption<'all'> = {
  label: 'All',
  key: 'all',
  color: '',
};

function ColorOptionsDropdown<T extends string>({
  selected,
  onSelectedChange,
  options: initialOptions,
  ...props
}: ColorOptionsDropdownProps<T>) {
  const [isVisible, setIsVisible] = useState(false);

  const handleItemClick = (key) => {
    setIsVisible(false);
    onSelectedChange(key);
  };

  const options = useMemo(
    () =>
      initialOptions.find((o) => o.key === 'all') === undefined
        ? [ALL_OPTION, ...initialOptions]
        : initialOptions,
    [initialOptions],
  );

  const currentOption = options.find((o) => o.key === selected) ?? ALL_OPTION;

  return (
    <Dropdown
      items={options.map((o) => (
        <DropdownItem
          key={o.key}
          active={selected === o.key}
          onClick={() => handleItemClick(o.key)}
          className={styles.item}
          style={{
            width: 175,
          }}
        >
          {o.label}{' '}
          <StatusIconsContainer options={options} currentKey={o.key} />
        </DropdownItem>
      ))}
      className={styles.dropdown}
      maxWidth="12rem"
      visible={isVisible}
      hideOnExpandedAreaClick
      onClickOutside={() => setIsVisible(false)}
      {...props}
    >
      <Button
        iconPosition="right"
        iconName="bottom"
        size="s"
        offHoverStyles
        className={cn(styles.button, {
          [styles.button_opened]: isVisible,
          [styles.button_selected]: selected !== 'all',
        })}
        onClick={() => setIsVisible(!isVisible)}
      >
        {selected !== 'all' && (
          <StatusIcon backgroundColor={currentOption.color} />
        )}{' '}
        {upperCase(currentOption.label)}
      </Button>
    </Dropdown>
  );
}

export default ColorOptionsDropdown;

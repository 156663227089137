import { typedLocalStorage } from '@/shared/lib/localStorage';
import ahoy from '@symmetre-inc/ahoy';

export const getToken = () => typedLocalStorage.getItem('authToken') ?? '';
export const inImpersonationMode = () =>
  typedLocalStorage.getItem('currentMirrorModeRole') != undefined;
export const login = (token: string) =>
  typedLocalStorage.setItem('authToken', token);

export const logout = async () => {
  // Devise requires to send request to warden to reset session
  await fetch('/users/sign_out', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Type': 'application/json',
    },
  });
  typedLocalStorage.removeItem('authToken');
  ahoy.reset();

  // { state: { from: window.location.pathname } } need to figure out redirect back
  window.location.href = '/users/sign_in'; // hard reload to drop data
};

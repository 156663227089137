import React, { useState } from 'react';
import tagColumns from './helpers/tagTableColumns';
import TagModal from './TagModal';
import {
  useCreateTagMutation,
  useDestroyTagMutation,
  useGetTagsQuery,
  useUpdateTagMutation,
} from 'bundles/UserManagement/api/settingsCoreTagsApi';
import { IPaginationParams, IQueryParams } from 'types/Pagination';
import { SortType } from 'types/Sort';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { Button } from 'stories/index';
import Table from 'bundles/Shared/components/Table/Table';
import { useModal } from '@/shared/lib/hooks/useModal';
import EditUserModal from 'bundles/UserManagement/components/EditUserModal';
import { IUser } from 'types/User';

export type TagsPageParams = Required<IQueryParams> &
  Required<IPaginationParams> & {
    sortField: 'updated_at';
    sortOrder: SortType;
  };

const TagTracker = () => {
  const [pageParams, setPageParams] = useState<TagsPageParams>({
    page: 1,
    perPage: 20,
    query: '',
    sortField: 'updated_at',
    sortOrder: 'desc',
  });
  const { data, isFetching, refetch } = useGetTagsQuery(pageParams);

  const { openModal } = useModal();

  const [updateTag] = useUpdateTagMutation();
  const [destroyTag] = useDestroyTagMutation();
  const [createTag] = useCreateTagMutation();

  const columns = tagColumns({
    destroy: destroyTag,
    edit: async (item) => {
      const res = await openModal(TagModal, {
        currentTag: item,
      });
      if (!res) return;

      updateTag({
        id: item.id,
        ...res,
      });
    },
    onUserClick: (user: IUser) => {
      openModal(EditUserModal, {
        user,
        afterUpdateCallback: refetch,
      });
    },
  });

  const handleCreateTag = async () => {
    const res = await openModal(TagModal, {});
    if (!res) return;

    createTag(res);
  };

  return (
    <>
      <div className="flex flex-col gap-tw-4">
        <PageParamsProvider
          pageParams={pageParams}
          setPageParams={setPageParams}
        >
          <div className="mt-tw-4 flex items-center justify-between">
            <PageParamsPagination
              loading={isFetching}
              sizePerPage={pageParams.perPage ?? 0}
              totalSize={data?.meta?.totalSize ?? 0}
            />
            <div className="ml-auto flex items-center">
              <PageParamsSearch size="m" />
              <Button
                className="ml-tw-6 font-medium"
                onClick={handleCreateTag}
                variant="primary"
              >
                Add New Tag
              </Button>
            </div>
          </div>
          <Table
            items={data?.items ?? []}
            columns={columns}
            settings={pageParams}
            setSettings={setPageParams}
            loading={isFetching}
          />
        </PageParamsProvider>
      </div>
    </>
  );
};

export default TagTracker;

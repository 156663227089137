export enum TTransactionType {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export const TRANSACTION_TYPE_LABELS: Record<TTransactionType | 'all', string> =
  {
    all: 'All',
    [TTransactionType.DEBIT]: 'Debit',
    [TTransactionType.CREDIT]: 'Credit',
  };

export const TRANSACTION_TYPE_COLORS: Record<TTransactionType, string> = {
  [TTransactionType.CREDIT]: 'var(--red)',
  [TTransactionType.DEBIT]: 'var(--green)',
};

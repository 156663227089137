import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { findKey, capitalize } from 'lodash-es';
import { Frequency, RRule } from 'rrule';
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  Input,
  LinkButton,
  Modal,
  ModalActions,
  RadioButton,
} from 'stories';

interface Props {
  onClose: () => void;
  onSubmit: (newRrule: RRule) => void;
  rrule: RRule;
  defaultDailyRules: any;
  defaultWeeklyRules: any;
  defaultMonthlyRules: any;
  defaultYearlyRules: any;
}

const ReccurenceModal = ({
  onClose,
  onSubmit,
  rrule,
  defaultDailyRules,
  defaultWeeklyRules,
  defaultMonthlyRules,
  defaultYearlyRules,
}: Props) => {
  const [newRruleOptions, setNewRruleOptions] = useState({ ...rrule.options });
  // radio button for day/weekday for monthly/yearly
  const [monthYearRadioOnDay, setMonthYearRadioOnDay] = useState(true);

  const currentRule = (currentFreq) => {
    switch (currentFreq) {
      case Frequency.DAILY:
        return defaultDailyRules;
      case Frequency.WEEKLY:
        return defaultWeeklyRules;
      case Frequency.MONTHLY:
        return defaultMonthlyRules;
      case Frequency.YEARLY:
        return defaultYearlyRules;
      default:
        return defaultDailyRules;
    }
  };

  const mapFrequncyToLabel = {
    day: Frequency.DAILY,
    week: Frequency.WEEKLY,
    month: Frequency.MONTHLY,
    year: Frequency.YEARLY,
  };

  const mapWeekdayToRRule = {
    monday: RRule.MO,
    tuesday: RRule.TU,
    wednesday: RRule.WE,
    thursday: RRule.TH,
    friday: RRule.FR,
    saturday: RRule.SA,
    sunday: RRule.SU,
  };

  const everyWeekDayOptions = {
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    last: -1,
  };

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const filterRruleObjByWhitelist = (rruleObj) => {
    const commonWhitelistItems = [
      'dtstart',
      'interval',
      'byhour',
      'byminute',
      'until',
      'freq',
    ];
    const whitelists = {
      [RRule.DAILY]: [...commonWhitelistItems],
      [RRule.WEEKLY]: [...commonWhitelistItems, 'byweekday'],
      [RRule.MONTHLY]: [
        ...commonWhitelistItems,
        'bymonthday',
        'bysetpos',
        'byweekday',
      ],
      [RRule.YEARLY]: [
        ...commonWhitelistItems,
        'bymonth',
        'bymonthday',
        'bysetpos',
        'byweekday',
      ],
    };

    const whitelist = whitelists[rruleObj.freq];

    const filteredRruleObj = {};
    Object.keys(rruleObj).forEach((key) => {
      if (whitelist.includes(key)) {
        filteredRruleObj[key] = rruleObj[key];
      }
    });

    return filteredRruleObj;
  };

  return (
    <Modal
      toggle={onClose}
      header="Configuring Recurrence Rules"
      actions={
        <ModalActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              onSubmit(new RRule(filterRruleObjByWhitelist(newRruleOptions)))
            }
            variant="success"
          >
            Set Reccurence Rules
          </Button>
        </ModalActions>
      }
    >
      <div className="reccurence-grid">
        <div className="font-weight-500 secondary-regular light-60 text-right">
          Start
        </div>
        <div>
          <ReactDatePicker
            portalId="datepicker-portal"
            placeholderText="Start date"
            selected={newRruleOptions.dtstart}
            onChange={(date) =>
              setNewRruleOptions({ ...newRruleOptions, dtstart: date })
            }
            wrapperClassName="w-8-75rem"
            customInput={<Input rightIcon="calendar" className="w-8-75rem" />}
          />
        </div>
        <div className="font-weight-500 secondary-regular light-60 text-right">
          Repeat every
        </div>
        <div className="flex items-center">
          <Input
            className="w-3-75rem mr-s"
            value={newRruleOptions.interval || ''}
            onChange={(e) =>
              setNewRruleOptions({
                ...newRruleOptions,
                interval: Number(e.target.value),
              })
            }
          />
          <Dropdown
            items={
              <>
                {Object.keys(mapFrequncyToLabel).map((key) => (
                  <DropdownItem
                    key={key}
                    onClick={() =>
                      setNewRruleOptions(currentRule(mapFrequncyToLabel[key]))
                    }
                  >
                    {capitalize(key)}
                  </DropdownItem>
                ))}
              </>
            }
          >
            <Button
              iconName="bottom"
              size="m"
              variant="secondary"
              iconPosition="right"
            >
              {capitalize(
                findKey(
                  mapFrequncyToLabel,
                  (value) => value === newRruleOptions.freq,
                ),
              )}
            </Button>
          </Dropdown>
        </div>
        <div />
        <div className="flex flex-col">
          {newRruleOptions.freq === RRule.WEEKLY &&
            Object.keys(mapWeekdayToRRule).map((key) => {
              const weekdayNumbers = newRruleOptions.byweekday || [];

              return (
                <Checkbox
                  key={key}
                  labelClassName="mt-s"
                  checked={weekdayNumbers.includes(
                    mapWeekdayToRRule[key].weekday,
                  )}
                  onChange={() => {
                    if (
                      weekdayNumbers.includes(mapWeekdayToRRule[key].weekday)
                    ) {
                      setNewRruleOptions({
                        ...newRruleOptions,
                        byweekday: newRruleOptions.byweekday.filter(
                          (day) => day !== mapWeekdayToRRule[key].weekday,
                        ),
                      });
                    } else {
                      setNewRruleOptions({
                        ...newRruleOptions,
                        byweekday: [
                          ...weekdayNumbers,
                          mapWeekdayToRRule[key].weekday,
                        ],
                      });
                    }
                  }}
                >
                  <span className="dark-60 font-weight-400 inline-regular">
                    {capitalize(key)}
                  </span>
                </Checkbox>
              );
            })}
          {newRruleOptions.freq === RRule.MONTHLY && (
            <>
              <div className="flex items-center">
                <RadioButton
                  checked={monthYearRadioOnDay}
                  onChange={(e) => {
                    setNewRruleOptions(currentRule(newRruleOptions.freq));
                    setMonthYearRadioOnDay(e.target.checked);
                  }}
                  labelProps={{
                    style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                  }}
                >
                  On
                </RadioButton>
                <Input
                  className="w-[4rem] mr-s"
                  value={newRruleOptions.bymonthday[0] || ''}
                  onChange={(e) =>
                    setNewRruleOptions({
                      ...newRruleOptions,
                      bymonthday: [Number(e.target.value)],
                    })
                  }
                  disabled={!monthYearRadioOnDay}
                />
              </div>
              <div className="flex items-center mt-s">
                <RadioButton
                  checked={!monthYearRadioOnDay}
                  onChange={(e) => {
                    setNewRruleOptions(currentRule(newRruleOptions.freq));
                    setMonthYearRadioOnDay(!e.target.checked);
                  }}
                  labelProps={{
                    style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                  }}
                >
                  On the
                </RadioButton>
                <Dropdown
                  disabled={monthYearRadioOnDay}
                  items={
                    <>
                      {Object.keys(everyWeekDayOptions).map((key) => (
                        <DropdownItem
                          key={key}
                          onClick={() =>
                            setNewRruleOptions({
                              ...newRruleOptions,
                              bysetpos: [everyWeekDayOptions[key]],
                            })
                          }
                        >
                          {capitalize(key)}
                        </DropdownItem>
                      ))}
                    </>
                  }
                >
                  <Button
                    iconName="bottom"
                    size="m"
                    variant="secondary"
                    iconPosition="right"
                  >
                    {capitalize(
                      findKey(
                        everyWeekDayOptions,
                        (value) =>
                          value ===
                          (newRruleOptions?.bysetpos &&
                            newRruleOptions.bysetpos[0]),
                      ),
                    )}
                  </Button>
                </Dropdown>
                <div className="ml-s">
                  <Dropdown
                    disabled={monthYearRadioOnDay}
                    items={
                      <>
                        {Object.keys(mapWeekdayToRRule).map((key) => (
                          <DropdownItem
                            key={key}
                            onClick={() =>
                              setNewRruleOptions({
                                ...newRruleOptions,
                                byweekday: [mapWeekdayToRRule[key]],
                              })
                            }
                          >
                            {capitalize(key)}
                          </DropdownItem>
                        ))}
                      </>
                    }
                  >
                    <Button
                      iconName="bottom"
                      size="m"
                      variant="secondary"
                      iconPosition="right"
                    >
                      {capitalize(
                        findKey(
                          mapWeekdayToRRule,
                          (value) =>
                            value ===
                            (newRruleOptions?.byweekday &&
                              newRruleOptions.byweekday[0]),
                        ),
                      )}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
          {newRruleOptions.freq === RRule.YEARLY && (
            <>
              <div className="flex items-center">
                <RadioButton
                  checked={monthYearRadioOnDay}
                  onChange={(e) => {
                    setNewRruleOptions(currentRule(newRruleOptions.freq));
                    setMonthYearRadioOnDay(e.target.checked);
                  }}
                  labelProps={{
                    style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                  }}
                >
                  On
                </RadioButton>
                <Dropdown
                  disabled={!monthYearRadioOnDay}
                  items={
                    <>
                      {months.map((month, index) => (
                        <DropdownItem
                          key={month}
                          onClick={() =>
                            setNewRruleOptions({
                              ...newRruleOptions,
                              bymonth: [index + 1],
                            })
                          }
                        >
                          {capitalize(month)}
                        </DropdownItem>
                      ))}
                    </>
                  }
                >
                  <Button
                    iconName="bottom"
                    size="m"
                    variant="secondary"
                    iconPosition="right"
                  >
                    {capitalize(
                      months[
                        (newRruleOptions.bymonth &&
                          newRruleOptions.bymonth[0] - 1) ||
                          0
                      ],
                    )}
                  </Button>
                </Dropdown>
                <Input
                  className="w-[4rem] ml-s"
                  value={newRruleOptions.bymonthday[0] || ''}
                  onChange={(e) =>
                    setNewRruleOptions({
                      ...newRruleOptions,
                      bymonthday: [Number(e.target.value)],
                    })
                  }
                  disabled={!monthYearRadioOnDay}
                />
              </div>
              <div className="flex items-center mt-s">
                <RadioButton
                  checked={!monthYearRadioOnDay}
                  onChange={(e) => {
                    setNewRruleOptions(currentRule(newRruleOptions.freq));
                    setMonthYearRadioOnDay(!e.target.checked);
                  }}
                  labelProps={{
                    style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                  }}
                >
                  On the
                </RadioButton>
                {/* TODO: DRY */}
                <Dropdown
                  disabled={monthYearRadioOnDay}
                  items={
                    <>
                      {Object.keys(everyWeekDayOptions).map((key) => (
                        <DropdownItem
                          key={key}
                          onClick={() =>
                            setNewRruleOptions({
                              ...newRruleOptions,
                              bysetpos: [everyWeekDayOptions[key]],
                            })
                          }
                        >
                          {capitalize(key)}
                        </DropdownItem>
                      ))}
                    </>
                  }
                >
                  <Button
                    iconName="bottom"
                    size="m"
                    variant="secondary"
                    iconPosition="right"
                  >
                    {capitalize(
                      findKey(
                        everyWeekDayOptions,
                        (value) =>
                          value ===
                          (newRruleOptions.bysetpos &&
                            newRruleOptions.bysetpos[0]),
                      ),
                    )}
                  </Button>
                </Dropdown>
                <div className="ml-s">
                  {/* TODO: DRY */}
                  <Dropdown
                    disabled={monthYearRadioOnDay}
                    items={
                      <>
                        {Object.keys(mapWeekdayToRRule).map((key) => (
                          <DropdownItem
                            key={key}
                            onClick={() =>
                              setNewRruleOptions({
                                ...newRruleOptions,
                                byweekday: [mapWeekdayToRRule[key]],
                              })
                            }
                          >
                            {capitalize(key)}
                          </DropdownItem>
                        ))}
                      </>
                    }
                  >
                    <Button
                      iconName="bottom"
                      size="m"
                      variant="secondary"
                      iconPosition="right"
                    >
                      {capitalize(
                        findKey(
                          mapWeekdayToRRule,
                          (value) =>
                            value ===
                            (newRruleOptions.byweekday &&
                              newRruleOptions.byweekday[0]),
                        ),
                      )}
                    </Button>
                  </Dropdown>
                </div>
                <div className="ml-s">
                  {/* TODO: DRY */}
                  <Dropdown
                    disabled={monthYearRadioOnDay}
                    items={
                      <>
                        {months.map((month, index) => (
                          <DropdownItem
                            key={month}
                            onClick={() =>
                              setNewRruleOptions({
                                ...newRruleOptions,
                                bymonth: [index + 1],
                              })
                            }
                          >
                            {capitalize(month)}
                          </DropdownItem>
                        ))}
                      </>
                    }
                  >
                    <Button
                      iconName="bottom"
                      size="m"
                      variant="secondary"
                      iconPosition="right"
                    >
                      {capitalize(
                        months[
                          (newRruleOptions.bymonth &&
                            newRruleOptions.bymonth[0] - 1) ||
                            0
                        ],
                      )}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="font-weight-500 secondary-regular light-60 text-right">
          Time
        </div>
        <div>
          <ReactDatePicker
            portalId="datepicker-portal"
            placeholderText="Time"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            selected={
              new Date(
                null,
                null,
                null,
                newRruleOptions.byhour || 0,
                newRruleOptions.byminute || 0,
                0,
                0,
              )
            }
            onChange={(time) => {
              setNewRruleOptions({
                ...newRruleOptions,
                byhour: [time?.getHours() || 0],
                byminute: [time?.getMinutes() || 0],
              });
            }}
            wrapperClassName="w-8-75rem"
            customInput={<Input rightIcon="clock" className="w-8-75rem" />}
          />
        </div>
        <div className="font-weight-500 secondary-regular light-60 text-right">End</div>
        <div className="flex-inline items-center">
          <ReactDatePicker
            portalId="datepicker-portal"
            placeholderText="Never"
            selected={newRruleOptions.until}
            onChange={(date) =>
              setNewRruleOptions({
                ...newRruleOptions,
                until: date,
              })
            }
            customInput={<Input rightIcon="calendar" className="w-8-75rem" />}
            wrapperClassName="w-8-75rem"
          />
          {newRruleOptions.until && (
            <LinkButton
              className="blue ml-m"
              onClick={() =>
                setNewRruleOptions({
                  ...newRruleOptions,
                  until: undefined,
                })
              }
            >
              Remove
            </LinkButton>
          )}
        </div>
        <div />
        <p className="dark-60 font-weight-400 inline-regular mt-s">
          Occurs {new RRule(newRruleOptions).toText()}
        </p>
      </div>
    </Modal>
  );
};

export default ReccurenceModal;

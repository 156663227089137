import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { useModal } from '@/shared/lib/hooks/useModal';
import PublishLegalEntitiesModal from 'bundles/Settings/components/REport/DataPeriods/PublishLegalEntitiesModal/PublishLegalEntitiesModal';
import { SmallAddingButton } from 'stories';

interface Props
  extends Pick<
    React.ComponentProps<typeof PublishLegalEntitiesModal>,
    'dialogType' | 'period'
  > {
  count: number;
}

function LegalEntitiesCell({ count, dialogType, period }: Props) {
  const { openModal } = useModal();

  const handleClick = async () => {
    const res = await openModal(PublishLegalEntitiesModal, {
      dialogType,
      period,
    });
  };

  return (
    <div className="flex items-start justify-between gap">
      <div className="flex-col flex gap-xs">
        <h4
          className={cn(
            'header4-regular',
            count === 0 ? 'light-60' : 'light-90',
          )}
        >
          {count}
        </h4>
        <span className="light-60">LEGAL ENTITIES</span>
      </div>
      {count > 0 && (
        <SmallAddingButton
          withoutIcon
          className={cn(dialogType !== 'draft' && 'light-60 border bg-white')}
          onClick={handleClick}
        >
          {dialogType === 'draft' ? 'Publish' : 'View'}
        </SmallAddingButton>
      )}
    </div>
  );
}

export default LegalEntitiesCell;

import { mapListToIds } from '@/shared/lib/listHelpers';
import { NavigateOptions, useNavigate, useParams } from '@reach/router';
import {
  filterIncludedLegalEntities,
  ObjectDashboardAssetDto,
  useGetApiReportObjectDashboardsByIdQuery,
} from 'bundles/Shared/entities/dashboard';
import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';
import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { isEqual, orderBy } from 'lodash-es';
import { useEffect } from 'react';

export const useReportDashboardScreenQueryParams = () => {
  const { assetId, boardId } = useQueryParams<{
    assetId?: string;
    boardId?: string;
  }>();
  return {
    assetId: assetId && Number(assetId),
    boardId,
  };
};

export const useReportDashboardFromScreenParams = () => {
  const { dashboardId } =
    useParams<ExtractParams<typeof ROUTES_ROOT.report.dashboards.fullPath>>();

  return useGetApiReportObjectDashboardsByIdQuery({
    id: dashboardId,
  });
};

export const useReportDashboardNavigate = () => {
  const navigate = useNavigate();
  const { dashboardId } =
    useParams<ExtractParams<typeof ROUTES_ROOT.report.dashboards.fullPath>>();
  const { boardId: currentBoardId } = useReportDashboardScreenQueryParams();
  return (
    {
      assetId,
      boardId = currentBoardId,
    }: {
      assetId: string;
      boardId?: string;
    },
    options?: NavigateOptions<any>,
  ) =>
    navigate(
      generateUrl(ROUTES_ROOT.report.dashboards.fullPath, {
        pathParams: {
          dashboardId,
        },
        queryParams: {
          assetId,
          boardId,
        },
      }),
      options,
    );
};

export const useFavoriteObjectDashboardAssets = () => {
  const [favouriteItems, setFavouriteItems] = useLocalStorageValue(
    'reportObjectDashboard',
    [],
  );

  const toggleItemFavorite = (item: {
    dashboardId: string;
    assetId: number;
  }) => {
    setFavouriteItems(
      favouriteItems.some((itemX) => isEqual(itemX, item))
        ? favouriteItems.filter((itemX) => !isEqual(itemX, item))
        : [...favouriteItems, item],
    );
  };

  const isItemFavorite = (item: { dashboardId: string; assetId: number }) =>
    favouriteItems.some((itemX) => isEqual(itemX, item));

  return {
    toggleItemFavorite,
    isItemFavorite,
    favouriteItems,
  };
};

export const useReportDashboardRedirectToFirstOrFavoriteAssetEffect = () => {
  const { data } = useReportDashboardFromScreenParams();
  const { assetId, boardId } = useReportDashboardScreenQueryParams();
  const navigateToDashboard = useReportDashboardNavigate();
  const { favouriteItems } = useFavoriteObjectDashboardAssets();

  useEffect(() => {
    const newBoardId = boardId ?? data?.boards[0]?.id;
    if (data != null && assetId == null) {
      if (
        favouriteItems.filter((item) => item.dashboardId === data.id).length > 0
      ) {
        navigateToDashboard(
          {
            assetId: favouriteItems[0].assetId.toString(),
            boardId: newBoardId,
          },
          {
            replace: true,
          },
        );
        return;
      }
      navigateToDashboard(
        {
          assetId: orderBy(data.assets, 'name')[0].id.toString(),
          boardId: newBoardId,
        },
        {
          replace: true,
        },
      );
    }
  }, [data]);
};

export const useReportDashboardSelectedAsset = ():
  | ObjectDashboardAssetDto
  | undefined => {
  const { data } = useReportDashboardFromScreenParams();
  const { assetId } = useReportDashboardScreenQueryParams();
  const asset = data?.assets.find((a) => a.id === assetId);

  if (asset == null) return undefined;

  const excludedLegalEntityIds = mapListToIds(
    data?.excludedLegalEntities ?? [],
  );

  return {
    ...asset,
    legalEntities: asset.legalEntities.filter((le) =>
      filterIncludedLegalEntities(le, excludedLegalEntityIds),
    ),
  };
};

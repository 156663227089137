import React, { FC } from 'react';
import { IChangeOrder } from 'bundles/Construction/types';
import COItem from './COItem';

interface IIncludedChangeOrderProps {
  changeOrder: IChangeOrder;
}

const IncludedChangeOrder: FC<IIncludedChangeOrderProps> = ({
  changeOrder,
}) => (
  <div className="change-card mb-m pb-m">
    <div className="change-card__header change-card__header_line">
      <div className="change-card__title header6-bold pb-m">
        Included Change Order
      </div>
    </div>
    <COItem {...changeOrder} />
  </div>
);

export default IncludedChangeOrder;

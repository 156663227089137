import { DateSSN } from '@/shared/lib/dateSSN/ssn';

import { VariableValueType } from '@/bundles/Shared/entities/formula/model';

import InputNumber from '@/bundles/Shared/components/GroupForm/FormItems/InputNumber';

import { VALUE_TYPE_ICON } from '@/entities/report/manualVariable/config';

import CurrencyInputNumber from '@/bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import {
  convertDecimalToPercentage,
  convertPercentageToDecimal,
} from '@/shared/lib/converters';
import { ReactDatePickerWithState } from '@/widgets/report/manualVariable/settings/lib';

export const ValueTypeInput = ({
  valueType,
  value,
  onChange,
}: {
  valueType: VariableValueType;
  value: number;
  onChange: (value: number) => void;
}) => {
  if (valueType === 'percentage') {
    return (
      <InputNumber
        defaultValue={convertDecimalToPercentage(value)}
        size="m"
        rightIcon={valueType ? VALUE_TYPE_ICON[valueType] : undefined}
        onBlur={(newValue) => {
          onChange(newValue ? convertPercentageToDecimal(newValue) : 0);
        }}
      />
    );
  }
  if (valueType === 'currency') {
    return <CurrencyInputNumber defaultValue={value} onBlur={onChange} />;
  }
  if (valueType === 'date') {
    const currentDate = new DateSSN(value).toDate();
    return (
      <ReactDatePickerWithState
        defaultValue={currentDate}
        onUpdate={(date) => {
          const newValue = new DateSSN(date ?? new Date()).toNumber();
          onChange(newValue);
        }}
      />
    );
  }
  return (
    <InputNumber
      defaultValue={value}
      size="m"
      rightIcon={valueType ? VALUE_TYPE_ICON[valueType] : undefined}
      onBlur={(newValue) => {
        onChange(newValue ?? 0);
      }}
    />
  );
};

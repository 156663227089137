interface IState {
  label: string;
  backgroundColor: string;
}

export default {
  published: {
    label: 'Published',
    backgroundColor: 'var(--gr)',
  },
  in_progress: {
    label: 'In progress',
    backgroundColor: 'var(--bl)',
  },
  draft: {
    label: 'Draft',
    backgroundColor: 'var(--dark-60)',
  },
} as const satisfies Record<string, IState>;

import { useCallback } from 'react';
import { ThresholdModal } from '@/entities/report/thresholds/ui/ThresholdModal';
import {
  useDeleteApiSettingsReportThresholdsByKindMutation,
  usePutApiSettingsReportThresholdsByKindMutation,
} from '@/bundles/Shared/shared/api/settingsReportThresholdsEnhancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { Threshold } from '@/entities/report/thresholds/model';

export const useUpdateThreshold = (threshold: Threshold) => {
  const { openModal } = useModal();
  const [updateThreshold] = usePutApiSettingsReportThresholdsByKindMutation();
  return useCallback(
    async (
      modalProps?: Omit<
        React.ComponentProps<typeof ThresholdModal>,
        'onSubmit' | 'onClose'
      >,
    ) => {
      const res = await openModal(ThresholdModal, {
        threshold,
        ...modalProps,
      });
      if (res) {
        await updateThreshold({
          kind: threshold.kind,
          body: {
            global_value: res.globalValue,
          },
        });
      }
    },
    [threshold],
  );
};

export const useDeleteThreshold = (threshold: Threshold) => {
  const { confirm } = useModal();
  const [deleteThreshold, options] =
    useDeleteApiSettingsReportThresholdsByKindMutation();
  const callback = useCallback(async () => {
    const res = await confirm({
      title: 'Delete Threshold',
      subtitle:
        'Are you sure you want to remove this threshold? Note all the specified Legal Entity thresholds will also be removed.',
    });
    if (res) {
      await deleteThreshold({ kind: threshold.kind });
    }
  }, [threshold]);

  return [callback, options] as const;
};

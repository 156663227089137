import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories/index';
import styles from './Impersonation.module.scss';

interface Props {
  label: string;
  active?: boolean;
}

function ImpersonationSwitchButton({ label, active = false }: Props) {
  return (
    <div
      className={cn(
        'inline-semibold gap-tw-2',
        styles.impersonationSwitchButtonContainer,
        { [styles.active]: active },
      )}
    >
      {label}
      <IconButton
        iconName="columnExpand"
        size="s"
        className={styles.expandButton}
      />
    </div>
  );
}

export default ImpersonationSwitchButton;

import React from 'react';
import { TMentionedUser } from 'bundles/REconcile/types/Comments';
import {
  MENTION_MARKER,
  processMentions,
} from 'bundles/REconcile/components/comments/CommentEditor/utils';
import { cn } from '@/shared/lib/css/cn';
import ContentOverflow from 'bundles/Shared/components/ContentOverflow/ContentOverflow';

interface Props {
  message: string;
  mentionedUsers: TMentionedUser[];
  className?: string;
}

function CommentContent({ mentionedUsers, message, className }: Props) {
  const content = processMentions({
    text: message,
    direction: 'client',
    users: mentionedUsers,
    clientFormat: (user) =>
      `<span class="secondary-semibold" >${MENTION_MARKER}${user.fullName}</span>`,
  });

  return (
    <ContentOverflow maxHeight={130}>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
        className={cn('white-space-pl secondary-regular', className)}
      />
    </ContentOverflow>
  );
}

export default CommentContent;

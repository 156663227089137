import React from 'react';
import { Control, useController } from 'react-hook-form';
import { ResetButton } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { ColorPickerWidget } from '@/widgets/core/sreColors/settings/ui/ColorPickerWidget';

export function ColorField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: React.ComponentProps<typeof SectionField> & {
  name: TName;
  control: Control<TFieldValues>;
}) {
  const { field } = useController({ name, control });
  return (
    <SectionField
      labelText={'Color'}
      button={
        <ResetButton
          onClick={() =>
            field.onChange(null, {
              shouldDirty: true,
            })
          }
          name={name}
        />
      }
      {...props}
    >
      <ColorPickerWidget value={field.value} onChange={field.onChange} />
    </SectionField>
  );
}

import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsSreColors: build.query<
      GetApiSettingsSreColorsApiResponse,
      GetApiSettingsSreColorsApiArg
    >({
      query: () => ({ url: `/api/settings/sre_colors` }),
    }),
    postApiSettingsSreColors: build.mutation<
      PostApiSettingsSreColorsApiResponse,
      PostApiSettingsSreColorsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/sre_colors`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsSreColorsById: build.mutation<
      PutApiSettingsSreColorsByIdApiResponse,
      PutApiSettingsSreColorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/sre_colors/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsSreColorsById: build.mutation<
      DeleteApiSettingsSreColorsByIdApiResponse,
      DeleteApiSettingsSreColorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/sre_colors/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiSettingsSreColorsBulk: build.mutation<
      PostApiSettingsSreColorsBulkApiResponse,
      PostApiSettingsSreColorsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/sre_colors/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as sreColorsGeneratedApi };
export type GetApiSettingsSreColorsApiResponse =
  /** status 200 SRE colors found */ SreColorDto[];
export type GetApiSettingsSreColorsApiArg = void;
export type PostApiSettingsSreColorsApiResponse = unknown;
export type PostApiSettingsSreColorsApiArg = {
  body: {
    value: string;
  };
};
export type PutApiSettingsSreColorsByIdApiResponse = unknown;
export type PutApiSettingsSreColorsByIdApiArg = {
  id: string;
  body: {
    value: string;
  };
};
export type DeleteApiSettingsSreColorsByIdApiResponse = unknown;
export type DeleteApiSettingsSreColorsByIdApiArg = {
  id: string;
};
export type PostApiSettingsSreColorsBulkApiResponse = unknown;
/**
 * @param _destroy - NOTE! it's hardcoded string `true`. Not boolean. TODO update FE & BE into `boolean` type
 */
export type PostApiSettingsSreColorsBulkApiArg = {
  body: {
    sre_colors: {
      id?: string;
      value: string;
      _destroy?: 'true';
    }[];
  }
};
export type SreColorDto = {
  id: string;
  value: string;
  isDefault: boolean;
};

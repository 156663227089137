import { apiClient, requireAuthorizationHeaders } from '@/lib/http';
import { AxiosError } from 'axios';

export async function importFile({
  file,
  url,
  enableLoading,
  disableLoading,
  args,
}: {
  url: string;
  file: File;
  enableLoading?: VoidFunction;
  disableLoading?: VoidFunction;
  args?: Record<string, string | Blob>;
}) {
  const formData = new FormData();
  formData.set('file', file);
  for (let key in (args ?? {})) {
    formData.set(key, args![key])
  }

  try {
    enableLoading?.();
    const res = await apiClient.post(url, formData, {
      headers: requireAuthorizationHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    });

    if (res.status !== 200) {
      throw res;
    }
    toastr.success('Import is complete');
    return res;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      const syErrors = e as AxiosError<
        { errors: string[] },
        { errors: string[] }
      >;

      const msg =
        syErrors.response?.data.errors.join(', \n') ?? 'Something went wrong';

      toastr.error(msg, 'Errors', {
        timeOut: 0,
        extendedTimeOut: 0,
      });
    }
  } finally {
    disableLoading?.();
  }
}

export enum TYPOGRAPHY_COMPONENTS {
  HEADER_1_REGULAR = '.header1-regular',
  HEADER_2_REGULAR = '.header2-regular',
  HEADER_3_REGULAR = '.header3-regular',
  HEADER_4_REGULAR = '.header4-regular',
  HEADER_5_REGULAR = '.header5-regular',
  HEADER_6_REGULAR = '.header6-regular',
  HEADER_1_BOLD = '.header1-bold',
  HEADER_2_BOLD = '.header2-bold',
  HEADER_3_BOLD = '.header3-bold',
  HEADER_4_BOLD = '.header4-bold',
  HEADER_5_BOLD = '.header5-bold',
  HEADER_6_BOLD = '.header6-bold',
  DISPLAY_1 = '.display-1',
  DISPLAY_2 = '.display-2',
  DISPLAY_3 = '.display-3',
  DISPLAY_4 = '.display-4',
  DISPLAY_5 = '.display-5',
  DISPLAY_6 = '.display-6',
  BLOCKQUOTE_REGULAR = '.blockquote-regular',
  LEAD_REGULAR = '.lead-regular',
  BODY_REGULAR = '.body-regular',
  INLINE_REGULAR = '.inline-regular',
  LABEL_REGULAR = '.label-regular',
  SECONDARY_REGULAR = '.secondary-regular',
  BLOCKQUOTE_SEMIBOLD = '.blockquote-semibold',
  LEAD_SEMIBOLD = '.lead-semibold',
  BODY_SEMIBOLD = '.body-semibold',
  INLINE_SEMIBOLD = '.inline-semibold',
  LABEL_SEMIBOLD = '.label-semibold',
  SECONDARY_SEMIBOLD = '.secondary-semibold',
}

import React, { useMemo, useRef } from 'react';
import { useAmchart } from 'lib/amcharts/useAmchart';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import {
  addCenteredLegend,
  addCursor,
  createXYChart,
  getReturnDashboardTheme,
} from 'lib/amcharts/utils';
import { PIPELINE_CHART_COLOR_SET } from 'bundles/Scoreboard/Pipeline/components/chartUtils';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { abbreviateNumberFormat } from '@/shared/lib/formatting/number';

interface Props {
  data: any[];
  blockRefresh?: boolean;
  chartRef?: React.RefObject<{
    exporting: am5plugins_exporting.Exporting;
  }>;
}

function WaterfallChart({ data, chartRef }: Props) {
  const ref = useRef(null);

  const mappedData = useMemo(
    () =>
      data.map((el, index) => {
        const prevHardBreaks = data.filter(
          (elem, i) => elem.hardBreak && i < index,
        );
        const prevHardBreak =
          prevHardBreaks.length > 0
            ? prevHardBreaks[prevHardBreaks.length - 1]
            : undefined;

        const sumValue = data
          .slice(prevHardBreak ? data.indexOf(prevHardBreak) : 0, index + 1)
          .reduce((s, f) => s + f.value, 0);

        return {
          category: el.name,
          value: el.hardBreak ? el.value : sumValue,
          open: el.hardBreak
            ? 0
            : data
                .slice(prevHardBreak ? data.indexOf(prevHardBreak) : 0, index)
                .reduce((s, f) => s + f.value, 0),
          stepValue: el.hardBreak ? el.value : sumValue,
          displayValue: el.value,
          columnSettings: {
            fill: el.color,
          },
        };
      }),
    [data],
  );
  useAmchart(
    ref,
    (root) => {
      if (data.length === 0) return;
      chartRef!.current = {
        exporting: am5plugins_exporting.Exporting.new(root, {}),
      };
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', PIPELINE_CHART_COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const chart = createXYChart(root, {});

      // chart.responsive.enabled = true;
      root.numberFormatter.set('numberFormat', "'$'#.00a");
      const categoryAxisRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 40,
      });
      categoryAxisRenderer.labels.template.setAll({
        disabled: true,
        visible: false,
      });
      const categoryAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: categoryAxisRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        }),
      );

      categoryAxis.data.setAll(mappedData);

      const valueAxisRenderer = am5xy.AxisRendererY.new(root, {});
      const valueAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          max: Math.max(...data.map((el) => el.value)) * 1.3,
          renderer: valueAxisRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        }),
      );

      const columnSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Name',
          xAxis: categoryAxis,
          yAxis: valueAxis,
          valueYField: 'value',
          openValueYField: 'open',
          categoryXField: 'category',
          interpolationDuration: 1500,
          sequencedInterpolation: true,
        }),
      );
      columnSeries.data.processor = am5.DataProcessor.new(root, {
        colorFields: ['columnSettings.fill'],
      });
      columnSeries.columns.template?.setAll({
        fillOpacity: 0.7,
        strokeOpacity: 0,
        templateField: 'columnSettings',
      });
      const labelTemplate = am5.Template.new({});
      labelTemplate.events.on('positionchanged', function () {
        am5.array.each(columnSeries.dataItems, function (dataItem) {
          const bullets = dataItem.bullets!;
          const bullet = bullets[0]!;
          const valueY = dataItem.get('valueY')!;
          const openValueY = dataItem.get('openValueY')!;
          if (valueY < openValueY) {
            bullet.set('locationY', 0);
          } else {
            bullet.set('locationY', 1);
          }
        });
      });
      labelTemplate.adapters.add('text', (text, target) => {
        if (!target.dataItem) {
          return text;
        }
        return abbreviateNumberFormat(target.dataItem.dataContext.displayValue);
      });
      columnSeries.bullets.push((r) => {
        const bullet = am5.Bullet.new(r, {
          locationX: 0.5,
          sprite: am5.Label.new(
            r,
            {
              text: '{valueY}',
              paddingBottom: 20,
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              populateText: true,
            },
            labelTemplate,
          ),
        });

        return bullet;
      });

      columnSeries.data.setAll(mappedData);

      const stepSeries = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: 'Series',
          xAxis: categoryAxis,
          yAxis: valueAxis,
          valueYField: 'stepValue',
          categoryXField: 'category',
          interpolationDuration: 2000,
          sequencedInterpolation: true,
          noRisers: true,
          stroke: root.interfaceColors.get('alternativeBackground'),
        }),
      );

      stepSeries.strokes.template.setAll({
        strokeDasharray: [3, 3],
      });

      stepSeries.data.setAll(mappedData);

      const legend = addCenteredLegend(root, chart, {
        useDefaultMarker: true,
        nameField: 'categoryX',
        centerY: 40,
        height: 50,
        layout: am5.GridLayout.new(root, {
          fixedWidthGrid: true,
        }),
      });
      legend.labels.template.setAll({
        oversizedBehavior: 'truncate',
        maxWidth: 150,
      });
      legend.data.setAll(columnSeries.dataItems);

      // legend.contentAlign = 'right';
      addCursor(root, chart, {
        behavior: 'none',
      });
      return chart;
    },
    [mappedData],
  );

  return <div ref={ref} style={{ width: '100%', height: '400px' }} />;
}

export default WaterfallChart;

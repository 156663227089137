import { ISelectOption } from './Select';

export function assertIsSelectOption(
  selected: ISelectOption | ISelectOption[] | undefined,
): asserts selected is ISelectOption {
  if (selected === undefined || Array.isArray(selected)) {
    throw new Error('Not a single select option');
  }
}

export function isSelectedArray(
  selected: ISelectOption | ISelectOption[],
): selected is ISelectOption[] {
  return Array.isArray(selected);
}

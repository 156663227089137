import { emptySplitApi as api } from '../../../../app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportRentRollSourceUnitStatuses: build.query<
      GetApiSettingsReportRentRollSourceUnitStatusesApiResponse,
      GetApiSettingsReportRentRollSourceUnitStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_unit_statuses`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          legal_entity_ids: queryArg.legalEntityIds,
          with_unit_status: queryArg.withUnitStatus,
          without_unit_status: queryArg.withoutUnitStatus,
          query: queryArg.query,
          group: queryArg.group,
          sort: queryArg.sort,
          sre_unit_statuses: queryArg.sreUnitStatusIds.map((item) => item.id),
          erps: queryArg.erps.map((item) => item.id),
          pmc_ids: queryArg.pmcIds.map((item) => item.id),
        },
      }),
    }),
    putApiSettingsReportRentRollSourceUnitStatusesSetUnitStatus: build.mutation<
      PutApiSettingsReportRentRollSourceUnitStatusesSetUnitStatusApiResponse,
      PutApiSettingsReportRentRollSourceUnitStatusesSetUnitStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_unit_statuses/set_unit_status`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportRentRollSourceUnitStatusesExportCsv: build.query<
      GetApiSettingsReportRentRollSourceUnitStatusesExportCsvApiResponse,
      GetApiSettingsReportRentRollSourceUnitStatusesExportCsvApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_unit_statuses/export.csv`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          with_unit_status: queryArg.withUnitStatus,
          without_unit_status: queryArg.withoutUnitStatus,
          query: queryArg.query,
          group: queryArg.group,
          sort: queryArg.sort,
        },
      }),
    }),
    postApiSettingsReportRentRollSourceUnitStatusesImport: build.mutation<
      PostApiSettingsReportRentRollSourceUnitStatusesImportApiResponse,
      PostApiSettingsReportRentRollSourceUnitStatusesImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_unit_statuses/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportRentRollUnitStatuses: build.query<
      GetApiSettingsReportRentRollUnitStatusesApiResponse,
      GetApiSettingsReportRentRollUnitStatusesApiArg
    >({
      query: () => ({ url: `/api/settings/report/rent_roll/unit_statuses` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportOperationalUnitStatusesApi };
export type GetApiSettingsReportRentRollSourceUnitStatusesApiResponse =
  /** status 200 success */ {
    items: ReportRentRollSourceUnitStatusDto[];
    meta: PaginationDto & {
      legalEntities: {
        id: string;
        name: string;
        code: string;
      }[];
      withoutUnitStatusSize: number;
      withUnitStatusSize: number;
      usedSreUnitStatusesSize: number;
    };
  };
export type GetApiSettingsReportRentRollSourceUnitStatusesApiArg = {
  page?: number;
  perPage?: number;
  legalEntityIds?: string[];
  withUnitStatus?: boolean;
  withoutUnitStatus?: boolean;
  query?: string;
  group?: ('erp' | 'pmc' | 'le')[];
  sort?: {
    field?: 'legal_entity';
    order?: 'asc' | 'desc';
  };
};
export type PutApiSettingsReportRentRollSourceUnitStatusesSetUnitStatusApiResponse =
  unknown;
export type PutApiSettingsReportRentRollSourceUnitStatusesSetUnitStatusApiArg =
  {
    body: {
      source_unit_status_ids: string[];
      unit_status:
        | 'admin'
        | 'applicant'
        | 'occupied'
        | 'occupied_ntv'
        | 'occupied_ntvl'
        | 'vacant'
        | 'vacant_leased' | null;
    };
  };
export type GetApiSettingsReportRentRollSourceUnitStatusesExportCsvApiResponse =
  unknown;
export type GetApiSettingsReportRentRollSourceUnitStatusesExportCsvApiArg = {
  legalEntityIds?: string[];
  withUnitStatus?: boolean;
  withoutUnitStatus?: boolean;
  query?: string;
  group?: ('erp' | 'pmc' | 'le')[];
  sort?: {
    field?: 'legal_entity';
    order?: 'asc' | 'desc';
  };
};
export type PostApiSettingsReportRentRollSourceUnitStatusesImportApiResponse =
  unknown;
export type PostApiSettingsReportRentRollSourceUnitStatusesImportApiArg = {
  body: string;
};
export type GetApiSettingsReportRentRollUnitStatusesApiResponse =
  /** status 200 success */ {
    items: {
      key: string;
      label: string;
    }[];
  };
export type GetApiSettingsReportRentRollUnitStatusesApiArg = void;
export type ReportRentRollSourceUnitStatusLegalEntityDto = {
  id: string;
  name: string;
  classification: string | null;
  code: string;
  asset: {
    id: number;
    name: string;
  } | null;
};
export type ReportRentRollSourceUnitStatusDto = {
  id: string;
  name: string;
  legalEntity: ReportRentRollSourceUnitStatusLegalEntityDto;
  unitStatus: string | null;
  erpSystem: string;
  propertyManagementCompany: {
    id: number;
    name: string;
  };
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};

import { INotification } from 'types/Notification';
import { AnyAction } from 'redux';
import {
  FETCH_NOTIFICATIONS,
  HIDE_NOTIFICATION,
  HIDE_ALL_NOTIFICATIONS,
  FETCH_NEW_NOTIFICATIONS,
} from 'bundles/Notifications/actions';

interface INotificationsState {
  all?: INotification[];
  unread?: INotification[];
}

const initialState: INotificationsState = {
  all: undefined,
  unread: undefined,
};

export default (
  state = initialState,
  action: AnyAction,
): INotificationsState => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        all: action.payload,
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        all: action.payload.all,
        unread: action.payload.unread,
      };

    case HIDE_ALL_NOTIFICATIONS:
      return {
        ...state,
        all: action.payload,
        unread: [],
      };

    case FETCH_NEW_NOTIFICATIONS:
      return {
        ...state,
        unread: action.payload,
      };

    default:
      return state;
  }
};

import {
  IConnection,
  ParserDocumentStatus,
  TConnectionReportType,
} from 'bundles/Shared/entities/connection/model';
import { CssVar } from '@/shared/config/cssVar';
import { IconsId } from 'types/sre-icons';

type ConnectionStatusConfig = Record<
  IConnection['connectionStatus'],
  {
    bgColor: string;
    iconName: IconsId;
    color: CssVar;
  }
>;

export const CONNECTION_STATUS_UI_CONFIG: ConnectionStatusConfig = {
  active: {
    color: CssVar.success055,
    bgColor: 'bg-success-055',
    iconName: 'checkSmall',
  },
  inactive: {
    color: CssVar.attention055,
    bgColor: 'bg-attention-055',
    iconName: 'closeSmall',
  },
  corrupted: {
    color: CssVar.danger055,
    bgColor: 'bg-danger-055',
    iconName: 'closeSmall',
  },
  deactivated: {
    color: CssVar.neutral450,
    bgColor: 'bg-neutral-450',
    iconName: 'closeSmall',
  },
};

type ParserStatusConfig = Record<
  IConnection['parserStatus'],
  {
    bgColor: string;
    iconName: IconsId;
    color: CssVar;
  }
>;

export const PARSER_STATUS_UI_CONFIG: ParserStatusConfig = {
  active: {
    color: CssVar.success055,
    bgColor: 'bg-success-055',
    iconName: 'checkSmall',
  },
  error: {
    color: CssVar.danger055,
    bgColor: 'bg-danger-055',
    iconName: 'closeSmall',
  },
  empty: {
    color: CssVar.neutral450,
    bgColor: 'bg-neutral-450',
    iconName: 'closeSmall',
  },
};

type ParserDocumentStatusConfig = Record<
  ParserDocumentStatus,
  {
    bgColor: string;
    iconName: IconsId;
  }
>;

export const CONNECTION_PARSER_DOCUMENT_STATUS_UI_CONFIG: ParserDocumentStatusConfig =
  {
    processed: {
      bgColor: 'bg-success-055',
      iconName: 'checkSmall',
    },
    error: {
      bgColor: 'bg-danger-055',
      iconName: 'attention',
    },
    processing: {
      bgColor: 'bg-attention-055',
      iconName: 'attention',
    },
    empty: {
      bgColor: 'bg-neutral-450',
      iconName: 'closeSmall',
    },
  };

export type TParserDocumentStatus =
  | 'processed'
  | 'processing'
  | 'error'
  | 'empty';

export const CONNECTION_TYPE_OPTIONS = [
  { label: 'Operational', value: TConnectionReportType.OPERATIONAL },
  { label: 'Financial', value: TConnectionReportType.FINANCIAL },
  {
    label: 'Combined (Operational & Financial)',
    value: TConnectionReportType.COMBINED,
  },
  { label: 'Market Data', value: TConnectionReportType.MARKET_DATA },
  { label: 'CRM', value: TConnectionReportType.CRM },
] as const satisfies readonly { label: string; value: TConnectionReportType }[];

export const REPORT_TYPE_ERP_SYSTEM_OPTIONS = {
  [TConnectionReportType.COMBINED]: {
    erpSystem: [],
  },
  [TConnectionReportType.OPERATIONAL]: {
    erpSystem: [],
  },
  [TConnectionReportType.FINANCIAL]: {
    erpSystem: [],
  },
  [TConnectionReportType.MARKET_DATA]: {
    erpSystem: [],
  },
} as const satisfies Record<
  TConnectionReportType,
  {
    erpSystem: [];
  }
>;

export const ETL_MAIL_DOMAIN = 'etl2go.com';

import { useMatch } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Button, Icon, LinkButton } from 'stories';
import UserStatistics from '../components/UserStatistics';
import { changePagePosition } from '../reducers/userRolesSlice';

export function UserManagementHeader() {
  const dispatch = useAppDispatch();
  const isActivityLogBtnShown = useMatch('/user-management/roles');
  const pagePosition = useAppSelector((state) => state.userRoles.position);
  const [showStatisctics, setShowStatisctics] = useState(true);
  const styles = useSpring({
    opacity: showStatisctics ? 1 : 0,
    pointerEvents: 'none' as const,
    marginBottom: showStatisctics ? 0 : -100,
    paddingBottom: showStatisctics ? 16 : 0,
  });

  const isActivityLogBtnDisabled =
    pagePosition === 'all-user-roles-actvityLog' || pagePosition === 'loading';

  return (
    <>
      <div className="mb-m flex items-center justify-between">
        <h1 className="header3-bold">User Management</h1>
        <div className="ml-auto flex items-center justify-end gap-tw-1">
          <Icon iconName={showStatisctics ? 'eyeSlash' : 'eye'} />
          <LinkButton
            className="secondary-regular mr-[1.3rem]"
            onClick={() => setShowStatisctics(!showStatisctics)}
          >
            {showStatisctics ? 'HIDE STATS' : 'SEE STATS'}
          </LinkButton>
        </div>
        {isActivityLogBtnShown && (
          <Button
            iconName="reload"
            iconClasses="light-60"
            variant="secondary"
            disabled={isActivityLogBtnDisabled}
            onClick={() =>
              dispatch(changePagePosition('all-user-roles-actvityLog'))
            }
          >
            Activity Log
          </Button>
        )}
      </div>

      <div className="overflow-hidden">
        <animated.div style={styles}>
          <UserStatistics />
        </animated.div>
      </div>
    </>
  );
}

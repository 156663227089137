import { useParams } from '@reach/router';
import { useGetInvoiceDocumentQuery } from 'bundles/Construction/api/core';
import prettyBytes from 'pretty-bytes';
import React, { FC } from 'react';
import { Icon } from 'stories';
import InvoiceCreateBlock from '../../InvoiceCreateBlock';

const InvoiceDetails: FC<IInvoiceDetailsProps> = () => {
  const params = useParams();

  const { data: invoiceDocument } = useGetInvoiceDocumentQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });
  return (
    <div className="flex flex-col gap-tw-6 pt-tw-6">
      <div className="flex flex-col gap-tw-2 px-tw-6">
        <div className="body-semibold text-dark-60">Invoice</div>
        <div className="flex flex-[1] gap-[10px] rounded-2xl border border-solid border-light-10 p-tw-2">
          <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-light-5">
            <Icon className="text-2xl" color="red" iconName="docPdf" />
          </div>
          <div>
            <div className="dark-60 inline-semibold">
              {invoiceDocument?.title}
            </div>
            <div className="light-60 secondary-regular flex gap-tw-1">
              <span>{prettyBytes(invoiceDocument?.size || 0)}</span>
              <span>•</span>
              <span>{invoiceDocument?.extension}</span>
            </div>
          </div>
        </div>
        <div className="secondary-regular flex gap-tw-1 rounded-lg bg-light p-tw-2">
          <span>Uploaded by</span>
          <span className="text-light-90">
            {invoiceDocument?.source?.email || invoiceDocument?.source?.name}
          </span>
          <span>on</span>
          <span className="text-light-90">{invoiceDocument?.uploadedAt}</span>
        </div>
      </div>
      <div className="flex flex-col gap-tw-4 px-tw-6">
        <div className="body-semibold text-dark-60">Add Invoice Details</div>
        <div>
          <InvoiceCreateBlock />
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;

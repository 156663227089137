import * as React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { IconButton } from 'stories/index';
import useHeaderSorting from 'lib/ag-grid/useHeaderSorting';

interface HeaderComponentParams {
  subHeaderName?: string;
}

function HeaderComponent(props: IHeaderParams & HeaderComponentParams) {
  const { onSortRequested, activeSort } = useHeaderSorting(props);

  const getSortIcon = () => {
    if (activeSort === 'asc') {
      return 'sortDesc';
    }
    if (activeSort === 'desc') {
      return 'sortAsc';
    }
    return 'sortOff';
  };

  const sort = props.enableSorting ? (
    <div>
      <IconButton
        onClick={onSortRequested}
        onTouchEnd={onSortRequested}
        variant="primary"
        iconName={getSortIcon()}
      />
    </div>
  ) : null;

  return (
    <div className="flex w-full items-center justify-between">
      <div className="mr-xs flex flex-col">
        <span className="text-wrap">{props.displayName}</span>
        {props.subHeaderName ? (
          <span className="primary-light-5">{props.subHeaderName}</span>
        ) : null}
      </div>
      {sort}
    </div>
  );
}

export default HeaderComponent;

type HighlighedTextProps = {
  text: string;
  beforeStr: string;
  middleStr: string;
  afterStr: string;
  highlightSearchResults: boolean;
};

export function HighlightedText({
  text,
  afterStr,
  beforeStr,
  middleStr,
  highlightSearchResults,
}: HighlighedTextProps) {
  return (
    <>
      {highlightSearchResults ? (
        <>
          <span className="text-ellipsis">
            {beforeStr}
            <span className="rounded bg-info-055 text-white">{middleStr}</span>
            {afterStr}
          </span>
        </>
      ) : (
        <>{text}</>
      )}
    </>
  );
}

HighlightedText.getProps = ({
  query,
  text,
}: {
  query: string;
  text: string;
}): HighlighedTextProps => {
  const _query = query.trim().toLowerCase();
  const index = text.trim().toLowerCase().indexOf(_query);
  const highlightSearchResults = index > -1;
  const beforeStr = text.substring(0, index);
  const middleStr = text.substring(index, index + _query.length);
  const afterStr = text.slice(index + _query.length);
  return {
    text,
    beforeStr,
    middleStr,
    afterStr,
    highlightSearchResults,
  };
};

import { IGetTransactionsRequest } from 'bundles/REconcile/components/development/api/transactions';
import { DateRange } from 'bundles/REport/components/financials/filter/monthRangePicker/MonthsRangePicker';
import { TFilterModel } from 'bundles/REconcile/components/development/requisitions/modals/createTransactionsFromSource/useColumns';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export const mapPageParamsToDateRange = (
  pageParams: Omit<IGetTransactionsRequest, 'category'>,
) => ({
  from: pageParams.date_range?.from
    ? formatToDateStringForRequest(pageParams.date_range?.from)
    : undefined,
  to: pageParams.date_range?.to
    ? formatToDateStringForRequest(pageParams.date_range?.to)
    : undefined,
});

export const mapDateRangeToPageParams = (
  dateRange: DateRange,
): Pick<IGetTransactionsRequest, 'date_range'> => {
  if (dateRange.from === undefined && dateRange.to === undefined) {
    return { date_range: undefined };
  }

  return {
    date_range: {
      from:
        dateRange.from &&
        formatToDateStringForRequest(dateRange.from.startOf('M')),
      to: dateRange.to && formatToDateStringForRequest(dateRange.to.endOf('M')),
    },
  };
};

export const mapFilterModelToPageParams = (
  filterModel: TFilterModel,
): Omit<IGetTransactionsRequest, 'category'> => ({
  amount:
    filterModel.transaction_value?.from || filterModel.transaction_value?.to
      ? {
          from: filterModel.transaction_value.from
            ? Number(filterModel.transaction_value.from)
            : undefined,
          to: filterModel.transaction_value.to
            ? Number(filterModel.transaction_value.to)
            : undefined,
        }
      : undefined,
  transaction_type: filterModel.transaction_type,
  vendor_ids: filterModel.vendor_entity_name?.map((vendor) => vendor.id),
});

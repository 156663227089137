import { reportManualVariableTagsApi } from '@/entities/report/manualVariable/api/settingsReportManualVariableTagsGeneratedApi';

export const reportManualVariablesEnhancedApi =
  reportManualVariableTagsApi.enhanceEndpoints({
    addTagTypes: [
      'ManualVariables',
      'ManualVariableLegalEntities',
      'ManualVariableLegalEntityValues',
      'ManualVariableTags',
    ],
    endpoints: {
      getApiSettingsReportManualVariableTags: {
        providesTags: ['ManualVariableTags'],
      },
      postApiSettingsReportManualVariableTagsSync: {
        invalidatesTags: ['ManualVariableTags', 'ManualVariables'],
      },
      deleteApiSettingsReportManualVariableTagsById: {
        invalidatesTags: ['ManualVariableTags', 'ManualVariables'],
        transformResponse: (response) => {
          toastr.success('Successfully deleted');
          return response;
        },
      },
      postApiSettingsReportManualVariableTags: {
        invalidatesTags: ['ManualVariableTags'],
        transformResponse: (response) => {
          toastr.success('Successfully created');
          return response;
        },
      },
      putApiSettingsReportManualVariableTagsById: {
        invalidatesTags: ['ManualVariableTags', 'ManualVariables'],
        transformResponse: (response) => {
          toastr.success('Successfully updated');
          return response;
        },
      },
      getApiReportManualVariables: {
        providesTags: ['ManualVariables'],
      },
      postApiReportManualVariables: {
        invalidatesTags: ['ManualVariables'],
        transformResponse: (response) => {
          toastr.success('Successfully created');
          return response;
        },
      },
      putApiReportManualVariablesById: {
        invalidatesTags: [
          'ManualVariables',
          'ManualVariableLegalEntities',
          'ManualVariableLegalEntityValues',
        ],
        transformResponse: (response) => {
          toastr.success('Successfully updated');
          return response;
        },
      },
      deleteApiReportManualVariablesById: {
        invalidatesTags: ['ManualVariables'],
        transformResponse: (response) => {
          toastr.success('Successfully deleted');
          return response;
        },
      },
      getApiReportManualVariablesByManualVariableIdLegalEntities: {
        providesTags: ['ManualVariableLegalEntities'],
      },
      getApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValues:
        {
          providesTags: ['ManualVariableLegalEntityValues'],
        },

      deleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesId:
        {
          invalidatesTags: [
            'ManualVariables',
            'ManualVariableLegalEntityValues',
            'ManualVariableLegalEntities',
          ],
          transformResponse: (response) => {
            toastr.success('Successfully deleted');
            return response;
          },
          transformErrorResponse(res) {
            toastr.error('Something went wrong');
            return res;
          },
        },

      postApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValues:
        {
          invalidatesTags: [
            'ManualVariables',
            'ManualVariableLegalEntityValues',
            'ManualVariableLegalEntities',
          ],
          transformResponse: (response) => {
            toastr.success('Successfully created');
            return response;
          },
          transformErrorResponse(res) {
            toastr.error('Something went wrong');
            return res;
          },
        },

      putApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesId:
        {
          invalidatesTags: [
            'ManualVariables',
            'ManualVariableLegalEntityValues',
            'ManualVariableLegalEntities',
          ],
          transformResponse: (response) => {
            toastr.success('Successfully updated');
            return response;
          },
          onQueryStarted(patch, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              reportManualVariablesEnhancedApi.util.updateQueryData(
                'getApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValues',
                {
                  legalEntityId: patch.legalEntityId,
                  manualVariableId: patch.manualVariableId,
                },
                (draft) => {
                  Object.assign(draft, {
                    items: draft.items.map((item) => {
                      if (item.id === patch.id) {
                        return {
                          id: item.id,
                          valueType: item.valueType,
                          dateTo: patch.body.date_to,
                          dateFrom: patch.body.date_from,
                          value: patch.body.value,
                        };
                      }
                      return item;
                    }),
                  });
                },
              ),
            );
            queryFulfilled.catch(patchResult.undo);
          },
        },
    },
  });

export const {
  useGetApiReportManualVariablesQuery,
  useDeleteApiSettingsReportManualVariableTagsByIdMutation,
  usePutApiSettingsReportManualVariableTagsByIdMutation,
  usePostApiSettingsReportManualVariableTagsMutation,
  useDeleteApiReportManualVariablesByIdMutation,
  usePostApiReportManualVariablesMutation,
  usePutApiReportManualVariablesByIdMutation,
  useGetApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesQuery,
  useGetApiReportManualVariablesByManualVariableIdLegalEntitiesQuery,
  useDeleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdMutation,
  usePostApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesMutation,
  usePutApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdMutation,
  useGetApiSettingsReportManualVariableTagsQuery,
  usePostApiSettingsReportManualVariableTagsSyncMutation,
} = reportManualVariablesEnhancedApi;

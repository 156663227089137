import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import { SelectedEntityTags } from '@/bundles/Settings/components/REport/Operational/ui/SelectedEntityTags';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import {
  SourceUnitType,
  SreUnitType,
} from '@/bundles/Shared/entities/sreUnitType';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { asserts } from '@/lib/typeHelpers/assertsType';
import {
  Button,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
  SearchInput,
} from '@/stories';
import { useState } from 'react';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { SectionField } from 'stories/Field/FieldsWrappers';
import fuzzysort from 'fuzzysort';

export function BulkSetUnitTypeModal({
  sourceUnitTypes,
  onClose,
  onSubmit,
  unitTypes,
}: {
  unitTypes: SreUnitType[];
  sourceUnitTypes: SourceUnitType[];
} & DialogProps<SreUnitType>) {
  const [checkedUnitType, setCheckedUnitType] = useState<SreUnitType | null>(
    null,
  );

  const { filteredItems: filteredUnitTypes, inputProps } = useItemsFilterByText(
    unitTypes,
    (item, searchText) =>
      fuzzysort.go(searchText, [item.label, item.name]).length > 0,
  );

  const handleSubmit = () => {
    asserts(checkedUnitType != null);
    onSubmit?.(checkedUnitType);
  };

  return (
    <Modal
      header={<ModalHeaderWithSubtitle title="Set Unit Types" />}
      size="400"
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={checkedUnitType == null}
          >
            Set Unit Status
          </Button>
        </ModalActions>
      }
      classes={{
        body: 'gap-tw-4 flex flex-col',
      }}
      toggle={onClose}
    >
      <SelectedEntityTags
        items={sourceUnitTypes}
        itemName={SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}
      />
      <SearchInput placeholder="Unit Type" {...inputProps} />
      <SectionField className="overflow-y-auto" labelText="sRE Unit Types">
        <RadioGroup
          options={mapItemsToListOption(filteredUnitTypes, 'label')}
          value={checkedUnitType?.id}
          onChange={(o) =>
            setCheckedUnitType(
              unitTypes.find(
                (unitType) => unitType.id === o.value,
              ) as SreUnitType,
            )
          }
        />
        {filteredUnitTypes.length === 0 && <NoDataOverlay title="Not Found" />}
      </SectionField>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import http from 'lib/http';
import EmailTemplateCard from '../EmailTemplateCard';

const ForgotPasswordTemplatesTab = () => {
  const [templates, setTemplates] = useState([]);

  const fetchForgotPasswordTemplates = () =>
    http.get('/forgot_password_templates').then((res) => res.json());

  useEffect(() => {
    fetchForgotPasswordTemplates().then((data) => setTemplates(data));
  }, []);

  const defaultTemplate = templates.find((it) => it.default);

  // TODO: create shared component for default template
  return (
    <div>
      <h5 className="light-60 font-weight-500 mt-[1.5rem] mb-tw-4 header5-regular">
        Default Template
      </h5>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-30">
          {defaultTemplate && (
            <EmailTemplateCard
              isDefault
              id={defaultTemplate.id}
              name={defaultTemplate.name}
              editPath={`./forgot-password/${defaultTemplate.id}/edit`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordTemplatesTab;

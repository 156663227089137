import React from 'react';
import Badge from 'stories/Badges/Badge/Badge';
import { CssVar } from '@/shared/config/cssVar';
import Icon from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  value: React.ReactNode;
  className?: string;
}

function YieldBadge({ value, className }: Props) {
  return (
    <div
      className={cn(
        'flex rounded-[0.5rem] items-center border border-light-10 bg-white',
        className,
      )}
    >
      <span className="light-80 secondary-regular bg-white px-s">Yield</span>
      <div className="flex" style={{ padding: '1.5px 2px 1.5px 0' }}>
        <Badge
          backgroundColor={CssVar.greenLight5}
          classes={{
            value: 'py-0',
          }}
        >
          <div className="flex green-dark-2 items-center gap-xs">
            <Icon iconName="profitAlt" />
            <span className="inline-semibold">{value}</span>
          </div>
        </Badge>
      </div>
    </div>
  );
}

export default YieldBadge;

import React, { FC, ReactNode } from 'react';

interface IKanbanColumnProps {
  children: ReactNode;
  headerColumn?: ReactNode;
  badge?: ReactNode;
}

const KanbanColumn: FC<IKanbanColumnProps> = ({
  headerColumn,
  badge,
  children,
}) => (
  <div className="h-fit w-[25%] rounded-2xl bg-light-20 p-tw-1.5">
    {headerColumn}
    {badge}
    {children}
  </div>
);

export default KanbanColumn;

import React, { useRef } from 'react';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';

import { useAmchart } from 'lib/amcharts/useAmchart';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';

interface Props {
  data: any;
  name: string;
}

function RadarChartDealPathSettings({ data, name }: Props) {
  const ref = useRef(null);
  useAmchart(
    ref,
    (root) => {
      const chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
        }),
      );

      root.setThemes([am5themesAnimated.new(root)]);

      const xRenderer = am5radar.AxisRendererCircular.new(root, {});
      xRenderer.labels.template.setAll({
        radius: 10,
        textType: 'circular',
      });

      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: 'stage',
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        }),
      );

      xAxis.data.setAll(data);

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5radar.AxisRendererRadial.new(root, {}),
          numberFormatter: am5.NumberFormatter.new(root, {
            numberFormat: "#.#'%'",
          }),
        }),
      );

      const tooltip = am5.Tooltip.new(root, {
        labelText: '{categoryX}\nUtilization: {utilization}',
        getFillFromSprite: false,
      });

      const series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          name: 'Target',
          xAxis,
          yAxis,
          valueYField: 'utilization',
          categoryXField: 'stage',
          tooltip,
          fill: am5.color('rgba(19,171,132,0.40)'),
        }),
      );

      series.columns.template.setAll({
        fillOpacity: 0.4,
      });

      series.data.setAll(data);
    },
    [data],
  );

  return (
    <div>
      {data && data.length > 0 && (
        <div ref={ref} style={{ width: '100%', height: '400px' }} />
      )}
    </div>
  );
}

export default RadarChartDealPathSettings;

import { cn } from '@/shared/lib/css/cn';
import { LinkButton } from 'stories/index';
import { SIDE_TAB_ITEM_ACTIVE_CSS_CLASS } from '../consts';
import { SideTabKey } from '../types';

export function SideTabItem({
  tabKey,
  title,
  currentSideTab,
  onSideTabChange,
  counter,
}: {
  title: string;
  tabKey: SideTabKey;
  currentSideTab: SideTabKey;
  onSideTabChange: (sideTab: SideTabKey) => void;
  counter: number;
}) {
  return (
    <li
      className={cn('border-b border-solid border-light-10 last:border-none', {
        [SIDE_TAB_ITEM_ACTIVE_CSS_CLASS]: tabKey === currentSideTab,
      })}
    >
      <LinkButton
        onClick={() => onSideTabChange(tabKey)}
        className="block h-[100px] w-full gap-tw-2 text-light-60"
      >
        <div>{counter}</div>
        {title}
      </LinkButton>
    </li>
  );
}

// todo: DRY FundAccess
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ObjectAccessForm from './ObjectAccessForm';
import { userRoleIsManager } from 'lib/permissions';
import LoadingPanel from '../../../Shared/components/LoadingPanel';
import { ASSET_ACCESS_DICT } from './dictionary';

const AssetAccess = ({
  fields, setValue, assets, funds,
}) => {
  const [filteredAssets, setFilteredAssets] = useState(assets);
  const onSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setFilteredAssets(assets.filter((asset) => asset.name.toLowerCase().includes(query)));
  };

  useEffect(() => {
    setFilteredAssets(assets);
  }, [assets]);

  if (!filteredAssets || !funds) return <LoadingPanel />;

  const selectedFunds = fields.canViewAllFunds || fields.canManageAllFunds
    ? funds
    : funds.filter((fund) => (
      fields.canViewFundIds.includes(fund.id) || fields.canManageFundIds.includes(fund.id)
    ));
  const selectedByFund = (id) => (
    [...new Set(selectedFunds.map((fund) => fund.assetIds))].flat().includes(id)
  );

  let inputState;
  if (fields.userRole.name === 'Super Admin') {
    // NOTE: Force checked for Super Admins. Should we hide those tabs completely?
    inputState = () => ({
      disabled: true,
      disableLabel: null,
      checked: true,
      changeHandler: undefined,
    });
  } else {
    inputState = (rule, key) => {
      const result = {
        disabled: false,
        disableLabel: null,
        checked: undefined,
        changeHandler: undefined,
      };

      if (key === 'all') {
        if (rule === 'View') {
          result.disabled = fields.canManageAll;
          result.checked = fields.canManageAll || fields.canViewAll;
          result.changeHandler = (e) => setValue('canViewAll', e.target.checked);
        } else if (rule === 'Manage') {
          result.checked = fields.canManageAll;
          result.changeHandler = (e) => setValue('canManageAll', e.target.checked);
        }

        return result;
      }

      if (rule === 'View') {
        const forced = fields.canManageAll || fields.canViewAll
          || fields.canManageAssetIds.includes(key) || selectedByFund(key);

        result.disabled = forced;
        result.checked = forced || fields.canViewAssetIds.includes(key);
        result.changeHandler = (e) => setValue(
          'canViewAssetIds',
          e.target.checked
            ? [...fields.canViewAssetIds, key]
            : fields.canViewAssetIds.filter((f) => f !== key),
        );
        if (selectedByFund(key)) result.disableLabel = ASSET_ACCESS_DICT.AutoSelectedFromFund;
      } else if (rule === 'Manage') {
        result.disabled = fields.canManageAll;
        result.checked = fields.canManageAll || fields.canManageAssetIds.includes(key);
        result.changeHandler = (e) => setValue(
          'canManageAssetIds',
          e.target.checked
            ? [...fields.canManageAssetIds, key]
            : fields.canManageAssetIds.filter((f) => f !== key),
        );
      }
      return result;
    };
  }

  return (
    <ObjectAccessForm
      title="All Assets"
      rules={userRoleIsManager(fields.userRole) ? ['View', 'Manage'] : ['View']}
      objects={filteredAssets}
      objectName="Asset"
      searchHandler={onSearch}
      inputState={inputState}
    />
  );
};

AssetAccess.propTypes = {
  fields: PropTypes.shape().isRequired,
  setValue: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  funds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default AssetAccess;

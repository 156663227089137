import HistoryDetailsSwitcher from 'bundles/Shared/components/HistoryDetails/HistoryDetailsSwitcher';
import { FC } from 'react';

interface IHistoryDetailsBodyProps {
  fields: {
    fieldName: string;
    changes: {
      current: object | string | number;
      old: object | string | number;
    };
  }[];
  historyData: {
    title: string;
    data: {
      titleView: string;
      fieldName: string;
      typeField: string;
    }[];
  }[];
}

const HistoryDetailsBody: FC<IHistoryDetailsBodyProps> = ({
  fields,
  historyData,
}) => {
  return (
    <div>
      {historyData.map((historyItem) => (
        <div>
          <div className="dark-60 header6-regular mb-s">
            {historyItem.title}
          </div>
          <div className="rounded-[8px] mb-m bg-white">
            {historyItem.data.map((changeItem) => (
              <HistoryDetailsSwitcher
                {...changeItem}
                title={changeItem.titleView}
                changes={
                  fields.find((item) => item.fieldName === changeItem.fieldName)
                    ?.changes ?? {
                    current: false,
                    old: false,
                  }
                }
                transformerView={changeItem.transformerView}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HistoryDetailsBody;

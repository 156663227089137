import React, { useEffect, useMemo, useState } from 'react';
import http from 'lib/http';
import { AnimationLoader, Button, ColorTag, Tag } from 'stories';
import pluralize from 'pluralize';
import { Link } from '@reach/router';
import { removeCustomTemplate } from 'bundles/Settings/actions/customTemplates';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { RRule } from 'rrule';
import EmailTemplateCard from '../EmailTemplateCard';

const CustomTemplatesTab = ({ children }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchTemplates = () =>
    http.get('/custom_templates').then((res) => res.json());

  useEffect(() => {
    fetchTemplates().then((json) => {
      setTemplates(json);
      setLoading(false);
    });
  }, []);

  const handleDelete = (id) => {
    removeCustomTemplate({ id }).then((templateJson) => {
      if (templateJson.errors === undefined) {
        setTemplates((currentTemplates) =>
          currentTemplates.filter((t) => t.id !== id),
        );
      }
    });
  };

  const handleCardToggle = async ({ id, isActive }) => {
    const payload = { template_attributes: { active: !isActive } };
    const res = await http.put(`/custom_templates/${id}`, payload);
    const responseData = await res.json();
    return responseData;
  };

  const getFrequency = (schedule) => RRule.fromString(schedule)?.options?.freq;
  const filterByFrequency = (items, freq) =>
    items.filter((template) => {
      const templateFrequency = getFrequency(template.schedule);
      return templateFrequency === freq;
    });

  const frequencyTabs = useMemo(
    () => [
      { label: 'All', color: 'blue', visible: true },
      {
        label: 'Daily',
        color: 'green',
        visible: filterByFrequency(templates, RRule.DAILY).length > 0,
      },
      {
        label: 'Weekly',
        color: 'violet',
        visible: filterByFrequency(templates, RRule.WEEKLY).length > 0,
      },
      {
        label: 'Monthly',
        color: 'yellow',
        visible: filterByFrequency(templates, RRule.MONTHLY).length > 0,
      },
      {
        label: 'Annualy',
        color: 'pumpkin',
        visible: filterByFrequency(templates, RRule.YEARLY).length > 0,
      },
    ],
    [templates],
  );

  const [currentFrequencyTab, setCurrentFrequencyTab] = useState(
    frequencyTabs[0].label,
  );
  const [currentTemplates, setCurrentTemplates] = useState(templates);

  useEffect(() => {
    const filteredTemplates = templates.filter((template) => {
      const frequency = RRule.fromString(template?.schedule)?.options?.freq;

      if (currentFrequencyTab === 'All') return true;
      if (currentFrequencyTab === 'Daily' && frequency === RRule.DAILY)
        return true;
      if (currentFrequencyTab === 'Weekly' && frequency === RRule.WEEKLY)
        return true;
      if (currentFrequencyTab === 'Monthly' && frequency === RRule.MONTHLY)
        return true;
      if (currentFrequencyTab === 'Annualy' && frequency === RRule.YEARLY)
        return true;
    });
    setCurrentTemplates(filteredTemplates);
  }, [currentFrequencyTab, templates]);

  if (loading) return <AnimationLoader className="static min-h-[360px]" />;

  return (
    <div>
      {templates.length === 0 && (
        <div className="flex items-center flex-col">
          <NoDataOverlay
            title="No Templates"
            subTitle="Here you can create additional custom templates"
            className="pb-l"
          />
          <Link to="/settings/emails/templates/custom/new">
            <Button size="m" variant="primary">
              New Custom Template
            </Button>
          </Link>
        </div>
      )}
      {templates.length > 0 && (
        <>
          <div className="flex justify-between items-center mb-s mt-m">
            <h6 className="light-90 header6-regular">
              {templates.length} {pluralize('Template', templates.length)}
            </h6>
            <Link to="/settings/emails/templates/custom/new">
              <Button size="m" variant="primary">
                <span className="font-weight-600 light line-height-21px inline-semibold">
                  New Template
                </span>
              </Button>
            </Link>
          </div>
          <div className="flex mb-l gap-s">
            {frequencyTabs.map((s) =>
              s.label === 'All' ? (
                <Tag
                  key={s.label}
                  label={s.label}
                  selected={currentFrequencyTab === s.label}
                  variant={s.color}
                  onClick={() => setCurrentFrequencyTab(s.label)}
                  disabled={!s.visible}
                />
              ) : (
                <ColorTag
                  key={s.label}
                  label={s.label}
                  selected={currentFrequencyTab === s.label}
                  variant={s.color}
                  onClick={() => setCurrentFrequencyTab(s.label)}
                  disabled={!s.visible}
                />
              ),
            )}
          </div>
          <div className="row">
            {currentTemplates.map((template) => (
              <div
                key={template.id}
                className="col-xs-12 col-sm-6 col-md-6 col-lg-3 pb-30"
              >
                <EmailTemplateCard
                  id={template.id}
                  name={template.name}
                  editPath={`./custom/${template.id}/edit`}
                  onDelete={handleDelete}
                  onToggle={handleCardToggle}
                  roles={template.userRoles}
                  users={template.users}
                  withoutStatusToggle
                  schedule={template.schedule}
                />
              </div>
            ))}
          </div>
          {children}
        </>
      )}
    </div>
  );
};

export default CustomTemplatesTab;

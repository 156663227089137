import { cn } from '@/shared/lib/css/cn';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-symmetre';
import { LinkButton } from 'stories';

const colors = [
  {
    color: '#000',
    label: 'Black',
  },
  {
    color: '#4d4d4d',
    label: 'Dim grey',
  },
  {
    color: '#999',
    label: 'Grey',
  },
  {
    color: '#e6e6e6',
    label: 'Light grey',
  },
  {
    color: '#fff',
    label: 'White',
    hasBorder: true,
  },
  {
    color: '#e64c4c',
    label: 'Red',
  },
  {
    color: '#e6994d',
    label: 'Orange',
  },
  {
    color: '#e6e64b',
    label: 'Yellow',
  },
  {
    color: '#99e64c',
    label: 'Light green',
  },
  {
    color: '#4ce64c',
    label: 'Green',
  },
  {
    color: '#4de698',
    label: 'Aquamarine',
  },
  {
    color: '#4ce6e6',
    label: 'Turquoise',
  },
  {
    color: '#4c99e5',
    label: 'Light blue',
  },
  {
    color: '#4c4be6',
    label: 'Blue',
  },
  {
    color: '#994ce6',
    label: 'Purple',
  },
];

const editorConfiguration = {
  toolbar: {
    items: [
      'bold',
      'italic',
      'underline',
      'heading',
      'fontBackgroundColor',
      'fontColor',
      'fontSize',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'link',
      'outdent',
      'indent',
      'alignment',
      '|',
      'horizontalLine',
      'blockQuote',
      'insertTable',
      '|',
      'undo',
      'redo',
    ],
    shouldNotGroupWhenFull: true,
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
    ],
  },
  fontSize: {
    options: [14, 16, 18, 20, 22, 24],
  },
  alignment: {
    options: ['left', 'center', 'right'],
  },
  fontColor: { colors },
  fontBackgroundColor: { colors },
  language: 'en',
  link: {
    addTargetToExternalLinks: true,
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableCellProperties',
      'tableProperties',
    ],
  },
};

type Editor = {
  getData: () => string;
};

const TextEditor = ({
  text,
  setText,
  copyFrom,
  config,
  onFocus,
  onChangeStart,
  onChangeEnd,
  onReady,
  className,
}: {
  text: string;
  setText: (text: string) => void;
  copyFrom?: string;
  config?: object;
  onFocus?: () => void;
  onChangeStart?: (editor: Editor) => void;
  onChangeEnd?: (data: string) => void;
  onReady?: () => void;
  className?: string;
}) => {
  return (
    <div className={cn(className, 'ck-editor-text-editor')}>
      <CKEditor
        data={text}
        editor={Editor}
        config={config ?? editorConfiguration}
        onChange={(_: unknown, editor: Editor) => {
          if (onChangeStart) onChangeStart(editor);
          const data = editor.getData();

          setText(data);
          if (onChangeEnd) onChangeEnd(data);
        }}
        onFocus={
          onFocus ??
          (() => {
            document.documentElement.style.cssText =
              '--ck-color-base-border: #0092EE !important';
          })
        }
        onBlur={() => {
          document.documentElement.style.cssText =
            '--ck-color-base-border: #C7CED5 !important';
        }}
        onReady={onReady}
      />
      {copyFrom && copyFrom !== '<p></p>' && (
        <div className="text-right">
          <LinkButton
            onClick={() => {
              setText(copyFrom);
            }}
          >
            Copy from feed message
          </LinkButton>
        </div>
      )}
    </div>
  );
};

export default TextEditor;

import React from 'react';
import { ImageCard } from 'stories';

type Item = {
  id: number;
  smallImageUrl: string;
  videoUrl: string;
  description: string;
};

const MediaList = ({
  items,
  toggleSelectItem,
  canManage,
  checkboxDisabled,
  openMoveModal,
  openEditModal,
  removeSelectedSharedFiles,
  openLightbox,
  selectedMedia,
  actionsVisabilityMode,
  canSelect = false,
}: {
  items: Item[];
  toggleSelectItem: (item: Item) => void;
  canManage: boolean;
  checkboxDisabled: boolean;
  openMoveModal: (item: Item, isSingle: boolean) => void;
  openEditModal: (item: Item, isSingle: boolean) => void;
  removeSelectedSharedFiles: (ids: number[]) => void;
  openLightbox: (id: number) => void;
  selectedMedia: Item[];
  canSelect?: boolean;
  actionsVisabilityMode?: 'hover' | 'always';
}) => (
  <div className="grid h-full gap-tw-6 md:grid-cols-4 4xl:grid-cols-6">
    {items.map((m) => (
      <div key={m.id}>
        <ImageCard
          key={m.id}
          image={m.smallImageUrl}
          video={m.videoUrl}
          subtitle={m.description}
          onSelect={
            canSelect &&
            (() => {
              toggleSelectItem(m);
            })
          }
          checkboxDisabled={checkboxDisabled}
          actions={
            canManage
              ? [
                  {
                    icon: 'transferFile',
                    onClick: () => openMoveModal(m, true),
                  },
                  {
                    icon: 'edit',
                    onClick: () => openEditModal(m, true),
                  },
                  {
                    icon: 'trash',
                    onClick: () => removeSelectedSharedFiles([m.id]),
                  },
                ]
              : []
          }
          handleClick={!m.videoUrl ? () => openLightbox(m.id) : () => {}}
          selected={Boolean(selectedMedia.find((s) => s.id === m.id))}
          actionsVisabilityMode={actionsVisabilityMode}
        />
      </div>
    ))}
  </div>
);

export default MediaList;

export default async function processResponse(res, toastrTitle, toastrSuccessMessage) {
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success(toastrSuccessMessage);
  }

  return responseData;
}

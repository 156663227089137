import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { CssVar } from '@/shared/config/cssVar';

export interface ISharedSelect extends StateManagerProps {
  disabled?: boolean;
  newGen?: boolean;
}

const NEW_STYLE = {
  minHeight: 32,
  height: 32,
  backgroundColor: 'white',
  borderColor: CssVar.neutral200,
  borderWidth: 2,
  borderRadius: 8,
  fontSize: 14,
};

export const SharedCreatableSelect = ({
  disabled,
  newGen,
  ...props
}: ISharedSelect) => (
  <CreatableSelect
    classNamePrefix={newGen ? 'new-gen-react-select' : 'react-select'}
    isDisabled={disabled}
    menuPlacement="bottom"
    menuPosition="fixed"
    styles={{
      control: (baseStyles) => ({
        ...baseStyles,
        ...(newGen ? NEW_STYLE : {}),
      }),
    }}
    {...props}
  />
);

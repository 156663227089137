import React from 'react';
import { ClassNameProps } from 'types/Props';
import { cn } from '@/shared/lib/css/cn';
import styles from './SkeletonBlock.module.scss';

type Props = ClassNameProps;

function SkeletonBlock({ className }: Props) {
  return <div className={cn(styles.block, 'rounded-lg', className)} />;
}

export default SkeletonBlock;

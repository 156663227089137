import { useDashboardEagleEyeItems } from '@/bundles/Shared/features/dashboard/create/lib';
import { LegalEntitiesSelectionModal } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesSelectionModal';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { xor } from 'lodash-es';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  useController,
  useWatch,
} from 'react-hook-form';

export function LegalEntitiesField<
  _FieldValues extends FieldValues = FieldValues,
  AssetsName extends FieldPath<_FieldValues> = FieldPath<_FieldValues>,
  LegalEntitiesName extends FieldPath<_FieldValues> = FieldPath<_FieldValues>,
>({
  control,
  assetsName,
  legalEntitiesName,
}: {
  assetsName: AssetsName;
  legalEntitiesName: LegalEntitiesName;
  control: Control<_FieldValues>;
}) {
  const {
    value: legalEntitiesModalOpened,
    toggle: toggleLegalEntitiesModalOpened,
  } = useBoolean();
  const { field: assetIdsField } = useController({
    control,
    name: assetsName,
  });

  const assetsReference = useWatch({
    control,
    name: assetsName,
  });
  const legalEntitiesReference = useWatch({
    control,
    name: legalEntitiesName,
  });
  const selectedAssetIds = assetsReference;
  const excludedLegalEntityIds = legalEntitiesReference;

  const { allLegalEntities, includedLegalEntities, selectedAssetsWithLEs } =
    useDashboardEagleEyeItems({
      selectedAssetIds,
      excludedLegalEntityIds,
    });

  return (
    <Controller
      control={control}
      name={legalEntitiesName}
      render={({ field }) => {
        const legalEntitiesCounter = includedLegalEntities.length;
        return (
          <LegalEntitiesSelectionModal.Wrapper
            legalEntitiesCounter={legalEntitiesCounter}
            toggleLegalEntitiesModalOpened={toggleLegalEntitiesModalOpened}
          >
            {legalEntitiesModalOpened && (
              <LegalEntitiesSelectionModal
                onChangeAll={(type) => {
                  field.onChange(
                    type === 'excluded' ? [] : mapListToIds(allLegalEntities),
                  );
                }}
                onIncludeAsset={(assetId, assetExcludedLegalEntityIds) => {
                  if (selectedAssetIds.includes(assetId)) {
                    field.onChange(
                      xor(assetExcludedLegalEntityIds, excludedLegalEntityIds),
                    );
                    return;
                  }
                  assetIdsField.onChange([
                    ...(selectedAssetIds ?? []),
                    assetId,
                  ]);
                }}
                onIncludeLegalEntity={(legalEntityId) => {
                  field.onChange(
                    excludedLegalEntityIds?.filter(
                      (id: string) => id !== legalEntityId,
                    ),
                  );
                }}
                onExcludeAsset={(assetId) => {
                  assetIdsField.onChange(
                    selectedAssetIds?.filter((id: number) => id !== assetId),
                  );
                }}
                onExcludeLegalEntity={(legalEntityId) => {
                  field.onChange([
                    ...(excludedLegalEntityIds ?? []),
                    legalEntityId,
                  ]);
                }}
                includedLegalEntities={includedLegalEntities}
                excludedLegalEntityIds={field.value ?? []}
                onClose={toggleLegalEntitiesModalOpened}
                assets={selectedAssetsWithLEs}
              />
            )}
          </LegalEntitiesSelectionModal.Wrapper>
        );
      }}
    />
  );
}

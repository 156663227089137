import React, { FC, ReactNode } from 'react';
import { IconsId } from 'types/sre-icons';
import { Icon } from '..';

export enum StatusesTrackerItem {
  DONE = 'done',
  IN_PROGRESS = 'in_progress',
  NONE = 'none',
}

interface IStatusTrackerItemProps {
  children: ReactNode;
  styles: {
    iconColor: string;
    iconBgColor: string;
    borderColor?: string;
  };
  progress: `${StatusesTrackerItem}`;
  icon: IconsId;
}

export const INFO_STATUS_TRACKER = {
  iconColor: 'text-white',
  iconBgColor: 'bg-blue',
  borderColor: 'blue',
};

export const NEUTRAL_STATUS_TRACKER = {
  iconColor: 'text-light-60',
  iconBgColor: 'bg-light-15',
};

export const DANGER_STATUS_TRACKER = {
  iconColor: 'text-white',
  iconBgColor: 'bg-red',
  borderColor: 'red',
};

export const StatusTrackerItem: FC<IStatusTrackerItemProps> = ({
  children,
  styles,
  progress,
  icon,
}) => (
  <div className="flex gap-tw-2">
    <div className="flex flex-col items-center gap-tw-2">
      <div
        className={`${styles.iconBgColor} flex h-[16px] w-[16px] items-center justify-center rounded-lg`}
      >
        <Icon iconName={icon} className={`${styles.iconColor} text-xs`} />
      </div>
      {progress === 'done' && (
        <div
          className={`h-full border-2 border-solid border-${
            styles.borderColor ?? 'gray'
          } rounded-sm`}
        />
      )}
      {progress === 'inprogress' && (
        <div
          className={`h-full border-2 border-dashed border-${
            styles.borderColor ?? 'gray'
          } rounded-sm`}
        />
      )}
    </div>
    {children}
  </div>
);

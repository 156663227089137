import {
  createLegalEntity,
  updateLegalEntity,
} from 'bundles/Settings/actions/legalEntity';
import { LEFieldOption } from 'bundles/Settings/components/Portal/LegalEntities/UpdateLEModals';
import PermissionList from 'bundles/Shared/components/PermissionList';
import PermissionsModal from 'bundles/Shared/components/PermissionsModal';
import {
  AssetClassSelect,
  LeClassificationSelectField,
  LeClassification,
  findAssetClassOptionByValue,
} from 'bundles/Shared/entities/leClasssification';
import { CoreLegalEntitiesLegalEntitiables } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApi';
import { legalEntityPermissions } from 'bundles/Shared/legalEntityPermissions';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';
import { useMemo, useState } from 'react';
import Select from 'react-select';
import { Badge, Button, Input, Modal, ModalActions } from 'stories';
import { SnakeCasedProperties } from 'type-fest';
import { LegalEntity } from '@/entities/core/legalEntity';
import { selectStyles } from './LEModalSelectStyles';

interface Props {
  id: string;
  setLegalEntityModalOpened: (value: boolean) => void;
  assets: CoreLegalEntitiesLegalEntitiables[];
  funds: CoreLegalEntitiesLegalEntitiables[];
  onSubmitCallback: () => void;
  currentLegalEntity: LegalEntity;
}

export type LEFieldsToUpdate = SnakeCasedProperties<
  Pick<
    LegalEntity,
    | 'id'
    | 'name'
    | 'code'
    | 'source'
    | 'legalEntitiableId'
    | 'legalEntitiableType'
    | 'classification'
  >
>;

const LegalEntityModal = ({
  currentLegalEntity,
  setLegalEntityModalOpened,
  assets,
  funds,
  onSubmitCallback,
}: Props) => {
  const [legalEntity, setLegalEntity] =
    useState<LegalEntity>(currentLegalEntity);
  const setLegalEntityAttribute = (
    attribute: 'name' | 'code' | 'permitted',
    value: any,
  ) => {
    setLegalEntity({
      ...legalEntity,
      [attribute]: value,
    });
  };

  const [permissionsModalOpened, setPermissionsModalOpened] = useState(false);

  const onSubmit = async () => {
    let params = {
      id: legalEntity.id,
      name: legalEntity.name,
      code: legalEntity.code,
      legal_entitiable_id: legalEntity.legalEntitiableId,
      legal_entitiable_type: legalEntity.legalEntitiableType,
      source: legalEntity.source,
      classification: legalEntity.classification,
    } satisfies LEFieldsToUpdate;

    if (legalEntity.permitted)
      params = {
        ...params,
        ...legalEntityPermissions(legalEntity.permitted),
      };

    const success = legalEntity.id
      ? await updateLegalEntity(params)
      : await createLegalEntity(params);

    if (success) {
      setLegalEntityModalOpened(false);
      onSubmitCallback();
    }
  };

  const assetOptions = useMemo(
    () =>
      assets.map((asset) => ({
        value: `Asset_${asset.id}`,
        label: asset.name,
      })),
    [assets],
  );

  const fundOptions = useMemo(
    () => funds.map((fund) => ({ value: `Fund_${fund.id}`, label: fund.name })),
    [funds],
  );

  const onInvestmentObjectChange = (option: LEFieldOption) => {
    let legalEntitiableId;
    let legalEntitiableType;

    if (option) {
      legalEntitiableId = option.value.split('_')[1];
      legalEntitiableType = option.value.split('_')[0];
    }
    setLegalEntity({
      ...legalEntity,
      legalEntitiableId,
      legalEntitiableType,
    });
  };

  const onClassificationChange = (value: LeClassification) => {
    setLegalEntity({
      ...legalEntity,
      classification: value,
    });
  };

  const sourceOptions = useMemo(
    () =>
      Object.keys(SOURCE_TYPE_SETTINGS).map((k) => ({
        label: SOURCE_TYPE_SETTINGS[k].title,
        value: k,
      })),
    [],
  );

  const onSourceChange = ({ value }) => {
    setLegalEntity({
      ...legalEntity,
      source: value,
    });
  };

  const defaultInvestmentObject = useMemo(
    () =>
      [...assetOptions, ...fundOptions].find(
        (option) =>
          option.value ===
          `${legalEntity.legalEntitiableType ?? ''}_${
            legalEntity.legalEntitiableId ?? ''
          }`,
      ),
    [legalEntity],
  );

  const headerText = legalEntity?.id ? 'Edit Legal Entity' : 'Add Legal Entity';
  const successActionText = legalEntity?.id
    ? 'Edit Legal Entity'
    : 'Add Legal Entity';

  return (
    <Modal
      toggle={() => setLegalEntityModalOpened(false)}
      header={headerText}
      actions={
        <ModalActions>
          <Button
            variant="secondary"
            onClick={() => setLegalEntityModalOpened(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !legalEntity.name ||
              !legalEntity.code ||
              !legalEntity.source ||
              !legalEntity.classification
            }
            variant="success"
            onClick={onSubmit}
          >
            {successActionText}
          </Button>
        </ModalActions>
      }
    >
      <h6 className="header6-regular mb-m">Basic</h6>
      <label htmlFor="le-name" className="mb-s">
        Name <span className="red">*</span>
      </label>
      <Input
        onChange={(e) => setLegalEntityAttribute('name', e.target.value)}
        placeholder="Enter Entity Name"
        id="le-name"
        defaultValue={legalEntity?.name || ''}
        className="mb-m"
      />
      <label htmlFor="le-code" className="mb-s">
        Code <span className="red">*</span>
      </label>
      <Input
        onChange={(e) => setLegalEntityAttribute('code', e.target.value)}
        placeholder="Enter Code"
        id="le-code"
        defaultValue={legalEntity?.code || ''}
        className="mb-m"
        disabled={legalEntity.id && legalEntity?.source !== 'custom'}
      />
      <label htmlFor="le-source" className="mb-s">
        Source <span className="red">*</span>
      </label>
      <Select
        menuPosition="fixed"
        menuShouldBlockScroll
        className="mb-m"
        styles={selectStyles}
        onChange={onSourceChange}
        options={sourceOptions}
        classNamePrefix="react-select"
        placeholder="Select source..."
        defaultValue={sourceOptions.find(
          (option) => option.value === legalEntity.source,
        )}
      />
      <label htmlFor="object" className="mb-s">
        Object
      </label>
      <Select
        menuPosition="fixed"
        menuShouldBlockScroll
        className="mb-m"
        styles={selectStyles}
        onChange={onInvestmentObjectChange}
        options={[
          {
            label: 'Assets',
            options: assetOptions,
          },
          {
            label: 'Funds',
            options: fundOptions,
          },
        ]}
        classNamePrefix="react-select"
        placeholder="Select object..."
        defaultValue={defaultInvestmentObject}
      />
      <LeClassificationSelectField className="mb-m">
        <AssetClassSelect
          defaultValue={
            legalEntity.classification
              ? findAssetClassOptionByValue(legalEntity.classification)
              : undefined
          }
          onChange={onClassificationChange}
        />
      </LeClassificationSelectField>

      <label htmlFor="permissions" className="mb-m">
        Permissions
      </label>
      {legalEntity.permitted && (
        <div className="flex">
          <PermissionList
            permissions={legalEntity.permitted}
            productName={
              legalEntity.legalEntitiableType == 'Fund'
                ? FUND_PORTAL_PRODUCT_NAME
                : ASSET_PORTAL_PRODUCT_NAME
            }
            investmentObject={{
              type: legalEntity.legalEntitiableType,
              entity: { id: legalEntity.legalEntitiableId },
            }}
            hideActions={false}
            onSubmit={(permissions) =>
              setLegalEntityAttribute('permitted', permissions)
            }
            objectableId={legalEntity.id}
            objectableType="LegalEntity"
            itemType="Legal Entity"
          />
        </div>
      )}
      {!legalEntity.permitted && (
        <Badge
          backgroundColor="var(--blue-light-4)"
          textColor="var(--bl)"
          className="flex w-max cursor-pointer"
          onClick={() => setPermissionsModalOpened(true)}
        >
          Set Permissions
        </Badge>
      )}
      {permissionsModalOpened && (
        <PermissionsModal
          onClose={() => {
            setPermissionsModalOpened(false);
          }}
          onSubmit={(permissions) =>
            setLegalEntityAttribute('permitted', permissions)
          }
          initialState={legalEntity?.permitted || null}
          initialTab={legalEntity?.permitted?.public ? 'public' : 'restricted'}
          productName={ASSET_PORTAL_PRODUCT_NAME}
          itemType="Legal Entity"
        />
      )}
    </Modal>
  );
};

export default LegalEntityModal;

// Move to: src/bundles/Shared/components/Filters/SetFilter.tsx ?
import React, { ComponentProps, useMemo, useState } from 'react';
import { Popover, SearchInput } from 'stories';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { ListOption } from 'stories/Checkbox/CheckList';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import fuzzysort from 'fuzzysort';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';

type PopoverProps = Omit<
  ComponentProps<typeof Popover>,
  'template' | 'trigger'
>;
interface Props extends PopoverProps {
  items: ListOption[];
  value: ListOption[];
  onChange: (items: ListOption[]) => void;
  label: string;
  filterName?: string;
}

/** @deprecated */
function SetFilter({
  items,
  value,
  onChange,
  label,
  filterName = '',
  placement = 'bottom-end',
  ...props
}: Props) {
  const { filteredItems, inputProps, searchText, setSearchText } =
    useItemsFilterByText<{
      value: string | number;
      label: string;
    }>(
      items,
      (item, searchText) => fuzzysort.go(searchText, [item.label]).length > 0,
    );

  return (
    <Popover
      className="p-m"
      trigger="click"
      hiddenArrow
      placement={placement}
      maxWidth="14rem"
      offset={[0, 2]}
      template={
        <div>
          <SearchInput
            value={searchText}
            resetValue={() => setSearchText('')}
            size="s"
            placeholder={label}
            className="mb-m"
            {...inputProps}
          />
          <OverlayScrollbarsComponent>
            <div style={{ maxHeight: '450px' }}>
              <SplittedCheckList
                items={filteredItems}
                value={value}
                onChange={onChange}
              />
            </div>
          </OverlayScrollbarsComponent>
        </div>
      }
      {...props}
    >
      <div className="flex items-center">
        <p>{filterName}</p>
        <div className="ml-tw-2">
          <UniversalFilterButton
            filtered={value.length > 0}
            onClose={() => onChange([])}
          />
        </div>
      </div>
    </Popover>
  );
}

export default SetFilter;

import { useNavigate } from '@reach/router';
import InvestmentObjectImage from 'bundles/InvestmentObjects/components/investmentObjectImage/InvestmentObjectImage';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Icon, IconButton } from 'stories/index';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { ALL_STATUS_OPTIONS_MAP } from 'bundles/Settings/components/Portal/shared/consts';
import { IFund } from 'types/Fund';

function FundAssetList({ assets }: { assets: IFund['assets'] }) {
  const navigate = useNavigate();

  return assets?.map((asset) => (
    <div
      key={asset.id}
      className="group flex items-stretch gap-tw-4 border-b-2 border-solid border-neutral-150 bg-neutral-000 p-tw-4 last:border-b-0"
    >
      <div className="flex items-center ">
        <InvestmentObjectImage
          fallbackNode={
            <Icon
              iconName="asset"
              className="header1-regular text-neutral-550"
            />
          }
          pictureUrl={asset.smallPictureUrl}
          imagePlaceholder=""
        />
      </div>

      <div className="flex flex-grow flex-col justify-center gap-tw-2">
        <div className="flex items-center justify-between gap-tw-2">
          <p className="body-semibold text-neutral-800">{asset.name}</p>
          <div className="flex items-center gap-tw-2">
            {asset.viewersCount > 0 && (
              <div className="flex items-center gap-tw-1 text-neutral-450">
                <Icon iconName="members" />
                <p className="secondary-regular">{asset.viewersCount}</p>
              </div>
            )}
            <Icon
              iconName={ALL_STATUS_OPTIONS_MAP[asset.aasmState].iconName}
              className={ALL_STATUS_OPTIONS_MAP[asset.aasmState].className}
            />
            <IconButton
              variant="secondary"
              iconName="arrowRight"
              onClick={() =>
                navigate(
                  generateUrl(ROUTES_ROOT.assets.asset.fullPath, {
                    pathParams: {
                      assetSlug: asset.slug,
                    },
                  }),
                )
              }
            />
          </div>
        </div>
        <ListNavLayout.NavigationCardList.Label
          color={asset.stage?.color}
          text={asset.stage?.name}
        />
      </div>
    </div>
  ));
}

export default FundAssetList;

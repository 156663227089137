/* eslint-disable */
import { emptySplitApi as api } from "../../../../app/stores/api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportDataPeriodBatchGroups: build.query<
      GetApiSettingsReportDataPeriodBatchGroupsApiResponse,
      GetApiSettingsReportDataPeriodBatchGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/data_period_batch_groups`,
        params: {
          years: queryArg.years,
          query: queryArg.query,
          sort: queryArg.sort,
          page: queryArg.page,
          per_page: queryArg.perPage,
        },
      }),
    }),
    getApiSettingsReportDataPeriodBatches: build.query<
      GetApiSettingsReportDataPeriodBatchesApiResponse,
      GetApiSettingsReportDataPeriodBatchesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/data_period_batches`,
        params: {
          legal_entitiables: queryArg.legalEntitiables,
          period: queryArg.period,
          state: queryArg.state,
          query: queryArg.query,
          sort: queryArg.sort,
          page: queryArg.page,
          per_page: queryArg.perPage,
        },
      }),
    }),
    putApiSettingsReportDataPeriodBatchesBulk: build.mutation<
      PutApiSettingsReportDataPeriodBatchesBulkApiResponse,
      PutApiSettingsReportDataPeriodBatchesBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/data_period_batches/bulk`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportDataPeriodBatchesLegalEntitiables: build.query<
      GetApiSettingsReportDataPeriodBatchesLegalEntitiablesApiResponse,
      GetApiSettingsReportDataPeriodBatchesLegalEntitiablesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/data_period_batches/legal_entitiables`,
        params: { period: queryArg.period, state: queryArg.state },
      }),
    }),
    putApiSettingsReportDataPeriodYearsByYearPublish: build.mutation<
      PutApiSettingsReportDataPeriodYearsByYearPublishApiResponse,
      PutApiSettingsReportDataPeriodYearsByYearPublishApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/data_period_years/${queryArg.year}/publish`,
        method: "PUT",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportDataPeriodsApi };
export type GetApiSettingsReportDataPeriodBatchGroupsApiResponse =
  /** status 200 success */ {
    items: ReportDataPeriodBatch[];
    meta: Pagination;
  };
export type GetApiSettingsReportDataPeriodBatchGroupsApiArg = {
  years?: number[];
  query?: string;
  sort?: {
    field?: "period";
    order?: "asc" | "desc";
  };
  page?: number;
  perPage?: number;
};
export type GetApiSettingsReportDataPeriodBatchesApiResponse =
  /** status 200 success */ {
    items: ReportDataPeriodBatch2[];
    meta: Pagination;
  };
export type GetApiSettingsReportDataPeriodBatchesApiArg = {
  legalEntitiables?: {
    Asset?: number[];
    Fund?: number[];
  };
  period?: string;
  state?: "draft" | "published" | "unpublished";
  query?: string;
  sort?: {
    field?: "legal_entity" | "legal_entitiable";
    order?: "asc" | "desc";
  };
  page?: number;
  perPage?: number;
};
export type PutApiSettingsReportDataPeriodBatchesBulkApiResponse = unknown;
export type PutApiSettingsReportDataPeriodBatchesBulkApiArg = {
  body: {
    data_period_batches: {
      period: string;
      legal_entity_id: string;
      state: "draft" | "published" | "unpublished";
    }[];
  };
};
export type GetApiSettingsReportDataPeriodBatchesLegalEntitiablesApiResponse =
  /** status 200 success */ ReportDataPeriodBatchLegalEntitiable[];
export type GetApiSettingsReportDataPeriodBatchesLegalEntitiablesApiArg = {
  period?: string;
  state?: "draft" | "published" | "unpublished";
};
export type PutApiSettingsReportDataPeriodYearsByYearPublishApiResponse =
  unknown;
export type PutApiSettingsReportDataPeriodYearsByYearPublishApiArg = {
  year: number;
};
export type ReportDataPeriodBatch = {
  period: string;
  draftBatchesCount: number;
  publishedBatchesCount: number;
  unpublishedBatchesCount: number;
};
export type Pagination = {
  perPage: number;
  totalSize: number;
};
export type ReportDataPeriodBatchLegalEntitiable = {
  id: number;
  slug: string;
  name: string;
  type: "Asset" | "Fund";
};
export type ReportDataPeriodBatch2 = {
  period: string;
  legalEntityId: string;
  state: "draft" | "published" | "unpublished";
  publishedRecently: boolean;
  legalEntity: {
    id: string;
    code: string;
    excluded: boolean;
    source:
      | "quickbooks_desktop"
      | "yardi"
      | "entrata"
      | "m3"
      | "mri"
      | "realpage"
      | "resman"
      | "sage"
      | "symmetre";
    legalEntitiableType: ("Asset" | "Fund") | null;
    legalEntitiableId: number | null;
    legalEntitiable: ReportDataPeriodBatchLegalEntitiable | null;
  };
};

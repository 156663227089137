import http from 'lib/http';
import { stringify } from 'qs';
import { IReportBudgetOnShow } from 'types/ReportBudget';
import { IColumnGroup, IRowBase } from '../components/ReportTable/types';

export async function fetchReportBudgetYears(data) {
  const res = await http.get(
    `/report_budgets/years${stringify(data, { addQueryPrefix: true })}`,
  );
  const responseData: unknown = await res.json();
  return responseData;
}

export async function fetchReportBudgets(data) {
  const res = await http.get(
    `/report_budgets${stringify(data, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    })}`,
  );
  const responseData: unknown = await res.json();
  return responseData;
}

export interface ReconcileOperationalTableData {
  reportBudget: IReportBudgetOnShow;
  meta: {
    data: {
      rows: IRowBase[];
      columns: IColumnGroup[];
    };
  };
}

type SymmetreResponse<T extends object = object> = T | { errors: string[] };

export const fetchReconcileOperationalTableData = async (
  id: number,
): Promise<ReconcileOperationalTableData> => {
  const res = await http.get(`/report_budgets/${id}`);
  const responseData: SymmetreResponse<ReconcileOperationalTableData> =
    await res.json();

  if ('errors' in responseData) {
    toastr.error(`${responseData.errors.join(', ')}\n`);
    throw new Error(responseData.errors.join(', '));
  }

  return responseData;
};

export async function updateReportBudget(id: number, data) {
  const res = await http.put(`/report_budgets/${id}`, data);
  const responseData: SymmetreResponse = await res.json();

  if ('errors' in responseData) {
    toastr.error(`${responseData.errors.join(', ')}\n`);
  } else {
    toastr.success('Report budget successfully updated');
  }

  return responseData;
}

export async function createReportBudgetsBulk(data) {
  const res = await http.post('/report_budgets/bulk', data);
  const responseData: SymmetreResponse = await res.json();

  if ('errors' in responseData) {
    toastr.error(`${responseData.errors.join(', ')}\n`);
  } else {
    toastr.success('Report budgets successfully created');
  }

  return responseData;
}

import { INotification } from 'types/Notification';
import http from '../../../lib/http';

export const FETCH_NEW_NOTIFICATIONS =
  'symmetre-client-api/FETCH_NEW_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS = 'symmetre-client-api/FETCH_NOTIFICATIONS';
export const HIDE_ALL_NOTIFICATIONS =
  'symmetre-client-api/HIDE_ALL_NOTIFICATIONS';
export const HIDE_NOTIFICATION = 'symmetre-client-api/HIDE_NOTIFICATION';

export function fetchNewNotifications() {
  return async function (dispatch) {
    const res = await http.get('/notifications?viewed=false');
    const responseData = await res.json();

    dispatch({
      type: FETCH_NEW_NOTIFICATIONS,
      payload: responseData as INotification[],
    });
  };
}

export function fetchNotifications() {
  return async function (dispatch) {
    const res = await http.get('/notifications');
    const responseData = await res.json();

    dispatch({
      type: FETCH_NOTIFICATIONS,
      payload: responseData as INotification[],
    });
  };
}

export function hideAllNotifications() {
  return async (dispatch) => {
    const res = await http.put('/notifications/hide_all', {});
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      dispatch({
        type: HIDE_ALL_NOTIFICATIONS,
        payload: responseData,
      });
    }
  };
}

export function hideNotification(data: { id: number }) {
  return async function (dispatch, getState) {
    const res = await http.put(`/notifications/${data.id}/hide`, data);
    const responseData = await res.json();
    const notifications = [...getState().notifications.all];
    const unreadNotifications = [...getState().notifications.unread].filter(
      ({ id }) => responseData.id === id,
    );

    const i = notifications.findIndex(
      (notification) => notification.id === responseData.id,
    );
    notifications[i] = responseData;

    dispatch({
      type: HIDE_NOTIFICATION,
      payload: { all: notifications, unread: unreadNotifications },
    });
  };
}

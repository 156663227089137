import React from 'react';
import { Button, Dropdown, DropdownItem, Icon } from 'stories';

interface Props {
  actions: {
    label: string;
    handler: () => void;
    locked: boolean;
    lockedText?: string;
  }[];
  navigateToSettingJCC: () => void;
  isDisabled?: boolean;
}

export function CreateLineItemDropdown({
  actions,
  isDisabled,
  navigateToSettingJCC,
}: Props) {
  const [manual, source] = actions;
  return (
    <Dropdown
      maxWidth="17rem"
      items={
        <div>
          <DropdownItem
            key={manual.label}
            onClick={manual.handler}
            className="bg-tw-white"
          >
            {manual.label}
          </DropdownItem>
          <DropdownItem
            key={source.label}
            onClick={source.locked ? navigateToSettingJCC : source.handler}
            className={source.locked ? 'bg-tw-light' : 'bg-tw-white'}
          >
            {source.locked && (
              <div className="flex flex-col gap-tw-2 py-tw-2">
                <div className="flex items-center gap-tw-2.5">
                  <div>From Source</div>
                  <Icon iconName="lock" />
                </div>
                <div className="light-60 secondary-regular whitespace-normal">
                  {source?.lockedText}
                </div>
                <Button
                  variant="secondary"
                  size="xs"
                  onClick={navigateToSettingJCC}
                  className="w-fit"
                >
                  Set General Ledger
                </Button>
              </div>
            )}
            {!source.locked && source.label}
          </DropdownItem>
        </div>
      }
    >
      <Button
        variant="primary"
        iconName="bottom"
        iconPosition="right"
        size="s"
        disabled={isDisabled}
      >
        Create Line Item
      </Button>
    </Dropdown>
  );
}

export default CreateLineItemDropdown;

import {
  FETCH_SHARED_FILES,
  REMOVE_SHARED_FILE,
  REMOVE_SHARED_FILES,
  UPDATE_SHARED_FILE,
} from '../../actions';

const initialState = null;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SHARED_FILES:
      return payload;
    case UPDATE_SHARED_FILE:
      return state ? state.map((item) => (item.id !== payload.id ? item : payload)) : [];
    case REMOVE_SHARED_FILE:
      return state ? state.filter(({ id }) => id !== payload.id) : [];
    case REMOVE_SHARED_FILES:
      return state ? state.filter(({ id }) => !payload.ids.includes(id)) : [];
    default:
      return state;
  }
};

import * as React from 'react';

interface Props {
  header: React.ReactNode;
  preview: React.ReactNode;
  form: React.ReactNode;
}

function TemplateEditorContainer({ header, preview, form }: Props) {
  return (
    <div className="bg-light-5">
      {header}
      <div className="py-[1rem] email-template-form__container">
        <div className="row">
          <div className="col-5">{form}</div>
          <div className="col-7 rounded-[1rem] email-template-preview__container bg-light-10">
            {preview}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateEditorContainer;

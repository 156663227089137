import React from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useGenericData } from 'bundles/Shared/GenericContext';
import UserCard from '../../LeftSidebar/cards/UserCard';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps;

const UserModal = ({ onClose }: Props) => {
  const { genericData } = useGenericData();
  const { currentUser } = genericData!;

  return (
    <SidePanel size="md" toggle={onClose} header="Account">
      <div className="flex h-full flex-row items-center justify-center">
        <UserCard currentUser={currentUser} />
      </div>
    </SidePanel>
  );
};

export default UserModal;

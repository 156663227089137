import { replacePathParams } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/crudApi';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { isLeftSidebarPinned } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import useMediaQuery, {
  TW_2XL_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useWindowSize } from '@/shared/lib/hooks/useWindowSize';
import { asserts } from 'lib/typeHelpers/assertsType';
import { useMemo } from 'react';
import { IReportTableConfig } from 'types/ReportTableConfig';

const TAB_PADDING = 16;
const ALL_TABS_PADDING = 24;
const ALL_TABS_PADDING_X = ALL_TABS_PADDING * 2;
const TAB_PADDING_X = TAB_PADDING * 2;
const AVERAGE_TAB_CHAR_WIDTH = 8;
const [NAV_WIDTH, NAV_WIDTH_2_XL] = [480, 584];
const COLLAPSED_SIDE_MENU_WIDTH = 56;
const EXPANDED_SIDE_MENU_WIDTH = 256;

export type Tab = {
  label: string;
  path: string;
  slug?: string;
};

export function useCurrentTab({
  reportTableConfig,
  tabs,
}: {
  reportTableConfig: IReportTableConfig;
  tabs: Tab[];
}) {
  const tab = useMemo(
    () => tabs.find((t) => t.slug === reportTableConfig.slug),
    [reportTableConfig.name, tabs],
  );
  return tab;
}

export function useDynamicTabs({
  dashboardExpanded,
}: {
  dashboardExpanded: boolean;
}) {
  const isPinned = useAppSelector(isLeftSidebarPinned);
  const { genericData } = useGenericData();
  asserts(genericData);
  const { mainNavigation } = genericData;

  const is2XLWidth = useMediaQuery(TW_2XL_WIDTH_MEDIA_QUERY);
  const { width: innerWidth } = useWindowSize();
  const getCurrentNavWidth = () => {
    if (dashboardExpanded) return 0;

    if (is2XLWidth) return NAV_WIDTH_2_XL;

    return NAV_WIDTH;
  };

  const getCurrentSideMenu = () => {
    if (isPinned) return EXPANDED_SIDE_MENU_WIDTH;

    return COLLAPSED_SIDE_MENU_WIDTH;
  };

  const currentDashboardWidth =
    (innerWidth ?? 0) - getCurrentNavWidth() - getCurrentSideMenu();

  const tabs = useMemo<{
    allTabs: Tab[];
    shownTabs: Tab[];
    restTabs: Tab[];
  }>(() => {
    const allTabs: Tab[] = mainNavigation.financialSubItems.map((t) => ({
      path: replacePathParams(ROUTES_ROOT.report.financials.fullPath, {
        reportName: t.slug,
      }),
      slug: t.slug,
      label: t.label,
    }));

    const tabsPixels = allTabs.map((t) => {
      const tabPixels = t.label.length * AVERAGE_TAB_CHAR_WIDTH;
      const tabPixelsAndPadding = tabPixels + TAB_PADDING_X;
      return tabPixelsAndPadding;
    });

    const getLastFittedTabIdx = () => {
      let acc = 0;
      let lastFittedTabidx = 0;
      for (let tabIdx = 0; tabIdx < tabsPixels.length + 1; tabIdx++) {
        const tabPixels = tabsPixels[tabIdx];
        const allTabsWidthPixels = acc + tabPixels;

        const dashboardWithoutPadding =
          currentDashboardWidth - ALL_TABS_PADDING_X;
        if (allTabsWidthPixels > dashboardWithoutPadding) break;

        acc = allTabsWidthPixels;
        lastFittedTabidx = tabIdx;
      }

      return lastFittedTabidx;
    };
    const lastFittedTabIdx = getLastFittedTabIdx();

    return {
      allTabs,
      shownTabs: allTabs.slice(0, lastFittedTabIdx),
      restTabs: allTabs.slice(lastFittedTabIdx),
    };
  }, [mainNavigation, currentDashboardWidth]);

  return tabs;
}

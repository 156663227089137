import React from 'react';
import { Popover } from 'stories';
import { LegalEntity } from '@/entities/core/legalEntity';
import PseudoLink from 'stories/PseudoLink/PseudoLink';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';

interface Props {
  legalEntities: Pick<LegalEntity, 'id' | 'name'>[];
  className?: string;
}

function LegalEntitiesLinkWithPopover({ legalEntities, className }: Props) {
  return (
    <Popover
      offset={DEFAULT_DROPDOWN_OFFSET}
      hiddenArrow
      placement="bottom-start"
      disabled={legalEntities.length <= 1}
      template={
        <span className="dark-60 secondary-regular">
          {legalEntities.map((le, i, arr) => (
            <>
              {le.name}
              {i + 1 !== arr.length && <span className="light-60">, </span>}
            </>
          ))}
        </span>
      }
    >
      {legalEntities.length === 0 && 'No Legal Entities'}
      {legalEntities.length === 1 && legalEntities[0].name}
      {legalEntities.length > 1 && (
        <PseudoLink className={className}>
          {legalEntities.length > 1 && `${legalEntities.length} Legal Entities`}
        </PseudoLink>
      )}
    </Popover>
  );
}

export default LegalEntitiesLinkWithPopover;

import * as React from 'react';
import { SreAvatar } from 'stories/index';
import { IAccessListItem } from '../types';

export const singlePermittedMemberJSX = (member: IAccessListItem) => (
  <div className="documents-member-preview my-xs">
    <div className="flex w-full justify-center">
      <SreAvatar text={member.value} width="2.5rem" height="2.5rem" />
    </div>
    <div className="dark-60 weight-500 mt-xs">{member.value}</div>
    <div className="muted">{member.role}</div>
  </div>
);

import React, { useEffect } from 'react';
import { AnimationLoader, Tab } from 'stories';
import { Router } from '@reach/router';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import NavLink from 'bundles/Shared/components/NavLink';
import PipelineCards from 'bundles/Settings/components/DealPath/PipelineCards';
import DealsTargets from 'bundles/Settings/components/DealPath/DealTargets';
import { fetchDealPathSettings } from '../actions/dealPathSettings';
import DealStages from '../components/DealPath/DealStages';
import DealsProbability from '../components/DealPath/DealsProbability';
import EmployeeUtilization from '../components/DealPath/EmployeeUtilization';
import DealPropertyTypes from '../components/DealPath/DealPropertyTypes';
import DealTeams from '../components/DealPath/DealTeams';
import DealFields from 'bundles/Settings/components/DealPath/DealFields';
import DealPathEmployees from 'bundles/Settings/components/DealPath/DealPathEmployees';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';

const CoreTab = () => (
  <div className="flex flex-col gap-tw-3.5">
    <DealPropertyTypes />
    <DealStages />
    <DealTeams />
  </div>
);

const DashboardTab = () => (
  <div className="flex flex-col gap-tw-3.5">
    <DealsTargets />
    <DealsProbability />
    <EmployeeUtilization />
  </div>
);

const PIPELINE_ROUTES = ROUTES_ROOT.settings.pipeline;

const PipelineSettings = () => {
  const dispatch = useAppDispatch();
  const dealPathSettings = useAppSelector((state) => state.dealPathSettings);
  useEffect(() => {
    dispatch(fetchDealPathSettings());
  }, []);

  const TABS = [
    { label: 'Dashboard', link: PIPELINE_ROUTES.fullPath },
    { label: 'Core', link: PIPELINE_ROUTES.core.fullPath },
    { label: 'Dealpath Fields', link: PIPELINE_ROUTES.fields.fullPath },
    { label: 'Employees', link: PIPELINE_ROUTES.employees.fullPath },
    { label: 'Manage charts', link: PIPELINE_ROUTES.cards.fullPath },
  ];

  if (!dealPathSettings.categories || !dealPathSettings.statuses)
    return <AnimationLoader />;

  return (
    <div className="custom-container">
      <h1 className="mb-35 mt-50">Pipeline Settings</h1>
      <Tab className="mb-tw-4">
        {TABS.map((tab) => (
          <NavLink
            key={tab.link}
            className="mgmt-tab mgmt-tab-s"
            activeClassName="active-mgmt-tab"
            to={tab.link}
          >
            {tab.label}
          </NavLink>
        ))}
      </Tab>
      <Router basepath="/" primary={false}>
        <DashboardTab path={PIPELINE_ROUTES.fullPath} />
        <CoreTab path={PIPELINE_ROUTES.core.fullPath} />
        <DealFields path={PIPELINE_ROUTES.fields.fullPath} />
        <DealPathEmployees path={PIPELINE_ROUTES.employees.fullPath} />
        <PipelineCards path={PIPELINE_ROUTES.cards.fullPath} />
      </Router>
    </div>
  );
};

export default PipelineSettings;

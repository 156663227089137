import { convertCentsToDollars } from '@/shared/lib/converters';
import { formatDate } from '@/shared/lib/formatting/dates';
import { CurrencyFormatter } from '@/stories';
import Table from 'bundles/Shared/components/Table/Table';
import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import InvestmentEntityColumn from '../../ownershipTable/formatters/InvestmentEntityColumn';
import styles from '../capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import { CapitalInvestment } from '@/entities/return';
import { firstContributionDateColumn } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/AccruedValuesEditor';
import { createAccruedTableItems } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/AccruedAmountValuesEditor';

export const ReviewStep = ({
  selectedCapitalInvestments,
}: {
  selectedCapitalInvestments: CapitalInvestment[];
}) => {
  const getContributionsAmountCents = (item) => {
    return (
      item.totalClassAContributionsAmountCents +
      item.totalClassBContributionsAmountCents
    );
  };

  const getContributionsAmountDollars = (item) => {
    return convertCentsToDollars(getContributionsAmountCents(item));
  };

  const tableItems = useMemo(() => {
    return createAccruedTableItems(selectedCapitalInvestments);
  }, [selectedCapitalInvestments]);

  const columns = useMemo(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        formatter: ({ row }) => {
          if (row.hidden) return;

          return (
            <InvestmentEntityColumn
              capitalInvestment={row}
              withIcon={false}
              withUsers={false}
              withLegalEntity
            />
          );
        },
      },
      firstContributionDateColumn(),
      {
        dataField: 'startDate',
        text: 'Start Date',
        headerStyle: {
          width: 130,
        },
        formatter: ({ row }) => {
          if (row.hidden) return;
          return formatDate(row.accruedStartDate, 'MMM DD, YYYY');
        },
      },
      {
        dataField: 'endDate',
        text: 'End Date',
        headerStyle: {
          width: 130,
        },
        formatter: ({ row }) => {
          if (row.hidden) return;
          return formatDate(row.accruedEndDate, 'MMM DD, YYYY');
        },
      },
      {
        dataField: 'periodType',
        text: 'period',
        headerStyle: {
          width: 100,
        },
        formatter: ({ row }) => {
          if (row.hidden) return;
          return capitalize(row.periodType);
        },
      },
      {
        text: 'Total Contrib.',
        dataField: 'contribution',
        classes: 'text-right',
        headerStyle: {
          width: 140,
        },
        formatter: ({ row }) => {
          if (row.hidden) return;
          return (
            <CurrencyFormatter
              classes={{ value: 'text-neutral-900' }}
              value={getContributionsAmountDollars(row)}
            />
          );
        },
      },
      {
        text: (
          <div>
            <div className="mt-s">Accrued Pref.</div>
          </div>
        ),
        dataField: 'amount',
        headerStyle: {
          width: 180,
        },
        formatter: ({ row }) => {
          const currentAmount = row.entries.find(
            (entry) =>
              entry.startDate >= row.startDate &&
              (entry.endDate ? entry.endDate <= row.endDate : true),
          )?.amount;

          return (
            <>
              <div className="text-right">
                <div>
                  <CurrencyFormatter
                    classes={{ value: 'text-neutral-900' }}
                    value={currentAmount * -1}
                  />
                </div>
                <div className="text-neutral-550">
                  From: {formatDate(row.startDate, 'MMM DD, YYYY')}
                </div>
                {row.endDate && (
                  <span className="text-neutral-550">
                    To: {formatDate(row.endDate, 'MMM DD, YYYY')}
                  </span>
                )}
              </div>
            </>
          );
        },
      },
    ],
    [selectedCapitalInvestments],
  );

  return (
    <>
      <div className="flex justify-between gap-l">
        <div>
          <span className="inline-semibold text-dark-80">Review action:</span>
          <span className="text-danger-055"> *</span>
        </div>
      </div>
      <div className="inline-regular mb-24 mt-xs text-sm text-neutral-650">
        Please note the Accrued Preferred entry will be generated automatically
        every month or quarter, depending on your selection
      </div>

      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
          th: 'align-bottom',
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-tw-2 light-60',
        }}
        items={tableItems}
        columns={columns}
      />
    </>
  );
};

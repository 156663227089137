import React, { useMemo } from 'react';
import {
  useReportSettingsDashboardById,
  useReportSettingsDashboardScreenParams,
} from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { DashboardLayout } from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import {
  DashboardContext,
  DashboardContextValue,
  ReportDashboardType,
} from 'bundles/Shared/entities/dashboard';
import SettingsReportEagleEyeDashboard from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportEagleEyeDashboard';
import { SettingsReportObjectLevelDashboard } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportObjectLevelDashboard';
import { useQueryParams } from '@/shared/lib/hooks/useNavigation';
import { SettingsReportUnsupportedDashboard } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportUnsupportedDashboard';
import { SettingsReportComparisonDashboard } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportComparisonDashboard';

export function SettingsReportDashboard() {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const { boardId } = useQueryParams<{
    boardId?: string;
  }>();
  const { data, isLoading } = useReportSettingsDashboardById(dashboardId);

  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardId,
      dashboardType:
        (data?.type as ReportDashboardType) ?? ReportDashboardType.OBJECT,
      boardId: boardId ?? '',
    }),
    [data, boardId],
  );

  if (isLoading) {
    return <DashboardLayout.LoadingPlaceholder />;
  }

  const resolveDashboardJSXElement = () => {
    switch (data?.type) {
      case ReportDashboardType.EAGLE_EYE: {
        return <SettingsReportEagleEyeDashboard />;
      }
      case ReportDashboardType.OBJECT: {
        return <SettingsReportObjectLevelDashboard />;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return <SettingsReportComparisonDashboard />;
      }
      default: {
        return <SettingsReportUnsupportedDashboard />;
      }
    }
  };

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      {resolveDashboardJSXElement()}
    </DashboardContext.Provider>
  );
}

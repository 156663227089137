import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';

export const SavedSvg = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1564_48171)">
      <path
        d="M10.3732 7.36359L7.82775 9.90905L6.55502 8.63632M15.4641 9.90905C15.4641 8.50323 14.3245 7.36359 12.9187 7.36359C12.9036 7.36359 12.8889 7.36373 12.8739 7.36399C12.5653 5.20506 10.7084 3.54541 8.46411 3.54541C6.68442 3.54541 5.14878 4.58907 4.43526 6.09775C2.77633 6.20633 1.46411 7.58629 1.46411 9.27267C1.46411 11.0299 2.88866 12.4545 4.64593 12.4545H12.9187C14.3245 12.4545 15.4641 11.3149 15.4641 9.90905Z"
        stroke="#848D91"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1564_48171">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.464111)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const RECONCILE_OPERATIONAL_TABLE_SKELETON_JSX = (
  <>
    <div className="flex justify-between">
      <SkeletonBlock className="h-[32px] w-[100px]" />
    </div>
    <div className="h-[1px] w-full bg-light-10" />
    <SkeletonBlock className="h-[32px] w-[460px]" />
    <div className="flex justify-between">
      <SkeletonBlock className="h-[32px] w-[160px]" />
      <SkeletonBlock className="h-[32px] w-[200px]" />
    </div>
    <div className="mt-[34px] flex flex-col gap-tw-0.5">
      {Array.from({ length: 8 }).map((_, i) => (
        <SkeletonBlock className="h-[65px] w-full" key={i} />
      ))}
    </div>
  </>
);

import { join, omit, startCase, values } from 'lodash-es';
import { IThinTabItemWithAmount } from 'stories/ThinTabGroupWithAmount/ThinTabGroupWithAmount';
import { BUDGET_STATUSES } from './consts';
import { BudgetYear } from './types';
import {
  ImportBudgetResponse,
  ImportBudgetResponseStatus,
} from './api/legalEntities';
import dayjs from 'dayjs';
import { Button, Icon, IconButton } from 'stories/index';
import { ComponentProps } from 'react';

export const createBudgetTabs = (
  budget: BudgetYear,
): IThinTabItemWithAmount[] => [
  {
    id: 'all',
    label: 'All',
  },
  ...BUDGET_STATUSES.map(
    (stateKey): IThinTabItemWithAmount => ({
      id: stateKey,
      label: startCase(stateKey),
      amount: budget.stateCounts[stateKey],
    }),
  ),
];

export const createImportResultsNotification = (data: ImportBudgetResponse) => {
  Object.entries(data).forEach(([sheetName, result]) => {
    switch (result.status) {
      case 'success':
        toastr.success(join([`'${sheetName}' imported. `], '\n'));
        break;
      case 'ignored':
        toastr.warning(join([`'${sheetName}' ignored. `], '\n'));
        break;
      case 'failure':
        toastr.error(join([`'${sheetName}' failed to import. `], '\n'));
        break;
    }
  });
};

export const resolveIconProps = (
  status: ImportBudgetResponseStatus,
): ComponentProps<typeof Icon> => {
  switch (status) {
    case 'success':
      return {
        iconName: 'check',
        className: 'text-success-055',
      } as const;

    case 'failure':
      return {
        iconName: 'nodeOpen',
        className: 'text-danger-070',
      } as const;

    case 'ignored':
      return {
        iconName: 'nodeOpen',
        className: 'text-neutral-500',
      } as const;

    default:
      return {
        iconName: 'nodeOpen',
      };
  }
};

const MIN_YEAR = 1970;
const MAX_YEAR = 2099;
export const budgetYearValidator = (searchText: string, allYears: number[]) => {
  const yearNum = Number(searchText);

  const validationFlags = {
    isText: searchText !== '',
    isValidYearCharLen: searchText.length === 4,
    isValidYear: dayjs(searchText).isValid(),
    isNewYear: !allYears.includes(yearNum),
    isValidYearRange: yearNum > MIN_YEAR && yearNum <= MAX_YEAR,
  };

  const areAllFlagsTrue = Object.values(validationFlags).every(Boolean);

  return areAllFlagsTrue;
};

export const resolveTotal = (b: BudgetYear) => {
  const actualBudgetCounts = omit(b.stateCounts, 'no_budget');
  const total = values(actualBudgetCounts).reduce((acc, val) => acc + val, 0);
  return total;
};

export const resolveTotalPublishedPercentage = (b: BudgetYear) => {
  const total = resolveTotal(b);
  return Number(((b.stateCounts.published / total) * 100).toFixed(2));
};

export const buildIconButtonProps = (
  tooltipText: string,
  iconName: ComponentProps<typeof IconButton>['iconName'],
): ComponentProps<typeof IconButton> => ({
  iconName,
  tooltipProps: {
    mainText: tooltipText,
  },
  className: 'text-neutral-500',
  size: 'm',
  variant: 'secondary',
});

export const buildButtonProps = (
  iconName: NonNullable<ComponentProps<typeof Button>['iconName']>,
) =>
  ({
    iconName,
    size: 's',
    variant: 'secondary',
    iconPosition: 'left',
  }) as const satisfies ComponentProps<typeof Button>;

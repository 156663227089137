import {
  useGetApiSettingsSreColorsQuery,
  usePostApiSettingsSreColorsBulkMutation,
} from '@/entities/sreColors/model/sreColorsEnhancedApi';
import { ColorPickerList } from '@/shared/widgets/colorPicker/widget';
import { sreColorsTransformer } from '@/shared/widgets/colorPicker/lib';
import { ComponentProps, useCallback, useMemo } from 'react';

export const ColorPickerWidget = (
  props: Pick<ComponentProps<typeof ColorPickerList>, 'onChange' | 'value'>,
) => {
  const { data, isFetching: sreColorsDataIsFetching } =
    useGetApiSettingsSreColorsQuery();
  const [bulkUpdateColors, { isLoading: bulkUpdateColorsIsLoading }] =
    usePostApiSettingsSreColorsBulkMutation();

  const sreColorOptions = useMemo(() => {
    return sreColorsTransformer.dtoToColorOptions(data ?? []);
  }, [data]);

  const sreColorOptionsToDtoTransformer = useCallback(
    sreColorsTransformer.getColorOptionsToDtoTransformer(sreColorOptions),
    [sreColorOptions],
  );

  const sreColorPickerIsFetching =
    sreColorsDataIsFetching || bulkUpdateColorsIsLoading;
  return (
    <ColorPickerList
      isFetching={sreColorPickerIsFetching}
      onSubmit={(res) => {
        bulkUpdateColors({
          body: {
            sre_colors: sreColorOptionsToDtoTransformer(res.colors),
          },
        });
        if (res.selectedColor != null) {
          props.onChange(res.selectedColor?.value);
        }
      }}
      onChange={props.onChange}
      value={props.value}
      options={sreColorOptions}
    />
  );
};

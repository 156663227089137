import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from './StatisticsRow.module.scss';
import { ClassNameProps } from 'types/Props';

export function StatisticRowList({
  children,
  className,
}: React.PropsWithChildren<ClassNameProps>) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}

interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
  className?: string;
}

function StatisticsRow({ label, value, className }: Props) {
  return (
    <div
      className={cn(
        styles.row,
        'flex items-center justify-between',
        className,
      )}
    >
      <span className="secondary-regular">{label}</span>
      <span className="secondary-regular">{value}</span>
    </div>
  );
}

export default StatisticsRow;

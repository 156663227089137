import { Button } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import React from 'react';

const SubmitMessageButton = ({
  disabled, feedMessage,
}) => {
  const buttonText = () => {
    if (feedMessage) return 'Update Post';

    return 'Create Feed Message';
  };

  return (
    <div className="flex flex-col items-center">
      <p className="mb-075rem label-regular light-60">
        {disabled
          ? 'Complete all required steps to create a new feed message'
          : 'You can now create a new feed message!'}
      </p>
      <div
        id="submit-feed-message"
        className="w-100 submit-button-container"
      >
        <Button
          variant="success"
          type="submit"
          className={cn('w-100', { disabled })}
        >
          {buttonText()}
        </Button>
      </div>
    </div>
  );
};

export default SubmitMessageButton;

import React from 'react';
import { Icon, Tooltip } from '@/stories';

const RecapPageButton = ({ name, slug }: { name?: string; slug?: string }) => {
  if (!slug) return null;

  return (
    <Tooltip
      titleText={name}
      actionText="Show Documentation"
      actionHandler={() => {
        window.open(`/documentation/${slug}`, '_blank');
      }}
      theme="light"
      variant="explanation"
    >
      <div className="mx-8">
        <Icon
          padding="xs"
          style={{ fontSize: '10px' }}
          backgroundColor="var(--neutral-500)"
          className="!rounded-full"
          iconName="question"
          color="white"
        />
      </div>
    </Tooltip>
  );
};

export default RecapPageButton;

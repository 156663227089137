import { createSlice } from '@reduxjs/toolkit';

export const widgetFullscreenSlice = createSlice({
  name: 'fullscreen',
  initialState: {
    value: false,
  },
  reducers: {
    enableFullscreen(state) {
      state.value = true;
    },
    disableFullscreen(state) {
      state.value = false;
    },
  },
});

import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard';
import { useNavigate } from '@reach/router';
import {
  DashboardCreationForm,
  useCreateDashboard,
} from 'bundles/Shared/features/dashboard/create';
import { useCreateDashboardForm } from 'bundles/Shared/features/dashboard/create/lib';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { FormProvider } from 'react-hook-form';
import { Button } from 'stories';

export function SettingsReportDashboardsNew() {
  const navigate = useNavigate();
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.report.dashboards.fullPath,
  });
  const { confirm } = useModal();
  const methods = useCreateDashboardForm({
    dashboard_type: ReportDashboardType.EAGLE_EYE,
    excluded_legal_entity_ids: [],
    segment_ids: [],
    asset_ids: [],
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;
  const [createDashboard, { isLoading: isSubmitting }] = useCreateDashboard();

  const onSubmit = handleSubmit(async (data) => {
    const res = await createDashboard(data);
    if ('error' in res) {
      return;
    }
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
        pathParams: {
          dashboardId: res.data.id,
        },
      }),
    );
  });
  const onBack = async () => {
    const res = await confirm({
      title: 'Do you really want to cancel this process?',
    });
    if (!res) {
      return;
    }
    navigateBack();
  };
  return (
    <div className="relative h-screen w-screen p-tw-4">
      <Button
        onClick={onBack}
        className="t-tw-4 l-tw-4 absolute"
        variant="secondary"
      >
        Back
      </Button>
      <div className="flex w-full justify-center">
        <div className="flex flex-col gap-tw-4">
          <div className="flex flex-col items-center rounded-2xl bg-neutral-250">
            <span className="inline-semibold py-tw-2 text-neutral-700">
              New Dashboard
            </span>
            <FormProvider {...methods}>
              <DashboardCreationForm />
            </FormProvider>
          </div>
          <div className="flex flex-col gap-tw-2">
            {!isDirty && (
              <span className="inline-regular text-center text-neutral-550">
                You need to fill all fields to continue
              </span>
            )}
            <Button
              disabled={!isValid || isSubmitting}
              variant="success"
              size="l"
              onClick={onSubmit}
            >
              Start Building
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

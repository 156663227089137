import { Input } from 'stories';
import {
  MonthItem,
  SliderWithNavigation,
  YearItem,
} from '@/stories/FlexibleFilterByPeriods/ItemComponents';
import {
  MONTHLY_THIN_TAB,
  QUARTERLY_THIN_TAB,
  THIN_TABS,
} from '@/stories/FlexibleFilterByPeriods/consts';
import ThinTabGroup from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { cn } from '@/shared/lib/css/cn';
import dayjs from 'dayjs';
import { range } from 'lodash-es';
import ReactDatePicker from 'react-datepicker';
import { useState } from 'react';

const START_YEAR = 1990;
const END_YEAR = dayjs().year() + 30;
const YEARS = range(START_YEAR, END_YEAR, 1);
const DIFF = dayjs(new Date()).year() - START_YEAR - 2;

export function ReactDatePickerWrapper({
  periodType: _periodType,
  setPeriodType: _setPeriodType,
  ...props
}: React.ComponentProps<typeof ReactDatePicker> & {
  periodType?: 'mtd' | 'qtd';
  setPeriodType?: (periodType: 'mtd' | 'qtd') => void;
}) {
  const [localPeriodType, setLocalPeriodType] = useState<'mtd' | 'qtd'>('qtd');
  const { selected } = props;
  const selectedQuarter = selected ? dayjs(selected).quarter() : 0;
  const selectedMonthIdx = selected ? dayjs(selected).month() : 0;

  const periodType = _periodType ?? localPeriodType;
  const setPeriodType = _setPeriodType ?? setLocalPeriodType;

  return (
    <ReactDatePicker
      calendarClassName="symmetre-calendar"
      className="w-full"
      placeholderText="Select Period"
      showQuarterYearPicker={periodType === 'qtd'}
      showMonthYearPicker={periodType === 'mtd'}
      dateFormat={periodType === 'mtd' ? 'MMM-yy' : 'yyyy, QQQ'}
      renderCustomHeader={({ date, changeYear }) => (
        <div className="flex flex-col items-center justify-center gap-tw-1">
          <ThinTabGroup
            onSelectedItemChange={(sel) =>
              setPeriodType(sel.id as 'mtd' | 'qtd')
            }
            selectedItem={THIN_TABS.find((tab) => tab.id === periodType)}
            items={[MONTHLY_THIN_TAB, QUARTERLY_THIN_TAB]}
          />

          <div className="flex items-center gap-tw-1">
            <SliderWithNavigation initialSlide={DIFF}>
              {YEARS.map((year) => (
                <YearItem
                  key={year}
                  opened={year === dayjs(date).year()}
                  onClick={() => {
                    changeYear(year);
                    props.onChange(dayjs(date).year(year).toDate());
                  }}
                >
                  {year}
                </YearItem>
              ))}
            </SliderWithNavigation>
          </div>
        </div>
      )}
      renderQuarterContent={(quarter, shortQuarter) => (
        // use QuerterItem form FlexibleFilterByPeriods/ItemComponents.tsx in next iteration
        <button
          type="button"
          className={cn(
            'header5-regular h-tw-10 w-full rounded-lg border border-none transition disabled:!bg-light-5 disabled:!text-light-40',
            {
              'border-light-10 bg-white text-light-60 hover:bg-light':
                selectedQuarter !== quarter,
              'border-blue bg-blue text-white hover:bg-blue-dark-2':
                selectedQuarter === quarter,
            },
          )}
        >
          {shortQuarter}
        </button>
      )}
      renderMonthContent={(monthIdx, shortMonthText) => (
        <MonthItem className="w-full" active={monthIdx === selectedMonthIdx}>
          {shortMonthText}
        </MonthItem>
      )}
      customInput={<Input leftIcon="calendar" size="m" />}
      {...props}
    />
  );
}

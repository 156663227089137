import React, { useState } from 'react';
import canManageObject from 'lib/InvestmentObject';
import { RelatedPartiesCollapsableSection } from 'stories';
import { urlPattern } from 'lib/regexpPatterns';
import InvestmentObjectInfoModal from './InvestmentObjectInfoModal';

const RelatedParties = ({ object, update, removeConfirmAlert }) => {
  const { id, relatedParties } = object;
  const canManage = canManageObject(object);
  const [relatedPartyModalIsOpen, setRelatedPartyModalIsOpen] = useState(false);
  const [editableFieldIndex, setEditableFieldIndex] = useState();

  const toggleRelatedPartyModal = (idx) => {
    setEditableFieldIndex(idx);
    setRelatedPartyModalIsOpen(!relatedPartyModalIsOpen);
  };

  return (
    <>
      <RelatedPartiesCollapsableSection
        canManage={canManage}
        relatedParties={relatedParties}
        onEdit={(relatedParty) => {
          const idx = relatedParties.findIndex((i) => i === relatedParty);
          toggleRelatedPartyModal(idx);
        }}
        onCreate={() => setRelatedPartyModalIsOpen(true)}
        onDelete={(relatedParty) => {
          removeConfirmAlert(() => update({
            id,
            slug: object.slug,
            [object.objectType]: {
              related_parties: relatedParties.filter((i) => i !== relatedParty),
            },
          }), 'Related Party');
        }}
      />
      {relatedPartyModalIsOpen && (
        <InvestmentObjectInfoModal
          isOpen
          close={() => toggleRelatedPartyModal(undefined)}
          object={object}
          items={[...relatedParties]}
          currentItemIndex={editableFieldIndex}
          update={update}
          header={editableFieldIndex !== undefined ? 'Edit Related Party' : 'Add Related Party'}
          newFieldTemplate={{ name: '', role: '', website: '' }}
          fields={[
            { name: 'role', validations: () => ({ required: true }) },
            { name: 'name', validations: () => ({ required: true }) },
            {
              name: 'website',
              validations: () => ({ pattern: urlPattern }),
            },
          ]}
          dataName="related_parties"
          actionText={editableFieldIndex !== undefined ? 'Save Updates' : 'Add Related Party'}
        />
      )}
    </>
  );
};

export default RelatedParties;

RelatedParties.propTypes = {

};

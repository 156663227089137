import { transformJCCKeyToId } from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { ILineItem } from 'bundles/REconcile/types/ILineItem';
import { DevelopmentCategory } from '@/shared/types/reconcile/Invoice';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export interface ILineItemWithCategory {
  vendor_name: string;
  development_category: {
    id: string;
    type: string;
  };
  amount: string;
  transaction_date: string;
  description: string;
}

export const transformLineSelectedItems = (
  selectedLineItems: ILineItem[],
  vendorName: string,
  categoriesFlat: DevelopmentCategory[] | undefined,
) =>
  selectedLineItems
    .filter((lineItem) => !lineItem.id)
    .map((lineItem) => ({
      vendor_name: vendorName,
      development_category: {
        id: transformJCCKeyToId(lineItem.category_id),
        type: lineItem.category_type,
      },
      amount: lineItem.amount,
      transaction_date: formatToDateStringForRequest(lineItem.date),
      description: lineItem.description ?? lineItem.remark,
    })) as ILineItemWithCategory[];

import React from 'react';
import styles from 'bundles/Construction/components/eSignature/ESignPlacementModal.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { Icon, Label, MultilineAlert } from 'stories';
import { IESignCoordinate } from 'bundles/Construction/types';
import { LABELS } from 'bundles/Construction/components/eSignature/consts';
import {
  TApprover,
  TField,
  TIndicator,
} from 'bundles/Construction/components/eSignature/types';
import { CssVar } from '@/shared/config/cssVar';
import { navigate } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useAppSelector } from '@/shared/lib/hooks/redux';

interface Props {
  approver: TApprover;
  color: TIndicator;
  fields: TField[];
  onFieldClick: (field: TField & { color: TIndicator }) => void;
  selectedField: IESignCoordinate;
  coordinates: IESignCoordinate[];
  requiredJobTitle: boolean;
  onClose: VoidFunction;
}

const ApproverCard = ({
  approver,
  fields,
  onFieldClick,
  selectedField,
  coordinates,
  color,
  requiredJobTitle,
  onClose,
}: Props) => {
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );

  const isSelected = (field: TField) =>
    selectedField?.kind === field.kind &&
    selectedField?.approverId === approver.id;
  const isPlaced = (field: TField) =>
    coordinates.some(
      (coord) => coord.kind === field.kind && coord.approverId === approver.id,
    );
  const label = LABELS[approver.userRoleCategory];

  const hasJobTitle = Boolean(approver.jobTitle);

  const moveToSettings = () => {
    onClose();
    navigate(
      generateUrl(
        ROUTES_ROOT.reconcile.development.legalEntity.settings.fullPath,
        {
          pathParams: {
            legalEntityCode: legalEntity.code,
            tab: 'workflow/approvers',
          },
        },
      ),
    );
  };

  return (
    <div className="mb-tw-1.5 flex flex-col gap-tw-2 rounded-2xl border-2 border-solid border-light-5 bg-white p-tw-4">
      {requiredJobTitle && !hasJobTitle && (
        <MultilineAlert
          message={
            <div>
              <span>The Job Title is missing, please fill it in the </span>
              <span
                onClick={moveToSettings}
                className="cursor-pointer font-semibold text-info-055"
              >
                settings
              </span>
            </div>
          }
          className="min-w-full"
        />
      )}
      <div className="flex">
        <div className="flex flex-1 flex-row">
          <div
            style={{
              backgroundColor: color.main,
              borderColor: color.light,
            }}
            className={styles.approverCardIndicator}
          />
          <div>
            <div className="dark-40 body-semibold">{approver.userName}</div>
            <div className="light-60 secondary-regular">
              {approver.userRoleName}
            </div>
          </div>
        </div>
        <div>
          <Label color={label.color} outline>
            {label.text}
          </Label>
        </div>
      </div>
      <ul>
        {fields
          .filter((field) => !(!hasJobTitle && field.kind === 'job_title'))
          .map((field) => (
            <li
              key={field.kind}
              onClick={() => onFieldClick({ ...field, color })}
              className={cn(
                styles.fieldItem,
                'group rounded-xl px-tw-2 hover:bg-neutral-100',
              )}
            >
              <div className="flex cursor-pointer items-center">
                <div
                  className={cn(styles.fieldCircle, {
                    [styles.dashed]: field.optional,
                    [styles.placed]: isPlaced(field),
                  })}
                >
                  {isPlaced(field) && (
                    <Icon iconName="checkSmall" color={CssVar.white} />
                  )}
                </div>
                <div
                  className={cn('inline-regular ml-tw-2', {
                    ['font-semibold text-neutral-850']: isSelected(field),
                  })}
                >
                  {field.name}
                </div>
              </div>
              <div
                className={cn(
                  styles.fieldItemActions,
                  'group-hover:opacity-100',
                  isSelected(field) ? 'opacity-100' : 'opacity-0',
                )}
              >
                {isPlaced(field) && (
                  <Icon
                    className="mr-tw-1 opacity-70 hover:opacity-100"
                    iconName="ip"
                  />
                )}
                <Icon
                  className="opacity-0 group-hover:opacity-70"
                  iconName="add"
                />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ApproverCard;

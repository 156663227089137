import React, { RefCallback, useCallback, useEffect, useMemo } from 'react';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { DASHBOARD_SKELETON_JSX } from 'bundles/Scoreboard/dumbJSX';
import { ResponsiveGridLayout } from '@/shared/lib/react-grid-layout/ResponsiveGridLayout';
import {
  buildLayoutsId,
  DashboardContext,
  DashboardContextValue,
  DashboardSection,
  ReportDashboardType,
  selectReportEagleEyeDashboardSettingsById,
  useReportEagleEyeDashboardById,
} from 'bundles/Shared/entities/dashboard';
import { useNavigate, useParams } from '@reach/router';
import { DEFAULT_LAYOUT_PROPS } from 'bundles/Shared/widgets/dashboard/layout';
import {
  generateUrl,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { DashboardLayout } from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { EagleEyeDashboardWidget } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { DashboardBoards } from 'bundles/Shared/widgets/dashboard/board/ui/DashboardBoards';
import { DashboardFilterSets } from 'bundles/Shared/widgets/dashboard/filterSet/ui/DashboardFilterSets';
import { DashboardSettingsPageToggle } from '@/pages/report/dashboards/ui/DashboardSettingsPageToggle';

function EagleEyeDashboard(props: { slug?: string }) {
  const { slug = props.slug } = useParams();
  const { boardId } = useQueryParams<{
    boardId?: string;
  }>();
  const navigate = useNavigate();
  const { dashboard, isFetching } = useReportEagleEyeDashboardById({
    dashboardId: slug,
  });
  const dashboardState = useAppSelector((state) =>
    selectReportEagleEyeDashboardSettingsById(
      state,
      buildLayoutsId({
        dashboardId: dashboard?.id ?? '',
        boardId,
      }),
    ),
  );
  const { layouts } = dashboardState ?? {};

  const hasData = dashboard != null;
  const navigateToBoard = (newBoardId: string) => {
    navigate(
      generateUrl(ROUTES_ROOT.scoreboards.scoreboard.fullPath, {
        pathParams: {
          slug,
        },
        queryParams: {
          boardId: newBoardId,
        },
      }),
      {
        replace: true,
      },
    );
  };
  /**
   * Without this hack the move animations are triggered on initial load and all panels fly into position.
   * This can be quite distracting and make the dashboard appear to less snappy.
   */
  const wrapperRefCallback = useCallback<RefCallback<HTMLDivElement>>((ref) => {
    if (ref) {
      setTimeout(() => {
        ref.classList.add('react-grid-layout--enable-move-animations');
      }, 50);
    }
  }, []);

  useEffect(() => {
    if (dashboard == null || dashboard?.boards.length === 0 || boardId) {
      return;
    }
    navigateToBoard(dashboard.boards[0].id);
  }, [dashboard]);

  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardType: ReportDashboardType.EAGLE_EYE,
      dashboardId: dashboard?.id ?? '',
      boardId,
    }),
    [boardId, dashboard],
  );

  const sections = useMemo(() => {
    const board = dashboard?.boards.find((b) => b.id === boardId);
    if (!board || !layouts || !dashboard) {
      return null;
    }
    return (
      <ResponsiveGridLayout
        {...DEFAULT_LAYOUT_PROPS}
        className="w-full"
        useCSSTransforms
      >
        {board.sections.map((section) => {
          const layoutItem = layouts?.lg.find((item) => item.i === section.id);
          return (
            <div
              ref={wrapperRefCallback}
              key={section.id}
              data-grid={layoutItem}
            >
              <DashboardSection color={section.color}>
                <EagleEyeDashboardWidget
                  mode="view"
                  widgetId={section.id}
                  widgetSection={section}
                  dashboardId={dashboard.id}
                  boardId={board.id}
                  assets={dashboard?.assets ?? []}
                  segments={dashboard?.segments ?? []}
                />
              </DashboardSection>
            </div>
          );
        })}
      </ResponsiveGridLayout>
    );
  }, [layouts, dashboard?.sections, boardId]);

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <DashboardLayout className="h-auto">
        <div className="sticky top-0 z-[1] flex flex-col bg-neutral-150 pb-tw-2">
          <DashboardLayout.Header>
            <DashboardLayout.Header.Title
              classes={{
                title: 'header1-bold',
              }}
              withoutBackButton
              title={dashboard?.name ?? ''}
            />
            <div className="grow" />
            <DashboardFilterSets dashboardId={dashboard?.id ?? ''} />
            <DashboardSettingsPageToggle />
          </DashboardLayout.Header>
          {dashboard && (
            <DashboardBoards
              className="px-tw-6"
              boards={dashboard.boards}
              selectedBoardId={boardId}
              onBoardChange={navigateToBoard}
            />
          )}
        </div>
        <DashboardLayout.Body>
          {!isFetching && !hasData ? (
            <NoDataOverlay title="No periods or assets yet" />
          ) : null}
          {isFetching && DASHBOARD_SKELETON_JSX}
          {!isFetching && hasData && sections}
        </DashboardLayout.Body>
      </DashboardLayout>
    </DashboardContext.Provider>
  );
}

export default EagleEyeDashboard;

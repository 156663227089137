import http from '@/lib/http';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import { stringify } from 'qs';
import { UnknownRecord } from 'type-fest/source/internal';

export async function exportFile({
  url,
  params,
  filePostfix = 'sheet',
  enableLoading,
  disableLoading,
}: {
  url: string;
  params?: UnknownRecord;
  filePostfix?: string;
  enableLoading?: VoidFunction;
  disableLoading?: VoidFunction;
}) {
  try {
    enableLoading?.();
    const urlWithParams = `${url}${stringify(params, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
      encode: false,
    })}`;

    const res = await http.get(urlWithParams);

    if (!res.ok) throw res;

    const file = await res.blob();

    saveFileOnUserDevice(
      file,
      `${window.symmetreConfig.customer.name}_${filePostfix}`,
    );
    toastr.success('Export is complete');
  } catch (e) {
    toastr.error(e?.errors);
  } finally {
    disableLoading?.();
  }
}

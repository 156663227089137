import { Commitment } from '@/entities/return';
import { useGetApiCapitalInvestmentCommitmentsQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  GetApiCapitalInvestmentCommitmentsApiArg,
  GetApiCapitalInvestmentCommitmentsApiResponse,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { convertCentsToDollars } from '@/shared/lib/converters';

export function useCapitalCommitments(
  arg: GetApiCapitalInvestmentCommitmentsApiArg,
) {
  const prepareData = useMemo(() => {
    const emptyArray: GetApiCapitalInvestmentCommitmentsApiResponse = [];
    return createSelector(
      (data: GetApiCapitalInvestmentCommitmentsApiResponse | undefined) => {
        return data ?? emptyArray;
      },
      (commitments) => {
        return commitments.map(
          (item) =>
            ({
              ...item,
              amount: convertCentsToDollars(item.amountCents),
              _type: 'commitment',
            }) as Commitment,
        );
      },
    );
  }, []);

  return useGetApiCapitalInvestmentCommitmentsQuery(arg, {
    selectFromResult: (res) => {
      return {
        ...res,
        commitments: prepareData(res.data),
      };
    },
  });
}

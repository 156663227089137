import React from 'react';
import { useGetCompaniesQuery } from 'bundles/Construction/api/companies';
import CreatableSelect from 'react-select/creatable';
import { creatableSelectCreateVendorMessage } from 'bundles/REconcile/components/development/lineItems/utils';
import { CreatableProps } from 'react-select/dist/declarations/src/Creatable';
import { GroupBase } from 'react-select';

type Props = Omit<
  CreatableProps<
    {
      value: string;
      label: string;
    },
    false,
    GroupBase<{
      label: string;
      value: string;
    }>
  >,
  'value'
> & {
  value: string | undefined;
};

function VendorSelect({ value, ...props }: Props) {
  const { data, isLoading } = useGetCompaniesQuery({});
  const options =
    data?.map((item) => ({ value: item.id, label: item.name })) ?? [];
  return (
    <div className="form-item__select">
      <CreatableSelect
        isLoading={isLoading}
        value={options.find((o) => o.label === value)}
        options={options}
        classNamePrefix="react-select"
        formatCreateLabel={creatableSelectCreateVendorMessage}
        {...props}
      />
    </div>
  );
}

export default VendorSelect;

import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import './badge.css';

interface Props {
  direction?: 'horizontal' | 'vertical';
  label?: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
  clickable?: boolean;
  className?: string;
  classes?: {
    label?: string;
    value?: string;
  };
  onClick?: () => void;
}

export function Badge({
  direction = 'horizontal',
  label,
  children,
  className,
  // should we use this? or just use the onClick?
  clickable,
  textColor,
  onClick,
  classes,
  backgroundColor,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <span
      className={cn(
        'sre-badge',
        `sre-badge_${direction}`,
        {
          'sre-badge_standalone': !label,
          'sre-badge_clickable': clickable,
        },
        className,
      )}
      {...props}
      onClick={onClick || undefined}
    >
      {label && (
        <span className={cn('sre-badge__label', classes?.label)}>{label}</span>
      )}
      <span
        style={{
          background: backgroundColor,
          color: textColor,
        }}
        className={cn('sre-badge__value', classes?.value)}
      >
        {children}
      </span>
    </span>
  );
}

export default Badge;

/* eslint-disable react/jsx-wrap-multilines */
import { CapitalEntry, ReportLineItem } from 'bundles/REturn/actions/types';
import QuickFilterCheckList, {
  TQuickFilterCheckListProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import { uniqBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { Button, CurrencyFormatter, Icon, InlineObject } from 'stories/index';
import {
  createExactMatchFilter,
  TRANSACTION_TYPE_FILTER,
} from 'bundles/Shared/components/lineItems/filters';
import { EXACT_MATCH_LABEL_JSX } from '../../../manageCapitalInvestmentModal/Columns/dumbJSX';
import {
  getLineItemTypeFormatter,
  getRemarkFormatter,
} from 'bundles/Shared/components/lineItems/formatters';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@/shared/lib/converters';

interface Params {
  entryAmount: CapitalEntry['amount'];
  transactions: ReportLineItem[];
  resolveIsExactMatch: (transactionAmount: ReportLineItem['amount']) => boolean;
  actions: {
    onLinkTransaction: (r: ReportLineItem) => Promise<void>;
  };
}

export function useAccountingTransactionColumns({
  entryAmount,
  transactions,
  actions,
  resolveIsExactMatch,
}: Params) {
  const columns: IColumn<ReportLineItem>[] = useMemo(() => {
    const createVendorOptions = () => {
      const vendors = uniqBy(
        transactions
          .filter(({ vendorEntity }) => vendorEntity)
          .map(({ vendorEntity }) => vendorEntity),
        'code',
      );
      return vendors;
    };

    return [
      {
        sortable: true,
        dataField: 'date',
        text: 'Tx date',
        formatter: ({ row }) => (
          <span>{formatDate(row.date, 'MMM DD, YYYY')}</span>
        ),
        headerClasses: 'min-w-[120px]',
      },
      {
        dataField: 'period',
        text: 'Period',
        formatter: ({ row }) => (
          <span>{formatDate(row.period, 'MMM-YYYY')}</span>
        ),
        headerClasses: 'min-w-[108px]',
      },
      {
        dataField: 'vender_entity_ids',
        text: 'Vendor',
        formatter: ({ row }) => (
          <InlineObject
            fallbackNode="-"
            iconName="bag"
            object={row.vendorEntity?.name}
          />
        ),
        classes: 'max-w-[300px]',
        headerClasses: 'min-w-[300px]',
        filterComponent: QuickFilterCheckList,
        filterComponentParams: {
          hideWhenItemsLessThanOne: true,
          items: createVendorOptions(),
          getLabel: (item) => item?.name,
          getId: (item) => item?.code,
        } as TQuickFilterCheckListProps<ReportLineItem['vendorEntity']>,
      },
      {
        dataField: 'type',
        text: 'Type',
        formatter: ({ row }) => getLineItemTypeFormatter(row),
        headerClasses: 'min-w-[154px]',
        ...TRANSACTION_TYPE_FILTER,
      },
      {
        dataField: 'amount',
        text: 'Amount',
        formatter: ({ row }) => (
          <div className="flex items-center justify-between">
            {resolveIsExactMatch(convertCentsToDollars(row.amountCents)) &&
              EXACT_MATCH_LABEL_JSX}
            <CurrencyFormatter
              classes={{ value: 'inline-regular text-dark-60' }}
              value={convertCentsToDollars(row.amountCents)}
            />
          </div>
        ),
        headerClasses: 'min-w-[240px]',
        sortable: true,
        ...createExactMatchFilter(convertDollarsToCents(entryAmount)),
      },
      {
        dataField: 'note',
        text: 'Description',
        formatter: getRemarkFormatter((item) => item.description),
        headerClasses: 'min-w-[300px] max-w-[300px]',
        classes: 'max-w-[300px]',
      },
      {
        dataField: 'actions',
        text: 'Actions',
        formatter: ({ row }) => (
          <Button
            variant="secondary"
            size="xs"
            onClick={() => actions.onLinkTransaction(row)}
            className="flex items-center gap-tw-1"
          >
            <Icon iconName="link" className="text-light-60" />
            <span>Link Transaction</span>
          </Button>
        ),
        headerClasses: 'min-w-[168px]',
      },
    ];
  }, [transactions, actions]);
  return columns;
}

/* eslint-disable arrow-body-style */
import { DistributionKind } from 'bundles/REturn/actions/types';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { mustFind } from 'lib/typeHelpers';
import { useMemo } from 'react';
import { ISelectOption } from 'stories/FormControls/Select/Select';
import { assertIsSelectOption } from 'stories/FormControls/Select/utils';
import { IconButton, Select } from 'stories/index';
import { DISTRIBUTION_KIND_SELECT_OPTIONS } from '../../consts';
import { EditableDistribution } from '../../splitModal/SplitDistributions';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { useAllocationContext } from 'bundles/REconcile/components/AllocationProvider';

type ItemId = EditableDistribution['id'];

interface Params {
  capitalInvestments: ICapitalInvestment[];
  actions: {
    onRemove: (id: ItemId) => void;
    onChangeItemById: (id: string, item: Partial<EditableDistribution>) => void;
  };
}

export function useDistributionSplitColumns({
  capitalInvestments,
  actions: { onRemove, onChangeItemById },
}: Params) {
  const { inputProps } = useAllocationContext()!;
  const columns = useMemo<IColumn<EditableDistribution>[]>(() => {
    const capitalInvestmentOptions: ISelectOption<number>[] =
      capitalInvestments.map((capInv) => ({
        id: capInv.id,
        label: capInv.investmentEntity.name,
        value: capInv.investmentEntity.id,
      }));

    const handleEditableDistributionChange = (
      row: EditableDistribution,
      selectedCapitalInvestmentId: ICapitalInvestment['id'],
    ) => {
      const capitalInvestment = mustFind(
        capitalInvestments,
        (capInv) => capInv.id === selectedCapitalInvestmentId,
      );
      onChangeItemById(row.id, {
        capitalInvestmentId: capitalInvestment.id,
        investmentEntity: {
          id: capitalInvestment.investmentEntity.id,
          name: capitalInvestment.investmentEntity.name,
          legalEntityName: capitalInvestment.legalEntity.name,
          totalAmount: capitalInvestment.totalDistributions,
        },
      });
    };

    return [
      {
        dataField: 'investmentEntity.name',
        text: 'Investment Entity',
        formatter: ({ row }) => (
          <span className="dark-60 inline-regular">
            <Select
              inputType="text"
              disabled={row.disableInvestmentEntityChange}
              onSelectedChange={(selected) => {
                assertIsSelectOption(selected);
                const capitalInvestmentId = selected.id;
                handleEditableDistributionChange(row, capitalInvestmentId);
              }}
              selected={capitalInvestmentOptions.find(
                (opt) => opt.id === row.capitalInvestmentId,
              )}
              placeholder="Investment Entity"
              options={capitalInvestmentOptions}
              popoverProps={{
                appendToBody: true,
              }}
            />
          </span>
        ),
      },
      {
        text: 'Legal Entity',
        dataField: 'legalEntity',
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            {item.investmentEntity?.legalEntityName ?? '-'}
          </span>
        ),
      },
      {
        text: 'Amount',
        dataField: 'amount',
        formatter: ({ row: item }) => (
          <CurrencyInputNumber
            allowNegative={false}
            onValueChange={(e) => {
              onChangeItemById(item.id, {
                ...item,
                amount: Number(e ?? 0),
              });
            }}
            error={inputProps.error}
          />
        ),
      },
      {
        text: 'Category',
        dataField: 'category',
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            <Select<DistributionKind>
              inputType="text"
              onSelectedChange={(selected) => {
                assertIsSelectOption(selected);
                onChangeItemById(item.id, { kind: selected.id });
              }}
              selected={DISTRIBUTION_KIND_SELECT_OPTIONS.find(
                ({ id }) => id === item.kind,
              )}
              placeholder="Select Category"
              options={DISTRIBUTION_KIND_SELECT_OPTIONS}
              popoverProps={{
                appendToBody: true,
              }}
            />
          </span>
        ),
      },
      {
        text: '',
        dataField: 'actions',
        headerStyle: {
          width: 40,
        },
        formatter: ({ row: item }) => (
          <div className="flex justify-center">
            <IconButton
              disabled={item.disableRemove}
              onClick={() => onRemove(item.id)}
              iconName="closeSmall"
            />
          </div>
        ),
      },
    ];
  }, [capitalInvestments]);

  return columns;
}

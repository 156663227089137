import { useState } from 'react';
import { TPerPageSizeOptions } from '../../../bundles/Shared/components/Table/pagination/TablePagination';

export interface IPagination {
  sizePerPage: TPerPageSizeOptions;
  currentPage: number;
}

export default function usePagination(initialState: IPagination) {
  const [pagination, setPagination] = useState(initialState);

  const setSizePerPage = (size: TPerPageSizeOptions) =>
    setPagination({
      ...pagination,
      sizePerPage: size,
    });

  const setCurrentPage = (page: number) =>
    setPagination({
      ...pagination,
      currentPage: page,
    });

  return {
    setSizePerPage,
    setCurrentPage,
    setPagination,
    pagination,
  };
}

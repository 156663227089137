import React from 'react';
import styles from './RowsAction.module.scss';

interface Props {
  items: {
    label: string;
    onClick: () => void;
  }[];
}

const RowsAction = ({ items }: Props) => {
  return (
    <div>
      {items.map(({ label, onClick }, index) => (
        <div
          key={index}
          onClick={onClick}
          className={`${styles.item} secondary-regular`}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export default RowsAction;

import { cn } from '@/shared/lib/css/cn';
import DropdownItem from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react/headless';
import React, { CSSProperties, memo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Instance } from 'tippy.js';
import './dropdown.css';

type ExcludedTippyProps = Omit<
  TippyProps,
  'render' | 'interactive' | 'onCreate' | 'animation' | 'onMount' | 'onHide'
>;

interface Props extends ExcludedTippyProps {
  items: React.ReactNode | React.ReactNode[];
  classNameContainer?: string;
  maxWidth?: CSSProperties['maxWidth'];
  hideOnExpandedAreaClick?: boolean;
}

// @ts-expect-error `Item` component will be added later
export const Dropdown: React.MemoExoticComponent<
  (props: Props) => React.JSX.Element
> & { Item: typeof DropdownItem } = memo(
  ({
    placement = 'bottom-end',
    items,
    className,
    classNameContainer,
    children,
    hideOnExpandedAreaClick = true,
    maxWidth = '11.5rem',
    ...props
  }: Props) => {
    const [instance, setInstance] = useState<Instance | null>(null);
    const config = { duration: 150 };
    const initialStyles = { opacity: 0 };
    const [styles, setSpring] = useSpring(() => initialStyles);
    const onMount = () => {
      setSpring({
        opacity: 1,
        config,
      });
    };
    const onHide = ({ unmount }) => {
      setSpring({
        ...initialStyles,
        onRest: unmount,
        config: { ...config, clamp: true },
      });
    };
    return (
      <Tippy
        interactive
        trigger="click"
        onCreate={(tippy) => setInstance(tippy)}
        render={(attrs) => (
          <animated.div style={styles}>
            <ul
              onClick={
                hideOnExpandedAreaClick
                  ? () => {
                      instance?.hide();
                      props.onHidden?.(instance);
                    }
                  : undefined
              }
              className={cn('sre-dropdown', className)}
              style={{ maxWidth }}
              tabIndex="-1"
              {...attrs}
            >
              {items}
            </ul>
          </animated.div>
        )}
        offset={[0, 2]}
        animation
        onMount={onMount}
        onHide={onHide}
        placement={placement}
        {...props}
      >
        <span className={cn('sre-dropdown_container', classNameContainer)}>
          {children}
        </span>
      </Tippy>
    );
  },
);

Dropdown.Item = DropdownItem;

export default Dropdown;

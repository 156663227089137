import React, { ReactNode } from 'react';
import { IChangeOrder } from 'bundles/Construction/types';
import {
  fetchChangeOrder,
  selectFinalDocumentCO,
} from 'bundles/Construction/actions/changeOrders';
import { uploadChangeOrderSharedFiles } from '../../actions';
import { removeSharedFiles } from '../../../Assets/actions';
import DocumentsUpload from '../DocumentsUpload';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { AnimationLoader } from 'stories';

interface Props {
  changeOrder: IChangeOrder;
  legalEntityCode: string;
  children: ReactNode;
}

function DocumentsCO({ changeOrder, legalEntityCode, children }: Props) {
  const dispatch = useAppDispatch();

  const uploadFilesCO = async (_, id: number, files) => {
    const filesData = await dispatch(
      uploadChangeOrderSharedFiles(legalEntityCode, id, files),
    );
    return filesData;
  };

  if (!changeOrder?.documents) return <AnimationLoader />;

  return (
    <div className="change-card overflow-hidden">
      <div className="change-card__header">
        <div className="change-card__title header6-bold">Documents</div>
      </div>
      <DocumentsUpload
        uploadFiles={uploadFilesCO}
        documents={changeOrder?.documents}
        id={changeOrder?.id}
        legalEntityCode={legalEntityCode}
        selectHandle={async (_, id, idFile) =>
          dispatch(selectFinalDocumentCO(legalEntityCode, id!, idFile))
        }
        removeItems={(ids) => dispatch(removeSharedFiles(ids))}
        refresh={async () =>
          dispatch(fetchChangeOrder(legalEntityCode, changeOrder.id))
        }
        isReadOnly={changeOrder.status !== 'DRAFT'}
        canUpload={changeOrder.status === 'DRAFT'}
        finalFile={changeOrder?.contractFileId}
        finalFileStatus={
          changeOrder.status === 'APPROVED' ? 'Approved' : 'Verified'
        }
        supportSelect={changeOrder.status === 'DRAFT'}
      />
      {children}
    </div>
  );
}

export default DocumentsCO;

import { ValueDisplayOptionsFields } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ValueDisplayOptionsFields';
import { Button, Field, RadioButton, Tumbler } from '@/stories';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { COMPARE_TO_COLOR_RULE_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { SortingField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SortingField';
import React, { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';

export const ResetButton = ({
  name,
  onClick,
  children,
}: React.PropsWithChildren<{ name: string; onClick?: VoidFunction }>) => {
  const { resetField } = useFormContext();

  return (
    <Button
      variant="secondary"
      size="xs"
      onClick={onClick ?? (() => resetField(name))}
    >
      {children ?? 'Reset'}
    </Button>
  );
};

export const useColumnsConfigOptions = () => {
  const columnsConfig = [];

  const options = useMemo(
    () =>
      columnsConfig.map((c) => ({
        label: c.name,
        value: c.key,
      })),
    [columnsConfig],
  );

  const findOptionByKey = useCallback(
    (key: string) => options.find((o) => o.value === key),
    [options],
  );

  return {
    findOptionByKey,
    options,
  } as const;
};

export function StylingFields() {
  const {
    options: columnsConfigOptions,
    findOptionByKey: findColumnConfigOptionByKey,
  } = useColumnsConfigOptions();
  const { control, resetField, setValue, trigger } = useFormContext();
  return (
    <>
      <SortingField control={control} name="sort" />
      <SectionFieldsContainer>
        <Controller
          name="style.color.showCompareTo"
          control={control}
          render={({ field }) => (
            <SectionField
              button={
                <Tumbler
                  onChange={() => {
                    field.onChange(!field.value);
                    setValue('style.color.compareTo', null);
                  }}
                  checked={field.value}
                />
              }
              labelText="Comparison"
              note="Here you can set the comparison rules for the column"
            >
              {field.value && (
                <Field
                  required
                  labelText="Compare with"
                  note="Which column you would like to compare the result to?"
                >
                  <Controller
                    control={control}
                    name="style.color.compareTo"
                    render={({ field: compareToField }) => (
                      <SharedSelect
                        placeholder="Select column"
                        menuPortalTarget={document.body}
                        value={findColumnConfigOptionByKey(
                          compareToField.value,
                        )}
                        onChange={(e) => compareToField.onChange(e.value)}
                        options={columnsConfigOptions}
                      />
                    )}
                  />
                </Field>
              )}
            </SectionField>
          )}
        />
        <Controller
          control={control}
          name="style.color.rule"
          render={({ field, formState }) => (
            <SectionField
              labelText="Highliting Rules"
              button={
                <Tumbler
                  onChange={(e) => {
                    field.onChange(
                      e.target.checked
                        ? COMPARE_TO_COLOR_RULE_OPTIONS[0].value
                        : null,
                    );
                  }}
                  checked={field.value != null}
                />
              }
            >
              {field.value != null && (
                <div className="flex flex-col gap-tw-2">
                  {COMPARE_TO_COLOR_RULE_OPTIONS.map((o) => (
                    <RadioButton
                      key={o.value}
                      checked={field.value === o.value}
                      onChange={(e) => field.onChange(o.value)}
                    >
                      {o.label}
                    </RadioButton>
                  ))}
                </div>
              )}
            </SectionField>
          )}
        />
      </SectionFieldsContainer>
      <ValueDisplayOptionsFields
        control={control}
        name="value_display_options"
      />
    </>
  );
}

import React, { FC, SetStateAction } from 'react';
import { Button, Textarea } from 'stories';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { changeReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';

interface IReviseFormProps {
  setShowCECreateModal: (type: 'revise') => void;
  commentRevise: string;
  setCommentRevise: SetStateAction<string>;
}

const ReviseForm: FC<IReviseFormProps> = ({
  setShowCECreateModal,
  commentRevise,
  setCommentRevise,
}) => {
  const dispatch = useAppDispatch();

  const reviseHandle = async () => {
    setShowCECreateModal('revise');
    dispatch(changeReconcileActiveAction(null));
  };
  return (
    <div>
      <div className="ce-bb-line px-m py-m">
        <div className="light-90 inline-semibold mb-xs">
          Leave Revision Comment
        </div>
        <div className="secondary-regular mb-s">
          To start the revision process please leave a comment to explain the
          reason for the revision
        </div>
        <Textarea
          placeholder="Enter Comment"
          onChange={(e) => setCommentRevise(e.target.value)}
          value={commentRevise}
        />
      </div>
      <div className="px-m py-m">
        <Button
          variant="success"
          disabled={!commentRevise}
          onClick={reviseHandle}
          className="w-full"
        >
          Revise
        </Button>
      </div>
    </div>
  );
};

export default ReviseForm;

import React from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import MirrorModeCard from 'bundles/Shared/components/LeftSidebar/cards/MirrorModeCard';
import { IUser } from 'types/User';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps {
  items: IUser[];
}

const AdviseesModal = ({ onClose, items }: Props) => (
  <SidePanel toggle={onClose} size="md" header="Advisees">
    <MirrorModeCard users={items} maxHeight="none" />
  </SidePanel>
);

export default AdviseesModal;

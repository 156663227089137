import React, { useEffect, useMemo } from 'react';
import ChangeManagementInfoCard, {
  TInfoCardType,
} from 'bundles/Construction/components/cards/ChangeManagementInfoCard/ChangeManagementInfoCard';
import ChangeManagementCEFilterCard from 'bundles/Construction/components/cards/ChangeManagementFilterCard/ChangeManagementCEFilterCard';
import { IPageParams, TFilterCardType } from 'bundles/Construction/types';
import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader, FilterCard } from 'stories/index';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useParams } from '@reach/router';
import styles from './BadgesBar.module.scss';
import { fetchChangeOrderEventbadges } from '../../../actions';

const INFO_CARDS: TInfoCardType[] = [
  'currentContingency',
  'projectedContingency',
  'internalContingency',
];
const FILTER_CARDS: TFilterCardType[] = [
  'completed',
  'approved',
  'pending',
  'irc',
];
const FILTER_CARDS_PARAMS_MAPPING: Record<
  TFilterCardType,
  Partial<IPageParams>
> = {
  irc: {
    categoryList: ['IRC'],
  },
  pending: {
    categoryList: ['PCO', 'COR'],
    statusList: [
      'PENDING-PROCEEDING',
      'PENDING-NOT PROCEEDING',
      'DRAFT',
      'DISPUTED',
    ],
  },
  completed: {
    statusList: ['COMPLETED'],
  },
  approved: {
    statusList: ['APPROVED'],
  },
};

interface Props {
  setParams: (pageParams: IPageParams) => void;
  pageParams: IPageParams;
}

const BadgesBar = (props: Props) => {
  const { setParams, pageParams } = props;
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const asset = useAppSelector((state) => state.currentAsset);
  const construction = useAppSelector((state) => state.construction);
  const params = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchChangeOrderEventbadges(
        params?.legalEntityCode ||
          asset?.legalEntities[0].code ||
          legalEntity?.code,
      ),
    );
  }, []);

  const filteredCards = useMemo(
    () => [
      ...(pageParams.categoryList ===
      FILTER_CARDS_PARAMS_MAPPING.irc.categoryList
        ? ['irc']
        : []),
      ...(pageParams.categoryList ===
        FILTER_CARDS_PARAMS_MAPPING.pending.categoryList &&
      pageParams.statusList === FILTER_CARDS_PARAMS_MAPPING.pending.statusList
        ? ['pending']
        : []),
      ...(pageParams.statusList ===
      FILTER_CARDS_PARAMS_MAPPING.completed.statusList
        ? ['completed']
        : []),
      ...(pageParams.statusList ===
      FILTER_CARDS_PARAMS_MAPPING.approved.statusList
        ? ['approved']
        : []),
    ],
    [pageParams],
  );

  const handleFilter = (cardType: TFilterCardType) => {
    setParams({
      ...pageParams,
      ...(!filteredCards.includes(cardType)
        ? FILTER_CARDS_PARAMS_MAPPING[cardType]
        : {
            statusList: [],
            categoryList: [],
          }),
    });
  };

  if (construction == null) {
    return null;
  }

  const transformReport = (report) => ({
    amount: report.amount,
    approvedCo: report.used.completedCes,
    approvedCor: report.used.approvedCes,
    otherReallocations: report.used.otherAllocations,
    usedPercent: report.used.percent,

    pendingCor: report.pending?.corPending,
    pendingPco: report.pending?.pcosSubmitted,
    disputed: report.pending?.corsDisputed,
    pendingPercent: report.pending?.percent,

    remainingPercent: report.remainingPercent,
    ircPercent: report.ircPercent,
  });

  if (construction.badgesinfo == null) {
    return <AnimationLoader className="static min-h-[100px]" />;
  }

  return (
    <div className={cn(styles.container, 'mb-l mt-m')}>
      <FilterCard
        header={
          <div className="flex flex-col">
            <span className="inline-semibold">Initial Contingency</span>
            <span className="lead-semibold">
              {accountingNumberFormat(
                construction.badgesinfo.initialContingency,
              )}
            </span>
          </div>
        }
        content={<></>}
      />
      {INFO_CARDS.map((c) => (
        <ChangeManagementInfoCard
          key={c}
          report={transformReport(construction.badgesinfo[c])}
          cardType={c}
        />
      ))}
      {FILTER_CARDS.map((c) => (
        <ChangeManagementCEFilterCard
          key={c}
          filtered={filteredCards.includes(c)}
          onFilter={() => handleFilter(c)}
          report={transformReport(construction.badgesinfo[c])}
          initialContingency={construction.badgesinfo.initialContingency}
          cardType={c}
        />
      ))}
    </div>
  );
};

export default BadgesBar;

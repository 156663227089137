export interface IVendorFundingSource {
  id: number;
  name: string;
}

export enum FUNDING_SOURCE_KINDS {
  GENERAL_PARTNER = 'general_partner',
  LIMITED_PARTNER = 'limited_partner',
  LENDER = 'lender',
}

export interface FundingSource {
  id: number;
  name: string;
  kind: `${FUNDING_SOURCE_KINDS}`;
  amount: RawFloat;
  balance: RawFloat;
  fundedToDate: RawFloat;
  vendor: IVendorFundingSource;
}

export type FundingSourceForDraw = Pick<
  FundingSource,
  'id' | 'amount' | 'name' | 'balance'
>;

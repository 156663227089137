import http from 'lib/http';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';

export const FETCH_LAST_PAY_APP = 'symmetre-client-api/FETCH_LAST_PAY_APP';
export const FETCH_PAY_APP = 'symmetre-client-api/FETCH_PAY_APP';
export const SEARCH_PAY_APPS = 'symmetre-client-api/SEARCH_PAY_APPS';
export const CREATE_PAY_APP = 'symmetre-client-api/CREATE_PAY_APP';
export const UPDATE_PAY_APP_SUMMARY = 'symmetre-client-api/UPDATE_PAY_APP_SUMMARY';
export const UPDATE_CONTINUATION_SHEET_ITEM = 'symmetre-client-api/UPDATE_CONTINUATION_SHEET_ITEM';
export const DESTROY_CONTINUATION_SHEET_ITEM = 'symmetre-client-api/DESTROY_CONTINUATION_SHEET_ITEM';

export function fetchLastPayApp(legalEntityCode) {
  return async function (dispatch) {
    const res = await http.get(`/legal_entities/${legalEntityCode}/pay_apps/last`);
    let payload = true;
    if (res.status === 200) {
      payload = await res.json();
    }
    dispatch({ type: FETCH_LAST_PAY_APP, payload });
    return payload;
  };
}

export function fetchPayApp(legalEntityCode, id) {
  return async function (dispatch) {
    const res = await http.get(`/legal_entities/${legalEntityCode}/pay_apps/${id}`);
    let payload = true;
    if (res.status === 200) {
      payload = await res.json();
    }
    dispatch({ type: FETCH_PAY_APP, payload });
  };
}

export function searchPayApps(legalEntityCode, keyword, sort_order, sort_field) {
  return async function (dispatch) {
    const res = await http.get(`/legal_entities/${legalEntityCode}/pay_apps?keyword=${keyword}&sort_order=${sort_order}&sort_field=${sort_field}`);
    const responseData = await res.json();

    dispatch({
      type: SEARCH_PAY_APPS,
      payload: responseData,
    });
  };
}

export function updatePayAppSummary(legalEntityCode, payAppId, id, data) {
  return async function (dispatch) {
    const res = await http.put(`/legal_entities/${legalEntityCode}/pay_apps/${payAppId}/pay_app_summaries/${id}`, data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('PayApp Summary has been successfully updated');

      dispatch({
        type: UPDATE_PAY_APP_SUMMARY,
        payload: { id: payAppId, payAppSummary: responseData },
      });
    }
  };
}

export function updateContinuationSheetItem(legalEntityCode, payAppId, id, data) {
  return async function (dispatch) {
    const res = await http.put(`/legal_entities/${legalEntityCode}/pay_apps/${payAppId}/continuation_sheet_items/${id}`, data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Continuation Sheet has been successfully updated');

      dispatch({
        type: UPDATE_CONTINUATION_SHEET_ITEM,
        payload: { id: payAppId, continuationSheet: responseData },
      });
    }
  };
}

export function destroyContinuationSheetItem(legalEntityCode, payAppId, id) {
  return async function (dispatch) {
    const res = await http.del(`/legal_entities/${legalEntityCode}/pay_apps/${payAppId}/continuation_sheet_items/${id}`);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Continuation Sheet has been successfully updated');

      dispatch({
        type: DESTROY_CONTINUATION_SHEET_ITEM,
        payload: { id: payAppId, continuationSheet: responseData },
      });
      return true;
    }
  };
}

export function createPayApp(legalEntityCode, data) {
  return async function (dispatch) {
    const res = await http.post(`/legal_entities/${legalEntityCode}/pay_apps`, data);
    const responseData = await res.json();
    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('PayApp has been successfully created');
      dispatch({
        type: CREATE_PAY_APP,
        payload: responseData,
      });
    }
  };
}

export async function exportContinuationSheet(legalEntityCode, id) {
  const res = await http.post(`/legal_entities/${legalEntityCode}/pay_apps/${id}/export_continuation_sheet.xlsx`);
  const file = await res.blob();
  saveFileOnUserDevice(
    file,
    `${window.symmetreConfig.customer.subdomain}-continuation-sheet.xlsx`,
  );
}

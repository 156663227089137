import http from 'lib/http';
import { ICustomTemplate } from '../components/EmailSettings/editor/CustomTemplateEdit';
import * as Type from '../components/EmailSettings/editor/types';

interface ICustomTemplateParams {
  id?: number;
  template?: ICustomTemplate;
  usedVariables?: Type.InvitationTemplateVars[];
}

const buildCustomTemplateParams = ({
  id,
  template,
  usedVariables,
}: ICustomTemplateParams) => ({
  id,
  custom_template: {
    user_role_ids: template?.userRoles?.map((userRole) => userRole.id),
    user_ids: template?.users?.map((user) => user.id),
    schedule: template.schedule ? template.schedule : null,
    name: template?.name,
    template_attributes: {
      subject: template?.subject,
    },
    sections_attributes: template?.sections?.map((section) => {
      const result = {
        position: section.position,
        _destroy: section._destroy,
        id: section.id,
        type: section.type,
      };

      if (section.type === 'text') {
        result.text_section_attributes = {
          text: section.textSection?.text,
        };
      }

      if (section.type === 'header') {
        result.header_section_attributes = {
          text_attributes: {
            text_variables_attributes: usedVariables?.header,
            content: section.headerSection?.text,
          },
        };
      }

      return result;
    }),
  },
});

export async function getCustomTemplateVariables() {
  const result = await http.get('/custom_template_variables');

  return result.json();
}

export async function customTemplatePreviews(payload: ICustomTemplateParams) {
  const result = await http.post(
    '/custom_template_previews',
    buildCustomTemplateParams(payload),
  );
  const json = await result.json();

  return json.body;
}

export async function getCustomTemplate(id: number) {
  const result = await http.get(`/custom_templates/${id}`);
  return result.json();
}

export async function plainFetchReplyRecipients() {
  const res = await http.get('/settings/emails/templates/reply_recipients');
  return res.json() as Promise<Type.ReplyRecipient[]>;
}

export async function createCustomTemplate(params) {
  const res = await http.post(
    '/custom_templates',
    buildCustomTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Custom template has been successfully created');
  }

  return responseData;
}

export async function updateCustomTemplate(params: ICustomTemplateParams) {
  const res = await http.put(
    `/custom_templates/${params.id}`,
    buildCustomTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Custom template has been successfully updated');
  }

  return responseData;
}

export async function removeCustomTemplate({ id }: { id: number }) {
  const res = await http.del(`/custom_templates/${id}`);
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Custom template have been successfully removed');
  }

  return responseData;
}

export async function sendCustomTemplateEmail(params: ICustomTemplateParams) {
  const res = await http.post(
    '/custom_template_emails',
    buildCustomTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Email has been sent');
  }

  return responseData;
}

import { maxIdGenerator } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { FinancialTableSingleDateWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { produce } from 'immer';
import { UnknownRecord } from 'type-fest/source/internal';

type FinancialTableWidgetSection =
  | FinancialTableSinglePeriodWidgetSection
  | FinancialTableSingleDateWidgetSection;

type Updater<Args extends UnknownRecord> = <
  Section extends FinancialTableWidgetSection,
>(
  args: Args,
  widgetConfig: Section['widgetConfig'],
) => Section['widgetConfig'];

const upsertGroup: Updater<{
  headerName: string;
  groupId?: string;
}> = ({ headerName, groupId }, widgetConfig) => {
  return produce(widgetConfig, (draft) => {
    if (groupId) {
      const index =
        draft.viz_config?.column_groups?.findIndex(
          (cd) => cd.group_id.toString() === groupId,
        ) ?? -1;

      if (index === -1) return;

      draft.viz_config!.column_groups![index].header_name = headerName;
      return;
    }

    draft.viz_config?.column_groups?.push({
      group_id: String(
        maxIdGenerator(draft.viz_config.column_groups, 'group_id'),
      ),
      header_name: headerName,
      icon: null,
      background: 'transparent',
      border_color: 'transparent',
      order: draft.viz_config.column_groups.length,
    });
  });
};

export const financialTableUpdaters = {
  cloneColumn: commonTableWidgetUpdaters.cloneColumn,
  removeColumn: commonTableWidgetUpdaters.removeColumn,
  moveColumn: commonTableWidgetUpdaters.moveColumn,
  toggleColumnHidden: commonTableWidgetUpdaters.toggleColumnHidden,
  toggleAllColumnsHidden: commonTableWidgetUpdaters.toggleAllColumnsHidden,

  removeGroup: commonTableWidgetUpdaters.removeGroup,
  upsertGroup,
  moveGroup: commonTableWidgetUpdaters.moveGroup,
  toggleGroupHidden: commonTableWidgetUpdaters.toggleGroupHidden,
};

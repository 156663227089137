import React, { useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Button, ColorTag } from 'stories';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { IFireAlertCard } from 'types/FireAlertCard';
import PermissionList from 'bundles/Shared/components/PermissionList';
import {
  normalizeModuleTagLabel,
  MODULES,
  generatePopoverContent,
} from '../utils/utils';
import { FireEventCover } from '../FireEventCover/FireEventCover';
import { FireTag } from '../FireTag/FireTag';
import './FireAlertCard.css';
import { alertDataFormatter } from './utils';

interface Props {
  fireAlertCard: IFireAlertCard;
  className?: string;
  fireAlertCardResolver: (kind: string) => void;
}

export const FireAlertCard = ({
  className,
  fireAlertCard,
  fireAlertCardResolver,
  ...props
}: Props) => {
  const [hover, setHover] = useState(false);

  const data = alertDataFormatter(fireAlertCard);
  if (!data) return null;

  return (
    <div
      className={cn(
        'sre-fire-event-card flex rounded-[1rem] bg-white p-m',
        className,
      )}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      <div className="sre-fire-event-card__marker flex-[0_0_32px]  pr-m">
        <FireEventCover priority={fireAlertCard.priority} />
      </div>
      <div className="sre-fire-event-card__content flex-grow">
        <div className="mb-s flex items-start gap-m">
          <div>
            <h5 className="sre-fire-event-card__title dark-60">{data.title}</h5>
            <span className="secondary-regular">
              {formatUnixDate(fireAlertCard.latestAlertDate, 'LL [at] LT')}
            </span>
          </div>
          <div className="ml-auto flex flex-[0_0_auto] items-center gap-s text-light">
            <span className="light-60 label-regular">Assigned to:</span>
            <PermissionList
              hideAvatar
              hideIcon
              permissions={generatePopoverContent(fireAlertCard.recipients)}
              className="d-inline-flex"
              mainListClasses="label-regular dark-60"
            />
            <ColorTag
              variant={MODULES[fireAlertCard.module].color}
              label={normalizeModuleTagLabel(fireAlertCard.module)}
              disabled
            />
            <FireTag priority={fireAlertCard.priority} disabled />
          </div>
        </div>
        <p className="inline-regular mb-m">{data.body}</p>
        <Button
          size="s"
          variant={hover ? 'primary' : 'secondary'}
          onClick={() => fireAlertCardResolver(fireAlertCard.kind)}
        >
          {data.actionText}
        </Button>
      </div>
    </div>
  );
};

export default FireAlertCard;

import { legalEntitiesSettingsApi } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApi';

enum TAGS {
  LEGAL_ENTITIABLES = 'LegalEntitiables',
}

legalEntitiesSettingsApi.enhanceEndpoints({
  addTagTypes: [TAGS.LEGAL_ENTITIABLES],
  endpoints: {
    getApiSettingsCoreLegalEntitiesLegalEntitiables: {
      providesTags: [TAGS.LEGAL_ENTITIABLES],
    },
  },
});

export const { useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery } =
  legalEntitiesSettingsApi;

import React from 'react';
import prettyBytes from 'pretty-bytes';
import { currentUserIsExternal } from 'lib/permissions';

interface Props {
  size?: number;
  extension?: string;
  permissions?: string;
}

const FileInfo = ({ size, extension, permissions }: Props) => {
  const permissionsIsVisible = permissions && !currentUserIsExternal();
  return (
    <p className="secondary-regular flex items-center text-light-60">
      {size && prettyBytes(size)}
      {size && extension && ' · '}
      {extension && extension}
      {extension && permissionsIsVisible && ' · '}
      {permissionsIsVisible && <div className="ml-xs">{permissions}</div>}
    </p>
  );
};

export default FileInfo;

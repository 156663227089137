import { ISortParamsField } from 'types/Sort';
import React, { useCallback } from 'react';
import { ISortSettings } from 'bundles/Shared/components/Table/types';

type TGetSortSettings<T> = T extends ISortParamsField<infer Sort extends string>
  ? ISortSettings<Sort>
  : ISortSettings<string>;

export function usePageParamsTableSorting<T extends ISortParamsField>(
  pageParams: T,
  setPageParams: React.Dispatch<React.SetStateAction<T>>,
) {
  const setSettings = useCallback(
    (settings: TGetSortSettings<T>) => {
      setPageParams((prevParams) => ({
        ...prevParams,
        sort: settings.sortOrder && {
          order: settings.sortOrder,
          field: settings.sortField,
        },
      }));
    },
    [setPageParams],
  );

  return {
    settings: {
      sortField: pageParams?.sort?.field,
      sortOrder: pageParams?.sort?.order,
    } as TGetSortSettings<T>,
    setSettings,
  };
}

import http from 'lib/http';
import { stringify } from 'qs';

export const CREATE_DOCUMENT_TYPE = 'symmetre-client-api/CREATE_DOCUMENT_TYPE';
export const UPDATE_DOCUMENT_TYPE = 'symmetre-client-api/UPDATE_DOCUMENT_TYPE';
export const DELETE_DOCUMENT_TYPE = 'symmetre-client-api/DELETE_DOCUMENT_TYPE';

export function plainFetchDocumentTypes(data) {
  const dataParams = { indices: false, arrayFormat: 'brackets', encode: false };

  return http.get(`/document_types?${stringify(data, dataParams)}`).then((response) => response.json());
}

export function createDocumentType(data) {
  return async function (dispatch) {
    try {
      const res = await http.post('/document_types', data);
      const payload = await res.json();

      if (payload.errors != null) {
        toastr.error(payload.errors[0]);
      } else {
        toastr.success('Document type was successfully created');
        dispatch({ type: CREATE_DOCUMENT_TYPE, payload });
      }
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function updateDocumentType(id, data) {
  return async function (dispatch) {
    try {
      const res = await http.put(`/document_types/${id}`, data);
      const payload = await res.json();
      if (payload.errors != null) {
        toastr.error(payload.errors[0]);
      } else {
        toastr.success('Document type was successfully updated');
        dispatch({ type: UPDATE_DOCUMENT_TYPE, payload });
      }
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function deleteDocumentType(id) {
  return async function (dispatch) {
    try {
      const res = await http.del(`/document_types/${id}`);
      if (res.status === 403) {
        toastr.error('Document type assigned to some document message and can not be deleted');
      } else {
        const payload = await res.json();

        toastr.success('Document type was successfully deleted');
        dispatch({ type: DELETE_DOCUMENT_TYPE, payload });
      }
    } catch {
      throw new Error(`Can not delete document type with id ${id}`);
    }
  };
}

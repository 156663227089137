import * as React from 'react';
import { Button } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './Segment.module.scss';

interface Props {
  disabled?: boolean;
  label: string;
  onClick?: () => void;
}

function Segment({ label, disabled, onClick }: Props) {
  return (
    <Button
      className={cn(styles.segment, {
        [styles.segment_disabled]: disabled,
      })}
      offHoverStyles
      iconName="segments"
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

export default Segment;

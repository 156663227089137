import { cn } from '@/shared/lib/css/cn';
import PermissionList from 'bundles/Shared/components/PermissionList';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';
import { PhoneLink } from 'bundles/Shared/shared/PhoneLink';
import { sortBy, uniqBy } from 'lodash-es';
import React from 'react';
import { Icon, Popover, PseudoLink, ReturnableTag, Tumbler } from 'stories';
import { ClassNameProps } from 'types/Props';
import { IUser } from 'types/User';

interface ListItemBase {
  id: number | string;
  name: string;
  excluded?: boolean;
  undeselectable?: boolean;
}

interface ListProps<LI extends ListItemBase> extends ClassNameProps {
  items: LI[];
  onClose: (item: LI) => void;
  closable?: boolean;
  title?: string;
}

export function CommonList<LI extends ListItemBase>({
  items,
  onClose,
  closable,
  title,
  className,
}: ListProps<LI>) {
  return (
    <div className={className}>
      <h6 className="mb-tw-2 text-light-60">{title}</h6>
      <div className="flex flex-wrap gap-tw-1">
        {items.map((item) => (
          <ReturnableTag
            label={item.name}
            key={item.id}
            defaultExcluded={item.excluded}
            onExclude={() => onClose(item)}
            onInclude={() => onClose(item)}
            disabled={!closable || item.undeselectable}
          />
        ))}
      </div>
    </div>
  );
}

export function RolesList<LI extends ListItemBase>(props: ListProps<LI>) {
  return <CommonList {...props} title="Roles" />;
}

export function TagsList<LI extends ListItemBase>(props: ListProps<LI>) {
  return <CommonList {...props} title="Tags" />;
}

export function FiltersList<LI extends ListItemBase>(props: ListProps<LI>) {
  return <CommonList {...props} title="Quick filters" />;
}

export const AssetsList = <LI extends ListItemBase>(props: ListProps<LI>) => <CommonList {...props} title="Assets" />;

export const FundsList = <LI extends ListItemBase>(props: ListProps<LI>) => <CommonList {...props} title="Funds" />;

export function InvestmentEntitiesList<LI extends ListItemBase>({
  items,
  onClose,
  closable,
}: ListProps<LI>) {
  return (
    <CommonList
      items={items}
      onClose={onClose}
      closable={closable}
      title="Entities"
    />
  );
}

export const DocumentTypesList = <LI extends ListItemBase>(props: ListProps<LI>) => (
  <CommonList {...props} title="Document Types" />
);

export function UsersList<
  LI extends Pick<
    IUser,
    'id' | 'tags' | 'fullName' | 'role' | 'email' | 'phone' | 'investmentEntities'
  > & { excluded?: boolean },
>({
  members,
  onClose,
  closable,
  onToggle = undefined,
  autoSelectedMembers = [],
  displayTagsColumn = true,
  customColumns = [],
  title = 'Members',
  classes = 'mt-s',
}: {
  members: LI[];
  onClose?: (item: LI) => void;
  onToggle?: (item: LI) => void;
  closable?: boolean;
  autoSelectedMembers?: LI[];
  title?: string;
  displayTagsColumn?: boolean;
  customColumns?: { title: string; buildCell: (l: LI) => React.ReactNode }[];
  classes?: string;
}) {
  const tableItems = (items: LI[]) => (
    <tbody>
      {items.map((user) => {
        const userNameClass = 'flex justify-between';
        return (
          <tr key={user.id} className={cn({ 'bg-light-10': user.excluded })}>
            <td className={cn('flex items-center', userNameClass)}>
              <div className="flex gap-tw-2">
                {onToggle && (
                  <Tumbler
                    className="mt-[0.2rem]"
                    checked={!user.excluded}
                    onChange={() => onToggle(user)}
                  />
                )}
                <div id={`user-info-${user.id}`}>
                  <Popover
                    placement="left-end"
                    maxWidth="25rem"
                    arrowPosition="end"
                    className="p-075rem"
                    offset={[0, 10]}
                    template={
                      <div className="text-left">
                        <h6 className="dark-60">{user.fullName}</h6>
                        {user?.role?.name && (
                          <div className="light-60">{user.role.name}</div>
                        )}
                        {user?.email && (
                          <div className="mt-s flex items-center">
                            <div className="advisee-card__details-badge mr-s h-[1.5rem] w-[1.5rem] flex-[0_0_24px] bg-light-5">
                              <Icon iconName="email" />
                            </div>
                            <EmailLink
                              email={user.email}
                              className="word-break inline-regular"
                            >
                              {user.email}
                            </EmailLink>
                          </div>
                        )}
                        {user.phone && (
                          <div className="mt-s flex items-center">
                            <div className="advisee-card__details-badge mr-s h-[1.5rem] w-[1.5rem] flex-[0_0_24px] bg-light-5">
                              <Icon iconName="phone" />
                            </div>
                            <PhoneLink
                              phone={user.phone}
                              className="inline-regular"
                            >
                              {user.phone}
                            </PhoneLink>
                          </div>
                        )}
                      </div>
                    }
                  >
                    <PseudoLink
                      className={cn('light-90', {
                        'line-through': user.excluded,
                      })}
                    >
                      {user.fullName}
                    </PseudoLink>
                  </Popover>
                </div>
              </div>
              {closable &&
                !autoSelectedMembers.map(({ id }) => id).includes(user.id) && (
                  <Icon
                    className={cn('light-60 ml-[0.5rem]', {
                      blue: user.excluded,
                    })}
                    onClick={() => onClose?.(user)}
                    iconName={user.excluded ? 'unarchive' : 'closeSmall'}
                  />
                )}
            </td>
            <td className="light-90">{user.role?.name}</td>
            {displayTagsColumn && (
              <td>
                <PermissionList
                  mainListClasses="light-90"
                  permissions={{
                    roles: [],
                    tags: user.tags,
                    investmentEntities: user.investmentEntities ?? [],
                    id: user.id,
                    users: [],
                    autoSelectedUsers: [],
                    public: false,
                    type: 'document',
                  }}
                />
              </td>
            )}
            {customColumns.length > 0 &&
              customColumns.map((column) => (
                <td key={column?.title}>
                  <div className="permissions-list-title d-inline">
                    {column?.buildCell(user)}
                  </div>
                </td>
              ))}
          </tr>
        );
      })}
    </tbody>
  );

  const allMembers = uniqBy(members.concat(autoSelectedMembers), 'id');

  return (
    <div className={classes}>
      <h6 className="light-60 mb-[0.5rem]">{title}</h6>
      <table className="table-sm table-striped members-table table">
        <thead className="members-table__header">
          <tr>
            <th>Name</th>
            <th>Role</th>
            {displayTagsColumn && <th>Related to:</th>}
            {customColumns.length > 0 &&
              customColumns.map((column) => <th>{column?.title}</th>)}
          </tr>
        </thead>
        {tableItems(
          sortBy(allMembers, (member) => member.fullName.toLowerCase()),
        )}
      </table>
    </div>
  );
}

import { HistoricalTableWidget } from './widget';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { HistoricalTableWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config';
import { WidgetContextDateConfigCard } from '../common/ui/state/WidgetContextDateConfigCard';

export * from './widget';
export * from './config';
export * from './model';

export const HISTORICAL_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 6,
    maxH: 8,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Historical Table',
  icon: 'table',
  Component: HistoricalTableWidget,
  ConfigComponent: HistoricalTableWidgetConfig,
  CardConfigComponent: WidgetContextDateConfigCard,
} as const satisfies WidgetConfiguration;

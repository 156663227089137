import React, { FC, ReactNode } from 'react';
import {
  ApprovalStatus,
  IApprovalStatusProps,
} from '../ApprovalStatus/ApprovalStatus';
import Checkbox from '../Checkbox/Checkbox';
import IconButton from '../IconButton/IconButton';
import { cn } from '@/shared/lib/css/cn';
import './collapse-card.scss';
import { RadioButton } from 'stories';

interface ICollapsedCardProps {
  title: ReactNode;
  subtitle: ReactNode;
  approvalStatus: IApprovalStatusProps;
  checked: boolean;
  activated: boolean;
  selectable: boolean;
  checkHandle: (activated: boolean) => void;
  activatedHandle: (activated: boolean) => void;
  content: ReactNode;
  radioSelect?: boolean;
}

export const CollapsedCard: FC<ICollapsedCardProps> = ({
  title,
  subtitle,
  approvalStatus: { text, iconName, tooltipText },
  selectable,
  checked,
  activated,
  checkHandle,
  activatedHandle,
  content,
  radioSelect,
}) => {
  return (
    <div className="flex sre-collapse-card">
      {selectable && !radioSelect && (
        <div className="pr-s pt-xxs">
          <Checkbox checked={checked} onChange={() => checkHandle(!checked)} />
        </div>
      )}
      {selectable && radioSelect && (
        <div className="pr-s pt-xxs">
          <RadioButton
            checked={checked}
            onChange={() => checkHandle(!checked)}
          />
        </div>
      )}
      <div className="sre-collapse-card__collapse">
        <div className="sre-collapse-card__body">
          <div>
            <div className="dark-60 inline-regular">{title}</div>
            <div className="secondary-regular">{subtitle}</div>
          </div>
          <ApprovalStatus
            text={text}
            iconName={iconName}
            tooltipText={tooltipText}
          />
        </div>
        <div
          className={cn(
            'sre-collapse-card__content',
            activated && 'sre-collapse-card__content_open',
          )}
        >
          {content}
        </div>
      </div>
      {content && (
        <IconButton
          iconName="arrowBottom"
          variant="white"
          onClick={() => activatedHandle(!activated)}
        />
      )}
    </div>
  );
};

import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { PDFLightMediumTitle } from 'bundles/Shared/components/PDFLibrary/ui/LightSmallTitle';
import {
  DEFAULT_VAR_PDF,
  uiPDFStyles,
} from 'bundles/Shared/components/PDFLibrary/ui/uiPDFStyles';
import React, { FC } from 'react';

interface IHeaderRLProps {}

const HeaderRL: FC<IHeaderRLProps> = ({}) => {
  const styles = StyleSheet.create({
    header: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '100%',
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      padding: 2,
    },
    bigHeightCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '14%',
      borderTopWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
    },
    smallHeightCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      borderTopWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flex: 1,
    },
  });
  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <View style={styles.header}>
        <PDFLightMediumTitle>Sorted by: Subcategory</PDFLightMediumTitle>
      </View>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Req: #</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Requisition Date</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Line Item</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Budget Subcategory</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Description</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Amount</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Invoice #</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Date of Invoice</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Vendor</Text>
        </View>
      </View>
    </View>
  );
};

export default HeaderRL;

import React, { FC, ReactNode } from 'react';
import { CurrencyFormatter } from 'stories';
import { InvoiceLineItem } from '@/shared/types/reconcile/Invoice';
import CategoryLineItem from '../../invoice/CategoryLineItem';
import { Card } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/Card';

interface ILineItemCardProps extends InvoiceLineItem {
  actions: ReactNode;
}

const LineItemCardSimple: FC<ILineItemCardProps> = ({
  amount,
  description,
  developmentCategory,
  actions,
}) => (
  <Card actions={actions}>
    <div className="flex flex-1 flex-col gap-tw-2">
      <div className="flex justify-between">
        <div className="inline-semibold text-dark-60">
          {developmentCategory.name}
        </div>
        <CurrencyFormatter
          value={amount}
          classes={{
            value: 'inline-semibold text-dark-60',
          }}
        />
      </div>
      <div>
        <CategoryLineItem item={{ developmentCategory }} />
      </div>
      <div className="inline-regular text-light-90">{description}</div>
    </div>
  </Card>
);

export default LineItemCardSimple;

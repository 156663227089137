import { TInvoicesWithMeta } from 'bundles/Construction/api/invoices';

export const getTotalData = (meta: TInvoicesWithMeta['meta']) => [
  {
    title: 'Line Items Total:',
    value: meta.totalInvoices,
  },
  {
    title: 'Budget:',
    value: meta.totalBudget,
  },
  {
    title: 'Variance:',
    value: meta.totalVariance,
  },
];

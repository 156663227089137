import React from 'react';
import InvestmentObjectSelect from 'bundles/Shared/components/Filters/assets/investmentObjectSelect/InvestmentObjectSelect';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { Popover } from 'stories';
import { IInvestmentObject } from 'types/IInvestmentObject';

interface Props {
  investmentObjects: IInvestmentObject[];
  selectedObjects: IInvestmentObject[];
  setSelectedObjects: (selectedObjects: IInvestmentObject[]) => void;
  resetFilter: () => void;
}

const QuickFilterByObject = ({
  investmentObjects,
  selectedObjects,
  setSelectedObjects,
  resetFilter,
}: Props) => {
  return (
    <Popover
      template={
        investmentObjects ? (
          <InvestmentObjectSelect
            withoutPopover
            selectedInvestmentObjects={selectedObjects}
            investmentObjects={investmentObjects}
            onSelectedInvestmentObjectsChange={(objects) =>
              setSelectedObjects(objects)
            }
          />
        ) : (
          <LoadingPanel styles={{ minHeight: '15rem', minWidth: '15rem' }} />
        )
      }
      maxWidth="37.5rem"
      placement="bottom-start"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={selectedObjects.length > 0}
          onClose={resetFilter}
        />
      )}
    </Popover>
  );
};

export default QuickFilterByObject;

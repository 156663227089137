import React, { useState } from 'react';
import { Button, Tumbler } from 'stories/index';
import { REQUEST_APPROVAL_NOTES } from 'bundles/Construction/components/ChangeOrder/AvailableActions/dumbJSX';
import { TApprover } from 'bundles/Construction/components/eSignature/types';
import { cn } from '@/shared/lib/css/cn';
import { capitalize, sortBy } from 'lodash-es';
import { StepLine } from 'bundles/Construction/components/ChangeOrder/AvailableActions/Wrappers';

interface Props {
  approvers: TApprover[];
  onSubmit: () => void;
}

const UnitNum = ({ number }) => (
  <div className="label-semibold h-24 w-24 rounded-lg bg-light-10 text-center leading-6 text-light-90">
    {number}
  </div>
);

const ApproverItem = ({
  approver,
  notLast,
}: {
  approver: Pick<
    TApprover,
    'priority' | 'userName' | 'userRoleCategory' | 'userRoleName'
  >;
  notLast?: boolean;
}) => (
  <div className={cn('flex gap-tw-2', notLast && 'h-[57px]')}>
    <div className="gap-tw-w flex flex-col">
      <UnitNum number={approver.priority} />
      {notLast && <StepLine className="border-solid border-light-10" />}
    </div>
    <div>
      <div className="inline-regular text-dark-60">{approver.userName}</div>
      <div className="label-regular text-light-80">
        {capitalize(approver.userRoleCategory)} • {approver.userRoleName}
      </div>
    </div>
  </div>
);

const RequestApprovalForm = ({ approvers, onSubmit }: Props) => {
  const [confirmed, setConfirmed] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-tw-4 p-tw-4">
        {REQUEST_APPROVAL_NOTES}
        <div>
          <h5 className="inline-semibold mb-tw-2 text-light-90">Approvers</h5>
          <div className="rounded-lg bg-white px-tw-3 py-tw-4">
            {sortBy(approvers, 'priority').map((approver, i) => (
              <ApproverItem
                key={approver.id}
                approver={approver}
                notLast={i < approvers.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-tw-4 border-t border-solid border-light-20 p-tw-4">
        <Tumbler onChange={() => setConfirmed(!confirmed)} checked={confirmed}>
          <div className="label-regular text-light-90">
            <strong>I confirm</strong>, that Change Order details have been
            Reviewed
          </div>
        </Tumbler>
        <Button
          variant="success"
          iconName="bell"
          onClick={onSubmit}
          disabled={!confirmed}
          fluid
        >
          Request Approval
        </Button>
      </div>
    </>
  );
};

export default RequestApprovalForm;

import React, { useMemo, useState } from 'react';
import { IChangeOrder } from 'bundles/Construction/types';
import {
  getDataCOFinancialDetails,
  getDataCOKeyinfo,
  getDataCOSubstantialDate,
} from './initData';
import FormViewSwitcher from 'bundles/Shared/components/GroupForm/FormViewSwitcher';
import EditChangeOrderModal from '../EditChangeOrderModal/EditChangeOrderModal';
import { updateChangeOrder } from 'bundles/Construction/actions';
import { useAppDispatch } from '@/shared/lib/hooks/redux';

import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface Props {
  changeOrder: IChangeOrder;
  canEdit?: boolean;
}

const ChangeOrderDetails = ({ changeOrder, canEdit }: Props) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const isVerified = changeOrder?.verifiedAt;

  const dataCOKeyinfo = useMemo(
    () => getDataCOKeyinfo(changeOrder),
    [changeOrder],
  );
  const dataCOFinancialDetails = useMemo(
    () => getDataCOFinancialDetails(),
    [changeOrder],
  );
  const dataCOSubstantialDate = useMemo(
    () => getDataCOSubstantialDate(changeOrder),
    [changeOrder],
  );

  const handleEdit = async (data) => {
    const { number, date, description } = data;
    await dispatch(
      updateChangeOrder(changeOrder.legalEntityCode, changeOrder?.id, {
        number,
        co_date: formatToDateStringForRequest(date),
        description,
      }),
    );
    setShowModal(false);
  };

  return (
    <div className="change-card mb-m">
      <div className="change-card__header change-card__header_line">
        <div className="change-card__title header6-bold pb-m">
          Change Order Details
        </div>
        {canEdit && (
          <div
            className="change-card__button inline-regular"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Edit
          </div>
        )}
      </div>
      <div className="change-card__body">
        <div className="group-view mb-l">
          <div className="group-view__title body-semibold">Key Info</div>
          <div className="group-view__body">
            {dataCOKeyinfo.map((item, idx) => (
              <FormViewSwitcher
                key={`keyinfo${idx}`}
                formItem={item}
                formData={changeOrder}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-wrap gap-tw-4">
          <div className="group-view">
            <div className="group-view__title body-semibold">
              Financial Details
            </div>
            <div className="group-view__body">
              {dataCOFinancialDetails.map((item, idx) => (
                <FormViewSwitcher
                  key={`financial${idx}`}
                  formItem={item}
                  formData={changeOrder}
                />
              ))}
            </div>
          </div>
          {changeOrder?.status !== 'DRAFT' && (
            <div className="group-view">
              <div className="group-view__title body-semibold">
                Substantial Completion date
              </div>
              <div className="group-view__body">
                {dataCOSubstantialDate.map((item, idx) => (
                  <FormViewSwitcher
                    key={`date${idx}`}
                    formItem={item}
                    formData={changeOrder}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <EditChangeOrderModal
          changeOrder={changeOrder}
          setShowModal={setShowModal}
          handleEdit={handleEdit}
          isVerified={isVerified}
        />
      )}
    </div>
  );
};

export default ChangeOrderDetails;

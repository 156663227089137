import { ObjAndStateProps } from '../components/financials/types';
import { areReportFinancialsObjectsEqual } from '../components/financials/utils';

export function updateObjSelection(
  objects: ObjAndStateProps[],
  object: ObjAndStateProps,
  _selected: boolean,
  _firstSelected?: boolean,
) {
  return objects.map((i) => {
    if (!areReportFinancialsObjectsEqual(object, i)) return i;
    const additionalProps = _firstSelected ? { _firstSelected } : {};

    return {
      ...i,
      ...additionalProps,
      _selected,
      _firstSelected,
      legalEntities: i.legalEntities.map((le) => ({ ...le, _selected })),
    };
  });
}

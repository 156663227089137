import React, { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { DocumentCard } from 'stories';
import { IFormItem } from '../types/types';
import { FormFilesActions } from '../FormItems/files/components/FormFilesActions';
import { getFileExtensionFromName } from 'lib/uploadFiles';

const FormViewFiles: FC<IFormItem> = ({
  formData,
  fieldName,
  styles,
  actions = false,
  actionsView = () => false,
}) => {
  const onViewItem = (file) => {
    const url = URL.createObjectURL(file);
    window.open(url, '_blank');
  };

  const files: (File & { extension?: string })[] = Array.isArray(
    formData[fieldName],
  )
    ? formData[fieldName]
    : [formData[fieldName]];

  return (
    <div className={cn(styles?.wrapper ?? 'w-full')}>
      <div className="flex flex-col gap-tw-2">
        {formData[fieldName] &&
          files.map((file, idx) => (
            <div key={`file${idx}`}>
              <DocumentCard
                filename={file.name}
                fileSize={file.size}
                fileExtension={
                  file.extension ?? getFileExtensionFromName(file.name)
                }
                actions={
                  actionsView(file) ? (
                    actions
                  ) : (
                    <FormFilesActions onViewItem={() => onViewItem(file)} />
                  )
                }
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FormViewFiles;

import React, { FC } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import GoogleMapReact from 'google-map-react';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';

const markerStyles: React.CSSProperties = {
  fontSize: '3em',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
};

const Marker: FC<{ lat: number; lng: number }> = () => (
  <Icon iconName="location" aria-hidden="true" style={markerStyles} />
);

interface Props {
  latitude: string;
  longitude: string;
  classes?: {
    value?: string;
  };
}

const GoogleMap = ({ latitude, longitude, classes }: Props) => {
  if (!latitude || !longitude) return null;

  const center = [Number(latitude), Number(longitude)];

  return (
    <LazyLoadComponent delayTime={21000}>
      <div
        className={cn(
          'card card-profile-feed map-container shadow',
          classes?.value,
        )}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY }}
          defaultCenter={center}
          center={center}
          defaultZoom={14}
        >
          <Marker lat={center[0]} lng={center[1]} />
        </GoogleMapReact>
      </div>
    </LazyLoadComponent>
  );
};

export default GoogleMap;

import FormDate from 'bundles/Shared/components/GroupForm/FormItems/FormDate';
import React, { FC, SetStateAction } from 'react';
import { IChangeOrderEvent } from 'bundles/Construction/types';

interface ICODORDateProps {
  formData: IChangeOrderEvent;
  setFormData: SetStateAction<IChangeOrderEvent>;
  canEditAllAttr: boolean;
}

const CODORDate: FC<ICODORDateProps> = ({
  formData,
  setFormData,
  canEditAllAttr,
}) => (
  <div>
    <FormDate
      fieldName="sentToDesignerOfRecordDate"
      formData={formData}
      setFormData={setFormData}
      styles={{
        wrapper: 'w-25 form-item_white',
        size: 'm',
        nomb: true,
      }}
      placeholder="Date"
      format="MM-DD-YYYY"
      formatView="MM-dd-yy"
      leftIcon="calendar"
      maxDate={new Date()}
      disabled={canEditAllAttr}
    />
  </div>
);

export default CODORDate;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  title?: string;
  subtitle?: React.ReactNode;
  order?: 'title-subtitle' | 'subtitle-title';
  classes?: {
    title?: string;
    subtitle?: string;
  };
}

export const ModalHeaderWithSubtitle = ({
  title,
  subtitle,
  order,
  classes,
}: Props) => {
  const content = [
    <div
      key="1"
      className={cn(
        'dark-60 font-weight-700 word-break inline-semibold',
        classes?.title,
      )}
    >
      {title}
    </div>,
    <div
      key="2"
      className={cn('light-60 secondary-regular', classes?.subtitle)}
    >
      {subtitle}
    </div>,
  ];
  return <div>{order === 'title-subtitle' ? content : content.reverse()}</div>;
};

export default ModalHeaderWithSubtitle;

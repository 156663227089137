import { useDeleteApiReconcileUnderwritingBudgetsByBudgetIdMutation } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import { UnderwritingBudget } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useCallback } from 'react';

export function useDeleteUnderwritingBudget() {
  const [deleteBudget, options] =
    useDeleteApiReconcileUnderwritingBudgetsByBudgetIdMutation();
  const { confirm } = useModal();

  return [
    useCallback(async (budgetId: UnderwritingBudget['id']) => {
      const res = await confirm({
        title: 'Remove Budget',
        subtitle:
          'Are you sure you want to remove the underwriting budget for Chateau Apartments, LLC?',
      });

      if (!res) return;

      await deleteBudget({
        budgetId,
      });
    }, []),
    options,
  ] as const;
}

import { ResponsiveProps } from 'react-grid-layout';

export const DEFAULT_LAYOUT_PROPS: ResponsiveProps = {
  className: 'layout',
  cols: {
    lg: 2,
    md: 2,
    sm: 1,
    xs: 1,
    xxs: 1,
  },
  rowHeight: 16,
  isDraggable: false,
  isResizable: false,
  margin: [32, 32],
  containerPadding: [0, 0],
};

import React from 'react';

export const ColumnActionText = ({
  text,
  xlText,
}: React.PropsWithChildren<{
  text: string;
  xlText: string;
}>) => (
  <>
    <span className="hidden xl:inline-block">{xlText}</span>
    <span className="xl:hidden">{text}</span>
  </>
);

export const ActionsColumnText = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) => (
  <div className="flex w-full items-center justify-between gap-xs">
    <span className="hidden xl:inline-flex">{title}</span>
    {children}
  </div>
);

export const ActionsColumnDropdownText = ({ title }: { title: string }) => (
  <>
    <span className="hidden xl:block">Add</span>
    <span className="xl:hidden">{title}</span>
  </>
);

import React, { useMemo } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import styles from '../capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import InvestmentEntityColumn, {
  InvestmentEntityClassesBadges,
} from '../../ownershipTable/formatters/InvestmentEntityColumn';
import ReactDatePicker from 'react-datepicker';
import { STATIC_DATE_PICKER_PROPS } from '../consts';
import { IconButton, Select, Tumbler } from '@/stories';
import { formatDate } from '@/shared/lib/formatting/dates';

const options = [
  {
    id: 'monthly',
    label: 'Monthly',
  },
  {
    id: 'quarterly',
    label: 'Quarterly',
  },
] as const;

const SameValueForAllToggle = ({ value, onChange, text }) => (
  <Tumbler onChange={onChange} value={value}>
    {text}
  </Tumbler>
);

export const firstContributionDateColumn = () => {
  return {
    text: 'First contrib. date',
    dataField: 'firstContributionDate',
    headerStyle: {
      width: 160,
    },
    formatter: ({ row }) => {
      if (
        !row.firstClassAContributionDate &&
        !row.firstClassBContributionDate
      ) {
        return '-';
      }

      return (
        <div className="flex flex-col gap-s">
          {row.firstClassAContributionDate && (
            <div className="grid grid-cols-[1fr_auto]">
              <div>
                {formatDate(row.firstClassAContributionDate, 'MMM DD, YYYY')}
              </div>
              <div>
                <InvestmentEntityClassesBadges
                  capitalInvestmentClasses={['a']}
                />
              </div>
            </div>
          )}

          {row.firstClassBContributionDate && (
            <div className="grid grid-cols-[1fr_auto]">
              <div>
                {formatDate(row.firstClassBContributionDate, 'MMM DD, YYYY')}
              </div>
              <div>
                <InvestmentEntityClassesBadges
                  capitalInvestmentClasses={['b']}
                />
              </div>
            </div>
          )}
        </div>
      );
    },
  };
};

export const AccruedValuesEditor = ({
  selectedCapitalInvestments,
  setSelectedCapitalInvestments,
}) => {
  const [sameStartDate, setSameStartDate] = React.useState(false);
  const [sameEndDate, setSameEndDate] = React.useState(false);
  const [samePeriod, setSamePeriod] = React.useState(false);

  const dateForm = (rowId, fieldForUpdate, updateAll, maxDate = undefined) => {
    const index = selectedCapitalInvestments.findIndex(
      (entry) => entry.id === rowId,
    );

    return (
      <ReactDatePicker
        maxDate={maxDate}
        {...STATIC_DATE_PICKER_PROPS}
        selected={selectedCapitalInvestments[index][fieldForUpdate]}
        onChange={(e) => {
          if (updateAll) {
            setSelectedCapitalInvestments(
              selectedCapitalInvestments.map((item) => ({
                ...item,
                [fieldForUpdate]: e,
              })),
            );
          } else {
            setSelectedCapitalInvestments(
              selectedCapitalInvestments.map((item) => {
                if (item.id === rowId) {
                  return {
                    ...item,
                    [fieldForUpdate]: e,
                  };
                }
                return item;
              }),
            );
          }
        }}
      />
    );
  };

  const updateAllFields = (field, value) => {
    setSelectedCapitalInvestments(
      selectedCapitalInvestments.map((entry) => ({
        ...entry,
        [field]: value,
      })),
    );
  };

  const updatePeriod = (field, option) => {
    if (samePeriod) {
      updateAllFields('periodType', option?.id);
    } else {
      setSelectedCapitalInvestments((selectedCapitalInvestments) => {
        const newSelectedCapitalInvestments = [...selectedCapitalInvestments];
        newSelectedCapitalInvestments[field].periodType = option?.id;
        return newSelectedCapitalInvestments;
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        headerStyle: {
          width: 190,
        },
        formatter: ({ row: item }) => (
          <div>
            <InvestmentEntityColumn
              capitalInvestment={item}
              withIcon={false}
              withUsers={false}
              withLegalEntity
            />
          </div>
        ),
      },
      firstContributionDateColumn(),
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={sameStartDate}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('startDate', null);
                }
                setSameStartDate(e.target.checked);
              }}
              text="SAME DATE FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              Start Date
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('startDate', null)}
              />
            </div>
          </div>
        ),
        dataField: 'startDate',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) =>
          dateForm(row.id, 'startDate', sameStartDate, new Date()),
      },
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={sameEndDate}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('endDate', null);
                }
                setSameEndDate(e.target.checked);
              }}
              text="SAME DATE FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              End Date (Optional)
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('endDate', null)}
              />
            </div>
          </div>
        ),
        dataField: 'endDate',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) => dateForm(row.id, 'endDate', sameEndDate),
      },
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={samePeriod}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('periodType', null);
                }
                setSamePeriod(e.target.checked);
              }}
              text="SAME PERIOD FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              Period
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('periodType', null)}
              />
            </div>
          </div>
        ),
        dataField: 'Period',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) => {
          const index = selectedCapitalInvestments.findIndex(
            (entry) => entry.id === row.id,
          );

          return (
            <Select
              placeholder="Select Period"
              size="m"
              options={options}
              selected={{
                id: selectedCapitalInvestments[index].periodType,
                label: options.find(
                  (option) =>
                    option.id === selectedCapitalInvestments[index]?.periodType,
                )?.label,
              }}
              onSelectedChange={(option) => {
                updatePeriod(index, option);
              }}
            />
          );
        },
      },
    ],
    [sameStartDate, sameEndDate, samePeriod, selectedCapitalInvestments],
  );

  return (
    <>
      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
          th: 'align-bottom',
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-tw-2 light-60',
        }}
        items={selectedCapitalInvestments}
        columns={columns}
      />
    </>
  );
};

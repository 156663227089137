import { settingsReportOperationalUnitTypesApi } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';

export const settingsReportOperationalUnitTypesEnhancedApi =
  settingsReportOperationalUnitTypesApi.enhanceEndpoints({
    addTagTypes: ['SourceUnitTypes', 'SreUnitTypes'],
    endpoints: {
      getApiSettingsReportRentRollSourceUnitTypes: {
        providesTags: ['SourceUnitTypes'],
      },
      putApiSettingsReportRentRollSourceUnitTypesSetUnitType: {
        transformResponse: (res) => {
          toastr.success('Source Unit Type(s) updated');
          return res;
        },
        invalidatesTags: ['SourceUnitTypes'],
      },

      getApiSettingsReportRentRollUnitTypes: {
        providesTags: ['SreUnitTypes'],
      },
      deleteApiSettingsReportRentRollUnitTypesById: {
        invalidatesTags: ['SreUnitTypes', 'SourceUnitTypes'],
        transformResponse: (res) => {
          toastr.success('Source Unit Type(s) deleted');
          return res;
        },
      },
      putApiSettingsReportRentRollUnitTypesById: {
        invalidatesTags: ['SreUnitTypes', 'SourceUnitTypes'],
        transformResponse: (res) => {
          toastr.success('Source Unit Type(s) updated');
          return res;
        },
      },
      postApiSettingsReportRentRollUnitTypes: {
        invalidatesTags: ['SreUnitTypes', 'SourceUnitTypes'],
        transformResponse: (res) => {
          toastr.success('Source Unit Type(s) created');
          return res;
        },
      },
    },
  });

export const {
  // Source Unit Types
  useGetApiSettingsReportRentRollSourceUnitTypesQuery,
  usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation,

  // SRE Unit Types
  useGetApiSettingsReportRentRollUnitTypesQuery,
  usePutApiSettingsReportRentRollUnitTypesByIdMutation,
  useDeleteApiSettingsReportRentRollUnitTypesByIdMutation,
  usePostApiSettingsReportRentRollUnitTypesMutation,

  // import/export doesn't work properly with rtk-query approach
  // useGetApiSettingsReportRentRollSourceUnitTypesExportCsvQuery,
  // usePostApiSettingsReportRentRollSourceUnitTypesImportMutation,
} = settingsReportOperationalUnitTypesEnhancedApi;

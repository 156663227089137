import { cn } from '@/shared/lib/css/cn';
import React from 'react';

export const ChartContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  return (
    <div className={cn('min-h-[350px] grow', className)} ref={ref} {...props}>
      {children}
    </div>
  );
});

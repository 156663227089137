import { fetchGenericData } from '@/app/reducers/actions';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { stringify } from 'qs';
import React, { useState } from 'react';
import { ColorTag } from 'stories';
import { IFireAlertCard } from 'types/FireAlertCard';
import http from '@/lib/http';
import FireAlertCard from './FireAlertCard/FireAlertCard';
import FireTag from './FireTag/FireTag';
import { MODULES, PRIORITIES, normalizeModuleTagLabel } from './utils/utils';

export const FireStation = () => {
  const dispatch = useAppDispatch();
  const [selectedFireTag, setSelectedFireTag] = useState();
  const [selectedModuleTag, setSelectedModuleTag] = useState();
  const [fireEventCards, setFireEventCards] = useState<IFireAlertCard[] | null>(
    null,
  );
  async function fetchFireEventCards() {
    const queryParams = stringify(
      {
        filter_by_modules: [selectedModuleTag],
        filter_by_priorities: [selectedFireTag],
      },
      { arrayFormat: 'brackets' },
    );
    const res = await http.get(`/fire_station/alert_cards?${queryParams}`);
    const resData = await res.json();
    setFireEventCards(resData);
  }
  async function fireAlertCardResolver(kind) {
    const res = await http.post(`/fire_station/alert_cards/${kind}/resolve`);
    const resData = await res.json();
    if (resData.success) {
      fetchFireEventCards();
      dispatch(fetchGenericData());
    }
  }
  React.useEffect(() => {
    fetchFireEventCards();
  }, [selectedFireTag, selectedModuleTag]);
  return (
    <>
      <div className="mb-m flex flex-wrap gap-l">
        <div className="flex items-center gap-s">
          <span className="light-60 label-regular">PRIORITY:</span>
          <div className="flex flex-wrap gap-xs">
            {PRIORITIES.map((priority) => (
              <FireTag
                key={priority}
                priority={priority}
                selected={selectedFireTag === priority}
                onClick={() => {
                  setSelectedFireTag(
                    selectedFireTag === priority ? undefined : priority,
                  );
                }}
              />
            ))}
          </div>
        </div>
        <div className="flex items-center gap-s">
          <span className="light-60 label-regular">MODULES:</span>
          <div className="flex flex-wrap gap-xs">
            {Object.entries(MODULES).map(([key, module]) => (
              <ColorTag
                key={key}
                variant={module.color}
                label={normalizeModuleTagLabel(key)}
                selected={selectedModuleTag === key}
                onClick={() => {
                  setSelectedModuleTag(
                    selectedModuleTag === key ? undefined : key,
                  );
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-s">
        {fireEventCards?.length >= 1 &&
          fireEventCards.map((fireAlertCard) => (
            <FireAlertCard
              key={fireAlertCard.kind}
              fireAlertCard={fireAlertCard}
              fireAlertCardResolver={fireAlertCardResolver}
            />
          ))}
        {fireEventCards?.length === 0 && (
          <NoDataOverlay
            title="No Fire tasks yet"
            subTitle="Reset filters to see all documents"
          />
        )}
      </div>
    </>
  );
};

export default FireStation;

import React from 'react';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import {
  canConfigureDashboard,
  DashboardSettingsToggle,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import { Link } from '@reach/router';

interface Props {
  queryParams?: {
    assetId?: string;
  };
}

export function DashboardSettingsPageToggle({ queryParams }: Props) {
  const { dashboardId, boardId } = useDashboardContext();
  if (!canConfigureDashboard()) {
    return;
  }
  return (
    <Link
      to={generateUrl(
        ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath,
        {
          pathParams: {
            dashboardId,
          },
          queryParams: {
            boardId,
            ...queryParams,
          },
        },
      )}
    >
      <DashboardSettingsToggle />
    </Link>
  );
}

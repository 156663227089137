import dayjs from 'dayjs';
import { round, sum } from 'lodash-es';
import { StatisticsRecord } from 'stories/filterCard/statistic/StatisticLine';
import { MAX_MONTHS_IN_YEAR } from 'stories/FlexibleFilterByPeriods/consts';
import { CssVar } from '@/shared/config/cssVar';
import { STAT_LINES_COLORS } from './consts';

import { DATE_STRING_FORMAT } from '@/shared/lib/converters';

const MONTHS_TO_SUBTRACT_FROM_ENTRY_DATE = 1;

export function subtractAndFormatDate(
  entryDate: string,
  monthsToSubtract: number = MONTHS_TO_SUBTRACT_FROM_ENTRY_DATE,
) {
  return dayjs(entryDate)
    .subtract(monthsToSubtract, 'month')
    .format(DATE_STRING_FORMAT);
}

export function getStatisticInfo({
  amounts,
  totalAmount,
}: {
  totalAmount: number;
  amounts: number[];
}) {
  const declaredAmount = round(sum(amounts), 2);

  return {
    declaredAmount,
    unDeclaredAmount: totalAmount - declaredAmount,
    isFullyDeclared: declaredAmount === totalAmount,
    isGreaterThanNeeded: declaredAmount > totalAmount,
  };
}

export const createUndeclaredStatLine = (value: number) => ({
  unDeclared: {
    value,
    color: CssVar.light20,
  },
});

export function createStatLines(amounts: number[]): StatisticsRecord {
  const statLineEntries = amounts.map((value, idx) => [
    idx,
    {
      value,
      color: STAT_LINES_COLORS[idx] ?? CssVar.primary,
    },
  ]);

  const statLines = Object.fromEntries(statLineEntries) as StatisticsRecord;

  return statLines;
}

export function createPossiblePeriods(
  date: string,
  monthCount: number = MAX_MONTHS_IN_YEAR,
) {
  const yearAgoDayjsDate = dayjs(date).subtract(monthCount, 'month');
  const possiblePeriods = Array.from({ length: monthCount }, (_, idx) => {
    const num = idx + 1;
    return yearAgoDayjsDate.add(num, 'month').format(DATE_STRING_FORMAT);
  });
  return possiblePeriods;
}

import React, { FC } from 'react';
import { CurrencyFormatter } from 'stories';
import { InvoiceLineItem } from '@/shared/types/reconcile/Invoice';
import CategoryLineItem from '../../invoice/CategoryLineItem';

interface ILineItemSimpleCardProps extends InvoiceLineItem {
  idx?: number;
  maxAmount?: number;
  onRemove?: (idx: number) => void;
  selectFlow?: boolean;
  selected?: boolean;
  inRequisition?: boolean;
}

const LineItemSimpleCard: FC<ILineItemSimpleCardProps> = ({
  amount,
  description,
  developmentCategory,
}) => (
  <div className="flex gap-tw-4 border-b border-solid border-light-10 p-tw-4 last:border-b-0">
    <div className="flex flex-1 flex-col gap-tw-2">
      <div className="flex justify-between">
        <div className="inline-semibold text-dark-60">
          {developmentCategory.name}
        </div>
        <div className="pr-tw-10">
          <CurrencyFormatter
            value={amount}
            classes={{
              value: 'inline-semibold text-dark-60',
            }}
          />
        </div>
      </div>
      <div>
        <CategoryLineItem item={{ developmentCategory }} />
      </div>
      <div className="inline-regular text-light-90">{description}</div>
    </div>
  </div>
);

export default LineItemSimpleCard;

import type { ReportBuilderTemplateGroupWidgetSectionDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';

export const isReportBuilderTempalteWidgetEditable = (
  widgetType: ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
) => {
  switch (widgetType) {
    case 'kpi_card':
    case 'xy_chart':
    case 'historical_review_table':
    case 'recent_rent_roll_table':
    case 'delinquency_tracker_table':
    case 'unit_mix_table':
    case 'unit_mix_summary_table':
    case 'financial_table_single_period':
    case 'financial_table_single_date': {
      return true;
    }
    default: {
      return false;
    }
  }
};

import React from 'react';
import { exportGeneralLedgers } from 'bundles/Settings/actions/generalLedger';
import { currentUserAllowedTo, REPORT_PRODUCT_NAME } from 'lib/permissions';
import { Button, Tooltip } from 'stories';

function ExportGeneralLedgerButton() {
  if (!currentUserAllowedTo('manage', REPORT_PRODUCT_NAME)) return null;

  return (
    <Tooltip mainText="Export as xlsx" arrowPosition="start">
      <Button
        className="font-weight-500"
        onClick={exportGeneralLedgers}
        variant="secondary"
        iconName="docExcel"
        iconClasses="green-dark-1"
      >
        Export
      </Button>
    </Tooltip>
  );
}

export default ExportGeneralLedgerButton;

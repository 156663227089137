import { IDevelopmentProjectDetailed } from 'bundles/REconcile/types/IDevelopmentProject';
import { IChangeOrderEventBages } from 'types/reconcile';

export const mapChangeManagementDataToCard = (
  data: IDevelopmentProjectDetailed['changeManagement'],
): IChangeOrderEventBages => ({
  amount: Number(data.projectedContingency.amount),
  approvedCo: data.projectedContingency.used.completedCes,
  approvedCor: data.projectedContingency.used.approvedCes,
  otherReallocations: data.projectedContingency.used.otherAllocations,
  usedPercent: Number(data.projectedContingency.used.percent),
  pendingCor: data.projectedContingency.pending?.corPending,
  pendingPco: data.projectedContingency.pending?.pcosSubmitted,
  disputed: data.projectedContingency.pending?.corsDisputed,
  pendingPercent: Number(data.projectedContingency.pending?.percent),
  remainingPercent: Number(data.projectedContingency.remainingPercent),
  ircPercent: data.projectedContingency.ircPercent,
});

import { createMapByKey } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { KpiCardWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/kpiCard/model';
import { cn } from '@/shared/lib/css/cn';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';
import { getNumberFormatterByDisplayType } from '@/shared/lib/formatting/table';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { KpiCardWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { isEmpty } from 'lodash-es';
import React from 'react';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { TitledCard } from 'stories/TitledCard/TitledCard';

const KpiWrapper = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col items-center gap-tw-2">{children}</div>
);

export function KpiCardWidget(
  props: WidgetProps<KpiCardWidgetDto, KpiCardWidgetSection>,
) {
  const { data, widgetSection, isLoading, className, mode } = props;
  const kpisVizConfigMap = createMapByKey(widgetSection.widgetConfig.kpis);
  return (
    <TitledCard
      className={cn(
        'h-full max-h-[200px] min-h-[144px]',
        mode === 'pdf' && 'border border-solid border-neutral-200 shadow-none',
        className,
      )}
      classes={{
        children: 'gap-tw-8',
      }}
      style={{
        padding: `0 ${120 / (data?.kpis.length ?? 1)}px`,
      }}
      title={widgetSection.title}
    >
      {isLoading && (
        <KpiWrapper>
          <SkeletonBlock className="h-[30px] w-[80px]" />
          <SkeletonBlock className="h-[20px] w-[40px]" />
        </KpiWrapper>
      )}

      {!isLoading && isEmpty(data?.kpis) && (
        <KpiWrapper>
          <span className="header3-regular text-neutral-400">Value</span>
          <span className="inline-semibold text-neutral-400">Label</span>
        </KpiWrapper>
      )}
      {!isLoading &&
        !isEmpty(data?.kpis) &&
        data?.kpis.map((kpi, index, arr) => {
          const kpiVizConfig = kpisVizConfigMap.get(String(kpi.key));
          if (kpiVizConfig == null) return null;

          const Component = getNumberFormatterByDisplayType({
            precision: kpiVizConfig.value_display_options.precision,
            displayType: kpiVizConfig.value_display_options.type,
            hideCommaSeparator:
              kpiVizConfig.value_display_options.hide_comma_separator,
            dateFormat: kpiVizConfig.value_display_options.date_format,
          });

          return (
            <>
              <div
                key={kpi.key}
                className="flex flex-col items-center gap-tw-2 "
              >
                {kpi.valueDisplayOptions.type !== 'number' && Component ? (
                  <Component
                    classes={{
                      allParts: 'header3-regular',
                      value: 'text-neutral-850',
                    }}
                    value={kpi.value}
                  />
                ) : (
                  <span className="header3-regular text-neutral-850">
                    {kpi.value?.toFixed(kpi.valueDisplayOptions.precision) ??
                      DEFAULT_NUMBER_FALLBACK}
                  </span>
                )}
                <span className="inline-semibold whitespace-nowrap text-neutral-700 ">
                  {kpi.label}
                </span>
              </div>
              {arr.length > 0 && index < arr.length - 1 && (
                <VerticalSeparator size="1.25rem" />
              )}
            </>
          );
        })}
    </TitledCard>
  );
}

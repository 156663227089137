import React, { FC } from 'react';
import { Link, RouteComponentProps, Router, useMatch } from '@reach/router';
import { cn } from '@/shared/lib/css/cn';
import EntitiesTracker from 'bundles/Settings/components/Portal/InvestmentEntities/EntitiesTracker';
import UserTracker from '../components/UserTracker/UserTracker';
import TagTracker from '../components/TagTracker';
import { UserRoles } from '../components/roles/UserRoles';
import { UserManagementHeader } from './UserManagementHeader';

const TABS_LIST = [
  { name: 'Members', path: '.' },
  { name: 'Tags', path: './tags' },
  { name: 'Roles', path: './roles' },
  { name: 'Investment Entities', path: './investment-entities' },
];

const UserManagement: FC<RouteComponentProps> = () => {
  const isUserRolesRoute = useMatch('/user-management/roles');

  return (
    <div
      className={cn('custom-container mb-40 mt-l', {
        'custom-container_with-right-bar': isUserRolesRoute,
      })}
    >
      <UserManagementHeader />

      <div className="mgmt-tabs-underline mb-m flex flex-wrap items-center justify-between">
        <div className="btn-group" role="group">
          {TABS_LIST.map((category) => (
            <Link
              key={category.name}
              color="link"
              getProps={({ isCurrent: active }) => ({
                className: cn('mgmt-tab mgmt-tab-s', {
                  'active-mgmt-tab': active,
                }),
              })}
              to={category.path}
            >
              {category.name}
            </Link>
          ))}
        </div>
      </div>

      <Router primary={false}>
        <UserTracker path="/" />
        <TagTracker path="tags" />
        <UserRoles path="roles" />
        <EntitiesTracker path="investment-entities" />
      </Router>
    </div>
  );
};

export default UserManagement;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  overlayClasses?: string;
  color?: string;
  size?: string;
  inline?: boolean;
}

export function OverlaySpinner({
  overlayClasses,
  color = 'grey',
  size,
  inline,
}: Props) {
  return (
    <div
      className={cn('overlay', overlayClasses, size, {
        overlay_inline: inline,
      })}
    >
      <div
        className="spinner"
        style={
          color ? { borderColor: color, borderRightColor: 'transparent' } : {}
        }
      />
    </div>
  );
}

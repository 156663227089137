import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LegalEntityUnderwritingBudgetModel } from 'bundles/REconcile/underwritting/model';

interface State {
  isTableExpanded: boolean;
  onlyInUseMetrics: boolean;
  leFilter: LegalEntityUnderwritingBudgetModel['state'] | undefined;
}

const initialState: State = {
  isTableExpanded: false,
  onlyInUseMetrics: false,
  leFilter: undefined,
};

const underwritingBudgetSlice = createSlice({
  name: 'underwriting budget',
  initialState,
  reducers: {
    toggleExpandTable(state) {
      state.isTableExpanded = !state.isTableExpanded;
    },
    toggleOnlyInUseMetrics(state) {
      state.onlyInUseMetrics = !state.onlyInUseMetrics;
    },
    changeLEFilter(state, { payload }: PayloadAction<State['leFilter']>) {
      state.leFilter = payload;
    },
  },
});

export const uwbSliceActions = underwritingBudgetSlice.actions;
export default underwritingBudgetSlice.reducer;

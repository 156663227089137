import { TDevelopmentLegalEntityReconcileSettings } from 'bundles/REconcile/types/TDevelopmentLegalEntityReconcileSettings';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReconcileDevelopmentLegalEnitity } from 'bundles/REconcile/types/IReconcileDevelopmentLegalEnitity';
import { plainFetchReconcileDevelopmentLegalEntity } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntities';
import {
  fetchLegalEntityLegacySettings,
  plainFetchJccForReconcileSettings,
  TUpdateLegalEntityLegacySettingsRequest,
  updateLegalEntityLegacySettings,
} from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntitiesSettings';
import { IJobCostCode } from 'types/JobCostCode';
import {
  fetchChangeOrderConfigsForReconcileSettings,
  IReconcileBudgetsSettingsChangeOrderConfig,
  setChangeOrderConfigForAsset,
} from 'bundles/REconcile/actions/changeOrderConfig';
import {
  fetchWorkflowReconcileSettings as plainFetchWorkflowReconcileSettings,
  updateWorkflowReconcileSettings as plainUpdateWorkflowReconcileSettings,
  IWorkflowConfig,
} from 'bundles/REconcile/actions/workflowConfig';
import { findConfigSetForAsset } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/sovc/utils';
import {
  fetchThresholdsInReconcileSettings,
  IWorkflowThreshold,
} from 'bundles/REconcile/actions/workflowThresholds';

interface IDevelopmentLegalEntityState {
  state: 'idle' | 'loading' | 'success';
  data?: IReconcileDevelopmentLegalEnitity;
  settings?: TDevelopmentLegalEntityReconcileSettings;
  jobCostCodes: Pick<IJobCostCode, 'code' | 'id' | 'name'>[];
  changeOrderConfigs: IReconcileBudgetsSettingsChangeOrderConfig[];
  selectedChangeOrderConfig: IReconcileBudgetsSettingsChangeOrderConfig | null;
  workflow?: IWorkflowConfig;
  workflowThresholds: IWorkflowThreshold[];
}

const initialState: IDevelopmentLegalEntityState = {
  state: 'idle',
  jobCostCodes: [],
  changeOrderConfigs: [],
  selectedChangeOrderConfig: null,
  workflowThresholds: [],
};

export const fetchReconcileDevelopmentLegalEntity = createAsyncThunk(
  'developmentLegalEntity/fetch',
  async (code: string) => plainFetchReconcileDevelopmentLegalEntity(code),
);

export const fetchLegacySettings = createAsyncThunk(
  'developmentLegalEntity/fetchSettings',
  async (legalEntityId: string) =>
    fetchLegalEntityLegacySettings(legalEntityId),
);

export const fetchChangeOrderConfigs = createAsyncThunk(
  'developmentLegalEntity/fetchChangeOrderConfigs',
  async (legalEntityId: string) =>
    fetchChangeOrderConfigsForReconcileSettings({ legalEntityId }, {}),
);

export const updateLegacySettings = createAsyncThunk(
  'developmentLegalEntity/updateSettings',
  async ({
    legalEntityId,
    settings,
  }: {
    legalEntityId: string;
    settings: TUpdateLegalEntityLegacySettingsRequest;
  }) => updateLegalEntityLegacySettings(legalEntityId, settings),
);

export const fetchJccForReconcileSettings = createAsyncThunk(
  'developmentLegalEntity/fetchJCCs',
  (legalEntityId: string) => plainFetchJccForReconcileSettings(legalEntityId),
);

export const updateSelectedChangeOrderConfig = createAsyncThunk(
  'developmentLegalEntity/updateSelectedChangeOrderConfig',
  (request: { configId: number; legalEntityId: string }) =>
    setChangeOrderConfigForAsset({
      ...request,
    }),
);

export const fetchWorkflowReconcileSettings = createAsyncThunk(
  'developmentLegalEntity/fetchWorkflowSettings',
  (...args: Parameters<typeof plainFetchWorkflowReconcileSettings>) =>
    plainFetchWorkflowReconcileSettings(...args),
);

export const fetchWorkflowThresholds = createAsyncThunk(
  'developmentLegalEntity/fetchWorkflowThresholds',
  ({
    legalEntityId,
  }: Parameters<typeof fetchThresholdsInReconcileSettings>[0]) =>
    fetchThresholdsInReconcileSettings(
      {
        legalEntityId,
      },
      {},
    ),
);

export const updateWorkflowReconcileSettings = createAsyncThunk(
  'developmentLegalEntity/updateWorkflowSettings',
  ({
    legalEntityId,
    ...request
  }: Parameters<typeof plainUpdateWorkflowReconcileSettings>[0] &
    Parameters<typeof plainUpdateWorkflowReconcileSettings>[1]) =>
    plainUpdateWorkflowReconcileSettings(
      {
        legalEntityId,
      },
      request,
    ),
);

const developmentLegalEntitySlice = createSlice({
  name: 'developmentLegalEntity',
  initialState,
  reducers: {
    updateSelectedConfigToSetForAsset: (state) => {
      const configSetForAsset = findConfigSetForAsset(state.changeOrderConfigs);
      if (
        configSetForAsset != null &&
        state.selectedChangeOrderConfig?.id !== configSetForAsset.id
      ) {
        state.selectedChangeOrderConfig = configSetForAsset;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReconcileDevelopmentLegalEntity.pending, (state) => {
        state.state = 'loading';
      })
      .addCase(
        fetchReconcileDevelopmentLegalEntity.fulfilled,
        (state, action) => {
          state.state = 'success';
          state.data = action.payload;
        },
      )
      .addCase(fetchLegacySettings.fulfilled, (state, action) => {
        state.settings = action.payload;
      })
      .addCase(updateLegacySettings.fulfilled, (state, action) => {
        state.settings = action.payload;
        window.toastr.success('Settings has been updated');
      })
      .addCase(fetchJccForReconcileSettings.fulfilled, (state, action) => {
        state.jobCostCodes = action.payload.items;
      })
      .addCase(fetchChangeOrderConfigs.fulfilled, (state, action) => {
        const changeOrderConfigs = action.payload;
        state.changeOrderConfigs = changeOrderConfigs;
      })
      .addCase(updateSelectedChangeOrderConfig.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedChangeOrderConfig = action.payload;
        }
      })
      .addCase(fetchWorkflowReconcileSettings.fulfilled, (state, action) => {
        if (action.payload) {
          state.workflow = action.payload;
        }
      })
      .addCase(updateWorkflowReconcileSettings.fulfilled, (state, action) => {
        if (action.payload) {
          state.workflow = action.payload;
        }
      })
      .addCase(fetchWorkflowThresholds.fulfilled, (state, action) => {
        if (action.payload) {
          state.workflowThresholds = action.payload.items;
        }
      });
  },
});

export const { updateSelectedConfigToSetForAsset } =
  developmentLegalEntitySlice.actions;

export default developmentLegalEntitySlice.reducer;

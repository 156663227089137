import React from 'react';
import { IFormItem } from 'bundles/Shared/components/GroupForm/types/types';
import { cn } from '@/shared/lib/css/cn';
import { Tumbler } from 'stories';

interface Props extends IFormItem {
  value: boolean;
}

function FormTumbler({
  title,
  formData,
  setFormData,
  fieldName,
  required,
  value,
  styles,
  disabled,
}: Props) {
  const handleChange = () => {
    setFormData({
      ...formData,
      [fieldName]: !formData[fieldName],
    });
  };
  return (
    <div
      className={cn(
        'form-item',
        styles?.wrapper ?? 'w-full',
        styles?.nomb && 'form-item_nomb',
      )}
    >
      <Tumbler
        onChange={handleChange}
        checked={value}
        disabled={disabled}
        required={required}
      >
        {title}
      </Tumbler>
    </div>
  );
}

export default FormTumbler;

import React from 'react';
import { Icon } from 'stories';
import styles from './ApprovedReallocation.module.scss';

const ApprovedReallocation = () => (
  <div className={styles.wrapper}>
    <div className={styles.icon}>
      <Icon iconName="acceptAlt" />
    </div>
    <div className={styles.title}>Approved</div>
  </div>
);

export default ApprovedReallocation;

import { TRouteQueryParams, useQueryParams } from '@/shared/lib/hooks/useNavigation';
import { useMemo } from 'react';

export function useSettingReportOperationTabs() {
  const { mapped: mappedQueryParamStr } =
    useQueryParams<
      TRouteQueryParams['/settings/report/operational/unit-types']
    >();

  const isMappedUnitTypesTab = useMemo(() => {
    const parsed =
      mappedQueryParamStr == null
        ? null
        : (JSON.parse(mappedQueryParamStr as string) as boolean | undefined);
    return parsed === true || parsed == null;
  }, [mappedQueryParamStr]);

  return isMappedUnitTypesTab;
}

import React, { useMemo } from 'react';
import {
  IWorkflowThreshold,
  TWorkflowThresholdType,
} from 'bundles/REconcile/actions/workflowThresholds';
import {
  daysNumberFormat,
  nextThreshold,
  thresholdRangeText,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/thresholdHelpers';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import PermissionList from 'bundles/Shared/components/PermissionList';
import { IColumn } from 'bundles/Shared/components/Table/types';

export function useThresholdColumns(thresholds: IWorkflowThreshold[]) {
  return useMemo<IColumn<IWorkflowThreshold>[]>(
    () => [
      {
        text: 'Number',
        dataField: 'number',
        classes: 'light-60',
        formatter: ({ row }) => `Threshold #${row.number}`,
        headerStyle: {
          minWidth: 140,
        },
      },
      {
        text: 'Range',
        dataField: 'value',
        formatter: ({ row }) =>
          thresholdRangeText(
            row.value,
            nextThreshold(row, thresholds)?.value,
            row.kind === TWorkflowThresholdType.TIM
              ? (value) => (value == null ? '∞' : daysNumberFormat(value))
              : (value) =>
                  value == null ? '∞' : accountingNumberFormat(value),
          ),
        headerStyle: {
          width: '30%',
        },
      },
      {
        text: 'Internal Approver',
        dataField: 'internalApprover',
        formatter: ({ row }) => row.internalApprover.fullName,
        headerStyle: {
          width: '30%',
        },
      },
      {
        text: 'External Approvers',
        dataField: 'externalApprovers',
        formatter: ({ row }) => (
          <PermissionList
            permissions={{
              roles: [],
              tags: [],
              investmentEntities: [],
              users: row.externalApprovers,
              autoSelectedUsers: [],
              public: false,
              type: 'asset',
            }}
          />
        ),
        headerStyle: {
          width: '30%',
        },
      },
    ],
    [thresholds],
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'lib/http';
import { ICompany } from 'bundles/Construction/types';

export const reconcileCompanies = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/reconcile/companies`,
  }),
  reducerPath: 'reconcileCompanies',
  endpoints: (build) => ({
    getCompanies: build.query<ICompany[], Record<string, never>>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCompaniesQuery } = reconcileCompanies;

import {
  AssetsReportBuilderReportDto,
  AssetsReportBuilderReportWidgetDto,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { REPORT_BUILDER_WIDGET_TYPES_LIST } from '@/entities/report/reportBuilder/model';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { isEqual, pick, sortBy } from 'lodash-es';
import { UseFormWatch } from 'react-hook-form';

export const filterKnownWidgetTypes = (
  widget: AssetsReportBuilderReportWidgetDto,
): boolean => REPORT_BUILDER_WIDGET_TYPES_LIST.includes(widget.widgetType);

type PayloadBody =
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg['body'];

export const transformFormWatchToPayloadBody = (
  watch: UseFormWatch<{
    name: string;
    subtitle: string;
    date: string | undefined;
    legalEntityIds: string[];
  }>,
): PayloadBody => {
  return {
    name: watch('name').trim(),
    subtitle: watch('subtitle').trim(),
    date: watch('date'),
    legal_entity_ids: watch('legalEntityIds'),
  };
};
export const isReportPayloadBodyAndReportDataEqual = (
  report: AssetsReportBuilderReportDto,
  payloadBody: PayloadBody,
) => {
  const isFormDateAndDataDateEqual = isEqual(report.date, payloadBody.date);
  const isFormLEsAndDataLEsEqual = isEqual(
    sortBy(mapListToIds(report.legalEntities)),
    sortBy(payloadBody.legal_entity_ids),
  );
  const isFormAndDataEqual =
    isFormDateAndDataDateEqual &&
    isFormLEsAndDataLEsEqual &&
    isEqual(pick(report, ['name', 'subtitle']), {
      name: payloadBody.name,
      subtitle: payloadBody.subtitle,
    });
  return {
    isFormDateAndDataDateEqual,
    isFormLEsAndDataLEsEqual,
    isFormAndDataEqual,
  };
};

export const filterRedundantFieldsFromPayloadBody = ({
  isFormDateAndDataDateEqual,
  isFormLEsAndDataLEsEqual,
  payloadBody,
}: {
  payloadBody: PayloadBody;
  isFormDateAndDataDateEqual: boolean;
  isFormLEsAndDataLEsEqual: boolean;
}): PayloadBody => ({
  date: !isFormDateAndDataDateEqual ? payloadBody.date : undefined,
  legal_entity_ids: !isFormLEsAndDataLEsEqual
    ? payloadBody.legal_entity_ids
    : undefined,
  name: payloadBody.name,
  subtitle: payloadBody.subtitle,
});

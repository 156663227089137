import { useLoadedCapitalTransactionSources } from '@/bundles/REturn/hooks/useLoadedCapitalTransactionSources';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { filtterSourceByType } from '@/entities/return/capital/lib';
import { useMemo } from 'react';

interface Params {
  kind: TransactionSource['kind'];
}

export function useGeneralLedgersAsSources({ kind: type }: Params) {
  const { data: capitalTransactionSources } =
    useLoadedCapitalTransactionSources();

  const generalLedgers = useMemo(() => {
    const sourcesByType = filtterSourceByType(capitalTransactionSources, type);

    return sourcesByType.map(({ generalLedger }) => generalLedger);
  }, [type, capitalTransactionSources]);

  return generalLedgers;
}

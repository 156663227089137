import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IReconcileDevelopmentBudgetItem } from 'bundles/REconcile/types/IReconcileDevelopmentBudgetItem';
import {
  destroyDevelopmentBudget,
  fetchReconcileDevelopmentLegalEntities as fetchReconcileDevelopmentLegalEntities1,
} from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntities';

interface DevelopmentBudgetsState {
  items: IReconcileDevelopmentBudgetItem[];
  state: 'idle' | 'loading' | 'success';
}

const initialState: DevelopmentBudgetsState = {
  items: [],
  state: 'idle',
};

export const fetchReconcileDevelopmentLegalEntities = createAsyncThunk(
  'developmentLegalEntities/fetch',
  () => fetchReconcileDevelopmentLegalEntities1(),
);

export const deleteDevelopmentBudget = createAsyncThunk(
  'developmentBudget/delete',
  destroyDevelopmentBudget,
);

const developmentLegalenitiesSlice = createSlice({
  name: 'developmentLegalEntities',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchReconcileDevelopmentLegalEntities.pending,
        (state, action) => {
          state.state = 'loading';
        },
      )
      .addCase(
        fetchReconcileDevelopmentLegalEntities.fulfilled,
        (state, action) => {
          state.state = 'success';
          state.items = action.payload;
        },
      );

    builder.addCase(deleteDevelopmentBudget.fulfilled, (state, action) => {
      state.items = state.items.filter(
        (devBudget) => devBudget.id !== action.payload.id,
      );
    });
  },
});

export default developmentLegalenitiesSlice.reducer;

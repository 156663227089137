import React from 'react';
import { stringify } from 'qs';

import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import http from 'lib/http';

const SearchableMultiSelect = ({
  value,
  setValue,
  getOptions,
  url,
  placeholder,
  params = {},
  creatable = false,
}) => {
  const SIZE_PER_PAGE = 20;

  const loadOptions = async (inputValue, _, { page }) => {
    const queryData = {
      search_query: inputValue,
      size_per_page: SIZE_PER_PAGE,
      page,
      ...params,
    };

    let options = [];
    let hasMore = true;

    await http.get(`${url}?${stringify(queryData)}`)
      .then((data) => data.json())
      .then((data) => {
        options = getOptions(data.items);
        hasMore = data.meta.currentPage < data.meta.totalPages;
      });
    return ({
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    });
  };

  const CreatableAsyncPaginate = creatable ? withAsyncPaginate(Creatable) : AsyncPaginate;

  return (
    <CreatableAsyncPaginate
      classNamePrefix="react-select"
      menuPosition="fixed"
      menuShouldBlockScroll
      isMulti
      cacheOptions
      value={value}
      onChange={setValue}
      loadOptions={loadOptions}
      debounceTimeout={300}
      additional={{
        page: 1,
      }}
      placeholder={placeholder}
    />
  );
};

export default SearchableMultiSelect;

const NOTIFICATIONS_MAPPING = {
  // TODO replace with more acceptable icon
  feed_message_created: {
    containerClassName: 'success',
    innerClassName: 'role',
  },
  email_processor_success: {
    containerClassName: 'success',
    innerClassName: 'email',
  },
  email_processor_failed: {
    containerClassName: 'danger',
    innerClassName: 'email',
  },
  // TODO replace with more acceptable icon
  files_added: { containerClassName: 'success', innerClassName: 'file' },
  // TODO replace with more acceptable icon
  high_amount_cor: { containerClassName: 'warning', innerClassName: 'file' },
  photos_added: { containerClassName: 'success', innerClassName: 'media' },
};
export default NOTIFICATIONS_MAPPING;

import { Link, useParams } from '@reach/router';
import {
  selectCETimingImpact,
  selectChangeEvent,
  setCETimingImpact,
  setSubsDateChangeEvent,
  updateChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import FormDate from 'bundles/Shared/components/GroupForm/FormItems/FormDate';
import FormViewSwitcher from 'bundles/Shared/components/GroupForm/FormViewSwitcher';
import { toDate } from '@/shared/lib/formatting/dates';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import {
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MultilineAlert, Tooltip } from 'stories';
import StepsFormStepv2, {
  QAItemsContainer,
} from 'stories/StepsFormStep/StepsFormStepv2';
import {
  NoUnitQuestionAnswer,
  YesUnitQuestionAnswer,
} from 'stories/UnitQuestionAnswer/UnitQuestionAnswer';
import EditChangeEventModal from '../EditChangeEventModal/EditChangeEventModal';
import { IActionsHints } from './../';
import {
  getDataCEAdditionalDetails,
  getDataCEFinancialImpact,
  getDataCEKeyinfo,
} from './initData';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface Props {
  actionsHints: IActionsHints;
  dateParentRef: RefObject<HTMLDivElement>;
}

const ChangeEventDetails = forwardRef(
  ({ actionsHints, dateParentRef }: Props) => {
    const changeEvent = useAppSelector(selectChangeEvent);
    const showTimingImpact = useAppSelector(selectCETimingImpact);
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigation = useNavigation();
    const dateRef = useRef();
    const [loading, setLoading] = useState(false);

    const isVerified = changeEvent?.verified;
    const isCOR = changeEvent?.category === 'COR';
    const substantialCompletionDate = changeEvent?.substantialCompletionDate;

    const [showModal, setShowModal] = useState(false);

    const dataCEKeyinfo = useMemo(() => {
      if (changeEvent) {
        return getDataCEKeyinfo(changeEvent);
      }
      return [];
    }, [changeEvent]);
    const dataCEAdditionalDetails = useMemo(() => {
      if (changeEvent) {
        return getDataCEAdditionalDetails(changeEvent);
      }
      return [];
    }, [changeEvent]);
    const dataCEFinancialImpact = useMemo(() => {
      setLoading(false);
      if (changeEvent) {
        return getDataCEFinancialImpact(changeEvent);
      }
      return [];
    }, [changeEvent]);

    useEffect(() => {
      setLoading(true);
    }, [dataCEFinancialImpact]);

    useEffect(() => {
      if (substantialCompletionDate) {
        dispatch(setCETimingImpact(true));
      }
    }, [substantialCompletionDate]);

    const chooseDate = (date) => {
      dispatch(setCETimingImpact(Boolean(date)));
      dispatch(
        setSubsDateChangeEvent({
          legalEntityCode: params.legalEntityCode,
          id: params.id,
          data: {
            substantial_completion_date: date?.substantialCompletionDate,
          },
        }),
      );
    };
    const updateSubsDate = (date) => {
      dispatch(setCETimingImpact(Boolean(date)));
      dispatch(
        setSubsDateChangeEvent({
          legalEntityCode: params.legalEntityCode,
          id: params.id,
          data: {
            substantial_completion_date: formatToDateStringForRequest(
              date?.substantialCompletionDate,
            ),
          },
        }),
      );
    };

    const handleEdit = async (data) => {
      const {
        title,
        description,
        amount,
        number,
        contractorDate,
        receivedDate,
        probability,
        probability_comment,
        reallocationJccId,
        changeOrderReasons,
      } = data;

      await dispatch(
        updateChangeEvent({
          legalEntityCode: params.legalEntityCode,
          id: params.id,
          data: {
            reallocation_jcc_id: reallocationJccId,
            title,
            description,
            ...(!isVerified && {
              value: amount,
              number,
              contractor_date: formatToDateStringForRequest(contractorDate),
              received_date: formatToDateStringForRequest(receivedDate),
              change_order_reason_ids: changeOrderReasons.map((r) => r.id),
              probability,
              probability_comment: probability ? probability_comment : null,
            }),
          },
        }),
      );
      setShowModal(false);
    };

    useImperativeHandle(dateParentRef, () => ({
      get getDateRef() {
        return dateRef.current;
      },
    }));

    return (
      <div className="change-card mb-m">
        <div className="change-card__header change-card__header_line">
          <div className="change-card__title header6-bold pb-m">
            Change Event Details
          </div>
          <div
            className="change-card__button inline-regular"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Edit
          </div>
        </div>
        <div className="change-card__body">
          <div className="group-view mb-l">
            <div className="group-view__title body-semibold">Key Info</div>
            <div className="group-view__body">
              {dataCEKeyinfo.map((item) => (
                <FormViewSwitcher
                  key={item.fieldName}
                  formItem={item}
                  formData={changeEvent}
                />
              ))}
            </div>
          </div>
          <div className="flex gap-tw-4">
            <div className="group-view">
              <div className="group-view__title body-semibold">
                Additional Details
              </div>
              <div className="group-view__body">
                {dataCEAdditionalDetails.map((item) => (
                  <FormViewSwitcher
                    key={item.fieldName}
                    formItem={item}
                    formData={changeEvent}
                  />
                ))}
              </div>
            </div>
            {loading && (
              <div className="group-view">
                <div className="group-view__title body-semibold">
                  Financial Impact
                </div>
                <div className="group-view__body">
                  {dataCEFinancialImpact.map((item) => (
                    <FormViewSwitcher
                      key={item.fieldName}
                      formItem={item}
                      formData={changeEvent}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {isCOR && !changeEvent?.lastVerifiedSubstantialCompletionDate && (
          <div className="change-card__quiz" ref={dateRef}>
            <MultilineAlert
              message={
                <div>
                  <span className="mr-xs">
                    Selecting a new Substantial Completion Date is forbidden
                    until the Initial Estimated Completion date is provided
                    within the
                  </span>
                  <Link
                    to={navigation.getUrl(
                      'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS',
                      {
                        legalEntityCode: params.legalEntityCode,
                        tab: 'legacy',
                      },
                    )}
                    className="blue"
                  >
                    LE settings
                  </Link>
                </div>
              }
              status="attention"
              className="mb-s w-full"
            />
          </div>
        )}
        {isCOR && changeEvent?.lastVerifiedSubstantialCompletionDate && (
          <Tooltip
            trigger="click"
            triggerTarget={actionsHints.checkTimingImpact}
            className="!block"
            placement="right"
            arrowPosition="left"
            titleText="Please answer the question"
          >
            <div className="change-card__quiz" ref={dateRef}>
              <StepsFormStepv2
                title="Will this COR impact the Substantial Completion Date?"
                disabled={isVerified}
                afterTitle={
                  <QAItemsContainer>
                    <YesUnitQuestionAnswer
                      chosen={
                        substantialCompletionDate &&
                        showTimingImpact !== 'empty'
                      }
                      onClick={() => {
                        chooseDate(
                          {
                            substantialCompletionDate:
                              changeEvent?.lastVerifiedSubstantialCompletionDate,
                          },
                          true,
                        );
                      }}
                      disabled={isVerified}
                    />
                    <NoUnitQuestionAnswer
                      chosen={showTimingImpact === false}
                      onClick={() => {
                        chooseDate(null);
                      }}
                      disabled={isVerified}
                    />
                  </QAItemsContainer>
                }
              />
              {showTimingImpact !== 'empty' && substantialCompletionDate && (
                <FormDate
                  title="Select Substantial Completion Date"
                  fieldName="substantialCompletionDate"
                  formData={{
                    substantialCompletionDate: toDate(
                      changeEvent?.substantialCompletionDate,
                    ),
                  }}
                  setFormData={updateSubsDate}
                  styles={{
                    wrapper: 'w-40 form-item_white mb-s ml-s',
                    size: 'm',
                    nomb: true,
                  }}
                  placeholder="Date"
                  format="MM-DD-YYYY"
                  formatView="MM-dd-yy"
                  leftIcon="calendar"
                  disabled={isVerified}
                />
              )}
            </div>
          </Tooltip>
        )}
        {showModal && (
          <EditChangeEventModal
            setShowModal={setShowModal}
            handleEdit={handleEdit}
            isVerified={isVerified}
          />
        )}
      </div>
    );
  },
);

export default ChangeEventDetails;

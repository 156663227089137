import React, { useState } from 'react';
import { LinkButton } from 'stories';
import SubfolderCard from './SubfolderCard';

const SubfoldersList = ({
  subfolders,
  onFolderChange,
  hideActions,
  openMoveFolderModal,
  openNewFolderModal,
  onDelete,
  currentUserCanManage,
}) => {
  if (subfolders.length === 0) return null;
  const getVisibleSubfolders = () => {
    const width = window.innerWidth;
    if (width >= 1440) return 9;
    if (width < 1439 && width > 1199) return 7;
    if (width < 1200 && width > 991) return 5;
    return 4;
  };
  const [subfolderIsHidden, setSubfolderIsHidden] = useState(true);
  const [visibleSubfolderCount, setVisibleSubfoldersCount]
    = useState(getVisibleSubfolders);
  window.addEventListener('resize', () => {
    setVisibleSubfoldersCount(getVisibleSubfolders());
  });

  const visibleSubfolders = subfolders.slice(
    0,
    subfolderIsHidden ? visibleSubfolderCount : subfolders.length,
  );

  const onSubfolderClick = (e, folderId) => {
    const exceptedClasses = [
      'sre-icon-transferFile',
      'sre-icon-edit',
      'sre-icon-trash',
    ];
    const { target } = e;
    const { className, tagName } = target;
    if (
      new RegExp(exceptedClasses.join('|')).test(className)
      || tagName === 'BUTTON'
    ) {
      return null;
    }

    onFolderChange({ folder_id: folderId });
    setSubfolderIsHidden(true);
  };

  return (
    <div className="row folders-grid row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-1 flex">
      {visibleSubfolders.map((f) => (
        <div
          key={f.id}
          onClick={(e) => onSubfolderClick(e, f.id)}
          className="col my-[0.5rem] px-[0.5rem] cursor-pointer"
        >
          <SubfolderCard
            subfolder={f}
            currentUserCanManage={currentUserCanManage}
            hideActions={hideActions}
            onDelete={onDelete}
            openNewFolderModal={openNewFolderModal}
            openMoveFolderModal={openMoveFolderModal}
          />
        </div>
      ))}

      {subfolderIsHidden && visibleSubfolderCount < subfolders.length && (
        <div className="col my-[0.5rem] px-[0.5rem] cursor-pointer">
          <div
            className="folder-tile__show-more"
            onClick={() => setSubfolderIsHidden(false)}
          >
            <LinkButton>Show more</LinkButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubfoldersList;

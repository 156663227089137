import http from 'lib/http';

export const FETCH_USER_MANAGEMENT_FILTERS = 'symmetre-client-api/FETCH_USER_MANAGEMENT_FILTERS';

export function fetchUserManagementFilters() {
  return async (dispatch) => {
    const res = await http.get('/settings/user_management/filters');
    const data = await res.json();
    dispatch({ type: FETCH_USER_MANAGEMENT_FILTERS, payload: data });

    return data;
  };
}

import { createSlice } from '@reduxjs/toolkit';
import { FundingSourceForDraw } from '@/shared/types/reconcile/FundingSource';
import { TRootState } from '@/app/stores';
import { TSpecifyReallocationAmount } from 'bundles/Shared/components/SpecifySourcesModal/SpecifySourcesModal';

interface IInitialState {
  fundingSources: (FundingSourceForDraw & TSpecifyReallocationAmount)[];
}

const initialState: IInitialState = {
  fundingSources: [],
};

const reconcileDrawSlice = createSlice({
  name: 'draws',
  initialState,
  reducers: {
    setFundingSource: (state, action) => {
      state.fundingSources = action.payload;
    },
    resetFundingSource: (state) => {
      state.fundingSources = initialState.fundingSources;
    },
  },
});

export const { setFundingSource, resetFundingSource } =
  reconcileDrawSlice.actions;

export const selectFundingSources = (state: TRootState) =>
  state.draws.fundingSources;

export default reconcileDrawSlice.reducer;

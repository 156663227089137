import { emptySplitApi as api } from '@/app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportThresholds: build.query<
      GetApiSettingsReportThresholdsApiResponse,
      GetApiSettingsReportThresholdsApiArg
    >({
      query: () => ({ url: `/api/settings/report/thresholds` }),
    }),
    putApiSettingsReportThresholdsByKind: build.mutation<
      PutApiSettingsReportThresholdsByKindApiResponse,
      PutApiSettingsReportThresholdsByKindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/thresholds/${queryArg.kind}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportThresholdsByKind: build.mutation<
      DeleteApiSettingsReportThresholdsByKindApiResponse,
      DeleteApiSettingsReportThresholdsByKindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/thresholds/${queryArg.kind}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportThresholdsApi };
export type GetApiSettingsReportThresholdsApiResponse =
  /** status 200 success */ {
    items?: ThresholdDto[];
  };
export type GetApiSettingsReportThresholdsApiArg = void;
export type PutApiSettingsReportThresholdsByKindApiResponse =
  /** status 200 success */ ThresholdDto;
export type PutApiSettingsReportThresholdsByKindApiArg = {
  kind: string;
  body: {
    global_value: number;
  };
};
export type DeleteApiSettingsReportThresholdsByKindApiResponse =
  /** status 200 success */ ThresholdDto;
export type DeleteApiSettingsReportThresholdsByKindApiArg = {
  kind: string;
};
export type ThresholdDto = {
  kind:
    | 'delinq_balance'
    | 'delinq_move_in'
    | 'lto_min_term'
    | 'lto_max_variance';
  title: string;
  description: string;
  allowedOverrides: boolean;
  valueType: 'number' | 'date';
  globalValue: number | null;
};

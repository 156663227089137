import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AssetStages from './AssetStages';
import { fetchCustomerConfig } from '../../../Assets/actions';
import FeedTypes from './FeedTypes';
import DocumentTypes from './documentTypes/DocumentTypes';

const PortalCoreSettings = (props) => {
  useEffect(() => {
    props.fetchCustomerConfig();
  }, []);

  const { assetStages, feedTypes, documentTypes } = props.customerConfig;
  return (
    <div>
      <AssetStages assetStages={assetStages} />
      <FeedTypes feedTypes={feedTypes} />
      <DocumentTypes documentTypes={documentTypes} />
    </div>
  );
};

const mapStateToProps = ({ customerConfig }) => ({ customerConfig });

const mapActionCreators = {
  fetchCustomerConfig,
};

export default connect(mapStateToProps, mapActionCreators)(PortalCoreSettings);

import { ReportDashboardSectionPosition, UNGROUPED_GROUP_KEY } from '@/bundles/Shared/entities/dashboard/model';
import { AdjustmentExpressionName } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { CellClassParams } from 'ag-grid-community';
import { CssVar } from '@/shared/config/cssVar';

export * from './config';
export * from './data';

export const isUnderwritingAdjustment = (value: AdjustmentExpressionName) => {
  return value.startsWith('uw_');
};

export const isWidgetSectionPositionHalfSized = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 50;
};

export const isWidgetSectionPositionWidthFullSize = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 100;
};

export * from './config';
export * from './data';

export function resolveComparisonTextColor(
  comparison: {
    rule?:
      | 'negative_red'
      | 'negative_red_positive_green'
      | 'negative_green_positive_red';
    compareTo?: string;
  },
  params: CellClassParams,
  valueGetter?: (data: any, key: string) => number,
) {
  function getAgNodeValue(cellParams: CellClassParams, key: string) {
    const { node } = cellParams;
    const { data } = node;
    return valueGetter ? valueGetter(data, key) : data?.columnValues?.[key];
  }

  if (comparison === undefined) return undefined;

  if (comparison.rule != null) {
    const compareWithClasses = (value: number) => {
      const { rule } = comparison;
      if (rule === 'negative_red') {
        return params.value < value ? CssVar.red : '';
      }
      if (rule === 'negative_red_positive_green') {
        return params.value < value ? CssVar.red : CssVar.green;
      }
      if (rule === 'negative_green_positive_red') {
        return params.value < value ? CssVar.green : CssVar.red;
      }
    };
    const { compareTo } = comparison ?? {};

    if (compareTo !== undefined) {
      if (getAgNodeValue(params, compareTo) == null) return {};

      return compareWithClasses(getAgNodeValue(params, compareTo)!);
    }

    return compareWithClasses(0);
  }

  return undefined;
}

export const isHideColGroupDefEnabled = (
  groupId: StringAndOptions<typeof UNGROUPED_GROUP_KEY>,
): boolean => groupId !== UNGROUPED_GROUP_KEY;

import { ISortParamsField } from 'types/Sort';
import {
  IPaginationMeta,
  IPaginationParams,
  IQueryParams,
} from 'types/Pagination';
import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/index';

export type TGetSovcsInReconcileSettingsParams = IQueryParams &
  ISortParamsField<'id' | 'name' | 'code' | 'jcc'> &
  IPaginationParams & {
    job_cost_code_ids?: number[];
  };

export interface IReconcileSettingsSovc {
  id: number;
  name: string;
  code: string;
  job_cost_code: {
    id: number;
    name: string;
  } | null;
}

export interface IReconcileSettingsUpdateSovc {
  name: string;
  code: string;
  job_cost_code_id: number | null;
}

export const sovcsCrudApi = createCrudApi<
  {},
  TGetSovcsInReconcileSettingsParams,
  IReconcileSettingsSovc,
  IReconcileSettingsUpdateSovc,
  {
    items: IReconcileSettingsSovc[];
    meta: IPaginationMeta;
  }
>('/settings/reconcile/schedule_of_value_codes', 'Schedule of Value Code');

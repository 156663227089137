import React, { PropsWithChildren, useMemo } from 'react';
import { Icon } from 'stories';
import { NoPossiblePeriodsCase } from 'stories/FlexibleFilterByPeriods/FlexibleFilterByPeriods';
import * as FilterLabel from '../SelectedPeriodTypeLabel';
import * as Const from '../consts';
import * as Type from '../types';

interface Props {
  periodItems: Type.IPeriodItem[];
  filterByPeriodsType: Type.IPeriodItem['type'];
  disableOnlyLabel?: boolean;
  placeholder?: React.ReactNode;
  noPossiblePeriodsCase?: NoPossiblePeriodsCase;
  fromToMode?: boolean;
}

function IconsWrapper({ children }: PropsWithChildren) {
  return (
    <>
      <Icon iconName="calendar" className="text-neutral-450" />
      {children}
      <Icon iconName="arrowBottom" className="ml-auto text-neutral-450" />
    </>
  );
}

export function useComputedFilterLabel({
  periodItems,
  filterByPeriodsType,
  disableOnlyLabel,
  placeholder,
  noPossiblePeriodsCase,
  fromToMode,
}: Props) {
  const labelJSX = useMemo(() => {
    if (noPossiblePeriodsCase?.active) return noPossiblePeriodsCase.label;
    if (!periodItems.length) return placeholder ?? 'No Period Selected';

    let label: React.ReactNode = null;

    switch (filterByPeriodsType) {
      case Const.MONTHLY_THIN_TAB.id: {
        label = (
          <FilterLabel.Monthly
            periodItems={periodItems}
            disableOnlyLabel={disableOnlyLabel}
            fromToMode={fromToMode}
          />
        );
        break;
      }
      case Const.QUARTERLY_THIN_TAB.id: {
        label = <FilterLabel.Quarterly periodItems={periodItems} />;
        break;
      }
      default: {
        label = <FilterLabel.Yearly periodItems={periodItems} />;
        break;
      }
    }

    return <IconsWrapper>{label}</IconsWrapper>;
  }, [
    periodItems,
    filterByPeriodsType,
    noPossiblePeriodsCase,
    disableOnlyLabel,
    placeholder,
  ]);

  return labelJSX;
}

import React, { useMemo } from 'react';
import { nameOf } from 'types/utils';
import { TReconcileBudgetSettingsFee } from 'bundles/REconcile/actions/feesConfig';
import {
  DEFAULT_CODE_COLUMN,
  DEFAULT_NAME_COLUMN,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IColumn } from 'bundles/Shared/components/Table/types';

export function useFeesColumns() {
  return useMemo<IColumn<TReconcileBudgetSettingsFee>[]>(
    () => [
      DEFAULT_NAME_COLUMN,
      DEFAULT_CODE_COLUMN,
      {
        dataField: nameOf<TReconcileBudgetSettingsFee>('percent'),
        text: 'Percent',
        sortable: true,
      },
    ],
    [],
  );
}

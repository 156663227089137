import { IUpdateWorkflowReconcileSettings } from 'bundles/REconcile/actions/workflowConfig';
import React from 'react';
import ThresholdTypeCard from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/checkboxes/cards/ThresholdTypeCard';

export const THRESHOLD_TYPE_CARDS_PROPS: ({
  updateKey: keyof IUpdateWorkflowReconcileSettings;
} & Omit<React.ComponentProps<typeof ThresholdTypeCard>, 'onChange'>)[] = [
  {
    label: (
      <>
        Individual <br /> COR Amount
      </>
    ),
    waitingApprovalKey: 'individualAmount',
    valueKey: 'individualCorAmountThresholdEnabled',
    updateKey: 'enable_individual_cor_amount_threshold',
  },
  {
    label: (
      <>
        Aggregate <br /> Contingency Threshold
      </>
    ),
    waitingApprovalKey: 'aggregateContingency',
    valueKey: 'contingencyThresholdEnabled',
    updateKey: 'enable_aggregate_contingency_threshold',
  },
  {
    label: (
      <>
        Estimated <br /> Timing Impact
      </>
    ),
    waitingApprovalKey: 'estimatedTimingImpact',
    valueKey: 'timingImpactThresholdEnabled',
    updateKey: 'enable_estimated_timing_impact_threshold',
  },
];
export const TUMBLER_PROPS = {
  classes: {
    text: 'inline-regular font-weight-400',
  },
};
export const TOOLTIP_TEXT =
  'The option can not be disabled while at least one Change Event is awaiting approval';

import { KpiTableWidget } from './widget';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { KpiTableWidgetConfigComponent } from './config';

export const KPI_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Kpi Table',
  icon: 'table',
  Component: KpiTableWidget,
  ConfigComponent: KpiTableWidgetConfigComponent,
} as const satisfies WidgetConfiguration;

export * from './config';
export * from './lib';
export * from './model';
export * from './widget';

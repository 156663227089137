import { capitalize } from 'lodash-es';
import { ComponentProps } from 'react';
import { Select } from 'stories';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { IUserRole } from 'types/User';
import { AccessType, IPermissionRequestBody } from '../types';

export const TABLE_ITEMS: IThinTabItem[] = [
  {
    id: 1,
    label: 'Rows',
  },
  {
    id: 2,
    label: 'Columns',
  },
];

export const [ROWS_TAB, COLUMNS_TAB] = TABLE_ITEMS;

export const PUBLIC_PERMISSION_ATTRIBUTES: IPermissionRequestBody = {
  public_permission_attributes: {},
  directly_permitted_user_ids: [],
  directly_permitted_user_role_ids: [],
};

export const ACCESS_TYPES: AccessType[] = ['detailed', 'partial', 'summary'];

export const ACCESS_TYPE_OPTIONS = ACCESS_TYPES.map((type) => ({
  id: type,
  value: type,
  label: capitalize(type),
}));

export const DEFAULT_PERIOD_LIMITATION_RULE_LABEL = 'All Roles';
export const DEFAULT_PERIOD_LIMITATION_RULE_ID = 'default';
export const DEFAULT_PERIOD_LIMITATION_ROLE: Pick<IUserRole, 'id' | 'name'> = {
  id: DEFAULT_PERIOD_LIMITATION_RULE_ID,
  name: DEFAULT_PERIOD_LIMITATION_RULE_LABEL,
};

export const SHARED_SELECT_PROPS: Pick<
  ComponentProps<typeof Select>,
  'popoverProps' | 'spanContainer' | 'classes'
> = {
  popoverProps: {
    appendToBody: true,
  },
  spanContainer: 'w-full',
  classes: { input: 'w-full' },
};

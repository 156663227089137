import { CapitalInvestmentClass } from '@/entities/return';
import type {
  InvestmentIndexDto,
  InvestmentObjectUnitPriceDto,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { parseIntOrFloat } from '@/shared/lib/formatting/number';
import {
  MAX_MONTHS_IN_YEAR,
  QUARTERS_IN_YEAR,
} from '@/stories/FlexibleFilterByPeriods/consts';
import { IPeriodItem } from '@/stories/FlexibleFilterByPeriods/types';
import { DistributionRecordItem } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import { TEditableCapitalInvestments } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitalInvestmentsEditor';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { sortBy } from 'lodash-es';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export const capitalInvestmentSumMapper = (item: TEditableCapitalInvestments) =>
  Number(item.amount ?? 0);

export const getQuarterlyPreferredReturn = (
  contributions: number,
  percentage: number,
): number =>
  parseIntOrFloat(((contributions / 100) * percentage) / QUARTERS_IN_YEAR);

export const investmentIncludesClass = (
  investment: InvestmentIndexDto,
  invClass: CapitalInvestmentClass,
) => investment.investmentClasses.includes(invClass);

export const getMonthlyPreferredReturn = (
  contributions: number,
  percentage: number,
): number =>
  parseIntOrFloat(((contributions / 100) * percentage) / MAX_MONTHS_IN_YEAR);

export const getSuggestedValue = ({
  currentTotalAmount,
  amount,
  expectedTotalAmount,
}: Required<Omit<DistributionRecordItem, 'valid' | 'diff'>>) => {
  const isTotalAndAmountEquals = currentTotalAmount === amount;
  const isTotalAndExpectedTotalEquals =
    currentTotalAmount === expectedTotalAmount;
  const totalsDiff = currentTotalAmount - expectedTotalAmount;
  const totalIsGreaterThanExpected = currentTotalAmount > expectedTotalAmount;

  if (currentTotalAmount === 0 && (amount === 0 || amount == null)) {
    return expectedTotalAmount;
  }

  if (isTotalAndExpectedTotalEquals) return -1;

  if (
    isTotalAndAmountEquals &&
    currentTotalAmount - expectedTotalAmount === expectedTotalAmount
  )
    return 0;

  if (amount === currentTotalAmount - expectedTotalAmount) return 0;

  if (isTotalAndAmountEquals) return expectedTotalAmount;

  if (totalIsGreaterThanExpected && totalsDiff > amount) return -1;

  return parseIntOrFloat(expectedTotalAmount - (currentTotalAmount - amount));
};

type UnitPriceWithDiff = InvestmentObjectUnitPriceDto & {
  diffDays: number;
};

const isDateBetweenDays = (
  date: dayjs.ConfigType,
  a: dayjs.ConfigType,
  b: dayjs.ConfigType,
): boolean => dayjs(date).isBetween(a, b, 'day', '[)');

export function getClosestUnitPrice({
  date,
  unitPrices,
}: {
  date: IPeriodItem['period'];
  unitPrices: InvestmentObjectUnitPriceDto[];
}): UnitPriceWithDiff {
  if (unitPrices.length === 0)
    throw new Error(
      'The capital investment object has no "unitPrices" yet. This should not happen.',
    );

  const _unitPrices = sortBy(unitPrices, (u) => dayjs(u.date).unix()).map(
    (up, idx, self) => ({
      ...up,
      prevDate: idx === 0 ? null : self[idx - 1].date,
      nextDate: idx === self.length - 1 ? null : self[idx + 1].date,
      diffDays: Math.abs(dayjs(date).diff(up.date, 'day')),
    }),
  );

  const unitPriceWithDiff: UnitPriceWithDiff = _unitPrices.reduce(
    (acc, curr) => {
      if (curr.prevDate == null && dayjs(curr.nextDate).isAfter(dayjs(date))) {
        return curr;
      }
      if (
        curr.nextDate == null &&
        dayjs(curr.date).isSameOrBefore(dayjs(date))
      ) {
        return curr;
      }
      if (
        curr.prevDate != null &&
        curr.nextDate != null &&
        isDateBetweenDays(date, curr.date, curr.nextDate)
      ) {
        return curr;
      }
      return acc;
    },
  );

  return unitPriceWithDiff;
}

export const getUnitsQty = (amount: number, unitPrice: number): number => {
  if (!amount || !unitPrice) return 0;
  return parseIntOrFloat(amount / unitPrice);
};

export function resolveValidContribution({
  capitalInvestments,
}: {
  capitalInvestments: TEditableCapitalInvestments[];
}): {
  someZeroBasedCapitalInvestments: boolean;
  invalid: boolean;
} {
  let someZeroBasedCapitalInvestments = false;
  const invalid = capitalInvestments.some((item) => {
    if (item._basedUnitPrice === 0) {
      someZeroBasedCapitalInvestments = true;
      return item.unitsQuantity == null || item.unitsQuantity === 0;
    }
    return item.amount == null || item.amount === 0;
  });

  return {
    invalid,
    someZeroBasedCapitalInvestments,
  };
}

import { clamp, replace } from 'lodash-es';
import React from 'react';

export const DISABLE_CURRENCY_SYMBOL_OPTIONS = {
  style: undefined,
  currency: undefined,
  currencyDisplay: undefined,
};

export const DEFAULT_NUMBER_FALLBACK = '–' as const;
export const DEFAULT_CURRENCY_FALLBACK = 'n.a.' as const;

export function formatAmount(
  value: number,
  options: Intl.NumberFormatOptions = {},
): string {
  if (value === null || value === undefined || isNaN(value))
    return DEFAULT_CURRENCY_FALLBACK;

  const formattedAmount = Math.abs(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  });

  return Math.sign(value) < 0 ? `(${formattedAmount})` : formattedAmount;
}

export function accountingNumberFormat(
  value: string,
  options: Intl.NumberFormatOptions = {},
): string {
  return value && value !== '–'
    ? formatAmount(value, options)
    : DEFAULT_NUMBER_FALLBACK;
}

export function percentNumberFormat(
  value: number | null,
  options = {},
  emptyValue: string | React.ReactNode = 'n.a.',
) {
  if (value === null || isNaN(value)) return emptyValue;

  const dividedValue = Math.abs(value) / 100.0;
  const formattedAmount = dividedValue.toLocaleString('en-US', {
    style: 'percent',
    useGrouping: true,
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    ...options,
  });

  return Math.sign(value) < 0 ? `(${formattedAmount})` : formattedAmount;
}

export function metricNumberFormat(value: string): string {
  return `${parseFloat(value).toFixed(2)}x`;
}

// thousands, millions, billions abbreviation
const SI_SYMBOL = ['', 'k', 'M', 'B'];

export function getAbbreviateNumber(number: number) {
  const abs = Math.abs(number);

  // what tier? (determines SI symbol)
  const tier = clamp(Math.floor(Math.log10(abs) / 3), 0, SI_SYMBOL.length - 1);

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = 10 ** (tier * 3);

  // scale the number
  const scaled = abs / scale;

  return {
    suffix,
    scaled,
  };
}

export function abbreviateNumberFormat(number: number): string {
  const { suffix, scaled } = getAbbreviateNumber(number);

  // format number and add suffix
  const formatted = `$${scaled.toFixed(1).toLocaleString()}${suffix}`;

  // format if negative
  if (Math.sign(number) < 0) {
    return `(${formatted})`;
  }

  return formatted;
}

export const trimSeparator = (value: string, trimValue = ',') =>
  Number(replace(value, trimValue, ''));

export function parseNumberFromCurrencyInput(
  value: string,
): number | undefined {
  const atLeastZero = value.length === 0 ? undefined : value;
  return atLeastZero ? parseFloat(atLeastZero.replaceAll(',', '')) : undefined;
}

/**
 * Returns a number with certain amount of fraction digits if a passed number was a float number.
 * @param fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 */
export function parseIntOrFloat(n: number, fractionDigits = 2): number {
  if (Number.isInteger(n)) return n;

  return Number(n.toFixed(fractionDigits));
}
/**
 * Returns the absolute difference between two numbers, or the signed difference if `options.abs` is `false`.
 *
 * @param {number} a - The first number.
 * @param {number} b - The second number.
 * @param {{ abs?: boolean }} [options] - The optional options object.
 * @param {boolean} [options.abs=true] - Whether to return the absolute difference or the signed difference.
 * @returns {number} The absolute or signed difference between `a` and `b`.
 */
export function numDiff(
  a: number,
  b: number,
  options: {
    abs?: boolean;
  } = {
    abs: true,
  },
): number {
  if (options.abs) return Math.abs(a - b);

  return a - b;
}

import { cn } from '@/shared/lib/css/cn';
import { ComponentProps } from 'react';
import { Icon } from 'stories';
import FilterButton from '../filterButton/FilterButton';
import styles from '../userFilterButton/UserFilterButton.module.scss';

type Props = Omit<
  ComponentProps<typeof FilterButton>,
  'iconName' | 'iconPosition' | 'size'
>;

function GroupByButton({ className, children, ...props }: Props) {
  return (
    <FilterButton
      className={cn('inline-semibold', styles.button, className)}
      classes={{
        container: styles.container,
        filtered: styles.button_filtered,
        clicked: styles.button_clicked,
        close: styles.close,
        containerClicked: styles.container_clicked,
        disabled: styles.button_disabled,
      }}
      {...props}
    >
      <Icon className={cn('mr-s', styles.leftIcon)} iconName="groupByFilled" />
      <span className={cn(props.filtered && 'mr-xs', styles.label)}>
        Group by{props.filtered && ':'}&nbsp;
      </span>
      {children}
      {!props.disabled && (
        <Icon className={cn(styles.rightIcon, 'ml-s')} iconName="arrowBottom" />
      )}
    </FilterButton>
  );
}

export default GroupByButton;

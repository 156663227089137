import { CssVar } from '@/shared/config/cssVar';
import { TPlacementKinds } from 'bundles/Construction/components/eSignature/types';

export const CURSOR_DISCREPANCY = 15;

export const LABELS = {
  internal: { color: CssVar.light60, text: 'Internal' },
  external: { color: CssVar.dark20, text: 'External' },
};

export const EXAMPLES = {
  signature: 'John Smith',
  date: '12/31/2022',
};

export const WORKFLOW = Object.keys(EXAMPLES); // TODO: this one will be changed according to workflow

export const COLORS = ['blue', 'green', 'violet'] as const;

export const INDICATOR_STYLES = {
  blue: { main: CssVar.blue, light: CssVar.blueLight4 },
  green: { main: CssVar.green, light: CssVar.greenLight4 },
  violet: { main: CssVar.violet, light: CssVar.violetLight4 },
};

export const FIELDS = {
  company: 'Company',
  date: 'Date',
  job_title: 'Job Title',
  initials: 'Printed Name',
  signature: 'Signature',
} as const;

export const FIELD_KINDS = Object.keys(FIELDS) as TPlacementKinds[];

import { cn } from '@/shared/lib/css/cn';
import React from 'react';
import { ClassNameProps } from 'types/Props';

function ReportSettingsScreenLayout({
  children,
  subTitle,
  title,
  actions,
  className,
}: React.PropsWithChildren<
  {
    title: string;
    subTitle: string;
    actions?: React.ReactNode;
  } & ClassNameProps
>) {
  return (
    <div className={cn('flex min-h-screen flex-col', className)}>
      <div className="radius000 flex gap-tw-4 px-tw-6 py-tw-3 shadow-z-020">
        <div className="flex flex-col">
          <span className="secondary-semibold text-neutral-500">
            {subTitle}
          </span>
          <span className="header6-bold text-neutral-800">{title}</span>
        </div>
        {actions}
      </div>
      {children}
    </div>
  );
}

ReportSettingsScreenLayout.Content = ({
  children,
  className,
}: React.PropsWithChildren<ClassNameProps>) => (
  <div className={cn('relative flex flex-col gap-tw-4 p-tw-6', className)}>
    {children}
  </div>
);

export default ReportSettingsScreenLayout;

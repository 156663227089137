// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ComponentProps } from 'react';
import { capitalize } from 'lodash-es';
import http from 'lib/http';
import { Button, IconButton } from 'stories';
import InvestmentObjectHoverable from 'bundles/Shared/components/InvestmentObjectHoverable';
import QuickFilterByObject from 'bundles/Assets/components/SharedFiles/Table/Filters/QuickFilterByObject';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import SourceTypeColumn from 'bundles/Shared/components/SourceType/SourceTypeColumn/SourceTypeColumn';
import { TConfirmFunc } from '@/shared/lib/hooks/useModal';
import QuickFilterFireButton, {
  TQuickFilterFireButtonProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterFireButton';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { LEConfigsMeta, LegalEntity } from '@/entities/core/legalEntity';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { ILegalEntitiable } from 'types/LegalEntitiable';
import LastActivityOfLEPopover from './LastActivityOfLEPopover';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { IInvestmentObject } from 'types/IInvestmentObject';
import { CoreLegalEntitiesLegalEntitiables } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApi';
import { LeClassificationFormatter } from 'bundles/Shared/entities/leClasssification/ui/LeClassificationFormatters';

export type TFilterModel = {
  permissions_fire?: boolean;
};

const sortOptions: Pick<IColumn<LegalEntity>, 'sortable'> = {
  sortable: true,
};

const getSortOptions = (hasSort: boolean) => (hasSort ? sortOptions : {});

const code = (sort: typeof sortOptions): IColumn<LegalEntity> => ({
  dataField: 'code',
  text: 'Code',
  ...sort,
});

const name = (sort: typeof sortOptions): IColumn<LegalEntity> => ({
  dataField: 'name',
  text: 'Name',
  ...sort,
});

const source = (): IColumn<LegalEntity> => ({
  dataField: 'source',
  text: 'Source',
  headerStyle: { width: 200 },
  formatter: ({ row }) => <SourceTypeColumn sourceType={row.source} />,
});

const leClassification = (actions: Actions): IColumn<LegalEntity> => ({
  dataField: 'classification',
  text: 'Class',
  headerStyle: { width: 200 },
  formatter: ({ row }) => (
    <LeClassificationFormatter
      classification={row.classification}
      onClick={() => {
        actions.openSetLEClassifaction(row);
      }}
      showFireIcon={row.classification == null && row.legalEntitiableId != null}
    />
  ),
});

const lastUpdated = (): IColumn<LegalEntity> => ({
  dataField: 'lastUpdated',
  text: 'Last updated',
  headerStyle: { width: 185 },
  formatter: ({ row }) =>
    row.lastActivity ? <LastActivityOfLEPopover legalEntity={row} /> : '-',
});

// eslint-disable-next-line
const legalEntitiable = (actions: Actions, allAssets: IAsset[], allFunds: IFund[], selectedFilters): IColumn<LegalEntity> => {
  const investmentObjects =
    allAssets && allFunds
      ? [...allAssets, ...allFunds].map((obj) => ({
          id: obj.id,
          name: obj.name,
          klass: capitalize(obj.objectType),
          uniqId: `${obj.id}-${obj.objectType}`,
        }))
      : [];

  return {
    dataField: 'legalEntitiable',
    text: 'Object',
    classes: 'min-w-[160px]',
    filterComponent:
      actions.legalEntitiesScope === 'active-and-mapped'
        ? QuickFilterByObject
        : undefined,
    filterComponentParams: {
      investmentObjects: investmentObjects as IInvestmentObject[],
      selectedObjects: selectedFilters.investmentObjects,
      setSelectedObjects: (items) => {
        actions.setSelectedFilters({ investmentObjects: items });
      },
      resetFilter: () => {
        actions.localResetFilter('investmentObjects');
      },
    } satisfies ComponentProps<typeof QuickFilterByObject>,
    formatter: ({ row }) => {
      if (!row.legalEntitiable) {
        return (
          <div className="flex items-center gap-tw-2">
            <Button
              size="xs"
              variant="secondary"
              onClick={() => {
                actions.setCurrentLegalEntity(row);
                actions.openSetObjModalForSingleLE(row);
              }}
            >
              Set Object
            </Button>
            <FireIcon priority="medium" />
          </div>
        );
      }

      return (
        <InvestmentObjectHoverable
          investmentObjectType={row.legalEntitiable.objectType}
          investmentObject={row.legalEntitiable}
          forcedKey={`le-${row.id}`}
        />
      );
    },
    ...getSortOptions(actions.legalEntitiesScope === 'active-and-mapped'),
  };
};

const permissions = (
  actions: Actions,
  fireStationOptions: LEFireStationOptions,
): IColumn<LegalEntity> => ({
  dataField: 'permissions',
  text: 'Permissions',
  filterComponent: QuickFilterFireButton,
  filterComponentParams: {
    count: fireStationOptions.totals.totalFireStationPermissions,
  } as TQuickFilterFireButtonProps,
  formatter: ({ row }) => (
    <PermissionListLine
      onClick={() => actions.onViewPermissions(row)}
      permissions={{
        directInvestmentEntities: row.permitted.investmentEntities,
        directRoles: row.permitted.roles,
        directTags: row.permitted.tags,
        directUsers: row.permitted.users,
        indirectUsers: row.permitted.autoSelectedUsers,
        isPublic: row.permitted.public,
      }}
    />
  ),
});

const leActions = (actions, confirm: TConfirmFunc): IColumn<LegalEntity> => ({
  dataField: 'actions',
  text: 'actions',
  headerStyle: { width: 109 },
  formatter: ({ row }) => (
    <div className="flex w-max items-center gap-tw-2">
      <Button
        size="xs"
        variant="secondary"
        onClick={() => actions.setLegalEntityModalOpened(row)}
      >
        Edit
      </Button>
      <IconButton
        tooltipProps={{
          mainText: row.excluded ? 'Set Active' : 'Exclude',
        }}
        variant="secondary"
        onClick={async () => {
          const result = await confirm({
            title: 'Warning',
            subtitle: 'Are you sure?',
          });
          if (result) {
            http
              .post(`/legal_entities/${row.id}/exclude`, {})
              .then((res) => {
                // TODO: error handling
                if (res.ok)
                  toastr.success('Legal Entity has been successfully updated');
              })
              .then(() =>
                actions.setLegalEntitiesScope(actions.legalEntitiesScope),
              )
              .then(() => {
                actions.refreshMeta();
                actions.setSelectedRows([]);
              });
          }
        }}
        iconName={row.excluded ? 'eye' : 'eyeSlash'}
      />
    </div>
  ),
});

interface Actions {
  onViewPermissions: (le: LegalEntity) => Promise<void>;
  setLegalEntityModalOpened: (v: boolean) => void;
  setPermissionsModalOpened: (v: boolean) => void;
  setCurrentLegalEntity: (le: LegalEntity) => void;
  openSetObjModalForSingleLE: (le: LegalEntity) => void;
  openSetLEClassifaction: (le: LegalEntity) => void;
  setLegalEntitiesScope: (scope: string) => void;
  refreshMeta: () => void;
  setSelectedRows: (rows: LegalEntity[]) => void;
  setSelectedFilters: (filters: Record<string, unknown>) => void;
  localResetFilter: (filterName: string) => void;
}

export interface LEFireStationOptions {
  totals: Omit<LEConfigsMeta, 'perPage'>;
}

interface Params {
  actions: Actions;
  allAssets: CoreLegalEntitiesLegalEntitiables[] | undefined;
  allFunds: CoreLegalEntitiesLegalEntitiables[] | undefined;
  selectedFilters: {
    investmentObjects: ILegalEntitiable[];
  };
  fireStationOptions: LEFireStationOptions;
  confirm: TConfirmFunc;
}

export const createLegalEntityColumns = ({
  actions,
  allAssets = [],
  allFunds = [],
  selectedFilters,
  fireStationOptions,
  confirm,
}: Params): IColumn<LegalEntity>[] => [
  code(sortOptions),
  name(sortOptions),
  source(),
  legalEntitiable(actions, allAssets, allFunds, selectedFilters),
  leClassification(actions),
  permissions(actions, fireStationOptions),
  lastUpdated(),
  leActions(actions, confirm),
];

import { ObjectLegalEntityCheckboxList } from '@/bundles/REport/components/financials/filter/LegalEntitiesFilter';
import { useSubscribePeriodItemsToLocalStorage } from '@/bundles/REport/components/financials/hooks/useSubscribePeriodItemsToLocalStorage';
import {
  _ObjectableType,
  selectIsSingleMode,
  selectReportTableConfig,
  selectSelectedLegalEntities,
  selectSelectedObjects,
  toggleDashboardExpanded,
  toggleObjectLegalEntitySelection,
  toggleObjectSelection,
  updateObjectListSelectionByType,
  updateObjectSelection,
} from '@/bundles/REport/reducers/financialsSlice';
import { LegalEntity } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { useLocation, useNavigate } from '@reach/router';
import {
  useGetTableDataQuery,
  useGetTablePeriodsQuery,
} from 'bundles/REport/api/financialsApi';
import { REPORT_FINANCIALS_DASHBOARD_HEADER_LOADING_JSX } from 'bundles/REport/components/financials/dumbJSX';
import AssetFilterItemDropdown from 'bundles/Shared/components/Filters/assets/AssetFilterItemDropdown';
import FilterBar from 'bundles/Shared/components/Filters/common/filterBar/FilterBar';
import FilterItem from 'bundles/Shared/components/Filters/common/filterItem/FilterItem';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { ReportFinancialsTable } from 'bundles/Shared/components/ReportTable/ReportFinancialsTable';
import { IColumn, IGlRow } from 'bundles/Shared/components/ReportTable/types';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import ScreenWithListNavigationLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { objectEntries } from 'lib/typeHelpers';
import { capitalize, groupBy, xorBy } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { ListOption } from 'stories/Checkbox/CheckList';
import FavoriteCardIcon from 'stories/ProjectCard/FavoriteCardIcon';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { IconButton } from 'stories/index';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { ExportButtonWithModal } from './ExportButton';
import { FinancialsTabs } from './FinancialsTabs';
import * as Const from './consts';
import FinancialFilterSegmentFilterItem from './filter/FinancialFilterSegmentFilterItem';
import TransactionDetailsModal from './modal/TransactionDetailsModal';
import type * as Type from './types';
import { createReportFinancialsObjIdAndType as createObjIdAndType } from './utils';
import { FinancialsDatePicker } from './widgets/FinancialsDatePicker';

function FinancialsDashboardHeaderObject({
  object,
  loading,
}: {
  object: Type.ObjAndStateProps;
  loading: boolean;
}) {
  if (loading) return REPORT_FINANCIALS_DASHBOARD_HEADER_LOADING_JSX;

  return (
    <div className="flex items-center gap-tw-4">
      <ScreenWithListNavigationLayout.HeaderImg
        imgSrc={'smallPictureUrl' in object ? object.smallPictureUrl : null}
        placeholderIcon={Const.OBJECTABLE_TYPE_ICON_NAME_MAP[object._type]}
      />
      <div className="flex flex-col gap-tw-1">
        <p
          className={cn(
            Const.OBJECTABLE_TYPE_CLASS_NAME_MAP[object._type],
            'secondary-semibold uppercase',
          )}
        >
          {object._type}
        </p>
        <p className="header5-bold text-neutral-800">
          {'title' in object ? object.title : object.name}
        </p>
      </div>
    </div>
  );
}

function FinancialsObjectLegalEntities({
  object,
  onCheck,
}: {
  object: Type.ObjAndStateProps;
  onCheck: (option: ListOption<LegalEntity['id']>) => void;
}) {
  return (
    <FilterBar
      isCollapsible
      className={cn('inline-flex')}
      title="Legal Entities"
    >
      {object.legalEntities.map((le) => (
        <FilterItem
          key={le.id}
          label={le.name}
          hidden={!le._selected}
          onHide={() => onCheck({ label: le.name, value: String(le.id) })}
        />
      ))}
    </FilterBar>
  );
}

function FinancialsTableContainer({
  reportTableConfig,
  legalEntities,
  isTablePeriodsDataFetching,
}: {
  reportTableConfig: IReportTableConfig;
  legalEntities: LegalEntity[];
  isTablePeriodsDataFetching: boolean;
}) {
  const { openModal } = useModal();
  const financialsSlice = useAppSelector((state) => state.financials);
  const { showUnpublished, periodItems, possiblePeriods } = financialsSlice;

  const handleCellClick = (row: IGlRow, col: IColumn) => {
    openModal(TransactionDetailsModal, {
      legalEntities,
      generalLedger: row.generalLedger,
      showUnpublished,
      periods: col.periods,
      reportTableConfigId: reportTableConfig.id,
    });
  };

  const arraysToCheck = [legalEntities, periodItems, possiblePeriods] as const;
  const skipTableDataLoading = arraysToCheck.some((arr) => arr.length === 0);

  const {
    data: tableData,
    isFetching,
    isSuccess,
  } = useGetTableDataQuery(
    {
      reportTableConfigId: reportTableConfig.id,
      reqBody: {
        legal_entity_ids: legalEntities.map((l) => l.id),
        periods: periodItems,
        show_unpublished: showUnpublished,
      },
    },
    {
      skip: skipTableDataLoading,
    },
  );

  if (isFetching || isTablePeriodsDataFetching) {
    return <LoadingTable className="grow bg-neutral-000" rows={18} cols={6} />;
  }

  if (skipTableDataLoading) {
    let title = '';

    switch (true) {
      case legalEntities.length === 0:
        title = 'No legal entities selected';
        break;

      case possiblePeriods.length === 0:
        title = 'Nothing found';
        break;

      case periodItems.length === 0:
        title = 'Periods are not selected';
        break;

      default:
        break;
    }

    return <NoDataOverlay title={title} />;
  }

  if (!isSuccess) {
    return <NoDataOverlay title="No data available, please try later" />;
  }
  if (tableData === undefined) return null;

  if (tableData.columns.length === 0 || tableData.rows.length === 0) {
    return <NoDataOverlay title="Nothing found" />;
  }

  return (
    <ReportFinancialsTable
      periodsType={reportTableConfig.periodsType}
      onActiveCellClick={handleCellClick}
      rows={tableData.rows}
      columns={tableData.columns}
    />
  );
}

export function FinancialsDashboard({
  isObjectsLoading,
}: {
  isObjectsLoading: boolean;
}) {
  const navigate = useNavigate();
  const { favouriteItems, toggleBulkItemsFavorite: toggleItemsListFavorite } =
    useFavoriteItemIds('reportFinancialsObjectIdAndType');
  const { navigateBack } = useNavigateBack({
    fallbackUrl: generateUrl(ROUTES_ROOT.assets.fullPath),
  });
  const financialsSlice = useAppSelector((state) => state.financials);
  const isSingleMode = useAppSelector(selectIsSingleMode);

  const reportTableConfig = useAppSelector(selectReportTableConfig);
  const { dashboardExpanded, showUnpublished, periodItems } = financialsSlice;

  const selectedObjects = useAppSelector(selectSelectedObjects);
  const selectedLegalEntities = useAppSelector(selectSelectedLegalEntities);

  useSubscribePeriodItemsToLocalStorage();

  const dispatch = useAppDispatch();

  const { isFetching: isTablePeriodsDataFetching } = useGetTablePeriodsQuery({
    reportTableConfigId: reportTableConfig.id,
    show_unpublished: showUnpublished,
    legal_entity_ids: selectedLegalEntities.map((l) => l.id),
  });

  const groupedSelectedObjectables = useMemo(() => {
    const grouped = groupBy(selectedObjects, '_type');
    return grouped as Record<_ObjectableType, typeof selectedObjects>;
  }, [selectedObjects]);

  const handleSelect = (object: Type.ObjAndStateProps) => {
    dispatch(toggleObjectSelection({ object }));
  };

  const handleSelectLE = (
    object: Type.ObjAndStateProps,
    legalEntityId: Type.LEAndStateProps['id'],
  ) => {
    dispatch(
      toggleObjectLegalEntitySelection({
        object,
        legalEntityId,
      }),
    );
  };

  const handleToggle = (object: Type.ObjAndStateProps, _selected: boolean) => {
    dispatch(
      updateObjectSelection({
        object,
        _selected,
      }),
    );
  };

  const handleUpdateObjectGroupSelection = (
    _type: _ObjectableType,
    _selected: boolean,
  ) => {
    dispatch(
      updateObjectListSelectionByType({
        _selected,
        _type,
      }),
    );
  };

  const handleToggleDashboardExpand = () => {
    dispatch(toggleDashboardExpanded());
  };

  const areSelectedObjectsFavoriteItems = selectedObjects
    .map(createObjIdAndType)
    .every((itemId) => favouriteItems.includes(itemId));

  const singleCheckedObject = selectedObjects.find((obj) => obj._firstSelected);

  const { state: locationState } = useLocation();
  const showBackButton = useMemo<boolean>(() => {
    if (typeof locationState !== 'object' || locationState == null)
      return false;
    if (singleCheckedObject?._type === 'segment') return false;
    if ('from' in locationState && typeof locationState.from === 'string') {
      return locationState.from.includes(singleCheckedObject?.slug ?? '');
    }
    return false;
  }, [locationState, singleCheckedObject]);

  return (
    <div className="flex w-full flex-col">
      <div className="radius000 flex h-max flex-col gap-tw-4 border-none bg-white px-tw-6 pb-tw-1 pt-tw-4">
        <div className="flex min-h-[56px] items-center justify-between gap-tw-4">
          {showBackButton && (
            <IconButton
              onClick={navigateBack}
              className="h-full min-w-[24px]"
              iconName="arrowLeft"
              variant="secondary"
            />
          )}
          {!isSingleMode && (
            <div className="flex flex-wrap gap-tw-4">
              {objectEntries(groupedSelectedObjectables).map(
                ([key, groupedObjects]) => (
                  <FilterBar
                    key={key}
                    title={pluralize(capitalize(key))}
                    onClose={() => handleUpdateObjectGroupSelection(key, false)}
                  >
                    {groupedObjects.map((obj) => {
                      if (obj._type === 'segment') {
                        return (
                          <FinancialFilterSegmentFilterItem
                            key={obj.id}
                            segment={obj}
                            onClose={() => handleToggle(obj, false)}
                          />
                        );
                      }
                      const selectedLEs = obj.legalEntities.filter(
                        (le) => le._selected,
                      );
                      return (
                        <AssetFilterItemDropdown
                          key={obj.id}
                          legalEntitiesCount={obj.legalEntities.length}
                          selectedLegalEntitiesCount={selectedLEs.length}
                          label={obj.name}
                          onClose={() => handleSelect(obj)}
                        >
                          {obj.legalEntities.length > 0 && (
                            <ObjectLegalEntityCheckboxList
                              objectName={obj.name}
                              legalEntities={obj.legalEntities}
                              selectedLegalEntities={selectedLEs}
                              onChange={(options) => {
                                handleSelectLE(
                                  obj,
                                  xorBy(
                                    mapItemsToListOption(selectedLEs, 'name'),
                                    options as ListOption<string>[],
                                    'id',
                                  )[0].value,
                                );
                              }}
                            />
                          )}
                        </AssetFilterItemDropdown>
                      );
                    })}
                  </FilterBar>
                ),
              )}
            </div>
          )}
          {isSingleMode && singleCheckedObject && (
            <FinancialsDashboardHeaderObject
              loading={isObjectsLoading}
              object={singleCheckedObject}
            />
          )}
          {isObjectsLoading ? (
            <div className="flex gap-tw-2">
              <SkeletonBlock className="h-[32px] w-[240px]" />
            </div>
          ) : (
            <div className="ml-auto flex items-center gap-tw-2">
              {dashboardExpanded && (
                <FinancialsDatePicker
                  possiblePeriodsFetching={isTablePeriodsDataFetching}
                />
              )}
              <IconButton
                size="l"
                iconName="settings"
                onClick={() =>
                  navigate(
                    generateUrl(
                      ROUTES_ROOT.settings.report.tableBuilder.tableSlug
                        .fullPath,
                      {
                        pathParams: {
                          tableSlug: reportTableConfig.slug,
                        },
                      },
                    ),
                  )
                }
              />
              <IconButton
                size="l"
                variant="secondary"
                iconName={dashboardExpanded ? 'collapse' : 'expand'}
                onClick={handleToggleDashboardExpand}
              />
              <FavoriteCardIcon
                onClick={() =>
                  toggleItemsListFavorite(
                    selectedObjects.map(createObjIdAndType),
                  )
                }
                selected={areSelectedObjectsFavoriteItems}
                className="h-tw-8 w-tw-8 !rounded-lg border border-solid border-neutral-100 shadow-z-020"
              />
              <VerticalSeparator />
              <ExportButtonWithModal
                legalEntities={selectedLegalEntities}
                periodItems={periodItems}
                reportTableConfig={reportTableConfig}
                showUnpublished={showUnpublished}
              />
            </div>
          )}
        </div>
        {isSingleMode &&
          (isObjectsLoading ? (
            <div className="flex gap-tw-2">
              <SkeletonBlock className="h-[40px] w-[480px]" />
            </div>
          ) : (
            singleCheckedObject && (
              <FinancialsObjectLegalEntities
                object={singleCheckedObject}
                onCheck={(option) => {
                  handleSelectLE(singleCheckedObject, option.value);
                }}
              />
            )
          ))}
        <FinancialsTabs />
      </div>
      <FinancialsTableContainer
        reportTableConfig={reportTableConfig}
        legalEntities={selectedLegalEntities}
        isTablePeriodsDataFetching={isTablePeriodsDataFetching}
      />
    </div>
  );
}

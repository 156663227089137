import React, { FC } from 'react';
import { Field, Input } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from '../types/types';

interface Props extends IFormItem {
  value: string;
  leftIcon?: string;
}

const FormInput: FC<Props> = ({
  title,
  placeholder,
  note,
  value,
  formData,
  setFormData,
  hint,
  fieldName,
  required,
  loading,
  leftIcon,
  styles,
  disabled,
  error,
  validation,
  validate,
  setValidate,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (validation) {
      setValidate({
        ...validate,
        [fieldName]: validation.func(event.target.value),
      });
    }
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
  };

  return (
    <div
      className={cn(
        'form-item',
        styles?.wrapper ?? 'w-full',
        styles?.nomb && 'form-item_nomb',
      )}
    >
      <Field
        labelText={title}
        required={required}
        noLabel={!title}
        hint={hint}
        note={note}
      >
        <Input
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          disabled={loading || disabled}
          leftIcon={leftIcon}
          className={styles?.input}
          size={styles?.size ?? 'l'}
          type={styles?.type ?? 'text'}
          classes={{
            input: styles?.input__item,
          }}
        />
        {error && <div className="red">{error}</div>}
        {validation && formData[fieldName] && !validate?.[fieldName] && (
          <div className="red">{validation.message}</div>
        )}
      </Field>
    </div>
  );
};
export default FormInput;

import { DEFAULT_TABLE_VIZ_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { ReadonlyDeep } from 'type-fest';
import { KpiTableSingleDateWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';

export const DEFAULT_KPI_TABLE_SINGLE_DATE_CONFIG = {
  ...DEFAULT_TABLE_VIZ_CONFIG,
  columns: [],
  default_options: {
    grouping_type: 'assets',
    date: {
      period_shift: {
        weeks: -1,
      },
    },
  },
} as const satisfies ReadonlyDeep<KpiTableSingleDateWidgetConfigDto>;

export const ERROR_MESSAGE = 'Item not found!';

/**
 * Executes vanilla "find" function from Array.prototype
 * and makes sure that type is NOT "undefined"
 *
 * CAUTION! Only use when you are sure that item exists in a passed array
 */
export function mustFind<T>(arr: T[], predicate: (t: T) => boolean): T {
  const item = arr.find(predicate);

  if (item === undefined) throw new Error(ERROR_MESSAGE);

  return item;
}

import { Vendor } from '@/shared/types/reconcile/Invoice';
import { cn } from '@/shared/lib/css/cn';
import { Tooltip, Icon } from 'stories';
import { capitalize } from 'lodash-es';
import { ReportLineItemType } from 'bundles/REturn/actions/types';

export const getVendorFormatter = (
  vendor?: Pick<Vendor, 'name'>,
  classNameContainer?: string,
) =>
  vendor ? (
    <Tooltip titleText={vendor.name}>
      <div
        className={cn(
          'flex w-fit items-center gap-tw-1 overflow-hidden rounded-lg bg-light px-tw-1 py-tw-0.5',
          classNameContainer,
        )}
      >
        <Icon className="pb-1 text-light-60" iconName="bag" />
        <span className="secondary-regular text-ellipsis text-dark-60">
          {vendor.name}
        </span>
      </div>
    </Tooltip>
  ) : (
    '-'
  );

export const getSliceNumber = (value: string, countVisibleValue: number) => {
  const valueLength = value.length;
  const restSymbolsCount = valueLength - countVisibleValue;

  if (valueLength <= countVisibleValue * 2) {
    return value;
  }
  return `${value.slice(0, countVisibleValue)} ... ${value.slice(
    restSymbolsCount,
  )}`;
};

export const getValueTypeTransaction = (valueType: ReportLineItemType) => (
  <div className="flex items-center">
    <Icon
      className={cn(
        'mr-tw-2',
        valueType === 'debit'
          ? 'green bg-green-light-5'
          : 'bg-red-light-5 text-red',
      )}
      padding="xs"
      iconName={valueType}
    />
    <span className="whitespace-nowrap text-light-60">
      {capitalize(valueType)}
    </span>
  </div>
);

import { TRentrollUnitStatus } from '@/entities/report/unitTypes/model';

export const SRE_UNIT_STATUS_MAP = {
  [TRentrollUnitStatus.ADMIN]: {
    label: 'Admin',
    value: TRentrollUnitStatus.ADMIN,
  },
  [TRentrollUnitStatus.APPLICANT]: {
    label: 'Applicant',
    value: TRentrollUnitStatus.APPLICANT,
  },
  [TRentrollUnitStatus.OCCUPIED]: {
    label: 'Occupied',
    value: TRentrollUnitStatus.OCCUPIED,
  },
  [TRentrollUnitStatus.OCCUPIED_NTV]: {
    label: 'Occupied ntv',
    value: TRentrollUnitStatus.OCCUPIED_NTV,
  },
  [TRentrollUnitStatus.OCCUPIED_NTVL]: {
    label: 'Occupied ntvl',
    value: TRentrollUnitStatus.OCCUPIED_NTVL,
  },
  [TRentrollUnitStatus.VACANT_LEASED]: {
    label: 'Vacant Leased',
    value: TRentrollUnitStatus.VACANT_LEASED,
  },
  [TRentrollUnitStatus.VACANT]: {
    label: 'Vacant',
    value: TRentrollUnitStatus.VACANT,
  },
} as const satisfies Record<
  TRentrollUnitStatus,
  {
    label: string;
    value: TRentrollUnitStatus;
  }
>;

export const SRE_UNIT_STATUSES = Object.values(SRE_UNIT_STATUS_MAP) as {
  label: string;
  value: TRentrollUnitStatus;
}[];

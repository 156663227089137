import { useModal } from '@/shared/lib/hooks/useModal';
import {
  useDeleteApiSettingsReportManualVariableTagsByIdMutation,
  usePostApiSettingsReportManualVariableTagsMutation,
  usePutApiSettingsReportManualVariableTagsByIdMutation,
} from '@/entities/report/manualVariable';
import {
  EntityTag,
  EntityTagKind,
  TAG_ENTITIES,
  TagEntity,
} from 'bundles/Shared/entities/tag';
import { TagModal } from 'bundles/Shared/entities/tag/ui/TagModal';
import { useCallback } from 'react';
import {
  useDeleteApiSettingsReportFormulaTagsByIdMutation,
  usePostApiSettingsReportFormulaTagsMutation,
  usePutApiSettingsReportFormulaTagsByIdMutation,
} from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';

export const useEditEntityTag = (entity: TagEntity) => {
  const { openModal } = useModal();
  const [updateTag] =
    entity === TAG_ENTITIES.MANUAL_VARIABLE
      ? usePutApiSettingsReportManualVariableTagsByIdMutation()
      : usePutApiSettingsReportFormulaTagsByIdMutation();
  return useCallback(async (tag: EntityTag) => {
    const res = await openModal(TagModal, {
      tag,
    });
    if (res) {
      await updateTag({
        id: tag.id,
        body: {
          name: res.name,
          kind: res.isSystem ? EntityTagKind.System : EntityTagKind.Other,
        },
      });
    }
  }, []);
};

export const useCreateEntityTag = (entity: TagEntity) => {
  const { openModal } = useModal();
  const [createTag] =
    entity === TAG_ENTITIES.MANUAL_VARIABLE
      ? usePostApiSettingsReportManualVariableTagsMutation()
      : usePostApiSettingsReportFormulaTagsMutation();
  return useCallback(
    async (
      modalProps?: Omit<
        React.ComponentProps<typeof TagModal>,
        'onSubmit' | 'onClose'
      >,
    ) => {
      const res = await openModal(TagModal, modalProps ?? {});
      if (res) {
        await createTag({
          body: {
            name: res.name,
            kind: res.isSystem ? EntityTagKind.System : EntityTagKind.Other,
          },
        });
      }
    },
    [],
  );
};

export const useDeleteEntityTag = (entity: TagEntity) => {
  const { confirm } = useModal();
  const [deleteTag] =
    entity === TAG_ENTITIES.MANUAL_VARIABLE
      ? useDeleteApiSettingsReportManualVariableTagsByIdMutation()
      : useDeleteApiSettingsReportFormulaTagsByIdMutation();
  return useCallback(async (tag: EntityTag) => {
    const res = await confirm({
      title: 'Delete Tag',
      subtitle: `Are you sure you want to delete the tag "${tag.name}"?`,
    });
    if (!res) {
      return;
    }
    await deleteTag({ id: tag.id });
  }, []);
};

import { OBJECT_DASHBOARD_PAGE_TABS_MAP } from '@/pages/assets/page';
import { AssetReportsPage } from '@/pages/assets/reports/page';
import { currentUserAllowedToSeeReportsPageAndThisAsset } from '@/pages/assets/reports/permissions';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  ROUTES_ROOT,
  allSubPathMatches,
  generateUrl,
} from '@/shared/lib/hooks/useNavigation';
import {
  Link,
  RouteComponentProps,
  Router,
  useLocation,
  useNavigate,
} from '@reach/router';
import AssetDocuments from 'bundles/Assets/components/AssetDocuments';
import AssetFormModal from 'bundles/Assets/components/AssetFormModal';
import { Media } from 'bundles/Assets/components/Media';
import ExternalUsersListModal from 'bundles/InvestmentObjects/components/ExternalUsersListModal';
import Posts from 'bundles/InvestmentObjects/components/Overview/Posts';
import {
  useGetAssetQuery,
  useGetVisibleReportTableConfigsQuery,
  useHeavyUpdateAssetMutation,
  useHeavyUpdateAssetWithFormMutation,
  useHeavyUpdateAssetWithInvalidationMutation,
  useSimpleUpdateAssetMutation,
} from 'bundles/Settings/components/Portal/AssetDashboard/api/coreAssetsApi';
import { toggleCoreEntityDashboardExpanded } from 'bundles/Settings/components/Portal/AssetDashboard/api/coreEntitySlice';
import { ALL_STATUS_OPTIONS_MAP } from 'bundles/Settings/components/Portal/shared/consts';
import Banner from 'bundles/Settings/components/Portal/shared/ui/Banner';
import KeyInfoModal from 'bundles/Settings/components/Portal/shared/ui/KeyInfoModal';
import ObjectFinancialTabs from 'bundles/Settings/components/Portal/shared/ui/ObjectFinancialTabs';
import { ObjectReportTabs } from 'bundles/Settings/components/Portal/shared/ui/ObjectReportTabs';
import StatusDropdown from 'bundles/Settings/components/Portal/shared/ui/StatusDropdown';
import TabLink, {
  TAB_LINK_CLASSES,
} from 'bundles/Settings/components/Portal/shared/ui/TabLink';
import { getDraftObjectsWarningMessage } from 'bundles/Settings/components/Portal/shared/utils';
import EntityContext from 'bundles/Shared/EntityContext';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { MODULE_LABELS } from 'lib/dictionaries';
import {
  TProductNames,
  currentUserAllowedTo,
  currentUserIsExternal,
  currentUserIsInternal,
  currentUserIsSreAdmin,
} from 'lib/permissions';
import { RouterComponentWithoutWrapper } from 'lib/reach-router';
import { capitalize } from 'lodash-es';
import { useState } from 'react';
import FavoriteCardIcon from 'stories/ProjectCard/FavoriteCardIcon';
import { AnimationLoader, Button, Icon, IconButton } from 'stories/index';
import { IAsset } from 'types/Asset';
import { ISharedDocument } from 'types/SharedDocument';
import { PropertiesCamelToSnakeCase } from 'types/StringUtilityTypes';

export type UpdatedAsset = {
  id: IAsset['id'];
  slug: IAsset['slug'];
  asset: PropertiesCamelToSnakeCase<Partial<IAsset>>;
};

function AssetDashboardContent({
  assetSlug,
}: RouteComponentProps<{ assetSlug: string }>) {
  const { data: asset, isFetching } = useGetAssetQuery(assetSlug ?? '', {
    skip: !assetSlug,
  });
  const isUserAllowedToViewReport = currentUserAllowedTo(
    'view',
    TProductNames.REPORT,
  );
  const {
    data: visibleReportTableConfigs,
    isFetching: isFetchingVisibleConfigs,
  } = useGetVisibleReportTableConfigsQuery(asset?.slug ?? '', {
    skip: !isUserAllowedToViewReport || !asset?.slug,
  });
  const { openModal } = useModal();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<ISharedDocument[]>([]);
  const dashboardExpanded = useAppSelector(
    (state) => state.coreEntity.dashboardExpanded,
  );
  const dispatch = useAppDispatch();
  const [keyInfoModalIsOpen, setKeyInfoModalIsOpen] = useState(false);
  const [simpleUpdate] = useSimpleUpdateAssetMutation();
  const [heavyUpdate] = useHeavyUpdateAssetMutation();
  const [heavyUpdateWithInvalidate] =
    useHeavyUpdateAssetWithInvalidationMutation();
  const [heavyUpdateWithForm] = useHeavyUpdateAssetWithFormMutation();

  const { isItemFavorite, toggleItemFavorite } = useFavoriteItemIds(
    'coreAssetsFavoriteIds',
  );

  const handleKeyInfoClick = () => {
    if (asset == null) return;

    setKeyInfoModalIsOpen(true);
  };

  const handleToggleExpand = () => {
    dispatch(toggleCoreEntityDashboardExpanded());
  };

  if (isFetching) {
    return <AnimationLoader className="relative h-full w-full" />;
  }

  if (!asset) return null;

  const externalUsers = asset?.users?.filter((user) => user.external) ?? [];

  const externalUsersListButton = (
    <Button
      tooltipProps={{
        mainText: `This asset has ${externalUsers.length} external users`,
      }}
      onClick={() => {
        openModal(ExternalUsersListModal, {
          object: asset,
          externalUsers,
        });
      }}
      className="secondary-regular gap-tw-1"
      iconName="users"
      variant="secondary"
      size="s"
    >
      {externalUsers.length}
    </Button>
  );

  const openObjectModal = async () => {
    const res = await openModal(AssetFormModal, {
      asset,
    });

    if (res == null) return;

    const response = await heavyUpdateWithForm(res);

    if (
      response != null &&
      'data' in response &&
      typeof response.data.slug === 'string'
    ) {
      navigate(
        generateUrl(ROUTES_ROOT.assets.asset.fullPath, {
          pathParams: {
            assetSlug: response.data.slug,
          },
        }),
      );
    }
  };
  const isVisibleReportTableConfigDataExist =
    (visibleReportTableConfigs?.reportTableConfigs.length ?? 0) > 0;

  const isCurrentUserExternal = currentUserIsExternal();
  const isUserAllowedToManageAssetPortal = currentUserAllowedTo(
    'manage',
    TProductNames.ASSET_PORTAL,
    'Asset',
    asset.id,
  );
  const isUserAllowedToManageReturn = currentUserAllowedTo(
    'manage',
    TProductNames.RETURN,
  );
  const isUserAllowedToViewAsset = currentUserAllowedTo(
    'view',
    TProductNames.ASSET_PORTAL,
    'Asset',
    asset.id,
  );

  const isUserAllowedToViewAssetStatus =
    !isCurrentUserExternal &&
    !isUserAllowedToManageAssetPortal &&
    isUserAllowedToViewAsset;

  const statusOption = ALL_STATUS_OPTIONS_MAP[asset.aasmState];

  const assetHasLegalEntities = (asset.legalEntities?.length ?? 0) > 0;
  const isUserAllowedToViewReportsTab =
    (asset.hasReportBuilderData ||
      currentUserAllowedToSeeReportsPageAndThisAsset(asset.id)) &&
    assetHasLegalEntities;

  const slot = (
    <>
      <div className="flex min-h-[56px] items-start justify-between gap-tw-4">
        {dashboardExpanded && (
          <IconButton
            onClick={handleToggleExpand}
            className="h-full"
            iconName="arrowLeft"
            variant="secondary"
          />
        )}
        <ListNavLayout.HeaderImg
          imgSrc={asset.smallPictureUrl}
          placeholderIcon="asset"
        />
        <ListNavLayout.DashboardHeaderInfo
          subtitle="Asset"
          title={asset.name}
          label={
            asset.stage
              ? {
                  text: asset.stage.name,
                  color: asset.stage.color,
                }
              : null
          }
        />
        <div className="ml-auto flex gap-tw-2">
          {isUserAllowedToManageAssetPortal && (
            <StatusDropdown
              object={asset}
              heavyUpdate={heavyUpdateWithInvalidate}
              status={asset.aasmState}
            >
              <IconButton
                iconName={statusOption.iconName}
                classes={{ icon: statusOption.className }}
                size="l"
                variant="secondary"
              />
            </StatusDropdown>
          )}
          {isUserAllowedToViewAssetStatus && (
            <IconButton
              tooltipProps={{
                mainText: capitalize(statusOption.value),
              }}
              iconName={statusOption.iconName}
              className="!cursor-default"
              classes={{ icon: statusOption.className }}
              size="l"
              variant="secondary"
            />
          )}
          {!dashboardExpanded && (
            <IconButton
              className="flex 3xl:hidden"
              onClick={handleKeyInfoClick}
              iconName="infoAlt"
              variant="secondary"
              size="l"
            />
          )}
          {isUserAllowedToManageAssetPortal && (
            <IconButton
              onClick={openObjectModal}
              iconName="settings"
              variant="secondary"
              size="l"
            />
          )}
          {(currentUserIsInternal() || currentUserIsSreAdmin()) &&
            externalUsers.length > 0 &&
            externalUsersListButton}
          <VerticalSeparator />
          <IconButton
            size="l"
            variant="secondary"
            iconName={dashboardExpanded ? 'collapse' : 'expand'}
            onClick={handleToggleExpand}
          />
          <FavoriteCardIcon
            onClick={() => toggleItemFavorite(asset.id)}
            selected={isItemFavorite(asset.id)}
            className="h-tw-8 w-tw-8 !rounded-lg border border-solid border-neutral-100 shadow-z-020"
          />
        </div>
      </div>
      <div className="flex">
        {[...OBJECT_DASHBOARD_PAGE_TABS_MAP].map(([tab, { to }]) => (
          <TabLink
            key={tab}
            tab={tab}
            to={generateUrl(to, {
              pathParams: {
                assetSlug: assetSlug ?? '',
              },
            })}
          />
        ))}
        {isUserAllowedToManageReturn && asset.hasReturnData && (
          <Link
            className={cn(TAB_LINK_CLASSES, 'group items-center gap-tw-2')}
            to={generateUrl(ROUTES_ROOT.return.object.fullPath, {
              pathParams: {
                objectId: asset.capitalInvestmentObjectId ?? '',
              },
            })}
            state={{ from: pathname }}
          >
            {MODULE_LABELS.Return}
            <Icon className="text-neutral-450" iconName="externalLink" />
          </Link>
        )}
        {isUserAllowedToViewReport && (
          <>
            {asset.objectDashboards.length > 0 && (
              <ObjectReportTabs object={asset} />
            )}
            {isVisibleReportTableConfigDataExist && (
              <ObjectFinancialTabs
                object={asset}
                isFetching={isFetchingVisibleConfigs}
                visibleReportTableConfigs={visibleReportTableConfigs}
              />
            )}
          </>
        )}
        {isUserAllowedToViewReportsTab && (
          <TabLink
            tab="REports"
            to={generateUrl(ROUTES_ROOT.assets.asset.reports.fullPath, {
              pathParams: {
                assetSlug: assetSlug ?? '',
              },
            })}
          />
        )}
      </div>
      {asset.aasmState === 'draft' && (
        <Banner className="-mx-tw-6 -mt-tw-4 w-[calc(100%_+_48px)]">
          {getDraftObjectsWarningMessage()}
        </Banner>
      )}
    </>
  );

  return (
    <ListNavLayout.DashboardHeader slot={slot}>
      <EntityContext.Provider value={{ entity: asset, type: 'Asset' }}>
        {keyInfoModalIsOpen && (
          <KeyInfoModal
            object={asset}
            onClose={() => setKeyInfoModalIsOpen(false)}
            heavyUpdate={heavyUpdate}
            simpleUpdate={simpleUpdate}
          />
        )}
        <div className="grid h-full p-tw-4 4xl:p-tw-6">
          <Router basepath="/" component={RouterComponentWithoutWrapper}>
            <Posts
              showKeyInfo={dashboardExpanded}
              path={ROUTES_ROOT.assets.asset.fullPath}
              heavyUpdate={heavyUpdate}
              simpleUpdate={simpleUpdate}
            />
            <AssetDocuments
              path={ROUTES_ROOT.assets.asset.documents.fullPath}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              hideTreeNavigation={!dashboardExpanded}
            />
            <Media
              path={allSubPathMatches(ROUTES_ROOT.assets.asset.media.fullPath)}
            />
            {isUserAllowedToViewReportsTab && (
              <AssetReportsPage
                assetId={asset.id}
                path={ROUTES_ROOT.assets.asset.reports.fullPath}
              />
            )}
          </Router>
        </div>
      </EntityContext.Provider>
    </ListNavLayout.DashboardHeader>
  );
}

export default AssetDashboardContent;

import React, { FC } from 'react';
import { Icon } from 'stories';

interface IAutoUploadingBadgeProps {
  enabledUploading?: boolean;
}

const AutoUploadingBadge: FC<IAutoUploadingBadgeProps> = ({
  enabledUploading,
}) => (
  <div className="flex items-center gap-tw-1">
    <Icon color="var(--teal-light-3)" iconName="actionsAlt" />
    <div className="label-regular flex gap-4">
      <span>Auto-uploading</span>
      {!enabledUploading && <span>disabled</span>}
    </div>
  </div>
);

export default AutoUploadingBadge;

import * as React from 'react';
import { FilterCard, Icon, StatisticLine } from 'stories';
import {
  IChangeOrderEventBages,
  IConstructionReportParsed,
} from 'types/reconcile';
import {
  accountingNumberFormat,
  percentNumberFormat,
} from '@/shared/lib/formatting/number';
import {
  CARD_ACTIVE_CATEGORIES,
  CARD_TYPE_LABELS,
  CATEGORY_COLORS,
  getContingencyRemaining,
  getContingencyStatistics,
  getRemainingColors,
} from 'bundles/Construction/utils';
import ConstructionReportCardStatistics from 'bundles/Construction/components/cards/ConstructionReportCardStatistics';
import { TContingencyCategory } from 'bundles/Construction/types';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import { round } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import styles from './ChangeManagementInfoCard.module.scss';

export type TInfoCardType = keyof Pick<
  IConstructionReportParsed,
  'currentContingency' | 'projectedContingency' | 'internalContingency'
>;

interface Props {
  report: IChangeOrderEventBages;
  cardType: TInfoCardType;
  className?: string;
}

function ChangeManagementInfoCard({ report, cardType, className }: Props) {
  const { onMouseLeave, onMouseOver, hovered } = useHovered();
  const activeCategories = CARD_ACTIVE_CATEGORIES[cardType];
  const remaining = getContingencyRemaining(report, cardType);
  const remainingColors = getRemainingColors(remaining);
  const statistics = getContingencyStatistics(report, cardType);

  return (
    <FilterCard
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      className={cn(styles.card, className)}
      classes={{
        hoverPart: styles.hoverPart,
      }}
      header={
        <div className="flex flex-col">
          <span className="inline-semibold">{CARD_TYPE_LABELS[cardType]}</span>
          <span className="lead-semibold">
            {accountingNumberFormat(round(report.amount, 2))}
          </span>
        </div>
      }
      content={
        <>
          <div className="flex justify-between">
            <span className="label-regular">
              {percentNumberFormat(remaining)} of IC is remaining
            </span>
            {remaining < 50 && (
              <Icon
                style={{
                  color: remainingColors.hoverColor,
                }}
                iconName="fire"
              />
            )}
          </div>
          <StatisticLine
            className="mt-tw-2"
            hovered={hovered}
            statistics={statistics}
          />
        </>
      }
      hoverContent={
        <ConstructionReportCardStatistics
          activeCategories={activeCategories}
          colors={Object.fromEntries([
            ...Object.entries(CATEGORY_COLORS).map(([k, colors]) => [
              k,
              colors.hoverColor,
            ]),
            [TContingencyCategory.Remaining, remainingColors.hoverColor],
          ])}
          report={report}
        />
      }
    />
  );
}

export default ChangeManagementInfoCard;

import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import { BOTTOM_LEVEL_POPOVER_Z_INDEX } from '@/bundles/Shared/constants';
import {
  SRE_UNIT_STATUS_MAP,
  SRE_UNIT_STATUSES,
} from '@/bundles/Shared/entities/sreUnitStatuses/ui/config';
import { Button, IconButton, Popover } from '@/stories';
import { useState } from 'react';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { TRentrollUnitStatus } from '@/entities/report/unitTypes/model';

export function SreUnitStatusFormatter({
  value,
  onSubmit,
  initialValue = null,
  showEditButton = false,
  onReset,
  disabled,
}: {
  value: string | null | undefined;
  onSubmit: (sreUnitType: TRentrollUnitStatus) => void;
  initialValue?: TRentrollUnitStatus | null;
  showEditButton?: boolean;
  onReset?: VoidFunction;
  disabled?: boolean;
}) {
  const [checkedUnitStatus, setCheckedUnitStatus] =
    useState<TRentrollUnitStatus | null>(initialValue);

  return (
    <div className="flex items-center gap-tw-1">
      {value == null || showEditButton ? (
        <Popover
          onHidden={() => {
            if (checkedUnitStatus == null) return;

            if (initialValue === checkedUnitStatus) return;

            onSubmit(checkedUnitStatus);
          }}
          zIndex={BOTTOM_LEVEL_POPOVER_Z_INDEX}
          appendToBody
          placement="bottom-end"
          trigger="click"
          maxWidth={280}
          hiddenArrow
          template={
            <div className="flex flex-col gap-tw-2">
              <div className="flex w-[248px] items-center justify-between">
                <p className="secondary-semibold text-neutral-500">
                  sRE{' '}
                  {SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.pluralForm}
                </p>

                {initialValue && (
                  <Button variant="secondary" size="xs" onClick={onReset}>
                    Reset
                  </Button>
                )}
              </div>
              <RadioGroup
                value={checkedUnitStatus}
                onChange={(o) =>
                  setCheckedUnitStatus(o.value as TRentrollUnitStatus)
                }
                options={SRE_UNIT_STATUSES}
              />
            </div>
          }
        >
          {showEditButton ? (
            <IconButton size="s" iconName="edit" />
          ) : (
            <Button
              altIconName="arrowBottom"
              altIconPosition="right"
              iconName="link"
              variant="secondary"
              size="xs"
              disabled={disabled}
              tooltipProps={{
                disabled: !disabled,
                mainText: 'Legal Entity classification is required',
              }}
            >
              Set{' '}
              {SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}
            </Button>
          )}
        </Popover>
      ) : (
        SRE_UNIT_STATUS_MAP[value].label
      )}
    </div>
  );
}

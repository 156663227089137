import React from 'react';
import { GroupCellRendererParams } from 'ag-grid-community';
import { Checkbox, Icon } from 'stories';
import { GENERAL_LEDGERS_COLUMN_IDS } from 'bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import { CategoryPath } from 'bundles/Settings/components/REport/GeneralLedgers/Table/CategoryPath/CategoryPath';
import { IGeneralLedgerOnIndex } from 'types/GeneralLedger';

interface Props extends GroupCellRendererParams {
  handleGroupCheck: (groupChildren: IGeneralLedgerOnIndex[]) => void;
  resolveGroupCheck: (groupChildren: IGeneralLedgerOnIndex[]) => boolean;
}

function GroupRowInnerRenderer(props: Props) {
  const { value, node, api, handleGroupCheck, resolveGroupCheck } = props;
  const isCategoryColumn =
    node.rowGroupColumn?.getColId() === GENERAL_LEDGERS_COLUMN_IDS.categories;

  const glGroup = node.allLeafChildren.map(
    ({ data }) => data as IGeneralLedgerOnIndex,
  );

  return (
    <div className="flex items-center h-full">
      <Checkbox
        labelClassName="align-self-center"
        onChange={() => handleGroupCheck(glGroup)}
        checked={resolveGroupCheck(glGroup)}
      />
      <Icon
        onClick={() => {
          node.setExpanded(!node.expanded);
          api.redrawRows({
            rowNodes: [node],
          });
        }}
        className="mx-s"
        iconName={node.expanded ? 'arrowTop' : 'arrowBottom'}
      />
      {isCategoryColumn ? (
        <CategoryPath categoryId={parseInt(value)} />
      ) : (
        <span className="dark-60 inline-semibold">{value}</span>
      )}
    </div>
  );
}

export default GroupRowInnerRenderer;

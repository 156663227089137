import { IUserRole } from '@/types/User';
import { uniqBy } from 'lodash-es';

export const filterRoleByUndeselectable = <
  R extends Pick<IUserRole, 'id' | 'undeselectable'>,
>(
  r: R,
): boolean => {
  return Boolean(r.undeselectable);
};

export const concatUndeselectableRoleOptions = <
  R extends Pick<IUserRole, 'id' | 'undeselectable'>,
>(
  userRoleOptions: R[],
  directRoles: R[],
): R[] =>
  uniqBy(
    [...directRoles, ...userRoleOptions.filter(filterRoleByUndeselectable)],
    'id',
  );

import { ReportManualVariable } from '@/entities/report/manualVariable';
import { useImportMVLegalEntities } from '@/features/report/manualVariable';
import { ImportButton } from '@/shared/ui/ImportButton';

export const ImportMVLegalEntitiesButton = ({
  manualVariableId,
}: {
  manualVariableId: ReportManualVariable['id'];
}) => {
  const [sendFile, options] = useImportMVLegalEntities();

  return (
    <ImportButton
      loading={options.isLoading}
      disabled={options.isLoading}
      onClick={() => {
        sendFile({
          mvId: manualVariableId,
        });
      }}
    />
  );
};

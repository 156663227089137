import http from '../../lib/http';
import { login } from '../../lib/currentUser';
import { typedLocalStorage } from '@/shared/lib/localStorage';

const onUserImpersonation = async (id: string) => {
  const res = await http.post('/user_impersonation', { id });
  if (res.status === 400) {
    const error = await res.json();
    window.toastr.error(error);
    return;
  }

  const json = await res.json();
  login(json.token);
  if (!window.symmetreConfig.impersonating) {
    typedLocalStorage.setItem('trueUserRedirectUrl', window.location.pathname);
  }
};

export default onUserImpersonation;

import React, { useState } from 'react';
import FeedMessageFormModal from './FeedMessageFormModal';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { cn } from '@/shared/lib/css/cn';

const NEW_BUTTON_CLASS_NAMES = `
radius008 shadow-z-020 px-[12px] py-[4px] border-1 border-solid border-neutral-100 hover:border-neutral-200 transition-colors inline-regular hover:bg-neutral-100 text-neutral-500 text-left flex-grow w-full`;

const NewFeedMessage = ({
  updateFeedMessagesList,
  object,
  setCurrentFeedMessage,
}: {
  updateFeedMessagesList: () => void;
  object: IAsset | IFund;
  setCurrentFeedMessage: (feedMessage: unknown) => void;
}) => {
  const [opened, setOpened] = useState(false);

  const content = (
    <>
      <div className="flex items-center gap-tw-4">
        <div className="flex-grow flex-col flex">
          <button
            type="button"
            onClick={() => setOpened(true)}
            className={cn(
              NEW_BUTTON_CLASS_NAMES,
              'w-[calc(100%-16px) 4xl:m-tw-2 4xl:w-[calc(100%-18px)] 4xl:px-tw-4 4xl:py-tw-3.5 4xl:outline 4xl:outline-8 4xl:outline-neutral-150',
            )}
          >
            What&apos;s new?
          </button>
        </div>
      </div>
      {opened && (
        <FeedMessageFormModal
          onClose={() => setOpened(false)}
          updateFeedMessagesList={updateFeedMessagesList}
          object={object}
          setCurrentFeedMessage={setCurrentFeedMessage}
        />
      )}
    </>
  );

  return content;
};

export default NewFeedMessage;

import { useEffect } from 'react';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { resetFormulasState } from 'bundles/Shared/entities/formula';

export const useResetFormulasSettingsOnUnmount = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetFormulasState());
    };
  }, []);
};

import { IS_RUM_SUBDOMAIN, IS_WHISKEY_SUBDOMAIN } from "@/lib/http";
import { currentUserAllowedTo, currentUserIsSreAdmin } from "@/lib/permissions";

export const currentUserAllowedToSeeNavigateToReportViewPage = () => {
  return (currentUserIsSreAdmin() && (IS_WHISKEY_SUBDOMAIN || IS_RUM_SUBDOMAIN));
}

export const currentUserAllowedToSeeReportViewPage = () => {
  return currentUserIsSreAdmin() || currentUserAllowedTo('manage', 'report');
}

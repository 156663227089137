import { Color } from '@/shared/widgets/colorPicker/model';
import { useCallback, useMemo, useState } from 'react';
type ColorSlot = {
  id: number;
  colorValue: Color['value'] | null;
  entityId?: string;
  isDefault?: boolean;
};
type ColorSlotsState = {
  selectedColorSlotIdx: number;
  slots: ColorSlot[];
};

export const INITIAL_COLOR = '#FFFFFF';

export const useColorSlots = ({
  initialColorSlots,
}: {
  initialColorSlots: ColorSlot[];
}) => {
  const [colorSlot, setColorSlot] = useState<ColorSlotsState>({
    selectedColorSlotIdx: initialColorSlots.length,
    slots: [
      ...initialColorSlots,
      {
        id: initialColorSlots.length,
        colorValue: null,
      },
    ],
  });

  const onSelectedSlotUpdate = (newSlot: Pick<ColorSlot, 'colorValue'>) => {
    setColorSlot((prev) => ({
      ...prev,
      slots: prev.slots.map((slot) => {
        return prev.selectedColorSlotIdx === slot.id && !slot.isDefault
          ? { ...slot, ...newSlot }
          : slot;
      }),
    }));
  };
  const onSlotAdd = () => {
    setColorSlot((prev) => ({
      ...prev,
      slots: [
        ...prev.slots,
        {
          id: prev.slots.length,
          colorValue: null,
        },
      ],
    }));
  };
  const onSlotRemove = (slotToRemove: ColorSlot) => {
    setColorSlot((prev) => {
      return {
        ...prev,
        slots:
          slotToRemove.id === prev.selectedColorSlotIdx
            ? prev.slots.map((slot) =>
                slotToRemove.id === slot.id
                  ? { ...slot, colorValue: null }
                  : slot,
              )
            : prev.slots.filter((slot) => slotToRemove.id !== slot.id),
      };
    });
  };

  const onSelectedSlotChange = (newIdx: number) => {
    setColorSlot((prev) => {
      const isNewSlot = Boolean(prev.slots.find(({ id }) => id === newIdx));
      return {
        ...prev,
        selectedColorSlotIdx: newIdx,
        slots: !isNewSlot
          ? [
              ...prev.slots,
              {
                id: newIdx,
                colorValue: null,
              },
            ]
          : prev.slots,
      };
    });
  };

  const colorSlotsFromEntries = useMemo(() => {
    return Object.fromEntries(
      colorSlot.slots.map((slot) => [slot.id, slot] as const),
    );
  }, [colorSlot.slots]);

  const getColorSlot = useCallback(
    (id: ColorSlot['id']) => colorSlotsFromEntries[id],
    [colorSlot.slots],
  );

  const selectedSlot = useMemo(
    () =>
      colorSlot.slots.find(({ id }) => id === colorSlot.selectedColorSlotIdx),
    [colorSlot],
  );
  const resolveIsSelectedSlotResolverByIndex = useCallback(
    (idx: number) => {
      return idx === colorSlot.selectedColorSlotIdx && selectedSlot != null;
    },
    [colorSlot.selectedColorSlotIdx, selectedSlot],
  );
  return [
    colorSlot,
    selectedSlot,
    {
      getColorSlot,
      resolveIsSelectedSlotResolverByIndex,
      onSelectedSlotChange,
      onSlotAdd,
      onSlotRemove,
      onSlotUpdate: onSelectedSlotUpdate,
    },
  ] as const;
};

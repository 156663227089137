import http from 'lib/http';
import { IReconcileDevelopmentCategoryTreeNode } from 'types/ReconcileDevelopmentCategory';

import { FinancialCategory } from 'bundles/Shared/entities/finanicalCategory/model';

interface IFetchReconcileDevelopmentCategoriesTreeResponse {
  tree: IReconcileDevelopmentCategoryTreeNode[];
}

export async function fetchReconcileDevelopmentCategoriesTree() {
  const res = await http.get('/reconcile_development_categories/tree');
  const responseData = await res.json();

  return responseData as IFetchReconcileDevelopmentCategoriesTreeResponse;
}

interface ICreateReconcileDevelopmentCategoryParams {
  code: string;
  parentId: number | null;
}

export async function createReconcileDevelopmentCategory(
  params: ICreateReconcileDevelopmentCategoryParams,
) {
  const res = await http.post('/reconcile_development_categories', params);
  const data = await res.json();

  return data as Omit<FinancialCategory, 'children'>;
}

type IUpdateReconcileDevelopmentCategoryParams =
  ICreateReconcileDevelopmentCategoryParams;

export async function updateReconcileDevelopmentCategory(
  id: number,
  params: IUpdateReconcileDevelopmentCategoryParams,
) {
  const res = await http.put(`/reconcile_development_categories/${id}`, params);
  const data = await res.json();

  return data as Omit<FinancialCategory, 'children'>;
}

export async function deleteReconcileDevelopmentCategory(id: number) {
  const res = await http.del(`/reconcile_development_categories/${id}`);
  const data = await res.json();

  return data as Omit<FinancialCategory, 'children'>;
}

export async function updateReconcileDevelopmentCategoryTree(
  params: Exclude<FinancialCategory, 'parentId'>[],
) {
  const res = await http.put('/reconcile_development_categories/tree', {
    tree: params,
  });
  const data = await res.json();

  if (data.errors) {
    toastr.error(`${data.errors}\n`);
    return undefined;
  }
  toastr.success('Development Category Tree successfully updated');

  return data.tree as FinancialCategory[];
}

import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { plainFetchFunds } from 'bundles/Funds/actions/funds';
import FundFormModal from 'bundles/Funds/components/FundFormModal';
import {
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { useEffect, useState } from 'react';
import { AnimationLoader, Button } from 'stories';
import { IFund } from 'types/Fund';
import NoDataOverlay from '../../Shared/components/NoDataOverlay';
import TablePagination from '../../Shared/components/Table/pagination/TablePagination';
import { createFund } from '../actions';
import FundCardHorizontal from './FundCardHorizontal';

interface Props {
  editable?: boolean;
  isCreate?: boolean;
  searchQuery?: string;
  withoutPagination?: boolean;
  containerClassName?: string;
}

const FundCardList = ({
  isCreate = false,
  editable,
  searchQuery = '',
  withoutPagination = false,
  containerClassName,
}: Props) => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const [funds, setFunds] = useState<IFund[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);

  const fetchFunds = async () => {
    setIsLoading(true);
    const result = await plainFetchFunds({
      search_by_query: searchQuery,
      page: 1,
    }) as {
      funds: IFund[];
      meta: {
        totalSize: number;
      }
    };
    setFunds(result.funds);
    setTotalSize(result.meta.totalSize);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchFunds();
  }, [searchQuery]);

  if (isLoading) return <AnimationLoader />;

  const handleCreateFund = async () => {
    const res = await openModal(FundFormModal, {});
    if (res != null) {
      await dispatch(createFund(res));
      fetchFunds();
    }
  };

  return (
    <>
      <div
        className={cn('flex', containerClassName, {
          'justify-end': totalSize === 0,
          'justify-between': totalSize > 0,
        })}
      >
        {!withoutPagination && (
          <TablePagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={isLoading}
            totalSize={totalSize}
            sizePerPage={totalSize}
          />
        )}
        {isCreate &&
          currentUserAllowedTo('manage', FUND_PORTAL_PRODUCT_NAME) && (
            <Button onClick={handleCreateFund} variant="primary">
              Create New Fund
            </Button>
          )}
      </div>
      {totalSize === 0 && (
        <NoDataOverlay
          title={searchQuery ? 'Nothing found' : 'No Funds yet'}
          subTitle={searchQuery ? 'Reset filters to see all funds' : ''}
        />
      )}
      <div>
        {funds.map((fund) => (
          <FundCardHorizontal
            key={fund.id}
            fund={fund}
            editable={editable}
            funds={funds} // TODO: move to redux
            setFunds={setFunds} // TODO: move to redux
          />
        ))}
      </div>
    </>
  );
};

export default FundCardList;

import React, { useEffect, useState } from 'react';
import {
  TFilterModel,
  useMarkupsColumns,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/markups/useColumns';
import useEvent from '@/shared/lib/hooks/useEvent';
import ChangeOrderConfigSelect from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/changeOrderConfigs/ChangeOrderConfigSelect';
import {
  createMarkupInReconcileSettings,
  deleteMarkupInReconcileSettings,
  fetchMarkupsForReconcileSettings,
  IFetchMarkupsForReconcileBudgetSettingsRequest,
  IReconcileBudgetsSettingsMarkup,
  MARKUP_ENTITY_NAME,
  updateMarkupInReconcileSettings,
} from 'bundles/REconcile/actions/markupsConfig';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  fetchChangeOrderConfigs,
  fetchJccForReconcileSettings,
} from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import { useModal } from '@/shared/lib/hooks/useModal';
import MarkupCrudModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/markups/MarkupCrudModal';
import {
  CrudTablePanel,
  CrudTable,
  CrudContextProvider,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

interface Props {
  legalEntityId: string;
}

function MarkupsSettings({ legalEntityId }: Props) {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const selectedChangeOrderConfig = useAppSelector(
    (state) => state.developmentLegalEntity.selectedChangeOrderConfig,
  );
  const [markups, setMarkups] = useState<IReconcileBudgetsSettingsMarkup[]>([]);
  const [pageParams, setPageParams] =
    useState<IFetchMarkupsForReconcileBudgetSettingsRequest>({});
  const [totalSize, setTotalSize] = useState<number | null>(null);

  const loadMarkups = async () => {
    const res = await fetchMarkupsForReconcileSettings(
      {
        legalEntityId: legalEntityId.toString(),
      },
      pageParams,
    );
    setMarkups(res);
    if (totalSize === null) {
      setTotalSize(res.length);
    }
  };

  const handleRowRemove = useEvent(
    async (row: IReconcileBudgetsSettingsMarkup) => {
      await deleteMarkupInReconcileSettings({
        legalEntityId: legalEntityId.toString(),
        entityId: row.id,
      });
      loadMarkups();
    },
  );
  const handleRowEdit = useEvent(
    async (row: IReconcileBudgetsSettingsMarkup) => {
      const res = await openModal(MarkupCrudModal, {
        entity: {
          ...row,
          included_in_fee: row.includedInFee,
        },
        type: 'edit',
      });
      if (res) {
        await updateMarkupInReconcileSettings(
          {
            legalEntityId: legalEntityId.toString(),
            entityId: row.id,
          },
          res,
        );
        loadMarkups();
      }
    },
  );

  const handleCreate = useEvent(async () => {
    const res = await openModal(MarkupCrudModal, {
      entity: {
        name: '',
        code: '',
        included_in_fee: false,
        percent: 0,
      },
      type: 'create',
    });
    if (res) {
      await createMarkupInReconcileSettings(
        {
          legalEntityId: legalEntityId.toString(),
        },
        res,
      );
      loadMarkups();
    }
  });

  const columns = useMarkupsColumns();

  useEffect(() => {
    if (selectedChangeOrderConfig !== null) {
      loadMarkups();
    }
  }, [pageParams, selectedChangeOrderConfig]);

  useEffect(() => {
    dispatch(fetchChangeOrderConfigs(legalEntityId));
    dispatch(fetchJccForReconcileSettings(legalEntityId));
  }, []);

  const handleFilterModelChange = (filterModel: TFilterModel) =>
    setPageParams((prevParams) => ({
      ...prevParams,
      filter_by_included: filterModel.includedInFee
        ? filterModel.includedInFee === 'true'
        : undefined,
    }));

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entities={markups}
      entityName={MARKUP_ENTITY_NAME}
      handlers={{
        create: handleCreate,
        delete: handleRowRemove,
        update: handleRowEdit,
      }}
    >
      <div>
        <ChangeOrderConfigSelect
          legalEntityId={legalEntityId}
          className="mb-m"
        />
        <CrudTablePanel />
        <CrudTable
          columns={columns}
          onFilterModelChange={handleFilterModelChange}
        />
      </div>
    </CrudContextProvider>
  );
}

export default MarkupsSettings;

import React from 'react';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectInvoiceESignData } from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { cn } from '@/shared/lib/css/cn';
import { formatDate } from '@/shared/lib/formatting/dates';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { useGenericData } from 'bundles/Shared/GenericContext';

const StampPreviewArea = () => {
  const eSignData = useAppSelector(selectInvoiceESignData);
  const { genericData } = useGenericData();
  const { currentUser } = genericData;
  const { fullName } = currentUser;

  const baseSettings = eSignData.stamp.base;
  const additionalSettings = eSignData.stamp.additional;

  return (
    <div className="relative flex h-full items-center justify-center bg-white">
      <div
        style={{
          borderColor: additionalSettings.color.value,
        }}
        className={cn('flex flex-col gap-tw-2 px-tw-6 py-tw-3 text-center', {
          'border-2 border-solid': additionalSettings.border.value,
        })}
      >
        {additionalSettings.header.value && (
          <div
            style={{
              color: additionalSettings.color.value,
            }}
            className={cn('font-semibold')}
          >
            APPROVED
          </div>
        )}
        <div className="flex flex-col gap-tw-1">
          {baseSettings.name.value && (
            <div className="dark-60 body-regular">{fullName}</div>
          )}
          {baseSettings.date.value && (
            <div className="dark-60 body-regular">
              {formatDate(new Date(), 'MM-DD-YYYY')}
            </div>
          )}
          {baseSettings.signature.value && (
            <div
              className={cn(
                styles.signature,
                'text-[2rem] leading-[1] text-dark-60',
              )}
            >
              {fullName}
            </div>
          )}
        </div>
      </div>
      <div className="secondary-regular absolute bottom-tw-4 w-full text-center">
        e-Signature example
      </div>
    </div>
  );
};

export default StampPreviewArea;

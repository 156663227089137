import { ColDef } from 'ag-grid-community';
import { DependencyList, useMemo } from 'react';
import GroupWithConnector, {
  DEFAULT_CONNECTOR_GROUP_CELL_STYLE,
} from 'bundles/Shared/components/AgGrid/GroupWithConnector/GroupWithConnector';

export default function useGroupWithConnectorColDef(
  colDef: Omit<ColDef, 'cellRenderer' | 'cellStyle'>,
  deps: DependencyList | undefined,
) {
  return useMemo<ColDef>(
    () => ({
      cellStyle: DEFAULT_CONNECTOR_GROUP_CELL_STYLE,
      cellRenderer: GroupWithConnector,
      ...colDef,
    }),
    deps,
  );
}

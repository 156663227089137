import React, { PropsWithChildren, useRef } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { animated, easings, useSpring } from 'react-spring';
import useElementSize from '@/shared/lib/hooks/useElementSize';

interface Props extends PropsWithChildren {
  expanded: boolean;
  classes?: {
    wrapper?: string;
    container?: string;
  };
}

export function Collapse({ expanded, classes, children }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { height: measuredHeight } = useElementSize(ref);

  const style = useSpring({
    from: {
      height: 0,
      opacity: 0,
    },
    to: {
      height: expanded ? measuredHeight : 0,
      opacity: expanded ? 1 : 0,
    },
    config: {
      duration: 300,
      easing: easings.easeInOutCubic,
    },
  });

  return (
    <animated.div
      className={cn('overflow-hidden', classes?.wrapper)}
      style={style}
    >
      <animated.div ref={ref} className={classes?.container}>
        {children}
      </animated.div>
    </animated.div>
  );
}

export default Collapse;

import React, { useState } from 'react';
import {
  CrudEntityModalProps,
  CrudModal,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import {
  COMPANIES_ENTITY_NAME,
  IReconcileSettingsCompanyUpdate,
} from 'bundles/REconcile/actions/companies';
import CompanyFields from 'bundles/REconcile/components/development/home/settingsModal/companies/CompanyFields';

function CompanyCrudModal({
  onSubmit,
  entity,
  ...props
}: CrudEntityModalProps<IReconcileSettingsCompanyUpdate>) {
  const [formData, setFormData] = useState(entity);
  return (
    <CrudModal
      entityName={COMPANIES_ENTITY_NAME}
      onSubmit={() => onSubmit?.(formData)}
      {...props}
    >
      <CompanyFields formData={formData} setFormData={setFormData} />
    </CrudModal>
  );
}

export default CompanyCrudModal;

/* eslint-disable max-len */
import {
  reconcileWorkflowApproversApi,
  PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiResponse,
  PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse,
  DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse,
  PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiResponse,
  PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse,
  DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse,
  PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapApiResponse,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/approvers/api/reconcileWorkflowApproversApi';

export const enhancedApi = reconcileWorkflowApproversApi.enhanceEndpoints({
  addTagTypes: ['ChangeOrderApprover', 'ChargeOrderAdditionalRecipient'],
  endpoints: {
    getApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipients:
      {
        providesTags: ['ChargeOrderAdditionalRecipient'],
      },
    postApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipients:
      {
        transformResponse: (response: {
          data: PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiResponse;
        }) => {
          toastr.success('Charge Order Additional Recipient Created');
          return response.data;
        },
        invalidatesTags: ['ChargeOrderAdditionalRecipient'],
      },
    putApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndId:
      {
        transformResponse: (response: {
          data: PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse;
        }) => {
          toastr.success('Charge Order Additional Recipient Updated');
          return response.data;
        },
        invalidatesTags: ['ChargeOrderAdditionalRecipient'],
      },
    deleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndId:
      {
        transformResponse: (response: {
          data: DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse;
        }) => {
          toastr.success('Charge Order Additional Recipient Deleted');
          return response.data;
        },
        invalidatesTags: ['ChargeOrderAdditionalRecipient'],
      },
    getApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApprovers: {
      providesTags: ['ChangeOrderApprover'],
    },
    postApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApprovers: {
      transformResponse: (response: {
        data: PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiResponse;
      }) => {
        toastr.success('Change Order Approver Created');
        return response.data;
      },
      invalidatesTags: ['ChangeOrderApprover'],
    },
    putApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndId:
      {
        transformResponse: (response: {
          data: PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse;
        }) => {
          toastr.success('Change Order Approver Updated');
          return response.data;
        },
        invalidatesTags: ['ChangeOrderApprover'],
      },
    deleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndId:
      {
        transformResponse: (response: {
          data: DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse;
        }) => {
          toastr.success('Change Order Approver Deleted');
          return response.data;
        },
        invalidatesTags: ['ChangeOrderApprover'],
      },
    putApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwap:
      {
        transformResponse: (response: {
          data: PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapApiResponse;
        }) => {
          toastr.success('Change Order Approver Updated');
          return response.data;
        },
        invalidatesTags: ['ChangeOrderApprover'],
      },
  },
});

export const {
  useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsQuery,
  usePostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsMutation,
  usePutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdMutation,
  useDeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdMutation,
  useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversQuery,
  usePostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversMutation,
  usePutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdMutation,
  useDeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdMutation,
  usePutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapMutation,
} = enhancedApi;

import React, { useEffect, useState } from 'react';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { useNavigate } from '@reach/router';
import { IUserRole } from 'types/User';
import { plainFetchReplyRecipients } from 'bundles/Settings/actions/customTemplates';
import InvitationTemplateForm from '../forms/InvitationTemplateForm';
import TemplateFormHeader from './TemplateFormHeader';
import {
  createInvitationTemplate,
  getInvitationTemplate,
  invitationTemplatePreviews,
  invitationTemplateRoles,
  invitationTemplateVariables,
  removeInvitationTemplate,
  updateInvitationTemplate,
} from '../../../actions/invitationTemplates';
import TemplatePreview from '../TemplatePreview';
import TemplateEditorContainer from './TemplateEditorContainer';
import * as Type from './types';
import { initInvitationTemplateVars } from '../utils/consts';
import { canSaveTemplate } from '../utils/canSaveTemplate';
import { AnimationLoader } from 'stories/index';

interface Props {
  invitationTemplateId?: number;
}

const InvitationTemplateEdit = ({ invitationTemplateId }: Props) => {
  const navigate = useNavigate();
  const [template, setTemplate] = useState<Type.InvitationTemplateState | null>(
    null,
  );
  const [isDefault, setIsDefault] = useState(true);
  const [roles, setRoles] = useState<IUserRole[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IUserRole[]>([]);
  const [replyRecipients, setReplyRecipients] = useState<Type.ReplyRecipient[]>(
    [],
  );
  const [variables, setVariables] = useState(initInvitationTemplateVars);
  const [usedVariables, setUsedVariables] = useState(
    initInvitationTemplateVars,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [templatePreview, setTemplatePreview] = useState<RawHTML | undefined>();
  const debouncedTemplate = useDebounce(template, 500);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const [
        invitationTemplateJson,
        rolesJson,
        variablesJson,
        replyRecipientsJson,
      ] = await Promise.all([
        invitationTemplateId !== undefined
          ? getInvitationTemplate(invitationTemplateId)
          : null,
        invitationTemplateRoles(invitationTemplateId),
        invitationTemplateVariables(),
        plainFetchReplyRecipients(),
      ]);

      setReplyRecipients(replyRecipientsJson);

      if (invitationTemplateJson !== null) {
        setTemplate({
          subject: invitationTemplateJson.subject,
          greeting: invitationTemplateJson.rawGreeting,
          body: invitationTemplateJson.rawBody,
          acceptInvitation: invitationTemplateJson.rawAcceptInvitation,
          additionalInfo: invitationTemplateJson.rawAdditionalInfo,
          replyRecipients: invitationTemplateJson.replyRecipients,
        });
        setIsDefault(invitationTemplateJson.default);

        setUsedVariables({
          acceptInvitation: invitationTemplateJson.acceptInvitationVariables,
          body: invitationTemplateJson.bodyVariables,
          greeting: invitationTemplateJson.greetingVariables,
          additionalInfo: invitationTemplateJson.additionalInfoVariables,
        });
        setSelectedRoles(invitationTemplateJson.selectedRoles);
      }
      setRoles(rolesJson ?? []);
      setVariables({
        greeting: variablesJson.greeting,
        body: variablesJson.body,
        acceptInvitation: variablesJson.accept_invitation,
        additionalInfo: variablesJson.additional_info,
      });
      setIsLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (template === null) return;

    const fetch = async () => {
      setIsPreviewLoading(true);
      const previewJSON = await invitationTemplatePreviews({
        template,
        invitationTemplateId,
        usedVariables,
        selectedRolesIds: selectedRoles.map((r) => r.id),
      });
      setTemplatePreview(previewJSON);
      setIsPreviewLoading(false);
    };
    fetch();
  }, [debouncedTemplate]);

  const handleSubmit = async () => {
    if (template === null) return;
    const params: Parameters<typeof updateInvitationTemplate>[0] = {
      template,
      invitationTemplateId,
      usedVariables,
      selectedRolesIds: selectedRoles.map((r) => r.id),
    };
    const invitationTemplate = invitationTemplateId
      ? await updateInvitationTemplate(params)
      : await createInvitationTemplate(params);

    if (invitationTemplate.errors === undefined) {
      navigate(-1);
    }
  };

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (invitationTemplateId) {
      return debouncedTemplate != null && templatePreview != null;
    }

    return true;
  };

  const saveButtonEnabled = canSaveTemplate({
    isLoading,
    template,
    initialFieldsToCheck: ['acceptInvitation', 'greeting', 'subject', 'body'],
  });

  return (
    <TemplateEditorContainer
      header={
        <TemplateFormHeader
          saveButtonEnabled={saveButtonEnabled}
          isDefault={isDefault}
          removeTemplate={() =>
            removeInvitationTemplate({ id: invitationTemplateId })
          }
          afterRemoveRedirectPath="/settings/emails/templates/invitations"
          onSave={() => handleSubmit()}
          title="Invitations"
        />
      }
      form={
        <InvitationTemplateForm
          isLoading={isLoading}
          isDefault={isDefault}
          invitationTemplateId={invitationTemplateId}
          roles={roles}
          selectedRoles={selectedRoles}
          replyRecipients={replyRecipients}
          variables={variables}
          usedVariables={usedVariables}
          template={template ?? {}}
          onSelectedRolesChange={(newRoles) => setSelectedRoles(newRoles)}
          onTemplateChange={(newTemplate) => setTemplate(newTemplate)}
          onVariablesChange={(newVariables) => setUsedVariables(newVariables)}
        />
      }
      preview={
        isPreviewReady() ? (
          <TemplatePreview
            subject={debouncedTemplate?.subject ?? 'Feed Message'}
            templatePreview={templatePreview}
          />
        ) : (
          <AnimationLoader />
        )
      }
    />
  );
};

export default InvitationTemplateEdit;

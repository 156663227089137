import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { BaseCard } from '@/shared/ui/BaseCard';
import { useNavigate } from '@reach/router';
import {
  DashboardCard,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard';
import { DashboardPermissionListLine } from 'bundles/Shared/features/dashboard/updatePermissions';
import { useGetApiSettingsReportDashboardsQueryById } from 'bundles/Shared/widgets/dashboard/card/lib';
import { DashboardSettingsCardActions } from 'bundles/Shared/widgets/dashboard/card/ui/DashboardSettingsCardActions';

interface Props {
  dashboardId: ReportEagleEyeDashboard['id'];
}

export function DashboardSettingsCard({ dashboardId }: Props) {
  const navigate = useNavigate();
  const dashboard = useGetApiSettingsReportDashboardsQueryById(dashboardId)!;

  return (
    <DashboardCard
      type={dashboard.type}
      status={dashboard.status}
      onClick={() => {
        navigate(
          generateUrl(
            ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath,
            {
              pathParams: {
                dashboardId,
              },
            },
          ),
        );
      }}
    >
      <div className="flex justify-between">
        <BaseCard.Title>{dashboard.name}</BaseCard.Title>
        <BaseCard.OverlayActions>
          <DashboardSettingsCardActions
            id={dashboardId}
            type={dashboard.type}
            dashboardName={dashboard.name}
          />
        </BaseCard.OverlayActions>
      </div>
      <DashboardPermissionListLine
        dashboardType={dashboard.type}
        permissions={dashboard.permissions}
        dashboardId={dashboard.id}
      />
    </DashboardCard>
  );
}

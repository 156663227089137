import React from 'react';
import { Link } from '@reach/router';
import { TProductNames, currentUserAllowedTo } from 'lib/permissions';

export const GoToSettingsPageWarning = ({ link }: { link: string }) =>
  currentUserAllowedTo('configure', TProductNames.CHANGE_MANAGEMENT) ? (
    <div>
      You can do that on the <Link to={link}>Settings page</Link>
    </div>
  ) : (
    <div>
      Please contact the person responsible for portal management within your
      organization and provide information above.
    </div>
  );

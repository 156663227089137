import { ExternalSourceType } from '@/entities/core/legalEntity';
import SourceTypeImage from 'bundles/Shared/components/SourceType/SourceTypeImage';
import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

export function SourceTypeImageWrapper({
  children,
  isGLRow,
  source,
}: PropsWithChildren & {
  isGLRow: boolean;
  source: ExternalSourceType | null;
}) {
  return (
    <>
      {isGLRow && source && (
        <div className="pointer-events-none absolute right-[8px] flex h-full w-[24px] items-center justify-center">
          <SourceTypeImage
            className={styles.sourceTypeImage}
            sourceType={source}
          />
        </div>
      )}
      {children}
    </>
  );
}

import React from 'react';
import ContainerWithAppBar from '../../Shared/components/Containers/ContainerWithAppBar';
import AppBar from '../../Shared/components/Containers/appBar/AppBar';
import FireStation from '../components/FireStation';
import AppBarDivider from '../../Shared/components/Containers/AppBarDivider';

const FireStationContainer = () => (
  <ContainerWithAppBar
    className="ml-auto mr-auto"
    style={{ maxWidth: 'calc(59.5rem + 3rem)' }}
    appBar={
      <AppBar title="Fire Station">
        <AppBarDivider />
        {/* TODO */}
        {/* Add select with search inside when there will be more types of fireevent */}
        {/* <SearchInput placeholder="Search" style={{maxWidth: '13.75rem'}} /> */}
      </AppBar>
    }
  >
    <FireStation />
  </ContainerWithAppBar>
);

export default FireStationContainer;

import React from 'react';
import { Link } from '@reach/router';
import { cn } from '@/shared/lib/css/cn';

/**
 * @deprecated
 */
const NavLink = ({
  className = 'list-group-item list-group-item-action',
  activeClassName = 'active',
  ...props
}) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => ({
      className: cn(className, { [activeClassName]: isCurrent }),
    })}
  />
);

export default NavLink;

import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Table from 'bundles/Shared/components/Table/Table';
import { Button } from 'stories/index';
import { generateXLSX } from '@/shared/lib/deprecated/constructionReport';
import { budgetTypesMissingWarning } from 'lib/dictionaries';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { setReallocation } from 'bundles/Construction/reducers/ReconcileReallocationSlice';
import { requiredBudgetTypesSpecified } from 'bundles/Construction/components/helpers';
import { GoToSettingsPageWarning } from 'bundles/Construction/components/Shared/GoToSettingsPageWarning';
import ExportDropdownButton from 'bundles/Construction/components/ExportDropdownButton';
import type { IPageParams, IReallocation } from 'bundles/Construction/types';
import styles from 'bundles/Construction/styles.module.scss';
import { useItems } from 'bundles/Construction/hooks/Reallocations/useItems';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { saveReallocationLogAsPDF } from 'bundles/Construction/utils';
import { useColumns } from 'bundles/Construction/components/Reallocation/useColumns';

interface Props extends RouteComponentProps {
  reallocations: IReallocation[];
  // navigate: NavigateFn;
  children: React.ReactNode;
}

const initialPageParams: IPageParams = {
  sortField: 'number',
  sortOrder: 'asc',
};

const ReallocationLogs = ({ reallocations, navigate, children }: Props) => {
  const [pageParams, setPageParams] = useState(initialPageParams);
  const [openedRows, setOpenedRows] = useState<number[]>([]);
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const dispatch = useAppDispatch();
  const items = useItems(reallocations, [pageParams]);

  const columns = useColumns([], {
    setOpenedRows,
  });

  if (!requiredBudgetTypesSpecified(legalEntity.config)) {
    return (
      <div className="card card-body mb-20 ml-50 mr-50 mt-50">
        {budgetTypesMissingWarning}
        <br />
        <GoToSettingsPageWarning
          link={generateUrl(
            ROUTES_ROOT.reconcile.development.legalEntity.settings.fullPath,
            {
              pathParams: {
                legalEntityCode: legalEntity.code,
                tab: 'legacy',
              },
            },
          )}
        />
      </div>
    );
  }

  const handleRowClick = ({ target }, row: IReallocation) => {
    const ANCHOR_TAG_NAME = 'A';
    const path = row.reallocationId
      ? String(row.reallocationId)
      : String(row.id);

    if (
      target.tagName !== ANCHOR_TAG_NAME &&
      target.parentNode.tagName !== ANCHOR_TAG_NAME
    ) {
      dispatch(setReallocation(row));
      navigate(path);
    }
  };

  return (
    <div className="custom-container">
      <div className="mb-tw-6 mt-tw-8 flex items-center justify-between">
        <h2 className="font-bold">REallocations</h2>
      </div>
      <div className="mb-m flex basis-full items-center">
        {items.length > 0 && (
          <span className={styles.itemsInfo}>
            {' '}
            <strong>1 - {items.length}</strong> of {items.length}
          </span>
        )}
        <ExportDropdownButton
          onClickPDF={() =>
            saveReallocationLogAsPDF({
              assetName: legalEntity.assetName,
              reallocations,
            })
          }
          onClickExcel={() =>
            generateXLSX(legalEntity.code, {
              visibility: 'reallocations',
            })
          }
        />
        <Button
          className="ml-[10px]"
          variant="primary"
          iconName="addSmall"
          onClick={() => navigate('new-co')}
        >
          Add CO reallocation
        </Button>
        <Button
          className="ml-10"
          variant="primary"
          iconName="addSmall"
          onClick={() => navigate('new-manual')}
        >
          Add manual reallocation
        </Button>
      </div>
      <div>
        <Table
          columns={columns}
          items={items || []}
          onRowClick={handleRowClick}
          settings={pageParams}
          setSettings={setPageParams}
          openedRows={openedRows}
          setOpenedRows={setOpenedRows}
        />
      </div>
      {children}
    </div>
  );
};

export default ReallocationLogs;

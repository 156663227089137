import { getItemIds } from '@/lib/helpers';
import { cn } from '@/shared/lib/css/cn';
import { useGetObjectFoldersQuery } from 'bundles/Assets/components/Media/api/objectFoldersApi';
import CircleProgress from 'bundles/Shared/components/CircleProgress';
import PermissionList from 'bundles/Shared/components/PermissionList';
import PermissionsModal from 'bundles/Shared/components/PermissionsModal';
import PermissionsModalDocsHeader from 'bundles/Shared/components/PermissionsModalDocsHeader';
import { getProductName } from 'lib/InvestmentObject';
import { capitalize, isEqual, orderBy } from 'lodash-es';
import pluralize from 'pluralize';
import { useState } from 'react';
import { Icon, IconButton, LinkButton } from 'stories';
import BulkUploaderFileColumn from './BulkUploader/BulkUploaderFileColumn';
import BulkUploaderToggler from './BulkUploader/BulkUploaderToggler';
import FileInfo from './FileInfo';
import FileMediaPreview from './FileMediaPreview';
import SetFolderModal from './SetFolderModal';
import TypeModal from './TypeModal/TypeModal';

interface Props {
  uploadingState: string;
  files: unknown[];
  setFiles: React.Dispatch<React.SetStateAction<unknown[]>>;
  sameFolder: boolean;
  sameType: boolean;
  sameRights: boolean;
  setSameFolder: React.Dispatch<React.SetStateAction<boolean>>;
  setSameType: React.Dispatch<React.SetStateAction<boolean>>;
  setSameRights: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentFile: React.Dispatch<React.SetStateAction<unknown>>;
  currentFile: unknown;
  documentType: string;
  setDocumentType: React.Dispatch<React.SetStateAction<string>>;
  permissions: unknown;
  setPermissions: React.Dispatch<React.SetStateAction<unknown>>;
  currentFolderId: string;
  setCurrentFolderId: React.Dispatch<React.SetStateAction<string>>;
  investmentObject: {
    type: string;
    entity: {
      id: number;
      type: string;
    };
  };
  folderType: string;
  onAddMoreClick: () => void;
}

const BulkUploaderTable = ({
  uploadingState,
  files,
  setFiles,
  sameFolder,
  sameType,
  sameRights,
  setSameFolder,
  setSameType,
  setSameRights,
  setCurrentFile,
  currentFile,
  documentType,
  setDocumentType,
  permissions,
  setPermissions,
  currentFolderId,
  setCurrentFolderId,
  investmentObject,
  folderType,
  onAddMoreClick,
}: Props) => {
  const { data: foldersData } = useGetObjectFoldersQuery({
    entityId: investmentObject.entity.id,
    entityType: investmentObject.type,
    folderType,
  });

  const folders = foldersData ?? [];

  const [{ sortOrder, sortField }, setSortOrder] = useState({
    sortOrder: 'asc',
    sortField: undefined,
  });
  const [typeModalOpened, setTypeModalOpened] = useState(false);
  const [setFolderModalOpened, setSetFolderModalOpened] = useState(false);
  const exludedFolderIds = getItemIds(
    folders.filter(({ title }) => !['Change Events'].includes(title)),
  );
  const [permissionsModalOpened, setPermissionsModalOpened] = useState(false);
  const onSort = (column) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder({ sortOrder: newSortOrder, sortField: column });
    const sortedFiles = orderBy(files, column, newSortOrder);
    setFiles(sortedFiles);
  };

  const removeFile = (file: unknown) =>
    setFiles(files.filter((f) => !isEqual(file, f)));

  const countFiles = currentFile ? [currentFile].length : files.length;

  const permittedFiles = currentFile ? [currentFile] : files;

  const toggleSetFolderModalOpened = (file: unknown) => {
    setCurrentFile(file);
    setSetFolderModalOpened(true);
  };

  const toggleTypeModalOpened = (file: unknown) => {
    setCurrentFile(file);
    setTypeModalOpened(true);
  };

  const togglePermissionsModalOpened = (file: unknown) => {
    setCurrentFile(file);
    setPermissionsModalOpened(true);
  };

  const setCurrentFilePermissions = (newPermissions: unknown) => {
    const filePermissions =
      newPermissions.type === 'public'
        ? {
            ...newPermissions,
            users: newPermissions.allUsers,
          }
        : newPermissions;

    setFiles(
      files.map((f) =>
        isEqual(f, currentFile) ? { ...f, permissions: filePermissions } : f,
      ),
    );
  };

  const currentFolder = folders.find(({ id }) => id === currentFolderId);

  const handleDocumentTypeSubmit = (dt: unknown) => {
    if (currentFile) {
      setFiles(
        files.map((f) =>
          isEqual(f, currentFile) ? { ...f, documentType: dt } : f,
        ),
      );
    } else {
      setDocumentType(dt);
    }
  };

  const onPermissionsModalClose = () => {
    setPermissionsModalOpened(false);
    setCurrentFile(null);
  };

  const initialTab = () => {
    if (sameRights && permissions?.type === 'public') {
      return 'public';
    }
    if (currentFile?.permissions?.type === 'public') {
      return 'public';
    }

    return 'restricted';
  };

  return (
    <>
      <table className="bulk-uploader-files-table">
        {uploadingState === 'notStarted' && (
          <thead>
            <tr>
              <td
                style={{ backgroundColor: 'transparent' }}
                className={cn('pb-tw-2', {
                  '!w-[25%]': folderType === 'documents',
                  'w-70': folderType === 'media',
                })}
              >
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <p className="light-60 secondary-regular mr-tw-2">
                      {`${countFiles} ${pluralize(
                        folderType === 'documents' ? 'DOCUMENT' : 'MEDIA',
                        countFiles,
                      )}`}
                    </p>
                    <IconButton
                      iconName={`sort${capitalize(
                        sortField === 'name' ? sortOrder : 'off',
                      )}`}
                      className={cn(
                        'rounded-6 border-light-30 bg-transparent',
                        {
                          blue: sortField === 'name',
                        },
                      )}
                      onClick={() => onSort('name')}
                    />
                  </div>
                  {folderType === 'media' && (
                    <>
                      <div className="flex-grow" />
                      <LinkButton onClick={onAddMoreClick}>ADD MORE</LinkButton>
                    </>
                  )}
                </div>
              </td>

              <BulkUploaderToggler
                label={`SAME ${
                  folderType === 'media' ? 'ALBUM' : 'FOLDER'
                } FOR ALL`}
                onToggle={(value) => setSameFolder(value)}
                sameValue={sameFolder}
                currentValue={currentFolder?.title}
                customCurrentValueFormatter={
                  <div
                    className={cn('lh-[1rem] items-center', {
                      flex: folderType === 'documents',
                      'd-none': folderType === 'media',
                    })}
                  >
                    <Icon
                      iconName={folderType === 'media' ? 'media' : 'folder'}
                      className="mr-xs"
                    />
                    {currentFolder?.title}
                  </div>
                }
                disabled={folderType === 'media'}
                openEditModal={
                  folderType === 'documents'
                    ? () => setSetFolderModalOpened(true)
                    : null
                }
                sort={sortField === 'folder.title' ? sortOrder : null}
                onSort={() => onSort('folder.title')}
                assignLabel="ASSIGN FOLDER"
                fieldLabel="FOLDER"
              />
              {folderType === 'documents' && (
                <>
                  <BulkUploaderToggler
                    label="SAME TYPE FOR ALL"
                    onToggle={(value) => setSameType(value)}
                    sameValue={sameType}
                    currentValue={documentType?.name}
                    openEditModal={() => setTypeModalOpened(true)}
                    sort={sortField === 'documentType.name' ? sortOrder : null}
                    onSort={() => onSort('documentType.name')}
                    assignLabel="ASSIGN TYPE"
                    fieldLabel="DOCUMENT TYPE"
                  />
                  <BulkUploaderToggler
                    label="SAME RIGHTS FOR ALL"
                    onToggle={(value) => setSameRights(value)}
                    sameValue={sameRights}
                    currentValue={permissions}
                    customCurrentValueFormatter={
                      <PermissionList
                        permissions={permissions}
                        hideActions
                        type="files"
                        onSubmit={setPermissions}
                      />
                    }
                    openEditModal={() => setPermissionsModalOpened(true)}
                    assignLabel="ASSIGN PERMISSIONS"
                    fieldLabel="PERMISSIONS"
                  />
                </>
              )}
              <td style={{ width: '3.4rem', visibility: 'hidden' }} />
            </tr>
          </thead>
        )}
        <tbody>
          {files.map((file) => (
            <tr key={`${file.name}${file.droppedAt}`}>
              <td className="w-[25%]">
                {folderType === 'media' && (
                  <FileMediaPreview
                    className="rounded-[8px] object-fit-cover float-left mr-m"
                    width={120}
                    height={80}
                    file={file.file}
                  />
                )}
                <p className="dark-60 inline-regular font-semibold">
                  {file.name}
                </p>
                <FileInfo
                  size={file.size}
                  extension={file.type.split('/')[1]}
                />
              </td>

              <BulkUploaderFileColumn
                sameValue={sameFolder}
                currentValue={currentFolder}
                notCompletedLabel="Folder selection is not yet complete"
                completedLabel={
                  <div className="lh-[1rem] flex items-center">
                    <Icon
                      iconName={folderType === 'documents' ? 'folder' : 'media'}
                      className="mr-xs"
                    />
                    {sameFolder ? currentFolder?.title : file.folder?.title}
                  </div>
                }
                onAssign={() => toggleSetFolderModalOpened(file)}
                currentFileValue={file.folder_id}
                uploadingState={uploadingState}
              />
              {folderType === 'documents' && (
                <>
                  <BulkUploaderFileColumn
                    sameValue={sameType}
                    currentValue={documentType?.name}
                    notCompletedLabel="Type selection is not yet complete"
                    completedLabel={
                      sameType ? documentType?.name : file.documentType?.name
                    }
                    onAssign={() => toggleTypeModalOpened(file)}
                    currentFileValue={file.documentType?.name}
                    uploadingState={uploadingState}
                  />

                  <BulkUploaderFileColumn
                    sameValue={sameRights}
                    currentValue={permissions}
                    notCompletedLabel="Permissions is not set yet"
                    completedLabel={
                      <PermissionList
                        permissions={
                          sameRights ? permissions : file.permissions
                        }
                        type="files"
                        hideActions
                        onSubmit={setCurrentFilePermissions}
                      />
                    }
                    onAssign={() => togglePermissionsModalOpened(file)}
                    currentFileValue={file.permissions}
                    uploadingState={uploadingState}
                  />
                </>
              )}

              <td className="text-center" style={{ width: '50px' }}>
                {uploadingState === 'notStarted' && (
                  <IconButton
                    classes={{
                      icon: 'secondary-regular',
                    }}
                    iconName="close"
                    onClick={() => removeFile(file)}
                  />
                )}
                {uploadingState !== 'notStarted' && file.progress < 100 && (
                  <CircleProgress progress={file.progress} />
                )}
                {(uploadingState === 'finished' || file.progress === 100) && (
                  <Icon iconName="check" className="green-dark-2" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {typeModalOpened && (
        <TypeModal
          documentType={currentFile ? currentFile.documentType : documentType}
          onSubmit={handleDocumentTypeSubmit}
          onClose={() => {
            setTypeModalOpened(false);
            setCurrentFile(null);
          }}
        />
      )}
      {setFolderModalOpened && (
        <SetFolderModal
          folders={folders.filter(({ id }) => exludedFolderIds.includes(id))}
          folder={
            currentFile
              ? files.find((f) => isEqual(f, currentFile)).folder
              : folders.find((f) => f.id === currentFolderId)
          }
          setFolder={currentFile ? () => {} : setCurrentFolderId}
          onClose={() => {
            setSetFolderModalOpened(false);
            setCurrentFile(null);
          }}
          investmentObject={investmentObject}
          files={files}
          setFiles={setFiles}
          currentFile={currentFile}
        />
      )}

      {permissionsModalOpened && (
        <PermissionsModal
          customHeader={
            <PermissionsModalDocsHeader fileItems={permittedFiles} />
          }
          onClose={onPermissionsModalClose}
          onSubmit={currentFile ? setCurrentFilePermissions : setPermissions}
          initialState={
            sameRights
              ? {
                  roles: permissions?.roles || [],
                  users: permissions?.users || [],
                  tags: permissions?.tags || [],
                  investmentEntities: permissions?.investmentEntities || [],
                }
              : {
                  roles: currentFile?.permissions?.roles || [],
                  users: currentFile?.permissions?.users || [],
                  tags: currentFile?.permissions?.tags || [],
                  investmentEntities:
                    currentFile?.permissions?.investmentEntities || [],
                }
          }
          initialTab={initialTab}
          investmentObject={investmentObject}
          productName={getProductName(investmentObject.type)}
        />
      )}
    </>
  );
};

export default BulkUploaderTable;

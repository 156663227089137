import React, { useState } from 'react';
import { Button, Modal, SearchInput, ThinTabGroup } from 'stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { orderBy } from 'lodash-es';
import { ReportDashboardAsset } from 'bundles/Shared/entities/dashboard';

type Props = DialogProps<ReportDashboardAsset['id']> & {
  assets: Pick<ReportDashboardAsset, 'id' | 'name'>[];
};
const TABS = [
  {
    label: 'Assets',
    id: 'assets',
  },
  {
    label: 'Funds',
    id: 'funds',
    disabled: true,
  },
  {
    label: 'Segments',
    id: 'segments',
    disabled: true,
  },
] as const satisfies readonly IThinTabItem[];

export function ReportDashboardFilterByObjectModal({
  onClose,
  onSubmit,
  assets,
}: Props) {
  const assetsOrderedByName = orderBy(assets, 'name');
  const [searchText, setSearchText] = useState('');
  const [selectedTab, setSelectedTab] = useState<IThinTabItem>(TABS[0]);

  const filteredAssets = assetsOrderedByName.filter((asset) =>
    includesInLowerCase(asset.name, searchText),
  );

  return (
    <Modal
      toggle={onClose}
      header="Object Selection"
      bodyHeight={600}
      classes={{
        body: 'bg-neutral-050 ',
      }}
    >
      <div className="flex h-full flex-col gap-tw-4">
        <div className="flex flex-col gap-tw-3">
          <SearchInput
            placeholder="Search by Object name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <ThinTabGroup
            selectedItem={selectedTab}
            onSelectedItemChange={setSelectedTab}
            items={TABS}
            classes={{
              itemContainer: 'grow',
              item: 'w-full justify-center',
            }}
          />
        </div>
        <div className="flex flex-col gap overflow-auto rounded-xl bg-neutral-150">
          {filteredAssets.map((asset) => (
            <div
              key={asset.id}
              onClick={() => onSubmit?.(asset.id)}
              className="group/item flex w-full cursor-pointer justify-between bg-neutral-000 p-tw-4"
            >
              <span className="body-semibold text-neutral-800">
                {asset.name}
              </span>
              <Button
                className="invisible group-hover/item:!visible"
                variant="secondary"
                size="xs"
              >
                Select
              </Button>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

import React, { ComponentProps, ReactNode, useState } from 'react';
import { Button, Modal, ModalActions } from 'stories';
import ModalAdditionalActions from '../Modal/ModalAdditionalActions';
import './confirmation-modal.css';

type ButtonProps = Pick<
  ComponentProps<typeof Button>,
  'variant' | 'disabled'
> & { text: string };

interface Props {
  handleResolve: (resolved: boolean) => Promise<void> | boolean;
  subtitle?: ReactNode | ReactNode[];
  title?: React.ReactNode;
  actions?: {
    primaryButton: ButtonProps;
    secondaryButton?: ButtonProps;
  };
  additionalActions?: {
    buttonText: string;
    description?: string;
    onClick?: () => void;
  };
  onClose?: () => void;
  size?: ComponentProps<typeof Modal>['size'];
}

const DEFAULT_ACTIONS = {
  primaryButton: {
    text: 'Yes',
    variant: 'danger',
  },
  secondaryButton: {
    text: 'Cancel',
    variant: 'secondary',
  },
} as const satisfies Props['actions'];

const Title = ({ children }: React.PropsWithChildren) => (
  <span className="confirmation-modal-title">{children}</span>
);

const Subtitle = ({ children }: React.PropsWithChildren) => (
  <span className="confirmation-modal-subtitle">{children}</span>
);

export const CONFIRMATION_MODAL_CLASSES = {
  footer: 'p-tw-6',
  footerAdditionalActions: 'border-top',
  body: 'pt-tw-8 pb-tw-2',
};

export const ConfirmationModal = ({
  title = 'Are you sure?',
  subtitle,
  actions = DEFAULT_ACTIONS,
  handleResolve,
  additionalActions,
  size,
  onClose,
}: Props) => {
  const [disabled, setDisabled] = useState(false);

  const clickHandle = async () => {
    setDisabled(true);
    const res = await handleResolve(true);
    if (res) {
      onClose?.();
    } else {
      setDisabled(false);
    }
  };

  return (
    <Modal
      toggle={() => {
        handleResolve(false);
        onClose?.();
      }}
      size={size}
      showDivider={false}
      actions={
        <ModalActions>
          {actions.secondaryButton && (
            <Button
              data-testid="confirmation-cancel"
              onClick={() => {
                handleResolve(false);
                onClose?.();
              }}
              fluid
              {...DEFAULT_ACTIONS.secondaryButton}
              {...actions?.secondaryButton}
            >
              {actions.secondaryButton.text}
            </Button>
          )}
          {actions.primaryButton && (
            <Button
              data-testid="confirmation-confirm"
              onClick={clickHandle}
              fluid
              {...DEFAULT_ACTIONS.primaryButton}
              {...actions?.primaryButton}
              disabled={disabled || actions.primaryButton.disabled}
            >
              {actions.primaryButton.text}
            </Button>
          )}
        </ModalActions>
      }
      classes={CONFIRMATION_MODAL_CLASSES}
      additionalActions={
        additionalActions && (
          <ModalAdditionalActions
            description={additionalActions.description}
            buttonText={additionalActions.buttonText}
            onClick={() => {
              if (additionalActions.onClick) additionalActions.onClick();
              handleResolve(false);
            }}
          />
        )
      }
    >
      <div className="flex flex-col">
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
    </Modal>
  );
};

ConfirmationModal.Title = Title;
ConfirmationModal.Subtitle = Subtitle;

export default ConfirmationModal;

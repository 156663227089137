import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { AnimationLoader, ChangeableStatusBadge } from 'stories';
import { VelocityChart } from 'bundles/Scoreboard/Pipeline/components/Pipeline/PipelineCharts/VelocityChart';
import { fetchVelocityData } from 'bundles/Scoreboard/Pipeline/actions';
import { colors } from 'bundles/Scoreboard/Pipeline/components/chartUtils';

const PipelineCharts = () => {
  const DEFAULT_PROPERTY_TYPE = {
    key: 'all',
    color: 'blue',
    label: 'Consolidated',
  };

  const dispatch = useAppDispatch();
  const propertyTypes = useAppSelector((state) => state.deals.propertyTypes);
  const velocity = useAppSelector((state) => state.deals.velocity);

  const [propertyType, setPropertyType] = useState(DEFAULT_PROPERTY_TYPE);

  useEffect(() => {
    dispatch(fetchVelocityData(propertyType.label));
  }, [propertyType]);

  const options = useMemo(
    () =>
      propertyTypes.map((item, index) => ({
        label: item,
        key: item.replace(' ', '_').toLowerCase(),
        color: colors[index % colors.length],
      })),
    [propertyTypes],
  );

  return (
    <div className="card">
      <div className="card-header card-header-action">
        <div>
          Pipeline
          <h4 className="dark-60">Velocity</h4>
        </div>
        <ChangeableStatusBadge
          handleItemClick={setPropertyType}
          options={[DEFAULT_PROPERTY_TYPE, ...options]}
          badgeLabel={null}
          selected={propertyType}
        />
      </div>

      <div className="card-body p-0 min-h-[500px]">
        {!velocity && <AnimationLoader />}
        {velocity && <VelocityChart pipelineChartsData={velocity} />}
      </div>
    </div>
  );
};

export default PipelineCharts;

import { fetchGenericData } from '@/app/reducers/actions';
import http, { apiClient, requireAuthorizationHeaders } from 'lib/http';
import { camelCase, mapKeys } from 'lodash-es';
import { stringify } from 'qs';

export const FETCH_FUNDS = 'symmetre-client-api/FETCH_FUNDS';
export const FETCH_FUND = 'symmetre-client-api/FETCH_FUND';
export const CREATE_FUND = 'symmetre-client-api/CREATE_FUND';
export const UPDATE_FUND = 'symmetre-client-api/UPDATE_FUND';

export const plainFetchFunds = async (data = null) => {
  const options = {
    indices: false,
    arrayFormat: 'brackets',
    encode: false,
  };
  const res = await http.get(`/funds?${data ? stringify(data, options) : ''}`);
  return res.json();
};

export function fetchFunds(extended) {
  return async (dispatch) => {
    const res = await http.get(`/funds${extended ? '?extended=true' : ''}`);
    const funds = await res.json();

    dispatch({ type: FETCH_FUNDS, payload: funds });

    return funds;
  };
}

export async function plainFetchFund(id) {
  const res = await http.get(`/funds/${id}`);
  const fund = await res.json();

  return fund;
}

export function fetchFund(id, shouldReset) {
  return async (dispatch) => {
    if (shouldReset) {
      // if switching between assets
      dispatch({ type: FETCH_FUND, payload: null });
    }
    const fund = await plainFetchFund(id);

    dispatch({ type: FETCH_FUND, payload: fund });
  };
}

export async function linkAssetToFund({
  asset_ids,
  fundId,
}: {
  fundId: number;
  asset_ids: number[];
}) {
  const res = await http.post(`/funds/${fundId}/link`, {
    asset_ids,
  });
  if (res.ok) {
    toastr.success('Fund has been successfully mapped');
    return true;
  }
  return false;
}

export function createFund(formData) {
  return async (dispatch) => {
    const res = await apiClient.post('/api/funds', formData, {
      headers: requireAuthorizationHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    });

    const fund = res.data;

    if (fund.errors) {
      toastr.error(`${fund.errors}\n`);
    } else {
      toastr.success('Fund created successfully');
      dispatch({ type: CREATE_FUND, payload: fund });
      dispatch(fetchGenericData());
    }
  };
}

export function heavyUpdateFund(data) {
  return async (dispatch, getState) => {
    const { currentFund } = getState();

    const res = await apiClient.put(`/api/funds/${data.get('id')}`, data, {
      headers: requireAuthorizationHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    });

    const responseData = res.data;

    const newData = {
      ...currentFund,
      ...responseData,
    };

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
      return;
    }

    toastr.success('Fund has been updated');
    if (window.location.href.includes('/funds/'))
      history.pushState({}, null, responseData.slug);

    dispatch({ type: UPDATE_FUND, payload: newData });
  };
}

export function simpleUpdateFund(data) {
  return async (dispatch, getState) => {
    const { currentFund } = getState();

    const res = await http.put(`/funds/${data.id}/simple_update`, data);
    const responseData = await res.json();
    const transformData = mapKeys(data.fund, (_value, key) => camelCase(key));

    const newData = {
      ...currentFund,
      ...transformData,
    };

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
      return;
    }

    toastr.success('Fund has been updated');
    if (window.location.href.includes('/funds/'))
      history.pushState({}, null, responseData.slug);

    dispatch({ type: UPDATE_FUND, payload: newData });

    return newData;
  };
}

/* eslint-disable max-len */
import React, { FC, useCallback, useEffect } from 'react';
import Field, { FieldsContainer } from 'stories/Field/Field';
import { Controller, useForm } from 'react-hook-form';
import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';
import { UserSelect } from 'bundles/Shared/entities/user/ui/UserSelect';
import {
  useDeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdMutation,
  useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsQuery,
  usePostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsMutation,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/approvers/api/reconcileWorkflowApproversApiExtended';
import pluralize from 'pluralize';
import { Icon, IconButton, Popover, PseudoLink } from 'stories/index';
import { LegalEntity } from '@/entities/core/legalEntity';
import { UserRoleSelect } from 'bundles/Shared/entities/user/ui/UserRoleSelect';
import { PhoneLink } from 'bundles/Shared/shared/PhoneLink';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';

interface IApprovalNotificationProps {
  legalEntityId: LegalEntity['id'];
}

type Recepient = {
  user_id: string;
  user_role_id: string;
};

const UserInfoPopover = ({
  fullName,
  phone,
  email,
}: {
  fullName: string;
  email?: string;
  phone?: string;
}) => (
  <div className="flex flex-col gap-tw-2 p-tw-3">
    <div className="body-semibold text-neutral-850">{fullName}</div>
    {phone && (
      <div className="flex items-center gap-tw-2">
        <div className="flex h-[24px] w-[24px] items-center justify-center rounded-lg bg-neutral-100">
          <Icon iconName="phone" />
        </div>
        <PhoneLink phone={phone} className="inline-regular">
          {phone}
        </PhoneLink>
      </div>
    )}
    {email && (
      <div className="flex items-center gap-tw-2">
        <div className="flex h-[24px] w-[24px] items-center justify-center rounded-lg bg-neutral-100">
          <Icon iconName="email" />
        </div>
        <EmailLink email={email} className="inline-regular text-info-055">
          {email}
        </EmailLink>
      </div>
    )}
  </div>
);

const ApprovalNotification: FC<IApprovalNotificationProps> = ({
  legalEntityId,
}) => {
  const { data: recipients } =
    useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsQuery(
      {
        legalEntityId,
      },
      { skip: !legalEntityId },
    );
  const [
    createRecipient,
    {
      isLoading: isCreatingRecipientLoading,
      isSuccess: isSuccessCreatingRecipient,
    },
  ] =
    usePostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsMutation();

  const [deleteRecipient] =
    useDeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdMutation();

  const users = recipients?.meta.users ?? [];
  const userRoles = recipients?.meta.userRoles ?? [];

  const resolver = useCallback((values: Recepient) => {
    const errors: Partial<Recepient> = {};
    if (!values.user_role_id) {
      errors.user_role_id = 'Role is required';
    }

    if (!values.user_id) {
      errors.user_id = 'User is required';
    }
    return {
      values,
      errors,
    };
  }, []);

  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver,
  });

  const userRoleId = watch('user_role_id');

  useEffect(() => {
    if (isSuccessCreatingRecipient) {
      reset();
    }
  }, [isSuccessCreatingRecipient]);

  const handleAddUser = () => {
    const recipient = getValues();
    createRecipient({
      legalEntityId,
      body: recipient,
    });
  };

  const handleDeletedRecipient = (recipientId: number) => {
    deleteRecipient({
      id: String(recipientId),
      legalEntityId,
    });
  };

  return (
    <div className="relative flex h-screen flex-col gap-tw-4 bg-white p-tw-6">
      <div className="flex flex-col gap-tw-1.5">
        <div className="body-semibold text-neutral-850">
          Approval Notifications
        </div>
        <div className="inline-regular text-neutral-500">
          Want to keep other users in the loop? Select them below so they will
          receive an email upon successful Change Order Approval.
        </div>
      </div>
      <div className="flex flex-col gap-tw-4">
        <FieldsContainer className="flex-row">
          <Field required labelText="Role" className="flex-1">
            <Controller
              control={control}
              name="user_role_id"
              render={({ field }) => (
                <UserRoleSelect
                  value={field.value}
                  onChange={(o) => field.onChange(o?.value)}
                  options={userRoles.map((userRole) => ({
                    value: userRole.id,
                    label: userRole.name,
                  }))}
                />
              )}
            />
          </Field>
          <Field required labelText="User" className="flex-1">
            <Controller
              control={control}
              name="user_id"
              render={({ field }) => (
                <UserSelect
                  value={field.value}
                  onChange={(o) => field.onChange(o?.value)}
                  options={users
                    ?.filter((u) => u.userRoleId === userRoleId)
                    .map((user) => ({
                      value: user.id,
                      label: user.fullName,
                    }))}
                  disabled={!userRoleId}
                />
              )}
            />
          </Field>
        </FieldsContainer>
        <SendDataButton
          variant="success"
          isLoading={isCreatingRecipientLoading}
          disabled={!isValid || isCreatingRecipientLoading}
          className="w-full"
          onClick={handleAddUser}
        >
          Add User
        </SendDataButton>
      </div>
      {Boolean(recipients?.items.length) && (
        <div>
          <div className="secondary-semibold">
            {recipients?.items.length}{' '}
            {pluralize('user', recipients?.items.length ?? 0)}
          </div>
          {recipients?.items.map((recipient) => (
            <div
              key={recipient.id}
              className="inline-regular flex justify-between border-t border-solid border-neutral-200 py-tw-4"
            >
              <Popover
                maxWidth="37.5rem"
                hiddenArrow
                trigger="click"
                placement="left-start"
                template={
                  <UserInfoPopover
                    fullName={recipient.user.fullName}
                    phone={recipient.user.phone}
                    email={recipient.user.email}
                  />
                }
              >
                <PseudoLink>
                  <div className="text-neutral-850">
                    {recipient.user.fullName}
                  </div>
                </PseudoLink>
              </Popover>
              <div className="flex items-center gap-tw-4">
                <div>{recipient.userRole.name}</div>
                <IconButton
                  iconName="closeSmall"
                  variant="white"
                  onClick={() => handleDeletedRecipient(recipient.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ApprovalNotification;

import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import React, { FC, useEffect, useState } from 'react';
import { SearchInput } from 'stories';

interface ISearchPayAppInputProps {
  onSubmit: () => void;
}

const SearchPayAppInput: FC<ISearchPayAppInputProps> = ({ onSubmit }) => {
  const [loadingSearch, setLoadingSearch] = useState(false);

  let delaySearch;
  const handleChange = (e) => {
    clearTimeout(delaySearch);
    setLoadingSearch(true);
    delaySearch = setTimeout(() => {
      onSubmit(e);
      setLoadingSearch(false);
    }, 1500);
  };

  return (
    <div className="flex pay-app-search__header">
      <div className="pay-app-search__title">Pay Apps</div>
      <div className="flex items-center">
        <div className="pay-app-search__modal-input mr-tw-2">
          <SearchInput
            onChange={handleChange}
            placeholder="Search Pay App"
            autoFocus
            resetValue={handleChange}
          />
        </div>
        <div className="pay-app-search-load">
          {loadingSearch && <LoadingPanel />}
        </div>
      </div>
    </div>
  );
};

export default SearchPayAppInput;

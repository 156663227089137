import React, { ComponentProps, useMemo } from 'react';
import CheckList from 'stories/Checkbox/CheckList';

type Props = ComponentProps<typeof CheckList>;

function SplittedCheckList({
  value,
  onChange,
  items,
  disabled,
  className,
}: Props) {
  const unCheckedItems = useMemo(
    () =>
      items.filter(
        (item) =>
          value.find((checkedItem) => checkedItem.value === item.value) == null,
      ),
    [value, items],
  );

  return (
    <div className={className}>
      {value.length > 0 ? (
        <div>
          <p className="mb-xs">
            <span className="light-60 label-regular mr-s">
              {value.length} SELECTED
            </span>
            <span
              onClick={() => onChange([])}
              className="blue cursor-pointer label-regular"
            >
              CLEAR
            </span>
          </p>

          <CheckList
            disabled={disabled}
            items={value}
            value={value}
            onChange={onChange}
          />
        </div>
      ) : null}
      {value.length > 0 && value.length !== items.length ? (
        <span className="light-60 label-regular mb-xs mt-s flex">OTHER</span>
      ) : null}
      <CheckList
        disabled={disabled}
        items={unCheckedItems}
        value={[]}
        onChange={(items) => onChange([...value, ...items])}
      />
    </div>
  );
}

export default SplittedCheckList;

import http from 'lib/http';
import { IBudgetVarianceComment } from 'bundles/REconcile/types/Comments';
import { LegalEntity } from '@/entities/core/legalEntity';
import { TDevelopmentCategorizableType } from 'bundles/REconcile/actions/developmentBudgetVariance';

interface IPathParams {
  legalEntityCode: LegalEntity['code'];
}

export interface ICommentAttachment {
  key: string;
  filename: string;
  content_type: string;
}

interface ICommentRequestBody {
  message: string;
  attachments: ICommentAttachment[];
}

export const getDevelopmentBudgetVarianceComments = async ({
  legalEntityCode,
}: IPathParams) => {
  const res = await http.get(
    `/development/legal_entities/${legalEntityCode}/budget_variance/comments`,
  );
  const json = await res.json();
  return json as IBudgetVarianceComment[];
};

export interface IReplyToDevelopmentBudgetVarianceCommentRequest
  extends IPathParams,
    ICommentRequestBody {
  commentId: string;
}

export const replyToDevelopmentBudgetVarianceComment = async ({
  legalEntityCode,
  commentId,
  ...comment
}: IReplyToDevelopmentBudgetVarianceCommentRequest) => {
  const res = await http.post(
    `/development/legal_entities/${legalEntityCode}/budget_variance/comments/${commentId}/reply`,
    comment,
  );
  const json = await res.json();
  return json as IBudgetVarianceComment;
};

export interface IAddRequisitionCommentRequest
  extends IPathParams,
    ICommentRequestBody {
  requisition_id: string;
  development_categorizable_id: number;
  development_categorizable_type: TDevelopmentCategorizableType;
}

export const addRequisitionComment = async ({
  legalEntityCode,
  ...requestBody
}: IAddRequisitionCommentRequest) => {
  const res = await http.post(
    `/development/legal_entities/${legalEntityCode}/budget_variance/add_requisition_comment`,
    requestBody,
  );
  const json = await res.json();
  if (res.ok) {
    toastr.success('Comment Created');
  }
  return json as IBudgetVarianceComment;
};

export const fetchMentionableUsers = async ({
  legalEntityCode,
}: IPathParams) => {
  const res = await http.get(
    `/development/legal_entities/${legalEntityCode}/budget_variance/mentionable_users`,
  );
  const json = await res.json();
  return json as { id: number; fullName: string }[];
};

import {
  IDealAddress,
  IDealData,
} from 'bundles/Scoreboard/Pipeline/components/DealItem/types';

export function resolveAddress(address: IDealAddress) {
  const addressArr: string[] = [];
  const keysToAdd = ['address_1', 'postal_code', 'city', 'country'] as const;

  keysToAdd.forEach((key) => {
    const value = address[key];
    if (value === null) return;

    addressArr.push(value);
  });

  return addressArr.join(', ');
}

export function resolveInfoFieldEntries(data?: IDealData) {
  if (!data) return [];
  const fieldKeysToRemove: string[] = [
    'stage',
    'deal_type',
    'deal_status',
    'deal_members',
    'deal_progress',
  ];
  const entries = Object.entries(data);
  const fields = entries.filter(([key, v]: [string, string | null]) => {
    if (fieldKeysToRemove.includes(key)) return false;
    return Boolean(v);
  }) as [string, string][];
  return fields;
}

import GoogleMap from 'bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/GoogleMap';
import React from 'react';
import { IconButton, Icon, AssetInfoCard } from 'stories';
import styles from './assetLocationCard.module.scss';

interface Props {
  latitude: string;
  longitude: string;
  address: string;
  canManage: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const AssetLocationCard = ({
  onEdit,
  onDelete,
  latitude,
  longitude,
  address,
  canManage,
}: Props) => (
  <AssetInfoCard
    header={<div />}
    actions={
      canManage && (
        <>
          <IconButton onClick={onEdit} variant="secondary" iconName="edit" />
          <IconButton onClick={onDelete} variant="secondary" iconName="trash" />
        </>
      )
    }
    content={
      <>
        <div className={styles.map}>
          <GoogleMap
            latitude={latitude}
            longitude={longitude}
            classes={{ value: 'overflow-hidden border' }}
          />
        </div>
        <div className={styles.address}>
          <div className={styles.icon}>
            <Icon iconName="location" />
          </div>
          <div className="dark-60 inline-regular ml-s">{address}</div>
        </div>
      </>
    }
  />
);

export default AssetLocationCard;

import React, { useEffect, useState } from 'react';
import {
  CrudContextProvider,
  CrudTable,
  CrudTablePanel,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import useEvent from '@/shared/lib/hooks/useEvent';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  CHANGE_ORDER_REASON_ENTITY_NAME,
  createChangeOrderReasonsInReconcileSettings,
  deleteChangeOrderReasonsInReconcileSettings,
  getChangeOrderReasonsInReconcileSettings,
  IReconcileSettingsChangeOrderReason,
  TGetReconcileSettingsChangeOrderReasonParams,
  updateChangeOrderReasonsInReconcileSettings,
} from 'bundles/REconcile/actions/changeOrderReasons';
import {
  TFilterModel,
  useChangeOrderReasonsCrudColumns,
} from 'bundles/REconcile/components/development/home/settingsModal/changeOrderReasons/useColumns';
import ChangeOrderReasonCrudModal from 'bundles/REconcile/components/development/home/settingsModal/changeOrderReasons/ChangeOrderReasonCrudModal';
import { isEmpty } from 'lodash-es';

function ChangeOrderReasonsCrud() {
  const { openModal } = useModal();
  const [totalSize, setTotalSize] = useState(0);
  const [pageParams, setPageParams] =
    useState<TGetReconcileSettingsChangeOrderReasonParams>({
      per_page: 10,
    });
  const [changeOrderReasons, setChangeOrderReasons] = useState<
    IReconcileSettingsChangeOrderReason[]
  >([]);
  const [categories, setCategories] = useState<string[] | null>(null);
  const columns = useChangeOrderReasonsCrudColumns({
    categories: categories ?? [],
  });

  const loadChangeOrderReasons = async () => {
    const data = await getChangeOrderReasonsInReconcileSettings({}, pageParams);

    setTotalSize(data.meta.totalSize);
    setCategories(data.meta.categories);
    setChangeOrderReasons(data.items);
  };

  const handleCreate = useEvent(async () => {
    const res = await openModal(ChangeOrderReasonCrudModal, {
      categories,
      type: 'create',
      entity: {
        name: '',
        category: null,
        active: false,
      },
    });

    if (res) {
      await createChangeOrderReasonsInReconcileSettings({}, res);
      loadChangeOrderReasons();
    }
  });

  const handleEdit = useEvent(
    async (row: IReconcileSettingsChangeOrderReason) => {
      const res = await openModal(ChangeOrderReasonCrudModal, {
        categories,
        type: 'edit',
        entity: {
          name: row.name,
          category: row.category,
          active: row.active,
        },
      });

      if (res) {
        await updateChangeOrderReasonsInReconcileSettings(
          {
            entityId: row.id,
          },
          res,
        );
        loadChangeOrderReasons();
      }
    },
  );

  const handleRemove = useEvent(
    async (row: IReconcileSettingsChangeOrderReason) => {
      await deleteChangeOrderReasonsInReconcileSettings({
        entityId: row.id,
      });
      loadChangeOrderReasons();
    },
  );

  useEffect(() => {
    loadChangeOrderReasons();
  }, [pageParams]);

  const handleFilterModelChange = (filterModel: TFilterModel) =>
    setPageParams((prevParams) => ({
      ...prevParams,
      categories: isEmpty(filterModel.category)
        ? undefined
        : filterModel.category,
      active: filterModel.active ? filterModel.active === 'true' : undefined,
    }));

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entities={changeOrderReasons}
      entityName={CHANGE_ORDER_REASON_ENTITY_NAME}
      handlers={{
        create: handleCreate,
        update: handleEdit,
        delete: handleRemove,
      }}
    >
      <div>
        <CrudTablePanel
          paginationProps={{
            totalSize,
          }}
        />
        <CrudTable
          onFilterModelChange={handleFilterModelChange}
          columns={columns}
        />
      </div>
    </CrudContextProvider>
  );
}

export default ChangeOrderReasonsCrud;

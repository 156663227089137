import React, { useEffect, useState } from 'react';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { navigate } from '@reach/router';
import {
  createCustomTemplate,
  customTemplatePreviews,
  getCustomTemplate,
  getCustomTemplateVariables,
  removeCustomTemplate,
  sendCustomTemplateEmail,
  updateCustomTemplate,
} from 'bundles/Settings/actions/customTemplates';
import { IUser, IUserRole } from 'types/User';
import TemplateFormHeader from './TemplateFormHeader';
import TemplatePreview from '../TemplatePreview';
import TemplateEditorContainer from './TemplateEditorContainer';
import CustomTemplateForm from '../forms/CustomTemplateForm';
import { AnimationLoader } from 'stories/index';

export interface ICustomTemplate {
  id?: number;
  subject?: string;
  sections: any[];
  userRoles: IUserRole[];
  users: IUser[];
  schedule?: string;
  usedVariables?: ICustomTemplateVariables[];
  name: string;
}

interface Props {
  id?: number;
}

interface ICustomTemplateVariables {
  header: any[];
}

const CustomTemplateEdit = ({ id }: Props) => {
  const [template, setTemplate] = useState<ICustomTemplate>({
    id: undefined,
    name: '',
    subject: '',
    sections: [],
    userRoles: [],
    users: [],
    usedVariables: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [templatePreview, setTemplatePreview] = useState();
  const debouncedTemplate = useDebounce(template, 500);
  const [variables, setVariables] = useState<ICustomTemplateVariables>({
    header: [],
  });
  const [usedVariables, setUsedVariables] = useState<ICustomTemplateVariables>({
    header: [],
  });

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const [variablesJson] = await Promise.all([getCustomTemplateVariables()]);

      if (id !== undefined) {
        const json = await getCustomTemplate(id);
        setUsedVariables({ header: json?.headerVariables ?? [] });
        setTemplate(json);
      }

      setVariables({
        header: variablesJson.header,
      });

      setIsLoading(false);
    };
    try {
      fetch();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setIsPreviewLoading(true);
      const previewJson = await customTemplatePreviews({
        id,
        template,
        usedVariables,
      });
      setTemplatePreview(previewJson);
      setIsPreviewLoading(false);
    };
    if (template !== null) {
      try {
        fetch();
      } catch (err) {
        (err) => console.error(err);
      }
    }
  }, [debouncedTemplate]);

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (id) return debouncedTemplate != null && templatePreview != null;

    return true;
  };

  const canSave = () => !isLoading && template != null;

  const handleSubmit = async () => {
    const params = { id, template, usedVariables };
    const templateReponse = id
      ? await updateCustomTemplate(params)
      : await createCustomTemplate(params);

    if (templateReponse.errors === undefined) {
      navigate('/settings/emails/templates/custom');
    }
  };

  const handleSendEmail = async () => {
    await sendCustomTemplateEmail({
      id,
      template,
    });
  };

  return (
    <TemplateEditorContainer
      header={
        <TemplateFormHeader
          saveButtonEnabled={canSave()}
          removeTemplate={id ? () => removeCustomTemplate({ id }) : undefined}
          afterRemoveRedirectPath="/settings/emails/templates/custom"
          onSave={() => handleSubmit()}
          onSend={() => handleSendEmail()}
          title="Custom Templates"
        />
      }
      form={
        <CustomTemplateForm
          isLoading={isLoading}
          template={template}
          onTemplateChange={(newTemplate) => setTemplate(newTemplate)}
          variables={variables}
          usedVariables={usedVariables}
          onUsedVariablesChange={(newVariables) =>
            setUsedVariables(newVariables)
          }
          onVariablesChange={(newVariables) => setVariables(newVariables)}
        />
      }
      preview={
        <>
          {isPreviewReady() ? (
            <TemplatePreview
              subject={debouncedTemplate?.subject ?? 'Custom Template'}
              templatePreview={templatePreview}
            />
          ) : (
            <AnimationLoader />
          )}
        </>
      }
    />
  );
};

export default CustomTemplateEdit;

import React, { FC, SetStateAction } from 'react';
import StepsFormStepv2, {
  QAItemsContainer,
} from 'stories/StepsFormStep/StepsFormStepv2';
import { IFormItem } from '../../types/types';
import FCStepCore from '../FCStepCore';
import {
  NoUnitQuestionAnswer,
  YesUnitQuestionAnswer,
} from 'stories/UnitQuestionAnswer/UnitQuestionAnswer';

interface IFCStepsNumberProps {
  formItem: IFormItem;
  value: any;
  formData: object;
  setFormData: SetStateAction<object>;
  loading: string;
  validate: object;
  setValidate: SetStateAction<object>;
  formItems: IFormItem[];
  styles?: {
    wrapper?: string;
    stepWrapper?: string;
    gap?: string;
    transparent?: string;
  };
  compId: number;
  lineForStep: boolean;
  title: string;
  required: boolean;
  activated: boolean;
  // todo duplicate GroupFormStep
  hasQA?: boolean;
  qaItems?: {
    yes: {
      active: boolean;
      handle: (status: boolean) => void;
      disabled?: boolean;
    };
    no: {
      active: boolean;
      handle: (status: boolean) => void;
      disabled?: boolean;
    };
  };
}

const FCStepsNumber: FC<IFCStepsNumberProps> = ({
  formData,
  setFormData,
  loading,
  formItems,
  styles,
  validate,
  setValidate,
  compId,
  lineForStep,
  title,
  required,
  activated,
  hasQA,
  qaItems,
}) => (
  <StepsFormStepv2
    title={title}
    stepNumber={compId}
    required={required}
    lineForStep={lineForStep}
    activated={activated}
    afterTitle={
      hasQA && (
        <QAItemsContainer>
          <YesUnitQuestionAnswer
            chosen={qaItems?.yes.active}
            onClick={() => qaItems?.yes.handle(true)}
            disabled={qaItems?.yes?.disabled}
          />
          <NoUnitQuestionAnswer
            chosen={qaItems?.no.active}
            onClick={() => qaItems?.no.handle(false)}
            disabled={qaItems?.no?.disabled}
          />
        </QAItemsContainer>
      )
    }
  >
    <FCStepCore
      formData={formData}
      setFormData={setFormData}
      loading={loading}
      validate={validate}
      setValidate={setValidate}
      formItems={formItems}
      required={required}
      title={title}
      styles={styles}
    />
  </StepsFormStepv2>
);

export default FCStepsNumber;

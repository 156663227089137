import { cn } from '@/shared/lib/css/cn';
import React, { FC, ReactNode } from 'react';
import { getMergedClasses } from 'stories/ValueFormatters/utils';

export type OutputFormatterClassKeys =
  | 'wrapper'
  | 'startSymbol'
  | 'endSymbol'
  | 'value'
  | 'brackets'
  | 'allParts';

export type OutputFormatterClasses = Partial<
  Record<OutputFormatterClassKeys, string>
>;
export type OutputFormatterStyles = Partial<
  Record<OutputFormatterClassKeys, React.CSSProperties>
>;

export interface IOutputFormatterProps {
  children: ReactNode | null | undefined;
  classes?: OutputFormatterClasses;
  styles?: OutputFormatterStyles;
  startSymbol?: '$';
  endSymbol?: StringAndOptions<'%' | 'x' | 'ft²' | 'bps'>;
}

export const OUTPUT_DEFAULT_CLASSES = {
  startSymbol: 'text-neutral-400',
  brackets: 'text-neutral-400',
  endSymbol: 'text-neutral-400',
} as const satisfies OutputFormatterClasses;

export const OutputFormatter: FC<IOutputFormatterProps> = ({
  children,
  startSymbol,
  endSymbol,
  ...props
}) => {
  const classes = getMergedClasses({
    classes: props.classes,
    defaultClasses: OUTPUT_DEFAULT_CLASSES,
  });

  return (
    <span
      style={props.styles?.wrapper}
      className={cn(
        'inline-flex items-center gap-tw-0.5 whitespace-nowrap',
        classes?.wrapper,
      )}
    >
      {startSymbol && (
        <span style={props.styles?.startSymbol} className={classes.startSymbol}>
          {startSymbol}
        </span>
      )}
      <span style={props.styles?.value} className={classes.value}>
        {children}
      </span>
      {endSymbol && (
        <span style={props.styles?.endSymbol} className={classes.endSymbol}>
          {endSymbol}
        </span>
      )}
    </span>
  );
};

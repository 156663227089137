/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModalConfigOptions } from '@/bundles/Shared/ModalProvider';
import { ComponentProps, createContext, useContext, useEffect } from 'react';
import ConfirmationModal from 'stories/Modals/ConfirmationModal/ConfirmationModal';

const defaultCallback: any = () => {
  console.error("Can't open modal - context not found.");
  return new Promise(() => null);
};
const defaultConfirm: any = () => {
  console.error("Can't open confirm - context not found.");
  return new Promise(() => null);
};

export type DialogProps<T = any> = {
  onClose: () => void;
  onSubmit?: (res?: T) => void | Promise<T>;
};

// todo write type for redux
export type TOpenDialog = <C extends React.ComponentType<DialogProps>>(
  Modal: C,
  props: Omit<ComponentProps<C>, 'onClose' | 'onSubmit'>,
  modalConfigOptions?: ModalConfigOptions,
) => Promise<
  ComponentProps<C> extends DialogProps<infer R> ? R | undefined : never
>;

export type TConfirmFuncProps = Omit<
  ComponentProps<typeof ConfirmationModal>,
  'handleResolve'
>;

export type TConfirmFunc = (props?: TConfirmFuncProps) => Promise<boolean>;

export const ModalContext = createContext<{
  openModal: TOpenDialog;
  resetAllModals: VoidFunction;
  confirm: TConfirmFunc;
  modalList: React.JSX.Element[];
}>({
  openModal: defaultCallback,
  resetAllModals: defaultCallback,
  confirm: defaultConfirm,
  modalList: [],
});

export const useModal = () => useContext(ModalContext);

export const useResetAllModalsOnUnmount = () => {
  const { resetAllModals } = useModal();

  useEffect(
    () => () => {
      resetAllModals();
    },
    [],
  );
};

import { settingsReportOperationalUnitStatusesApi } from '@/entities/report/unitStatuses/api/settingsReportOperationalUnitStatusesGeneratedApi';

export const settingsReportOperationalUnitStatusesEnhancedApi =
  settingsReportOperationalUnitStatusesApi.enhanceEndpoints({
    addTagTypes: ['SourceUnitStatuses'],
    endpoints: {
      getApiSettingsReportRentRollSourceUnitStatuses: {
        providesTags: ['SourceUnitStatuses'],
      },
      putApiSettingsReportRentRollSourceUnitStatusesSetUnitStatus: {
        invalidatesTags: ['SourceUnitStatuses'],
        transformResponse: (res) => {
          toastr.success('Source Unit Status(es) updated');
          return res;
        },
      },
    },
  });

export const {
  // Source Unit Statuses
  useGetApiSettingsReportRentRollSourceUnitStatusesQuery,
  usePutApiSettingsReportRentRollSourceUnitStatusesSetUnitStatusMutation,

  // Unit Statuses
  useGetApiSettingsReportRentRollUnitStatusesQuery,
} = settingsReportOperationalUnitStatusesEnhancedApi;

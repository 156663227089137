import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import dayjs from 'dayjs';
import {
  coeWeightedAmountFormatter,
  reallocationNumberFormatter,
} from 'bundles/Construction/components/helpers';
import { fetchImage } from 'lib/helpers';
import { defaultHeader, initPDFBuilder, IPdfTableHeader } from 'lib/pdfBuilder';
import {
  IChangeEvent,
  IChangeOrder,
  IChangeOrderEvent,
  IReallocation,
  WithChangeOrderReasons,
} from 'bundles/Construction/types';
import { orderBy } from 'lodash-es';

export const CE_HEADERS: IPdfTableHeader<IChangeOrderEvent>[] = [
  {
    key: 'displayNumber',
    label: 'Number',
    width: 55,
  },
  {
    key: 'category',
    label: 'Category',
    width: 55,
    alignment: 'center',
  },
  { key: 'title', label: 'Title', width: 430 },
  {
    key: 'value',
    label: 'Amount',
    width: 60,
    display: coeWeightedAmountFormatter,
  },
  {
    key: 'status',
    label: 'Status',
    width: 105,
  },
  {
    key: 'changeOrder',
    label: 'CO #',
    alignment: 'center',
    width: 35,
    display: (row) => row.changeOrder?.number.toString(),
  },
];

const CE_HEADERS_WITH_REASONS: IPdfTableHeader<
  IChangeEvent & WithChangeOrderReasons
>[] = [
  {
    key: 'displayNumber',
    label: 'Number',
    width: 45,
  },
  {
    key: 'category',
    label: 'Category',
    width: 45,
    alignment: 'center',
  },
  { key: 'title', label: 'Title', width: 230 },
  {
    key: 'changeOrderReasons',
    label: 'Reason',
    width: 230,
    display: (row) => row.changeOrderReasons.map((r) => r.name).join(', '),
  },
  {
    key: 'value',
    label: 'Amount',
    width: 60,
    display: (row) =>
      row.status === 'VOID' ? '$0' : coeWeightedAmountFormatter(row),
  },
  { key: 'status', label: 'Status', width: 90 },
  {
    key: 'changeOrder',
    label: 'CO #',
    alignment: 'center',
    width: 35,
    display: (row) => row.changeOrder?.number.toString(),
  },
];
export const CO_HEADERS: IPdfTableHeader<IChangeOrder>[] = [
  {
    key: 'number',
    label: 'Number',
    width: 37,
  },
  {
    key: 'date',
    label: 'Date',
    width: 50,
    alignment: 'center',
    display: (row) => formatUnixDate(row.date, 'L'),
  },
  {
    key: 'description',
    label: 'Description',
    width: 285,
  },
  {
    key: 'amount',
    label: 'CO Amount',
    width: 60,
    alignment: 'center',
    display: (row) => accountingNumberFormat(row.amount),
  },
  {
    key: 'changeOrderVerification.newContractSum',
    label: 'New Contract Sum',
    width: 65,
    alignment: 'center',
    display: (row) => accountingNumberFormat(row.newContractSum),
  },
  {
    key: 'timingImpact',
    label: 'Impact (Days)',
    alignment: 'center',
    width: 40,
    display: (row) => row.timingImpactDays ?? '-',
  },
  { key: 'status', label: 'Status', width: 55 },
  {
    key: 'approvedAt',
    label: 'Approved at',
    width: 60,
    alignment: 'center',
    display: (row) => row.approvedAt && dayjs(row.approvedAt).format('L'),
  },
  {
    key: 'reallocationDate',
    label: 'Realloc. Date',
    width: 60,
    alignment: 'center',
    display: (row) => formatUnixDate(row.reallocationDate, 'L'),
  },
];
export const REALLOCATION_HEADERS: IPdfTableHeader<IReallocation>[] = [
  {
    key: 'number',
    label: 'Number',
    width: 36,
    display: reallocationNumberFormatter,
  },
  { key: 'changeOrderNumber', label: 'CO#', width: 36 },
  { key: 'description', label: 'Description', width: 207 },
  {
    key: 'value',
    label: 'Value',
    width: 50,
    alignment: 'center',
    display: (row) => accountingNumberFormat(row.value),
  },
  { key: 'status', label: 'Status', width: 55 },
  {
    key: 'movedFrom',
    label: 'Moved From',
    width: 110,
    display: (row) => row.movedFrom.name,
  },
  {
    key: 'movedTo',
    label: 'Moved To',
    width: 110,
    display: (row) => row.movedTo.name,
  },
  {
    key: 'changeOrder.date',
    label: 'CO date',
    width: 50,
    alignment: 'center',
    display: (row) => formatUnixDate(row.changeOrder?.date, 'L'),
  },
  {
    key: 'approvedAt',
    label: 'Realloc. date',
    width: 50,
    alignment: 'center',
    display: (row) => formatUnixDate(row.approvedAt, 'L'),
  },
];

export const numberDesc = (a, b) => b.number - a.number;
export const saveItemLogAsPDF = async ({
  assetName,
  items,
  itemName,
  headers,
}: {
  assetName: string;
  items: Record<string, unknown>[];
  itemName: string;
  headers: IPdfTableHeader[];
}) => {
  const logoUrl =
    window.location.origin + window.symmetreConfig.customer.emailLogoUrl;
  const logo = await fetchImage(logoUrl);
  const builder = initPDFBuilder({
    header: defaultHeader(assetName, logo),
    content: [],
  });
  builder.addHeader(`${itemName} Log`);
  builder.addTable(
    headers,
    orderBy(items, (item) => item.number, ['desc']),
  );

  await builder.print();
};
export const saveCOLogAsPDF = async ({
  assetName,
  changeOrders,
}: {
  assetName: string;
  changeOrders: IChangeOrder[];
}) => {
  await saveItemLogAsPDF({
    assetName,
    items: changeOrders,
    itemName: 'Change Order',
    headers: CO_HEADERS,
  });
};
export const saveCELogAsPDF = async ({
  assetName,
  changeOrderEvents,
}: {
  assetName: string;
  changeOrderEvents: IChangeOrderEvent[];
}) => {
  await saveItemLogAsPDF({
    assetName,
    items: changeOrderEvents,
    itemName: 'Change Event',
    headers: CE_HEADERS_WITH_REASONS,
  });
};
export const saveReallocationLogAsPDF = async ({
  assetName,
  reallocations,
}: {
  assetName: string;
  reallocations: IReallocation[];
}) => {
  await saveItemLogAsPDF({
    assetName,
    items: reallocations,
    itemName: 'Reallocation',
    headers: REALLOCATION_HEADERS,
  });
};

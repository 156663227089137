import { updateLegalEntity } from '@/bundles/Settings/actions/legalEntity';
import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import { SourceUnitType } from '@/bundles/Shared/entities/sreUnitType';
import { settingsReportOperationalChargeCodesEnhancedApi } from '@/entities/report/chargeCodes';
import { settingsReportOperationalUnitStatusesEnhancedApi } from '@/entities/report/unitStatuses/api';
import { settingsReportOperationalUnitTypesEnhancedApi } from '@/entities/report/unitTypes';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { LegalEntity } from '@/entities/core/legalEntity';

const ENTITY_MAP = {
  'unit-types': {
    tags: ['SourceUnitTypes', 'SreUnitTypes'],
    api: settingsReportOperationalUnitTypesEnhancedApi,
  },
  'unit-statuses': {
    tags: ['SourceUnitStatuses'],
    api: settingsReportOperationalUnitStatusesEnhancedApi,
  },
  'charge-codes': {
    tags: ['SourceChargeCodes'],
    api: settingsReportOperationalChargeCodesEnhancedApi,
  },
} as const;

export function useLEClassUpdateAndInvalidateEntity(
  entityKey: keyof typeof ENTITY_MAP,
) {
  const dispatch = useAppDispatch();

  const onSetClassification = async (
    classification: LeClassification,
    unitType: SourceUnitType,
  ) => {
    await updateLegalEntity({
      id: unitType.legalEntity.id,
      classification,
    });
    const entity = ENTITY_MAP[entityKey];
    dispatch(entity.api.util.invalidateTags(entity.tags));
  };

  return onSetClassification;
}

export function filterWithLEClass<
  T extends { legalEntity: Pick<LegalEntity, 'classification'> },
>(items: T[]): T[] {
  return items.filter((i) => i.legalEntity.classification != null);
}

import React from 'react';
import {
  IFilterComponentProps,
  IRowBase,
} from 'bundles/Shared/components/Table/types';
import { TFireStationPriority } from 'bundles/FireStation/types';
import UserFireStationFilterButton from 'bundles/Shared/components/Filters/buttons/userFireStationFilterButton/UserFireStationFilterButton';

interface Props<R extends IRowBase> extends IFilterComponentProps<R> {
  fireFilterList: {
    count: number;
    priority: TFireStationPriority;
    filterModelField: string;
  }[];
  hidden?: boolean;
}

function FireFilters<R extends IRowBase>({
  fireFilterList,
  filterModel,
  setFilterModel,
  hidden,
}: Props<R>) {
  if (hidden) return null;

  const item = fireFilterList.reduce((curr, next) => {
    if (next.priority === TFireStationPriority.HIGH && Boolean(next.count)) {
      return next;
    }
    return curr;
  });
  const { count, filterModelField, priority } = item;

  if (count === 0) return null;

  return (
    <UserFireStationFilterButton
      priority={priority}
      filtered={filterModel[filterModelField]}
      onClick={() => {
        setFilterModel({
          ...filterModel,
          [filterModelField]: true,
        });
      }}
      onClose={() => {
        setFilterModel({
          ...filterModel,
          [filterModelField]: undefined,
        });
      }}
    >
      {count}
    </UserFireStationFilterButton>
  );
}

export default FireFilters;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'stories';
import SearchableMultiSelect from '../../../Shared/components/SearchableMultiSelect';

const TagForm = ({ action, currentTag, fields, setValue }) => {
  const basicInfoParams = [
    { name: 'name', title: 'Name', placeholder: 'Enter Name' },
    { name: 'reason', title: 'Reason', placeholder: 'Enter Reason' },
    { name: 'users', title: 'Members' },
  ];

  const getUsersOptions = (usersList) => (
    usersList.map(({ fullName, role, id }) => ({ label: `${fullName} - ${role?.name}`, value: id }))
  );

  const [users, setUsers] = useState(getUsersOptions(currentTag?.users || []));

  return (
    <div className="flex flex-col gap-tw-4">
      {basicInfoParams.map((param) => (
        <React.Fragment key={param.name}>
          {param.name !== 'users' && (
            <label className="w-full">
              <span>{param.title}</span>
              <Input
                size="l"
                required
                classes={{ input: '' }}
                onChange={(e) => setValue(param.name, e.target.value)}
                placeholder={param.placeholder}
                value={fields[param.name]}
              />
            </label>
          )}

          {action !== 'Create' && param.name === 'users' && (
            <label className="w-full">
              <span>{param.title}</span>

              <SearchableMultiSelect
                params={{ exclude_by_role_name: 'symmetRE Admin' }}
                url="/users"
                value={users}
                setValue={(options) => {
                  setValue('user_ids', options.map(({ value }) => value));
                  setUsers(options);
                }}
                getOptions={getUsersOptions}
              />
            </label>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

TagForm.propTypes = {
  fields: PropTypes.shape().isRequired,
  setValue: PropTypes.func.isRequired,
};

export default TagForm;

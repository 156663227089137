import { plainPreviewReportPdf } from '@/entities/report/reportBuilder/lib/plainPreviewReportPdf';
import {
  useFetchPreviewPdf,
  usePdfBlobCreateObjectURL,
} from '@/entities/report/reportBuilder/lib/previewPdf';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import { useParams } from '@reach/router';

export const useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery =
  () => {
    const params = useParams();
    const assetId = useAssetIdSearchParam();
    const hostedUrl = useHostedUrl();
    const pendingRequestIds = useAppSelector(
      (s) => s.reportBuilder.pendingRequestIds,
    );

    const { isLoading, pdfBlob } = useFetchPreviewPdf({
      effectDeps: [pendingRequestIds],
      waitIfCallback: () =>
        pendingRequestIds.length !== 0 || assetId == null || params.id == null,
      fetchCallback: async () => {
        return await plainPreviewReportPdf({
          assetId: assetId!,
          renderPdfHost: hostedUrl,
          reportId: params.id,
        });
      },
    });

    const dataObjectURL = usePdfBlobCreateObjectURL(pdfBlob);

    return {
      dataObjectURL,
      data: pdfBlob,
      isLoading,
    };
  };

import { getClosestUnitPrice } from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import { ContributionForm } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useAddManualContributionSettingsModal';
import { useCapitalUnitPricesByInvestmentClass } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { parseIntOrFloat } from '@/shared/lib/formatting/number';
import { exists } from '@/lib/typeHelpers';
import { useCallback } from 'react';
import { convertCentsToDollars } from '@/shared/lib/converters';

export function useUnitPriceFieldFlags() {
  const { capitalUnitPricesPartitionByInvestmentClass } =
    useCapitalUnitPricesByInvestmentClass({
      investmentClass: 'a',
    });

  const [unitPricesClassA, unitPricesClassB] =
    capitalUnitPricesPartitionByInvestmentClass;

  const resolveUnitPriceFieldFlags = useCallback(
    ({
      form,
      hasNoCommitmentsOptions,
    }: {
      form: ContributionForm;
      hasNoCommitmentsOptions: boolean;
    }) => {
      const useSignedDateForUnitPriceDate =
        !hasNoCommitmentsOptions && form.investmentClass === 'b';

      const unitPriceDate = useSignedDateForUnitPriceDate
        ? form.signedDate
        : form.date;

      const unitsDisabledToChange = !exists(unitPriceDate);
      const unitPricesByInvestmentClass =
        form.investmentClass === 'a' ? unitPricesClassA : unitPricesClassB;

      const hasUnitPrices = unitPricesByInvestmentClass.length > 0;

      const noAvailableUnitPricesByInvestmentClass =
        unitPricesByInvestmentClass.length === 0;

      let closestUnitPrice;

      if (form.selectedUnitPrice) {
        closestUnitPrice = convertCentsToDollars(form.selectedUnitPrice.priceCents);
      } else {
        closestUnitPrice =
          exists(unitPriceDate) && hasUnitPrices
            ? convertCentsToDollars(
                getClosestUnitPrice({
                  date: unitPriceDate,
                  unitPrices: unitPricesByInvestmentClass,
                }).priceCents,
              )
            : null;
      }

      const isClosestUnitPriceZero = closestUnitPrice === 0;

      const resolveValue = () => {
        if (isClosestUnitPriceZero) return form.unitsQuantity;

        return form.amount && closestUnitPrice
          ? parseIntOrFloat((Number(form.amount) ?? 0) / closestUnitPrice)
          : undefined;
      };

      return {
        closestUnitPrice,
        useSignedDateForUnitPriceDate,
        value: resolveValue(),
        unitsDisabledToChange,
        noAvailableUnitPricesByInvestmentClass,
        isClosestUnitPriceZero,
      };
    },
    [],
  );

  return resolveUnitPriceFieldFlags;
}

import { cn } from '@/shared/lib/css/cn';
import { sortBy } from 'lodash-es';
import { Tumbler } from 'stories';
import {
  IProduct,
  IProductPermissionKeys,
  IUserRole,
  TUserRoleCategory,
} from 'types/User';
import { PERMISSIONS_TABLE, PermissionTableHeaderJSX } from '../constants';
import { camelCaseToCapitalizedWords } from '../utils';
import styles from './styles.module.scss';

interface Props {
  roleDataState: IUserRole | Omit<IUserRole, 'id' | 'users'>;
  actions: {
    handleChangedState: () => void;
    resetState: () => void;
    toggleDataPermission: (
      key: IProductPermissionKeys,
      id: IProduct['id'],
    ) => void;
  };
  requiredMark?: boolean;
  readonly?: boolean;
  isSaving?: boolean;
}

export function RolePermissions({
  actions,
  roleDataState,
  requiredMark,
  readonly,
  isSaving,
}: Props) {
  return (
    <div data-name="permissions" className="mb-auto">
      <div className="mb-tw-2 flex items-center justify-between">
        <span className="inline-semibold text-light-90">
          Permissions{requiredMark && <span className="red"> *</span>}
        </span>
      </div>

      <div className="overflow-hidden rounded-[8px]">
        <div className={cn('h-[3rem]', styles.row)}>
          {PermissionTableHeaderJSX}
        </div>

        <div className="grid gap">
          {sortBy(roleDataState.products, ['name']).map((product) => (
            <div key={product.id} className={styles.row}>
              <div className="inline-regular flex h-[3.5rem] items-center pl-tw-4 text-dark-60">
                {camelCaseToCapitalizedWords(product.name)}
              </div>
              {PERMISSIONS_TABLE.map(({ key }) => (
                <div
                  key={key}
                  className="inline-regular flex h-[3.5rem] items-center justify-center text-dark-60"
                >
                  <div>
                    <Tumbler
                      disabled={
                        readonly ||
                        (roleDataState.category ===
                          TUserRoleCategory.EXTERNAL &&
                          key !== 'canView') ||
                        // TODO: add this condition to the backend
                        (roleDataState.category ===
                          TUserRoleCategory.EXTERNAL &&
                          product.name == 'return')
                      }
                      classes={{ input: 'sre-tumbler__input_green' }}
                      onChange={() =>
                        actions.toggleDataPermission(key, product.id)
                      }
                      checked={isSaving ? false : product[key]}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import http, { apiClient, requireAuthorizationHeaders } from 'lib/http';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import processResponse from '../../../../lib/processResponse';

export const FETCH_USERS = 'symmetre-client-api/FETCH_USERS';
export const UPDATE_USER = 'symmetre-client-api/UPDATE_USER';

export async function createUser(data) {
  const res = await http.post('/users', data);
  return processResponse(res, 'Create User', 'You can now send their invitation link to join when ready');
}

export async function deactivateUser(data) {
  const res = await http.post('/users/deactivate-toggle', data);
  processResponse(res, 'Deactivate User', 'User has been successfully activated/deactivated');
}

export async function inviteUser(data) {
  const res = await http.post('/users/invite', data);
  processResponse(res, 'Invite User', 'Invite has been sent');
}

export async function updateUser(data) {
  const res = await http.put(`/users/${data.id}`, data);
  processResponse(res, 'Update User', 'User has been successfully updated');
}

export async function exportUsers() {
  const res = await http.post('/users/export.xlsx');
  const file = await res.blob();
  saveFileOnUserDevice(
    file,
    `${window.symmetreConfig.customer.subdomain}-users.xlsx`,
  );
}

export async function importUsers(data) {
  const res = await apiClient.post(
    'api/users/import',
    data,
    { headers: requireAuthorizationHeaders({ 'Content-Type': 'multipart/form-data' }) },
  );

  const responseData = res.data;

  if (responseData.errors) {
    toastr.warning(`${responseData.errors}\n`);
  } else {
    toastr.success('Users have been successfully imported');
  }

  return responseData;
}

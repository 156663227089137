import { cn } from '@/shared/lib/css/cn';
import { formatDate } from '@/shared/lib/formatting/dates';
import { Icon, Tooltip } from '@/stories';
import { ClassNameProps } from '@/types/Props';
import hintImg from 'images/reconcile/underwriting/no-budget-hint.png';

export const ADD_BUDGET_HINT_JSX = (
  <div className="flex grow flex-col items-center justify-center gap-tw-6">
    <p className="header5-regular text-neutral-500">
      To create an underwriting budget, use the plus icon at the top right
    </p>
    <img alt="hint" className="w-[348px]" src={hintImg} />
  </div>
);

export const BUDGET_DATE_FROM_AND_FINANCIAL_FIRST_PERIOD_MISMATCHED_TOOLTIP =
  'Financial data and UW Budget data do not have the same start date';

export const BudgetDateFromAndFinancialFirstPeriodFireIcon = (
  props: ClassNameProps & {
    budgetDateFrom?: DateString | null | undefined;
    financialFirstPeriod?: DateString | null | undefined;
  },
) => (
  <Tooltip
    theme="light"
    mainText={
      <div className="flex flex-col gap-tw-2">
        <p className="secondary-regular text-neutral-850">
          {BUDGET_DATE_FROM_AND_FINANCIAL_FIRST_PERIOD_MISMATCHED_TOOLTIP}
        </p>

        {(props.budgetDateFrom ?? props.financialFirstPeriod) && (
          <ul className="flex flex-col gap-tw-0.5">
            {props.financialFirstPeriod && (
              <li className="flex gap-tw-1">
                <p className="secondary-regular text-neutral-550">
                  Financial Data:
                </p>
                <p className="secondary-regular text-neutral-850">
                  {formatDate(props.financialFirstPeriod, 'MMM-YY')}
                </p>
              </li>
            )}
            {props.budgetDateFrom && (
              <li className="flex gap-tw-1">
                <p className="secondary-regular text-neutral-550">
                  UW Budget data:
                </p>
                <p className="secondary-regular text-neutral-850">
                  {formatDate(props.budgetDateFrom, 'MMM-YY')}
                </p>
              </li>
            )}
          </ul>
        )}
      </div>
    }
  >
    <Icon iconName="fire" className={cn('text-danger-070', props.className)} />
  </Tooltip>
);

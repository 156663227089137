import React from 'react';
import { CssVar } from '@/shared/config/cssVar';

export const Card = ({
  label,
  children,
}: React.PropsWithChildren<{ label: React.ReactNode }>) => (
  <div
    className="p-m flex flex-col justify-between flex-grow rounded-[1rem]
                  gap-m border border-light-10
                  bg-light
                  "
  >
    <div
      style={{
        height: CssVar.spacingL,
      }}
      className="flex justify-between items-center"
    >
      {children}
    </div>
    {label}
  </div>
);

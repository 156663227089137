import { useAppSelector } from '@/shared/lib/hooks/redux';
import { typedLocalStorage } from '@/shared/lib/localStorage';
import { useEffect } from 'react';

export const useSubscribePeriodItemsToLocalStorage = () => {
  const periodItems = useAppSelector((state) => state.financials.periodItems);

  useEffect(() => {
    typedLocalStorage.setItem(
      'reportFinancialsDatePickerPeriodItems',
      periodItems,
    );
  }, [periodItems]);
};

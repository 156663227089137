import React, { useState } from 'react';
import { Dropdown, DropdownItem, Button } from 'stories';

const CardScopeDropdown = ({
  onChange, items, color = 'secondary', className, defaultValue, right,
}) => {
  const [selected, setSelected] = useState(defaultValue || items[0]);

  const handleChange = (item) => {
    setSelected(item);
    onChange(item);
  };

  return (
    <Dropdown
      items={(
        <>
          {items.filter((item) => item !== selected).map((item) => (
            <DropdownItem key={item} onClick={() => handleChange(item)}>{item}</DropdownItem>
          ))}
        </>
      )}
    >
      <Button
        variant={color}
        iconPosition="right"
        iconName="arrowBottom"
        className={className}
      >
        {selected}
      </Button>
    </Dropdown>
  );
};

export default CardScopeDropdown;

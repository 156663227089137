import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { FieldsManageable, FieldsWithStatusBadge } from 'stories';
import { IPipelineCard } from 'bundles/Settings/components/DealPath/PipelineCards';
import { IOption } from 'types/Option';
import { sortBy } from 'lodash-es';
import {
  fetchPipelineCard,
  fetchPipelineCards,
  updateStaticCard,
} from 'bundles/Scoreboard/Pipeline/actions';
import { colors } from 'bundles/Scoreboard/Pipeline/components/chartUtils';
import { StaticCardField } from 'bundles/Scoreboard/Pipeline/types';

const Snapshot = () => {
  const dispatch = useAppDispatch();
  const propertyTypes = useAppSelector((state) => state.deals.propertyTypes);
  const pipelineCards = useAppSelector((state) => state.deals.pipelineCards);

  useEffect(() => {
    dispatch(fetchPipelineCards(['snapshot', 'static-card']));
  }, []);

  const handleChange = (card: IPipelineCard, value: IOption) => {
    dispatch(fetchPipelineCard(card, value.label));
  };

  const handleUpdateCard = async (
    cardId: string,
    fields: StaticCardField[],
  ) => {
    const reqBody = {
      pipeline_card: {
        schema: fields,
      },
    };
    await dispatch(updateStaticCard({ id: cardId }, reqBody));
  };

  const options = useMemo(
    () =>
      propertyTypes.map((item, index) => ({
        label: item,
        key: item.replace(' ', '_').toLowerCase(),
        color: colors[index % colors.length],
      })),
    [propertyTypes],
  );

  return (
    <div className="grid grid-cols-4 gap-m">
      {pipelineCards &&
        sortBy(pipelineCards, 'position').map((card) =>
          card.cardType === 'snapshot' ? (
            <FieldsWithStatusBadge
              key={card.id}
              initFields={card.data ?? []}
              onChange={(value) => handleChange(card, value)}
              options={[
                { key: 'all', color: 'blue', label: 'Consolidated' },
                ...options,
              ]}
            />
          ) : (
            <FieldsManageable
              key={card.id}
              initFields={card.data ?? []}
              onChange={(fields) => handleUpdateCard(card.id, fields)}
            />
          ),
        )}
    </div>
  );
};

export default Snapshot;

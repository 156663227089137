import { useNavigate } from '@reach/router';
import { LEGAL_ENTITIABLES_TABS } from 'bundles/Settings/components/Portal/shared/consts';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { ThinTabGroup } from 'stories/index';

export function LegalEntitiablesThinTabGroup({
  selectedItemId,
}: {
  selectedItemId?: 'assets' | 'funds';
}) {
  const navigate = useNavigate();

  return (
    <ThinTabGroup
      className="grid grid-cols-2 justify-center"
      classes={{ item: 'justify-center w-full' }}
      selectedItem={selectedItemId}
      onSelectedItemChange={({ id }) => {
        if (id === selectedItemId) return;
        navigate(
          id === 'assets'
            ? ROUTES_ROOT.assets.fullPath
            : ROUTES_ROOT.funds.fullPath,
        );
      }}
      items={LEGAL_ENTITIABLES_TABS}
    />
  );
}

import React from 'react';
import { IconButton } from 'stories';
import { cn } from '@/shared/lib/css/cn';

interface Props
  extends Omit<React.ComponentProps<typeof IconButton>, 'iconName'> {
  selected?: boolean;
}

function FavoriteIconButton({ selected, className, ...props }: Props) {
  return (
    <IconButton
      className={cn(
        selected ? 'text-yellow-dark-2' : 'text-light-60',
        className,
      )}
      iconName={selected ? 'favoriteFilled' : 'favoriteUnfiled'}
      variant="secondary"
      size="l"
      {...props}
    />
  );
}

export default FavoriteIconButton;

import React, { forwardRef } from 'react';

const TooltipArrow = () => (
  <div className="sre-popover__arrow" data-popper-arrow>
    <svg width="13" height="4" viewBox="0 0 13 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M.085 0H12.915c-.606 0-1.208.098-1.783.29a2.82 2.82 0 0 0-1.102.68L7.913 3.087a2 2 0 0 1-2.828 0L2.97.97A2.82 2.82 0 0 0 1.868.289 5.64 5.64 0 0 0 .085 0z" fill="#0D1113" />
    </svg>
  </div>
);

export default TooltipArrow;

import http from 'lib/http';
import { PropertiesCamelToSnakeCase } from 'types/StringUtilityTypes';
import type * as Type from '../components/EmailSettings/editor/types';
import { buildTemplateAttributes } from './utils/templateParams/buildTemplateAttributes';

export interface IForgotPasswordTemplateParams {
  forgotPasswordTemplateId: Type.ForgotPasswordTemplate['id'] | undefined;
  template: Type.ForgotPasswordTemplateState;
  usedVariables: Type.ForgotPasswordTemplateVars;
}

const buildForgotPasswordTemplateParams = ({
  forgotPasswordTemplateId,
  template,
  usedVariables,
}: IForgotPasswordTemplateParams) => ({
  id: forgotPasswordTemplateId,
  forgot_password_template: {
    greeting_attributes: buildTemplateAttributes({
      content: template?.greeting,
      vars: usedVariables.greeting,
    }),
    call_to_action_attributes: buildTemplateAttributes({
      content: template?.callToAction,
      vars: usedVariables.callToAction,
    }),
    body_attributes: buildTemplateAttributes({
      content: template?.body,
      vars: usedVariables.body,
    }),
    additional_info_attributes: buildTemplateAttributes({
      content: template.additionalInfo,
      vars: usedVariables.additionalInfo,
      withDestroy: true,
    }),
    template_attributes: {
      subject: template?.subject,
      reply_recipient_ids: template.replyRecipients?.map((re) => re.id) ?? [],
    },
  },
});

export async function forgotPasswordTemplatePreviews(
  params: IForgotPasswordTemplateParams,
) {
  const result = await http.post(
    '/forgot_password_template_previews',
    buildForgotPasswordTemplateParams(params),
  );
  return result.json();
}

export async function getForgotPasswordTemplateVariables() {
  const result = await http.get('/forgot_password_template_variables');

  return result.json() as Promise<
    PropertiesCamelToSnakeCase<Type.ForgotPasswordTemplateVars>
  >;
}

export async function getForgotPasswordTemplate(id: number) {
  const result = await http.get(`/forgot_password_templates/${id}`);

  return result.json() as Promise<Type.ForgotPasswordTemplate>;
}

export async function createForgotPasswordTemplate(
  params: IForgotPasswordTemplateParams,
) {
  const res = await http.post(
    '/forgot_password_templates',
    buildForgotPasswordTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Template has been successfully created');
  }

  return responseData;
}

export async function updateForgotPasswordTemplate(
  params: IForgotPasswordTemplateParams,
) {
  const res = await http.put(
    `/forgot_password_templates/${params.forgotPasswordTemplateId}`,
    buildForgotPasswordTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Template has been successfully updated');
  }

  return responseData;
}

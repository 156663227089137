import { BaseFinancialTableWidgetConfigBaseColumnDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  ADJUSTMENT_EXPRESSION_ONE_SCHEMA,
  ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  ADJUSTMENT_TYPE_ONE_SCHEMA,
  ADJUSTMENT_TYPE_VALUE_TOTAL,
  PERIOD_SHIFT_SCHEMA,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { DEFAULT_VALUE_DISPLAY_OPTIONS } from '@/shared/lib/formatting/displayOptions';
import { transformPeriodShiftDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { COLUMN_SETTINGS_FORM_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import * as yup from 'yup';

export const FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA =
  COLUMN_SETTINGS_FORM_SCHEMA.shape({
    key: yup.number(),
    label: yup.string().required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable().optional(),
    adjustment_expression:
      ADJUSTMENT_EXPRESSION_ONE_SCHEMA.nullable().optional(),
    adjustment_type: ADJUSTMENT_TYPE_ONE_SCHEMA.nullable().optional(),
  });

export type FinancialTableWidgetConfigColumnForm = yup.InferType<
  typeof FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const transformFinancialTableWidgetColumnConfigToForm = (
  columnConfig: BaseFinancialTableWidgetConfigBaseColumnDto,
): Pick<
  FinancialTableWidgetConfigColumnForm,
  | 'adjustment_expression'
  | 'adjustment_type'
  | 'key'
  | 'period_shift'
  | 'value_display_options'
  | 'header'
> => ({
  key: columnConfig.key,
  label: columnConfig.label,
  value_display_options:
    columnConfig.value_display_options ?? DEFAULT_VALUE_DISPLAY_OPTIONS,
  period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
  header: columnConfig.header,
  // @ts-expect-error Not all options "adjustment_expression" are opt in
  adjustment_expression:
    columnConfig.adjustment_expression_name ??
    ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  adjustment_type:
    columnConfig.adjustment_average_per ?? ADJUSTMENT_TYPE_VALUE_TOTAL,
});

export const transformFinancialTableWidgetAdjustmentFormToConfig = (
  fields: Pick<
    FinancialTableWidgetConfigColumnForm,
    'adjustment_expression' | 'adjustment_type'
  >,
): Pick<
  BaseFinancialTableWidgetConfigBaseColumnDto,
  'adjustment_average_per' | 'adjustment_expression_name'
> => ({
  // @ts-expect-error Not all options "adjustment_expression" are opt in
  adjustment_expression_name:
    fields.adjustment_expression === ADJUSTMENT_EXPRESSION_VALUE_ACTUAL
      ? undefined
      : fields.adjustment_expression,
  adjustment_average_per:
    fields.adjustment_type === ADJUSTMENT_TYPE_VALUE_TOTAL
      ? undefined
      : fields.adjustment_type ?? undefined,
});

export const DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN: Partial<FinancialTableWidgetConfigColumnForm> =
  {
    adjustment_expression: ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
    adjustment_type: ADJUSTMENT_TYPE_VALUE_TOTAL,
    value_display_options: DEFAULT_VALUE_DISPLAY_OPTIONS,
    period_shift: {
      key: 'weeks',
      value: 0,
    },
  };

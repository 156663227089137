import { cn } from '@/shared/lib/css/cn';
import { debounce } from 'lodash-es';
import React, { useCallback, useLayoutEffect } from 'react';
import { Icon, Tooltip } from 'stories';
import '../inputs.css';

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'size'
  > {
  resetValue?: (value: string) => void;
  status?: 'success' | 'error';
  size?: 's' | 'm' | 'l';
  onSearch?: () => void;
  tooltipProps?: React.ComponentProps<typeof Tooltip>;
  suggestions?: string[];
  inputClassName?: string;
  isSearching?: boolean;
  delay?: number;
}

const ListOfSuggestions = ({ suggestions }: { suggestions: string[] }) => (
  <div className="flex flex-col">
    <p className="secondary-semibold text-neutral-050">You can search by:</p>
    <ul className="secondary-regular list-disc pl-tw-4 text-neutral-050">
      {suggestions.map((suggestion) => (
        <li key={suggestion} className="">
          {suggestion}
        </li>
      ))}
    </ul>
  </div>
);

export const SearchInput = React.forwardRef<
  HTMLInputElement,
  Omit<Props, 'ref'>
>(
  (
    {
      size = 'm',
      resetValue,
      onSearch,
      className,
      value,
      onChange,
      status,
      placeholder,
      suggestions,
      tooltipProps,
      inputClassName,
      isSearching = false,
      delay = 0,
      ...props
    },
    ref,
  ) => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (value) {
        if (e.key === 'Escape') {
          resetValue?.('');
        } else if (e.key === 'Enter') {
          onSearch?.();
        }
      }
    };

    useLayoutEffect(() => {
      document.addEventListener('keydown', keyDownListener);
      return () => {
        document.removeEventListener('keydown', keyDownListener);
      };
    });

    // @ts-ignore
    const debouncedOnChange = useCallback(debounce(onChange, delay), [delay]);
    const [query, setQuery] = React.useState(value);

    const setInputQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
      debouncedOnChange(e);
    };

    return (
      <div
        className={cn(
          `sre-input sre-input_search sre-input_size-${size}`,
          className,
        )}
      >
        <Tooltip
          arrowPosition="start"
          placement="bottom-start"
          trigger="click"
          mainText={<ListOfSuggestions suggestions={suggestions ?? []} />}
          disabled={(suggestions?.length ?? 0) === 0}
          classes={{
            spanContainer: 'flex',
          }}
          {...tooltipProps}
        >
          <input
            className={cn(
              'sre-input__field',
              { [`sre-input__field_${status ?? ''}`]: status },
              inputClassName,
            )}
            placeholder={placeholder}
            onChange={setInputQuery}
            value={query}
            ref={ref}
            {...props}
          />
          <div className="sre-input__back-layer">
            <div className="sre-input__search-icons">
              {value && resetValue && (
                <Icon
                  className="sre-input__icon-close"
                  iconName="close"
                  onClick={() => {
                    setQuery('');
                    resetValue?.('');
                  }}
                />
              )}
              <Icon
                className={cn(
                  'sre-input__icon-search',
                  isSearching && 'animate-spin',
                )}
                iconName={isSearching ? 'loader' : 'search'}
                onClick={value ? onSearch : undefined}
              />
            </div>
          </div>
        </Tooltip>
      </div>
    );
  },
);

export default SearchInput;

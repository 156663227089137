import dayjs, { Dayjs } from 'dayjs';
import { DAYS_IN_WEEK } from 'stories/FlexibleFilterByPeriods/consts';
import weekDay from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);
dayjs.extend(weekDay);

export const getRowKey = (rowIndex: number) => `row-${rowIndex}`;
export const getCellKey = (rowIndex: number, cellIndex: number) =>
  `cell-${rowIndex}-${cellIndex}`;
export const getRowIndexFromCellKey = (cellKey: string) =>
  Number(cellKey.split('-')[1]);

export const getCurrentStartOfMonthByValue = (
  value: Dayjs | Dayjs[],
): Dayjs => {
  if (Array.isArray(value)) {
    return value[0].startOf('month');
  }
  return value.startOf('month');
};

export const generateDatesFrom = (start: Dayjs, end: Dayjs) => {
  if (start.isSame(end, 'date')) {
    return [start];
  }
  if (!start.isBefore(end)) {
    throw Error('start date must be before end date');
  }
  const resultArr: Dayjs[] = [];
  let currentDate = start;
  while (!currentDate.isSame(end.add(1, 'day'), 'date')) {
    resultArr.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }
  return resultArr;
};

export const getDatesArrayForCurrentMonth = (startOfMonth: Dayjs) => {
  const endOfMonth = startOfMonth.endOf('month');

  const previousMonthWeekDates =
    startOfMonth.weekday() === 0
      ? []
      : generateDatesFrom(
          startOfMonth.startOf('week'),
          startOfMonth.subtract(1),
        );

  const currentMonthWeekDates = generateDatesFrom(startOfMonth, endOfMonth);

  const nextMonthWeekDates =
    DAYS_IN_WEEK - 1 === endOfMonth.weekday()
      ? []
      : generateDatesFrom(endOfMonth.add(1, 'day'), endOfMonth.endOf('week'));

  return [
    ...previousMonthWeekDates,
    ...currentMonthWeekDates,
    ...nextMonthWeekDates,
  ];
};

export const getWeekDaysRange = () =>
  Array.from({ length: DAYS_IN_WEEK }).map((_, index) =>
    dayjs().weekday(index).weekday(),
  );

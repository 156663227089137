import {
  IReconcileSettingsCompany,
  TGetCompaniesRequestParams,
} from 'bundles/REconcile/actions/companies';
import { useMemo } from 'react';
import {
  DEFAULT_CODE_COLUMN,
  DEFAULT_NAME_COLUMN,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants/columns';
import { IColumn } from 'bundles/Shared/components/Table/types';

interface Params {
  params: TGetCompaniesRequestParams;
  setParams: React.Dispatch<React.SetStateAction<TGetCompaniesRequestParams>>;
}

export function useCompaniesCrudColumns({ setParams }: Params) {
  return useMemo<IColumn<IReconcileSettingsCompany>[]>(
    () => [
      DEFAULT_CODE_COLUMN,
      {
        ...DEFAULT_NAME_COLUMN,
        text: 'Company Name',
      },
    ],
    [],
  );
}

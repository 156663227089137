import { cn } from '@/shared/lib/css/cn';
import React from 'react';
import { ClassNameProps } from 'types/Props';
import styles from './DashboardSection.module.scss';

interface Props extends ClassNameProps {
  id: string;
  color?: string | null;
  panel?: React.ReactNode;
}

export const DashboardSection = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(({ color, panel, children, className, id }, ref) => (
  <div
    id={`section-${id}`}
    ref={ref}
    style={
      color
        ? ({ ['--container-border-color']: color } as React.CSSProperties)
        : undefined
    }
    className={cn(
      styles.container,
      color && styles.container_has_color,
      panel && styles.container_has_panel,
      'hover:shadow-neutral-550',
      className,
    )}
  >
    {panel && <div className={styles.panel}>{panel}</div>}
    {children}
  </div>
));

import { GridReadyEvent, RowGroupOpenedEvent } from 'ag-grid-community';
import {
  autoSizeGroupColumnHandler,
  expandOnClickHandler,
  redrawNodeOnExpandHandler,
} from 'lib/ag-grid/utils';
import { useCallback } from 'react';

type SizeColumnsToFitOptions = {
  afterColumnsChanged?: boolean;
};

interface GridDefaultParams {
  enableExpandOnClick?: boolean;
  autosizeGroupColumnOnExpand?: boolean;
  redrawNodeOnExpand?: boolean;
  sizeColumnsToFit?: boolean | SizeColumnsToFitOptions;
}

/**
 * @param sizeColumnsToFit - flag prop plus extendable with options `SizeColumnsToFitOptions`
 */

export function useGridDefaultParams(params: GridDefaultParams) {
  const onGridReady = useCallback((e: GridReadyEvent) => {
    if (params.enableExpandOnClick) {
      e.api.addEventListener('cellClicked', expandOnClickHandler);
    }
    if (params.autosizeGroupColumnOnExpand || params.redrawNodeOnExpand) {
      const rowGroupOpenedHandler = (roe: RowGroupOpenedEvent) => {
        params.autosizeGroupColumnOnExpand && autoSizeGroupColumnHandler(roe);
        params.redrawNodeOnExpand && redrawNodeOnExpandHandler(roe);
      };
      e.api.addEventListener('rowGroupOpened', rowGroupOpenedHandler);
    }

    if (params.sizeColumnsToFit) {
      e.api.sizeColumnsToFit();

      if (
        typeof params.sizeColumnsToFit === 'object' &&
        params.sizeColumnsToFit.afterColumnsChanged
      ) {
        e.api.addEventListener('gridColumnsChanged', () => {
          e.api.sizeColumnsToFit();
        });
      }
    }
  }, []);

  return {
    onGridReady,
  };
}

import React, { ComponentProps } from 'react';
import { IconButton } from 'stories';
import { ClassNameProps } from 'types/Props';
import { cn } from '@/shared/lib/css/cn';

interface Props
  extends ClassNameProps,
    Omit<ComponentProps<typeof IconButton>, 'iconName'> {
  selected?: boolean;
}

function FavoriteCardIcon({ selected, className, ...props }: Props) {
  return (
    <IconButton
      className={cn(
        selected ? 'text-yellow-dark-2' : 'text-light-60',
        className,
      )}
      variant="secondary"
      iconName={selected ? 'favoriteFilled' : 'favoriteUnfiled'}
      size="m"
      {...props}
    />
  );
}

export default FavoriteCardIcon;

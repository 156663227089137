import { useMemo } from 'react';
import {
  isRowGl,
  IRow,
  IRowDataValue,
  IRowBase,
} from 'bundles/Shared/components/ReportTable/types';
import { treeDF } from 'lib/treeHelpers';
import { calcTotalGlColValue } from '../helpers/calcTotalGlColValue';

export function useRowData({ rows }: { rows: IRow[] }) {
  return useMemo<IRowBase[]>(() => {
    const rowData: IRowBase[] = [];

    treeDF(rows, (row, path) => {
      const updatedRow = { ...row };

      if (isRowGl(updatedRow)) {
        const rowDataValueObjList = Object.values(updatedRow.data);

        const totalRowData: IRowDataValue = {
          active: false,
          value: calcTotalGlColValue(rowDataValueObjList),
        };

        const updatedValueObjList = [...rowDataValueObjList, totalRowData];

        const updatedRowData = Object.fromEntries(
          updatedValueObjList.map((item, idx) => [idx, item]),
        );

        updatedRow.data = updatedRowData;
      }

      rowData.push({
        ...updatedRow,
        path: path.map((p) => p.key),
      });
    });
    return rowData;
  }, [rows]);
}

import Table from 'bundles/Shared/components/Table/Table';
import { ComponentProps } from 'react';

export const SELECTION_COLUMN_CLASSES: ComponentProps<
  typeof Table
>['selectionColumn'] = {
  classes: {
    td: '!px-tw-2 !py-tw-4 !w-tw-8 bg-light',
    th: '!px-tw-2 !py-tw-3 !w-tw-8',
  },
};

import React, { useEffect, useState } from 'react';
import { ICompany } from 'bundles/Construction/types';
import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { useGetCompaniesQuery } from 'bundles/Construction/api/companies';

interface Props extends ISharedSelectProps {
  options?: {
    value: ICompany['id'] | ICompany['name'];
    label: ICompany['name'];
  }[];
  value?: ICompany['id'];
  disabled?: boolean;
  typeValue?: 'id' | 'name';
}

export const CompanySelect = ({
  onChange,
  disabled,
  options,
  value,
  typeValue = 'id',
}: Props) => {
  const [optionsCompany, setOptionCompany] = useState(options ?? []);

  const { data: companies } = useGetCompaniesQuery(
    {},
    {
      skip: Boolean(options),
    },
  );

  useEffect(() => {
    if (companies) {
      setOptionCompany(
        companies.map((company) => ({
          label: company.name,
          value: company[typeValue],
        })),
      );
    }
  }, [companies, typeValue]);

  return (
    <SharedSelect
      placeholder="Select Company"
      options={optionsCompany}
      onChange={onChange}
      value={optionsCompany.filter((opt) => opt.value === value)}
      disabled={disabled}
    />
  );
};

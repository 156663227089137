/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { capitalize, sortBy } from 'lodash-es';
import { FiltersList, InvestmentEntitiesList, RolesList, TagsList, UsersList } from '../../../../Shared/components/ModalWithSideTabs/Lists';
import FeedMessageFormStep from '../Posts/FeedMessageFormStep';
import EmailPreviewCell from './EmailPreviewCell';

const FeedMessageEmailRecipientsStep = ({
  permissionsApproved,
  permissions,
  setPermissions,
  emailBody,
  emailSubject,
  feedMessageId,
  replyRecipients,
  setReplyRecipients,
  selectedDocuments,
  selectedMedia,
  finalUsersList,
  setFinalUsersList,
  calculateFinalUsersList,
  filters,
  setFilters,
}) => {
  const onListItemClose = (allItems, item, type) => {
    const items = [...allItems];
    const itemIdx = items.findIndex(({ id }) => id === item.id);
    items[itemIdx] = { ...items[itemIdx], excluded: !items[itemIdx].excluded };
    const newPermissions = { ...permissions, [type]: items };
    setPermissions(newPermissions);

    const newFinalUsersList = calculateFinalUsersList(newPermissions, filters);
    setFinalUsersList(newFinalUsersList);
  };

  const autoDisableExcludedOnSelect = (items) => {
    const newFilters = [...filters];
    ['external', 'internal'].forEach((category) => {
      const allExcluded = items
        .filter((item) => item.role.category === category && !item.excluded).length === 0;

      const filterIndex = newFilters.findIndex(({ name }) => name === capitalize(category));

      if (!allExcluded) {
        newFilters[filterIndex] = { ...newFilters[filterIndex], excluded: allExcluded };
      }
    });

    setFilters(newFilters);
  };

  const applyFilter = (category, excluded) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex(({ name }) => name === category);
    newFilters[filterIndex] = { ...newFilters[filterIndex], excluded };
    setFilters(newFilters);
    setFinalUsersList(calculateFinalUsersList(permissions, newFilters));
  };

  const finalUsersListWithoutExcluded = finalUsersList.filter(({ excluded }) => !excluded);

  const buildEmailPreviewColumn = () => (
    {
      title: 'Email Preview',
      buildCell: (user) => (
        <EmailPreviewCell
          userId={user.id}
          feedMessageId={feedMessageId}
          emailBody={emailBody}
          subject={emailSubject}
          selectedDocuments={selectedDocuments}
          selectedMedia={selectedMedia}
        />
      ),
    }
  );

  return (
    <div className="flex items-start">
      <FeedMessageFormStep
        stepNumber={4}
        isActive={permissionsApproved && finalUsersListWithoutExcluded.length > 0}
        isWarning={!permissionsApproved || finalUsersListWithoutExcluded.length === 0}
        borderless
      />
      <div className="flex flex-col gap-tw-3 w-full">
        <h6 className="header6-regular dark-60 mb-s vertical-align-middle feed-message-step-title">
          Who will receive this email notification?{' '}
          <span className="red">*</span>
        </h6>
        <p className="red inline-regular">
          Applicable users are generated based on the docs and/or media you select in Step 3.
          <br />
          Please review the list below, edit if necessary, and confirm by selecting the final{' '}
          check box:
        </p>
        <FiltersList
          closable
          items={filters}
          onClose={(item) => applyFilter(item.name, !item.excluded)}
        />
        {permissions.roles.length > 0 && (
          <RolesList
            closable
            items={sortBy(permissions.roles, (item) => item.name)}
            onClose={(item) => onListItemClose(permissions.roles, item, 'roles')}
          />
        )}
        {permissions.tags.length > 0 && (
          <TagsList
            closable
            items={sortBy(permissions.tags, (item) => item.name)}
            onClose={(item) => onListItemClose(permissions.tags, item, 'tags')}
          />
        )}
        {permissions.investmentEntities.length > 0 && (
          <InvestmentEntitiesList
            closable
            items={sortBy(permissions.investmentEntities, (item) => item.name)}
            onClose={(item) => onListItemClose(permissions.investmentEntities, item, 'investmentEntities')}
          />
        )}
        {finalUsersList.length > 0 && (
          <UsersList
            classes=""
            members={finalUsersList}
            onToggle={(item) => {
              const items = [...finalUsersList];
              const itemIdx = items.findIndex(({ id }) => id === item.id);
              items[itemIdx] = { ...items[itemIdx], excluded: !items[itemIdx].excluded };
              autoDisableExcludedOnSelect(items);
              setFinalUsersList(items);
            }}
            customColumns={[buildEmailPreviewColumn()]}
          />
        )}
        {replyRecipients.length > 0 && (
          <UsersList
            classes="-mt-tw-4"
            title="Where to send replies?"
            members={replyRecipients}
            onToggle={(item) => {
              const items = [...replyRecipients];
              const itemIdx = items.findIndex(({ id }) => id === item.id);
              items[itemIdx] = { ...items[itemIdx], excluded: !items[itemIdx].excluded };
              setReplyRecipients(items);
            }}
          />
        )}
        {finalUsersListWithoutExcluded.length === 0 && (
          <>
            {finalUsersList.length === 0 && (
              <div className="mt-m">
                <h6 className="light-60 mb-[0.5rem]">
                  Users
                </h6>
              </div>
            )}
            <p className="red">No Users have been selected, please adjust filters above.</p>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedMessageEmailRecipientsStep;

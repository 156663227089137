import { snakeCaseKeys } from '@/bundles/Construction/components/helpers';
import { GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiArg } from '@/entities/report/chargeCodes/api/settingsReportOperationalChargeCodesGeneratedApi';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { exportFile } from '@/shared/lib/exportFile';

export const useSettingsReportChargeCodesExport = () => {
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleExport = (
    params: GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiArg,
  ) => {
    exportFile({
      params: snakeCaseKeys(params),
      url: '/settings/report/rent_roll/source_charge_codes/export.csv',
      filePostfix: 'charge-codes',
      enableLoading,
      disableLoading,
    });
  };

  return [handleExport, { isLoading }] as const;
};

import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { ICellRendererParams } from 'ag-grid-community';

export interface IUSDCurrencyFormatterProps {
  value: number;
  inheritColor?: boolean;
  emptyValue?: React.ReactNode;
  maximumFractionDigits?: number;
}
type Props = ICellRendererParams & IUSDCurrencyFormatterProps;

function USDCurrencyFormatter({
  value,
  inheritColor,
  emptyValue = null,
  maximumFractionDigits,
}: Props) {
  if (value == null) {
    return emptyValue;
  }
  const formattedAmount = Math.abs(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits ?? 0,
  });

  const withCurrency = (
    <>
      <span
        className={cn(
          'secondary-regular mr-tw-0.5',
          !inheritColor && 'light-60',
        )}
      >
        $
      </span>
      {formattedAmount}
    </>
  );

  return Math.sign(value) < 0 ? <>({withCurrency})</> : withCurrency;
}

export default USDCurrencyFormatter;

import { CurrencyCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';

export enum UWBRowType {
  RootSettings = 'root-settings',
  Divider = 'divider',
  CellEditor = 'cell-editor',
}

export const FINANCIAL_ROOT_ROW = {
  id: 0,
  key: '0',
  path: ['empty'],
  type: 'root-settings',
} as const;

export const METRICS_ROOT_ROW = {
  id: 'metrics',
  key: 'metrics',
  path: ['metrics-settings'],
  type: 'root-settings',
} as const;

export const DIVIDER_ROW = {
  id: 'divider',
  key: 'divider',
  path: ['empty-divider'],
  type: 'divider',
} as const;

export const DISABLED_CELL_RENDERER_PARAMS = {
  classes: {
    inner: CELL_CLASS_NAMES.CommonCell.inner.disabled,
    wrapper: CELL_CLASS_NAMES.CommonCell.wrapper.disabled,
  },
  formatterParams: {
    fallbackValue: '',
  },
} satisfies CurrencyCellRendererProps;

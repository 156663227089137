import React, { useMemo, useState } from 'react';
import {
  Button,
  CurrencyFormatter,
  Icon,
  IconButton,
  InlineObject,
  Tumbler,
} from 'stories';
import { formatDate } from '@/shared/lib/formatting/dates';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import {
  IDevelopmentLegalEntityRequisitionTransaction,
  ILineItem,
} from 'bundles/REconcile/types/ILineItem';
import VendorPopover from 'bundles/REport/components/financials/modal/VendorPopover';
import { sumBy, uniqueId } from 'lodash-es';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';
import pluralize from 'pluralize';
import { TRouteParams, useQueryParams } from '@/shared/lib/hooks/useNavigation';
import { getTransactionAmountByType } from './createTransactionsFromSource/bisUtils';
import { cn } from '@/shared/lib/css/cn';
import { useGetLineItemsInRequisitionQuery } from '../../api/lineItems';
import { IRouteParamsLineItems } from 'bundles/REconcile/components/development/lineItems/LineItemsTable';
import LineItemCardSimple from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/lineItems/LineItemCardSimple';
import stylesInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import LineItemModal from 'bundles/REconcile/components/LineItemModal';
import { transformCategorizedIdToKey } from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';
import { CssVar } from '@/shared/config/cssVar';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { joinWithDash } from '@/shared/lib/string';

const colors = [CssVar.green, CssVar.yellow, CssVar.blue, CssVar.pumpkin];

type Props = DialogProps &
  IDevelopmentLegalEntityRequisitionTransaction & {
    requisitionNumber: number;
    existingLineItems: ILineItem[];
    header: string;
    submitText: string;
    params: IRouteParamsLineItems;
    invoice?: {
      id: Invoice;
    };
  };

type TItemWithExtraData = ILineItem & {
  color: string;
  category_id: string;
  temporaryId: string;
  developmentCategory: {
    name: string;
    path: string;
  };
};

const LineItemsLinkTxModal = ({
  onClose,
  onSubmit,
  header,
  submitText,
  vendorEntity,
  value,
  date,
  period,
  note,
  requisitionNumber,
  existingLineItems = [],
  valueType,
  params,
  invoice,
}: Props) => {
  const transactionAmount = getTransactionAmountByType(valueType, value);
  const [lineItems, setLineItems] = useState<TItemWithExtraData[]>([]);
  const [allExistingLineItems, setAllExistingLineItems] =
    useState<TItemWithExtraData[]>(existingLineItems);
  const [sameInvoice, setSameInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();
  const { legalEntityCode } = params;
  const { data: categoriesFlat } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode,
  });

  const { data: lineItemsInRequisition } = useGetLineItemsInRequisitionQuery({
    legalEntityCode: params.legalEntityCode,
    requisitionId: params.requisitionId,
  });
  const { categoryId, categoryType } =
    useQueryParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS']
    >();

  const categoryTypeShort = categoryType === 'JobCostCode' ? 'jcc' : 'sovc';

  // including some extra parameters for existing line items as its have different shape
  const allLineItems = useMemo(
    () => [
      ...allExistingLineItems.map((li, i) => ({
        ...li,
        color: colors[i % colors.length],
        category_id: joinWithDash([categoryTypeShort, categoryId]),
      })),
      ...lineItems,
    ],
    [lineItems, allExistingLineItems, lineItemsInRequisition],
  );

  const transformLineItems = () => {
    if (lineItemsInRequisition) {
      return allLineItems.map((lineItem) => {
        if (lineItem.id) {
          return lineItemsInRequisition.items.find(
            (item) => item.id === lineItem.id,
          );
        }
        return lineItem;
      });
    }
    return [];
  };

  const transformExistingLineItems = () => {
    if (lineItemsInRequisition) {
      return lineItemsInRequisition.items
        .filter((lineItem) =>
          sameInvoice
            ? lineItem.invoice && invoice?.id === lineItem.invoice?.id
            : true,
        )
        .filter((lineItem) => {
          if (valueType === 'credit') {
            return Number(lineItem.amount) < 0;
          }
          return Number(lineItem.amount) > 0;
        })
        .filter(
          (lineItem) =>
            !allExistingLineItems.find(
              (existLineItem) => existLineItem.id === lineItem.id,
            ),
        );
    }
    return [];
  };

  const haveExistingLineItemsInInvoice = () =>
    transformExistingLineItems().find(
      (item) => item.invoice && invoice?.id === item.invoice?.id,
    );

  const renderHeader = (type: 'new' | 'edit') => (
    <div>
      <div className="dark-60 header6-bold">
        {type === 'edit' ? 'Edit' : 'Add'} Line Item
      </div>
      <div className="light-60 label-regular ">
        Requisition #{requisitionNumber}
      </div>
    </div>
  );

  const { total, allocatedAmount, notAllocatedAmount, allocationFlags } =
    useAllocation({
      total: Math.abs(Number(transactionAmount)),
      items: allLineItems,
      sumMapper: (item) => Math.abs(Number(item.amount)),
    });

  const lineItemsTotal = sumBy(allLineItems, (item) => Number(item.amount));

  const addLineItem = async () => {
    const res = await openModal(LineItemModal, {
      lineItem: {
        vendor: vendorEntity?.name,
        date: new Date(date),
        category_id: Number(categoryId),
      },
      legalEntityCode,
      categoryType,
      fieldSettings: {
        amount: {
          disallowPositive: valueType === 'credit',
          allowNegative: valueType === 'credit',
          suggestedValue:
            (valueType === 'credit' ? -1 : 1) * notAllocatedAmount,
        },
      },
      header: renderHeader('new'),
      submitText: 'Add item',
    });

    if (!res) return;
    const categoryItem = categoriesFlat?.find(
      (category) =>
        category.key ===
        transformCategorizedIdToKey(
          res.category_id,
          categoryType === 'JobCostCode' ? 'jcc' : 'sovc',
        ),
    );
    const color = colors[allLineItems.length % colors.length];
    setLineItems([
      ...lineItems,
      {
        developmentCategory: {
          name: categoryItem?.name ?? '',
          path: categoryItem?.categoryPath ?? '',
        },
        ...res,
        color,
        temporaryId: uniqueId('line_item_'),
        description: res.description,
      },
    ]);
  };

  const handleRowRemove = (id: string, temporaryId: string) => {
    if (id) {
      const newAllExistingLineItems = allExistingLineItems.filter(
        (item) => item.id !== id,
      );
      setAllExistingLineItems(newAllExistingLineItems);
    }
    if (temporaryId) {
      setLineItems(lineItems.filter((li) => li.temporaryId !== temporaryId));
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    onSubmit({ lineItems, allExistingLineItems });
    setLoading(false);
  };

  const addExistLineItem = (item: ILineItem) => {
    setAllExistingLineItems([...allExistingLineItems, item]);
  };

  const handleSameInvoice = () => {
    setSameInvoice(!sameInvoice);
  };

  return (
    <div>
      <SidePanel toggle={onClose} header={header} bodyPadding="0">
        <div className="flex h-full flex-col">
          <div className="px-tw-6 py-tw-4">
            <div className="flex">
              <div className="mb-tw-4 w-[100px]">
                <small className="mb-tw-1.5 text-light-60">TX DATE</small>
                <div className="light-90 inline-regular">
                  {formatDate(date, 'MMM DD, YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-tw-1 text-light-60">VENDOR</small>
                <div>
                  {vendorEntity && (
                    <VendorPopover vendor={vendorEntity}>
                      <InlineObject
                        iconName="bag"
                        object={vendorEntity?.name}
                        hideTooltipWhenOverflow
                      />
                    </VendorPopover>
                  )}
                  {!vendorEntity && <div>-</div>}
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-[100px]">
                <small className="mb-tw-1.5 text-light-60">PERIOD</small>
                <div className="light-90 inline-regular">
                  {formatDate(period, 'MMM-YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-tw-1.5 text-light-60">DESCRIPTION</small>
                <div className="light-90 inline-regular">{note}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col bg-light px-tw-6 py-tw-4">
            <div className="mb-tw-4">
              <AllocationStatistics
                totalAmount={total ?? 0}
                allocatedAmount={allocatedAmount}
              />
            </div>

            <div
              className={cn(
                'flex flex-1 flex-col gap-tw-4',
                stylesInvoice.tableBody,
                stylesInvoice.linkTxContainer,
              )}
            >
              <div className="flex flex-col gap-tw-2">
                <div className="flex items-center justify-between">
                  <div className="text-sm font-semibold text-dark-60">
                    {allLineItems.length > 0 && allLineItems.length}{' '}
                    {pluralize('Line Item', allLineItems.length)}
                  </div>
                  <Button
                    disabled={allocationFlags.isFullyAllocated}
                    variant="secondary"
                    size="s"
                    onClick={addLineItem}
                  >
                    Add Line Item
                  </Button>
                </div>
                <div className="flex flex-col gap-tw-1.5">
                  {transformLineItems().map((item, idx) => (
                    <LineItemCardSimple
                      key={`lineItem${idx}`}
                      idx={idx}
                      {...item}
                      description={item.remark ?? item.description ?? ''}
                      actions={
                        idx !== 0 && (
                          <div>
                            <IconButton
                              iconName="closeSmall"
                              onClick={() =>
                                handleRowRemove(item.id, item.temporaryId)
                              }
                            />
                          </div>
                        )
                      }
                    />
                  ))}
                </div>
                {allLineItems.length > 0 && (
                  <div className="flex justify-end px-tw-6">
                    <div className="flex items-center">
                      <span className="light-60 font-14 mr-tw-1">Total:</span>
                      <CurrencyFormatter value={lineItemsTotal} />
                    </div>
                  </div>
                )}
              </div>
              {Boolean(transformExistingLineItems().length) && (
                <div className="flex flex-col gap-tw-2">
                  <div className="flex flex-col gap-tw-1">
                    <div className="text-sm font-semibold text-dark-60">
                      Suggestions
                    </div>
                  </div>
                  {haveExistingLineItemsInInvoice() && (
                    <div>
                      <Tumbler
                        checked={sameInvoice}
                        classes={{
                          text: 'inline-regular text-light-80',
                        }}
                        onChange={handleSameInvoice}
                      >
                        Same Invoice
                      </Tumbler>
                    </div>
                  )}
                  <div className="flex flex-col gap-tw-1.5">
                    {transformExistingLineItems().map((item, idx) => (
                      <LineItemCardSimple
                        key={`lineItem${idx}`}
                        {...item}
                        description={item.remark ?? item.description ?? ''}
                        actions={
                          <div>
                            <Button
                              variant="secondary"
                              size="xs"
                              onClick={() => addExistLineItem(item)}
                            >
                              Add
                            </Button>
                          </div>
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <Button
              variant="success"
              className="mt-tw-4 w-full"
              onClick={handleSubmit}
              disabled={!allocationFlags.isFullyAllocated || loading}
            >
              <div className="flex items-center">
                {loading && (
                  <Icon
                    className="form-button-loading mr-tw-2"
                    iconName="sync"
                  />
                )}
                {submitText}
              </div>
            </Button>
          </div>
        </div>
      </SidePanel>
    </div>
  );
};

export default LineItemsLinkTxModal;

import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/index';
import {
  IPaginationMeta,
  IPaginationParams,
  IQueryParams,
} from 'types/Pagination';

export type TGetContactsRequestParams = IPaginationParams & IQueryParams;
export interface IReconcileSettingsContact {
  id: number;
  name: string;
  code: string;
}

export interface IReconcileSettingsContactUpdate {
  code: string;
  name: string;
}

export const {
  getEntities: getContactsInReconcileSettings,
  createEntity: createContactInReconcileSettings,
  updateEntity: updateContactInReconcileSettings,
  deleteEntity: deleteContactInReconcileSettings,
  entityName: CONTACT_ENTITY_NAME,
} = createCrudApi<
  {},
  TGetContactsRequestParams,
  IReconcileSettingsContact,
  IReconcileSettingsContactUpdate,
  {
    items: IReconcileSettingsContact[];
    meta: IPaginationMeta;
  }
>('/settings/reconcile/contacts', 'Contact');

import dayjs from 'dayjs';
import pdfMake from 'pdfmake';
import { Content, TDocumentDefinitions } from 'pdfmake/build/pdfmake';

export interface IPdfTableHeader<T = Record<string, unknown>> {
  key: string;
  label: string;
  width: number;
  display?: (row: T) => string;
  alignment?: 'left' | 'right' | 'center';
}
// TODO: hotfix for failed fonts with exporting PDF resolve it later
pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

const DEFAULT_DOC_OPTIONS: TDocumentDefinitions = {
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [20, 70, 20, 20],
  content: [],
};

export const defaultHeader = (assetName: string, logo: string): Content => ({
  columns: [
    { image: logo, width: 150, margin: [10, 0, 0, 0] },
    {
      stack: [
        {
          text: assetName,
          fontSize: 15,
          color: '#128141',
          bold: true,
          margin: [250, 10, 0, 0],
        },
        {
          text: 'Change Order Management | Summary Report',
          bold: true,
          fontSize: 12,
          color: '#0d1a3e',
          margin: [250, 0, 0, 0],
        },
        {
          text: `Report Date: ${dayjs().format('MMM D, YYYY')}`,
          fontSize: 10,
          color: '#575c5f',
          italics: true,
          margin: [250, 0, 0, 0],
        },
      ],
    },
  ],
  columnGap: 130,
});

export const initPDFBuilder = (options: Partial<TDocumentDefinitions> = {}) => {
  const doc: TDocumentDefinitions = { ...DEFAULT_DOC_OPTIONS, ...options };
  const docContent = doc.content as Content[];

  const addHeader = (text: Content['text'], opts?: Partial<Content>) => {
    docContent.push({
      text,
      fontSize: 14,
      bold: true,
      margin: [0, 5, 0, 10],
      color: '#128141',
      ...opts,
    });
  };

  const buildSubHeader = (
    text: Content['text'],
    opts: Partial<Content>,
  ): Content => ({
    text,
    italics: true,
    margin: [0, 5, 0, 4],
    color: '#128141',
    ...opts,
  });

  const addImage = (img: Content['image'], opts: Partial<Content>) => {
    docContent.push({ image: img, ...opts });
  };

  const addTable = (
    headers: IPdfTableHeader[],
    data: Record<string, unknown>[],
    opts?: Partial<Content>,
  ) => {
    docContent.push({
      table: {
        headerRows: 1,
        widths: headers.map((h) => h.width),
        body: [
          headers.map((h) => ({
            text: h.label,
            bold: true,
            fontSize: 10,
            alignment: h.alignment,
          })),
          ...data.map((row) =>
            headers.map((h) => ({
              text: h.display ? h.display(row) : row[h.key],
              fontSize: 8,
              alignment: h.alignment,
              ...row.styles,
            })),
          ),
        ],
        ...opts,
      },
    });
  };

  const buildSmallTable = (
    headers: IPdfTableHeader[],
    data: Record<string, unknown>,
    opts: Partial<Content>,
  ) => ({
    layout: 'lightHorizontalLines',
    table: {
      headerRows: false,
      widths: headers.map((h) => h.width || '*'),
      body: Object.values(data).map((row) =>
        headers.map((h) => ({
          text: h.display ? h.display(row) : row[h.key],
          fontSize: 10,
          alignment: h.alignment,
          ...row.styles,
        })),
      ),
      ...opts,
    },
    margin: [0, 0, 0, 15],
  });

  const addPageBreak = () => docContent.push({ text: '', pageBreak: 'before' });

  const print = () => pdfMake.createPdf(doc).open();

  return {
    addHeader,
    addImage,
    addTable,
    buildSmallTable,
    buildSubHeader,
    addPageBreak,
    print,
    doc,
  };
};

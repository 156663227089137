import React from 'react';

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  alignItems?: 'space-between' | 'flex-end';
}

export const ModalActions = ({
  children,
  alignItems = 'space-between',
}: Props) => (
  <div className="w-full flex gap-m" style={{ justifyContent: alignItems }}>
    {children}
  </div>
);

export default ModalActions;

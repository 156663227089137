import React, { CSSProperties, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  appBar: ReactNode;
  children: ReactNode | ReactNode[];
  padding?: 's' | 'm' | 'l' | 'xs' | 'none';
  style?: CSSProperties;
  className?: string;
  classes?: {
    appBar?: string;
    container?: string;
    body?: string;
  };
}

function ContainerWithAppBar({
  children,
  appBar,
  padding = 'l',
  style,
  className,
  classes,
}: Props) {
  return (
    <div
      className={cn(
        'flex flex-col',
        padding !== 'none' && `p-${padding}`,
        className,
        classes?.container,
      )}
      style={style}
    >
      <div className={cn(classes?.appBar)}>{appBar}</div>
      <div className={cn('grow', classes?.body)}>{children}</div>
    </div>
  );
}

export default ContainerWithAppBar;

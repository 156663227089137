export const MAP_KINDS_TO_TEXT = {
  created: 'Legal Entity has been created',
  updated: 'Legal Entity has been updated',
  assigned_to_asset: 'Legal Entity has been assigned to',
  assigned_to_fund: 'Legal Entity has been assigned to',
  public_permissions_granted: 'Public permissions granted',
  restricted_permissions_granted: 'Restricted permissions granted',
  restricted_permissions_revoked: 'Restricted permissions revoked',
  excluded: 'Legal Entity has been excluded',
  included: 'Legal Entity has been included',
} as const;

export const MAP_KINDS_TO_ICON_NAME = {
  created: 'check',
  updated: 'edit',
  assigned_to_asset: 'asset',
  assigned_to_fund: 'funds',
  public_permissions_granted: 'public',
  restricted_permissions_granted: 'lock',
  restricted_permissions_revoked: 'lock',
  excluded: 'eye',
  included: 'eye',
} as const;

import React, { useEffect } from 'react';
import {
  updateSelectedChangeOrderConfig,
  updateSelectedConfigToSetForAsset,
} from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import ChangeOrderConfigsModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/changeOrderConfigs/ChangeOrderConfigsModal';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { Dropdown, DropdownItem, Button, IconButton } from 'stories';

interface Props {
  className?: string;
  legalEntityId: string;
}

function ChangeOrderConfigSelect({ className, legalEntityId }: Props) {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const changeOrderConfigs = useAppSelector(
    (state) => state.developmentLegalEntity.changeOrderConfigs,
  );
  const selectedChangeOrderConfig = useAppSelector(
    (state) => state.developmentLegalEntity.selectedChangeOrderConfig,
  );

  useEffect(() => {
    dispatch(updateSelectedConfigToSetForAsset());
  }, [changeOrderConfigs]);

  return (
    <div className={cn('flex items-center gap-s', className)}>
      <h6 className="light-90 header6-bold">Config</h6>
      <Dropdown
        items={changeOrderConfigs.map((coc) => (
          <DropdownItem
            key={coc.id}
            onClick={() =>
              dispatch(
                updateSelectedChangeOrderConfig({
                  legalEntityId,
                  configId: coc.id,
                }),
              )
            }
            active={
              selectedChangeOrderConfig
                ? selectedChangeOrderConfig.id === coc.id
                : undefined
            }
          >
            {coc.name}
          </DropdownItem>
        ))}
      >
        <Button
          iconName="bottom"
          iconPosition="right"
          variant="secondary"
          size="s"
        >
          {selectedChangeOrderConfig?.name}
        </Button>
      </Dropdown>
      <IconButton
        iconName="edit"
        onClick={() =>
          openModal(ChangeOrderConfigsModal, {
            legalEntityId,
          })
        }
      />
    </div>
  );
}

export default ChangeOrderConfigSelect;

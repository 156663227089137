import { getCssVariableByAccessor } from '@/lib/helpers';
import { CssVar } from '@/shared/config/cssVar';
import { Color } from '@/shared/widgets/colorPicker/model';

export const STANDARD_COLOR_BOXES = [
  '#192029',
  '#192F43',
  '#152838',
  '#291734',
  '#371510',
  '#421B16',
  '#473211',
  '#493D14',
  '#1F3E26',
  '#17312A',
  '#1F2832',
  '#1F3A58',
  '#1A3145',
  '#321D40',
  '#441914',
  '#58221A',
  '#693D16',
  '#6B4C19',
  '#274F2F',
  '#1C3C34',
  '#242F3C',
  '#26457D',
  '#1F3A5A',
  '#3C224C',
  '#561F17',
  '#7D2820',
  '#8F491B',
  '#926C1F',
  '#2F6F38',
  '#22483D',
  '#2A3745',
  '#2C54A1',
  '#244379',
  '#462768',
  '#73231B',
  '#A02E25',
  '#B55E1F',
  '#B98C24',
  '#379240',
  '#285D47',
  '#303E4E',
  '#326DC4',
  '#294C96',
  '#532C84',
  '#90281F',
  '#C43429',
  '#DC7924',
  '#E1AE29',
  '#3EB249',
  '#2E7656',
  '#596572',
  '#448BCF',
  '#3D6FAB',
  '#74419C',
  '#A53E36',
  '#CD473E',
  '#E19334',
  '#E7BD37',
  '#4BC168',
  '#3D9077',
  '#828B95',
  '#68A7DB',
  '#5D93C0',
  '#9666B5',
  '#B95E51',
  '#D86F5F',
  '#E9AE4B',
  '#EDCE4C',
  '#72CF8E',
  '#58AC99',
  '#ACB2B8',
  '#99C4E7',
  '#90B6D5',
  '#B999CD',
  '#CF938B',
  '#E39D95',
  '#F0C884',
  '#F2DD85',
  '#9DE0B3',
  '#8EC7BA',
  '#D6D8DC',
  '#CBE1F2',
  '#C8DCEA',
  '#DBCDE6',
  '#E6C8C5',
  '#F1CECA',
  '#F8E3C1',
  '#F8EEC1',
  '#CEF0D9',
  '#C5E3DD',
  '#EAECEE',
  '#E6F1F8',
  '#E4EDF4',
  '#EEE4F2',
  '#F2E3E1',
  '#F8E7E4',
  '#FBF1DF',
  '#FCF7E0',
  '#E6F7ED',
  '#E3F2EE',
].map((value) => ({ id: value, value })) satisfies readonly Color[];

export const PRE_CONFIGURED_COLORS = [
  {
    id: 'primary-090',
    value: getCssVariableByAccessor(CssVar.primary090),
    isDefault: true,
  },
  {
    id: 'neutral-650',
    value: getCssVariableByAccessor(CssVar.neutral650),
    isDefault: true,
  },
  {
    id: 'success-080',
    value: getCssVariableByAccessor(CssVar.success080),
    isDefault: true,
  },
  {
    id: 'info-080',
    value: getCssVariableByAccessor(CssVar.info080),
    isDefault: true,
  },
] as const satisfies readonly Color[];

export type ColorButtonSizeProp = '24' | '32';

export const COLOR_BOX_SIZE_CLASSNAME = {
  '24': 'h-[24px] w-[24px]',
  '32': 'h-[32px] w-[32px]',
} as const satisfies Record<ColorButtonSizeProp, string>;

import React from 'react';
import styles from 'bundles/Construction/styles.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { IColumn } from 'bundles/Shared/components/Table/types';
import QuickFilterAmount from 'bundles/Shared/components/Table/filters/QuickFilterAmount';
import { capitalize } from 'lodash';
import { Badge, Button, CurrencyFormatter, Icon, Tooltip } from 'stories';
import { DrawRequest } from '@/shared/types/reconcile/DrawRequest';
import { Link } from '@reach/router';
import { formatDate } from '@/shared/lib/formatting/dates';
import DocumentBadge from 'bundles/Construction/components/Shared/DocumentBadge/DocumentBadge';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import { getSliceNumber } from 'bundles/Shared/components/Table/formatters/drawPackage';
import ActionsCell from './ActionCell';
import drawStyles from './drawStyles.module.scss';

export enum TColumnIdsDrawRequest {
  NUMBER = 'number',
  REQUEST_DOCUMENT = 'document',
  REQUISITION = 'requisition_date',
  AMOUNT = 'amount',
  DATE = 'date',
  INVOICES = 'invoices_count',
  STATUS = 'status',
}

export type TFilterModelDrawRequest = {
  [TColumnIdsDrawRequest.AMOUNT]?: {
    from: string;
    to: string;
  };
};

export const drawStatuses = {
  created: {
    color: 'green',
  },
};

export const drawRequestColumns = (
  getUrl,
  legalEntityCode: string,
): IColumn<DrawRequest>[] => [
  {
    text: '#',
    dataField: TColumnIdsDrawRequest.NUMBER,
    classes: styles.invoiceNumber,
    headerClasses: styles.invoiceNumberHeader,
    headerStyle: {
      minWidth: 50,
      maxWidth: 70,
    },
  },
  {
    sortable: true,
    text: 'Draw Request',
    dataField: TColumnIdsDrawRequest.REQUEST_DOCUMENT,
    formatter: ({ row: object }) =>
      object.document && (
        <DocumentBadge
          titleText={object.document.title}
          title={object.document.title}
          url={object.document.url}
        />
      ),
    classes: styles.invoiceDocumentColumn,
    headerStyle: {
      minWidth: 120,
      maxWidth: 180,
    },
  },
  {
    sortable: true,
    text: 'Date',
    dataField: TColumnIdsDrawRequest.DATE,
    formatter: ({ row: object }) => (
      <span className="light-90">
        {formatDate(object.date, 'MMM DD, YYYY')}
      </span>
    ),
    headerStyle: {
      minWidth: 150,
    },
  },
  {
    sortable: true,
    text: 'Amount',
    dataField: TColumnIdsDrawRequest.AMOUNT,
    formatter: ({ row: object }) => (
      <div className={styles.amount}>
        <CurrencyFormatter value={object.amount} />
      </div>
    ),
    filterComponent: QuickFilterAmount,
    classes: styles.invoiceAmount,
    headerClasses: styles.invoiceAmountHeader,
    headerStyle: {
      minWidth: 150,
    },
  },
  {
    sortable: true,
    text: 'Requisition',
    dataField: TColumnIdsDrawRequest.REQUISITION,
    formatter: ({ row: object }) => (
      <div className={styles.amount}>
        <Link
          to={getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS', {
            legalEntityCode,
            activeRequisition: object.requisition.id,
          })}
        >
          <div className="blue">
            {formatDate(object.requisition.endingDate, 'MMM DD, YYYY')}
          </div>
        </Link>
      </div>
    ),
    headerStyle: {
      minWidth: 150,
    },
  },
  {
    sortable: true,
    text: 'Invoices',
    dataField: TColumnIdsDrawRequest.INVOICES,
    formatter: ({ row: object }) => (
      <div className={styles.amount}>
        <Link
          to={getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES', {
            legalEntityCode,
            drawId: object.id,
          })}
        >
          <div className="blue">{object.invoicesCount}</div>
        </Link>
      </div>
    ),
    headerStyle: {
      minWidth: 150,
    },
  },
  {
    text: 'Status',
    dataField: 'status',
    formatter: ({ row: object }) => (
      <span>
        <Badge backgroundColor={`var(--${drawStatuses[object.status].color})`}>
          {capitalize(object.status)}
        </Badge>
      </span>
    ),
    headerStyle: {
      minWidth: 150,
    },
  },
  {
    text: 'Actions',
    dataField: 'actions',
    formatter: ({ row: object }) => <ActionsCell drawRequest={object} />,
    classes: drawStyles.invoiceActionsCell,
    headerStyle: {
      width: 50,
    },
  },
];

export const drawInvoicesColumns = (showDetails): IColumn<Invoice>[] => [
  {
    text: '#',
    dataField: 'number',
    classes: drawStyles.invoiceNumber,
    headerClasses: [
      drawStyles.invoiceHeader,
      drawStyles.invoiceHeaderNumber,
    ].join(' '),
    formatter: ({ row: object }) => (
      <Tooltip titleText={object.number}>
        <div className="light-90">{getSliceNumber(object.number, 3)}</div>
      </Tooltip>
    ),
    headerStyle: {
      width: 110,
    },
  },
  {
    text: 'Vendor',
    dataField: 'vendorName',
    formatter: ({ row: object }) => (
      <Tooltip titleText={object.vendor.name}>
        <div className={cn(styles.invoiceCompany, drawStyles.invoiceCompany)}>
          <Icon
            className={cn('light-60', styles.invoiceCompanyIcon)}
            iconName="bag"
          />
          <span className="secondary-regular text-ellipsis">
            {object.vendor.name}
          </span>
        </div>
      </Tooltip>
    ),
    classes: drawStyles.invoiceColumn,
    headerClasses: drawStyles.invoiceHeader,
    headerStyle: {
      minWidth: 120,
      maxWidth: 150,
    },
  },
  {
    text: 'Date',
    dataField: 'date',
    formatter: ({ row: object }) => (
      <span className="inline-regular text-light-60">
        {formatDate(object.date, 'MMM DD, YYYY')}
      </span>
    ),
    classes: drawStyles.invoiceColumn,
    headerClasses: drawStyles.invoiceHeader,
    headerStyle: {
      minWidth: 110,
    },
  },
  {
    text: 'Amount',
    dataField: 'amount',
    formatter: ({ row: object }) => (
      <div className={styles.amount}>
        <CurrencyFormatter
          value={object.amount}
          classes={{
            wrapper: 'font-14 font-weight-500 flex items-center gap-tw-0.5',
          }}
        />
      </div>
    ),
    classes: drawStyles.invoiceColumn,
    headerClasses: [
      drawStyles.invoiceHeader,
      drawStyles.invoiceHeaderAmount,
    ].join(' '),
    headerStyle: {
      minWidth: 110,
      maxWidth: 140,
    },
  },
  {
    dataField: 'document',
    formatter: ({ row: object }) =>
      object.document && (
        <DocumentBadge
          titleText={object.document.title}
          url={object.document.url}
          classNameWrapper="p-tw-1"
        />
      ),
    classes: drawStyles.invoiceDocument,
    headerStyle: {
      width: 24,
    },
  },
  {
    dataField: 'actions',
    formatter: ({ row: object }) => (
      <div className="flex justify-center">
        <Button
          variant="secondary"
          size="xs"
          onClick={() => showDetails(object.id)}
        >
          Details
        </Button>
      </div>
    ),
    classes: drawStyles.invoiceActionsCellShort,
    headerStyle: {
      width: 60,
    },
  },
];

import React, { FC } from 'react';
import { formatAmount } from '@/shared/lib/formatting/number';
import { formatUnixDate } from '@/shared/lib/formatting/dates';

interface ISelectCOItemSubtitleProps {
  number: number;
  category: string;
  value: string;
  receivedDate: string | number;
}

const SelectCOItemSubtitle: FC<ISelectCOItemSubtitleProps> = ({
  number,
  category,
  value,
  receivedDate,
}) => (
  <div className="flex items-center gap-xs">
    <div>{`${category} ${number}`}</div>
    <div>•</div>
    <div>{formatAmount(value)}</div>
    <div>•</div>
    <div>Received: {formatUnixDate(receivedDate, 'MM-DD-YYYY')}</div>
  </div>
);

export default SelectCOItemSubtitle;

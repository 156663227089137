import {
  RowHeightParams,
  RowNode,
  ValueFormatterFunc,
} from 'ag-grid-community';
import {
  ITotalRow,
  TExtendedRow,
} from 'bundles/Shared/components/ReportTable/ReportTable';
import {
  IColumn,
  IColumnGroup,
  IRow,
  isRowCategory,
  isRowDivider,
  isRowFormula,
  isRowGl,
  isRowHeader,
  isRowJcc,
} from 'bundles/Shared/components/ReportTable/types';
import { isNodeTotalFooter } from 'lib/ag-grid/utils';
import { exists } from 'lib/typeHelpers';
import { camelCase } from 'lodash-es';
import { IReportTableConfig } from 'types/ReportTableConfig';

export const COLORS_TO_CLASSES = {
  green: '!bg-green-dark-3 text-white',
  blue: 'bg-blue-dark-2 text-white',
  grey: 'bg-light',
  'dark-grey': 'bg-light-70 text-white',
  dark: 'bg-dark-60 text-white',
} as const;

export const FONT_COLOR_RULES = {
  negative_red: (value: number | null) => ({
    red: (value ?? 0) < 0,
    green: undefined,
  }),
  negative_red_positive_green: (value: number | null) => ({
    red: (value ?? 0) < 0,
    green: (value ?? 0) > 0,
  }),
};
export const DEFAULT_ROW_HEIGHT = 32;
export const NO_DATA_SYMBOL = '-';

export const periodTypeWhichShouldHaveCenteredColumnHeaders: IReportTableConfig['periodsType'][] =
  [
    'variance_report',
    'single_mtd_and_ytd',
    'single_mtd_qtd_and_ytd',
    'single_mtd_qtd_and_itd',
    'single_mtd_qtd_and_uw_itd',
    'single_qtd_and_ytd',
    'single_qtd_ytd_and_itd',
    'single_qtd_ytd_and_uw_itd',
    'single_t3_and_t12',
    'total',
  ];

export const getColId = (columnGroup: IColumnGroup, column?: IColumn) => {
  if (column == null) {
    return camelCase(columnGroup.label);
  }
  return `${camelCase(columnGroup.label)}-${column.key}`;
};

export const rowHasBackground = (row: TExtendedRow) =>
  row.display?.background != null;

export const isRowTotals = (row: TExtendedRow): row is ITotalRow =>
  row.type === 'totals';

export const isEditable = (node: RowNode) =>
  (isRowGl(node.data) || isRowJcc(node.data)) && !node.hasChildren();

export const isCellClickable = (row: IRow, column: IColumn) =>
  isRowGl(row) && row.data[column.key]?.active;

export const hideTotalsOnExpand = (node: RowNode, expanded: boolean) => {
  if (isRowCategory(node.data)) {
    if (expanded) {
      node.setData({
        ...node.data,
        data: null,
        _data: node.data.data,
      });
    } else {
      node.setData({
        ...node.data,
        data: node.data._data,
      });
    }
  }
};

export const autoGroupValueFormatter: ValueFormatterFunc = ({ node }) => {
  const row = node?.data as IRow;

  if (node && isNodeTotalFooter(node)) {
    return 'TOTALS';
  }
  if (row == null || isRowDivider(row)) {
    return '';
  }
  let label: string | null = null;
  if (isRowGl(row)) {
    label = `${row.generalLedger.accNumber} - ${row.generalLedger.accTitle}`;
  }
  if (isRowJcc(row)) {
    label = row.jobCostCode.name;
  }
  if (
    isRowFormula(row) ||
    isRowCategory(row) ||
    isRowHeader(row) ||
    isRowTotals(row)
  ) {
    // eslint-disable-next-line prefer-destructuring
    label = row.label;
  }
  if (node?.footer) {
    return `Totals ${label ?? ''}`;
  }

  return label ?? '';
};

export const getRowHeight = ({ data: row }: RowHeightParams) => {
  if (!row) {
    return DEFAULT_ROW_HEIGHT;
  }
  if (isRowDivider(row)) {
    return 16;
  }
  if (isRowFormula(row) || isRowHeader(row)) {
    return 24;
  }
  if (isRowTotals(row)) {
    return 36;
  }
  return DEFAULT_ROW_HEIGHT;
};

import http from 'lib/http';
import type { IUserRole } from 'types/User';
import type * as Types from 'bundles/UserManagement/components/roles/types';

export const FETCH_USER_ROLES = 'symmetre-client-api/FETCH_USER_ROLES';

const OLD_PATH = '/user_roles';
const PATH = '/settings/user_management/user_roles';

export function fetchUserRoles() {
  return async (dispatch) => {
    const res = await http.get(PATH);
    const responseData: IUserRole[] = await res.json();

    dispatch({
      type: FETCH_USER_ROLES,
      payload: responseData,
    });
  };
}

export async function plainCreateUserRole(data: Types.ICreateRoleFormData) {
  const res = await http.post(PATH, data);
  const responseData: IUserRole = await res.json();
  return responseData;
}

export async function newPlainFetchUserRoles() {
  const res = await http.get(PATH);
  const responseData: IUserRole[] = await res.json();
  return responseData;
}

export async function plainFetchUserRoles() {
  const res = await http.get(OLD_PATH);
  return res.json();
}

export async function plainUpdateUserRole({
  id,
  data,
}: {
  data: Partial<Pick<IUserRole, 'group' | 'name'>>;
  id: IUserRole['id'];
}) {
  const path = `${PATH}/${id}`;
  const res = await http.put(path, data);
  const responseData: IUserRole = await res.json();

  if (responseData.errors) {
    throw new Error();
  }

  return responseData;
}

export async function plainDeleteUserRole(uuid: IUserRole['id']) {
  const path = `${PATH}/${uuid}`;
  const res = await http.del(path);
  return res.json();
}

export async function plainFecthAllUserRoleActivityLog() {
  const res = await http.get(
    '/settings/user_management/user_roles/activity_log',
  );
  const json: Types.IUserRoleActivityLogItem[] = await res.json();
  return json;
}

export async function plainFecthUserRoleActivityLogItem(uuid: IUserRole['id']) {
  const res = await http.get(
    `/settings/user_management/user_roles/${uuid}/activity_log`,
  );
  const json: Types.IUserRoleActivityLogItem[] = await res.json();
  return json;
}

import React, { FC } from 'react';
import { IconButton } from 'stories/index';

interface IUserActivityHeaderProps {
  onClick: () => void;
}

export const UserActivityHeader: FC<IUserActivityHeaderProps> = ({
  onClick,
}) => (
  <div className="z-20 flex gap-tw-4 bg-white px-tw-6 py-tw-5">
    <IconButton iconName="arrowLeft" onClick={onClick} variant="secondary" />
    <div>
      <div className="font-medium text-neutral-800">User Activity</div>
    </div>
  </div>
);

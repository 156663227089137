import * as d3 from 'd3';
import { getComponentSelector } from './TreeView';

export const NODE_BACKGROUND_COMPONENT = 'BackgroundGroup';

export function NodeBackground(
  selection,
  lifecycle: 'create' | 'update',
  options?: {
    cursorPointer?: boolean;
  },
) {
  const [PADDING_X, PADDING_Y] = [4, 2];
  if (lifecycle === 'create') {
    const backgroundGroup = selection
      .append('g')
      .attr('cursor', options?.cursorPointer ? 'pointer' : 'default')
      .attr('data-component', NODE_BACKGROUND_COMPONENT);

    backgroundGroup.append('rect').attr('fill', 'transparent').attr('ry', 6);
  }

  selection
    .select(getComponentSelector(NODE_BACKGROUND_COMPONENT))
    .on('mouseover', function (e, d) {
      if (!d.clicked) {
        d3.select(this).select('rect').attr('fill', 'var(--light-10)');
      }
    })
    .on('mouseleave', function (e, d) {
      if (!d.clicked) {
        d3.select(this).select('rect').attr('fill', 'transparent');
      }
    });

  selection
    .select(getComponentSelector(NODE_BACKGROUND_COMPONENT))
    .each(function (d) {
      const group = d3.select(this);
      const rect = group.select('rect');
      const groupBound = group.node().getBBox();
      const rectBound = rect.node()!.getBBox();
      d.bgBox = groupBound;

      const width =
        groupBound.width !== rectBound.width
          ? groupBound.width + PADDING_X * 2
          : rectBound.width;
      const height =
        groupBound.height !== rectBound.height
          ? groupBound.height + PADDING_Y * 2
          : rectBound.height;

      group
        .select('rect')
        .attr('width', width)
        .attr('height', height)
        .attr('x', -PADDING_X * (d.checkBox ? 1 : 1))
        .attr('y', -(height / 2));
    });

  selection.select('rect');
}

import { IDetailsItem } from 'bundles/REturn/components/Home/investmentObjectDetailsModal/DetailsTable';
import { IReturnInvestmentObjectDetails } from 'bundles/REturn/types';
import { groupBy, max, sumBy } from 'lodash-es';

import { convertCentsToDollars } from '@/shared/lib/converters';

export const MAX_DISPLAYED_CHART_ITEMS = 5;
export const RETURN_ENTITY_KEYS = [
  'commitments',
  'contributions',
  'distributions',
  'accrued_preferred',
] as const;

export const RETURN_HOME_PAGE_DETAILS_MODAL_TABS = RETURN_ENTITY_KEYS.reduce(
  (obj, key) => ({ ...obj, [key]: key }),
  {} as Record<
    (typeof RETURN_ENTITY_KEYS)[number],
    (typeof RETURN_ENTITY_KEYS)[number]
  >,
);

export type ReturnInvestmentObjectTab =
  keyof typeof RETURN_HOME_PAGE_DETAILS_MODAL_TABS;
export const DEFAULT_TAB: ReturnInvestmentObjectTab = 'commitments';

export const getDetailsItemsForTab = (
  tab: ReturnInvestmentObjectTab,
  data: IReturnInvestmentObjectDetails,
  legalEntitiesIds: string[],
): IDetailsItem[] => {
  const getFilteredItems = () => {
    if (tab == 'accrued_preferred') {
      return data.capitalInvestments.filter((item) => {
        return (
          (legalEntitiesIds.length === 0 ||
            legalEntitiesIds.includes(item.legalEntity.id)) &&
          item.totalAccruedEndingBalanceCents != 0
        );
      });
    }
    return data[tab].filter(
      (item) =>
        legalEntitiesIds.length === 0 ||
        legalEntitiesIds.includes(item.legalEntity.id),
    );
  };
  const filtered = getFilteredItems();

  if (tab == 'accrued_preferred') {
    return filtered.map((item) => {
      return {
        investmentEntity: item.investmentEntity,
        amount: convertCentsToDollars(item.totalAccruedEndingBalanceCents),
        date: item.lastAccruedEntryDate,
      };
    });
  }

  const groups = groupBy(filtered, (item) => item.investmentEntity.id);
  return Object.entries(groups).map(([_, items]) => {
    return {
      investmentEntity: items[0].investmentEntity,
      amount: sumBy(items, (item) =>
        item.amount
          ? Number(item.amount)
          : convertCentsToDollars(item.amountCents),
      ),
      date: max(items.map((item) => item.date)),
    };
  });
};

export const getTabIsDisabled = (
  tab: ReturnInvestmentObjectTab,
  data: IReturnInvestmentObjectDetails,
) => {
  if (tab === 'accrued_preferred') {
    return (
      data.capitalInvestments.filter(
        (capitalInvestment) =>
          capitalInvestment.totalAccruedEndingBalance !== 0,
      ).length === 0
    );
  }

  const key = RETURN_HOME_PAGE_DETAILS_MODAL_TABS[tab];

  if (data[key].length === 0) return true;
  if (key === 'distributions')
    return data[key].every((item) => Number(item.amount) === 0);
  if (key === 'contributions')
    return data[key].every((item) => Number(item.amountCents) === 0);

  return data[key].every((item) => Number(item.commitmentAmount) === 0);
};

import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from 'stories';

const DropzoneArea = ({ onChange }) => {
  const onDrop = (newFiles) => {
    const filesWithImageUrl = newFiles.map((file) =>
      Object.assign(file, {
        smallPictureUrl: URL.createObjectURL(file),
      }),
    );

    onChange(filesWithImageUrl);
  };

  return (
    <Dropzone onDrop={onDrop} accept={{ 'image/*': [] }}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: 'dropzone border-radius-[0.5rem]' })}>
          <input {...getInputProps()} multiple={false} />
          <div className="dz-default dz-message">
            <div className="dz-icon">
              <i className="demo-pli-upload-to-cloud icon-5x" />
            </div>
            <div>
              <span className="inline-regular">
                Drag a photo to start uploading
              </span>
              <p className="inline-regular light-60 m-2">or</p>
              <Button variant="primary">Browse photos</Button>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default DropzoneArea;

DropzoneArea.propTypes = {
  onChange: PropTypes.func.isRequired,
};

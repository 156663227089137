import { Button, IconButton } from '@/stories';
import { ComponentProps } from 'react';

export const ExportButton = (
  props: Omit<
    ComponentProps<typeof Button>,
    'variant' | 'iconName' | 'iconPosition'
  >,
) => {
  return (
    <Button
      iconName="exportDownload"
      iconPosition="left"
      variant="secondary"
      size="s"
      children="Export"
      {...props}
    />
  );
};

export const ExportIconButton = (
  props: Omit<ComponentProps<typeof Button>, 'variant' | 'iconName'>,
) => {
  return (
    <IconButton
      iconName="exportDownload"
      variant="secondary"
      size="m"
      {...props}
    />
  );
};

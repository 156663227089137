import { useState } from 'react';

const FIRST_STEP_IDX = 0;
const LAST_STEP_IDX = 2;
export type StepIdx = 0 | 1 | 2;

export function useWorkflowSteps() {
  const [stepIdx, setStepIdx] = useState<StepIdx>(FIRST_STEP_IDX);

  const onForward = () => {
    setStepIdx((prev) =>
      prev === LAST_STEP_IDX ? LAST_STEP_IDX : ((prev + 1) as StepIdx),
    );
  };
  const onBackward = () => {
    setStepIdx(
      (prev) =>
        (prev === FIRST_STEP_IDX ? FIRST_STEP_IDX : prev - 1) as StepIdx,
    );
  };

  const onReset = () => {
    setStepIdx(FIRST_STEP_IDX);
  };

  return {
    stepIdx,
    onForward,
    onBackward,
    onReset,
  };
}

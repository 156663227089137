import React, { useEffect } from 'react';
import { PipelineCards } from 'bundles/Scoreboard/Pipeline/components/Pipeline/PipelineCards';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { fetchPropertyTypes } from 'bundles/Scoreboard/Pipeline/actions';
import Velocity from 'bundles/Scoreboard/Pipeline/components/Pipeline/Velocity';
import PipelineTable from 'bundles/Scoreboard/Pipeline/components/Pipeline/PipelineTable';
import EmployeeUtilization from 'bundles/Scoreboard/Pipeline/components/Pipeline/EmployeeUtilization';
import GoalsSummary from 'bundles/Scoreboard/Pipeline/components/Pipeline/GoalsSummary';

const Pipeline = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPropertyTypes());
  }, []);

  return (
    <div>
      <div className="mt-0">
        <div className="row">
          <div className="col-lg-6">
            <GoalsSummary />
          </div>
          <div className="col-lg-6">
            <Velocity />
          </div>
        </div>
        <PipelineTable />
        <EmployeeUtilization />
        <div className="relative">
          <PipelineCards />
        </div>
      </div>
    </div>
  );
};

export default Pipeline;

import React, { FC, useState } from 'react';
import { IChangeOrder } from 'bundles/Construction/types';
import { Button } from 'stories/index';
import FormDate from 'bundles/Shared/components/GroupForm/FormItems/FormDate';
import {
  approveChangeOrder,
  fetchChangeOrder,
} from 'bundles/Construction/actions/changeOrders';
import { useAppDispatch } from '@/shared/lib/hooks/redux';

import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface IApproveFormProps {
  changeOrder: IChangeOrder;
  legalEntityCode: string;
}

const ApprovalForm: FC<IApproveFormProps> = ({
  changeOrder,
  legalEntityCode,
}) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    date: '' as DateString,
  });
  const approveHandle = async () => {
    await dispatch(
      approveChangeOrder(legalEntityCode, changeOrder.id, {
        date: formatToDateStringForRequest(formData.date),
      }),
    );
    await dispatch(fetchChangeOrder(legalEntityCode, changeOrder.id));
  };
  const chooseDate = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e[fieldName],
    });
  };

  return (
    <div>
      <div className="">
        <div className="available-action__line px-m pt-m">
          <div className="mb-m">
            <FormDate
              title="Approval Date"
              fieldName="date"
              formData={formData}
              setFormData={(e) => chooseDate(e, 'date')}
              styles={{
                wrapper: 'w-full form-item_white',
                size: 'm',
                nomb: true,
              }}
              placeholder="Date"
              format="MM-DD-YYYY"
              maxDate={new Date()}
              formatView="MM-dd-yy"
              leftIcon="calendar"
            />
          </div>
        </div>
        <div className="px-m py-m">
          <Button
            variant="success"
            iconName="acceptAlt"
            onClick={approveHandle}
            disabled={!formData.date}
            fluid
          >
            Approve
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalForm;

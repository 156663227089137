import React, { FC } from 'react';

interface IFormViewTitleProps {
  title?: string;
}

const FormViewTitle: FC<IFormViewTitleProps> = ({ title }) => (
  <div className="form-view__title">{title}</div>
);

export default FormViewTitle;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { IDocumentType } from 'types/DocumentType';
import { IUserRole } from 'types/User';
import { IUserTag } from 'types/IUserTag';
import FilterBar from '../../Filters/common/filterBar/FilterBar';
import FilterItem from '../../Filters/common/filterItem/FilterItem';

interface Filter {
  items: (IAsset | IFund | IDocumentType | IUserRole | IUserTag)[];
  title: string;
}

interface FilterObj {
  assets: Filter;
  funds: Filter;
  documentTypes: Filter;
  tags: Filter;
  roles: Filter;
}

interface Props {
  filters: FilterObj;
  resetFilter: (key: keyof Filter, itemId?: number) => void;
  className?: string;
}

const TableAppliedFilters = ({ filters, resetFilter, className }: Props) => {
  const allFilterItems = Object.values(filters).flatMap(
    (filter) => filter.items,
  );

  if (allFilterItems.length === 0) return null;

  return (
    <div className={cn(className)}>
      {Object.keys(filters).map(
        (key) =>
          filters[key].items.length > 0 && (
            <FilterBar
              className="mr-s"
              key={key}
              title={filters[key].title}
              onClose={() => resetFilter(key)}
            >
              {filters[key].items.map((item) => (
                <FilterItem
                  label={item.name}
                  key={item.id}
                  onClose={() => resetFilter(key, item.id)}
                />
              ))}
            </FilterBar>
          ),
      )}
    </div>
  );
};

export default TableAppliedFilters;

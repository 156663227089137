import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiReconcileOperationalBudgetsBulk: build.mutation<
      PostApiReconcileOperationalBudgetsBulkApiResponse,
      PostApiReconcileOperationalBudgetsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiReconcileOperationalBudgetsBulkImport: build.mutation<
      PostApiReconcileOperationalBudgetsBulkImportApiResponse,
      PostApiReconcileOperationalBudgetsBulkImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/bulk/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiReconcileOperationalBudgetsBulkExport: build.query<
      GetApiReconcileOperationalBudgetsBulkExportApiResponse,
      GetApiReconcileOperationalBudgetsBulkExportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/bulk/export`,
        params: { budget_ids: queryArg.budgetIds },
      }),
    }),
    getApiReconcileOperationalBudgetsYearsByYearLegalEntities: build.query<
      GetApiReconcileOperationalBudgetsYearsByYearLegalEntitiesApiResponse,
      GetApiReconcileOperationalBudgetsYearsByYearLegalEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/years/${queryArg.year}/legal_entities`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          per_page: queryArg.perPage,
          query: queryArg.query,
          state: queryArg.state,
          asset_ids: queryArg.assetIds,
          legal_entity_ids: queryArg.legalEntityIds,
        },
      }),
    }),
    getApiReconcileOperationalBudgetsYears: build.query<
      GetApiReconcileOperationalBudgetsYearsApiResponse,
      GetApiReconcileOperationalBudgetsYearsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/years`,
        params: { sort: queryArg.sort },
      }),
    }),
    postApiReconcileOperationalBudgets: build.mutation<
      PostApiReconcileOperationalBudgetsApiResponse,
      PostApiReconcileOperationalBudgetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiReconcileOperationalBudgetsById: build.query<
      GetApiReconcileOperationalBudgetsByIdApiResponse,
      GetApiReconcileOperationalBudgetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/${queryArg.id}`,
      }),
    }),
    putApiReconcileOperationalBudgetsById: build.mutation<
      PutApiReconcileOperationalBudgetsByIdApiResponse,
      PutApiReconcileOperationalBudgetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReconcileOperationalBudgetsById: build.mutation<
      DeleteApiReconcileOperationalBudgetsByIdApiResponse,
      DeleteApiReconcileOperationalBudgetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReconcileOperationalBudgetsByIdExport: build.query<
      GetApiReconcileOperationalBudgetsByIdExportApiResponse,
      GetApiReconcileOperationalBudgetsByIdExportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/${queryArg.id}/export`,
      }),
    }),
    postApiReconcileOperationalBudgetsByIdImport: build.mutation<
      PostApiReconcileOperationalBudgetsByIdImportApiResponse,
      PostApiReconcileOperationalBudgetsByIdImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/operational/budgets/${queryArg.id}/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reconcileOperationalGeneratedApi };
export type PostApiReconcileOperationalBudgetsBulkApiResponse =
  /** status 200 success */ {
    items: OperationalBudgetDto[];
  };
export type PostApiReconcileOperationalBudgetsBulkApiArg = {
  body: {
    items: {
      year: number;
      legal_entity_id: string;
    }[];
  };
};
export type PostApiReconcileOperationalBudgetsBulkImportApiResponse =
  /** status 200 success */ {
    [key: string]: OperationalBudgetImportResultEntryDto;
  };
export type PostApiReconcileOperationalBudgetsBulkImportApiArg = {
  body: {
    file: Blob;
    type: 'symmetre';
  };
};
export type GetApiReconcileOperationalBudgetsBulkExportApiResponse = unknown;
export type GetApiReconcileOperationalBudgetsBulkExportApiArg = {
  budgetIds?: {
    ''?: number[];
  };
};
export type GetApiReconcileOperationalBudgetsYearsByYearLegalEntitiesApiResponse =
  /** status 200 success */ {
    items: OperationalBudgetYearLegalEntityDto[];
    meta: PaginationDto;
  };
export type GetApiReconcileOperationalBudgetsYearsByYearLegalEntitiesApiArg = {
  year: number;
  sort?: {
    field?: 'asset' | 'legal_entity' | 'state' | 'updated_at';
    order?: 'asc' | 'desc';
  };
  page?: number;
  perPage?: number;
  query?: string;
  state?: 'draft' | 'in_progress' | 'published' | 'no_budget';
  assetIds?: number[];
  legalEntityIds?: string[];
};
export type GetApiReconcileOperationalBudgetsYearsApiResponse =
  /** status 200 success */ {
    items: OperationalBudgetYearDto[];
    meta: {
      assets: OperationalBudgetYearAssetWithinMetaDto[];
      legalEntities: OperationalBudgetYearLegalEntityWithinMetaDto[];
    };
  };
export type GetApiReconcileOperationalBudgetsYearsApiArg = {
  sort?: {
    field?: 'year' | 'progress';
    order?: 'asc' | 'desc';
  };
};
export type PostApiReconcileOperationalBudgetsApiResponse =
  /** status 200 success */ OperationalBudgetDto;
export type PostApiReconcileOperationalBudgetsApiArg = {
  body: {
    year: number;
    legal_entity_id: string;
  };
};
export type GetApiReconcileOperationalBudgetsByIdApiResponse =
  /** status 200 success */ OperationalBudgetDto;
export type GetApiReconcileOperationalBudgetsByIdApiArg = {
  id: number;
};
export type PutApiReconcileOperationalBudgetsByIdApiResponse =
  /** status 200 success */ OperationalBudgetDto;
export type PutApiReconcileOperationalBudgetsByIdApiArg = {
  id: number;
  body: {
    state?: 'draft' | 'in_progress' | 'published';
  };
};
export type DeleteApiReconcileOperationalBudgetsByIdApiResponse = unknown;
export type DeleteApiReconcileOperationalBudgetsByIdApiArg = {
  id: number;
};
export type GetApiReconcileOperationalBudgetsByIdExportApiResponse = unknown;
export type GetApiReconcileOperationalBudgetsByIdExportApiArg = {
  id: number;
};
export type PostApiReconcileOperationalBudgetsByIdImportApiResponse =
  /** status 200 success */ {
    [key: string]: OperationalBudgetImportResultEntryDto;
  };
export type PostApiReconcileOperationalBudgetsByIdImportApiArg = {
  id: number;
  body: {
    file: Blob;
    type: 'symmetre' | 'symmetre_raw';
  };
};
export type OperationalBudgetDto = {
  id: number;
  year: number;
  state: 'draft' | 'in_progress' | 'published';
  legalEntity: {
    id: string;
    code: string;
    name: string;
    asset: {
      id: number;
      name: string;
      slug: string;
    };
  };
  createdAt: number;
  updatedAt: number;
};
export type OperationalBudgetImportResultEntryDto = {
  status: 'success' | 'ignored' | 'failure';
  errors: string[];
  warnings: string[];
};
export type OperationalBudgetYearLegalEntityDto = {
  id: string;
  code: string;
  name: string;
  asset: {
    id: number;
    name: string;
    slug: string;
    pictureUrl: string | null;
  };
  state: 'draft' | 'in_progress' | 'published' | 'no_budget';
  budgetId: number | null;
  updatedAt: number | null;
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};
export type OperationalBudgetYearDto = {
  year: number;
  stateCounts: {
    draft: number;
    in_progress: number;
    published: number;
    no_budget: number;
  };
};
export type OperationalBudgetYearAssetWithinMetaDto = {
  id: number;
  name: string;
};
export type OperationalBudgetYearLegalEntityWithinMetaDto = {
  id: string;
  code: string;
  name: string;
  assetId: number;
};
export type UnprocessableEntityDto = {
  errors?: string[];
};

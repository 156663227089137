import { groupBy } from 'lodash-es';
import { IChangeOrder, IESignCoordinate } from 'bundles/Construction/types';
import { ICOContractFile } from 'bundles/Construction/components/eSignature/types';
import pluralize from 'pluralize';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import React from 'react';

export const transformData = (
  data: IESignCoordinate[],
  contractFile: ICOContractFile,
) =>
  Object.entries(groupBy(data, 'approverId')).map(([approverId, markups]) => {
    const approvers =
      contractFile.approvers.length > 0
        ? contractFile.approvers
        : contractFile.defaultApprovers;
    const approver = approvers.find((a) => a.id === Number(approverId))!;

    return {
      user_id: approver.userId,
      user_role_id: approver.userRoleId,
      company_id: approver.companyId,
      priority: approver.priority,
      job_title: approver.jobTitle,
      markups: markups.map((m) => ({
        kind: m.kind,
        page_index: m.pageIndex,
        page_width: m.pageWidth,
        page_height: m.pageHeight,
        x: m.x,
        y: m.y,
      })),
    };
  });

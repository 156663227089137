import React from 'react';
import { Button, Tumbler } from '@/stories';
import { useFormContext } from 'react-hook-form';
import { DialogProps } from '@/shared/lib/hooks/useModal';

interface Props extends DialogProps {
  type: 'create' | 'edit';
  onRemove?: () => void;
  hideRepeat?: boolean;
}

export type CreateNewForm = {
  createNew: boolean;
};

export function EditPanelActions({
  type,
  onRemove,
  onClose,
  onSubmit,
  hideRepeat,
}: Props) {
  const {
    setValue,
    watch,
    formState: { isValid, isSubmitting, isDirty },
  } = useFormContext<CreateNewForm>();
  const createNewIsActive = watch('createNew');
  return (
    <>
      <Button
        disabled={isSubmitting}
        variant={type === 'create' ? 'secondary' : 'danger'}
        onClick={type === 'create' ? onClose : onRemove}
      >
        {type === 'create' ? 'Cancel' : 'Remove'}
      </Button>
      <div className="flex gap-tw-4">
        {type === 'create' && !hideRepeat && (
          <Tumbler
            className="!self-center"
            checked={createNewIsActive}
            onChange={(e) => {
              setValue('createNew', e.target.checked);
            }}
          >
            Repeat
          </Tumbler>
        )}
        <Button
          onClick={onSubmit}
          disabled={!isValid || isSubmitting || !isDirty}
          variant="success"
        >
          {type === 'create' ? 'Create' : 'Save Updates'}
        </Button>
      </div>
    </>
  );
}

/* eslint-disable spaced-comment */
import { useUpdateSharedFileMutation } from '@/bundles/Assets/components/Media/api/objectFoldersApi';
import { ViewSharedWithModalHeader } from '@/bundles/Assets/components/SharedFiles/Table/formatters/PermissionedUsers';
import {
  fetchPermissionBySharedFileId,
  fetchSubjectablesBySharedFileId,
} from '@/bundles/Shared/actions/permission_modal';
import { EditPermissionsModal } from '@/bundles/Shared/components/Permissions/EditPermissionsModal';
import { PermissionListLine } from '@/bundles/Shared/components/Permissions/PermissionListLine';
import { ViewPermissionedUsersModal } from '@/bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  getReportBuilderReportSubtitle,
  replaceTemplateWithReportLabel,
} from '@/bundles/Shared/features/dashboard/exportSettings/utils';
import { typedSharedFilePermissions } from '@/bundles/Shared/sharedFilePermissions';
import { ReportBuilderTemplatesModal } from '@/bundles/Shared/widgets/report/widgets/ui/ReportBuilderTemplatesModal';
import { ReportCard } from '@/entities/core/assets/reports';
import {
  useGetApiCoreAssetsByAssetIdReportBuilderReportsQuery,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsMutation,
} from '@/entities/report/reportBuilder';
import { AssetsReportBuilderPreviewReportDto } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { useDeleteReport } from '@/features/report/report/deleteReport';
import { currentUserAllowedToManageThisAssetAndReport } from '@/pages/assets/reports/permissions';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { yesterdayDate } from '@/shared/lib/date';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { TileList } from '@/shared/ui/TileList';
import { Button } from '@/stories';
import { IAsset } from '@/types/Asset';
import { ISharedDocument } from '@/types/SharedDocument';
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';

export const AssetReportsPage = ({
  assetId,
}: {
  assetId: IAsset['id'];
} & RouteComponentProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openModal } = useModal();
  const [pageParams, setPageParams] = useState({
    query: '',
  });

  const [updateSharedFile] = useUpdateSharedFileMutation();

  const { data, isFetching, refetch } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsQuery({
      assetId: String(assetId),
    });

  const handleEditPermissions = async (sharedId: ISharedDocument['id']) => {
    const res = await openModal(EditPermissionsModal, {
      // @ts-expect-error
      fetchSubjectables: () => fetchSubjectablesBySharedFileId(sharedId),
      objectableName: 'document',
    });
    if (res) {
      const sharedFilePermissionsReqBody = {
        id: sharedId,
        ...typedSharedFilePermissions(res),
      };
      await updateSharedFile({ shared_file: sharedFilePermissionsReqBody });
      refetch();
    }
  };

  const handleViewPermissions = async (sharedId: ISharedDocument['id']) => {
    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: () =>
        fetchPermissionBySharedFileId(sharedId),
      headerTitle: (
        <ViewSharedWithModalHeader
          row={{
            permissionsLocked: false,
          }}
          handleEditPermissions={() => handleEditPermissions(sharedId)}
        />
      ),
    });
  };

  const items = useMemo(() => {
    return data?.filter((i) =>
      isEmpty(pageParams.query)
        ? data
        : includesInLowerCase(i.name, pageParams.query),
    );
  }, [pageParams.query, data]);

  const [createReport, { isLoading }] =
    usePostApiCoreAssetsByAssetIdReportBuilderReportsMutation();

  const [handleDeleteReport] = useDeleteReport({
    assetId,
  });

  const handleCreateReport = async () => {
    const res = await openModal(ReportBuilderTemplatesModal, {});

    if (res == null) return;

    const response = await createReport({
      assetId: String(assetId),
      body: {
        date: formatToDateStringForRequest(yesterdayDate()),
        name: replaceTemplateWithReportLabel(res.name),
        template_id: res.id,
        subtitle: getReportBuilderReportSubtitle(),
      },
    });

    if ('error' in response) {
      return toastr.error(JSON.stringify(response.error));
    }

    navigate(
      generateUrl(ROUTES_ROOT.reports.report.fullPath, {
        pathParams: {
          id: response.data.id,
        },
        queryParams: {
          assetId,
        },
      }),
      {
        state: { from: pathname },
      },
    );
  };

  const handleReportClick = (item: AssetsReportBuilderPreviewReportDto) => {
    if (item.status === 'published') {
      if (item.sharedFile) {
        const url = generateUrl('/shared-files/:id', {
          pathParams: {
            id: String(item.sharedFile.id),
          },
          queryParams: {
            disposition: 'inline',
          },
        });

        navigate(url, {
          state: { from: pathname },
        });
      }
      return;
    }
    navigate(
      generateUrl(ROUTES_ROOT.reports.report.fullPath, {
        pathParams: {
          id: item.id,
        },
        queryParams: {
          assetId,
        },
      }),
      {
        state: { from: pathname },
      },
    );
  };

  const isAllowedToManage =
    currentUserAllowedToManageThisAssetAndReport(assetId);

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <TileList className="p-0">
        <TileList.Header>
          <TileList.ItemCounter count={items?.length ?? 0} />
          <div className="grow" />
          <PageParamsSearch />

          {isAllowedToManage && (
            <Button
              onClick={handleCreateReport}
              variant="primary"
              iconName="add"
              loading={isLoading}
              size="s"
            >
              Create Report
            </Button>
          )}
        </TileList.Header>

        <TileList.Items
          isLoading={isLoading}
          isListEmpty={items?.length === 0}
          isQueryEmpty={isEmpty(pageParams.query)}
          noDataOverlayTitle="No reports yet"
        >
          {items?.map((item) => (
            <ReportCard
              isAllowedToManage={isAllowedToManage}
              tooltipProps={
                item.sharedFile?.confidential
                  ? {
                      mainText: 'You are not allowed to see this report',
                    }
                  : {
                      disabled: true,
                    }
              }
              status={item.status}
              subtitle={item.subtitle}
              label={item.name}
              key={item.id}
              onDelete={() => {
                handleDeleteReport(item.id);
              }}
              permissionLine={
                item.sharedFile?.id != null && (
                  <PermissionListLine
                    onEdit={() => handleEditPermissions(item.sharedFile!.id)}
                    onClick={() => handleViewPermissions(item.sharedFile!.id)}
                    hidePublicIcon
                    hideActions
                    permissions={{
                      isPublic: item.sharedFile?.permitted?.isPublic ?? false,
                      //@ts-expect-error
                      directTags: item.sharedFile?.permitted?.tags ?? [],
                      directRoles: item.sharedFile?.permitted?.roles ?? [],
                      //@ts-expect-error
                      directUsers: item.sharedFile?.permitted?.users ?? [],
                      indirectUsers: [],
                      //@ts-expect-error
                      directInvestmentEntities:
                        item.sharedFile?.permitted?.investmentEntities ?? [],
                    }}
                  />
                )
              }
              onClick={
                item.sharedFile?.confidential
                  ? undefined
                  : () => handleReportClick(item)
              }
            />
          ))}
        </TileList.Items>
      </TileList>
    </PageParamsProvider>
  );
};

// https://www.ag-grid.com/react-data-grid/clipboard/#pasting-new-rows-at-the-bottom-of-the-grid
import { ProcessDataFromClipboardParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { transformAmount } from 'bundles/REconcile/components/operational/editor/helpers/transformAmounts';
import { useCallback } from 'react';

export function useProcessDataFromClipboard(
  gridRef: React.RefObject<AgGridReact>,
): (params: ProcessDataFromClipboardParams) => string[][] | null {
  return useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const rawData = [...params.data];
      const data = rawData.map((amounts) => amounts.map(transformAmount));

      const lastRowIndex = data.length - 1;
      const lastRow = data[lastRowIndex];

      const emptyLastRow = lastRow[0] === '' && lastRow.length === 1;

      if (emptyLastRow) {
        data.splice(lastRowIndex, 1);
      }

      const lastIndex = gridRef.current!.api.getModel().getRowCount() - 1;
      const focusedCell = gridRef.current!.api.getFocusedCell();
      const focusedIndex = focusedCell!.rowIndex;

      if (focusedIndex + lastRowIndex > lastIndex) {
        const resultLastIndex = focusedIndex + lastRowIndex;
        const numRowsToAdd = resultLastIndex - lastIndex;
        const rowsToAdd: unknown[] = [];
        for (let i = 0; i < numRowsToAdd; i++) {
          const index = lastRowIndex;
          const [row] = data.slice(index, index + 1);
          // Create row object
          const rowObject = {};
          let currentColumn: unknown = focusedCell!.column;
          row.forEach((item) => {
            if (!currentColumn) {
              return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            rowObject[currentColumn.colDef.field] = item;
            currentColumn = gridRef.current!.columnApi.getDisplayedColAfter(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              currentColumn,
            );
          });
          rowsToAdd.push(rowObject);
        }
        gridRef.current!.api.applyTransaction({ add: rowsToAdd });
      }
      return data;
    },
    [],
  );
}

import React from 'react';
import { LinkButton } from 'stories';
import pluralize from 'pluralize';

interface Props {
  count: number;
  onClear: () => void;
}

function PreviousSelectionLabel({ count, onClear }: Props) {
  return (
    <div className="flex justify-start">
      <span className="rounded-[0.5rem] px-075rem inline-regular mb-m border border-light-10 bg-white py-xs">
        <span className="inline-semibold">
          {count} {pluralize('Legal Entity', count)}
        </span>{' '}
        selected by previous session
        <LinkButton className="ml-s" onClick={onClear}>
          Clear Selection
        </LinkButton>
      </span>
    </div>
  );
}

export default PreviousSelectionLabel;

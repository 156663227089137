import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories';

export function LegalEntitiesCounter({
  legalEntityCount,
}: {
  legalEntityCount: number;
}) {
  return (
    <div className="flex items-center">
      <Icon className="text-light-60" iconName="entities" />
      <span
        className={cn(
          'secondary-regular flex w-[18px] items-center justify-center text-light-60',
          legalEntityCount > 9 && 'w-[24px]',
          legalEntityCount > 99 && 'w-[32px]',
        )}
      >
        {legalEntityCount}
      </span>
    </div>
  );
}

import { Invoice } from '@/shared/types/reconcile/Invoice';

export const hasRequisition = (invoice?: Invoice) =>
  Boolean(invoice?.requisition);

export const isInvoiceVerify = (invoice?: Invoice) =>
  invoice?.status === 'verified';

export const isInvoiceInDraw = (invoice?: Invoice) =>
  invoice?.funding === 'in_draw';

import React, { FC, SetStateAction, useMemo } from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { IReallocation } from 'bundles/Construction/types';
import { getItemsSubReallocationEditModal } from 'bundles/Construction/components/Reallocation/ReallocationDetails/initData';

interface ISubReallocationProps {
  formData: IReallocation;
  setFormData: SetStateAction<IReallocation>;
}

const SubReallocationsEdit: FC<ISubReallocationProps> = ({
  formData,
  setFormData,
}) => {
  const formItems = useMemo(
    () => getItemsSubReallocationEditModal(formData),
    [],
  );

  const updateReallocation = (data: IReallocation, idx: number) => {
    const newSubReallocation = formData.subReallocations.map(
      (subReallocation, idItem) => {
        if (idItem === idx) return data;
        return subReallocation;
      },
    );
    setFormData({
      ...formData,
      subReallocations: newSubReallocation,
    });
  };
  return (
    <div>
      {formData.subReallocations?.map((subReallocation, idx) => (
        <FormComponent
          key={`subRealllocation${idx}`}
          formData={subReallocation}
          setFormData={(newData) => updateReallocation(newData, idx)}
          formItems={formItems}
          styles={{
            wrapper: 'group-form__step-item bg-light',
          }}
          unitNum={idx + 1}
          unitNumTitle="SubREallocation"
        />
      ))}
    </div>
  );
};

export default SubReallocationsEdit;

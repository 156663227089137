import React from 'react';
import { IconButton, AssetInfoCard } from 'stories';

interface IRelatedParty {
  name: string;
  role: string;
  website: string;
}
interface Props {
  onEdit: () => void;
  onDelete: () => void;
  relatedParty: IRelatedParty;
  canManage: boolean;
}

export const AssetRelatedPartyCard = ({
  relatedParty,
  onEdit,
  onDelete,
  canManage,
}: Props) => (
  <AssetInfoCard
    content={
      <>
        {relatedParty.website ? (
          <a
            href={relatedParty.website}
            target="_blank"
            rel="noreferrer"
            className="body-semibold text-neutral-900"
          >
            {relatedParty.name}
          </a>
        ) : (
          <span className="body-semibold text-neutral-900">
            {relatedParty.name}
          </span>
        )}
        <div className="inline-regular mt-xxs text-neutral-500">
          {relatedParty.role}
        </div>
      </>
    }
    actions={
      canManage && (
        <>
          <IconButton onClick={onEdit} variant="secondary" iconName="edit" />
          <IconButton onClick={onDelete} variant="secondary" iconName="trash" />
        </>
      )
    }
  />
);

export default AssetRelatedPartyCard;

import {
  DISTRIBUTION_CATEGORIES_OPTIONS,
  RemainingDistribution,
} from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import { assertsType } from 'lib/typeHelpers/assertsType';
import { sumBy, uniqueId } from 'lodash-es';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type BulkDistributionContext = {
  onChangeRemainingDistribution: (item: RemainingDistribution) => void;
  onAddRemainingDistribution: () => void;
  onRemoveRemainingAllocation: (id: RemainingDistribution['id']) => void;
  remainingDistribution: RemainingDistribution[];
  hasEmptyKind: boolean;
  remainingKindOptions: typeof DISTRIBUTION_CATEGORIES_OPTIONS;
  currentRemainingDistributionAmount: number;
};

export const BulkDistributionContext =
  createContext<BulkDistributionContext | null>(null);

export function useBulkDistributionContext(): BulkDistributionContext {
  const context = useContext(BulkDistributionContext);
  assertsType<BulkDistributionContext>(context, (inp) => inp != null);

  return context;
}
const createEmptyDistribution = () =>
  ({
    id: uniqueId('remainingDistribution_'),
    kind: null,
    amount: 0,
  }) as const satisfies RemainingDistribution;

export const useBulkDistribution = (): BulkDistributionContext => {
  const [remainingDistribution, setRemainingDistribution] = useState<
    RemainingDistribution[]
  >([createEmptyDistribution()]);

  const onChangeRemainingDistribution = useCallback(
    (item: RemainingDistribution) => {
      setRemainingDistribution((prev) =>
        prev.map((prevItem) => (prevItem.id === item.id ? item : prevItem)),
      );
    },
    [],
  );

  const hasEmptyKind = useMemo(() => {
    const someWithEmptyKind = remainingDistribution.some(
      (item) => item.kind == null,
    );
    return someWithEmptyKind;
  }, [remainingDistribution]);

  const onAddRemainingDistribution = useCallback(() => {
    setRemainingDistribution((prev) => [...prev, createEmptyDistribution()]);
  }, []);

  const onRemoveRemainingAllocation = useCallback((id: string) => {
    setRemainingDistribution((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const remainingKindOptions = useMemo(() => {
    const usedKindOptions = remainingDistribution.map(
      ({ kind: localKind }) => localKind,
    );
    return DISTRIBUTION_CATEGORIES_OPTIONS.filter(
      ({ id }) => id !== 'preferred',
    ).map((option) => {
      if (!usedKindOptions.includes(option.id)) return option;

      return {
        ...option,
        disabled: true,
      };
    });
  }, [remainingDistribution]);

  const currentRemainingDistributionAmount = useMemo(() => {
    const res = sumBy(remainingDistribution, 'amount');
    return res;
  }, [remainingDistribution]);

  return {
    remainingDistribution,
    hasEmptyKind,
    onAddRemainingDistribution,
    onChangeRemainingDistribution,
    remainingKindOptions,
    onRemoveRemainingAllocation,
    currentRemainingDistributionAmount,
  };
};

import React, { ComponentProps } from 'react';
import ConfirmationModal from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { startCase } from 'lodash-es';
import { IUser } from 'types/User';

export const getInviteConfirmationParams = (
  user: IUser,
): Omit<ComponentProps<typeof ConfirmationModal>, 'handleResolve'> => ({
  title: `Invite ${startCase(user.fullName)}`,
  subtitle: (
    <p>
      Are you sure you want to send this invitation email?
      <br />
      You will not be able to undo this action.
    </p>
  ),
  actions: {
    primaryButton: {
      text: 'Yes',
      variant: 'success',
    },
    secondaryButton: {
      text: 'Cancel',
      variant: 'secondary',
    },
  },
});

import { useGetApiSettingsReportThirdPartyExtractionsConnectionsQuery } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { LayoutProps } from '@/types/Props';
import { navigate } from '@reach/router';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams/PageParamsContext';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { ConnectionPreviewCard } from 'bundles/Shared/entities/connection/ui/ConnectionPreviewCard';
import { random } from 'lodash-es';
import { AnimationLoader } from 'stories/index';

const ConnectionTiles = () => {
  const { pageParams } =
    usePageParamsContext<GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg>();
  const { data: connections, isLoading } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsQuery({
      ...pageParams,
    });

  const openConnectionPage = (id: IConnection['id']) => {
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.connections.connection.fullPath, {
        pathParams: {
          connectionId: String(id),
        },
      }),
    );
  };

  return (
    <ConnectionTiles.Wrapper>
      {connections?.companies.map(
        (item) =>
          Boolean(item.connections.length) && (
            <ConnectionTiles.Tile key={item.companyName}>
              <div className="header5-bold text-neutral-800">
                {item.companyName}
              </div>
              <div className="grid grid-cols-4 gap-tw-6 lg:grid-cols-3">
                {(item.connections as unknown as IConnection[]).map(
                  (connection, idx) => (
                    <ConnectionPreviewCard
                      key={idx}
                      onClick={() => openConnectionPage(connection.id)}
                      {...connection}
                    />
                  ),
                )}
              </div>
            </ConnectionTiles.Tile>
          ),
      )}
      {isLoading && <AnimationLoader />}
    </ConnectionTiles.Wrapper>
  );
};

ConnectionTiles.Tile = (props: LayoutProps) => (
  <div className={cn('flex flex-col gap-tw-6', props.className)}>
    {props.children}
  </div>
);
ConnectionTiles.Wrapper = (props: LayoutProps) => (
  <div className={cn('flex flex-col gap-tw-8', props.className)}>
    {props.children}
  </div>
);

ConnectionTiles.Fetching = () => (
  <ConnectionTiles.Wrapper>
    {Array.from({ length: 4 }, (_, idx) => (
      <ConnectionTiles.Tile key={idx}>
        <SkeletonBlock className="h-[24px] w-[200px]" />
        <div className="grid grid-cols-4 gap-tw-6 lg:grid-cols-3">
          {Array.from({ length: random(1, 3) }, (__, jIdx) => (
            <SkeletonBlock
              key={jIdx}
              className="h-[150px] w-[280px] rounded-2xl"
            />
          ))}
        </div>
      </ConnectionTiles.Tile>
    ))}
  </ConnectionTiles.Wrapper>
);
ConnectionTiles.Loading = ConnectionTiles.Fetching;

export default ConnectionTiles;

import {
  IMutateReportTableConfigPayload,
  IPeriodsLimitations,
  IReportTableConfig,
  IUserFinancialCategoryRule,
  IUserRoleFinancialCategoryRule,
} from 'types/ReportTableConfig';
import { PUBLIC_PERMISSION_ATTRIBUTES } from './constants';
import { createRestrictedPermissionAttributes } from './utils';

interface Params {
  periodsLimitations: IPeriodsLimitations;
  columnsConfig: string;
  rowsConfig: string;
  permitted: IReportTableConfig['permitted'];
  roleRules: IUserRoleFinancialCategoryRule[];
  userRules: IUserFinancialCategoryRule[];
}

type RoleRulesPayload = Pick<
  IMutateReportTableConfigPayload,
  'user_role_financial_category_rules'
>;
type UserRulesPayload = Pick<
  IMutateReportTableConfigPayload,
  'user_financial_category_rules'
>;

export function getUpdateReportTableConfigPayload({
  periodsLimitations,
  columnsConfig,
  rowsConfig,
  permitted,
  userRules,
  roleRules,
}: Params): IMutateReportTableConfigPayload {
  const createPermissionsPayload = () => {
    if (permitted.public) {
      return PUBLIC_PERMISSION_ATTRIBUTES;
    }
    const userIds = permitted.directUsers.map((u) => u.id);
    const roleIds = permitted.directRoles?.map((r) => r.id) ?? [];
    const tagIds = permitted.directTags.map((t) => t.id);

    return createRestrictedPermissionAttributes({
      userIds,
      roleIds,
      tagIds,
    });
  };

  const permissionsPayload = createPermissionsPayload();

  const rolesRulesPayload: RoleRulesPayload = {
    user_role_financial_category_rules: roleRules.map((rule) => ({
      financial_category_path: rule.categoryPath,
      user_role_id: rule.userRole.id,
      access_type: rule.accessType,
    })),
  };

  const userRulesPayload: UserRulesPayload = {
    user_financial_category_rules: userRules.map((rule) => ({
      financial_category_path: rule.categoryPath,
      user_id: rule.user.id,
      access_type: rule.accessType,
    })),
  };

  return {
    periods_limitations: periodsLimitations,
    columns_config: JSON.parse(columnsConfig),
    rows_config: JSON.parse(rowsConfig),

    ...permissionsPayload,
    ...rolesRulesPayload,
    ...userRulesPayload,
  };
}

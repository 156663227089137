import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Formula } from 'bundles/Shared/entities/formula';
import { TRootState } from '@/app/stores';
import { EditFormulaForm } from 'bundles/Shared/features/formula/editFormula';
import { uniqBy } from 'lodash-es';
import { settingsReportFormulasApiEnhanced } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';

const initialState: {
  openedFormulas: EditFormulaForm[];
  currentFormulaIndex: number | null;
  opened?: boolean;
  lastSelectedFormulaReference: string | null;
} = {
  openedFormulas: [],
  currentFormulaIndex: null,
  lastSelectedFormulaReference: null,
};

export const formulasSettingsSlice = createSlice({
  name: 'formulasSettings',
  reducers: {
    openFormulasSettings: (state) => {
      state.opened = !state.opened;
    },
    openFormula: (state, action: PayloadAction<EditFormulaForm>) => {
      state.opened = true;
      const formulaIndex = state.openedFormulas.findIndex(
        (formula) => formula.id === action.payload.id,
      );
      if (formulaIndex !== -1) {
        state.currentFormulaIndex = formulaIndex;
        return;
      }
      const newFormulas = [...state.openedFormulas, action.payload];
      state.openedFormulas = newFormulas;
      state.currentFormulaIndex = newFormulas.length - 1;
    },
    openFormulas: (state, action: PayloadAction<Formula[]>) => {
      state.openedFormulas = uniqBy(
        [...state.openedFormulas, ...action.payload],
        'id',
      );
      if (state.currentFormulaIndex == null) {
        state.currentFormulaIndex = state.openedFormulas.length - 1;
      }
    },
    updateCurrentFormulaIndex: (state, action: PayloadAction<number>) => {
      state.currentFormulaIndex = action.payload;
    },
    updateFormulaByIndex: (
      state,
      action: PayloadAction<{ index: number; formula: EditFormulaForm }>,
    ) => {
      const { index, formula } = action.payload;
      state.openedFormulas[index] = formula;
    },
    closeFormula: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.openedFormulas = state.openedFormulas.filter((_, i) => i !== index);
      const lastFormulaIndex = state.openedFormulas.length - 1;
      state.currentFormulaIndex =
        lastFormulaIndex < 0 ? null : lastFormulaIndex;
    },
    closeAllFormulas: (state) => {
      state.openedFormulas = [];
      state.currentFormulaIndex = null;
    },
    resetFormulasState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      settingsReportFormulasApiEnhanced.endpoints.getApiSettingsReportFormulas
        .matchFulfilled,
      (state, action) => {
        const newFormulas = state.openedFormulas.map((formula) => {
          const foundFormula = action.payload.find((f) => f.id === formula.id);
          return foundFormula ?? null;
        });
        state.openedFormulas = newFormulas.filter(
          (formula) => formula != null,
        ) as EditFormulaForm[];
        if (newFormulas.some((formula) => formula == null)) {
          state.currentFormulaIndex = state.openedFormulas.length - 1;
        }
      },
    );
  },
  initialState,
});

export const {
  openFormula,
  closeFormula,
  updateCurrentFormulaIndex,
  updateFormulaByIndex,
  openFormulas,
  resetFormulasState,
  openFormulasSettings,
  closeAllFormulas,
} = formulasSettingsSlice.actions;

export const selectFormulasSettingsIsOpened = (state: TRootState) =>
  state.formulasSettings.opened;
export const selectOpenedFormulas = (state: TRootState) =>
  state.formulasSettings.openedFormulas;
export const selectOpenedFormulasCount = (state: TRootState) =>
  state.formulasSettings.openedFormulas.length;

export const selectCurrentFormulaIndex = (state: TRootState) =>
  state.formulasSettings.currentFormulaIndex;
export const selectCurrentFormula = (state: TRootState) => {
  const currentFormulaTabIndex = selectCurrentFormulaIndex(state);
  if (currentFormulaTabIndex == null) {
    return null;
  }
  return selectOpenedFormulas(state)[currentFormulaTabIndex];
};

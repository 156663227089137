import { reportGeneralLedgersApi } from 'bundles/Settings/actions/report/generalLedgersApiGeneratedApi';

export const REPORT_GENERAL_LEDGERS_TAG = 'ReportGeneralLedgers';
export const REPORT_GENERAL_LEDGERS_META_TAG = 'ReportGeneralLedgersMeta';
const REPORT_GENERAL_LEDGERS_QUICK_MAP_SUGGESTIONS_TAG =
  'ReportGeneralLedgersQuickMapSuggestions';

export const reportGeneralLedgersApiEnhanced =
  reportGeneralLedgersApi.enhanceEndpoints({
    addTagTypes: [
      REPORT_GENERAL_LEDGERS_TAG,
      REPORT_GENERAL_LEDGERS_META_TAG,
      REPORT_GENERAL_LEDGERS_QUICK_MAP_SUGGESTIONS_TAG,
    ],
    endpoints: {
      getApiSettingsReportGeneralLedgers: {
        providesTags: [REPORT_GENERAL_LEDGERS_TAG],
      },
      getApiSettingsReportGeneralLedgersMeta: {
        providesTags: [REPORT_GENERAL_LEDGERS_META_TAG],
      },
      putApiSettingsReportGeneralLedgersExcludeAll: {
        invalidatesTags: [
          REPORT_GENERAL_LEDGERS_TAG,
          REPORT_GENERAL_LEDGERS_META_TAG,
        ],
        transformResponse: (response) => {
          toastr.success('General Ledgers successfully excluded');
          return response;
        },
      },
      putApiSettingsReportGeneralLedgersIncludeAll: {
        invalidatesTags: [
          REPORT_GENERAL_LEDGERS_TAG,
          REPORT_GENERAL_LEDGERS_META_TAG,
        ],
        transformResponse: (response) => {
          toastr.success('General Ledgers successfully included');
          return response;
        },
      },
      putApiSettingsReportGeneralLedgersSetCategory: {
        invalidatesTags: [
          REPORT_GENERAL_LEDGERS_TAG,
          REPORT_GENERAL_LEDGERS_META_TAG,
        ],
        transformResponse: (response) => {
          toastr.success('General Ledger category successfully updated');
          return response;
        },
      },
      getApiSettingsReportGeneralLedgersQuickMapSuggestions: {
        providesTags: [REPORT_GENERAL_LEDGERS_QUICK_MAP_SUGGESTIONS_TAG],
      },
      putApiSettingsReportGeneralLedgersQuickMapping: {
        invalidatesTags: [REPORT_GENERAL_LEDGERS_QUICK_MAP_SUGGESTIONS_TAG],
        transformResponse: (response) => {
          toastr.success('General Ledger mapping successfully updated');
          return response;
        },
      },
      putApiSettingsReportGeneralLedgersFlag: {
        invalidatesTags: [REPORT_GENERAL_LEDGERS_TAG],
        transformResponse: (response) => {
          toastr.success('General Ledger(s) successfully flagged');
        },
      },
      putApiSettingsReportGeneralLedgersUnflag: {
        invalidatesTags: [REPORT_GENERAL_LEDGERS_TAG],
        transformResponse: (response) => {
          toastr.success('General Ledger(s) flag removed');
        },
      },
    },
  });

export const {
  useGetApiSettingsReportGeneralLedgersMetaQuery,
  useGetApiSettingsReportGeneralLedgersQuery,
  useGetApiSettingsReportGeneralLedgersQuickMapSuggestionsQuery,
  usePutApiSettingsReportGeneralLedgersFlagMutation,
  usePutApiSettingsReportGeneralLedgersUnflagMutation,
  usePutApiSettingsReportGeneralLedgersExcludeAllMutation,
  usePutApiSettingsReportGeneralLedgersIncludeAllMutation,
  usePutApiSettingsReportGeneralLedgersSetCategoryMutation,
  usePutApiSettingsReportGeneralLedgersQuickMappingMutation,
} = reportGeneralLedgersApi;

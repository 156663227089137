import React, { useEffect, useMemo, useState } from 'react';
import { Icon, Tooltip, Tumbler } from 'stories';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { EMPTY_NOTIFICATIONS } from 'bundles/Notifications/components/dumbJSX';
import NotificationCard from 'bundles/Notifications/components/NotificationCard';
import { fetchNotifications, hideAllNotifications } from '../actions/index';

function Notifications() {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications.all);
  const [hideReadNotifications, setHideReadNotifications] = useState(false);

  const filteredNotifications = useMemo(
    () =>
      (notifications ?? []).filter(
        (notification) => !hideReadNotifications || !notification.viewed,
      ),
    [notifications, hideReadNotifications],
  );

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  if (!notifications) return null;

  return (
    <div className="container mt-[50px]">
      <div className="mb-tw-5 flex">
        <h4 className="text-bootstrap-blue text-center">
          Notifications center
        </h4>
        <div className="ml-auto">
          <Tumbler
            reverse
            defaultChecked={hideReadNotifications}
            onChange={(e) => setHideReadNotifications(e.target.checked)}
          >
            Hide read notifications
          </Tumbler>
          <Tooltip mainText="Mark all as read">
            <Icon
              iconName="check"
              className="notifications__read-all"
              data-tip="Mark all as read"
              onClick={() => dispatch(hideAllNotifications())}
            />
          </Tooltip>
        </div>
      </div>
      {filteredNotifications.length === 0 && EMPTY_NOTIFICATIONS}
      {filteredNotifications.map((notification) => (
        <NotificationCard key={notification.id} {...notification} />
      ))}
    </div>
  );
}

export default Notifications;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props extends React.AllHTMLAttributes<HTMLElement> {
  phone: string;
}

export const PhoneLink = ({ phone, children, className, ...props }: Props) => (
  <a
    href={`tel:${phone}`}
    className={cn('whitespace-nowrap text-info-055', className)}
    {...props}
  >
    {children}
  </a>
);

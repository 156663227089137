import { usePublishReport } from '@/features/report/report/publishReport/lib';
import { Button } from '@/stories';

export const PublishReportButton = (props: {
  reportId: string;
  assetId: number;
}) => {
  const [publish, options] = usePublishReport(props);

  return (
    <Button
      loading={options.isLoading}
      disabled={options.isLoading}
      variant="success"
      className="w-max"
      onClick={publish}
    >
      Publish
    </Button>
  );
};

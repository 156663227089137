import { isNodeTotalFooter } from 'lib/ag-grid/utils';
import {
  isBudgetCategoryRow,
  isBudgetJccRow,
  isBudgetSovcRow,
  TDevelopmentBudgetDataRowValues,
} from 'bundles/REconcile/types/BudgetRows';
import {
  ColDef,
  IAggFunc,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { sum } from 'lodash-es';
import { AUTO_GROUP_COLUMN_KEY } from 'lib/ag-grid/constants';

export const PLACEHOLDER_COLUMN = 'PLACEHOLDER_COLUMN';

export const autoGroupColumnFormatter = ({
  data,
  node,
}: ValueFormatterParams) => {
  if (isNodeTotalFooter(node)) {
    return 'TOTALS';
  }
  if (data) {
    let label = null;
    if (isBudgetCategoryRow(data)) {
      label = data.label;
    }
    if (isBudgetJccRow(data)) {
      label = data.jobCostCode.name;
    }
    if (isBudgetSovcRow(data)) {
      label = data.scheduleOfValueCode.name;
    }
    if (node.footer) {
      label = `Totals ${label}`;
    }
    return label;
  }
};
export const autoGroupColumnFormatterSimplified = ({
  data,
  node,
}: ValueFormatterParams) => {
  if (isNodeTotalFooter(node)) return 'TOTALS';

  if (data) {
    return node.footer ? `Totals ${data.label}` : data.label;
  }
};

export const getRevisionValueGetter =
  (columnKey: string) =>
  ({ data: row }: ValueGetterParams) => {
    if (row && (isBudgetSovcRow(row) || isBudgetJccRow(row))) {
      return row.data[columnKey];
    }
    return undefined;
  };

export const parseData = (data: TDevelopmentBudgetDataRowValues) =>
  Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k, v != null ? parseFloat(v) : null]),
  );

export const customAggSumFunc: IAggFunc = ({
  values,
  data: row,
  column,
  rowNode,
  api,
}) => {
  if (row && isBudgetJccRow(row)) {
    const value = row.data[column.getColDef().field];
    if (value != null) {
      return value;
    }
  }
  return sum(values);
};

export const isCellEditable = ({
  node,
  data,
  colDef,
}: {
  node: RowNode;
  data: any;
  colDef: ColDef;
}) =>
  colDef.colId !== AUTO_GROUP_COLUMN_KEY &&
  colDef.colId !== PLACEHOLDER_COLUMN &&
  !node.footer &&
  data &&
  (isBudgetSovcRow(data) || isBudgetJccRow(data));

import React, { FC } from 'react';
import { CurrencyFormatter, StatusItem } from 'stories';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import { generateTypeStatusItem } from '../utils';
import BoardCard from './BoardCard';

const VerifyInvoiceCard: FC<Invoice> = ({
  number,
  amount,
  date,
  vendor,
  approval,
  funding,
}) => {
  const approvalSettings = generateTypeStatusItem({
    type: 'approval',
    approval,
  });

  const fundingSettings = generateTypeStatusItem({
    type: 'funding',
    funding,
  });
  return (
    <BoardCard number={number}>
      <div className="flex flex-col">
        <div className="mb-tw-2">
          <CurrencyFormatter
            classes={{
              wrapper: 'header5-regular',
            }}
            value={amount}
          />
        </div>
        <div className="mb-tw-3 flex gap-tw-1">
          <span className="light-90 label-regular">{date}</span>
          <span className="light-60 secondary-regular">•</span>
          <span className="light-90 label-regular">{vendor.name}</span>
        </div>
        <div className="flex">
          <StatusItem
            title="Approval"
            subTitle={approvalSettings.title}
            type={approvalSettings.type}
          />
          <StatusItem
            title="Funding"
            subTitle={fundingSettings.title}
            type={fundingSettings.type}
          />
        </div>
      </div>
    </BoardCard>
  );
};

export default VerifyInvoiceCard;

import { DATE_STRING_FORMAT } from '@/shared/lib/converters';
import { isSameOrBeforeDate } from '@/shared/lib/date';
import { formatDate } from '@/shared/lib/formatting/dates';
import dayjs, { UnitTypeLong } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { takeRight, uniqBy } from 'lodash-es';
import { IReportTableConfig } from 'types/ReportTableConfig';
import * as Const from './consts';
import * as Types from './types';
import { DefaultMonthDateRange } from './types';

dayjs.extend(quarterOfYear);

export const createPeriod = ({
  monthDigits,
  year,
}: Record<'monthDigits' | 'year', string>) =>
  `${year}-${monthDigits}-01` as DateString;

export function createPeriodItemList(
  allMonthDigits: Types.MonthDigitsOfQuarterList | Types.MonthDigitsList,
  year: string,
  selectedPeriodType: Types.IPeriodItem['type'],
): Types.IPeriodItem[] {
  return allMonthDigits.map((monthDigits: string) => ({
    period: createPeriod({ year, monthDigits }),
    type: selectedPeriodType,
  }));
}

export const getLatestYearFromEntries = (
  entries: Entries<string, Entries<Types.QuarterMapKey, number[]>>,
) => entries?.at(-1)?.[0] ?? String(dayjs().year());

export const createPeriodItem = ({
  period,
  type,
}: Types.IPeriodItem): Types.IPeriodItem => ({ period, type });

export const uniqPeriodsByYear = (
  periods: Types.IPeriodItem['period'][],
): Types.IPeriodItem['period'][] =>
  uniqBy(periods, (period) => formatDate(period, 'YYYY'));

export const uniqPeriodsByQuarter = (
  periods: Types.IPeriodItem['period'][],
): Types.IPeriodItem['period'][] =>
  uniqBy(periods, (period) => formatDate(period, 'Q-YY'));

export const createPeriodFromDate = (date: Date) =>
  dayjs(date).format(DATE_STRING_FORMAT) as DateString;

export const createMonthPeriodItem = (period: DateString) =>
  createPeriodItem({ period, type: Const.PeriodItemType.Mtd });

export const createQuarterPeriodItem = (period: DateString) =>
  createPeriodItem({ period, type: Const.PeriodItemType.Qtd });

export const createYearPeriodItem = (period: DateString) =>
  createPeriodItem({ period, type: Const.PeriodItemType.Ytd });

export const filterPeriodIsSameOrBeforeMonth = (period: Date) => {
  return isSameOrBeforeDate(period, new Date(), 'month');
};

export const resolveSelectAllTitle = (allSelected: boolean) =>
  allSelected ? 'Deselect All' : 'Select All';

const SINGLE_MONTH_PERIODS_TYPE_LIST = [
  'variance_report',
  'single_mtd',
  'single_mtd_and_ytd',
  'single_mtd_qtd_and_ytd',
  'single_mtd_qtd_and_itd',
  'single_mtd_qtd_and_uw_itd',
  'single_qtd_and_ytd',
  'single_qtd_ytd_and_itd',
  'single_qtd_ytd_and_uw_itd',
  'single_t3_and_t12',
] as const satisfies readonly IReportTableConfig['periodsType'][];

export const isSingleMonthSelection = (
  type: IReportTableConfig['periodsType'],
) => SINGLE_MONTH_PERIODS_TYPE_LIST.includes(type);

export class ReportTableConfigType {
  #type: IReportTableConfig['periodsType'];

  constructor(type: IReportTableConfig['periodsType']) {
    this.#type = type;
  }

  get isSingleMonthSelection() {
    return SINGLE_MONTH_PERIODS_TYPE_LIST.includes(this.#type);
  }

  get isCommonTable() {
    switch (this.#type) {
      case 'year_mtd':
      case 'mtd':
      case 'mtd_and_total':
      case 'all_time_to_date':
        return true;

      default:
        return false;
    }
  }
}

export const getMonthDigit = (n: number) =>
  String(n).padStart(2, '0') as Types.MonthDigitsList[number];

export const getYearFromPeriod = (period: Types.IPeriodItem['period']) =>
  dayjs(period).year();

export const getAvailableMonthDigitList = ({
  yearWithQuartersEntries,
  year,
}: {
  year: string;
  yearWithQuartersEntries: Entry<
    string,
    Entries<Types.QuarterMapKey, number[]>
  >[];
}) => {
  const quarters = Object.fromEntries(yearWithQuartersEntries)[year] ?? [];
  const availableMonthDigitList = quarters.flatMap(([_, monthNumbers]) =>
    monthNumbers.map(getMonthDigit),
  );

  return availableMonthDigitList;
};

export const createMonthName = (monthDigits: string) => {
  const monthIdx = Number(monthDigits) - 1;
  const monthName = dayjs().month(monthIdx).format('MMM');
  return monthName;
};

export const isQuarterDisabled = (monthNumbers: number[]) =>
  monthNumbers.length === 0;

export const uniqByPeriod = (periodItems: Types.IPeriodItem[]) =>
  uniqBy(periodItems, 'period');

export const getAllPeriods = (periodItems: Types.IPeriodItem[]) =>
  periodItems.map(({ period }) => period);

export const getShortYear = (year: string) => dayjs(year).format('YY');

export const getMonthName = (monthNumber: number) =>
  dayjs()
    .month(monthNumber - 1)
    .format('MMM');

export function getMonthNumberFromPeriod(period: Types.IPeriodItem['period']) {
  return dayjs(period).month() + 1;
}

export const preparePeriodForMonthlyForLabel = (
  period: Types.IPeriodItem['period'],
) => dayjs(period).format('MMM-YY');

export const filterSelectedPeriodItems = (
  periodItems: Types.IPeriodItem[],
  allPeriods: Types.IPeriodItem['period'][],
) => periodItems.filter(({ period }) => allPeriods.includes(period));

export const filterOutSelectedPeriodItems = (
  periodItems: Types.IPeriodItem[],
  allPeriods: Types.IPeriodItem['period'][],
) => periodItems.filter(({ period }) => !allPeriods.includes(period));

export function createArrOfSequences(sortedArr: number[]) {
  const res: number[][] = [];
  let currentIdx = 0;

  for (let idx = 0; idx < sortedArr.length; idx++) {
    const element = sortedArr[idx];

    if (idx === 0) {
      res[0] = [element];
      continue;
    }

    const possiblePrevNumber = element - 1;
    const prevElement = sortedArr[idx - 1];

    if (possiblePrevNumber === prevElement) {
      res[currentIdx].push(element);
      continue;
    }

    currentIdx += 1;
    res[currentIdx] = [element];
  }

  return res;
}

export function createQuarterEntries(allMonthNumberArr: number[]) {
  const quartersObj: Record<Types.QuarterMapKey, number[]> = {
    q1: [],
    q2: [],
    q3: [],
    q4: [],
  };

  allMonthNumberArr.forEach((monthNumber) => {
    switch (true) {
      case Const.QUARTER_MONTH_NUMBER_MAP[1].includes(monthNumber):
        quartersObj.q1.push(monthNumber);
        return;
      case Const.QUARTER_MONTH_NUMBER_MAP[2].includes(monthNumber):
        quartersObj.q2.push(monthNumber);
        return;
      case Const.QUARTER_MONTH_NUMBER_MAP[3].includes(monthNumber):
        quartersObj.q3.push(monthNumber);
        return;
      case Const.QUARTER_MONTH_NUMBER_MAP[4].includes(monthNumber):
        quartersObj.q4.push(monthNumber);
        return;
      default:
        return;
    }
  });

  const entries = Object.entries(quartersObj) as Entries<
    Types.QuarterMapKey,
    number[]
  >;

  return entries;
}

export function createLatestYearsPeriodItems(yearArrForYearly: string[]) {
  const latestTwoYears = takeRight(yearArrForYearly, 2);
  const latestTwoYearsPeriodItems = latestTwoYears.map((year) =>
    createPeriodItem({
      period: createPeriod({
        monthDigits: '01',
        year,
      }),
      type: Const.PeriodItemType.Ytd,
    }),
  );

  return latestTwoYearsPeriodItems;
}

export function createLatestTwoQuartersPeriodItems(
  possiblePeriods: Types.IPeriodItem['period'][],
) {
  if (possiblePeriods.length < 2) {
    return [];
  }
  const latestPeriod = possiblePeriods.at(possiblePeriods.length - 1);
  const latestYear = dayjs(latestPeriod).year();
  const latestQuarter = dayjs(latestPeriod).quarter();

  const latestTwo = Array.from({ length: 2 }, (_, idx) => ({
    quarterNumber: latestQuarter - idx || 4,
    yearNumber: latestQuarter - idx ? latestYear : latestYear - 1,
  }));

  const latestTwoPeriodItems = latestTwo.map(
    ({ quarterNumber, yearNumber }) => {
      const period = createPeriod({
        year: String(yearNumber),
        monthDigits: getMonthDigit(
          Const.QUARTER_MONTH_NUMBER_MAP[quarterNumber][0],
        ),
      });
      return createPeriodItem({ period, type: Const.PeriodItemType.Qtd });
    },
  );

  return latestTwoPeriodItems;
}

export function createArrOfSequencesForMonthlyPeriodType(
  periodItems: Types.IPeriodItem[],
) {
  const sortedPeriods = periodItems.map(({ period }) => period);
  const monthNumber = (period: string) => dayjs(period).month() + 1;

  const res: Types.IPeriodItem['period'][][] = [];
  let currentIdx = 0;
  const lastMonthNumber = 12;

  for (let idx = 0; idx < sortedPeriods.length; idx++) {
    const item = sortedPeriods[idx];

    if (idx === 0) {
      res[0] = [item];
      continue;
    }

    const itemMonthNumber = monthNumber(item);

    const possiblePrevMonthNumber = itemMonthNumber - 1 || lastMonthNumber;

    const prevItem = sortedPeriods[idx - 1];

    const prevItemMonthNumber = monthNumber(prevItem);

    const isPrevMonthNumberCorrect =
      possiblePrevMonthNumber === prevItemMonthNumber;

    const itemYearNumber = getYearFromPeriod(item);
    const possiblePrevYearNumber = itemYearNumber - 1;
    const prevItemYearNumber = getYearFromPeriod(prevItem);

    const isPrevMonthNumberLast = possiblePrevMonthNumber === lastMonthNumber;
    const isPrevYearNumberCorrect =
      possiblePrevYearNumber === prevItemYearNumber;

    if (isPrevMonthNumberLast && !isPrevYearNumberCorrect) {
      currentIdx += 1;
      res[currentIdx] = [item];
      continue;
    }

    if (isPrevMonthNumberCorrect) {
      res[currentIdx].push(item);
      continue;
    }

    currentIdx += 1;
    res[currentIdx] = [item];
  }

  return res;
}

export const getQuarterNumber = (period: Types.IPeriodItem['period']) =>
  dayjs(period).quarter();

export function createArrOfSequencesForQuarterlyPeriodType(
  periodItems: Types.IPeriodItem[],
) {
  const periodList = periodItems.map(({ period }) => period);

  const res: Types.IPeriodItem['period'][][] = [];
  let currentIdx = 0;
  const lastQuarterNumber = 4;

  for (let idx = 0; idx < periodList.length; idx++) {
    const period = periodList[idx];

    if (idx === 0) {
      res[0] = [period];
      continue;
    }

    const itemQuarterNumber = getQuarterNumber(period);

    const possiblePrevQuarterNumber =
      itemQuarterNumber - 1 || lastQuarterNumber;

    const prevPeriod = periodList[idx - 1];

    const prevPeriodQuarterNumber = getQuarterNumber(prevPeriod);

    const isPrevQuarterNumberCorrect =
      possiblePrevQuarterNumber === prevPeriodQuarterNumber;

    const periodYearNumber = getYearFromPeriod(period);
    const possiblePrevYearNumber = periodYearNumber - 1;
    const prevPeriodYearNumber = getYearFromPeriod(prevPeriod);

    const isPrevQuarterNumberLast =
      possiblePrevQuarterNumber === lastQuarterNumber;
    const isPrevYearNumberCorrect =
      possiblePrevYearNumber === prevPeriodYearNumber;

    if (isPrevQuarterNumberLast && !isPrevYearNumberCorrect) {
      currentIdx += 1;
      res[currentIdx] = [period];
      continue;
    }

    if (isPrevQuarterNumberCorrect) {
      res[currentIdx].push(period);
      continue;
    }

    currentIdx += 1;
    res[currentIdx] = [period];
  }

  return res;
}

export const resolveDayjsRange = (
  thisDayjs: dayjs.Dayjs,
  dayjsA: dayjs.Dayjs,
  dayjsB: dayjs.Dayjs,
): boolean => {
  const isAfterOrEqualYearA = dayjsA.year() <= thisDayjs.year();
  const isAfterMonthA = dayjsA.month() < thisDayjs.month();
  const isYearBLessThanYearA = dayjsB.year() > dayjsA.year();
  const isBeforeMonthB = dayjsB.month() > thisDayjs.month();

  const isBeforeOrEqualYearA = dayjsA.year() >= thisDayjs.year();
  const isBeforeMonthA = dayjsA.month() > thisDayjs.month();
  const isYearBMoreThanYearA = dayjsB.year() < dayjsA.year();
  const isAfterMonthB = dayjsB.month() < thisDayjs.month();

  const isDayjsAStarting =
    isAfterOrEqualYearA &&
    (isAfterMonthA || isYearBLessThanYearA) &&
    isBeforeMonthB;

  const isDayjsBStarting =
    isBeforeOrEqualYearA &&
    (isBeforeMonthA || isYearBMoreThanYearA) &&
    isAfterMonthB;

  return isDayjsAStarting || isDayjsBStarting;
};

export const resolveFromToRange = (
  thisDayjs: dayjs.Dayjs,
  fromPeriodDayjs: dayjs.Dayjs,
  toPeriodDayjs: dayjs.Dayjs,
): boolean => {
  const fromPeriodYear = fromPeriodDayjs.year();
  const toPeriodYear = toPeriodDayjs.year();
  const fromPeriodMonth = fromPeriodDayjs.month();
  const toPeriodMonth = toPeriodDayjs.month();

  const thisYear = thisDayjs.year();
  const thisMonth = thisDayjs.month();

  const isAfterOrEqualFromPeriodYear = fromPeriodYear <= thisYear;
  const isBeforeOrEqualToPeriodYear = toPeriodYear >= thisYear;

  const isAfterInFromPeriodYear =
    fromPeriodYear === thisYear ? thisMonth > fromPeriodMonth : true;
  const isBeforeInToPeriodYear =
    toPeriodYear === thisYear ? thisMonth < toPeriodMonth : true;

  return [
    isAfterOrEqualFromPeriodYear,
    isBeforeOrEqualToPeriodYear,
    isAfterInFromPeriodYear,
    isBeforeInToPeriodYear,
  ].every(Boolean);
};

export const genPossiblePeriods = (
  {
    yearsCount,
    startYear,
  }: {
    yearsCount: number;
    startYear: number;
  } = {
    yearsCount: 60,
    startYear: 1980,
  },
) => {
  const possibleYear = Array.from({ length: yearsCount });
  const arr = possibleYear.flatMap((_, idx) => {
    const year = startYear + idx;
    const startDate = dayjs(`${year}-01-01`);

    const res = Array.from({ length: Const.MAX_MONTHS_IN_YEAR }, (__, i) => {
      return startDate.add(i, 'month').format(DATE_STRING_FORMAT);
    });

    return res;
  });

  return arr;
};

export const genPossiblePeriodsRange = ({
  from,
  to,
  diffUnits = 'month',
}: {
  from: string;
  to: string;
  diffUnits?: UnitTypeLong;
}) => {
  const diff = dayjs(from).diff(to, diffUnits);
  const periodsLen = Math.abs(diff) + 1;

  const initialPeriods = Array.from({ length: periodsLen }, (_, idx) => {
    return createMonthPeriodItem(
      dayjs(from).add(idx, diffUnits).format(DATE_STRING_FORMAT) as DateString,
    );
  });

  return initialPeriods;
};

export const resolveMonthRangeSelected = ({
  periodItems,
  key,
}: {
  key: DefaultMonthDateRange;
  periodItems: Types.IPeriodItem[];
}) => {
  const shiftedStartRange = dayjs()
    .subtract(Const.DEFAULT_MONTH_DATE_RANGES[key].shift, 'month')
    .startOf('month');
  const shiftedEndRange = dayjs().startOf('month');
  return (
    shiftedStartRange.isSame(periodItems[0]?.period, 'date') &&
    shiftedEndRange.isSame(periodItems[1]?.period, 'date')
  );
};

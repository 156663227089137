import useEvent from '@/shared/lib/hooks/useEvent';
import { useEventListener } from '@/shared/lib/hooks/useEventListener';
import {
  LocalStorageSchema,
  typedLocalStorage,
} from '@/shared/lib/localStorage';
import { useCallback, useEffect, useState } from 'react';

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>;

// https://usehooks-ts.com/react-hook/use-local-storage
export function useLocalStorageValue<T extends keyof LocalStorageSchema>(
  key: T,
  initialValue: LocalStorageSchema[T],
): [LocalStorageSchema[T], (val: LocalStorageSchema[T]) => void] {
  const readValue = useCallback((): LocalStorageSchema[T] => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = typedLocalStorage.getItem(key);
      return item ?? initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] =
    useState<LocalStorageSchema[T]>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<LocalStorageSchema[T]> = useEvent((value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      typedLocalStorage.setItem(key, newValue);

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  });

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue],
  );

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  useEventListener('storage', handleStorageChange);
  // @ts-ignore this is a custom event, triggered in writeValueToLocalStorage
  useEventListener('local-storage', handleStorageChange);

  return [storedValue, setValue];
}

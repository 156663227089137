import { useAppSelector } from '@/shared/lib/hooks/redux';
import { isEmpty, partition, sortBy, union } from 'lodash-es';
import { useMemo } from 'react';
import { ObjAndStateProps } from '../types';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { createReportFinancialsObjIdAndType as createObjIdAndType } from '../utils';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { ALL_VIEW_PARAM_TYPE } from '@/bundles/REport/reducers/financialsSlice';

const filterByNameAndLegalEntityName = (
  obj: ObjAndStateProps,
  query: string,
) => {
  const includesName = includesInLowerCase(
    'name' in obj ? obj.name : obj.title,
    query,
  );

  if (includesName) return true;

  const allLegalEntityNames = obj.legalEntities.map(
    (legalEntity) => legalEntity.name,
  );
  const includesLegalEntityName = includesInLowerCase(
    allLegalEntityNames,
    query,
  );

  return includesLegalEntityName;
};

export function useComputedObjects({ query }: { query: string }) {
  const financialsSlice = useAppSelector((state) => state.financials);
  const { viewParamType, objects, objectSelectionMode } = financialsSlice;
  const { isItemFavorite } = useFavoriteItemIds(
    'reportFinancialsObjectIdAndType',
  );

  const shownObjects = useMemo(() => {
    const filterByFavorite = (obj: ObjAndStateProps) =>
      isItemFavorite(createObjIdAndType(obj));

    const [selectedObjects, notSelectedObjects] = partition(
      objects,
      (obj) => obj._selected,
    );

    const computeObjects = (objList: ObjAndStateProps[]) => {
      const filteredByQuery = isEmpty(query)
        ? objList
        : objList.filter((obj) => filterByNameAndLegalEntityName(obj, query));

      if (viewParamType === ALL_VIEW_PARAM_TYPE) {
        return sortBy(filteredByQuery, (obj) => !filterByFavorite(obj));
      }

      if (viewParamType === 'favorite') {
        return filteredByQuery.filter(filterByFavorite);
      }

      return filteredByQuery.filter((obj) => obj._type === viewParamType);
    };

    if (objectSelectionMode === 'single') {
      return computeObjects(objects);
    }

    const shown = union(computeObjects(notSelectedObjects), selectedObjects);

    return sortBy(shown, (obj) => !obj._selected);
  }, [objects, viewParamType, query, isItemFavorite, objectSelectionMode]);

  return shownObjects;
}

import React from 'react';
import pluralize from 'pluralize';

const SharedFileTreeItem = ({ folder }) => {
  const childrenLength = folder.childrenCount + folder.sharedFilesCount;

  return (
    <div className="flex">
      <span className="folder-icon"><span className="sre-icon-folder" /></span>
      <div style={{ lineHeight: '1rem' }}>
        {folder.title}
        {childrenLength > 0 && (
          <p className="secondary-regular">
            {`${childrenLength} ${pluralize('item', childrenLength)}`}
          </p>
        )}
      </div>
    </div>
  );
};

export default SharedFileTreeItem;

import {
  createContext,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export default function useElementSize(elementRef: RefObject<HTMLDivElement>) {
  const [height, setHeight] = useState(elementRef.current?.offsetHeight ?? 0);
  const [width, setWidth] = useState(elementRef.current?.offsetWidth ?? 0);

  const observer = useRef(
    new ResizeObserver((entries) => {
      setHeight(entries[0].target.getBoundingClientRect().height);
      setWidth(entries[0].target.getBoundingClientRect().width);
    }),
  );

  useEffect(() => {
    if (elementRef.current == null) {
      return () => null;
    }

    observer.current.observe(elementRef.current);

    return () => {
      observer.current.disconnect();
    };
  }, [elementRef.current]);

  return {
    width,
    height,
  };
}

export const ElementSizeContext = createContext<
  ReturnType<typeof useElementSize>
>({
  width: 0,
  height: 0,
});

export const useElementSizeContext = () => useContext(ElementSizeContext);

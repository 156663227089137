import {
  ISovcRowUpdate,
  TReconcileBudgetSettingsSoVC,
} from 'bundles/REconcile/actions/sovcConfig';
import { IReconcileBudgetsSettingsChangeOrderConfig } from 'bundles/REconcile/actions/changeOrderConfig';

export const findConfigSetForAsset = (
  configs: IReconcileBudgetsSettingsChangeOrderConfig[],
) => configs.find((coc) => coc.setForLegalEntity);

export const mapSovcFromRequest = (
  row: TReconcileBudgetSettingsSoVC,
): ISovcRowUpdate => ({
  name: row.name,
  job_cost_code_id: row.jobCostCode?.id ?? null,
  code: row.code,
});

export const INIT_SOVC: ISovcRowUpdate = {
  code: null,
  job_cost_code_id: null,
  name: null,
};

import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { ReportLineItem } from 'bundles/REturn/actions/types';
import { TOpenDialog } from '@/shared/lib/hooks/useModal';
import { uniqueId } from 'lodash-es';
import { SplitTxBetweenKinds } from '../../splitModal/SplitTxBetweenKinds';
import { EntyTxWithReviewInfo, SingleDistributionKind } from '../../types';
import { createWorkflowFlags } from '../createWorkflowFlags';

import { convertCentsToDollars } from '@/shared/lib/converters';

export const SIMPLE_LINKING_ERROR_MSG =
  'There should be only 1 selected Capital Investment and 1 selected Transaction in Simple Linking Workflow!';

export async function simpleLinkingReviewEntryTx({
  selectedCapitalInvestments,
  selectedTxs,
  workflowFlags,
  singleDistributionKind,
  totalEntryAmountKeyName,
  openModal,
}: {
  selectedTxs: ReportLineItem[];
  selectedCapitalInvestments: InvestmentIndex[];
  workflowFlags: ReturnType<typeof createWorkflowFlags>;
  singleDistributionKind: SingleDistributionKind;
  totalEntryAmountKeyName: 'totalContributions' | 'totalDistributions';
  openModal: TOpenDialog;
}): Promise<EntyTxWithReviewInfo[] | null> {
  if (selectedCapitalInvestments.length !== 1 || selectedTxs.length !== 1) {
    throw new Error(SIMPLE_LINKING_ERROR_MSG);
  }

  const [transaction] = selectedTxs;
  const { amountCents, id: accountingTransactionId } = transaction;
  const amount = convertCentsToDollars(amountCents);

  const [{ investmentEntity, ...capitalInvestment }] =
    selectedCapitalInvestments;
  const { shouldSelectKindForSingleTx, selectedOneKind } = workflowFlags;

  if (
    shouldSelectKindForSingleTx &&
    singleDistributionKind === 'split_transaction_between_categories'
  ) {
    const entryTxs = await openModal(SplitTxBetweenKinds, {
      transaction,
      capitalInvestment: selectedCapitalInvestments[0],
    });

    if (!entryTxs) return null;

    return entryTxs;
  }

  let entryTx: EntyTxWithReviewInfo = {
    amount: Number(amount),
    accountingTransactionId,
    investmentId: capitalInvestment.id,
    investmentName: investmentEntity.name,
    totalAmount: capitalInvestment[totalEntryAmountKeyName],
  };

  if (shouldSelectKindForSingleTx && selectedOneKind(singleDistributionKind)) {
    entryTx = {
      ...entryTx,
      kind: singleDistributionKind,
      kindReviewInfo: [
        {
          id: uniqueId(),
          amount: entryTx.amount,
          kind: singleDistributionKind,
        },
      ],
    };
    return [entryTx];
  }

  return [entryTx];
}

import { TABLE_CELL_FONT_SIZE_CSS_VAR_REF } from '@/bundles/Shared/components/AgGrid/Table/utils/getTableCellStyle';
import EntitiesCounter from '@/bundles/Shared/components/Filters/assets/entitiesCounter/EntitiesCounter';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories';
import { IHeaderGroupParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ComponentProps, CSSProperties, PropsWithChildren } from 'react';
import { IReportBudgetOnShow } from 'types/ReportBudget';
import { CELL_CLASS_NAMES } from '../classNames';

export type HeaderGroupComponentClasses = {
  classes?: {
    wrapper?: string;
    inner?: string;
    displayName?: string;
  };
};

export type HeaderGroupComponentProps = HeaderGroupComponentClasses &
  PropsWithChildren & {
    style?: CSSProperties;
  };

export function HeaderGroupComponent({
  classes,
  style,
  ...params
}: HeaderGroupComponentProps & IHeaderGroupParams) {
  return (
    <div
      style={style}
      className={cn(CELL_CLASS_NAMES.Header.wrapper.default, classes?.wrapper)}
    >
      <div
        className={cn(
          CELL_CLASS_NAMES.Header.inner.default,
          'overflow-auto',
          classes?.inner,
        )}
        style={{ fontSize: `calc(${TABLE_CELL_FONT_SIZE_CSS_VAR_REF} - 2px)` }}
      >
        <p
          className={cn(
            'grow overflow-hidden text-ellipsis whitespace-nowrap text-center',
            classes?.displayName,
          )}
        >
          {params.displayName}
        </p>
        {params.children}
      </div>
    </div>
  );
}

export function ComparisonObjectHeaderGroupComponent({
  entityCounterProps,
  ...params
}: HeaderGroupComponentProps &
  IHeaderGroupParams & {
    entityCounterProps: ComponentProps<typeof EntitiesCounter>;
  }) {
  return (
    <HeaderGroupComponent
      {...params}
      classes={{ ...params.classes, displayName: 'order-2' }}
    >
      <Icon iconName="asset" className="order-1 text-neutral-500" />
      <EntitiesCounter {...entityCounterProps} className="order-3" />
    </HeaderGroupComponent>
  );
}

export function HeaderGroupComponentWithShortYear(
  params: IHeaderGroupParams &
    HeaderGroupComponentProps &
    Pick<IReportBudgetOnShow, 'year'>,
) {
  const shortYear = dayjs().year(params.year).format('YY');

  return (
    <HeaderGroupComponent {...params}>
      <span className="label-regular text-neutral-500">{shortYear}</span>
    </HeaderGroupComponent>
  );
}

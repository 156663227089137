import { ViewsCountFormatter } from '../../ColumnFormatters/ViewsCountFormatter';
import {
  fullNameColumnContent,
  roleColumnContent,
} from '../../SharedWithTableColumns';
import { IColumn } from '../../Table/types';
import { tableHeaderStyle } from './consts';
import { LightUser } from './types';

export const fullNameColumn: IColumn<LightUser> = {
  dataField: 'fullName',
  headerStyle: tableHeaderStyle,
  text: 'Name',
  formatter: ({ row }) => fullNameColumnContent(row),
  sortable: true,
};

export const roleNameColumn: IColumn<LightUser> = {
  dataField: 'role.name',
  headerStyle: tableHeaderStyle,
  text: 'Role',
  formatter: ({ row }) => roleColumnContent(row),
  sortable: true,
};

export const viewsColumn: IColumn<
  LightUser & {
    lastViewedAt: UnixTime | null;
    viewsCount: number;
  }
> = {
  sortable: true,
  dataField: 'viewsCount',
  headerStyle: {
    minWidth: 260,
  },
  text: 'Viewed',
  formatter: ({ row }) => (
    <ViewsCountFormatter
      viewsCount={row.viewsCount}
      lastViewedAt={row.lastViewedAt}
    />
  ),
};

import React from 'react';
import { Button } from 'stories';

interface Props {
  title: string;
  text: string;
  buttonText: string;
  clickBtnHandler: () => void;
}

const SectionToCreateEmailCard = ({
  title,
  text,
  buttonText,
  clickBtnHandler,
}: Props) => (
  <div className="flex flex-col justify-center items-center h-full w-full p-l rounded-[1rem] border-1 overflow-hidden border-solid border-light-30 bg-light-10 text-center">
    <h4 className="light-90 header5-regular mb-m">{title}</h4>
    <p className="light-60 inline-regular mb-l">{text}</p>
    <Button variant="primary" onClick={clickBtnHandler}>
      {buttonText}
    </Button>
  </div>
);

export default SectionToCreateEmailCard;

import { ReportLineItem } from 'bundles/REturn/actions/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import React from 'react';
import { InlineObject } from 'stories/index';
import { HeaderInfo } from './HeaderInfo';

interface Props {
  transaction: ReportLineItem;
}

export function TransactionHeaderInfo({ transaction }: Props) {
  return (
    <div className="-m-tw-6 mb-0 grid grid-cols-[max-content_1fr] grid-rows-2 gap-tw-4 border-b border-solid border-light-10 bg-white px-tw-6 py-tw-4">
      <HeaderInfo
        infoName="TX Date"
        value={formatDate(transaction.date, 'MMM DD, YYYY')}
      />
      <HeaderInfo
        infoName="Vendor"
        value={
          <InlineObject
            iconName="bag"
            fallbackNode="-"
            object={transaction.vendorEntity?.name}
          />
        }
      />
      <HeaderInfo
        infoName="PERIOD"
        value={formatDate(transaction.period, 'MMM-YY')}
      />
      <HeaderInfo infoName="Description" value={transaction.description} />
    </div>
  );
}

import { XyChartSingleKpiWidgetConfigDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { transformPeriodShiftFormToDto } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { XYChartSingleKpiWidgetConfigForm } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/component.form';
import {
  DEFAULT_EAGLE_EYE_XY_CHART_DATE_CONFIG,
  EAGLE_EYE_XY_CHART_CATEGORICAL_CONFIG,
} from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/defaults';
import { produce } from 'immer';
import { omit } from 'lodash-es';

import { findTemplateSeriesRef } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';

export const updateDefaultOptions = (
  values: XYChartSingleKpiWidgetConfigForm,
  widgetConfig: XyChartSingleKpiWidgetConfigDto,
): XyChartSingleKpiWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    const templateSeriesRef = findTemplateSeriesRef(draft.am_chart_config);
    const chartTypeUpdated =
      (values.xAxis === 'date' && templateSeriesRef == null) ||
      (values.xAxis !== 'date' && templateSeriesRef != null);
    if (chartTypeUpdated) {
      Object.assign(
        draft,
        omit(
          values.xAxis === 'date'
            ? DEFAULT_EAGLE_EYE_XY_CHART_DATE_CONFIG
            : EAGLE_EYE_XY_CHART_CATEGORICAL_CONFIG,
          'kpis',
        ),
      );
      return;
    }
    draft.default_options.date_to = {
      period_type: 'month',
      period_shift: transformPeriodShiftFormToDto({
        key: 'months',
        ...values.periodShiftTo,
      }),
    };
    draft.default_options.date_from = {
      period_type: 'month',
      period_shift: transformPeriodShiftFormToDto(values.periodShiftFrom),
    };
    draft.default_options.granularity = values.granularity!;
    draft.default_options.grouping_type = values.groupingType!;
  });
};

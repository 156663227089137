import { DateFormat } from '@/shared/lib/formatting/dates';
import { capitalize } from 'lodash-es';
import * as yup from 'yup';
import { InferType } from 'yup';

export const VALUE_TYPES = [
  'number',
  'percentage',
  'currency',
  'sqft',
  'metric',
  'bps',
  'date',
] as const;

export const VALUE_TYPE_OPTIONS = VALUE_TYPES.map((v) => ({
  label: capitalize(v),
  value: v,
}));

export const DATE_FORMAT_VALUES = [
  'YYYY',
  'MM',
  'MMM',
  'MM/DD/YYYY',
  'MMMM D, YYYY',
  'MMM D, YYYY',
  'Relative',
] as const satisfies DateFormat[];

export const DATE_FORMAT_VALUE_MAP = {
  MM: {
    label: 'MM',
    value: 'MM',
  },
  YYYY: {
    label: 'YYYY',
    value: 'YYYY',
  },
  'MMMM D, YYYY': {
    label: 'MMMM D, YYYY',
    value: 'MMMM D, YYYY',
  },
  'MMM D, YYYY': {
    label: 'MMM D, YYYY',
    value: 'MMM D, YYYY',
  },
  'MM/DD/YYYY': {
    label: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  },
  MMM: {
    label: 'MMM',
    value: 'MMM',
  },
  Relative: {
    label: 'Relative (calculate time to or from the date)',
    value: 'Relative',
  },
} as const satisfies Record<
  DateFormat,
  {
    label: string;
    value: DateFormat;
  }
>;

export const DATE_FORMAT_VALUE_OPTIONS = Object.values(DATE_FORMAT_VALUE_MAP);

export const PRECISION_OPTIONS = [0, 1, 2];
export type ValuePrecision = (typeof PRECISION_OPTIONS)[number];

export const VALUE_DISPLAY_OPTIONS_SCHEMA = yup.object().shape({
  type: yup
    .string()
    .oneOf([...VALUE_TYPES])
    .required(),
  precision: yup.number().oneOf(PRECISION_OPTIONS).required(),
  kilo_formatting: yup.boolean(),
  hide_comma_separator: yup.boolean(),
  date_format: yup
    .string()
    .oneOf([...DATE_FORMAT_VALUES])
    .default(DATE_FORMAT_VALUES[0]),
});
export type ValueDisplayOptionsForm = InferType<
  typeof VALUE_DISPLAY_OPTIONS_SCHEMA
>;
export const DEFAULT_VALUE_DISPLAY_OPTIONS = {
  type: 'currency',
  precision: 0,
  kilo_formatting: false,
} as const satisfies ValueDisplayOptions;
export type ValueDisplayType = (typeof VALUE_TYPES)[number];

export type ValueDisplayOptions = {
  type: ValueDisplayType;
  precision?: ValuePrecision;
  kilo_formatting?: boolean;
  hide_comma_separator?: boolean;
  date_format?: DateFormat;
};

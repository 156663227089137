import React from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import {
  DEFAULT_CODE_FIELD,
  DEFAULT_NAME_FIELD,
  getJccsSelectField,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IFieldsProps } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { IReconcileSettingsContactUpdate } from 'bundles/REconcile/actions/contacts';
import { TReconcileSettingsJCC } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntitiesSettings';

interface Props extends IFieldsProps<IReconcileSettingsContactUpdate> {
  jccList: TReconcileSettingsJCC[];
}

function SovcFields({ formData, setFormData, jccList }: Props) {
  return (
    <FormComponent
      styles={{
        wrapper__item: 'mb-0',
      }}
      setFormData={setFormData}
      formData={formData}
      formItems={[
        DEFAULT_CODE_FIELD,
        DEFAULT_NAME_FIELD,
        getJccsSelectField(jccList, {
          styles: {
            nomb: true,
            size: 'm',
          },
        }),
      ]}
    />
  );
}

export default SovcFields;

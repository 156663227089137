import React, { FC, Dispatch, SetStateAction, ReactNode } from 'react';
import { UnitForStepByStepReview, UnitNum } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import FormSwitcher from '../FormSwitcher';
import FormViewSwitcher from '../FormViewSwitcher';
import '../styles/forms.scss';
import { IFormItem } from '../types/types';
import { isEmpty } from 'lodash-es';
import { IGroupFormConfig } from '../GroupForm';
import FCStepsNumber from './variants/FCStepsNumber';
import FCStepsWithoutNumber from './variants/FCStepsWithoutNumber';

type TQaItem = {
  active: boolean;
  handle: () => void;
  disabled: boolean;
};

interface Props {
  formItems: IFormItem[];
  title: string;
  titleView?: string;
  disabledTitleView?: boolean;
  width?: string;
  activated?: boolean;
  required?: boolean;
  hasQA?: boolean;
  formData: object;
  styles?: any;
  stylesView?: any;
  setFormData: Dispatch<SetStateAction<object>>;
  loading: boolean;
  steps?: boolean;
  compId?: number;
  lastCompId: number;
  viewMode?: boolean;
  viewModeSwitcher?: () => void;
  onYesClick: () => void;
  onNoClick: () => void;
  qaItems: {
    yes: TQaItem;
    no: TQaItem;
  };
  unitNum?: number;
  unitNumTitle?: string;
  actions?: ReactNode;
  validate: object;
  setValidate: SetStateAction<object>;
  groupFormConfig: IGroupFormConfig;
}

const FormComponent: FC<Props> = ({
  formItems = [],
  title = '',
  titleView,
  disabledTitleView,
  width = '100%',
  required,
  activated,
  formData,
  setFormData,
  loading,
  styles,
  stylesView,
  steps,
  compId,
  lastCompId,
  hasQA,
  viewMode,
  viewModeSwitcher,
  qaItems,
  unitNum,
  unitNumTitle,
  actions,
  validate,
  setValidate,
  groupFormConfig,
}) => {
  const countOptional = [];
  const countValue = [];
  if (steps) {
    formItems.forEach((formItem) => {
      if (formItem.required) {
        countOptional.push(formItem);
        if (
          formData[formItem.fieldName] ||
          formData[formItem.fieldName] === 0
        ) {
          countValue.push(formItem);
        }
      }
    });
  }
  return (
    <div
      className={cn(
        (!steps || viewMode) && 'group-form',
        viewMode ? stylesView?.wrapper : styles?.wrapper,
        styles?.wrapper__item,
      )}
      style={{ width }}
    >
      {steps && !viewMode && !groupFormConfig?.withoutNumberStep && (
        <FCStepsNumber
          title={title}
          stepNumber={compId}
          required={required}
          lineForStep={lastCompId !== compId}
          compId={compId}
          activated={
            activated &&
            formItems.length &&
            countOptional.length <= countValue.length
          }
          hasQA={hasQA}
          qaItems={qaItems}
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          validate={validate}
          setValidate={setValidate}
          formItems={formItems}
          styles={styles}
        />
      )}
      {steps && !viewMode && groupFormConfig?.withoutNumberStep && (
        <div className="mb-m">
          <FCStepsWithoutNumber
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            validate={validate}
            setValidate={setValidate}
            formItems={formItems}
            required={required}
            title={title}
            styles={styles}
          />
        </div>
      )}
      {!steps && !viewMode && !isEmpty(title) && (
        <div className="group-form__title">{title}</div>
      )}
      {viewMode && (
        <UnitForStepByStepReview
          title={titleView ?? title}
          number={compId}
          onEdit={loading ? false : viewModeSwitcher}
          disabled={disabledTitleView}
        >
          <div
            className={cn(
              'group-form__step-item',
              'group-form__step-item_summary',
              styles?.transparent && 'group-form__step-item_transparent',
            )}
          >
            {formItems
              .filter((item) => !item.contentViewIndepented)
              .map((formItem, idx) => (
                <>
                  {formItem?.optionalView && (
                    <FormViewSwitcher
                      formItem={formItem}
                      key={`formViewItem${idx}`}
                      formData={formData}
                      value={formData[formItem.fieldName]}
                    />
                  )}
                  {formItem?.optionalView &&
                    formItem.viewComponent &&
                    formItem.viewComponent.map((viewItem) => (
                      <FormViewSwitcher
                        formItem={viewItem}
                        key={`formViewItemAdd${idx}`}
                        formData={formData}
                        value={viewItem.value ?? formData[viewItem.fieldName]}
                      />
                    ))}
                </>
              ))}
          </div>
        </UnitForStepByStepReview>
      )}
      {viewMode &&
        formItems
          .filter((item) => item.contentViewIndepented)
          .map(
            (formItem, idx) =>
              formItem?.optionalView && (
                <FormViewSwitcher
                  formItem={formItem}
                  key={`formViewItem${idx}`}
                  formData={formData}
                  value={formData[formItem.fieldName]}
                />
              ),
          )}
      <div className="flex justify-between w-full">
        {unitNum && (
          <div className="w-full flex mb-m">
            <UnitNum>{unitNum}</UnitNum>
            {unitNumTitle && (
              <div className="dark-60 header6-regular ml-s">{unitNumTitle}</div>
            )}
          </div>
        )}
        {actions}
      </div>
      {!steps &&
        formItems.map((formItem, idx) => (
          <FormSwitcher
            formItem={formItem}
            key={`formItem${idx}`}
            formData={formData}
            value={formData[formItem.fieldName]}
            setFormData={setFormData}
            loading={loading}
            validate={validate}
            setValidate={setValidate}
          />
        ))}
    </div>
  );
};
export default FormComponent;

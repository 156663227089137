import { RouteComponentProps } from '@reach/router';
import {
  changeActiveRoleId,
  changePagePosition,
  fetchUserRoles,
} from 'bundles/UserManagement/reducers/userRolesSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { usePermissions } from '@/app/lib';
import { sortBy } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { AnimationLoader, Button, ThinTabGroupWithAmount } from 'stories';
import { IUserRole } from 'types/User';
import { RightBar } from './RightBar';
import { RoleItem } from './RoleItem';
import { EXTERNAL_CATEGORY, INTERNAL_CATEGORY } from './constants';
import type * as Types from './types';
import { getGroupOptions } from './utils';

export const UserRoles: FC<RouteComponentProps> = () => {
  const dispatch = useAppDispatch();
  const userRolesState = useAppSelector((state) => state.userRoles);
  const { activeRoleId, position, roles } = userRolesState;
  const { canConfigureUserRoles } = usePermissions();

  const { externalUsersLen, internalUsersLen } = useMemo(
    () => ({
      externalUsersLen: roles.filter((r) => r.category === EXTERNAL_CATEGORY)
        .length,
      internalUsersLen: roles.filter((r) => r.category === INTERNAL_CATEGORY)
        .length,
    }),
    [roles],
  );

  const UserCategoryTabs: Types.IUserCategoryTab[] = [
    {
      id: 1,
      label: 'External',
      amount: externalUsersLen,
      key: EXTERNAL_CATEGORY,
    },
    {
      id: 2,
      label: 'Internal',
      amount: internalUsersLen,
      key: INTERNAL_CATEGORY,
    },
  ];
  const [ExternalTab] = UserCategoryTabs; // InternalTab
  const [selectedUserCategoryTab, setSelectedUserCategoryTab] =
    useState(ExternalTab);

  const currentCategoryItems = useMemo(
    () =>
      sortBy(
        roles.filter((r) => selectedUserCategoryTab.key === r.category),
        ['group'],
      ) as IUserRole[],
    [roles, selectedUserCategoryTab],
  );

  const groupOptions = useMemo<Types.IGroupOptions>(
    () => ({
      [INTERNAL_CATEGORY]: getGroupOptions(roles, INTERNAL_CATEGORY),
      [EXTERNAL_CATEGORY]: getGroupOptions(roles, EXTERNAL_CATEGORY),
    }),
    [roles],
  );

  const resolveActiveRoleId = () => {
    dispatch(
      changeActiveRoleId(
        currentCategoryItems.length ? currentCategoryItems[0].id : null,
      ),
    );
  };

  const selectRole = (id: IUserRole['id']) =>
    activeRoleId !== id && dispatch(changeActiveRoleId(id));

  useEffect(() => {
    if (!roles.length) {
      dispatch(fetchUserRoles());
    } else {
      dispatch(changePagePosition('idle'));
    }
    resolveActiveRoleId();
  }, []);

  useEffect(() => {
    if (activeRoleId === null) {
      resolveActiveRoleId();
    }
  }, [roles]);

  useEffect(() => {
    resolveActiveRoleId();
  }, [selectedUserCategoryTab]);

  const isCreateRoleBtnDisabled =
    ['create-role', 'error', 'loading'].includes(position) ||
    !canConfigureUserRoles;

  return (
    <>
      {position === 'loading' ? (
        <AnimationLoader className="static min-h-[360px]" />
      ) : (
        <>
          <div className="mb-s flex justify-between">
            <ThinTabGroupWithAmount
              selectedItem={selectedUserCategoryTab}
              onSelectedItemChange={(selected) =>
                setSelectedUserCategoryTab(selected)
              }
              items={UserCategoryTabs}
            />
            <Button
              disabled={isCreateRoleBtnDisabled}
              onClick={() => dispatch(changePagePosition('create-role'))}
              variant="primary"
              size="s"
            >
              Create New Role
            </Button>
          </div>
          <div>
            <ul className="dark-60 grid gap overflow-hidden rounded-[8px]">
              {currentCategoryItems?.map((role) => (
                <RoleItem
                  key={role.id}
                  active={role.id === activeRoleId}
                  role={role}
                  onClick={selectRole}
                />
              ))}
            </ul>
          </div>
        </>
      )}
      <RightBar
        resolveActiveRoleId={resolveActiveRoleId}
        groupOptions={groupOptions}
        activeRole={roles.find((r) => r.id === activeRoleId)!}
      />
    </>
  );
};

import * as d3 from 'd3';
import { getComponentSelector } from './TreeView';
import type { INodeConfig, Selection } from './types';

export const NODE_EXPANDER_GROUP_COMPONENT = 'NodeExpanderGroup';
export const NODE_EXPANDER_CIRCLE_COMPONENT = 'NodeExpanderCircle';

function expandCircleAttrs(
  selection: Selection<SVGCircleElement>,
  { radius }: INodeConfig,
) {
  selection
    .attr('r', radius)
    .attr('fill', (d) => (d.children ? 'var(--light-30)' : 'var(--dark-100)'))
    .attr('stroke-width', 10);
}

function expandIconSvgAttrs(selection: Selection<SVGSVGElement>) {
  selection.attr('width', 10).attr('height', 10).attr('x', -5).attr('y', -5);
}

function expandIconAttrs(selection: Selection<SVGSVGElement>) {
  selection
    .attr('transform-origin', 'center center')
    .attr('transform', (d) =>
      d.children == null ? 'rotate(180)' : 'rotate(0)',
    )
    .attr('fill', (d) =>
      d.children == null ? 'var(--white)' : 'var(--dark-100)',
    );
}

export function NodeExpander(
  enterSelection: Selection<SVGRectElement>,
  lifecycle: 'create' | 'update',
  { radius, leftMargin, leftIcon }: INodeConfig,
) {
  function appendComponents(internalSelection: Selection<SVGGElement>) {
    const expandG = internalSelection
      .append('g')
      .attr('cursor', 'pointer')
      .attr('data-component', NODE_EXPANDER_GROUP_COMPONENT);

    expandG
      .append('circle')
      .attr('data-component', NODE_EXPANDER_CIRCLE_COMPONENT);

    expandG.nodes().forEach((n) => {
      n?.append(leftIcon.documentElement.cloneNode(true));
    });
  }
  if (lifecycle === 'create') {
    appendComponents(enterSelection);
  }

  if (lifecycle === 'update') {
    enterSelection
      .filter(function (d) {
        return (
          d._children?.length > 0 &&
          d3
            .select(this)
            .select(getComponentSelector(NODE_EXPANDER_GROUP_COMPONENT))
            .empty()
        );
      })
      .call(appendComponents);

    enterSelection
      .filter((d) => d._children == null)
      .select(getComponentSelector(NODE_EXPANDER_GROUP_COMPONENT))
      .remove();
  }

  const expandG = enterSelection
    .select(getComponentSelector(NODE_EXPANDER_GROUP_COMPONENT))
    .attr(
      'transform',
      (d) =>
        `translate(${
          d.textBox.width +
          (d?.nodeLinksBox?.width ?? 0) +
          (d.nodeInfoBox?.width ?? 0) +
          radius +
          leftMargin
        }, 0)`,
    );

  expandG.select('svg').call(expandIconSvgAttrs);

  enterSelection
    .select(getComponentSelector(NODE_EXPANDER_GROUP_COMPONENT))
    .select('svg > path')
    .call(expandIconAttrs);

  enterSelection
    .select(getComponentSelector(NODE_EXPANDER_CIRCLE_COMPONENT))
    .call(expandCircleAttrs, {
      radius,
    });

  expandG.each(function (d) {
    d.circleWidth = leftMargin + this.getBBox().width;
  });
}

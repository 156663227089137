import _ from 'lodash-es';
import { userRoleIsManager } from 'lib/permissions';

function infoTab(fields) {
  return {
    id: fields.id,
    first_name: fields.firstName,
    last_name: fields.lastName,
    phone: fields.phone?.replace('x-____', '').replace('_', ''),
    email: fields.email,
    info: fields.info,
  };
}

function roleTab(fields) {
  return {
    user_role_id: fields.userRole.id,
  };
}

function investmentEntitiesTab(fields) {
  if (!['Equity Partner', 'Lender'].includes(fields.userRole.group)
    && fields.userRole.name !== 'Viewer') return { investment_entities: [], total_equity_invested: null };

  return {
    investment_entities: fields.investmentEntities.map((ie) => ie.label),
  };
}

function adviseesTab(fields) {
  if (fields.userRole.name !== 'Advisor') return { advisee_ids: [] };

  return {
    advisee_ids: fields.adviseeIds,
  };
}

function advisorsTab(fields) {
  if (fields.userRole.name === 'Advisor') return { advisor_ids: [] };

  return {
    advisor_ids: fields.advisorIds,
  };
}

function tagsTab(fields) {
  if (fields.userRole.name === 'Advisor') return { user_tags: [] };

  return {
    user_tags: fields.userTags ? fields.userTags.map((userTag) => userTag.label) : [],
  };
}

function fundsAndAssetsTab(fields) {
  const result = {
    can_view_object_ids: { Asset: [], Fund: [] },
    can_manage_object_ids: { Asset: [], Fund: [] },
    can_view_all: false,
    can_manage_all: false,
    can_view_all_funds: false,
    can_manage_all_funds: false,
  };
  if (fields.userRole.name === 'Advisor') return result;

  if (fields.userRole.name === 'Super Admin') {
    result.can_view_all = true;
    result.can_manage_all = true;
    result.can_view_all_funds = true;
    result.can_manage_all_funds = true;

    return result;
  }

  if (userRoleIsManager(fields.userRole)) {
    result.can_manage_all = fields.canManageAll ? fields.canManageAll : false;
    result.can_manage_object_ids.Asset = result.can_manage_all ? [] : fields.canManageAssetIds;

    result.can_manage_all_funds = fields.canManageAllFunds;
    result.can_manage_object_ids.Fund = result.can_manage_all_funds ? [] : fields.canManageFundIds;
  }

  result.can_view_all = fields.canViewAll || fields.canManageAll;
  result.can_view_all_funds = fields.canViewAllFunds || fields.canManageAllFunds;
  result.can_view_object_ids = {
    Asset: _.union(
      result.can_view_all ? [] : fields.canViewAssetIds,
      result.can_manage_object_ids.Asset,
    ),
    Fund: _.union(
      result.can_view_all_funds ? [] : fields.canViewFundIds,
      result.can_manage_object_ids.Fund,
    ),
  };

  return result;
}

function userFormRequest(fields) {
  return {
    ...infoTab(fields),
    ...roleTab(fields),
    ...investmentEntitiesTab(fields),
    ...adviseesTab(fields),
    ...advisorsTab(fields),
    ...tagsTab(fields),
    ...fundsAndAssetsTab(fields),
  };
}

export default userFormRequest;

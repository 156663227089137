import {
  DeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdApiResponse,
  PostApiReconcileBudgetMetricsApiResponse,
  PostApiReconcileUnderwritingBudgetsApiResponse,
  PostApiReconcileUnderwritingBudgetsCreateFromSourceApiResponse,
  PutApiReconcileBudgetMetricsByIdApiResponse,
  PutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiResponse,
  PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiResponse,
  underwritingBudgetGeneratedApi,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';

export const reconcileUnderwrittingBudgetEnhancedApi =
  underwritingBudgetGeneratedApi.enhanceEndpoints({
    addTagTypes: [
      'legalEntities',
      'budget',
      'financialCategories',
      'budgetMetrics',
    ],
    endpoints: {
      getApiReconcileUnderwritingBudgetsLegalEntities: {
        providesTags: ['legalEntities'],
      },
      getApiReconcileUnderwritingBudgetsByBudgetId: {
        providesTags: ['budget'],
      },
      getApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategories: {
        providesTags: ['financialCategories'],
      },
      getApiReconcileUnderwritingBudgetsByBudgetIdMetrics: {
        providesTags: ['budgetMetrics'],
      },
      postApiReconcileUnderwritingBudgets: {
        transformResponse: (
          res: PostApiReconcileUnderwritingBudgetsApiResponse,
        ) => {
          toastr.success('Budget has been created');
          return res;
        },
        invalidatesTags: ['legalEntities'],
      },
      postApiReconcileUnderwritingBudgetsCreateFromSource: {
        transformResponse: (
          res: PostApiReconcileUnderwritingBudgetsCreateFromSourceApiResponse,
        ) => {
          toastr.success('Budget has been created from Source Budget');
          return res;
        },
        invalidatesTags: ['legalEntities'],
      },

      postApiReconcileBudgetMetrics: {
        invalidatesTags: ['budget', 'budgetMetrics'],
        transformResponse: (res: PostApiReconcileBudgetMetricsApiResponse) => {
          toastr.success('Budget Metric has been created');
          return res;
        },
      },
      putApiReconcileBudgetMetricsById: {
        invalidatesTags: ['budget', 'budgetMetrics'],
        transformResponse: (
          res: PutApiReconcileBudgetMetricsByIdApiResponse,
        ) => {
          toastr.success('Budget Metric has been updated');
          return res;
        },
      },

      putApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategories: {
        invalidatesTags: ['budget', 'financialCategories'],
        transformResponse: (
          res: PutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiResponse,
        ) => {
          toastr.success('Financial Categories have been updated');
          return res;
        },
      },

      postApiReconcileUnderwritingBudgetsByBudgetIdMetrics: {
        invalidatesTags: ['budget', 'budgetMetrics'],
        transformResponse: (
          res: PostApiReconcileUnderwritingBudgetsApiResponse,
        ) => {
          toastr.success('Budget Metric have been added to this budget');
          return res;
        },
      },
      deleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricId: {
        invalidatesTags: ['budget', 'budgetMetrics'],
        transformResponse: (
          res: DeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdApiResponse,
        ) => {
          toastr.success('Budget Metric have been removed from this budget');
          return res;
        },
      },

      putApiReconcileUnderwritingBudgetsByBudgetIdValuesBulk: {
        transformResponse: (
          res: PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiResponse,
        ) => {
          toastr.success('Budget has been updated');
          return res;
        },
      },

      deleteApiReconcileUnderwritingBudgetsByBudgetId: {
        invalidatesTags: ['legalEntities'],
        transformResponse: (
          res: PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiResponse,
        ) => {
          toastr.success('Budget has been removed');
          return res;
        },
      },

      putApiReconcileUnderwritingBudgetsByBudgetId: {
        invalidatesTags: ['budget', 'legalEntities'],
        transformResponse: (
          res: PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiResponse,
        ) => {
          toastr.success('Budget has been updated');
          return res;
        },
      },
    },
  });

export const {
  useGetApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesQuery,
  useGetApiReconcileUnderwritingBudgetsLegalEntitiesQuery,
  useGetApiReconcileUnderwritingBudgetsSourceLegalEntitiesQuery,
  useGetApiReconcileUnderwritingBudgetsByBudgetIdQuery,

  usePostApiReconcileUnderwritingBudgetsMutation,
  usePutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesMutation,
  usePostApiReconcileUnderwritingBudgetsCreateFromSourceMutation,
  usePutApiReconcileUnderwritingBudgetsByBudgetIdMutation,
  usePutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkMutation,
  useDeleteApiReconcileUnderwritingBudgetsByBudgetIdMutation,

  // KPIs & Metrics
  useGetApiReconcileUnderwritingBudgetsByBudgetIdMetricsQuery,
  usePostApiReconcileUnderwritingBudgetsByBudgetIdMetricsMutation,
  useDeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdMutation,
  usePostApiReconcileBudgetMetricsMutation,
  usePutApiReconcileBudgetMetricsByIdMutation,
  // end
} = reconcileUnderwrittingBudgetEnhancedApi;

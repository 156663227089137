import { ColDef } from 'ag-grid-community';
import { cn } from '@/shared/lib/css/cn';
import { isRowDivider, isRowFormula, isRowHeader } from '../types';
import { COLORS_TO_CLASSES, isRowTotals, rowHasBackground } from '../utils';
import styles from '../ReportTable.module.scss';

export const autoGroupColumnDefCellClassResolver: ColDef['cellClass'] = ({
  node,
}) => {
  const isSecondarySemibold =
    node.hasChildren() ||
    isRowTotals(node.data) ||
    isRowFormula(node.data) ||
    isRowHeader(node.data);

  const isGroupHoverStyles =
    !isRowHeader(node.data) &&
    !isRowDivider(node.data) &&
    !rowHasBackground(node.data);

  return cn(
    node.data.display?.background
      ? COLORS_TO_CLASSES[node.data.display.background]
      : 'bg-white',
    {
      'secondary-semibold': isSecondarySemibold,
      'secondary-regular': !node.hasChildren(),
      'light-70': isRowTotals(node.data),
      'cursor-pointer': node.hasChildren(),
      [styles.groupHover]: isGroupHoverStyles,
    },
  );
};

export const autoGroupColumnDefCellClassResolverForPreview: ColDef['cellClass'] =
  ({ node }) => {
    const isSecondarySemibold =
      node.hasChildren() ||
      isRowTotals(node.data) ||
      isRowFormula(node.data) ||
      isRowHeader(node.data);

    return cn(
      node.data.display?.background &&
        COLORS_TO_CLASSES[node.data.display.background],
      'bg-white flex items-center',
      {
        'secondary-semibold': isSecondarySemibold,
        'secondary-regular': !node.hasChildren(),
        'text-light-70': isRowTotals(node.data),
      },
    );
  };

export const AUTO_GROUP_COLUMN_DEF_STATIC_FIELDS: ColDef = {
  flex: 1,
  minWidth: 240,
  headerClass: 'bg-dark-60 text-white',
  headerName: 'headerTitle',
  suppressMenu: true,
};

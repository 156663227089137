import { _ObjectableType } from '@/bundles/REport/reducers/financialsSlice';
import { CssVar } from '@/shared/config/cssVar';
import { IconsId } from 'types/sre-icons';

export const OBJECTABLE_TYPE_ICON_NAME_MAP = {
  asset: 'asset',
  fund: 'funds',
  segment: 'segments',
} as const satisfies Record<_ObjectableType, IconsId>;

export const OBJECTABLE_TYPE_COLOR_MAP = {
  asset: CssVar.neutral500,
  fund: CssVar.violet080,
  segment: CssVar.info050,
} as const satisfies Record<_ObjectableType, CssVar>;

export const OBJECTABLE_TYPE_CLASS_NAME_MAP = {
  asset: 'text-neutral-500',
  fund: 'text-violet-080',
  segment: 'text-info-050',
} as const satisfies Record<_ObjectableType, string>;

export const REPORT_FINANCIALS_OBJECT_ID_AND_TYPE_DELIMITER = '}x{';

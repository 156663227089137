import {
  IChangeOrderEventBages,
  IConstructionReport,
  IConstructionReportParsed,
} from 'types/reconcile';
import { TContingencyCategory } from 'bundles/Construction/types';
import { inRange, round, sum } from 'lodash-es';
import { TInfoCardType } from 'bundles/Construction/components/cards/ChangeManagementInfoCard/ChangeManagementInfoCard';
import { TStatisticEntry } from 'stories/filterCard/statistic/StatisticLine';

export const CARD_TYPE_LABELS: Record<TInfoCardType, string> = {
  internalContingency: 'Internal Contingency',
  projectedContingency: 'Projected Contingency',
  currentContingency: 'Current Contingency',
};
export const CARD_ACTIVE_CATEGORIES: Record<
  TInfoCardType,
  Exclude<TContingencyCategory, TContingencyCategory.Remaining>[]
> = {
  currentContingency: [TContingencyCategory.Used],
  projectedContingency: [
    TContingencyCategory.Used,
    TContingencyCategory.Pending,
  ],
  internalContingency: [
    TContingencyCategory.Used,
    TContingencyCategory.Pending,
    TContingencyCategory.IRC,
  ],
};
export const CATEGORY_COLORS: Partial<
  Record<
    TContingencyCategory,
    {
      hoverColor: string;
    }
  >
> = {
  [TContingencyCategory.Used]: {
    hoverColor: 'var(--dark-60)',
  },
  [TContingencyCategory.Pending]: {
    hoverColor: 'var(--light-80)',
  },
  [TContingencyCategory.Used]: {
    hoverColor: 'var(--dark-60)',
  },
  [TContingencyCategory.IRC]: {
    hoverColor: 'var(--light-50)',
  },
};

export const parseConstructionReport = (
  report: IConstructionReport,
): IConstructionReportParsed => ({
  remainingGmp: parseFloat(report.remainingGmp),
  approvedCo: parseFloat(report.approvedCo),
  approvedCor: parseFloat(report.approvedCor),
  assetId: report.assetId,
  assetName: report.assetName,
  budgetUpdatedAt: report.budgetUpdatedAt,
  completedAndStored: report.completedAndStored,
  constructionCompletePercent: parseFloat(report.constructionCompletePercent),
  currentContingency: parseFloat(report.currentContingency),
  disputed: parseFloat(report.disputed),
  hardConstructionCost: report.hardConstructionCost,
  hardCostContingency: report.hardCostContingency,
  hcContingencyUsedPercent: parseFloat(report.hcContingencyUsedPercent),
  internalHcContingency: parseFloat(report.internalHcContingency),
  ircWeighted: parseFloat(report.ircWeighted),
  otherReallocations: parseFloat(report.otherReallocations),
  pendingCor: parseFloat(report.pendingCor),
  pendingPco: parseFloat(report.pendingPco),
  previousCertifiedWorks: report.previousCertifiedWorks,
  projectedConstructionCost: parseFloat(report.projectedConstructionCost),
  projectedContingency: parseFloat(report.projectedContingency),
  remainingContingency: parseFloat(report.remainingContingency),
  retainage: report.retainage,
  siteworkContingency: report.siteworkContingency,
  summaryWithInternal: report.summaryWithInternal,
  summaryWithProjected: report.summaryWithProjected,
  totalChangeEvents: parseFloat(report.totalChangeEvents),
  updatedAt: report.updatedAt,
  approvedPercentOfContingency: parseFloat(report.approvedPercentOfContingency),
  approvedTotal: parseFloat(report.approvedTotal),
  completedPercentOfContingency: parseFloat(
    report.completedPercentOfContingency,
  ),
  completedTotal: parseFloat(report.completedTotal),
  draftPercentOfContingency: parseFloat(report.draftPercentOfContingency),
  draftTotal: parseFloat(report.draftTotal),
  pendingPercentOfContingency: parseFloat(report.pendingPercentOfContingency),
  pendingTotal: parseFloat(report.pendingTotal),
});

export const percentOfContingencyGetter = (amount, initialContingency) => {
  round((amount * 100) / initialContingency, 1);
};

export const getRemainingColors = (value: number) => {
  // enough money to cover changes
  if (value >= 75) {
    return {
      color: 'var(--green-light-4)',
      hoverColor: 'var(--green)',
    };
  }
  // still enough money to cover changes
  if (inRange(value, 75, 50)) {
    return {
      color: 'var(--yellow-light-3)',
      hoverColor: 'var(--yellow-dark-2)',
    };
  }
  // need to be cautious approving changes
  if (inRange(value, 50, 20)) {
    return {
      color: 'var(--pumpkin-light-4)',
      hoverColor: 'var(--pumpkin-light-1)',
    };
  }
  // no space for unreasonable changes
  return {
    color: 'var(--red-light-4)',
    hoverColor: 'var(--red-dark-1)',
  };
};

export const getContingencyCategoriesPercent = (
  report: Pick<
    IChangeOrderEventBages,
    'usedPercent' | 'pendingPercent' | 'ircPercent'
  >,
): Record<
  Exclude<TContingencyCategory, TContingencyCategory.Remaining>,
  number
> => ({
  USED: round(report.usedPercent, 1),
  PENDING: round(report.pendingPercent, 1),
  IRC: round(report.ircPercent, 1),
});

export const getContingencyRemaining = (
  report: Pick<
    IChangeOrderEventBages,
    'usedPercent' | 'pendingPercent' | 'ircPercent'
  >,
  contingencyKey: `${TInfoCardType}` | TInfoCardType,
) => {
  const activeCategories = CARD_ACTIVE_CATEGORIES[contingencyKey];
  const categoriesPercent = getContingencyCategoriesPercent(report);
  const value =
    100 -
    sum(
      Object.entries(categoriesPercent)
        .filter(([k]) => activeCategories.includes(k as TContingencyCategory))
        .map(([_, v]) => v),
    );
  return value;
};

export const getContingencyStatistics = (
  report: Pick<
    IChangeOrderEventBages,
    'usedPercent' | 'pendingPercent' | 'ircPercent'
  >,
  contingencyKey: `${TInfoCardType}` | TInfoCardType,
): Record<string, TStatisticEntry> => {
  const activeCategories = CARD_ACTIVE_CATEGORIES[contingencyKey];
  const remaining = getContingencyRemaining(report, contingencyKey);
  const colors = getRemainingColors(remaining);
  const categoriesPercent = getContingencyCategoriesPercent(report);

  return Object.fromEntries([
    ...Object.values(activeCategories).map((key) => [
      key,
      {
        color: colors.color,
        hoverColor: CATEGORY_COLORS[key]!.hoverColor,
        value: categoriesPercent[key],
      },
    ]),
    [
      TContingencyCategory.Remaining,
      {
        color: colors.hoverColor,
        hoverColor: colors.hoverColor,
        value: remaining,
      },
    ],
  ]) as Record<string, TStatisticEntry>;
};

import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';

interface IPayAppSummary {
  originalContractSum?: number;
}

export const payData = [
  {
    fieldName: 'originalContractSum',
    fieldNameSnap: 'original_contract_sum',
    title: 'Original Contract Sum',
  },
  {
    fieldName: 'netChangeByChangeOrder',
    fieldNameSnap: 'net_change_by_change_order',
    title: 'Net Change By Change Order',
  },
  {
    fieldName: 'contractSumToDate',
    fieldNameSnap: 'contract_sum_to_date',
    title: 'Contract Sum To Date',
  },
  {
    fieldName: 'totalCompletedAndStoredToDate',
    fieldNameSnap: 'total_completed_and_stored_to_date',
    title: 'Total Completed And Stored To Date',
  },
  {
    fieldName: 'totalRetainage',
    fieldNameSnap: 'total_retainage',
    title: 'Total Retainage',
  },
  {
    fieldName: 'totalEarnedLessRetainage',
    fieldNameSnap: 'total_earned_less_retainage',
    title: 'Total Earned Less Retainage',
  },
  {
    fieldName: 'lessPreviousCertificatesForPayments',
    fieldNameSnap: 'less_previous_certificates_for_payments',
    title: 'Less Previous Certificates For Payments',
  },
  {
    fieldName: 'currentPaymentDue',
    fieldNameSnap: 'current_payment_due',
    title: 'Current Payment Due',
  },
  {
    fieldName: 'balanceToFinishPlusRetainage',
    fieldNameSnap: 'balance_to_finish_plus_retainage',
    title: 'Balance To Finish, Plus Retainage',
  },
];

const getPayAppSummary = (payAppSummary: IPayAppSummary) => {
  return payData.map((payItem) => ({
    ...payItem,
    value: payAppSummary[payItem.fieldName]
      ? payAppSummary[payItem.fieldName]
      : '-',
  }));
};
export default getPayAppSummary;

export const getGroupFormItems = () => {
  return {
    formItems: payData.map((item) => ({
      ...item,
      type: FieldType.INPUT_NUMBER,
      prefix: '$',
      styles: {
        wrapper: 'w-50',
      },
    })),
  };
};

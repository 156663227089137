import React from 'react';
import dayjs from 'dayjs';
import { RangeDatePicker } from 'stories/FlexibleFilterByPeriods/calendar/Calendar';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams';
import { ConnectionFilterByDatePageParams } from 'bundles/Shared/features/connection/filter/byDate/model';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export function ConnectionFilterByDate() {
  const { pageParams, setPageParams } =
    usePageParamsContext<ConnectionFilterByDatePageParams>();
  return (
    <RangeDatePicker
      dayjsPeriods={
        pageParams.createdAtRange.from && pageParams.createdAtRange.to
          ? [
              dayjs(pageParams.createdAtRange.from),
              dayjs(pageParams.createdAtRange.to),
            ]
          : []
      }
      onChange={(periods) => {
        setPageParams({
          ...pageParams,
          createdAtRange: {
            from: formatToDateStringForRequest(periods[0]),
            to: formatToDateStringForRequest(periods[1]),
          },
          page: 1,
        });
      }}
    />
  );
}

import { PropsWithChildren, ReactNode } from 'react';
import {
  FIRST_STEP_HINT_INFO_JSX,
  SELECT_TRANSACTION_TEXT_JSX,
} from '../dumbJSX';
import { createWorkflowFlags } from '../utils/createWorkflowFlags';

interface Props extends PropsWithChildren {
  workflowFlags: ReturnType<typeof createWorkflowFlags>;
  kindSelector: ReactNode;
}

export function TransactionStep({
  workflowFlags,
  kindSelector,
  children,
}: Props) {
  return (
    <div className="flex flex-col gap-tw-4 p-tw-6">
      {!workflowFlags.transactions.isSelected && FIRST_STEP_HINT_INFO_JSX}
      {workflowFlags.transactions.isSelected && (
        <div className="flex flex-col gap-tw-2">
          {SELECT_TRANSACTION_TEXT_JSX}
          {children}
        </div>
      )}

      {workflowFlags.shouldSelectOneKindForMultipleTx && kindSelector}
    </div>
  );
}

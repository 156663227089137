import {
  FetchPermissionedUsersWithMetaResponse,
  LightUser,
} from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal/types';
import http from 'lib/http';
import { IReportTableConfig } from 'types/ReportTableConfig';

export const getReportTablePermissions = async (
  tableConfigId: IReportTableConfig['id'],
) => {
  const res = await http.get(
    `/settings/report/table_configs/${tableConfigId}/permissions`,
  );
  const data = await res.json();

  return data as FetchPermissionedUsersWithMetaResponse<LightUser>;
};

import React, { useMemo } from 'react';
import { currentUserAllowedTo, TProductNames } from 'lib/permissions';
import { AnimationLoader, StreamlinedContainer } from 'stories';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { PayApp } from '@/shared/types/reconcile/PayApp';
import GridTable from 'bundles/Shared/components/GridTable/GridTable';
import {
  destroyContinuationSheetItem,
  updateContinuationSheetItem,
} from '../../../actions';
import { exportContinuationSheet } from '../../../actions/payApps';
import getFieldHead from './initData';
import ActionsContinuationTable from './ActionsContinuationTable';

interface Props {
  payApp: PayApp;
}

const ContinuationSheet = ({ payApp }: Props) => {
  const canEdit = currentUserAllowedTo(
    'configure',
    TProductNames.CHANGE_MANAGEMENT,
  );
  const { assetId, id: payAppId, continuationSheet } = payApp;
  const dispatch = useAppDispatch();

  const agGridProps = {
    headerHeight: 90,
  };

  const configGridProps = {
    editCells: canEdit,
    deleteRow: canEdit,
  };

  const onDestroyAction = (itemId) =>
    dispatch(destroyContinuationSheetItem(assetId, payAppId, itemId));

  const initialColumnDefs = useMemo(
    () => getFieldHead(continuationSheet?.columnValidations, configGridProps),
    [onDestroyAction, payApp],
  );

  const afterSaveCell = (newValue, rowId, dataField) => {
    dispatch(
      updateContinuationSheetItem(assetId, payAppId, rowId, {
        continuation_sheet_item: {
          [dataField === 'gDividedByC' ? 'g_divided_by_c' : dataField]:
            newValue,
        },
      }),
    );
  };

  return (
    <StreamlinedContainer
      actions={
        <ActionsContinuationTable
          exportContinuationSheet={(assetId, id) =>
            dispatch(exportContinuationSheet(assetId, id))
          }
          payApp={payApp}
        />
      }
      title="Continuation Sheet"
      subtitle="Snapshot"
      className="continuation-sheet-table_light"
    >
      <div className="continuation-sheet-table">
        {continuationSheet.items.length ? (
          <GridTable
            data={continuationSheet.items}
            initialRows={continuationSheet.rowValidations}
            initialColumnDefs={initialColumnDefs}
            agGridProps={agGridProps}
            configGridProps={configGridProps}
            height="65vh"
            afterSaveCell={afterSaveCell}
            onDestroyAction={onDestroyAction}
          />
        ) : (
          <AnimationLoader className="relative" />
        )}
      </div>
    </StreamlinedContainer>
  );
};

export default ContinuationSheet;

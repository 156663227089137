import FlyBottomPanelContainer from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelContainer';
import TreeViewWithNavigation from 'bundles/Settings/components/REport/CategoriesList/TreeViewWithNavigation/TreeViewWithNavigation';
import {
  LeClassificationModal,
  LeClassification,
  resolveAvailableAssetClassesForCategory,
} from 'bundles/Shared/entities/leClasssification';
import { FinancialCategory } from 'bundles/Shared/entities/finanicalCategory/model';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { traverse, treeDFS } from 'lib/treeHelpers';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { useState } from 'react';
import Button from 'stories/Button/Button';
import { Modal } from 'stories/index';
import EditCategoryModal from './EditCategoryModal';
import NewSubCategoriesModal from './NewSubCategoriesModal';

interface Props
  extends DialogProps<FinancialCategory[]>,
    Pick<React.ComponentProps<typeof Modal>, 'header'> {
  categories: FinancialCategory[];
  readonly?: boolean;
}

interface ICategoryWithName extends FinancialCategory {
  name: string;
  children: ICategoryWithName[];
  parentId: number | null;
  title: string;
  isNew?: boolean;
  expanded?: boolean;
}

// all state mutation works for now, because d3 manageTags dom,
// can breaks if react will manageTags dom
function ManageCategoriesModal({
  onClose,
  onSubmit,
  categories: initialCategories,
  ...props
}: Props) {
  const [_, setIsSubmitting] = useState(false);
  const { openModal } = useModal();
  const [categories, setCategories] = useState<ICategoryWithName[]>(
    initialCategories.map((c) =>
      traverse(
        c,
        (cx) =>
          ({
            ...cx,
            name: cx.code,
            title: cx.code,
            expanded: true,
          }) as ICategoryWithName,
      ),
    ),
  );
  const [isNewCategoriesModalOpen, setIsNewCategoriesModalOpen] =
    useState(false);
  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] =
    useState<ICategoryWithName | null>(null);
  const [createMode, setCreateMode] = useState<'below' | 'sub' | null>(null);

  const handleNewCategoriesModalClose = (newCategories: string[] | null) => {
    if (newCategories != null) {
      const newCategoriesNodes = newCategories.map((c, i) => ({
        id: new Date().getTime() + i,
        isNew: true,
        code: c,
        name: c,
      }));
      const categoryToUpdatePath = treeDFS(categories, (node) =>
        createMode === 'sub'
          ? node.id === currentCategory?.id
          : node.children?.some((cx) => cx.id === currentCategory?.id),
      );
      const categoryToUpdate = categoryToUpdatePath?.at(-1);

      if (categoryToUpdate == null) {
        setCategories([...categories, ...newCategoriesNodes]);
      } else {
        categoryToUpdate.children = [
          ...(categoryToUpdate.children ?? []),
          ...newCategoriesNodes,
        ];

        setCategories([...categories]);
      }
    }
    setIsNewCategoriesModalOpen(false);
    setCurrentCategory(null);
    setCreateMode(null);
  };

  const handleEditCategoryModalClose = (newName: string | null) => {
    if (newName != null) {
      const categoryToUpdatePath = treeDFS(
        categories,
        (node) => node.id === currentCategory?.id,
      );
      const categoryToUpdate = categoryToUpdatePath?.at(-1);

      if (categoryToUpdate) {
        categoryToUpdate.code = newName;
        categoryToUpdate.name = newName;
      }

      setCategories([...categories]);
    }
    setIsEditCategoryModalOpen(false);
    setCurrentCategory(null);
  };

  const handleRename = (data) => {
    setCurrentCategory(data);
    setIsEditCategoryModalOpen(true);
  };

  const handleCreateNearby = (data) => {
    setCreateMode('below');
    setCurrentCategory(data);
    setIsNewCategoriesModalOpen(true);
  };

  const handleCreateSub = (data) => {
    setCreateMode('sub');
    setCurrentCategory(data);
    setIsNewCategoriesModalOpen(true);
  };

  const handleRemove = (data) => {
    const categoryToUpdatePath = treeDFS(
      categories,
      (node) => node.id === data.id,
    );
    const categoryToUpdate = categoryToUpdatePath?.at(-2);

    if (categoryToUpdate == null) {
      setCategories(categories.filter((c) => c.id !== data.id));
    } else {
      categoryToUpdate.children = categoryToUpdate.children?.filter(
        (c) => c.id !== data.id,
      );
      setCategories([...categories]);
    }
  };

  const handleSpecifyAssetClasses = async (data: ICategoryWithName) => {
    const categoryToUpdatePath = treeDFS(
      categories,
      (node) => node.id === data.id,
    )!;
    const categoryToUpdate = categoryToUpdatePath.at(-1)!;

    const { availableAssetClasses, requiredAssetClasses } =
      resolveAvailableAssetClassesForCategory({
        categoryPath: categoryToUpdatePath,
      });

    const res = await openModal(LeClassificationModal, {
      isMulti: true,
      defaultClass: isEmpty(data.classifications)
        ? requiredAssetClasses
        : data.classifications,
      availableClasses: isEmpty(availableAssetClasses)
        ? undefined
        : availableAssetClasses,
      isDefaultClassesClearable: isEmpty(requiredAssetClasses),
    });

    if (res == null) {
      return;
    }

    categoryToUpdate.classifications = res as LeClassification[];
    setCategories([...categories]);
  };

  const handleSubmit = async () => {
    const newTree = traverse(
      {
        children: categories,
      } as ICategoryWithName,
      (n) =>
        ({
          id: n.isNew ? undefined : n.id,
          code: n.code,
          children: n.children,
          classifications: n.classifications,
        }) as Omit<FinancialCategory, 'parentId'>,
    ).children;
    setIsSubmitting(true);
    await onSubmit?.(newTree);
    setIsSubmitting(false);
  };

  const actions = [
    {
      text: 'Rename',
      handler: handleRename,
    },
    {
      text: 'New Category Below',
      handler: handleCreateNearby,
    },
    {
      text: 'Create Sub Category',
      handler: handleCreateSub,
    },
    {
      text: 'Specify Legal Entity Classes',
      handler: handleSpecifyAssetClasses,
      disabled: (data) => isEmpty(data.children),
    },
    {
      text: 'Remove',
      handler: handleRemove,
    },
  ];

  return (
    <Modal
      size="huge"
      header="Categories"
      bodyPadding="0"
      toggle={onClose}
      {...props}
    >
      <div className="h-full w-full">
        <TreeViewWithNavigation
          data={categories}
          onChange={setCategories}
          actions={actions}
          showMultipleRootsParent
          multipleRootsParentName="Categories"
          readonlyNavigation
          handleManageAssetClasses={handleSpecifyAssetClasses}
        />
      </div>
      <FlyBottomPanelContainer>
        <Button variant="success" size="s" onClick={handleSubmit}>
          Save
        </Button>
      </FlyBottomPanelContainer>
      {isNewCategoriesModalOpen && currentCategory != null && (
        <NewSubCategoriesModal
          createMode={createMode}
          current={currentCategory.name}
          onClose={handleNewCategoriesModalClose}
        />
      )}
      {isEditCategoryModalOpen && currentCategory != null && (
        <EditCategoryModal
          current={currentCategory.name}
          onClose={handleEditCategoryModalClose}
        />
      )}
    </Modal>
  );
}

export default ManageCategoriesModal;

import React, { PropsWithChildren } from 'react';
import { FromSpan, GrayColorSpan } from './Monthly';
import * as Types from '../types';
import * as Util from '../utils';

interface Props {
  periodItems: Types.IPeriodItem[];
}

interface AndWrapperProps extends PropsWithChildren {
  arrLength: number;
  currIdx: number;
}

export const ConditionalAnd = ({
  currIdx,
  arrLength,
  children,
}: AndWrapperProps) => (
  <span>
    {children}
    {arrLength - 1 !== currIdx && <GrayColorSpan text=" and " />}
  </span>
);

export function Quarterly({ periodItems }: Props) {
  const arrOfPeriodSequences =
    Util.createArrOfSequencesForQuarterlyPeriodType(periodItems);

  if (arrOfPeriodSequences.length > 1) {
    return <>Custom Period</>;
  }

  if (
    arrOfPeriodSequences.length === 1 &&
    arrOfPeriodSequences[0].length === 1
  ) {
    const period = arrOfPeriodSequences[0][0];
    const quarterNumber = Util.getQuarterNumber(period);
    const year = Util.getShortYear(period);
    const fromQuarter = `Q${quarterNumber}-${year}`;

    return (
      <div>
        <GrayColorSpan text="Only" /> {fromQuarter}
      </div>
    );
  }

  return (
    <div>
      {arrOfPeriodSequences.map((periodSeq, idx, arr) => {
        const renderQuarters = () => {
          const firstPeriod = periodSeq[0];
          const firstQuarter = Util.getQuarterNumber(firstPeriod);
          const firstYear = Util.getShortYear(firstPeriod);
          const firstItemJSX = (
            <>
              Q{firstQuarter}-{firstYear}
            </>
          );

          if (periodSeq.length > 1) {
            const lastPeriod = periodSeq.at(-1)!;
            const lastQuarter = Util.getQuarterNumber(lastPeriod);
            const lastYear = Util.getShortYear(lastPeriod);

            const lastItemJSX = (
              <>
                Q{lastQuarter}-{lastYear}
              </>
            );

            return (
              <span>
                <FromSpan idx={idx} /> {firstItemJSX}{' '}
                <GrayColorSpan text="to" /> {lastItemJSX}
              </span>
            );
          }

          return <span>{firstItemJSX}</span>;
        };

        return (
          <ConditionalAnd
            key={periodSeq[0]}
            currIdx={idx}
            arrLength={arr.length}
          >
            {renderQuarters()}
          </ConditionalAnd>
        );
      })}
    </div>
  );
}

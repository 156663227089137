import { updateUserRole } from 'bundles/UserManagement/reducers/userRolesSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import React, { useEffect, useState } from 'react';
import { Button, Field, IconButton, Input, Select } from 'stories';
import type { ISelectOption } from 'stories/FormControls/Select/Select';
import type { IUserRole } from 'types/User';
import type * as Types from '../types';

interface Props {
  rightBarState: Types.RightBarStateType;
  setRightBarState: React.Dispatch<
    React.SetStateAction<Types.RightBarStateType>
  >;
  activeRole: IUserRole;
  groupOptions: Types.IGroupOptions;
}

type FormData = Partial<Pick<IUserRole, 'group' | 'name'>>;

export function EditBasicInfo({
  rightBarState,
  setRightBarState,
  activeRole,
  groupOptions,
}: Props) {
  const initialFormData: Pick<IUserRole, 'group' | 'name'> = {
    group: activeRole.group,
    name: activeRole.name,
  };
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(initialFormData);

  const editState = rightBarState.includes('edit:');

  const leaveEditState = () => {
    setRightBarState('idle');
    setFormData(initialFormData);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setFormData((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const handleSelect = (sel: ISelectOption) => {
    setFormData((prev) => ({
      ...prev,
      group: sel?.label ?? null,
    }));
  };

  const handleSubmit = () => {
    setRightBarState('saving');
    const preparedFormData: FormData = {};
    if (formData.group !== activeRole.group) {
      preparedFormData.group = formData.group;
    }
    if (formData.name !== activeRole.name) {
      preparedFormData.name = formData.name;
    }
    dispatch(updateUserRole({ id: activeRole.id, data: preparedFormData }));
    setRightBarState('idle');
  };

  useEffect(() => {
    setRightBarState(
      editState && ['', null].includes(formData.name)
        ? 'edit:invalidData'
        : 'edit:validData',
    );
  }, [formData]);

  const isSaveBtnDisabled =
    ['edit:invalidData', 'edit:initial'].includes(rightBarState) ||
    (formData.group === activeRole.group && formData.name === activeRole.name);

  return (
    <div className="mb-xl grid gap-m rounded-[8px] bg-white p-m">
      <div className="flex items-center justify-between">
        <h2 className="header5-bold">Edit Basic Information</h2>
        <IconButton onClick={leaveEditState} size="l" iconName="close" />
      </div>
      <Field labelText="Group">
        <Select
          spanContainer="m-0"
          inputProps={{ className: 'w-full' }}
          selected={{ id: formData?.group ?? '', label: formData?.group ?? '' }}
          options={groupOptions[activeRole.category]}
          onSelectedChange={handleSelect}
        />
      </Field>
      <Field labelText="Role Name" required>
        <Input value={formData.name} name="name" onChange={handleChange} />
      </Field>

      <Button
        className="mt-s"
        size="m"
        onClick={handleSubmit}
        disabled={isSaveBtnDisabled}
        variant="success"
      >
        Save Updates
      </Button>
    </div>
  );
}

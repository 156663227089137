import * as yup from 'yup';
import { FilterSetDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { mapListToIds } from '@/shared/lib/listHelpers';

export const DASHBOARD_FILTER_SET_FORM = yup.object().shape({
  name: yup.string().required(),
  asset_ids: yup.array().of(yup.number().required()).required(),
  segment_ids: yup.array().of(yup.number().required()).required(),
  excluded_legal_entity_ids: yup.array().of(yup.string().required()).required(),
});

export type DashboardFilterSetForm = yup.InferType<
  typeof DASHBOARD_FILTER_SET_FORM
>;

export const DEFAULT_DASHBOARD_FILTER_SET_FORM_VALUES: Partial<DashboardFilterSetForm> =
  {
    asset_ids: [],
    segment_ids: [],
    excluded_legal_entity_ids: [],
  };

export const transformFilterSetToForm = (
  filterSet: FilterSetDto,
): DashboardFilterSetForm => ({
  name: filterSet.name,
  asset_ids: mapListToIds(filterSet.assets),
  segment_ids: mapListToIds(filterSet.segments),
  excluded_legal_entity_ids: mapListToIds(filterSet.excludedLegalEntities),
});

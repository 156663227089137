import { useInvalidateGeneralLedgers } from '@/bundles/REturn/hooks/useInvalidateGeneralLedgers';
import {
  useInvalidateCapitalTransactionSources,
  useLoadedCapitalTransactionSources,
} from '@/bundles/REturn/hooks/useLoadedCapitalTransactionSources';
import { useLoadedCapitalUnitPrices } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { CapitalInvestmentCard } from '@/entities/return/ui/CapitalInvestmentCard';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { cn } from '@/shared/lib/css/cn';
import { createCapitalTransactionSource as plainCreateCapitalTransactionSource } from 'bundles/REturn/actions/capitalTransactionSources';
import SourcesModal from 'bundles/REturn/components/Ownership/ownershipsHeader/sourcesModal/SourcesModal';
import {
  useInvalidateCapitalInvestments,
  useLoadedInvestmentObject,
} from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import { TReturnInvestmentTransactionDirection } from 'bundles/REturn/types';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { useCustomer } from 'lib/customers';
import { useModal } from '@/shared/lib/hooks/useModal';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { partition } from 'lodash-es';
import { CapitalInvestmentClassSettingsCard } from './CapitalInvestmentClassSettingsCard';

export function CardTitle({
  value,
  title,
  variant = 'default',
  zeroValue = false,
}: {
  value: React.ReactNode;
  title: string;
  variant?: 'default' | 'percentage';
  zeroValue?: boolean;
}) {
  return (
    <div className="flex flex-col items-center pt-tw-2">
      <p
        className={cn(
          'header5-regular text-neutral-900',
          (value === 0 || zeroValue) && 'text-neutral-500',
        )}
      >
        {value}
        {variant === 'percentage' && (
          <span className="text-neutral-500">{' %'}</span>
        )}
      </p>
      <p className="label-semibold w-max text-center text-neutral-500">
        {title}
      </p>
    </div>
  );
}

const UNIT_PRICES_LEN_BREAKPOINT = 3;

export default function OwnershipsCards() {
  const {
    value: isUpdatingSources,
    setFalse: setUpdatingSourcesDisabled,
    setTrue: setUpdatingSourcesEnabled,
  } = useBoolean();
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const { fromSourceEnabled } = capitalInvestmentObject;

  const { data: unitPricesData } = useLoadedCapitalUnitPrices();

  const { data: capitalTransactionSources } =
    useLoadedCapitalTransactionSources();

  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);
  const { openModal } = useModal();

  const [contributions, distributions] = partition(
    capitalTransactionSources,
    (source) =>
      source.kind === TReturnInvestmentTransactionDirection.CONTRIBUTION,
  );
  const contributionSources = contributions.length;
  const hasContributionSources = contributionSources > 0;

  const distributionSources = distributions.length;
  const hasDistributionSources = distributionSources > 0;

  const unitPrices = unitPricesData ?? [];

  const unitPricesLen = unitPrices.length;

  const invalidateTransactions = useInvalidateCapitalTransactionSources();
  const invalidateCapitalInvestments = useInvalidateCapitalInvestments();
  const invalidateGeneralLedgers = useInvalidateGeneralLedgers();

  const handleSources = async (kind: TReturnInvestmentTransactionDirection) => {
    const maybeArgs = await openModal(SourcesModal, {
      kind,
      capitalTransactionSources,
      legalEntitiesIds: capitalInvestmentObject.legalEntities.map(
        (le) => le.id,
      ),
    });

    if (maybeArgs === undefined) return;

    setUpdatingSourcesEnabled();
    await plainCreateCapitalTransactionSource(
      {
        data: maybeArgs.contributions,
        kind: 'contribution',
        investment_object_id: capitalInvestmentObject.object.id,
        investment_object_type: capitalInvestmentObject.object.objectType,
      },
      false,
    );
    await plainCreateCapitalTransactionSource({
      data: maybeArgs.distributions,
      kind: 'distribution',
      investment_object_id: capitalInvestmentObject.object.id,
      investment_object_type: capitalInvestmentObject.object.objectType,
    });
    setUpdatingSourcesDisabled();

    invalidateGeneralLedgers();
    invalidateTransactions();
    invalidateCapitalInvestments();
  };

  return (
    <div
      className={cn(
        'grid items-center gap-tw-4 px-tw-6 pt-tw-6',
        unitPricesLen >= UNIT_PRICES_LEN_BREAKPOINT &&
          'grid-cols-[1fr_2fr_2fr]',
        unitPricesLen < UNIT_PRICES_LEN_BREAKPOINT &&
          'grid-cols-[repeat(3,1fr)]',
      )}
    >
      {currentCustomerHasReportProduct && (
        <CapitalInvestmentCard
          fireEnabled={!hasContributionSources || !hasDistributionSources}
          onSettings={() =>
            handleSources(TReturnInvestmentTransactionDirection.CONTRIBUTION)
          }
          isLoading={isUpdatingSources}
          title="Sources"
          isDisabled={!fromSourceEnabled}
        >
          <CardTitle value={contributionSources} title="Contribution" />
          <VerticalSeparator className="-mx-tw-2 w-tw-6 bg-neutral-150" />
          <CardTitle value={distributionSources} title="Distribution" />
        </CapitalInvestmentCard>
      )}

      <CapitalInvestmentClassSettingsCard investmentClass="a" />
      <CapitalInvestmentClassSettingsCard investmentClass="b" />
    </div>
  );
}

import {
  accountingNumberFormat,
  DISABLE_CURRENCY_SYMBOL_OPTIONS,
} from '@/shared/lib/formatting/number';
import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { Button, IconButton } from 'stories';
import styles from './AmountCard.module.scss';

const CardContainer = ({
  className,
  children,
}: React.PropsWithChildren<{ className?: string }>) => (
  <div className={cn('rounded-[1rem] bg-light-5', className)}>
    {children}
  </div>
);

const WhitePartContainer = ({ children }) => (
  <div className="rounded-[1rem] p-m flex flex-col items-start gap-tw-2 border bg-white">
    {children}
  </div>
);

export const AmountNoDataCard = ({
  label,
  className,
  onAdd,
}: {
  label: string;
  className?: string;
  onAdd?: () => void;
}) => (
  <CardContainer className={className}>
    <WhitePartContainer>
      <span className="header-5 regular dark-60">{label}</span>
      <span className="light-60 label-regular">No Changes yet</span>
      <Button variant="primary" onClick={onAdd}>
        Add Data
      </Button>
    </WhitePartContainer>
  </CardContainer>
);

interface Props {
  amount: number;
  label: string;
  source: string;
  date: number;
  className?: string;
  onEdit?: () => void;
}

export const AmountCard = ({
  amount,
  label,
  source,
  date,
  onEdit,
  className,
}: Props) => (
  <CardContainer className={className}>
    <WhitePartContainer>
      <div className="flex justify-between w-full">
        <span className="header-5 regular dark-60">
          <span className="light-60">$ </span>
          {accountingNumberFormat(amount, DISABLE_CURRENCY_SYMBOL_OPTIONS)}
        </span>
        <IconButton onClick={onEdit} iconName="edit" />
      </div>
      <span className="light-60 label-regular">{label}</span>
    </WhitePartContainer>
    <div className="px-m py-s">
      <div className={styles.table}>
        <span className="light-60 secondary-regular">Source</span>
        <span className="light-90 label-regular">{source}</span>
        <span className="light-60 secondary-regular">Date</span>
        <span className="light-90 label-regular">
          {formatUnixDate(date, 'LL [at] LT')}
        </span>
      </div>
    </div>
  </CardContainer>
);

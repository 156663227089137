import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';
import Tag from '../Tag/Tag';

type Props = Omit<React.ComponentProps<typeof Tag>, 'endIcon'>;

export const ColorTag = ({ className, variant, selected, ...props }: Props) => (
  <Tag
    className={cn('sre-tag_color-component', className)}
    startIcon={selected && <Icon iconName="checkSmall" />}
    selected={selected}
    variant={variant}
    {...props}
  />
);

export default ColorTag;

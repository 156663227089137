import { cn } from '@/shared/lib/css/cn';

const Header = ({
  children,
  className,
  ...props
}: React.AllHTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className={cn(
      'group flex h-[24px] cursor-pointer items-center justify-between gap-tw-2 px-tw-4',
      className,
    )}
  >
    {children}
  </div>
);

export const ConnectionExtractionCard = ({
  children,
  className,
  ...props
}: React.AllHTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col gap-tw-2 rounded-2xl bg-neutral-250 px-tw-1 pb-tw-1 pt-tw-2',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

ConnectionExtractionCard.Header = Header;

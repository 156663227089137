import { LE_CLASSIFICATION_OPTIONS } from 'bundles/Shared/entities/leClasssification/config';
import { LeClassification } from 'bundles/Shared/entities/leClasssification/types';
import { FinancialCategory } from 'bundles/Shared/entities/finanicalCategory/model';
import { isEmpty, uniq } from 'lodash-es';
import { traverse, treeDFM } from 'lib/treeHelpers';

export const findAssetClassOptionByValue = (value: LeClassification) =>
  LE_CLASSIFICATION_OPTIONS.find((option) => option.value === value);

export const resolveAvailableAssetClassesForCategory = ({
  categoryPath,
}: {
  categoryPath: FinancialCategory[];
}): {
  availableAssetClasses: LeClassification[];
  requiredAssetClasses: LeClassification[];
} => {
  const parentWithClassifications = categoryPath
    .slice(0, -1)
    .find((node) => !isEmpty(node.classifications));

  if (parentWithClassifications != null) {
    return {
      requiredAssetClasses: [],
      availableAssetClasses: parentWithClassifications.classifications,
    };
  }
  const category = categoryPath.at(-1)!;

  return {
    requiredAssetClasses: uniq(
      treeDFM(
        category.children ?? [],
        (node) => node.classifications ?? [],
      ).flat(),
    ),
    availableAssetClasses: [],
  };
};

export const filterCategoriesByAssetClass = ({
  categories,
  assetClass,
}: {
  categories: FinancialCategory[];
  assetClass: LeClassification;
}) => {
  // if has class and doesn't include assetClass -> remove
  // if doesn't have class and has children -> keep

  const filteredTree = traverse(
    { children: categories } as FinancialCategory,
    (node) => {
      const children = node.children?.filter(
        (child) =>
          isEmpty(child.classifications) ||
          child.classifications.includes(assetClass),
      );
      return {
        ...node,
        children,
      };
    },
  );

  return filteredTree.children;
};

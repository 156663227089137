type TTreeFuncParams<T, M, K extends string = string> = [
  tree: T[],
  callback: (n: T, path: T[]) => M,
  options?: { visitedNodes: T[]; childrenKey: K },
];

export const treeDF = <T, K extends string = string>(
  ...params: TTreeFuncParams<T, boolean | void, K>
) => {
  const [
    tree,
    callback,
    options = { visitedNodes: [], childrenKey: 'children' as K },
  ] = params;

  return tree?.some((node) => {
    const nodePath = [...options.visitedNodes, node];

    if (callback(node, nodePath)) return true;

    if (
      treeDF(node[options.childrenKey], callback, {
        visitedNodes: nodePath,
        childrenKey: options.childrenKey,
      })
    )
      return true;
    return false;
  });
};

export class TreeData {
  private static isTree<T>(x: unknown): x is T[] {
    return Array.isArray(x);
  }

  static deepFirst<
    Node extends Record<string, unknown>,
    Key extends keyof Node,
  >(
    tree: Node[],
    callback: (n: Node, path: Node[]) => boolean,
    options: {
      visitedNodes: Node[];
      childrenKey: Key;
    } = {
      visitedNodes: [],
      childrenKey: 'children' as Key,
    },
  ): boolean {
    return tree.some((node) => {
      const nodePath = [...options.visitedNodes, node];

      if (callback(node, nodePath)) return true;

      const nextTree = node[options.childrenKey];

      if (!TreeData.isTree<Node>(nextTree)) return false;

      return TreeData.deepFirst(nextTree, callback, {
        visitedNodes: nodePath,
        childrenKey: options.childrenKey,
      });
    });
  }
}

export const treeDFS = <T, K extends string = string>(
  ...params: TTreeFuncParams<T, boolean, K>
): T[] | null => {
  const [tree, matcher, options] = params;

  let result: T[] | null = null;

  treeDF(
    tree,
    (node, nodePath) => {
      if (!matcher(node, nodePath)) return false;

      result = nodePath;
      return true;
    },
    options,
  );

  return result;
};

export const treeDFM = <T, M, K extends string = string>(
  ...params: TTreeFuncParams<T, M, K>
) => {
  const [tree, callback, options] = params;

  const result: M[] = [];

  treeDF(
    tree,
    (...args) => {
      result.push(callback(...args));
    },
    options,
  );

  return result;
};

export function traverse<
  T extends {
    children?: T[];
  },
  M,
>(current: T, callback: (n: T, path: T[]) => M, path: T[] = [current]): M {
  const temp = {
    ...current,
    children: current.children?.map((child) =>
      traverse(child, callback, [...path, child]),
    ),
  };
  return callback(temp, path);
}

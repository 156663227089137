import { reportDashboardsApi } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';

const enhancedApi = reportDashboardsApi.enhanceEndpoints({
  addTagTypes: ['DashboardSettingsFilterSet'],
  endpoints: {
    postApiSettingsReportEagleEyeDashboardsFilterSets: {
      invalidatesTags: ['DashboardSettingsFilterSet'],
      transformResponse: (response) => {
        toastr.success('Filter set created successfully');
        return response;
      },
    },
    deleteApiSettingsReportEagleEyeDashboardsFilterSetsById: {
      invalidatesTags: ['DashboardSettingsFilterSet'],
      transformResponse: (response) => {
        toastr.success('Filter set removed successfully');
        return response;
      },
    },
    getApiReportEagleEyeDashboardsFilterSets: {
      providesTags: ['DashboardSettingsFilterSet'],
    },
    putApiSettingsReportEagleEyeDashboardsFilterSetsById: {
      invalidatesTags: ['DashboardSettingsFilterSet'],
      transformResponse: (response) => {
        toastr.success('Filter set updated successfully');
        return response;
      },
    },
  },
});

export { enhancedApi as dashboardSettingsFilterSetsEnhancedApi };

export const {
  useGetApiReportEagleEyeDashboardsFilterSetsQuery,
  useGetApiSettingsReportEagleEyeDashboardsFilterSetsQuery,
  usePostApiSettingsReportEagleEyeDashboardsFilterSetsMutation,
  useDeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation,
  useGetApiSettingsReportEagleEyeDashboardsFilterSetsMetaQuery,
  usePutApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation,
} = enhancedApi;

import React from 'react';
import { navigate } from '@reach/router';
import { Button } from 'stories';
import styles from './OpenDealsCarousel.module.scss';
import { truncate } from 'lodash-es';
import Slider from 'react-slick';
import { GetApiPortfolioDetailsApiResponse } from 'bundles/Shared/shared/api/portfolioGeneratedApi';

const MAX_MEASURE_NAME_CHAR_LENGTH = 42;

interface Props {
  offering:
    | GetApiPortfolioDetailsApiResponse['funds'][number]
    | GetApiPortfolioDetailsApiResponse['assets'][number];
  totalSize: number;
  activeIndex: number;
  slider: React.RefObject<Slider>;
}

const OpenDealsSlide = ({
  offering,
  totalSize,
  activeIndex,
  slider,
}: Props) => (
  <div className={styles.container}>
    <div
      className={styles.header}
      style={
        offering.smallPictureUrl
          ? {
              backgroundImage: `url(${offering.smallPictureUrl})`,
            }
          : undefined
      }
    >
      <div className={styles.overlay}>
        <div className="dark-60 font-weight-700">{offering.name}</div>
        {'address' in offering && (
          <div className="dark-60 font-weight-400 secondary-regular">
            {offering.address}
          </div>
        )}
      </div>
    </div>
    <div className={styles.body}>
      <div className="flex flex-wrap justify-between">
        {offering.info.map((info) => (
          <div key={info.measure_name} className="w-48">
            <div className="dark-60 inline-semibold">{info.value}</div>
            <div className="dark-40 secondary-regular mb-m">
              {truncate(info.measure_name, {
                length: MAX_MEASURE_NAME_CHAR_LENGTH,
              })}
            </div>
          </div>
        ))}
      </div>
      <Button
        variant="primary"
        onClick={() => navigate(`${offering.objectType}s/${offering.slug}`)}
      >
        Learn More
      </Button>
    </div>
  </div>
);

export default OpenDealsSlide;

import {
  Link,
  RouteComponentProps,
  Router,
  useLocation,
  useNavigate,
} from '@reach/router';
import AssetDocuments from 'bundles/Assets/components/AssetDocuments';
import FundFormModal from 'bundles/Funds/components/FundFormModal';
import ExternalUsersListModal from 'bundles/InvestmentObjects/components/ExternalUsersListModal';
import Posts from 'bundles/InvestmentObjects/components/Overview/Posts';
import { toggleCoreEntityDashboardExpanded } from 'bundles/Settings/components/Portal/AssetDashboard/api/coreEntitySlice';
import { FUND_DASHBOARD_PAGE_TABS_MAP } from 'bundles/Settings/components/Portal/FundDashboard';
import {
  useGetFundQuery,
  useGetVisibleReportTableConfigsQuery,
  useHeavyUpdateFundMutation,
  useHeavyUpdateFundWithFormMutation,
  useHeavyUpdateFundWithInvalidationMutation,
  useSimpleUpdateFundMutation,
} from 'bundles/Settings/components/Portal/FundDashboard/api/coreFundsApi';
import FundAssetList from 'bundles/Settings/components/Portal/FundDashboard/ui/FundAssetList';
import FundAssetsPanel from 'bundles/Settings/components/Portal/FundDashboard/ui/FundAssetsPanel';
// import { ALL_STATUS_OPTIONS_MAP } from 'bundles/Settings/components/Portal/shared/consts';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { ALL_STATUS_OPTIONS_MAP } from 'bundles/Settings/components/Portal/shared/consts';
import Banner from 'bundles/Settings/components/Portal/shared/ui/Banner';
import KeyInfoModal from 'bundles/Settings/components/Portal/shared/ui/KeyInfoModal';
import ObjectFinancialTabs from 'bundles/Settings/components/Portal/shared/ui/ObjectFinancialTabs';
import StatusDropdown from 'bundles/Settings/components/Portal/shared/ui/StatusDropdown';
import TabLink, {
  TAB_LINK_CLASSES,
} from 'bundles/Settings/components/Portal/shared/ui/TabLink';
import { getDraftObjectsWarningMessage } from 'bundles/Settings/components/Portal/shared/utils';
import EntityContext from 'bundles/Shared/EntityContext';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { filterByExternal } from 'bundles/Shared/entities/user/lib';
import { MODULE_LABELS } from 'lib/dictionaries';
import {
  currentUserAllowedTo,
  currentUserIsExternal,
  currentUserIsInternal,
  currentUserIsSreAdmin,
  REPORT_PRODUCT_NAME,
  TProductNames,
} from 'lib/permissions';
import { RouterComponentWithoutWrapper } from 'lib/reach-router';
import { capitalize } from 'lodash-es';
import { useEffect, useState } from 'react';
import FavoriteCardIcon from 'stories/ProjectCard/FavoriteCardIcon';
import { AnimationLoader, Button, Icon, IconButton } from 'stories/index';
import { ISharedDocument } from 'types/SharedDocument';

export function FundDashboardContent({
  fundSlug,
}: RouteComponentProps<{ fundSlug: string }>) {
  const { data: fund, isFetching } = useGetFundQuery(fundSlug ?? '', {
    skip: !fundSlug,
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    data: visibleReportTableConfigs,
    isFetching: isFetchingVisibleConfigs,
  } = useGetVisibleReportTableConfigsQuery(fund?.slug ?? '', {
    skip: !currentUserAllowedTo('view', REPORT_PRODUCT_NAME) || !fund?.slug,
  });

  const [heavyUpdate] = useHeavyUpdateFundMutation();
  const [simpleUpdate] = useSimpleUpdateFundMutation();
  const [heavyUpdateWithInvalidate] =
    useHeavyUpdateFundWithInvalidationMutation();
  const [heavyUpdateWithForm] = useHeavyUpdateFundWithFormMutation();

  const dispatch = useAppDispatch();
  const { openModal, resetAllModals } = useModal();
  const [selectedRows, setSelectedRows] = useState<ISharedDocument[]>([]);
  const {
    value: keyInfoModalIsOpen,
    setFalse: closeKeyInfoModal,
    setTrue: openKeyInfoModal,
  } = useBoolean(false);
  const { isItemFavorite, toggleItemFavorite } = useFavoriteItemIds(
    'coreFundsFavoriteIds',
  );

  const handleToggleExpand = () => {
    dispatch(toggleCoreEntityDashboardExpanded());
  };

  useEffect(
    () => () => {
      resetAllModals();
    },
    [],
  );

  const dashboardExpanded = useAppSelector(
    (state) => state.coreEntity.dashboardExpanded,
  );

  if (isFetching) {
    return <AnimationLoader className="relative h-full w-full" />;
  }

  if (!fund) return null;

  const externalUsers = filterByExternal(fund?.users ?? []);

  const externalUsersListButton = (
    <Button
      tooltipProps={{
        mainText: `This asset has ${externalUsers.length} external users`,
      }}
      onClick={() => {
        openModal(ExternalUsersListModal, {
          object: fund,
          externalUsers,
        });
      }}
      className="secondary-regular gap-tw-1"
      iconName="users"
      variant="secondary"
      size="s"
    >
      {externalUsers.length}
    </Button>
  );

  const openObjectModal = async () => {
    const res = await openModal(FundFormModal, {
      fund,
    });

    if (res == null) return;

    const response = await heavyUpdateWithForm(res);

    if (
      response != null &&
      'data' in response &&
      typeof response.data.slug === 'string'
    ) {
      navigate(
        generateUrl(ROUTES_ROOT.funds.fund.fullPath, {
          pathParams: {
            fundSlug: response.data.slug,
          },
        }),
      );
    }
  };

  const isCurrentUserExternal = currentUserIsExternal();
  const isUserAllowedToManageFund = currentUserAllowedTo(
    'manage',
    TProductNames.FUND_PORTAL,
    'Fund',
    fund.id,
  );
  const isUserAllowedToManageReturn = currentUserAllowedTo(
    'manage',
    TProductNames.RETURN,
  );
  const isUserAllowedToViewReport = currentUserAllowedTo(
    'view',
    TProductNames.REPORT,
  );
  const isUserAllowedToViewFund = currentUserAllowedTo(
    'view',
    TProductNames.FUND_PORTAL,
    'Fund',
    fund.id,
  );

  const isUserAllowedToViewFundStatus =
    !isCurrentUserExternal &&
    !isUserAllowedToManageFund &&
    isUserAllowedToViewFund;

  const statusOption = ALL_STATUS_OPTIONS_MAP[fund.aasmState];

  const slot = (
    <>
      <div className="flex min-h-[56px] items-start justify-between gap-tw-4">
        {dashboardExpanded && (
          <IconButton
            onClick={handleToggleExpand}
            className="h-full"
            iconName="arrowLeft"
            variant="secondary"
          />
        )}
        <ListNavLayout.HeaderImg
          imgSrc={fund.smallPictureUrl}
          placeholderIcon="funds"
        />
        <ListNavLayout.DashboardHeaderInfo subtitle="Fund" title={fund.name} />

        <div className="ml-auto flex gap-tw-2">
          {isUserAllowedToManageFund && (
            <StatusDropdown
              object={fund}
              heavyUpdate={heavyUpdateWithInvalidate}
              status={fund.aasmState}
            >
              <IconButton
                iconName={statusOption.iconName}
                classes={{ icon: statusOption.className }}
                size="l"
                variant="secondary"
              />
            </StatusDropdown>
          )}
          {isUserAllowedToViewFundStatus && (
            <IconButton
              tooltipProps={{
                mainText: capitalize(statusOption.value),
              }}
              iconName={statusOption.iconName}
              className="!cursor-default"
              classes={{ icon: statusOption.className }}
              size="l"
              variant="secondary"
            />
          )}
          {!dashboardExpanded && (
            <IconButton
              className="flex 4xl:hidden"
              onClick={openKeyInfoModal}
              iconName="infoAlt"
              variant="secondary"
              size="l"
            />
          )}
          {isUserAllowedToManageFund && (
            <IconButton
              onClick={openObjectModal}
              iconName="settings"
              variant="secondary"
              size="l"
            />
          )}
          {!dashboardExpanded && fund.assets.length > 0 && (
            <Button
              onClick={() => {
                openModal(FundAssetsPanel, {
                  fund,
                });
              }}
              className="secondary-regular flex gap-tw-1 4xl:hidden"
              iconName="asset"
              variant="secondary"
              size="s"
            >
              {fund.assets.length}
            </Button>
          )}
          {(currentUserIsInternal() || currentUserIsSreAdmin()) &&
            externalUsers.length > 0 &&
            externalUsersListButton}
          <VerticalSeparator />
          <IconButton
            size="l"
            variant="secondary"
            iconName={dashboardExpanded ? 'collapse' : 'expand'}
            onClick={handleToggleExpand}
          />
          <FavoriteCardIcon
            onClick={() => toggleItemFavorite(fund.id)}
            selected={isItemFavorite(fund.id)}
            className="h-tw-8 w-tw-8 !rounded-lg border border-solid border-neutral-100 shadow-z-020"
          />
        </div>
      </div>
      <div className="flex">
        {[...FUND_DASHBOARD_PAGE_TABS_MAP].map(([tab, { to }]) => (
          <TabLink
            key={tab}
            tab={tab}
            to={generateUrl(to, {
              pathParams: {
                fundSlug: fundSlug!,
              },
            })}
          />
        ))}
        {isUserAllowedToManageReturn && fund.hasReturnData && (
          <Link
            className={cn(TAB_LINK_CLASSES, 'group items-center gap-tw-2')}
            to={generateUrl(ROUTES_ROOT.return.object.fullPath, {
              pathParams: {
                objectId: fund.capitalInvestmentObjectId ?? '',
              },
            })}
            state={{ from: pathname }}
          >
            {MODULE_LABELS.Return}
            <Icon className="text-neutral-450" iconName="externalLink" />
          </Link>
        )}
        {isUserAllowedToViewReport && (
          <ObjectFinancialTabs
            object={fund}
            visibleReportTableConfigs={
              isFetchingVisibleConfigs ? undefined : visibleReportTableConfigs
            }
          />
        )}
      </div>
      {fund.aasmState === 'draft' && (
        <Banner className="-mx-tw-6 -mt-tw-4 w-[calc(100%_+_48px)]">
          {getDraftObjectsWarningMessage('fund')}
        </Banner>
      )}
    </>
  );

  return (
    <ListNavLayout.DashboardHeader slot={slot}>
      <EntityContext.Provider value={{ entity: fund, type: 'Fund' }}>
        {keyInfoModalIsOpen && (
          <KeyInfoModal
            object={fund}
            onClose={closeKeyInfoModal}
            heavyUpdate={heavyUpdate}
            simpleUpdate={simpleUpdate}
          />
        )}

        <div className="grid h-full p-tw-4 4xl:p-tw-6">
          <Router basepath="/" component={RouterComponentWithoutWrapper}>
            <Posts
              showKeyInfo={dashboardExpanded}
              path={ROUTES_ROOT.funds.fund.fullPath}
              heavyUpdate={heavyUpdate}
              simpleUpdate={simpleUpdate}
              lastColumn={
                <div
                  className={cn(
                    'hidden flex-col self-start overflow-hidden rounded-2xl 4xl:flex',
                    dashboardExpanded && 'flex',
                  )}
                >
                  <FundAssetList assets={fund?.assets ?? []} />
                </div>
              }
            />
            <AssetDocuments
              path={ROUTES_ROOT.funds.fund.documents.fullPath}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              hideTreeNavigation={!dashboardExpanded}
            />
          </Router>
        </div>
      </EntityContext.Provider>
    </ListNavLayout.DashboardHeader>
  );
}

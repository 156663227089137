import React from 'react';
import { useSingleton } from '@tippyjs/react/headless';
import { Icon, IconButton, Tooltip } from 'stories';
import { ISubfolder } from '../../../types/Subfolder';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  hideActions: boolean;
  currentUserCanManage: boolean;
  subfolder: ISubfolder;
  openMoveFolderModal: (subfolder: ISubfolder) => void;
  openNewFolderModal: (subfolder: ISubfolder, subfolderId: number) => void;
  onDelete: (subfolder: ISubfolder) => void;
}

const SubfolderCard = ({
  subfolder,
  hideActions,
  currentUserCanManage,
  openMoveFolderModal,
  openNewFolderModal,
  onDelete,
}: Props) => {
  const getActions = (f) => [
    {
      iconName: 'transferFile',
      onClick: () => openMoveFolderModal(f),
      tooltip: 'Move',
      visible: true,
    },
    {
      iconName: 'edit',
      onClick: () => openNewFolderModal(f, f.parentId),
      tooltip: 'Edit',
      visible: true,
    },
    {
      iconName: 'trash',
      onClick: () => onDelete(f),
      tooltip: 'Remove',
      visible: f.sharedFilesCount === 0,
    },
  ];
  const [source, target] = useSingleton();
  return (
    <div className="rounded-[0.5rem] p-[0.5rem] folder-tile bg-light">
      <div className="flex items-center justify-between mb-[0.5rem]">
        <div className="folder-tile__action flex justify-center items-center bg-light-10">
          <Icon iconName="folder" />
        </div>

        {!subfolder.internal && !hideActions && currentUserCanManage && (
          <div className="flex gap-s">
            {getActions(subfolder).map((action) => (
              <React.Fragment key={action.tooltip}>
                {action.visible && (
                  <Tooltip placement="top" mainText={action.tooltip}>
                    <IconButton
                      iconName={`${action.iconName}`}
                      onClick={action.onClick}
                    />
                  </Tooltip>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <div className="sre-link folder-tile__text" title={subfolder.title}>
        {subfolder.title}
      </div>
    </div>
  );
};

export default SubfolderCard;

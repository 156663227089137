import React from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { DEFAULT_NAME_FIELD } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IFieldsProps } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { IReconcileSettingsChangeOrderReasonUpdate } from 'bundles/REconcile/actions/changeOrderReasons';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import { optionsFor } from 'lib/helpers';

function ChangeOrderReasonFields({
  formData,
  setFormData,
  categories,
}: IFieldsProps<IReconcileSettingsChangeOrderReasonUpdate> & {
  categories: string[];
}) {
  return (
    <FormComponent
      setFormData={setFormData}
      formData={formData}
      formItems={[
        {
          ...DEFAULT_NAME_FIELD,
          styles: {
            size: 'm',
            input: 'bg-white mb-tw-4',
          },
        },
        {
          type: FieldType.CREATABLE_SELECT,
          fieldName: 'category',
          title: 'Category',
          required: true,
          placeholder: 'Select Category',
          options: optionsFor(categories),
          menuPosition: 'fixed',
          styles: {
            wrapper: 'mb-tw-4',
          },
        },
        {
          type: FieldType.TUMBLER,
          title: 'COR Status',
          fieldName: 'active',
        },
      ]}
    />
  );
}

export default ChangeOrderReasonFields;

import React, { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from './PseudoLink.module.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const PseudoLink = React.forwardRef(
  ({ className, children, ...props }: React.PropsWithChildren<Props>, ref) => (
    <span className={cn(styles['pseudo-link'], className)} ref={ref} {...props}>
      {children}
    </span>
  ),
);

export default PseudoLink;

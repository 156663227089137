import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';

const selectCategoryNameBy = (category) =>
  ({
    external: 'External',
    internal: 'Internal',
    symmetre: 'sRE Admins',
  })[category] || 'Unknown';

const SelectRoleGroup = ({
  userRoles,
  userCategory,
  fields,
  setValue,
  setExpanded,
}) => (
  <div className="flex flex-col gap-tw-1">
    <div className="mb-075rem light-90">
      {selectCategoryNameBy(userCategory)}
    </div>

    {_.uniq(userRoles.map((role) => role.group))
      .map((roleGroup) => (
        <React.Fragment key={roleGroup}>
          {roleGroup && <div className="secondary-regular light-60 mt-[0.6rem] mb-[0.4rem]">{roleGroup}</div>}
          <RadioGroup
            options={mapItemsToListOption(userRoles.filter((userRole) => userRole.group === roleGroup), 'name')}
            value={fields.userRole?.id}
            onChange={(o) => {
              setValue('userRole', userRoles.find((role) => role.id === o.value));
              setExpanded(false);
            }}
            getItemProps={() => ({
              classes: {
                label: 'font-normal text-xs',
              },
            })}
          />
        </React.Fragment>
      ))}
  </div>
);

export default SelectRoleGroup;

SelectRoleGroup.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userCategory: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  setValue: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MutateTagParams } from 'bundles/UserManagement/api/settingsCoreTagsApi';
import { API_URL } from 'lib/http';
import { IUserTag } from 'types/IUserTag';

const TAG_OPTIONS_TAG = 'TagOptionsTag';

export const settingsCoreUsersApi = createApi({
  reducerPath: 'settingsCoreUsersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
  }),
  tagTypes: [TAG_OPTIONS_TAG],
  endpoints: (builder) => ({
    getTagOptions: builder.query<
      { items: Pick<IUserTag, 'id' | 'name'>[] },
      void
    >({
      providesTags: [TAG_OPTIONS_TAG],
      query: () => ({ url: '/settings/user_management/user_tags' }),
    }),

    createTagOption: builder.mutation<unknown, MutateTagParams>({
      invalidatesTags: [TAG_OPTIONS_TAG],
      query: (data) => ({
        method: 'POST',
        url: '/user_tags',
        body: data,
      }),
      transformResponse: (response: { errors: string[] | null } | []) => {
        if ('errors' in response) {
          toastr.error(`${(response.errors ?? []).toString()}\n`);
        } else {
          toastr.success('User Tag has been successfully created');
        }
        return response;
      },
    }),
  }),
});

export const { useGetTagOptionsQuery, useCreateTagOptionMutation } =
  settingsCoreUsersApi;

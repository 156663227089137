import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IReallocation, IChangeOrderEvent } from 'bundles/Construction/types';
import { TRootState } from '@/app/stores';
import {
  deleteReconcileReallocation,
  fetchReconcileReallocation,
  getReconcileCOforRE,
  updateReconcileReallocation,
  uploadReconcileReallocationSharedFiles,
} from '../actions/reallocation';

export interface IReallocationState {
  item: IReallocation | null;
  state: 'idle' | 'loading' | 'success';
  changeOrderEvent: IChangeOrderEvent | null;
}

const initialState: IReallocationState = {
  item: null,
  state: 'idle',
  changeOrderEvent: null,
};

const fetchReallocation = createAsyncThunk<
  IReallocation,
  {
    reallocationId: string;
  }
>('developmentReallocation/fetch', async (params) => {
  const reallocation = await fetchReconcileReallocation(params.reallocationId);

  return reallocation;
});

const uploadFilesReallocation = createAsyncThunk<
  IReallocation,
  {
    reallocationId: string;
    data: File[];
  }
>('developmentReallocation/files', async (params) => {
  const documents = await uploadReconcileReallocationSharedFiles(
    params.reallocationId,
    params.data,
  );
  return documents;
});

const updateReallocation = createAsyncThunk<
  IReallocation,
  {
    reallocationId: string;
    data: IReallocation;
  }
>('developmentReallocation/update', async (params) => {
  const documents = await updateReconcileReallocation(
    params.reallocationId,
    params.data,
  );
  return documents;
});

const getCOforRE = createAsyncThunk<
  {
    changeOrderEvents: IChangeOrderEvent[];
  },
  {
    legalEntityCode: string;
    changeOrderId: number;
  }
>('developmentReallocation/getCO', async (params) => {
  const data = await getReconcileCOforRE(
    params.legalEntityCode,
    params.changeOrderId,
  );
  return data;
});

const deleteReallocation = createAsyncThunk<
  {
    reallocation: IReallocation[];
  },
  {
    reallocationId: number;
  }
>('developmentReallocation/delete', async (params) => {
  const data = await deleteReconcileReallocation(params.reallocationId);
  return data;
});

const reconcileReallocationSlice = createSlice({
  name: 'reallocation',
  initialState,
  reducers: {
    setReallocation: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReallocation.pending, (state) => {
        state.state = 'loading';
      })
      .addCase(fetchReallocation.fulfilled, (state, action) => {
        const reallocation = action.payload;
        state.item = reallocation;
        state.state = 'success';
      });

    builder.addCase(uploadFilesReallocation.fulfilled, (state, action) => {
      const sharedFiles = action.payload;
      state.item = {
        ...state.item,
        sharedFiles: [...state.item.sharedFiles, ...sharedFiles],
      };
    });

    builder
      .addCase(updateReallocation.pending, (state) => {
        state.state = 'loading';
      })
      .addCase(updateReallocation.fulfilled, (state, action) => {
        const reallocation = action.payload;
        state.item = reallocation;
        state.state = 'success';
      });

    builder
      .addCase(getCOforRE.pending, (state) => {
        state.state = 'loading';
      })
      .addCase(getCOforRE.fulfilled, (state, action) => {
        const data = action.payload;
        const [COE] = data.changeOrderEvents;
        state.changeOrderEvent = COE;
        state.state = 'success';
      });

    builder
      .addCase(deleteReallocation.pending, (state) => {
        state.state = 'loading';
      })
      .addCase(deleteReallocation.fulfilled, (state, action) => {
        if (action.payload.errors) {
          toastr.error(action.payload.errors.join('; '));
        }
      });
  },
});

export {
  fetchReallocation,
  uploadFilesReallocation,
  updateReallocation,
  getCOforRE,
  deleteReallocation,
};

export const { setReallocation } = reconcileReallocationSlice.actions;

export const selectReallocation = (state: TRootState) =>
  state.reallocation.item;

export default reconcileReallocationSlice.reducer;

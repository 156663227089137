import { IGeneralLedgerTransactionSource } from '@/bundles/REturn/types';
import { API_URL } from '@/lib/http';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { stringify } from 'qs';

type GetCapitalGeneralLedgersResponse = {
  items: IGeneralLedgerTransactionSource[];
};

export const capitalGeneralLedgersApi = createApi({
  reducerPath: 'capitalGeneralLedgersApi',
  tagTypes: ['GeneralLedgers'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
    paramsSerializer: (params) =>
      stringify(params, {
        encode: false,
        arrayFormat: 'brackets',
      }),
  }),
  endpoints: (builder) => ({
    getCapitalGeneralLedgers: builder.query<
      GetCapitalGeneralLedgersResponse,
      {
        legal_entity_ids: string[];
        kind: 'contribution' | 'distribution';
      }
    >({
      providesTags: ['GeneralLedgers'],
      query: (params) => ({
        url: '/capital/general_ledgers',
        params,
      }),
      transformResponse(
        baseQueryReturnValue: GetCapitalGeneralLedgersResponse,
        _meta,
        arg,
      ) {
        if ('items' in baseQueryReturnValue) {
          const res = { ...baseQueryReturnValue };
          res.items = res.items.map((i) => ({ ...i, kind: arg.kind }));
          return res;
        }
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const { useGetCapitalGeneralLedgersQuery } = capitalGeneralLedgersApi;

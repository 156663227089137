import {
  ContingencyImpactsMoved,
  ICreateReallocationFormData,
} from 'bundles/Construction/components/Reallocation/Modals/types';
import React, { SetStateAction } from 'react';
import { IHardCostCode } from 'bundles/Construction/types';
import { IJobCostCode } from 'types/JobCostCode';
import { DevelopmentCategory } from '@/shared/types/reconcile/Invoice';
import {
  FieldType,
  FieldTypeView,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import {
  getDocumentsFormField,
  getSetterForSharedFilesLoad,
} from 'bundles/Construction/components/Reallocation/Modals/utils';
import { jCCToOption } from 'bundles/Shared/helpers/job_cost_code';
import { transformCategoriesForTree } from 'bundles/DrawPackage/InvoicesFlow/Invoices/utils';
import { transformIdToJCC } from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { formatAmount } from '@/shared/lib/formatting/number';
import ReallocationWarning from 'bundles/Construction/components/Reallocation/Modals/components/ReallocationWarning';
import SubReallocation from 'bundles/Construction/components/Reallocation/Modals/components/SubReallocations';
import SubReallocationView from 'bundles/Construction/components/Reallocation/Modals/components/SubReallocationsView';
import { subReallocationsFulfilled } from 'bundles/Construction/components/Reallocation/Modals/initData';

export const getItemsReallocationManualModal = (options: {
  formData: ICreateReallocationFormData;
  setFormData: React.Dispatch<SetStateAction<ICreateReallocationFormData>>;
  softCostContingency: IHardCostCode;
  hardConstructionCost: IHardCostCode;
  hardCostContingency: IHardCostCode;
  JCCForReallocations: IJobCostCode[];
  JCCTree: DevelopmentCategory[];
}): IGroupFormItem[] => {
  const {
    formData,
    setFormData,
    softCostContingency,
    hardConstructionCost,
    hardCostContingency,
    JCCForReallocations,
    JCCTree,
  } = options;
  const movedFromImpact =
    formData?.contingencyImpact === ContingencyImpactsMoved.FROM;
  const movedToImpact =
    formData?.contingencyImpact === ContingencyImpactsMoved.TO;

  const qaItems = {
    yes: {
      active:
        formData?.sharedFiles?.length ||
        formData?.sharedFiles?.name ||
        formData?.sharedFilesLoad === 'YES',
      handle: () => getSetterForSharedFilesLoad(setFormData)('YES'),
      disabled: !formData?.contingencyImpact,
    },
    no: {
      active: formData?.sharedFilesLoad === 'NO',
      handle: () => getSetterForSharedFilesLoad(setFormData)('NO'),
      disabled: !formData?.contingencyImpact,
    },
  };

  const availableSettings = (currentField: ContingencyImpactsMoved) => {
    const contingencyOptions = [
      {
        label: 'Recommended',
        options: [jCCToOption(softCostContingency)],
      },
      {
        label: 'Not Recommended',
        options: [jCCToOption(hardCostContingency)],
      },
    ];
    const isCurrentFieldMovedImpact =
      currentField === ContingencyImpactsMoved.FROM;
    const allOptions = transformCategoriesForTree({
      categories: JCCTree,
    });
    const formField = isCurrentFieldMovedImpact
      ? 'moved_from_id'
      : 'moved_to_id';
    const optionsForJobCostCode = contingencyOptions.filter(
      (c) => c.options[0]?.value !== formData[formField],
    );

    if (movedFromImpact) {
      return {
        options: isCurrentFieldMovedImpact ? optionsForJobCostCode : allOptions,
        type: isCurrentFieldMovedImpact
          ? FieldType.SELECT
          : FieldType.TREE_SELECT,
      };
    }
    if (movedToImpact) {
      return {
        options: isCurrentFieldMovedImpact ? allOptions : optionsForJobCostCode,
        type: !isCurrentFieldMovedImpact
          ? FieldType.SELECT
          : FieldType.TREE_SELECT,
      };
    }
    return {
      options: allOptions,
      type: FieldType.TREE_SELECT,
    };
  };

  const impactFunc = (value: ContingencyImpactsMoved) => {
    setFormData({
      ...formData,
      contingencyImpact: value,
    });
  };

  const groupFormItems: IGroupFormItem[] = [
    {
      title: 'Main Details',
      titleView: 'Main Details',
      required: true,
      activated: formData.description && formData?.contingencyImpact,
      open: true,
      formItems: [
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Detailed Description',
          titleView: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
        {
          fieldName: 'contingencyImpact',
          fieldNameSnap: 'contingency_impact',
          type: FieldType.SELECTED_BUTTONS,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Contingency impact',
          titleView: 'Contingency impact',
          buttons: [
            {
              title: 'Moved From',
              active:
                formData.contingencyImpact === ContingencyImpactsMoved.FROM,
              value: 'Moved from',
            },
            {
              title: 'Moved To',
              active: formData.contingencyImpact === ContingencyImpactsMoved.TO,
              value: 'Moved to',
            },
            {
              title: 'No Impact',
              active: formData.contingencyImpact === ContingencyImpactsMoved.NO,
              value: 'No impact',
            },
          ],
          required: true,
          optionalView: true,
          handleFunc: impactFunc,
          styles: {
            nomb: true,
            wrapper: 'w-full',
            button: 'flex flex-col items-center py-m',
          },
        },
      ],
    },
    {
      title: 'REallocation Details',
      required: true,
      activated: subReallocationsFulfilled(formData.subReallocations),
      open: false,
      formItems: [],
    },
    {
      ...getDocumentsFormField(formData),
      qaItems,
    },
  ];

  if (formData.contingencyImpact) {
    const [, details] = groupFormItems;
    details.formItems.push(
      {
        ...availableSettings('Moved from'),
        fieldName: 'moved_from_id',
        fieldNameSnap: 'moved_from_id',
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Moved From',
        titleView: 'Moved From',
        placeholder: 'Select',
        required: true,
        optionalView: true,
        transformerView: (id) => transformIdToJCC(id, JCCForReallocations),
        isGroupSelect: true,
        styles: {
          wrapper: 'w-50',
          inputBackLayer: 'bg-white',
          size: 'm',
        },
      },
      {
        ...availableSettings('Moved to'),
        fieldName: 'moved_to_id',
        fieldNameSnap: 'moved_to_id',
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Moved To',
        titleView: 'Moved To',
        placeholder: 'Select',
        required: true,
        optionalView: true,
        transformerView: (id) => transformIdToJCC(id, JCCForReallocations),
        isGroupSelect: true,
        styles: {
          wrapper: 'w-50',
          inputBackLayer: 'bg-white',
          size: 'm',
        },
      },
      ...(hardCostContingency.id === formData.moved_from_id ||
      hardCostContingency.id === formData.moved_to_id
        ? [
            {
              fieldName: 'warning',
              fieldNameSnap: 'value',
              type: FieldType.CUSTOM,
              typeView: FieldTypeView.VIEW_CUSTOM,
              title: 'Final Amount',
              titleView: 'Final Amount',
              transformerView: formatAmount,
              leftIcon: 'paid',
              placeholder: '0',
              optionalView: false,
              styles: {
                wrapper: 'w-full form-item_white',
                size: 'm',
              },
              content: <ReallocationWarning />,
            },
          ]
        : []),
      {
        fieldName: 'value',
        fieldNameSnap: 'value',
        type: FieldType.INPUT_NUMBER,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Final Amount',
        titleView: 'Final Amount',
        transformerView: formatAmount,
        leftIcon: 'paid',
        placeholder: '0',
        required: true,
        optionalView: true,
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        thousandSeparator: true,
      },
      ...(movedFromImpact
        ? [
            {
              fieldName: 'change_order_id',
              fieldNameSnap: 'change_order_id',
              type: FieldType.CUSTOM,
              typeView: FieldTypeView.VIEW_CUSTOM,
              content: (
                <SubReallocation
                  formData={formData}
                  setFormData={setFormData}
                  hardConstructionCost={hardConstructionCost}
                  hardCostContingency={hardCostContingency}
                  JCCForReallocations={JCCForReallocations}
                  JCCTree={JCCTree}
                />
              ),
              contentViewIndepented: true,
              contentView: (
                <SubReallocationView
                  formData={formData}
                  hardConstructionCost={hardConstructionCost}
                  hardCostContingency={hardCostContingency}
                  JCCForReallocations={JCCForReallocations}
                  JCCTree={JCCTree}
                />
              ),
              optional: false,
              optionalView: true,
              styles: {
                wrapper: 'w-full form-item_nomb',
                size: 'm',
              },
            },
          ]
        : []),
    );
  }

  return groupFormItems;
};

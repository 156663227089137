import * as React from 'react';
import { HTMLAttributes, useState } from 'react';
import dayjs from 'dayjs';
import { useCalendar } from '../useCalendar';
import { CalendarDays, CalendarDaysProps } from '../Days/CalendarDays';
import { CalendarHeader } from '../Header/CalendarHeader';
import styles from './CalendarRange.module.css';

import { navigateDate } from 'stories/Calendar/lib';
import { sortBy } from 'lodash-es';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'stories/Calendar/config';
import { isSameDay } from '@/shared/lib/date';
export type DateRangeType = [Date | null, Date | null];

export interface CalendarRangeProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'defaultValue'>,
    Pick<CalendarDaysProps, 'listenDayChangesForUpdate'> {
  /** The selected date range. */
  value?: DateRangeType;
  /** If true, the dates in the past are disabled. */
  disablePast?: boolean;
  /** If true, the dates in the future are disabled. */
  disableFuture?: boolean;
  /** If true, the date pickers are disabled. */
  disablePickers?: boolean;
  /** The day that the week starts on. */
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  /** The latest date that can be selected. */
  maxDate?: Date;
  /** The earliest date that can be selected. */
  minDate?: Date;
  /** If true, the onChange event will be raised when the anchor date is changed. */
  raiseOnChangeOnAnchorChanged?: boolean;
  /** Callback fired when the date is changed. */
  onChange?: (
    value: DateRangeType,
    { anchorDate }?: { anchorDate?: Date | null },
  ) => void;
  /** Function to determine if a date should be disabled. */
  shouldDisableDate?: (value: Date) => boolean;
}
export type CalendarRangeRef = {
  onClose(): void;
};

const getIsDaySelected = (day: Date, value?: DateRangeType) => {
  if (!value?.[0] || !value[1]) {
    return false;
  }

  return Boolean(
    dayjs(day).isBetween(
      dayjs(value[0]).startOf('day').toDate(),
      dayjs(value[1]).endOf('day').toDate(),
      'date',
      '[]',
    ),
  );
};

export const CalendarRange = React.forwardRef<
  CalendarRangeRef,
  CalendarRangeProps
>(
  (
    {
      value,
      onChange,
      disablePast,
      disableFuture,
      shouldDisableDate,
      weekStartsOn = 1,
      disablePickers,
      listenDayChangesForUpdate,
      maxDate = DEFAULT_MAX_DATE,
      minDate = DEFAULT_MIN_DATE,
      raiseOnChangeOnAnchorChanged,
      ...props
    },
    ref,
  ): React.ReactNode => {
    const {
      viewDate,
      setViewDate,
      setPrevMonth,
      setNextMonth,
      focusedDay,
      setFocusedDay,
      isDayFocused,
      isDayDisabled,
      resetSelectedDay,
    } = useCalendar({ value, disableFuture, disablePast, shouldDisableDate });
    const [hintedDate, setHintedDate] = React.useState<DateRangeType>();
    const [anchorDate, setAnchorDate] = useState<Date | null>(null);
    const secondViewDate = dayjs(viewDate).add(1, 'month').toDate();

    React.useEffect(() => {
      setViewDate(value?.[0] ?? new Date());
    }, [value]);

    const onClose = React.useCallback(() => {
      setAnchorDate(null);
      setHintedDate(undefined);
    }, []);

    React.useImperativeHandle(
      ref,
      () => ({
        onClose,
      }),
      [onClose],
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent) => {
        if (
          ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(
            event.key,
          )
        ) {
          event.preventDefault();
        }

        if (event.key === 'Escape') {
          setAnchorDate(null);
        }

        const newFocusedDay = navigateDate(focusedDay ?? value?.[1], event.key);

        if (
          newFocusedDay &&
          !dayjs(newFocusedDay).isSame(viewDate, 'month') &&
          !dayjs(newFocusedDay).isSame(dayjs(viewDate).add(1, 'month'), 'month')
        ) {
          setViewDate(newFocusedDay);
        }
        setFocusedDay(newFocusedDay);
      },
      [focusedDay, setFocusedDay, setViewDate, value, viewDate],
    );

    const onSecondDateChange = React.useCallback(
      (date: Date) => setViewDate(dayjs(date).subtract(1, 'month').toDate()),
      [anchorDate],
    );

    const onDayChange = React.useCallback(
      (date: Date) => {
        if (anchorDate == null) {
          setAnchorDate(date);
          if (raiseOnChangeOnAnchorChanged) {
            onChange?.(sortBy([date, null]) as DateRangeType, {
              anchorDate: date,
            });
          }
          return;
        }
        onChange?.(sortBy([anchorDate, date]) as DateRangeType);
        setAnchorDate(null);
        setHintedDate(undefined);
      },
      [onChange, anchorDate],
    );

    const isDaySelected = React.useCallback(
      (day: Date) => {
        // if new range is selected don't highlight the old one
        if (anchorDate) {
          return false;
        }
        return getIsDaySelected(day, value);
      },
      [value, anchorDate],
    );

    const isDayActive = React.useCallback(
      (day: Date) => {
        const [start, end] = value ?? [];
        const isStartRangeSame = value?.[0] && isSameDay(day, start);
        const isEndRangeSame = value?.[1] && isSameDay(day, end);
        // if new range is selected don't highlight the old one
        if (anchorDate) {
          return anchorDate && isSameDay(day, anchorDate);
        }
        return Boolean(isEndRangeSame || isStartRangeSame);
      },
      [value, anchorDate],
    );

    const isDaySelectionEnd = React.useCallback(
      (day: Date) => Boolean(value?.[1] && isSameDay(day, value[1])),
      [value],
    );

    const isHintedDaySelectionEnd = React.useCallback(
      (day: Date) => Boolean(hintedDate?.[1] && isSameDay(day, hintedDate[1])),
      [hintedDate],
    );

    const isDaySelectionStart = React.useCallback(
      (day: Date) => Boolean(value?.[0] && isSameDay(day, value[0])),
      [value],
    );

    const isHintedDaySelectionStart = React.useCallback(
      (day: Date) => Boolean(hintedDate?.[0] && isSameDay(day, hintedDate[0])),
      [hintedDate],
    );

    const onDayEnter = React.useCallback(
      (date: Date) => {
        if (isDayDisabled(date) || anchorDate == null) {
          return;
        }
        setHintedDate(sortBy([anchorDate, date]) as DateRangeType);
      },
      [setHintedDate, anchorDate, isDayDisabled],
    );

    const onDayLeave = React.useCallback(
      () => setHintedDate(undefined),
      [setHintedDate],
    );

    const isDayHinted = React.useCallback(
      (day: Date) => getIsDaySelected(day, hintedDate),
      [hintedDate],
    );

    return (
      <div {...props} className={styles.CalendarRange}>
        <div className={styles.CalendarRange__inner}>
          <CalendarHeader
            viewDate={viewDate}
            onChange={setViewDate}
            onPrevMonth={setPrevMonth}
            onNextMonth={setNextMonth}
            maxDate={maxDate}
            minDate={minDate}
            disablePickers={disablePickers}
            className={styles.CalendarRange__header}
          />
          <CalendarDays
            viewDate={viewDate}
            value={value}
            weekStartsOn={weekStartsOn}
            onKeyDown={handleKeyDown}
            isDayFocused={isDayFocused}
            onDayChange={onDayChange}
            isDaySelected={isDaySelected}
            isDayActive={isDayActive}
            isDaySelectionEnd={isDaySelectionEnd}
            isDaySelectionStart={isDaySelectionStart}
            isDayHinted={isDayHinted}
            onDayEnter={onDayEnter}
            onDayLeave={onDayLeave}
            isHintedDaySelectionEnd={isHintedDaySelectionEnd}
            isHintedDaySelectionStart={isHintedDaySelectionStart}
            isDayDisabled={isDayDisabled}
            listenDayChangesForUpdate={listenDayChangesForUpdate}
          />
        </div>
        <div className={styles.CalendarRange__inner}>
          <CalendarHeader
            viewDate={secondViewDate}
            onChange={onSecondDateChange}
            prevMonthHidden
            nextMonthHidden
            disablePickers={disablePickers}
            maxDate={maxDate}
            minDate={minDate}
            className={styles.CalendarRange__header}
            disableFuture
          />
          <CalendarDays
            viewDate={secondViewDate}
            value={value}
            weekStartsOn={weekStartsOn}
            onKeyDown={handleKeyDown}
            isDayFocused={isDayFocused}
            onDayChange={onDayChange}
            isDaySelected={isDaySelected}
            isDayActive={isDayActive}
            isDaySelectionEnd={isDaySelectionEnd}
            isDaySelectionStart={isDaySelectionStart}
            isDayHinted={isDayHinted}
            onDayEnter={onDayEnter}
            onDayLeave={onDayLeave}
            isHintedDaySelectionEnd={isHintedDaySelectionEnd}
            isHintedDaySelectionStart={isHintedDaySelectionStart}
            isDayDisabled={isDayDisabled}
            listenDayChangesForUpdate={listenDayChangesForUpdate}
            tabIndex={0}
            onBlur={resetSelectedDay}
          />
        </div>
      </div>
    );
  },
);

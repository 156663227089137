import * as Sentry from '@sentry/react';
import http from 'lib/http';
import { IChangeOrderEvent } from 'bundles/Construction/types';
import { ISharedDocument } from 'types/SharedDocument';
import { IReallocation } from 'bundles/Construction/types';
import { stringify } from 'qs';

export async function fetchReconcileReallocation(reallocationId: string) {
  const res = await http.get(`/reconcile/reallocations/${reallocationId}`);
  const json = await res.json();

  if (res.status === 404) {
    Sentry.captureMessage(`Reallocation (${reallocationId}) not found`);
  }

  return json as IReallocation;
}

export async function uploadReconcileReallocationSharedFiles(
  id: string,
  data: File[],
) {
  const res = await http.post(`/reconcile/reallocations/${id}/upload`, data);
  const json = await res.json();

  return json as ISharedDocument[];
}

export async function updateReconcileReallocation(
  id: string,
  data: IReallocation,
) {
  const res = await http.put(`/reconcile/reallocations/${id}`, data);
  const json = await res.json();

  return json as IReallocation;
}

export async function getReconcileCOforRE(
  legalEntityCode: string,
  changeOrderId: number,
) {
  const query = stringify({ change_order_id: changeOrderId });
  const res = await http.get(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events?${query}`,
  );
  const json = await res.json();

  return json as {
    changeOrderEvents: IChangeOrderEvent[];
  };
}

export async function deleteReconcileReallocation(reallocationId: number) {
  const res = await http.del(`/reconcile/reallocations/${reallocationId}`);
  const json = await res.json();

  return json as IReallocation;
}

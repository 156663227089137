import { createSlice } from '@reduxjs/toolkit';

const coreEntitySlice = createSlice({
  name: 'coreEntity',
  initialState: {
    dashboardExpanded: false,
  },
  reducers: {
    toggleCoreEntityDashboardExpanded: (state) => {
      state.dashboardExpanded = !state.dashboardExpanded;
    },
  },
});

export default coreEntitySlice.reducer;
export const { toggleCoreEntityDashboardExpanded } = coreEntitySlice.actions;

import React from 'react';
import { ExternalSourceType } from '@/entities/core/legalEntity';
import {
  IUserFinancialCategoryRule,
  IUserRoleFinancialCategoryRule,
} from 'types/ReportTableConfig';

export interface IResponse {
  columns: IColumnGroup[];
  rows: TRow[];
}

export interface IColumnGroup {
  label: string;
  children: IColumn[];
  type?: 'regular' | 'totals';
}

type TColumnKey = string;

export interface IColumn {
  display: {
    colorRule?: 'negative_red' | 'negative_red_positive_green'; // Default is no coloring
    formatter?: 'metric' | 'percentage' | 'number'; // Default is number formatting
    precision?: number;
  };
  /**
   * @property Unique identifier of column
   */
  key: TColumnKey;
  label: string;
  /**
   * @property Array of dates in format '2022-03-01'
   */
  periods: string[];
  containsUnpublished?: boolean;
}

export interface IRowDataValue {
  value: string | null;
  active: boolean;
}

export type TRowData = Record<TColumnKey, IRowDataValue>;

export type TRow =
  | IHeaderRow
  | IDividerRow
  | ICategoryRow
  | IFormulaRow
  | IGlRow
  | IJccRow;

export interface IRowBase extends IRow {
  data: TRowData;
  children?: Array<IRowBase>;
  generalLedger?: {
    accNumber: string;
    accTitle: string;
    id: number;
    source?: ExternalSourceType;
  };
  label?: string;
}
export interface IRow {
  data: TRowData;
  display: {
    /**
     * @property Default is no background
     */
    background?: 'green' | 'blue' | 'grey' | 'dark-grey' | 'dark';
    /**
     * @property Default is number formatting. Prioritize the column one
     */
    formatter?: 'metric' | 'percentage' | 'number';
    /**
     * @default null
     */
    precision?: number;
  };
  key: string;
  type: 'header' | 'divider' | 'category' | 'formula' | 'gl' | 'jcc';
  path?: string[];
}

export function isRowHeader(row: IRow): row is IHeaderRow {
  return row.type === 'header';
}
export function isRowDivider(row: IRow): row is IDividerRow {
  return row.type === 'divider';
}
export function isRowFormula(row: IRow): row is IFormulaRow {
  return row.type === 'formula';
}
export function isRowCategory(row: IRow): row is ICategoryRow {
  return row.type === 'category';
}
export function isRowGl(row: IRow): row is IGlRow {
  return row.type === 'gl';
}
export function isRowJcc(row: IRow): row is IJccRow {
  return row.type === 'jcc';
}
export function isRowWithColoredBG(row: IRow): boolean {
  return row.display.background != null;
}

export interface IHeaderRow extends IRow {
  type: 'header';
  label: string;
}

interface IDividerRow extends IRow {
  type: 'divider';
}

interface IFormulaRow extends IRow {
  type: 'formula';
  label: string;
  data: TRowData;
}

export interface ICategoryRow extends IRow {
  type: 'category';
  categoryPath: string;
  label: string;
  children: Array<IFormulaRow>;
  data: TRowData;
}

export interface IGlRow extends IRow {
  type: 'gl';
  generalLedger: {
    accNumber: string;
    accTitle: string;
    id: number;
    legalEntity: {
      name: string;
    };
    source?: ExternalSourceType;
  };
  children: Array<IRow>;
  data: TRowData;
}

interface IJccRow extends IRow {
  type: 'jcc';
  jobCostCode: {
    name: string;
  };
  data: TRowData;
}

export type UserRuleSetter = React.Dispatch<
  React.SetStateAction<IUserFinancialCategoryRule[]>
>;
export type RoleRuleSetter = React.Dispatch<
  React.SetStateAction<IUserRoleFinancialCategoryRule[]>
>;

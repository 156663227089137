import React, { SetStateAction, useMemo } from 'react';
import { IconButton, MultilineAlert, SmallAddingButton } from 'stories';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { getFees } from './initData';
import { calcAllowedFeeValue, withinCushionedAmount } from './utils';
import {
  IChangeOrderMarkup,
  IFee,
} from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';

interface Props {
  changeOrderFees: IChangeOrderMarkup[];
  feeData: IFee[];
  setFeeData: SetStateAction<IFee>;
  invalidAmount?: number;
}

const FeeModal = ({
  changeOrderFees,
  invalidAmount,
  feeData,
  setFeeData,
}: Props) => {
  const formItems = useMemo(
    () =>
      getFees(
        changeOrderFees.map((v) => ({
          value: v.id,
          label: `${v.code} - ${v.name}`,
          code: v.code,
          name: v.name,
          percent: v.percent,
          includedInFee: v.includedInFee,
        })),
      ),
    [],
  );

  const setFormDataFunc = (newData, idx) => {
    const newGroupFormData = feeData.map((i, idGroup) => {
      if (idx === idGroup) return newData;
      return i;
    });
    if (feeData) {
      setFeeData(newGroupFormData);
    }
  };

  const removeItem = (idx) => {
    const newGroupFormData = feeData.filter((item, idItem) => idItem !== idx);
    setFeeData(newGroupFormData);
  };
  return (
    <div>
      {feeData.map((formItem, idx) => {
        const [value, textMessage] = calcAllowedFeeValue(
          changeOrderFees,
          formItem,
          invalidAmount,
        );

        const feeExceeded = !withinCushionedAmount(formItem.amount, value);

        return (
          <div key={`fee${idx}`}>
            {feeExceeded && formItem.changeOrderFee && (
              <MultilineAlert
                status="attention"
                icon="paid"
                message={
                  <div className="dark-60 inline-semibold">
                    Allowed Fees: {textMessage}
                  </div>
                }
                className="mb-m"
              />
            )}
            <FormComponent
              formData={formItem}
              setFormData={(newData) => setFormDataFunc(newData, idx)}
              formItems={formItems.map((item) => {
                if (item.fieldName === 'amount') {
                  return {
                    ...item,
                    error:
                      !formItem.comment && formItem.amount && feeExceeded
                        ? 'Allowable fee exceeded. You need to leave a comment'
                        : '',
                  };
                }
                return item;
              })}
              styles={{
                wrapper: 'group-form__step-item',
              }}
              unitNum={idx + 1}
              actions={
                <IconButton
                  iconName="closeSmall"
                  onClick={() => removeItem(idx)}
                />
              }
            />
          </div>
        );
      })}
      <SmallAddingButton
        onClick={() => {
          setFeeData([
            ...feeData,
            {
              changeOrderMarkup: '',
              amount: '',
              comment: '',
            },
          ]);
        }}
      >
        Add Contractor Fee
      </SmallAddingButton>
    </div>
  );
};

export default FeeModal;

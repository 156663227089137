/* eslint-disable max-len */
import {
  IConnection,
  TConnectionReportType,
} from 'bundles/Shared/entities/connection/model';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { CONNECTION_TYPE_OPTIONS } from 'bundles/Shared/entities/connection/config';

// This component refactoring to Checkboxes after BE changing reportType will be array
export const ConnectionReportTypeSelect = ({
  value,
  onChange,
}: {
  value: IConnection['reportType'];
  onChange: (value: TConnectionReportType) => void;
}) => (
  <RadioGroup
    options={CONNECTION_TYPE_OPTIONS}
    value={value}
    onChange={(o) => onChange(o.value as TConnectionReportType)}
  />
);

import React, { ComponentProps, ReactNode } from 'react';
import { Modal } from 'stories';
import { IEventDetails } from 'bundles/Construction/types';
import HistoryDetailsModalHeaderContent from '../../HistoryDetailsModalHeaderContent/HistoryDetailsModalHeaderContent';
import { MODAL_HEADERS } from '../utils/utils';

interface Props
  extends Omit<ComponentProps<typeof Modal>, 'children' | 'header' | 'toggle'> {
  eventDetails: IEventDetails;
  bodyContent: ReactNode;
  onClose: () => void;
  title?: string;
}

const defaultModalClasses = { body: 'bg-light' };

export const HistoryDetailsModal = ({
  eventDetails,
  bodyContent,
  onClose,
  classes = defaultModalClasses,
  size = '600',
  title,
}: Props) => (
  <Modal
    size={size}
    toggle={onClose}
    header={
      <HistoryDetailsModalHeaderContent
        title={title || MODAL_HEADERS[eventDetails.kind].title}
        user={eventDetails.createdBy}
        date={eventDetails.createdAt}
        iconName={MODAL_HEADERS[eventDetails.kind].iconName}
        iconColor={MODAL_HEADERS[eventDetails.kind].iconColor}
      />
    }
    classes={classes}
  >
    {bodyContent}
  </Modal>
);

export default HistoryDetailsModal;

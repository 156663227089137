import {
  CREATE_CHANGE_ORDER,
  CREATE_CHANGE_ORDER_EVENT,
  DELETE_CHANGE_ORDER_EVENT,
  FETCH_CHANGE_ORDER,
  FETCH_CHANGE_ORDER_BADGES,
  FETCH_CHANGE_ORDER_EVENT,
  FETCH_CHANGE_ORDER_EVENTS,
  FETCH_CHANGE_ORDERS,
  GET_CHANGE_EVENT_INCLUSION,
  REVISE_CHANGE_ORDER_EVENT,
  UPDATE_CHANGE_ORDER,
  UPDATE_CHANGE_ORDER_EVENT,
  UPLOAD_CHANGE_ORDER_EVENT_SHARED_FILES,
  UPLOAD_CHANGE_ORDER_SHARED_FILES,
} from '../actions';
import {
  IChangeOrder,
  IChangeOrderEvent,
  IChangeOrderEventStatisticsByStatus,
} from 'bundles/Construction/types';
import {
  APPROVE_CHANGE_ORDER,
  REVERT_CHANGE_ORDER,
} from 'bundles/Construction/actions/changeOrders';
import { TRootState } from '@/app/stores';

export const RESET_CHANGE_ORDER_MANAGEMENT_STATE =
  'RESET_CHANGE_ORDER_MANAGEMENT_STATE';

type State = {
  changeOrderEvents: IChangeOrderEvent[] | null;
  changeOrders: IChangeOrder[] | null;
  changeOrder: IChangeOrder | null;
  badgesInfo: {
    currentContractSum: number;
    timingImpact: number;
    stats: IChangeOrderEventStatisticsByStatus;
  } | null;
};

const initialState: State = {
  changeOrderEvents: null,
  changeOrders: null,
  changeOrder: null,
  badgesInfo: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case RESET_CHANGE_ORDER_MANAGEMENT_STATE: {
      return initialState;
    }
    case FETCH_CHANGE_ORDER_EVENTS:
      return {
        ...state,
        changeOrderEvents: payload.changeOrderEvents,
      };

    case FETCH_CHANGE_ORDER_BADGES:
      return {
        ...state,
        badgesInfo: payload,
      };

    case FETCH_CHANGE_ORDER_EVENT:
      return {
        ...state,
        changeOrderEvents: state.changeOrderEvents?.length
          ? state.changeOrderEvents.map(
              (ce) =>
                (ce.id === payload.id ? payload : ce) as IChangeOrderEvent,
            )
          : [payload],
      };

    case CREATE_CHANGE_ORDER_EVENT:
      return {
        ...state,
        changeOrderEvents: [
          {
            ...payload,
            value: payload.amount,
            weightedAmount: payload.weightedAmount,
            sharedFiles: payload.documents,
          },
          ...state.changeOrderEvents!,
        ],
      };

    case UPDATE_CHANGE_ORDER_EVENT:
    case REVISE_CHANGE_ORDER_EVENT: {
      const { id, responseData } = payload;
      const changeOrderEvents = state.changeOrderEvents!.map((co) =>
        co.id === id ? responseData : co,
      );

      return {
        ...state,
        changeOrderEvents,
      };
    }

    case DELETE_CHANGE_ORDER_EVENT: {
      const filteredOrderEvents = state.changeOrderEvents!.filter(
        (item) => payload.id !== item.id,
      );

      return {
        ...state,
        changeOrderEvents: filteredOrderEvents,
      };
    }

    case UPLOAD_CHANGE_ORDER_EVENT_SHARED_FILES:
      return {
        ...state,
        changeOrderEvents: state.changeOrderEvents!.map((co) =>
          co.id === payload.id
            ? {
                ...co,
                sharedFiles: co.sharedFiles.concat(payload.responseData),
              }
            : co,
        ),
      };

    case FETCH_CHANGE_ORDERS:
      return {
        ...state,
        changeOrders: payload,
      };

    case FETCH_CHANGE_ORDER:
      return {
        ...state,
        changeOrder: payload,
      };

    case CREATE_CHANGE_ORDER:
      return {
        ...state,
        changeOrders: [payload, ...state.changeOrders!],
      };

    case UPDATE_CHANGE_ORDER:
      return {
        ...state,
        changeOrder: payload.responseData,
      };

    case GET_CHANGE_EVENT_INCLUSION: {
      const { responseData } = payload;

      return {
        ...state,
        changeOrdersInclusion: responseData,
      };
    }

    case UPLOAD_CHANGE_ORDER_SHARED_FILES:
      return {
        ...state,
        changeOrder: {
          ...state.changeOrder,
          documents: state.changeOrder!.documents.concat(payload.responseData),
        },
      };
    case APPROVE_CHANGE_ORDER: {
      const { responseData } = payload;
      return {
        ...state,
        changeOrder: {
          ...state.changeOrder,
          ...responseData,
        },
      };
    }
    case REVERT_CHANGE_ORDER: {
      const { responseData } = payload;
      return {
        ...state,
        changeOrder: {
          ...state.changeOrder,
          ...responseData,
        },
      };
    }

    default:
      return state;
  }
}

export const selectApprovedChangeOrdersForReallocation = (state: TRootState) =>
  state.changeOrderManagement.changeOrders?.filter(
    (co) => !co.inReallocation && co.status === 'APPROVED',
  );

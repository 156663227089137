import * as Type from '../editor/types';

type Fields = Type.InvitationTemplateState &
  Type.FeedMessageTemplateState &
  Type.ForgotPasswordTemplateState;

interface Params {
  isLoading: boolean;
  template: Type.InvitationTemplateState | null;
  initialFieldsToCheck: (keyof Fields)[];
}

export function canSaveTemplate({
  template,
  isLoading,
  initialFieldsToCheck,
}: Params) {
  if (isLoading || template == null) return false;

  const doesEveryFieldHaveContent = initialFieldsToCheck.every((field) => {
    const value = template[field];
    return value != null && value.length > 0;
  });

  return doesEveryFieldHaveContent;
}

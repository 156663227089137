import { IDocumentType } from 'types/DocumentType';
import { IInvestmentObject } from 'types/IInvestmentObject';
import { IUserTag } from 'types/IUserTag';
import { IUserRole } from 'types/User';

export const prepareSelectedFilters = (filters: {
  investmentObjects?: IInvestmentObject[];
  documentTypes?: IDocumentType[];
  tags?: IUserTag[];
  roles?: IUserRole[];
}) => ({
  assets: {
    title: 'Assets',
    items:
      filters?.investmentObjects?.filter((item) => item.klass === 'Asset') ||
      [],
  },
  funds: {
    title: 'Funds',
    items:
      filters?.investmentObjects?.filter((item) => item.klass === 'Fund') || [],
  },
  documentTypes: {
    title: 'Document types',
    items: filters.documentTypes || [],
  },
  tags: {
    title: 'Tags',
    items: filters?.tags?.map((tag) => ({ id: tag.id, name: tag.label })) || [],
  },
  roles: {
    title: 'Roles',
    items: filters?.roles || [],
  },
});

export const resetFilter = (
  setSelectedFilters: (filters: {
    investmentObjects?: IInvestmentObject[];
    documentTypes?: IDocumentType[];
  }) => void,
  selectedFilters: {
    investmentObjects?: IInvestmentObject[];
    documentTypes?: IDocumentType[];
  },
  key: string,
  id: number,
) => {
  if (key === 'assets') {
    setSelectedFilters({
      investmentObjects: selectedFilters?.investmentObjects?.filter((item) =>
        id
          ? !(item.klass === 'Asset' && id === item.id)
          : item.klass !== 'Asset',
      ),
    });
  } else if (key === 'funds') {
    setSelectedFilters({
      investmentObjects: selectedFilters?.investmentObjects?.filter((item) =>
        id ? !(item.klass === 'Fund' && id === item.id) : item.klass !== 'Fund',
      ),
    });
  } else {
    setSelectedFilters({
      [key]: id ? selectedFilters[key].filter((item) => item.id !== id) : [],
    });
  }
};

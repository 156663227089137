import * as React from 'react';
import { useMemo } from 'react';

interface Props {
  file: File;
  width?: number | string;
  height?: number | string;
  className?: string;
}

function FileMediaPreview({ file, width, height, className }: Props) {
  const media = useMemo(() => URL.createObjectURL(file), [file]);

  return file.type.startsWith('video') ? (
    <video
      controls
      className={className}
      draggable={false}
      width={width}
      height={height}
      src={media}
    />
  ) : (
    <img
      alt={file.name}
      className={className}
      draggable={false}
      src={media}
      width={width}
      height={height}
    />
  );
}

export default FileMediaPreview;

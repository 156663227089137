import React, { useState, useRef } from 'react';
import {
  isVarianceCategoryRow,
  isVarianceJccRow,
  IVarianceJccRow,
  IVarianceSovcRow,
} from 'bundles/REconcile/types/VarianceRows';
import { ICellRendererParams } from 'ag-grid-community';
import CommentFlyFormPopover from 'bundles/REconcile/components/comments/Popover/CommentFlyFormPopover';
import CommentEditor, {
  ICommentSubmitBody,
} from 'bundles/REconcile/components/comments/CommentEditor/CommentEditor';
import { ThinTabGroup } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import {
  AddCommentBadge,
  AvatarCommentBadge,
} from 'bundles/REconcile/components/comments/CommentBadge/CommentBadge';
import { PopoverRef } from 'stories/Popover/Popover';
import { TMentionedUser } from 'bundles/REconcile/types/Comments';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import styles from './RequisitionCell.module.scss';
import { CurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { CornerArrow } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CornerArrow';
import { getNodeFlags } from 'bundles/Shared/components/AgGrid/Table/utils/getNodeFlags';
import { generateRequisitionDates } from 'bundles/DrawPackage/utils';
import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import { ILineItem } from 'bundles/REconcile/types/ILineItem';
import { RequisitionCellPanel } from 'bundles/REconcile/components/development/requisitions/shared/requisitionsTable/ui/RequisitionCellPanel/RequisitionCellPanel';

interface Props extends ICellRendererParams {
  onLinkClick: (row: IVarianceJccRow | IVarianceSovcRow) => void;
  onCommentSubmit: (comment: ICommentSubmitBody) => void;
  onCommentOpen: (commentId: string) => void;
  mentionableUsers: TMentionedUser[];
  requisition: IRequisitionIndexed;
}

export type IRequisitionIndexed = IRequisition & {
  index: number;
  lineItems: ILineItem[];
};

function RequisitionCell({
  onCommentOpen,
  onLinkClick,
  onCommentSubmit,
  mentionableUsers,
  requisition,
  ...params
}: Props) {
  const [showEditor, setShowEditor] = useState(false);
  const popoverRef = useRef<PopoverRef>();
  const { node, data: row, value } = params;

  const { isGrandTotal } = getNodeFlags(node);

  const requisitionTab = {
    id: 1,
    label: `Requisition (${generateRequisitionDates(
      requisition.beginningDate,
      requisition.endingDate,
    )})`,
  };
  if (row && !isVarianceCategoryRow(row)) {
    const { comments } = row as IVarianceJccRow | IVarianceSovcRow;
    const comment = comments.find((c) => c.requisitionId === requisition.id);

    const hasComment = comment !== undefined;
    const categoryId = isVarianceJccRow(row)
      ? row.jobCostCode.id
      : row.scheduleOfValueCode.id;
    const categoryType = row.type.toUpperCase();
    const hasFire = requisition.lineItems.some(
      (li) =>
        li.developmentCategory.id === categoryId &&
        li.developmentCategory.type === categoryType &&
        !li.hasInvoice,
    );

    return (
      <div
        className={cn(
          'group flex h-full items-center justify-between',
          styles.requisitionCell,
        )}
      >
        {showEditor && (
          <CommentFlyFormPopover
            theme="dark"
            appendTo={document.body}
            visible
            onClickOutside={() => setShowEditor(false)}
            ref={popoverRef}
            template={
              <>
                <ThinTabGroup
                  className="bg-transparent"
                  selectedItem={requisitionTab}
                  items={[requisitionTab]}
                />
                <CommentEditor
                  className="p-0"
                  variant="dark"
                  resetOnSubmit
                  onSubmit={onCommentSubmit}
                  mentionableUsers={mentionableUsers}
                  onCancel={() => popoverRef.current?.hide()}
                />
              </>
            }
          />
        )}

        <RequisitionCellPanel>
          {hasComment ? (
            <AvatarCommentBadge
              onClick={() => onCommentOpen(comment.commentId)}
            />
          ) : (
            <div className="opacity-0 group-hover:opacity-100">
              <AddCommentBadge onClick={() => setShowEditor(true)} />
            </div>
          )}
          {hasFire && !requisition.funded && <FireIcon priority="high" />}
        </RequisitionCellPanel>
        <CornerArrow>
          <div
            className="relative h-full w-full cursor-pointer"
            onClick={() => onLinkClick(row)}
          >
            <CurrencyCellRenderer value={value ?? 0} {...params} />
          </div>
        </CornerArrow>
      </div>
    );
  }

  return value != null ? (
    <CurrencyCellRenderer
      {...params}
      formatterParams={{
        classes: {
          allParts: isGrandTotal
            ? 'text-white font-semibold'
            : 'font-semibold text-neutral-500',
        },
      }}
      classes={{
        inner: isGrandTotal
          ? 'bg-green border-transparent'
          : 'border-transparent',
      }}
    />
  ) : null;
}

export default RequisitionCell;

import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import React from 'react';
import { IUserRole } from 'types/User';

interface Props
  extends Omit<
    ISharedSelectProps<
      {
        value: IUserRole['id'];
        label: IUserRole['name'];
      },
      false,
      any
    >,
    'value'
  > {
  value?: IUserRole['id'];
  disabled?: boolean;
}

export const UserRoleSelect = ({
  onChange,
  disabled,
  options,
  value,
}: Props) => (
  <SharedSelect
    placeholder="Select Role"
    options={options}
    onChange={onChange}
    value={options?.filter((opt) => opt.value === value)}
    disabled={disabled}
  />
);

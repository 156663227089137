import React, { useEffect, useState } from 'react';
import { ISharedSelectProps } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { useGetApiPropertyManagementCompaniesQuery } from 'bundles/Shared/entities/propertyManagementCompany/api/settingPropertyManagementCompanyApi';
import { SharedCreatableSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedCreatableSelect';
import { ActionMeta } from 'react-select';
import { IPropertyManagementCompany } from 'bundles/Shared/entities/propertyManagementCompany/model';

type OptionsItem = {
  value: IPropertyManagementCompany['id'] | IPropertyManagementCompany['name'];
  label: IPropertyManagementCompany['name'];
};

interface Props extends ISharedSelectProps {
  options?: OptionsItem[];
  value?: IPropertyManagementCompany['id'];
  disabled?: boolean;
  typeValue?: 'id' | 'name';
}

export const PropertyManagementCompanySelect = ({
  onChange,
  disabled,
  options,
  value,
  typeValue = 'id',
}: Props) => {
  const [optionsPMC, setOptionPMC] = useState(options ?? []);

  const { data: pmc } = useGetApiPropertyManagementCompaniesQuery(
    {},
    {
      skip: Boolean(options),
    },
  );

  useEffect(() => {
    if (pmc?.items) {
      setOptionPMC(
        pmc.items.map((company) => ({
          label: company.name,
          value: company[typeValue],
        })),
      );
    }
  }, [pmc, typeValue]);

  const handleChange = (
    newValue: OptionsItem,
    actionMeta: ActionMeta<OptionsItem>,
  ) => {
    const item = optionsPMC.find((opt) => opt.value === value);
    if (!item) {
      setOptionPMC([...optionsPMC, newValue]);
    }
    onChange(newValue, actionMeta);
  };

  return (
    <SharedCreatableSelect
      menuPortalTarget={document.body}
      placeholder="Select Company"
      options={optionsPMC}
      onChange={handleChange}
      value={optionsPMC.filter((opt) => opt.value === value)}
      disabled={disabled}
      newGen
    />
  );
};

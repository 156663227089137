import React, { useEffect, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Icon } from 'stories';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  approveChangeEvent,
  fetchChangeEventMobile,
  rejectChangeEvent,
  selectChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import styles from './ApproveReject.module.scss';
import Actions from './Actions';
import RejectForm from './RejectForm';
import { useParams } from '@reach/router';

const ApproveReject = () => {
  const { id, legalEntityCode } = useParams();
  const [reject, setReject] = useState(false);

  const changeOrderEvent = useAppSelector(selectChangeEvent);
  const dispatch = useAppDispatch();
  const { confirm } = useModal();

  useEffect(() => {
    dispatch(fetchChangeEventMobile({ id, legalEntityCode }));
  }, [id]);

  const onReject = (comment) => {
    dispatch(
      rejectChangeEvent({
        id,
        legalEntityCode,
        data: { event_comment: comment },
      }),
    );
  };

  const onApprove = async () => {
    const result = await confirm({
      title: 'Approve Change Event',
      subtitle: (
        <div>
          Are you sure you want to Approve Change Event?
          <div className="light-90 secondary-regular mt-s">
            <span className="red">*</span> This can't be undone
          </div>
        </div>
      ),
      actions: {
        primaryButton: {
          variant: 'success',
          text: 'Approve',
        },
        secondaryButton: {
          variant: 'secondary',
          text: 'Cancel',
        },
      },
    });
    if (result) {
      await dispatch(approveChangeEvent({ id, legalEntityCode }));
      dispatch(fetchChangeEventMobile({ id, legalEntityCode }));
    }
  };

  if (!changeOrderEvent) return <LoadingPanel />;

  return (
    <div className={cn(styles.container)}>
      <h3 className="header3-bold">Change Event</h3>
      <div
        className={cn(styles.tableContainer, {
          [styles.tableContainerRejected]:
            changeOrderEvent.status === 'REJECTED',
          [styles.tableContainerApproved]:
            changeOrderEvent.status === 'APPROVED',
        })}
      >
        <table className={cn(styles.table)}>
          <tr>
            <td className="light-60 inline-semibold">Asset</td>
            <td>
              <span className="dark-60 inline-semibold">
                {changeOrderEvent.assetName}
              </span>
            </td>
          </tr>
          <tr>
            <td className="light-60 inline-semibold">COR</td>
            <td>
              <div className={cn(styles.number, 'inline-regular')}>
                {changeOrderEvent.displayNumber}
              </div>
              <div className="dark-60 inline-semibold mt-s">
                {changeOrderEvent.title}
              </div>
              <div className="light-90 secondary-regular mt-s">
                {changeOrderEvent.description}
              </div>
            </td>
          </tr>
          <tr>
            <td className="light-60 inline-semibold">REASON</td>
            <td>
              <div className="light-60 secondary-regular mb-s">
                Threshold Type
              </div>
              <div className="dark-60 inline-semibold mb-m">
                {changeOrderEvent.thresholdCategoryMessage}
              </div>

              {changeOrderEvent.internalApproverName && (
                <>
                  <div className="light-60 secondary-regular mb-s">
                    Internal Approver
                  </div>
                  <div className="dark-60 inline-regular mb-m">
                    {changeOrderEvent.internalApproverName}
                  </div>
                </>
              )}

              {changeOrderEvent.externalApproverNames?.length > 0 && (
                <>
                  <div className="light-60 secondary-regular mb-s">
                    External Approver
                  </div>
                  <div className="dark-60 inline-regular mb-m">
                    {changeOrderEvent.externalApproverNames.join(', ')}
                  </div>
                </>
              )}
            </td>
          </tr>
        </table>
      </div>
      {changeOrderEvent.status !== 'REJECTED' &&
        changeOrderEvent.status !== 'APPROVED' && (
          <div className={cn(styles.actionsContainer)}>
            {reject ? (
              <div className="w-full">
                <RejectForm
                  onCancel={() => setReject(false)}
                  onReject={onReject}
                />
              </div>
            ) : (
              <Actions onReject={() => setReject(true)} onApprove={onApprove} />
            )}
          </div>
        )}
      {changeOrderEvent.status === 'REJECTED' && (
        <div className={cn('inline-semibold', styles.rejected)}>
          <Icon iconName="nodeOpen" />
          <span className="ml-s">REJECTED</span>
        </div>
      )}
      {changeOrderEvent.status === 'APPROVED' && (
        <div className={cn('inline-semibold', styles.approved)}>
          <Icon iconName="acceptAlt" />
          <span className="ml-s">APPROVED</span>
        </div>
      )}
    </div>
  );
};

export default ApproveReject;

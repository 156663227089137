import React, { ComponentProps, useState } from 'react';
import pluralize from 'pluralize';
import { cn } from '@/shared/lib/css/cn';
import {
  Button,
  Modal,
  ModalHeaderWithIcon,
  MultipleItemsIcons,
  RadioButton,
  TreeFolderNavigation,
} from 'stories';
import { TreeItem } from 'react-sortable-tree';
import { ISharedFolder } from 'types/SharedFolder';
import { FAKE_ROOT_NODE_ID } from 'stories/TreeFolderNavigation/TreeFolderNavigation';
import ChooseFolderTooltip from '../../Assets/components/SharedFiles/ChooseFolderTooltip';
import DocumentsPopover from './DocumentsPopover/DocumentsPopover';

interface Props {
  close: () => void;
  onMove: (folder: ISharedFolder) => void;
  foldersTreeData: TreeItem<ISharedFolder>[];
  disabledFolderIds: string[];
  itemType: ComponentProps<typeof MultipleItemsIcons>['itemType'];
  entityName: string;
  items: File[];
}

const MoveItemsModal = ({
  close,
  items,
  onMove,
  foldersTreeData,
  disabledFolderIds,
  itemType,
  entityName,
}: Props) => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const resolveTitle = (files: File[]) => {
    if (files.length === 1) return files[0]?.name || files[0]?.title;
    return (
      <DocumentsPopover files={files}>
        <div className="dashed-border-bottom cursor-pointer">
          {`${files.length} ${itemType}`}
        </div>
      </DocumentsPopover>
    );
  };

  const moveFileHeader = (
    <ModalHeaderWithIcon
      icons={<MultipleItemsIcons itemType={itemType} items={items} />}
      title={resolveTitle(items)}
      subtitle="Moving to..."
    />
  );

  return (
    <Modal
      header={moveFileHeader}
      toggle={close}
      actions={
        <div className="flex flex-col w-full">
          {!selectedFolder && (
            <span className="light-60 mb-[0.5rem] secondary-regular text-center">
              Please select destination
            </span>
          )}
          <Button
            variant="success"
            onClick={() => onMove(selectedFolder)}
            disabled={selectedFolder === null}
            type="submit"
            fluid
          >
            Move {items.length} {pluralize(itemType, items.length)}
          </Button>
        </div>
      }
    >
      <div id={itemType}>
        <div className="flex justify-between items-center mb-[0.375rem]">
          <div className="dark-60 inline-semibold">
            Where do you want to move the {pluralize(itemType, items.length)}?
          </div>
          <div>
            <ChooseFolderTooltip />
          </div>
        </div>
        <div>
          <TreeFolderNavigation
            selected={selectedFolder}
            treeData={foldersTreeData}
            onSelectedChange={(selected) => setSelectedFolder(selected)}
            additionalNodeProps={(node) => ({
              isDisabled:
                disabledFolderIds.includes(node.id) ||
                (node.id === FAKE_ROOT_NODE_ID && !items[0].parentId),
            })}
            rootNode={
              entityName && {
                hasCheckbox: true,
                title: entityName,
              }
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default MoveItemsModal;

import React from 'react';
import { Icon } from 'stories';

interface Props {
  subject: string;
  content: string;
  linkedWithHref?: string;
  linkedWithTitle?: string;
  investmentObjectHref?: string;
  investmentObjectTitle?: string;
}

const CampaignEmailCardContent = ({
  subject,
  content,
  linkedWithHref,
  linkedWithTitle,
  investmentObjectHref,
  investmentObjectTitle,
}: Props) => (
  <div className="flex flex-col gap-s">
    {linkedWithHref && (
      <div className="flex" style={{ gap: '0.8rem' }}>
        <span className="inline-regular light-60 inline-regular">Linked with</span>
        <div className="flex items-center">
          <a
            href={linkedWithHref}
            target="_blank"
            className="sre-link flex items-center"
            rel="noreferrer"
          >
            <Icon iconName="externalLinkSquare" className="mr-xs" />
            {linkedWithTitle}
          </a>
          {investmentObjectHref && (
            <span className="inline-regular">
              &nbsp;in&nbsp;
              <a
                href={investmentObjectHref}
                target="_blank"
                rel="noreferrer"
                className="sre-link"
              >
                {investmentObjectTitle}
              </a>
            </span>
          )}
        </div>
      </div>
    )}
    <div className="flex" style={{ gap: '2.1rem' }}>
      <span className="inline-regular light-60 inline-regular">Subject:</span>
      <h6 className="font-weight-500 text-[1rem] dark-60 header6-regular">
        {subject}
      </h6>
    </div>
    {content && (
      <div className="flex" style={{ gap: '1.9rem' }}>
        <span className="inline-regular light-60 inline-regular">Content:</span>
        <p className="inline-regular dark-60 inline-regular">{content}</p>
      </div>
    )}
  </div>
);

export default CampaignEmailCardContent;

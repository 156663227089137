import React, { CSSProperties, useMemo, useState } from 'react';
import { Popover, SearchInput } from 'stories';
import pluralize from 'pluralize';
import { cn } from '@/shared/lib/css/cn';

import { ILegalEntitiableWithLegalEntities } from 'types/ReportTableSegment';

import { mapLEToListOption } from 'bundles/REport/components/financials/filter/utils';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { ListOption } from 'stories/Checkbox/CheckList';
import { getNewLegalEntitiables } from './utils';

interface Props {
  assets: ILegalEntitiableWithLegalEntities[];
  funds: ILegalEntitiableWithLegalEntities[];
  selectedAssets: ILegalEntitiableWithLegalEntities[];
  selectedFunds: ILegalEntitiableWithLegalEntities[];
  setSelectedAssets: React.Dispatch<
    React.SetStateAction<ILegalEntitiableWithLegalEntities[]>
  >;
  setSelectedFunds: React.Dispatch<
    React.SetStateAction<ILegalEntitiableWithLegalEntities[]>
  >;
  maxHeight?: CSSProperties['maxHeight'];
  searchInputDataTestId?: string;
}

const DEFAULT_MAX_HEIGHT = 350;

export function InvestmentObjectWithLegalEntitiesSelect({
  assets,
  funds,
  selectedAssets,
  selectedFunds,
  setSelectedAssets,
  setSelectedFunds,
  maxHeight = DEFAULT_MAX_HEIGHT,
  searchInputDataTestId,
}: Props) {
  const [filterText, setFilterText] = useState('');

  const { filteredAssets, filteredFunds } = useMemo(
    () => ({
      filteredAssets: assets.filter((a) =>
        a.name.toLowerCase().includes(filterText.toLowerCase()),
      ),
      filteredFunds: funds.filter((f) =>
        f.name.toLowerCase().includes(filterText.toLowerCase()),
      ),
    }),
    [assets, funds, filterText],
  );

  const onAssetsChange = (
    itemOptions: ListOption<ILegalEntitiableWithLegalEntities['id']>[],
  ) => {
    setSelectedAssets((prevItems) =>
      getNewLegalEntitiables({
        allItems: assets,
        itemOptions,
        prevItems,
      }),
    );
  };

  const onFundsChange = (
    itemOptions: ListOption<ILegalEntitiableWithLegalEntities['id']>[],
  ) => {
    setSelectedFunds((prevItems) =>
      getNewLegalEntitiables({
        allItems: funds,
        itemOptions,
        prevItems,
      }),
    );
  };

  const lists = (
    <div
      style={{
        maxHeight,
      }}
      className="grid grid-cols-[1fr_1fr] gap-tw-6 overflow-auto"
    >
      {[
        {
          type: 'Asset' as const,
          selectedItems: selectedAssets,
          filteredItems: filteredAssets,
          onChange: onAssetsChange,
        },
        {
          type: 'Fund' as const,
          selectedItems: selectedFunds,
          filteredItems: filteredFunds,
          onChange: onFundsChange,
        },
      ].map(({ filteredItems, selectedItems, type, onChange }, idx, arr) => (
        <div
          key={type}
          className={cn('w-[250px] overflow-auto border-light-20', {
            'border-r': idx !== arr.length - 1,
          })}
        >
          <p className="inline-semibold mb-s">{pluralize(type, 2)}</p>
          <SplittedCheckList
            value={selectedItems.map(mapLEToListOption)}
            onChange={(items) => onChange(items)}
            items={filteredItems.map(mapLEToListOption)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex-col flex">
      <Popover
        maxWidth="auto"
        placement="bottom-start"
        offset={[0, 2]}
        appendTo={() => document.body}
        hiddenArrow
        trigger="click"
        className="p-s pb-s pl-m pr-m"
        template={lists}
      >
        <SearchInput
          data-testid={searchInputDataTestId}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder="Assets & Funds"
          value={filterText}
          resetValue={() => setFilterText('')}
          size="s"
        />
      </Popover>
    </div>
  );
}

import { useParams } from '@reach/router';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';
import SourcesViewModal from 'bundles/Construction/components/Reallocation/Modals/SourcesViewModal';
import { getDestinationReallocationAmount } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/utils';
import { ICreateReallocationFormData } from 'bundles/Construction/components/Reallocation/Modals/types';
import { getReallocationTextColor } from 'bundles/Construction/components/Reallocation/Modals/utils';
import { IReconcileJCC } from 'bundles/Construction/types';
import { cn } from '@/shared/lib/css/cn';
import { useModal } from '@/shared/lib/hooks/useModal';
import { capitalize, isEmpty } from 'lodash-es';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Icon } from 'stories';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { IJobCostCode } from 'types/JobCostCode';
import { ClassNameProps } from 'types/Props';

export const ReallocationCurrencyFormatter = ({
  classes,
  value,
  ...props
}: Omit<React.ComponentProps<typeof CurrencyFormatter>, 'value'> & {
  value: Float;
}) => (
  <CurrencyFormatter
    negativeVariant={value !== 0 ? 'sign' : 'brackets'}
    value={value}
    classes={{
      allParts: cn(value !== 0 && getReallocationTextColor(value)),
      wrapper: 'inline-semibold',
      ...classes,
    }}
    {...props}
  />
);

export const FromToContainer = ({ children }: React.PropsWithChildren) => (
  <div className="flex items-center gap-tw-2">
    <div className="h-[2px] w-[14px] bg-light-20" />
    {children}
    <Icon className="text-light-20" iconName="arrowRightAlt" />
  </div>
);

export const JobCostCodeView = ({
  jobCostCode,
}: {
  jobCostCode: Pick<IReconcileJCC, 'code' | 'name'>;
}) => (
  <div className="flex flex-col gap-tw-1.5">
    <span className="secondary-regular text-dark-60">{jobCostCode.code}</span>
    <span className="secondary-regular text-light-60">{jobCostCode.name}</span>
  </div>
);

export const JobCostCodeFieldView = ({ id }: Pick<IJobCostCode, 'id'>) => {
  const params = useParams();
  const { data: JCCForReallocations } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode: params.legalEntityCode,
    without_sovcs: 'false',
  });
  const jobCostCode = JCCForReallocations?.find((jcc) => jcc.id === id);

  return jobCostCode ? <JobCostCodeView jobCostCode={jobCostCode} /> : null;
};

export const FromToValuesView = ({
  destination,
}: {
  destination: keyof Pick<
    ICreateReallocationFormData,
    'moved_from_id' | 'moved_to_id'
  >;
}) => {
  const params = useParams();
  const { openModal } = useModal();
  const { getValues } = useFormContext<ICreateReallocationFormData>();
  const { data: JCCForReallocations, isLoading } =
    useGetDevelopmentCategoryFlatQuery({
      legalEntityCode: params.legalEntityCode,
      without_sovcs: 'false',
    });

  const reallocationAmount = getValues('value')!;
  const destinationReallocationAmount = getDestinationReallocationAmount(
    destination === 'moved_from_id' ? 'from' : 'to',
    reallocationAmount,
  );
  const jobCostCode = JCCForReallocations?.find(
    (jcc) => jcc.id === getValues(destination),
  );
  const sovcs =
    destination === 'moved_from_id'
      ? getValues('movedFromSovcs')
      : getValues('movedToSovcs');

  if (isLoading) {
    return <SkeletonBlock className="h-[20px] w-full" />;
  }
  if (!jobCostCode) {
    return null;
  }
  return (
    <div className="flex flex-grow items-start justify-between gap-tw-4">
      <div className="flex flex-col gap-tw-1.5">
        <JobCostCodeView jobCostCode={jobCostCode} />
        {!isEmpty(sovcs) && (
          <span
            className="secondary-regular cursor-pointer text-blue"
            onClick={() =>
              openModal(SourcesViewModal, {
                reallocationAmount,
                jobCostCode,
                sovcs,
                destination: destination === 'moved_from_id' ? 'from' : 'to',
              })
            }
          >
            Details
          </span>
        )}
      </div>
      <div className="flex w-[280px] gap-tw-2">
        <CurrencyFormatter
          classes={{ wrapper: 'secondary-regular', value: 'text-light-60' }}
          value={jobCostCode.amount}
        />
        <FromToContainer>
          <ReallocationCurrencyFormatter
            negativeVariant="brackets"
            value={destinationReallocationAmount}
          />
        </FromToContainer>
        <CurrencyFormatter
          classes={{ wrapper: 'secondary-regular', value: 'text-dark-60' }}
          value={Number(jobCostCode.amount) + destinationReallocationAmount}
        />
      </div>
    </div>
  );
};

export const AmountContainer = ({
  oldValue,
  newValue,
}: {
  newValue: Float;
  oldValue?: Float;
}) => (
  <div className="flex flex-col gap-tw-1">
    <div className="flex gap-tw-1">
      <span className="inline-regular text-light-60">Amount:</span>
      <div className="flex flex-col gap-tw-1">
        {oldValue && (
          <CurrencyFormatter
            classes={{
              value: 'text-light-60',
              wrapper: 'text-light-60 inline-regular line-through',
            }}
            value={oldValue}
          />
        )}
        <CurrencyFormatter
          classes={{ wrapper: 'inline-regular', value: 'text-dark-60' }}
          value={newValue}
        />
      </div>
    </div>
  </div>
);

export const SovcItemContainer = ({ children }: React.PropsWithChildren) => (
  <div className="gap-px flex overflow-hidden rounded-lg border border-light-10 bg-light-10">
    {children}
  </div>
);

export const SovcItem = ({
  className,
  type,
  onSetValues,
  sovcCount,
  active,
}: {
  type: 'destination' | 'source';
  onSetValues: VoidFunction;
  sovcCount: number;
  active?: boolean;
} & ClassNameProps) => {
  const disabledTextClass = !active && 'text-light-60';
  const typeLabel = `${capitalize(type)}`;
  return (
    <div
      className={cn(
        'flex flex-1 flex-col items-start justify-start gap-tw-2 bg-light p-tw-4',
        className,
      )}
    >
      <div className="  flex flex-col gap-tw-0.5">
        <span className={cn('inline-regular text-black', disabledTextClass)}>
          {sovcCount === 0 ? 'Action Required' : typeLabel}
        </span>
        <span
          className={cn('secondary-regular text-light-80', disabledTextClass)}
        >
          {sovcCount === 0
            ? `Drill down to ${typeLabel}`
            : `${
                type === 'source' ? 'from' : 'to'
              } ${sovcCount} schedule of value codes`}
        </span>
      </div>
      <Button
        variant="secondary"
        size="xs"
        disabled={!active}
        onClick={onSetValues}
      >
        {sovcCount === 0 ? 'Set Values' : 'Details'}
      </Button>
    </div>
  );
};

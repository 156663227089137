import { CellEditor } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CellEditor/CellEditor';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import {
  HeaderGroupComponent,
  HeaderGroupComponentProps,
  HeaderGroupComponentWithShortYear,
} from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { getReconcileOperationalCellRendererParams } from 'bundles/Shared/components/AgGrid/Table/utils/getCurrencyCellRendererProps';
import {
  IColumn,
  IColumnGroup,
  IRow,
  IRowBase,
  isRowGl,
  isRowJcc,
} from 'bundles/Shared/components/ReportTable/types';
import { getColId } from 'bundles/Shared/components/ReportTable/utils';
import { useMemo } from 'react';
import { IReportBudgetOnShow } from 'types/ReportBudget';
import { parseStrToInt } from '../helpers/calcTotalGlColValue';
import { HandleCellChange } from './useCellChangeHandler';

export const isRowEditable = (row: IRow) => isRowGl(row) || isRowJcc(row);

export function useColumnDefs({
  columns,
  budget,
  handleCellChange,
}: {
  columns: IColumnGroup[];
  budget: IReportBudgetOnShow;
  handleCellChange: HandleCellChange;
}): ColGroupDef[] {
  const columnDefs = useMemo(() => {
    const totalPeriods = columns.flatMap((columnGroup) =>
      columnGroup.children.flatMap(
        (columnGroupChild) => columnGroupChild.periods,
      ),
    );

    const parsedLastColumnKey = parseStrToInt(columns.at(-1)?.children[0]?.key);

    const totalKeyNumber =
      columns.reduce(
        (prev, curr) => Math.max(prev, parseStrToInt(curr.children[0].key)),
        parsedLastColumnKey,
      ) + 1;

    const totalKey = String(totalKeyNumber);

    const totalsColGroup: IColumnGroup = {
      label: 'Total',
      type: 'totals',
      children: [
        {
          display: {},
          key: totalKey,
          label: 'Budget',
          periods: totalPeriods,
        },
      ],
    };

    const getValue = (row: IRowBase, colChild: IColumn) => {
      if (isRowEditable(row)) {
        const valueObj = row.data[colChild.key];
        if (valueObj?.value == null) return null;

        const parsedFloat = parseFloat(valueObj.value);

        if (Number.isNaN(parsedFloat)) return null;

        return parseFloat(valueObj.value) ?? null;
      }
      return null;
    };

    const totalCol: ColGroupDef = {
      headerName: totalsColGroup.label,
      headerGroupComponent: HeaderGroupComponent,
      headerGroupComponentParams: {
        year: budget.year,
      } satisfies HeaderGroupComponentProps,
      children: totalsColGroup.children.map((colChild) => ({
        aggFunc: 'sum',
        colId: getColId(totalsColGroup, colChild),
        field: colChild.key,
        pinned: 'right',
        headerName: colChild.label,
        cellRendererParams: getReconcileOperationalCellRendererParams,
        valueGetter: ((params) => {
          const row = params.data as IRowBase;
          return getValue(row, colChild);
        }) satisfies ColDef['valueGetter'],
      })),
    };

    const result: ColGroupDef[] = columns.map((col) => ({
      colId: getColId(col),
      headerName: col.label,
      headerGroupComponent: HeaderGroupComponentWithShortYear,
      headerGroupComponentParams: {
        year: budget.year,
      } satisfies HeaderGroupComponentProps,
      children: col.children.map((colChild) => ({
        editable: (p) => isRowEditable(p.data),
        aggFunc: 'sum',
        colId: getColId(col, colChild),
        field: colChild.key,
        headerName: colChild.label,
        cellRendererParams: getReconcileOperationalCellRendererParams,
        cellEditor: CellEditor,
        valueGetter: ((params) => {
          const row = params.data as IRowBase;
          return getValue(row, colChild);
        }) satisfies ColDef['valueGetter'],
        valueSetter: ((params) => {
          handleCellChange({
            columnKey: colChild.key,
            ...params,
          });
          return false;
        }) satisfies ColDef['valueSetter'],
      })),
    }));
    return [...result, totalCol];
  }, [columns]);

  return columnDefs;
}

import { emptySplitApi as api } from '../../../app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsCoreFundsAssets: build.query<
      GetApiSettingsCoreFundsAssetsApiResponse,
      GetApiSettingsCoreFundsAssetsApiArg
    >({
      query: () => ({ url: '/api/settings/core/funds/assets' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreFundsAssetsSettingsApi };
export type GetApiSettingsCoreFundsAssetsApiResponse =
  /** status 200 success */ CoreFundsAssets[];
export type GetApiSettingsCoreFundsAssetsApiArg = void;
export type CoreFundsAssets = {
  id: number;
  name: string;
};

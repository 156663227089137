import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { BasicAutoGroupCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import {
  MasterUnitTableDto,
  ReportObjectDashboardSection,
} from 'bundles/Shared/entities/dashboard';
import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  FiltersWidgetState,
  getStateFromFilterChangedEvent,
  PaginationWidgetState,
  postProcessPopup,
  QueryWidgetState,
  useWidgetTableDefaultColDef,
  WIDGET_TABLE_AUTO_GROUP_COL_DEF,
  WidgetStateDate,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateTablePagination } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateTablePagination';
import { WidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import { buildWidgetTableColumnDefs } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { getMasterUnitTableColumnDefs } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/config';
import { LeaseDetailsModal } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/ui/LeaseDetailsModal';
import { useMemo, useRef } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';
import { useObjectDashboardWidgetTableExport } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { WidgetStateSearchInput } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateSearchInput';

export type UnitTableWidgetState = PaginationWidgetState &
  DateWidgetState &
  QueryWidgetState &
  FiltersWidgetState;

export function MasterUnitTableWidget(
  props: WidgetProps<MasterUnitTableDto, ReportObjectDashboardSection> &
    WidgetConfigProps<UnknownRecord> &
    WidgetStateProps<UnitTableWidgetState> &
    WidgetContextProps<ObjectDashboardWidgetContext>,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    isLoading,
    isFetching,
    isError,
    mode,
    context,
  } = props;

  const { openModal } = useModal();
  const gridRef = useRef<AgGridReact>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });

  const exportFeature = useObjectDashboardWidgetTableExport({
    mode,
    gridRef,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
    context,
  });
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);
  const mappedData = useMemo(
    () =>
      data
        ? {
            ...data,
            rows: data.rows.map((row) => ({
              ...row,
              values: Object.fromEntries(
                row.values.map((v) => [v.key, v.value]),
              ),
            })),
          }
        : undefined,
    [data],
  );

  const columnDefs = useMemo(() => {
    return buildWidgetTableColumnDefs({
      columns: data?.columns ?? [],
      columnToColDefMapper: (c, cg) => ({
        headerName: c.label,
        colId: c.key.toString(),
        field: `values.${c.key.toString()}`,
        headerComponentParams: {
          ...cg?.headerGroupComponentParams,
          subHeaderName: c.sub_label,
        },
        ...(c.valueDisplayOptions &&
          getDefaultAgGridNumberColDef(c.valueDisplayOptions)),
      }),
      rows: data?.rows ?? [],
      agGridConfig: getMasterUnitTableColumnDefs(data?.filterOptions ?? []),
    });
  }, [JSON.stringify(data?.columns)]);

  const defaultColDef = useWidgetTableDefaultColDef({
    mode,
  });
  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      ...WIDGET_TABLE_AUTO_GROUP_COL_DEF,
      onCellClicked: (params) => {
        const row = params.data as MasterUnitTableDto['rows'][number];
        openModal(LeaseDetailsModal, {
          unitId: row.unitId!,
          unitLabel: row.label,
        });
      },
      headerName: 'Unit',
      maxWidth: 100,
      cellRendererParams: {
        linkable: true,
      } satisfies BasicAutoGroupCellRendererProps,
      cellClass: exportFeature.autoGroupColumnDef.cellClass,
    };
  }, [exportFeature.autoGroupColumnDef.cellClass]);

  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
        </DashboardWidgetCard.Header.Title>

        <div className="grow" />
        <WidgetStateDate state={state} onStateChange={onStateChange} />
        <exportFeature.ExportButtonComponent />
        {mode !== 'pdf' && <widgetStateFullScreenFeature.IconButton />}
      </DashboardWidgetCard.Header>
      <div className="flex items-center bg-neutral-100 p-tw-4">
        <WidgetStateTablePagination
          isLoading={isLoading ?? isFetching ?? false}
          state={state}
          onStateChange={onStateChange}
          totalSize={data?.totalSize ?? 0}
          includeTotalSizeInPerPage
        />
        <div className="grow" />
        <WidgetStateSearchInput
          state={state}
          onStateChange={onStateChange}
          suggestions={['unit label', 'current and future resident names']}
        />
      </div>
      {!isLoading && !isError && (
        <WidgetTable
          ref={gridRef}
          mode={mode}
          rowData={mappedData?.rows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          getRowId={({ data: row }) => row.key.toString()}
          getDataPath={(row) => [row.key.toString()]}
          domLayout="autoHeight"
          defaultParams={{
            sizeColumnsToFit: false,
          }}
          excelStyles={exportFeature.excelStyles}
          postProcessPopup={postProcessPopup}
          onFilterChanged={(e) => {
            onStateChange({
              ...state,
              ...getStateFromFilterChangedEvent(e, data?.columns ?? []),
            });
          }}
        />
      )}
    </DashboardWidgetTableCard>
  );
}

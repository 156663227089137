import React from 'react';
import { CollapsableSection, AssetRelatedPartyCard, IconButton } from 'stories';

export const RelatedPartiesCollapsableSection = ({
  canManage,
  relatedParties,
  onDelete,
  onEdit,
  onCreate,
}) => (
  <CollapsableSection
    canManage={canManage}
    count={relatedParties.length}
    content={
      <div className="flex flex-col gap-tw-2">
        {relatedParties.map((relatedParty) => (
          <AssetRelatedPartyCard
            key={relatedParty.id}
            canManage={canManage}
            relatedParty={relatedParty}
            onEdit={() => {
              onEdit(relatedParty);
            }}
            onDelete={() => {
              onDelete(relatedParty);
            }}
          />
        ))}
      </div>
    }
    title="RELATED PARTIES"
    actions={
      canManage && (
        <IconButton
          iconName="addSmall"
          onClick={onCreate}
          variant="secondary"
        />
      )
    }
  />
);

export default RelatedPartiesCollapsableSection;

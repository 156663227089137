import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/reportBuilderTemplatesGeneratedApi';
import {
  useGetApiSettingsReportBuilderTemplatesQuery,
  usePostApiSettingsReportBuilderTemplatesMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { CreateReportBuilderTemplateModal } from '@/entities/report/reportBuilder/ui/CreateReportBuilderTemplateModal';
import { ReportBuilderTemplateCard } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateCard';
import { useModal } from '@/shared/lib/hooks/useModal';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { TileList } from '@/shared/ui/TileList';
import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';

export const ReportTemplatesPage = () => {
  const [pageParams, setPageParams] = useState<{
    query: string;
    tags: ReportBuilderTemplateDto['tags'];
  }>({
    query: '',
    tags: [],
  });
  const { data, isLoading } = useGetApiSettingsReportBuilderTemplatesQuery();
  const { openModal } = useModal();
  const [createTemplate, options] =
    usePostApiSettingsReportBuilderTemplatesMutation();

  const items = useMemo(
    () =>
      (data?.items ?? []).filter((template) => {
        const matchesQuery = includesInLowerCase(
          template.name,
          pageParams.query ?? '',
        );
        return matchesQuery;
      }),
    [data, pageParams],
  );

  const handleCreateTemplate = async () => {
    const res = await openModal(CreateReportBuilderTemplateModal, {});

    if (res == null) return;

    createTemplate({
      body: {
        name: res.name,
      },
    });
  };

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <TileList>
        <TileList.Header>
          <GrowDiv />
          <TileList.CreateButton
            onClick={handleCreateTemplate}
            loading={options.isLoading}
            disabled={options.isLoading}
            entityName="Template"
          />
          <PageParamsSearch placeholder="Template Name" />
        </TileList.Header>
        <TileList.ItemCounter count={items.length} />
        <TileList.Items
          isLoading={isLoading}
          isListEmpty={items.length === 0}
          isQueryEmpty={isEmpty(pageParams.query)}
          noDataOverlayTitle="No Templates found"
        >
          {items.map((template) => (
            <ReportBuilderTemplateCard template={template} key={template.id} />
          ))}
        </TileList.Items>
      </TileList>
    </PageParamsProvider>
  );
};

import React from 'react';
import CommentsList from 'bundles/REconcile/components/comments/CommentsList/CommentsList';
import CommentPreviewSection from 'bundles/REconcile/components/comments/CommentPreviewSection/CommentPreviewSection';
import CommentsPanel from 'bundles/REconcile/components/comments/CommentsPanel/CommentsPanel';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { IconButton } from 'stories';
import BudgetCommentsRepliesPanel from 'bundles/REconcile/components/development/requisitions/comments/BudgetCommentsRepliesPanel';
import {
  openComment,
  openReplies,
  selectCommentsItems,
  selectOpenedComment,
  selectOpenedReplies,
  selectTypeCommentsPanel,
} from 'bundles/REconcile/reducers/developmentBudgetVarianceCommentSlice';
import pluralize from 'pluralize';
import { IBudgetVarianceComment } from 'bundles/REconcile/types/Comments';
import { convertToMilliseconds } from '@/shared/lib/converters';

interface Props {
  onClose: () => void;
}

function BudgetsCommentsPanel({ onClose }: Props) {
  const dispatch = useAppDispatch();
  const comments = useAppSelector(selectCommentsItems);
  const typeCommentsPanel = useAppSelector(selectTypeCommentsPanel);
  const openedComment = useAppSelector(selectOpenedComment);
  const openedReplies = useAppSelector(selectOpenedReplies);

  const handleReplies = (comment: IBudgetVarianceComment) => {
    dispatch(
      openComment({
        typeCommentsPanel,
        commentId: comment.id,
      }),
    );
    dispatch(openReplies(true));
  };

  if (openedReplies) {
    return <BudgetCommentsRepliesPanel parentCommentId={openedComment.id} />;
  }

  return (
    <CommentsPanel
      header={
        <>
          <span className="dark-60 inline-semibold">
            {typeCommentsPanel === 'one' && 'Comment'}
            {typeCommentsPanel === 'many' &&
              `${comments.length} ${pluralize('Comments', comments.length)}`}
          </span>
          <IconButton
            iconName="close"
            size="l"
            variant="secondary"
            onClick={onClose}
          />
        </>
      }
      comments={
        <CommentsList>
          {openedComment && typeCommentsPanel === 'one' && (
            <CommentPreviewSection
              createdAt={convertToMilliseconds(openedComment.createdAt)}
              code={openedComment.categoryName}
              message={openedComment.message}
              repliesCount={openedComment.replies?.length ?? 0}
              fullName={openedComment.author.fullName}
              attachments={openedComment.attachments ?? []}
              onRepliesClick={() => handleReplies(openedComment)}
              mentionedUsers={openedComment.mentionedUsers}
            />
          )}
          {typeCommentsPanel === 'many' &&
            comments.map((c) => (
              <CommentPreviewSection
                key={c.id}
                createdAt={convertToMilliseconds(c.createdAt)}
                code={c.categoryName}
                message={c.message}
                repliesCount={c.replies?.length ?? 0}
                fullName={c.author.fullName}
                attachments={c.attachments ?? []}
                onRepliesClick={() => handleReplies(c)}
                mentionedUsers={c.mentionedUsers}
              />
            ))}
        </CommentsList>
      }
    />
  );
}

export default BudgetsCommentsPanel;

import { ReportLineItem } from 'bundles/REturn/actions/types';
import { sumBy } from 'lodash-es';
import { EditableDistribution } from '../../splitModal/SplitDistributions';
import { EntyTxWithReviewInfo } from '../../types';

export function splitLinkingReviewEntryTx({
  transaction,
  editableDistributions,
}: {
  transaction: ReportLineItem;
  editableDistributions: EditableDistribution[];
}): (EntyTxWithReviewInfo | null)[] {
  const entryTxs: (EntyTxWithReviewInfo | null)[] = editableDistributions.map(
    ({ investmentEntity, ...item }) => {
      if (
        investmentEntity &&
        item.capitalInvestmentId &&
        item.amount > 0 &&
        item.kind != undefined
      ) {
        const sameEntryTx = editableDistributions.filter(
          (innerCi) => innerCi.capitalInvestmentId === item.capitalInvestmentId,
        );

        const totalTransactionsAmount = sumBy(sameEntryTx, 'amount');

        const reviewEntryTx: EntyTxWithReviewInfo = {
          kind: item.kind,
          amount: item.amount,
          investmentId: item.capitalInvestmentId,
          accountingTransactionId: transaction.id,
          investmentName: investmentEntity.name,
          totalAmount: investmentEntity.totalAmount,
          totalTransactionsAmount,
          kindReviewInfo: sameEntryTx.map((innerCi) => ({
            id: innerCi.id,
            kind: innerCi.kind,
            amount: innerCi.amount,
          })),
        };

        return reviewEntryTx;
      }
      return null;
    },
  );

  return entryTxs;
}

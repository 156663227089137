import { TRequisitionId } from 'bundles/REconcile/types/IRequisition';
import { IGeneralLedger } from 'types/GeneralLedger';

export interface IVarianceRow {
  type: 'category' | 'jcc' | 'sovc';
  key: string;
}

export interface IVarianceCategoryRow extends IVarianceRow {
  type: 'category';
  categoryPath: string;
  label: string;
  children: Array<IVarianceRow>;
}

export type TVarianceRowData = {
  budget: string;
  requisitions: Record<TRequisitionId, string | null>;
};

interface IVarianceRowComment {
  commentId: string;
  requisitionId: string;
}

export interface IVarianceJccRow extends IVarianceRow {
  type: 'jcc';
  jobCostCode: {
    id: number;
    name: string;
  };
  comments: IVarianceRowComment[];
  data: TVarianceRowData;
  generalLedgers: Array<IGeneralLedger>;
}

export interface IVarianceSovcRow extends IVarianceRow {
  type: 'sovc';
  scheduleOfValueCode: {
    id: number;
    name: string;
  };
  comments: IVarianceRowComment[];
  data: TVarianceRowData;
  generalLedgers: Array<IGeneralLedger>;
}

export function isVarianceCategoryRow(
  row: IVarianceRow,
): row is IVarianceCategoryRow {
  return row?.type === 'category';
}

export function isVarianceJccRow(row: IVarianceRow): row is IVarianceJccRow {
  return row?.type === 'jcc';
}

export function isVarianceSovcRow(row: IVarianceRow): row is IVarianceSovcRow {
  return row?.type === 'sovc';
}

import React, { FC } from 'react';
import { Icon } from 'stories';

interface INumberColumnProps {
  number: number;
  handleChoosePayApp: (id: number) => void;
  id: number;
}

const NumberColumn: FC<INumberColumnProps> = ({
  number,
  handleChoosePayApp,
  id,
}) => (
  <div
    className="flex items-center link-payapp"
    onClick={() => handleChoosePayApp(id)}
  >
    <Icon iconName="scroll" className="light-60 mr-xs" />
    <div className="blue">{number}</div>
  </div>
);

export default NumberColumn;

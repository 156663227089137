import { IRowDataValue } from 'bundles/Shared/components/ReportTable/types';

export const parseStrToInt = (str?: string) => parseFloat(str ?? '0') || 0;

/**
 * @param {rowDataValueObjList} should be without total column item
 ```js
  const rowDataWithoutTotalColumn: TRowData = omit(rowData, totalColumnKey);

  const rowDataValueObjList = Object.values(updatedRowDataWithoutTotalColumn);
  ```
 */
export function calcTotalGlColValue(
  rowDataValueObjList: IRowDataValue[],
): IRowDataValue['value'] {
  const isNullValue = rowDataValueObjList.every((item) => item.value === null);
  if (isNullValue) return null;

  const totalRowValueNum = rowDataValueObjList.reduce(
    (prev, curr) => prev + parseStrToInt(curr.value ?? undefined),
    0,
  );

  return String(totalRowValueNum);
}

import React, { useRef, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';
import BadgeWithStatus from '../Shared/components/BadgeWithStatus';

const PasswordFields = ({ errors, watch, register, getValues }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation]
    = useState(false);
  const passwordErrors = errors?.password?.types;
  const passwordErrorsKeys
    = (passwordErrors && Object.keys(passwordErrors)) || [];

  const allErrors = {
    minUppercase: {
      text: '1 uppercase letter',
      valid: passwordErrors && !passwordErrorsKeys.includes('minUppercase'),
    },
    minLowercase: {
      text: '1 lowercase letter',
      valid: passwordErrors && !passwordErrorsKeys.includes('minLowercase'),
    },
    minNumber: {
      text: '1 number',
      valid: passwordErrors && !passwordErrorsKeys.includes('minNumber'),
    },
    minSymbol: {
      text: '1 symbol',
      valid: passwordErrors && !passwordErrorsKeys.includes('minSymbol'),
    },
    min: {
      text: 'at least 8 characters',
      valid: passwordErrors && !passwordErrorsKeys.includes('min'),
    },
  };

  const passwordInput = useRef(null);
  const executeScroll = () => {
    if (window.innerWidth < 600) passwordInput.current.scrollIntoView({ behavior: 'smooth' });
  };

  const { password, password_confirmation } = watch();
  const passwordsMatched = password === password_confirmation;
  const passwordValidationMessage
    = 'Ensure all requirements are met to proceed!';
  return (
    <div>
      <div className="form-group auth__form-group">
        <label
          className="auth__label"
          onClick={executeScroll}
          ref={passwordInput}
        >
          Password
          <span className="red"> *</span>
          <p
            className="text-[0.75rem] font-weight-400 mt-[0.5rem]"
            style={{ color: '#848d91' }}
          >
            Please create a secure password <br />
            including the following criteria below
          </p>
          <div className="position-relative">
            <input
              onClick={executeScroll}
              {...register('password')}
              className={cn('form-control auth__input', {
                'is-invalid': errors.password,
              })}
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
            />
            <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
              <Icon
                iconName={showPassword ? 'eye' : 'eyeSlash'}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
          </div>
          <div
            className={cn('invalid-feedback', { 'd-block': errors.password })}
          >
            {passwordValidationMessage}
          </div>
        </label>
        {Object.keys(allErrors).map((errorKey) => (
          <BadgeWithStatus
            key={errorKey}
            className="mt-[0.5rem] mr-[0.5rem]"
            isSuccessful={
              allErrors[errorKey].valid
              || (!errors?.password?.types
                && getValues('password') !== undefined
                && getValues('password') !== '')
            }
          >
            {allErrors[errorKey].text}
          </BadgeWithStatus>
        ))}
      </div>
      <div className="form-group auth__form-group">
        <label className="auth__label">
          Password confirmation
          <span className="red"> *</span>
          <div className="position-relative">
            <input
              {...register('password_confirmation')}
              className={cn('form-control auth__input', {
                'is-invalid': !passwordsMatched,
              })}
              type={showPasswordConfirmation ? 'text' : 'password'}
              placeholder="Repeat password"
            />

            <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
              <Icon
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
                iconName={showPasswordConfirmation ? 'eye' : 'eyeSlash'}
              />
            </div>
          </div>
          <div
            className={cn('invalid-feedback', { 'd-block': !passwordsMatched })}
          >
            {passwordValidationMessage}
          </div>
        </label>
        <BadgeWithStatus
          key="password-confirmation"
          className="mt-[0.5rem] mr-[0.5rem]"
          isSuccessful={passwordsMatched && password_confirmation !== undefined}
        >
          Passwords must match
        </BadgeWithStatus>
      </div>
    </div>
  );
};

export default PasswordFields;

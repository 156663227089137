import { BaseCard } from '@/shared/ui/BaseCard';
import {
  ReportDashboardType,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard';
import { useDeleteDashboard } from 'bundles/Shared/features/dashboard/delete';
import { useDuplicateDashboard } from 'bundles/Shared/features/dashboard/duplicate';

interface Props {
  id: ReportEagleEyeDashboard['id'];
  type: `${ReportDashboardType}`;
  dashboardName?: string;
}

export function DashboardSettingsCardActions({
  id,
  type,
  dashboardName,
}: Props) {
  const [deleteDashboard] = useDeleteDashboard(type);
  const [duplicateDashboard] = useDuplicateDashboard({ type, dashboardName });

  return (
    <BaseCard.DropdownActions
      items={[
        {
          text: 'Duplicate',
          onAction: () => {
            duplicateDashboard(id);
          },
        },
        {
          text: 'Delete',
          onAction: () => {
            deleteDashboard(id);
          },
        },
      ]}
    />
  );
}

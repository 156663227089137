import { twMergeExtended } from '@/shared/lib/css/tw-merge';
import { uniq } from 'lodash-es';
import { ClassRule, GetStylesObject } from 'stories/ValueFormatters/types';

export const getFormattedValueParts = (value: string) => {
  const formattedValueParts = value.split('.');
  const [displayValue, fractionsValue] = formattedValueParts;

  return {
    displayValue,
    fractionsValue,
  };
};

const getClassName = (
  className: string | ClassRule | undefined,
  value: number | null | undefined,
) => {
  if (typeof className === 'function') {
    return className({ value });
  }
  return className;
};

export const getMergedClasses = <Classes>({
  defaultClasses,
  allParts,
  classes,
  value,
}: {
  value?: number | null | undefined;
  defaultClasses?: Record<string, string | ClassRule>;
  classes?: Classes;
  allParts?: string | ClassRule;
}) => {
  const getClassNameLocal = (className: string | undefined | ClassRule) =>
    getClassName(className, value);

  const uniqKeys = uniq([
    ...Object.keys(defaultClasses ?? {}),
    ...Object.keys(classes ?? {}),
  ]);
  const entries = uniqKeys.map((key) => [
    key,
    twMergeExtended(
      getClassNameLocal(defaultClasses?.[key]),
      getClassNameLocal(allParts),
      getClassNameLocal(classes?.[key]),
    ),
  ]);

  return Object.fromEntries(entries) as Record<keyof Classes, string>;
};

type StylePartNames = string;

export const getMergedStyles = ({
  allParts,
  partNames,
  ...restParts
}: {
  partNames: StylePartNames[];
  allParts?: React.CSSProperties;
} & GetStylesObject<StylePartNames>) => {
  return Object.fromEntries(
    partNames.map((partKey) => [
      partKey,
      {
        ...(restParts[partKey] ?? {}),
        ...(allParts ?? {}),
      },
    ]),
  ) as Record<'allParts' | StylePartNames, React.CSSProperties>;
};

import { View, StyleSheet, Text } from '@react-pdf/renderer';
import {
  DEFAULT_VAR_PDF,
  uiPDFStyles,
} from 'bundles/Shared/components/PDFLibrary/ui/uiPDFStyles';
import React, { FC } from 'react';

interface IHeaderDPSProps {}

const HeaderDPS: FC<IHeaderDPSProps> = ({}) => {
  const styles = StyleSheet.create({
    header: {
      flexDirection: 'row',
      width: '100%',
    },
    bigCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '28%',
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
    },
    bigHeightCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '14%',
      borderTopWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
    },
    smallHeightCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '8%',
      borderTopWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flex: 1,
    },
    rightCol: {
      flex: 1,
      width: '72%',
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      textAlign: 'center',
      alignItems: 'center',
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      paddingVertical: 5,
    },
  });
  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <View style={styles.header}>
        <View style={styles.bigCol} />
        <View style={styles.rightCol}>
          <Text style={uiPDFStyles.lightMediumTitle}>Budget Cost Tracking</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Category</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Budget SubCategory</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Budget</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Total Reallocations</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Revised Budget</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Funded to Date</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Current Requisition</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Actual to Date</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>
            Balance to Complete (Revised Budget)
          </Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>% Complete</Text>
          <Text style={uiPDFStyles.lightSmallTitle}>(Revised Budget)</Text>
        </View>
      </View>
    </View>
  );
};

export default HeaderDPS;

import { STYLING_KEYSTROKES } from '@/shared/lib/ckEditor/constants';

export const disableStylingKeystrokes = (editor) => {
  const keystrokeHandler = (data, cancel) => {
    cancel();
  };
  STYLING_KEYSTROKES.forEach((keystroke) => {
    editor.keystrokes.set(keystroke, keystrokeHandler, {
      priority: 'high',
    });
  });
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'lib/http';

export interface ISummaryRow {
  budget_line_items: RawFloat;
  reallocations: RawFloat;
  adjusted_budget: RawFloat;
  requisitions: RawFloat;
  forecasted_spend: RawFloat;
  total_spend: RawFloat;
}

export type ISummaryResponse = ISummaryRow[];

export const reconcileLegalEntityProjectSummaryApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/reconcile/legal_entities/`,
  }),
  reducerPath: 'reconcileLegalEntityProjectSummary',
  endpoints: (build) => ({
    getProjectSummary: build.query<
      ISummaryResponse,
      { legalEntityCode: string }
    >({
      query: ({ legalEntityCode }) => `${legalEntityCode}/project_summary`,
    }),
  }),
});

export const { useGetProjectSummaryQuery } =
  reconcileLegalEntityProjectSummaryApi;

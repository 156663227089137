import { useDeleteApiSettingsReportBuilderTemplatesByIdMutation } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useDeleteReportBuilderTemplate = () => {
  const [deleteReport, options] =
    useDeleteApiSettingsReportBuilderTemplatesByIdMutation();
  const { confirm } = useModal();

  const handleDelete = async (templateId: string) => {
    const answer = await confirm({
      title: 'Are you sure you want to delete this template?',
    });

    if (!answer) return;

    const response = await deleteReport({
      id: templateId,
    });

    return response;
  };

  return [handleDelete, options] as const;
};

import {
  ADJUSTMENT_TYPE_OPTIONS,
  FINANCIAL_TABLE_WIDGET_ADJUSTMENT_EXPRESSION_OPTIONS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { Control } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';

export function FinancialTableSinglePeriodColumnAdjustmentFields<
  FV extends FieldValues = FieldValues,
>({ control }: { control: Control<FV> }) {
  const getFieldName = (name: 'adjustment_expression' | 'adjustment_type') =>
    name as FieldPath<FV>;

  return (
    <SectionFieldsContainer>
      <SectionField labelText="Column Type">
        <RadioGroupController
          control={control}
          name={getFieldName('adjustment_expression')}
          options={FINANCIAL_TABLE_WIDGET_ADJUSTMENT_EXPRESSION_OPTIONS}
        />
      </SectionField>
      <SectionField labelText="Adjustment Type">
        <RadioGroupController
          control={control}
          name={getFieldName('adjustment_type')}
          options={ADJUSTMENT_TYPE_OPTIONS}
        />
      </SectionField>
    </SectionFieldsContainer>
  );
}

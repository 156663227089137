export function StatusIndicator({
  variant,
}: {
  variant: 'empty' | 'in_progress' | 'success' | 'too_many';
}) {
  switch (variant) {
    case 'too_many': {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
            fill="#F6413E"
          />
          <path
            d="M8.1875 9.93421C9.11161 9.93421 9.875 10.6842 9.875 11.5921C9.875 12.5395 9.11161 13.25 8.1875 13.25C7.22321 13.25 6.5 12.5395 6.5 11.5921C6.5 10.6842 7.22321 9.93421 8.1875 9.93421Z"
            fill="white"
          />
          <path
            d="M6.86161 8.63158L6.58036 3.26316C6.58036 2.98684 6.78125 2.75 7.0625 2.75H9.27232C9.55357 2.75 9.75446 2.98684 9.75446 3.26316L9.47321 8.63158C9.47321 8.86842 9.23214 9.06579 8.99107 9.06579H7.34375C7.10268 9.06579 6.86161 8.86842 6.86161 8.63158Z"
            fill="white"
          />
        </svg>
      );
    }
    case 'empty':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8 2.4C4.90721 2.4 2.4 4.90721 2.4 8Z"
            fill="#848D91"
          />
        </svg>
      );
    case 'success': {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
            fill="#2CAE4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.774 4.94296C13.1237 5.37041 13.0607 6.00043 12.6332 6.35016L6.43282 11.4232L3.29289 8.28331C2.90237 7.89278 2.90237 7.25962 3.29289 6.8691C3.68342 6.47857 4.31658 6.47857 4.70711 6.8691L6.56718 8.72917L11.3668 4.80224C11.7942 4.45252 12.4242 4.51552 12.774 4.94296Z"
            fill="white"
          />
        </svg>
      );
    }
    case 'in_progress': {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8 2.4C4.90721 2.4 2.4 4.90721 2.4 8Z"
            fill="#FEBE46"
          />
          <path
            d="M8 0C5.87827 -2.53014e-08 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 1.85488e-07 5.87827 0 8C-1.85488e-07 10.1217 0.842854 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16L8 13.6C6.51479 13.6 5.0904 13.01 4.0402 11.9598C2.99 10.9096 2.4 9.48521 2.4 8C2.4 6.51479 2.99 5.09041 4.0402 4.0402C5.09041 2.99 6.51479 2.4 8 2.4V0Z"
            fill="#848D91"
          />
        </svg>
      );
    }
  }
}

import { PostApiCapitalInvestmentsApiArg } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { fetchInvestmentEntitiesByObject } from 'bundles/REturn/actions/capitalInvestmentEntities';
import SelectInvestmentEntityStep from 'bundles/REturn/components/Ownership/modals/createOwnerModal/SelectInvestmentEntityStep';
import SelectLegalEntitiesStep from 'bundles/REturn/components/Ownership/modals/createOwnerModal/SelectLegalEntitiesStep';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { ObjectType } from 'bundles/UserManagement/components/EditUserModalComponents/types';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { exists } from 'lib/typeHelpers';
import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalActions, ModalHeaderWithSubtitle } from 'stories';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props extends DialogProps<PostApiCapitalInvestmentsApiArg['body']> {
  entityId: number;
  entityType: ObjectType;
  disabledInvestmentEntitiesIds: {
    legalEntityId: string;
    investmentEntityId: number;
  }[];
  legalEntities: Pick<LegalEntity, 'id' | 'name'>[];
}

// Rework with rtk query caching
// Reason: Investment entities are being fetched every time a user opens the modal

export const INVESTMENT_CLASSES = ['a', 'b'] as const;

const CreateOwnerModal = ({
  entityId,
  entityType,
  onClose,
  onSubmit,
  disabledInvestmentEntitiesIds,
  legalEntities,
}: Props) => {
  const [step, setStep] = useState<number>(1);
  const [investmentEntities, setInvestmentEntities] = useState<
    IInvestmentEntity[] | undefined
  >(undefined);
  const [selectedInvestmentEntities, setSelectedInvestmentEntities] = useState<
    IInvestmentEntity[]
  >([]);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState<Pick<
    LegalEntity,
    'id' | 'name'
  > | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchInvestmentEntitiesByObject(entityId, entityType);

      setInvestmentEntities(data);
    };

    fetchData();
  }, []);

  const step1 = useMemo(
    () =>
      legalEntities && (
        <SelectLegalEntitiesStep
          legalEntities={legalEntities}
          selected={selectedLegalEntity}
          onSelectedChange={setSelectedLegalEntity}
        />
      ),
    [legalEntities, selectedLegalEntity],
  );

  const step2 = useMemo(
    () =>
      investmentEntities &&
      selectedLegalEntity && (
        <SelectInvestmentEntityStep
          investmentEntities={investmentEntities}
          selectedInvestmentEntities={selectedInvestmentEntities}
          onSelectedChanged={setSelectedInvestmentEntities}
          disabledItemIds={disabledInvestmentEntitiesIds
            .filter((entry) => entry.legalEntityId === selectedLegalEntity.id)
            .map((entry) => entry.investmentEntityId)}
        />
      ),
    [
      investmentEntities,
      selectedInvestmentEntities,
      disabledInvestmentEntitiesIds,
      selectedLegalEntity,
    ],
  );

  const steps = {
    1: step1,
    2: step2,
  };

  const stepsValid = {
    1: selectedLegalEntity !== null,
    2: selectedInvestmentEntities.length !== 0,
  };

  const stepCount = Object.keys(steps).length;

  const submitButtonText = {
    1: 'Continue',
    2: 'Create Owners',
  };

  const handleSubmit = () => {
    if (!exists(selectedLegalEntity)) return;

    onSubmit?.({
      investment_entity_ids: selectedInvestmentEntities.map(({ id }) => id),
      legal_entity_id: selectedLegalEntity.id,
      commitment_amount: 0,
    });
  };

  return (
    <Modal
      bodyHeight={600}
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          title="Create Owners"
          subtitle={`Step ${step} of ${stepCount}`}
          order="subtitle-title"
        />
      }
      classes={{
        body: 'px-tw-6 py-tw-4 flex flex-col gap-tw-6',
      }}
      size="600"
      actions={
        <ModalActions alignItems="space-between">
          <Button
            variant="secondary"
            onClick={
              step === 1 ? onClose : () => setStep((prevStep) => prevStep - 1)
            }
          >
            {step === 1 ? 'Cancel' : 'Back'}
          </Button>
          <Button
            variant="success"
            onClick={
              step !== stepCount
                ? () => setStep((prevStep) => prevStep + 1)
                : handleSubmit
            }
            disabled={!stepsValid[step]}
          >
            {submitButtonText[step]}
          </Button>
        </ModalActions>
      }
    >
      {(!investmentEntities || !legalEntities) && <LoadingPanel />}
      {investmentEntities && legalEntities && steps[step]}
    </Modal>
  );
};

export default CreateOwnerModal;

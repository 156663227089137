import React from 'react';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';

export const EMPTY_NOTIFICATIONS = (
  <CardWrapper className="shadow">
    <div className="px-tw-5 py-tw-5">
      <div className="items-center pb-[100px] pt-[100px] text-center">
        <h4 className="text-[#9e9e9e]">Notifications is empty</h4>
      </div>
    </div>
  </CardWrapper>
);

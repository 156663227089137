import React, { useState, useMemo } from 'react';
import { IReallocation } from 'bundles/Construction/types';
import FormViewSwitcher from 'bundles/Shared/components/GroupForm/FormViewSwitcher';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { updateReallocation } from 'bundles/Construction/reducers/ReconcileReallocationSlice';
import { useParams } from '@reach/router';
import {
  getDataREKeyinfo,
  getDataREKeyinfoManual,
  getDataSubRE,
} from './initData';
import EditREModal from './EditREModal';

interface Props {
  reallocation: IReallocation;
  canEdit: boolean;
}

const ReallocationDetails = ({ reallocation, canEdit }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigation = useNavigation();

  const dataREKeyinfo = useMemo(
    () => getDataREKeyinfo(reallocation),
    [reallocation],
  );
  const dataREKeyinfoManual = useMemo(
    () => getDataREKeyinfoManual(reallocation, navigation, params),
    [reallocation],
  );
  const dataSubRE = useMemo(() => getDataSubRE(reallocation), [reallocation]);

  const handleEdit = async (data) => {
    await dispatch(
      updateReallocation({
        reallocationId: params.reallocationId,
        data: {
          change_order_id: data.change_order_id,
          value: Number(data.value),
          moved_from_id: data.moved_from_id,
          moved_to_id: data.moved_to_id,
          input_type: 'auto',
          reallocation: {
            description: data.description,
            sub_reallocations: data?.subReallocations.map((subr) => ({
              id: subr.id,
              moved_from_id: subr.moved_from_id,
              moved_to_id: subr.moved_to_id,
              value: subr.value,
              status: 'PENDING',
            })),
          },
        },
      }),
    );
    setShowModal(false);
  };

  return (
    <div className="change-card mb-m">
      <div className="change-card__header change-card__header_line">
        <div className="change-card__title header6-bold pb-m">
          REallocation Details
        </div>
        {canEdit && (
          <div
            className="change-card__button inline-regular"
            onClick={() => setShowModal(true)}
          >
            Edit
          </div>
        )}
      </div>
      <div className="change-card__body">
        <div className="group-view">
          <div className="group-view__title body-semibold">Key Info</div>
          <div className="group-view__body">
            {dataREKeyinfo.map((item, idx) => (
              <FormViewSwitcher
                key={`keyinfo${idx}`}
                formItem={item}
                formData={reallocation}
              />
            ))}
          </div>
        </div>
        {!reallocation?.changeOrder && (
          <div className="group-view mt-m">
            <div className="group-view__title body-semibold">Details</div>
            <div className="group-view__body">
              {dataREKeyinfoManual.map((item, idx) => (
                <FormViewSwitcher
                  key={`keyinfo${idx}`}
                  formItem={item}
                  formData={reallocation}
                />
              ))}
            </div>
          </div>
        )}
        {Boolean(reallocation?.children?.length) && (
          <div className="group-view mt-m">
            <div className="group-view__title body-semibold">
              SubREallocations
            </div>
            {reallocation.children.map((reItem, idx) => (
              <div key={`reallocation${idx}`} className="group-view__body">
                {dataSubRE.map((item, idx) => (
                  <FormViewSwitcher
                    key={`reallocationItem${idx}`}
                    formItem={item}
                    formData={reItem}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      {showModal && (
        <EditREModal setShowModal={setShowModal} handleEdit={handleEdit} />
      )}
    </div>
  );
};

export default ReallocationDetails;

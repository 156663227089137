import {
  FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  FinancialTableSingleDateWidgetSection,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/defaults';
import { FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod';
import { DEFAULT_FINANCIAL_TABLE_SINGLE_PERIOD_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/defaults';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { DEFAULT_HISTORICAL_TABLE_WIDGET_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/historicalTable/config/defaults';
import {
  KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  KpiTableSingleDateWidgetSection,
  KpiTableSingleDateWidgetState,
} from '@/bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { UnitTableWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable';
import { MASTER_UNIT_TABLE_WIDGET_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/config';
import { MEDIA_WIDGET_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/media/config';
import {
  REPORT_BUILDER_WIDGET_TYPES,
  ReportBuilderWidgetTypes,
} from '@/entities/report/reportBuilder';
import type { ReportBuilderTemplateGroupWidgetSectionDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import type { IconsId } from '@/types/sre-icons';
import {
  AllWidgetTypes,
  COMPARISON_DASHBOARD_SECTION_TYPE,
  ComparisonDashboardSectionTypes,
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  EagleEyeDashboardSectionType,
  OBJECT_DASHBOARD_SECTION_TYPE,
  ObjectDashboardSectionTypes,
  REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE,
  ReportObjectDashboardSection,
} from 'bundles/Shared/entities/dashboard';
import {
  FinancialTableSingleDateWidgetConfigDto,
  FinancialTableSinglePeriodWidgetConfigDto,
} from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { AVERAGE_EFFECTIVE_RENT_CHART_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/averageActualRentChart/config';
import {
  DateWidgetState,
  PeriodTypeWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { DEFAULT_AM_CHART_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { DELINQUENCY_AVERAGE_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencyAverage';
import { DELINQUENCY_SNAPSHOT_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencySnapshot/config';
import { DELINQUENCY_TRACKER_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencyTracker';
import {
  DEFAULT_GLOBAL_LEASE_TABLE_CONFIG,
  GLOBAL_LEASE_TABLE_WIDGET_CONFIG,
} from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable';
import {
  HISTORICAL_TABLE_WIDGET_CONFIG,
  HistoricalReviewTableWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable';
import {
  KPI_CARD_WIDGET_CONFIG,
  KpiCardWidgetConfig,
  KpiCardWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiCard';
import { DEFAULT_KPI_CARD_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/kpiCard/config/defaults';
import {
  KPI_TABLE_WIDGET_CONFIG,
  KpiTableWidgetSection,
  KpiTableWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { DEFAULT_KPI_TABLE_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/config/defaults';
import { DEFAULT_KPI_TABLE_SINGLE_DATE_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/defaults';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import {
  RECENT_RENTROLL_WIDGET_CONFIG,
  UNIT_MIX_SUMMARY_TABLE_CONFIG,
  UNIT_MIX_WIDGET_CONFIG,
} from 'bundles/Shared/widgets/dashboard/widgets/recentRentroll';
import { TEXT_AREA_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/textArea';
import { XY_CHART_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/xyChart';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';
import { XYChartWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/widget';
import { DEFAULT_AMCHART_CONFIG_TEMPLATE } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/amchartDefaults';
import {
  DEFAULT_EAGLE_EYE_XY_CHART_DATE_CONFIG,
  XY_SINGLE_KPI_CHART_WIDGET_CONFIG,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/defaults';
import {
  XYChartSingleKpiWidgetSection,
  XYChartWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { XYChartSingleKpiWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/widget';
import { UnionToIntersection } from 'type-fest';
import { UnknownRecord } from 'type-fest/source/internal';

export const EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP: Record<
  EagleEyeDashboardSectionType,
  WidgetConfiguration
> = {
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD]: KPI_CARD_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: KPI_TABLE_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]:
    KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]:
    XY_SINGLE_KPI_CHART_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.GLOBAL_LEASE_TABLE]:
    GLOBAL_LEASE_TABLE_WIDGET_CONFIG,
};

export const OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP = {
  [OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD]: KPI_CARD_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]:
    HISTORICAL_TABLE_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]:
    DELINQUENCY_TRACKER_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_SNAPSHOT_COLUMN_CHART]:
    DELINQUENCY_SNAPSHOT_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_AVERAGE_COLUMN_CHART]:
    DELINQUENCY_AVERAGE_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.RECENT_RENT_ROLL_TABLE]:
    RECENT_RENTROLL_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_TABLE]: {
    ...RECENT_RENTROLL_WIDGET_CONFIG,
    title: 'Unit Mix',
  },
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_SUMMARY_TABLE]: {
    ...RECENT_RENTROLL_WIDGET_CONFIG,
    title: 'Unit Mix Summary',
  },
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: XY_CHART_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG as WidgetConfiguration,
  [OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART]:
    AVERAGE_EFFECTIVE_RENT_CHART_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE]:
    MASTER_UNIT_TABLE_WIDGET_CONFIG,
} as const satisfies Record<ObjectDashboardSectionTypes, WidgetConfiguration>;

export const COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP = {
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]:
    FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG,
} as const satisfies Record<
  ComparisonDashboardSectionTypes,
  WidgetConfiguration
>;

export const REPORT_BUILDER_WIDGETS_CONFIG_MAP = {
  [REPORT_BUILDER_WIDGET_TYPES.TEXT_AREA]:
    TEXT_AREA_WIDGET_CONFIG as WidgetConfiguration,
  [REPORT_BUILDER_WIDGET_TYPES.KPI_CARD]: KPI_CARD_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.HISTORICAL_TABLE]:
    HISTORICAL_TABLE_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.UNIT_MIX_TABLE]: UNIT_MIX_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.MEDIA]: MEDIA_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.UNIT_MIX_SUMMARY_TABLE]:
    UNIT_MIX_SUMMARY_TABLE_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.FINANCIAL_TABLE_SINGLE_DATE]:
    FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG as WidgetConfiguration,
  [REPORT_BUILDER_WIDGET_TYPES.DELINQUENCY_TRACKER_TABLE]:
    DELINQUENCY_TRACKER_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.RECENT_RENT_ROLL_TABLE]:
    RECENT_RENTROLL_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.XY_CHART]: XY_CHART_WIDGET_CONFIG,
} as const satisfies Record<ReportBuilderWidgetTypes, WidgetConfiguration>;

export const WIDGETS_CONFIG_MAP = {
  ...EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP,
  ...OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP,
  ...COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
  ...REPORT_BUILDER_WIDGETS_CONFIG_MAP,
} as const satisfies Record<AllWidgetTypes, WidgetConfiguration>;

export type WidgetSectionMap = {
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]: HistoricalReviewTableWidgetSection;
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: XYChartWidgetSection;
  [OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD]: KpiCardWidgetSection;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: KpiTableWidgetSection;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]: KpiTableSingleDateWidgetSection;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]: XYChartSingleKpiWidgetSection;

  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]: FinancialTableSinglePeriodWidgetSection;
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]: FinancialTableSingleDateWidgetSection;
} & Record<string, ReportObjectDashboardSection>;

export type ObjectDashboardWidgetStateMap = {
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]: DateWidgetState;
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]: DateWidgetState;
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: XYChartWidgetState;
  [OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE]: UnitTableWidgetState;
};
export type ComparisonDashboardWidgetStateMap = {
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]: DateWidgetState;
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]: DateWidgetState &
    PeriodTypeWidgetState;
};
export type ReportBuilderTemplateWidgetStateMap = {
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]: DateWidgetState;
};

export type ComparisonDashboardWidgetStateIntersection = UnionToIntersection<
  ComparisonDashboardWidgetStateMap[keyof ComparisonDashboardWidgetStateMap]
>;
export type ObjectDashboardWidgetStateIntersection = UnionToIntersection<
  ObjectDashboardWidgetStateMap[keyof ObjectDashboardWidgetStateMap]
>;

export type EagleEyeDashboardWidgetStateMap = {
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD]: UnknownRecord;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: KpiTableWidgetState;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]: KpiTableSingleDateWidgetState;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]: XYChartSingleKpiWidgetState;
};

const OBJECT_DASHBOARD_DEFAULT_CONFIG_MAP = {
  [OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD]: {
    kpis: [],
  } satisfies KpiCardWidgetConfig,
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]:
    DEFAULT_HISTORICAL_TABLE_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_SNAPSHOT_COLUMN_CHART]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_AVERAGE_COLUMN_CHART]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.RECENT_RENT_ROLL_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: {
    kpis: [],
    granularities: ['day', 'week', 'month'],
    default_options: { granularity: 'day', date_from: { period_type: 'day' } },
    am_chart_config: DEFAULT_AMCHART_CONFIG_TEMPLATE,
  } satisfies XYChartWidgetConfig,
  [OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART]: {
    granularities: ['month'],
    default_options: {
      granularity: 'month',
      date_from: { period_type: 'month', period_shift: { months: 12 } },
    },
    am_chart_config: DEFAULT_AM_CHART_CONFIG,
  },
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_SUMMARY_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE]: {},
} as const satisfies Record<ObjectDashboardSectionTypes, UnknownRecord>;

const EAGLE_EYE_DASHBOARD_DEFAULT_CONFIG_MAP = {
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD]: DEFAULT_KPI_CARD_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: DEFAULT_KPI_TABLE_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]:
    DEFAULT_KPI_TABLE_SINGLE_DATE_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]:
    DEFAULT_EAGLE_EYE_XY_CHART_DATE_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.GLOBAL_LEASE_TABLE]:
    DEFAULT_GLOBAL_LEASE_TABLE_CONFIG,
} as const satisfies Record<
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.GLOBAL_LEASE_TABLE,
  UnknownRecord
>;

const COMPARISON_DASHBOARD_DEFAULT_CONFIG_MAP = {
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG satisfies FinancialTableSingleDateWidgetConfigDto,
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]:
    DEFAULT_FINANCIAL_TABLE_SINGLE_PERIOD_CONFIG,
} as const satisfies Record<
  | typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE
  | typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD,
  | FinancialTableSinglePeriodWidgetConfigDto
  | FinancialTableSingleDateWidgetConfigDto
>;

export const REPORT_BUILDER_TEMPLATE_DEFAULT_CONFIG_MAP = {
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.TEXT_AREA]: {
    text: '',
  },
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.MEDIA]: {},
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.KPI_CARD]: {
    kpis: [],
  } satisfies KpiCardWidgetConfig,
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.HISTORICAL_TABLE]:
    DEFAULT_HISTORICAL_TABLE_WIDGET_CONFIG,
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]: {},
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.RECENT_RENT_ROLL_TABLE]: {},
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.XY_CHART]: {
    kpis: [],
    granularities: ['day', 'week', 'month'],
    default_options: { granularity: 'day', date_from: { period_type: 'day' } },
    am_chart_config: DEFAULT_AMCHART_CONFIG_TEMPLATE,
  } satisfies XYChartWidgetConfig,
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.UNIT_MIX_TABLE]: {},
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.UNIT_MIX_SUMMARY_TABLE]: {},
  [REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG,
} as const satisfies Record<ReportBuilderWidgetTypes, unknown>;

export const DEFAULT_CONFIG_MAP = {
  ...OBJECT_DASHBOARD_DEFAULT_CONFIG_MAP,
  ...EAGLE_EYE_DASHBOARD_DEFAULT_CONFIG_MAP,
  ...COMPARISON_DASHBOARD_DEFAULT_CONFIG_MAP,
  ...REPORT_BUILDER_TEMPLATE_DEFAULT_CONFIG_MAP,
};

export const BUILDER_UI_WIDGET_CARD_WIDGET_TYPE_ICON_MAP = {
  text_area: 'text',
  kpi_card: 'trending',
  xy_chart: 'chartBar',
  media: 'media',
  financial_table_single_date: 'table',
  delinquency_tracker_table: 'table',
  financial_table_single_period: 'table',
  historical_review_table: 'table',
  recent_rent_roll_table: 'table',
  unit_mix_summary_table: 'table',
  unit_mix_table: 'table',
} as const satisfies Record<
  ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
  IconsId
>;

import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import TransactionDetailsModal from 'bundles/REport/components/financials/modal/TransactionDetailsModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { LinkButton } from 'stories';

type Props = ICellRendererParams;

function LineItemsCountCell({ value, data: row }: Props) {
  const { openModal } = useModal();

  const handleClick = () => {
    openModal(TransactionDetailsModal, {
      generalLedger: row,
      legalEntities: [row.legalEntity],
      showUnpublished: true,
    });
  };

  return (
    <div className="flex items-center gap-tw-1">
      <LinkButton onClick={handleClick}>{value}</LinkButton>
    </div>
  );
}

export default LineItemsCountCell;

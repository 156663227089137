// TODO: copypaste from GroupedCheckboxes.tsx

import React, { useMemo, useState } from 'react';
import { Badge, RadioButton, SearchInput, Tooltip } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import useAlphabetGroups from '@/shared/lib/hooks/useAlphabetGroups';
import pluralize from 'pluralize';
import { isEmpty } from 'lodash-es';

interface Props<T extends { id: number | string }> {
  items: T[];
  getLabelFromItem: (item: T) => string;
  getTooltipText?: (item: T) => string | null;
  selected: T | null;
  setSelected: (selectedItem: T) => void;
  disabledItemIds?: T extends { id: infer Id } ? Id[] : never[];
  withSearch?: boolean;
  searchEntityLabel?: string;
}

const GroupedRadioButtons = <T extends { id: number | string }>({
  items,
  getLabelFromItem,
  selected,
  setSelected,
  disabledItemIds,
  withSearch,
  searchEntityLabel,
  getTooltipText,
}: Props<T>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const filtered = useMemo(
    () =>
      items.filter(
        (item) =>
          !searchQuery ||
          getLabelFromItem(item)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
      ),
    [items, searchQuery],
  );

  const sortedGroups = useAlphabetGroups(filtered, getLabelFromItem, (a, b) =>
    getLabelFromItem(a).localeCompare(getLabelFromItem(b)),
  );

  return (
    <div className="flex flex-col gap-m">
      {withSearch && (
        <div className="flex flex-col gap-s">
          <div className="light-60 font-weight-600 secondary-semibold">
            {items.length}{' '}
            {pluralize(searchEntityLabel ?? 'Entity', items.length)}
          </div>
          <SearchInput
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            value={searchQuery}
            resetValue={() => setSearchQuery('')}
            size="s"
          />
        </div>
      )}

      {Object.keys(sortedGroups).length === 0 && (
        <div className="mb-m mt-l text-center">
          <p>No results found</p>
        </div>
      )}
      <div className="flex flex-col gap-m">
        {Object.entries(sortedGroups).map(([group, groupedItems]) => (
          <div key={group}>
            <Badge
              className="mb-m"
              classes={{ label: '', value: cn('header6-bold sre-badge_dark') }}
            >
              {group.toUpperCase()}
            </Badge>
            <div className="flex flex-col gap-s">
              {groupedItems.map((item) => (
                <div key={item.id}>
                  <Tooltip
                    disabled={isEmpty(getTooltipText?.(item))}
                    mainText={getTooltipText?.(item)}
                  >
                    <RadioButton
                      classes={{
                        label: 'ml-xs',
                      }}
                      checked={selected?.id === item.id}
                      disabled={
                        disabledItemIds && disabledItemIds.includes(item.id)
                      }
                      onChange={() => setSelected(item)}
                    >
                      {getLabelFromItem(item)}
                    </RadioButton>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupedRadioButtons;

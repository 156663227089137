import { cn } from '@/shared/lib/css/cn';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate, useLocation } from '@reach/router';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { login } from 'lib/currentUser';
import { apiClient } from 'lib/http';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import yupPassword from 'yup-password';
import PasswordFields from './PasswordFields';
import { Button } from '@/stories';

yupPassword(yup);

const Registration = () => {
  const params = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);

  function onSubmit(data) {
    const body = {
      user: {
        password: data.password,
        password_confirmation: data.password_confirmation,
        first_name: data.firstName,
        last_name: data.lastName,
        invitation_token: params.get('invitation_token'),
      },
    };
    apiClient.put('/users/invitation', body)
      .then(({ data }) => {
        if (data.errors) {
          window.toastr.error(data.errors);
        } else {
          login(data.token);
          window.location.href = '/';
          window.toastr.success(data.message);
        }
      });
  }

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Complete field to proceed!'),
    lastName: yup.string().required('Complete field to proceed!'),
    password: yup.string()
      .required()
      .minUppercase(1)
      .minLowercase(1)
      .minNumbers(1)
      .minSymbols(1)
      .min(8),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], '').required(''),
    agreed: yup.boolean().oneOf([true], 'Review and accept to proceed!'),
  });

  const {
    register, handleSubmit, getValues, setValue, watch, formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    const queryObj = { invitation_token: params.get('invitation_token') };
    apiClient('/users/invitation', { params: queryObj })
      .then(({ data }) => {
        setLoading(false);
        setValue('firstName', data.firstName);
        setValue('lastName', data.lastName);
      }).catch(() => {
        toastr.info('Your account was already activated, please try signing in');
        navigate('/');
      });
  }, []);

  if (loading) return <LoadingPanel />;

  return (
    <div>
      <h1 className="auth__header"> Set up your password </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group auth__form-group">
          <label className="auth__label">
            First name
            <span className="red"> *</span>
            <input
              type="text"
              {...register('firstName')}
              className={cn('form-control auth__input', { 'is-invalid': errors.firstName })}
              placeholder="Enter first name"
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </label>
        </div>
        <div className="form-group auth__form-group">
          <label className="auth__label">
            Last name
            <span className="red"> *</span>
            <input
              className={cn('form-control auth__input', { 'is-invalid': errors.lastName })}
              {...register('lastName')}
              type="text"
              placeholder="Enter last name"
            />
            <div className="invalid-feedback">{errors.lastName?.message}</div>
          </label>
        </div>
        {/* by default chrome adding email/password autocomplete for the first password field */}
        {/* and for the previous field (which is last name) */}
        {/* so this hidden field will prevent suggesting autocompletion for the last name */}
        <input type="text" style={{ position: 'absolute', top: -1000 }} />
        <PasswordFields errors={errors} watch={watch} register={register} getValues={getValues} />
        <div className="custom-control checkbox pl-0 mb-[2rem]">
          <input
            className={cn('custom-checkbox', { 'is-invalid': errors.agreed })}
            id="disclaimer"
            type="checkbox"
            {...register('agreed')}
          />
          <label htmlFor="disclaimer" className="flex items-center gap-tw-2">
            <div className="auth__disclaimer">
              I agree to the
              {' '}
              <a
                href="https://symmetre.com/terms-of-service"
                target="_blank"
                rel="noreferrer"
                className="sre-link"
              >
                Terms of Service
              </a>
              {' and '}
              <a
                href="https://symmetre.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="sre-link"
              >
                Privacy Policy
              </a>
            </div>
          </label>
          <div className="invalid-feedback">{errors.agreed?.message}</div>
        </div>
        <Button
          variant="success"
          type="submit"
          size="l"
          className="mb-tw-4 w-full"
        >
          Let's Get Started!
        </Button>
      </form>
    </div>
  );
};

export default Registration;

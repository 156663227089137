import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import pluralize from 'pluralize';

interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
  count: number;
  word: string;
}

export const ItemTableCounter = ({
  count,
  word,
  className,
  ...props
}: Props) => (
  <div className={cn('label-regular uppercase', className)} {...props}>
    Shown <span className="text-neutral-900"> {count} </span>{' '}
    {pluralize(word, count)}
  </div>
);

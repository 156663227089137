import {
  EditableReportManualVariable,
  ReportManualVariable,
  usePutApiReportManualVariablesByIdMutation,
} from '@/entities/report/manualVariable';
import { isTagNew } from 'bundles/Shared/entities/tag';

export const editReportManualVariable = () => {
  const [edit, options] = usePutApiReportManualVariablesByIdMutation();
  return [
    (
      args: Omit<EditableReportManualVariable, 'reference'> &
        Pick<ReportManualVariable, 'id'>,
    ) =>
      edit({
        id: args.id,
        body: {
          label: args.name,
          period_aggregation_strategy: args.periodAggregationStrategy,
          value_type: args.valueType,
          description: args.description,
          tags:
            args.tags?.map((tag) => ({
              name: isTagNew(tag) ? tag.name : undefined,
              id: isTagNew(tag) ? undefined : tag.id,
            })) ?? [],
        },
      }),
    options,
  ] as const;
};

import React from 'react';
import { Icon } from '@/stories';
import { BoardModal } from 'bundles/Shared/features/dashboard/boardCRUD/ui/BoardModal';
import useBoolean from '@/shared/lib/hooks/useBoolean';

export function CreateBoardButton() {
  const { value: isModalOpen, toggle: toggleModal } = useBoolean();
  return (
    <>
      <Icon onClick={() => toggleModal()} iconName="addSmall" />
      {isModalOpen && (
        <BoardModal
          board={null}
          onClose={() => {
            toggleModal();
          }}
        />
      )}
    </>
  );
}

import React, { FC } from 'react';
import pluralize from 'pluralize';

interface IResetSearchPayAppProps {
  countPayApp?: number;
  onSubmit?: () => void;
  sPayApps: any[];
}

const ResetSearchPayApp: FC<IResetSearchPayAppProps> = ({
  countPayApp = 0,
  onSubmit,
  sPayApps,
}) => {
  const diffPayApp = countPayApp - sPayApps.length;
  return (
    <div className="flex justify-center">
      <div className="blue link-payapp mr-1" onClick={onSubmit}>
        Reset search
      </div>
      <div>
        to see {countPayApp > sPayApps.length ? diffPayApp : countPayApp} pay{' '}
        {pluralize('app', countPayApp || diffPayApp)}
      </div>
    </div>
  );
};

export default ResetSearchPayApp;

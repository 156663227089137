/* eslint-disable import/prefer-default-export */
import * as d3 from 'd3';
import { DatumNode } from './TreeView';

function linkDotAttrs(
  selection:
    | d3.Selection<
        SVGCircleElement,
        d3.HierarchyLink<DatumNode>,
        SVGGElement,
        unknown
      >
    | d3.Transition<
        d3.BaseType,
        d3.HierarchyLink<DatumNode>,
        SVGGElement,
        unknown
      >,
  {
    radius,
    gap,
    position,
  }: {
    radius: number;
    position: 'start' | 'end';
    gap: number;
  },
) {
  selection
    .attr('data-circle', position)
    .attr('r', radius)
    .attr('fill', 'var(--light-40)')
    .attr('stroke-width', 0)
    .attr('cx', (d) =>
      position === 'end'
        ? d.target.y - gap
        : d.source.y + d.source.nodeBox.width + gap,
    )
    .attr('cy', (d) => (position === 'end' ? d.target.x : d.source.x));
}

export function Link(
  link: d3.Selection<
    d3.BaseType,
    d3.HierarchyLink<DatumNode>,
    SVGGElement,
    unknown
  >,
  {
    source,
  }: {
    source: d3.HierarchyLink<DatumNode>;
  },
) {
  const diagonal = d3
    .linkHorizontal()
    .x((d) => d.y)
    .y((d) => d.x);

  const GAP_BETWEEN_NODE_AND_LINK = 10;
  const LINK_DOT_RADIUS = 2;
  const endDotArgs = {
    gap: GAP_BETWEEN_NODE_AND_LINK,
    radius: LINK_DOT_RADIUS,
    position: 'end',
  };
  const startDotArgs = {
    gap: GAP_BETWEEN_NODE_AND_LINK,
    radius: LINK_DOT_RADIUS,
    position: 'start',
  };

  const generateStartD = (d) => {
    const o = { y: source.x0, x: source.y0 };
    return diagonal({ source: o, target: o });
  };

  const generateEndD = (d) =>
    diagonal({
      // link gap
      source: {
        ...d.source,
        y: d.source.y + d.source.nodeBox.width + GAP_BETWEEN_NODE_AND_LINK,
      },
      target: {
        ...d.target,
        y: d.target.y - GAP_BETWEEN_NODE_AND_LINK,
      },
    });

  const resolveStrokeColor = (d: d3.HierarchyLink<DatumNode>): string => {
    if (d.source._selectedFromChild && d.target.selected) {
      return 'var(--bl)';
    }

    return '#DFE3E4';
  };
  link.join(
    (enter) => {
      const linkGroup = enter
        .append('g')
        .attr('id', (d) => `path${d.source.id}-${d.target.id}`)
        .attr('stroke', resolveStrokeColor);

      linkGroup
        .append('path')
        .attr('d', generateStartD)
        .attr('d', generateEndD);
      linkGroup.append('circle').call(linkDotAttrs, startDotArgs);

      linkGroup.append('circle').call(linkDotAttrs, endDotArgs);

      return linkGroup;
    },
    (update) => {
      update
        .select('path')
        .attr('stroke', resolveStrokeColor)
        .attr('d', generateEndD);

      update.select('circle[data-circle="end"').call(linkDotAttrs, endDotArgs);

      update
        .select('circle[data-circle="start"')
        .call(linkDotAttrs, startDotArgs);

      return undefined;
    },

    (exit) => {
      exit.remove().select('path').attr('d', generateStartD);
    },
  );
}

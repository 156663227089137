import React, { useEffect, useMemo, useState } from 'react';
import SortableTree, {
  ExtendedNodeData,
  ReactSortableTreeProps,
  TreeItem,
} from 'react-sortable-tree';
import TreeNodeRenderer from 'stories/TreeFolderNavigation/TreeNodeRenderer';
import TreeFolderItem, {
  AdditionalProps as TreeFolderItemProps,
} from './TreeFolderItem';

interface Props<T>
  extends Pick<ReactSortableTreeProps<T>, 'treeData' | 'placeholderRenderer'> {
  selected?: T;
  onSelectedChange?: (node: T) => void;
  current?: T | null;
  additionalNodeProps?: (
    node: TreeItem<T>,
    isSearchMatch: boolean,
  ) => {
    actions?: React.ReactNode;
    isClickable?: boolean;
    isDisabled?: boolean;
    classes?: {
      radio?: string;
      item?: string;
      focus?: string;
    };
  };
  onNodeClick?: (node: TreeItem<T>) => void;
  rootNode?: {
    title: string;
    hasCheckbox?: boolean;
  };
  onChange?: ReactSortableTreeProps<T>['onChange'];
  disableIcons?: boolean;
  searchQuery?: string | null;
  searchMethod?: ReactSortableTreeProps['searchMethod'];
  onlyLastIsSelectable?: boolean;
}

export const FAKE_ROOT_NODE_ID = 'FAKE_ROOT_NODE_ID';

export function TreeFolderNavigation<T extends { id: number | string } = any>({
  selected,
  current,
  onSelectedChange,
  onNodeClick,
  additionalNodeProps,
  treeData,
  rootNode,
  onChange,
  disableIcons = false,
  onlyLastIsSelectable = false,
  ...props
}: Props<T>) {
  const [localTreeData, setLocalTreeData] = useState<TreeItem<T>[]>([]);
  const isStateControlled = onChange != null;
  const hasRoot = rootNode != null;
  const treeDataWithRoot = useMemo<TreeItem<T>[]>(() => {
    const data = isStateControlled ? treeData : localTreeData;
    return hasRoot && data.length > 0
      ? [
          {
            id: FAKE_ROOT_NODE_ID as string,
            expanded: true,
            title: rootNode.title,
            children: data,
          } as TreeItem<T>,
        ]
      : data;
  }, [treeData, localTreeData]);

  useEffect(() => {
    if (!isStateControlled) {
      setLocalTreeData(treeData);
    }
  }, [treeData]);

  const generateNodeProps = (
    data: ExtendedNodeData<T>,
  ): TreeFolderItemProps<T> => ({
    ...data,
    selectable:
      data.node.id === FAKE_ROOT_NODE_ID
        ? rootNode?.hasCheckbox
        : !data.node.internal &&
          onSelectedChange != null &&
          !(onlyLastIsSelectable && data.node.children?.length > 0),
    selected: data.node?.id === selected?.id,
    current:
      data.node?.id === current?.id ||
      (current == null && data.node.id === FAKE_ROOT_NODE_ID),
    onNodeClick,
    disableIcons,
    onSelectedChange,
    ...additionalNodeProps?.(data.node, data.isSearchMatch),
  });

  const handleChange = (newTreeData: TreeItem<T>[]) => {
    const changeHandler = isStateControlled ? onChange : setLocalTreeData;
    const withoutFakeRoot = hasRoot ? newTreeData[0].children : newTreeData;

    changeHandler(withoutFakeRoot);
  };

  return (
    <SortableTree
      className="w-full"
      canDrag={false}
      isVirtualized={false}
      generateNodeProps={generateNodeProps}
      theme={{
        nodeContentRenderer: TreeFolderItem,
        treeNodeRenderer: TreeNodeRenderer,
      }}
      rowHeight={24}
      treeData={treeDataWithRoot}
      onChange={handleChange}
      {...props}
    />
  );
}

export default TreeFolderNavigation;

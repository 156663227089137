import React, { useEffect, useState } from 'react';
import { OverlaySpinner } from 'stories';
import http from '../../../lib/http';
import AdviseeCard from './AdviseeCard';
import TablePagination from '../../Shared/components/Table/pagination/TablePagination';
import TableSearch from '../../Shared/components/Table/TableSearch';

const AdviseeCardList = ({ advisorId }) => {
  const [advisees, setAdvisees] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);

  const fetchAdvisees = () => http.get(`/advisees?advisor_id=${advisorId}&search_query=${searchQuery}`)
    .then((res) => res.json());

  useEffect(() => {
    setLoading(true);
    fetchAdvisees()
      .then((adviseesJson) => {
        setAdvisees(adviseesJson);
        setTotalSize(adviseesJson.length);
        setLoading(false);
      });
  }, [searchQuery]);

  return (
    <>
      <div className="flex flex-wrap items-center justify-between mt-tw-4 advisee-card__list-header">
        {totalSize === 0 && <p className="light-60">No advisees found</p>}
        {totalSize > 0 && <h6 className="light-90 d-block d-md-none">All Advisees</h6>}
        <TablePagination
          currentPage={currentPage}
          loading={loading}
          totalSize={totalSize}
          sizePerPage={totalSize}
        />
        <div className="flex-break d-md-none" />
        {/* DEPRECATED: FE-2167 use SearchInput */}
        <TableSearch
          setSearchQuery={setSearchQuery}
          setCurrentPage={setCurrentPage}
        />
      </div>
      { loading
        ? <OverlaySpinner />
        : (advisees.map((advisee) => <AdviseeCard {...advisee} />))}
    </>
  );
};

export default AdviseeCardList;

import React, { useMemo } from 'react';
import { sum } from 'lodash-es';
import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from 'types/Props';
import styles from './StatisticLine.module.scss';

export type TStatisticEntry = {
  value: number;
  color?: CssVar | string;
  hoverColor?: string;
};

export type StatisticsRecord = Record<string, TStatisticEntry>;

interface Props extends ClassNameProps {
  statistics: StatisticsRecord | TStatisticEntry[];
  hovered?: boolean;
}

export function StatisticLine({ statistics, hovered, className }: Props) {
  const total = useMemo(
    () => sum(Object.values(statistics).map((s) => s.value)),
    [statistics],
  );

  return (
    <div className={cn(styles.ratioChart, className)}>
      {Object.keys(statistics)
        .filter((key) => statistics[key].value > 0)
        .map((key) => (
          <div
            key={key}
            style={{
              backgroundColor: hovered
                ? statistics[key].hoverColor
                : statistics[key].color ?? CssVar.light20,
              width: `${(100.0 * statistics[key].value) / total}%`,
            }}
          />
        ))}
    </div>
  );
}

export default StatisticLine;

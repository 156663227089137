import {
  PutApiSettingsReportDataPeriodBatchesBulkApiResponse,
  reportDataPeriodsApi,
} from 'bundles/Settings/actions/report/dataPeriodsApi';

const PERIOD_BATCH_TAG = 'ReportDataPeriodBatch';
const PERIOD_BATCH_GROUP_TAG = 'ReportDataPeriodBatchGroup';

reportDataPeriodsApi.enhanceEndpoints({
  addTagTypes: [PERIOD_BATCH_TAG, PERIOD_BATCH_GROUP_TAG],
  endpoints: {
    getApiSettingsReportDataPeriodBatchGroups: {
      providesTags: [PERIOD_BATCH_GROUP_TAG],
    },
    getApiSettingsReportDataPeriodBatches: {
      providesTags: [PERIOD_BATCH_TAG],
    },
    putApiSettingsReportDataPeriodBatchesBulk: {
      invalidatesTags: [PERIOD_BATCH_TAG, PERIOD_BATCH_GROUP_TAG],
      transformResponse: (
        response: PutApiSettingsReportDataPeriodBatchesBulkApiResponse,
      ) => {
        toastr.success('Data Periods have been updated');
        return response;
      },
    },
    putApiSettingsReportDataPeriodYearsByYearPublish: {
      invalidatesTags: [PERIOD_BATCH_TAG, PERIOD_BATCH_GROUP_TAG],
      transformResponse: (
        response: PutApiSettingsReportDataPeriodBatchesBulkApiResponse,
      ) => {
        toastr.success('Year has been published');
        return response;
      },
    },
  },
});

export const {
  useGetApiSettingsReportDataPeriodBatchGroupsQuery,
  useGetApiSettingsReportDataPeriodBatchesQuery,
  usePutApiSettingsReportDataPeriodBatchesBulkMutation,
  useGetApiSettingsReportDataPeriodBatchesLegalEntitiablesQuery,
  usePutApiSettingsReportDataPeriodYearsByYearPublishMutation,
} = reportDataPeriodsApi;

import Table from 'bundles/Shared/components/Table/Table';
import {
  IColumn,
  IRowBase,
  ITableProps,
} from 'bundles/Shared/components/Table/types';
import styles from './CapitaInvestmentEditor.module.scss';

interface Props<T extends IRowBase>
  extends Omit<ITableProps<T>, 'items' | 'columns'> {
  items: T[];
  columns: IColumn<T>[];
}

export function ReturnRawTableEditor<T extends IRowBase>({
  items,
  columns,
  ...props
}: Props<T>) {
  return (
    <Table
      borderLessOutside
      classes={{
        table: styles.table,
        container: styles.container,
      }}
      defaultColumn={{
        headerClasses: 'bg-white pt-s light-60',
        classes: '!pt-tw-2 !px-tw-4 !pb-tw-3',
      }}
      items={items}
      columns={columns}
      {...props}
    />
  );
}

import React from 'react';
import { Dropdown, DropdownItem, IconButton } from 'stories';
import { IconsId } from 'types/sre-icons';

interface Props {
  actions: {
    label: string;
    icon: IconsId;
    handler: () => void;
  }[];
}

export function ActionsCell({ actions }: Props) {
  return (
    <Dropdown
      items={
        <div className="sre-dropdown-v2">
          {actions.map((action) => (
            <DropdownItem
              key={action.label}
              iconName={action.icon}
              onClick={action.handler}
            >
              {action.label}
            </DropdownItem>
          ))}
        </div>
      }
    >
      <IconButton iconName="more" />
    </Dropdown>
  );
}

export default ActionsCell;

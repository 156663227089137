import { Img } from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import { Icon } from 'stories/index';

export default function CapitalInvestmentImage({
  pictureUrl,
}: {
  pictureUrl?: string | null;
}) {
  return (
    <div className="border-1 flex h-[56px] w-[56px] items-center rounded-lg border-solid border-neutral-100 p-tw-1">
      <div className="flex h-full w-full items-center justify-center rounded-[4px] bg-neutral-200">
        <Img
          fallbackNode={
            <Icon
              iconName="asset"
              className="header4-regular text-neutral-450"
            />
          }
          alt="investment object"
          src={pictureUrl ?? undefined}
          className="h-full w-full rounded-[4px] bg-neutral-200 object-cover"
        />
      </div>
    </div>
  );
}

import * as React from 'react';
import { IFolder, ISharedDocument } from 'types/SharedDocument';
import FileExtensionIcon from '../../../../../../Shared/components/fileExtensionIcon/FileExtensionIcon';
import FileInfo from '../../../FileInfo';
import DocumentLink from '../../fieldRenderers/DocumentLink';
import DocumentType from '../../fieldRenderers/DocumentType';
import UploadDate from '../../fieldRenderers/UploadDate';
import DownloadButton from '../../buttons/DownloadButton';
import ExtendedDetailsPopover from './ExtendedDetailsPopover';
import DocumentableAndFolder from '../../fieldRenderers/DocumentableAndFolder';

export interface Actions {
  handleFolderClick: (folder: IFolder) => void;
}

interface Props {
  row: ISharedDocument;
  actions: Actions;
}

export const FileLink = ({ row }: { row: ISharedDocument }) => (
  <div>
    <DocumentLink row={row}>{row.title}</DocumentLink>
  </div>
);

function ExtendedDetails({ row, actions }: Props) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col items-start gap-tw-2 lg:flex-row">
        <FileExtensionIcon className="bg-light-10" extension={row.extension} />
        <div>
          <FileLink row={row} />
          <span className="hidden lg:block">
            <FileInfo
              size={row.size}
              extension={row.extension.toLocaleUpperCase()}
            />
          </span>
        </div>
        <div className="flex flex-col gap-tw-4 lg:hidden">
          <div className="flex flex-col gap-tw-2">
            <DocumentType row={row} mobile />
            <UploadDate row={row} mobile />
          </div>
          <DocumentableAndFolder row={row} />
          <DownloadButton row={row} />
        </div>
      </div>
      <ExtendedDetailsPopover row={row} actions={actions} />
    </div>
  );
}

export default ExtendedDetails;

import { CssVar } from '@/shared/config/cssVar';

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? CssVar.blue : CssVar.light60,
    borderWidth: '2px',
    backgroundColor: CssVar.light,
    height: '37px',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 12px',
    paddingBottom: '0',
    paddingTop: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: CssVar.light60,
    fontSize: '0.875rem',
    fontWeight: '400',
  }),
};

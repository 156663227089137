import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Tab, TabItem } from 'stories';
import { Link } from '@reach/router';
import styles from './ObjectsSettingsNavigationSection.module.scss';

interface Props {
  title: string;
  tabs: ({
    title: string;
    active?: boolean;
  } & Pick<React.ComponentProps<typeof Link>, 'onClick' | 'replace'> & {
      link?: string;
    })[];
}

export const ObjectsSettingsNavigationSection = ({ title, tabs }: Props) => (
  <div className={styles.container}>
    <div className={cn(styles.title, 'inline-semibold')}>{title}</div>
    <Tab>
      {tabs.map((tab) => (
        <Link
          key={tab.title}
          to={tab.link || ''}
          onClick={tab.onClick}
          replace={tab.replace}
        >
          <TabItem
            className={cn(styles.tabItem, {
              [styles.tabItemActive]: tab.active,
            })}
            variant="overline"
            active={tab.active}
          >
            {tab.title}
          </TabItem>
        </Link>
      ))}
    </Tab>
  </div>
);

export default ObjectsSettingsNavigationSection;

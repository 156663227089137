import React from 'react';
import { Button, IconButton } from 'stories';
import { Link } from '@reach/router';

const TemplateFormHeader = ({
  title,
  onSave,
  onSend = undefined,
  isDefault,
  removeTemplate,
  afterRemoveRedirectPath,
  saveButtonEnabled,
}) => {
  const handleDelete = () => {
    removeTemplate()
      .then((templateJson) => {
        if (templateJson.errors === undefined) {
          window.location.replace(afterRemoveRedirectPath);
        }
      });
  };

  return (
    <div className="flex justify-between bg-light email-template-form__header">
      <div className="items-center d-none d-mflex">
        <Link to={afterRemoveRedirectPath || '../../'}>
          <IconButton iconName="arrowLeft" />
        </Link>
        <h6 className="header6-regular font-weight-500 text-[1rem] dark-60 ml-[0.5rem]">{title}</h6>
        {!isDefault && removeTemplate && (
          <IconButton
            className="ml-[0.5rem]"
            iconName="trash"
            onClick={handleDelete}
          />
        )}
      </div>
      <div>
        {onSend && (
          <Button
            className="mr-s"
            disabled={!saveButtonEnabled}
            variant="secondary"
            size="m"
            onClick={onSend}
          >
            <span className="inline-semibold font-weight-600">
              Send me an email
            </span>
          </Button>
        )}
        <Button
          disabled={!saveButtonEnabled}
          variant="success"
          size="m"
          onClick={onSave}
        >
          <span className="inline-semibold font-weight-600">
            Save
          </span>
        </Button>
      </div>
    </div>
  );
};

export default TemplateFormHeader;

import { ETL_MAIL_DOMAIN } from 'bundles/Shared/entities/connection/config';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { ERPSystem } from 'bundles/Shared/entities/erpsystem/model';

const ERP_SYSTEMS_WITH_DOT_DELIMITER: ERPSystem[] = ['app_folio', 'entrata'];
export const isErpSystemWithDotDelimiter = (erpSystem?: ERPSystem) =>
  erpSystem && ERP_SYSTEMS_WITH_DOT_DELIMITER.includes(erpSystem);

export const buildConnectionAddress = (
  postfix: string,
  erpSystem?: ERPSystem,
) => {
  const CUSTOMER_DOMAIN: string = window.symmetreConfig.customer.subdomain;
  const DELIMITER: string = isErpSystemWithDotDelimiter(erpSystem) ? '.' : '+';
  return `${CUSTOMER_DOMAIN}${DELIMITER}${postfix}@${ETL_MAIL_DOMAIN}`;
};

export const generateConnectionEmailPostfix = () => {
  const postfix = Math.random().toString(36).slice(2, 10);
  return postfix.toLowerCase();
};

export const transformPostfixUserValue = (
  value: string,
  erpSystem?: ERPSystem,
) => (isErpSystemWithDotDelimiter(erpSystem) ? value : value.toUpperCase());

export const extractConnectionEmailPostfix = (email?: string | null) =>
  email?.split(/[+.@]/)[1];

export function isEmailExtractions(connection?: IConnection): boolean {
  return connection?.type === 'email';
}

export function isSftpExtractions(connection?: IConnection): boolean {
  return connection?.type === 'sftp';
}
export function isApiExtractions(
  connection?: IConnection,
): connection is IConnection & { type: 'api' } {
  return connection?.type === 'api';
}

export function hasMemo(connection?: IConnection): boolean {
  return Boolean(connection?.memo);
}

export const hasCompanyInfo = (connectionItem?: IConnection) =>
  connectionItem?.company.keyInfo ?? connectionItem?.company.contactPerson;

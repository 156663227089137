import React, { FC, useState } from 'react';
import { IconButton, ConfirmationModal } from 'stories';
import { useDeleteDrawMutation } from 'bundles/Construction/api/invoices';
import { DrawRequest } from '@/shared/types/reconcile/DrawRequest';

interface IActionCellProps {
  drawRequest: DrawRequest;
}

const ActionsCell: FC<IActionCellProps> = ({ drawRequest }) => {
  const [deleteDraw] = useDeleteDrawMutation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const destroyHandle = (status: boolean) => {
    if (status) {
      deleteDraw({
        drawId: drawRequest.id,
      });
    } else {
      setShowConfirmModal(false);
    }
  };

  return (
    <div className="flex justify-center gap-tw-2">
      <IconButton
        iconName="trash"
        variant="white"
        onClick={(e) => {
          e.stopPropagation();
          setShowConfirmModal(true);
        }}
      />
      {showConfirmModal && (
        <ConfirmationModal
          title="Delete Draw"
          subtitle={<div>Are you sure you want to delete this Draw?</div>}
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={destroyHandle}
        />
      )}
    </div>
  );
};

export default ActionsCell;

import { PostApiReconcileOperationalBudgetsApiResponse, PostApiReconcileOperationalBudgetsBulkApiResponse, reconcileOperationalGeneratedApi } from "@/bundles/REconcile/components/operational/api/reconcileOperationalGeneratedApi";

export const reconcileOperationalEnhancedApi = reconcileOperationalGeneratedApi.enhanceEndpoints({
  addTagTypes: [
    'budgetYears',
    'budget',
    'budgetLegalEntities',
  ],
  endpoints: {
    getApiReconcileOperationalBudgetsById: {
      providesTags: ['budget'],
    },
    getApiReconcileOperationalBudgetsYears: {
      providesTags: ['budgetYears']
    },
    getApiReconcileOperationalBudgetsYearsByYearLegalEntities: {
      providesTags: ['budgetLegalEntities'],
    },
    postApiReconcileOperationalBudgetsBulk: {
      invalidatesTags: ['budgetYears', 'budget', 'budgetLegalEntities'],
      transformResponse: (r: PostApiReconcileOperationalBudgetsBulkApiResponse) => {
        toastr.success('Budgets has been created successfully');
        return r;
      },
    },
    postApiReconcileOperationalBudgets: {
      invalidatesTags: ['budgetYears', 'budget', 'budgetLegalEntities'],
      transformResponse: (r: PostApiReconcileOperationalBudgetsApiResponse) => {
        toastr.success('Budget has been created successfully');
        return r;
      },
    },
    deleteApiReconcileOperationalBudgetsById: {
      invalidatesTags: ['budgetYears', 'budget', 'budgetLegalEntities'],
      transformResponse: (r) => {
        toastr.success('Budget has been deleted')
        return r;
      }
    }
  }
})

export const {
  useGetApiReconcileOperationalBudgetsByIdQuery,
  useGetApiReconcileOperationalBudgetsYearsByYearLegalEntitiesQuery,
  useGetApiReconcileOperationalBudgetsYearsQuery,

  useDeleteApiReconcileOperationalBudgetsByIdMutation,

  usePostApiReconcileOperationalBudgetsMutation,
  usePostApiReconcileOperationalBudgetsBulkMutation,
} = reconcileOperationalEnhancedApi;

import { ColumnState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect } from 'react';

export const useAgGridApplyColumnStateEffect = ({
  grid,
  columnState,
}: {
  grid: AgGridReact | null;
  columnState: ColumnState[] | undefined;
}) => {
  useEffect(() => {
    const applyState = () => {
      grid?.columnApi?.applyColumnState({
        state: columnState,
      });
    };
    applyState();
  });
};

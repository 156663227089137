import { ComponentProps } from 'react';
import { CurrencyFormatter, Icon, Popover } from 'stories/index';

export function AutofillWrapper({
  children,
  amountToFill,
  onClick,
  ...popoverProps
}: {
  onClick: () => void;
  amountToFill: number;
} & Omit<ComponentProps<typeof Popover>, 'template'>) {
  return (
    <Popover
      placement="bottom-start"
      className="cursor-pointer p-0 hover:bg-light-20"
      trigger="click"
      spanContainerTabIndex={-1}
      hiddenArrow
      hideOnExpandedAreaClick
      template={
        <button
          onClick={onClick}
          className="flex flex-col border-0 bg-transparent px-tw-4 py-tw-2"
        >
          <div className="flex gap-tw-2">
            <Icon iconName="magic" />
            <CurrencyFormatter
              classes={{
                value: 'inline-semibold text-dark-60',
              }}
              value={amountToFill}
            />
          </div>
          <span className="secondary-regular text-light-60">
            Autofill amount
          </span>
        </button>
      }
      {...popoverProps}
    >
      {children}
    </Popover>
  );
}

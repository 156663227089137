/* eslint-disable react/jsx-wrap-multilines */
import { DistributionKind, ReportLineItem } from 'bundles/REturn/actions/types';
import { ReturnRawTableEditor } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/ReturnRawTableEditor';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useItems } from '@/shared/lib/hooks/items/useItems';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { uniqueId } from 'lodash-es';
import {
  Button,
  CurrencyFormatter,
  Modal,
  ModalHeaderWithIcon,
} from 'stories/index';
import { useDistributionSplitColumns } from '../hooks/columns/useDistributionSplitColumns';
import { EntyTxWithReviewInfo } from '../types';
import { hasNotNulls } from '../utils';
import { splitLinkingReviewEntryTx } from '../utils/goForward/splitLinking';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import {
  AllocationContext,
  useAllocation,
} from 'bundles/REconcile/components/AllocationProvider';
import { useCallback } from 'react';

import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props extends DialogProps<EntyTxWithReviewInfo[]> {
  transaction: ReportLineItem;
  capitalInvestments: ICapitalInvestment[];
}

export type EditableDistribution = {
  id: string;
  capitalInvestmentId: ICapitalInvestment['id'] | undefined;
  investmentEntity:
    | (Pick<ICapitalInvestment['investmentEntity'], 'id' | 'name'> & {
        legalEntityName: ICapitalInvestment['legalEntity']['name'];
        totalAmount: RawFloat;
      })
    | undefined;
  amount: number;
  kind: DistributionKind | undefined;
  disableInvestmentEntityChange?: boolean;
  disableRemove?: boolean;
};

const SplitDistributionTable = ({
  onRemoveItemById,
  capitalInvestments,
  onChangeItemById,
  editableDistributions,
}: {
  onRemoveItemById: (id: string) => void;
  capitalInvestments: ICapitalInvestment[];
  onChangeItemById: (id: string, item: Partial<EditableDistribution>) => void;
  editableDistributions: EditableDistribution[];
}) => {
  const columns = useDistributionSplitColumns({
    capitalInvestments,
    actions: {
      onRemove: onRemoveItemById,
      onChangeItemById,
    },
  });

  return (
    <ReturnRawTableEditor columns={columns} items={editableDistributions} />
  );
};

export function SplitDistribution({
  transaction,
  capitalInvestments,
  onClose,
  onSubmit,
}: Props) {
  const [
    editableDistributions,
    _,
    { onChangeItemById, onRemoveItemById, onAddItem },
  ] = useItems<EditableDistribution>(
    capitalInvestments.map(
      ({ investmentEntity, legalEntity, ...capitalInvestment }) => ({
        id: uniqueId(),
        capitalInvestmentId: capitalInvestment.id,
        investmentEntity: {
          id: investmentEntity.id,
          name: investmentEntity.name,
          totalAmount: capitalInvestment.totalDistributions,
          legalEntityName: legalEntity.name,
        },
        amount: 0,
        kind: undefined,
        disableRemove: true,
        disableInvestmentEntityChange: true,
      }),
    ),
  );
  const sumMapper = useCallback(
    (item: EditableDistribution) => item.amount ?? 0,
    [],
  );
  const updateItem = useCallback(
    (index: number, amount: number) => {
      onChangeItemById(editableDistributions[index].id, { amount });
    },
    [editableDistributions, onChangeItemById],
  );
  const allocation = useAllocation({
    total: convertCentsToDollars(transaction.amountCents),
    items: editableDistributions,
    sumMapper,
    updateItem,
  });
  const { total, allocatedAmount, allItemsAreAllocated, allocationFlags } =
    allocation;

  const resolveIsSubmitDisabled = () => {
    if (editableDistributions.some((ci) => !ci.kind)) return true;

    if (editableDistributions.some((ci) => !ci.investmentEntity)) return true;

    return !allItemsAreAllocated || !allocationFlags.isFullyAllocated;
  };

  const handleSubmit = () => {
    const entryTxs = splitLinkingReviewEntryTx({
      editableDistributions,
      transaction,
    });

    if (!hasNotNulls(entryTxs)) return;

    onSubmit?.(entryTxs);
  };

  return (
    <Modal
      size="900"
      toggle={onClose}
      header={<ModalHeaderWithIcon title="Split Transaction" />}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={resolveIsSubmitDisabled()}
            type="submit"
            variant="success"
          >
            Split Transaction and Continue
          </Button>
        </>
      }
    >
      <div className="-m-tw-6 flex flex-col">
        <div className="grid grid-cols-[repeat(2,94px)] items-center gap-x-tw-8 gap-y-tw-1.5 border-b border-solid border-light-10 bg-white px-tw-6 py-tw-4">
          <p className="secondary-regular uppercase text-light-60">Amount</p>
          <p className="secondary-regular uppercase text-light-60">Date</p>
          <CurrencyFormatter
            value={convertCentsToDollars(transaction.amountCents)}
          />
          <p className="inline-regular text-dark-60">
            {formatDate(transaction.date, 'MMM DD, YYYY')}
          </p>
        </div>
        <div className="flex flex-col gap-tw-4 bg-light px-tw-6 py-tw-4">
          <AllocationStatistics
            allocatedAmount={allocatedAmount}
            totalAmount={total ?? 0}
          />

          <div className="flex flex-col items-start gap-tw-1.5">
            <AllocationContext.Provider value={allocation}>
              <SplitDistributionTable
                onRemoveItemById={onRemoveItemById}
                onChangeItemById={onChangeItemById}
                capitalInvestments={capitalInvestments}
                editableDistributions={editableDistributions}
              />
            </AllocationContext.Provider>

            {!allocationFlags.isFullyAllocated && (
              <Button
                variant="secondary"
                size="xs"
                onClick={() =>
                  onAddItem({
                    amount: 0,
                    id: uniqueId(),
                    capitalInvestmentId: undefined,
                    investmentEntity: undefined,
                    kind: undefined,
                  })
                }
              >
                Add Category
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React, { FC } from 'react';
import SelectCOItem from './SelectCOItem';
import { IChangeOrder, IChangeOrderEvent } from 'bundles/Construction/types';

interface ISelectCOProps {
  COE: IChangeOrderEvent[];
  formData: IChangeOrder[];
  setFormData: any;
  selectable: boolean;
}

const SelectCO: FC<ISelectCOProps> = ({
  COE,
  formData,
  setFormData,
  selectable,
}) => (
  <div className="select-change-orders">
    {COE.map((item, idx: number) => (
      <SelectCOItem
        key={`coe${idx}`}
        COE={COE}
        formData={formData}
        setFormData={setFormData}
        idx={idx}
        selectable={selectable}
        {...item}
      />
    ))}
  </div>
);

export default SelectCO;

import { apiClient, requireAuthorizationHeaders } from '@/lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from '@/lib/stringify';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { stringify } from 'qs';
export * from './helpers';

export const plainPreviewReportTemplatePdf = ({
  templateId,
  assetId,
  date,
}: {
  assetId: string;
  date: DateString;
  templateId: string;
}) => {
  return apiClient.get<Blob>(
    `/api/settings/report_builder/templates/${templateId}/preview_pdf${stringify(
      {
        date: formatToDateStringForRequest(date),
        asset_id: assetId,
      },
      DEFAULT_STRINGIFY_OPTIONS,
    )}`,
    {
      responseType: 'blob',
      headers: requireAuthorizationHeaders({}),
    },
  );
};

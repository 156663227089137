import OwnershipsCards from '@/widgets/return/ui/OwnershipsCards';
import { RouteComponentProps } from '@reach/router';
import OwnershipTable from 'bundles/REturn/components/Ownership/ownershipTable/OwnershipTable';
import OwnershipsHeader from 'bundles/REturn/components/Ownership/ownershipsHeader/OwnershipsHeader';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { FC } from 'react';
import OwnershipBreadcrumbs from './OwnershipBreadcrumbs';

const Ownerships: FC<
  RouteComponentProps<ExtractParams<typeof ROUTES_ROOT.return.object.fullPath>>
> = () => (
  <>
    <div className="flex gap-tw-5 bg-white px-tw-6 py-tw-4">
      <OwnershipBreadcrumbs />
      <OwnershipsHeader />
    </div>
    <OwnershipsCards />
    <OwnershipTable />
  </>
);

export default Ownerships;

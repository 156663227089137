export const MESSAGE_NAME = {
  unsubscribed: 'Unsubscribed',
  complained: 'Marked as spam',
} as const;

export const MESSAGE_STATUS = {
  delivered: 'Delivered',
  opened: 'Opened',
  failed: 'Failed',
  sent: 'Sent',
} as const;

import {
  FetchPermissionedUsersWithMetaResponse,
  LightUser,
} from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal/types';
import http from 'lib/http';
import { IAsset } from 'types/Asset';

export async function fetchAssetPermissionedUsers({
  assetId,
}: {
  assetId: IAsset['id'];
}) {
  const res = await http.get(`/assets/${assetId}/permissions`);
  const data = await res.json();

  return data as FetchPermissionedUsersWithMetaResponse<LightUser>;
}

import React from 'react';
import { Input } from 'stories';
import { disableStylingKeystrokes } from '@/shared/lib/ckEditor/utils';
import { useModal } from '@/shared/lib/hooks/useModal';
import RecipientsModal from 'bundles/Shared/components/RecipientsModal/RecipientsModal';
import EmailTemplateFormInputGroup from 'bundles/EmailBuilder/components/EmailTemplateFormInputGroup';
import TemplateEditor from '../editor/TemplateEditor';
import * as Type from '../editor/types';
import ReplyRecipientsSection from '../ReplyRecipientsSection';
import {
  confirmResetingReplyRecipients,
  TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS,
} from '../utils/consts';

interface Props {
  template: Type.FeedMessageTemplateState;
  variables: Type.FeedMessageTemplateVars;
  usedVariables: Type.FeedMessageTemplateVars;
  replyRecipients: Type.ReplyRecipient[];
  onTemplateChange: (template: Type.FeedMessageTemplateState) => void;
  onUsedVariablesChange: (variables: Type.FeedMessageTemplateVars) => void;
  isLoading?: boolean;
}

const FeedMessageTemplateForm = ({
  template,
  usedVariables,
  variables,
  replyRecipients,
  onTemplateChange,
  onUsedVariablesChange,
  isLoading,
}: Props) => {
  const { confirm, openModal } = useModal();
  const setTemplateField = <K extends keyof Type.FeedMessageTemplateState>(
    field: K,
    value: Type.FeedMessageTemplateState[K],
  ) => {
    onTemplateChange({
      ...template,
      [field]: value,
    });
  };

  const setVariableField = <K extends keyof Type.FeedMessageTemplateVars>(
    field: K,
    value: Type.FeedMessageTemplateVars[K],
  ) => {
    onUsedVariablesChange({
      ...usedVariables,
      [field]: value,
    });
  };

  const forceEditorHeading = (editor) => {
    editor.execute('heading', { value: 'heading1' });
  };

  const handleGreetingLineEditorReady = (editor) => {
    forceEditorHeading(editor);
    disableStylingKeystrokes(editor);
  };

  const handleSetReplyRecipients = async () => {
    const res = await openModal(RecipientsModal, {
      replyRecipientOptions: replyRecipients,
      initSelectedRecipients: template.replyRecipients ?? [],
    });
    if (!res) return;

    setTemplateField('replyRecipients', res);
  };

  const handleReset = async () => {
    const result = await confirm(confirmResetingReplyRecipients);

    if (!result) return;

    setTemplateField('replyRecipients', []);
  };

  return (
    <form>
      <div className="flex flex-col gap-tw-4">
        <div>
          <EmailTemplateFormInputGroup title="General" isLoading={isLoading}>
            <label>
              <span className="label dark-60 secondary-regular mb-tw-2 flex">
                Email Subject
              </span>
              <Input
                id="subject"
                onChange={(e) => setTemplateField('subject', e.target.value)}
                value={template.subject}
                placeholder="Enter Subject"
              />
            </label>
            <ReplyRecipientsSection
              replyRecipients={template.replyRecipients ?? []}
              onReset={handleReset}
              onSet={handleSetReplyRecipients}
            />
            <span className="secondary-regular mt-tw-2 text-red">
              If the added User does not have access to the asset, they will not
              receive the reply.
            </span>
          </EmailTemplateFormInputGroup>
        </div>
        <div>
          <EmailTemplateFormInputGroup
            title="Template"
            description="In this section you can use special variables to automate filling
              templates, these variables are under fields"
            isLoading={isLoading}
          >
            <div className="label-regular mb-tw-2 text-dark-60">
              Greeting Line<span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Greeting Line"
              text={template.greeting}
              setText={(text) => setTemplateField('greeting', text)}
              variables={variables.greeting}
              usedVariables={usedVariables.greeting}
              setUsedVariables={(newVariables) =>
                setVariableField('greeting', newVariables)
              }
              toolbarItems={TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS}
              onChangeStart={(editor) => forceEditorHeading(editor)}
              onReady={handleGreetingLineEditorReady}
            />
            <div className="label-regular mb-tw-2 mt-tw-4 text-dark-60">
              View Feed Message Button
              <span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Button Text"
              text={template.callToAction}
              setText={(text) => setTemplateField('callToAction', text)}
              variables={variables.callToAction}
              usedVariables={usedVariables.callToAction}
              setUsedVariables={(newVariables) =>
                setVariableField('callToAction', newVariables)
              }
              onReady={(editor) => disableStylingKeystrokes(editor)}
              toolbarItems={['variable']}
            />
            <div className="label-regular mb-tw-2 mt-tw-4 text-dark-60">
              Additional Info
            </div>
            <TemplateEditor
              placeholder="Enter Additional Info"
              text={template.additionalInfo}
              setText={(text) => setTemplateField('additionalInfo', text)}
              variables={variables.additionalInfo}
              usedVariables={usedVariables.additionalInfo}
              setUsedVariables={(newVariables) =>
                setVariableField('additionalInfo', newVariables)
              }
              onReady={(editor) => disableStylingKeystrokes(editor)}
              toolbarItems={TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS}
            />
          </EmailTemplateFormInputGroup>
        </div>
      </div>
    </form>
  );
};

export default FeedMessageTemplateForm;

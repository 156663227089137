import { useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useParams } from '@reach/router';
import { hasCompanyInfo } from 'bundles/Shared/entities/connection/lib';
import { PMCCardLine } from 'bundles/Shared/features/propertyManagementCompany/PMCCardLine';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';
import { PhoneLink } from 'bundles/Shared/shared/PhoneLink';
import PMCModal from 'bundles/Shared/widgets/propertyManagementCompany/PMCModal';
import { IconButton } from 'stories/index';

export const PMCInfoCard = () => {
  const { openModal } = useModal();
  const params = useParams();

  const { data: connectionData } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery({
      id: params.connectionId,
    });

  const connection = connectionData?.item as IConnection | undefined;

  const handleEdit = () => {
    openModal(PMCModal, {
      connectionId: params.connectionId,
    });
  };

  const { keyInfo, contactPerson } = connection?.company ?? {};

  return (
    <div className="flex flex-col gap-tw-4 rounded-2xl bg-tw-white p-tw-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-neutral-800">Company Info</div>
        <IconButton
          iconName={hasCompanyInfo(connection) ? 'editAlt' : 'addSmall'}
          onClick={handleEdit}
        />
      </div>
      {!hasCompanyInfo(connection) && (
        <div className="inline-regular">No information</div>
      )}
      {hasCompanyInfo(connection) && (
        <div className="flex flex-col gap-tw-3">
          <div className="flex flex-col gap-tw-2">
            {keyInfo?.address && (
              <PMCCardLine iconName="location">
                <div className="inline-regular text-neutral-900">
                  {keyInfo?.address}
                </div>
              </PMCCardLine>
            )}
            {keyInfo?.site && (
              <PMCCardLine iconName="globe">
                <a
                  href={keyInfo?.site}
                  className="text-sm font-medium text-info-055"
                >
                  {keyInfo?.site}
                </a>
              </PMCCardLine>
            )}
            {keyInfo?.email && (
              <PMCCardLine iconName="email">
                <EmailLink
                  email={keyInfo?.email}
                  className="text-sm font-medium"
                >
                  {keyInfo?.email}
                </EmailLink>
              </PMCCardLine>
            )}
            {keyInfo?.phone && (
              <PMCCardLine iconName="phone">
                <PhoneLink
                  phone={keyInfo?.phone}
                  className="text-sm font-medium"
                >
                  {keyInfo?.phone}
                </PhoneLink>
              </PMCCardLine>
            )}
          </div>
          <div className="border-b border-solid border-neutral-100" />
          <div className="flex flex-col gap-tw-2">
            {contactPerson?.name && (
              <PMCCardLine iconName="userAlt">
                <div>
                  <div className="inline-regular text-neutral-900">
                    {contactPerson?.name}
                  </div>
                  {contactPerson?.role && (
                    <div className="inline-regular">{contactPerson?.role}</div>
                  )}
                </div>
              </PMCCardLine>
            )}
            {contactPerson?.email && (
              <PMCCardLine iconName="email">
                <EmailLink
                  email={contactPerson?.email}
                  className="text-sm font-medium"
                >
                  {contactPerson?.email}
                </EmailLink>
              </PMCCardLine>
            )}
            {contactPerson?.phone && (
              <PMCCardLine iconName="phone">
                <PhoneLink
                  phone={contactPerson?.phone}
                  className="text-sm font-medium"
                >
                  {contactPerson?.phone}
                </PhoneLink>
              </PMCCardLine>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { ComponentProps } from 'react';
import { Settings } from 'react-slick';
import { ThinTabGroup } from '..';
import * as Types from './types';

import {
  DATE_STRING_FORMAT,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';
import { isSameOrBeforeDate, isSameYear } from '@/shared/lib/date';

export const PeriodSelectorMap = {
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Yearly: 'yearly',
} as const;

export const MIN_YEARS_FOR_SLIDER = 5;
export const MAX_MONTHS_IN_YEAR = 12;
export const MAX_MONTHS_IN_QUARTER = 3;
export const MIN_MONTHS_IN_QUARTER = 1;
export const MAX_YEARS_IN_ROW = 5;
export const DAYS_IN_WEEK = 7;
export const QUARTERS_IN_YEAR = 4;
export const DEFAULT_MONTH_DATE_RANGES = {
  t3: {
    label: 'T3',
    shift: 3,
  },
  t6: {
    label: 'T6',
    shift: 6,
  },
  t12: {
    label: 'T12',
    shift: 12,
  },
} as const satisfies Record<
  string,
  {
    label: string;
    shift: number;
  }
>;

export const EMPTY_WEEK_LENGTH_ARRAY = Array.from({ length: DAYS_IN_WEEK });
export const INFINITE_PERIODS = Array.from({
  length: 20,
}).flatMap((_, index, arr) => {
  const years = index - arr.length / 2;
  const yearForMonths = dayjs().add(years, 'year');

  return Array.from({ length: MAX_MONTHS_IN_YEAR }).map((__, monthIndex) =>
    yearForMonths
      .startOf('year')
      .add(monthIndex, 'month')
      .startOf('month')
      .format(DATE_STRING_FORMAT),
  ) as DateString[];
});

/**
 * generated sorted chronologicaly periods
 * @param years How many year to gerenate. `default` is `5`
 */
export const generatePrevPeriods = (years = 5) =>
  Array.from({
    length: years,
  }).flatMap((_, index, self) => {
    const currentYearIndex = self.length - index - 1;

    const startOfYear = dayjs()
      .subtract(currentYearIndex, 'year')
      .startOf('year');

    let periodsOfYear = Array.from({ length: MAX_MONTHS_IN_YEAR }).map(
      (__, monthIndex) => startOfYear.add(monthIndex, 'month').startOf('month'),
    );

    if (isSameYear(startOfYear.toDate(), new Date())) {
      periodsOfYear = periodsOfYear.filter((period) =>
        isSameOrBeforeDate(period.toDate(), new Date(), 'month'),
      );
    }

    return periodsOfYear.map(formatToDateStringForRequest);
  });

export const PeriodItemType = {
  Mtd: 'mtd',
  Qtd: 'qtd',
  Ytd: 'ytd',
} as const;

export const QUARTER_MAP = {
  q1: {
    monthDigitsList: ['01', '02', '03'],
  },
  q2: {
    monthDigitsList: ['04', '05', '06'],
  },
  q3: {
    monthDigitsList: ['07', '08', '09'],
  },
  q4: {
    monthDigitsList: ['10', '11', '12'],
  },
} as const;

export const QUARTER_MONTH_NUMBER_MAP = {
  1: [1, 2, 3],
  2: [4, 5, 6],
  3: [7, 8, 9],
  4: [10, 11, 12],
};

export const QUARTER_KEY_LIST = Object.keys(
  QUARTER_MAP,
) as Types.QuarterMapKey[];

export const THIN_TABS: ComponentProps<typeof ThinTabGroup>['items'] = [
  {
    id: PeriodItemType.Mtd,
    label: capitalize(PeriodSelectorMap.Monthly),
  },
  {
    id: PeriodItemType.Qtd,
    label: capitalize(PeriodSelectorMap.Quarterly),
  },
  {
    id: PeriodItemType.Ytd,
    label: capitalize(PeriodSelectorMap.Yearly),
  },
];

export const [MONTHLY_THIN_TAB, QUARTERLY_THIN_TAB, YEARLY_THIN_TAB] =
  THIN_TABS;

export const SLIDER_SETTINGS = {
  arrows: false,
  infinite: false,
  draggable: false,
  speed: 500,
  slidesToShow: MIN_YEARS_FOR_SLIDER,
  slidesToScroll: 1,
  className: 'w-[266px]',
} as const satisfies Settings;

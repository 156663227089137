import { Permitted } from 'bundles/Shared/entities/permissions';

export const AccessItemType = {
  funds: 'funds',
  asset: 'asset',
  public: 'public',
  role: 'role',
  tag: 'tags',
  member: 'members',
  entity: 'entities',
} as const;

export const EDIT_PERMISSIONS_MODAL_TAB_ITEMS = [
  {
    id: 'public',
    label: 'All Users',
  },
  {
    id: 'restricted',
    label: 'Restricted',
  },
] as const;

export const EMPTY_PERMISSIONS: Permitted = {
  isPublic: true,
  directUsers: [],
  indirectUsers: [],
  directInvestmentEntities: [],
  directRoles: [],
  directTags: [],
};

import * as React from 'react';

interface Props {
  count: number;
}

function UserCountCell({ count }: Props) {
  return <span className="light-60 inline-regular">{count}</span>;
}

export default UserCountCell;

import type { IReportTableConfig } from 'types/ReportTableConfig';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import http from 'lib/http';

export interface IVisibleReportTableConfigsData {
  reportTableConfigs: IReportTableConfig[];
}

export interface IFinancialsReqBody {
  legal_entity_ids: string[];
  periods: {
    type: string;
    period: string;
  }[];
  show_unpublished: boolean;
}
export type GetPeriodsResponse = {
  items: IPeriodItem['period'][];
  meta: {
    actualAt: UnixTime;
  };
};

export async function exportReportTable(
  data: IFinancialsReqBody & {
    report_table_config_id: IReportTableConfig['id'];
  } & { with_detailed_breakdown: boolean | undefined } & {
    with_subtotals: boolean | undefined;
  } & { with_detailed_report_header: boolean | undefined },

  filename: string,
) {
  const res = await http.post(
    `/report/table_configs/${data.report_table_config_id}/export.xlsx`,
    data,
  );
  const file = await res.blob();
  saveFileOnUserDevice(file, filename);
}

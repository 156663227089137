import React, { FC } from 'react';
import { Modal } from 'stories/index';
import Table from 'bundles/Shared/components/Table/Table';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useGLTableModalColumns } from 'bundles/Shared/widgets/generalLedger/ui/useGLTableModalColumns';
import { IGeneralLedgerOnIndex } from 'types/GeneralLedger';

export interface IGLTableModalParams extends DialogProps {
  glsData: IGeneralLedgerOnIndex[];
}

const GLTableModal: FC<IGLTableModalParams> = ({ glsData, onClose }) => {
  const { columns } = useGLTableModalColumns(onClose);

  return (
    <Modal
      size="800"
      toggle={onClose}
      header="General Ledgers"
      classes={{
        body: 'bg-neutral-100',
      }}
    >
      <div className="flex flex-col gap-tw-4">
        <Table
          columns={columns}
          classes={{
            container: 'table-gen-2',
          }}
          items={glsData}
        />
      </div>
    </Modal>
  );
};

export default GLTableModal;

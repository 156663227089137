import { useMemo } from 'react';
import { sortBy } from 'lodash-es';
import { GetArrayElementType } from 'types/utils';
import { ISortSettings } from 'bundles/Shared/components/Table/types';

type TGetSortKeys<T> = T extends ISortSettings<infer Key> ? Key : unknown;
type TGetSortFunc<Items extends any[]> = (
  item: GetArrayElementType<Items>,
) => any;

// todo replace when sorting in table will be implemented
export function useLocalTableSorting<
  Items extends any[],
  Sort extends ISortSettings<any>,
>(
  items: Items,
  sortSettings: Sort,
  sortMapping: Record<TGetSortKeys<Sort>, TGetSortFunc<Items>>,
) {
  const sortedItems = useMemo(() => {
    if (!sortSettings.sortField) {
      return items;
    }

    const sorted = sortBy(
      items,
      sortMapping[sortSettings.sortField],
    ) as GetArrayElementType<Items>[];

    return sortSettings.sortOrder === 'desc' ? sorted : sorted.reverse();
  }, [items, sortSettings]);

  return sortedItems;
}

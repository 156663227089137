import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Checkbox } from 'stories';
import { IGeneralLedgerOnIndex } from 'types/GeneralLedger';

interface Props extends ICellRendererParams {
  handleChange: (glRow: IGeneralLedgerOnIndex) => void;
  selectedRows: IGeneralLedgerOnIndex[];
}

export const CheckboxCellRenderer = ({
  data,
  selectedRows,
  handleChange,
}: Props) => (
  <Checkbox
    onChange={() => handleChange(data)}
    checked={selectedRows.map((a) => a.id).includes(data.id)}
  />
);

import React from 'react';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import { Popover } from 'stories';
import {
  IFilterComponentProps,
  TFilterModel,
} from 'bundles/Shared/components/Table/types';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';

interface Props
  extends IFilterComponentProps<Record<string, unknown>>,
    React.PropsWithChildren {
  popoverProps?: Omit<
    React.ComponentProps<typeof Popover>,
    'offset' | 'template'
  >;
  buttonProps?: Omit<
    React.ComponentProps<typeof UniversalFilterButton>,
    'clicked' | 'filtered' | 'onClose'
  >;
  isFiltered?: (filterModel: TFilterModel) => boolean;
}

function HeaderQuickFilter({
  setFilterModel,
  filterModel,
  column,
  popoverProps,
  buttonProps,
  children,
  ...props
}: Props) {
  const isFiltered =
    props.isFiltered?.(filterModel) ??
    filterModel[column.dataField] !== undefined;
  return (
    <Popover
      offset={DEFAULT_DROPDOWN_OFFSET}
      hiddenArrow
      trigger="click"
      placement="bottom-end"
      className="p-m"
      maxWidth="none"
      classes={{
        spanContainer: 'ml-s',
      }}
      template={children}
      {...popoverProps}
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={isFiltered}
          onClose={() =>
            setFilterModel((prevModel) => ({
              ...prevModel,
              [column.dataField]: undefined,
            }))
          }
          {...buttonProps}
        />
      )}
    </Popover>
  );
}

export default HeaderQuickFilter;

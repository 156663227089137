import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';
import { useTableWidgetExportFeature } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import {
  useWidgetFullScreen,
  WidgetStateFullScreenIconButton,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useKpiWidgetColumnVisibilityState } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useColumnsVisibilityState';
import { useWidgetTableColumnSize } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useWidgetTableColumnSize';
import { useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { cn } from '@/shared/lib/css/cn';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { ThinTabGroup } from '@/stories';
import { AgGridReact } from 'ag-grid-react';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import {
  ColumSizeWidgetState,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
  WidgetStateColumns,
  WidgetStateDate,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  KPI_TABLE_WIDGET_EXPORT_PARAMS,
  KpiTableGroupingType,
  ROW_HEIGHT,
  useAutoGroupColDef,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableWidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/ui/KpiTableWidgetTable';
import {
  KpiTableSingleDateWidgetConfig,
  KpiTableSingleDateWidgetData,
  KpiTableSingleDateWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { capitalize } from 'lodash-es';
import { useRef } from 'react';

const GROUPING_TYPES = [
  'assets',
  'segments',
] as const satisfies readonly KpiTableGroupingType[];

export type KpiTableSingleDateWidgetState = ColumSizeWidgetState &
  DisplayedGroupsWidgetState & {
    date: string;
    groupingType: KpiTableGroupingType;
  };

export function KpiTableSingleDateWidget(
  props: WidgetProps<
    KpiTableSingleDateWidgetData,
    KpiTableSingleDateWidgetSection
  > &
    WidgetStateProps<KpiTableSingleDateWidgetState> &
    WidgetConfigProps<KpiTableSingleDateWidgetConfig> &
    WidgetContextProps<DisplayedGroupsWidgetContext>,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    settings,
    onSettingsChange,
    isLoading,
    isError,
    isFetching,
    mode,
    context,
  } = props;

  const gridRef = useRef<AgGridReact | null>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const exportFeature = useTableWidgetExportFeature(
    {
      gridRef,
      mode,
      widgetTitle: widgetSection.title,
      widgetId: widgetSection.id,
      state,
    },
    KPI_TABLE_WIDGET_EXPORT_PARAMS,
  );

  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  const columnVisibilityStateFeature = useKpiWidgetColumnVisibilityState({
    grid: gridRef.current,
    state,
    onStateChange,
  });
  const widgetStateFullScreenProps = useWidgetFullScreen(wrapperDivRef);
  const autoGroupColDef = useAutoGroupColDef(state.groupingType, gridRef, {
    cellClass: exportFeature.autoGroupColumnDef.cellClass,
  });
  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      <DashboardWidgetCard.Header>
        <div className="flex">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          <RecapPageButton
            name={widgetSection.recapPage?.name}
            slug={widgetSection.recapPage?.slug}
          />
        </div>
        {mode != 'pdf' && (
          <>
            <div className="grow" />
            {!widgetStateFullScreenProps.isFullscreen &&
              context.columnVisibilityEnabled && (
                <WidgetStateColumns
                  onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
                  state={state}
                  groupsState={{
                    groups: state.displayedGroups ?? [],
                  }}
                  onColumnStateChange={columnVisibilityStateFeature.onChange}
                />
              )}
            <div className="flex gap-tw-2">
              <ThinTabGroup
                selectedItem={state.groupingType}
                onSelectedItemChange={(tab) =>
                  onStateChange({
                    ...state,
                    groupingType: tab.id as KpiTableGroupingType,
                  })
                }
                items={GROUPING_TYPES.map((type) => ({
                  id: type,
                  label: capitalize(type),
                }))}
              />
            </div>
            <WidgetStateFullScreenIconButton {...widgetStateFullScreenProps} />
          </>
        )}
      </DashboardWidgetCard.Header>
      {mode != 'pdf' && (
        <DashboardWidgetCard.Panel>
          <WidgetStateDate state={state} onStateChange={onStateChange} />
          <GrowDiv />
          <exportFeature.ExportButtonComponent />
        </DashboardWidgetCard.Panel>
      )}
      {isLoading && !isError && (
        <LoadingTable rowHeight={ROW_HEIGHT} className="grow" cols={4} />
      )}
      {isError && (
        <div
          className={cn(
            'flex flex-col items-center justify-center',
            mode === 'edit' ? 'h-full' : 'p-tw-6',
          )}
        >
          <span className="text-neutral-550">Error loading data</span>
        </div>
      )}
      {!isLoading && !isError && (
        <KpiTableWidgetTable
          ref={gridRef}
          autoGroupColumnDef={autoGroupColDef}
          state={state}
          onStateChange={onStateChange}
          vizConfig={widgetSection.widgetConfig.viz_config}
          data={data}
          columnsConfig={widgetSection.widgetConfig.columns}
          settings={settings}
          onSettingsChange={onSettingsChange}
          mode={mode}
          onRowDataUpdated={columnSizeFeature.handleRowDataUpdated}
          excelStyles={exportFeature.excelStyles}
        />
      )}
    </DashboardWidgetTableCard>
  );
}

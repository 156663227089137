import { TReturnInvestmentTransactionDirection } from '@/bundles/REturn/types';
import { formatDate, formatDateToQuarterWithPrefix } from '@/shared/lib/formatting/dates';

export function filtterSourceByType<
  T extends { kind: `${TReturnInvestmentTransactionDirection}` },
>(arr: T[], kind: `${TReturnInvestmentTransactionDirection}`) {
  return arr.filter((item) => item.kind === kind);
}

export function formatDateByPeriodType(
  periodType: 'monthly' | 'quarterly',
  date: DateString,
) {
  return periodType === 'quarterly'
    ? formatDateToQuarterWithPrefix(date)
    : formatDate(date, 'MMM-YYYY');
}

import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories';

const CrumbSeparator = ({ className, isVertical }) => (
  <div className={
    cn(`folder-breadcrumbs__separator ${className}`,
      { 'folder-breadcrumbs__separator-vertical': isVertical })
  }
  />
);

const CrumbLink = ({ children, to, isCurrentLocation, onFolderChange }) => (
  isCurrentLocation
    ? (
      <Link
        to={to || './'}
        className="cursor-default light-90 secondary-semibold secondary-regular"
        onClick={(e) => e.preventDefault()}
      >
        {children}
      </Link>
    )
    : (
      <div className="flex items-center">
        <Link
          to={to || './'}
          className="blue secondary-semibold secondary-regular"
          onClick={onFolderChange}
        >
          {children}
        </Link>
        <CrumbSeparator className="ml-[0.5rem]" />
      </div>
    )
);

const AssetFolderBreadcrumbs = ({
  rootPath, rootName, onRootClick, folders, currentFolder, onFolderChange
}) => {
  const [crumbs, setCrumbs] = useState([]);

  const buildCrumbs = (folder, crumbsMemo) => {
    crumbsMemo.unshift(
      <CrumbLink
        key={folder.id}
        isCurrentLocation={folder === currentFolder}
        onFolderChange={() => onFolderChange({ folder_id: folder.id })}
      >
        {folder.title}
      </CrumbLink>,
    );
    if (folder.parentId == null) return crumbsMemo;

    const parentFolder = folders.find((f) => f.id === folder.parentId);
    return buildCrumbs(parentFolder, crumbsMemo);
  };

  useEffect(() => {
    setCrumbs(buildCrumbs(currentFolder, []));
  }, [currentFolder, folders]);

  const handleBackButtonClick = () => {
    currentFolder.parentId ? onFolderChange({ folder_id: currentFolder.parentId }) : onRootClick();
  };

  return (
    // TODO: replace with Breadcrumbs component when it's ready
    <div className="d-inline-flex items-center folder-breadcrumbs__container">
      <IconButton
        iconName="arrowLeft"
        onClick={handleBackButtonClick}
      />
      <CrumbSeparator isVertical />
      <CrumbLink
        to={rootPath}
        onFolderChange={() => {
          setCrumbs([]);
          onRootClick();
        }}
      >
        {rootName}
      </CrumbLink>
      {crumbs}
    </div>
  );
};

export default AssetFolderBreadcrumbs;

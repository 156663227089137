import { Logo } from '@/bundles/Shared/components/LeftSidebar/Logo/Logo';
import { REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from '@/types/Props';

export const ReportPDFHeader = ({
  objectName,
  objectAddress,
  reportSubtitle,
  reportTitle,
  className,
}: {
  objectName: string;
  reportTitle: string;
  objectAddress?: string;
  reportSubtitle?: string;
} & ClassNameProps) => (
  <div
    className={cn(
      'flex items-center justify-between border-b border-solid border-neutral-150 py-tw-4',
      REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME,
      className,
    )}
  >
    <div className="flex flex-col gap-tw-2">
      <div className="flex flex-col gap-tw-0.5">
        {reportSubtitle && (
          <p className="inline-regular text-[21px] text-neutral-800">
            {reportSubtitle}
          </p>
        )}
        <p className="inline-regular text-[21px] text-neutral-800">
          {reportTitle}
        </p>
      </div>
      <div className="gap-tw-0 5 flex flex-col">
        <p className="body-semibold text-[24px] text-neutral-800">
          {objectName}
        </p>
        {objectAddress && (
          <p className="inline-regular text-[21px] text-neutral-550">
            {objectAddress}
          </p>
        )}
      </div>
    </div>
    <div className="grow" />
    <Logo
      className="mb-tw-2 h-[3.5rem] max-w-full object-contain print:block"
      variant="large"
    />
  </div>
);

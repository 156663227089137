import React, { ComponentProps } from 'react';
import InvestmentObjectImage from 'bundles/InvestmentObjects/components/investmentObjectImage/InvestmentObjectImage';
import { cn } from '@/shared/lib/css/cn';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { useProjectCardResponsive } from 'stories/ProjectCard/hooks';
import { Img } from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';

type Props = Pick<
  React.ComponentProps<typeof InvestmentObjectImage>,
  'pictureUrl'
> & {
  header: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  classes?: {
    image?: string;
  };
  imagePlaceholder?: string;
} & JSX.IntrinsicElements['div'];

export function ProjectCard({
  pictureUrl,
  header,
  selected,
  disabled,
  children,
  className,
  classes,
  fallbackNode,
  imagePlaceholder,
  ...props
}: React.PropsWithChildren<Props> &
  Pick<ComponentProps<typeof Img>, 'fallbackNode'>) {
  const { mediumWidth } = useProjectCardResponsive();
  return (
    <div
      {...props}
      className={cn(
        'group flex min-h-[72px] items-stretch',
        disabled ? 'bg-light-5' : 'bg-tw-white hover:bg-light',
        mediumWidth ? 'gap-0 p-tw-4 pl-tw-6' : 'gap-tw-4 p-tw-6',
        className,
      )}
    >
      <div className="relative flex items-center">
        <div
          className={cn(
            'absolute bottom-0 top-0 z-[1] w-tw-1 rounded-sm bg-blue',
            selected ? 'visible' : 'hidden',
            mediumWidth ? '-left-tw-4' : '-left-tw-3',
          )}
        />
        <InvestmentObjectImage
          fallbackNode={fallbackNode}
          className={cn(classes?.image, mediumWidth && 'hidden')}
          pictureUrl={pictureUrl}
          imagePlaceholder={imagePlaceholder}
        />
      </div>

      <div className="flex flex-grow flex-col gap-tw-2">
        {header}
        {children}
      </div>
    </div>
  );
}

export const ProjectCardSkeleton = () => (
  <div className="grid grid-cols-[140px,auto] gap-tw-4 bg-light-5 p-tw-6">
    <SkeletonBlock className="h-[74px]" />
    <SkeletonBlock className="h-[74px]" />
  </div>
);

export default ProjectCard;

import React, { useEffect, useState } from 'react';
import { AnimationLoader, Icon, IconButton } from 'stories';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import type { IUserRole } from 'types/User';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { usePermissions } from '@/app/lib';
import { ActivityLogTab } from './RightBar/ActivityLogTab';
import { DetailsTab } from './RightBar/DetailsTab';
import type * as Types from './types';
import { EditBasicInfo } from './RightBar/EditBasicInfo';
import styles from './styles.module.scss';
import { CreateRole } from './RightBar/CreateRole';
import { AllActivityLog } from './RightBar/AllActivityLog';
import { userRoleNameTruncate } from './constants';

const TABS: IThinTabItem[] = [
  {
    id: 1,
    label: 'Details',
  },
  {
    id: 2,
    label: 'Activity Log',
  },
];
const [DETAILS_TAB, ACTIVITY_LOG_TAB] = TABS;

interface Props {
  resolveActiveRoleId: () => void;
  activeRole: IUserRole;
  groupOptions: Types.IGroupOptions;
}

export function RightBar(props: Props) {
  const { activeRole, groupOptions, resolveActiveRoleId } = props;
  const pagePosition = useAppSelector((state) => state.userRoles.position);
  const [rightBarState, setRightBarState] =
    useState<Types.RightBarStateType>('idle');
  const [selectedTab, setSelectedTab] = useState(DETAILS_TAB);
  const { canConfigureUserRoles } = usePermissions();

  const editState = rightBarState.includes('edit:');

  useEffect(() => {
    setRightBarState(
      pagePosition === 'create-role' ? 'create:initial' : 'idle',
    );
  }, [pagePosition]);

  useEffect(() => {
    setRightBarState('idle');
  }, [activeRole]);

  if (pagePosition === 'loading') {
    return (
      <div className={styles.rightBar}>
        <AnimationLoader />
      </div>
    );
  }

  return (
    <div className={styles.rightBar}>
      {pagePosition === 'create-role' && (
        <CreateRole
          activeRoleCategory={activeRole.category}
          groupOptions={groupOptions}
          setRightBarState={setRightBarState}
        />
      )}

      {pagePosition === 'all-user-roles-actvityLog' && <AllActivityLog />}

      {(pagePosition === 'loaded' || pagePosition === 'idle') &&
        (activeRole === undefined ? (
          <>No selected role</>
        ) : (
          <>
            {editState && (
              <EditBasicInfo
                rightBarState={rightBarState}
                setRightBarState={setRightBarState}
                {...props}
              />
            )}
            {rightBarState === 'idle' && (
              <div className="flex justify-between items-start mb-m">
                <div className="flex flex-col">
                  <h2 className="header4-bold">
                    {userRoleNameTruncate(activeRole.name)}
                    {(activeRole.readonly || !canConfigureUserRoles) && (
                      <Icon className="light-60 ml-075rem" iconName="lock" />
                    )}
                  </h2>
                  {activeRole.group && (
                    <span className="label-regular">{activeRole.group}</span>
                  )}
                </div>

                {!activeRole.readonly && canConfigureUserRoles && (
                  <IconButton
                    onClick={() => setRightBarState('edit:initial')}
                    size="l"
                    iconName="edit"
                  />
                )}
              </div>
            )}
            <ThinTabGroup
              classes={{ item: 'justify-center w-full' }}
              className="mb-tw-8 grid w-full grid-cols-2"
              selectedItem={selectedTab}
              onSelectedItemChange={(selected) => setSelectedTab(selected)}
              items={TABS}
            />
            {selectedTab === DETAILS_TAB && (
              <DetailsTab
                resolveActiveRoleId={resolveActiveRoleId}
                activeRole={activeRole}
              />
            )}
            {selectedTab === ACTIVITY_LOG_TAB && <ActivityLogTab />}
          </>
        ))}
    </div>
  );
}

import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import { TRouteParams, useNavigation } from '@/shared/lib/hooks/useNavigation';
import Errors from 'bundles/Errors';
import withAccess from 'bundles/Shared/components/withAccess';
import { AnimationLoader, Button } from 'stories';
import {
  currentUserAllowedTo,
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
  TProductNames,
} from 'lib/permissions';
import History from './History';
import ChangeOrderDetails from './ChangeOrderDetails/ChangeOrderDetails';
import {
  approveChangeOrder,
  fetchChangeOrder,
  requestApprovalChangeOrder,
  revertChangeOrder,
} from '../../actions/changeOrders';
import IncludesChangeEvents from './IncludesChangeEvents/IncludesChangeEvents';
import VerificationForm from './AvailableActions/VerificationForm';
import DocumentsCO from './DocumentsCO';
import ReconcileModalPage from './../Shared/ReconcileModalPage/ReconcileModalPage';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import ESignPlacementModalV2 from 'bundles/Construction/components/eSignature/ESignPlacementModalV2';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  useGetContractFileQuery,
  useUpdateEsignPlacementsMutation,
} from 'bundles/Construction/api/coContractFile';
import { transformData } from 'bundles/Construction/components/ChangeOrder/utils';
import RequestApprovalForm from 'bundles/Construction/components/ChangeOrder/AvailableActions/RequestApprovalForm';
import MessageWithApprovers from 'bundles/Construction/components/ChangeOrder/AvailableActions/MessageWithApprovers';
import { usePreventBodyScrollingEffect } from '@/shared/lib/hooks/usePreventBodyScollingEffect';
import DocumentESignatureActions from 'bundles/Construction/components/ChangeOrder/AvailableActions/DocumentESignatureActions';
import ApprovalForm from 'bundles/Construction/components/ChangeOrder/AvailableActions/ApprovalForm';
import { changeReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';
import { REVERTABLE_STATUSES } from '@/bundles/Construction/components/ChangeOrderEvent/AvailableActions/AvailableActions';

export type TStepVerifyCO =
  | 'addDocs'
  | 'completeCoDate'
  | 'selectFinal'
  | 'esignPlacement';

export interface IVerifySteps {
  completeCoDate: boolean;
  addDocs: boolean;
  selectFinal: boolean;
  esignPlacement: boolean;
}

export interface IActionsHints {
  completeCoDate: RefObject<HTMLDivElement>[];
  addDocs: RefObject<HTMLDivElement>[];
  selectFinal: RefObject<HTMLDivElement>[];
  esignPlacement: RefObject<HTMLDivElement>[];
}

type Props = TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER'] & {
  containerClassName: string;
};

function ChangeOrder({
  changeOrderId,
  containerClassName,
  legalEntityCode,
}: Props) {
  const documentRef = useRef(null);
  const dateRef = useRef(null);
  const navigation = useNavigation();
  const { openModal } = useModal();
  const params = useParams();
  const changeOrder = useAppSelector(
    (state) => state.changeOrderManagement.changeOrder,
  );
  const dispatch = useAppDispatch();
  const [esignSkipped, setEsignSkipped] = useState(false);

  const canEdit = changeOrder?.status === 'DRAFT';

  const { data: contractFile } = useGetContractFileQuery(
    {
      changeOrderId,
      legalEntityCode,
      contractFileId: changeOrder?.contractFileId,
    },
    {
      skip: changeOrder?.contractFileId == null,
      refetchOnMountOrArgChange: true,
    },
  );
  const [updateEsignPlacements] = useUpdateEsignPlacementsMutation();

  const onCloseModal = () => {
    navigate(
      navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDERS', {
        legalEntityCode: params.legalEntityCode,
      }),
    );
  };
  usePreventBodyScrollingEffect();
  useEffect(() => {
    dispatch(fetchChangeOrder(legalEntityCode, changeOrderId));

    return () => {
      dispatch(changeReconcileActiveAction(null));
    };
  }, [legalEntityCode, changeOrderId]);

  const verifyStep = useMemo(
    () => ({
      completeCoDate: changeOrder?.date,
      addDocs: changeOrder?.documents?.length > 0,
      selectFinal: changeOrder?.contractFileId,
      esignPlacement: esignSkipped || changeOrder?.signaturePlacementsComplete,
    }),
    [changeOrder, esignSkipped],
  );
  const scrollToFunc = (step: TStepVerifyCO) => {
    if (['addDocs', 'selectFinal', 'esignPlacement'].includes(step)) {
      documentRef.current.scrollIntoView();
    } else {
      dateRef.current.scrollIntoView();
    }
  };

  const handleShowSignaturePlacementModal = async (readonly = false) => {
    if (!contractFile) return;

    const data = await openModal(ESignPlacementModalV2, {
      contractFile,
      readonly,
    });

    if (!data) return;

    const result = transformData(data, contractFile);

    await updateEsignPlacements({
      changeOrderId,
      legalEntityCode,
      contractFileId: changeOrder.contractFileId,
      body: {
        approvers: result,
        signature_fields: contractFile.defaultSignatureFields.map((f) => ({
          kind: f,
        })),
      },
    });
    await dispatch(fetchChangeOrder(legalEntityCode, changeOrderId));
  };

  const hasRequestedApproval =
    changeOrder?.approvers?.length > 0 &&
    changeOrder.approvers.some((a) => a.requestedAt != null);

  const handleRequestApproval = async () => {
    await dispatch(requestApprovalChangeOrder(legalEntityCode, changeOrderId));
    await dispatch(fetchChangeOrder(legalEntityCode, changeOrderId));
  };

  const handleApprove = async () => {
    await dispatch(approveChangeOrder(legalEntityCode, changeOrderId));
    await dispatch(fetchChangeOrder(legalEntityCode, changeOrderId));
  };

  const handleRevert = async (status: boolean) => {
    if (!status) return false;
    await dispatch(revertChangeOrder(legalEntityCode, changeOrderId));
    await dispatch(fetchChangeOrder(legalEntityCode, changeOrderId));
    return true;
  };
  const showRevert =
    currentUserAllowedTo('configure', TProductNames.CHANGE_MANAGEMENT) &&
    REVERTABLE_STATUSES.includes(changeOrder?.status) &&
    changeOrder.verifiedAt != null;

  const revertFlow = showRevert
    ? {
        handler: handleRevert,
        modal: {
          title: 'Revert Change Order',
          subtitle:
            'Are you sure you want to Revert Change Order? This CO will be reverted to Unverified state & all attached Documents will be deleted',
          actions: {
            secondaryButton: {
              text: 'Cancel',
            },
            primaryButton: {
              text: 'Revert',
            },
          },
        },
      }
    : null;

  const shouldRequestApproval = () => {
    if (!changeOrder) return false;

    return (
      changeOrder.verifiedAt &&
      changeOrder.status === 'PENDING' &&
      changeOrder.approvers?.length > 0 &&
      changeOrder.approvers.every((a) => a.requestedAt == null)
    );
  };

  const actionsData = useMemo(() => {
    if (!changeOrder) return;

    return [
      {
        title: 'Verify',
        description: '',
        iconName: 'checkDouble',
        show: changeOrder.status === 'DRAFT',
        content: (
          <VerificationForm
            changeOrder={changeOrder}
            verifyStep={verifyStep}
            scrollToFunc={scrollToFunc}
            legalEntityCode={legalEntityCode}
          />
        ),
      },
      {
        title: 'Request Approval',
        description: '',
        iconName: 'bell',
        show: shouldRequestApproval(),
        content: (
          <RequestApprovalForm
            approvers={changeOrder.approvers}
            onSubmit={handleRequestApproval}
          />
        ),
      },
      {
        title: 'Approve',
        description: '',
        iconName: 'acceptAlt',
        show: changeOrder?.verifiedAt && changeOrder?.approvers?.length === 0,
        content: (
          <ApprovalForm
            changeOrder={changeOrder}
            legalEntityCode={legalEntityCode}
          />
        ),
      },
    ];
  }, [changeOrder, esignSkipped]);

  const resolveSidebarContent = useMemo(() => {
    if (!changeOrder) return;
    if (changeOrder?.approvers?.length > 0 && changeOrder.canApprove)
      return (
        <Button
          onClick={handleApprove}
          variant="success"
          iconName="acceptAlt"
          fluid
        >
          Approve
        </Button>
      );

    if (changeOrder.status === 'APPROVED' || hasRequestedApproval) {
      return (
        <MessageWithApprovers
          status={changeOrder.status}
          approvers={changeOrder.approvers}
        />
      );
    }

    return null;
  }, [changeOrder]);

  if (changeOrder?.errors?.length) {
    return (
      <Errors
        code="not_found"
        actionButton={{
          text: 'Back to Change Orders',
          onClick: onCloseModal,
        }}
      />
    );
  }

  if (!changeOrder) return <AnimationLoader />;

  return (
    <ReconcileModalPage
      containerClassName={containerClassName}
      header={{
        title: `CO ${changeOrder?.number}`,
        onCloseModal,
      }}
      infoBadge={{
        title: 'CHANGE ORDER INFO',
        status: changeOrder?.status,
        amount: changeOrder?.amount,
      }}
      availableActions={{
        actionsData: actionsData.filter((action) => action.show),
        revertFlow,
      }}
      sidebarContent={resolveSidebarContent}
      historyCustom={
        <History changeOrder={changeOrder} legalEntityCode={legalEntityCode} />
      }
      legalEntityCode={legalEntityCode}
    >
      <div ref={dateRef}>
        <ChangeOrderDetails changeOrder={changeOrder} canEdit={canEdit} />
      </div>
      <div>
        <IncludesChangeEvents
          changeOrder={changeOrder}
          legalEntityCode={legalEntityCode}
        />
      </div>
      <div ref={documentRef}>
        <DocumentsCO
          legalEntityCode={legalEntityCode}
          changeOrder={changeOrder}
        >
          {changeOrder?.contractFileId && !changeOrder.verifiedAt && (
            <DocumentESignatureActions
              skipped={esignSkipped}
              onSkip={() => setEsignSkipped(true)}
              canSkip={contractFile?.defaultApprovers?.length === 0}
              showPlacementsModal={handleShowSignaturePlacementModal}
              placed={Boolean(changeOrder?.signaturePlacementsComplete)}
            />
          )}
        </DocumentsCO>
      </div>
    </ReconcileModalPage>
  );
}

export default withAccess(ChangeOrder, {
  hasAccess: () =>
    currentUserIsSreAdmin() ||
    currentUserIsSuperAdmin() ||
    currentUserAllowedTo('view', TProductNames.CHANGE_MANAGEMENT),
});

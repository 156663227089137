import { IChangeOrderReason } from 'bundles/Construction/types';
import { formatAmount } from '@/shared/lib/formatting/number';
import { FC } from 'react';
import { Button, InlineAlert, SmallAddingButton, Tooltip } from 'stories';
import { IActionsHints } from '../..';

export interface IWorkCost {
  changeOrderReason: IChangeOrderReason;
  scheduleOfValueCode:
    | number
    | {
        codeName: string;
      };
  amount: number;
  description: string;
}

interface ICostOfWorkProps {
  workCosts: IWorkCost[];
  workCostsValid: boolean;
  editOnly: boolean;
  workCostsSubtotal: () => void;
  setShowModal: () => void;
  editCost: boolean;
  actionsHints: IActionsHints;
  removeReason: (id: number) => Promise<void>;
}

const CostOfWork: FC<ICostOfWorkProps> = ({
  workCosts = [],
  workCostsValid,
  editOnly = true,
  workCostsSubtotal,
  setShowModal,
  editCost,
  actionsHints,
  removeReason,
}) => (
  <div className="card-cost-breakdown card-cost-breakdown_line">
    <div className="dark-60 inline-semibold mb-s">Cost of Work</div>
    {!workCostsValid() && (
      <InlineAlert
        message="Add Cost of Work, Markups and Fees"
        status="attention"
        className="mb-s w-full"
      />
    )}
    <div>
      {workCosts[0]?.amount ? (
        <table className="table-reasons table">
          <thead>
            <tr>
              <th className="w-45">Reason</th>
              <th className="w-35">Cost Code</th>
              <th className="w-20 text-right">Amount</th>
            </tr>
          </thead>
          <tbody className="dark-60">
            {workCosts.map((w, i) => (
              <tr key={i}>
                <td>
                  {w.reason && (
                    <>
                      <div className="label-semibold">{`${w.reason.category} - ${w.reason.name}`}</div>
                      <div className="secondary-regular">{w.description}</div>
                    </>
                  )}
                </td>
                <td className="secondary-regular">{`${w.costCode?.code} ${w.costCode.name}`}</td>
                <td className="secondary-regular text-right">
                  {formatAmount(w.amount)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        workCosts.map((workCost, i) => (
          <Tooltip
            key={`workCost${i}`}
            trigger="click"
            triggerTarget={actionsHints.completeFields}
            className="!block"
            placement="right"
            arrowPosition="left"
            titleText="Required field"
            classes={{ spanContainer: 'mb-m w-full' }}
          >
            <div className="card-reasons">
              <div>
                <div className="secondary-regular mb-xs">Reason</div>
                <div className="dark-60 label-semibold">
                  {`${workCost?.reason?.category} - ${workCost?.reason?.name}`}
                </div>
              </div>
              <div className="flex">
                {!editOnly && (
                  <Button
                    variant="secondary"
                    size="s"
                    onClick={() => removeReason(workCost?.reason?.id)}
                  >
                    Remove
                  </Button>
                )}
                <Button
                  variant="warning"
                  size="s"
                  className="ml-s"
                  onClick={() => {
                    setShowModal('workCosts');
                  }}
                >
                  Add Details
                </Button>
              </div>
            </div>
          </Tooltip>
        ))
      )}
    </div>
    <div className="mt-m flex items-center justify-between">
      {!editOnly && !editCost && (
        <SmallAddingButton
          onClick={() => {
            setShowModal('workCosts');
          }}
        >
          Add Cost
        </SmallAddingButton>
      )}
      {workCosts[0]?.amount && (
        <div className="secondary-regular flex">
          <div className="mr-xs">Total Cost of Work</div>
          <div className="dark-60">{formatAmount(workCostsSubtotal())}</div>
        </div>
      )}
    </div>
  </div>
);

export default CostOfWork;

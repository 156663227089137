import * as monaco from 'monaco-editor';
import Editor, { loader } from '@monaco-editor/react';
import { ComponentProps } from 'react';
import { DEFAULT_JSON_CODE_EDITOR_PROPS } from '@/shared/ui/CodeEditor/config';
import JsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import EditorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';

self.MonacoEnvironment = {
  getWorker(
    _,
    label: StringAndOptions<
      | 'json'
      | 'css'
      | 'scss'
      | 'less'
      | 'html'
      | 'handlebars'
      | 'razor'
      | 'typescript'
      | 'javascript'
    >,
  ) {
    switch (label) {
      case 'json': {
        return new JsonWorker();
      }

      // Uncomment only when it's needed/used somewhere in the portal. Keep in mind of chunk size!

      // case 'css':
      // case 'scss':
      // case 'less': {
      //   return import('monaco-editor/esm/vs/language/css/css.worker?worker'); // 984.38 kB
      // }

      // case 'html':
      // case 'handlebars':
      // case 'razor': {
      //   return import('monaco-editor/esm/vs/language/html/html.worker?worker'); // 643.94 kB
      // }

      // case 'javascript':
      // case 'typescript': {
      //   return import(
      //     'monaco-editor/esm/vs/language/typescript/ts.worker?worker' // 4,848.04 kB
      //   );
      // }

      default: {
        return new EditorWorker();
      }
    }
  },
};

loader?.config({ monaco });

export const CodeEditor = (props: ComponentProps<typeof Editor>) => (
  <Editor {...props} />
);
export const JSONEditor = (props: ComponentProps<typeof Editor>) => (
  <Editor {...DEFAULT_JSON_CODE_EDITOR_PROPS} {...props} />
);

import { ISortParamsField } from 'types/Sort';
import { IQueryParams } from 'types/Pagination';
import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

type TCrudPathParameters = {
  legalEntityId: string;
};

export type TFetchFeesForReconcileBudgetSettingsRequest = ISortParamsField<
  'name' | 'code'
> &
  IQueryParams;

export type TReconcileBudgetSettingsFee = {
  id: number;
  code: string;
  name: string;
  percent: number;
};

export interface IFeeUpdate {
  code: string;
  name: string;
  percent: number;
}

export const {
  getEntities: fetchFeesForReconcileBudgetSettings,
  createEntity: createFeeInReconcileSettings,
  updateEntity: updateFeeInReconcileSettings,
  deleteEntity: deleteFeeInReconcileSettings,
  entityName: FEE_ENTITY_NAME,
} = createCrudApi<
  TCrudPathParameters,
  TFetchFeesForReconcileBudgetSettingsRequest,
  TReconcileBudgetSettingsFee,
  IFeeUpdate
>('/settings/reconcile/legal_entities/:legalEntityId/fees', 'Fee');

import React from 'react';
import { ExternalSourceType } from '@/entities/core/legalEntity';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';

interface Props {
  sourceType: ExternalSourceType;
  className?: string;
}

function SourceTypeImage({ sourceType, className }: Props) {
  const sourceSettings = SOURCE_TYPE_SETTINGS[sourceType];
  if (!sourceSettings.image) return null;

  return (
    <img
      className={className}
      alt={sourceSettings.title}
      src={sourceSettings.image}
    />
  );
}

export default SourceTypeImage;

import React, { useState } from 'react';
import {
  CrudModal,
  CrudModalProps,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/index';
import {
  IWorkflowThresholdUpdate,
  TWorkflowThresholdType,
} from 'bundles/REconcile/actions/workflowThresholds';
import ThresholdFields from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/cruds/ThresholdFields';

interface Props
  extends Omit<
      CrudModalProps<IWorkflowThresholdUpdate>,
      'entityName' | 'valid'
    >,
    Pick<
      React.ComponentProps<typeof ThresholdFields>,
      'maxThresholdValue' | 'minThresholdValue' | 'users'
    > {
  thresholdType: TWorkflowThresholdType;
  threshold: IWorkflowThresholdUpdate;
}

function ThresholdModal({
  thresholdType,
  threshold,
  onSubmit,
  users,
  maxThresholdValue,
  minThresholdValue,
  ...props
}: Props) {
  const [valid, setValid] = useState(true);
  const [formData, setFormData] = useState<IWorkflowThresholdUpdate>(threshold);
  return (
    <CrudModal
      entityName="Threshold"
      onSubmit={() => onSubmit?.(formData)}
      valid={valid}
      {...props}
    >
      <ThresholdFields
        formData={formData}
        setFormData={setFormData}
        setValid={setValid}
        users={users}
        maxThresholdValue={maxThresholdValue}
        minThresholdValue={minThresholdValue}
      />
    </CrudModal>
  );
}

export default ThresholdModal;

import React, { FC } from 'react';
import { IChangeOrder } from 'bundles/Construction/types';
import { CollapsedCard } from 'stories/index';
import { formatAmount } from '@/shared/lib/formatting/number';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import { Link } from '@reach/router';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import dayjs from 'dayjs';

const COItem: FC<IChangeOrder> = ({
  id,
  legalEntityCode,
  number,
  amount,
  coDate,
  approvedAt,
  description,
}) => {
  const { getUrl } = useNavigation();
  return (
    <div className="table-included-co mt-l">
      <div className="table-included-co__item">
        <CollapsedCard
          approvalStatus={{
            iconName: 'acceptAlt',
            text: dayjs(approvedAt).fromNow(),
            tooltipText: `Approved: ${formatDate(approvedAt, 'MM-DD-YYYY')}`,
          }}
          title={
            <Link
              to={getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER', {
                changeOrderId: id,
                legalEntityCode,
              })}
            >
              CO {number} • {formatAmount(amount)} •{' '}
              {formatUnixDate(coDate, 'MM-DD-YYYY')}
            </Link>
          }
          subtitle={description}
        />
      </div>
    </div>
  );
};

export default COItem;

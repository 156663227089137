import { DistributionKindOption, SingleDistributionKind } from '../types';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';

interface Props {
  options: DistributionKindOption[];
  kind: SingleDistributionKind;
  setKind: (kind: SingleDistributionKind) => void;
}

export function DistributionKindSelector({ options, kind, setKind }: Props) {
  return (
    <div className="flex flex-col gap-tw-4">
      <p className="body-semibold text-dark-60">
        Select Transaction Category{' '}
        <span className="inline-semibold text-red">*</span>
      </p>
      <RadioGroup
        options={options}
        value={kind}
        onChange={(o) => setKind(o.value as SingleDistributionKind)}
      />
    </div>
  );
}

import { LogItemKeyInfo } from 'bundles/Shared/components/ActivityLog/LogItemKeyInfo';
import PermissionList from 'bundles/Shared/components/PermissionList';
import { LE_CLASSIFICATION_LABEL_MAP } from 'bundles/Shared/entities/leClasssification';
import { getLEActivityLogKeyInfoFromDetails } from 'bundles/Shared/entities/legalEntity';
import { LegalEntityActivityLog } from 'bundles/Shared/entities/legalEntity/model';
import { LogItemAuthorAndDate } from 'bundles/UserManagement/components/roles/RightBar/ActivityLog/LogItemAuthorAndDate';
import { getLabelIfExists } from 'lib/getLabelIfExists';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { capitalize, isEmpty } from 'lodash-es';
import { Icon, Modal } from 'stories';

type Props = DialogProps & {
  activityLog: LegalEntityActivityLog;
};

const ActivityLogDetailsModal = ({ activityLog, onClose }: Props) => {
  const modalHeader = (
    <div className="flex gap-tw-4">
      <div className="w-40p h-40p flex items-center justify-center rounded-[8px] bg-light">
        <Icon className="header4-regular" iconName="edit" />
      </div>
      <div>
        <h6 className="header6-bold">
          Details of Modifying {activityLog.legalEntity.name}
        </h6>
        <LogItemAuthorAndDate
          className="inline-regular"
          authorName={activityLog.whoDoneIt.fullName}
          date={activityLog.updatedAt}
        />
      </div>
    </div>
  );

  const { details } = activityLog;

  const keyInfo = getLEActivityLogKeyInfoFromDetails(details);
  const hasKeyInfo = !isEmpty(keyInfo);
  const hasPermissionChanges = !isEmpty(details?.new_permissions);

  return (
    <Modal
      toggle={onClose}
      header={modalHeader}
      size="600"
      classes={{
        body: 'bg-light-10',
      }}
    >
      {hasKeyInfo && (
        <div className="mb-m">
          <h6 className="header6-regular mb-s">Key Info</h6>
          <div className="secondary-regular flex flex-col gap overflow-hidden rounded-[8px]">
            {keyInfo.map(([key, { newValue, oldValue }]) => (
              <LogItemKeyInfo
                key={key}
                newValue={getLabelIfExists(
                  newValue,
                  LE_CLASSIFICATION_LABEL_MAP,
                )}
                prevValue={getLabelIfExists(
                  oldValue,
                  LE_CLASSIFICATION_LABEL_MAP,
                )}
                label={capitalize(key)}
              />
            ))}
          </div>
        </div>
      )}

      {hasPermissionChanges && (
        <div>
          <h6 className="header6-regular mb-s">Permissions</h6>
          <div className="secondary-regular flex flex-col gap overflow-hidden rounded-[8px]">
            {[details?.new_permissions, details?.old_permissions].map(
              (permissions, i) => (
                <LogItemKeyInfo
                  key={`${i}-permissions`}
                  newValue={
                    permissions === 'Public' ? (
                      <span className="secondary-regular">All Users</span>
                    ) : (
                      <PermissionList
                        permissions={{
                          roles: permissions?.UserRole ?? [],
                          users: permissions?.User ?? [],
                          tags: permissions?.UserTag ?? [],
                          investmentEntities:
                            permissions?.InvestmentEntity ?? [],
                        }}
                        className="secondary-regular"
                      />
                    )
                  }
                  label={i === 0 ? 'Permissions Added' : 'Previous permissions'}
                />
              ),
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ActivityLogDetailsModal;

import React from 'react';
import LegalEntitiesIconWithTooltip from 'bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import FavoriteIconButton from 'stories/ProjectCard/FavoriteIconButton';
import {
  TRouteParams,
  useNavigation,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { useGetProjectQuery } from 'bundles/REconcile/components/development/api/projects';
import BudgetCategoriesTable from 'bundles/REconcile/components/development/home/dashboard/BudgetCategoriesTable';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { Button, IconButton } from 'stories';
import { useNavigate } from '@reach/router';
import ReconcileDevelopmentDashboardProjectCharts from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentDashboardProjectCharts';
import ReconcileDevelopmentDashboardChangeManagementNav from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentDashboardChangeManagementNav';
import { SeparatorLine } from 'bundles/REconcile/components/development/home/dashboard/Wrappers';
import ReconcileDevelopmentDashboardDrawPackageNav from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentDashboardDrawPackageNav';
import ReconcileDevelopmentDashboardActivityLog from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentDashboardActivityLog';
import { IS_STAGING } from 'lib/http';

function ReconcileDevelopmentDashboardProject() {
  const { projectId } = useQueryParams<TRouteParams['RECONCILE_DEVELOPMENT']>();
  const { isItemFavorite, toggleItemFavorite } = useFavoriteItemIds(
    'reconcileDevelopmentProjectFavoriteIds',
  );
  const { data, isFetching } = useGetProjectQuery(projectId!);
  const { getUrl } = useNavigation();
  const navigate = useNavigate();

  const legalEntityCode = data?.legalEntity.code;

  return (
    <>
      {isFetching && (
        <>
          <SkeletonBlock className="h-[50px]" />
          <div className="grid grid-cols-[repeat(3,minmax(100px,1fr))] gap-tw-6">
            <SkeletonBlock className="h-[200px]" />
            <SkeletonBlock className="h-[200px]" />
            <SkeletonBlock className="h-[200px]" />
          </div>
          <SkeletonBlock className="h-[200px]" />
          <div className="grid grid-cols-[4fr,1fr] gap-tw-6">
            <SkeletonBlock className="h-[200px]" />
            <SkeletonBlock className="h-[200px]" />
          </div>
        </>
      )}
      {!isFetching && data && legalEntityCode && (
        <>
          <div className="flex items-center justify-between gap-tw-2">
            <div className="flex items-center gap-tw-2">
              <span className="text-[20px] font-bold leading-6 text-dark-60">
                {data.name}
              </span>
              <LegalEntitiesIconWithTooltip
                legalEntities={[data.legalEntity]}
              />
            </div>
            <div className="flex items-center gap-tw-2">
              <Button
                size="s"
                variant="secondary"
                onClick={() =>
                  navigate(
                    getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_BUDGET_EDITOR', {
                      legalEntityCode,
                      budgetId: data.id,
                    }),
                  )
                }
              >
                Budget
              </Button>
              <Button
                size="s"
                variant="secondary"
                onClick={() =>
                  navigate(
                    getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SNAPSHOT', {
                      legalEntityCode,
                    }),
                  )
                }
              >
                Snapshot
              </Button>
              <IconButton
                iconName="settings"
                size="l"
                onClick={() =>
                  navigate(
                    getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS', {
                      legalEntityCode,
                      tab: 'legacy',
                    }),
                  )
                }
              />
              <FavoriteIconButton
                selected={isItemFavorite(data.id)}
                onClick={() => toggleItemFavorite(data.id)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-tw-6">
            <ReconcileDevelopmentDashboardProjectCharts />
            <BudgetCategoriesTable items={data.budgetCategories} />
            <SeparatorLine />
            <ReconcileDevelopmentDashboardChangeManagementNav />
            <SeparatorLine />
            <ReconcileDevelopmentDashboardDrawPackageNav />
            {IS_STAGING && <ReconcileDevelopmentDashboardActivityLog />}
          </div>
        </>
      )}
    </>
  );
}

export default ReconcileDevelopmentDashboardProject;

import { stringify } from 'qs';
import http from 'lib/http';
import { PropertiesCamelToSnakeCase } from 'types/StringUtilityTypes';
import { IUserRole } from 'types/User';
import * as Type from '../components/EmailSettings/editor/types';
import { buildTemplateAttributes } from './utils/templateParams/buildTemplateAttributes';

interface IInvitationTemplateParams {
  template: Type.InvitationTemplateState;
  usedVariables: Type.InvitationTemplateVars;
  selectedRolesIds: IUserRole['id'][];
  invitationTemplateId?: number;
}

const buildInvitationTemplateParams = ({
  invitationTemplateId,
  template,
  usedVariables,
  selectedRolesIds,
}: IInvitationTemplateParams) => ({
  id: invitationTemplateId,
  invitation_template: {
    greeting_attributes: buildTemplateAttributes({
      content: template.greeting,
      vars: usedVariables.greeting,
    }),
    body_attributes: buildTemplateAttributes({
      content: template.body,
      vars: usedVariables.body,
    }),
    accept_invitation_attributes: buildTemplateAttributes({
      content: template.acceptInvitation,
      vars: usedVariables.acceptInvitation,
    }),
    additional_info_attributes: buildTemplateAttributes({
      content: template.additionalInfo,
      vars: usedVariables.additionalInfo,
      withDestroy: true,
    }),
    template_attributes: {
      subject: template.subject,
      reply_recipient_ids: template.replyRecipients?.map((re) => re.id) ?? [],
    },
    user_role_ids: selectedRolesIds,
  },
});

export async function invitationTemplateVariables() {
  const result = await http.get('/invitation_template_variables');
  return result.json() as Promise<
    PropertiesCamelToSnakeCase<Type.InvitationTemplateVars>
  >;
}

export async function invitationTemplateRoles(invitationTemplateId?: number) {
  const params = stringify({ invitation_template_id: invitationTemplateId });
  const result = await http.get(`/invitation_template_roles?${params}`);
  return result.json() as Promise<IUserRole[]>;
}

export async function invitationTemplatePreviews(
  payload: IInvitationTemplateParams,
) {
  const result = await http.post(
    '/invitation_template_previews',
    buildInvitationTemplateParams(payload),
  );
  const json = await result.json();

  return json.body as RawHTML;
}

export async function getInvitationTemplate(id: number) {
  const result = await http.get(`/invitation_templates/${id}`);
  return result.json() as Promise<Type.InvitationTemplate>;
}

export async function createInvitationTemplate(
  params: IInvitationTemplateParams,
) {
  const res = await http.post(
    '/invitation_templates',
    buildInvitationTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Invitation has been successfully created');
  }

  return responseData;
}

export async function updateInvitationTemplate(
  params: IInvitationTemplateParams,
) {
  const res = await http.put(
    `/invitation_templates/${params.invitationTemplateId}`,
    buildInvitationTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Invitation has been successfully updated');
  }

  return responseData;
}

export async function removeInvitationTemplate({ id }: { id: number }) {
  const res = await http.del(`/invitation_templates/${id}`);
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Invitation template have been successfully removed');
  }

  return responseData;
}

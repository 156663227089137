import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LegalEntityActivityLog } from 'bundles/Shared/entities/legalEntity';
import { API_URL } from 'lib/http';

interface GetActivityLogsParams {
  legal_entity_id?: string;
}

export const LegalEntityActivityLogsTag = 'LegalEntityActivityLogsTag';

export const settingsCoreLegalEntityActivityLogsApi = createApi({
  reducerPath: 'legalEntityActivityLogsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/settings/core/legal_entity_activity_logs`,
  }),
  tagTypes: [LegalEntityActivityLogsTag],
  endpoints: (builder) => ({
    getLEActivityLogs: builder.query<
      LegalEntityActivityLog[],
      GetActivityLogsParams | undefined
    >({
      providesTags: [LegalEntityActivityLogsTag],
      query: (params) => ({ url: '/', params }),
    }),
  }),
});

export const { useGetLEActivityLogsQuery } =
  settingsCoreLegalEntityActivityLogsApi;

import { ILineItem } from 'bundles/REconcile/types/ILineItem';
import { Invoice } from '@/shared/types/reconcile/Invoice';

export const transformLineItem = (lineItem: ILineItem, invoice: Invoice) => ({
  amount: lineItem.amount,
  date: invoice.date,
  description: lineItem.description,
  vendor_name: {
    value: invoice.vendor.name,
  },
  category_id: `jcc-${lineItem.developmentCategory.id}`,
  category_type: lineItem.developmentCategory.type.toLowerCase(),
  id: null,
});

import React, { useState } from 'react';
import FeeFields from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fees/FeeFields';
import {
  FEE_ENTITY_NAME,
  IFeeUpdate,
} from 'bundles/REconcile/actions/feesConfig';
import {
  CrudEntityModalProps,
  CrudModal,
  CrudModalProps,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

function FeeCrudModal({
  entity,
  onSubmit,
  ...props
}: CrudEntityModalProps<IFeeUpdate>) {
  const [formData, setFormData] = useState(entity);
  return (
    <CrudModal
      entityName={FEE_ENTITY_NAME}
      onSubmit={() => onSubmit?.(formData)}
      {...props}
    >
      <FeeFields formData={formData} setFormData={setFormData} />
    </CrudModal>
  );
}

export default FeeCrudModal;

import { DisplayedGroupsWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import {
  COL_DEF_ID,
  parseColDefId,
  stringifyColDefId,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { FinancialTableSingeDateWidgetNS } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import { useAgGridApplyColumnStateEffect } from '@/lib/ag-grid/useAgGridApplyColumnStateEffect';
import { AgGridReact } from 'ag-grid-react';
import { useMemo } from 'react';

const onDisplayedColumnsHiddenChange = (groupId: string, colId?: string) => {
  return (displayedGroups: DisplayedGroupsWidgetState['displayedGroups']) =>
    (displayedGroups ?? []).map((g) => {
      if (g.id !== groupId) return g;

      if (colId) {
        const children =
          g.children?.map((i) => {
            if (i.id !== colId) return i;
            return { ...i, hide: !i.hide };
          }) ?? [];

        return {
          ...g,
          children,
          hide: children.every((i) => i.hide),
        };
      }

      return {
        ...g,
        hide: !g.hide,
        children: g.children?.map((i) => ({ ...i, hide: !g.hide })),
      };
    });
};

export const useKpiWidgetColumnVisibilityState = <
  S extends DisplayedGroupsWidgetState,
>({
  grid,
  state,
  onStateChange,
}: {
  grid: AgGridReact | null;
  state: S;
  onStateChange: (state: S) => void;
}) => {
  const columnState = useMemo(() => {
    return state.displayedGroups?.flatMap((g) => [
      ...(g.children?.flatMap((c) =>
        c.allColDefIds.map((colDefId) => ({
          colId: colDefId,
          sort: c.viz_config?.initial_sort ?? null,
          hide: c.hide,
        })),
      ) ?? []),
    ]);
  }, [state]);

  useAgGridApplyColumnStateEffect({
    grid,
    columnState,
  });

  const onChange = (groupId: string, colId?: string) => {
    onStateChange({
      ...state,
      displayedGroups: onDisplayedColumnsHiddenChange(
        groupId,
        colId,
      )(state.displayedGroups),
    });
  };

  return {
    onChange,
  };
};

export const useFinancialWidgetColumnsVisibilityState = ({
  data,
  grid,
  state,
  onStateChange,
}: {
  data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
  grid: AgGridReact | null;
  state: DisplayedGroupsWidgetState;
  onStateChange: (s: DisplayedGroupsWidgetState) => void;
}) => {
  const columnState = useMemo(() => {
    if (data?.groups == null) return [];

    return (state.displayedGroups ?? []).flatMap((group) => {
      return group.children?.flatMap((column) => {
        return data.groups.flatMap((objectGroup) => {
          const parsedColDefId = parseColDefId(column.allColDefIds[0]);

          return {
            colId: stringifyColDefId({
              objectId: String(objectGroup.key),
              groupId: parsedColDefId[COL_DEF_ID.GroupIdKey],
              columnId: parsedColDefId[COL_DEF_ID.ColumnIdKey],
            }),
            hide: column.hide,
          };
        });
      });
    });
  }, [state.displayedGroups, data?.groups]);

  const onChange = (groupId: string, colId?: string) => {
    onStateChange({
      ...state,
      displayedGroups: onDisplayedColumnsHiddenChange(
        groupId,
        colId,
      )(state.displayedGroups),
    });
  };

  useAgGridApplyColumnStateEffect({
    grid,
    columnState,
  });

  return {
    onChange,
  };
};

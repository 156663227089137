import {
  ReportManualVariable,
  reportManualVariablesEnhancedApi,
} from '@/entities/report/manualVariable';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import { ACCEPT_BUDGET_FILE_EXTENSION } from '@/shared/lib/browser/config';
import { importFile } from '@/shared/lib/importFile';

export function useImportMVLegalEntities() {
  const dispatch = useAppDispatch();
  const { openFileDialog } = useFileDialog({
    multiple: false,
    accept: ACCEPT_BUDGET_FILE_EXTENSION,
  });
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleImport = async ({
    mvId,
  }: {
    mvId: ReportManualVariable['id'];
  }) => {
    const fileList = await openFileDialog();

    if (fileList === null || fileList.length < 1) return;

    const [file] = fileList;

    const res = await importFile({
      url: `api/report/manual_variables/${mvId}/import`,
      file,
      disableLoading,
      enableLoading,
    });

    if (res) {
      dispatch(
        reportManualVariablesEnhancedApi.util.invalidateTags([
          'ManualVariableLegalEntities',
          'ManualVariables',
        ]),
      );
    }
  };

  return [handleImport, { isLoading }] as const;
}

import { LegalEntitiesField } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesField';
import { Button, Field, Input } from '@/stories';
import {
  DASHBOARD_ENTITY_NAME,
  DashboardFilterObjectItem,
  DashboardFilterObjectTypes,
  useReportEagleEyeDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';
import { ObjectSelectionModal } from 'bundles/Shared/features/dashboard/create/ui/ObjectSelectionModal';
import { EagleEyeDashboardUpdateForm } from 'bundles/Shared/features/dashboard/update/eagleEye';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import { Control, useController, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { SectionField } from 'stories/Field/FieldsWrappers';

type Props = DialogProps<EagleEyeDashboardUpdateForm> & {
  type: 'create' | 'edit';
  dashboard?: EagleEyeDashboardUpdateForm;
  submitText?: string;
};

const ObjectSelectionItem = ({
  type,
  control,
}: {
  type: DashboardFilterObjectTypes;
  control: Control<EagleEyeDashboardUpdateForm>;
}) => {
  const { objects } = useReportEagleEyeDashboardAssetsQuery();
  const { value: isOpen, toggle: toggleIsOpen } = useBoolean();
  const { field } = useController({
    control,
    name: type === 'fund' ? 'asset_ids' : `${type}_ids`,
  });
  const isImplemented = type !== 'fund';

  return (
    <>
      <DashboardFilterObjectItem
        onClick={toggleIsOpen}
        count={isImplemented ? field.value?.length : 0}
        type={type}
        disabled={!isImplemented}
      />
      {isOpen && (
        <ObjectSelectionModal
          objectType={type}
          onClose={toggleIsOpen}
          onChange={(ids) => {
            field.onChange(ids);
          }}
          defaultObjectIds={field.value}
          objects={objects.filter((o) => o.type === type)}
        />
      )}
    </>
  );
};

export function EagleEyeDashboardUpdate({
  onClose,
  onSubmit,
  dashboard,
  type,
  submitText,
}: Props) {
  const {
    register,
    formState: { isValid, isDirty },
    control,
    handleSubmit,
  } = useForm<EagleEyeDashboardUpdateForm>({
    mode: 'all',
    defaultValues: {
      ...dashboard,
    },
  });
  const onSubmitHandler = handleSubmit((data) => {
    onSubmit?.(data);
  });

  return (
    <SidePanel
      toggle={onClose}
      header={`${capitalize(type)} ${DASHBOARD_ENTITY_NAME}`}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={!isValid || (type === 'edit' && !isDirty)}
            onClick={onSubmitHandler}
          >
            {submitText ?? type === 'edit' ? 'Save Updates' : 'Create'}
          </Button>
        </>
      }
    >
      <FieldsContainer>
        <SectionField labelText="Basic">
          <Field labelText="Title" required>
            <Input
              {...register('name', {
                required: true,
              })}
            />
          </Field>
        </SectionField>
        <SectionField
          labelText="Objects"
          classes={{ itemsContainer: 'gap-tw-4' }}
        >
          <div className="grid grid-cols-3 gap-tw-2">
            <ObjectSelectionItem type="asset" control={control} />
            <ObjectSelectionItem type="fund" control={control} />
            <ObjectSelectionItem type="segment" control={control} />
          </div>

          <LegalEntitiesField
            control={control}
            assetsName="asset_ids"
            legalEntitiesName="excluded_legal_entity_ids"
          />
        </SectionField>
      </FieldsContainer>
    </SidePanel>
  );
}

export default EagleEyeDashboardUpdate;

import * as yup from 'yup';
import { VALUE_DISPLAY_OPTIONS_SCHEMA } from '@/shared/lib/formatting/displayOptions';

export const XYCHART_SINGLE_KPI_CONFIG_ROW_SCHEMA = yup.object().shape({
  key: yup.number(),
  label: yup.string().required(),
  expression: yup.string().required(),
  value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
});
export type XYChartSingleKpiWidgetRowForm = yup.InferType<
  typeof XYCHART_SINGLE_KPI_CONFIG_ROW_SCHEMA
>;

import { Icon, Tooltip } from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import { TooltipProps } from 'stories/Tooltip/Tooltip';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { CONNECTION_STATUS_UI_CONFIG } from 'bundles/Shared/entities/connection/config';

interface IConnectionStatus
  extends Partial<React.PropsWithChildren<TooltipProps>> {
  status: IConnection['connectionStatus'];
  description?: string;
}

const ConnectionIcon = ({
  status,
}: {
  status: IConnectionStatus['status'];
}) => (
  <Icon
    iconName={CONNECTION_STATUS_UI_CONFIG[status].iconName}
    className={cn(
      'flex h-[16px] w-[16px] items-center justify-center rounded-2xl text-tw-white',
      CONNECTION_STATUS_UI_CONFIG[status].bgColor,
    )}
  />
);

export const ConnectionStatus = ({
  status,
  description,
  ...props
}: IConnectionStatus) =>
  description ? (
    <Tooltip titleText={description} {...props}>
      <ConnectionIcon status={status} />
    </Tooltip>
  ) : (
    <ConnectionIcon status={status} />
  );

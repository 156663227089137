import React from 'react';
import styles from './RequisitionCellPanel.module.scss';
import { cn } from '@/shared/lib/css/cn';

export const RequisitionCellPanel = ({
  children,
  className,
  ...props
}: React.AllHTMLAttributes<HTMLDivElement>) => (
  <div className={cn(styles.wrapper, className)} {...props}>
    {children}
  </div>
);

import { extendTailwindMerge } from 'tailwind-merge';
import { THEME_SPACING, TYPOGRAPHY_COMPONENTS } from 'tailwind';

// todo replace with tailwind-merge
export const twMergeExtended = extendTailwindMerge({
  classGroups: {
    typography: Object.values(TYPOGRAPHY_COMPONENTS).map((key) => key.slice(1)),
  },
  theme: {
    spacing: Object.keys(THEME_SPACING),
  },
});

import React from 'react';
import { CurrencyFormatter, Icon } from 'stories';

export function LinkTransaction({
  generalLedgerTitle,
  userName,
  amountOnLeft,
  dateOnLeft,
  dateOnRight,
  amountOnRight,
}: {
  userName: string;
  generalLedgerTitle: string;

  amountOnLeft: number;
  dateOnLeft: string;

  dateOnRight: string;
  amountOnRight: number;
}) {
  return (
    <div className="grid grid-cols-[100px_56px_100px] items-center gap-x-tw-6 gap-y-tw-0.5 pr-tw-6">
      <span className="inline-regular mb-tw-1.5 text-right leading-5 text-dark-60">
        <CurrencyFormatter
          classes={{
            wrapper: 'justify-end',
            value: 'font-semibold text-dark-60',
          }}
          value={amountOnLeft}
        />
      </span>

      <div className="my-tw-0.5 flex items-center gap-tw-1 self-start">
        <span className="h-tw-0.5 w-tw-4 rounded bg-light-10" />
        <Icon iconName="link" className="leading-none text-blue" />
        <span className="h-tw-0.5 w-tw-4 rounded bg-light-10" />
      </div>

      <span className="inline-regular mb-tw-1.5 leading-5 text-dark-60">
        <CurrencyFormatter
          classes={{ value: 'font-semibold text-dark-60' }}
          value={amountOnRight}
        />
      </span>

      <span className="secondary-semibold text-right text-light-90">
        {userName}
      </span>

      <span />

      <span className="secondary-semibold text-light-90">
        <span className="secondary-regular text-light-60">GL:</span>{' '}
        {generalLedgerTitle}
      </span>

      <span className="secondary-regular text-right text-dark-60">
        <span className="text-light-60">on</span> {dateOnRight}
      </span>

      <span />

      <span className="secondary-regular text-dark-60">
        <span className="text-light-60">on</span> {dateOnLeft}
      </span>
    </div>
  );
}

import {
  FieldType,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { corNumberRegEx } from 'lib/dictionaries';

const getItemsCOModalEdit = (): IGroupFormItem[] => {
  const groupFormItems = [
    {
      customTitle: 'Key Info',
      required: true,
      activated: true,
      open: true,
      formItems: [
        {
          fieldName: 'number',
          fieldNameSnap: 'number',
          type: FieldType.INPUT_TEXT,
          title: 'Number',
          placeholder: '0000-A',
          required: true,
          validation: {
            message: `Please enter 1 to 4 digits and an optional letter (Examples: 123, 4325-A, 332G)`,
            func: (e) => corNumberRegEx.test(e),
          },
          styles: {
            wrapper: 'w-33 pr-s form-item_white',
            size: 'm',
          },
        },
        {
          fieldName: 'date',
          fieldNameSnap: 'contractor_date',
          type: FieldType.INPUT_DATE,
          title: 'CO date',
          placeholder: 'Date',
          required: true,
          format: 'MM-DD-YYYY',
          formatView: 'MM-dd-yy',
          leftIcon: 'calendar',
          styles: {
            wrapper: 'w-33 pl-s form-item_white',
            size: 'm',
          },
          maxDate: new Date(),
        },
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          title: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,

          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
      ],
    },
    {
      customTitle: 'Financial Details',
      required: true,
      activated: true,
      open: false,
      formItems: [
        {
          fieldName: 'priorContractSum',
          fieldNameSnap: 'priorContractSum',
          type: FieldType.INPUT_NUMBER,
          title: 'Prior Contract Sum',
          leftIcon: 'paid',
          placeholder: '0',
          optional: false,
          disabled: true,
          styles: {
            wrapper: 'w-33 mr-s form-item_white',
            size: 'm',
          },
          thousandSeparator: true,
        },
        {
          fieldName: 'amount',
          fieldNameSnap: 'amount',
          type: FieldType.INPUT_NUMBER,
          title: 'Pending CO amount',
          leftIcon: 'paid',
          placeholder: '0',
          optional: false,
          disabled: true,
          styles: {
            wrapper: 'w-33 form-item_white mx-s',
            size: 'm',
          },
          thousandSeparator: true,
        },
        {
          fieldName: 'newContractSum',
          fieldNameSnap: 'newContractSum',
          type: FieldType.INPUT_NUMBER,
          title: 'New Contract Sum',
          leftIcon: 'paid',
          placeholder: '0',
          optional: false,
          disabled: true,
          styles: {
            wrapper: 'w-33 ml-s form-item_white',
            size: 'm',
          },
          thousandSeparator: true,
        },
      ],
    },
  ];
  return groupFormItems;
};
export default getItemsCOModalEdit;

import { fetchGenericData } from '@/app/reducers/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, navigate, useLocation } from '@reach/router';
import { login } from 'lib/currentUser';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { apiClient } from 'lib/http';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import yupPassword from 'yup-password';
import PasswordFields from './PasswordFields';

yupPassword(yup);

const ResetPassword = () => {
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(useLocation().search);

  const onSubmit = (data) => {
    const body = {
      user: {
        ...data,
        reset_password_token: params.get('reset_password_token'),
      },
    };
    apiClient
      .put('/users/password', body)
      .then(({ data }) => {
        login(data.token);
        dispatch(fetchGenericData());

        window.toastr.success(data.message);
        navigate('/');
      })
      .catch((error) => toastr.error(error.response.data.errors));
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .minUppercase(1, 'Password should contain at least 1 uppercase letter')
      .minLowercase(1, 'Password should contain at least 1 lowercase letter')
      .minNumbers(1, 'Password should contain at least 1 number')
      .minSymbols(1, 'Password should contain at least 1 symbol')
      .min(8),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
    mode: 'onChange',
  });
  return (
    <div>
      <h1 className="auth__header"> Set up your password </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PasswordFields
          errors={errors}
          watch={watch}
          register={register}
          getValues={getValues}
        />
        <input
          type="submit"
          value="Update Password"
          className="btn btn-success btn-lg btn-block roundehidden5rem auth__btn my-tw-8"
        />

        <Link to="/users/sign_in" className="sre-link block text-center">
          Back to Log in
        </Link>
      </form>
    </div>
  );
};

export default ResetPassword;

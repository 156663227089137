import React, { useCallback } from 'react';
import { IFilterComponentProps } from 'bundles/Shared/components/Table/types';
import { QuickFilterByAmount } from 'stories';
import { debounce } from 'lodash-es';
import { IQuickFilterAmountModel } from 'stories/QuickFilterByAmount/QuickFilterByAmount';

function QuickFilterAmount({
  filterModel,
  setFilterModel,
  column,
  ...props
}: IFilterComponentProps & { debounce?: number }) {
  const defaultValue = {
    from: '',
    to: '',
  };

  const changeHandler = (newValue: IQuickFilterAmountModel) => {
    setFilterModel({
      ...filterModel,
      [column.dataField]: newValue,
    });
  };

  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, props.debounce ?? 300),
    [props.debounce],
  );

  return (
    <div className="ml-s">
      <QuickFilterByAmount
        {...props}
        handleChange={debouncedChangeHandler}
        resetFilter={() => {
          setFilterModel({
            ...filterModel,
            [column.dataField]: defaultValue,
          });
        }}
      />
    </div>
  );
}

export default QuickFilterAmount;

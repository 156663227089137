import { ReportManualVariable } from '@/entities/report/manualVariable';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ManualVariableSettingsModal } from '@/entities/report/manualVariable/ui/ManualVariableSettingsModal';

export type EditableReportManualVariable = Pick<
  ReportManualVariable,
  'description' | 'valueType' | 'periodAggregationStrategy' | 'tags'
> & {
  name: string;
  reference: string;
};

export function useManualVariableSettingsModal() {
  const { openModal } = useModal();

  return async (
    args?: Partial<
      Pick<
        React.ComponentProps<typeof ManualVariableSettingsModal>,
        'defaultValues'
      >
    >,
  ) => {
    return await openModal(ManualVariableSettingsModal, {
      ...args,
    });
  };
}

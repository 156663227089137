import { IDevelopmentLegalEntityRequisitionTransaction } from 'bundles/REconcile/types/ILineItem';
import { trimSeparator } from '@/shared/lib/formatting/number';

export const getTypeForTransactionByLineItem = (
  amount: string,
): IDevelopmentLegalEntityRequisitionTransaction['valueType'] => {
  const isNegativeLineItemAmount = trimSeparator(amount) < 0;

  if (isNegativeLineItemAmount) return 'credit';
  return 'debit';
};

export const getTransactionAmountByType = (
  type: IDevelopmentLegalEntityRequisitionTransaction['valueType'],
  amount: string,
) => {
  if (type === 'credit') return -Number(amount);
  return Number(amount);
};

export const transformTransactionAmountForFilter = (value: string) => {
  if (value === '') {
    return value;
  }
  const numValue = Number(value);
  return numValue < 0 ? -numValue : numValue;
};

import React, { PropsWithChildren } from 'react';
import './thinTabItem.scss';
import { cn } from '@/shared/lib/css/cn';

type Props = JSX.IntrinsicElements['div'] &
  PropsWithChildren & {
    selected?: boolean;
    disabled?: boolean;
    className?: string;
  };

export const ThinTabItem = React.forwardRef<HTMLDivElement, Props>(
  ({ children, onClick, selected, disabled, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('thin-tab-item font-semibold', className, {
        'thin-tab-item_selected': selected,
        'thin-tab-item_disabled': disabled,
      })}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      {children}
    </div>
  ),
);

export default ThinTabItem;

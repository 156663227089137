import { cn } from '@/shared/lib/css/cn';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useRef } from 'react';
import { Icon } from 'stories';
import { IconsId } from 'types/sre-icons';
import '../inputs.css';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  status?: 'success' | 'error';
  size?: 's' | 'm' | 'l';
  mode?: 'placeholder' | 'label';
  leftIcon?: IconsId | Exclude<React.ReactNode, string>;
  rightIcon?: IconsId | Exclude<React.ReactNode, string>;
  error?: boolean;
  classes?: {
    backLayer?: string;
    input?: string;
    rightIcon?: string;
    leftIcon?: string;
  };
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      size = 'm',
      leftIcon,
      rightIcon,
      className,
      value,
      onChange,
      status,
      mode = 'placeholder',
      placeholder,
      classes,
      error,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isLabelMode = mode === 'label';
    const trimmedHint = placeholder?.trim();
    if (isLabelMode && trimmedHint === '') {
      console.warn(
        'Avoid using empty string for placeholder field in label mode',
      );
    }

    const refCallback = useCallback((node: HTMLInputElement) => {
      inputRef.current = node;
      if (ref) {
        if (typeof ref === 'function') {
          ref(node);
        } else {
          ref.current = node;
        }
      }
    }, []);

    const onReset = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      ).set;
      nativeInputValueSetter?.call(inputRef.current, '');
      inputRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
    };

    return (
      <div
        className={cn(
          'sre-input',
          {
            'sre-input_label-mode': isLabelMode,
            'sre-input_bubbled': isLabelMode && value,
            [`sre-input_size-${size}`]: !isLabelMode,
            'sre-input_with-left-icon': leftIcon,
            'sre-input_with-right-icon': rightIcon,
          },
          className,
        )}
      >
        <input
          className={cn('sre-input__field', classes?.input, {
            [`sre-input__field_${status}`]: status,
            'sre-input__field_error': error,
            'bg-neutral-150': props.disabled,
          })}
          placeholder={(mode === 'placeholder' && trimmedHint) || ''}
          onChange={onChange}
          value={value}
          ref={refCallback}
          {...props}
        />
        <div className={cn('sre-input__back-layer', classes?.backLayer)}>
          {isLabelMode && (
            <div className="sre-input__label">{isLabelMode && placeholder}</div>
          )}
          {props.type === 'search' && !isEmpty(value) && (
            <div className="sre-input__side-icon sre-input__side-icon_close">
              <Icon onClick={onReset} iconName="close" />
            </div>
          )}
          {!isLabelMode && leftIcon && (
            <div
              className={cn(
                'sre-input__side-icon sre-input__side-icon_left',
                classes?.leftIcon,
              )}
            >
              {typeof leftIcon === 'string' ? (
                <Icon iconName={leftIcon as IconsId} />
              ) : (
                leftIcon
              )}
            </div>
          )}
          {rightIcon && (
            <div
              className={cn(
                'sre-input__side-icon sre-input__side-icon_right',
                classes?.rightIcon,
              )}
            >
              {typeof rightIcon === 'string' ? (
                <Icon iconName={rightIcon as IconsId} />
              ) : (
                rightIcon
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default Input;

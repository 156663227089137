import { AgGridReact } from 'ag-grid-react';

export const setGroupedColumns = (
  { colIds }: { colIds: string[] },
  { columnApi }: AgGridReact,
) => {
  const columnState = columnApi.getColumnState() ?? [];
  columnApi.applyColumnState({
    state: columnState.map((c) => ({
      ...c,
      rowGroup: colIds.includes(c.colId),
      rowGroupIndex: colIds.includes(c.colId) ? c.rowGroupIndex : null,
      hide: colIds.includes(c.colId),
    })),
  });
};

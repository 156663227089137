import React from 'react';

export const SIDEBAR_SUBHEADER_NOTE = (
  <div>
    <div className="dark-60 body-semibold">
      Please define & locate the objects
    </div>
    <p className="light-70 inline-regular mt-tw-2">
      To allocate the e-Signature items on the page, select the required item
      and click or drag it to the required area of the document
    </p>
  </div>
);

import {
  ReportManualVariable,
  ReportManualVariableLegalEntity,
  ReportManualVariableLegalEntityValue,
  useDeleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdMutation,
} from '@/entities/report/manualVariable';

export const useRemoveReportManualVariableValue = () => {
  const [remove, options] =
    useDeleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdMutation();
  return [
    ({
      legalEntityId,
      manualVariableId,
      ...args
    }: {
      manualVariableId: ReportManualVariable['id'];
      legalEntityId: ReportManualVariableLegalEntity['id'];
    } & Pick<ReportManualVariableLegalEntityValue, 'id'>) =>
      remove({
        id: args.id,
        legalEntityId,
        manualVariableId,
      }),
    options,
  ] as const;
};

import {
  CREATE_PAY_APP,
  SEARCH_PAY_APPS,
  FETCH_LAST_PAY_APP,
  FETCH_PAY_APP,
  UPDATE_PAY_APP_SUMMARY,
  UPDATE_CONTINUATION_SHEET_ITEM,
  DESTROY_CONTINUATION_SHEET_ITEM,
} from '../actions';

export default function (payApps = null, { type, payload }) {
  switch (type) {
    case SEARCH_PAY_APPS:
      return {
        ...payApps,
        sPayApps: JSON.parse(payload.pay_apps),
        countPayApp: payload.count,
      };
    case UPDATE_CONTINUATION_SHEET_ITEM:
    case DESTROY_CONTINUATION_SHEET_ITEM:
      return payApps.map((payApp) => (
        payApp.id === payload.id ? { ...payApp, ...payload } : payApp
      ));

    case FETCH_LAST_PAY_APP:
    case FETCH_PAY_APP:
    case CREATE_PAY_APP:
      return payload;

    case UPDATE_PAY_APP_SUMMARY:
      return {
        ...payApps,
        payAppSummary: payload.payAppSummary,
      };

    default:
      return payApps;
  }
}

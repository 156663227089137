import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useReportSettingsDashboardScreenParams } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { selectReportObjectDashboardMetadataById } from 'bundles/Shared/entities/dashboard';
import { ReportDashboardDateFilterBlock } from 'bundles/Shared/features/dashboard/object/filter/byDate';

export const SettingsReportComparisonDashboardDateFilter = () => {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const dashboardState = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(state, dashboardId ?? ''),
  );
  const { date } = dashboardState ?? {};

  return (
    <ReportDashboardDateFilterBlock
      dashboardId={dashboardId}
      date={date ?? null}
    />
  );
};

export function SettingsReportObjectDashboardDateFilter() {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const dashboardState = useAppSelector((state) =>
    selectReportObjectDashboardMetadataById(state, dashboardId ?? ''),
  );
  const { date } = dashboardState ?? {};

  return (
    <ReportDashboardDateFilterBlock
      dashboardId={dashboardId}
      date={date ?? null}
    />
  );
}

import {
  MANUAL_VARIABLE_REFERENCE_PREFIX,
  ReportManualVariable,
  ReportManualVariableLegalEntityValue,
} from '@/entities/report/manualVariable';

export * from './useManualVariableSettingsModal';

export const filterByFullPeriod = (i: ReportManualVariableLegalEntityValue) => {
  return i.dateFrom != null && i.dateTo != null;
};

export const someValueHasDateTo = (i: ReportManualVariableLegalEntityValue) => {
  return i.dateTo == null;
};

export const addManualVariableReferencePrefix = (
  varRef: ReportManualVariable['name'],
) => `${MANUAL_VARIABLE_REFERENCE_PREFIX}.${varRef}`;

export const removeManualVariableReferencePrefix = (
  varRef: ReportManualVariable['name'],
) => varRef.replace(`${MANUAL_VARIABLE_REFERENCE_PREFIX}.`, '');

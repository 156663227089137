import React, { FC, ReactNode } from 'react';
import { IconButton } from 'stories';
import { cn } from '@/shared/lib/css/cn';

export interface ICardProps<ID = UUID | number> {
  children: ReactNode;
  actions?: ReactNode;
  id?: ID;
  handleRemove?: (id?: ID) => void;
  className?: string;
}

export const Card: FC<ICardProps> = ({
  id,
  children,
  handleRemove,
  actions,
  className,
}) => (
  <div
    className={cn(
      'flex gap-tw-4 rounded-lg border border-solid border-light-5 bg-white p-tw-4',
      className,
    )}
  >
    {children}
    {actions}
    {!actions && handleRemove && (
      <IconButton
        iconName="closeSmall"
        onClick={() => handleRemove(id)}
        className="bg-white"
      />
    )}
  </div>
);

import { TRow } from 'bundles/Shared/components/ReportTable/types';
import http from 'lib/http';
import {
  IMutateReportTableConfigPayload,
  IReportTableConfig,
  RowsConfigSourceTable,
} from 'types/ReportTableConfig';

export async function fetchReportTableConfigs() {
  const res = await http.get('/settings/report/table_configs');
  const responseData = await res.json();

  return responseData;
}

export async function fetchReportTableConfig(id: IReportTableConfig['id']) {
  const res = await http.get(`/settings/report/table_configs/${id}`);
  const responseData = await res.json();

  return responseData;
}

export async function createReportTableConfig(
  payload: IMutateReportTableConfigPayload,
) {
  const res = await http.post('/settings/report/table_configs', payload);
  const responseData = await res.json();

  if (res.ok) {
    toastr.success('New report table has been created');
  } else if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.error('Something went wrong');
  }

  return responseData;
}

export async function updateReportTableConfig(
  id: IReportTableConfig['id'],
  payload: IMutateReportTableConfigPayload,
) {
  const res = await http.put(`/settings/report/table_configs/${id}`, payload);
  const responseData = await res.json();

  if (res.ok) {
    toastr.success('Report table has been updated');
  } else if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.error('Something went wrong');
  }

  return responseData;
}

export async function destroyReportTableConfig(id: IReportTableConfig['id']) {
  const res = await http.del(`/settings/report/table_configs/${id}`);
  const responseData = await res.json();

  if (res.ok) {
    toastr.success('Report table has been deleted');
  } else if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.error('Something went wrong');
  }

  return responseData;
}

interface IRowsConfigPreview {
  rows: TRow[];
  errors: any;
}

export async function createAndGetReportTableConfigRowsPreview(
  rowsEditorConfigJSON: TRow[],
) {
  const res = await http.post('/settings/report/table_configs/rows_preview', {
    rows_config: rowsEditorConfigJSON,
  });
  const data: IRowsConfigPreview = await res.json();

  if (data.errors) {
    toastr.error("Couldn' get rows config preview");
  }
  return data;
}

export async function fetchRowsConfigSourceTables() {
  const res = await http.get(
    '/settings/report/table_configs/rows_config_source_tables',
  );
  const data: { items: RowsConfigSourceTable[]; errors: any } =
    await res.json();

  if (data.errors) {
    toastr.error("Couldn' get rows config preview");
  }
  return data;
}

import sanitizeHtml from 'sanitize-html';

export const DEFAULT_SANITIZE_HTML_OPTIONS = {
  allowedClasses: {
    '*': ['table'],
  },
  allowedAttributes: {
    '*': ['href', 'align', 'alt', 'center', 'bgcolor', 'style'],
  },
} satisfies sanitizeHtml.IOptions;

export const ACCEPT_BUDGET_FILE_EXTENSION = '.xlsx,.xlsm,.xls,.csv,.txt';

import http from 'lib/http';
import { IReconcileDevelopmentBudgetItem } from 'bundles/REconcile/types/IReconcileDevelopmentBudgetItem';
import { IReconcileDevelopmentLegalEnitity } from 'bundles/REconcile/types/IReconcileDevelopmentLegalEnitity';

export async function plainFetchReconcileDevelopmentLegalEntity(code: string) {
  const res = await http.get(`/reconcile/development/legal_entities/${code}`);
  const json = await res.json();

  return json as IReconcileDevelopmentLegalEnitity;
}

export async function fetchReconcileDevelopmentLegalEntities() {
  const res = await http.get('/reconcile/development/budgets');
  const json = await res.json();

  return json as IReconcileDevelopmentBudgetItem[];
}

export async function destroyDevelopmentBudget(id) {
  const res = await http.del(`/reconcile/development/budgets/${id}`);
  const json = await res.json();

  if (json.errors) {
    window.toastr.error(json.errors);
    return false;
  }

  window.toastr.success('Budget has been deleted');

  return json as IReconcileDevelopmentBudgetItem;
}

import { LegalEntitiesCounter } from 'bundles/Shared/entities/legalEntity/ui/LegalEntityCounter';
import pluralize from 'pluralize';
import { Tooltip } from '@/stories';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props {
  legalEntities: Pick<LegalEntity, 'id' | 'name'>[];
}

// move to shared/entities/ui
export function LEList({ legalEntities }: Props) {
  return (
    <div className="flex flex-col gap-tw-1">
      <span className="leading-1 text-[12px] font-normal">
        Legal {pluralize('Entity', legalEntities.length)}
      </span>
      <span>{legalEntities.map((le) => le.name).join(', ')}</span>
    </div>
  );
}

function LegalEntitiesIconWithTooltip({ legalEntities }: Props) {
  return (
    <Tooltip
      arrowPosition="start"
      placement="right-start"
      mainText={<LEList legalEntities={legalEntities} />}
    >
      <LegalEntitiesCounter legalEntityCount={legalEntities.length} />
    </Tooltip>
  );
}

export default LegalEntitiesIconWithTooltip;

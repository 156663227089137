import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';

interface Props extends ReactDatePickerProps {
  size?: 's' | 'm' | 'l';
  leftIcon?: boolean;
}

function DateSelect({ size = 'm', leftIcon, ...props }: Props) {
  return (
    <div className="form-item">
      <div className="form-item-date">
        <ReactDatePicker
          className={cn(
            'form-control',
            'form-item__date',
            size ? `form-item__date_size-${size}` : '',
            leftIcon ? 'form-item__date_left' : '',
          )}
          {...props}
        />
        <div className="form-item-date__icon">
          <Icon iconName="arrowBottom" />
        </div>
        {leftIcon && (
          <div className="form-item-date__icon form-item-date__icon_left">
            <Icon iconName="calendar" />
          </div>
        )}
      </div>
    </div>
  );
}

export default DateSelect;

import React from 'react';
import { ExportingFormats } from '@amcharts/amcharts5/.internal/plugins/exporting/Exporting';
import { ActionItem, FileIcon, GroupContainerItem } from '@/stories';
import { upperCase } from 'lodash-es';
import { ActionsContainer } from 'stories/Table/Actions/ActionsContainer';
import { ExportButton } from '@/shared/ui/ExportButton';
import { DropdownPopover } from 'stories/Popover/Popover';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
// @ts-ignore we have no declaration file for this pkg
import ahoy from '@symmetre-inc/ahoy';

interface Props {
  chartRef: React.RefObject<{
    exporting: am5plugins_exporting.Exporting;
  }>;
  widgetSectionId: string;
}

const CHART_EXPORT_DATA_FORMATS = [
  'csv',
  'xlsx',
] as const satisfies ExportingFormats[];

export function ExportChartButton({ chartRef, widgetSectionId }: Props) {
  const ExportActionItem = ({ format }: { format: ExportingFormats }) => {
    const handleExport = () => {
      if (!chartRef.current) {
        return;
      }

      ahoy.track('Widget Export', {
        widget_section_id: widgetSectionId,
      });

      chartRef.current.exporting.download(format, {});
    };
    return (
      <ActionItem
        className="secondary-regular flex h-[24px] cursor-pointer flex-row items-center gap-tw-1 text-neutral-900 hover:bg-neutral-100"
        onClick={handleExport}
      >
        <FileIcon className="leading-[inherit]" extension={format} />
        {upperCase(format)}
      </ActionItem>
    );
  };
  return (
    <DropdownPopover
      className="min-w-[100px]"
      maxWidth="max-content"
      placement="bottom-end"
      template={
        <ActionsContainer>
          <GroupContainerItem title="Image">
            <ExportActionItem format="png" />
          </GroupContainerItem>
          <GroupContainerItem title="Data">
            {CHART_EXPORT_DATA_FORMATS.map((format) => (
              <ExportActionItem key={format} format={format} />
            ))}
          </GroupContainerItem>
        </ActionsContainer>
      }
    >
      <ExportButton />
    </DropdownPopover>
  );
}

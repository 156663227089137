import {
  THRESHOLD_SCHEMA,
  ThresholdForm,
} from '@/entities/report/thresholds/config';
import { Threshold } from '@/entities/report/thresholds/model';
import { getDefaultValueForValueType } from '@/entities/valueType/lib';
import { ValueTypeInput } from '@/entities/valueType/ui/ValueTypeInput';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  Button,
  Field,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from '@/stories';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';

type Props = DialogProps<ThresholdForm> & {
  threshold?: Threshold;
};

export function ThresholdModal({ onClose, onSubmit, threshold }: Props) {
  const methods = useForm<ThresholdForm>({
    defaultValues: {
      globalValue:
        threshold?.globalValue ??
        getDefaultValueForValueType(threshold?.valueType ?? 'number'),
    },
    resolver: yupResolver(THRESHOLD_SCHEMA),
  });

  const {
    formState: { isValid, isSubmitting, isDirty },
    control,
  } = methods;
  const handleSubmit = methods.handleSubmit((values) => onSubmit?.(values));

  return (
    <Modal
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle title="Add Threshold" order="subtitle-title" />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={
              !isValid ||
              isSubmitting ||
              (!isDirty && threshold?.globalValue != null)
            }
          >
            Submit
          </Button>
        </ModalActions>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field
            labelText="Threshold"
            note="This threshold will be used by default for all Legal Entities. You can override it for specific Legal Entities later"
            required
          >
            <Controller
              control={control}
              name="globalValue"
              render={({ field }) => (
                <ValueTypeInput
                  {...field}
                  valueType={threshold?.valueType ?? 'number'}
                />
              )}
            />
          </Field>
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
}

import { shiftDateYearBackward } from '@/shared/lib/date';
import { formatDate } from '@/shared/lib/formatting/dates';
import { formatRelativeDate } from '@/shared/lib/relativeDate';
import type { ListOption } from '@/stories/Checkbox/CheckList';
import type { RadioGroupItemProps } from '@/stories/RadioButton/RadioGroup';
import type { DateFormat } from '@/stories/ValueFormatters/DateFormatter';

export const getRadioGroupItemPropsHandler = (
  item: ListOption<DateFormat>,
): RadioGroupItemProps => {
  let example = '';
  try {
    example = formatDate(new Date(), item.value);
  } catch {
    if (item.value !== 'Relative') return {};

    example = formatRelativeDate(shiftDateYearBackward(new Date()), new Date());
  }
  return {
    tooltipProps: {
      classes: {
        spanContainer: 'w-max',
      },
      mainText: (
        <p className="text-neutral-850">
          <strong>Eg. </strong>
          {example}
        </p>
      ),
      placement: 'right',
      theme: 'light',
    },
  };
};

import { DashboardFilterObjectTypes } from 'bundles/Shared/entities/dashboard/model';
import { IconsId } from 'types/sre-icons';

export const DASHBOARD_ENTITY_NAME = 'Dashboard';

export const DASHBOARD_FILTER_OBJECT_ICONS: Record<
  DashboardFilterObjectTypes,
  IconsId
> = {
  asset: 'asset',
  segment: 'segments',
  fund: 'funds',
};

export enum DashboardStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export const STATUS_OPTIONS_SETTINGS = {
  [DashboardStatus.DRAFT]: {
    icon: 'draft',
    color: 'text-pumpkin-light-1',
    hoverColor: 'group-hover:text-pumpkin-light-1',
  },
  [DashboardStatus.PUBLISHED]: {
    icon: 'public',
    color: 'text-success-055',
    hoverColor: 'group-hover:text-success-055',
  },
} as const satisfies Record<
  DashboardStatus,
  {
    icon: IconsId;
    color: string;
    hoverColor: string;
  }
>;

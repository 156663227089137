import { easings, useSpring } from 'react-spring';

export function useFade(visible: boolean) {
  const style = useSpring({
    from: {
      opacity: 0,
      pointerEvents: 'none',
    },
    to: {
      opacity: visible ? 1 : 0,
      pointerEvents: visible ? 'all' : 'none',
    },
    config: {
      duration: 300,
      easing: easings.easeInOutCubic,
    },
  });
  return style;
}

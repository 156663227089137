import UnitQuantity from 'bundles/REturn/components/Home/formatters/UnitQuantity';
import { PropsWithChildren } from 'react';
import { CurrencyFormatter } from 'stories/index';
import Table from '../Table';
import { IRowBase, ITableProps } from '../types';
import styles from './FullWhiteTable.module.scss';

type Props<R extends IRowBase> = ITableProps<R>;

function FullWhiteTableTotal({
  value,
  units,
}: {
  value: number;
  units?: number;
}) {
  return (
    <span className="secondary-regular flex justify-end gap-tw-1 pr-tw-4 text-dark-60">
      <span className="text-light-60">Total</span>
      <span>
        <CurrencyFormatter
          classes={{
            wrapper: 'secondary-regular inline-flex',
          }}
          value={value}
        />
        {units != null && <UnitQuantity units={units} />}
      </span>
    </span>
  );
}

function FullWhiteTable<R extends IRowBase>({
  children,
  ...tableProps
}: Props<R> & PropsWithChildren) {
  const hasItems = Boolean(tableProps.items.length) || undefined;
  return (
    <div className="flex flex-col gap-tw-2">
      <Table
        classes={{
          table: hasItems && styles.table,
        }}
        borderLessOutside
        {...tableProps}
      />
      {children}
    </div>
  );
}

FullWhiteTable.Total = FullWhiteTableTotal;

export default FullWhiteTable;

import { useGetApiCapitalUnitPricesQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  GetApiCapitalUnitPricesApiResponse,
  InvestmentObjectUnitPrice,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { assertsQueryDataLoadedBefore } from '@/lib/typeHelpers/redux/rtkApiHelpers';
import { useParams } from '@reach/router';
import { createSelector } from '@reduxjs/toolkit';
import { partition } from 'lodash-es';
import { useMemo } from 'react';

export function useLoadedCapitalUnitPrices() {
  const params = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();
  const { objectId } = params;

  if (!objectId) throw new Error('"objectId" must exist in params');

  const res = useGetApiCapitalUnitPricesQuery({
    investmentObjectId: objectId,
  });
  assertsQueryDataLoadedBefore(res.data);

  return {
    ...res,
    data: res.data,
  };
}

export const useCapitalUnitPricesByInvestmentClass = ({
  investmentClass,
}: {
  investmentClass: InvestmentObjectUnitPrice['investmentClass'];
}) => {
  const params = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();
  const { objectId } = params;

  if (!objectId) throw new Error('"objectId" must exist in params');

  const selectUnitPricesPartitionByInvestmentClass = useMemo(() => {
    return createSelector(
      (res: { data: GetApiCapitalUnitPricesApiResponse | undefined }) =>
        res.data ?? [],
      (data) =>
        partition(data, (res) => res.investmentClass === investmentClass),
    );
  }, [investmentClass]);

  return useGetApiCapitalUnitPricesQuery(
    {
      investmentObjectId: objectId,
    },
    {
      selectFromResult: ({ data, ...result }) => ({
        ...result,
        capitalUnitPricesPartitionByInvestmentClass:
          selectUnitPricesPartitionByInvestmentClass({
            data,
          }),
      }),
    },
  );
};

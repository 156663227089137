import { cn } from '@/shared/lib/css/cn';
import { IHeaderParams } from 'ag-grid-community';
import { usePutApiReportFinancialCategoriesTreeMutation } from 'bundles/Shared/shared/api/reportFinancialCategoriesApiEnhanced';
import { GroupedByTableActions } from 'bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import { FinancialCategory } from 'bundles/Shared/entities/finanicalCategory/model';
import ManageCategoriesModal from 'bundles/Shared/widgets/category/manageCategories/ManageCategoriesModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { currentUserIsSreAdmin } from 'lib/permissions';
import { Button, IconButton } from 'stories';
import {
  SyncWithClientsButton,
  useSyncWithUniformMeta,
} from 'bundles/Shared/features/syncWithUniform';

type Props = IHeaderParams & {
  actions: GroupedByTableActions;
  categoriesTree: FinancialCategory[];
  flaggedSize: number;
  generalLedgersFlagged?: boolean;
};

export function CategoriesHeader({
  displayName,
  categoriesTree,
  flaggedSize,
  actions,
  generalLedgersFlagged,
}: Props) {
  const { openModal } = useModal();
  const { data } = useSyncWithUniformMeta();
  const [updateCategoriesTree] =
    usePutApiReportFinancialCategoriesTreeMutation();

  const handleClick = async () => {
    const res = await openModal(ManageCategoriesModal, {
      categories: categoriesTree,
      classes: {
        headerContent: 'flex grow pr-tw-4',
        header: 'relative',
      },
      header: (
        <div className="header6-bold flex grow items-center justify-between text-neutral-900">
          Manage Categories
          <SyncWithClientsButton entity="category" meta={data} />
        </div>
      ),
    });
    if (res != null) {
      updateCategoriesTree({
        body: {
          tree: res,
        },
      });
    }
  };

  return (
    <div className="flex items-center gap-tw-2">
      {displayName}
      {currentUserIsSreAdmin() && (
        <IconButton
          className="ml-s"
          onClick={handleClick}
          iconName="settings"
        />
      )}
      {flaggedSize > 0 && (
        <Button
          size="xs"
          iconName="flagOn"
          className={cn(
            'h-[20px]',
            generalLedgersFlagged
              ? '!bg-attention-055 !text-white'
              : '!text-attention-055',
          )}
          onClick={actions.onFlagFilterChange}
          iconClasses={cn(
            generalLedgersFlagged
              ? '!bg-attention-055 !text-white'
              : '!text-attention-055',
          )}
          variant="secondary"
        >
          {flaggedSize}
        </Button>
      )}
    </div>
  );
}

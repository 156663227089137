import { useLoadedCapitalPreferredReturn } from '@/bundles/REturn/hooks/useLoadedCapitalPreferredReturn';
import { usePostApiCapitalPreferredReturnsMutation } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  InvestmentIndex,
  PutApiCapitalInvestmentDistributionsByIdApiArg,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { ReactDatePickerWrapper } from '@/stories/FlexibleFilterByPeriods/reactdatepickerWrapper';
import { PreferredReturnSettingsModal } from '@/widgets/return/ui/PreferredReturnSettingsModal';
import {
  DISTRIBUTION_CATEGORIES_OPTIONS,
  RemainingDistribution,
} from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import { useBulkDistributionContext } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/hooks/useBulkDistribution';
import {
  getMonthlyPreferredReturn,
  getQuarterlyPreferredReturn,
} from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import EnterEntriesStep from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/EnterEntriesStep';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import dayjs from 'dayjs';
import { useModal } from '@/shared/lib/hooks/useModal';
import { parseIntOrFloat } from '@/shared/lib/formatting/number';
import { sum } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Field, IconButton, Input, Select } from 'stories';
import { createPeriodFromDate } from 'stories/FlexibleFilterByPeriods/utils';
import { assertIsSelectOption } from 'stories/FormControls/Select/utils';
import StepsFormStepv2 from 'stories/StepsFormStep/StepsFormStepv2';
import { STATIC_DATE_PICKER_PROPS } from '../consts';
import { convertCentsToDollars } from '@/shared/lib/converters';

export interface StepData {
  date: string | null;
  period: string | null;
  periodType: 'monthly' | 'quarterly';
  total: number;
  preferred: number;
  remaining: number;
  remainingDistribution: NonNullishFields<RemainingDistribution>[];
}

interface Props {
  selectedCapitalInvestments: InvestmentIndex[];
  data: StepData;
  setData: React.Dispatch<React.SetStateAction<StepData>>;
  onValidChange: (valid: boolean) => void;
  closeModal: () => void;
}

type Period = 'mtd' | 'qtd';

export const PERIOD_MAP = {
  monthly: 'mtd',
  quarterly: 'qtd',
} as const satisfies Record<
  NonNullable<
    PutApiCapitalInvestmentDistributionsByIdApiArg['body']['period_type']
  >,
  Period
>;

export const PERIOD_MAP_REVERSED = {
  mtd: 'monthly',
  qtd: 'quarterly',
} as const satisfies Record<
  Period,
  NonNullable<
    PutApiCapitalInvestmentDistributionsByIdApiArg['body']['period_type']
  >
>;

export const calculatePreferred = (
  periodType: 'monthly' | 'quarterly',
  classAPercent: number,
  classBPercent: number,
  capitalInvestments: InvestmentIndex[],
) => {
  const getPreferredReturn = (
    ci: InvestmentIndex[],
    investmentClass: 'a' | 'b',
    preparedClassPercent: number,
  ) => {
    const allSum = ci.map((inv) => {
      if (periodType === 'monthly') {
        return getMonthlyPreferredReturn(
          convertCentsToDollars(
            investmentClass == 'a'
              ? inv.totalClassAContributionsAmountCents
              : inv.totalClassBContributionsAmountCents,
          ),
          preparedClassPercent,
        );
      }
      return getQuarterlyPreferredReturn(
        convertCentsToDollars(
          investmentClass == 'a'
            ? inv.totalClassAContributionsAmountCents
            : inv.totalClassBContributionsAmountCents,
        ),
        preparedClassPercent,
      );
    });

    const res = parseIntOrFloat(sum(allSum));

    return res;
  };

  const classAPreferred = getPreferredReturn(
    capitalInvestments,
    'a',
    classAPercent,
  );

  const classBPreferred = getPreferredReturn(
    capitalInvestments,
    'b',
    classBPercent,
  );

  const totalPreferred = classAPreferred + classBPreferred;

  return parseIntOrFloat(totalPreferred);
};

export default function EnterDistributionsStep({
  data,
  onValidChange,
  selectedCapitalInvestments,
  setData,
  closeModal,
}: Props) {
  const {
    hasEmptyKind,
    onAddRemainingDistribution,
    onChangeRemainingDistribution,
    onRemoveRemainingAllocation,
    remainingDistribution,
    remainingKindOptions,
    currentRemainingDistributionAmount,
  } = useBulkDistributionContext();
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();

  const { date, period, periodType } = data;
  const { data: preferredReturn } = useLoadedCapitalPreferredReturn();
  const classAPercent = preferredReturn?.classAPercent ?? 0;
  const classBPercent = preferredReturn?.classBPercent ?? 0;

  const { openModal } = useModal();
  const [createPreferredReturns] = usePostApiCapitalPreferredReturnsMutation();

  const handlePreferredReturnSettings = async () => {
    const res = await openModal(PreferredReturnSettingsModal, {
      capitalInvestmentObject,
    });

    if (!res) return;

    await createPreferredReturns({
      body: {
        class_a_percent: res.preferredReturnPercent,
        class_b_percent: res.preferredReturnPercent,
        investment_object_id: capitalInvestmentObject.id,
      },
    });

    closeModal();
  };

  const calculatePreferredAmount = () => {
    return calculatePreferred(
      periodType,
      classAPercent,
      classBPercent,
      selectedCapitalInvestments,
    );
  };

  const [preferred, setPreferred] = useState(() => {
    calculatePreferredAmount();
  });

  const [total, setTotal] = useState(data.total);

  const remaining = useMemo(() => {
    if (total === 0) return 0;

    const res = parseIntOrFloat(total - preferred);

    if (res < 0) return 0;

    return res;
  }, [total, preferred]);

  const section1Valid = period != null && date != null;

  const section2Valid = useMemo(() => {
    if (total === 0) return false;
    const isValid = total === preferred + remaining;
    return isValid;
  }, [total, preferred, remaining]);

  const hasRemainingDistribution = useMemo(() => remaining > 0, [remaining]);

  const section3Valid = useMemo(() => {
    if (!hasRemainingDistribution) return true;
    return remaining === currentRemainingDistributionAmount && !hasEmptyKind;
  }, [
    remaining,
    preferred,
    hasRemainingDistribution,
    remainingDistribution,
    currentRemainingDistributionAmount,
    hasEmptyKind,
  ]);

  useEffect(() => {
    setPreferred(calculatePreferredAmount());
  }, [periodType]);

  const valid = section1Valid && section2Valid && section3Valid;

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      preferred,
      remaining,
      total,
      remainingDistribution: hasRemainingDistribution
        ? (remainingDistribution.filter(
            (item) => item.amount > 0 || item.kind != null,
          ) as NonNullishFields<RemainingDistribution>[])
        : [],
    }));
    onValidChange(valid);
  }, [section1Valid, section2Valid, section3Valid]);

  return (
    <div className="flex flex-col gap-tw-4 px-tw-4 py-tw-2">
      {/* Update this flow in FE-2130 */}
      {/* {preferredReturn == null && (
        <MultilineAlertContainer classes={{ body: 'secondary-regular !p-tw-2 flex items-center' }}>
          <Icon iconName="info" />
          Please set your preferred return settings and we can calculate these fields for you.
          <Button onClick={handlePreferredReturnSettings} variant="warning" size="s" className="ml-auto">Set Preferred</Button>
        </MultilineAlertContainer>
      )} */}
      <StepsFormStepv2
        title="Enter Date & Quarter period"
        stepNumber={1}
        required
        activated={section1Valid}
      >
        <div className="flex items-center gap-tw-4">
          <Field labelText="Date" required className="w-[272px]">
            <ReactDatePicker
              {...STATIC_DATE_PICKER_PROPS}
              selected={date ? dayjs(date).toDate() : null}
              onChange={(selectedDate) =>
                setData((prevData) => ({
                  ...prevData,
                  period:
                    data.period == null && selectedDate != null
                      ? createPeriodFromDate(selectedDate)
                      : prevData.period,
                  date: selectedDate
                    ? createPeriodFromDate(selectedDate)
                    : null,
                }))
              }
              customInput={<Input leftIcon="calendar" size="m" />}
            />
          </Field>
          <Field labelText="Period" required className="w-[272px]">
            <div className="grid">
              <ReactDatePickerWrapper
                periodType={PERIOD_MAP[periodType]}
                setPeriodType={(shortPeriodType) => {
                  setData((prev) => ({
                    ...prev,
                    periodType: PERIOD_MAP_REVERSED[shortPeriodType],
                  }));
                }}
                selected={period ? dayjs(period).toDate() : null}
                onChange={(selectedDate) =>
                  setData({
                    ...data,
                    period: selectedDate
                      ? createPeriodFromDate(selectedDate)
                      : null,
                  })
                }
              />
            </div>
          </Field>
        </div>
      </StepsFormStepv2>

      <StepsFormStepv2
        title="Enter Total Distribution"
        stepNumber={2}
        activated={section2Valid}
        required
      >
        <div className="flex items-center gap-tw-4">
          <Field labelText="Total Distribution" required className="w-[272px]">
            <CurrencyInputNumber
              selectOnFocus
              value={total}
              placeholder="Enter Value"
              allowNegative={false}
              onValueChange={(value) => setTotal(value)}
            />
          </Field>
          <Field
            labelText="Preferred Distribution"
            required
            className="w-[272px]"
          >
            <CurrencyInputNumber
              selectOnFocus
              value={preferred}
              placeholder="Enter Value"
              allowNegative={false}
              onValueChange={(value) => setPreferred(value)}
            />
          </Field>
          <Field labelText="Remaining Distribution" className="w-[272px]">
            <CurrencyInputNumber
              selectOnFocus
              disabled
              value={remaining}
              placeholder="Enter Value"
              allowNegative={false}
            />
          </Field>
        </div>
      </StepsFormStepv2>
      {hasRemainingDistribution && (
        <EnterEntriesStep
          title="Allocate Remaining Distribution Between types"
          stepNumber={3}
          activated={section3Valid}
          total={remaining}
          currentTotal={currentRemainingDistributionAmount}
          classes={{
            content: 'flex flex-col gap-tw-4 items-start',
          }}
        >
          {remainingDistribution.map((item, idx) => (
            <div key={item.id} className="flex items-center gap-tw-4">
              <Field
                labelText="Distribution Amount"
                required
                className="w-[272px]"
              >
                <CurrencyInputNumber
                  selectOnFocus
                  value={item.amount}
                  placeholder="Enter Value"
                  allowNegative={false}
                  onValueChange={(value) =>
                    onChangeRemainingDistribution({
                      ...item,
                      amount: value ?? 0,
                    })
                  }
                />
              </Field>

              <Field labelText="Kind" required className="w-[272px]">
                <Select
                  placeholder="Select Kind"
                  size="m"
                  options={remainingKindOptions}
                  selected={DISTRIBUTION_CATEGORIES_OPTIONS.find(
                    (c) => c.id === item.kind,
                  )}
                  onSelectedChange={(e) => {
                    if (e == null)
                      return onChangeRemainingDistribution({
                        ...item,
                        kind: null,
                      });

                    assertIsSelectOption(e);
                    onChangeRemainingDistribution({
                      ...item,
                      kind: e.id,
                    });
                  }}
                />
              </Field>

              {idx !== 0 && (
                <div className="flex self-end py-[2.5px]">
                  <IconButton
                    variant="secondary"
                    size="l"
                    onClick={() => onRemoveRemainingAllocation(item.id)}
                    iconName="closeSmall"
                  />
                </div>
              )}
            </div>
          ))}
          <Button
            size="xs"
            variant="secondary"
            onClick={onAddRemainingDistribution}
            disabled={
              hasEmptyKind || remaining === currentRemainingDistributionAmount
            }
          >
            Add another
          </Button>
        </EnterEntriesStep>
      )}
    </div>
  );
}

import { LegalEntitiesField } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesField';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { PermissionList } from 'bundles/Shared/components/Permissions/PermissionList';
import {
  DASHBOARD_FILTER_OBJECT_ICONS,
  DASHBOARD_OBJECT_TYPES,
  DashboardFilterObject,
  DashboardFilterObjectTypes,
  ReportDashboardType,
  useReportEagleEyeDashboardAssetsQuery,
  useReportObjectDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';
import { DashboardSelectionItem } from 'bundles/Shared/features/dashboard/create/ui/DashboardSelectionItem';
import { ObjectSelectionModal } from 'bundles/Shared/features/dashboard/create/ui/ObjectSelectionModal';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import {
  Control,
  Controller,
  FormProvider,
  useController,
  useFormContext,
} from 'react-hook-form';
import { IconButton, Input } from 'stories';
import { Field, FieldsContainer } from 'stories/Field/Field';
import {
  DashboardCreationFormModel,
  DEFAULT_DASHBOARD_PERMITTED,
} from '../config';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { useSetDashboardObjectsEffect } from 'bundles/Shared/features/dashboard/create';

const getIsImplemented = (
  dashboardType: ReportDashboardType,
  type: DashboardFilterObjectTypes,
) => {
  switch (dashboardType) {
    case ReportDashboardType.OBJECT: {
      return type === 'asset';
    }
    case ReportDashboardType.EAGLE_EYE: {
      return type !== 'fund';
    }
    case ReportDashboardType.COMPARISON_MODE: {
      return type === 'asset';
    }
    default: {
      return false;
    }
  }
};

export const ObjectSelectionContainer = ({
  children,
}: React.PropsWithChildren) => {
  return <div className="grid grid-cols-3 gap-tw-4">{children}</div>;
};

export const ObjectSelectionItem = ({
  type,
  isImplemented,
  objects,
  control,
}: {
  type: DashboardFilterObjectTypes;
  control: Control<DashboardCreationFormModel>;
  objects: DashboardFilterObject[];
  isImplemented?: boolean;
}) => {
  const { value: isOpen, toggle: toggleIsOpen } = useBoolean();
  const { field } = useController({
    control,
    name: type === 'fund' ? 'asset_ids' : `${type}_ids`,
  });

  return (
    <div className={cn('h-min rounded-xl bg-neutral-100 p-tw-1')}>
      <DashboardSelectionItem
        disabled={!isImplemented}
        selected={isImplemented && field.value?.length > 0}
        iconName={DASHBOARD_FILTER_OBJECT_ICONS[type]}
        onClick={toggleIsOpen}
        label={
          <div className="flex flex-col gap-tw-1">
            {capitalize(type)}
            {!isImplemented && (
              <span className="secondary-regular">Coming soon</span>
            )}
          </div>
        }
      />
      {isImplemented && field.value?.length > 0 && (
        <div className="flex items-center justify-between p-tw-2">
          <div className="flex flex-col">
            <span className="secondary-semibold text-neutral-550">
              INCLUDED
            </span>
            <span className="inline-semibold text-neutral-800">
              {field.value.length}{' '}
              {pluralize(capitalize(type), field.value.length)}
            </span>
          </div>
          <IconButton iconName="edit" onClick={toggleIsOpen} />
        </div>
      )}
      {isOpen && (
        <ObjectSelectionModal
          onClose={toggleIsOpen}
          objectType={type}
          defaultObjectIds={field.value}
          objects={objects}
          onChange={(objectIds) => field.onChange(objectIds)}
        />
      )}
    </div>
  );
};

export const ObjectSelection = {
  Container: ObjectSelectionContainer,
  Item: ObjectSelectionItem,
} as const;

export function DashboardCreationForm() {
  const { assetObjects } = useReportObjectDashboardAssetsQuery();

  const { objects } = useReportEagleEyeDashboardAssetsQuery();
  const methods = useFormContext<DashboardCreationFormModel>();
  const { register, control, watch } = methods;

  const dashboardType = watch('dashboard_type');

  const dashboardObjects = useMemo(
    () =>
      dashboardType === ReportDashboardType.EAGLE_EYE ? objects : assetObjects,
    [assetObjects, objects, dashboardType],
  );

  const objectIds = useMemo(
    () => ({
      assetIds:
        dashboardType === ReportDashboardType.OBJECT ||
        dashboardType === ReportDashboardType.COMPARISON_MODE
          ? mapListToIds(assetObjects)
          : mapListToIds(objects.filter((o) => o.type === 'asset')),
      segmentIds:
        dashboardType === ReportDashboardType.EAGLE_EYE
          ? mapListToIds(objects.filter((o) => o.type === 'segment'))
          : [],
    }),
    [dashboardType, objects, assetObjects],
  );

  useSetDashboardObjectsEffect(objectIds);

  return (
    <FormProvider {...methods}>
      <FieldsContainer className="w-[532px] rounded-2xl bg-neutral-100 p-tw-4">
        <Field labelText="Name" required>
          <Input {...register('name')} />
        </Field>
        <SectionFieldsContainer>
          <Controller
            control={control}
            name="dashboard_type"
            render={({ field }) => (
              <SectionField
                className="gap-tw-4"
                labelText="Choose your Dashboard type"
                required
              >
                <div className="grid grid-cols-3 gap-tw-4">
                  <DashboardSelectionItem
                    selected={field.value === ReportDashboardType.EAGLE_EYE}
                    onClick={() =>
                      field.onChange(ReportDashboardType.EAGLE_EYE)
                    }
                    label="Eagle Eye"
                    iconName="eagleEyeDashboardGreyscale"
                  />
                  <DashboardSelectionItem
                    selected={field.value === ReportDashboardType.OBJECT}
                    onClick={() => field.onChange(ReportDashboardType.OBJECT)}
                    label="Object Level"
                    iconName="objectLevelDashboardGreyscale"
                  />
                  <DashboardSelectionItem
                    selected={
                      field.value === ReportDashboardType.COMPARISON_MODE
                    }
                    onClick={() =>
                      field.onChange(ReportDashboardType.COMPARISON_MODE)
                    }
                    label="Comparison"
                    iconName="compare"
                  />
                </div>
              </SectionField>
            )}
          />
          <SectionField
            labelText="Select an object"
            required
            classes={{ itemsContainer: 'gap-tw-4' }}
          >
            <ObjectSelection.Container>
              {DASHBOARD_OBJECT_TYPES.map((type) => (
                <ObjectSelection.Item
                  key={type}
                  type={type as DashboardFilterObjectTypes}
                  objects={dashboardObjects.filter((o) => o.type === type)}
                  control={control}
                  isImplemented={getIsImplemented(dashboardType, type)}
                />
              ))}
            </ObjectSelection.Container>
            <LegalEntitiesField
              control={control}
              assetsName="asset_ids"
              legalEntitiesName="excluded_legal_entity_ids"
            />
          </SectionField>
        </SectionFieldsContainer>
        <SectionField required labelText="Permissions">
          <Controller
            control={control}
            name="permitted"
            render={({ field }) => (
              <PermissionList
                type="dashboard"
                hideActions={false}
                permitted={field.value ?? DEFAULT_DASHBOARD_PERMITTED}
                onSubmit={(permitted) => field.onChange(permitted)}
                productName={REPORT_PRODUCT_NAME}
                placeholderRenderer="Access"
              />
            )}
          />
        </SectionField>
      </FieldsContainer>
    </FormProvider>
  );
}

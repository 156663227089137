import { useModal } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import { Button, IconButton, Popover, PseudoLink } from 'stories/index';
import {
    IAllRules,
    IUserFinancialCategoryRule,
    IUserRoleFinancialCategoryRule,
} from 'types/ReportTableConfig';
import { CategoryRulesModal } from './CategoryRulesModal';
import { PopoverContent } from './PopoverContent';
import styles from './styles.module.scss';

interface Props {
  allRules: IAllRules;
  categoryPath: string;
  label: string;
  handleRoleRulesChange: (
    newRules: IUserRoleFinancialCategoryRule[],
    categoryPath: string,
  ) => void;
  handleUserRulesChange: (
    newRules: IUserFinancialCategoryRule[],
    categoryPath: string,
  ) => void;
  handleHasChanges: (changes: boolean) => void;
}

export function CategoryRules({
  allRules,
  label,
  handleRoleRulesChange,
  handleUserRulesChange,
  handleHasChanges,
  categoryPath,
}: Props) {
  const allRulesLen = allRules.roleRules.length + allRules.userRules.length;
  const { openModal } = useModal();

  const handleSetRules = async () => {
    const res = await openModal(CategoryRulesModal, {
      categoryPath,
      allRules,
      label,
    });

    if (res) {
      handleHasChanges(true);
      const allRulesFromModal = res;
      handleUserRulesChange(allRulesFromModal.userRules, categoryPath);
      handleRoleRulesChange(allRulesFromModal.roleRules, categoryPath);
    }
  };

  const popover = (
    <Popover
      template={<PopoverContent {...allRules} />}
      maxWidth="400px"
      trigger="mouseenter"
      touch={false}
      hideOnExpandedAreaClick
      appendTo={() => document.body}
      placement="bottom"
      arrowPosition="center"
      classes={{
        spanContainer: 'd-inline-flex mnw-0',
      }}
    >
      <PseudoLink className="secondary-regular text-ellipsis">
        {allRulesLen} {pluralize('Rule', allRulesLen)}
      </PseudoLink>
    </Popover>
  );

  if (allRulesLen) {
    return (
      <div className={styles.rulesWrapper}>
        {popover}
        <IconButton
          variant="white"
          className="self-start"
          iconName="edit"
          onClick={handleSetRules}
        />
      </div>
    );
  }

  return (
    <Button
      onClick={handleSetRules}
      variant="secondary"
      size="xs"
      iconName="link"
      iconClasses="text-neutral-450"
    >
      Set Rules
    </Button>
  );
}

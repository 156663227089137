import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCoreReportBuilderTemplates: build.query<
      GetApiCoreReportBuilderTemplatesApiResponse,
      GetApiCoreReportBuilderTemplatesApiArg
    >({
      query: () => ({ url: `/api/core/report_builder/templates` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreReportBuilderTemplatesGeneratedApi };
export type GetApiCoreReportBuilderTemplatesApiResponse =
  /** status 200 success */ ReportBuilderTemplateDto[];
export type GetApiCoreReportBuilderTemplatesApiArg = void;
export type ReportBuilderTemplateDto = {
  id: string;
  name: string;
  tags: string[];
};

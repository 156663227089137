import React from 'react';
import {
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import {
  getAvailableObjectsCount,
  IUserTableColumnsActions,
} from 'bundles/UserManagement/components/table/userTableColumns';
import { IUser } from 'types/User';
import { LinkButton } from 'stories';

interface Props {
  userAccessLevels: any;
  user: IUser;
  actions: IUserTableColumnsActions;
}

function ManageCell({ user, userAccessLevels, actions }: Props) {
  const userAccessLevel = userAccessLevels[user.id];
  const canManageAssetsCount = userAccessLevel?.canManageAssetsCount;
  const canManageFundsCount = userAccessLevel?.canManageFundsCount;

  const availableAssetsCount = getAvailableObjectsCount(
    userAccessLevel.canManageAllAssets,
    canManageAssetsCount,
  );

  const availableFundsCount = getAvailableObjectsCount(
    userAccessLevel.canManageAllFunds,
    canManageFundsCount,
  );

  return (
    <>
      <div>
        {availableAssetsCount !== false ? (
          <LinkButton onClick={() => actions.edit(user, 'assets')}>
            {availableAssetsCount}
          </LinkButton>
        ) : (
          <>&ndash;</>
        )}
      </div>
      {currentUserAllowedTo('view', FUND_PORTAL_PRODUCT_NAME) && (
        <div>
          {availableFundsCount !== false ? (
            <LinkButton onClick={() => actions.edit(user, 'funds')}>
              {availableFundsCount}
            </LinkButton>
          ) : (
            <>&ndash;</>
          )}
        </div>
      )}
    </>
  );
}

export default ManageCell;

import {
  EditableEmailConnectionProps,
  EditableSftpConnectionProps,
  EmailConnectionProps,
  SftpConnectionProps,
} from 'bundles/Shared/features/connection/crud/ui/ConnectionCrudModal';

export const EMAIL_REQUIRED_FIELD = [
  'name',
  'companyName',
  'erpSystem',
  'reportType',
  'type',
  'emailPostfix',
] as const satisfies readonly (keyof EmailConnectionProps)[];

export const EDITABLE_EMAIL_REQUIRED_FIELD = [
  'name',
  'reportType',
] as const satisfies readonly (keyof EditableEmailConnectionProps)[];
export const SFTP_REQUIRED_FIELD = [
  'name',
  'companyName',
  'erpSystem',
  'reportType',
  'type',
  'username',
  'host',
  'password',
] as const satisfies readonly (keyof SftpConnectionProps)[];

export const EDITABLE_SFTP_REQUIRED_FIELD = [
  'name',
  'reportType',
] as const satisfies readonly (keyof EditableSftpConnectionProps)[];

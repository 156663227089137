import React from 'react';
import { CssVar } from '@/shared/config/cssVar';
import { capitalize, startCase } from 'lodash-es';
import { Badge } from '@/stories';

interface Props extends React.ComponentProps<typeof Badge> {
  kind: string;
}

export function DistributionKindBadge({ kind, ...props }: Props) {
  return (
    <Badge backgroundColor={CssVar.light} textColor={CssVar.dark60} {...props}>
      <span className="secondary-regular">{startCase(capitalize(kind))}</span>
    </Badge>
  );
}

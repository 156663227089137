import * as d3 from 'd3';

const CONTRAST_THRESHOLD = 165;

export function luma(color: string) {
  // color can be a hx string or an array of RGB values 0-255
  const rgb = d3.color(color)!.rgb().clamp();
  // https://en.wikipedia.org/wiki/Relative_luminance
  return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
}

export function contrastingTextColor(
  color: string,
  options: {
    dark?: string;
    light?: string;
  } = { dark: '#000', light: '#fff' },
) {
  if (d3.color(color) == null) {
    return options.dark;
  }
  return luma(color) >= CONTRAST_THRESHOLD ? options.dark : options.light;
}

export const convertGradientArrayToCss = (
  gradient: readonly string[] | string[],
): string => {
  return `linear-gradient(to right, ${gradient.join(', ')})`;
};

export const convertColorToHex = (color: string) => {
  return d3.color(color)?.formatHex();
};

import { CurrencyFormatter, IconButton, InlineObject } from 'stories/index';
import { ReportLineItem } from 'bundles/REturn/actions/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import { SelectedItemsWrapper } from './SelectedItems';

import { convertCentsToDollars } from '@/shared/lib/converters';

export function SelectedTransactions({
  transactions,
  onRemoveItem,
  ...props
}: {
  totalCountLabel: string;
  transactions: ReportLineItem[];
  onRemoveItem: (id: ReportLineItem['id']) => void;
  hideTotalInfo?: boolean;
}) {
  return (
    <SelectedItemsWrapper {...props} itemsLen={transactions.length}>
      {transactions.map((item) => (
        <div
          key={item.id}
          className="grid grid-cols-[3fr_2fr_max-content] bg-white"
        >
          <div className="flex flex-col gap-tw-2 p-tw-4 pb-tw-3">
            <p className="inline-regular text-light-90">
              {formatDate(item.date, 'MMM DD, YYYY')}
            </p>
            <p className="inline-regular text-light-90">
              <InlineObject
                fallbackNode=" "
                iconName="bag"
                object={item.vendorEntity?.name}
              />
            </p>
          </div>
          <CurrencyFormatter
            classes={{
              wrapper: 'p-tw-4 justify-end items-start',
              value: 'inline-regular text-dark-60',
            }}
            value={convertCentsToDollars(item.amountCents)}
          />
          <IconButton
            onClick={() => onRemoveItem(item.id)}
            iconName="closeSmall"
            variant="white"
            className="m-tw-4"
          />
        </div>
      ))}
    </SelectedItemsWrapper>
  );
}

import React from 'react';
import { ProjectCard } from 'stories';
import LegalEntitiesIconWithTooltip from 'bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import FavoriteCardIcon from 'stories/ProjectCard/FavoriteCardIcon';
import { IDevelopmentProject } from 'bundles/REconcile/types/IDevelopmentProject';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import StatisticLine from 'stories/filterCard/statistic/StatisticLine';
import { CssVar } from '@/shared/config/cssVar';
import { PercentFormatter } from 'stories/ValueFormatters/PercentFormatter';
import { cn } from '@/shared/lib/css/cn';
import { useProjectCardResponsive } from 'stories/ProjectCard/hooks';

type Props = Omit<React.ComponentProps<typeof ProjectCard>, 'header'> &
  Pick<
    React.ComponentProps<typeof LegalEntitiesIconWithTooltip>,
    'legalEntities'
  > &
  Pick<
    IDevelopmentProject,
    'percentOfCompletion' | 'name' | 'latestBudgetAmount' | 'fundedToDate'
  > & {
    onFavoriteClick: VoidFunction;
    favorite?: boolean;
  };

const ValueCard = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => (
  <div className="flex flex-col gap-tw-0.5">
    <div>{value}</div>
    <span className="secondary-regular whitespace-nowrap text-light-60">
      {label}
    </span>
  </div>
);

const FORMATTER_CLASSES = {
  wrapper: 'body-semibold',
  value: 'text-light-90',
};

function ReconcileDevelopmentProjectCard({
  legalEntities,
  onFavoriteClick,
  favorite,
  name,
  percentOfCompletion: percentOfCompletionCoeff,
  latestBudgetAmount,
  fundedToDate,
  ...props
}: Props) {
  const { mediumWidth } = useProjectCardResponsive();
  const percentOfCompletion = Number(percentOfCompletionCoeff);
  const header = (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-tw-2">
        <span className="inline-semibold text-dark-60">{name}</span>
      </div>
      <div className="flex items-center gap-tw-2">
        <LegalEntitiesIconWithTooltip legalEntities={legalEntities} />
        <FavoriteCardIcon
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onFavoriteClick();
          }}
          selected={favorite}
        />
      </div>
    </div>
  );

  return (
    <ProjectCard className="cursor-pointer" header={header} {...props}>
      <div className={cn('flex', mediumWidth ? 'gap-tw-4' : 'gap-tw-6')}>
        <ValueCard
          label="Revised Budget"
          value={
            <CurrencyFormatter
              classes={FORMATTER_CLASSES}
              abbreviate
              value={latestBudgetAmount}
            />
          }
        />
        <ValueCard
          label="Funded to Date"
          value={
            <CurrencyFormatter
              classes={FORMATTER_CLASSES}
              abbreviate
              value={fundedToDate}
            />
          }
        />
        <div className="flex w-[106px] flex-col gap-tw-2">
          <div className="flex items-end gap-tw-1">
            <PercentFormatter
              classes={{
                wrapper: 'inline-semibold',
              }}
              value={percentOfCompletionCoeff}
            />
            <span className="label-regular text-light-60">Completed</span>
          </div>
          <StatisticLine
            statistics={{
              full: {
                value: percentOfCompletion,
                color: CssVar.green,
                hoverColor: CssVar.green,
              },
              rest: {
                value: 100 - percentOfCompletion,
                color: CssVar.light10,
              },
            }}
          />
        </div>
      </div>
    </ProjectCard>
  );
}

export default ReconcileDevelopmentProjectCard;

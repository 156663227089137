import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Delimiter from '@editorjs/delimiter';
import CheckList from '@editorjs/checklist';
import ImageTool from '@symmetre-inc/image';
import http, { customerName } from '@/lib/http';
import axios from 'axios';
import { getToken } from '@/lib/currentUser';

const apiClient = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
    'X-Customer': customerName,
    Authorization: `Bearer ${getToken()}`,
  },
});

const getImageData = async (url: string) => {
  return apiClient.get(url).then((response) => {
    return fetch(response.data.url, {
      method: 'GET',
      headers: { 'Content-Type': 'image/*' },
    })
      .then((r) => r.blob())
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  });
};

export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  checkList: CheckList,
  list: List,
  header: Header,
  delimiter: Delimiter,
  image: {
    class: ImageTool,
    config: {
      downloader: {
        async download(url: string, file: any) {
          const blobUrl = await getImageData(url);
          return blobUrl;
        },
      },
      uploader: {
        async uploadByFile(file: File) {
          const presign = await http.get(
            `/recap/cache_file_storage/presign?file_name=${file.name}&type=${file.type}`,
          );
          const { url, fields } = await presign.json();
          const formData = new FormData();

          Object.entries({ ...fields, file }).forEach(([key, value]) => {
            formData.append(key, value);
          });

          const uploadImageResponse = await axios.post(url, formData);

          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(
            uploadImageResponse.data,
            'text/xml',
          );
          const key =
            xmlDoc.getElementsByTagName('Key')[0].childNodes[0].nodeValue;

          const createImageResponse = await http.post('/recap/images', {
            content_type: file.type,
            filename: file.name,
            key,
            size: file.size,
          });

          const createdImage = await createImageResponse.json();

          const returnedData = {
            success: 1,
            file: {
              url: createdImage.url,
            },
          };

          return returnedData;
        },
      },
    },
  },
};

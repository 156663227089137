import AssetDashboardPage from '@/pages/assets/page';
import { PortfolioPage } from '@/pages/portfolio';
import { currentUserAllowedToSeePortfolioPage } from '@/pages/portfolio/permissions';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import AdvisorDashboardContainer from 'bundles/AdvisorDashboard/containers/AdvisorDashboardContainer';
import EagleEyeDashboard from 'bundles/Scoreboard/EagleEyeDashboard';
import PipelineDashboard from 'bundles/Scoreboard/Pipeline/components/PipelineDashboard';
import { useGenericData } from 'bundles/Shared/GenericContext';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsAdvisor,
  PIPELINE_PRODUCT_NAME,
  REPORT_PRODUCT_NAME,
} from 'lib/permissions';

export const isActiveOnHomepage = (path?: string) => {
  if (path == null || path === '') {
    return false;
  }

  if (currentUserAllowedTo('manage', REPORT_PRODUCT_NAME)) {
    return ROUTES_ROOT.scoreboards.scoreboard.fullPath.includes(path);
  }
  if (currentUserAllowedTo('view', PIPELINE_PRODUCT_NAME)) {
    return ROUTES_ROOT.scoreboards.pipeline.fullPath.includes(path);
  }
  return false;
};

const HomePage = () => {
  const { genericData } = useGenericData();
  const { currentUser, mainNavigation } = genericData!;

  if (currentUserAllowedToSeePortfolioPage()) {
    return <PortfolioPage />;
  }

  if (currentUserAllowedTo('view', REPORT_PRODUCT_NAME)) {
    if (
      mainNavigation.scoreboardsSubItems.some(
        ({ slug }: { slug: string }) => slug === 'report',
      )
    ) {
      return <EagleEyeDashboard slug="report" />;
    }
    if (mainNavigation.scoreboardsSubItems.length > 0) {
      return (
        <EagleEyeDashboard slug={mainNavigation.scoreboardsSubItems[0].slug} />
      );
    }
  }

  if (currentUserAllowedTo('view', PIPELINE_PRODUCT_NAME)) {
    return <PipelineDashboard />;
  }

  if (currentUserIsAdvisor()) {
    return <AdvisorDashboardContainer advisorId={currentUser.id} />;
  }

  if (currentUserAllowedTo('view', ASSET_PORTAL_PRODUCT_NAME)) {
    return <AssetDashboardPage />;
  }

  // TODO: define what would we do if nothing matched
  return <div>You don't have any permissions</div>;
};

export default HomePage;

import {
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
} from 'lib/permissions';

export const usePermissions = () => {
  const canConfigureUserRoles =
    currentUserIsSreAdmin() || currentUserIsSuperAdmin();
  const canConfigureTableBuilder = currentUserIsSreAdmin();

  return {
    canConfigureUserRoles,
    canConfigureTableBuilder,
  };
};

import React, { useRef } from 'react';
import { useAmchart } from 'lib/amcharts/useAmchart';
import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import { IDevelopmentProjectDetailed } from 'bundles/REconcile/types/IDevelopmentProject';
import { startCase } from 'lodash-es';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { getCssVariableByAccessor } from 'lib/helpers';
import { CssVar } from '@/shared/config/cssVar';
import { COLOR_SET, getReturnDashboardTheme } from 'lib/amcharts/utils';

interface Props {
  data: IDevelopmentProjectDetailed['percentOfCompletionByCategories'];
}

function SolidGauge({ data }: Props) {
  const ref = useRef(null);
  useAmchart(
    ref,
    (root) => {
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);

      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          innerRadius: am5.percent(20),
          startAngle: -90,
          endAngle: 180,
        }),
      );

      const chartEntries = Object.entries(data).map(([key, value], index) => ({
        category: key === 'total' ? 'TOTAL' : startCase(key),
        value: Number(value),
        full: 100,
        columnSettings: {
          fill:
            key === 'total'
              ? am5.color(getCssVariableByAccessor(CssVar.light90))
              : am5.color(getCssVariableByAccessor(CssVar.light50)),
          fontWeight: key === 'total' ? 600 : 500,
        },
      }));
      const categoriesSet = chartEntries.map((e) => e.category);

      const cursor = chart.set('cursor', am5radar.RadarCursor.new(root, {}));

      cursor.lineY.set('visible', false);

      const xRenderer = am5radar.AxisRendererCircular.new(root, {});

      xRenderer.labels.template.setAll({
        radius: 5,
        fill: am5.color(getCssVariableByAccessor(CssVar.light60)),
      });

      xRenderer.grid.template.setAll({
        forceHidden: true,
      });

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xRenderer,
          min: 0,
          max: 100,
          strictMinMax: true,
          numberFormat: "#'%'",
          tooltip: am5.Tooltip.new(root, {}),
        }),
      );

      const yRenderer = am5radar.AxisRendererRadial.new(root, {
        minGridDistance: 10,
      });

      yRenderer.labels.template.setAll({
        centerX: am5.p100,
        fontSize: 12,
        templateField: 'columnSettings',
      });

      yRenderer.grid.template.setAll({
        forceHidden: true,
      });

      const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: yRenderer,
        }),
      );

      yAxis.data.setAll(chartEntries);

      const series1 = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          xAxis,
          yAxis,
          clustered: false,
          valueXField: 'full',
          categoryYField: 'category',
          fill: root.interfaceColors.get('alternativeBackground'),
        }),
      );

      series1.columns.template.setAll({
        width: am5.p100,
        fillOpacity: 0.08,
        strokeOpacity: 0,
        cornerRadius: 20,
      });

      series1.data.setAll(chartEntries);

      const series2 = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          xAxis,
          yAxis,
          clustered: false,
          valueXField: 'value',
          categoryYField: 'category',
        }),
      );

      series2.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0,
        tooltipText: '{category}: {valueX}%',
        cornerRadius: 20,
      });

      series2.columns.template.adapters.add('fill', (fill, target) => {
        const index = categoriesSet.findIndex(
          (value) => value === target.dataItem?.get('categoryY'),
        );
        return am5.ColorSet.new(root, {
          colors: ['#118068', '#FEAF96', '#E64821', '#FEBE46'].map((c) =>
            am5.color(c),
          ),
        }).getIndex(index);
      });

      series2.data.setAll(chartEntries);
    },
    [data],
  );
  return <div className="h-[230px] w-full" ref={ref} />;
}

export default SolidGauge;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import {
  ISignatureField,
  TSignatureKinds,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

interface ISignatureFieldsResponse {
  items: ISignatureField[];
  meta: {
    kinds: TSignatureKinds;
  };
}

interface IUpdateApproversListBody {
  signature_fields_attributes: Pick<ISignatureField, 'id' | 'kind'> &
    {
      _destroy: boolean;
    }[];
}

export const reconcileSignatureFieldsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/settings/reconcile/legal_entities`,
  }),
  reducerPath: 'reconcileSignatureField',
  tagTypes: ['SignatureField'],
  endpoints: (build) => ({
    getSignatureFields: build.query<
      ISignatureFieldsResponse,
      {
        legalEntityId: string;
      }
    >({
      query: (params) => `/${params.legalEntityId}/signature_fields`,
      providesTags: ['SignatureField'],
    }),
    updateFieldsList: build.mutation<
      ISignatureFieldsResponse,
      {
        legalEntityId: string;
        body: IUpdateApproversListBody;
      }
    >({
      query: (params) => ({
        url: `/${params.legalEntityId}/signature_fields/update_list`,
        method: 'PUT',
        body: JSON.stringify(params.body),
      }),
      transformResponse: (response) => {
        toastr.success('Fields successfully updated');
        return response.data as ISignatureFieldsResponse;
      },
      invalidatesTags: ['SignatureField'],
    }),
  }),
});

export const { useGetSignatureFieldsQuery, useUpdateFieldsListMutation } =
  reconcileSignatureFieldsApi;

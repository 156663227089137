import { createContext, useContext, useState } from 'react';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { ReadonlyDeep } from 'type-fest';

export interface TabsContextValue<T> {
  tab: T | undefined;
  setTab: React.Dispatch<React.SetStateAction<T | undefined>>;
}

const TabsContext = createContext<
  TabsContextValue<IThinTabItem | ReadonlyDeep<IThinTabItem>>
>({} as TabsContextValue<IThinTabItem | ReadonlyDeep<IThinTabItem>>);

export const useTabs = <T extends IThinTabItem | ReadonlyDeep<IThinTabItem>>(
  tabsArgs: T[] | readonly T[] | (() => T[] | readonly T[]),
  defaultTab?: T | number,
) => {
  const tabs = typeof tabsArgs === 'function' ? tabsArgs() : tabsArgs;

  const [tab, setTab] = useState<T | undefined>(
    typeof defaultTab === 'number' ? tabs[defaultTab] : defaultTab,
  );

  const thinTabGroupProps: Pick<
    React.ComponentProps<typeof ThinTabGroup>,
    'items' | 'selectedItem' | 'onSelectedItemChange'
  > = {
    items: tabs as IThinTabItem[],
    onSelectedItemChange: (item) => setTab(item as unknown as T),
    selectedItem: tab as IThinTabItem,
  };

  return {
    thinTabGroupProps,
    tab,
    setTab,
  };
};

export const useTabsContext = <
  T extends IThinTabItem | ReadonlyDeep<IThinTabItem>,
>(): TabsContextValue<T> => {
  return useContext<TabsContextValue<T>>(TabsContext as any);
};

export const TabsContextProvider = ({
  children,
  value,
}: React.PropsWithChildren<{
  value: TabsContextValue<IThinTabItem | ReadonlyDeep<IThinTabItem>>;
}>) => {
  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

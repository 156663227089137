import { mustFind } from 'lib/typeHelpers';
import { ILegalEntitiableWithLegalEntities } from 'types/ReportTableSegment';
import { ListOption } from 'stories/Checkbox/CheckList';

export function getNewLegalEntitiables({
  prevItems,
  itemOptions,
  allItems,
}: {
  prevItems: ILegalEntitiableWithLegalEntities[];
  itemOptions: ListOption<ILegalEntitiableWithLegalEntities['id']>[];
  allItems: ILegalEntitiableWithLegalEntities[];
}) {
  const newItems = itemOptions.map(({ value: optionId }) => {
    const prevItem = prevItems.find(
      ({ id: prevItemId }) => prevItemId === optionId,
    );

    return prevItem ?? mustFind(allItems, (item) => item.id === optionId);
  });
  return newItems;
}

/**
 * Asserts that the input `input` is of type `Output` using the `validate` function.
 * Throws an error with the optional `message` if the assertion fails.
 *
 * @template Output - The expected output type.
 * @template Input - The input type.
 * @param {Input} input - The input value to assert.
 * @param {(input: Input) => input is Output} validate - The function that validates the input type.
 * @param {string} [message] - The optional error message to throw if the assertion fails.
 * @throws {Error} If the assertion fails.
 * @returns {asserts input is Output} A type predicate that asserts that `input` is of type `Output`.
 */
export function assertsType<Output extends Input, Input = unknown>(
  input: Input,
  validate: boolean,
  message?: string,
): asserts input is Output;
export function assertsType<Output extends Input, Input = unknown>(
  input: Input,
  validate: (input: Input) => boolean,
  message?: string,
): asserts input is Output;
export function assertsType<Output extends Input, Input = unknown>(
  input: Input,
  validate: ((input: Input) => boolean) | boolean,
  message?: string,
): asserts input is Output {
  if (typeof validate === 'boolean' ? !validate : !validate(input)) {
    throw new Error(`Type assertion failed${message ? `: ${message}` : ''}`);
  }
}

/**
 * Asserts that a value is truthy, throwing an error if it is falsy.
 * @param value - The value to assert.
 * @param message - An optional message to include in the error.
 * @throws {Error} If the value is falsy.
 */

export function asserts(value: unknown, message?: string): asserts value {
  if (!value) {
    throw new Error(`Type assertion failed${message ? `: ${message}` : ''}`);
  }
}

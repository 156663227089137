import React from 'react';
import { TransactionTypeCell } from 'stories';
import dayjs from 'dayjs';
import { IReportLineItemOnIndex } from 'types/ReportLineItem';
import SourceTypeColumn from 'bundles/Shared/components/SourceType/SourceTypeColumn/SourceTypeColumn';
import {
  IColumn,
  ISortSettings,
  ITableProps,
} from 'bundles/Shared/components/Table/types';
import QuickFilterColorDropdown, {
  TQuickFilterColorDropdownProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterColorDropdown';
import {
  TRANSACTION_TYPE_COLORS,
  TRANSACTION_TYPE_LABELS,
  TTransactionType,
} from 'types/TransactionType';
import {
  getRemarkFormatter,
  getVendorEntityFormatter,
} from 'bundles/Shared/components/lineItems/formatters';
import {
  TFilterFields,
  TSortFields,
} from '../../../../Shared/actions/report_line_items';

export const defaultColumn: ITableProps<IReportLineItemOnIndex>['defaultColumn'] =
  {
    classes: 'bg-white',
  };

type SortFields = Extract<
  | 'legal_entity_code'
  | 'date'
  | 'transaction_value'
  | 'balance'
  | 'vendor_entity_code',
  TSortFields
>;

type FilterFields = TFilterFields;

export type TSettings = ISortSettings<SortFields> & FilterFields;

export type TFilterModel = {
  transaction_value?: TTransactionType;
};

const transactionTableColumns = (): IColumn<IReportLineItemOnIndex>[] => [
  {
    dataField: 'legal_entity_code',
    text: 'LE Code',
    sortable: true,
    formatter: ({ row: transaction }) => transaction.legalEntity.code,
    classes: 'light-60 bg-white',
    headerStyle: {
      width: 120,
    },
  },
  {
    dataField: 'date',
    text: 'Date',
    sortable: true,
    formatter: ({ row: transaction }) =>
      dayjs(transaction.date).format('MMMM DD, YYYY'),
    headerStyle: {
      width: 170,
    },
  },
  {
    dataField: '',
    text: 'Source',
    headerStyle: {
      width: 170,
    },
    formatter: ({ row: transaction }) => (
      <SourceTypeColumn sourceType={transaction.legalEntity.source} />
    ),
  },
  {
    dataField: 'transaction_value',
    text: 'Debit',
    sortable: true,
    headerStyle: {
      width: 240,
    },
    formatter: ({ row: transaction }) => (
      <TransactionTypeCell
        generalLedgerType={transaction.generalLedger.type}
        debit={parseFloat(transaction.debit)}
        credit={parseFloat(transaction.credit)}
      />
    ),
    filterComponent: QuickFilterColorDropdown,
    filterComponentParams: {
      options: Object.keys(TRANSACTION_TYPE_LABELS).map((k) => ({
        key: k as TTransactionType,
        color: k === 'all' ? '' : TRANSACTION_TYPE_COLORS[k],
        label: TRANSACTION_TYPE_LABELS[k],
      })),
    } as TQuickFilterColorDropdownProps,
  },
  {
    dataField: 'vendor_entity_code',
    text: 'Vendor',
    sortable: true,
    formatter: getVendorEntityFormatter<IReportLineItemOnIndex>(
      (item) => item.vendorEntity,
    ),
    headerStyle: {
      minWidth: 200,
    },
  },
  {
    dataField: 'note',
    text: 'Description',
    formatter: getRemarkFormatter<IReportLineItemOnIndex>((item) => item.note),
  },
];

export default transactionTableColumns;

import React, { useState } from 'react';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import { snakeCaseKeys } from 'bundles/Construction/components/helpers';
import { IPipelineCard } from 'bundles/Settings/components/DealPath/PipelineCards';
import { Modal } from 'stories';

const initGroupFormItems = [
  {
    title: '',
    required: true,
    activated: true,
    open: true,
    formItems: [
      {
        fieldName: 'title',
        fieldNameSnap: 'title',
        type: FieldType.INPUT_TEXT,
        title: 'Card Title',
        placeholder: 'Roadmap...',
        required: true,
      },
      {
        fieldName: 'cardType',
        fieldNameSnap: 'card_type',
        type: FieldType.SELECT,
        title: 'Card Type',
        placeholder: 'Enter or select the name',
        hint: { content: 'Choose the type of card to see variates of fields' },
        required: true,
        options: [
          { value: 'gantt-chart', label: 'Gantt Chart' },
          { value: 'static-card', label: 'Simple static card' },
          { value: 'snapshot', label: 'Snapshot (multiple fields)' },
        ],
      },
    ],
  },
];

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  card?: IPipelineCard;
}

const PipelineCardModal = ({ onSubmit, onClose, card }: Props) => {
  const [formData, setFormData] = useState(card || {});

  return (
    <Modal
      header={card ? 'Update card' : 'Add new card'}
      toggle={onClose}
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <GroupForm
        groupFormItems={initGroupFormItems}
        onSubmit={() => onSubmit(snakeCaseKeys(formData))}
        formData={formData}
        setFormData={setFormData}
        buttonConfig={{
          titleBtn: 'Submit',
          iconName: 'null',
          withoutCancel: false,
          funcCancel: onClose,
        }}
      />
    </Modal>
  );
};

export default PipelineCardModal;

import {
  PERIOD_MAP,
  PERIOD_MAP_REVERSED,
} from '@/bundles/REturn/components/Ownership/modals/addDistributionsModal/EnterDistributionsStep';
import { Distribution } from '@/entities/return';
import {
  PutApiCapitalInvestmentDistributionsByIdApiArg,
  SplitItem,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { ReactDatePickerWrapper } from '@/stories/FlexibleFilterByPeriods/reactdatepickerWrapper';
import { snakeCaseKeys } from 'bundles/Construction/components/helpers';
import { DistributionKind } from 'bundles/REturn/actions/types';
import { DISTRIBUTION_CATEGORIES_OPTIONS } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import styles from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import { STATIC_DATE_PICKER_PROPS } from 'bundles/REturn/components/Ownership/modals/consts';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import dayjs from 'dayjs';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { asserts } from 'lib/typeHelpers/assertsType';
import { omit, sumBy, uniqueId } from 'lodash-es';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { createPeriodFromDate } from 'stories/FlexibleFilterByPeriods/utils';
import { ISelectOption } from 'stories/FormControls/Select/Select';
import { isSelectedArray } from 'stories/FormControls/Select/utils';
import {
  Button,
  Field,
  IconButton,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
  Select,
} from 'stories/index';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@/shared/lib/converters';

const MIN_SPLIT_ITEMS_COUNT = 2;

export function SplitDistributionEditModal({
  onClose,
  onSubmit,
  splitItems: initialSplitItems,
  date,
  kind: initialKind,
  period,
  periodType,
  investmentEntity,
}: DialogProps<PutApiCapitalInvestmentDistributionsByIdApiArg['body']> &
  Distribution) {
  const [form, setForm] = useState<
    Pick<Distribution, 'date' | 'splitItems' | 'period' | 'periodType'>
  >({
    splitItems: initialSplitItems,
    date,
    periodType,
    period,
  });

  const handleChangeSplitItem = (
    id: string,
    newSplitItem: Partial<Distribution['splitItems'][number]>,
  ) =>
    setForm((prev) => ({
      ...prev,
      splitItems: prev.splitItems.map((s) =>
        s.id === id
          ? {
              ...s,
              ...newSplitItem,
            }
          : s,
      ),
    }));

  const computeRemaintKindOptions = (items: SplitItem) => {
    const usedKindOptions = items.map(({ kind }) => kind);
    return DISTRIBUTION_CATEGORIES_OPTIONS.map((option) => {
      if (!usedKindOptions.includes(option.id)) return option;

      return {
        ...option,
        disabled: true,
      };
    });
  };

  const handleSubmit = () => {
    const amount = convertCentsToDollars(sumBy(form.splitItems, 'amountCents'));

    let preparedSplitItems: PutApiCapitalInvestmentDistributionsByIdApiArg['body']['split_items_attributes'] =
      form.splitItems;

    const initialSplitItemIds = initialSplitItems.map((s) => s.id);
    const currentSplitItemIds = form.splitItems.map((s) => s.id);

    const unusedSplitItemIds = new Set(
      initialSplitItemIds.filter((id) => !currentSplitItemIds.includes(id)),
    );

    preparedSplitItems = form.splitItems.map((item) => {
      if (initialSplitItemIds.includes(item.id)) {
        return item;
      }

      const [newId] = [...unusedSplitItemIds];

      if (newId === undefined) {
        // creation of new split item
        return omit(item, 'id');
      }

      unusedSplitItemIds.delete(newId);

      // assign id from previous split item
      return {
        ...item,
        id: newId,
      };
    });

    preparedSplitItems = preparedSplitItems.map(snakeCaseKeys);

    if (unusedSplitItemIds.size > 0) {
      // remove split items
      const splitItemsForRemove = [...unusedSplitItemIds].map((unusedId) => ({
        id: unusedId,
        _destroy: true as const,
      }));

      preparedSplitItems = [...preparedSplitItems, ...splitItemsForRemove];
    }

    const res = {
      period: form.period ?? undefined,
      period_type: form.periodType ?? undefined,
      date: form.date,
      amount,
      split_items_attributes: preparedSplitItems,
      kind: initialKind,
    } satisfies PutApiCapitalInvestmentDistributionsByIdApiArg['body'];
    onSubmit?.(res);
  };

  const [remainingKindOptions, setRemainingKindOptions] = useState<
    ISelectOption<DistributionKind>[]
  >(() => {
    return computeRemaintKindOptions(initialSplitItems);
  });

  const handleRemoveSplitItem = (id: string) => {
    setForm((prev) => ({
      ...prev,
      splitItems: prev.splitItems.filter((s) => s.id !== id),
    }));

    setRemainingKindOptions(
      computeRemaintKindOptions(form.splitItems.filter((s) => s.id !== id)),
    );
  };

  const enabledKindOptions = remainingKindOptions.filter((o) => !o.disabled);

  const someSplitItemHasZeroAmount = form.splitItems.some(
    (s) => s.amountCents === 0,
  );

  const handleAddType = () => {
    const newItem = {
      id: uniqueId(),
      kind: enabledKindOptions[0].id,
      amountCents: 0,
    } satisfies SplitItem[number];

    setForm((prev) => ({
      ...prev,
      splitItems: [...prev.splitItems, newItem],
    }));

    setRemainingKindOptions(
      computeRemaintKindOptions([...form.splitItems, newItem]),
    );
  };

  const addTypeDisabled = enabledKindOptions.length === 0;

  const columns = useMemo<IColumn<Distribution['splitItems'][number]>[]>(
    () => [
      {
        dataField: 'type',
        text: 'Type',
        formatter: ({ row: splitItem }) => (
          <Select<DistributionKind>
            inputType="text"
            placeholder="Select Type"
            size="m"
            popoverProps={{
              appendToBody: true,
            }}
            classes={{ input: 'w-full' }}
            options={remainingKindOptions}
            selected={DISTRIBUTION_CATEGORIES_OPTIONS.find(
              (c) => c.id === splitItem.kind,
            )}
            disabled={enabledKindOptions.length === 0}
            onSelectedChange={(option) => {
              asserts(option);
              if (isSelectedArray(option)) return;

              const newItems = form.splitItems.map((s) =>
                s.id !== splitItem.id ? s : { ...s, kind: option.id },
              );
              setRemainingKindOptions(computeRemaintKindOptions(newItems));
              handleChangeSplitItem(splitItem.id, {
                kind: option.id,
              });
            }}
          />
        ),
      },
      {
        dataField: 'amount',
        text: 'Distribution amount',
        formatter: ({ row: splitItem }) => (
          <CurrencyInputNumber
            selectOnFocus
            size="m"
            value={convertCentsToDollars(splitItem.amountCents)}
            allowNegative={false}
            onValueChange={(value) => {
              handleChangeSplitItem(splitItem.id, {
                amountCents: value ? convertDollarsToCents(value) : 0,
              });
            }}
          />
        ),
      },
      {
        dataField: 'remove_action',
        text: '',
        headerClasses: 'w-[56px]',
        formatter: ({ row: splitItem }) => (
          <IconButton
            disabled={form.splitItems.length <= MIN_SPLIT_ITEMS_COUNT}
            onClick={() => handleRemoveSplitItem(splitItem.id)}
            iconName="closeSmall"
          />
        ),
      },
    ],
    [form.splitItems.length, remainingKindOptions],
  );

  return (
    <Modal
      toggle={onClose}
      size="600"
      header={
        <ModalHeaderWithSubtitle
          title="Edit a Distribution"
          order="subtitle-title"
          subtitle={investmentEntity.name}
        />
      }
      classes={{
        body: 'flex gap-tw-4 flex-col',
      }}
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={someSplitItemHasZeroAmount}
          >
            Save Updates
          </Button>
        </ModalActions>
      }
    >
      <div className="grid grid-cols-2 items-center gap-tw-4">
        <Field labelText="Date" required>
          <ReactDatePicker
            {...STATIC_DATE_PICKER_PROPS}
            selected={form.date ? dayjs(form.date).toDate() : null}
            onChange={(newDate) => {
              setForm((prev) => ({
                ...prev,
                date: createPeriodFromDate(newDate ?? new Date()),
              }));
            }}
          />
        </Field>
        <Field labelText="Period" required>
          <ReactDatePickerWrapper
            periodType={
              form.periodType ? PERIOD_MAP[form.periodType] : undefined
            }
            setPeriodType={(shortPeriodType) => {
              setForm((prev) => ({
                ...prev,
                periodType: PERIOD_MAP_REVERSED[shortPeriodType],
              }));
            }}
            selected={period ? dayjs(form.period).toDate() : null}
            onChange={(selectedDate) => {
              setForm((prev) => ({
                ...prev,
                period: selectedDate
                  ? createPeriodFromDate(selectedDate)
                  : null,
              }));
            }}
          />
        </Field>
      </div>

      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-tw-2 text-neutral-500',
        }}
        items={form.splitItems}
        columns={columns}
      />
      <Button
        size="xs"
        variant="secondary"
        className="self-start"
        iconName="addSmall"
        disabled={addTypeDisabled}
        onClick={handleAddType}
      >
        Add Type
      </Button>
    </Modal>
  );
}

import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import React from 'react';
import { CardObjectsList } from './CardObjectsList';
import { ILightLegalEntitiable, ObjectType } from './types';

interface Props {
  view: ILightLegalEntitiable[];
  manage: ILightLegalEntitiable[];
  type: ObjectType;
  canViewAll: boolean;
  canManageAll: boolean;
}

export const ObjectTab = ({
  view,
  manage,
  type,
  canViewAll,
  canManageAll,
}: Props) => {
  const objectsCount = view.length + manage.length;
  if (objectsCount === 0)
    return (
      <h6 className="mt-tw-4 text-center">
        {' '}
        User is not associated with any {type}
      </h6>
    );

  return (
    <div className="flex flex-col gap-s">
      <h6 className="light-60">{pluralize(capitalize(type))}</h6>

      <span className="light-60 text-sm">
        Note: {pluralize(capitalize(type))} in 'Draft' status are never visible
        to your external users.
      </span>
      <CardObjectsList
        title="CAN VIEW"
        objects={view}
        isAll={canViewAll}
        type={type}
      />
      <CardObjectsList
        title="CAN MANAGE"
        objects={manage}
        isAll={canManageAll}
        type={type}
      />
    </div>
  );
};

// TODO: @denis remove this component after we implement media component for feed messages
import { cn } from '@/shared/lib/css/cn';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button, Icon, Tooltip } from 'stories';
import BgCheckbox from '../../../Shared/components/BgCheckbox';

const MediaItem = ({
  media,
  openLightbox,
  remove,
  openEditModal,
  openMoveModal,
  selectedItems,
  toggleSelected,
  hideActions,
  onRemove,
  actionsAlwaysVisible,
  size,
  actions,
}) => {
  const {
    videoUrl,
    smallPlaceholderUrl,
    smallImageUrl,
    largeImageUrl,
    id,
    title,
    description,
    assetId,
    documentableId,
    documentableType,
  } = media;
  const productName = assetId
    ? ASSET_PORTAL_PRODUCT_NAME
    : FUND_PORTAL_PRODUCT_NAME;

  const canManage = currentUserAllowedTo(
    'manage',
    productName,
    documentableType,
    assetId || documentableId,
  );

  const isSelected = selectedItems.some(({ id }) => id === media.id);

  return (
    <CardWrapper
      className={cn('mb-0 border-0', {
        'blue-outline p-025rem': isSelected,
        'p-[0.5rem]': !isSelected && size === 'default',
        'mx-025rem p-0': size !== 'default',
      })}
    >
      <>
        <div
          className={cn('relative media-item', {
            'h-200p': size === 'default',
            'h-80p w-120p': size === 's',
            'h-160p w-240p': size === 'm',
          })}
        >
          <div
            className={cn('h-100', { 'overlay-wrapper': size !== 'default' })}
          >
            {onRemove && (
              <Icon
                className="media-item__close-button"
                onClick={() => onRemove(id)}
                iconName="close"
              />
            )}
            <div
              className={cn('border-radius-[1rem] overflow-hidden', {
                'overlay overlay_dark': onRemove,
              })}
            />
            {videoUrl ? (
              <video
                src={videoUrl}
                height={200}
                className="w-100 border-radius-[1rem]"
                controls
              />
            ) : (
              <LazyLoadImage
                wrapperClassName="h-100 w-100"
                src={smallImageUrl}
                placeholderSrc={smallPlaceholderUrl}
                effect="blur"
                large_src={largeImageUrl}
                onClick={openLightbox ? () => openLightbox(id) : null}
                className={cn(
                  'w-100 h-100 card-img-top card-image border-radius-[1rem]',
                  { 'open-lightbox': openLightbox },
                )}
              />
            )}
            {canManage && !hideActions && (
              <div
                className={cn('folder-actions', {
                  flex: actionsAlwaysVisible,
                })}
              >
                <BgCheckbox
                  id={`media-${media.id}-checkbox`}
                  isSelected={isSelected}
                  toggleSelected={toggleSelected}
                  tooltip="Select media"
                />
                <div className="ml-auto">
                  {openMoveModal && (
                    <Tooltip mainText="Move to...">
                      <Button
                        color="dark"
                        size="s"
                        className="mr-[0.5rem]"
                        onClick={() => openMoveModal(media, true)}
                      >
                        <div className="sre-icon-transferFile" />
                      </Button>
                    </Tooltip>
                  )}
                  {openEditModal && (
                    <Tooltip mainText="Edit media">
                      <Button
                        color="dark"
                        size="sm"
                        className="mr-[0.5rem]"
                        onClick={() => openEditModal(media, true)}
                      >
                        <div className="sre-icon-edit" />
                      </Button>
                    </Tooltip>
                  )}
                  {remove && (
                    <Tooltip mainText="Remove media">
                      <Button color="dark" size="sm" onClick={remove}>
                        <div className="sre-icon-trash" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
            {actions}
          </div>
        </div>
        {title && size === 'default' && (
          <div className="card-body p-tw-2">
            <p className="inline-regular album-description">{title}</p>
          </div>
        )}
      </>

      {description && size === 'default' && (
        <div className="card-body p-tw-2">
          <p className="inline-regular album-description">{description}</p>
        </div>
      )}
    </CardWrapper>
  );
};

export default MediaItem;

MediaItem.propTypes = {
  media: PropTypes.shape({
    id: PropTypes.number,
    videoUrl: PropTypes.string,
    smallImageUrl: PropTypes.string,
    smallPlaceholderUrl: PropTypes.string,
    largeImageUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    assetId: PropTypes.number,
    documentableId: PropTypes.number,
    documentableType: PropTypes.string,
  }).isRequired,
  openLightbox: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  size: PropTypes.string,
};

MediaItem.defaultProps = {
  size: 'default',
};

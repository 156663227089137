import React, { useMemo } from 'react';
import ReportDataPeriodsTable from 'bundles/Settings/components/REport/DataPeriods/Table/ReportDataPeriodsTable';
import { groupBy, sortBy } from 'lodash-es';
import dayjs from 'dayjs';
import { IPeriodBatchGroup } from 'bundles/Settings/types/ReportDataPeriodBulk';

interface Props {
  items: IPeriodBatchGroup[];
}

function ReportDataPeriodBulkList({ items }: Props) {
  const sortedByDateDescItems = useMemo(() => {
    const grouped = groupBy(items, (item) => dayjs(item.period).year());
    return sortBy(Object.entries(grouped), ([period]) => period).reverse();
  }, [items]);

  return (
    <div className="flex flex-col gap-m">
      {sortedByDateDescItems.map(([period, periodItems]) => (
        <ReportDataPeriodsTable
          key={period}
          period={period}
          items={periodItems}
        />
      ))}
    </div>
  );
}

export default ReportDataPeriodBulkList;

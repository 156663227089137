import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { currentUserIsSreAdmin } from 'lib/permissions';
import { Tab, TabItem } from 'stories';

const TemplatesTab = ({ children }) => {
  const tabs = [
    { label: 'Invitations', path: 'invitations' },
    { label: 'Feed Messages', path: 'feed-messages' },
    { label: 'Forgot Password', path: 'forgot-password' },
  ];

  if (currentUserIsSreAdmin())
    tabs.push({ label: 'Custom Templates', path: 'custom' });

  const getCurrentTab = () =>
    tabs.find(
      (tab) => tab.path === window.location.pathname.split('/').at(-1),
    ) || tabs[0];
  const [tab, setTab] = useState(getCurrentTab());

  useEffect(() => {
    setTab(getCurrentTab());
  }, [window.location.pathname]);

  return (
    <div className="mt-l">
      <Tab className="tabs-underline-light-30">
        {tabs.map((t) => (
          <TabItem
            onClick={() => navigate(`./${t.path}`)}
            active={tab.path === t.path}
          >
            {t.label}
          </TabItem>
        ))}
      </Tab>
      {children}
    </div>
  );
};

export default TemplatesTab;

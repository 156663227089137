import { groupBy, values } from 'lodash-es';
import {
  ILegalEntitiableWithLegalEntities,
  ILegalEntity,
} from 'types/ReportTableSegment';

function getOneTypeOfLegalEntitiables(
  legalEntities: ILegalEntity[],
): ILegalEntitiableWithLegalEntities[] {
  const groupedById = groupBy(legalEntities, 'legalEntitiableId');
  const legalEnitiesLists = values(groupedById);

  return legalEnitiesLists.map((entities) => ({
    ...entities[0].legalEntitiable,
    legalEntities: entities,
  }));
}

export function getLegalEntitiables(legalEntities: ILegalEntity[]): {
  legalEntitiables: ILegalEntitiableWithLegalEntities[];
  assets: ILegalEntitiableWithLegalEntities[];
  funds: ILegalEntitiableWithLegalEntities[];
  legalEntitiesFromAsset: ILegalEntity[];
  legalEntitiesFromFund: ILegalEntity[];
} {
  const legalEntitiesFromAsset = legalEntities.filter(
    (le) => le.legalEntitiableType === 'Asset',
  );
  const legalEntitiesFromFund = legalEntities.filter(
    (le) => le.legalEntitiableType === 'Fund',
  );

  const assetsWithLegalEntities = getOneTypeOfLegalEntitiables(
    legalEntitiesFromAsset,
  );
  const fundsWithLegalEntities = getOneTypeOfLegalEntitiables(
    legalEntitiesFromFund,
  );

  const legalEntitiables = [
    ...assetsWithLegalEntities,
    ...fundsWithLegalEntities,
  ];

  return {
    legalEntitiables,
    assets: assetsWithLegalEntities,
    funds: fundsWithLegalEntities,
    legalEntitiesFromAsset,
    legalEntitiesFromFund,
  };
}

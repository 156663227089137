export const NO_SELECTED_SOURCES = (
  <div className="light-60 pt-s text-center">
    <div className="inline-semibold mb-s font-semibold">
      No Selected Sources
    </div>
    <div className="secondary-regular px-tw-6 font-normal">
      Just start to select General Ledgers to complete this form
    </div>
  </div>
);

export const PUBLICATION_DATASETS = (
  <div className="flex flex-col gap-s">
    <span className="dark-60 body-semibold">
      What do my Equity Partners see?
    </span>
    <span className="light-60 secondary-regular">
      In Draft status, the data below will remain hidden. Once Published, any
      manual entries will immediately be visible to your equity partners.
    </span>
    <span className="light-60 secondary-regular">
      Any linked transactions via your REport module will only be visible if
      you've published the period. You can revert to Draft status at any time.
    </span>
  </div>
);
export const CAPITAL_SETTINGS_DESCRIPTION_JSX = (
  <div className="flex flex-col gap-s">
    <span className="dark-60 body-semibold">Data synchronization</span>
    <span className="light-60 secondary-regular">
      The <b className="font-semibold">From source</b> setting enables linking
      and creating contribution or distribution entries using your financial
      transactions.
    </span>
  </div>
);

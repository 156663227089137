import { SharedSelectController } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { Field } from '@/stories';
import { ListOption } from '@/stories/Checkbox/CheckList';
import { Control, FieldValues, FieldPath } from 'react-hook-form';

export const GROUPING_TYPE_OPTIONS = [
  {
    label: 'Assets',
    value: 'assets',
  },
  {
    label: 'Segments',
    value: 'segments',
  },
  {
    label: 'Mixed',
    value: 'mixed',
  },
] as const satisfies ListOption[];

export const GroupingTypeField = <T extends FieldValues>({
  control,
  name,
  availableGroupingTypes,
}: {
  control: Control<T>;
  name: FieldPath<T>;
  availableGroupingTypes?: (typeof GROUPING_TYPE_OPTIONS)[number]['value'][];
}) => {
  const options = availableGroupingTypes
    ? GROUPING_TYPE_OPTIONS.filter((o) =>
        availableGroupingTypes.includes(o.value),
      )
    : GROUPING_TYPE_OPTIONS;
  return (
    <Field labelText="Grouping Type">
      <SharedSelectController
        options={options}
        control={control}
        name={name}
        menuPortalTarget={document.body}
      />
    </Field>
  );
};

import React, { ReactNode } from 'react';
import { Popover } from 'stories';
import ItemFilterList from './Filters/items/ItemFilterList';

interface Props<T> {
  allItems: T[];
  selectedItems: T[];
  onSelect: (item: T) => void;
  mask: string;
  children: ReactNode;
  getMainFieldTitle: (item: T) => string;
  getSecondaryFieldTitle?: (item: T) => string;
  className?: string;
  areItemsLoading?: boolean;
}

function AddItemFromList<T = any>({
  allItems,
  selectedItems,
  onSelect,
  mask,
  children,
  getMainFieldTitle,
  getSecondaryFieldTitle,
  className,
  areItemsLoading,
}: Props<T>) {
  return (
    <Popover
      appendTo={document.body}
      template={
        <ItemFilterList
          loading={areItemsLoading === true}
          items={allItems}
          selectedItems={selectedItems}
          onSelectedItemChange={onSelect}
          mask={mask}
          getMainFieldTitle={getMainFieldTitle}
          getSecondaryFieldTitle={getSecondaryFieldTitle}
          maxHeight="20rem"
        />
      }
      maxWidth="17.5rem"
      placement="bottom-end"
      hiddenArrow
      trigger="click"
      offset={[0, 4]}
      className={className}
    >
      {children}
    </Popover>
  );
}

export default AddItemFromList;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from './VerticalSeparator.module.scss';

interface Props {
  className?: string;
  size?: React.CSSProperties['width'];
}
function VerticalSeparator({ className, size }: Props) {
  return (
    <div style={{ width: size }} className={cn(styles.separator, className)} />
  );
}

export default VerticalSeparator;

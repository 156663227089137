import React, { useEffect, useState } from 'react';
import {
  CrudContextProvider,
  CrudTable,
  CrudTablePanel,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import useEvent from '@/shared/lib/hooks/useEvent';
import { useContactsCrudColumns } from 'bundles/REconcile/components/development/home/settingsModal/contacts/useColumns';
import { useModal } from '@/shared/lib/hooks/useModal';
import ContactCrudModal from 'bundles/REconcile/components/development/home/settingsModal/contacts/ContactCrudModal';
import {
  CONTACT_ENTITY_NAME,
  createContactInReconcileSettings,
  deleteContactInReconcileSettings,
  getContactsInReconcileSettings,
  IReconcileSettingsContact,
  TGetContactsRequestParams,
  updateContactInReconcileSettings,
} from 'bundles/REconcile/actions/contacts';

function ContactsCrud() {
  const { openModal } = useModal();
  const [totalSize, setTotalSize] = useState(0);
  const [pageParams, setPageParams] = useState<TGetContactsRequestParams>({
    per_page: 10,
  });
  const [contacts, setContacts] = useState<IReconcileSettingsContact[]>([]);
  const columns = useContactsCrudColumns({
    params: pageParams,
    setParams: setPageParams,
  });

  const loadContacts = async () => {
    const data = await getContactsInReconcileSettings({}, pageParams);
    setTotalSize(data.meta.totalSize);
    setContacts(data.items);
  };

  const handleCreate = useEvent(async () => {
    const res = await openModal(ContactCrudModal, {
      type: 'create',
      entity: {
        code: '',
        name: '',
      },
    });

    if (res) {
      await createContactInReconcileSettings({}, res);
      loadContacts();
    }
  });

  const handleEdit = useEvent(async (row: IReconcileSettingsContact) => {
    const res = await openModal(ContactCrudModal, {
      type: 'edit',
      entity: {
        name: row.name,
        code: row.code,
      },
    });

    if (res) {
      await updateContactInReconcileSettings(
        {
          entityId: row.id,
        },
        res,
      );
      loadContacts();
    }
  });

  const handleRemove = useEvent(async (row: IReconcileSettingsContact) => {
    await deleteContactInReconcileSettings({
      entityId: row.id,
    });
    loadContacts();
  });

  useEffect(() => {
    loadContacts();
  }, [pageParams]);

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entities={contacts}
      entityName={CONTACT_ENTITY_NAME}
      handlers={{
        create: handleCreate,
        update: handleEdit,
        delete: handleRemove,
      }}
    >
      <div>
        <CrudTablePanel
          paginationProps={{
            totalSize,
          }}
        />
        <CrudTable columns={columns} />
      </div>
    </CrudContextProvider>
  );
}

export default ContactsCrud;

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'stories';

const BgCheckbox = ({
  id, isSelected, toggleSelected, tooltip,
}) => (
  <Tooltip
    disabled={!tooltip}
    mainText={tooltip}
  >
    <input
      type="checkbox"
      className="custom-checkbox custom-checkbox_with-outline"
      checked={isSelected}
      onChange={toggleSelected}
      id={id}
      disabled={!toggleSelected}
    />
    <label htmlFor={id} id={`${id}-label`} />
  </Tooltip>
);

export default BgCheckbox;

BgCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

BgCheckbox.defaultProps = {
  tooltip: undefined,
};

import { useBudgetContext } from '@/bundles/REconcile/underwritting/ctx/useBudgetContext';
import { isBudgetDateAndFirstFinancialPeriodMismatched } from '@/bundles/REconcile/underwritting/util';
import { ACCEPT_BUDGET_FILE_EXTENSION } from '@/shared/lib/browser/config';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import {
  ExtractParams,
  ROUTES_ROOT,
  TRouteQueryParams,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { ExportIconButton } from '@/shared/ui/ExportButton';
import { ImportIconButton } from '@/shared/ui/ImportButton';
import {
  navigate,
  RouteComponentProps,
  useLocation,
  useParams,
} from '@reach/router';
import { UWBTableComponent } from 'bundles/REconcile/underwritting/UWBTableComponent';
import { LEGAL_ENTITY_STATUS_MAP } from 'bundles/REconcile/underwritting/UnderwritingBudgetPage';
import {
  reconcileUnderwrittingBudgetEnhancedApi,
  useGetApiReconcileUnderwritingBudgetsByBudgetIdQuery,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import { uwbSliceActions } from 'bundles/REconcile/underwritting/ctx/underwritingBudgetSlice';
import {
  ADD_BUDGET_HINT_JSX,
  BudgetDateFromAndFinancialFirstPeriodFireIcon,
} from 'bundles/REconcile/underwritting/dumbJSX';
import {
  exportUnderwritingBudget,
  importUnderwritingBudget,
  showUnderwritingBudgetMismatchFireIcon,
} from 'bundles/REconcile/underwritting/lib';
import StatusDropdown from 'bundles/REconcile/underwritting/ui/StatusDropdown';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { useCreateUnderwritingBudget } from 'bundles/Shared/features/underwritingBudget/budget/create';
import { useDeleteUnderwritingBudget } from 'bundles/Shared/features/underwritingBudget/budget/delete';
import {
  useUpdateUnderwritingBudgetPeriods,
  useUpdateUnderwritingBudgetState,
} from 'bundles/Shared/features/underwritingBudget/budget/update';
import { exists } from 'lib/typeHelpers';
import { FC } from 'react';
import { AnimationLoader, IconButton } from 'stories/index';

export const BudgetComponent: FC<RouteComponentProps> = () => {
  const location = useLocation();
  const { legalEntityId } =
    useParams<
      ExtractParams<
        typeof ROUTES_ROOT.reconcile.underwriting.legalEntity.fullPath
      >
    >();
  const { budgetId } =
    useQueryParams<
      TRouteQueryParams['/reconcile/underwriting/:legalEntityId']
    >();
  const isTableExpanded = useAppSelector(
    (state) => state.underwritingBudgetSlice.isTableExpanded,
  );
  const [updateBudgetPeriods, { isLoading: isUpdatingBudget }] =
    useUpdateUnderwritingBudgetPeriods();
  const [updateBudgetState, { isLoading: isUpdateBudgetState }] =
    useUpdateUnderwritingBudgetState();
  const [deleteBudget, { isLoading: isDeletingBudget }] =
    useDeleteUnderwritingBudget();
  const [
    createBudget,
    {
      options: { isLoading: isBudgetCreating },
      optionsFromSource: { isLoading: isBudgetCreatingFromSource },
    },
  ] = useCreateUnderwritingBudget();
  const createBudgetButtonLoading =
    isBudgetCreating || isBudgetCreatingFromSource;

  const { openFileDialog } = useFileDialog({
    multiple: false,
    accept: ACCEPT_BUDGET_FILE_EXTENSION,
  });

  const dispatch = useAppDispatch();
  const dispatchToggleExpandTable = () =>
    dispatch(uwbSliceActions.toggleExpandTable());

  const disableButtons =
    isUpdatingBudget || isDeletingBudget || isUpdateBudgetState;

  const { data: budgetData, isLoading } =
    useGetApiReconcileUnderwritingBudgetsByBudgetIdQuery(
      { budgetId: budgetId! },
      {
        skip: budgetId === '' || budgetId == null,
      },
    );

  const { selectedLegalEntity } = useBudgetContext();

  const isMismatched = isBudgetDateAndFirstFinancialPeriodMismatched(
    selectedLegalEntity?.budget?.dateFrom,
    selectedLegalEntity?.financial?.firstPeriod,
  );
  const showMismatchedFire = showUnderwritingBudgetMismatchFireIcon(
    isMismatched,
    budgetData?.state ?? 'draft',
  );

  const budgetExists = budgetId && exists(budgetData);

  const status = LEGAL_ENTITY_STATUS_MAP[budgetData?.state ?? 'draft'];

  return (
    <div className="flex h-full flex-col">
      <div
        className={cn(
          'flex items-start justify-between gap-tw-4 border-b border-solid border-neutral-200 bg-neutral-000 px-tw-6 py-tw-3',
        )}
      >
        <div className="flex h-full min-h-[56px] items-center gap-tw-4">
          {isTableExpanded && (
            <IconButton
              onClick={dispatchToggleExpandTable}
              className="h-full"
              variant="secondary"
              iconName="arrowLeft"
            />
          )}
          {budgetExists && budgetData.legalEntity != null && (
            <div className="flex flex-col gap-tw-0.5">
              <p className="header5-bold text-neutral-900">
                {budgetData.legalEntity.name}
              </p>
              <p className="label-regular text-neutral-500">
                {budgetData.legalEntity.assetName}
              </p>
            </div>
          )}
        </div>

        <div className="flex items-center gap-tw-2">
          {showMismatchedFire && (
            <div className="flex items-center px-tw-2">
              <BudgetDateFromAndFinancialFirstPeriodFireIcon
                budgetDateFrom={selectedLegalEntity?.budget?.dateFrom}
                financialFirstPeriod={
                  selectedLegalEntity?.financial?.firstPeriod
                }
              />
            </div>
          )}
          {budgetExists && (
            <IconButton
              disabled={disableButtons}
              iconName="edit"
              onClick={() => {
                updateBudgetPeriods({
                  budgetData,
                });
              }}
              size="l"
            />
          )}

          {status?.icon && budgetExists && (
            <StatusDropdown
              onStatusSelect={(state) => {
                updateBudgetState({
                  budgetDataId: budgetData.id,
                  state,
                });
              }}
              dropdownDisabled={disableButtons}
              status={budgetData?.state}
            >
              <IconButton
                disabled={disableButtons}
                classes={{
                  icon: status.className,
                }}
                iconName={status.icon}
                size="l"
                variant="secondary"
              />
            </StatusDropdown>
          )}
          {
            <IconButton
              onClick={dispatchToggleExpandTable}
              iconName={isTableExpanded ? 'collapse' : 'expand'}
              size="l"
              variant="secondary"
            />
          }
          {budgetExists && (
            <ImportIconButton
              size="l"
              onClick={async () => {
                const fileList = await openFileDialog();

                if (fileList === null || fileList.length < 1) return;

                const [file] = fileList;

                await importUnderwritingBudget({
                  budgetId: budgetData.id,
                  file,
                });

                dispatch(
                  reconcileUnderwrittingBudgetEnhancedApi.util.invalidateTags([
                    'budget',
                  ]),
                );
              }}
            />
          )}
          {budgetExists && (
            <ExportIconButton
              size="l"
              onClick={() => {
                exportUnderwritingBudget({
                  budgetId: budgetData.id,
                });
              }}
            />
          )}
          {!isTableExpanded && <VerticalSeparator />}
          {budgetExists && (
            <IconButton
              disabled={disableButtons}
              iconName="trash"
              onClick={async () => {
                await deleteBudget(budgetData.id);
                navigate(location.pathname, { replace: true });
              }}
              size="l"
              variant="secondary"
            />
          )}
          {!budgetExists && (
            <IconButton
              isLoading={createBudgetButtonLoading}
              onClick={() => {
                createBudget({
                  legalEntityId,
                });
              }}
              iconName="add"
              size="l"
              variant="secondary"
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <AnimationLoader className="static h-full w-full" />
      ) : (
        <>
          {budgetExists ? (
            <UWBTableComponent budgetId={budgetId} />
          ) : (
            ADD_BUDGET_HINT_JSX
          )}
        </>
      )}
    </div>
  );
};

import { treeDFM } from 'lib/treeHelpers';
import { DataNode } from 'rc-tree/lib/interface';

export type DataNodeAndExtraProps = DataNode & {
  path: string;
  hasChildren: boolean;
  key: number;
  title: string;
  children?: DataNodeAndExtraProps[];
};

export type DataNodeAndProps = DataNode & {
  key: number;
  index: number;
  title: string;
  children?: DataNodeAndProps[];
};

export const addExtraProps = (data: DataNode[]): DataNodeAndExtraProps[] =>
  data.map((node) => ({
    ...node,
    key: node.id,
    hasChildren: node.children?.length > 0,
    children: node.children ? addExtraProps(node.children) : undefined,
  }));

export const getNodeParentKey = (
  key: number,
  tree: DataNodeAndExtraProps[],
): number => {
  let parentKey: number;

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getNodeParentKey(key, node.children)) {
        parentKey = getNodeParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export const RC_TREE_MOTION = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: () => ({ height: 0 }),
  onAppearActive: (node) => ({ height: node.scrollHeight }),
  onLeaveStart: (node) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};

export const getTreeDataList = (treeDataWithExtraProps: DataNode[]) => {
  const result = treeDFM(treeDataWithExtraProps, (node) => ({
    key: node.key,
    title: node.title,
    hasChildren: (node.children?.length ?? 0) > 0,
  }));

  return result;
};

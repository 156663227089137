import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import { SelectedEntityTags } from '@/bundles/Settings/components/REport/Operational/ui/SelectedEntityTags';
import { SourceUnitStatus } from '@/bundles/Shared/entities/sreUnitStatuses';
import { SRE_UNIT_STATUSES } from '@/bundles/Shared/entities/sreUnitStatuses/ui/config';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { asserts } from '@/lib/typeHelpers/assertsType';
import {
  Button,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from '@/stories';
import { useState } from 'react';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { TRentrollUnitStatus } from '@/entities/report/unitTypes/model';

export function BulkSetUnitStatusModal({
  sourceUnitStatuses,
  onClose,
  onSubmit,
}: {
  sourceUnitStatuses: SourceUnitStatus[];
} & DialogProps<TRentrollUnitStatus>) {
  const [checkedUnitType, setCheckedUnitType] =
    useState<TRentrollUnitStatus | null>(null);

  const handleSubmit = () => {
    asserts(checkedUnitType != null);
    onSubmit?.(checkedUnitType);
  };

  return (
    <Modal
      header={
        <ModalHeaderWithSubtitle
          title={`Set ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.pluralForm}`}
        />
      }
      size="400"
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={checkedUnitType == null}
          >
            Set {SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}
          </Button>
        </ModalActions>
      }
      classes={{
        body: 'gap-tw-4 flex flex-col',
      }}
      toggle={onClose}
    >
      <SelectedEntityTags
        items={sourceUnitStatuses}
        itemName={
          SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm
        }
      />
      <SectionField labelText="sRE Unit Statuses">
        <RadioGroup
          className="pl-tw-1"
          options={SRE_UNIT_STATUSES}
          value={checkedUnitType}
          onChange={(o) => setCheckedUnitType(o.value as TRentrollUnitStatus)}
        />
      </SectionField>
    </Modal>
  );
}

import dayjs from 'dayjs';
import React, { FC } from 'react';

interface IUploadColumnProps {
  uploadedBy: string;
  uploadedAt: number;
}

const UploadColumn: FC<IUploadColumnProps> = ({ uploadedBy, uploadedAt }) => (
  <div>
    <div>{dayjs.unix(uploadedAt).format('DD.MM.YYYY')}</div>
    <div className="light-60 secondary-regular">{uploadedBy}</div>
  </div>
);

export default UploadColumn;

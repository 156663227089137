import http from 'lib/http';

export const FETCH_DEAL_PATH_SETTINGS = 'symmetre-client-api/FETCH_DEAL_PATH_SETTINGS';
export const UPDATE_DEAL_PATH_CATEGORY = 'symmetre-client-api/UPDATE_DEAL_PATH_CATEGORY';
export const FETCH_DEAL_PATH_GOALS = 'symmetre-client-api/FETCH_DEAL_PATH_GOALS';
export const UPDATE_DEAL_PATH_GOAL = 'symmetre-client-api/UPDATE_DEAL_PATH_GOAL';
export const UPDATE_DEAL_PATH_TEAM = 'symmetre-client-api/UPDATE_DEAL_PATH_TEAM';
export const UPDATE_DEAL_PATH_PROPERTY_TYPE = 'symmetre-client-api/UPDATE_DEAL_PATH_PROPERTY_TYPE';
export const UPDATE_DEAL_PATH_FIELD = 'symmetre-client-api/UPDATE_DEAL_PATH_FIELD';
export const SYNC_DEAL_PATH_FIELD = 'symmetre-client-api/SYNC_DEAL_PATH_FIELD';
export const CREATE_DEAL_PATH_EMPLOYEE = 'symmetre-client-api/CREATE_DEAL_PATH_EMPLOYEE';
export const UPDATE_DEAL_PATH_EMPLOYEE = 'symmetre-client-api/UPDATE_DEAL_PATH_EMPLOYEE';
export const REMOVE_DEAL_PATH_EMPLOYEE = 'symmetre-client-api/REMOVE_DEAL_PATH_EMPLOYEE';
export const UPDATE_DEAL_PATH_USER_ROLE = 'symmetre-client-api/UPDATE_DEAL_PATH_USER_ROLE';

export function fetchDealPathSettings() {
  return async function (dispatch) {
    const res = await http.get('/deal_path_settings');
    const payload = await res.json();

    dispatch({ type: FETCH_DEAL_PATH_SETTINGS, payload });
  };
}

export function updateDealPathCategory(id, data) {
  return async function (dispatch) {
    try {
      const res = await http.put(`/deal_path_categories/${id}`, data);
      const payload = await res.json();

      // need to rebuild categories state and other settings according to changed status

      toastr.success('Category was successfully updated');
      dispatch({ type: UPDATE_DEAL_PATH_CATEGORY, payload });
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function updateDealPathUserRole(id, data) {
  return async function (dispatch) {
    try {
      const res = await http.put(`/deal_path_user_roles/${id}`, data);
      const payload = await res.json();

      toastr.success('Metrics was successfully updated');
      dispatch({ type: UPDATE_DEAL_PATH_USER_ROLE, payload });
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function updateDealPathGoal(id, data) {
  return async function (dispatch) {
    const res = await http.put(`/deal_path_goals/${id}`, data);
    const payload = await res.json();

    toastr.success('Goal was successfully updated');
    dispatch({ type: UPDATE_DEAL_PATH_GOAL, payload });
  };
}

export function updateDealPathTeam(id, data) {
  return async function (dispatch) {
    const res = await http.put(`/deal_path_teams/${id}`, data);
    const payload = await res.json();

    toastr.success('Setting was successfully updated');
    dispatch({ type: UPDATE_DEAL_PATH_TEAM, payload });
  };
}

export function updateDealPathPropertyType(id, data) {
  return async function (dispatch) {
    const res = await http.put(`/deal_path_property_types/${id}`, { ...data });
    const payload = await res.json();

    toastr.success('Setting was successfully updated');
    dispatch({ type: UPDATE_DEAL_PATH_PROPERTY_TYPE, payload });
  };
}

export function updateDealPathField(id, data) {
  return async function (dispatch) {
    const res = await http.put(`/deal_path_fields/${id}`, data);
    const payload = await res.json();

    toastr.success('Setting was successfully updated');
    dispatch({ type: UPDATE_DEAL_PATH_FIELD, payload });
  };
}

export function syncDealPathField(field) {
  return async function (dispatch) {
    const res = await http.post(`/deal_path_fields/${field.id}/sync`);
    if (res.status === 204) {
      toastr.info(`Field ${field.name} will be synced within next few minutes`);
      dispatch({ type: SYNC_DEAL_PATH_FIELD });
    } else {
      toastr.warning('You have reached daily limit. Try to run it tomorrow');
    }
  };
}

export function createDealPathEmployee(data) {
  return async function (dispatch) {
    const res = await http.post('/deal_path_employees', data);
    const payload = await res.json();

    toastr.success('Employee was successfully created');
    dispatch({ type: CREATE_DEAL_PATH_EMPLOYEE, payload });
  };
}

export function updateDealPathEmployee(data) {
  return async function (dispatch) {
    const res = await http.put(`/deal_path_employees/${data.id}`, data);
    const payload = await res.json();

    toastr.success('Employee was successfully updated');
    dispatch({ type: UPDATE_DEAL_PATH_EMPLOYEE, payload });
  };
}

export function removeDealPathEmployee(id) {
  return async function (dispatch) {
    const res = await http.del(`/deal_path_employees/${id}`);
    const payload = await res.json();

    toastr.success('Employee was successfully removed');
    dispatch({ type: REMOVE_DEAL_PATH_EMPLOYEE, payload });
  };
}

import { capitalGeneralLedgersApi } from '@/entities/return/api/capitalGeneralLedgersApi';
import { useAppDispatch } from '@/shared/lib/hooks/redux';

export function useInvalidateGeneralLedgers() {
  const dispatch = useAppDispatch();
  const invalidate = () => {
    dispatch(capitalGeneralLedgersApi.util.invalidateTags(['GeneralLedgers']));
  };

  return invalidate;
}

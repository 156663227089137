import { navigate } from '@reach/router';
import { UserActivityHeader } from './widgets/UserActivityHeader';
import UserActivityPanel from 'bundles/Shared/widgets/user/activity/ui/UserActivityPanel';
import UserActivityTable from 'bundles/Shared/widgets/user/activity/ui/UserActivityTable';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import React from 'react';

const UserManagementActivityPage = () => {
  const backToUserManagment = () => {
    navigate(ROUTES_ROOT.userManagement.fullPath);
  };

  return (
    <div>
      <UserActivityHeader onClick={backToUserManagment} />
      <div className="flex flex-col gap-tw-4 p-tw-6">
        <UserActivityPanel />
        <UserActivityTable />
      </div>
    </div>
  );
};

export default UserManagementActivityPage;

import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/shared/lib/css/cn';
import { Button } from 'stories';

const YesOrNo = ({ onYesClick, onNoClick, active }) => (
  <div className="d-inline-flex ml-l yes-no-buttons">
    <Button
      size="sm"
      disabled={!onYesClick}
      onClick={onYesClick}
      className={
        cn('mr-[0.5rem] yes-no-buttons__button', { 'yes-no-buttons__button_green': active === true })
      }
      type="button"
    >
      Yes
    </Button>
    <Button
      size="sm"
      disabled={!onNoClick}
      onClick={onNoClick}
      className={cn('yes-no-buttons__button', { 'yes-no-buttons__button_grey': active === false })}
      type="button"
    >
      No
    </Button>
  </div>
);

YesOrNo.propTypes = {
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  active: PropTypes.bool,
};

YesOrNo.defaultProps = {
  onYesClick: undefined,
  onNoClick: undefined,
  active: undefined,
};

export default YesOrNo;

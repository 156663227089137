import http from 'lib/http';
import * as Sentry from '@sentry/react';
import { IChangeOrder } from 'bundles/Construction/types';
import { IParamsCE } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { ISharedDocument } from 'types/SharedDocument';

export const FETCH_CHANGE_ORDERS = 'symmetre-client-api/FETCH_CHANGE_ORDERS';
export const FETCH_CHANGE_ORDER = 'symmetre-client-api/FETCH_CHANGE_ORDER';
export const CREATE_CHANGE_ORDER = 'symmetre-client-api/CREATE_CHANGE_ORDER';
export const UPDATE_CHANGE_ORDER = 'symmetre-client-api/UPDATE_CHANGE_ORDER';
export const GET_CHANGE_EVENT_INCLUSION =
  'symmetre-client-api/GET_CHANGE_EVENT_INCLUSION';
export const UPLOAD_CHANGE_ORDER_SHARED_FILES =
  'symmetre-client-api/UPLOAD_CHANGE_ORDER_SHARED_FILES';
export const APPROVE_CHANGE_ORDER = 'symmetre-client-api/APPROVE_CHANGE_ORDER';
export const REVERT_CHANGE_ORDER = 'symmetre-client-api/REVERT_CHANGE_ORDER';
export function fetchChangeOrders(legalEntityCode: string) {
  return async function (dispatch) {
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders`,
    );
    const payload = await res.json();

    dispatch({ type: FETCH_CHANGE_ORDERS, payload });
  };
}

export function fetchChangeOrder(legalEntityCode: string, id: number | string) {
  return async function (dispatch) {
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}`,
    );
    const responseData = (await res.json()) as IChangeOrder;

    if (res.status === 404) {
      Sentry.captureMessage(
        `Change Order (${id}) with LE (${legalEntityCode}) code not found`,
      );
    }
    dispatch({ type: FETCH_CHANGE_ORDER, payload: responseData });
    return responseData;
  };
}

export function createChangeOrder(legalEntityCode: string, data) {
  return async function (dispatch) {
    const res = await http.post(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('CO has been successfully created');

      dispatch({
        type: CREATE_CHANGE_ORDER,
        payload: responseData,
      });
    }
  };
}

export function updateChangeOrder(legalEntityCode: string, id: number, data) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('CO has been successfully updated');

      dispatch({
        type: UPDATE_CHANGE_ORDER,
        payload: { id, responseData },
      });
    }
  };
}

export function uploadChangeOrderSharedFiles(
  legalEntityCode: string,
  id: number,
  data,
) {
  return async function (dispatch) {
    const res = await http.post(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/documents/bulk_upload`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Documents have been successfully uploaded');
      dispatch({
        type: UPLOAD_CHANGE_ORDER_SHARED_FILES,
        payload: { id, responseData },
      });
    }
    return responseData;
  };
}

export function verifyChangeOrder(legalEntityCode: string, id: number, data) {
  return async function () {
    const res = await http.put(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/verify`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('CO has been successfully verified');
    }
  };
}

export function requestApprovalChangeOrder(
  legalEntityCode: string,
  id: number,
) {
  return async function () {
    const res = await http.put(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/request_approval`,
      {},
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('CO has been successfully verified');
    }
  };
}

export function approveChangeOrder(
  legalEntityCode: string,
  id: number,
  data?: { date: DateString },
) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/approve`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('CO has been successfully approved');
      dispatch({
        type: APPROVE_CHANGE_ORDER,
        payload: { id, responseData },
      });
    }
  };
}

export function revertChangeOrder(legalEntityCode: string, id: number) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/rollback`,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('CO has been successfully reverted');
      dispatch({
        type: REVERT_CHANGE_ORDER,
        payload: { id, responseData },
      });
    }
  };
}

export function selectFinalDocumentCO(
  legalEntityCode: string,
  id: number,
  idFile,
) {
  return async function () {
    const res = await http.put(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/set_supporting_document`,
      {
        document_id: idFile,
      },
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Document has been successfully selected');
    }
  };
}

export function getChangeEventForInclusionCO(legalEntityCode: string) {
  return async function (dispatch) {
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/change_order_events/for_co_inclusion`,
    );
    const responseData = await res.json();
    dispatch({
      type: GET_CHANGE_EVENT_INCLUSION,
      payload: { responseData },
    });
  };
}

export function updateChangeOrderIncludesCE(
  legalEntityCode: string,
  id: number,
  data,
) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/update_change_events`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('CO has been successfully updated');
    }
    dispatch({
      type: UPDATE_CHANGE_ORDER,
      payload: { id, responseData },
    });
  };
}

export function getHistoryEventCO(
  legalEntityCode: string,
  id: number,
  historyEventId: number,
) {
  return async function () {
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/history_events/${historyEventId}`,
    );
    const responseData = await res.json();
    return responseData;
  };
}

export async function viewDocumentReconcileChangeEvent({
  legalEntityCode,
  id,
  documentId,
}: IParamsCE['viewDocument']) {
  const res = await http.get(
    `/reconcile/legal_entities/${legalEntityCode}/change_orders/${id}/documents/${documentId}`,
  );
  const json = await res.json();

  return json as ISharedDocument;
}

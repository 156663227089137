import React, { useMemo } from 'react';
import { cn } from '@/shared/lib/css/cn';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker from 'react-datepicker';
import minMax from 'dayjs/plugin/minMax';
import MonthRangePickerDropdown from 'bundles/REport/components/financials/filter/monthRangePicker/MonthRangePickerDropdown';

dayjs.extend(minMax);

export interface DateRange {
  from?: Dayjs;
  to?: Dayjs;
}

interface Props {
  dateRange: DateRange;
  onChange: (dateRange: DateRange) => void;
  isSingleMonth?: boolean;
  availablePeriods?: Dayjs[];
  className?: string;
}

const DATE_FORMAT = 'MMM-YY';

const MonthsRangePicker = ({
  availablePeriods,
  dateRange,
  onChange,
  isSingleMonth,
  className,
}: Props) => {
  const [minDate, maxDate] = useMemo(
    () =>
      availablePeriods != null && availablePeriods.length > 0
        ? [
            dayjs.min(availablePeriods).toDate(),
            dayjs.max(availablePeriods).toDate(),
          ]
        : [],
    [availablePeriods],
  );

  const update = (from?: Dayjs, to?: Dayjs) => {
    onChange({ from: from ?? dateRange.from, to: to ?? dateRange.to });
  };

  return (
    <div className={cn('flex items-center', className)}>
      {isSingleMonth ? (
        <>
          <div className="light-60 inline-semibold mr-s">Period:</div>
          <MonthRangePickerDropdown
            buttonProps={{
              size: 's',
            }}
            label={dateRange.to?.format(DATE_FORMAT) ?? 'Not selected'}
          >
            <div style={{ width: 220 }}>
              <DatePicker
                inline
                showMonthYearPicker
                selected={dayjs(dateRange.to).toDate()}
                onChange={(date) => update(undefined, dayjs(date))}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </MonthRangePickerDropdown>
        </>
      ) : (
        <>
          <div className="light-60 inline-semibold mr-s">From:</div>
          <MonthRangePickerDropdown
            buttonProps={{
              size: 's',
            }}
            label={dateRange.from?.format(DATE_FORMAT) ?? 'Not selected'}
          >
            <div style={{ width: 220 }}>
              <DatePicker
                inline
                showMonthYearPicker
                selected={dayjs(dateRange.from).toDate()}
                startDate={dayjs(dateRange.from).toDate()}
                endDate={dayjs(dateRange.to).toDate()}
                onChange={(date) => update(dayjs(date))}
                selectsStart
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </MonthRangePickerDropdown>
          <div className="light-60 inline-semibold ml-s mr-s">To:</div>
          <MonthRangePickerDropdown
            buttonProps={{
              size: 's',
            }}
            label={dateRange.to?.format(DATE_FORMAT) ?? 'Not selected'}
          >
            <div style={{ width: 220 }}>
              <DatePicker
                inline
                showMonthYearPicker
                selected={dayjs(dateRange.to).toDate()}
                startDate={dayjs(dateRange.from).toDate()}
                endDate={dayjs(dateRange.to).toDate()}
                onChange={(date) => update(undefined, dayjs(date))}
                selectsEnd
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </MonthRangePickerDropdown>
        </>
      )}
    </div>
  );
};

export default MonthsRangePicker;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import { IChangeOrderApprover } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { ICompany } from 'bundles/Construction/types';
import { IUser, IUserRole } from 'types/User';

export interface IChangeOrderApproversResponseMeta {
  companies: Pick<ICompany, 'id' | 'name'>[];
  userRoles: Pick<IUserRole, 'id' | 'name'>[];
  users: (Pick<IUser, 'id' | 'fullName'> & { userRoleId: IUserRole['id'] })[];
}

interface IChangeOrderApproversResponse {
  items: IChangeOrderApprover[];
  meta: IChangeOrderApproversResponseMeta;
}

interface IUpdateApproversListBody {
  change_order_approvers_attributes: Pick<
    IChangeOrderApprover,
    'id' | 'priority'
  > &
    {
      user_id: IUser['id'];
      user_role_id: IUserRole['id'];
      company_id: ICompany['id'];
      _destroy: boolean;
    }[];
}

export const reconcileChangeOrderApproversApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/settings/reconcile/legal_entities`,
  }),
  reducerPath: 'reconcileChangeOrderApprover',
  tagTypes: ['ChangeOrderApprover'],
  endpoints: (build) => ({
    getChangeOrderApprovers: build.query<
      IChangeOrderApproversResponse,
      {
        legalEntityId: string;
      }
    >({
      query: (params) => `/${params.legalEntityId}/change_order_approvers`,
      providesTags: ['ChangeOrderApprover'],
    }),
    updateApproversList: build.mutation<
      IChangeOrderApproversResponse,
      {
        legalEntityId: string;
        body: IUpdateApproversListBody;
      }
    >({
      query: (params) => ({
        url: `/${params.legalEntityId}/change_order_approvers/update_list`,
        method: 'PUT',
        body: JSON.stringify(params.body),
      }),
      transformResponse: (response) => {
        toastr.success('Approvers successfully updated');
        return response.data as IChangeOrderApproversResponse;
      },
      invalidatesTags: ['ChangeOrderApprover'],
    }),
  }),
});

export const {
  useGetChangeOrderApproversQuery,
  useUpdateApproversListMutation,
} = reconcileChangeOrderApproversApi;

import React from 'react';
import { Badge, Icon } from 'stories';
import { CssVar } from '@/shared/config/cssVar';

interface Props {
  legalEntitiesCount: number;
  publishedCount: number;
  displayLabel?: boolean;
  clickable?: boolean;
  clicked?: boolean;
}

function PublishedStatusBadge({
  publishedCount,
  clickable,
  legalEntitiesCount,
  clicked,
  displayLabel,
}: Props) {
  const getPublishedLabel = () => {
    if (publishedCount === 0) return 'Draft';
    if (legalEntitiesCount === 1 || legalEntitiesCount === publishedCount)
      return 'Published';
    return `Published${
      legalEntitiesCount === 1
        ? ''
        : ` ${publishedCount} of ${legalEntitiesCount} LEs`
    }`;
  };

  const getPublishedColor = () => {
    if (publishedCount === 0) {
      return clicked ? CssVar.light80 : CssVar.light60;
    }
    if (publishedCount === legalEntitiesCount) {
      return CssVar.green;
    }
    return clicked ? CssVar.yellowDark4 : CssVar.yellowDark2;
  };

  return (
    <Badge
      clickable={clickable}
      backgroundColor={getPublishedColor()}
      label={displayLabel && 'Status'}
    >
      <div className="flex gap-xxs">
        {getPublishedLabel()}
        {clickable && <Icon iconName="bottom" />}
      </div>
    </Badge>
  );
}

export default PublishedStatusBadge;

import { omit } from 'lodash-es';
import { TAssetStatuses } from 'types/AssetStatus';

export type ObjectStatusLabel = {
  label: string;
  key: TAssetStatuses;
  color: string;
};
export const OBJECT_STATUSES_LABELS: ObjectStatusLabel[] = [
  { label: 'Draft', key: TAssetStatuses.DRAFT, color: '#E99023' },
  { label: 'Published', key: TAssetStatuses.PUBLISHED, color: '#21913C' },
  { label: 'Archived', key: TAssetStatuses.ARCHIVED, color: '#47545A' },
];
export const OBJECT_STATUSES_OPTIONS: (Omit<ObjectStatusLabel, 'key'> & {
  value: string;
})[] = OBJECT_STATUSES_LABELS.map((status) => ({
  ...omit(status, 'key'),
  value: status.key,
}));

export const getNextStatusAction = (value: TAssetStatuses) => {
  if (value === TAssetStatuses.DRAFT)
    return { label: 'Publish asset', status: 'published' };
  if (value === TAssetStatuses.PUBLISHED)
    return { label: 'Archive asset', status: 'archived' };
  if (value === TAssetStatuses.ARCHIVED)
    return { label: 'Unarchive asset', status: 'published' };
  return null;
};

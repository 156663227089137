import React from 'react';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { IconsId } from 'types/sre-icons';
import { capitalize } from 'lodash-es';

interface Props {
  extension: string;
  className?: string;
}

const ICON_COLORS = {
  image: 'blue-dark-3',
  pdf: 'red',
  excel: 'green-dark-1',
  word: 'blue',
  powerpoint: 'pumpkin-dark-5',
  video: 'primary',
  audio: 'violet',
  zip: 'yellow-dark-4',
  file: 'light-60',
};

const FORMATS = {
  pdf: ['pdf'],
  excel: ['xlsx', 'xlsm', 'xls', 'xltx', 'xltm'],
  word: ['docx', 'docm', 'dotx', 'dotm', 'docb'],
  powerpoint: [
    'pptx',
    'pptm',
    'potx',
    'potm',
    'ppam',
    'ppsx',
    'ppsm',
    'sldx',
    'sldm',
    'pa',
  ],
  image: ['png', 'jpeg', 'jpg'],
  video: ['mp4', 'mov', 'wmv', 'avi', 'mkv', 'avchd'],
  audio: ['mp3', 'aac', 'flac', 'alac', 'wav', 'aiff', 'dsd', 'pcm'],
  zip: ['zip', 'rar', 'jar', 'apk', 'cab', '7z', 'tag.gz'],
};

export const getIconParamsByExtension = (extension: string) => {
  const matchedTypes = Object.entries(FORMATS)
    .filter(([_, extensions]) => extensions.includes(extension))
    .map(([formatKey]) => formatKey);

  const matchedType = matchedTypes[0] ?? 'file';

  return {
    iconName:
      matchedType === 'file'
        ? 'file'
        : (`doc${capitalize(matchedType)}` as IconsId),
    iconColorClassName: ICON_COLORS[matchedType],
  };
};

export const FileIcon = ({ extension, className }: Props) => {
  const { iconColorClassName, iconName } = getIconParamsByExtension(extension);

  return (
    <Icon iconName={iconName} className={cn(iconColorClassName, className)} />
  );
};

export default FileIcon;

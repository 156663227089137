import React, { FC, useMemo } from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { IHardCostCode, IReallocation } from 'bundles/Construction/types';
import { getItemsSubReallocationModal } from '../initData';
import { useFormContext } from 'react-hook-form';
import { ICreateReallocationFormData } from 'bundles/Construction/components/Reallocation/Modals/types';
import SubReallocationForm from 'bundles/Construction/components/Reallocation/Modals/components/SubReallocationForm';
import { useParams } from '@reach/router';

interface ISubReallocationViewProps {
  formData: IReallocation;
  hardCostContingency: IHardCostCode;
  JCCForReallocations: IReallocation[];
}

export const SubReallocationViewNew = () => {
  const { legalEntityCode } = useParams();
  const { getValues } = useFormContext<ICreateReallocationFormData>();
  const subReallocations = getValues('subReallocations');

  if (subReallocations.length === 0) {
    return null;
  }

  return (
    <div>
      Sub Reallocations
      {subReallocations.map((subReallocation, idx) => (
        <div
          key={idx}
          className="group-form__step-item group-form__step-item_summary flex-col"
        >
          <SubReallocationForm
            subReallocation={subReallocation}
            legalEntityCode={legalEntityCode}
          />
        </div>
      ))}
    </div>
  );
};

const SubReallocationView: FC<ISubReallocationViewProps> = ({
  formData,
  hardCostContingency,
  JCCForReallocations,
  JCCTree,
}) => {
  const formItems = useMemo(
    () =>
      getItemsSubReallocationModal(
        hardCostContingency,
        JCCForReallocations,
        JCCTree,
      ),
    [JCCForReallocations],
  );
  return (
    <div className="bg-light-5">
      {Boolean(formData.subReallocations.length) && (
        <div className="text-uppercase light-60 inline-semibold mb-s mt-m">
          SubREallocation
        </div>
      )}
      {formData.subReallocations?.map((subReallocation, idx) => (
        <FormComponent
          key={`subRealllocation${idx}`}
          formData={subReallocation}
          formItems={formItems}
          styles={{
            wrapper: 'group-form__step-item mb-xs px-0 py-0 w-full bg-white',
          }}
          stylesView={{
            wrapper: 'mb-s',
          }}
          viewMode
          steps
        />
      ))}
    </div>
  );
};

export default SubReallocationView;

import { IconButton, StagePopover } from 'stories';
import React from 'react';
import assetImagePlaceholderNotArchived from 'images/asset-placeholder-not-archived.png';
import assetImagePlaceholderArchived from 'images/asset-placeholder-archived.png';
import { cn } from '@/shared/lib/css/cn';
import { IAsset } from 'types/Asset';
import { currentUserIsExternal } from 'lib/permissions';
import { IAssetStage } from 'types/AssetStage';
import styles from './AssetCard.module.scss';
import AssetStatusBadge from '../AssetBadge/AssetStatusBadge';
import AssetStageBadge from '../AssetBadge/AssetStageBadge';

export type TAssetCardSizes = 'l' | 's';

interface Props {
  asset: IAsset;
  className?: string;
  size?: TAssetCardSizes;
  stages: IAssetStage[];
  onClick?: () => void;
}

function AssetCard({ asset, size, stages, onClick, ...props }: Props) {
  return (
    <div
      className={cn(styles.card, {
        [styles.card_small]: size === 's',
        [styles.card_archived]: asset.aasmState === 'archived',
      })}
      onClick={onClick}
      {...props}
    >
      <img
        className={styles.bgImage}
        src={
          asset.smallPictureUrl ??
          (asset.aasmState === 'archived'
            ? assetImagePlaceholderArchived
            : assetImagePlaceholderNotArchived)
        }
        alt={asset.name}
      />
      <div className={cn(styles.content)}>
        <div className={cn(styles.header)} />
        <div className={styles.footer}>
          <div className={styles.badges}>
            {!currentUserIsExternal() && (
              <AssetStatusBadge
                label="Status"
                direction="vertical"
                status={asset.aasmState}
              />
            )}
            <StagePopover stages={stages} activeStage={asset.stage?.name}>
              <AssetStageBadge
                asset={asset}
                badgeProps={{
                  label: 'Stage',
                  direction: 'vertical',
                }}
              />
            </StagePopover>
          </div>
          <h4
            className={cn(styles.name, {
              'header6-regular': size === 's',
              'header5-regular': size === 'l',
            })}
          >
            {asset.name}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default AssetCard;

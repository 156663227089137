import * as yup from 'yup';
import { VALUE_DISPLAY_OPTIONS_SCHEMA } from '@/shared/lib/formatting/displayOptions';

export const XYCHART_CONFIG_ROW_SCHEMA = yup.object().shape({
  key: yup.number(),
  expression: yup.string().required(),
  label: yup.string().required(),
  color: yup.string(),
  value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
});
export type XYChartWidgetConfigRowForm = yup.InferType<
  typeof XYCHART_CONFIG_ROW_SCHEMA
>;

import http from 'lib/http';
import {
  FUND_PORTAL_PRODUCT_NAME,
  USER_MANAGEMENT_PRODUCT_NAME,
  currentUserAllowedTo,
} from 'lib/permissions';
import { useEffect, useState } from 'react';
import {
  AnimationLoader,
  Badge,
  Modal,
  SreAvatar,
  Tab,
  TabItem,
} from 'stories';

import LoadingPanel from '@/bundles/Shared/components/LoadingPanel';
import { ReadOnlyBasicInfo } from 'bundles/UserManagement/components/UserForm/BasicInfo';
import { IUser } from 'types/User';
import fetchViewableAssets from '../actions/ViewableAsset';
import fetchViewableFunds from '../actions/ViewableFund';
import AdviseesTab from './AdviseesTab';
import AdvisorsTab from './AdvisorsTab';
import { ObjectTab } from './EditUserModalComponents/ObjectTab';
import { IExtendedUser } from './EditUserModalComponents/types';
import EditUserForm from './UserForm/EditUserForm';
import PermissionsFor from './UserForm/PermissionsFor';
import { TUserTrackerTab } from './UserTracker/UserTracker';
import userFormTabs, { UserFormTabs } from './helpers/userFormTabs';

const RoleTab = ({ role }) => (
  <>
    <h6 className="mb-tw-2 text-neutral-450">
      Role:&nbsp;
      <span className="dark-60">{role.name}</span>
    </h6>
    <PermissionsFor selectedRole={role} />
  </>
);

interface Props {
  user: IUser;
  onClose: VoidFunction;
  afterUpdateCallback: VoidFunction;
  initialTab?: TUserTrackerTab;
}

const EditUserModal = ({
  onClose,
  initialTab,
  user,
  afterUpdateCallback,
}: Props) => {
  const [extendedUser, setExtendedUser] = useState<IExtendedUser>();
  const activeUserRole = extendedUser?.role;
  const isViewOnly =
    !currentUserAllowedTo('configure', USER_MANAGEMENT_PRODUCT_NAME) &&
    activeUserRole?.category !== 'external';

  const [viewModeTab, setViewModeTab] = useState<TUserTrackerTab>(
    initialTab ?? 'info',
  );
  const [editModeTab, setEditModeTab] = useState<UserFormTabs[number]>('Info');

  const [funds, setFunds] = useState();
  const [assets, setAssets] = useState();

  const tabs = userFormTabs(activeUserRole);

  useEffect(() => {
    fetchViewableAssets().then((data) => setAssets(data));
    fetchViewableFunds().then((data) => setFunds(data));
  }, []);

  const getUser = () => {
    http
      .get(`/settings/user_management/users/${user.id}`)
      .then((response) => response.json())
      .then((data) => setExtendedUser(data));
  };

  useEffect(() => {
    getUser();
  }, []);

  const loading = !extendedUser;

  return (
    <Modal
      toggle={onClose}
      size="700"
      classes={{
        header: 'w-full flex',
        headerContent: 'w-full',
        close: 'self-start mt-tw-2',
      }}
      header={
        <>
          {loading ? (
            <LoadingPanel />
          ) : (
            <div className="flex w-full flex-col items-center justify-center">
              {viewModeTab !== 'editUser' && !isViewOnly && (
                // todo: replace with storybook component
                <button
                  className="btn btn-link document-type-badge-outline z-[5] mb-tw-2 flex items-center gap-tw-2 px-tw-1 py-0"
                  onClick={() => setViewModeTab?.('editUser')}
                  type="button"
                >
                  <i className="sre-icon-edit light-60" />
                  Edit
                </button>
              )}
              {viewModeTab === 'editUser' && (
                <Badge className="badge-um-blue mb-xl">Edit Mode</Badge>
              )}
              <SreAvatar
                text={extendedUser.fullName}
                fontSize="1rem"
                height="3rem"
                width="3rem"
                className="mb-tw-2 mr-tw-2"
              />
              <div className="w-full text-center">
                <h6 className="dark-60">{extendedUser.fullName}</h6>
                <p className="light-60">{extendedUser.email}</p>
                <div className="my-tw-4 flex flex-wrap justify-center">
                  {extendedUser.tags.map(({ id, name }) => (
                    // TODO: replace with badge component
                    <div
                      key={id}
                      className="document-type-badge-outline light-90 py-0125rem px-045rem h-25p inline-regular mb-[0.5rem] mr-[0.5rem]"
                    >
                      <span className="sre-icon-tag mr-03125rem" />
                      {name}
                    </div>
                  ))}
                </div>
                {viewModeTab !== 'editUser' && (
                  <Tab fluid className="mb-tw-4 justify-between">
                    <TabItem
                      onClick={() => setViewModeTab?.('info')}
                      active={viewModeTab === 'info'}
                    >
                      Info
                    </TabItem>
                    <TabItem
                      onClick={() => setViewModeTab?.('role')}
                      active={viewModeTab === 'role'}
                    >
                      Role
                    </TabItem>
                    {extendedUser?.role?.name !== 'Advisor' && (
                      <TabItem
                        onClick={() => setViewModeTab?.('assets')}
                        active={viewModeTab === 'assets'}
                      >
                        Assets
                      </TabItem>
                    )}
                    {currentUserAllowedTo('view', FUND_PORTAL_PRODUCT_NAME) &&
                      extendedUser?.role?.name !== 'Advisor' && (
                        <TabItem
                          onClick={() => setViewModeTab?.('funds')}
                          active={viewModeTab === 'funds'}
                        >
                          Funds
                        </TabItem>
                      )}
                    {extendedUser?.role?.name === 'Advisor' && (
                      <TabItem
                        onClick={() => setViewModeTab?.('advisees')}
                        active={viewModeTab === 'advisees'}
                      >
                        Advisees
                      </TabItem>
                    )}
                    {user?.advisorIds?.length > 0 && (
                      <TabItem
                        onClick={() => setViewModeTab?.('advisors')}
                        active={viewModeTab === 'advisors'}
                      >
                        Advisors
                      </TabItem>
                    )}
                  </Tab>
                )}
              </div>
              {viewModeTab === 'editUser' && (
                <Tab fluid className="mb-tw-4 justify-between">
                  {tabs.map((tabType) => (
                    <TabItem
                      key={tabType}
                      onClick={() => setEditModeTab(tabType)}
                      active={editModeTab === tabType}
                    >
                      {tabType}
                    </TabItem>
                  ))}
                </Tab>
              )}
            </div>
          )}
        </>
      }
    >
      <>
        {loading && <AnimationLoader className="static min-h-[120px]" />}
        {!loading && (
          <>
            {viewModeTab === 'info' && (
              <ReadOnlyBasicInfo user={extendedUser} />
            )}
            {viewModeTab === 'role' && <RoleTab role={activeUserRole} />}
            {viewModeTab === 'assets' && (
              <ObjectTab
                view={extendedUser.viewAssetsList}
                manage={extendedUser.manageAssetsList}
                type="asset"
                canViewAll={extendedUser.userAccessLevel.canViewAll}
                canManageAll={extendedUser.userAccessLevel.canManageAll}
              />
            )}
            {viewModeTab === 'funds' && (
              <ObjectTab
                view={extendedUser.viewFundsList}
                manage={extendedUser.manageFundsList}
                type="fund"
                canViewAll={extendedUser.userAccessLevel.canViewAll}
                canManageAll={extendedUser.userAccessLevel.canManageAll}
              />
            )}

            {viewModeTab === 'advisees' && (
              <AdviseesTab advisees={extendedUser.advisees} />
            )}
            {viewModeTab === 'advisors' && (
              <AdvisorsTab advisors={extendedUser.advisors} />
            )}
            {viewModeTab === 'editUser' && (
              <EditUserForm
                tab={editModeTab}
                user={extendedUser}
                onClose={onClose}
                afterUpdateCallback={afterUpdateCallback}
                funds={funds}
                assets={assets}
              />
            )}
          </>
        )}
      </>
    </Modal>
  );
};

export default EditUserModal;

import { HistoricalReviewTableWidgetConfigDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';

export const DEFAULT_HISTORICAL_TABLE_WIDGET_CONFIG = {
  columns: [],
  rows: [],
  viz_type: 'table',
  viz_config: {
    column_groups: [],
    rows: [],
    columns: [
      {
        key: 'label',
        col_id: 'label',
        order: 0,
        header: {
          hide_title: true,
          hide_subtitle: true,
        },
        align: 'left',
      },
    ],
  },
} satisfies HistoricalReviewTableWidgetConfigDto;

/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { editor as editorTypes } from 'monaco-editor';
import { FORMULA_REFERENCE_PREFIX } from 'bundles/Shared/entities/formula';
import styles from 'bundles/Settings/components/REport/TableBuilder/ReportTableConfigEditor.module.scss';

export const markFormulas = (editor: editorTypes.IStandaloneCodeEditor) => {
  const model = editor.getModel()!;
  const matches = model.findMatches(
    new RegExp(`${FORMULA_REFERENCE_PREFIX}[^"\\s]+`, 'g'),
    true,
    true,
    false,
    null,
    true,
  );

  matches.forEach((match) => {
    editor.createDecorationsCollection([
      {
        range: match.range,
        options: {
          isWholeLine: false,
          blockClassName: styles.textRed,
          inlineClassName: styles.textRed,
        },
      },
    ]);
  });
};

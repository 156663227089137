import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'lib/http';
import {
  BudgetLegalEntitiesByYearResponse,
  BudgetLegalEntity,
  BudgetLegalEntityRequest,
  BudgetLegalEntityResp as BudgetLegalEntityResp,
  BudgetYear,
  CreateBudgetLegalEntityReq,
  OperationalBudgetsYearsResponse,
} from '../types';
import { stringify } from 'qs';
import { createImportResultsNotification } from '../utils';
import { camelKeysToSnake } from 'bundles/UserManagement/components/roles/utils';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';

const BUDGET_YEAR_TAG = 'BudgetYear';
const BUDGET_LEGAL_ENTITY_TAG = 'LegalEntity';

export const reconcileOperationalBudgetsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/reconcile/operational/budgets/`,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [BUDGET_YEAR_TAG, BUDGET_LEGAL_ENTITY_TAG],
  reducerPath: 'reconcileOperationalLegalEntities',
  endpoints: (build) => ({
    getBudgets: build.query<OperationalBudgetsYearsResponse, never>({
      providesTags: [BUDGET_YEAR_TAG],
      query: () => {
        const query = stringify(
          {
            sort: {
              field: 'year',
              order: 'desc',
            },
          },
          DEFAULT_STRINGIFY_OPTIONS,
        );
        return `/years${query}`;
      },
    }),

    getLegalEntities: build.query<
      BudgetLegalEntitiesByYearResponse,
      BudgetLegalEntityRequest
    >({
      providesTags: [BUDGET_LEGAL_ENTITY_TAG],
      query: (params) => {
        const snakeCaseReqParams = camelKeysToSnake(params);
        const query = stringify(snakeCaseReqParams, DEFAULT_STRINGIFY_OPTIONS);
        return `/years/${params.year}/legal_entities${query}`;
      },
    }),

    exportBudget: build.mutation({
      query: ({
        budgetId,
      }: {
        year: number;
        budgetId: BudgetLegalEntity['budgetId'];
        name: BudgetLegalEntity['name'];
      }) => ({
        url: `/${budgetId}/export`,
        responseHandler: (response) => response.blob(),
      }),
      transformErrorResponse(baseQueryReturnValue) {
        console.error('baseQueryReturnValue:', baseQueryReturnValue);
        toastr.error('Budget export failed');
        return baseQueryReturnValue;
      },
      transformResponse: (response, meta, arg) => {
        toastr.success('Budget exported successfully');
        saveFileOnUserDevice(response, `${arg.name ?? ''} ${arg.year}.xlsx`);
        return {
          name: arg.name,
        };
      },
    }),

    getBudgetsBulkTemplates: build.mutation({
      query: (budgetIds: BudgetLegalEntity['budgetId'][]) => {
        const query = stringify(
          { budget_ids: budgetIds },
          {
            addQueryPrefix: true,
            arrayFormat: 'brackets',
          },
        );
        return `/bulk/export${query}`;
      },
      transformResponse: (response) => {
        toastr.success('Templates exported successfully');
        // const file = await response.blob();
        // saveFileOnUserDevice(file, `${budget.legalEntity.name} ${budget.year}.xlsx`);
        return response;
      },
    }),

    createBudgetsBulk: build.mutation<
      unknown,
      {
        year: BudgetYear['year'];
        legalEntityIds: BudgetLegalEntity['id'][];
      }
    >({
      query: ({ year, legalEntityIds }) => ({
        url: '/bulk',
        method: 'POST',
        body: {
          items: legalEntityIds.map((legalEntityId) => ({
            year,
            legal_entity_id: legalEntityId,
          })),
        },
      }),
      transformResponse: (response) => {
        toastr.success('Budgets created successfully');
        return response;
      },
    }),

    importBudget: build.mutation<ImportBudgetResponse, ImportBudgetReq>({
      query: ({ budgetId, file, type }) => {
        const formData = new FormData();
        formData.set('import[file]', file);
        formData.set('import[type]', type);

        return {
          url: `/${budgetId}/import`,
          method: 'POST',
          formData,
          headers: { 'content-type': 'multipart/form-data' },
        };
      },
      transformResponse: (response: ImportBudgetResponse) => {
        createImportResultsNotification(response);
        return response;
      },
    }),

    createBudget: build.mutation<
      BudgetLegalEntityResp,
      CreateBudgetLegalEntityReq
    >({
      invalidatesTags: [BUDGET_YEAR_TAG, BUDGET_LEGAL_ENTITY_TAG],
      query: ({ year, legalEntityId }) => ({
        url: '/',
        method: 'POST',
        body: {
          year,
          legal_entity_id: legalEntityId,
        },
      }),
    }),

    updateBudgetState: build.mutation<
      BudgetLegalEntityResp,
      {
        budgetId: BudgetLegalEntity['budgetId'];
        state: 'draft' | 'in_progress' | 'published';
      }
    >({
      invalidatesTags: [BUDGET_YEAR_TAG, BUDGET_LEGAL_ENTITY_TAG],
      query: ({ budgetId, state }) => ({
        url: `/${budgetId}`,
        body: {
          state,
        },
      }),
    }),
  }),
});

export type ImportBudgetResponseStatus = 'success' | 'failure' | 'ignored';

export type ImportBudgetItemInfo = {
  status: ImportBudgetResponseStatus;
  errors: string[];
  warnings: string[];
};

export type ImportBudgetResponse = Record<string, ImportBudgetItemInfo>;

export interface ImportBudgetReq {
  budgetId: number;
  file: File;
  type: 'symmetre' | 'symmetre_raw';
}

export const {
  useExportBudgetMutation,

  // Remove those endpoints and this API file after migration from 'report/budget' -> 'reconcile/operational'

  // useGetBudgetsBulkTemplatesMutation,
  // useUpdateBudgetStateMutation,
  // useGetBudgetsQuery,
  // useGetLegalEntitiesQuery,
  // useCreateBudgetsBulkMutation,
  // useImportBudgetMutation,
  // useCreateBudgetMutation,
} = reconcileOperationalBudgetsApi;

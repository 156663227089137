import React from 'react';
import styles from 'bundles/Settings/components/REport/CategoriesList/PathBar/PathBar.module.scss';
import { IconButton } from 'stories';
import { ClassNameProps } from 'types/Props';
import { cn } from '@/shared/lib/css/cn';

interface Props extends ClassNameProps {
  onClose?: VoidFunction;
}

function FlyBottomPanelBar({
  onClose,
  children,
  className,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={cn(styles.bar, className)}>
      {children}
      {onClose && (
        <IconButton
          className="border-neutral-800 bg-neutral-800 text-white hover:bg-neutral-700"
          offHoverStyles
          iconName="closeSmall"
          onClick={onClose}
        />
      )}
    </div>
  );
}

export default FlyBottomPanelBar;

import React from 'react';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { cn } from '@/shared/lib/css/cn';

interface LogItemAuthorAndDateProps {
  date: number;
  authorName: string;
  className?: string;
}

export const LogItemAuthorAndDate = ({
  date,
  authorName,
  className,
}: LogItemAuthorAndDateProps) => (
  <p className={cn('flex light-60 flex-wrap gap-xs', className)}>
    <span>{authorName}</span>
    {date && authorName && <span> • </span>}
    {formatUnixDate(date, 'LL [at] LT')}
  </p>
);

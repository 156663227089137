import React from 'react';
import { ReportDashboardStatus } from 'bundles/Shared/entities/dashboard';
import {
  IThinTabItem,
  ThinTabGroup,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';

interface Props {
  status: ReportDashboardStatus | `${ReportDashboardStatus}` | null;
  onChange: (
    status: ReportDashboardStatus | `${ReportDashboardStatus}` | null,
  ) => void;
}

const TABS = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: ReportDashboardStatus.DRAFT,
    label: 'Draft',
  },
  {
    id: ReportDashboardStatus.PUBLISHED,
    label: 'Published',
  },
] as const satisfies readonly IThinTabItem[];

export function DashboardFilterByStatusTabs({ status, onChange }: Props) {
  return (
    <div className="flex items-center gap-tw-2">
      <span className="inline-regular text-neutral-500">Status</span>
      <ThinTabGroup
        items={TABS}
        selectedItem={TABS.find((tab) => tab.id === status) ?? TABS[0]}
        onSelectedItemChange={(tab) =>
          onChange(tab.id === 'all' ? null : (tab.id as ReportDashboardStatus))
        }
      />
    </div>
  );
}

import { navigate } from '@reach/router';
import React, { FC, useMemo } from 'react';
import { Button, Icon } from 'stories';
import { IGroupFormConfig } from '../GroupForm';
import { IFormButton, IFormItem, IGroupFormItem } from '../types/types';

interface Props {
  onSubmit: () => Promise<any>;
  formData: object;
  groupFormItems: IGroupFormItem[];
  loading: boolean;
  onLoading: (status: boolean) => void;
  buttonConfig: IFormButton;
  view?: boolean;
  viewMode?: boolean;
  viewModeSwitcher?: () => void;
  validate: object;
  groupFormConfig: IGroupFormConfig;
}

const FormButtons: FC<Props> = ({
  formData,
  onSubmit,
  groupFormItems,
  loading,
  onLoading,
  buttonConfig,
  view,
  viewMode,
  viewModeSwitcher,
  validate,
  groupFormConfig,
}) => {
  const {
    titleBtn,
    titleView,
    titleLoading,
    withoutCancel,
    width,
    message,
    funcSendForm,
    funcCancel,
  } = buttonConfig;
  const success = useMemo(() => {
    const fields = [];
    const arrOptionalValues = [];
    groupFormItems.forEach((groupItem: IGroupFormItem) =>
      groupItem.formItems.forEach((formItem: IFormItem) => {
        const value = formData[formItem.fieldName];
        if (formItem.required) {
          const valueIsSet = Boolean((value && value !== 'YES') || value === 0);

          fields.push(formItem);
          if (valueIsSet) {
            if (Array.isArray(value)) {
              if (value.length) {
                arrOptionalValues.push(value);
              }
            } else {
              arrOptionalValues.push(value);
            }
          }
        }
      }),
    );
    return arrOptionalValues.length >= fields.length;
  }, [formData]);

  const accept = useMemo(
    () => groupFormItems.filter((v) => !v?.activated),
    [formData],
  );

  const cancellingHandle = () => {
    if (funcCancel) funcCancel();
    else () => navigate('.');
  };

  const submitHandle = async () => {
    if (groupFormConfig?.reviewFlow && !viewMode) {
      viewModeSwitcher();
    } else {
      funcSendForm && funcSendForm();
      onLoading(true);
      const res = await onSubmit();

      if (!res) {
        onLoading(false);
        funcSendForm && funcSendForm(false);
      }
    }
  };
  return (
    <div className={buttonConfig?.stylesForMainWrapper}>
      {message && viewMode && !loading && (
        <div className="flex justify-center label-regular mb-s">
          <div
            onClick={viewModeSwitcher}
            className="light-90 inline-semibold mb-xs cursor-pointer"
          >
            Back to edit
          </div>
        </div>
      )}
      {message && !viewMode && (
        <div className="flex justify-center light-60 label-regular mb-s">
          {message}
        </div>
      )}
      <div className="flex justify-between">
        {!withoutCancel && (
          <Button variant="secondary" onClick={cancellingHandle}>
            Cancel
          </Button>
        )}
        <div
          className="flex items-center"
          style={{
            width: width ?? 'auto',
          }}
        >
          <Button
            style={{
              width: width ?? 'auto',
            }}
            variant="success"
            disabled={
              !success ||
              (groupFormConfig?.reviewFlow && accept.length) ||
              loading ||
              (validate && Object.values(validate)?.some((val) => !val))
            }
            iconName={buttonConfig?.iconName || undefined}
            iconPosition={buttonConfig?.iconPosition ?? 'right'}
            onClick={submitHandle}
          >
            {loading && (
              <Icon className="form-button-loading mr-s" iconName="sync" />
            )}
            {!viewMode && !loading && titleBtn}
            {view && viewMode && !loading && titleView}
            {loading && (titleLoading ?? titleBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default FormButtons;

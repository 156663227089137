import { Link } from '@reach/router';

export const LinkWrapper: React.FC<
  Omit<React.ComponentProps<typeof Link>, 'to'> & { to?: string }
> = ({ to, children, ...props }) =>
  to ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

import http from 'lib/http';
import { stringify } from 'qs';
import { IJobCostCode, IJobCostCodeOnIndex } from 'types/JobCostCode';

interface IFetchJobCostCodesArgs {
  page?: number;
  per_page?: number;
  query?: string;
  sort?: {
    field?: Pick<IJobCostCode, 'id' | 'name' | 'code'>;
    order?: 'asc' | 'desc';
  };
}

interface IFetchJobCostCodesResponse {
  items: IJobCostCodeOnIndex[];
  meta: {
    perPage: number;
    totalSize: number;
  };
}

export async function fetchJobCostCodes(data: IFetchJobCostCodesArgs) {
  const res = await http.get(
    `/settings/reconcile/job_cost_codes${stringify(data, {
      addQueryPrefix: true,
    })}`,
  );
  const responseData = await res.json();

  return responseData as IFetchJobCostCodesResponse;
}

export async function fetchJobCostCodesLE(
  legalEntityCode: string,
  data: IFetchJobCostCodesArgs,
) {
  const res = await http.get(
    `/settings/reconcile/legal_entities/${legalEntityCode}/job_cost_codes${stringify(
      data,
      { addQueryPrefix: true },
    )}`,
  );
  const responseData = await res.json();

  return responseData as IFetchJobCostCodesResponse;
}

interface ICreateJobCostCodesArgs {
  name?: string;
  code?: string;
  general_ledger_ids?: number[];
}

export async function createJobCostCode(data: ICreateJobCostCodesArgs) {
  const res = await http.post('/job_cost_codes', data);
  const responseData = await res.json();

  if (responseData.errors) {
    window.toastr.error(`${responseData.errors}\n`);
  } else {
    window.toastr.success('JCC successfully created');
  }

  return responseData as IJobCostCode;
}

interface IUpdateJobCostCodesArgs {
  name?: string;
  code?: string;
  general_ledger_ids?: number[];
}

export async function updateJobCostCode(
  id: number,
  data: IUpdateJobCostCodesArgs,
  legalEntityCode?: string,
) {
  const res = await http.put(
    legalEntityCode
      ? `/settings/reconcile/legal_entities/${legalEntityCode}/job_cost_codes/${id}`
      : `/settings/reconcile/job_cost_codes/${id}`,
    data,
  );
  const responseData = await res.json();

  if (responseData.errors) {
    window.toastr.error(`${responseData.errors}\n`);
  } else {
    window.toastr.success('JCC successfully updated');
  }

  return responseData as IJobCostCode;
}

export async function updateJobCostCodeCategories(request: {
  ids: number[];
  reconcile_development_category_id: number | null;
}) {
  const res = await http.put(
    '/settings/report/job_cost_codes/set_category',
    request,
  );
  const responseData = await res.json();

  if (responseData.errors) {
    window.toastr.error(`${responseData.errors}\n`);
    return false;
  }
  window.toastr.success('JCC categories successfully updated');

  return true;
}

import React from 'react';
import ProjectSummary from './ProjectSummary';

const Summary = () => (
  <>
    <h2 className="font-weight-700 p-tw-6">Project Summary</h2>
    <div className="h-[600px]">
      <ProjectSummary />
    </div>
  </>
);

export default Summary;

import * as monaco from 'monaco-editor';

class ProviderRegistry {
  private readonly registry = new Map<string, monaco.IDisposable>();

  public set(
    {
      language,
      providerType,
    }: {
      language: string;
      providerType: 'hover' | 'suggestions';
    },
    provider: monaco.IDisposable,
  ) {
    this.registry.set(`${language}-${providerType}`, provider);
  }

  public get({
    language,
    providerType,
  }: {
    language: string;
    providerType: 'hover' | 'suggestions';
  }) {
    return this.registry.get(`${language}-${providerType}`);
  }

  public delete({
    language,
    providerType,
  }: {
    language: string;
    providerType: 'hover' | 'suggestions';
  }) {
    this.registry.delete(`${language}-${providerType}`);
  }
}

export const providerRegistry = new ProviderRegistry();

import { CamelCase } from 'type-fest';

const TYPE_CLASS_NAMES = [
  'basic',
  'footer',
  'editable',
  'root',
  'grandTotal',
  'disabled',
] as const;
const FORMULA_CLASS_NAMES = [
  'green',
  'blue',
  'dark-grey',
  'dark',
  'grey',
] as const;
type FormulaTypeKey = 'formula';

type ClassNameType = (typeof TYPE_CLASS_NAMES)[number];
type FormulaClassNameType = (typeof FORMULA_CLASS_NAMES)[number];
export type CellTypeFlag = `is${Capitalize<
  Exclude<ClassNameType, 'basic'> | FormulaTypeKey
>}`;
export type FormulaCellTypeFlag = `is${Capitalize<
  CamelCase<FormulaClassNameType>
>}${Capitalize<FormulaTypeKey>}`;
export type CellTypeFlags = Record<CellTypeFlag | FormulaCellTypeFlag, boolean>;

type CommonCellClassName = 'cell-wrapper' | 'cell-inner';
type AutoGroupCellClassName =
  | 'auto-group-cell-wrapper'
  | 'auto-group-cell-inner';
export type AllCellClassNames = CommonCellClassName | AutoGroupCellClassName;

type CellClassNameDelimiter = '_';
type FormulaClassNameDelimiter = '_formula-';

type FormulaClassName<CN extends AllCellClassNames> = {
  [FormulaType in FormulaClassNameType]: `${CN}${FormulaClassNameDelimiter}${FormulaType}`;
};

type CellClassNamesObj<CN extends AllCellClassNames> = {
  [Type in ClassNameType]: `${CN}${CellClassNameDelimiter}${Type}`;
};

export type CellClassNames<CN extends AllCellClassNames> =
  CellClassNamesObj<CN> & {
    default: CN;
  } & {
    formula: FormulaClassName<CN>;
  };

const generateClassNames = <CN extends AllCellClassNames>(
  prefix: CN,
): CellClassNames<CN> => {
  const classNames = Object.fromEntries(
    TYPE_CLASS_NAMES.map((cn) => [cn, `${prefix}_${cn}`]),
  ) as CellClassNamesObj<CN>;

  const formula = Object.fromEntries(
    FORMULA_CLASS_NAMES.map((cn) => [cn, `${prefix}_formula-${cn}`]),
  ) as FormulaClassName<CN>;

  return {
    default: prefix,
    ...classNames,
    formula,
  };
};

const AUTO_GROUP_CELL_CLASS_NAMES = {
  wrapper: generateClassNames('auto-group-cell-wrapper'),
  inner: generateClassNames('auto-group-cell-inner'),
} as const satisfies AutoGroupCellClassNamesStruct;

export interface AutoGroupCellClassNamesStruct {
  wrapper: CellClassNames<'auto-group-cell-wrapper'>;
  inner: CellClassNames<'auto-group-cell-inner'>;
}

export interface CommonCellClassNamesStruct {
  wrapper: CellClassNames<'cell-wrapper'>;
  inner: CellClassNames<'cell-inner'>;
}

export interface HeaderCellClassNames {
  default: string;
}

export interface HeaderCellClassNamesStruct {
  wrapper: HeaderCellClassNames;
  inner: HeaderCellClassNames;
}

const COMMON_CELL_CLASS_NAMES = {
  wrapper: generateClassNames('cell-wrapper'),
  inner: generateClassNames('cell-inner'),
} as const satisfies CommonCellClassNamesStruct;

const HEADER_CELL_CLASS_NAMES = {
  wrapper: {
    default: 'header-cell-wrapper',
  },
  inner: {
    default: 'header-cell-inner',
  },
} as const satisfies HeaderCellClassNamesStruct;

const AUTO_GROUP_HEADER_CELL_CLASS_NAMES = {
  wrapper: {
    default: 'auto-group-header-cell-wrapper',
  },
  inner: {
    default: 'auto-group-header-cell-inner',
  },
} as const satisfies HeaderCellClassNamesStruct;

export const CELL_CLASS_NAMES = {
  AutoGroupCell: AUTO_GROUP_CELL_CLASS_NAMES,
  CommonCell: COMMON_CELL_CLASS_NAMES,
  Header: HEADER_CELL_CLASS_NAMES,
  AutoGroupHeader: AUTO_GROUP_HEADER_CELL_CLASS_NAMES,
} as const;

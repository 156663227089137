import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import React from 'react';
import { TConnectionType } from 'bundles/Shared/entities/connection/model';

interface Props extends ISharedSelectProps {
  value?: `${TConnectionType}`;
  disabled?: boolean;
}

export const CONNECTION_TYPE_OPTIONS = [
  {
    label: 'Inbound Email',
    value: TConnectionType.EMAIL,
  },
  {
    label: 'SFTP',
    value: TConnectionType.SFTP,
  },
  {
    label: 'API',
    value: TConnectionType.API,
  },
];

export const ConnectionTypeSelect = ({ onChange, disabled, value }: Props) => (
  <SharedSelect
    placeholder="Select Connection Type"
    options={CONNECTION_TYPE_OPTIONS.filter(
      (o) => o.value !== TConnectionType.API,
    )} // remove this filter when API connection creation with UI is supported
    onChange={onChange}
    value={CONNECTION_TYPE_OPTIONS.filter((opt) => opt.value === value)}
    disabled={disabled}
    newGen
  />
);

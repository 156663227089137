import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/Settings/shared/ReportSettingsNavigation.module.scss';
import React from 'react';

function ReportSettingsNavigation({ children }: React.PropsWithChildren) {
  return <nav className="flex flex-col gap-tw-8">{children}</nav>;
}

ReportSettingsNavigation.List = ({
  children,
  title,
}: React.PropsWithChildren<{ title: string }>) => (
  <div className="flex flex-col gap-tw-2">
    <span className="secondary-semibold pl-tw-3 uppercase  text-neutral-500">
      {title}
    </span>
    <ul className="ml-0">{children}</ul>
  </div>
);

ReportSettingsNavigation.Item = ({
  children,
  active,
}: React.PropsWithChildren<{
  active?: boolean;
}>) => (
  <li className={cn(styles.item, active && styles.item__active)}>{children}</li>
);

export default ReportSettingsNavigation;

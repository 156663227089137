/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import { ChangeableStatusBadge } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { StaticCardField } from 'bundles/Scoreboard/Pipeline/types';
import { IOption } from 'types/Option';
import { abbreviateNumberFormat } from '@/shared/lib/formatting/number';
import styles from './styles.module.scss';

interface Props {
  options: IOption[];
  initFields: StaticCardField[];
  onChange: (selected: IOption) => void;
}

export const FieldsWithStatusBadge = ({
  options,
  initFields,
  onChange,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<IOption | null>(
    options[0] ?? null,
  );

  return (
    <div className={cn('p-m rounded-[1rem] bg-light-5')}>
      <div className="flex flex-row-reverse">
        <ChangeableStatusBadge
          handleItemClick={(selected: IOption) => {
            setSelectedOption(selected);
            onChange(selected);
          }}
          options={options}
          badgeLabel=""
          selected={selectedOption}
        />
      </div>
      <div className="mt-m">
        <div className="flex flex-wrap gap-m">
          {initFields.map(({ id, label, main, value }) => (
            <div
              key={id}
              style={{ flex: main ? '100%' : '1', position: 'relative' }}
            >
              <div
                className={cn(
                  main ? styles.fieldWrapperMain : styles.fieldWrapper,
                )}
              >
                <div className={cn(styles.field)}>
                  <div className={cn('text-[1rem] text-center', styles.fieldTitle)}>
                    {main ? (
                      <h1 className="dark-60 font-44 text-center text-green">
                        {abbreviateNumberFormat(value)}
                      </h1>
                    ) : (
                      value.toLocaleString()
                    )}
                  </div>
                  <div className={cn('text-center', styles.description)}>
                    {label}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldsWithStatusBadge;

import React from 'react';
import { SreAvatar } from 'stories';

const AdvisorsTab = ({ advisors }) => (
  <>
    {advisors.length > 0 ? (
      <table className="table">
        <tbody>
          {advisors.map((advisor) => (
            <tr>
              <td>
                <div className="flex items-center">
                  <SreAvatar
                    text={advisor.fullName}
                    className="mr-[0.5rem]"
                    height="2.25rem"
                    width="2.25rem"
                  />
                  <div>
                    {advisor.fullName}
                    <div className="text-light-60 mt-tw-2">{advisor.email}</div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : <h6 className="text-center mt-tw-4">Advisors list is empty</h6>}
  </>
);

export default AdvisorsTab;

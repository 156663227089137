import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { FinancialTableSinglePeriodPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { SelectableMultilineButton } from '@/stories';
import { PeriodTypeWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  AVAILABLE_PERIOD_TYPES,
  AVAILABLE_PERIOD_TYPES_OPTIONS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';

export function WidgetStateReckonerPeriodType({
  state,
  onStateChange,
  periodTypes,
}: WidgetStateProps<PeriodTypeWidgetState> & {
  periodTypes: FinancialTableSinglePeriodPeriodType[];
}) {
  if (
    periodTypes.length <= 1 &&
    periodTypes[0] === AVAILABLE_PERIOD_TYPES.MONTH
  ) {
    return null;
  }

  const handleChange = (
    value: (typeof AVAILABLE_PERIOD_TYPES_OPTIONS)[number]['value'],
  ) => {
    onStateChange({
      period: getReckonerPeriodByPeriodTypeAndDate(
        value,
        state.period.date ?? state.period.to_date ?? state.period.last_date,
      ),
    });
  };

  return (
    <div className="flex gap-tw-1">
      {AVAILABLE_PERIOD_TYPES_OPTIONS.filter((o) =>
        periodTypes.includes(o.value),
      ).map((o) => (
        <SelectableMultilineButton
          selected={state.period.type === o.value}
          title={o.label}
          className="h-[32px] w-[initial] flex-[initial] py-tw-1"
          key={o.value}
          onClick={() => {
            handleChange(o.value);
          }}
        />
      ))}
    </div>
  );
}

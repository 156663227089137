import { HUGE_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import {
  InvestmentIndex,
  InvestmentObjectPreferredReturnDto,
  PostApiCapitalInvestmentDistributionsBulkApiArg,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import SelectInvestmentEntity from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalActions, ModalHeaderWithSubtitle } from 'stories';
import { EnterAccruedValuesStep } from './EnterAccruedValuesStep';
import { EnterAccruedAmountValuesStep } from './EnterAccruedAmountValuesStep';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';
import { Contribution } from '@/entities/return';
import { useLoadedCapitalPreferredReturn } from '@/bundles/REturn/hooks/useLoadedCapitalPreferredReturn';
import { useParams } from '@reach/router';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { useCapitalContributions } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useCapitalContributions';
import {
  getMonthlyPreferredReturn,
  getQuarterlyPreferredReturn,
} from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import { ReviewStep } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/ReviewStep';
import { isSameMonth } from '@/shared/lib/date';

interface Props
  extends DialogProps<PostApiCapitalInvestmentDistributionsBulkApiArg['body']> {
  capitalInvestments: InvestmentIndex[];
}

const calculateClassAPreferredReturnCents = ({
  contributions,
  periodType,
  preferredReturn,
}) => {
  const contributionsAmount = contributions.reduce(
    (acc, contribution) => acc + contribution.amountCents,
    0,
  );

  const calcFunc =
    periodType === 'monthly'
      ? getMonthlyPreferredReturn
      : getQuarterlyPreferredReturn;
  return calcFunc(contributionsAmount, preferredReturn?.classAPercent ?? 0);
};

const calculateClassBPreferredReturnCents = ({
  contributions,
  periodType,
  preferredReturn,
}) => {
  const contributionsAmount = contributions.reduce(
    (acc, contribution) => acc + contribution.amountCents,
    0,
  );

  const calcFunc =
    periodType === 'monthly'
      ? getMonthlyPreferredReturn
      : getQuarterlyPreferredReturn;
  return calcFunc(contributionsAmount, preferredReturn?.classBPercent ?? 0);
};

const calculateAccruedAmount = ({
  contributions,
  periodType,
  preferredReturn,
}: {
  contributions: Contribution[];
  periodType: string;
  preferredReturn: InvestmentObjectPreferredReturnDto | undefined;
}) => {
  return convertCentsToDollars(
    calculateClassAPreferredReturnCents({
      contributions: contributions.filter(
        (contribution) => contribution.investmentClass === 'a',
      ),
      periodType,
      preferredReturn,
    }) +
      calculateClassBPreferredReturnCents({
        contributions: contributions.filter(
          (contribution) => contribution.investmentClass === 'b',
        ),
        periodType,
        preferredReturn,
      }),
  );
};

const generateEntriesByDateRange = (
  capitalInvestment: InvestmentIndex,
  contributions: Contribution,
  preferredReturn: InvestmentObjectPreferredReturnDto | undefined,
) => {
  const { startDate, endDate, periodType } = capitalInvestment;
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const start = new Date(startDate);
  const finalEndDate = endDate ? new Date(endDate) : currentDate;

  const entries = [];

  switch (periodType) {
    case 'monthly': {
      let date = start;
      while (date <= finalEndDate) {
        const nextDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const contributionsFilteredByDates = contributions.filter(
          (contribution) => new Date(contribution.date) <= new Date(nextDate),
        );

        const amount = calculateAccruedAmount({
          contributions: contributionsFilteredByDates,
          periodType,
          preferredReturn,
        });

        entries.push({
          startDate: new Date(date),
          endDate: new Date(nextDate),
          amount,
          initialAmount: amount,
        });
        date = new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 1);
      }
      break;
    }
    case 'quarterly': {
      let date = start;
      while (date <= finalEndDate) {
        const nextDate = new Date(
          date.getMonth() < 3
            ? date.getFullYear()
            : date.getFullYear() + Math.floor((date.getMonth() + 3) / 12),
          ((Math.floor(date.getMonth() / 3) + 1) * 3) % 12,
          0,
        );
        const contributionsFilteredByDates = contributions.filter(
          (contribution) => new Date(contribution.date) <= new Date(nextDate),
        );

        const amount = calculateAccruedAmount({
          contributions: contributionsFilteredByDates,
          periodType,
          preferredReturn,
        });

        entries.push({
          startDate: new Date(date),
          endDate: new Date(nextDate),
          amount,
          initialAmount: amount,
        });
        date = new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 1);
      }
      break;
    }
    default:
      throw new Error(`Unsupported period type: ${periodType}`);
  }

  if (entries.length > 0) {
    entries[entries.length - 1].endDate = endDate;
  }
  return entries;
};

const AddAccruedModal = ({ onClose, capitalInvestments, onSubmit }: Props) => {
  const { data: preferredReturn } = useLoadedCapitalPreferredReturn();
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCapitalInvestments, setSelectedCapitalInvestments] = useState<
    InvestmentIndex[]
  >([]);

  const { objectId } = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();
  const { contributions, isFetching: isContributionDataFetching } =
    useCapitalContributions({ investmentObjectId: objectId });

  const step1Content = useMemo(
    () => (
      <SelectInvestmentEntity
        capitalInvestments={capitalInvestments}
        selectedItems={selectedCapitalInvestments}
        setSelectedItems={(selectedItems) => {
          setSelectedCapitalInvestments(selectedItems);
        }}
      />
    ),
    [selectedCapitalInvestments],
  );

  const step2Content = (
    <div className="p-tw-6">
      <EnterAccruedValuesStep
        selectedCapitalInvestments={selectedCapitalInvestments}
        setSelectedCapitalInvestments={setSelectedCapitalInvestments}
      />
    </div>
  );

  const step3Content = (
    <div className="p-tw-6">
      <EnterAccruedAmountValuesStep
        selectedCapitalInvestments={selectedCapitalInvestments}
        setSelectedCapitalInvestments={setSelectedCapitalInvestments}
      />
    </div>
  );

  const step4Content = (
    <div className="p-tw-6">
      <ReviewStep selectedCapitalInvestments={selectedCapitalInvestments} />
    </div>
  );

  const steps: Record<string, React.JSX.Element> = {
    1: step1Content,
    2: step2Content,
    3: step3Content,
    4: step4Content,
  };

  const stepsLength = Object.keys(steps).length;

  const step2Valid = () => {
    return selectedCapitalInvestments.every(
      (entry) => entry.startDate && entry.periodType,
    );
  };

  const stepsValid: Record<string, boolean> = {
    1: selectedCapitalInvestments.length !== 0,
    2: step2Valid(),
    3: selectedCapitalInvestments
      .flatMap((entry) => entry.entries)
      .every((entry) => entry?.amount),
    4: true,
  };

  const pluralizedSelectedCapitalInvestments = `${
    selectedCapitalInvestments.length
  } ${pluralize('Investment Entity', selectedCapitalInvestments.length)}`;
  const stepsSubmitText: Record<string, string> = {
    1: `Continue ${
      selectedCapitalInvestments.length > 0
        ? `with ${pluralizedSelectedCapitalInvestments}`
        : ''
    }`,
    2: 'Next',
    3: 'Next',
    4: 'Create Accrued Preferred',
  };

  const handleSubmit = () => {
    if (currentStep !== stepsLength) {
      setCurrentStep(currentStep + 1);

      if (currentStep == 2) {
        setSelectedCapitalInvestments(
          selectedCapitalInvestments.map((item) => ({
            ...item,
            entries: generateEntriesByDateRange(
              item,
              contributions.filter(
                (contribution) => contribution.capitalInvestmentId === item.id,
              ),
              preferredReturn,
            ),
          })),
        );
      }
      return;
    }

    const payload = {
      accrued_perferred_return: selectedCapitalInvestments.map((entry) => {
        const entries = entry.entries.map((accruedEntry) => ({
          date: formatToDateStringForRequest(accruedEntry.endDate),
          amount_cents: convertDollarsToCents(accruedEntry.amount),
        }));

        // Remove the last entry if it's not the last day of the month
        if (entries.length > 0) {
          const lastEntry = entries[entries.length - 1];

          if (!isSameMonth(lastEntry, lastEntry)) {
            entries.pop();
          }
        }

        const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
        const filteredEntries = entries.filter(
          (entry) => new Date(entry.date) <= currentDate,
        );

        return {
          capital_investment_id: entry.id,
          start_date: formatToDateStringForRequest(entry.startDate),
          end_date: entry.endDate
            ? formatToDateStringForRequest(entry.endDate)
            : undefined,
          period_type: entry.periodType,
          amount_cents: convertDollarsToCents(
            entry.entries.reduce(
              (acc, accruedEntry) => acc + accruedEntry.amount,
              0,
            ),
          ),
          accrued_entries_attributes: filteredEntries,
        };
      }),
    };

    onSubmit(payload);
  };

  if (isContributionDataFetching) {
    return null;
  }

  return (
    <Modal
      toggle={() => onClose()}
      size="xl"
      classes={{
        body: '!p-0',
      }}
      bodyHeight={600}
      zIndex={HUGE_MODAL_Z_INDEX + 2}
      header={
        <ModalHeaderWithSubtitle
          title="Bulk Adding of Accrued Preferred Values"
          subtitle={`Step ${currentStep} of ${stepsLength}`}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button
            variant="secondary"
            onClick={
              currentStep === 1
                ? onClose
                : () => setCurrentStep(currentStep - 1)
            }
          >
            {currentStep === 1 ? 'Cancel' : 'Back to Selection'}
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!stepsValid[currentStep]}
          >
            {stepsSubmitText[currentStep]}
          </Button>
        </ModalActions>
      }
    >
      {steps[currentStep]}
    </Modal>
  );
};

export default AddAccruedModal;

import React, { useState } from 'react';
import {
  CrudEntityModalProps,
  CrudModal,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import {
  CHANGE_ORDER_REASON_ENTITY_NAME,
  IReconcileSettingsChangeOrderReasonUpdate,
} from 'bundles/REconcile/actions/changeOrderReasons';
import ChangeOrderReasonFields from 'bundles/REconcile/components/development/home/settingsModal/changeOrderReasons/ChangeOrderReasonFields';
import { optionFor } from 'lib/helpers';

function ChangeOrderReasonCrudModal({
  onSubmit,
  entity,
  categories,
  ...props
}: CrudEntityModalProps<IReconcileSettingsChangeOrderReasonUpdate> & {
  categories: string[];
}) {
  const [formData, setFormData] = useState({
    ...entity,
    category: optionFor(entity.category),
  });

  const handleSubmit = () =>
    onSubmit?.({
      ...formData,
      category: formData.category?.value,
    });
  return (
    <CrudModal
      entityName={CHANGE_ORDER_REASON_ENTITY_NAME}
      onSubmit={handleSubmit}
      {...props}
    >
      <ChangeOrderReasonFields
        formData={formData}
        setFormData={setFormData}
        categories={categories}
      />
    </CrudModal>
  );
}

export default ChangeOrderReasonCrudModal;

import React, { useEffect } from 'react';
import { navigate, useParams } from '@reach/router';
import {
  useGetInvoiceQuery,
  useMarkForPaymentInvoiceMutation,
} from 'bundles/Construction/api/invoices';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import { replacePathParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { IconButton, Checkbox, AnimationLoader } from 'stories';
import EmailBlock from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markForPayment/EmailBlock';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import {
  selectMarkForPaymentData,
  setMarkForPaymentData,
  resetInvoiceState,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { useAppSelector, useAppDispatch } from '@/shared/lib/hooks/redux';
import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';

const MarkForPaymentInvoice = () => {
  const markForPaymentData = useAppSelector(selectMarkForPaymentData);
  const dispatch = useAppDispatch();

  const params = useParams();

  const { data: invoice, isLoading } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const [markForPaymentInvoice, { isLoading: isSending }] =
    useMarkForPaymentInvoiceMutation();

  useEffect(() => {
    dispatch(resetInvoiceState());
  }, []);

  const handleClose = () => {
    navigate(
      replacePathParams(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  };

  const handleMarkForPayment = async () => {
    const res = await markForPaymentInvoice({
      legalEntityCode: params.legalEntityCode,
      invoiceId: params.invoiceId,
      body: {
        contact_id: markForPaymentData.payableTeam?.id,
        subject: markForPaymentData.subject,
        body: markForPaymentData.body,
        save_as_template: markForPaymentData.saveAsTemplate,
      },
    });
    if (res?.error) return false;
    handleClose();
  };

  const saveTemplates = () => {
    dispatch(
      setMarkForPaymentData({
        ...markForPaymentData,
        saveAsTemplate: !markForPaymentData.saveAsTemplate,
      }),
    );
  };

  const canSending = () =>
    !(
      markForPaymentData.subject &&
      markForPaymentData.payableTeam &&
      markForPaymentData.body
    );

  if (isLoading && !invoice) return <AnimationLoader />;

  return (
    <div>
      <FilePreview
        file={{
          url: invoice?.document.url,
        }}
        handleClose={handleClose}
        withoutHeader
      >
        <div className="relative flex h-screen flex-col bg-white">
          <div className="absolute right-tw-5 top-tw-5 flex gap-tw-2">
            <IconButton
              iconName="close"
              size="l"
              onClick={handleClose}
              variant="secondary"
            />
          </div>
          <div className="header6-bold border-b border-solid border-light-10 p-tw-6 text-dark-60">
            Mark for Payment
          </div>
          <div
            className={cn(
              'flex flex-1 flex-col gap-tw-6 p-tw-6',
              styles.markForPaymentContainer,
              styles.tableBody,
            )}
          >
            <div className="inline-regular text-light-90">
              Once completed, the system will send out an approved and stamped
              Invoice to your Accounts Payable Team.
            </div>
            <EmailBlock />
          </div>
          <div className="flex h-[110px] flex-col justify-end gap-tw-4 bg-white px-tw-6 pb-tw-6 pt-tw-4">
            {Boolean(markForPaymentData.payableTeam) && (
              <div className="flex items-center gap-tw-2">
                <Checkbox
                  checked={markForPaymentData.saveAsTemplate}
                  onChange={saveTemplates}
                />
                <div className="label-regular text-light-80">
                  {`Save as default Email Template for ${
                    markForPaymentData.payableTeam?.name as string
                  }`}
                </div>
              </div>
            )}
            <SendDataButton
              variant="success"
              className="w-full"
              onClick={handleMarkForPayment}
              isLoading={canSending() || isSending}
              disabled={canSending() || isSending}
              disappearIconName="email"
            >
              Mark for Payment
            </SendDataButton>
          </div>
        </div>
      </FilePreview>
    </div>
  );
};

export default MarkForPaymentInvoice;

import React, { useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton, Collapse } from 'stories';

const CardHorizontalCollapse = ({ header, collapseButtonHidden = false, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => setIsOpen((opened) => !opened);

  return (
    <div className={cn('card-collapse-container', { 'card-collapse-container_opened': isOpen })}>
      <div className="flex items-center">
        {!collapseButtonHidden && (
          <IconButton
            onClick={toggleCollapse}
            iconName={`${isOpen ? 'top' : 'bottom'}`}
          />
        )}
        {header && (<div className="ml-tw-4">{header}</div>)}
      </div>
      <Collapse classes={{ container: 'flex' }} expanded={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

export default CardHorizontalCollapse;

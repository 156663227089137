import React from 'react';
import {
  currentUserAllowedTo,
  USER_MANAGEMENT_PRODUCT_NAME,
} from 'lib/permissions';
import { isEqual } from 'lodash-es';
import { IUser } from 'types/User';
import { IUserTableColumnsActions } from 'bundles/UserManagement/components/table/userTableColumns';
import { IconButton, Dropdown, DropdownItem } from 'stories';

interface Props {
  user: IUser;
  actions: IUserTableColumnsActions;
}

function ActionsCell({ actions, user }: Props) {
  const actionsList = [];
  const canConfigureUM = currentUserAllowedTo(
    'configure',
    USER_MANAGEMENT_PRODUCT_NAME,
  );
  if (canConfigureUM || user.external) actionsList.push('edit');
  if (window.symmetreConfig.currentUser.id !== user.id) {
    if (
      user.invitationSentAt &&
      user.inviteStatus === ('invited' || 'invited_long_time_ago')
    )
      actionsList.push('reinvite');
    if (user.inviteStatus === 'created') actionsList.push('invite');
    if (canConfigureUM && user.inviteStatus === 'deactivated')
      actionsList.push('activate');
    if (
      (canConfigureUM || user.external) &&
      user.inviteStatus !== 'deactivated'
    )
      actionsList.push('deactivate');
  }

  if (actionsList.length === 0) return <div>–</div>;

  return (
    <div>
      <IconButton
        disabled={!actionsList.includes('edit')}
        iconName="edit"
        className="mr-s"
        onClick={() => actions.edit(user, 'editUser')}
      />

      {!isEqual(actionsList, ['edit']) && (
        <Dropdown
          items={
            <>
              {actionsList.includes('reinvite') && (
                <DropdownItem onClick={() => actions.invite(user)}>
                  Send invite again
                </DropdownItem>
              )}
              {actionsList.includes('invite') && (
                <DropdownItem onClick={() => actions.invite(user)}>
                  Send invite
                </DropdownItem>
              )}
              {actionsList.includes('activate') && (
                <DropdownItem onClick={() => actions.deactivate(user)}>
                  Activate
                </DropdownItem>
              )}
              {actionsList.includes('deactivate') && (
                <DropdownItem onClick={() => actions.deactivate(user)}>
                  Deactivate
                </DropdownItem>
              )}
            </>
          }
        >
          <IconButton iconName="more" />
        </Dropdown>
      )}
    </div>
  );
}

export default ActionsCell;

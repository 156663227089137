import React, { FC } from 'react';
import { Icon } from 'stories';
import { IFormItem } from '../types/types';
import FormViewTitle from './FormViewTitle';

const FormViewText: FC<IFormItem> = ({
  titleView,
  formData,
  emptyView,
  fieldName,
  transformerView,
  value,
}) => {
  const valueIsSet = Boolean(formData[fieldName] || value || value === 0);

  return (
    <div className="flex form-view-item gap-s">
      <FormViewTitle title={titleView} />
      {valueIsSet && (
        <div>
          {transformerView
            ? transformerView(value ?? formData[fieldName], formData)
            : value ?? formData[fieldName]}
        </div>
      )}
      {!valueIsSet && emptyView}
      {!valueIsSet && !emptyView && (
        <div>
          <Icon iconName="minus" className="light-20" />
        </div>
      )}
    </div>
  );
};

export default FormViewText;

import { snakeCaseKeys } from '@/bundles/Construction/components/helpers';
import { GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { exportFile } from '@/shared/lib/exportFile';

export const useSettingsReportUnitStatusesExport = () => {
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleExport = (
    params: GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg,
  ) => {
    exportFile({
      url: '/settings/report/rent_roll/source_unit_statuses/export.csv',
      params: snakeCaseKeys(params),
      filePostfix: 'unit-statuses',
      enableLoading,
      disableLoading,
    });
  };

  return [handleExport, { isLoading }] as const;
};

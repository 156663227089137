import { ICapitalInvestment } from 'bundles/REturn/types';

export function createWorkflowFlags({
  selectedSourceInvestmentEntity,
  selectedClass,
  amountToTransfer,
  transferDate,
  receivers,
  stepIdx,
  hasUnitPrices,
  dateForUnitPrice,
  currentUnitPrice,
  unitsToTransfer,
}: {
  selectedSourceInvestmentEntity: ICapitalInvestment | null;
  selectedClass: 'a' | 'b' | null;
  amountToTransfer: number | null;
  transferDate: Date | null;
  receivers: ICapitalInvestment[];
  stepIdx: number;
}) {
  const steps = {
    transferSource: {
      isCurrent: stepIdx === 0,
      get isDisabledToGoForward() {
        if (!selectedSourceInvestmentEntity) return true;
        if (!selectedClass) return true;
        if (!transferDate) return true;
        if (hasUnitPrices && !dateForUnitPrice) return true;
        if (!hasUnitPrices && amountToTransfer <= 0) return true;
        if (currentUnitPrice?.priceCents == 0 && !unitsToTransfer) return true;
        if (currentUnitPrice?.priceCents > 0 && (!amountToTransfer || !unitsToTransfer))
          return true;
      },
    },
    receivers: {
      isCurrent: stepIdx === 1,
      get isDisabledToGoForward() {
        return receivers.length == 0;
      },
    },
    review: {
      isCurrent: stepIdx === 2,
    },
  } as const;

  return {
    steps,
  };
}

import { stringify } from 'qs';
import http from 'lib/http';
import pluralize from 'pluralize';

export const UPDATE_SHARED_FILE = 'symmetre-client-api/UPDATE_SHARED_FILE';
export const MOVE_SHARED_FILE_TO_FOLDER =
  'symmetre-client-api/MOVE_SHARED_FILE_TO_FOLDER';
export const FETCH_SHARED_FILES_BY_FOLDER =
  'symmetre-client-api/FETCH_SHARED_FILES_BY_FOLDER';
export const LOADING_SHARED_FILES = 'symmetre-client-api/LOADING_SHARED_FILES';
export const REMOVE_SHARED_FILE = 'symmetre-client-api/REMOVE_SHARED_FILE';
export const REMOVE_SHARED_FILES = 'symmetre-client-api/REMOVE_SHARED_FILES';
export const START_REMOVE_SHARED_FILES =
  'symmetre-client-api/START_REMOVE_SHARED_FILES';
export const FETCH_SHARED_FILES = 'symmetre-client-api/FETCH_SHARED_FILES';
export const CREATE_SHARED_FILES = 'symmetre-client-api/CREATE_SHARED_FILES';

export function fetchSharedFiles() {
  return async (dispatch) => {
    const res = await http.get('/shared_files');
    const responseData = await res.json();

    dispatch({
      type: FETCH_SHARED_FILES,
      payload: responseData.sharedFiles,
    });
  };
}

export function fetchSharedFilesByFolder(data) {
  return async (dispatch) => {
    dispatch({ type: LOADING_SHARED_FILES });
    const res = await http.get(`/shared_files?${stringify(data)}`);
    const responseData = await res.json();

    dispatch({
      type: FETCH_SHARED_FILES_BY_FOLDER,
      payload: responseData,
    });
  };
}

export function updateSharedFile(data) {
  return async (dispatch) => {
    // TODO: use `http.update`
    const res = await http.put(`/shared_files/${data.shared_file.id}`, data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Item has been successfully updated');
      dispatch({
        type: UPDATE_SHARED_FILE,
        payload: responseData,
      });
    }
  };
}

export function bulkUpdateSharedFiles(data) {
  return async (dispatch) => {
    const res = await http.put('/shared_files/bulk', data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Files have been updated');
      dispatch({
        type: MOVE_SHARED_FILE_TO_FOLDER,
        payload: responseData,
      });
    }
  };
}

export function removeSharedFile({ sharedFile }) {
  return async (dispatch) => {
    const res = await http.del(`/shared_files/${sharedFile.id}`);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('File has been removed');
      dispatch({
        type: REMOVE_SHARED_FILE,
        payload: sharedFile,
      });
    }
  };
}

export function removeSharedFiles(data: { ids: number[] }) {
  return async (dispatch) => {
    const res = await http.del('/shared_files/bulk', data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success(
        `${pluralize('File', data.ids.length)} ${pluralize(
          'have',
          data.ids.length,
        )} been removed`,
      );
      dispatch({
        type: REMOVE_SHARED_FILES,
        payload: data,
      });
    }
  };
}

export function newCreateSharedFiles(data) {
  return async () => {
    const res = await http.post('/shared_files/bulk', data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Files have been successfully uploaded');
      return responseData;
    }
  };
}

export async function toggleLockSharedFile({ id }) {
  const res = await http.put(`/shared_files/${id}/toggle_lock_permissions`);
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('File has been successfully updated');
  }
}

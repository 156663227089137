import VarianceHeader from 'bundles/REconcile/components/development/requisitions/headers/VarianceHeader';
import { varianceValueGetter } from 'bundles/REconcile/components/development/requisitions/shared/requisitionsTable/utils';
import { Icon } from 'stories/index';
import { CustomCurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CustomCurrencyCellRenderer';
import { Dispatch, SetStateAction } from 'react';
import { BasicHeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';

export const getVarianceColumns = (
  showAccountPayable: boolean,
  setShowAccountPayable: Dispatch<SetStateAction<boolean>>,
) => ({
  headerName: 'Variance',
  headerComponent: (params) => (
    <BasicHeaderComponent>
      <VarianceHeader {...params} />
    </BasicHeaderComponent>
  ),
  pinned: 'right',
  headerComponentParams: {
    subHeaderName: 'Accounts Payable',
    rightBar: (
      <Icon
        iconName={showAccountPayable ? 'eye' : 'eyeSlash'}
        onClick={() => setShowAccountPayable(!showAccountPayable)}
      />
    ),
    verticalAlignContent: 'end',
  },
  valueGetter: (data) => varianceValueGetter({ showAccountPayable, ...data }),
  cellRenderer: CustomCurrencyCellRenderer,
  width: 165,
  aggFunc: 'sum',
});

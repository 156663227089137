/* eslint-disable quote-props */
import Table from 'bundles/Shared/components/Table/Table';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { useGetApiSettingsUserManagementUserActivitiesUsersQuery } from 'bundles/Shared/entities/user/api/userActivityApi';
import { TrendLabel } from 'bundles/Shared/shared/TrendLabel';
import React, { useState } from 'react';
import { useUserActivityColumns } from 'bundles/Shared/widgets/user/activity/ui/useColumns';
import { selectUserActivitySummary } from 'bundles/Shared/entities/user/slices/userActivitySlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { IQueryParams } from 'types/Pagination';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

const UserActivityTable = () => {
  const { fromDate, toDate, roleIds } = useAppSelector(
    selectUserActivitySummary,
  );

  const [pageParams, setPageParams] = useState<
    IQueryParams & {
      roleIds?: string[];
    }
  >({
    roleIds,
    query: undefined,
  });

  const { data: users, isFetching } =
    useGetApiSettingsUserManagementUserActivitiesUsersQuery({
      ...pageParams,
      fromDate: formatToDateStringForRequest(fromDate),
      toDate: formatToDateStringForRequest(toDate),
      userRoleIds: roleIds,
      query: pageParams.query,
    });

  const columns = useUserActivityColumns();

  if (users?.items?.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-tw-4 overflow-hidden rounded-[16px] bg-neutral-000 pt-tw-4">
      <div className="flex items-center gap-tw-4 px-tw-6">
        <div className="font-bold text-neutral-900">Users</div>
        <TrendLabel
          value={users?.meta?.overallTrend ?? 0}
          className="label-regular"
        >
          <div>Active users</div>
        </TrendLabel>
      </div>
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div className="flex justify-between px-tw-6">
          <PageParamsPagination
            loading={isFetching}
            sizePerPage={10}
            totalSize={users?.meta.totalSize ?? 0}
          />
          <PageParamsSearch />
        </div>
        <Table
          items={users?.items ?? []}
          columns={columns}
          loading={isFetching}
          settings={pageParams}
          setSettings={setPageParams}
          classes={{
            container: 'table-gen-2',
            th: 'bg-neutral-900 !text-neutral-000',
          }}
        />
      </PageParamsProvider>
    </div>
  );
};

export default UserActivityTable;

import React from 'react';
import { Modal } from 'stories/';
import CampaignEmailCardInfo from './CampaignEmailCardInfo';

const CampaignCardEmailListModal = ({
  iconClass,
  color,
  title,
  date,
  time,
  onClose,
  children,
}) => {
  const Header = () => (
    <div className="items-center bg-white">
      <CampaignEmailCardInfo
        size="sm"
        title={title}
        date={date}
        time={time}
        iconClass={iconClass}
        color={color}
      />
    </div>
  );

  return (
    <Modal
      size="lg"
      toggle={onClose}
      header={<Header />}
      classes={{ body: 'bg-light pt-s' }}
    >
      {children}
    </Modal>
  );
};

export default CampaignCardEmailListModal;

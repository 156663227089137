export const PERIOD_AGGREGATION_STRATEGIES = {
  FIRST: 'first',
  LAST: 'last',
  SUM: 'sum',
  AVG: 'avg',
  AVG_TIME_WEIGHTED: 'avg_time_weighted',
  MIN: 'min',
  MAX: 'max',
} as const;

export type PeriodAggregationStrategy =
  (typeof PERIOD_AGGREGATION_STRATEGIES)[keyof typeof PERIOD_AGGREGATION_STRATEGIES];

export const PERIOD_AGGREGATION_STRATEGIES_OPTIONS = [
  {
    id: PERIOD_AGGREGATION_STRATEGIES.FIRST,
    value: PERIOD_AGGREGATION_STRATEGIES.FIRST,
    label: 'First',
  },
  {
    id: PERIOD_AGGREGATION_STRATEGIES.LAST,
    value: PERIOD_AGGREGATION_STRATEGIES.LAST,
    label: 'Last',
  },
  {
    id: PERIOD_AGGREGATION_STRATEGIES.SUM,
    value: PERIOD_AGGREGATION_STRATEGIES.SUM,
    label: 'Sum',
  },
  {
    id: PERIOD_AGGREGATION_STRATEGIES.AVG,
    value: PERIOD_AGGREGATION_STRATEGIES.AVG,
    label: 'Avg',
  },
  {
    id: PERIOD_AGGREGATION_STRATEGIES.AVG_TIME_WEIGHTED,
    value: PERIOD_AGGREGATION_STRATEGIES.AVG_TIME_WEIGHTED,
    label: 'Avg time weighted',
  },
  {
    id: PERIOD_AGGREGATION_STRATEGIES.MIN,
    value: PERIOD_AGGREGATION_STRATEGIES.MIN,
    label: 'Min',
  },
  {
    id: PERIOD_AGGREGATION_STRATEGIES.MAX,
    value: PERIOD_AGGREGATION_STRATEGIES.MAX,
    label: 'Max',
  },
] as const satisfies readonly {
  id: PeriodAggregationStrategy;
  label: string;
  value: PeriodAggregationStrategy;
}[];

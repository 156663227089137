import { usePutApiReconcileUnderwritingBudgetsByBudgetIdMutation } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import { UnderwritingBudget } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { SettingsFormModal } from 'bundles/Shared/components/SettingsModal/SettingsModal';
import dayjs from 'dayjs';
import { useModal } from '@/shared/lib/hooks/useModal';
import { asserts } from 'lib/typeHelpers/assertsType';
import { useCallback } from 'react';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import {
  genPossiblePeriods,
  genPossiblePeriodsRange,
} from 'stories/FlexibleFilterByPeriods/utils';
import { FlexibleFilterByPeriods } from 'stories/index';
import { useController, useFormContext } from 'react-hook-form';

const BudgetFields = () => {
  const { control } = useFormContext();
  const { field } = useController({
    control,
    name: 'periods',
  });
  return (
    <FlexibleFilterByPeriods
      possiblePeriods={genPossiblePeriods()}
      popoverProps={{
        placement: 'bottom-start',
      }}
      buttonProps={{
        className: 'w-full justify-start',
      }}
      periodItems={field.value}
      filterByPeriodsType="mtd"
      onUpdatePeriodItems={(periods) => {
        field.onChange(periods);
      }}
      initialOpenedYear={dayjs().year().toString()}
      isSingleSelection
      fromToMode
    />
  );
};

export function useUpdateUnderwritingBudgetPeriods() {
  const [updateBudget, options] =
    usePutApiReconcileUnderwritingBudgetsByBudgetIdMutation();
  const { openModal } = useModal();

  const handleUpdate = useCallback(
    async ({ budgetData }: { budgetData: UnderwritingBudget }) => {
      const initialPeriods = genPossiblePeriodsRange({
        from: budgetData.dateFrom,
        to: budgetData.dateTo,
      });

      const res = await openModal(
        SettingsFormModal<{
          periods: IPeriodItem[];
        }>,
        {
          formProps: {
            defaultValues: {
              periods: initialPeriods,
            },
          },
          modalProps: {
            size: 'sm',
          },
          fields: <BudgetFields />,
        },
      );

      if (res == null) return;
      const dateToPeriodItem = res.periods.at(-1);

      asserts(dateToPeriodItem, '"date_to" should be returned from datepicker');

      await updateBudget({
        budgetId: budgetData.id,
        body: {
          date_from: res.periods[0].period,
          date_to: dateToPeriodItem.period,
        },
      });
    },
    [],
  );

  return [handleUpdate, options] as const;
}

export function useUpdateUnderwritingBudgetState() {
  const [updateBudget, options] =
    usePutApiReconcileUnderwritingBudgetsByBudgetIdMutation();

  const handleUpdate = useCallback(
    async ({
      budgetDataId,
      state,
    }: {
      budgetDataId: UnderwritingBudget['id'];
      state: 'published' | 'draft';
    }) => {
      await updateBudget({
        budgetId: budgetDataId,
        body: {
          state,
        },
      });
    },
    [],
  );

  return [handleUpdate, options] as const;
}

import { TDevelopmentLegalEntityReconcileSettings } from 'bundles/REconcile/types/TDevelopmentLegalEntityReconcileSettings';
import { CamelToSnakeCase } from 'types/StringUtilityTypes';
import { TUpdateLegalEntityLegacySettingsRequest } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntitiesSettings';

import { formatToDateStringForRequest } from '@/shared/lib/converters';

type TCardKeys = keyof Pick<
  TDevelopmentLegalEntityReconcileSettings,
  | 'hardConstructionCost'
  | 'hardCostContingency'
  | 'siteworkContingency'
  | 'completedAndStored'
  | 'retainage'
  | 'previousCertifiedWorks'
>;

type TSelectKeys = keyof Pick<
  TDevelopmentLegalEntityReconcileSettings,
  | 'hardCostContingencyCodeId'
  | 'hardConstructionCostCodeId'
  | 'softCostContingencyCodeId'
>;

export const costsFields: Extract<
  TCardKeys,
  'hardConstructionCost' | 'hardCostContingency' | 'siteworkContingency'
>[] = ['hardConstructionCost', 'hardCostContingency', 'siteworkContingency'];
export const constructionBudgetFields: Extract<
  TCardKeys,
  'completedAndStored' | 'retainage' | 'previousCertifiedWorks'
>[] = ['completedAndStored', 'retainage', 'previousCertifiedWorks'];

export const mainBudgetTypeCodeFields: TSelectKeys[] = [
  'hardConstructionCostCodeId',
  'hardCostContingencyCodeId',
  'softCostContingencyCodeId',
];
export const rootBreadcrumb = {
  label: 'Settings',
  href: '/settings/construction/assets',
};

export const mapSettingsToCardProperties = (
  settings: TDevelopmentLegalEntityReconcileSettings,
): {
  [P in TCardKeys]: {
    label: string;
    key: CamelToSnakeCase<P>;
    value: TDevelopmentLegalEntityReconcileSettings[P];
    lastChangedBy: string | null;
    lastChangedAt: number | null;
  };
} => ({
  hardConstructionCost: {
    label: 'Hard Construction Cost',
    key: 'hard_construction_cost',
    value: settings.hardConstructionCost,
    lastChangedBy: settings.hardConstructionCostSource,
    lastChangedAt: settings.hardConstructionCostDate,
  },
  hardCostContingency: {
    label: 'Hard Cost Contingency',
    key: 'hard_cost_contingency',
    value: settings.hardCostContingency,
    lastChangedBy: settings.hardCostContingencySource,
    lastChangedAt: settings.hardCostContingencyDate,
  },
  siteworkContingency: {
    label: 'Sitework Contingency',
    key: 'sitework_contingency',
    value: settings.siteworkContingency,
    lastChangedBy: settings.siteworkContingencySource,
    lastChangedAt: settings.siteworkContingencyDate,
  },
  completedAndStored: {
    label: 'Completed & Stored (to date)',
    key: 'completed_and_stored',
    value: settings.completedAndStored,
    lastChangedBy: settings.completedAndStoredSource,
    lastChangedAt: settings.completedAndStoredDate,
  },
  retainage: {
    label: 'Retainage',
    key: 'retainage',
    value: settings.retainage,
    lastChangedBy: settings.retainageSource,
    lastChangedAt: settings.retainageDate,
  },
  previousCertifiedWorks: {
    label: 'Previous Certified Works',
    key: 'previous_certified_works',
    value: settings.previousCertifiedWorks,
    lastChangedBy: settings.previousCertifiedWorksSource,
    lastChangedAt: settings.previousCertifiedWorksDate,
  },
});

export const mapSettingsToSelectProperties = (
  settings: TDevelopmentLegalEntityReconcileSettings,
): {
  [P in TSelectKeys]: {
    label: string;
    key: CamelToSnakeCase<P>;
    value: TDevelopmentLegalEntityReconcileSettings[P];
  };
} => ({
  hardConstructionCostCodeId: {
    label: 'Hard Construction Cost Code',
    key: 'hard_construction_cost_code_id',
    value: settings.hardConstructionCostCodeId,
  },
  hardCostContingencyCodeId: {
    label: 'Hard Cost Contingency Code',
    key: 'hard_cost_contingency_code_id',
    value: settings.hardCostContingencyCodeId,
  },
  softCostContingencyCodeId: {
    label: 'Soft Cost Contingency Code',
    key: 'soft_cost_contingency_code_id',
    value: settings.softCostContingencyCodeId,
  },
});

export const mapSettingsToRequest = (
  settings: TDevelopmentLegalEntityReconcileSettings,
): TUpdateLegalEntityLegacySettingsRequest => ({
  initial_estimated_completion_date:
    settings.initialEstimatedCompletionDate &&
    formatToDateStringForRequest(settings.initialEstimatedCompletionDate),
  retainage: settings.retainage,
  contractor_retainage_value: settings.contractorRetainageValue,
  soft_cost_contingency_code_id: settings.softCostContingencyCodeId,
  hard_cost_contingency_code_id: settings.hardCostContingencyCodeId,
  hard_construction_cost_code_id: settings.hardConstructionCostCodeId,
  completed_and_stored: settings.completedAndStored,
  hard_construction_cost: settings.hardConstructionCost,
  hard_cost_contingency: settings.hardCostContingency,
  previous_certified_works: settings.previousCertifiedWorks,
  sitework_contingency: settings.siteworkContingency,
});

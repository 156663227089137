export interface IDevelopmentBudgetData {
  columns?: {
    label?: string;
    children?: {
      key?: string;
      label?: string;
    }[];
  }[];
  rows?: (IDevelopmentBudgetDataCategoryRow | IDevelopmentBudgetDataJccRow)[];
}

export interface IDevelopmentBudgetDataRow {
  key: string;
  type: 'category' | 'jcc' | 'sovc';
}

export interface IDevelopmentBudgetDataCategoryRow
  extends IDevelopmentBudgetDataRow {
  type: 'category';
  label?: string;
  children?: (
    | IDevelopmentBudgetDataCategoryRow
    | IDevelopmentBudgetDataJccRow
  )[];
}

export interface IDevelopmentBudgetDataJccRow
  extends IDevelopmentBudgetDataRow {
  type: 'jcc';
  jobCostCode?: {
    id?: number;
    name?: string;
  };
  children?: IDevelopmentBudgetDataSovcRow[];
  data?: TDevelopmentBudgetDataRowValues;
}

export interface IDevelopmentBudgetDataSovcRow
  extends IDevelopmentBudgetDataRow {
  type: 'sovc';
  scheduleOfValueCode?: {
    id?: number;
    name?: string;
  };
  data?: TDevelopmentBudgetDataRowValues;
}

export type TDevelopmentBudgetDataRowValues = Record<string, string | null>;

export function isBudgetCategoryRow(
  row: IDevelopmentBudgetDataRow,
): row is IDevelopmentBudgetDataCategoryRow {
  return row.type === 'category';
}
export function isBudgetSovcRow(
  row: IDevelopmentBudgetDataRow,
): row is IDevelopmentBudgetDataSovcRow {
  return row.type === 'sovc';
}
export function isBudgetJccRow(
  row: IDevelopmentBudgetDataRow,
): row is IDevelopmentBudgetDataJccRow {
  return row.type === 'jcc';
}

import React, { FC } from 'react';
import { CurrencyFormatter } from 'stories';

interface ITotalBadgeProps {
  totalAmount?: string;
}

const TotalBadge: FC<ITotalBadgeProps> = ({ totalAmount }) => (
  <div className="flex items-center gap-tw-1">
    <div className="label-regular">Total:</div>
    <CurrencyFormatter
      value={totalAmount}
      classes={{
        wrapper: 'label-semibold',
        value: 'text-white',
        startSymbol: 'text-white',
      }}
    />
  </div>
);

export default TotalBadge;

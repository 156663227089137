import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { AnimationLoader, Button } from 'stories';
import http from 'lib/http';
import pluralize from 'pluralize';
import { removeInvitationTemplate } from '../../../actions/invitationTemplates';
import EmailTemplateCard from '../EmailTemplateCard';
import SectionToCreateEmailCard from '../EmailCampaign/SectionToCreateEmailCard/SectionToCreateEmailCard';

const InvitationTemplatesTab = ({ children }) => {
  const [invitationTemplates, setInvitationTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchInvitationTemplates = () => http.get('/invitation_templates').then((res) => res.json());

  useEffect(() => {
    fetchInvitationTemplates()
      .then((invitationTemplatesJson) => {
        setInvitationTemplates(invitationTemplatesJson);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleInvitationTemplateDelete = (id) => {
    removeInvitationTemplate({ id })
      .then((templateJson) => {
        if (templateJson.errors === undefined) {
          setInvitationTemplates((currentTemplates) => currentTemplates.filter((t) => t.id !== id));
        }
      });
  };

  const handleCardToggle = async ({ id, isActive }) => {
    const payload = { template_attributes: { active: !isActive } };
    const res = await http.put(`/invitation_templates/${id}`, payload);
    const responseData = await res.json();
    return responseData;
  };

  const defaultTemplate = invitationTemplates.find((it) => it.default);
  const customTemplates = invitationTemplates.filter((it) => it.default === false);

  if (loading) return <AnimationLoader className="static min-h-[360px]" />;

  return (
    <div>
      <div className="flex items-center justify-between mt-m mb-l">
        <h5 className="header6-regular">
          {`${invitationTemplates.length} ${pluralize('Template', invitationTemplates.length)}`}
        </h5>
        <div className="flex justify-between items-center">
          <Link to="/settings/emails/templates/invitations/new">
            <Button size="m" variant="primary">
              New Template
            </Button>
          </Link>
        </div>
      </div>
      <div className="row">
        {defaultTemplate && (
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 pb-30">
            <EmailTemplateCard
              isDefault
              id={defaultTemplate.id}
              name={defaultTemplate.name}
              editPath={`./invitations/${defaultTemplate.id}/edit`}
            />
          </div>
        )}
        {customTemplates.map((template) => (
          <div key={template.id} className="col-xs-12 col-sm-6 col-md-6 col-lg-3 pb-30">
            <EmailTemplateCard
              isDefault={template.default}
              isActiveDefault={template.active}
              id={template.id}
              name={template.name}
              roles={template.selectedRoles}
              editPath={`./invitations/${template.id}/edit`}
              onDelete={handleInvitationTemplateDelete}
              onToggle={handleCardToggle}
            />
          </div>
        ))}
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 pb-30">
          <SectionToCreateEmailCard
            title="Custom Template"
            text="Based on the basic template you can create your own template for a unique set of roles"
            buttonText="New Custom Template"
            clickBtnHandler={() => navigate('/settings/emails/templates/invitations/new')}
          />
        </div>
      </div>
      {/* TODO: remove this since we don't pass children to component */}
      {children}
    </div>
  );
};

export default InvitationTemplatesTab;

export const getTotalData = (meta: {
  totalInvoices: string;
  totalBudget: string;
  totalVariance: string;
}) => [
  {
    title: 'Invoices:',
    value: meta.totalInvoices,
  },
  {
    title: 'Budget:',
    value: meta.totalBudget,
  },
  {
    title: 'Variance:',
    value: meta.totalVariance,
  },
];

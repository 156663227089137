import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ISharedFilePermissions } from 'bundles/Shared/sharedFilePermissions';
import { API_URL } from 'lib/http';
import { stringify } from 'qs';
import { Permitted } from 'bundles/Shared/entities/permissions';
import { ISharedFolder } from '@/types/SharedFolder';

interface SharedFilesResponse {
  sharedFiles: {
    id: string;
    confidential: boolean;
    smallImageUrl: string;
    createdAt: number;
    documentType: {
      id: number;
      internal: boolean;
      name: string;
    };
    documentable: {
      id: number;
      name: string;
      objectType: 'Asset' | 'Fund';
      slug: string;
    };
    extension: string;
    folder: {
      id: number;
      title: string;
    };
    lastViewedAt: null;
    permissionsLocked: boolean;
    permitted: Permitted;
    size: number | null;
    title: string;
    viewsCount: number;
    url?: string;
    description?: string;
  }[];
  meta: {
    totalSize: number;
    totalTrashSize: number;
  };
}

export const objectFoldersApi = createApi({
  reducerPath: 'objectFoldersApi',
  tagTypes: ['folders', 'documentTypes', 'sharedFiles'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
    paramsSerializer: (params) =>
      stringify(params, {
        arrayFormat: 'brackets',
      }),
  }),
  endpoints: (builder) => ({
    getObjectFolders: builder.query<
      ISharedFolder[],
      {
        entityId: number;
        entityType: 'Asset' | 'Fund';
        parentId?: number;
        folderType?: 'documents' | 'media';
      }
    >({
      query: ({ entityId, entityType, parentId, folderType }) => ({
        url: '/folders',
        params: {
          folderable_id: entityId,
          folderable_type: entityType,
          folder_type: folderType,
          parent_id: parentId,
        },
      }),
      providesTags: ['folders'],
    }),
    getCustomerConfig: builder.query<never, {}>({
      query: () => '/customer_config',
    }),
    getDocumentTypes: builder.query<
      {
        active: boolean;
        id: number;
        inUse: boolean;
        internal: boolean;
        media: boolean;
        name: string;
      }[],
      {
        with_documents: boolean;
        shared_file_documentable_id: number;
        shared_file_documentable_type: 'Asset' | 'Fund';
      }
    >({
      query: (params) => ({
        url: '/document_types',
        params,
      }),
      providesTags: ['documentTypes'],
    }),
    getSharedFiles: builder.query<
      SharedFilesResponse,
      {
        sort_order?: string;
        sort_field?: string;
        search_query?: string;
        page?: number;
        size_per_page?: number;
        entity_id: number;
        entity_type: 'Asset' | 'Fund';
        filters?: {
          shown_as_user_id: number;
          only_public: boolean;
          document_type_ids: number[];
        };
        folder_id?: number;
        document_types?: 'document' | 'media';
      }
    >({
      providesTags: ['sharedFiles'],
      query: (params) => ({
        url: '/shared_files',
        params,
      }),
    }),

    deleteBulkSharedFile: builder.mutation<any, { ids: number[] }>({
      invalidatesTags: ['sharedFiles', 'folders'],
      query: (data) => ({
        method: 'DELETE',
        url: '/shared_files/bulk',
        body: data,
      }),
    }),

    createBulkSharedFile: builder.mutation({
      invalidatesTags: ['sharedFiles', 'folders'],
      query: (data) => ({
        method: 'POST',
        url: '/shared_files/bulk',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Files have been successfully uploaded');
        return res;
      },
    }),

    updateSharedFile: builder.mutation({
      invalidatesTags: ['sharedFiles'],
      query: (data) => ({
        method: 'PUT',
        url: `/shared_files/${data.shared_file.id}`,
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('File has been successfully updated');
        return res;
      },
    }),

    updateBulkSharedFiles: builder.mutation<
      unknown,
      {
        shared_file_ids: number[];
      } & (
        | ISharedFilePermissions
        | {
          document_type_id: number;
        }
        | {
          folder_id: number;
          shared_files: SharedFilesResponse['sharedFiles'];
        }
      )
    >({
      invalidatesTags: ['sharedFiles', 'folders'],
      query: (data) => ({
        method: 'PUT',
        url: '/shared_files/bulk',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Files have been successfully updated');
        return res;
      },
    }),

    toggleLockSharedFile: builder.mutation<
      unknown,
      {
        id: number;
      }
    >({
      invalidatesTags: ['sharedFiles'],
      query: ({ id }) => ({
        method: 'PUT',
        url: `/shared_files/${id}/toggle_lock_permissions`,
      }),
      transformResponse: (res) => {
        toastr.success('File has been successfully updated');
        return res;
      },
    }),

    createFolder: builder.mutation({
      invalidatesTags: ['folders', 'sharedFiles'],
      query: (data) => ({
        url: '/folders',
        method: 'POST',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Folder has been successfully created');
        return res;
      },
    }),

    updateBulkFolders: builder.mutation({
      invalidatesTags: ['folders', 'sharedFiles'],
      query: (data) => ({
        url: '/folders/bulk',
        method: 'PUT',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Folders have been successfully moved');
        return res;
      },
    }),

    deleteBulkFolders: builder.mutation<
      unknown,
      {
        ids: number[];
      }
    >({
      invalidatesTags: ['folders', 'sharedFiles'],
      query: (data) => ({
        url: '/folders/bulk',
        method: 'DELETE',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Folders have been successfully moved');
        return res;
      },
    }),

    updateFolder: builder.mutation({
      invalidatesTags: ['folders', 'sharedFiles'],
      query: (data) => ({
        url: `/folders/${data.folder.id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Folder has been successfully updated');
        return res;
      },
    }),
  }),
});

export const {
  useGetObjectFoldersQuery,
  useGetDocumentTypesQuery,
  useGetSharedFilesQuery,
  useGetCustomerConfigQuery,

  useUpdateSharedFileMutation,
  useCreateBulkSharedFileMutation,
  useDeleteBulkSharedFileMutation,
  useToggleLockSharedFileMutation,
  useUpdateBulkSharedFilesMutation,

  useCreateFolderMutation,
  useUpdateFolderMutation,
  useUpdateBulkFoldersMutation,
  useDeleteBulkFoldersMutation,
} = objectFoldersApi;

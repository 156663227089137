import React, { ComponentProps, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { RadioButton } from 'stories';
import styles from './SelectionSectionItem.module.scss';

interface Props extends Omit<ComponentProps<typeof RadioButton>, 'children'> {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

export const SelectionSectionItem = ({
  title,
  subtitle,
  classes,
  children,
  ...props
}: Props) => (
  <RadioButton
    classes={{
      label: cn(styles.wrapper, classes?.label),
    }}
    {...props}
  >
    {children}
    {!children && (
      <div>
        <h5 className="dark-60 inline-semibold mb-xs">{title}</h5>
        <p className="light-60 secondary-regular">{subtitle}</p>
      </div>
    )}
  </RadioButton>
);

export default SelectionSectionItem;

import {
  COLORS,
  FIELD_KINDS,
  FIELDS,
  INDICATOR_STYLES,
} from 'bundles/Construction/components/eSignature/consts';
import { TPlacementKinds } from 'bundles/Construction/components/eSignature/types';
import { sortBy } from 'lodash-es';

export const getIndicatorColor = (approverId, approvers) => {
  const approver = approvers.find((a) => a.id === approverId);
  const color = COLORS[(approver.priority - 1) % COLORS.length];
  return INDICATOR_STYLES[color];
};

export const preparedFields = (fields) =>
  sortBy(
    FIELD_KINDS.map((f: TPlacementKinds) => ({
      kind: f,
      name: FIELDS[f],
      optional: !fields.includes(f),
    })),
    'optional',
  );

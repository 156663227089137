import React, { useState } from 'react';
import {
  CrudEntityModalProps,
  CrudModal,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import ContactFields from 'bundles/REconcile/components/development/home/settingsModal/contacts/ContactFields';
import {
  CONTACT_ENTITY_NAME,
  IReconcileSettingsContactUpdate,
} from 'bundles/REconcile/actions/contacts';

function ContactCrudModal({
  onSubmit,
  entity,
  ...props
}: CrudEntityModalProps<IReconcileSettingsContactUpdate>) {
  const [formData, setFormData] = useState(entity);
  return (
    <CrudModal
      entityName={CONTACT_ENTITY_NAME}
      onSubmit={() => onSubmit?.(formData)}
      {...props}
    >
      <ContactFields formData={formData} setFormData={setFormData} />
    </CrudModal>
  );
}

export default ContactCrudModal;

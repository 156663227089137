import { buildExecutiveSummaryReport } from '@/shared/lib/deprecated/constructionReport';
import dayjs from 'dayjs';
import { useState } from 'react';
import { AsOfDate, IconButton } from 'stories';
import ProgressBar from './ProgressBar';

const ExecSummaryLine = ({ mainCost, percent, items }) => (
  <div className="mb-m grid grid-cols-5  gap-tw-4 rounded-[8px] bg-light p-m">
    <div className="p-m">
      <h3>{mainCost.amount}</h3>
      <div className="light-60 font-14">{mainCost.label}</div>
    </div>
    <div className="rounded-[8px] bg-white p-m">
      <div className="mb-[0.5rem]">
        <span className="h5">{percent.amount}</span>
        <span className="light-60 font-14 ml-s">{percent.label}</span>
      </div>
      <ProgressBar value={percent.amount} />
    </div>
    {items.map((item, i) => (
      <div className="rounded-[8px] bg-white p-m" key={i}>
        <h5>{item.amount}</h5>
        <div className="font-14 light-60">{item.label}</div>
      </div>
    ))}
  </div>
);

const ExecSummarySection = ({ title, asOfDate, summary, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className="card bg-white p-m">
      <div className="mb-m flex items-center justify-between">
        <h5>{title}</h5>
        <AsOfDate
          date={dayjs.unix(asOfDate)}
          badgeBackgroundColor="var(--white)"
          iconIsBadge
          block
        />
      </div>
      {summary}
      <div className="flex">
        <IconButton
          iconName={collapsed ? 'arrowTop' : 'arrowBottom'}
          variant="white"
          onClick={() => setCollapsed(!collapsed)}
        />
        <span className="ml-s">Details</span>
      </div>
      {collapsed && children}
    </div>
  );
};

const ExecSummaryTable = ({ rows, title }) => (
  <>
    <p className="font-weight-600 mb-[0.5rem]">{title}</p>
    <div className="flex flex-col gap overflow-hidden rounded-[8px]">
      {rows.map((row, i) => (
        <div
          className="flex justify-between bg-light px-[1rem] py-[0.5rem]"
          key={i}
        >
          <div className="">{row.label}</div>
          <div className="">{row.amount}</div>
        </div>
      ))}
    </div>
  </>
);

const ExecutiveSummarySnapshot = ({ report }) => {
  const {
    generalSummary,
    constructionBudgetSummary,
    changeEventsSummary,
    contingencySummary,
    constructionBalance,
    contingencyBalance,
  } = buildExecutiveSummaryReport(report);

  const { summaryWithProjected } = report;
  const changeEventDetailsRows = () => {
    const changeEventDetailsBaseRows = [
      changeEventsSummary.approvedCo,
      changeEventsSummary.approvedCor,
    ];

    if (summaryWithProjected) {
      changeEventDetailsBaseRows.push(
        changeEventsSummary.pendingCor,
        changeEventsSummary.pendingPco,
        changeEventsSummary.disputed,
      );
    }
    return changeEventDetailsBaseRows;
  };

  const hardCostContingencyRows = () => {
    const hardCostContingencyBaseRows = [
      contingencySummary.hardCostContingency,
      contingencySummary.remainingContingency,
      contingencySummary.remainingMinusApproved,
    ];

    if (summaryWithProjected) {
      hardCostContingencyBaseRows.push(
        contingencySummary.approvedAndPending,
        contingencySummary.approvedAndPendingAndDisputed,
      );
    }
    return hardCostContingencyBaseRows;
  };

  return (
    <div>
      <ExecSummarySection
        title="Construction"
        asOfDate={report.budgetUpdatedAt}
        summary={
          <ExecSummaryLine
            mainCost={constructionBalance.remainingGmp}
            percent={constructionBalance.constructionCompletePercent}
            items={[
              generalSummary.originalGmp,
              generalSummary.adjustedDirectCost,
              constructionBudgetSummary.currentConstructionRequest,
            ]}
          />
        }
      >
        <div className="dark-60 mt-l grid grid-cols-2 gap-m">
          <div className="grid">
            <ExecSummaryTable
              title="Cost Details:"
              rows={[
                generalSummary.originalGmp,
                generalSummary.netChangeByApproved,
                generalSummary.siteworkContingency,
                generalSummary.balanceOfHardCostContingency,
                generalSummary.adjustedDirectCost,
              ]}
            />
          </div>
          <div className="grid">
            <ExecSummaryTable
              title="Current Data:"
              rows={[
                constructionBudgetSummary.completedAndStored,
                constructionBudgetSummary.retainage,
                constructionBudgetSummary.totalEarned,
                constructionBudgetSummary.previousCertifiedWorks,
                constructionBudgetSummary.currentConstructionRequest,
              ]}
            />
          </div>
        </div>
      </ExecSummarySection>
      <ExecSummarySection
        title="Change Events"
        asOfDate={report.updatedAt}
        summary={
          <ExecSummaryLine
            mainCost={contingencyBalance.remainingContingency}
            percent={contingencyBalance.hcContingencyUsedPercent}
            items={[
              changeEventsSummary.total,
              contingencySummary.hardCostContingency,
            ]}
          />
        }
      >
        <div className="dark-60 mt-l grid grid-cols-2 gap-m">
          <div className="grid">
            <ExecSummaryTable
              title="Change Event Details:"
              rows={changeEventDetailsRows()}
            />
          </div>
          <div className="grid">
            <ExecSummaryTable
              title="Hard Cost Contingency Budget:"
              rows={hardCostContingencyRows()}
            />
          </div>
        </div>
        <p style={{ fontSize: 12 }} className="light-60 mt-m">
          Hard Cost Contingency values shown above include reallocations from
          other budget line items
        </p>
      </ExecSummarySection>
    </div>
  );
};

export default ExecutiveSummarySnapshot;

import styles from 'bundles/Shared/components/Filters/common/dropdown/ColorOptionsDropdown.module.scss';
import React from 'react';
import { IColorOption } from 'bundles/Shared/components/Filters/common/dropdown/ColorOptionsDropdown';
import { cn } from '@/shared/lib/css/cn';

const STATUS_ICON_WIDTH = 8;

export const StatusIcon = ({
  backgroundColor,
}: {
  backgroundColor: string;
}) => <div className={styles.icon} style={{ backgroundColor }} />;

export const StatusIconsContainer = ({
  currentKey,
  options,
}: {
  currentKey: string | 'all';
  options: IColorOption[];
}) => {
  const currentOption = options.find((o) => o.key === currentKey)!;

  return (
    <div
      className={cn(styles.iconContainer, {
        [styles.iconContainer_all]: currentKey === 'all',
      })}
      style={{
        width:
          currentKey === 'all'
            ? STATUS_ICON_WIDTH * (options.length - 1)
            : undefined,
      }}
    >
      {currentKey === 'all' &&
        options
          .filter((o) => o.key !== 'all')
          .map((option) => (
            <StatusIcon key={option.label} backgroundColor={option.color} />
          ))}
      {currentKey !== 'all' && (
        <StatusIcon backgroundColor={currentOption.color} />
      )}
    </div>
  );
};

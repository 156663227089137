import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntries:
      build.mutation<
        PostApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesApiResponse,
        PostApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/capital/investment/accrued_preferred_returns/${queryArg.accruedPreferredReturnId}/accrued_entries`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndId:
      build.mutation<
        PutApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiResponse,
        PutApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/capital/investment/accrued_preferred_returns/${queryArg.accruedPreferredReturnId}/accrued_entries/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndId:
      build.mutation<
        DeleteApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiResponse,
        DeleteApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/capital/investment/accrued_preferred_returns/${queryArg.accruedPreferredReturnId}/accrued_entries/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    postApiCapitalInvestmentAccruedPreferredReturnsBulk: build.mutation<
      PostApiCapitalInvestmentAccruedPreferredReturnsBulkApiResponse,
      PostApiCapitalInvestmentAccruedPreferredReturnsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/accrued_preferred_returns/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCapitalInvestmentAccruedPreferredReturns: build.query<
      GetApiCapitalInvestmentAccruedPreferredReturnsApiResponse,
      GetApiCapitalInvestmentAccruedPreferredReturnsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/accrued_preferred_returns`,
        params: { investment_id: queryArg.investmentId },
      }),
    }),
    putApiCapitalInvestmentAccruedPreferredReturnsById: build.mutation<
      PutApiCapitalInvestmentAccruedPreferredReturnsByIdApiResponse,
      PutApiCapitalInvestmentAccruedPreferredReturnsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/accrued_preferred_returns/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiCapitalInvestmentAccruedPreferredReturnsById: build.mutation<
      DeleteApiCapitalInvestmentAccruedPreferredReturnsByIdApiResponse,
      DeleteApiCapitalInvestmentAccruedPreferredReturnsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/accrued_preferred_returns/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiCapitalInvestmentCommitments: build.query<
      GetApiCapitalInvestmentCommitmentsApiResponse,
      GetApiCapitalInvestmentCommitmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/commitments`,
        params: {
          investment_id: queryArg.investmentId,
          investment_ids: queryArg.investmentIds,
        },
      }),
    }),
    putApiCapitalInvestmentCommitmentsById: build.mutation<
      PutApiCapitalInvestmentCommitmentsByIdApiResponse,
      PutApiCapitalInvestmentCommitmentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/commitments/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiCapitalInvestmentCommitmentsById: build.mutation<
      DeleteApiCapitalInvestmentCommitmentsByIdApiResponse,
      DeleteApiCapitalInvestmentCommitmentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/commitments/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiCapitalInvestmentCommitmentsBulk: build.mutation<
      PostApiCapitalInvestmentCommitmentsBulkApiResponse,
      PostApiCapitalInvestmentCommitmentsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/commitments/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiCapitalInvestmentContributionTransactionMappingsBulk: build.mutation<
      PostApiCapitalInvestmentContributionTransactionMappingsBulkApiResponse,
      PostApiCapitalInvestmentContributionTransactionMappingsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/contribution_transaction_mappings/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCapitalInvestmentContributions: build.query<
      GetApiCapitalInvestmentContributionsApiResponse,
      GetApiCapitalInvestmentContributionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/contributions`,
        params: {
          investment_id: queryArg.investmentId,
          investment_object_id: queryArg.investmentObjectId,
          not_mapped: queryArg.notMapped,
          max_amount: queryArg.maxAmount,
          from_date: queryArg.fromDate,
          to_date: queryArg.toDate,
        },
      }),
    }),
    putApiCapitalInvestmentContributionsById: build.mutation<
      PutApiCapitalInvestmentContributionsByIdApiResponse,
      PutApiCapitalInvestmentContributionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/contributions/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiCapitalInvestmentContributionsById: build.mutation<
      DeleteApiCapitalInvestmentContributionsByIdApiResponse,
      DeleteApiCapitalInvestmentContributionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/contributions/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiCapitalInvestmentContributionsTransfer: build.mutation<
      PostApiCapitalInvestmentContributionsTransferApiResponse,
      PostApiCapitalInvestmentContributionsTransferApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/contributions/transfer`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiCapitalInvestmentContributionsBulk: build.mutation<
      PostApiCapitalInvestmentContributionsBulkApiResponse,
      PostApiCapitalInvestmentContributionsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/contributions/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiCapitalInvestmentDistributionTransactionMappingsBulk: build.mutation<
      PostApiCapitalInvestmentDistributionTransactionMappingsBulkApiResponse,
      PostApiCapitalInvestmentDistributionTransactionMappingsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/distribution_transaction_mappings/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCapitalInvestmentDistributions: build.query<
      GetApiCapitalInvestmentDistributionsApiResponse,
      GetApiCapitalInvestmentDistributionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/distributions`,
        params: {
          investment_id: queryArg.investmentId,
          investment_object_id: queryArg.investmentObjectId,
          not_mapped: queryArg.notMapped,
          max_amount: queryArg.maxAmount,
          from_date: queryArg.fromDate,
          to_date: queryArg.toDate,
        },
      }),
    }),
    putApiCapitalInvestmentDistributionsById: build.mutation<
      PutApiCapitalInvestmentDistributionsByIdApiResponse,
      PutApiCapitalInvestmentDistributionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/distributions/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiCapitalInvestmentDistributionsById: build.mutation<
      DeleteApiCapitalInvestmentDistributionsByIdApiResponse,
      DeleteApiCapitalInvestmentDistributionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/distributions/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiCapitalInvestmentDistributionsBulk: build.mutation<
      PostApiCapitalInvestmentDistributionsBulkApiResponse,
      PostApiCapitalInvestmentDistributionsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment/distributions/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCapitalInvestmentObjectSummaries: build.query<
      GetApiCapitalInvestmentObjectSummariesApiResponse,
      GetApiCapitalInvestmentObjectSummariesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment_object_summaries`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          query: queryArg.query,
          object_type: queryArg.objectType,
          sort: queryArg.sort,
          published: queryArg.published,
        },
      }),
    }),
    getApiCapitalInvestmentObjectsById: build.query<
      GetApiCapitalInvestmentObjectsByIdApiResponse,
      GetApiCapitalInvestmentObjectsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment_objects/${queryArg.id}`,
      }),
    }),
    putApiCapitalInvestmentObjectsById: build.mutation<
      PutApiCapitalInvestmentObjectsByIdApiResponse,
      PutApiCapitalInvestmentObjectsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment_objects/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiCapitalInvestmentObjectsByIdPreferredReturn: build.query<
      GetApiCapitalInvestmentObjectsByIdPreferredReturnApiResponse,
      GetApiCapitalInvestmentObjectsByIdPreferredReturnApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment_objects/${queryArg.id}/preferred_return`,
      }),
    }),
    getApiCapitalInvestmentObjectsByIdDetails: build.query<
      GetApiCapitalInvestmentObjectsByIdDetailsApiResponse,
      GetApiCapitalInvestmentObjectsByIdDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investment_objects/${queryArg.id}/details`,
      }),
    }),
    getApiCapitalInvestments: build.query<
      GetApiCapitalInvestmentsApiResponse,
      GetApiCapitalInvestmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investments`,
        params: {
          investment_object_id: queryArg.investmentObjectId,
          sort: queryArg.sort,
          legal_entity_ids: queryArg.legalEntityIds,
        },
      }),
    }),
    postApiCapitalInvestments: build.mutation<
      PostApiCapitalInvestmentsApiResponse,
      PostApiCapitalInvestmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investments`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteApiCapitalInvestmentsById: build.mutation<
      DeleteApiCapitalInvestmentsByIdApiResponse,
      DeleteApiCapitalInvestmentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/investments/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiCapitalPreferredReturns: build.mutation<
      PostApiCapitalPreferredReturnsApiResponse,
      PostApiCapitalPreferredReturnsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/preferred_returns`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiCapitalPreferredReturnsById: build.mutation<
      PutApiCapitalPreferredReturnsByIdApiResponse,
      PutApiCapitalPreferredReturnsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/preferred_returns/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiCapitalTransactionSources: build.query<
      GetApiCapitalTransactionSourcesApiResponse,
      GetApiCapitalTransactionSourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/transaction_sources`,
        params: { investment_object_id: queryArg.investmentObjectId },
      }),
    }),
    getApiCapitalUnitPrices: build.query<
      GetApiCapitalUnitPricesApiResponse,
      GetApiCapitalUnitPricesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/unit_prices`,
        params: { investment_object_id: queryArg.investmentObjectId },
      }),
    }),
    postApiCapitalUnitPrices: build.mutation<
      PostApiCapitalUnitPricesApiResponse,
      PostApiCapitalUnitPricesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/unit_prices`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiCapitalUnitPricesById: build.mutation<
      PutApiCapitalUnitPricesByIdApiResponse,
      PutApiCapitalUnitPricesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/unit_prices/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiCapitalUnitPricesById: build.mutation<
      DeleteApiCapitalUnitPricesByIdApiResponse,
      DeleteApiCapitalUnitPricesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/capital/unit_prices/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as capitalInvestmentObjectsApi };
export type PostApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesApiResponse =
  /** status 200 success */ AccruedEntryDto;
export type PostApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesApiArg =
  {
    accruedPreferredReturnId: string;
    body: {
      accrued_entry: {
        date: string;
        amount_cents: number;
        created_by_id: number;
      };
    };
  };
export type PutApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiResponse =
  /** status 200 success */ AccruedEntryDto;
export type PutApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiArg =
  {
    accruedPreferredReturnId: string;
    id: string;
    body: {
      accrued_entry: {
        date?: string;
        amount_cents?: number;
        created_by_id?: number;
      };
    };
  };
export type DeleteApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiResponse =
  unknown;
export type DeleteApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdApiArg =
  {
    accruedPreferredReturnId: string;
    id: string;
  };
export type PostApiCapitalInvestmentAccruedPreferredReturnsBulkApiResponse =
  unknown;
export type PostApiCapitalInvestmentAccruedPreferredReturnsBulkApiArg = {
  body: {
    accrued_perferred_return: {
      start_date: string;
      end_date?: string;
      period_type: string;
      amount_cents: number;
      capital_investment_id: number;
      accrued_entries_attributes?: {
        date?: string;
        amount_cents?: number;
      }[];
    }[];
  };
};
export type GetApiCapitalInvestmentAccruedPreferredReturnsApiResponse =
  /** status 200 success */ AccruedPreferredReturnDto[];
export type GetApiCapitalInvestmentAccruedPreferredReturnsApiArg = {
  investmentId: number;
};
export type PutApiCapitalInvestmentAccruedPreferredReturnsByIdApiResponse =
  unknown;
export type PutApiCapitalInvestmentAccruedPreferredReturnsByIdApiArg = {
  id: string;
  body: {
    start_date?: string;
    end_date?: string;
    amount_cents?: number;
    status?: string;
  };
};
export type DeleteApiCapitalInvestmentAccruedPreferredReturnsByIdApiResponse =
  unknown;
export type DeleteApiCapitalInvestmentAccruedPreferredReturnsByIdApiArg = {
  id: string;
};
export type GetApiCapitalInvestmentCommitmentsApiResponse =
  /** status 200 success */ CommitmentDto[];
export type GetApiCapitalInvestmentCommitmentsApiArg = {
  investmentId?: number;
  investmentIds?: {
    ''?: number[];
  };
};
export type PutApiCapitalInvestmentCommitmentsByIdApiResponse = unknown;
export type PutApiCapitalInvestmentCommitmentsByIdApiArg = {
  id: number;
  body: {
    amount_cents?: number;
    signed_date?: string;
  };
};
export type DeleteApiCapitalInvestmentCommitmentsByIdApiResponse = unknown;
export type DeleteApiCapitalInvestmentCommitmentsByIdApiArg = {
  id: number;
};
export type PostApiCapitalInvestmentCommitmentsBulkApiResponse = unknown;
export type PostApiCapitalInvestmentCommitmentsBulkApiArg = {
  body: {
    commitment: {
      investment_id: number;
      amount_cents?: number;
      signed_date?: string;
    }[];
  };
};
export type PostApiCapitalInvestmentContributionTransactionMappingsBulkApiResponse =
  unknown;
export type PostApiCapitalInvestmentContributionTransactionMappingsBulkApiArg =
  {
    body: {
      accounting_transaction_id: number;
      contribution_transaction_ids: number[];
    };
  };
export type GetApiCapitalInvestmentContributionsApiResponse =
  /** status 200 success */ {
    items: ContributionDto[];
    meta: {
      notMappedCount: number;
    };
  };
export type GetApiCapitalInvestmentContributionsApiArg = {
  investmentId: number;
  investmentObjectId?: string;
  notMapped?: boolean;
  maxAmount?: number;
  fromDate?: string;
  toDate?: string;
};
export type PutApiCapitalInvestmentContributionsByIdApiResponse = unknown;
export type PutApiCapitalInvestmentContributionsByIdApiArg = {
  id: number;
  body: {
    amount_cents?: number;
    date?: string;
    commitment_id?: string | null;
    investment_class?: 'a' | 'b';
    units_quantity?: number | null;
  };
};
export type DeleteApiCapitalInvestmentContributionsByIdApiResponse = unknown;
export type DeleteApiCapitalInvestmentContributionsByIdApiArg = {
  id: number;
};
export type PostApiCapitalInvestmentContributionsTransferApiResponse = unknown;
export type PostApiCapitalInvestmentContributionsTransferApiArg = {
  body: {
    source_investment_id?: number;
    investment_class?: 'a' | 'b';
    target_investments_with_amount_cents?: {
      investment_id?: number;
      amount_cents?: number;
      date?: string;
    }[];
    date?: string;
  };
};
export type PostApiCapitalInvestmentContributionsBulkApiResponse =
  /** status 200 success */ {
    items: ContributionDto[];
  };
export type PostApiCapitalInvestmentContributionsBulkApiArg = {
  body: {
    accounting_transaction_id?: string;
    investment_id: number;
    amount_cents: number;
    date?: string;
    commitment_id?: string | null;
    investment_class: 'a' | 'b';
    units_quantity?: number | null;
  }[];
};
export type PostApiCapitalInvestmentDistributionTransactionMappingsBulkApiResponse =
  unknown;
export type PostApiCapitalInvestmentDistributionTransactionMappingsBulkApiArg =
  {
    body: {
      accounting_transaction_id: number;
      distribution_transaction_ids: number[];
    };
  };
export type GetApiCapitalInvestmentDistributionsApiResponse =
  /** status 200 success */ {
    items: DistributionDto[];
    meta: {
      notMappedCount: number;
    };
  };
export type GetApiCapitalInvestmentDistributionsApiArg = {
  investmentId?: number;
  investmentObjectId?: string;
  notMapped?: boolean;
  maxAmount?: number;
  fromDate?: string;
  toDate?: string;
};
export type PutApiCapitalInvestmentDistributionsByIdApiResponse = unknown;
export type PutApiCapitalInvestmentDistributionsByIdApiArg = {
  id: number;
  body: {
    amount?: number;
    kind?:
      | 'preferred'
      | 'excess_cash'
      | 'refinance'
      | 'sale'
      | 'liquidation'
      | 'split_items'
      | 'return_of_capital';
    period_type?: 'monthly' | 'quarterly';
    period?: string;
    date?: string;
    split_items_attributes?: {
      id?: string;
      amount_cents?: number;
      kind?:
        | 'preferred'
        | 'excess_cash'
        | 'refinance'
        | 'sale'
        | 'liquidation'
        | 'split_items'
        | 'return_of_capital';
      _destroy?: true;
    }[];
  };
};
export type DeleteApiCapitalInvestmentDistributionsByIdApiResponse = unknown;
export type DeleteApiCapitalInvestmentDistributionsByIdApiArg = {
  id: number;
};
export type PostApiCapitalInvestmentDistributionsBulkApiResponse =
  /** status 200 success */ {
    items: DistributionDto[];
  };
export type PostApiCapitalInvestmentDistributionsBulkApiArg = {
  body: {
    accounting_transaction_id?: string;
    date?: string;
    investment_id: number;
    amount: number;
    kind:
      | 'preferred'
      | 'excess_cash'
      | 'refinance'
      | 'sale'
      | 'liquidation'
      | 'split_items'
      | 'return_of_capital';
    period_type: 'monthly' | 'quarterly';
    period: string;
    split_items?: {
      amount_cents: number;
      kind:
        | 'preferred'
        | 'excess_cash'
        | 'refinance'
        | 'sale'
        | 'liquidation'
        | 'split_items'
        | 'return_of_capital';
    }[];
  }[];
};
export type GetApiCapitalInvestmentObjectSummariesApiResponse =
  /** status 200 success */ {
    investmentObjects?: InvestmentObjectDto[];
    meta?: PaginationDto;
  };
export type GetApiCapitalInvestmentObjectSummariesApiArg = {
  page?: number;
  perPage?: number;
  query?: string;
  objectType?: 'Asset' | 'Fund';
  sort?: {
    field?:
      | 'objectName'
      | 'totalCommitments'
      | 'totalContributions'
      | 'totalDistributions'
      | 'yield';
    order?: 'asc' | 'desc';
  };
  published?: boolean;
};
export type GetApiCapitalInvestmentObjectsByIdApiResponse =
  /** status 200 success */ InvestmentObjectShowDto;
export type GetApiCapitalInvestmentObjectsByIdApiArg = {
  id: string;
};
export type PutApiCapitalInvestmentObjectsByIdApiResponse = unknown;
export type PutApiCapitalInvestmentObjectsByIdApiArg = {
  id: string;
  body: {
    from_source_enabled?: boolean;
  };
};
export type GetApiCapitalInvestmentObjectsByIdPreferredReturnApiResponse =
  /** status 200 success */ InvestmentObjectPreferredReturnDto;
export type GetApiCapitalInvestmentObjectsByIdPreferredReturnApiArg = {
  id: string;
};
export type GetApiCapitalInvestmentObjectsByIdDetailsApiResponse =
  /** status 200 success */ InvestmentObjectDetailsDto;
export type GetApiCapitalInvestmentObjectsByIdDetailsApiArg = {
  id: string;
};
export type GetApiCapitalInvestmentsApiResponse =
  /** status 200 success */ InvestmentIndexDto[];
export type GetApiCapitalInvestmentsApiArg = {
  investmentObjectId: string;
  /** sort: {
                      field: 'investment_entity' | 'legal_entity_name' | 'total_contributions' | 'total_distributions' |
                      'total_accrued_ending_balance', order: 'asc' | 'desc'
                    } */
  sort?: {
    field?:
      | 'investment_entity'
      | 'legal_entity_name'
      | 'total_contributions'
      | 'total_distributions'
      | 'total_accrued_ending_balance_cents';
    order?: 'asc' | 'desc';
  };
  legalEntityIds?: {
    ''?: string[];
  };
};
export type PostApiCapitalInvestmentsApiResponse = unknown;
export type PostApiCapitalInvestmentsApiArg = {
  body: {
    investment_entity_ids: number[];
    legal_entity_id: string;
  };
};
export type DeleteApiCapitalInvestmentsByIdApiResponse = unknown;
export type DeleteApiCapitalInvestmentsByIdApiArg = {
  id: number;
};
export type PostApiCapitalPreferredReturnsApiResponse =
  /** status 200 success */ InvestmentObjectPreferredReturnDto;
export type PostApiCapitalPreferredReturnsApiArg = {
  body: {
    class_a_percent: number;
    class_b_percent: number;
    investment_object_id: string;
  };
};
export type PutApiCapitalPreferredReturnsByIdApiResponse =
  /** status 200 success */ InvestmentObjectPreferredReturnDto;
export type PutApiCapitalPreferredReturnsByIdApiArg = {
  id: string;
  body: {
    class_a_percent: number;
    class_b_percent: number;
  };
};
export type GetApiCapitalTransactionSourcesApiResponse =
  /** status 200 success */ TransactionSourceDto[];
export type GetApiCapitalTransactionSourcesApiArg = {
  investmentObjectId: string;
};
export type GetApiCapitalUnitPricesApiResponse =
  /** status 200 success */ InvestmentObjectUnitPriceDto[];
export type GetApiCapitalUnitPricesApiArg = {
  investmentObjectId: string;
};
export type PostApiCapitalUnitPricesApiResponse =
  /** status 200 success */ InvestmentObjectUnitPriceDto;
export type PostApiCapitalUnitPricesApiArg = {
  body: {
    date: string;
    price_cents: number;
    investment_object_id: string;
  };
};
export type PutApiCapitalUnitPricesByIdApiResponse =
  /** status 200 success */ InvestmentObjectUnitPriceDto;
export type PutApiCapitalUnitPricesByIdApiArg = {
  id: string;
  body: {
    date: string;
    price_cents: number;
    investment_class: 'a' | 'b';
  };
};
export type DeleteApiCapitalUnitPricesByIdApiResponse = unknown;
export type DeleteApiCapitalUnitPricesByIdApiArg = {
  id: string;
};
export type AccruedEntryDto = {
  id: string;
  date: string;
  amountCents: number;
  createdBy: {
    id: string;
    fullName: string;
  } | null;
  accruedPreferredReturnId: string;
  createdAt?: number;
};
export type AccruedPreferredReturnDto = {
  id: string;
  startDate: string;
  endDate: string | null;
  amountCents: number;
  periodType: 'monthly' | 'quarterly';
  status: 'active' | 'stopped';
  capitalInvestmentId: number;
  accruedEntries?: AccruedEntryDto[];
};
export type CommitmentDto = {
  id: string;
  investmentId?: number;
  createdAt?: number;
  updatedAt?: number;
  units?: number | null;
  amountCents: number;
  signedDate: string | null;
  createdBy: {
    id: string;
    email: string;
    fullName: string;
  };
  updatedBy?: {
    id: string;
    email: string;
    fullName: string;
  } | null;
  removable: boolean;
};
export type ContributionDto = {
  id: number;
  isTransfer: boolean;
  fromTransferName: string | null;
  toTransferNames: string[];
  units: number | null;
  unitsQuantity: number | null;
  capitalInvestmentId?: number;
  amountCents: number;
  commitment: {
    id: string;
    signedDate: string | null;
  } | null;
  date: string;
  transferDate: string | null;
  createdBy: {
    fullName: string;
  };
  createdAt: number;
  updatedAt?: number;
  investmentEntity: {
    id: number;
    name: string;
    users: string[];
  };
  accountingTransaction: {
    id: number;
    date: string;
    deletedAt?: string | null;
    type: string;
    amountCents?: number;
    generalLedger: {
      id: number;
      accTitle: string;
      accNumber: string;
      legalEntity: {
        source: string;
      };
    };
    mappedTransactions: {
      id: number;
      amountCents: number;
      createdBy: {
        fullName: string;
      };
      createdAt: string;
      investmentEntity: {
        id: number;
        name: string;
      };
    }[];
  } | null;
};
export type SplitItemDto = {
  id: string;
  amountCents: number;
  kind: string;
}[];
export type DistributionDto = {
  id: number;
  capitalInvestmentId: number;
  amount: number;
  date: string;
  createdBy: {
    fullName: string;
  };
  createdAt: number;
  updatedAt: number;
  kind:
    | 'preferred'
    | 'excess_cash'
    | 'refinance'
    | 'sale'
    | 'liquidation'
    | 'split_items'
    | 'return_of_capital';
  investmentEntity: {
    id: number;
    name: string;
    users: string[];
  };
  splitItems: SplitItemDto;
  accountingTransaction: {
    id: string;
    date: string;
    deletedAt?: string | null;
    type: string;
    amount?: number;
    generalLedger: {
      id: number;
      accTitle: string;
      accNumber: string;
      legalEntity: {
        source: string;
      };
    };
    mappedTransactions: {
      id: number;
      amount?: number;
      amountCents: number;
      createdBy: {
        fullName: string;
      };
      createdAt: number;
      investmentEntity: {
        id: number;
        name: string;
        kind?:
          | 'preferred'
          | 'excess_cash'
          | 'refinance'
          | 'sale'
          | 'liquidation'
          | 'split_items'
          | 'return_of_capital';
      } | null;
      splitItems: SplitItemDto;
      period?: string | null;
      periodType?: ('monthly' | 'quarterly') | null;
    }[];
  } | null;
};
export type InvestmentObjectDto = {
  id?: string;
  totalInvestments?: number;
  legalEntities?: {
    id?: string;
    isPublished?: boolean;
    name?: string;
  }[];
  object?: {
    id?: number;
    name?: string;
    objectType?: 'asset' | 'fund';
    pictureUrl?: string | null;
    slug?: string;
    smallPictureUrl?: string | null;
  };
  totalCommitments?: number;
  totalContributionsCents?: number;
  totalDistributions?: number;
  yield?: number;
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};
export type InvestmentObjectShowDto = {
  id: string;
  fromSourceEnabled: boolean;
  canDisableFromSource?: boolean;
  legalEntities: {
    id: string;
    isPublished: boolean;
    name: string;
  }[];
  object: {
    id: number;
    name: string;
    objectType: 'asset' | 'fund';
    slug: string;
    smallPictureUrl: string | null;
  };
};
export type InvestmentObjectPreferredReturnDto = {
  id: string;
  classAPercent: number;
  classBPercent: number;
} | null;
export type InvestmentObjectDetailsDto = {
  id: string;
  commitments: {
    id: string;
    amountCents: number;
    investmentEntity: {
      id?: number;
      name?: string;
    };
    legalEntity: {
      id?: string;
      name?: string;
    };
  }[];
  contributions: {
    id: number;
    amountCents: number;
    date: string;
    investmentEntity: {
      id?: number;
      name?: string;
    };
    legalEntity: {
      id?: string;
      name?: string;
    };
  }[];
  distributions: {
    id: number;
    amount: string;
    date: string;
    investmentEntity: {
      id?: number;
      name?: string;
    };
    legalEntity: {
      id?: string;
      name?: string;
    };
  }[];
  legalEntities: {
    id: string;
    name: string;
  }[];
  object: {
    id: number;
    name: string;
  };
};
export type InvestmentIndexDto = {
  id: number;
  investmentClasses: ('a' | 'b')[];
  totalContributionUnits: number | null;
  investmentEntity: {
    id: number;
    name: string;
    users: {
      id: number;
      fullName: string;
      email: string;
    }[];
  };
  legalEntity: {
    id: string;
    name: string;
  };
  interest: number | null;
  interestClassB: number | null;
  removable: boolean;
  totalCommitmentUnits: number;
  totalContributionsCents: number;
  totalContributionsCount: number;
  totalDistributions: number;
  totalDistributionsCents: number;
  totalDistributionsCount: number;
  totalCommitmentsCents: number;
  totalClassAContributionAmountCents?: number;
  totalClassBContributionAmountCents?: number;
  totalClassAContributionUnits?: number;
  totalClassBContributionUnits?: number;
  accruedEndingBalanceCents: number;
  lastCommitmentSignedDate: string | null;
  firstContributionDate: string | null;
  firstClassAContributionDate?: string | null;
  firstClassBContributionDate?: string | null;
  lastContributionDate: string | null;
  lastDistributionDate: string | null;
};
export type TransactionSourceDto = {
  id: number;
  kind: 'contribution' | 'distribution';
  generalLedger: {
    id: number;
    accTitle: string;
  };
  legalEntity: {
    id: string;
    name: string;
    code: string;
    source: string;
  };
};
export type InvestmentObjectUnitPriceDto = {
  id: string;
  priceCents: number;
  date: string;
  investmentClass: 'a' | 'b';
  locked: boolean;
};

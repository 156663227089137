import React from 'react';
import SearchableMultiSelect from 'bundles/Shared/components/SearchableMultiSelect';

const SetInvestmentEntities = ({ setValue, fields }) => (
  <div className="mb-tw-4 border-t border-light-20 rounded-lg p-2">
    <div className="custom-control checkbox pl-0">
      <div className="dark-60 secondary-regular font-weight-500 mb-[0.5rem]">
        Which Investment Entities does the user belong to? NOTE: {' '}
        if user does not have a formal legal entity, please create one using their name.
      </div>

      <SearchableMultiSelect
        url={'/settings/user_management/investment_entities'}
        value={fields.investmentEntities}
        placeholder="Choose Entities or type to create a new one"
        setValue={(options) => {
          setValue('investmentEntities', options);
        }}
        creatable
        getOptions={(options) => options.map(({ id, name }) => ({ label: name, value: id }))}
        params={{}}
        defaultValues={fields.investmentEntities}
      />
    </div>
  </div>
);

export default SetInvestmentEntities;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories';
import useBoolean from '@/shared/lib/hooks/useBoolean';

interface Props {
  content: React.ReactNode;
  count: number;
  title: string;
  canManage: boolean;
  actions?: React.ReactNode;
  className?: string;
  initialExpanded?: boolean;
  singleCount?: boolean;
}

export const CollapsableSection = ({
  className,
  content,
  count,
  title,
  actions,
  canManage = false,
  initialExpanded = true,
  singleCount = false,
}: Props) => {
  const { value: expanded, toggle } = useBoolean(initialExpanded);

  return (
    <div className={cn('bg-neutral-000 px-tw-4 py-tw-2', className)}>
      {count > 0 && (
        <>
          <div className="flex items-center justify-between gap-tw-2">
            <div className="flex items-center gap-tw-2">
              <div className="header6-regular text-neutral-500">{title}</div>
              {!expanded && !singleCount && (
                <div className="py-0.5 inline-regular flex items-center justify-center rounded-lg bg-neutral-150 px-1 text-neutral-600">
                  {count}
                </div>
              )}
            </div>
            <div className="flex items-center gap-tw-2">
              {actions}
              <IconButton
                variant="secondary"
                iconName={expanded ? 'arrowTop' : 'arrowBottom'}
                onClick={toggle}
              />
            </div>
          </div>
          {expanded && <div className="mt-m">{content}</div>}
        </>
      )}

      {count === 0 && canManage && (
        <div className="flex justify-between">
          <div className="header6-regular text-neutral-500">{title}</div>
          {actions}
        </div>
      )}
      {count === 0 && !canManage && (
        <div className="header6-regular text-neutral-500">
          {`NO ${title}`} YET
        </div>
      )}
    </div>
  );
};

export default CollapsableSection;

import { InvoiceLineItem } from '@/shared/types/reconcile/Invoice';

interface ITransformLineItem {
  id: string;
  amount: number;
  name: string;
}

export const transformLineItemsByCategory = (
  lineItems: InvoiceLineItem[] = [],
) => {
  let categoryData: ITransformLineItem[] = [];
  lineItems.forEach((lineItem) => {
    const repeatLineItem = categoryData.find(
      (category) => category.id === lineItem.developmentCategory.id,
    );
    if (repeatLineItem) {
      categoryData = categoryData.map((category) => {
        if (repeatLineItem.id === category.id) {
          return {
            ...category,
            amount: Number(category.amount) + Number(lineItem.amount),
          };
        }
        return category;
      });
    } else {
      categoryData.push({
        id: lineItem.developmentCategory.id,
        name: lineItem.developmentCategory.name,
        amount: Number(lineItem.amount),
      });
    }
  });

  return categoryData;
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IUserWithAccessLevel } from 'bundles/UserManagement/components/EditUserModalComponents/types';
import { API_URL } from 'lib/http';
import { stringify } from 'qs';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { SortType } from 'types/Sort';
import { IUser } from 'types/User';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import { InvestmentEntitiesPageParams } from 'bundles/Settings/components/Portal/InvestmentEntities/EntitiesTracker';
import { snakeCase } from 'lodash-es';
import { ILegalEntitiable } from 'types/LegalEntitiable';

export interface InvestmentEntity extends IInvestmentEntity {
  createdAt: UnixTime;
  updatedBy: Pick<IUser, 'fullName'>;
  linkedMembersCount: number;
  updatedAt: UnixTime;
  userIds: string[];
  users: IUserWithAccessLevel[];
  capitalAssets: Pick<ILegalEntitiable, 'id' | 'name'>[];
  capitalFunds: Pick<ILegalEntitiable, 'id' | 'name'>[];
  totalContributions: Integer;
  totalDistributions: Integer;
}

export type InvestmentEntitiesFetchReqParams = {
  page: number;
  size_per_page: number;
  search_query: string;
  sort_field: string;
  sort_order: SortType;
  filters: {
    vendor_entity_ids: VendorEntity['id'][];
    user_ids: IUser['id'][];
  };
};

type GetInvestmentEntitiesResponse = {
  items: InvestmentEntity[];
  meta: {
    totalSize: number;
  };
};

export interface InvestmentEnitityMutationRequest
  extends Pick<InvestmentEntity, 'id'> {
  investment_entity: Pick<InvestmentEntity, 'name'> & {
    user_ids: IUser['id'][];
    vendor_entity_ids: VendorEntity['id'][];
  };
}

export const investmentEntitiesApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/investment_entities`,
    paramsSerializer: (params) =>
      stringify(params, {
        arrayFormat: 'brackets',
      }),
  }),
  tagTypes: ['InvestmentEntitiesTag'],
  reducerPath: 'investmentEntities',
  endpoints: (build) => ({
    getInvestmentEntities: build.query<
      GetInvestmentEntitiesResponse,
      InvestmentEntitiesPageParams
    >({
      providesTags: ['InvestmentEntitiesTag'],
      query: (params) => ({
        url: '',
        params: {
          page: params.page,
          sort_order: params.sortOrder,
          sort_field: snakeCase(params.sortField),
          search_query: params.query,
          size_per_page: params.perPage,
          filters: {
            vendor_entity_ids: params.filters.vendorEntities.map(
              (item) => item.id,
            ),
            user_ids: params.filters.users.map((item) => item.id),
          },
        },
      }),
    }),

    destroyInvestmentEntity: build.mutation<unknown, InvestmentEntity>({
      invalidatesTags: ['InvestmentEntitiesTag'],
      query: (data) => ({
        method: 'DELETE',
        url: `/${data.id}`,
      }),
      transformResponse: (response: { errors: string[] | null } | []) => {
        if ('errors' in response) {
          toastr.error(`${(response.errors ?? []).toString()}\n`);
        } else {
          toastr.success('Entity has been successfully deleted');
        }
        return response;
      },
    }),

    updateInvestmentEntity: build.mutation<
      unknown,
      InvestmentEnitityMutationRequest
    >({
      invalidatesTags: ['InvestmentEntitiesTag'],
      query: (data) => ({
        method: 'PUT',
        url: `/${data.id}`,
        body: data,
      }),
      transformResponse: (response: { errors: string[] | null } | []) => {
        if ('errors' in response) {
          toastr.error(`${(response.errors ?? []).toString()}\n`);
        } else {
          toastr.success('Entity has been successfully updated');
        }
        return response;
      },
    }),

    createInvestmentEntity: build.mutation<
      unknown,
      InvestmentEnitityMutationRequest
    >({
      invalidatesTags: ['InvestmentEntitiesTag'],
      query: (data) => ({
        method: 'POST',
        url: '/',
        body: data,
      }),
      transformResponse: (response: { errors: string[] | null } | []) => {
        if ('errors' in response) {
          toastr.error(`${(response.errors ?? []).toString()}\n`);
        } else {
          toastr.success('Entity has been successfully created');
        }
        return response;
      },
    }),
  }),
});

export const {
  useGetInvestmentEntitiesQuery,
  useDestroyInvestmentEntityMutation,
  useUpdateInvestmentEntityMutation,
  useCreateInvestmentEntityMutation,
} = investmentEntitiesApi;

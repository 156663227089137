import { useExportExtractionAttachment } from '@/features/report/connections/exportExtractionAttachment/lib';
import { ExportIconButton } from '@/shared/ui/ExportButton';

export const ExportExtractionAttachmentIconButton = ({
  fileUrl,
}: {
  fileUrl: string;
}) => {
  const [exportExt] = useExportExtractionAttachment();

  return <ExportIconButton onClick={() => exportExt(fileUrl)} />;
};

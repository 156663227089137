export const transformEmptyObjectToUndefined = (value: unknown) => {
  // If any child property has a value, skip the transform
  if (
    value &&
    Object.values(value).some(
      (v) => !(v === null || v === undefined || v === ''),
    )
  ) {
    return value;
  }

  // Transform the value to undefined
  return undefined;
};

import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import React, { FC, ReactNode } from 'react';
import Icon from 'stories/Icon/Icon';
import Tooltip from 'stories/Tooltip/Tooltip';
import { IconsId } from 'types/sre-icons';
import './approval-status.scss';

export interface IApprovalStatusProps {
  iconName?: IconsId;
  text: string;
  priority?: 'none' | 'low' | 'medium' | 'high';
  tooltipText?: string;
}

export const ApprovalStatus: FC<IApprovalStatusProps> = ({
  iconName,
  text,
  priority,
  tooltipText,
}) => {
  return (
    <div className="sre-approval-status_wrapper">
      <Tooltip mainText={tooltipText} arrowPosition="center">
        <div className="sre-approval-status">
          {iconName && <Icon iconName={iconName} />}
          {text}
        </div>
      </Tooltip>
      {priority && (
        <FireIcon className="sre-approval-status__icon" priority={priority} />
      )}
    </div>
  );
};

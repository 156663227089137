import {
  selectUserActivitySummary,
  setUserActivityChangingDate,
  setUserActivityFilterRoles,
} from 'bundles/Shared/entities/user/slices/userActivitySlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import React, { useEffect } from 'react';
import { Button, ClassicTag } from 'stories';
import { fetchUserRoles } from 'bundles/UserManagement/reducers/userRolesSlice';
import { UserRoleFilter } from 'bundles/Shared/features/user/filter/byRole';
import { IUserRole, TUserRoleCategory } from 'types/User';
import { RangeDatePicker } from 'stories/FlexibleFilterByPeriods/calendar/Calendar';
import dayjs, { Dayjs } from 'dayjs';
import { UserActivityReportModal } from 'bundles/Shared/widgets/user/activity/ui/UserActivityReportModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export const PERIOD_FILTERS = [
  {
    title: 'Last 7 days',
    value: 7,
    get periods() {
      return [
        dayjs()
          .subtract(this.value - 1, 'day')
          .startOf('day'),
        dayjs().startOf('day'),
      ];
    },
  },
  {
    title: 'Last 14 days',
    value: 14,
    get periods() {
      return [
        dayjs()
          .subtract(this.value - 1, 'day')
          .startOf('day'),
        dayjs().startOf('day'),
      ];
    },
  },
  {
    title: 'Last 30 days',
    value: 30,
    get periods() {
      return [
        dayjs()
          .subtract(this.value - 1, 'day')
          .startOf('day'),
        dayjs().startOf('day'),
      ];
    },
  },
  {
    title: 'Last 90 days',
    value: 90,
    get periods() {
      return [
        dayjs()
          .subtract(this.value - 1, 'day')
          .startOf('day'),
        dayjs().startOf('day'),
      ];
    },
  },
];

export const DEFAULT_USER_ACTIVITY_PERIODS = PERIOD_FILTERS[0].periods;

const UserActivityFiltersBar = () => {
  const { openModal } = useModal();
  const { fromDate, toDate, roleIds } = useAppSelector(
    selectUserActivitySummary,
  );

  const defaultPeriods = PERIOD_FILTERS[0].periods;

  const periods: Dayjs[] = [
    dayjs(fromDate).startOf('day'),
    dayjs(toDate).startOf('day'),
  ];

  const userRolesState = useAppSelector((state) => state.userRoles);
  const { roles } = userRolesState;

  const dispatch = useAppDispatch();

  const onChangePeriods = (period: (typeof PERIOD_FILTERS)[number]) => {
    dispatch(
      setUserActivityChangingDate({
        fromDate: formatToDateStringForRequest(period.periods[0]),
        toDate: formatToDateStringForRequest(period.periods[1]),
      }),
    );
  };

  const onChangeRoles = (newRoles: IUserRole[]) => {
    dispatch(setUserActivityFilterRoles(newRoles.map((role) => role.id)));
  };

  useEffect(() => {
    if (!roles.length) {
      dispatch(fetchUserRoles());
    }
  }, []);

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-tw-2">
        {PERIOD_FILTERS.map((periodFilter) => (
          <ClassicTag
            key={periodFilter.value}
            variant="blue"
            label={periodFilter.title}
            onClick={() => onChangePeriods(periodFilter)}
            selected={periods.every((d, idx) =>
              d.isSame(periodFilter.periods[idx]),
            )}
            className="px-tw-3 py-tw-2"
          />
        ))}
      </div>
      <div className="flex items-center gap-tw-4">
        <div>
          <RangeDatePicker
            defaultPeriods={defaultPeriods}
            dayjsPeriods={periods}
            onChange={(p) => {
              dispatch(
                setUserActivityChangingDate({
                  fromDate: formatToDateStringForRequest(p[0]),
                  toDate: formatToDateStringForRequest(p[1]),
                }),
              );
            }}
          />
        </div>
        <UserRoleFilter
          roles={roles.filter(
            (role) => role.category !== TUserRoleCategory.SYMMETRE,
          )}
          roleIds={roleIds ?? []}
          onChangeRoles={onChangeRoles}
        />
        <Button
          variant="primary"
          onClick={() => {
            openModal(UserActivityReportModal);
          }}
        >
          Report
        </Button>
      </div>
    </div>
  );
};

export default UserActivityFiltersBar;

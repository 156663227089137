export default {
  COMPLETED: '#179237',
  APPROVED: '#69c982',
  'PARTIALLY APPROVED': '#69c982',
  PENDING: '#ffbf36',
  'PENDING-PROCEEDING': '#ffbf36',
  'PENDING-NOT PROCEEDING': '#ff9528',
  DRAFT: '#304e5a',
  REJECTED: '#8b0c12',
  DISPUTED: '#ed1622',
  VOID: '#cecece',
};

import React from 'react';
import { Button, SreAvatar } from 'stories';
import { logout } from 'lib/currentUser';
import { IUser } from 'types/User';

interface Props {
  currentUser: IUser;
}

const UserCard = ({ currentUser }: Props) => {
  const { fullName, role, email } = currentUser;
  const handleClick = () =>
    logout().then(() => {
      window.location.href = '/users/sign_in';
    });

  return (
    <div className="logout-card px-l pt-l">
      <div className="light-60 inline-regular flex items-center flex-col text-center">
        {role.name}
        <div className="my-[1rem]">
          <SreAvatar
            text={fullName}
            width="56px"
            height="56px"
            color="var(--light)"
            backgroundColor="var(--green)"
            borderRadius="1rem"
            fontSize="1.25rem"
            fontWeight="700"
          />
        </div>
        <h5 className="light-90 mb-[0.5rem]">{fullName}</h5>
        <span className="light-60 mb-[1.5rem] inline-regular font-weight-400">
          {email}
        </span>
        <Button
          variant="secondary"
          type="submit"
          onClick={handleClick}
          className="font-weight-400 blue mb-tw-4 inline-regular bg-light"
        >
          Logout
          <span className="sre-icon-logout light-60" />
        </Button>
      </div>
    </div>
  );
};

export default UserCard;

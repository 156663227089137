import { IScheduleOfValueCode } from 'types/ScheduleOfValueCode';
import { IJobCostCode } from 'types/JobCostCode';
import { TSharedFilesLoadData } from 'bundles/Construction/components/Reallocation/Modals/utils';

export type ICreateReallocationFormData = TSharedFilesLoadData & {
  subReallocations: TSubReallocationFormData[];
  movedFromSovcs?: TReallocatedSovcForReallocation[] | null;
  movedToSovcs?: TReallocatedSovcForReallocation[] | null;
  change_order_id?: number;
  contingencyImpact?: ContingencyImpactsMoved;
  moved_from_id?: number | null;
  moved_to_id?: number | null;
  description?: string;
  value?: Float | null;
};

export type TSubReallocationFormData = Pick<
  ICreateReallocationFormData,
  'moved_to_id' | 'moved_from_id' | 'value' | 'movedFromSovcs' | 'movedToSovcs'
>;

type TReallocationAmount = {
  amount: RawFloat | Integer;
};

export type TSovcForReallocation = Pick<
  IScheduleOfValueCode,
  'id' | 'code' | 'name'
> &
  TReallocationAmount;

export type TJobCostCodeForReallocation = Pick<
  IJobCostCode,
  'id' | 'code' | 'name'
> &
  TReallocationAmount;

export type TReallocatedSovcForReallocation = TSovcForReallocation & {
  reallocation: Float;
};

export const isReallocatedSovc = (
  sovc: TSovcForReallocation,
): sovc is TReallocatedSovcForReallocation =>
  'reallocation' in sovc && sovc.reallocation !== 0;

export enum ContingencyImpactsMoved {
  FROM = 'Moved from',
  TO = 'Moved to',
  NO = 'No impact',
}

import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { AnimationLoader, ChangeableStatusBadge } from 'stories';
import { fetchGoalsSummary } from 'bundles/Scoreboard/Pipeline/actions';
import GoalsSummaryChart from 'bundles/Scoreboard/Pipeline/components/Pipeline/PipelineCharts/GoalsSummaryChart';
import { colors } from 'bundles/Scoreboard/Pipeline/components/chartUtils';

const GoalsSummary = () => {
  const DEFAULT_PROPERTY_TYPE = {
    key: 'all',
    color: 'blue',
    label: 'Consolidated',
  };

  const dispatch = useAppDispatch();
  const propertyTypes = useAppSelector((state) => state.deals.propertyTypes);
  const goalsSummary = useAppSelector((state) => state.deals.goalsSummary);
  const [selectedPropertyType, setSelectedPropertyType] = useState(
    DEFAULT_PROPERTY_TYPE,
  );

  useEffect(() => {
    dispatch(fetchGoalsSummary(selectedPropertyType.label));
  }, [selectedPropertyType]);

  const options = useMemo(
    () =>
      propertyTypes.map((item, index) => ({
        label: item,
        key: item.replace(' ', '_').toLowerCase(),
        color: colors[index % colors.length],
      })),
    [propertyTypes],
  );

  return (
    <div className="card">
      <div className="card-header card-header-action">
        <div>
          Pipeline
          <h4 className="dark-60">Goal Summary</h4>
        </div>
        <ChangeableStatusBadge
          handleItemClick={setSelectedPropertyType}
          options={[DEFAULT_PROPERTY_TYPE, ...options]}
          badgeLabel={null}
          selected={selectedPropertyType}
        />
      </div>

      <div className="card-body p-0 min-h-[500px]">
        {!goalsSummary && <AnimationLoader />}
        {goalsSummary && (
          <GoalsSummaryChart pipelineChartsData={goalsSummary} />
        )}
      </div>
    </div>
  );
};

export default GoalsSummary;

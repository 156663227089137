import React, { useMemo, useState } from 'react';
import {
  createThresholdInReconcileSettings,
  deleteThresholdInReconcileSettings,
  IWorkflowThreshold,
  TFetchThresholdsRequest,
  TWorkflowThresholdType,
  updateThresholdInReconcileSettings,
} from 'bundles/REconcile/actions/workflowThresholds';
import useEvent from '@/shared/lib/hooks/useEvent';
import {
  CrudContextProvider,
  CrudCreateButton,
  CrudTable,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/index';
import { useThresholdColumns } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/cruds/useColumns';
import { sortBy } from 'lodash-es';
import { useModal } from '@/shared/lib/hooks/useModal';
import ThresholdModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/cruds/ThresholdModal';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  latestThresholdNumber,
  nextThresholdValue,
  previousThresholdValue,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/thresholdHelpers';
import { hasCorsAwaitingApproval } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/utils';

interface Props {
  thresholdType: TWorkflowThresholdType;
  legalEntityId: string;
  thresholds: IWorkflowThreshold[];
  onThresholdsChanged: () => void;
}

const THRESHOLD_TITLE_MAPPING: Record<TWorkflowThresholdType, string> = {
  [TWorkflowThresholdType.AGG]: 'Aggregate Contingency Threshold',
  [TWorkflowThresholdType.COR]: 'Individual COR Amount',
  [TWorkflowThresholdType.TIM]: 'Estimated Timing Impact',
};

function ThresholdCrud({
  legalEntityId,
  thresholdType,
  thresholds,
  onThresholdsChanged,
}: Props) {
  const { openModal } = useModal();
  const users = useAppSelector((state) => state.usersForReconcileSettings);
  const workflowSettings = useAppSelector(
    (state) => state.developmentLegalEntity.workflow,
  );
  const [pageParams, setPageParams] = useState<TFetchThresholdsRequest>({});

  const filteredThresholds = useMemo(
    () => thresholds.filter((t) => t.kind === thresholdType),
    [thresholds, thresholdType],
  );
  const sortedThresholds = useMemo(
    () =>
      pageParams.sort
        ? sortBy(filteredThresholds, pageParams.sort.field)
        : filteredThresholds,
    [pageParams, filteredThresholds],
  );
  const columns = useThresholdColumns(filteredThresholds);

  const handleCreate = useEvent(async () => {
    const currentNumber = latestThresholdNumber(sortedThresholds) + 1;
    const prevNumber = currentNumber - 1;
    const minThresholdValue = previousThresholdValue(
      prevNumber,
      filteredThresholds,
    );
    const maxThresholdValue = nextThresholdValue(
      currentNumber,
      filteredThresholds,
    );

    const res = await openModal(ThresholdModal, {
      thresholdType,
      threshold: {
        external_approver_ids: [],
        internal_approver_id: null,
        value: null,
        number: currentNumber,
        category: thresholdType,
      },
      maxThresholdValue,
      minThresholdValue,
      users: users ?? [],
      type: 'create',
    });
    if (res) {
      await createThresholdInReconcileSettings(
        {
          legalEntityId,
        },
        res,
      );
      onThresholdsChanged();
    }
  });

  const handleEdit = useEvent(async (row: IWorkflowThreshold) => {
    const currentNumber = row.number;
    const prevNumber = currentNumber;
    const minThresholdValue = previousThresholdValue(
      prevNumber,
      filteredThresholds,
    );
    const maxThresholdValue = nextThresholdValue(
      currentNumber,
      filteredThresholds,
    );

    const res = await openModal(ThresholdModal, {
      thresholdType,
      threshold: {
        external_approver_ids: row.externalApprovers.map((u) => u.id),
        internal_approver_id: row.internalApprover.id,
        value: row.value,
        number: row.number,
        category: thresholdType,
      },
      minThresholdValue,
      maxThresholdValue,
      users: users ?? [],
      type: 'edit',
    });
    if (res) {
      await updateThresholdInReconcileSettings(
        {
          legalEntityId,
          entityId: row.id,
        },
        res,
      );
      onThresholdsChanged();
    }
  });

  const handleRemove = useEvent(async (row: IWorkflowThreshold) => {
    await deleteThresholdInReconcileSettings({
      entityId: row.id,
      legalEntityId,
    });
    onThresholdsChanged();
  });

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entityName="Threshold"
      entities={sortedThresholds}
      handlers={{
        create: handleCreate,
        update: handleEdit,
        delete: handleRemove,
      }}
    >
      <div className="flex flex-col gap-s">
        <div className="flex justify-between items-center mb-m">
          <h6 className="light-90 header6-bold">
            {THRESHOLD_TITLE_MAPPING[thresholdType]}
          </h6>
          <CrudCreateButton />
        </div>
        <CrudTable
          editDisabled={() =>
            hasCorsAwaitingApproval(workflowSettings, thresholdType)
          }
          removeDisabled={() =>
            hasCorsAwaitingApproval(workflowSettings, thresholdType)
          }
          columns={columns}
        />
      </div>
    </CrudContextProvider>
  );
}

export default ThresholdCrud;

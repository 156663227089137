export type TConnectorType = '|' | '|-' | '|_';

export const getConnectorType = ({
  lastChild,
  depth,
  startDepth,
  index,
}: {
  index: number;
  depth: number;
  lastChild: boolean;
  startDepth: number;
  firstChild?: boolean;
}): TConnectorType => {
  const connectorDepth = startDepth + index + 1;
  if (connectorDepth === depth && !lastChild) {
    return '|-';
  }
  if (connectorDepth === depth && lastChild) {
    return '|_';
  }

  return '|';
};

import React from 'react';
import * as yup from 'yup';
import { ListOption } from 'stories/Checkbox/CheckList';
import {
  AVAILABLE_GRADIENTS,
  getGradientThreshold,
  GRADIENT_THRESHOLD_SCHEMA,
  GradientField,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GradientField';
import { COLOR_RULE_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { InlineAlert, Tumbler } from '@/stories';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Control, useFormContext } from 'react-hook-form';
import { capitalize } from 'lodash-es';
import { TumblerController } from 'stories/Tumbler/Tumbler';

const HIGHLITING_RULES_OPTIONS = [
  {
    label: 'Font',
    value: 'font',
  },
  {
    label: 'Column',
    value: 'column',
  },
] as const satisfies readonly ListOption[];

export const HIGHLITHING_RULES_SCHEMA = yup.object({
  type: yup
    .string()
    .oneOf(HIGHLITING_RULES_OPTIONS.map((o) => o.value))
    .required(),
  gradient_thresholds: yup
    .array()
    .of(GRADIENT_THRESHOLD_SCHEMA)
    .when('type', {
      is: 'font',
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
  gradient_ignore_zeros: yup.boolean().optional().nullable().default(undefined),
  color_rule: yup
    .string()
    .oneOf(COLOR_RULE_OPTIONS.map((o) => o.value))
    .when('type', {
      is: 'column',
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
});

type HighlightingRulesForm = yup.InferType<typeof HIGHLITHING_RULES_SCHEMA>;

export function HighlightingRulesField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  fieldType = 'column',
  initialHighlightingType = 'font',
  allowedHighlightingTypes,
  disabled,
  disabledNote,
}: {
  name: TName;
  control: Control<TFieldValues>;
  fieldType?: 'row' | 'column';
  allowedHighlightingTypes?: HighlightingRulesForm['type'][];
  initialHighlightingType?: HighlightingRulesForm['type'];
  disabled?: boolean;
  disabledNote?: string;
}) {
  const { watch, setValue } = useFormContext();
  const getFieldName = (fieldName: keyof HighlightingRulesForm) =>
    `${name}.${fieldName}` as FieldPath<TFieldValues>;

  const type = watch(getFieldName('type'));
  const highlightingOptions = HIGHLITING_RULES_OPTIONS.map((o) =>
    o.value === 'column' ? { ...o, label: capitalize(fieldType) } : o,
  );

  const setType = (newType: HighlightingRulesForm['type']) => {
    setValue(
      name,
      {
        type: newType,
        gradient_thresholds:
          newType === 'font'
            ? null
            : getGradientThreshold(AVAILABLE_GRADIENTS.GREEN_YELLOW_RED),
        color_rule: null,
      } as HighlightingRulesForm,
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  };

  return (
    <SectionFieldsContainer>
      <SectionField
        button={
          <Tumbler
            disabled={disabled}
            checked={type != null}
            onChange={(e) =>
              e.target.checked
                ? setType(initialHighlightingType)
                : setValue(name, null, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
            }
          />
        }
        labelText="Highlighting Rules"
        note={`Here you can set the highlighting rules for the ${fieldType}`}
      >
        {disabled && disabledNote && (
          <InlineAlert message={disabledNote} status="secondary" />
        )}
        {type != null && (
          <RadioGroupController
            control={control}
            name={getFieldName('type')}
            onChange={(o) => {
              setValue(getFieldName('type'), o.value);
              setType(o.value as HighlightingRulesForm['type']);
            }}
            getItemProps={(o) => ({
              disabled:
                allowedHighlightingTypes != null &&
                !allowedHighlightingTypes.includes(
                  o.value as HighlightingRulesForm['type'],
                ),
            })}
            options={highlightingOptions}
          />
        )}
      </SectionField>
      {type == null ? null : (
        <SectionField
          labelText={
            type === 'font' ? 'Color Rule' : `${capitalize(fieldType)} Rules`
          }
        >
          {type === 'font' ? (
            <RadioGroupController
              options={COLOR_RULE_OPTIONS}
              control={control}
              name={getFieldName('color_rule')}
            />
          ) : (
            <>
              <GradientField
                name={getFieldName('gradient_thresholds')}
                control={control}
              />

              <TumblerController
                control={control}
                name={getFieldName('gradient_ignore_zeros')}
              >
                Ignore Zero Values
              </TumblerController>
            </>
          )}
        </SectionField>
      )}
    </SectionFieldsContainer>
  );
}

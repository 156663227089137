import React, { FC, useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Field, Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from '../types/types';

interface Props extends IFormItem {
  value?: string;
  format?: string;
  formatView?: string;
  leftIcon?: string;
  maxDate?: Date;
  minDate?: Date;
}

const FormDate: FC<Props> = ({
  title,
  placeholder,
  fieldName,
  required,
  formData,
  setFormData,
  loading,
  hint,
  styles,
  formatView = 'MM-dd-yyyy',
  leftIcon,
  minDate,
  maxDate,
  disabled,
}) => {
  const handleChange = (date: Date) => {
    setFormData({
      ...formData,
      [fieldName]: date,
    });
  };

  return (
    <div
      className={cn(
        'form-item',
        styles?.wrapper ?? 'w-full',
        styles?.nomb && 'form-item_nomb',
      )}
    >
      <Field labelText={title} required={required} noLabel={!title} hint={hint}>
        <div className="form-item-date">
          <ReactDatePicker
            portalId="datepicker-portal"
            className={cn(
              'form-control',
              'form-item__date',
              styles?.size ? `form-item__date_size-${styles.size}` : '',
              leftIcon ? 'form-item__date_left' : '',
            )}
            selected={formData[fieldName]}
            onChange={handleChange}
            placeholderText={placeholder}
            dateFormat={formatView}
            disabled={loading || disabled}
            minDate={minDate}
            maxDate={maxDate}
          />
          <div className="form-item-date__icon">
            <Icon iconName="arrowBottom" />
          </div>
          {leftIcon && (
            <div className="form-item-date__icon form-item-date__icon_left">
              <Icon iconName="calendar" />
            </div>
          )}
        </div>
      </Field>
    </div>
  );
};
export default FormDate;

import { sortPeriodItemsByUnix } from '@/bundles/REport/components/financials/utils';
import { onFullHistoryActiveChange } from '@/bundles/REport/reducers/financialsSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { IPeriodItem } from '@/stories/FlexibleFilterByPeriods/types';
import {
  createMonthPeriodItem,
  createQuarterPeriodItem,
  createYearPeriodItem,
  ReportTableConfigType,
  uniqPeriodsByQuarter,
  uniqPeriodsByYear,
} from '@/stories/FlexibleFilterByPeriods/utils';

export function getFinancialsDatePickerFullHistoryPeriodItems(
  possiblePeriods: IPeriodItem['period'][],
  periodType: IPeriodItem['type'],
) {
  let periods: IPeriodItem[] = [];
  switch (periodType) {
    case 'ytd': {
      periods = uniqPeriodsByYear(possiblePeriods).map(createYearPeriodItem);
      break;
    }
    case 'qtd': {
      periods = uniqPeriodsByQuarter(possiblePeriods).map(
        createQuarterPeriodItem,
      );
      break;
    }
    case 'mtd': {
      periods = possiblePeriods.map(createMonthPeriodItem);
      break;
    }
  }

  return sortPeriodItemsByUnix(periods);
}

export const useFinancialsDatePickerFullHistory = () => {
  const financialsSlice = useAppSelector((state) => state.financials);
  const dispatch = useAppDispatch();
  const { isFullHistoryActive, reportTableConfig } = financialsSlice;

  const handleToggle = () => {
    dispatch(onFullHistoryActiveChange());
  };

  const isButtonHidden =
    reportTableConfig == null
      ? false
      : new ReportTableConfigType(reportTableConfig.periodsType)
          .isSingleMonthSelection;

  return {
    isActive: isFullHistoryActive,
    isButtonHidden,
    handleToggle,
  };
};

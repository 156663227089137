import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/index';
import {
  IPaginationMeta,
  IPaginationParams,
  IQueryParams,
} from 'types/Pagination';

export type TGetCompaniesRequestParams = IPaginationParams & IQueryParams;
export interface IReconcileSettingsCompany {
  id: number;
  name: string;
  code: string;
}

export interface IReconcileSettingsCompanyUpdate {
  code: string;
  name: string;
}

export const {
  getEntities: getCompaniesInReconcileSettings,
  createEntity: createCompanyInReconcileSettings,
  updateEntity: updateCompanyInReconcileSettings,
  deleteEntity: deleteCompanyInReconcileSettings,
  entityName: COMPANIES_ENTITY_NAME,
} = createCrudApi<
  {},
  TGetCompaniesRequestParams,
  IReconcileSettingsCompany,
  IReconcileSettingsCompanyUpdate,
  {
    items: IReconcileSettingsCompany[];
    meta: IPaginationMeta;
  }
>('/settings/reconcile/companies', 'Company');

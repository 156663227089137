import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';
import FormViewTitle from '../../GroupForm/FormViewItem/FormViewTitle';

const ArrowsTypeField = ({
  title,
  changes,
  transformerView,
  objectName,
  styles,
  isArray,
}) => {
  let oldValue = changes.old;
  let currentValue = changes.current;

  if (transformerView) {
    oldValue = transformerView ? transformerView(oldValue) : oldValue;
    currentValue = transformerView
      ? transformerView(currentValue)
      : currentValue;
  }
  if (isArray) {
    oldValue = oldValue
      .map((val) => {
        if (objectName) {
          return val[objectName];
        }
        return val;
      })
      .join('; ');
    currentValue = currentValue
      .map((val) => {
        if (objectName) {
          return val[objectName] || '';
        }
        return val;
      })
      .join('; ');
  } else if (objectName) {
    oldValue = oldValue[objectName];
    currentValue = currentValue[objectName];
  }
  const haveChanges = oldValue === currentValue;

  return (
    <div className="flex form-view-item gap-s">
      <FormViewTitle title={title} />
      <div>
        {!haveChanges ? (
          <div
            className={cn(
              styles?.wrapper !== 'bottom' && 'flex align-item-center',
            )}
          >
            <div className="hd-old-value">{oldValue}</div>
            <Icon
              iconName={
                styles?.wrapper === 'bottom' ? 'longArrowDown' : 'arrowRightAlt'
              }
              className={cn(
                styles?.wrapper === 'bottom' ? 'mb-s mt-s' : 'mx-s',
                'light-60',
                'd-block',
              )}
            />
            <div>{currentValue}</div>
          </div>
        ) : (
          <div>{oldValue}</div>
        )}
      </div>
    </div>
  );
};

export default ArrowsTypeField;

import { nameOf } from 'types/utils';
import { ISovcRowUpdate } from 'bundles/REconcile/actions/sovcConfig';
import {
  FieldType,
  IFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';

export const DEFAULT_CODE_FIELD: IFormItem = {
  fieldName: nameOf<ISovcRowUpdate>('code'),
  type: FieldType.INPUT_TEXT,
  required: true,
  title: 'Code',
  placeholder: 'Code',
};
export const DEFAULT_NAME_FIELD: IFormItem = {
  fieldName: nameOf<ISovcRowUpdate>('name'),
  type: FieldType.INPUT_TEXT,
  required: true,
  title: 'Name',
  placeholder: 'Name',
};

export const getJccsSelectField = (
  jccList: { id: number; name: string }[],
  settings?: Partial<IFormItem>,
): IFormItem => ({
  fieldName: 'job_cost_code_id',
  title: 'Job Cost Code',
  type: FieldType.SELECT,
  placeholder: 'Select Job Cost Code',
  options: jccList.map((jcc) => ({
    value: jcc.id,
    label: jcc.name,
  })),
  menuPlacement: 'bottom',
  ...settings,
});

import * as React from 'react';
import { FilterCard } from 'stories';
import { capitalize } from 'lodash-es';
import StatisticLine from 'stories/filterCard/statistic/StatisticLine';
import StatisticBulletItem from 'stories/filterCard/statistic/StatisticBulletItem';
import { ComponentProps, useMemo } from 'react';
import {
  accountingNumberFormat,
  percentNumberFormat,
} from '@/shared/lib/formatting/number';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import { IChangeOrderEventStatisticsByStatus } from 'bundles/Construction/types';

export type TCOFilterCardType = 'completed' | 'approved' | 'pending' | 'draft';

interface Props
  extends Pick<ComponentProps<typeof FilterCard>, 'filtered' | 'onFilter'> {
  stats: IChangeOrderEventStatisticsByStatus;
  type: TCOFilterCardType;
}

const ACTIVE_COLOR = 'var(--dark)';
const CARD_TYPE_COLORS: Record<TCOFilterCardType, string> = {
  draft: 'var(--primary-light-3)',
  approved: 'var(--green-light-3)',
  pending: 'var(--yellow-dark-2)',
  completed: 'var(--green)',
};

function ChangeManagementCOFilterCard({
  stats,
  type,
  onFilter,
  filtered,
}: Props) {
  const { hovered, onMouseOver, onMouseLeave } = useHovered();
  const currentStatusStats = stats[type];

  const statistics = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(stats).map(([key, statsEntry]) => [
          key,
          {
            value: statsEntry.percent,
            hoverColor: CARD_TYPE_COLORS[key],
            color: key === type ? ACTIVE_COLOR : undefined,
          },
        ]),
      ),
    [currentStatusStats],
  );

  return (
    <FilterCard
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      header={
        <div className="flex flex-col">
          <span className="inline-semibold">{capitalize(type)}</span>
          {/* TODO: this span been moved from below. Should be adjusted within https://linear.app/symmetre/issue/SRE-3205/improve-the-ui-of-the-co-badges */}
          <span className="dark-60 lead-semibold">
            {accountingNumberFormat(currentStatusStats.amount)}
          </span>
        </div>
      }
      filtered={filtered}
      onFilter={onFilter}
      filterText={`Filtered by ${capitalize(type)} CO`}
      content={
        <div className="flex flex-col">
          {/* TODO: https://linear.app/symmetre/issue/SRE-3205/improve-the-ui-of-the-co-badges*/}
          {/* <span className="header6-regular dark-60">*/}
          {/*  {accountingNumberFormat(currentStatusStats.amount)}*/}
          {/* </span>*/}
          {/* <span className="label-regular light-90">*/}
          {/*  {percentNumberFormat(currentStatusStats.percent)} used of Initial Contingency*/}
          {/* </span>*/}
          {/* <StatisticLine*/}
          {/*  hovered={hovered}*/}
          {/*  statistics={statistics}*/}
          {/* />*/}
        </div>
      }
      // hoverContent={(
      //   <div className="flex flex-col gap-s">
      //     {Object.entries(statistics).map(([key, statsEntry]) => (
      //       <StatisticBulletItem
      //         key={key}
      //         backgroundColor={CARD_TYPE_COLORS[key]}
      //         label={capitalize(key)}
      //         value={percentNumberFormat(statsEntry.value)}
      //       />
      //     ))}
      //   </div>
      // )}
    />
  );
}

export default ChangeManagementCOFilterCard;

import React, { PropsWithChildren, useCallback, useRef } from 'react';
import { capitalize } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import * as Types from './types';
import { ButtonTagProps } from './types';
import { Button, IconButton } from '..';
import Slider from 'react-slick';
import * as Const from 'stories/FlexibleFilterByPeriods/consts';

interface YearSelectorProps extends ButtonTagProps, PropsWithChildren {
  selected: boolean;
}

export function YearSelector({
  children,
  selected,
  className,
  ...buttonProps
}: YearSelectorProps) {
  return (
    <div className="pr-tw-0.5">
      <button
        type="button"
        className={cn(
          'inline-regular relative mx-tw-0.5 !flex items-center justify-center rounded-lg border-none px-tw-2 py-tw-1 transition',
          {
            'bg-blue text-white hover:bg-blue-dark-2': selected,
            'bg-light bg-transparent text-light-60 hover:bg-light-10':
              !selected,
          },
          className,
        )}
        {...buttonProps}
      >
        {children}
      </button>
    </div>
  );
}

interface MonthItemProps extends ButtonTagProps, PropsWithChildren {
  active: boolean;
  isIncludesInRange?: boolean;
}

export function MonthItem({
  active,
  children,
  isIncludesInRange,
  className,
  ...buttonProps
}: MonthItemProps) {
  return (
    <button
      type="button"
      className={cn(
        'secondary-regular flex items-center justify-center border-none bg-white py-tw-2 text-blue transition hover:!bg-light disabled:bg-light-5 disabled:text-light-40',
        active &&
          'cursor-not-allowed bg-blue text-white hover:!bg-blue-dark-2 disabled:!bg-blue-dark-1 disabled:text-white',
        isIncludesInRange && 'bg-blue-light-2 text-white hover:!bg-blue-dark-1',
        className,
      )}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

interface QuarterItemProps extends ButtonTagProps, PropsWithChildren {
  active: boolean;
}

export function QuarterItem({
  active,
  children,
  ...buttonProps
}: QuarterItemProps) {
  return (
    <button
      type="button"
      className={cn(
        'header5-regular h-tw-10 w-full rounded-lg border border-none transition disabled:!bg-light-5 disabled:!text-light-40',
        {
          'border-light-10 bg-white text-light-60 hover:bg-light': !active,
          'border-blue bg-blue text-white hover:bg-blue-dark-2': active, // resolve bg-blue css class conflict
        },
      )}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

interface YearItemProps extends ButtonTagProps, PropsWithChildren {
  opened: boolean;
  hasItems?: boolean;
}

export const YearItem = ({
  opened,
  hasItems = false,
  children,
  ...buttonProps
}: YearItemProps) => (
  <div className="pr-tw-0.5">
    <button
      type="button"
      className={cn(
        'inline-regular relative mx-tw-0.5 !flex items-center justify-center rounded-lg border-none px-tw-2 py-tw-1 transition',
        {
          'bg-dark-60 text-white hover:bg-dark-80': opened,
          'bg-transparent text-light-60 hover:!bg-light-10': !opened,
          'before:absolute before:top-tw-0.5 before:h-tw-1 before:w-tw-1 before:rounded-full before:bg-blue before:content-[""]':
            hasItems && !opened,
        },
      )}
      {...buttonProps}
    >
      {children}
    </button>
  </div>
);

interface Props extends PropsWithChildren {
  quarterKey: Types.QuarterMapKey;
  onClick: () => void;
  isQuarterSelected: boolean;
  quarterDisabled: boolean;
  hideBtn?: boolean;
}

export function QuarterWithMonths({
  quarterKey,
  onClick,
  isQuarterSelected,
  quarterDisabled,
  children,
  hideBtn = false,
}: Props) {
  return (
    <div className="grid grid-cols-3 gap-tw-px overflow-hidden rounded-lg border border-light-10 bg-light-10">
      <div className="col-span-3 flex items-center justify-between bg-light p-tw-2 pl-tw-4">
        <p className="header5-regular text-light-60">
          {capitalize(quarterKey)}
        </p>
        {!hideBtn && !quarterDisabled && isQuarterSelected && (
          <IconButton iconName="closeSmall" onClick={onClick} />
        )}
        {!hideBtn && !quarterDisabled && !isQuarterSelected && (
          <Button
            variant="secondary"
            size="xs"
            className="max-h-tw-6 transition hover:bg-light-10"
            onClick={onClick}
          >
            All
          </Button>
        )}
      </div>
      {children}
    </div>
  );
}

export const SliderWithNavigation = ({
  children,
  ...props
}: React.ComponentProps<typeof Slider>) => {
  const slider = useRef<Slider>(null);

  // Since the slider's plugin has an initialSlider property setting issue
  // we set an initial slide using the package API
  // Issue is created https://github.com/akiran/react-slick/issues/2207
  const initSlider = useCallback((ref: Slider) => {
    ref?.slickGoTo(props.initialSlide ?? 0, true);
    slider.current = ref;
  }, []);

  return (
    <>
      <IconButton
        onClick={() => slider.current?.slickPrev()}
        iconName="arrowLeft"
      />
      <Slider ref={initSlider} {...Const.SLIDER_SETTINGS} {...props}>
        {children}
      </Slider>
      <IconButton
        onClick={() => slider.current?.slickNext()}
        iconName="arrowRight"
      />
    </>
  );
};

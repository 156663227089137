import dayjs from 'dayjs';
import { ILegalEntitiable } from 'types/ReportTableSegment';
import { ListOption } from 'stories/Checkbox/CheckList';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { IInvestmentObject } from 'types/IInvestmentObject';

const START_OF_YEAR = dayjs().startOf('year');
export const DEFAULT_FILTER_DATE_RANGE = {
  from: START_OF_YEAR,
  to: START_OF_YEAR.add(11, 'month'),
};
export const INITIAL_FILTER_STATE = {
  selectedInvestmentObjects: [],
  selectedLegalEntities: [],
  selectedSegments: [],
  showUnpublished: false,
  dateRange: {},
};

function isInvestmentObject(
  entity: IInvestmentObject,
): entity is IInvestmentObject {
  return entity.klass !== undefined;
}

export const mapToListOption = (entity): ListOption => ({
  value: isInvestmentObject(entity) ? entity.uniqId : entity.id,
  label: entity.name,
});

export const mapLEToListOption = ({
  id,
  name,
}: ILegalEntitiable): ListOption => ({ value: id, label: name });

export function findIndexByPeriod(
  possiblePeriods: IPeriodItem['period'][],
  period: IPeriodItem['period'],
) {
  return possiblePeriods.findIndex((p) => p === period);
}

export function slicePossiblePeriodsFromAndTo(
  possiblePeriods: IPeriodItem['period'][],
  idxFrom: number,
  idxTo: number,
) {
  return possiblePeriods.slice(idxFrom, idxTo + 1);
}

export function slicePossiblePeriodsTo(
  possiblePeriods: IPeriodItem['period'][],
  idxTo: number,
) {
  return possiblePeriods.slice(0, idxTo);
}

export function slicePossiblePeriodsFrom(
  possiblePeriods: IPeriodItem['period'][],
  idxTo: number,
) {
  return possiblePeriods.slice(idxTo + 1);
}

import { emptySplitApi as api } from '../../../../app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportRentRollSourceUnitTypes: build.query<
      GetApiSettingsReportRentRollSourceUnitTypesApiResponse,
      GetApiSettingsReportRentRollSourceUnitTypesApiArg
    >({
      query: (queryArg) => {
       return ({
        url: `/api/settings/report/rent_roll/source_unit_types`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          legal_entity_ids: queryArg.legalEntityIds,
          with_unit_type: queryArg.withUnitType,
          without_unit_type: queryArg.withoutUnitType,
          with_legal_entity_without_class: queryArg.withLegalEntityWithoutClass,
          query: queryArg.query,
          group: queryArg.group,
          sort: queryArg.sort,
          sre_unit_type_ids: queryArg.sreUnitTypeIds.map((item) => item.id),
          erps: queryArg.erps.map((item) => item.id),
          pmc_ids: queryArg.pmcIds.map((item) => item.id),
        },
      })},
    }),
    putApiSettingsReportRentRollSourceUnitTypesSetUnitType: build.mutation<
      PutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeApiResponse,
      PutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_unit_types/set_unit_type`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportRentRollSourceUnitTypesExportCsv: build.query<
      GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiResponse,
      GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_unit_types/export.csv`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          with_unit_type: queryArg.withUnitType,
          without_unit_type: queryArg.withoutUnitType,
          with_legal_entity_without_class: queryArg.withLegalEntityWithoutClass,
          query: queryArg.query,
          group: queryArg.group,
          sort: queryArg.sort,
        },
      }),
    }),
    postApiSettingsReportRentRollSourceUnitTypesImport: build.mutation<
      PostApiSettingsReportRentRollSourceUnitTypesImportApiResponse,
      PostApiSettingsReportRentRollSourceUnitTypesImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_unit_types/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportRentRollUnitTypes: build.query<
      GetApiSettingsReportRentRollUnitTypesApiResponse,
      GetApiSettingsReportRentRollUnitTypesApiArg
    >({
      query: () => ({ url: `/api/settings/report/rent_roll/unit_types` }),
    }),
    postApiSettingsReportRentRollUnitTypes: build.mutation<
      PostApiSettingsReportRentRollUnitTypesApiResponse,
      PostApiSettingsReportRentRollUnitTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/unit_types`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportRentRollUnitTypesById: build.mutation<
      PutApiSettingsReportRentRollUnitTypesByIdApiResponse,
      PutApiSettingsReportRentRollUnitTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/unit_types/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportRentRollUnitTypesById: build.mutation<
      DeleteApiSettingsReportRentRollUnitTypesByIdApiResponse,
      DeleteApiSettingsReportRentRollUnitTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/unit_types/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportOperationalUnitTypesApi };
export type GetApiSettingsReportRentRollSourceUnitTypesApiResponse =
  /** status 200 success */ {
    items: ReportRentRollSourceUnitTypeDto[];
    meta: PaginationDto & {
      withoutUnitTypeSize: number;
      withUnitTypeSize: number;
      usedSreUnitTypesSize: number;
      legalEntities: {
        id: string;
        name: string;
        code: string;
      }[];
    };
  };
export type GetApiSettingsReportRentRollSourceUnitTypesApiArg = {
  page?: number;
  perPage?: number;
  legalEntityIds?: string[];
  withUnitType?: boolean;
  withoutUnitType?: boolean;
  withLegalEntityWithoutClass?: boolean;
  query?: string;
  group?: ('erp' | 'pmc' | 'le')[];
  sort?: {
    field?: 'legal_entity';
    order?: 'asc' | 'desc';
  };
};
export type PutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeApiResponse =
  unknown;
export type PutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeApiArg = {
  body: {
    source_unit_type_ids: string[];
    unit_type_id: number | null;
  };
};
export type GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiResponse =
  unknown;
export type GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg = {
  legalEntityIds?: string[];
  withUnitType?: boolean;
  withoutUnitType?: boolean;
  withLegalEntityWithoutClass?: boolean;
  query?: string;
  group?: ('erp' | 'pmc' | 'le')[];
  sort?: {
    field?: 'legal_entity';
    order?: 'asc' | 'desc';
  };
};
export type PostApiSettingsReportRentRollSourceUnitTypesImportApiResponse =
  unknown;
export type PostApiSettingsReportRentRollSourceUnitTypesImportApiArg = {
  body: string;
};
export type GetApiSettingsReportRentRollUnitTypesApiResponse =
  /** status 200 success */ {
    items: {
      id: number;
      name: string;
      label: string;
      lastMappedSourceUnitTypeId: number | null;
    }[];
  };
export type GetApiSettingsReportRentRollUnitTypesApiArg = void;
export type PostApiSettingsReportRentRollUnitTypesApiResponse = unknown;
export type PostApiSettingsReportRentRollUnitTypesApiArg = {
  body: {
    name?: string;
    label?: string;
  };
};
export type PutApiSettingsReportRentRollUnitTypesByIdApiResponse = unknown;
export type PutApiSettingsReportRentRollUnitTypesByIdApiArg = {
  id: number;
  body: {
    name?: string;
    label?: string;
  };
};
export type DeleteApiSettingsReportRentRollUnitTypesByIdApiResponse = unknown;
export type DeleteApiSettingsReportRentRollUnitTypesByIdApiArg = {
  id: number;
};
export type ReportRentRollSourceUnitTypeLegalEntityDto = {
  id: string;
  name: string;
  classification: string | null;
  code: string;
  asset: {
    id: number;
    name: string;
  } | null;
};
export type ReportRentRollSourceUnitTypeDto = {
  id: string;
  name: string;
  legalEntity: ReportRentRollSourceUnitTypeLegalEntityDto;
  erpSystem: string;
  unitType: {
    id: number;
    name: string;
  } | null;
  propertyManagementCompany: {
    id: number;
    name: string;
  };
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};

import { emptySplitApi as api } from '../../../../app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    putApiSettingsReportGeneralLedgersSetCategory: build.mutation<
      PutApiSettingsReportGeneralLedgersSetCategoryApiResponse,
      PutApiSettingsReportGeneralLedgersSetCategoryApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers/set_category',
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportGeneralLedgersIncludeAll: build.mutation<
      PutApiSettingsReportGeneralLedgersIncludeAllApiResponse,
      PutApiSettingsReportGeneralLedgersIncludeAllApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers/include_all',
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportGeneralLedgersExcludeAll: build.mutation<
      PutApiSettingsReportGeneralLedgersExcludeAllApiResponse,
      PutApiSettingsReportGeneralLedgersExcludeAllApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers/exclude_all',
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportGeneralLedgersQuickMapping: build.mutation<
      PutApiSettingsReportGeneralLedgersQuickMappingApiResponse,
      PutApiSettingsReportGeneralLedgersQuickMappingApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers/quick_mapping',
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportGeneralLedgersFlag: build.mutation<
      PutApiSettingsReportGeneralLedgersFlagApiResponse,
      PutApiSettingsReportGeneralLedgersFlagApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers/flag',
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportGeneralLedgersUnflag: build.mutation<
      PutApiSettingsReportGeneralLedgersUnflagApiResponse,
      PutApiSettingsReportGeneralLedgersUnflagApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers/unflag',
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportGeneralLedgers: build.query<
      GetApiSettingsReportGeneralLedgersApiResponse,
      GetApiSettingsReportGeneralLedgersApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers',
        params: {
          flagged: queryArg.flagged,
          misclassified: queryArg.misclassified,
          scope: queryArg.scope,
          report_type: queryArg.reportType,
          balance_type: queryArg.balanceType,
          legal_entity_ids: queryArg.legalEntityIds,
          sources: queryArg.sources,
          query: queryArg.query,
          group: queryArg.group,
          sort: queryArg.sort,
          page: queryArg.page,
          per_page: queryArg.perPage,
        },
      }),
    }),
    getApiSettingsReportGeneralLedgersMeta: build.query<
      GetApiSettingsReportGeneralLedgersMetaApiResponse,
      GetApiSettingsReportGeneralLedgersMetaApiArg
    >({
      query: () => ({ url: '/api/settings/report/general_ledgers/meta' }),
    }),
    getApiSettingsReportGeneralLedgersQuickMapSuggestions: build.query<
      GetApiSettingsReportGeneralLedgersQuickMapSuggestionsApiResponse,
      GetApiSettingsReportGeneralLedgersQuickMapSuggestionsApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/report/general_ledgers/quick_map_suggestions',
        params: { page: queryArg.page, per_page: queryArg.perPage },
      }),
    }),
    postApiSettingsReportGeneralLedgersExport: build.mutation<
      PostApiSettingsReportGeneralLedgersExportApiResponse,
      PostApiSettingsReportGeneralLedgersExportApiArg
    >({
      query: () => ({
        url: '/api/settings/report/general_ledgers/export',
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportGeneralLedgersApi };
export type PutApiSettingsReportGeneralLedgersSetCategoryApiResponse = unknown;
export type PutApiSettingsReportGeneralLedgersSetCategoryApiArg = {
  body: {
    ids: number[];
    report_financial_category_id: number | null;
  };
};
export type PutApiSettingsReportGeneralLedgersIncludeAllApiResponse = unknown;
export type PutApiSettingsReportGeneralLedgersIncludeAllApiArg = {
  body: {
    ids: number[];
  };
};
export type PutApiSettingsReportGeneralLedgersExcludeAllApiResponse = unknown;
export type PutApiSettingsReportGeneralLedgersExcludeAllApiArg = {
  body: {
    ids: number[];
  };
};
export type PutApiSettingsReportGeneralLedgersQuickMappingApiResponse = unknown;
export type PutApiSettingsReportGeneralLedgersQuickMappingApiArg = {
  body: {
    mappings: (
      | {
          id: number;
          report_financial_category_id: number;
        }
      | {
          id: number;
          ignored: boolean;
        }
    )[];
  };
};
export type PutApiSettingsReportGeneralLedgersFlagApiResponse = unknown;
export type PutApiSettingsReportGeneralLedgersFlagApiArg = {
  body: {
    ids: number[];
  };
};
export type PutApiSettingsReportGeneralLedgersUnflagApiResponse = unknown;
export type PutApiSettingsReportGeneralLedgersUnflagApiArg = {
  body: {
    ids: number[];
  };
};
export type GetApiSettingsReportGeneralLedgersApiResponse =
  /** status 200 success */ {
    items: ReportGeneralLedger[];
    meta: Pagination & {
      flaggedSize: number;
      misclassifiedSize: number;
    };
  };
export type GetApiSettingsReportGeneralLedgersApiArg = {
  flagged?: boolean;
  misclassified?: boolean;
  scope?:
    | 'active-and-mapped'
    | 'active-and-not-mapped'
    | 'excluded'
    | 'inactive';
  reportType?: 'income' | 'balance';
  balanceType?: 'debit' | 'credit';
  legalEntityIds?: {
    ''?: string[];
  };
  sources?: {
    ''?: (
      | 'quickbooks'
      | 'quickbooks_online'
      | 'yardi'
      | 'entrata'
      | 'm3'
      | 'mri'
      | 'realpage'
      | 'resman'
      | 'cyma'
      | 'rentegi'
      | 'sage'
      | 'net_suite'
      | 'symmetre'
    )[];
  };
  query?: string;
  group?: {
    ''?: ('acc_title' | 'acc_number' | 'category')[];
  };
  sort?: {
    field?:
      | 'id'
      | 'acc_title'
      | 'acc_number'
      | 'line_items_count'
      | 'legal_entity_id'
      | 'source';
    order?: 'asc' | 'desc';
  };
  page?: number;
  perPage?: number;
};
export type GetApiSettingsReportGeneralLedgersMetaApiResponse =
  /** status 200 success */ {
    mappedSize: number;
    mappedCategoriesSize: number;
    notMappedSize: number;
    inactiveSize: number;
    excludedSize: number;
    legalEntities?: {
      id: string;
      code: string;
      name: string;
      excluded: boolean;
      source: string;
    }[];
    financialCategorySync?: ReportGeneralLedgersFinancialCategorySync;
    sources: string[];
  };
export type GetApiSettingsReportGeneralLedgersMetaApiArg = void;
export type GetApiSettingsReportGeneralLedgersQuickMapSuggestionsApiResponse =
  /** status 200 success */ {
    items: {
      id: number;
      accNumber: string;
      accTitle: string;
      lineItemsCount: number | null;
      legalEntity: {
        id?: string;
        code?: string;
        name?: string;
        excluded?: boolean;
        source?: string;
      };
      suggestedReportFinancialCategories: {
        id: number;
        code: string;
      }[];
    }[];
    meta: Pagination;
  };
export type GetApiSettingsReportGeneralLedgersQuickMapSuggestionsApiArg = {
  page: number;
  perPage?: number;
};
export type PostApiSettingsReportGeneralLedgersExportApiResponse = unknown;
export type PostApiSettingsReportGeneralLedgersExportApiArg = void;
export type ReportGeneralLedger = {
  id: number;
  accNumber: string;
  accTitle: string;
  excluded: boolean;
  legalEntity: {
    id: string;
    code: string;
    excluded: boolean;
    name: string;
    source:
      | 'quickbooks_desktop'
      | 'quickbooks_online'
      | 'yardi'
      | 'entrata'
      | 'm3'
      | 'mri'
      | 'realpage'
      | 'resman'
      | 'cyma'
      | 'symmetre';
    classification:
      | (
          | 'multifamily'
          | 'retail'
          | 'hospitality'
          | 'office'
          | 'senior_living'
          | 'student'
          | 'mixed_use'
          | 'spv'
          | 'op_co'
          | 'commercial'
        )
      | null;
  };
  lineItemsCount: number | null;
  reportFinancialCategoryId: number | null;
  flagged: boolean;
  misclassified: boolean;
};
export type Pagination = {
  perPage: number;
  totalSize: number;
};
export type ReportGeneralLedgersFinancialCategorySync = {
  id: string;
  status: 'in_progress' | 'completed' | 'failed';
  memos: string[];
  syncedAt: number | null;
  createdAt: number;
  initiator: {
    id: string;
    name: string;
  };
};

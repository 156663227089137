import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import { stringify } from 'qs';
import { ILegalEntitiable } from 'types/LegalEntitiable';
import { IPermissions } from 'types/Permissions';

export interface FeedType {
  id: number;
  active: boolean;
  color: string;
  internal: null;
  title: string;
}

export interface FeedMessagesData {
  items: FeedMessage[];
  meta: {
    totalSize: number;
    replyRecipients: unknown[];
  };
}

export interface FeedMessage {
  id: number;
  createdAt: number;
  defaultSubject: string;
  documents: Document[];
  feedType: FeedType;
  text: string;
  user: unknown;
  feedMessageable: {
    id: ILegalEntitiable['id'];
    name: string;
    type: 'asset' | 'fund';
    slug: string;
  };
  emailMessages: unknown[];
  media: unknown[];
  permitted?: IPermissions;
}

export interface Document {
  id: string;
  confidential: boolean;
  filename: string;
  name: string;
  size: number;
  title: string;
  url: string;
  documentType: unknown;
  extension: unknown;
  permitted: unknown;
}

export const feedMessagesApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
    paramsSerializer: (params) =>
      stringify(params, {
        arrayFormat: 'brackets',
      }),
  }),
  reducerPath: 'feedMessages',
  tagTypes: ['feedMessageTypes', 'feedMessages'],
  endpoints: (build) => ({
    getFeedTypes: build.query<
      FeedType[],
      {
        feed_messageable_id: ILegalEntitiable['id'];
        feed_messageable_type: 'Asset' | 'Fund';
      }
    >({
      query: (params) => ({
        url: '/feed_types',
        params,
      }),
      providesTags: ['feedMessageTypes'],
    }),
    getFeedMessage: build.query<
      FeedMessage,
      {
        id: number;
      }
    >({
      query: (params) => ({
        url: `/feed_messages/${params.id}`,
      }),
    }),
    getFeedMessages: build.query<
      FeedMessagesData,
      {
        feed_messageable_id: ILegalEntitiable['id'];
        feed_messageable_type: 'Asset' | 'Fund';
        feed_type_ids: FeedType['id'][];
        page: number;
      }
    >({
      query: (params) => ({
        url: 'feed_messages',
        params,
      }),
      providesTags: ['feedMessages'],
    }),

    createFeedMessage: build.mutation({
      invalidatesTags: ['feedMessages', 'feedMessageTypes'],
      query: (data) => ({
        url: '/feed_messages',
        method: 'POST',
        body: data,
      }),
    }),

    updateFeedMessage: build.mutation<
      unknown,
      {
        id: number;
      }
    >({
      invalidatesTags: ['feedMessages', 'feedMessageTypes'],
      query: (data) => ({
        url: `/feed_messages/${data.id}`,
        method: 'PUT',
        body: data,
      }),
    }),

    deleteFeedMessage: build.mutation<
      unknown,
      {
        id: number;
      }
    >({
      invalidatesTags: ['feedMessages', 'feedMessageTypes'],
      query: (data) => ({
        url: `/feed_messages/${data.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetFeedTypesQuery,
  useGetFeedMessagesQuery,
  useGetFeedMessageQuery,
  useUpdateFeedMessageMutation,
  useCreateFeedMessageMutation,
  useDeleteFeedMessageMutation,
} = feedMessagesApi;

import { cn } from '@/shared/lib/css/cn';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekDay from 'dayjs/plugin/weekday';
import { toOrdinal } from 'number-to-words';
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getCellKey,
  getCurrentStartOfMonthByValue,
  getDatesArrayForCurrentMonth,
  getRowIndexFromCellKey,
  getRowKey,
} from 'stories/FlexibleFilterByPeriods/calendar/utils';
import {
  DAYS_IN_WEEK,
  EMPTY_WEEK_LENGTH_ARRAY,
  generatePrevPeriods,
  INFINITE_PERIODS,
} from 'stories/FlexibleFilterByPeriods/consts';

import { DATE_STRING_FORMAT } from '@/shared/lib/converters';
import { formatDate } from '@/shared/lib/formatting/dates';
import customLocale from 'dayjs/locale/en';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isEmpty } from 'lodash-es';
import { Button, FlexibleFilterByPeriods, Icon, Popover } from 'stories';
import { CalendarCell } from 'stories/FlexibleFilterByPeriods/calendar/CalendarCell';
import CalendarPagination from 'stories/FlexibleFilterByPeriods/calendar/CalendarPagination';
import {
  DEFAULT_DROPDOWN_OFFSET,
  PopoverProps,
  PopoverRef,
} from 'stories/Popover/Popover';
import { ClassNameProps } from 'types/Props';
import styles from './Calendar.module.scss';

customLocale.weekStart = 1;
dayjs.locale(customLocale);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(weekDay);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);

const Th = ({
  children,
  className,
}: React.PropsWithChildren<ClassNameProps>) => (
  <th
    className={cn(
      'secondary-regular bg-white px-tw-2 py-tw-1 text-center text-light-90',
      className,
    )}
  >
    {children}
  </th>
);

interface Props {
  selectionMode?: 'weekly' | 'daily';
  value?: Dayjs | Dayjs[] | null;
  defaultValue?: Dayjs | Dayjs[] | null;
  onChange?: (value: Dayjs[]) => void;
  isDayDisabled?: (day: Dayjs) => boolean;
  allowFutureDates?: boolean;
  popoverProps?: Omit<PopoverProps, 'template'>;
  buttonProps?: React.ComponentProps<typeof Button>;
  closeOnDateUpdate?: boolean;
}

function DateCell({
  children,
  hovered,
  disabled,
  selected,
  inRange,
  inHoveredRange,
  onClick,
  noBottomBorder,
  notAllowedToSelect,
  ...props
}: {
  hovered: boolean;
  inHoveredRange: boolean;
  disabled: boolean;
  selected: boolean;
  inRange: boolean;
  noBottomBorder?: boolean;
  notAllowedToSelect?: boolean;
} & PropsWithChildren &
  Pick<
    JSX.IntrinsicElements['td'],
    'onClick' | 'onMouseEnter' | 'onMouseLeave'
  >) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <td
      className={cn(
        'secondary-regular border-r border-solid border-neutral-150 px-tw-2 py-tw-1 text-center transition-colors last:border-r-0',
        !noBottomBorder && 'border-b',
        !disabled && 'cursor-pointer',
        selected && 'bg-info-055 text-tw-white hover:bg-info-060',
        inRange && 'bg-info-090 text-info-055 hover:bg-info-100',
        inHoveredRange && 'bg-info-000 text-info-055',
        hovered && !disabled && 'bg-info-020 text-info-055',
        disabled && 'bg-neutral-050 text-neutral-500',
        ((inRange && disabled) || (disabled && selected)) &&
        'bg-info-080 text-info-040',
        (disabled || notAllowedToSelect) && 'cursor-not-allowed',
      )}
      onClick={(e) => {
        if (disabled || notAllowedToSelect) return;
        onClick?.(e);
      }}
      {...props}
    >
      {children}
    </td>
  );
}

type Indexes = { rowIndex: number; cellIndex: number };

function checkIfDateIsAfterToday(date: Dayjs) {
  return date.isAfter(dayjs());
}

function isSameDay(dateA: Dayjs | null, dateB: Dayjs | null): boolean {
  return dateA?.isSame(dateB, 'day') ?? false;
}

// todo merge with Calendar
export function RangeDatePicker({
  dayjsPeriods,
  onChange,
  allowInfinitePeriods = false,
  disableHiddingAfterSelecting = false,
  defaultPeriods = [],
}: {
  dayjsPeriods: Dayjs[];
  onChange: (periods: Dayjs[]) => void;
  allowInfinitePeriods?: boolean;
  disableHiddingAfterSelecting?: boolean;
  defaultPeriods?: Dayjs[];
}) {
  const popoverRef = useRef<PopoverRef>(null);
  const [startOfRange, setStartOfRange] = useState<Dayjs | null>(null);
  const [hoveredOverPeriod, setHoveredOverPeriod] = useState<Dayjs | null>(
    null,
  );

  const [currentStartOfMonth, setCurrentStartOfMonth] = useState<Dayjs>(
    getCurrentStartOfMonthByValue(dayjs()),
  );

  const dates = getDatesArrayForCurrentMonth(currentStartOfMonth);

  const rowsCount = Math.floor(dates.length / DAYS_IN_WEEK);

  const getDateByIndexes = ({ rowIndex, cellIndex }: Indexes) =>
    dates[rowIndex * DAYS_IN_WEEK + cellIndex];

  const handleCellClick = ({ rowIndex, cellIndex }: Indexes) => {
    const date = getDateByIndexes({ rowIndex, cellIndex });

    if (startOfRange === null) {
      setStartOfRange(date);
      return;
    }
    setStartOfRange(null);

    onChange(
      startOfRange.isAfter(date) ? [date, startOfRange] : [startOfRange, date],
    );

    if (disableHiddingAfterSelecting) return;
    popoverRef.current?.hide();
  };

  const periodsOfCurrentMonth = useMemo(() => {
    let endOfMonth = currentStartOfMonth.endOf('month').startOf('day');
    while (endOfMonth.isAfter(dayjs())) {
      endOfMonth = endOfMonth.subtract(1, 'day');
    }
    return [currentStartOfMonth.startOf('month').startOf('day'), endOfMonth];
  }, [currentStartOfMonth]);

  useEffect(() => {
    if (startOfRange == null) return;
    setStartOfRange(null);
  }, [dayjsPeriods]);

  const getCellProps = ({
    rowIndex,
    cellIndex,
    cellDate,
  }: Indexes & {
    cellDate: Dayjs;
  }): React.ComponentProps<typeof DateCell> => ({
    disabled:
      !cellDate.isSame(currentStartOfMonth, 'month') ||
      (!allowInfinitePeriods && checkIfDateIsAfterToday(cellDate)),
    hovered: isSameDay(hoveredOverPeriod, cellDate),
    inRange:
      startOfRange == null &&
      dayjsPeriods.length === 2 &&
      cellDate.isBetween(dayjsPeriods[0], dayjsPeriods[1]),
    inHoveredRange:
      hoveredOverPeriod !== null &&
      startOfRange !== null &&
      cellDate.isBetween(startOfRange, hoveredOverPeriod),
    selected:
      isSameDay(startOfRange, cellDate) ||
      (!startOfRange && dayjsPeriods.some((d) => isSameDay(d, cellDate))),
    notAllowedToSelect: isSameDay(startOfRange, cellDate),
    onClick: () =>
      handleCellClick({
        rowIndex,
        cellIndex,
      }),
  });

  const monthSelector = useMemo(
    () => (
      <FlexibleFilterByPeriods
        popoverProps={{
          appendToBody: false,
        }}
        filterByPeriodsType="mtd"
        isSingleSelection
        periodItems={[
          {
            period: currentStartOfMonth.format(DATE_STRING_FORMAT),
            type: 'mtd',
          },
        ]}
        updatedStyles
        buttonProps={{
          size: 's',
        }}
        possiblePeriods={
          allowInfinitePeriods ? INFINITE_PERIODS : generatePrevPeriods()
        }
        onUpdatePeriodItems={(items) =>
          setCurrentStartOfMonth(dayjs(items[0].period))
        }
      />
    ),
    [currentStartOfMonth],
  );

  return (
    <Popover
      ref={popoverRef}
      placement="bottom-end"
      hiddenArrow
      className="!rounded-b-2xl !rounded-t-lg p-0"
      trigger="click"
      offset={DEFAULT_DROPDOWN_OFFSET}
      maxWidth="max-content"
      template={
        <div className="flex w-max flex-col gap-tw-4 rounded-b-[16px] rounded-t-[8px] bg-white p-tw-2 pt-tw-4">
          <div className="flex items-center justify-between px-tw-2">
            {monthSelector}
            <CalendarPagination
              onCurrent={() => setCurrentStartOfMonth(dayjs().startOf('month'))}
              onBack={() =>
                setCurrentStartOfMonth((prev) => prev.subtract(1, 'month'))
              }
              onNext={() =>
                setCurrentStartOfMonth((prev) => prev.add(1, 'month'))
              }
              disableOnNext={currentStartOfMonth.isSame(dayjs(), 'month')}
            />
          </div>
          <div className="flex overflow-hidden rounded-[8px] border-[1px] border-solid border-neutral-250">
            <table className={cn('w-[370px]')}>
              <thead>
                <tr>
                  {dayjs.weekdaysShort(true).map((weekday) => (
                    <th
                      className="secondary-regular h-[24px] w-[calc(100%/7)] border-b border-r border-solid border-neutral-250 bg-white px-tw-2 py-tw-1 text-center text-light-90 last:border-r-0"
                      key={`th-${weekday}`}
                    >
                      {weekday}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: rowsCount }).map((_, rowIndex, arr) => (
                  <tr key={getRowKey(rowIndex)}>
                    {EMPTY_WEEK_LENGTH_ARRAY.map((__, cellIndex) => {
                      const lastRowIndex = arr.length - 1 === rowIndex;

                      const cellDate = getDateByIndexes({
                        rowIndex,
                        cellIndex,
                      });
                      return (
                        <DateCell
                          {...getCellProps({ rowIndex, cellIndex, cellDate })}
                          key={getCellKey(rowIndex, cellIndex)}
                          onMouseEnter={() => {
                            if (!startOfRange) return;
                            setHoveredOverPeriod(cellDate);
                          }}
                          onMouseLeave={() => {
                            setHoveredOverPeriod(null);
                          }}
                          noBottomBorder={lastRowIndex}
                        >
                          {cellDate.date()}
                        </DateCell>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between">
            {defaultPeriods.length > 0 && (
              <Button
                variant="secondary"
                size="xs"
                onClick={() => {
                  onChange(defaultPeriods);
                  popoverRef.current?.hide();
                }}
              >
                Back to Default
              </Button>
            )}
            <Button
              variant="secondary"
              size="xs"
              onClick={() => {
                onChange(periodsOfCurrentMonth);
                popoverRef.current?.hide();
              }}
            >
              Select All
            </Button>
          </div>
        </div>
      }
    >
      <Button
        variant="secondary"
        size="s"
        iconName="arrowBottom"
        iconPosition="right"
      >
        <span className="text-neutral-500">From</span>
        <span>{dayjsPeriods[0]?.format('MMM-DD')}</span>
        <span className="text-neutral-500">to</span>
        <span>{dayjsPeriods[1]?.format('MMM-DD')}</span>
      </Button>
    </Popover>
  );
}

const Calendar = forwardRef<PopoverRef, Props>((props, forwardedRef) => {
  const internalRef = useRef<PopoverRef>(null);
  const popoverRef = (forwardedRef ??
    internalRef) as React.RefObject<PopoverRef>;
  const {
    defaultValue,
    selectionMode,
    allowFutureDates,
    popoverProps,
    buttonProps,
  } = props;
  const [internalValue, setInternalValue] = useState(defaultValue);
  const value = props.value === undefined ? internalValue : props.value;
  const [currentStartOfMonth, setCurrentStartOfMonth] = useState<Dayjs>(
    getCurrentStartOfMonthByValue(value ?? dayjs()),
  );
  const [hoveredCell, setHoveredCell] = useState<string | null>();

  const dates = useMemo(
    () => getDatesArrayForCurrentMonth(currentStartOfMonth),
    [currentStartOfMonth],
  );

  useEffect(() => {
    setCurrentStartOfMonth(getCurrentStartOfMonthByValue(value ?? dayjs()));
  }, [props.value]);

  const rowsCount = Math.floor(dates.length / DAYS_IN_WEEK);

  const onChange: Props['onChange'] = (newValue) => {
    if (props.value === undefined) {
      setInternalValue(newValue);
    }

    props.onChange?.(newValue);
  };

  const getDateByIndexes = ({ rowIndex, cellIndex }: Indexes): Dayjs =>
    dates[rowIndex * DAYS_IN_WEEK + cellIndex];

  const handleCellClick = ({ rowIndex, cellIndex }: Indexes) => {
    const date = getDateByIndexes({ rowIndex, cellIndex });
    onChange(
      selectionMode === 'weekly'
        ? [date.startOf('week'), date.endOf('week')]
        : [date],
    );

    if (props.closeOnDateUpdate) {
      popoverRef.current?.hide();
    }
  };

  const getCellSelected = ({ rowIndex, cellIndex }: Indexes) => {
    if (!value) {
      return false;
    }
    const date = getDateByIndexes({ rowIndex, cellIndex });
    if (selectionMode === 'weekly') {
      const [firstDayOfWeek] = value as unknown as Dayjs[];
      return firstDayOfWeek.isSame(date, 'week');
    }
    return date.isSame(value as Dayjs, 'day');
  };

  const getCellProps = ({
    rowIndex,
    cellIndex,
  }: Indexes): React.ComponentProps<typeof CalendarCell> => {
    const cellDate = getDateByIndexes({
      rowIndex,
      cellIndex,
    });
    const hovered =
      !isEmpty(hoveredCell) &&
      (selectionMode === 'weekly'
        ? getRowIndexFromCellKey(hoveredCell!) === rowIndex
        : hoveredCell === getCellKey(rowIndex, cellIndex));
    const disabled =
      (!allowFutureDates && checkIfDateIsAfterToday(cellDate)) ||
      props.isDayDisabled?.(cellDate);

    return {
      disabled,
      hovered,
      fromCurrentMonth: cellDate.isSame(currentStartOfMonth, 'month'),
      selected: getCellSelected({ rowIndex, cellIndex }),
      onClick: () =>
        handleCellClick({
          rowIndex,
          cellIndex,
        }),
    };
  };

  const monthSelector = useMemo(
    () => (
      <FlexibleFilterByPeriods
        popoverProps={{
          placement: 'bottom-start',
          appendTo: 'parent',
        }}
        filterByPeriodsType="mtd"
        closeOnMonthUpdate
        isSingleSelection
        periodItems={[
          {
            period: currentStartOfMonth.format(DATE_STRING_FORMAT),
            type: 'mtd',
          },
        ]}
        possiblePeriods={
          allowFutureDates ? INFINITE_PERIODS : generatePrevPeriods()
        }
        onUpdatePeriodItems={(items) =>
          setCurrentStartOfMonth(dayjs(items[0].period))
        }
      />
    ),
    [currentStartOfMonth],
  );

  const template = (
    <div className="flex w-max flex-col gap-tw-4 bg-white p-tw-2 pt-tw-4">
      <div className="flex justify-between px-tw-2">
        {monthSelector}
        <CalendarPagination
          disableOnNext={
            !allowFutureDates && currentStartOfMonth.isSame(dayjs(), 'month')
          }
          onCurrent={() => setCurrentStartOfMonth(dayjs().startOf('month'))}
          onBack={() =>
            setCurrentStartOfMonth((prev) => prev.subtract(1, 'month'))
          }
          onNext={() => setCurrentStartOfMonth((prev) => prev.add(1, 'month'))}
        />
      </div>
      <table
        className={cn(
          'w-[370px]',
          styles.roundedCorners,
          selectionMode === 'weekly' && styles.roundedCorners_weekly,
        )}
      >
        <thead>
          <tr>
            {selectionMode === 'weekly' && <Th>Week</Th>}
            {dayjs.weekdaysShort(true).map((weekday) => (
              <Th className="w-[calc(100%/7)]" key={`th-${weekday}`}>
                {weekday}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rowsCount }).map((_, rowIndex) => (
            <tr key={getRowKey(rowIndex)}>
              {selectionMode === 'weekly' && (
                <CalendarCell
                  {...getCellProps({ rowIndex, cellIndex: 0 })}
                  active
                  isRangeEdge
                  fromCurrentMonth
                >
                  {currentStartOfMonth.add(rowIndex, 'week').week()}
                </CalendarCell>
              )}
              {EMPTY_WEEK_LENGTH_ARRAY.map((__, cellIndex) => {
                const cellDate = getDateByIndexes({
                  rowIndex,
                  cellIndex,
                });
                const cellKey = getCellKey(rowIndex, cellIndex);
                return (
                  <CalendarCell
                    {...getCellProps({ rowIndex, cellIndex })}
                    key={cellKey}
                    onMouseEnter={() => setHoveredCell(cellKey)}
                    onMouseLeave={() => setHoveredCell(null)}
                  >
                    {cellDate.date()}
                  </CalendarCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const getLabel = () => {
    if (!value) {
      return selectionMode === 'weekly' ? 'Select Week' : 'Select Day';
    }
    if (selectionMode === 'daily') {
      const [day] = value as Dayjs[];
      return formatDate(day, 'MMM-DD');
    }

    const [startOfWeek, endOfWeek] = value as Dayjs[];
    return `${toOrdinal(startOfWeek.date())}-${toOrdinal(
      endOfWeek.date(),
    )} ${startOfWeek.format('MMMM')}`;
  };

  return (
    <Popover
      ref={popoverRef}
      placement="bottom-start"
      hiddenArrow
      className="overflow-hidden !rounded-b-2xl !rounded-t-lg p-0"
      trigger="click"
      offset={DEFAULT_DROPDOWN_OFFSET}
      template={template}
      maxWidth="max-content"
      {...popoverProps}
    >
      {popoverProps?.children ?? (
        <Button
          variant="secondary"
          size="s"
          iconName="bottom"
          iconPosition="right"
          className="font-medium leading-none text-info-055"
          {...buttonProps}
        >
          <Icon iconName="calendar" className="text-neutral-450" />
          {getLabel()}
        </Button>
      )}
    </Popover>
  );
});

export default Calendar;

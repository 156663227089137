import {
  useGetApiReconcileOperationalBudgetsYearsQuery,
  usePostApiReconcileOperationalBudgetsBulkMutation,
} from '@/bundles/REconcile/components/operational/api/reconcileOperationalEnhancedApi';
import { GetApiReconcileOperationalBudgetsYearsApiArg } from '@/bundles/REconcile/components/operational/api/reconcileOperationalGeneratedApi';
import SortButton from '@/bundles/Shared/components/Filters/buttons/sortButton/SortButton';
import { ShownInfoLine } from '@/bundles/Shared/components/Table/pagination/TablePagination';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useSortSearchParams } from '@/shared/lib/hooks/navigation/useSortSearchParams';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { TSort } from '@/types/Sort';
import { RouteComponentProps } from '@reach/router';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { FC } from 'react';
import { Button } from 'stories/index';
import CreateBudgetsForFutureYearsModal from '../modals/CreateBudgetsForFutureYearsModal';
import ReconcileOperational from './ReconcileOperational';
import { budgetYearValidator } from './utils';

export function prepareUrl(yearId: string) {
  return generateUrl(ROUTES_ROOT.reconcile.operational.fullPath, {
    pathParams: {},
    queryParams: {
      yearId,
    },
  });
}

export const ReconcileOperationalDashboard: FC<RouteComponentProps> = () => {
  const { openModal } = useModal();

  const [searchParams] = useSearchParams();
  const yearId = searchParams.get('yearId') ?? undefined;

  const { onSortToggle, sortField, sortOrder } = useSortSearchParams({
    defaultSortField: 'year',
    defaultSortOrder: TSort.DESC,
  });

  const { data, isFetching: isBudgetsLoading } =
    useGetApiReconcileOperationalBudgetsYearsQuery({
      sort: {
        field: sortField as NonNullable<
          GetApiReconcileOperationalBudgetsYearsApiArg['sort']
        >['field'],
        order: sortOrder,
      },
    });

  const [createBudgetsBulk] =
    usePostApiReconcileOperationalBudgetsBulkMutation();

  const budgets = data?.items ?? [];
  const legalEntities = data?.meta?.legalEntities ?? [];
  const assets = data?.meta?.assets ?? [];
  const currentBudget = budgets.find(({ year }) => String(year) === yearId);

  const handleCreateBudget = async () => {
    const years = budgets.map((b) => b.year);

    const res = await openModal(CreateBudgetsForFutureYearsModal, {
      legalEntities,
      years,
      selectProps: {
        notFoundFlow: {
          selectButtonLabel: 'Create a new year',
          searchValueValidator: (searchText) =>
            budgetYearValidator(searchText, years),
          textResolver: (searchText) => {
            if (years.includes(Number(searchText))) {
              return 'Year already exists';
            }
            return null;
          },
        },
      },
    });

    if (!res) return;

    await createBudgetsBulk({
      ...res,
      body: {
        items: res.legalEntities.map((i) => ({
          legal_entity_id: i.id,
          year: res.year,
        })),
      },
    });
  };

  return (
    <ListNavLayout>
      <ListNavLayout.Navigation>
        <ListNavLayout.NavigationHeaderGroup className="flex-row items-center justify-between">
          <ListNavLayout.NavigationHeader
            subtitle="REconcile"
            title="Operational"
          />
          <Button
            disabled={isBudgetsLoading}
            onClick={handleCreateBudget}
            variant="primary"
          >
            Create Budget
          </Button>
        </ListNavLayout.NavigationHeaderGroup>

        <div className="flex items-center justify-between">
          <ShownInfoLine totalSize={budgets.length} />
          <SortButton onClick={onSortToggle} sort={sortOrder} />
        </div>
        <ReconcileOperational.CardList
          budgets={budgets}
          isLoading={isBudgetsLoading}
          yearIdFromQuery={yearId}
        />
      </ListNavLayout.Navigation>
      <ListNavLayout.Content>
        {currentBudget && (
          <ReconcileOperational.Content
            assets={assets}
            legalEntities={legalEntities}
            currentBudget={currentBudget}
          />
        )}
      </ListNavLayout.Content>
    </ListNavLayout>
  );
};

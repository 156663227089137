export const getFailedEmailMsgs = (emailMessages) => {
  const filteredMsgs = emailMessages.filter(
    ({ events }) => events.length > 0 && events.at(-1).kind === 'failed',
  );
  return filteredMsgs;
};

export const filterUnsubscribedMessages = (deliveredEmails) =>
  deliveredEmails.filter((em) =>
    em.events.some((e) => e.kind === 'unsubscribed'),
  );

export const filterComplainedMessages = (deliveredEmails) =>
  deliveredEmails.filter((em) =>
    em.events.some((e) => e.kind === 'complained'),
  );

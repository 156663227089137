import {
  capitalInvestmentObjectsEnhancedApi,
  useGetApiCapitalInvestmentObjectsByIdQuery,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { useParams } from '@reach/router';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { assertsQueryDataLoadedBefore } from 'lib/typeHelpers/redux/rtkApiHelpers';

export function useInvalidateCapitalInvestments() {
  const dispatch = useAppDispatch();
  const invalidateCapitalInvestmentObject = () => {
    dispatch(
      capitalInvestmentObjectsEnhancedApi.util.invalidateTags([
        'CapitalInvestments',
      ]),
    );
  };

  return invalidateCapitalInvestmentObject;
}

export function useLoadedInvestmentObject() {
  const { objectId } = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();

  if (!objectId)
    throw new Error('"objectId" must exist either in params or in props');

  const res = useGetApiCapitalInvestmentObjectsByIdQuery({
    id: objectId,
  });
  assertsQueryDataLoadedBefore(res.data);

  return {
    ...res,
    data: res.data,
  };
}

const shouldIgnore = () => (
  import.meta.env.VITE_SYMMETRE_API_URL === 'https://api.symmetre.email' || ['investors', 'whiskey', 'tango', 'foxtrot', 'rum'].includes(window.location.host.split('.')[0])
);

export const initHotjar = () => {
  if (shouldIgnore()) return;

  return (function(h, o, t, j, a, r) {
    h.hj = h.hj || function() {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
    h._hjSettings = { hjid:2433039,hjsv:6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  }(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='));
};

export const initHotjarUser = ({ customer, currentUser }) => {
  if (shouldIgnore()) return;

  const userId = `${customer.subdomain}-${currentUser.id}`;
  window.hj('identify', userId, {
    Customer: customer.subdomain,
    'Full Name': currentUser.fullName,
    'User Role': currentUser.role.name,
  });
};

import React from 'react';
import { Control, useController } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import * as yup from 'yup';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import InputNumber from 'bundles/Shared/components/GroupForm/FormItems/InputNumber';
import { Button, Dropdown, DropdownItem, Field } from '@/stories';
import { isEqual, startCase, toLower } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import { objectEntries } from 'lib/typeHelpers';
import { convertGradientArrayToCss } from '@/shared/lib/colors';

const BOUNDARY_OPTIONS = [
  {
    label: 'Min',
    value: 'min',
  },
  {
    label: 'Max',
    value: 'max',
  },
  {
    label: 'Percentile',
    value: 'percentile',
  },
  {
    label: 'Value',
    value: 'value',
  },
  {
    label: 'Percentage',
    value: 'percentage',
  },
] as const;

export const AVAILABLE_GRADIENTS = {
  RED_YELLOW_GREEN: [
    'rgba(246, 65, 62, 1)',
    'rgba(254, 190, 70, 1)',
    'rgba(44, 174, 76, 1)',
  ],
  GREEN_YELLOW_RED: [
    'rgba(44, 174, 76, 1)',
    'rgba(254, 190, 70, 1)',
    'rgba(246, 65, 62, 1)',
  ],
  RED_WHITE_GREEN: [
    'rgba(246, 65, 62, 1)',
    'rgba(255, 255, 255, 1)',
    'rgba(44, 174, 76, 1)',
  ],
  GREEN_WHITE_RED: [
    'rgba(44, 174, 76, 1)',
    'rgba(255, 255, 255, 1)',
    'rgba(246, 65, 62, 1)',
  ],
} as const;

const isTypeMinOrMax = (type: string) => type === 'min' || type === 'max';

export const GRADIENT_THRESHOLD_SCHEMA = yup.object({
  type: yup
    .string()
    .oneOf(BOUNDARY_OPTIONS.map((o) => o.value))
    .required(),
  value: yup.number().optional().nullable().default(undefined),
  color: yup.string().required(),
});

export type GradientThresholdForm = yup.InferType<
  typeof GRADIENT_THRESHOLD_SCHEMA
>;

const INDEX_TYPE_MAPPING = {
  0: 'min',
  1: 'percentile',
  2: 'max',
} as const;

const filterBoundaryOptionByIndex = (index: number) => {
  if (index === 0) {
    return BOUNDARY_OPTIONS.filter((o) => o.value !== 'max');
  }

  if (index === 1) {
    return BOUNDARY_OPTIONS.filter(
      (o) => o.value !== 'min' && o.value !== 'max',
    );
  }

  return BOUNDARY_OPTIONS.filter((o) => o.value !== 'min');
};

export const getGradientThreshold = (
  gradient: readonly string[] | string[],
): GradientThresholdForm[] => {
  return gradient.map((color, index) => ({
    type: INDEX_TYPE_MAPPING[index],
    color,
    value: index === 1 ? 50 : undefined,
  }));
};

export const extractGradientFromThreshold = (
  field: GradientThresholdForm[],
): string[] => {
  return field.map((f) => f.color);
};

export const findMaxThreshold = (thresholds: GradientThresholdForm[]) => {
  return thresholds.find((t) => t.type === 'max');
};

export const findMinThreshold = (thresholds: GradientThresholdForm[]) => {
  return thresholds.find((t) => t.type === 'min');
};

const ColorSquare = ({ color, size }: { color: string; size?: number }) => {
  return (
    <div
      className={cn('shrink-0 !rounded-lg', !size && 'h-full w-full ')}
      style={{ background: color, width: size, height: size }}
    />
  );
};

const ThresholdField = ({
  onChange,
  index,
  ...props
}: {
  value: GradientThresholdForm;
  onChange: (value: GradientThresholdForm) => void;
  index: number;
}) => {
  const { type, value, color } = props.value;
  const handleTypeChange = (newType: GradientThresholdForm['type']) => {
    onChange({
      ...props.value,
      type: newType,
      value: isTypeMinOrMax(newType) ? null : value,
    });
  };
  const isTypePercentLike = type === 'percentile' || type === 'percentage';
  return (
    <div className="flex  w-full items-center gap-tw-2 bg-neutral-100 p-tw-2 ">
      <div className="h-[32px] w-[32px] shrink-0 !rounded-lg bg-neutral-000 p-tw-0.5">
        <div
          className="h-full w-full !rounded-lg"
          style={{ background: color }}
        />
      </div>
      <SharedSelect
        value={BOUNDARY_OPTIONS.find((o) => o.value === type)}
        isMulti={false}
        options={filterBoundaryOptionByIndex(index)}
        onChange={(o) => {
          handleTypeChange(o.value);
        }}
        menuPortalTarget={document.body}
      />
      <InputNumber
        rightIcon={isTypePercentLike ? 'percent' : undefined}
        min={isTypePercentLike ? 0 : undefined}
        max={isTypePercentLike ? 100 : undefined}
        disabled={isTypeMinOrMax(type)}
        onValueChange={(e) => onChange({ ...props.value, value: e.floatValue })}
        value={value}
        size="s"
      />
    </div>
  );
};

export function GradientField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name }: { name: TName; control: Control<TFieldValues> }) {
  const { field } = useController({ name, control });
  const [selectedGradientKey] =
    Object.entries(AVAILABLE_GRADIENTS).find(([_, value]) =>
      isEqual(value, extractGradientFromThreshold(field.value)),
    ) ?? [];

  const renderGradient = (_: GradientThresholdForm, index: number) => {
    return (
      <ThresholdField
        key={`threshold-${index}`}
        value={field.value[index]}
        index={index}
        onChange={(v) =>
          field.onChange(
            field.value.map((__, i) => (i === index ? v : field.value[i])),
          )
        }
      />
    );
  };

  const GradientItem = ({
    gradientKey,
    value,
  }: {
    gradientKey: string;
    value: readonly string[];
  }) => {
    return (
      <>
        <ColorSquare size={24} color={convertGradientArrayToCss(value)} />
        {startCase(toLower(gradientKey))}
      </>
    );
  };

  return (
    <>
      <Field labelText="Gradient Color">
        <Dropdown
          classNameContainer="self-start"
          maxWidth="max-content"
          items={objectEntries(AVAILABLE_GRADIENTS).map(([key, value]) => (
            <DropdownItem
              key={key}
              classes={{
                text: 'flex items-center gap-tw-2',
              }}
              active={key === selectedGradientKey}
              onClick={() => {
                field.onChange(getGradientThreshold(AVAILABLE_GRADIENTS[key]));
              }}
            >
              <GradientItem gradientKey={key} value={value} />
            </DropdownItem>
          ))}
        >
          <Button
            variant="secondary"
            iconPosition="right"
            iconName="arrowBottom"
            size="s"
          >
            {selectedGradientKey ? (
              <GradientItem
                gradientKey={selectedGradientKey}
                value={AVAILABLE_GRADIENTS[selectedGradientKey]}
              />
            ) : (
              'Select Gradient'
            )}
          </Button>
        </Dropdown>
      </Field>
      <Field labelText="Threshold Values">
        {field.value?.map(renderGradient)}
      </Field>
    </>
  );
}

import React, { FC, useState, useMemo } from 'react';
import { Modal, ConfirmationModal } from 'stories';
import getItemsCOModalEdit from './initData';
import { IChangeOrder } from 'bundles/Construction/types';
import { TTypeCostBreakdown } from '../CostBreakdownDetails';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import FormButtons from 'bundles/Shared/components/GroupForm/FormButtons/FormButtons';

import { convertToMilliseconds } from '@/shared/lib/converters';

interface IEditChangeEventModalProps {
  changeOrder: IChangeOrder;
  setShowModal: (typeCost: TTypeCostBreakdown) => void;
  handleEdit: () => void;
}

const EditChangeOrderModal: FC<IEditChangeEventModalProps> = ({
  changeOrder,
  setShowModal,
  handleEdit,
}) => {
  const [formData, setFormData] = useState({
    ...changeOrder,
    date: convertToMilliseconds(changeOrder?.date),
  });
  const [validate, setValidate] = useState({
    number: true,
  });
  const [loading, setLoading] = useState(false);
  const onLoading = () => {
    setLoading(true);
  };
  const [showConfirmModal, setConfirmModal] = useState(false);

  const closeCO = () => {
    setConfirmModal(true);
  };

  const confirmModalHandle = (status: boolean) => {
    if (status) {
      setShowModal(false);
    }
    setConfirmModal(false);
  };

  const initGroupFormItems = useMemo(
    () => getItemsCOModalEdit(),
    [formData, setFormData],
  );
  return (
    <>
      <Modal
        header={
          <div>
            <div className="dark header6-bold">Change Order Details</div>
          </div>
        }
        additionalActions={
          <FormButtons
            formData={formData}
            groupFormItems={initGroupFormItems}
            onSubmit={() => handleEdit(formData)}
            buttonConfig={{
              titleBtn: 'Save Updates',
              funcCancel: closeCO,
            }}
            loading={loading}
            onLoading={onLoading}
          />
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={closeCO}
        validate={validate}
      >
        {initGroupFormItems.map((formItem, idx) => (
          <div key={`form${idx}`}>
            <div className="dark-60 body-semibold mb-s">
              {formItem.customTitle}
            </div>
            <FormComponent
              {...formItem}
              formData={formData}
              setFormData={setFormData}
              styles={{
                wrapper: 'group-form__step-item',
              }}
              validate={validate}
              setValidate={setValidate}
            />
          </div>
        ))}
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure that you want to quit?"
          subtitle="The changed data will be lost."
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};

export default EditChangeOrderModal;

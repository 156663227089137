import { isSameDate, startOfMonth } from '@/shared/lib/date';

export function isBudgetDateAndFirstFinancialPeriodMismatched(
  budgetDateFrom: string | null | undefined,
  financialFirstPeriod: string | null | undefined,
): boolean {
  return (
    budgetDateFrom != null &&
    financialFirstPeriod != null &&
    !isSameDate(
      startOfMonth(new Date(budgetDateFrom)),
      startOfMonth(new Date(financialFirstPeriod)),
    )
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import { stringify } from 'qs';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import { LegalEntity } from '@/entities/core/legalEntity';
import { FundingSource } from '@/shared/types/reconcile/FundingSource';
import { TPageParamsFundingSources } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/widgets/Table/FundingSourceTable';
import { IMetaFundingSources } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/types/meta';
import { EditableFundingSource } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/features/FundingSourceModal';

export const reconcileFundingSourcesApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/settings/reconcile/development/legal_entities/`,
  }),
  reducerPath: 'reconcileFundingSources',
  tagTypes: ['FundingSource'],
  endpoints: (build) => ({
    getFundingSources: build.query<
      {
        items: FundingSource[];
        meta: IMetaFundingSources;
      },
      {
        legalEntityCode: LegalEntity['code'];
        pageParams: TPageParamsFundingSources;
      }
    >({
      query: (params) =>
        `/${params.legalEntityCode}/funding_sources${stringify(
          {
            ...params.pageParams,
          },
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
      providesTags: ['FundingSource'],
    }),
    createFundingSource: build.mutation<
      FundingSource,
      {
        legalEntityCode: string;
        body: EditableFundingSource;
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/funding_sources`,
          method: 'POST',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response: { data: FundingSource }) => {
        toastr.success('Funding Source Created');
        return response.data;
      },
      invalidatesTags: ['FundingSource'],
    }),
    updateFundingSource: build.mutation<
      FundingSource,
      {
        legalEntityCode: string;
        body: EditableFundingSource;
        fundingSourceId: number;
      }
    >({
      query(params) {
        return {
          url: `${API_URL}/api/settings/reconcile/development/funding_sources/${params.fundingSourceId}`,
          method: 'PUT',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response: { data: FundingSource }) => {
        toastr.success('Funding Source Updated');
        return response.data;
      },
      invalidatesTags: ['FundingSource'],
    }),
    deleteFundingSource: build.mutation<
      FundingSource,
      {
        legalEntityCode: string;
        fundingSourceId: number;
      }
    >({
      query(params) {
        return {
          url: `${API_URL}/api/settings/reconcile/development/funding_sources/${params.fundingSourceId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (response: { data: FundingSource }) => {
        toastr.success('Funding Source Deleted');
        return response.data;
      },
      invalidatesTags: ['FundingSource'],
    }),
  }),
});

export const {
  useGetFundingSourcesQuery,
  useCreateFundingSourceMutation,
  useUpdateFundingSourceMutation,
  useDeleteFundingSourceMutation,
} = reconcileFundingSourcesApi;

import { cn } from '@/shared/lib/css/cn';
import { useGetFeedTypesQuery, useUpdateFeedMessageMutation } from 'bundles/InvestmentObjects/components/Overview/Posts/api/feedMessagesApi';
import { feedMessagePermissions } from 'bundles/Shared/feedMessagePermissions';
import { ASSET_PORTAL_PRODUCT_NAME, FUND_PORTAL_PRODUCT_NAME } from 'lib/permissions';
import { useContext, useEffect, useState } from 'react';
import { AnimationLoader, LinkButton } from 'stories';
import EntityContext from '../../../../Shared/EntityContext';
import { BadgeSwitch } from '../../../../Shared/components/BadgeSwitch';
import PermissionList from '../../../../Shared/components/PermissionList';
import TextEditor from '../../../../Shared/components/TextEditor';
import AttachmentsStep from '../FeedMessage/AttachmentsStep';
import SubmitMessageButton from '../FeedMessage/SubmitMessageButton';
import FeedMessageFormStep from './FeedMessageFormStep';
import { useCreateFeedMessageMutation } from './api/feedMessagesApi';

const FeedMessageForm = ({
  feedMessage, updateFeedMessagesList, onClose, setCurrentFeedMessage, canClose = false,
}) => {
  const investmentObject = useContext(EntityContext);
  const [currentFeedType, setCurrentFeedType] = useState(feedMessage?.feedType);
  const [feedMessageText, setFeedMessageText] = useState(feedMessage?.text || '');
  const [attachedMedia, setAttachedMedia] = useState(feedMessage ? feedMessage.media : []);
  const [attachedDocuments, setAttachedDocuments] = useState(
    feedMessage ? feedMessage.documents : [],
  );
  const defaultPermissions = {
    public: feedMessage?.permitted?.public,
    roles: feedMessage?.permitted?.roles ?? [],
    users: feedMessage?.permitted?.users ?? [],
    tags: feedMessage?.permitted?.tags ?? [],
    investmentEntities: feedMessage?.permitted?.investmentEntities || [],
  };

  const [permissions, setPermissions] = useState(defaultPermissions);

  const permissionsIsSet = Object.values(permissions).some((item) => item?.length > 0)
    || permissions.public == true;

  const [attachingMode, setAttachingMode] = useState(
    feedMessage ? feedMessage.documents.length > 0 || feedMessage.media.length > 0 : undefined,
  );

  const {
    data: feedTypesData
  } = useGetFeedTypesQuery();
  const feedTypes = feedTypesData ?? [];

  useEffect(() => {
    if (attachingMode === false) {
      setAttachedMedia([]);
      setAttachedDocuments([]);
    }
  }, [attachingMode]);

  const getUniqPermissions = (docs, type) => (
    [...new Map(docs.map((sharedFile) => sharedFile.permitted[type])
      .flat().map((item) => [item.id, item])).values()]
  );

  useEffect(() => {
    if (attachingMode && attachedDocuments.length > 0) {
      const { roles, users, tags, investmentEntities } = Object.fromEntries(
        ['roles', 'users', 'tags', 'investmentEntities'].map((item) => (
          [item, getUniqPermissions(attachedDocuments, item)]
        )),
      );

      setPermissions({
        public: attachedDocuments.some((sharedFile) => sharedFile.permitted.isPublic),
        roles,
        users,
        tags,
        investmentEntities,
      });
    }

    if (attachingMode && attachedDocuments.length === 0) setPermissions(defaultPermissions);
  }, [attachedDocuments]);

  const [createFeedMessage, {
    isLoading: isCreating,
  }] = useCreateFeedMessageMutation();
  const [updateFeedMessage, {
    isLoading: isUpdating,
  }] = useUpdateFeedMessageMutation();
  const isLoading = isCreating || isUpdating;

  const feedMessageTextIsEmpty = feedMessageText === '<p></p>' || !feedMessageText;
  const cannotSubmit = (
    feedMessageTextIsEmpty
    || !permissionsIsSet
    || !currentFeedType
    || isLoading
  );
  // todo: move this to one place
  const productName = investmentObject.type === 'Asset' ? ASSET_PORTAL_PRODUCT_NAME : FUND_PORTAL_PRODUCT_NAME;

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: feedMessage?.id,
      feed_messageable_id: investmentObject.entity.id,
      feed_messageable_type: investmentObject.type,
      text: feedMessageText,
      feed_type_id: currentFeedType?.id,
      documents_list: attachedDocuments.map(({ id }) => id),
      media_list: attachedMedia.map(({ id }) => id),
      ...feedMessagePermissions(permissions),
    };

    if (data.id) {
      const res = await updateFeedMessage(data);
      updateFeedMessagesList(res.data);
      onClose();
    } else {
      const res = await createFeedMessage(data);
      updateFeedMessagesList(res.data);
      onClose();
      setCurrentFeedMessage(res.item);
    }
  };

  const attachStepValid = (
    (attachedDocuments.length > 0 || attachedMedia.length) > 0 && attachingMode
  ) || !attachingMode;

  return (
    <form onSubmit={onSubmit} className="relative">
      {isLoading && <AnimationLoader withBg />}
      <div className="flex flex-col">
        <div className="flex items-start">
          <FeedMessageFormStep
            stepNumber="1"
            isActive={currentFeedType}
            isWarning={!currentFeedType}
          />
          <div className="flex flex-grow flex-col mb-[1.5rem]">
            <div className="flex justify-between">
              <h6 className="header6-regular dark-60 mb-075rem vertical-align-middle feed-message-step-title mt-xs">
                What will this message refer to?{' '}
                <span className="red">*</span>
              </h6>
              {canClose && (
                <div className={cn({ 'opacity-[0.2]': cannotSubmit })}>
                  <LinkButton
                    onClick={onClose}
                    className="ml-auto"
                  >
                    <span className="sre-icon-close" />
                  </LinkButton>
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-tw-2">
              {feedTypes.map((feedType) => (
                <BadgeSwitch
                  key={feedType.title}
                  color={feedType.color}
                  label={feedType.title}
                  onClick={() => setCurrentFeedType(feedType)}
                  active={feedType.id === currentFeedType?.id}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-start">
          <FeedMessageFormStep
            stepNumber={2}
            isActive={!feedMessageTextIsEmpty}
            isWarning={feedMessageTextIsEmpty}
          />
          <div className="flex flex-col mb-[1.5rem] ck-container">
            <h6 className="header6-regular dark-60 mb-m vertical-align-middle feed-message-step-title mt-xs">
              What would you like to write about?{' '}
              <span className="red">*</span>
            </h6>
            <TextEditor
              text={feedMessageText}
              setText={(text) => setFeedMessageText(text)}
            />
          </div>
        </div>

        <div className="flex items-start">
          <FeedMessageFormStep
            stepNumber={3}
            isActive={(attachStepValid && attachingMode) || attachingMode === false}
            isWarning={(!attachStepValid && attachingMode) || attachingMode === undefined}
          />

          <AttachmentsStep
            attachedMedia={attachedMedia}
            attachedDocuments={attachedDocuments}
            setAttachedDocuments={setAttachedDocuments}
            setAttachedMedia={setAttachedMedia}
            onMediaRemove={(id) => setAttachedMedia(attachedMedia.filter((ad) => ad.id !== id))}
            onDocumentRemove={
              (id) => setAttachedDocuments(attachedDocuments.filter((ad) => ad.id !== id))
            }
            attachingMode={attachingMode}
            setAttachingMode={setAttachingMode}
            setPermissions={setPermissions}
            permissions={
              permissions?.type && attachedDocuments.length === 0 ? permissions : defaultPermissions
            }
          />
        </div>
        <div className="flex">
          <FeedMessageFormStep
            stepNumber={4}
            isActive={permissionsIsSet}
            isWarning={!permissionsIsSet}
            borderless
          />
          <div className="flex flex-col flex-grow">
            <h6 className="header6-regular dark-60 mt-xs mb-xs vertical-align-middle feed-message-step-title">
              Who can see this message?{' '}
              <span className="red">*</span>
            </h6>
            {attachedDocuments.length > 0 && (
              <p className="inline-regular light-60 mt-s">
                Permissions are generated based on the docs and/or media you attach in Step 3.
                <br />
                To see further detail, hover or click on the description below:
              </p>
            )}
            <div className="flex mt-m">
              <PermissionList
                permissions={permissions}
                productName={productName}
                onSubmit={setPermissions}
                hideActions={attachedDocuments.length > 0}
                defaultText="Set Permissions"
                type="post"
                investmentObject={investmentObject}
                objectableId={feedMessage?.id}
                objectableType="FeedMessage"
              />
            </div>
          </div>
        </div>
        <div className="mt-[1.25rem]">
          <SubmitMessageButton
            disabled={cannotSubmit}
            feedMessage={feedMessage}
          />
        </div>
      </div>
    </form>
  );
};

export default FeedMessageForm;

import React, { useEffect } from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import {
  IWorkflowThresholdUpdate,
  TWorkflowThresholdType,
} from 'bundles/REconcile/actions/workflowThresholds';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import {
  getUsersByIds,
  userOptions,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/ApproverThreshold';
import { nameOf } from 'types/utils';
import {
  resolveFormatter,
  thresholdRangeText,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/thresholdHelpers';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';

interface Props {
  setFormData: React.Dispatch<React.SetStateAction<IWorkflowThresholdUpdate>>;
  formData: IWorkflowThresholdUpdate;
  minThresholdValue: number;
  maxThresholdValue: number;
  setValid?: (valid: boolean) => void;
}

function ThresholdFields({
  formData,
  setFormData,
  minThresholdValue,
  maxThresholdValue,
  setValid,
}: Props) {
  const users = useAppSelector((state) => state.usersForReconcileSettings);

  const usersData = () => ({
    internalUsers: users?.filter((u) => !u.external),
    externalUsers: users?.filter((u) => u.external),
  });

  const { internalUsers, externalUsers } = usersData();

  const getValid = () => ({
    [nameOf<IWorkflowThresholdUpdate>('value')]: !(
      formData.value < minThresholdValue || formData.value > maxThresholdValue
    ),
    [nameOf<IWorkflowThresholdUpdate>('internal_approver_id')]:
      formData.internal_approver_id != null,
  });

  useEffect(() => {
    setValid?.(Object.values(getValid()).every((v) => v));
  }, [formData]);

  const internalFormData = {
    ...formData,
    external_approver_ids: userOptions(
      getUsersByIds(externalUsers, formData.external_approver_ids),
    ),
    value: formData.value?.toString(),
  };

  const handleSetFormData = (newFormData) => {
    setFormData({
      ...newFormData,
      external_approver_ids: newFormData.external_approver_ids.map(
        (o) => o.value,
      ),
    });
  };

  if (!users?.length) return <LoadingPanel />;

  return (
    <FormComponent
      styles={{
        wrapper__item: 'mb-0',
      }}
      formItems={[
        {
          type: FieldType.INPUT_NUMBER,
          title: `From${
            formData.category === TWorkflowThresholdType.TIM ? '(days)' : ''
          }`,
          placeholder: '0',
          fieldName: nameOf<IWorkflowThresholdUpdate>('value'),
          thousandSeparator: true,
          prefix: formData.category !== TWorkflowThresholdType.TIM ? '$' : '',
          required: true,
          error:
            formData.value == null ||
            getValid()[nameOf<IWorkflowThresholdUpdate>('value')]
              ? undefined
              : `Must be ${thresholdRangeText(
                  minThresholdValue,
                  maxThresholdValue,
                  resolveFormatter(formData.category),
                )}`,
          styles: {
            size: 'm',
            input: 'bg-white',
          },
        },
        {
          type: FieldType.SELECT,
          fieldName: nameOf<IWorkflowThresholdUpdate>('internal_approver_id'),
          inputId: nameOf<IWorkflowThresholdUpdate>('internal_approver_id'),
          required: true,
          placeholder: 'Select Internal Approver',
          title: 'Internal Approver',
          options: userOptions(internalUsers || []),
          menuPlacement: 'bottom',
          styles: {
            size: 'm',
          },
        },
        {
          type: FieldType.SELECT,
          title: 'External Approvers',
          inputId: nameOf<IWorkflowThresholdUpdate>('external_approver_ids'),
          fieldName: nameOf<IWorkflowThresholdUpdate>('external_approver_ids'),
          isMulti: true,
          options: userOptions(externalUsers || []),
          styles: {
            size: 'm',
            nomb: true,
          },
        },
      ]}
      formData={internalFormData}
      setFormData={handleSetFormData}
    />
  );
}

export default ThresholdFields;

import React from 'react';
import { Button } from '../../stories';
import { Logo } from 'bundles/Shared/components/LeftSidebar/Logo/Logo';

function ErrorBoundaryPage() {
  return (
    <div
      role="alert"
      className="flex h-screen flex-col justify-center text-center"
    >
      <div>
        <Logo variant="large" className="mb-5 w-20" />
      </div>
      <div className="text-dark-60">
        <h5 className="mb-2">
          Sorry, we are experiencing technical difficulties.
        </h5>
        <h5 className="mb-2">
          Our team is working to resolve the issue as soon as possible.
        </h5>
        <h5 className="mb-5">Please try again later.</h5>

        <Button variant="primary" onClick={() => window.location.replace('/')}>
          Go To Homepage
        </Button>
      </div>
    </div>
  );
}

export default ErrorBoundaryPage;

import React, { FC, useState } from 'react';
import { Modal, Button, ClassicTag } from 'stories';
import FormInput from 'bundles/Shared/components/GroupForm/FormItems/FormInput';
import {
  PayableTeamContact,
  SoftwareProvider,
} from '@/shared/types/reconcile/Invoice';
import { cn } from '@/shared/lib/css/cn';

interface IPayableTeamModalProps {
  onClose: (status: boolean) => void;
  email: string;
  softwareProviders: SoftwareProvider[];
  handleCreatePaybleTeam: (
    formData: Omit<PayableTeamContact, 'id'>,
  ) => Promise<boolean>;
}

const PayableTeamModal: FC<IPayableTeamModalProps> = ({
  onClose,
  email,
  softwareProviders,
  handleCreatePaybleTeam,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email,
    softwareProvider: '',
  });

  const canSubmit = () =>
    !Object.keys(formData).every((key) => Boolean(formData[key]));

  const submit = async () => {
    const res: boolean = await handleCreatePaybleTeam(formData);
    if (res) {
      onClose(false);
    }
  };

  return (
    <div>
      <Modal
        header={
          <div>
            <div className="header6-bold text-dark-60">
              New Accounts Payable Team Contact
            </div>
          </div>
        }
        size="md"
        classes={{
          body: 'reconcile-common-modal-create',
          footerAdditionalActions: 'bg-white p-m',
        }}
        toggle={onClose}
        additionalActions={
          <div className="flex w-full justify-between">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="success" onClick={submit} disabled={canSubmit()}>
              Create Contact
            </Button>
          </div>
        }
        bodyPadding="0"
      >
        <div className="flex flex-col gap-tw-4 bg-white p-tw-6">
          <FormInput
            title="Name"
            fieldName="name"
            styles={{
              size: 'm',
            }}
            value={formData.name}
            setFormData={(data) =>
              setFormData({
                ...formData,
                ...data,
              })
            }
            placeholder="Enter Name"
            required
          />
          <FormInput
            title="Email"
            fieldName="email"
            styles={{
              size: 'm',
            }}
            value={formData.email}
            setFormData={(data) =>
              setFormData({
                ...formData,
                ...data,
              })
            }
            placeholder="Enter Email"
            required
          />
          <div className="flex flex-col gap-tw-2">
            <div className="label-regular text-dark-60">
              Select AP Software Provider <span className="text-red">*</span>
            </div>
            <div className="flex flex-wrap gap-tw-1">
              {softwareProviders.map((provider) => (
                <ClassicTag
                  key={provider.key}
                  label={provider.name}
                  className={cn({
                    'border-blue bg-blue text-white':
                      formData.softwareProvider === provider.key,
                  })}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      softwareProvider: provider.key,
                    })
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PayableTeamModal;

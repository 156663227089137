import { useHoverSaveArea } from '@/bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import { useGenericData } from '@/bundles/Shared/GenericContext';
import { cn } from '@/shared/lib/css/cn';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import {
  replacePathParams,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { useMatch } from '@reach/router';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard';
import { LeftSidebarMenu } from 'bundles/Shared/shared/ui';
import { useRef } from 'react';
import { Icon } from 'stories';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import SecondaryMenuItem from '../items/SecondaryMenuItem';

const NAVIGATION_ITEM_NAME = 'Financial';

export const ReportFinancialsTableMenuItem = () => {
  const { triggered, ...handlers } = usePopoverTriggered();
  const { genericData } = useGenericData();
  const isMobile = useMediaQuery('(pointer:coarse)');
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const { mainNavigation } = genericData!;
  const { fullPath } = ROUTES_ROOT.report.financials;
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea({
    popoverPlacement: 'start',
  });
  const isMatchWithFinancials = useMatch(
    ROUTES_ROOT.report.financials.fullPath,
  );

  const financialMobileMenu = mainNavigation.financialSubItems.map((item) => (
    <SecondaryMenuItem
      key={item.slug}
      label={item.label}
      path={replacePathParams(ROUTES_ROOT.report.financials.fullPath, {
        reportName: item.slug,
      })}
    />
  ));

  if (mainNavigation.financialSubItems.length === 0) return null

  if (isMobile) {
    return (
      <>
        <div className="flex w-full items-center px-tw-2 py-tw-1">
          <div className="left-sidebar__second-menu-label inline-regular ml-tw-2">
            {NAVIGATION_ITEM_NAME}
          </div>
        </div>
        {financialMobileMenu}
      </>
    );
  }

  return (
    <>
      <SecondaryMenuItem
        className={cn(
          triggered && 'left-sidebar__second-menu-item_hovered',
          hoverClasses,
        )}
        ref={menuItemRef}
        onLinkClick={(e) => {
          e.preventDefault();
        }}
        linkProps={{
          onMouseMove,
        }}
        rightIcon={<Icon iconName="arrowRight" className="pr-tw-3" />}
        label={NAVIGATION_ITEM_NAME}
        isActive={() => isMatchWithFinancials!}
        path=""
      />
      {isMediumWidth && (
        <MenuItemPopover
          {...handlers}
          reference={menuItemRef}
          offset={DEFAULT_DROPDOWN_OFFSET}
          template={
            <LeftSidebarMenu title="Tables">
              {mainNavigation.financialSubItems.map((item) => (
                <LeftSidebarMenu.Item
                  to={replacePathParams(fullPath, { reportName: item.slug })}
                  key={item.label}
                >
                  <DashboardIcon iconName="eagleEyeDashboard" /> {item.label}
                </LeftSidebarMenu.Item>
              ))}
            </LeftSidebarMenu>
          }
          placement="right-start"
          hideOnExpandedAreaClick
          maxWidth="fit-content"
        />
      )}
    </>
  );
};

import React, { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { cn } from '@/shared/lib/css/cn';
import { formatAmount } from '@/shared/lib/formatting/number';
import { updateChangeOrderIncludesCE } from 'bundles/Construction/actions/changeOrders';
import { IChangeOrder } from 'bundles/Construction/types';
import TableCE from './TableCE';
import IncludedCEModal from './IncludedCEModal';

interface IIncludesChangeEventsProps extends PropsFromRedux {
  changeOrder: IChangeOrder;
  legalEntityCode: string;
}

const IncludesChangeEvents: FC<IIncludesChangeEventsProps> = ({
  changeOrder,
  legalEntityCode,
  updateChangeOrder,
}) => {
  const editCE = changeOrder?.status === 'DRAFT';
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [formData, setFormData] = useState({
    changeOrderEvents: changeOrder?.changeOrderEvents,
    value: changeOrder?.changeOrderEvents?.reduce(
      (acc, item) => acc + Number(item.value),
      0,
    ),
  });
  const saveUpdates = async () => {
    await updateChangeOrder(legalEntityCode, changeOrder?.id, {
      change_order_event_ids: formData?.changeOrderEvents.map((co) => co.id),
    });
    setShowModalEdit(false);
  };

  return (
    <div className="change-card mb-m">
      <div className="change-card__header change-card__header_line">
        <div className="change-card__title header6-bold pb-m">
          Included Change Events
        </div>
        {editCE && (
          <div
            className="change-card__button inline-regular"
            onClick={() => {
              setShowModalEdit(true);
            }}
          >
            Edit
          </div>
        )}
      </div>
      <TableCE changeOrder={changeOrder} />
      <div className="card-cost-breakdown-total">
        <div className="dark-60 inline-semibold">Total:</div>
        <div>
          <div className={cn('card-cost-breakdown-total__price')}>
            {formatAmount(changeOrder?.amount)}
          </div>
        </div>
      </div>
      {showModalEdit && (
        <IncludedCEModal
          setShowModalEdit={setShowModalEdit}
          saveUpdates={saveUpdates}
          changeOrderEvents={changeOrder?.changeOrderEvents}
          formData={formData}
          setFormData={setFormData}
          legalEntityCode={legalEntityCode}
        />
      )}
    </div>
  );
};

const mapActionCreators = {
  updateChangeOrder: updateChangeOrderIncludesCE,
};

const connector = connect(null, mapActionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(IncludesChangeEvents);

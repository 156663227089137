import React from 'react';
import PropTypes from 'prop-types';
import { SreAvatar } from 'stories';
import PermissionsFor from './PermissionsFor';
import ObjectAccessTable from './ObjectAccessTable';
import userFormRequest from '../helpers/userFormRequest';

const fetchAccessableEntitiesIds = (form, funds, assets) => {
  const allFundsIds = funds?.map(({ id }) => id) || [];
  const canViewFundIds = form.can_view_all_funds ? allFundsIds : form.can_view_object_ids.Fund;
  const canManageFundIds = form.can_manage_all_funds
    ? allFundsIds
    : form.can_manage_object_ids.Fund;

  const canViewFunds = funds?.filter((fund) => canViewFundIds.includes(fund.id)) || [];
  const selectedAssetIdsByFunds = canViewFunds.map(({ assetIds }) => assetIds).flat();

  const allAssetsIds = assets?.map(({ id }) => id) || [];
  const canViewAssetIds = form.can_view_all
    ? allAssetsIds
    : form.can_view_object_ids.Asset.concat(selectedAssetIdsByFunds);
  const canManageAssetIds = form.can_manage_all ? allAssetsIds : form.can_manage_object_ids.Asset;

  return {
    canViewFundIds,
    canManageFundIds,
    canViewAssetIds,
    canManageAssetIds,
  };
};

const ReviewStep = ({ fields, funds, assets }) => {
  // NOTE: basing review on real data to be sent
  const form = userFormRequest(fields);
  // NOTE: making an exception here, since userRole ain't affected by anything else
  const { userRole } = fields;

  const fullName = `${form.first_name} ${form.last_name}`;

  // NOTE: complicated logic for accessable entities ids
  const {
    canViewFundIds,
    canManageFundIds,
    canViewAssetIds,
    canManageAssetIds,
  } = fetchAccessableEntitiesIds(form, funds, assets);

  return (
    <>
      <div className="flex items-center flex-col">
        <SreAvatar
          height="3rem"
          width="3rem"
          text={fullName}
          className="mb-[0.5rem]"
          fontSize="1rem"
        />
        <div className="text-center w-100">
          <h6 className="dark-60">{fullName}</h6>
          <p className="light-60">{form.email}</p>
          {form.user_tags.length !== 0 && (
            <div className="flex mb-tw-4 justify-center flex-wrap">
              {form.user_tags.map((label) => (
                <div
                  key={label}
                  className="document-type-badge-outline mr-[0.5rem] mb-[0.5rem] light-90 inline-regular py-0125rem px-045rem h-25p"
                >
                  <span className="sre-icon-tag mr-03125rem" />
                  {label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <h6 className="light-60 mb-[0.5rem] mt-tw-4">
        Role:&nbsp;
        <span className="dark-60">{userRole.name}</span>
      </h6>
      <PermissionsFor selectedRole={userRole} />
      {(canViewFundIds.length > 0 || canManageFundIds.length > 0) && (
        <ObjectAccessTable
          objects={funds}
          canViewIds={canViewFundIds}
          canManageIds={canManageFundIds}
          type="Funds"
        />
      )}
      {(canViewAssetIds.length > 0 || canManageAssetIds.length > 0) && (
        <ObjectAccessTable
          objects={assets}
          canViewIds={canViewAssetIds}
          canManageIds={canManageAssetIds}
          type="Assets"
        />
      )}
    </>
  );
};

ReviewStep.propTypes = {
  fields: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    userRole: PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.shape(),
      category: PropTypes.string.isRequired,
    }),
    userTags: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    canViewAssetIds: PropTypes.arrayOf(PropTypes.number),
    canManageAssetIds: PropTypes.arrayOf(PropTypes.number),
    canViewFundIds: PropTypes.arrayOf(PropTypes.number),
    canManageFundIds: PropTypes.arrayOf(PropTypes.number),
    canViewAll: PropTypes.bool,
    canManageAll: PropTypes.bool,
    canViewAllFunds: PropTypes.bool,
    canManageAllFunds: PropTypes.bool,
  }).isRequired,
  funds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ReviewStep;

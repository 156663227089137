import * as React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';

export interface VarianceHeaderParams {
  funded: boolean;
  subHeaderName?: string;
  haveActions?: boolean;
  rightBar?: React.ReactNode;
  verticalAlignContent?: 'start' | 'center' | 'end';
}

type Props = IHeaderParams & VarianceHeaderParams;

function VarianceHeader({
  displayName,
  subHeaderName,
  haveActions,
  rightBar,
  funded,
}: Props) {
  return (
    <div className={cn('flex h-full w-full items-center justify-between')}>
      <div className="flex flex-col">
        <div className="white secondary-semibold flex items-center gap-tw-1.5">
          <div>{displayName}</div>
          {funded && (
            <div className="label-semibold text-success-080">FUNDED</div>
          )}
        </div>
        {subHeaderName && (
          <span className="secondary-regular text-neutral-500">
            {subHeaderName}
          </span>
        )}
      </div>
      <div className="flex items-center gap-tw-1">
        {rightBar}
        {haveActions && <Icon iconName="moreVertical" />}
      </div>
    </div>
  );
}

export default VarianceHeader;

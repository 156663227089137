import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import {
  DEFAULT_AMCHART_DATE_FORMATS,
  getCurrencyTooltipFormat,
} from '@/shared/lib/formatting/charts';
import * as am5 from '@amcharts/amcharts5';
import { getDefaultLabelStyleInJson } from 'lib/amcharts/utils';
import { AverageXYChartWidget } from 'bundles/Shared/widgets/dashboard/widgets/averageActualRentChart/widget';

import { XY_CHART_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/xyChart';

export const AVERAGE_EFFECTIVE_RENT_CHART_WIDGET_CONFIG = {
  ...XY_CHART_WIDGET_CONFIG,
  title: 'Average Effective Rent Chart',
  Component: AverageXYChartWidget,
} as const satisfies WidgetConfiguration;

export const AVERAGE_EFFECTIVE_AMCHART_CONFIG = {
  refs: [
    {
      xAxis: {
        type: 'DateAxis',
        settings: {
          dateFormats: DEFAULT_AMCHART_DATE_FORMATS,
          maxDeviation: 0.2,
          baseInterval: { timeUnit: 'day', count: 1 },
          renderer: {
            type: 'AxisRendererX',
            settings: {
              minGridDistance: 40,
              cellStartLocation: 0.1,
              cellEndLocation: 0.9,
            },
            properties: {
              labels: {
                properties: {
                  template: {
                    settings: {
                      location: 0,
                    },
                  },
                },
              },
            },
          },
          categoryField: 'dateFrom',
        },
        properties: {
          data: '#data',
        },
      },
      sumYAxis: {
        type: 'ValueAxis',
        settings: {
          renderer: {
            type: 'AxisRendererY',
          },
        },
        children: [
          {
            index: 0,
            type: 'Label',
            settings: {
              text: 'Average Rent ($)',
              y: am5.p50,
              centerX: am5.p50,
              rotation: -90,
              paddingTop: 16,
              ...getDefaultLabelStyleInJson(),
            },
          },
        ],
      },
    },
  ],
  type: 'XYChart',
  settings: {
    panY: false,
    cursor: {
      type: 'XYCursor',
      settings: {
        behavior: 'none',
      },
      properties: {
        lineY: {
          settings: {
            visible: false,
          },
        },
      },
    },
    layout: 'vertical',
    scrollbarX: {
      type: 'Scrollbar',
      settings: {
        orientation: 'horizontal',
        marginBottom: 20,
      },
    },
  },
  properties: {
    xAxes: ['#xAxis'],
    yAxes: ['#sumYAxis'],
    series: '#lineSeries',
  },
};
export const AVG_EFFECTIVE_RENT_CHART_LINE_SERIES_TEMPLATE = {
  type: 'LineSeries',
  settings: {
    name: 'Leases',
    yAxis: '#sumYAxis',
    xAxis: '#xAxis',
    valueXField: 'dateFrom',
    tooltip: {
      type: 'Tooltip',
      settings: {
        labelText: `{name}:${getCurrencyTooltipFormat()}`,
      },
    },
    locationX: 0,
  },
  properties: {
    strokes: {
      properties: {
        template: {
          settings: {
            strokeWidth: 1,
          },
        },
      },
    },
    bullets: [
      {
        type: 'Bullet',
        settings: {
          locationX: 0,
          sprite: {
            type: 'Circle',
            settings: {
              radius: 2,
              fill: "@series.get('fill')",
            },
          },
        },
      },
    ],
  },
};
export const AVG_EFFECTIVE_RENT_CHART_AREA_SERIES_TEMPLATE = {
  type: 'LineSeries',
  settings: {
    name: 'Average Rent',
    yAxis: '#sumYAxis',
    xAxis: '#xAxis',
    valueXField: 'dateFrom',
    valueYField: 'value',
    tooltip: {
      type: 'Tooltip',
      settings: {
        labelText: `{name}:${getCurrencyTooltipFormat()}`,
      },
    },
    fill: {
      type: 'Color',
      value: '#adb3b6',
    },
    locationX: 0,
  },
  properties: {
    data: '#averageData',
    fills: {
      properties: {
        template: {
          settings: {
            fillOpacity: 0.5,
            visible: true,
          },
        },
      },
    },
    strokes: {
      properties: {
        template: {
          settings: {
            strokeWidth: 0,
            strokeOpacity: 0,
          },
        },
      },
    },
  },
};

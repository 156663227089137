import React from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props
  extends Pick<
    JSX.IntrinsicElements['td'],
    'onClick' | 'onMouseEnter' | 'onMouseLeave'
  > {
  fromCurrentMonth: boolean;
  active?: boolean;
  selected?: boolean;
  clickable?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  isRangeEdge?: boolean;
}

export function CalendarCell({
  children,
  selected,
  active,
  clickable = true,
  fromCurrentMonth,
  hovered,
  isRangeEdge,
  disabled,
  ...props
}: React.PropsWithChildren<Props>) {
  const hoverable = clickable && !disabled;
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <td
      {...props}
      onClick={(e) => {
        if (disabled) {
          return;
        }
        props.onClick?.(e);
      }}
      className={cn(
        'secondary-regular px-tw-2 py-tw-1 text-center',
        active ? 'text-blue' : 'text-dark-60',
        fromCurrentMonth ? 'bg-tw-white' : 'bg-light text-light-60',
        hoverable &&
          cn(
            'cursor-pointer',
            hovered && {
              'text-dark-60': !active,
              'text-blue': active,
              'bg-blue-light-5': !isRangeEdge,
              'bg-blue-light-4': isRangeEdge,
            },
          ),
        !disabled &&
          selected &&
          cn('text-white', {
            'bg-blue-dark-4': !isRangeEdge,
            'bg-blue': isRangeEdge,
          }),
        disabled &&
          'bg-[repeating-linear-gradient(40deg,#E5E7E8,#E5E7E7_5px,#F5F5F6_0px,#F5F5F6_12px)] text-light-60',
      )}
    >
      {children}
    </td>
  );
}

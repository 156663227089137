import { IUser } from 'types/User';

export enum TEventTypes {
  BUDGET_CREATED = 'budgetCreated',
  BUDGET_PUBLISHED = 'budgetPublished',
  BUDGET_LINE_ITEM_ADDED = 'budgetLineItemAdded',
  BUDGET_LINE_ITEM_REMOVED = 'budgetLineItemRemoved',
  BUDGET_LINE_ITEM_VALUE_CHANGED = 'budgetLineItemValueChanged',
  REQUISITION_ADDED = 'requisitionAdded',
  REQUISITION_REMOVED = 'requisitionRemoved',
  REQUISITION_TRANSACTION_LINKED_TO_CATEGORY = 'requisitionTransactionLinkedToCategory',
  REQUISITION_TRANSACTION_UNLINKED_FROM_CATEGORY = 'requisitionTransactionUnlinkedFromCategory',
  REQUISITION_TRANSACTION_CATEGORY_CHANGED = 'requisitionTransactionCategoryChanged',
  COMMENT_ADDED_TO_REQUISITION_CATEGORY = 'commentAddedToRequisitionCategory',
  REPLY_ADDED_TO_REQUISITION_CATEGORY_COMMENT = 'replyAddedToRequisitionCategoryComment',
}

export interface IBudgetActivityLogEvent {
  kind: TEventTypes;
  updatedAt: number;
  whoDoneIt: Pick<IUser, 'id' | 'fullName'>;
}

export interface IBudgetCreatedEvent extends IBudgetActivityLogEvent {
  kind: TEventTypes.BUDGET_CREATED;
}
export interface IBudgetPublishedEvent extends IBudgetActivityLogEvent {
  kind: TEventTypes.BUDGET_PUBLISHED;
}
export interface IBudgetLineItemAdded extends IBudgetActivityLogEvent {
  kind: TEventTypes.BUDGET_LINE_ITEM_ADDED;
  brief: {
    newValue: string;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
  };
}

export interface IBudgetLineItemRemoved extends IBudgetActivityLogEvent {
  kind: TEventTypes.BUDGET_LINE_ITEM_REMOVED;
  brief: {
    newValue: number;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
  };
}

export interface IBudgetLineItemValueChanged extends IBudgetActivityLogEvent {
  kind: TEventTypes.BUDGET_LINE_ITEM_VALUE_CHANGED;
  brief: {
    newValue: number;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
  };
}

export interface IRequisitionAdded extends IBudgetActivityLogEvent {
  kind: TEventTypes.REQUISITION_ADDED;
  brief: {
    requisitionId: string;
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  };
}

export interface IRequisitionRemoved extends IBudgetActivityLogEvent {
  kind: TEventTypes.REQUISITION_REMOVED;
  brief: {
    requisitionId: string;
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  };
}

export interface IRequisitionTransactionLinkedToCategory
  extends IBudgetActivityLogEvent {
  kind: TEventTypes.REQUISITION_TRANSACTION_LINKED_TO_CATEGORY;
  brief: {
    transactionId: number;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
    requisitionId: string;
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  };
}

export interface IRequisitionTransactionUnlinkedFromCategory
  extends IBudgetActivityLogEvent {
  kind: TEventTypes.REQUISITION_TRANSACTION_UNLINKED_FROM_CATEGORY;
  brief: {
    transactionId: number;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
    requisitionId: string;
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  };
}

export interface IRequisitionTransactionCategoryChanged
  extends IBudgetActivityLogEvent {
  kind: TEventTypes.REQUISITION_TRANSACTION_CATEGORY_CHANGED;
  brief: {
    transactionId: number;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
    requisitionId: string;
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  };
}

export interface ICommentAddedToRequisitionCategory
  extends IBudgetActivityLogEvent {
  kind: TEventTypes.COMMENT_ADDED_TO_REQUISITION_CATEGORY;
  brief: {
    commentId: string;
    authorId: number;
    authorName: string;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
    requisitionId: string;
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  };
}

export interface IReplyAddedToRequisitionCategoryComment
  extends IBudgetActivityLogEvent {
  kind: TEventTypes.REPLY_ADDED_TO_REQUISITION_CATEGORY_COMMENT;
  brief: {
    commentId: string;
    authorId: number;
    authorName: string;
    categoryId: number;
    categoryType: string;
    categoryName: string;
    categoryCode: string;
    requisitionId: string;
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  };
}

export type TBudgetActivityLogEvents =
  | IBudgetCreatedEvent
  | IBudgetPublishedEvent
  | IBudgetLineItemAdded
  | IBudgetLineItemRemoved
  | IBudgetLineItemValueChanged
  | IRequisitionAdded
  | IRequisitionRemoved
  | IRequisitionTransactionLinkedToCategory
  | IRequisitionTransactionUnlinkedFromCategory
  | IRequisitionTransactionCategoryChanged
  | ICommentAddedToRequisitionCategory
  | IReplyAddedToRequisitionCategoryComment;

import React, { useRef } from 'react';
import { useAmchart } from 'lib/amcharts/useAmchart';
import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { getCssVariableByAccessor } from 'lib/helpers';
import { CssVar } from '@/shared/config/cssVar';

import { getReturnDashboardTheme } from 'lib/amcharts/utils';

interface Props {
  data: string;
}

function GaugeWithGradientFill({ data }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  useAmchart(
    containerRef,
    (root) => {
      const myTheme = getReturnDashboardTheme(root);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          startAngle: 180,
          endAngle: 360,
        }),
      );

      const axisRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 30,
        innerRadius: -10,
        strokeOpacity: 1,
        strokeWidth: 5,
        strokeGradient: am5.LinearGradient.new(root, {
          rotation: 0,
          stops: [
            { color: am5.color('#6DC885') },
            { color: am5.color('#FED37E') },
            { color: am5.color('#FD764C') },
          ],
        }),
      });

      axisRenderer.labels.template.setAll({
        fill: am5.color(getCssVariableByAccessor(CssVar.light60)),
      });

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          min: 0,
          max: 100,
          strictMinMax: true,
          renderer: axisRenderer,
        }),
      );

      const axisDataItem = xAxis.makeDataItem({});
      axisDataItem.set('value', 0);

      const hand = am5radar.ClockHand.new(root, {
        radius: am5.percent(80),
        topWidth: 3,
        bottomWidth: 6,
        pinRadius: 0,
      });
      hand.hand.setAll({
        fillGradient: am5.LinearGradient.new(root, {
          rotation: 0,
          stops: [
            {
              color: am5.color('#D9D9D9'),
            },
            {
              color: am5.color('#5C676D'),
            },
          ],
        }),
      });
      const bullet = axisDataItem.set(
        'bullet',
        am5xy.AxisBullet.new(root, {
          sprite: hand,
        }),
      );

      xAxis.createAxisRange(axisDataItem);

      axisDataItem.get('grid').set('visible', false);

      setInterval(() => {
        axisDataItem.animate({
          key: 'value',
          to: Number(data),
          duration: 800,
          easing: am5.ease.out(am5.ease.cubic),
        });
      }, 2000);

      // Make stuff animate on load
      chart.appear(1000, 100);
    },
    [data],
  );
  return (
    <div className="h-[130px] w-full flex-1 flex-grow" ref={containerRef} />
  );
}

export default GaugeWithGradientFill;

import { FinancialTableSingleDateWidgetConfigDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';

export const DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG = {
  columns: [],
  rows: [],
  default_options: {
    date: {
      period_shift: {},
    },
  },
  viz_config: {
    column_groups: [],
    rows: [],
    columns: [],
  },
} as const satisfies FinancialTableSingleDateWidgetConfigDto;

import http from 'lib/http';
import { IUser } from 'types/User';

export const FETCH_USERS_FOR_RECONCILE_SETTINGS =
  'symmetre-client-api/FETCH_USERS_FOR_RECONCILE_SETTINGS';

export function fetchUsersForReconcileSettings() {
  return async (dispatch) => {
    const res = await http.get('/reconcile/users');
    const responseData = (await res.json()) as IUser[];

    dispatch({
      type: FETCH_USERS_FOR_RECONCILE_SETTINGS,
      payload: responseData,
    });
  };
}

import { useNavigate } from '@reach/router';
import { useEffect } from 'react';
import { ILegalEntitiable } from 'types/LegalEntitiable';

export function useGoToFirstObjectFromDataEffect<Obj extends ILegalEntitiable>(
  {
    objects,
    slugFromQuery,
    prepareUrl,
  }: {
    objects: Obj[] | undefined;
    slugFromQuery: string | undefined;
    prepareUrl: (slug: string) => string;
  },
  effectDeps: React.DependencyList,
) {
  const navigate = useNavigate();

  useEffect(() => {
    if (objects == null || objects.length <= 0) return;

    const assetSlugs = objects.map((a) => a.slug);

    if (assetSlugs.includes(slugFromQuery ?? '')) return;
    if (slugFromQuery != null) return;

    const url = prepareUrl(assetSlugs[0]);
    navigate(url);
  }, [effectDeps]);
}

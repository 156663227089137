import React, { ComponentProps, FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import symmetreLogo from 'images/symmetRE_icon_full_green_navy.svg';
import { RouteComponentProps } from '@reach/router';
import { SymmetreLogoIcon } from 'bundles/Shared/components/LeftSidebar/Logo/Logo';

interface IError {
  code: 'not_found' | 'internal_server_error';
  className?: string;
  actionButton?: {
    text: string;
    onClick: () => void;
  };
}

const Errors: FC<IError> = ({ code, className, actionButton }) => {
  const img = (
    <SymmetreLogoIcon theme="colored" className="h-[128px] w-[128px]" />
  );

  const errors = {
    not_found: {
      status: <>4{img}4</>,
      subheader: 'Page Not Found',
      text: "We can't seem to find the page you're looking for. Go Back to choose a new direction.",
    },
    internal_server_error: {
      status: <>5{img}0</>,
      subheader: 'Something is wrong',
      text: 'Our development team have been notified of this issue and they are working on it.',
    },
  };

  const error = errors[code];

  return (
    <div
      className={cn(
        'flex min-h-[calc(100vh-70px)] items-center justify-center',
        className,
      )}
    >
      <div className="flex w-[600px] flex-col items-center justify-center rounded-[48px] bg-white px-tw-18 py-tw-12 text-center shadow">
        <div className="text-bootstrap-blue mb-0 flex items-center text-9xl leading-normal">
          {error.status}
        </div>
        <div className="text-bootstrap-blue header2-regular mb-tw-12">
          {error.subheader}
        </div>
        <h5 className="secondary-regular">{error.text}</h5>
        {actionButton ? (
          <div
            onClick={actionButton.onClick}
            className="btn btn-primary mt-tw-12"
          >
            {actionButton.text}
          </div>
        ) : (
          <a
            href="/"
            className="btn btn-primary mt-tw-12 !rounded-[8px] !border-tw-primary !bg-tw-primary !p-tw-4"
          >
            Back to Dashboard
          </a>
        )}
      </div>
    </div>
  );
};

export const NotFound = (
  props: RouteComponentProps & Omit<ComponentProps<typeof Errors>, 'code'>,
) => <Errors code="not_found" {...props} />;
export const InternalServerError = () => (
  <Errors code="internal_server_error" />
);

export default Errors;

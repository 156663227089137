import React, { FC, forwardRef } from 'react';
import { cn } from '@/shared/lib/css/cn';
import './linkButton.css';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: 'inline' | 'secondary';
  uppercase?: boolean;
  fontWeight?: 'normal' | 'medium' | 'bold';
}

export const LinkButton: FC<Props> = forwardRef(
  (
    {
      className,
      fontWeight = 'normal',
      variant = 'inline',
      children,
      uppercase,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        `sre-link-button sre-link-button_fw-${fontWeight}`,
        className,
        {
          'sre-link-button_secondary': variant === 'secondary',
          'sre-link-button_uppercase': uppercase,
        },
      )}
      {...props}
    >
      {children}
    </button>
  ),
);

export default LinkButton;

import React from 'react';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './NavigationPin.module.scss';

interface Props {
  className?: string;
  pinned: boolean;
  onClick: () => void;
}
export function NavigationPin({ className, pinned, ...props }: Props) {
  return (
    <button
      type="button"
      className={cn(
        styles['sre-navigation-pin'],
        pinned && styles['sre-navigation-pin_pinned'],
        className,
      )}
      {...props}
    >
      <Icon
        iconName="menuPin"
        className={cn(styles['sre-navigation-pin__icon'])}
      />
    </button>
  );
}

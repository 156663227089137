import React, { useState } from 'react';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, IconButton, LinkButton, Modal, Tumbler } from 'stories';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  createDealPathEmployee,
  removeDealPathEmployee,
  updateDealPathEmployee,
} from '../../actions/dealPathSettings';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';

interface DealPathEmployee {
  active: boolean;
  id: number;
  initials: string;
  full_name: string;
  aliases: string;
}

const tableColumns = ({
  toggleActive,
  onClick,
  onRemove,
}: {
  toggleActive: (row: DealPathEmployee) => void;
  onClick: (row: DealPathEmployee) => void;
  onRemove: (row: DealPathEmployee) => void;
}): IColumn<DealPathEmployee>[] => [
  {
    dataField: 'active',
    text: 'Active',
    formatter: ({ row }) => (
      <Tumbler
        className="mr-10"
        defaultChecked={row.active}
        onChange={() => toggleActive(row)}
      />
    ),
  },
  {
    dataField: 'initials',
    text: 'Initials',
    headerStyle: { minWidth: '80px', textAlign: 'center' },
  },
  {
    dataField: 'fullName',
    text: 'Full name',
    headerStyle: { minWidth: '280px', textAlign: 'center' },
  },
  {
    dataField: 'aliases',
    text: 'Aliases',
    headerStyle: { minWidth: '280px', textAlign: 'center' },
  },
  {
    dataField: 'actions',
    text: 'Actions',
    formatter: ({ row }) => (
      <>
        <IconButton
          iconName="edit"
          className="mr-s"
          onClick={() => onClick(row)}
        />
        <IconButton iconName="trash" onClick={() => onRemove(row)} />
      </>
    ),
  },
];

const DealPathEmployees = (props) => {
  const [selectedEmployee, setSelectedEmployee] = useState<
    DealPathEmployee | null | 'new'
  >();
  const { handleSubmit, setValue } = useForm();

  const { confirm } = useModal();

  const { employees } = props;

  const submit = async (data) => {
    const action = selectedEmployee === 'new' ? props.create : props.update;
    await action({
      id: selectedEmployee.id,
      full_name: data.fullName,
      initials: data.initials,
      aliases: data.aliases,
    });
    setSelectedEmployee(null);
  };

  const handleRemove = async (row) => {
    const result = await confirm({
      title: 'Remove employee',
      subtitle: 'Are you sure you want to remove employee?',
    });

    if (result) {
      props.remove(row.id);
    }
  };

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="px-tw-5 py-tw-3">
          <div className="flex justify-between">
            <h3>Pipeline Employees</h3>
            <LinkButton onClick={() => setSelectedEmployee('new')}>
              Add new employee
            </LinkButton>
          </div>
        </div>
        <div className="px-tw-5 py-tw-5">
          <div className="table-wrap position-relative">
            <div className="table-container deal-fields-table table-responsive">
              <Table
                items={employees}
                columns={tableColumns({
                  toggleActive: (row) =>
                    props.update({ id: row.id, active: !row.active }),
                  onClick: setSelectedEmployee,
                  onRemove: handleRemove,
                })}
              />
            </div>
          </div>
        </div>
      </CardWrapper>
      {selectedEmployee && (
        <Modal
          header={`${selectedEmployee === 'new' ? 'Add' : 'Update'} Employee`}
          toggle={() => setSelectedEmployee(null)}
          actions={
            <Button form="employeesForm" variant="success" fluid type="submit">
              Submit
            </Button>
          }
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
        >
          <form id="employeesForm" onSubmit={handleSubmit(submit)}>
            <div className="from-group mb-tw-2">
              <label htmlFor="full_name">Full name</label>
              <input
                className="form-control"
                defaultValue={selectedEmployee.fullName}
                id="fullName"
                onChange={(e) => setValue('fullName', e.target.value)}
              />
            </div>
            <div className="from-group mb-tw-2">
              <label htmlFor="initials">Initials</label>
              <input
                className="form-control"
                defaultValue={selectedEmployee.initials}
                id="initials"
                onChange={(e) => setValue('initials', e.target.value)}
              />
            </div>
            <div className="from-group mb-tw-4">
              <label htmlFor="aliases">Aliases</label>
              <input
                className="form-control"
                id="aliases"
                name="aliases"
                defaultValue={selectedEmployee.aliases}
                placeholder="other name which matches the employee"
                onChange={(e) => setValue('aliases', e.target.value)}
              />
              <small>
                This field helps to match employee by short name if he persisted
                in the snapshots like (John Snow vs Jonny Snow)
              </small>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ dealPathSettings }) => ({
  employees: dealPathSettings.employees,
});

const mapActionCreators = {
  create: createDealPathEmployee,
  update: updateDealPathEmployee,
  remove: removeDealPathEmployee,
};
export default connect(mapStateToProps, mapActionCreators)(DealPathEmployees);

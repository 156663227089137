import GroupByPopover from '@/bundles/Settings/components/REport/GeneralLedgers/GroupByPopover';
import GeneralLedgersTable from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GeneralLedgersTable';
import GroupRowInnerRenderer from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GroupRowInnerRenderer';
import { useTableRefCallback } from '@/bundles/Settings/components/REport/GeneralLedgers/hooks/useTableRefCallback';
import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import {
  filterWithLEClass,
  useLEClassUpdateAndInvalidateEntity,
} from '@/bundles/Settings/components/REport/Operational/lib';
import { getErpColumn } from '@/bundles/Settings/components/REport/Operational/lib/getErpColumn';
import { getLegalEntityColumn } from '@/bundles/Settings/components/REport/Operational/lib/getLegalEntityColumn';
import { getPmcColumn } from '@/bundles/Settings/components/REport/Operational/lib/getPmcColumn';
import { useSettingReportOperationTabs } from '@/bundles/Settings/components/REport/Operational/lib/useSettingReportOperationTabs';
import { useSettingReportOperationalPageParams } from '@/bundles/Settings/components/REport/Operational/lib/useSettingReportOperationalPageParams';
import { useSettingsReportOperationalLEOptions } from '@/bundles/Settings/components/REport/Operational/lib/useSettingsReportOperationalLEOptions';
import { BulkSetChargeCodeModal } from '@/bundles/Settings/components/REport/Operational/ui/BulkSetChargeCodeModal';
import { getCheckboxAggridTableColumn } from '@/bundles/Settings/shared/lib/getCheckboxAggridTableColumn';
import { getLEClassAggridTableColumn } from '@/bundles/Settings/shared/lib/getLEClassAggridTableColumn';
import BulkActionsPanel from '@/bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  generateUrlSettingsChargeCodes,
  SourceChargeCode,
  SreChargeCode,
} from '@/bundles/Shared/entities/sreChargeCodes';
import { OPERATIONAL_MAPPING_GROUP_BY_LIST } from '@/bundles/Shared/entities/sreUnitType';
import {
  useGetApiSettingsReportRentRollSourceChargeCodesQuery,
  usePutApiSettingsReportRentRollSourceChargeCodesSetChargeCodeMutation,
} from '@/entities/report/chargeCodes';
import {
  SettingsReportChargeCodesExportButton,
  SettingsReportChargeCodesImportButton,
} from '@/features/report/chargeCodes';
import useGridSelectedRows from '@/lib/ag-grid/useGridSelectedRows';
import { SOURCE_TYPE_SETTINGS } from '@/lib/sourceType';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { GeneralSettingsNavigationSection } from '@/stories';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { omit } from 'lodash-es';
import pluralize from 'pluralize';
import { FC, useMemo, useRef } from 'react';
import SetFilter from '@/bundles/REconcile/components/operational/table/SetFilter';

export const ChargeCodesPage: FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const { openModal, confirm } = useModal();
  const isMappedChargeCodesTab = useSettingReportOperationTabs();
  const {
    pageParams,
    setPageParams,
    setSortParams,
    setGroupByParam,
    setLegalEntityIds,
    setSreChargeCodeIds,
    setPmcIds,
    setErps
  } = useSettingReportOperationalPageParams();

  const tabParams = {
    withChargeCode: isMappedChargeCodesTab,
    withoutChargeCode: !isMappedChargeCodesTab,
  };
  const { data, isFetching, isLoading } =
    useGetApiSettingsReportRentRollSourceChargeCodesQuery({
      ...tabParams,
      ...pageParams,
    });

  const exportParams = {
    ...tabParams,
    ...omit(pageParams, ['page', 'perPage']),
  };
  const onSetClassification =
    useLEClassUpdateAndInvalidateEntity('charge-codes');
  const [updateChargeCodes] =
    usePutApiSettingsReportRentRollSourceChargeCodesSetChargeCodeMutation();

  const items = (data?.items ?? []) as SourceChargeCode[];
  const totalSize = data?.meta.totalSize ?? 0;
  const mappedSourceChargeCodesSize = data?.meta.withChargeCodeSize ?? 0;
  const unmappedSourceChargeCodesSize = data?.meta.withoutChargeCodeSize ?? 0;
  const usedSreChargeCodesSize = data?.meta.usedSreChargeCodesSize ?? 0;
  const sreChargeCodes = data?.meta.sreChargeCodes ?? [];

  const itemsMap = useMemo(() => {
    return new Map(items.map((i) => [i.id, i] as const));
  }, [items]);

  const tableRef = useRef<AgGridReact>();

  const { allLEOptions, selectedLEOptions } =
    useSettingsReportOperationalLEOptions({
      legalEntities: data?.meta.legalEntities ?? [],
      selectedLEIds: pageParams.legalEntityIds,
      deps: [pageParams, data],
    });

  const allSreChargeCodeOptions = useMemo(() => {
    return data?.meta?.sreChargeCodes?.map((ut) => ({ ...ut, value: ut.value, id: ut.value, label: ut.label })) ?? [];
  }, [data]);

  const allErpsOptions = useMemo(() => {
    return data?.meta?.erps?.map((ut) => ({ id: ut, value: ut, label: SOURCE_TYPE_SETTINGS[ut].title })) ?? [];
  }, [data]);

  const allPmcOptions = useMemo(() => {
    return data?.meta?.pmcs?.map((ut) => ({ ...ut, value: ut.id, label: ut.name })) ?? [];
  }, [data]);

  const {
    selectedRows: selectedChargeCodes,
    deselectAll: deselectAllChargeCodes,
    handleRowCheck,
    allShownRowsChecked,
    handleAllShownRowsCheck,
    handleGroupCheck,
    resolveGroupCheck,
  } = useGridSelectedRows<SourceChargeCode>(tableRef, items);

  const refCallBack = useTableRefCallback({
    setSortParams,
    tableRef,
  });

  const selectedChargeCodesWithLEClass = useMemo(() => {
    return filterWithLEClass(selectedChargeCodes);
  }, [selectedChargeCodes]);

  const handleSetBulkChargeCode = async () => {
    const res = await openModal(BulkSetChargeCodeModal, {
      sourceChargeCodes: selectedChargeCodesWithLEClass,
      sreChargeCodes,
    });

    if (res == null) return;

    updateChargeCodes({
      body: {
        charge_code: res,
        source_charge_code_ids: mapListToIds(selectedChargeCodesWithLEClass),
      },
    });
    deselectAllChargeCodes();
  };

  const handleResetBulkChargeCodes = async () => {
    const res = await confirm();

    if (!res) return;

    updateChargeCodes({
      body: {
        charge_code: null,
        source_charge_code_ids: mapListToIds(selectedChargeCodes),
      },
    });
    deselectAllChargeCodes();
  };

  const columnDefsAggrid: ColDef[] = useMemo(() => {
    const columns = [
      getCheckboxAggridTableColumn({
        allChecked: allShownRowsChecked,
        checked: selectedChargeCodes,
        onClickAll: handleAllShownRowsCheck,
        onClick: (item, options) => handleRowCheck(item, options?.withShift),
      }),
      {
        cellClass: 'first',
        colId: 'parseChargeCode',
        headerName: `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm}`,
        field: 'name',
        flex: 1,
        rowGroup: false,
      },
      {
        colId: 'sreChargeCode',
        headerName: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm}`,
        field: 'chargeCode',
        flex: 1,
        rowGroup: false,
        cellRenderer: ({ value, data }) => {
          const item = itemsMap.get(data.id)!;

          return (
            <SreChargeCode
              value={value}
              sreChargeCodes={sreChargeCodes}
              disabled={item?.legalEntity?.classification == null}
              onSubmit={(chargeCode) => {
                updateChargeCodes({
                  body: {
                    charge_code: chargeCode,
                    source_charge_code_ids: [item.id],
                  },
                });
              }}
            />
          );
        },
        headerComponent: allSreChargeCodeOptions.length && isMappedChargeCodesTab ? SetFilter : null,
        headerComponentParams: {
          filterName: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm}`,
          items: allSreChargeCodeOptions,
          value: pageParams.sreChargeCodeIds,
          onChange: setSreChargeCodeIds,
          label: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm}`,
          appendTo: () => document.body,
        },
      },
      getErpColumn({
        group: pageParams.group,
        options: allErpsOptions,
        onChange: setErps,
        value: pageParams.erps,
      }),
      getPmcColumn({
        group: pageParams.group,
        options: allPmcOptions,
        onChange: setPmcIds,
        value: pageParams.pmcIds,
      }),
      getLegalEntityColumn({
        allLEOptions,
        selectedLEOptions,
        setLegalEntityIds,
      }),
      getLEClassAggridTableColumn({
        isLastColumn: !isMappedChargeCodesTab,
        actions: {
          onSetClassification,
        },
      }),
    ];
    if (isMappedChargeCodesTab) {
      columns.push({
        cellClass: 'last',
        colId: 'actions',
        headerName: '',
        field: 'chargeCode',
        cellRenderer: ({ value, data }) => {
          return (
            <SreChargeCode
              onReset={async () => {
                const res = await confirm();
                if (!res) return;
                updateChargeCodes({
                  body: {
                    charge_code: null,
                    source_charge_code_ids: [data.id],
                  },
                });
              }}
              sreChargeCodes={sreChargeCodes}
              showEditButton
              initialValue={value}
              value={value}
              onSubmit={(chargeCode) => {
                updateChargeCodes({
                  body: {
                    charge_code: chargeCode,
                    source_charge_code_ids: [data.id],
                  },
                });
              }}
            />
          );
        },
        width: 40,
      });
    }
    return columns;
  }, [
    isMappedChargeCodesTab,
    allLEOptions,
    selectedLEOptions,
    items,
    selectedChargeCodes,
    allShownRowsChecked,
  ]);

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex flex-grow flex-col gap-tw-4">
        <div className="flex gap-tw-4">
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${mappedSourceChargeCodesSize} ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm,
              mappedSourceChargeCodesSize,
            )}`}
            subtitle={`Mapped with ${usedSreChargeCodesSize} sRE ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm,
              usedSreChargeCodesSize,
            )}`}
            active={isMappedChargeCodesTab}
            onClick={() => {
              navigate(generateUrlSettingsChargeCodes());
            }}
          />
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${unmappedSourceChargeCodesSize} Active ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm,
              unmappedSourceChargeCodesSize,
            )}`}
            subtitle="Not Mapped"
            active={!isMappedChargeCodesTab}
            onClick={() => {
              navigate(generateUrlSettingsChargeCodes(false));
              setSreChargeCodeIds([]);
            }}
          />
        </div>
        <div className="flex flex-grow flex-col gap-tw-4">
          <div className="flex flex-col gap-tw-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <PageParamsPagination
                  loading={isFetching}
                  totalSize={totalSize}
                />
              </div>
              <div className="flex items-center gap-tw-4">
                <GroupByPopover
                  items={OPERATIONAL_MAPPING_GROUP_BY_LIST}
                  value={OPERATIONAL_MAPPING_GROUP_BY_LIST.filter((c) => {
                    return pageParams.group.includes(c.value);
                  })}
                  onChange={setGroupByParam}
                />
                <PageParamsSearch
                  suggestions={[
                    `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm} name`,
                  ]}
                  size="s"
                  placeholder={
                    SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes
                      .singularForm
                  }
                />
                <SettingsReportChargeCodesImportButton />
                <SettingsReportChargeCodesExportButton params={exportParams} />
              </div>
            </div>
          </div>

          <GeneralLedgersTable
            className="ag-theme-light_small-padding h-[50vh] flex-grow"
            ref={refCallBack}
            loading={isLoading}
            fetching={isFetching}
            columnDefs={columnDefsAggrid}
            rowData={items}
            onRowClicked={(e) => {
              const event = e.event as MouseEvent;
              if (!event.shiftKey) return;
              handleRowCheck(e.data, true);
            }}
            groupRowRendererParams={{
              suppressCount: true,
              innerRenderer: GroupRowInnerRenderer,
              handleGroupCheck,
              resolveGroupCheck,
            }}
          />
          {selectedChargeCodes.length > 0 && (
            <BulkActionsPanel
              selectedRows={selectedChargeCodes}
              setSelectedRows={deselectAllChargeCodes}
              actions={[
                {
                  title: `Set ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm}`,
                  icon: 'edit',
                  handleClick: handleSetBulkChargeCode,
                  hidden: selectedChargeCodesWithLEClass.length === 0,
                },
                {
                  title: `Reset ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm}`,
                  icon: 'reset',
                  handleClick: handleResetBulkChargeCodes,
                  hidden:
                    selectedChargeCodes.length === 0 || !isMappedChargeCodesTab,
                },
              ]}
            />
          )}
        </div>
      </div>
    </PageParamsProvider>
  );
};

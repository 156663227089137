import { CategoriesHeader } from '@/bundles/Settings/components/REport/GeneralLedgers/Table/ColumnHeaderComponents/CategoriesHeader';
import { getCheckboxAggridTableColumn } from '@/bundles/Settings/shared/lib/getCheckboxAggridTableColumn';
import { getLEClassAggridTableColumn } from '@/bundles/Settings/shared/lib/getLEClassAggridTableColumn';
import { ExternalSourceType } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IconsId } from '@/types/sre-icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { SetCategoryWithGLsHeader } from 'bundles/Settings/components/REport/GeneralLedgers/SetCategoryWithGLsHeader';
import { ActionsCell } from 'bundles/Settings/components/REport/GeneralLedgers/Table/columns/ActionsCell';
import LineItemsCountCell from 'bundles/Settings/components/REport/GeneralLedgers/Table/columns/LineItemsCountCell';
import SourceTypeColumn from 'bundles/Shared/components/SourceType/SourceTypeColumn/SourceTypeColumn';
import { useCategoryPathById } from 'bundles/Shared/entities/finanicalCategory/lib';
import { FinancialCategory } from 'bundles/Shared/entities/finanicalCategory/model';
import {
  LeClassification,
  filterCategoriesByAssetClass,
} from 'bundles/Shared/entities/leClasssification';
import SetCategoryModal from 'bundles/Shared/widgets/category/setCategory/ui/SetCategoryModal';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { Button, CategoryObject, IconButton } from 'stories';
import { IGeneralLedgerOnIndex } from 'types/GeneralLedger';
import {
  LegalEntityHeaderParams,
  LegalEntitySourceHeaderParams,
} from '../GeneralLedgers';
import type * as Local from '../types';
import { LegalEntityHeader } from './ColumnHeaderComponents/LegalEntityHeader';
import { LegalEntitySourceHeader } from './ColumnHeaderComponents/LegalEntitySourceHeader';

export interface GroupedByTableActions<T> {
  onExcludeClick: (row: T) => void;
  onFlagClick: (row: T) => void;
  onMisclassifiedFilterChange: VoidFunction;
  onFlagFilterChange: VoidFunction;
  onSetCategory?: (
    categoryId: FinancialCategory['id'] | null,
    gl: number[],
  ) => void;
  onDisableMisclassified?: VoidFunction;
  onSetClassification?: (
    classification: LeClassification | null,
    row: T,
  ) => void;
}

export const SourceTypeCell = ({ value }: { value: ExternalSourceType }) => (
  <SourceTypeColumn sourceType={value} />
);

type CategoriesCellParams = Pick<Params, 'actions' | 'categoriesTree'> &
  ICellRendererParams;
const CategoriesCell = ({
  data,
  actions,
  categoriesTree,
}: CategoriesCellParams) => {
  const row = data as IGeneralLedgerOnIndex;
  const { openModal } = useModal();
  const { hovered, onMouseLeave, onMouseOver } = useHovered();
  const path = useCategoryPathById(
    row.reportFinancialCategoryId,
    categoriesTree,
  );
  const handleCategoryEdit = async (e) => {
    const res = await openModal(SetCategoryModal, {
      header: <SetCategoryWithGLsHeader selectedGLs={[row]} />,
      categories: row.legalEntity?.classification
        ? filterCategoriesByAssetClass({
            categories: categoriesTree,
            assetClass: row.legalEntity.classification,
          })
        : categoriesTree,
      selectedCategory: path.find(
        (c) => c.id === row.reportFinancialCategoryId,
      ),
      noSelectedPlaceholder: 'Select Category',
    });
    if (!res) {
      return;
    }
    actions.onSetCategory?.(res.id, [row.id]);
  };

  const resolveIcon = (): IconsId => {
    if (hovered) return row.flagged ? 'flagOff' : 'flag';

    return !row.flagged ? 'flag' : 'flagOn';
  };

  return (
    <div className="flex w-full justify-between gap-tw-2">
      {isEmpty(path) ? (
        <Button
          iconName="link"
          size="xs"
          variant="secondary"
          blurAfterClick
          tooltipProps={{
            disabled: row.legalEntity?.classification != null,
            mainText: 'Start by selecting a class',
          }}
          disabled={row.legalEntity?.classification == null}
          onClick={handleCategoryEdit}
        >
          Set Category
        </Button>
      ) : (
        <CategoryObject path={path.map(({ code }) => code)} />
      )}

      {row.reportFinancialCategoryId && (
        <IconButton onClick={handleCategoryEdit} iconName="edit" />
      )}
      <IconButton
        tooltipProps={{
          mainText: row.flagged ? 'Remove the flag' : 'Mark with a flag',
        }}
        className={cn(
          !row.flagged && 'invisible !text-neutral-500 group-hover:!visible',
          row.flagged && '!text-attention-055',
          hovered && row.flagged && '!text-danger-080',
        )}
        variant="secondary"
        onClick={() => actions.onFlagClick(row)}
        iconName={resolveIcon()}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    </div>
  );
};

export const GENERAL_LEDGERS_COLUMN_IDS = {
  accNumber: 'acc_number',
  accTitle: 'acc_title',
  legalEntitySource: 'source',
  itemsCount: 'line_items_count',
  legalEntityName: 'legal_entity_id',
  legelEntityClassification: 'legal_entity_classification',
  categories: 'category' as Local.GLGroupItem,
  actions: 'actions',
} as const;

export const AUTOSIZED_GENERAL_LEDGERS_COLUMN_IDS = [
  GENERAL_LEDGERS_COLUMN_IDS.legalEntitySource,
];

interface Params {
  actions: GroupedByTableActions<IGeneralLedgerOnIndex>;
  categoriesTree: FinancialCategory[];
  generalLedgersScope: Local.GLScope;
  generalLedgersGroup: Local.GLGroup | undefined;
  legalEntityHeaderParams: LegalEntityHeaderParams;
  legalEntitySourceHeaderParams: LegalEntitySourceHeaderParams;
  flaggedSize: number;
  meta: {
    misclassifiedSize: number;
  };
  flaggedMeta: {
    misclassified: boolean;
  };
  selection: {
    selectedRows: IGeneralLedgerOnIndex[];
    handleRowCheck: (glRow: IGeneralLedgerOnIndex) => void;
    handleAllShownRowsCheck: () => void;
    allShownRowsChecked: boolean;
  };
  generalLedgersFlagged?: boolean;
}

export const useColumnDefs = ({
  actions,
  categoriesTree,
  flaggedSize,
  generalLedgersScope,
  generalLedgersGroup,
  generalLedgersFlagged,
  legalEntityHeaderParams,
  legalEntitySourceHeaderParams,
  meta,
  flaggedMeta,
  selection: {
    selectedRows,
    allShownRowsChecked,
    handleRowCheck,
    handleAllShownRowsCheck,
  },
}: Params) => {
  return useMemo<ColDef[]>(
    () => [
      getCheckboxAggridTableColumn({
        allChecked: allShownRowsChecked,
        checked: selectedRows,
        onClickAll: handleAllShownRowsCheck,
        onClick: (item) => handleRowCheck(item),
      }),
      {
        colId: GENERAL_LEDGERS_COLUMN_IDS.accNumber,
        headerName: 'Account #',
        field: 'accNumber',
        cellClass: 'first',
        enableRowGroup: true,
        rowGroup:
          generalLedgersGroup?.find((item) => item === 'acc_number') &&
          generalLedgersScope === 'active-and-not-mapped',
        sortable: true,
        width: 145,
      },
      {
        colId: GENERAL_LEDGERS_COLUMN_IDS.accTitle,
        headerName: 'Account Title',
        field: 'accTitle',
        flex: 1,
        enableRowGroup: true,
        rowGroup:
          generalLedgersGroup?.find((item) => item === 'acc_title') &&
          generalLedgersScope === 'active-and-not-mapped',
        sortable: true,
      },
      {
        colId: GENERAL_LEDGERS_COLUMN_IDS.legalEntitySource,
        headerName: 'Source',
        field: 'legalEntity.source',
        headerComponent: legalEntitySourceHeaderParams.allSources.length
          ? LegalEntitySourceHeader
          : null,
        headerComponentParams: legalEntitySourceHeaderParams,
        cellRenderer: SourceTypeCell,
      },
      {
        colId: GENERAL_LEDGERS_COLUMN_IDS.itemsCount,
        headerName: 'Items count',
        field: 'lineItemsCount',
        width: 120,
        cellRenderer: LineItemsCountCell,
      },
      {
        colId: GENERAL_LEDGERS_COLUMN_IDS.legalEntityName,
        headerName: 'Legal Entity',
        field: 'legalEntity.name',
        flex: 1,
        headerComponent: legalEntityHeaderParams.allLEOptions.length
          ? LegalEntityHeader
          : null,
        headerComponentParams: legalEntityHeaderParams,
      },
      getLEClassAggridTableColumn({
        actions,
        misclassified: flaggedMeta.misclassified,
        misclassifiedSize: meta.misclassifiedSize,
      }),
      {
        minWidth: 200,
        colId: GENERAL_LEDGERS_COLUMN_IDS.categories,
        headerName: 'Categories',
        valueGetter: ({ data }: { data: IGeneralLedgerOnIndex }) =>
          data.reportFinancialCategoryId,
        maxWidth:
          generalLedgersScope === 'active-and-not-mapped' ? 200 : undefined,
        flex: generalLedgersScope === 'active-and-not-mapped' ? undefined : 1,
        rowGroup: generalLedgersScope === 'active-and-mapped',
        headerComponent: CategoriesHeader,
        headerComponentParams: {
          actions,
          categoriesTree,
          flaggedSize,
          generalLedgersFlagged,
        },
        equals: (a, b) =>
          a?.flagged === b?.flagged &&
          a?.reportFinancialCategoryId === b?.reportFinancialCategoryId,
        cellRenderer: CategoriesCell,
        cellRendererParams: {
          actions,
          categoriesTree,
          generalLedgersFlagged,
          selectedGls: selectedRows,
        },
      },
      {
        colId: GENERAL_LEDGERS_COLUMN_IDS.actions,
        headerName: 'actions',
        width: 80,
        cellRenderer: ActionsCell,
        cellClass: 'last',
        cellRendererParams: {
          actions,
        },
      },
    ],
    [
      categoriesTree,
      generalLedgersScope,
      legalEntityHeaderParams,
      legalEntitySourceHeaderParams,
      flaggedSize,
      generalLedgersFlagged,
    ],
  );
};

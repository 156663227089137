import { MediaWidget } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidget';
import { MediaWidgetConfigCard } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidgetConfigCard';
import { WidgetConfiguration } from '@/bundles/Shared/widgets/dashboard/widgets/model';

export const MEDIA_WIDGET_CONFIG = {
  icon: 'newIconText',
  title: 'Media',
  Component: MediaWidget,
  CardConfigComponent: MediaWidgetConfigCard,
  layout: {
    minW: 1,
    maxW: 2,
    minH: 12,
    maxH: 12,
    w: 1,
    h: 12,
  },
} as const satisfies WidgetConfiguration;

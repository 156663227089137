import { FinancialsFullHistoryButton } from '@/bundles/REport/components/financials/widgets/fullHistory/Button';
import { FinancialsShowPublishedDataButton } from '@/bundles/REport/components/financials/widgets/publishedData/Button';
import {
  ALL_VIEW_PARAM_TYPE,
  ViewParamType,
  _ObjectableType,
  onSelectMore,
  onSelectOne,
  onSelectOneObject,
  selectIsSingleMode,
  selectReportTableConfig,
  selectSelectedLegalEntities,
  selectSelectedObjects,
  updateBulkObjects,
  updateViewParamType
} from '@/bundles/REport/reducers/financialsSlice';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { useGetTablePeriodsQuery } from 'bundles/REport/api/financialsApi';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import ListLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import LegalEntitiesIconWithTooltip from 'bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import { currentUserIsExternal } from 'lib/permissions';
import { isEqual, sortBy } from 'lodash-es';
import { ComponentProps, useState } from 'react';
import FavoriteCardIcon from 'stories/ProjectCard/FavoriteCardIcon';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  LinkButton,
  Popover,
  ProjectCard,
  SearchInput,
  ThinTabGroup,
} from 'stories/index';
import { Maybe } from 'yup/lib/types';
import * as Const from './consts';
import { REPORT_FINANCIALS_CARD_LIST_JSX } from './dumbJSX';
import { useComputedObjects } from './hooks/useComputedObjects';
import { ObjAndStateProps } from './types';
import {
  areReportFinancialsObjectsEqual as areObjectsEqual,
  createReportFinancialsObjIdAndType as createObjIdAndType,
} from './utils';
import { FinancialsDatePicker } from './widgets/FinancialsDatePicker';

export function CardImg({
  type,
  imgSrc,
}: {
  type: _ObjectableType;
  imgSrc: Maybe<string>;
}) {
  return (
    <div className="flex h-[56px] w-[56px] rounded-[8px] border border-solid border-x-neutral-100 p-tw-1 shadow-z-020">
      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded bg-neutral-200 ">
        {imgSrc ? (
          <img src={imgSrc} alt={type} className="h-full w-full object-cover" />
        ) : (
          <Icon
            iconName={Const.OBJECTABLE_TYPE_ICON_NAME_MAP[type]}
            className="header4-regular"
          />
        )}
      </div>
    </div>
  );
}

export const CheckboxWithRefreshButton = (
  props: Pick<ComponentProps<typeof Checkbox>, 'checked' | 'onChange'> &
    Partial<
      Pick<
        ComponentProps<typeof ReportFinancialCard>,
        'onRefresh' | 'showRefreshTip'
      >
    >,
) => {
  return (
    <div className="flex gap-tw-4" onClick={(e) => e.stopPropagation()}>
      <Popover
        hideOnClick={false}
        appendToBody
        disabled={!props.showRefreshTip}
        placement="right"
        trigger="mouseenter"
        classes={{ spanContainer: '!grid' }}
        className={props.checked ? '' : 'hidden'}
        template={<LinkButton onClick={props.onRefresh}>Refresh</LinkButton>}
      >
        <Checkbox labelClassName="flex !self-center" {...props} />
      </Popover>
    </div>
  );
};

export function ReportFinancialCard({
  object,
  selected,
  onSelectMultiMode,
  onFavorite,
  favorite,
  onSelectSingleMode,
  isSingleMode,
  onRefresh,
  showRefreshTip,
}: {
  object: ObjAndStateProps;
  selected: boolean;
  onSelectMultiMode: () => void;
  onFavorite: ComponentProps<typeof Icon>['onClick'];
  onSelectSingleMode: () => void;
  onRefresh: () => void;
  favorite: boolean;
  isSingleMode: boolean;
  showRefreshTip: boolean;
}) {
  const { legalEntities, _type: type } = object;

  return (
    <button
      onClick={isSingleMode ? onSelectSingleMode : onSelectMultiMode}
      className={cn(
        'relative grid border-none bg-white p-0 text-left text-neutral-550 transition-all',
        selected && 'bg-neutral-050',
      )}
    >
      <ProjectCard
        imagePlaceholder="" // to disable "imagePlaceholder" in "InvestmentObjectImage"
        fallbackNode={
          <Icon
            iconName={Const.OBJECTABLE_TYPE_ICON_NAME_MAP[type]}
            className="header1-regular text-neutral-550"
          />
        }
        pictureUrl={
          'smallPictureUrl' in object ? object.smallPictureUrl : undefined
        }
        header={
          <div className="flex items-start justify-between gap-tw-1">
            <p className="body-semibold text-neutral-800">
              {'name' in object ? object.name : object.title}
            </p>
            <div className="flex items-center gap-tw-1">
              <div className="flex items-center gap-tw-1">
                <LegalEntitiesIconWithTooltip legalEntities={legalEntities} />
              </div>
              <FavoriteCardIcon
                onClick={onFavorite}
                selected={favorite}
                className="border-neutral-100 shadow-z-020"
              />
              {!isSingleMode && (
                <CheckboxWithRefreshButton
                  onRefresh={onRefresh}
                  showRefreshTip={showRefreshTip}
                  checked={selected}
                  onChange={onSelectMultiMode}
                />
              )}
            </div>
          </div>
        }
        selected={selected}
      >
        <ListLayout.NavigationCardList.Label
          color={Const.OBJECTABLE_TYPE_COLOR_MAP[type]}
          text={type}
        />
      </ProjectCard>
    </button>
  );
}

const OBJECTABLE_TYPES = [
  {
    id: ALL_VIEW_PARAM_TYPE,
    label: 'All',
  },
  {
    id: 'asset',
    label: <Icon iconName="asset" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Assets',
    },
  },
  {
    id: 'fund',
    label: <Icon iconName="funds" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Funds',
    },
  },
  {
    id: 'segment',
    label: <Icon iconName="segments" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Segments',
    },
  },
  {
    id: 'favorite',
    label: <Icon iconName="favoriteFilled" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Favorites',
    },
  },
] as const satisfies readonly IThinTabItem[];

const changeObjSelected = (
  obj: ObjAndStateProps,
  _selected: boolean,
): ObjAndStateProps => ({
  ...obj,
  _selected,
  legalEntities: obj.legalEntities.map((le) => ({ ...le, _selected })),
});
const makeObjSelected = (obj: ObjAndStateProps) => changeObjSelected(obj, true);
const makeObjUnselected = (obj: ObjAndStateProps) =>
  changeObjSelected(obj, false);

export function CardList({ isLoading }: { isLoading: boolean }) {
  const { isItemFavorite, toggleItemFavorite } = useFavoriteItemIds(
    'reportFinancialsObjectIdAndType',
  );
  const financialsSlice = useAppSelector((state) => state.financials);
  const selectedObjects = useAppSelector(selectSelectedObjects);
  const selectedLegalEntities = useAppSelector(selectSelectedLegalEntities);
  const reportTableConfig = useAppSelector(selectReportTableConfig);
  const { viewParamType, objects, showUnpublished } = financialsSlice;
  const isSingleMode = useAppSelector(selectIsSingleMode);
  const [items, setItems] = useState<ObjAndStateProps[]>([]);
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState('');

  const { isFetching: isTablePeriodsDataFetching } = useGetTablePeriodsQuery(
    {
      reportTableConfigId: reportTableConfig?.id,
      show_unpublished: showUnpublished,
      legal_entity_ids: selectedLegalEntities.map((l) => l.id),
    },
    {
      skip: selectedLegalEntities.length === 0,
    },
  );
  const shownObjects = useComputedObjects({
    query,
  });

  const handleViewParamTypeClick = (type: ViewParamType) => {
    dispatch(
      updateViewParamType(viewParamType === type ? ALL_VIEW_PARAM_TYPE : type),
    );
  };

  const handleSelectMore = () => {
    setItems(objects);
    dispatch(onSelectMore());
  };

  const handleSelectObjectMultiMode = (object: ObjAndStateProps) => {
    setItems((prev) =>
      prev.map((i) => {
        if (areObjectsEqual(object, i)) {
          return i._selected ? makeObjUnselected(i) : makeObjSelected(i);
        }
        return i;
      }),
    );
  };

  const handleSelectOne = () => {
    dispatch(onSelectOne());
  };

  const handleSelectOneObject = (obj: ObjAndStateProps) => {
    dispatch(onSelectOneObject(obj));
  };

  const handleRefresh = () => {
    dispatch(updateBulkObjects(items));
  };

  if (isLoading) return REPORT_FINANCIALS_CARD_LIST_JSX;

  const selectedItems = items.filter((i) => i._selected);

  const resolveSelectedObjectsSynced = (): boolean => {
    if (selectedItems.length !== selectedObjects.length) return false;

    const uniqIds = sortBy(selectedItems.map(createObjIdAndType));
    const uniqIds2 = sortBy(selectedObjects.map(createObjIdAndType));

    return isEqual(uniqIds, uniqIds2);
  };

  const isSelectedObjectsSynced = resolveSelectedObjectsSynced();
  return (
    <>
      <div className="flex flex-col gap-tw-4">
        <div className="flex items-center gap-tw-2">
          <SearchInput
            size="s"
            className="col-start-2 h-max w-full grow"
            value={query}
            resetValue={() => setQuery('')}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search"
            inputClassName="shadow-z-020"
            suggestions={[
              'Asset, Fund, Segment',
              'Legal Entity (name or code)',
            ]}
          />
          {!currentUserIsExternal() && <FinancialsFullHistoryButton />}
          {!currentUserIsExternal() && <FinancialsShowPublishedDataButton />}
        </div>

        <div className="flex items-center justify-between gap-tw-4">
          <FinancialsDatePicker
            possiblePeriodsFetching={isTablePeriodsDataFetching}
          />
        </div>
      </div>

      <ThinTabGroup
        classes={{
          itemContainer: 'flex grow',
          item: 'flex grow items-center justify-center',
        }}
        items={OBJECTABLE_TYPES}
        onSelectedItemChange={(option) => {
          handleViewParamTypeClick(option.id as ViewParamType);
        }}
        selectedItem={viewParamType}
      />

      <div className="flex min-h-[21px] items-center justify-between ">
        <ListLayout.NavigationCardList.ShownItemsInfo
          length={shownObjects.length}
        />
        {isSingleMode && (
          <LinkButton onClick={handleSelectMore}>Select More</LinkButton>
        )}
        {!isSingleMode && (
          <div className="flex items-center gap-tw-2">
            <span className="inline-regular text-neutral-900">
              Selected: {selectedItems.length}
            </span>
            <IconButton
              onClick={handleSelectOne}
              iconName="closeSmall"
              variant="secondary"
            />
          </div>
        )}
      </div>

      {shownObjects.length === 0 && <NoDataOverlay title="Nothing Found" />}

      {!isSingleMode && !isSelectedObjectsSynced && (
        <Button
          onClick={handleRefresh}
          variant="primary"
          className="absolute bottom-tw-10 z-50 w-[calc(100%-8rem)] self-center"
        >
          Refresh ({selectedItems.length})
        </Button>
      )}

      <ListLayout.NavigationCardList className="rounded-2xl">
        {shownObjects.map((object) => (
          <ReportFinancialCard
            object={object}
            favorite={isItemFavorite(createObjIdAndType(object))}
            onFavorite={(e) => {
              e.stopPropagation();
              e.preventDefault();
              toggleItemFavorite(createObjIdAndType(object));
            }}
            selected={
              isSingleMode
                ? (object._firstSelected ?? false) && object._selected
                : items.find((i) => areObjectsEqual(object, i))?._selected ??
                  false
            }
            onSelectMultiMode={() => handleSelectObjectMultiMode(object)}
            key={createObjIdAndType(object)}
            isSingleMode={isSingleMode}
            onSelectSingleMode={() => {
              handleSelectOneObject(object);
            }}
            onRefresh={handleRefresh}
            showRefreshTip={!isSelectedObjectsSynced}
          />
        ))}
      </ListLayout.NavigationCardList>
    </>
  );
}

import React, { ReactNode } from 'react';
import { Icon } from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import { IconsId } from 'types/sre-icons';

interface PMCCardLineProps extends React.PropsWithChildren {
  iconName: IconsId;
}

const IconWrapper = ({ children }) => (
  <div className="flex h-[24px] w-[24px] items-center justify-center rounded-lg bg-neutral-050">
    {children}
  </div>
);

const Line = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div className={cn('flex items-center gap-tw-2', className)} {...props}>
    {children}
  </div>
);

export const PMCCardLine = ({
  iconName,
  children,
  ...props
}: PMCCardLineProps) => (
  <PMCCardLine.Line className="items-start" {...props}>
    <PMCCardLine.IconWrapper>
      <Icon iconName={iconName} />
    </PMCCardLine.IconWrapper>
    {children}
  </PMCCardLine.Line>
);

PMCCardLine.IconWrapper = IconWrapper;
PMCCardLine.Line = Line;

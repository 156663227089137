import {
  PipelineCard,
  UpdatePipelineCardResp,
} from 'bundles/Scoreboard/Pipeline/types';

export const updatePipelineCards = (
  updatedCard: UpdatePipelineCardResp,
  cards: PipelineCard[],
) => {
  const { schema, ...fields } = updatedCard;

  return cards.map((card) => {
    if (card.id === updatedCard.id) {
      return {
        ...fields,
        data: schema,
      };
    }
    return card;
  });
};

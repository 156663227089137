import React, { useRef } from 'react';
import MainMenuItem from 'bundles/Shared/components/LeftSidebar/items/MainMenuItem';
import UserModal from 'bundles/Shared/components/Nav/Modals/UserModal';
import UserCard from 'bundles/Shared/components/LeftSidebar/cards/UserCard';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { SreAvatar } from 'stories';
import Icon from 'stories/Icon/Icon';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import { useHoverSaveArea } from 'bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { cn } from '@/shared/lib/css/cn';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';

function UserMenuItem() {
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const { openModal } = useModal();
  const { genericData } = useGenericData();
  const { currentUser } = genericData;
  const { fullName } = currentUser;
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const { triggered, ...handlers } = usePopoverTriggered();
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea();
  return (
    <>
      <MainMenuItem
        ref={menuItemRef}
        label={fullName}
        onClick={() => openModal(UserModal, {})}
        className={cn(
          hoverClasses,
          triggered && 'left-sidebar__main-menu-item_hovered',
        )}
        onMouseMove={onMouseMove}
        icon={
          <SreAvatar
            backgroundColor="#2CAE4C"
            color="#F5F5F6"
            borderRadius="0.5rem"
            height="1.625rem"
            width="1.625rem"
            text={fullName}
            fontSize="0.75rem"
            fontWeight="600"
          />
        }
        rightIcon={<Icon iconName="arrowRight" />}
      />
      {isMediumWidth && (
        <MenuItemPopover
          {...handlers}
          reference={menuItemRef}
          placement="right"
          template={<UserCard currentUser={currentUser} />}
          maxWidth="none"
        />
      )}
    </>
  );
}

export default UserMenuItem;

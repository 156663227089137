import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Modal, Input } from 'stories';
import EntityContext from 'bundles/Shared/EntityContext';
import { createFolder, updateFolder } from '../../actions';

const NewAlbumModal = ({
  setOpened,
  create,
  update,
  folder,
  fetchAlbums,
  parentId,
}) => {
  const investmentEntity = useContext(EntityContext);

  const {
    handleSubmit, register, watch, setValue, formState: { errors },
  } = useForm({
    defaultValues: {
      title: folder?.title,
      description: folder?.description,
    },
  });

  const onSubmit = async (formData) => {
    const data = {
      folder: {
        ...formData,
        id: folder?.id,
        folderable_id: investmentEntity.entity.id,
        folderable_type: investmentEntity.type,
        folder_type: 'media',
        parent_id: parentId,
      },
    };
    folder ? await update(data) : await create(data);
    fetchAlbums();
    setOpened(false);
  };

  const fields = watch();

  return (
    <Modal
      toggle={() => setOpened(false)}
      header={`${folder ? 'Edit' : 'Create'} Album`}
      actions={(
        <Button type="submit" variant="success" fluid onClick={handleSubmit(onSubmit)}>
          {folder ? 'Update' : 'Create'} Album
        </Button>
      )}
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Album Title
          <span className="red">*</span>
        </label>
        <Input
          size="l"
          {...register('title', { required: true })}
          value={fields.title}
          onChange={(e) => setValue('title', e.target.value)}
          placeholder="Enter Album Title"
          className={`${errors.title ? 'is-invalid' : ''} bg-white`}
        />
        <div className="invalid-feedback">Please enter the Album Title</div>

        <label className="mt-tw-4">Description </label>
        <Input
          size="l"
          value={fields.description}
          onChange={(e) => setValue('description', e.target.value)}
          placeholder="Enter description"
          className={`${errors.description ? 'is-invalid' : ''} bg-white`}
        />
      </form>
    </Modal>
  );
};

const mapActionCreators = {
  create: createFolder,
  update: updateFolder,
};

export default connect(null, mapActionCreators)(NewAlbumModal);

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isEmpty } from 'lodash-es';
import { ICellEditorReactComp } from 'ag-grid-react';
import { ICellEditorParams } from 'ag-grid-community';
import { cn } from '@/shared/lib/css/cn';
import styles from './CellInput.module.module.scss';

interface Props extends ICellEditorParams {
  height: number;
  value: string | null | undefined | number;
}

const BORDER_WIDTH = 2;

const CellInput = forwardRef<any, Props>(({ ...props }, ref) => {
  const [value, setValue] = useState(() => {
    if (props.value === undefined || props.value == null) {
      return props.value;
    }

    return Number(props.value);
  });
  const refInput = useRef<HTMLInputElement | null>(null);
  const height = props.height ? props.height - BORDER_WIDTH : '100%';

  useEffect(() => {
    // focus on the input
    refInput.current?.focus();
  }, []);

  useImperativeHandle<any, ICellEditorReactComp>(ref, () => ({
    getValue() {
      return value;
    },
  }));

  const handleChange = (newValue: string) => {
    if (isEmpty(newValue)) {
      setValue(null);
    } else {
      const number = Number(newValue);
      if (Number.isNaN(number)) {
        return;
      }
      setValue(number);
    }
  };

  return (
    <div className="h-full">
      <span className={styles.dollar}>$</span>
      <input
        type="number"
        ref={refInput}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        style={{ height }}
        className={cn(styles.input, 'w-full')}
      />
    </div>
  );
});

export default CellInput;

import React from 'react';
import UserFireStationFilterButton from 'bundles/Shared/components/Filters/buttons/userFireStationFilterButton/UserFireStationFilterButton';
import {
  IFilterComponentProps,
  IRowBase,
} from 'bundles/Shared/components/Table/types';
import { TFireStationPriority } from 'bundles/FireStation/types';

interface Props<R extends IRowBase> extends IFilterComponentProps<R> {
  count: number;
  priority?: TFireStationPriority;
}

export type TQuickFilterFireButtonProps<R extends IRowBase> = Omit<
  Props<R>,
  keyof IFilterComponentProps<R>
>;

export const getFireField = <T extends string>(field: T) =>
  `${field}_fire` as const;

function QuickFilterFireButton<R extends IRowBase>({
  count,
  priority,
  ...props
}: Props<R>) {
  const filterModelField = getFireField(props.column.dataField);
  if (count === 0) {
    return null;
  }
  return (
    <div className="ml-s">
      <UserFireStationFilterButton
        priority={priority}
        filtered={props.filterModel[filterModelField]}
        onClick={() =>
          props.setFilterModel({
            ...props.filterModel,
            [filterModelField]: true,
          })
        }
        onClose={() =>
          props.setFilterModel({
            ...props.filterModel,
            [filterModelField]: undefined,
          })
        }
      >
        {count}
      </UserFireStationFilterButton>
    </div>
  );
}

export default QuickFilterFireButton;

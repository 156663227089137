import React, { FC, useState } from 'react';
import {
  Badge,
  Tooltip,
  Icon,
  Button,
  ConfirmationModal,
  AnimationLoader,
} from 'stories';
import { navigate, useParams } from '@reach/router';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { replacePathParams, useNavigation } from '@/shared/lib/hooks/useNavigation';
import defaultStyles from 'bundles/Construction/styles.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { formatDate } from '@/shared/lib/formatting/dates';
import { formatAmount } from '@/shared/lib/formatting/number';
import { capitalize } from 'lodash-es';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  useGetInvoiceQuery,
  useApproveInvoiceMutation,
  useDeleteInvoiceMutation,
} from 'bundles/Construction/api/invoices';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import DocumentBadge from 'bundles/Construction/components/Shared/DocumentBadge/DocumentBadge';
import { calcTotal, invoiceStatuses } from './initData';
import styles from './invoice/Invoice.module.scss';
import TableLineItems from './invoice/TableLineItems';
import InvoiceRequisitionModal from './requisition/InvoiceRequisitionModal';
import {
  hasRequisition,
  isInvoiceInDraw,
  isInvoiceVerify,
} from 'bundles/Shared/entities/invoice/lib';

interface IInvoiceProps {
  backRoute: string;
  hideActionButton?: boolean;
  invoicesFlow?: boolean;
  payableFlow?: boolean;
  routingV2?: boolean;
  requisitionFlow?: boolean;
}

const Invoice: FC<IInvoiceProps> = ({
  backRoute,
  requisitionFlow,
  payableFlow,
  hideActionButton,
  invoicesFlow,
  routingV2,
}) => {
  const { openModal } = useModal();
  const legalEntity = useAppSelector(
    (store) => store.developmentBudget.legalEntity,
  );

  const params = useParams();

  const { data: invoice, isLoading } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode || legalEntity.code,
    invoiceId: params?.id || params?.invoiceId || params?.['*'],
  });
  const [approveInvoice, { isLoading: isLoadingApprove }] =
    useApproveInvoiceMutation();

  const navigation = useNavigation();

  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const destroyHandle = (status: boolean) => {
    if (status) {
      deleteInvoice({
        invoiceId: params.id,
        legalEntityCode: params.legalEntityCode,
      });
      navigate('../invoices');
    } else {
      setShowConfirmModal(false);
    }
  };

  const closeInvoice = () => {
    if (routingV2) {
      navigate(
        replacePathParams(backRoute, {
          legalEntityCode: params.legalEntityCode,
          requisitionId: params.requisitionId,
        }),
      );
    } else {
      navigate(
        navigation.getUrl(backRoute, {
          legalEntityCode: params?.legalEntityCode,
          requisitionId: params?.requisitionId,
          categoryId: params?.categoryId,
          drawId: params?.drawId,
          type: params?.type,
        }),
      );
    }
  };

  const verifyHandle = async () => {
    navigate(`verify/${invoice?.id}`);
  };

  const approveHandle = async () => {
    await approveInvoice({
      legalEntityCode: params?.legalEntityCode,
      invoiceId: params.id,
    });
    navigate('../invoices');
  };

  const requisitionHandle = async () => {
    openModal(InvoiceRequisitionModal, {
      params: {
        ...params,
        id: params?.id || params['*'],
      },
      type: hasRequisition(invoice) ? 'remove' : 'add',
      returnFunc:
        backRoute !== 'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES'
          ? closeInvoice
          : null,
    });
  };

  const editInvoiceHandle = () => {
    navigate(`edit/${params?.id}`);
  };

  const actionButton = {
    verified: {
      title: 'Verify',
      variant: 'success',
      onClick: verifyHandle,
      hide: false,
    },
    approved: {
      title: 'Approve',
      variant: 'success',
      onClick: approveHandle,
      hide: false,
    },
    requisition: {
      title: hasRequisition(invoice)
        ? 'Remove from Requisition'
        : 'Add to Requisition',
      variant: hasRequisition(invoice) ? 'danger' : 'success',
      onClick: requisitionHandle,
      hide:
        (payableFlow && isInvoiceVerify(invoice)) || isInvoiceInDraw(invoice),
    },
  };

  const typeButton = () => {
    if (requisitionFlow) {
      return 'requisition';
    }
    if (invoice?.status === 'verified') {
      return 'approved';
    }
    if (invoice?.status === 'approved') {
      return 'requisition';
    }
    return 'verified';
  };

  if (isLoading) return <AnimationLoader />;
  return (
    <div>
      <FilePreview
        file={{
          url: invoice?.document?.url,
        }}
        handleClose={closeInvoice}
        header={
          <div>
            <div className="dark-60 font-weight-700 word-break header6-bold">
              Invoice #{invoice?.number}
            </div>
          </div>
        }
      >
        <div className={styles.invoice}>
          <div>
            <div className={styles.infoBlock}>
              <div className="row mb-m">
                <div className="col-3">
                  <div className={cn(styles.title, 'mb-xs')}>Status</div>
                  <Badge
                    backgroundColor={`var(--${invoiceStatuses[invoice?.status]
                      ?.color})`}
                  >
                    {capitalize(invoice?.status || '')}
                  </Badge>
                </div>
                {invoice.document && (
                  <div className="col-6">
                    <div className={cn(styles.title, 'mb-xs')}>Invoice</div>
                    <DocumentBadge
                      titleText={invoice.document.title}
                      title={invoice.document.title}
                      url={invoice.document.url}
                      classNameWrapper={styles.documentInvoice}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-3">
                  <div className={styles.title}>Invoice Date</div>
                  <div className="light-90 inline-regular">
                    {formatDate(invoice.date, 'MM.DD.YYYY')}
                  </div>
                </div>
                <div className="col-6">
                  <div className={cn(styles.title, 'mb-xs')}>Vendor</div>
                  <Tooltip titleText={invoice.vendor.name}>
                    <div className={defaultStyles.invoiceCompany}>
                      <Icon
                        className={cn(
                          'light-60',
                          defaultStyles.invoiceCompanyIcon,
                        )}
                        iconName="bag"
                      />
                      <span className="secondary-regular text-ellipsis">
                        {invoice.vendor.name}
                      </span>
                    </div>
                  </Tooltip>
                </div>
                <div className="col-3 text-right">
                  <div className="pr-m">
                    <div className={styles.title}>Amount</div>
                    <div className="light-90 inline-regular">
                      {formatAmount(invoice.amount)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.lineItems}>
              <div>
                {invoice?.requisition && (
                  <div className="mb-m">
                    <div className="dark-60 inline-semibold">Requisition</div>
                    <div className={styles.createdManuallyLabel}>
                      {formatDate(
                        invoice?.requisition.beginningDate,
                        'DD.MM.YYYY',
                      )}{' '}
                      -{' '}
                      {formatDate(
                        invoice?.requisition.endingDate,
                        'DD.MM.YYYY',
                      )}
                    </div>
                  </div>
                )}
                {!invoice.requisition && invoice.status !== 'created' && (
                  <div className="mb-m">
                    <div className="light-60 inline-semibold">
                      No Requisition
                    </div>
                    <div className={styles.createdManuallyLabel}>
                      Created Manually
                    </div>
                  </div>
                )}
                {invoice.lineItems.length ? (
                  <div>
                    <TableLineItems items={invoice.lineItems} />
                    <div className="secondary-regular mt-s pr-m text-right">
                      Total
                      <span className="dark-60 pl-xs">
                        {formatAmount(calcTotal(invoice.lineItems))}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={styles.noVerifyInvoice}>
                    <div className="light-60 header5-regular mb-s">
                      No Development Line Items yet
                    </div>
                    <div className="light-60 body-regular mb-l">
                      To create Line Items, click Verify
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="px-tw-6 pb-tw-6">
            {invoicesFlow && invoice?.status === 'created' && (
              <div className="px-tw-6 pb-tw-3">
                <Button
                  variant="secondary"
                  className="w-full"
                  onClick={editInvoiceHandle}
                >
                  Edit
                </Button>
              </div>
            )}
            {!hideActionButton && !actionButton[typeButton()].hide && (
              <div className={styles.button}>
                <Button
                  variant={actionButton[typeButton()].variant}
                  className="w-full"
                  onClick={actionButton[typeButton()].onClick}
                  disabled={isLoadingApprove}
                >
                  <div className="flex items-center">
                    {isLoadingApprove && (
                      <Icon
                        className="form-button-loading mr-s"
                        iconName="sync"
                      />
                    )}
                    {actionButton[typeButton()].title}
                  </div>
                </Button>
              </div>
            )}
            {invoicesFlow && invoice?.status === 'created' && (
              <div
                className="inline-semibold mx-tw-6 mt-tw-4 flex cursor-pointer items-center justify-center"
                onClick={() => setShowConfirmModal(true)}
              >
                <Icon className="mr-tw-2 pb-tw-0.5" iconName="trash" />
                Delete Invoice
              </div>
            )}
          </div>
          {showConfirmModal && (
            <ConfirmationModal
              title="Delete Invoice"
              subtitle={
                <div>Are you sure you want to delete this Invoice?</div>
              }
              actions={{
                primaryButton: {
                  text: 'Yes',
                  variant: 'danger',
                },
                secondaryButton: {
                  text: 'No',
                  variant: 'secondary',
                },
              }}
              handleResolve={destroyHandle}
            />
          )}
        </div>
      </FilePreview>
    </div>
  );
};

export default Invoice;

import { AnyAction } from 'redux';
import type { IAssetStage } from 'types/AssetStage';
import type { IDocumentType } from 'types/DocumentType';
import type { IRestrictProduct, IFeedType } from 'types/CustomerConfig';
import { FETCH_CUSTOMER_CONFIG } from '../actions';
import {
  TOGGLE_ASSET_STAGE,
  CREATE_ASSET_STAGE,
  UPDATE_ASSET_STAGE,
  DELETE_ASSET_STAGE,
  UPDATE_POSITIONS_ASSET_STAGE,
} from '../../Settings/actions/assetStage';
import {
  CREATE_FEED_TYPE,
  DELETE_FEED_TYPE,
  UPDATE_FEED_TYPE,
} from '../../Settings/actions/feedType';
import {
  CREATE_DOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
  DELETE_DOCUMENT_TYPE,
} from '../../Settings/actions/documentType';

export interface ICustomerConfigState {
  documentTypes: IDocumentType[];
  assetStages: IAssetStage[];
  feedTypes: IFeedType[];
  mediaDocumentTypes: IDocumentType[];
  restrictProducts: IRestrictProduct[];
}

const initialState: ICustomerConfigState = {
  documentTypes: [],
  assetStages: [],
  feedTypes: [],
  mediaDocumentTypes: [],
  restrictProducts: [],
};

export default (
  state = initialState,
  { type, payload }: AnyAction,
): ICustomerConfigState => {
  switch (type) {
    case FETCH_CUSTOMER_CONFIG:
      return payload;
    case CREATE_ASSET_STAGE:
      return {
        ...state,
        assetStages: [...state.assetStages, payload],
      };
    case DELETE_ASSET_STAGE:
      return {
        ...state,
        assetStages: state.assetStages.filter((as) => as.id !== payload.id),
      };
    case UPDATE_POSITIONS_ASSET_STAGE:
      return {
        ...state,
        assetStages: payload,
      };
    case UPDATE_ASSET_STAGE:
    case TOGGLE_ASSET_STAGE:
      return {
        ...state,
        assetStages: state.assetStages.map((as) =>
          as.id === payload.id ? payload : as,
        ),
      };
    case CREATE_FEED_TYPE:
      return {
        ...state,
        feedTypes: [...state.feedTypes, payload],
      };
    case UPDATE_FEED_TYPE:
      return {
        ...state,
        feedTypes: state.feedTypes.map((ft) =>
          ft.id === payload.id ? payload : ft,
        ),
      };
    case DELETE_FEED_TYPE:
      return {
        ...state,
        feedTypes: state.feedTypes.filter((ft) => ft.id !== payload.id),
      };
    case CREATE_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypes: [...state.documentTypes, payload],
      };
    case UPDATE_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypes: state.documentTypes.map((dt) =>
          dt.id === payload.id ? payload : dt,
        ),
      };
    case DELETE_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypes: state.documentTypes.filter((dt) => dt.id !== payload.id),
      };
    default:
      return state;
  }
};

import http, { API_URL, requireAuthorizationHeaders } from 'lib/http';
import { stringify } from 'qs';
import axios from 'axios';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import { ISharedDocument } from 'types/SharedDocument';

// todo: get rid of set* methods and return only data
export const fetchSharedFilesByParams = async (
  data,
  setSharedFiles,
  setTotalSize,
  setLoading,
) => {
  setLoading(true);
  const dataParams = { indices: false, arrayFormat: 'brackets', encode: false };
  const res = await http.get(`/shared_files?${stringify(data, dataParams)}`);
  const fetchedSharedFiles = await res.json();

  setSharedFiles(fetchedSharedFiles.sharedFiles);
  setTotalSize(fetchedSharedFiles.meta.totalSize);
  setLoading(false);

  return fetchedSharedFiles;
};

export const bulkDownload = async (
  sharedFiles: ISharedDocument[],
): Promise<any> => {
  const query = stringify(
    { ids: sharedFiles.map((item) => item.id) },
    { encode: false, arrayFormat: 'brackets' },
  );
  // NOTE: avoiding await for immediate notice to user for started process
  axios
    .get(`${API_URL}/api/shared_files/bulk/download?${query}`, {
      headers: requireAuthorizationHeaders(),
      responseType: 'blob',
    })
    .then(({ data }) => saveFileOnUserDevice(data, 'files.zip'));

  window.toastr.info(
    "Your files are downloading. Please don't reload the page",
  );
};

export const downloadSharedFile = (
  sharedFile: Pick<ISharedDocument, 'url' | 'title'>,
): Promise<any> =>
  axios
    .get(sharedFile.url, { responseType: 'blob' })
    .then(({ data }) => saveFileOnUserDevice(data, sharedFile.title));

import http from 'lib/http';
import { IJobCostCode } from 'types/JobCostCode';

export const FETCH_JCC_FOR_REALLOCATIONS =
  'symmetre-client-api/FETCH_JCC_FOR_REALLOCATIONS';

export function fetchJCCForReallocations() {
  return async (dispatch) => {
    const res = await http.get('/reconcile/job_cost_codes');
    const responseData = await res.json();

    dispatch({
      type: FETCH_JCC_FOR_REALLOCATIONS,
      payload: responseData as IJobCostCode[],
    });
  };
}

import { useHeavyUpdateFundWithInvalidationMutation } from 'bundles/Settings/components/Portal/FundDashboard/api/coreFundsApi';
import { useHeavyUpdateAssetWithInvalidationMutation } from 'bundles/Settings/components/Portal/AssetDashboard/api/coreAssetsApi';
import {
  STATUS_OPTIONS_FOR_DRAFTS,
  STATUS_OPTIONS_MAP,
} from 'bundles/Settings/components/Portal/shared/consts';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { useModal } from '@/shared/lib/hooks/useModal';
import { capitalize, values } from 'lodash-es';
import { ComponentProps } from 'react';
import {
  GroupContainerItem,
  Icon,
  Popover,
  SelectableItem,
} from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { ILegalEntitiable } from 'types/LegalEntitiable';

type AssetHeavyUpdate = ReturnType<
  typeof useHeavyUpdateAssetWithInvalidationMutation
>[0];
type FundHeavyUpdate = ReturnType<
  typeof useHeavyUpdateFundWithInvalidationMutation
>[0];

function StatusDropdown({
  object,
  status,
  children,
  heavyUpdate,
}: {
  status: ILegalEntitiable['aasmState'];
  object: IFund | IAsset;
  heavyUpdate: AssetHeavyUpdate | FundHeavyUpdate;
} & Pick<ComponentProps<typeof Popover>, 'children'>) {
  const options =
    status === 'draft' ? STATUS_OPTIONS_FOR_DRAFTS : STATUS_OPTIONS_MAP;
  const { confirm } = useModal();

  const handleUpdateStatus = async (option: Values<typeof options>) => {
    if (option.value === status) return;
    const answer = await confirm({
      subtitle: `Do you want to change ${capitalize(
        object.objectType,
      )}'s status?`,
      title: `Changing ${capitalize(object.objectType)}'s Status`,
      actions: {
        primaryButton: {
          variant: 'success',
          text: `Yes, ${option.label}`,
        },
        secondaryButton: {
          variant: 'secondary',
          text: 'Cancel',
        },
      },
    });

    if (!answer) return;

    if (object.objectType === 'asset') {
      (heavyUpdate as AssetHeavyUpdate)({
        id: object.id,
        slug: object.slug,
        asset: {
          aasm_state: option.value,
        },
      });
      return;
    }
    (heavyUpdate as FundHeavyUpdate)({
      id: object.id,
      slug: object.slug,
      fund: {
        aasm_state: option.value,
      },
    });
  };
  return (
    <Popover
      appendToBody
      placement="bottom-end"
      offset={[0, 0]}
      className="w-[184px] p-0 !pt-tw-1"
      trigger="click"
      hiddenArrow
      zIndex={HUGE_MODAL_Z_INDEX - 1}
      template={
        <GroupContainerItem title="Options:" className="py-tw-1">
          {values(options).map((option) => (
            <SelectableItem
              key={option.value}
              id={option.value}
              text={
                <div className="flex items-center gap-tw-0.5">
                  <Icon
                    iconName={option.iconName}
                    className={option.className}
                  />
                  <p className={cn('secondary-regular text-neutral-900')}>
                    {option.label}
                  </p>
                </div>
              }
              onClick={() => handleUpdateStatus(option)}
              selected={option.value === status}
              className="min-h-fit"
            />
          ))}
        </GroupContainerItem>
      }
    >
      {children}
    </Popover>
  );
}

export default StatusDropdown;

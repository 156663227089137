import React, { MouseEventHandler } from 'react';
import { Icon, Popover } from 'stories/index';

type Folder = {
  id: string;
  title: string;
};

const MAX_FOLDER_TITLE_LENGTH = 28;

const InlineFolder = ({
  folder,
  onClick,
}: {
  folder: Folder;
  onClick: (f: Folder) => void;
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onClick(folder);
  };

  const createFolder = (title: string) => (
    <button
      onClick={handleClick}
      className="secondary-semibold inline-flex w-max items-center gap-tw-2 rounded-[8px] border-none bg-neutral-150 px-tw-1.5 py-tw-1 text-neutral-900 transition-colors hover:bg-neutral-200"
    >
      <Icon iconName="folder" className="leading-snug" />
      <span className="secondary-semibold">{title}</span>
    </button>
  );

  if (folder.title.length > MAX_FOLDER_TITLE_LENGTH) {
    return (
      <Popover
        theme="dark"
        template={<p className="text-neutral-000">{folder.title}</p>}
      >
        {createFolder(
          folder.title.slice(0, MAX_FOLDER_TITLE_LENGTH).concat('...'),
        )}
      </Popover>
    );
  }

  return createFolder(folder.title);
};

export default InlineFolder;

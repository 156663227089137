import React from 'react';
import { Link, navigate } from '@reach/router';
import { apiClient } from "lib/http";
import { Button } from '@/stories';

const ForgotPassword = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const userData = { email: form.email.value };
    apiClient.post('/users/password', { user: userData })
      .then((response) => response.data)
      .then(() => {
        navigate('/users/sign_in');
        window.toastr.info('You should receive an email with the instructions soon');
      })
      .catch((error) => toastr.error(`${error.response.data.errors}\n`));
  };

  return (
    <div>
      <h1 className="auth__header mb-tw-4">Don’t worry</h1>
      <p className="light-60 text-center inline-regular">
        We are here to help you recover your password. Enter the email address you used when you
        joined and we'll send you instructions to reset your password.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-group auth__form-group">
          <label className="auth__label">
            Email
            <input
              name="email"
              className="form-control auth__input"
              type="text"
              placeholder="Enter your email address"
            />
          </label>
        </div>
        <Button
          variant="success"
          type="submit"
          size="l"
          className="mb-tw-4 w-full"
        >
          Change My Password
        </Button>
        <Link to="/users/sign_in" className="sre-link text-center d-block">
          Back to Log in
        </Link>
      </form>
    </div>
  );
};

export default ForgotPassword;

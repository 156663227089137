import { cn } from '@/shared/lib/css/cn';
import { Link } from '@reach/router';
import { capitalize } from 'lodash-es';
import { ComponentProps } from 'react';

export const TAB_LINK_CLASSES =
  'flex items-center leading-none min-h-[32px] w-max px-tw-4 py-tw-1.5 inline-semibold text-info-055 border-none border-transparent bg-transparent';
const ACTIVE_TAB_CLASSES =
  'text-neutral-800 border-info-055 border-solid border-t-0 border-x-0 border-b-2';

const TabLink = ({
  tab,
  ...props
}: { tab: string } & ComponentProps<typeof Link>) => (
  <Link
    getProps={({ isPartiallyCurrent, isCurrent }) => {
      const currentLevel = tab === 'overview' ? isCurrent : isPartiallyCurrent;

      return {
        className: cn(TAB_LINK_CLASSES, currentLevel && ACTIVE_TAB_CLASSES),
      };
    }}
    {...props}
  >
    {capitalize(tab)}
  </Link>
);

export default TabLink;

import React, { useEffect } from 'react';
import { AnimationLoader, Modal } from 'stories';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import LegalEntityReconcileSettingsNavigation from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/LegalEntityReconcileSettingsNavigation';
import { navigate, useParams } from '@reach/router';
import LegacySettings from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/legacy/LegacySettings';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { fetchReconcileDevelopmentLegalEntity } from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import SovcConfig from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/sovc/SovcConfig';
import MarkupsSettings from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/markups/MarkupsSettings';
import FeesSettings from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fees/FeesSettings';
import WorkFlowSettings from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/WorkFlowSettings';
import JCCinLE from './jcc/JCCinLE';
import SnapshotSettings from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/SnapshotSettings';
import FundingSources from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/FundingSourcesPage';

function LegalEntityReconcileSettingsModal() {
  const params =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']>();
  const dispatch = useAppDispatch();
  const legalEntity = useAppSelector(
    (state) => state.developmentLegalEntity.data,
  );

  useEffect(() => {
    dispatch(fetchReconcileDevelopmentLegalEntity(params.legalEntityCode));
  }, []);

  const getTabs = (): Record<
    TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']['tab'],
    React.ReactNode
  > => ({
    legacy: <LegacySettings legalEntityId={legalEntity!.id} />,
    jcc: <JCCinLE legalEntityId={legalEntity!.id} />,
    sovc: <SovcConfig legalEntityId={legalEntity!.id} />,
    fees: <FeesSettings legalEntityId={legalEntity!.id} />,
    markups: <MarkupsSettings legalEntityId={legalEntity!.id} />,
    funding_sources: <FundingSources />,
    workflow: <WorkFlowSettings legalEntityId={legalEntity!.id} />,
    snapshot: <SnapshotSettings legalEntityId={legalEntity!.id} />,
  });
  return (
    <Modal
      size="huge"
      header={
        <div className="flex flex-col">
          <span className="light-60 label-regular">
            {legalEntity && `${legalEntity.name} • ${legalEntity.assetName}`}
          </span>
          <h6 className="header6-bold">Individual REconcile Settings</h6>
        </div>
      }
      classes={{
        body: 'bg-light-10',
      }}
      toggle={() => navigate(-1)}
    >
      {legalEntity == null && <AnimationLoader />}
      {legalEntity != null && (
        <div className="flex flex-col gap-m">
          <LegalEntityReconcileSettingsNavigation />
          <div className="border-t border-solid border-light-30" />
          {getTabs()[params.tab]}
        </div>
      )}
    </Modal>
  );
}

export default LegalEntityReconcileSettingsModal;

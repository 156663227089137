import { asserts } from '@/lib/typeHelpers/assertsType';
import { STATIC_DATE_PICKER_PROPS } from 'bundles/REturn/components/Ownership/modals/consts';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { trimSeparator } from '@/shared/lib/formatting/number';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { createPeriodFromDate } from 'stories/FlexibleFilterByPeriods/utils';
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from 'stories/index';
import { convertDollarsToCents } from '@/shared/lib/converters';

export function UnitPricesSettingsModal({
  onClose,
  onSubmit,
  label = 'Unit Prices',
  submitButtonLabel = 'Save Updates',
  priceInputLabel = 'Base Price',
  dateInputLabel = 'Start of investment',
  minDate,
  initialPrice,
  initialDate,
}: {
  label?: React.ReactNode;
  submitButtonLabel?: React.ReactNode;
  priceInputLabel?: React.ReactNode;
  dateInputLabel?: React.ReactNode;
  minDate?: Date | null;
  initialPrice?: number;
  initialDate?: Date;
} & DialogProps<{
  priceCents: number;
  period: IPeriodItem['period'];
}>) {
  const [price, setPrice] = useState(initialPrice);
  const [date, setDate] = useState<Date | null>(initialDate ?? null);

  const isDisabled = () => price == null || date === null;

  return (
    <Modal
      size="400"
      toggle={onClose}
      classes={{
        body: 'flex gap-tw-4 items-center',
      }}
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              asserts(date);
              asserts(price != null);

              onSubmit?.({
                priceCents: convertDollarsToCents(price),
                period: createPeriodFromDate(date),
              });
            }}
            disabled={isDisabled()}
          >
            {submitButtonLabel}
          </Button>
        </ModalActions>
      }
      header={
        <ModalHeaderWithSubtitle
          title="Autumn Oaks"
          subtitle={label}
          order="subtitle-title"
        />
      }
    >
      <label className="flex grow flex-col gap-tw-2">
        <p className="label-regular text-neutral-900">
          {priceInputLabel} <span className="text-danger-060">*</span>
        </p>
        <CurrencyInputNumber
          selectOnFocus
          size="m"
          allowNegative={false}
          value={String(price)}
          onChange={(a) => {
            setPrice(
              a.target.value
                ? Number(trimSeparator(a.target.value))
                : undefined,
            );
          }}
        />
      </label>
      <label className="flex grow flex-col gap-tw-2">
        <p className="label-regular text-neutral-900">
          {dateInputLabel} <span className="text-danger-060">*</span>
        </p>
        <ReactDatePicker
          {...STATIC_DATE_PICKER_PROPS}
          minDate={minDate}
          placeholderText="Select Date"
          selected={date}
          customInput={<Input leftIcon="calendar" />}
          onChange={(d) => setDate(d)}
        />
      </label>
    </Modal>
  );
}

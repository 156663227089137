import { Button } from '@/stories';
import { useUpdateThreshold } from '@/features/report/threshold/crud/lib';
import { Threshold } from '@/entities/report/thresholds/model';

const NOT_SUPPORTED_THRESHOLD_KINDS = ['delinq_balance', 'delinq_move_in'];

export const NoThreshold = ({ threshold }: { threshold: Threshold }) => {
  const updateThreshold = useUpdateThreshold(threshold);
  const isNotSupported = NOT_SUPPORTED_THRESHOLD_KINDS.includes(threshold.kind);
  return (
    <div className="flex w-full  grow flex-col items-center justify-center gap-tw-6">
      <div className="flex max-w-[400px] flex-col items-center justify-center gap-tw-2 text-center">
        <p className="header4-regular text-neutral-500">
          To get started, add a Threshold
        </p>
        <span className="inline-regular text-neutral-500">
          {threshold.description}
        </span>
      </div>

      <Button
        tooltipProps={{
          disabled: !isNotSupported,
          titleText: 'This feature will be available in a future update',
        }}
        disabled={isNotSupported}
        size="m"
        variant="primary"
        onClick={() => updateThreshold()}
      >
        Add Threshold
      </Button>
    </div>
  );
};

import React, { FC, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  disabled?: boolean;
}

export const SelectionSection = ({
  title,
  subtitle,
  children,
  disabled,
}: Props) => (
  <div>
    <h5 className="light-90 inline-semibold mb-xs">{title}</h5>
    <p className="light-60 secondary-regular mb-m">{subtitle}</p>
    <div
      className={cn(
        'rounded-[0.5rem] overflow-hidden',
        disabled ? 'bg-light-10' : 'bg-white',
      )}
    >
      {children}
    </div>
  </div>
);

export default SelectionSection;

import { cn } from '@/shared/lib/css/cn';
import { ZoomDetector, calculateScrollBarWidth } from 'lib/helpers';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Button, LinkButton, Modal } from 'stories';
import { ISharedDocument } from 'types/SharedDocument';
import AssetDocuments from '../../../../Assets/components/AssetDocuments';
import AttachMedia from './AttachMedia';
import AttachedItemsList from './AttachedItemsList';

const AttachModal = ({
  attachedMedia,
  setAttachedMedia,
  onClose,
  attachedDocuments,
  setAttachedDocuments,
}: {
  attachedMedia: unknown[];
  setAttachedMedia: (media: unknown[]) => void;
  onClose: () => void;
  attachedDocuments: ISharedDocument[];
  setAttachedDocuments: (documents: ISharedDocument[]) => void;
}) => {
  const [tab, setTab] = useState('documents');
  const [selectedMedia, setSelectedMedia] = useState(attachedMedia);
  const [selectedDocuments, setSelectedDocuments] =
    useState<ISharedDocument[]>(attachedDocuments);
  const [bottomMenuExpanded, setBottomMenuExpanded] = useState(false);
  const [disabledModalBodyScroll, setDisabledModalBodyScroll] = useState(false);
  const nothingSelected = selectedMedia.length + selectedDocuments.length === 0;
  // todo: temporary hide media for funds. Reason: media doesn't work yet for funds.

  const onSubmit = () => {
    if (selectedMedia && selectedMedia.length > 0)
      setAttachedMedia(selectedMedia);
    setAttachedDocuments(selectedDocuments);
    onClose();
  };

  useLayoutEffect(() => {
    calculateScrollBarWidth();
    ZoomDetector();
  }, []);
  // disable modal scroll when attached items list scrollable
  useEffect(() => {
    const attachedItemsList = document.getElementById('attached-items-list');
    setDisabledModalBodyScroll(Boolean(attachedItemsList));
  }, [bottomMenuExpanded]);

  return (
    <Modal
      toggle={onClose}
      size="1400"
      header={
        <>
          <div className="modal-header items-center  p-tw-4">
            <h6 className="modal-title inline-block w-full text-center text-neutral-850">
              Attach to Message
            </h6>
          </div>
        </>
      }
      classes={{
        header: 'bg-neutral-050 w-full p-0',
        headerContent: 'w-full',
        body: cn('py-tw-4 bg-light-10 flex flex-col h-screen relative', {
          'overflow-hidden': disabledModalBodyScroll,
        }),
        close: 'self-start absolute right-tw-4 top-tw-2',
        footer: 'bg-light',
      }}
    >
      <>
        <div className="-mx-tw-6 -mt-tw-6 mb-tw-6 flex bg-neutral-050">
          {['documents', 'media'].map((tabName) => (
            <div
              key={tabName}
              className={cn('mgmt-tab h-full flex-1 py-tw-0.5 text-center', {
                'active-mgmt-tab': tab === tabName,
              })}
            >
              <LinkButton className="py-tw-3" onClick={() => setTab(tabName)}>
                {capitalize(tabName)}
              </LinkButton>
            </div>
          ))}
        </div>
        <div className="attach-modal__tabs relative flex flex-1 pb-[60px]">
          {tab === 'media' && (
            <AttachMedia
              selected={selectedMedia}
              setSelected={setSelectedMedia}
            />
          )}
          {tab === 'documents' && (
            <AssetDocuments
              selected={selectedDocuments}
              setSelected={setSelectedDocuments}
              selectedRows={selectedDocuments}
              setSelectedRows={setSelectedDocuments}
              wide
              hideActions
            />
          )}
        </div>
        <div
          className={cn(
            'modal-sticky-bottom-menu fixed bottom-[50px] left-[20%] right-[20%]',
            { 'modal-sticky-bottom-menu_yellow-border': nothingSelected },
          )}
        >
          <div className="modal-sticky-bottom-menu__header flex items-center justify-between">
            {nothingSelected && (
              <div className="flex w-full flex-col justify-center text-center">
                <p className="inline-regular text-neutral-500">To start</p>
                <p className="header6-regular text-neutral-850">
                  Use the <strong>Documents</strong> and/or{' '}
                  <strong>Media</strong> tabs at the top of this window and then
                  select the check boxes to attach!
                </p>
              </div>
            )}
            {(selectedMedia.length > 0 || selectedDocuments.length > 0) && (
              <>
                <div className="flex items-center">
                  <div className="attach-button mr-tw-4">
                    <span className="sre-icon-attach" />
                  </div>
                  <div>
                    <h6>
                      {[
                        ['Document', selectedDocuments.length],
                        ['Media', selectedMedia.length],
                      ]
                        .filter((v) => v[1] > 0)
                        .map((v) => `${v[1]} ${pluralize(v[0], v[1])}`)
                        .join(', ')}
                    </h6>
                    <LinkButton
                      onClick={() => setBottomMenuExpanded(!bottomMenuExpanded)}
                    >
                      {!bottomMenuExpanded && (
                        <>
                          Show all
                          <span className="sre-icon-bottom" />
                        </>
                      )}

                      {bottomMenuExpanded && (
                        <>
                          Hide all
                          <span className="sre-icon-top" />
                        </>
                      )}
                    </LinkButton>
                  </div>
                </div>
                <div>
                  <Button
                    variant="secondary"
                    className="mr-tw-4"
                    onClick={() => {
                      setSelectedMedia([]);
                      setSelectedDocuments([]);
                      setAttachedMedia([]);
                      setAttachedDocuments([]);
                      setBottomMenuExpanded(false);
                    }}
                  >
                    Clear selection
                  </Button>
                  <Button variant="success" onClick={onSubmit}>
                    Attach to message
                  </Button>
                </div>
              </>
            )}
          </div>

          {bottomMenuExpanded && (
            <AttachedItemsList
              attachedMedia={selectedMedia}
              attachedDocuments={selectedDocuments}
              onMediaRemove={(id) =>
                setSelectedMedia(selectedMedia.filter((ad) => ad.id !== id))
              }
              onDocumentRemove={(id) =>
                setSelectedDocuments(
                  selectedDocuments.filter((ad) => ad.id !== id),
                )
              }
              disableScrollId="attached-items-list"
            />
          )}
        </div>
      </>
    </Modal>
  );
};

export default AttachModal;

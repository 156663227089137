import React from 'react';

interface Props {
  styles?: React.CSSProperties;
}

const LoadingPanel = ({ styles }: Props) => (
  <div className="flex-load-panel" style={styles}>
    <div className="loading-spinner" />
  </div>
);

export default LoadingPanel;

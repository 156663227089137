import printJS from 'print-js';
import { ISharedDocument } from 'types/SharedDocument';

type PrintDocumentOptions = {
  file: Pick<ISharedDocument, 'id' | 'extension' | 'url'>;
} & Pick<printJS.Configuration, 'onLoadingStart' | 'onLoadingEnd'>;

const printDocument = ({
  file,
  onLoadingStart,
  onLoadingEnd,
}: PrintDocumentOptions): void => {
  if (file.extension === 'pdf') {
    printJS({
      printable: file.url,
      type: file.extension,
      onLoadingStart,
      onLoadingEnd,
    });
  } else {
    printJS(file.url, 'image');
  }
};

export default printDocument;

import React from 'react';
import { TDocumentStatus } from 'stories/DocumentCard/DocumentCard';
import { Icon } from 'stories';

export const DocumentCardStatusIcon = ({
  documentStatus,
}: {
  documentStatus: TDocumentStatus;
}) => (
  <>
    {documentStatus.currentStatus === documentStatus.successStatus && (
      <Icon iconName="check" className="text-success-055" />
    )}
    {documentStatus.currentStatus === documentStatus.attentionStatus && (
      <Icon iconName="triangleWarning" className="text-attention-055" />
    )}
    {documentStatus.currentStatus === documentStatus.failedStatus && (
      <Icon iconName="close" className="text-danger-055" />
    )}
  </>
);

import { UpdatedAsset } from '@/widgets/core/assets/AssetDashboardContent';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IAssetsPageParams } from 'bundles/Assets/actions/assets';
import { FetchAssetsWithMetaResponse } from 'bundles/Assets/reducers/AssetsSlice';
import { IVisibleReportTableConfigsData } from 'bundles/REport/actions';
import { snakeKeysToCamel } from 'bundles/UserManagement/components/roles/utils';
import { API_URL, apiClient, requireAuthorizationHeaders } from 'lib/http';
import { stringify } from 'qs';
import { IAsset } from 'types/Asset';

export const ASSET_LIST_TAG = 'Assets';
export const ASSET_TAG = 'Asset';

export type CoreAsset = IAsset & {
  objectDashboards: {
    id: string;
    name: string;
  }[];
  hasReportBuilderData?: boolean;
  hasOperationalData?: boolean;
  hasReturnData?: boolean;
  capitalInvestmentObjectId?: string;
};

export const coreAssetsApi = createApi({
  reducerPath: 'coreAssets',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
    paramsSerializer: (params) =>
      stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      }),
  }),
  tagTypes: [ASSET_LIST_TAG, ASSET_TAG],
  endpoints: (build) => ({
    getAssets: build.query<FetchAssetsWithMetaResponse, IAssetsPageParams>({
      providesTags: [ASSET_LIST_TAG],
      query: (params) => ({
        url: '/assets',
        params,
      }),
    }),

    getAsset: build.query<CoreAsset, IAsset['slug']>({
      providesTags: [ASSET_TAG],
      query: (slug) => ({
        url: `/assets/${slug}`,
      }),
    }),

    getVisibleReportTableConfigs: build.query<
      IVisibleReportTableConfigsData,
      IAsset['slug']
    >({
      query: (slug) => ({
        url: `/assets/${slug}/visible_report_table_configs`,
      }),
    }),

    simpleUpdateAsset: build.mutation<Partial<IAsset>, UpdatedAsset>({
      query: (data) => ({
        url: `/assets/${data.id}/simple_update`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Asset has been updated');
        return (res as { data: Partial<IAsset> })?.data;
      },

      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          coreAssetsApi.util.updateQueryData(
            'getAsset',
            patch.slug,
            (draft) => {
              Object.assign(draft, snakeKeysToCamel(patch.asset));
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    heavyUpdateAsset: build.mutation<Partial<IAsset>, UpdatedAsset>({
      query: (data) => ({
        url: `/assets/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Asset has been updated');
        return (res as { data: Partial<IAsset> })?.data;
      },
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          coreAssetsApi.util.updateQueryData(
            'getAsset',
            patch.slug,
            (draft) => {
              Object.assign(draft, snakeKeysToCamel(patch.asset));
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    heavyUpdateAssetWithInvalidation: build.mutation<
      Partial<IAsset>,
      UpdatedAsset
    >({
      query: (data) => ({
        url: `/assets/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [ASSET_LIST_TAG],
      transformResponse: (res) => {
        toastr.success('Asset has been updated');
        return (res as { data: Partial<IAsset> })?.data;
      },
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          coreAssetsApi.util.updateQueryData(
            'getAsset',
            patch.slug,
            (draft) => {
              Object.assign(draft, snakeKeysToCamel(patch.asset));
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    heavyUpdateAssetWithForm: build.mutation<Partial<IAsset>, FormData>({
      invalidatesTags: ['Assets'],
      queryFn: async (formData) => {
        try {
          const res = await apiClient.put(
            `/api/assets/${formData.get('id') as string}`,
            formData,
            {
              headers: requireAuthorizationHeaders({
                'Content-Type': 'multipart/form-data',
              }),
            },
          );
          toastr.success('Asset has been updated');

          return { data: res.data };
        } catch (e) {
          throw new Error(e);
        }
      },
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useGetAssetQuery,
  useSimpleUpdateAssetMutation,
  useHeavyUpdateAssetMutation,
  useHeavyUpdateAssetWithInvalidationMutation,
  useHeavyUpdateAssetWithFormMutation,
  useGetVisibleReportTableConfigsQuery,
} = coreAssetsApi;

import React, { MouseEventHandler } from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const ActionItem = ({ className, onClick, children }: Props) => (
  <div onClick={onClick} className={cn('flex flex-col px-tw-1', className)}>
    {children}
  </div>
);

import { TTypeFieldHD } from 'bundles/Shared/components/HistoryDetails/HistoryDetailsSwitcher';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { formatAmount } from '@/shared/lib/formatting/number';

export const getDataHDArrowsCO = () => {
  const data = [
    {
      title: 'Key Info',
      data: [
        {
          titleView: 'Number',
          fieldName: 'number',
          typeField: TTypeFieldHD.ARROWS,
        },
        {
          fieldName: 'coDate',
          typeField: TTypeFieldHD.ARROWS,
          titleView: 'CO Date',
          transformerView: (date: Date) => formatUnixDate(date, 'MMM DD, YYYY'),
        },
        {
          titleView: 'Detailed Description',
          fieldName: 'description',
          typeField: TTypeFieldHD.ARROWS,
          styles: {
            wrapper: 'bottom',
          },
        },
      ],
    },
    {
      title: 'Financial Details',
      data: [
        {
          fieldName: 'projectedPriorContractSum',
          typeField: TTypeFieldHD.ARROWS,
          titleView: 'Prior Contract Sum',
          transformerView: formatAmount,
        },
        {
          fieldName: 'amount',
          typeField: TTypeFieldHD.ARROWS,
          titleView: 'Pending CO amount',
          transformerView: formatAmount,
        },
        {
          fieldName: 'changeOrderVerification',
          objectName: 'new_contract_sum',
          typeField: TTypeFieldHD.ARROWS,
          titleView: 'New Contract Sum',
          transformerView: formatAmount,
        },
      ],
    },
  ];
  return data;
};

export const getHistoryDataCO = (type: 'update') => {
  const types = [
    {
      type: 'update',
      title: 'Details of modifying Change Order Details',
    },
  ];

  return types.find((elem) => elem.type === type);
};

import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  ADJUSTMENT_EXPRESSION_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
  TOTAL_CALCULATION_STRATEGIES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  transformPeriodShiftDtoToForm,
  transformPeriodTypeDtoToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import {
  COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  KpiTableSingleDateWidgetConfig,
  KpiTableSingleDateWidgetConfigColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { findFirstColumnSettingsInGroup } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import { pick } from 'lodash-es';
import * as yup from 'yup';

export type KpiTableSingleDateWidgetConfigColumnForm = yup.InferType<
  typeof KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN: Partial<KpiTableSingleDateWidgetConfigColumnForm> =
  {
    value_display_options: {
      kilo_formatting: false,
      type: 'number',
      precision: 2,
    },
    adjustment: null,
  };
export const KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA =
  COLUMN_SETTINGS_FORM_SCHEMA.shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    period_type: PERIOD_TYPE_SCHEMA.required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
    total_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
  });

export const transformColumnConfigToForm = (
  columnConfig: KpiTableSingleDateWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): KpiTableSingleDateWidgetConfigColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    expression: columnConfig.expression,
    adjustment: columnConfig.adjustment_expression,
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    period_type: transformPeriodTypeDtoToForm(columnConfig.period_type),
    total_calculation_strategy: columnConfig.total_calculation_strategy,
    ...transformColumnSettingsToForm(columnSettings),
  };
};

const OVERRIDABLE_COLUMN_FIELDS = ['period_shift', 'period_type'] as const;

export const transformConfigToColumnForm = ({
  widgetConfig,
  columnConfig,
  groupId,
  columnSettings,
}: {
  widgetConfig: KpiTableSingleDateWidgetConfig;
  groupId?: string;
  columnConfig?: KpiTableSingleDateWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
}): KpiTableSingleDateWidgetConfigColumnForm => {
  const group = widgetConfig.viz_config.column_groups.find(
    (cg) => cg.group_id === groupId,
  );
  const cantOverridePeriod = group?.child_can_override_period === false;
  const { firstColumn, firstColumnSettingsInGroup } =
    findFirstColumnSettingsInGroup({
      columnsConfig: widgetConfig.columns,
      groupId: groupId ?? '',
      viz_config: widgetConfig.viz_config,
    });
  return {
    ...DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN,
    ...(group && cantOverridePeriod && firstColumnSettingsInGroup
      ? pick(
          transformColumnConfigToForm(firstColumn, firstColumnSettingsInGroup),
          OVERRIDABLE_COLUMN_FIELDS,
        )
      : {}),
    ...(columnConfig &&
      columnSettings &&
      transformColumnConfigToForm(columnConfig, columnSettings)),
  };
};

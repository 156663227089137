import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { FinancialTableSinglePeriodWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/component';
import { FinancialTableSinglePeriodWidget } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/widget';

export * from './options';

export const FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Financial Table Single Period',
  icon: 'table',
  Component: FinancialTableSinglePeriodWidget,
  ConfigComponent: FinancialTableSinglePeriodWidgetConfig,
} as const satisfies WidgetConfiguration;

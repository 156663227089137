import React, { ComponentProps } from 'react';
import { ThinTabGroup, Badge } from 'stories';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { CssVar } from '@/shared/config/cssVar';

/**
 * @deprecated Use `IThinTabItem` instead
 */
export interface IThinTabItemWithAmount extends IThinTabItem {
  amount?: number;
}
interface Props extends Omit<ComponentProps<typeof ThinTabGroup>, 'items'> {
  items: IThinTabItemWithAmount[];
}

const BADGE_PROPS = {
  className: 'rounded-4 ml-xs',
  classes: { value: 'py-0 px-xs square font-weight-400' },
  textColor: CssVar.light90,
} as const satisfies ComponentProps<typeof Badge>;

export function ThinTabGroupWithAmount({ items, ...props }: Props) {
  return (
    <ThinTabGroup
      {...props}
      items={[
        ...items.map((item) => ({
          ...item,
          label: (
            <>
              {item.label}{' '}
              {item?.amount !== undefined && (
                <Badge {...BADGE_PROPS}>{item.amount}</Badge>
              )}
            </>
          ),
        })),
      ]}
    />
  );
}

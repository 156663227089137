import { getRadioGroupItemPropsHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/getRadioGroupItemPropsHandler';
import {
  DATE_FORMAT_VALUE_OPTIONS,
  PRECISION_OPTIONS,
  VALUE_TYPE_OPTIONS,
  ValueDisplayOptionsForm,
} from '@/shared/lib/formatting/displayOptions';
import { ResetButton } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { Control, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import { TumblerController } from 'stories/Tumbler/Tumbler';

export function ValueDisplayOptionsFields<
  FV extends FieldValues = FieldValues,
  N extends FieldPath<FV> = FieldPath<FV>,
>({ name: parentFieldName, control }: { name: N; control: Control<FV> }) {
  const { resetField, watch, setValue } = useFormContext();

  const getFieldName = (name: keyof ValueDisplayOptionsForm) =>
    `${parentFieldName}.${name}` as FieldPath<FV>;

  const type = watch(getFieldName('type'));

  return (
    <SectionFieldsContainer>
      <SectionField
        labelText="Output Format"
        button={
          <ResetButton
            onClick={() => {
              resetField(parentFieldName, {
                defaultValue: {
                  type: null,
                  precision: null,
                  kilo_formatting: false,
                },
              });
            }}
            name={parentFieldName}
          >
            Reset All
          </ResetButton>
        }
      />
      <SectionField
        note="Select the format for the column"
        labelText="Value Type"
        button={<ResetButton name={getFieldName('type')} />}
      >
        <RadioGroupController
          control={control}
          name={getFieldName('type')}
          options={VALUE_TYPE_OPTIONS}
          onChange={(o) => {
            if (o.value === 'bps') {
              setValue(getFieldName('precision'), 0);
            }
            setValue(getFieldName('type'), o.value, {
              shouldDirty: true,
            });
          }}
        />
      </SectionField>
      {type !== 'bps' && type !== 'date' && (
        <SectionField
          labelText="Precision"
          button={<ResetButton name={getFieldName('precision')} />}
        >
          <RadioGroupController
            control={control}
            name={getFieldName('precision')}
            options={PRECISION_OPTIONS.map((o) => ({
              label: o.toString(),
              value: o,
            }))}
          />
        </SectionField>
      )}
      {type === 'currency' && (
        <SectionField
          labelText="Kilo Formatting"
          button={
            <TumblerController
              control={control}
              name={getFieldName('kilo_formatting')}
              classes={{
                input: 'self-center',
              }}
            />
          }
        />
      )}
      {type === 'date' && (
        <SectionField
          labelText="Date Format"
          button={<ResetButton name={getFieldName('date_format')} />}
        >
          <RadioGroupController
            control={control}
            name={getFieldName('date_format')}
            options={DATE_FORMAT_VALUE_OPTIONS}
            getItemProps={getRadioGroupItemPropsHandler}
          />
        </SectionField>
      )}
      {type !== 'date' && (
        <SectionField
          labelText="Hide Comma Separator"
          button={
            <TumblerController
              control={control}
              name={getFieldName('hide_comma_separator')}
              classes={{
                input: 'self-center',
              }}
            />
          }
        />
      )}
    </SectionFieldsContainer>
  );
}

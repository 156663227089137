import { useMemo } from 'react';
import { ColDef } from 'ag-grid-community';
import { AutoGroupCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupHeaderComponent';
import {
  IColumnGroup,
  IRowBase,
  isRowGl,
} from 'bundles/Shared/components/ReportTable/types';
import { IReportBudgetOnShow } from 'types/ReportBudget';

export function useAutoGroupColumnDef({
  columns,
  budget,
}: {
  columns: IColumnGroup[];
  budget: IReportBudgetOnShow;
}) {
  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      pinned: true,
      resizable: true,
      headerName: 'T-12',
      suppressMenu: true,
      minWidth: 300,
      initialWidth: 300,
      headerComponent: AutoGroupHeaderComponent,
      headerComponentParams: {
        subHeaderName: budget.year,
      },
      cellRenderer: AutoGroupCellRenderer,
      valueGetter: (params) => {
        const { node } = params;
        const isRootTotal = node?.level === -1 && node?.footer;
        const nodeData = node?.data as IRowBase | undefined;

        if (isRootTotal) return 'Grand Total';

        if (!nodeData) return null;

        if (nodeData.label) {
          if (node?.footer) return `Total ${nodeData.label}`;

          return nodeData.label;
        }

        if (isRowGl(nodeData)) {
          return `${nodeData.generalLedger.accNumber} - ${nodeData.generalLedger.accTitle}`;
        }
      },
    }),
    [columns],
  );
  return autoGroupColumnDef;
}

import { CssVar } from '@/shared/config/cssVar';
import { ISelectOption } from 'stories/FormControls/Select/Select';

export const optionFor = (value: string | undefined) =>
  value ? { value, label: value } : null;
export const optionsFor = (values: string[]): ISelectOption[] =>
  values.map(optionFor);
export const optionReconcileFor = <
  T extends {
    id: string | number;
    name: string;
  },
>(
  value: T,
) =>
  value
    ? {
        value: value.id,
        label: value.name,
      }
    : null;
export const optionsReconcileFor = <
  T extends {
    id: string | number;
    name: string;
  },
>(
  values?: T[],
) => (values ? values.map(optionReconcileFor) : []);

export const viewNameReconcileOption = <T extends string | number>(
  id: T,
  values: { id: T; name: string }[],
) => values.find((elem) => elem.id === id)?.name;
export const uniqItems = (values) => [...new Set(values)];

export const fetchImage = async (url: string) => {
  const res = await fetch(url);
  const blob = await res.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const getItemIds = (items) => items.map(({ id }) => id);

export const calculateScrollBarWidth = (isZoomChanged) => {
  const scrollBarWidthPropertyValue =
    document.documentElement.style.getPropertyValue('--scrollbar-width');
  if (scrollBarWidthPropertyValue && !isZoomChanged)
    return scrollBarWidthPropertyValue;
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = `${outer.offsetWidth - inner.offsetWidth}px`;
  outer.parentNode.removeChild(outer);
  document.documentElement.style.setProperty(
    '--scrollbar-width',
    scrollbarWidth,
  );
  return scrollbarWidth;
};

export const ZoomDetector = () => {
  let pxRatio =
    window.devicePixelRatio ||
    window.screen.availWidth / document.documentElement.clientWidth;

  function isZooming() {
    const newPxRatio =
      window.devicePixelRatio ||
      window.screen.availWidth / document.documentElement.clientWidth;
    if (newPxRatio !== pxRatio) {
      pxRatio = newPxRatio;
      calculateScrollBarWidth(true);
    }
  }

  window.onresize = () => isZooming();
};

export const getCssVariableByAccessor = (variable: CssVar) =>
  getComputedStyle(document.body)
    .getPropertyValue(variable.replaceAll(/[()]|var/g, ''))
    .trim();

import React, { useEffect, useRef, useState } from 'react';
import { LinkButton, Button, Icon, Modal } from 'stories';
import { find } from 'lodash-es';
import Dropzone from 'react-dropzone';
import { compareDroppedFiles, directUpload } from 'lib/uploadFiles';
import { presignFilesWithFolder } from 'lib/presignFilesWithFolder';
import { sharedFilePermissions } from 'bundles/Shared/sharedFilePermissions';
import SharedFileDropzone from '../../../Shared/components/SharedFileDropzone';
import BulkUploaderTable from './BulkUploaderTable';
import { plainFetchCustomerConfig } from '../../actions/CustomerConfig/fetchCustomerConfig';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';

const DEFAULT_MEDIA_PERMISSIONS = {
  roles: [],
  users: [],
  tags: [],
  investmentEntities: [],
  type: 'public',
  autoSelectedUsers: [],
};

interface IFile {
  droppedAt: number;
  progress: number;
  file: File;
  name: string;
  path: string;
  size: number;
  type: string;
}

interface DndWrapperProps {
  children: React.ReactNode;
  setClasses: (classes) => void;
  uploadingState: UploadingStateType;
  files: IFile[];
}

export const DndWrapper = ({
  children,
  setClasses,
  uploadingState,
  files,
}: DndWrapperProps) => {
  if (uploadingState !== 'notStarted' && files.length === 0)
    return <div>{children}</div>;

  return (
    <div
      onDragOver={(e) => {
        const dragAndDropContainer = document.getElementById(
          'dragAndDropContainer',
        );
        if (!dragAndDropContainer || !dragAndDropContainer.contains(e.target)) {
          setClasses('drap-and-drop-overlay');
        }
      }}
      onDragExit={() => {
        setClasses('d-none');
      }}
      onDrop={() => {
        setClasses('d-none');
      }}
    >
      {children}
    </div>
  );
};

export type TFolderType = 'documents' | 'media';
export type UploadingStateType = 'notStarted' | 'started' | 'finished';

interface Props {
  handleCreate: () => void;
  investmentObject: {
    type: string;
    entity: {
      id: number;
      type: string;
    };
  };
  setOpened: (opened: boolean) => void;
  defaultFolderId?: number;
  folderType?: TFolderType;
}

const BulkUploaderModal = ({
  setOpened,
  defaultFolderId,
  investmentObject,
  handleCreate,
  folderType = 'documents',
}: Props) => {
  const { openFileDialog } = useFileDialog();
  const [files, setFiles] = useState([]);
  const [uploadingState, setUploadingState] =
    useState<UploadingStateType>('notStarted');
  const [sameFolder, setSameFolder] = useState(Boolean(defaultFolderId));
  const [sameType, setSameType] = useState(folderType === 'media');
  const [sameRights, setSameRights] = useState(folderType === 'media');
  const [documentType, setDocumentType] = useState();
  const [currentFolderId, setCurrentFolderId] = useState(defaultFolderId);
  const [permissions, setPermissions] = useState(
    folderType === 'media' ? DEFAULT_MEDIA_PERMISSIONS : null,
  );
  const [dropMode, setDropMode] = useState(true);
  const [currentFile, setCurrentFile] = useState();
  const [dropWrapperClasses, setDropWrapperClasses] = useState('d-none');

  useEffect(() => {
    if (folderType === 'media') {
      plainFetchCustomerConfig().then((data) => {
        setDocumentType(data.mediaDocumentTypes[0]);
      });
    }
  }, []);

  const onUploadProgress = (file, signedData, progressEvent) => {
    setFiles((f) => {
      const updatedFiles = [...f];
      const currentFileIndex = updatedFiles.findIndex((indexFile) =>
        compareDroppedFiles(indexFile, file),
      );

      updatedFiles[currentFileIndex] = {
        ...updatedFiles[currentFileIndex],
        key: signedData.fields.key,
        progress: Number(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        ),
      };

      return updatedFiles;
    });
  };

  const setInitialProgress = (filesList) => {
    setFiles(filesList.map((f) => ({ ...f, progress: 0 }) as IFile));
  };

  const uploadFiles = async (filesToSubmit) => {
    const presignedFiles = await presignFilesWithFolder(filesToSubmit);
    await Promise.all(
      presignedFiles.map((data) =>
        directUpload(data, {
          onUploadProgress: (progressEvent) =>
            onUploadProgress(data.file, data.signedData, progressEvent),
        }),
      ),
    );

    return presignedFiles as IFile[];
  };

  const generateRequestData = (presignedFiles) => ({
    documents: presignedFiles.map((f) => ({
      shared_file: {
        file_data: {
          id: f.signedData.fields.key,
          metadata: {
            filename: f.file.name,
            mime_type: f.file.type,
            size: f.file.size,
          },
        },
        asset_id:
          investmentObject.type === 'Asset' ? investmentObject.entity.id : null,
        folder_id: f.folder_id,
        document_type_id: f.document_type_id,
        title: f.name,
        documentable_id: investmentObject.entity.id,
        documentable_type: investmentObject.type,
        ...sharedFilePermissions(f.permissions),
      },
    })),
    folder_type: folderType,
  });

  const onSubmit = async () => {
    setUploadingState('started');
    const filesToSubmit = [...files];
    await Promise.resolve(setInitialProgress(filesToSubmit));

    const filesWithInfo = filesToSubmit.map(
      (file) =>
        ({
          ...file,
          folder_type: folderType,
          documentable_id: investmentObject.entity.id,
          documentable_type: investmentObject.type,
        }) as IFile,
    );

    const presignedFiles = await uploadFiles(filesWithInfo);

    const presignedFilesWithInfo = presignedFiles.map((presignedFile) => {
      const file = find(files, { file: presignedFile.file });

      return {
        ...file,
        ...presignedFile,
        folder_id: sameFolder ? currentFolderId : file.folder_id,
        document_type_id: sameType ? documentType.id : file.documentType.id,
        permissions: sameRights ? permissions : file.permissions,
      } as IFile;
    });

    const data = generateRequestData(presignedFilesWithInfo);
    await Promise.resolve(handleCreate(data));
    setUploadingState('finished');
  };

  const onDropzoneChange = (filesList) => {
    setFiles([
      ...files,
      ...filesList.map((f) => ({
        file: Object.assign(f, { droppedAt: new Date().getTime() / 1000 }),
        name: f.name,
        path: f.path,
        size: f.size,
        type: f.type,
        extension: f.name.split('.').pop(),
        droppedAt: new Date().getTime() / 1000,
      })),
    ]);
    setDropMode(false);
  };

  const canSubmit = () => {
    const foldersIsSet =
      (sameFolder && currentFolderId) ||
      (!sameFolder && files.every((item) => item.folder_id));
    const documentTypeIsSet =
      (sameType && documentType) ||
      (!sameType && files.every((item) => item.documentType));
    const permissionsIsSet =
      (sameRights && permissions) ||
      (!sameRights && files.every((item) => item.permissions));

    return foldersIsSet && documentTypeIsSet && permissionsIsSet;
  };

  const handleTableSetPermissions = (newPermissions) => {
    setPermissions(
      newPermissions.type === 'public'
        ? {
            ...newPermissions,
            users: newPermissions.allUsers,
          }
        : newPermissions,
    );
  };

  const handleAddFiles = async () => {
    const inputFiles = await openFileDialog({
      accept: folderType === 'media' ? 'image/*, video/*' : '',
      multiple: true,
    });
    if (inputFiles == null) {
      return;
    }
    onDropzoneChange([...inputFiles]);
  };

  const table = (
    <BulkUploaderTable
      uploadingState={uploadingState}
      files={files}
      setFiles={setFiles}
      sameFolder={sameFolder}
      setSameFolder={setSameFolder}
      documentType={documentType}
      setDocumentType={setDocumentType}
      setSameType={setSameType}
      sameType={sameType}
      sameRights={sameRights}
      setSameRights={setSameRights}
      setCurrentFile={setCurrentFile}
      currentFile={currentFile}
      permissions={permissions}
      setPermissions={handleTableSetPermissions}
      currentFolderId={currentFolderId}
      folderType={folderType}
      setCurrentFolderId={setCurrentFolderId}
      onDropzoneChange={onDropzoneChange}
      investmentObject={investmentObject}
      onAddMoreClick={handleAddFiles}
    />
  );

  return (
    <DndWrapper
      setClasses={setDropWrapperClasses}
      uploadingState={uploadingState}
      files={files}
    >
      <Modal
        toggle={() => setOpened(false)}
        size="xl"
        header="Bulk Uploader"
        classes={{
          header: 'bg-light',
          body: 'bg-light-10 h-80vh p-0',
        }}
        actions={
          files.length > 0 && (
            <div className="flex justify-between w-full">
              <div>
                {uploadingState === 'notStarted' && (
                  <Button onClick={() => setOpened(false)} variant="secondary">
                    Close
                  </Button>
                )}
              </div>
              <div>
                {uploadingState === 'notStarted' && (
                  <Button
                    onClick={onSubmit}
                    variant="success"
                    disabled={!canSubmit()}
                    className="mr-[0.5rem]"
                  >
                    Upload{' '}
                    {folderType === 'documents' ? 'Documents' : 'Media Files'}
                  </Button>
                )}
                {uploadingState === 'finished' && (
                  <Button
                    onClick={() => {
                      setFiles([]);
                      setUploadingState('notStarted');
                      setCurrentFolderId(defaultFolderId);
                      setDocumentType(null);
                      setPermissions(null);
                    }}
                    variant="success"
                    className="mr-[0.5rem]"
                  >
                    Upload More
                  </Button>
                )}
                {uploadingState !== 'notStarted' && (
                  <Button onClick={() => setOpened(false)} variant="secondary">
                    Close
                  </Button>
                )}
              </div>
            </div>
          )
        }
      >
        {(files.length === 0 || dropMode) && (
          <div className="p-[1rem] h-full">
            <SharedFileDropzone
              accept={
                folderType === 'media'
                  ? { 'image/*': [], 'video/*': [] }
                  : undefined
              }
              label={
                folderType === 'media'
                  ? 'Drag your media files here to start uploading'
                  : undefined
              }
              buttonText={folderType === 'media' ? 'Browse media' : undefined}
              onChange={onDropzoneChange}
            />
          </div>
        )}
        {files.length > 0 && !dropMode && (
          <div className="flex justify-between flex-col h-full p-[1rem]">
            {uploadingState === 'notStarted' && (
              <div className="pb-xl">
                <div
                  className={`dropzone-invisible-area ${dropWrapperClasses}`}
                  onDrop={() => setDropWrapperClasses('d-none')}
                  onDragOver={() => {
                    setDropWrapperClasses(
                      'drap-and-drop-overlay drap-and-drop-overlay_can-drop',
                    );
                  }}
                  onDragExit={() =>
                    setDropWrapperClasses('drap-and-drop-overlay')
                  }
                >
                  <Dropzone
                    onDrop={onDropzoneChange}
                    useFsAccessApi={false}
                    accept={
                      folderType === 'media'
                        ? { 'image/*': [], 'video/*': [] }
                        : undefined
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="h-full opacity-100"
                        id="dragAndDropContainer"
                      >
                        <div {...getRootProps({ className: 'h-full' })}>
                          <input {...getInputProps()} />
                          <div className="h-full" />
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                {table}
              </div>
            )}
            {uploadingState !== 'notStarted' && table}

            {uploadingState === 'notStarted' && (
              <div className="inline-regular font-weight-400 dark-60 py-[0.5rem] continue-drag bg-light text-center">
                <Icon iconName="magic" className="mr-[0.5rem]" />
                Continue to drag & drop your{' '}
                {folderType === 'documents' ? 'documents' : 'media'} above
                or&nbsp;
                <LinkButton fontWeight="bold" onClick={handleAddFiles}>
                  Browse {folderType === 'documents' ? 'Documents' : 'Media'}
                </LinkButton>
              </div>
            )}
          </div>
        )}
      </Modal>
    </DndWrapper>
  );
};

export default BulkUploaderModal;

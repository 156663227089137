import React, { ComponentProps } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { FileIcon } from 'stories/FileIcon/FileIcon';
import './file-extension-icon.scss';

interface Props extends ComponentProps<typeof FileIcon> {
  classes?: {
    fileIconComponent?: string;
  };
}

const FileExtensionIcon = ({ extension, className, classes }: Props) => (
  <div className={cn('icon', className)}>
    <FileIcon
      extension={extension}
      className={cn('text-[1.5rem]-25rem', classes?.fileIconComponent)}
    />
  </div>
);

export default FileExtensionIcon;

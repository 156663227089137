import React from 'react';
import { IFeeUpdate } from 'bundles/REconcile/actions/feesConfig';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import { nameOf } from 'types/utils';
import {
  DEFAULT_CODE_FIELD,
  DEFAULT_NAME_FIELD,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IFieldsProps } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

function FeeFields({ formData, setFormData }: IFieldsProps<IFeeUpdate>) {
  return (
    <FormComponent
      styles={{
        wrapper__item: 'mb-0',
      }}
      formItems={[
        DEFAULT_CODE_FIELD,
        DEFAULT_NAME_FIELD,
        {
          type: FieldType.INPUT_NUMBER,
          title: 'Percent',
          fieldName: nameOf<IFeeUpdate>('percent'),
          required: true,
          placeholder: 'Percent',
          leftIcon: 'percent',
          min: 0,
          max: 0,
        },
      ]}
      formData={formData}
      setFormData={setFormData}
    />
  );
}

export default FeeFields;

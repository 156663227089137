import React, { useState } from 'react';
import ChangeOrderConfigFields from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/changeOrderConfigs/ChangeOrderConfigFields';
import { IReconcileSettingsChangeOrderConfigUpdate } from 'bundles/REconcile/actions/changeOrderConfig';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { CrudModal } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

interface Props extends DialogProps<IReconcileSettingsChangeOrderConfigUpdate> {
  changeOrderConfig: IReconcileSettingsChangeOrderConfigUpdate;
  type: 'edit' | 'create';
}

function ChangeOrderConfigModal({
  changeOrderConfig,
  onSubmit,
  ...props
}: Props) {
  const [formData, setFormData] = useState(changeOrderConfig);

  return (
    <CrudModal
      entityName="Change Order Config"
      onSubmit={() => onSubmit?.(formData)}
      {...props}
    >
      <ChangeOrderConfigFields formData={formData} setFormData={setFormData} />
    </CrudModal>
  );
}

export default ChangeOrderConfigModal;

import { StyleSheet } from '@react-pdf/renderer';

export const DEFAULT_VAR_PDF = {
  fontSizeSmall: 7,
  fontSizeMedium: 8,
  height: 12,
  borderColor: '#c1c6c8',
  borderDarkColor: '#212a2f',
  backgroundSubTotal: '#707a7f',
  backgroundTotal: '#1c8336',
  backgroundHeader: '#212a2f',
};

export const uiPDFStyles = StyleSheet.create({
  smallText: {
    fontSize: DEFAULT_VAR_PDF.fontSizeSmall,
  },
  smallTitle: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    fontSize: DEFAULT_VAR_PDF.fontSizeSmall,
  },
  lightSmallText: {
    color: 'white',
    fontSize: DEFAULT_VAR_PDF.fontSizeSmall,
  },
  lightSmallTitle: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    fontSize: DEFAULT_VAR_PDF.fontSizeSmall,
    color: 'white',
    lineHeight: 1.1,
  },
  lightMediumTitle: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    fontSize: DEFAULT_VAR_PDF.fontSizeMedium,
    color: 'white',
    lineHeight: 1.1,
  },
});

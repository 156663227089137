import { IChangeOrderEvent } from 'bundles/Construction/types';
import FormTextArea from 'bundles/Shared/components/GroupForm/FormItems/FormTextArea';
import { formatAmount } from '@/shared/lib/formatting/number';
import React, { FC } from 'react';
import { Icon, InlineAlert, Input } from 'stories/index';

interface ICOProbabilityProps {
  formData: IChangeOrderEvent;
  setFormData: any;
  isVerified?: boolean;
}

const Message = ({ value = 0, probability = 100 }) => {
  return (
    <div className="flex items-center">
      <div className="mr-xs">Weighted Amount:</div>
      <div className="mr-xs">{formatAmount(value)}</div>
      <Icon iconName="arrowRightAlt" />
      <div>{formatAmount((value / 100) * probability)}</div>
    </div>
  );
};

const COProbability: FC<ICOProbabilityProps> = ({
  formData,
  setFormData,
  isVerified,
}) => {
  const changeHandle = (e) => {
    setFormData({
      ...formData,
      probability: Number(e.target.value) > 100 ? 100 : e.target.value,
    });
  };
  return (
    <div>
      <div className="flex items-center mb-s gap-m">
        <Input
          leftIcon="percent"
          className="form-item_white w-25"
          value={formData.probability}
          onChange={changeHandle}
          type="number"
          disabled={isVerified}
        />
        <InlineAlert
          message={
            <Message
              value={formData?.amount || formData?.value}
              probability={formData?.probability}
            />
          }
          status="attention"
        />
      </div>
      <FormTextArea
        title="Comment"
        fieldName="probability_comment"
        formData={formData}
        setFormData={setFormData}
        value={formData?.probability_comment ?? formData?.probabilityComment}
        disabled={isVerified}
        styles={{
          wrapper: 'form-item_white',
          nomb: true,
        }}
      />
    </div>
  );
};

export default COProbability;

import React, { ComponentProps, useCallback } from 'react';
import {
  markFormulas,
  useFormulaLinkHoverProviderEffect,
} from 'bundles/Shared/entities/formula';
import { debounce } from 'lodash-es';
import { CodeEditor, JSONEditor } from '@/shared/ui/CodeEditor/component';
import { useCodeEditorRef } from '@/shared/ui/CodeEditor/lib';

export function ReportTableConfigEditor({
  onChange,
  ...props
}: ComponentProps<typeof JSONEditor>) {
  const codeEditorRef = useCodeEditorRef();
  const handleChange = useCallback(debounce(onChange ?? (() => null), 500), [
    onChange,
  ]);

  useFormulaLinkHoverProviderEffect(codeEditorRef);

  const handleMount = useCallback<
    NonNullable<ComponentProps<typeof CodeEditor>['onMount']>
  >((editor, monaco) => {
    markFormulas(editor);
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      enableSchemaRequest: false,
      validate: false,
    });
    editor.onDidChangeModelContent(() => {
      markFormulas(editor);
    });
  }, []);

  return (
    <JSONEditor
      beforeMount={(monaco) => {
        codeEditorRef.current = monaco;
      }}
      onMount={handleMount}
      onChange={handleChange}
      {...props}
    />
  );
}

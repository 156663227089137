import dayjs from 'dayjs';

export const generateDates = (startDate: DateString, endDate: DateString) => {
  const daysCount = dayjs(endDate).diff(startDate, 'days');

  return Array.from(Array(daysCount)).map((_, i) =>
    dayjs(startDate).add(i, 'day').toDate(),
  );
};

export const dateRangesCrossing = (dateRange1: Date[], dateRange2: Date[]) => {
  const datesAsStrings = dateRange2.map((d) => d.toDateString());

  return dateRange1.some((date) =>
    datesAsStrings.includes(date.toDateString()),
  );
};

import { ResponsiveGridLayout } from '@/shared/lib/react-grid-layout/ResponsiveGridLayout';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { getSectionLayoutByIndex } from 'bundles/Shared/entities/dashboard';
import { DEFAULT_LAYOUT_PROPS } from 'bundles/Shared/widgets/dashboard/layout';

const DASHBOARD_SKELETON_ARRAY = Array.from({ length: 4 });

export const DASHBOARD_SKELETON_JSX = (
  <ResponsiveGridLayout
    {...DEFAULT_LAYOUT_PROPS}
    layouts={{
      lg: DASHBOARD_SKELETON_ARRAY.map((_, index) => ({
        i: index.toString(),
        ...getSectionLayoutByIndex(index),
      })),
    }}
  >
    {DASHBOARD_SKELETON_ARRAY.map((_, index) => (
      <div key={index}>
        <SkeletonBlock className="h-full w-full" key={index} />
      </div>
    ))}
  </ResponsiveGridLayout>
);

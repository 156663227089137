import http from 'lib/http';
import { PropertiesCamelToSnakeCase } from 'types/StringUtilityTypes';
import type * as Type from '../components/EmailSettings/editor/types';
import { buildTemplateAttributes } from './utils/templateParams/buildTemplateAttributes';

export interface IFeedMessageTemplateParams {
  feedMessageTemplateId: Type.FeedMessageTemplate['id'] | undefined;
  template: Type.FeedMessageTemplateState;
  usedVariables: Type.FeedMessageTemplateVars;
}

const buildFeedMessageTemplateParams = ({
  feedMessageTemplateId,
  template,
  usedVariables,
}: IFeedMessageTemplateParams) => ({
  id: feedMessageTemplateId,
  feed_message_template: {
    greeting_attributes: buildTemplateAttributes({
      content: template?.greeting,
      vars: usedVariables.greeting,
    }),
    call_to_action_attributes: buildTemplateAttributes({
      content: template?.callToAction,
      vars: usedVariables.callToAction,
    }),
    additional_info_attributes: buildTemplateAttributes({
      content: template.additionalInfo,
      vars: usedVariables.additionalInfo,
      withDestroy: true,
    }),
    template_attributes: {
      subject: template?.subject,
      reply_recipient_ids: template.replyRecipients?.map((re) => re.id) ?? [],
    },
  },
});

export async function feedMessageTemplatePreviews(
  params: IFeedMessageTemplateParams,
) {
  const result = await http.post(
    '/feed_message_template_previews',
    buildFeedMessageTemplateParams(params),
  );
  return result.json() as Promise<Type.FeedMessageTemplate>;
}

export async function getFeedMessageTemplateVariables() {
  const result = await http.get('/feed_message_template_variables');

  return result.json() as Promise<
    PropertiesCamelToSnakeCase<Type.FeedMessageTemplateVars>
  >;
}

export async function getFeedMessageTemplate(id: number) {
  const result = await http.get(`/feed_message_templates/${id}`);

  return result.json() as Promise<Type.FeedMessageTemplate>;
}

export async function createFeedMessageTemplate(
  params: IFeedMessageTemplateParams,
) {
  const res = await http.post(
    '/feed_message_templates',
    buildFeedMessageTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Template has been successfully created');
  }

  return responseData;
}

export async function updateFeedMessageTemplate(
  params: IFeedMessageTemplateParams,
) {
  const res = await http.put(
    `/feed_message_templates/${params.feedMessageTemplateId}`,
    buildFeedMessageTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Template has been successfully updated');
  }

  return responseData;
}

export async function sendFeedMessageTemplateEmail(
  params: IFeedMessageTemplateParams,
) {
  const res = await http.post(
    '/feed_message_template_emails',
    buildFeedMessageTemplateParams(params),
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Email has been sent');
  }

  return responseData;
}

import React, { useState } from 'react';
import {
  CrudEntityModalProps,
  CrudModal,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import {
  IReconcileSettingsUpdateSovc,
  sovcsCrudApi,
} from 'bundles/REconcile/actions/sovcs';
import SovcFields from 'bundles/REconcile/components/development/home/settingsModal/sovcs/SovcFields';
import { TReconcileSettingsJCC } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntitiesSettings';

function SovcCrudModal({
  onSubmit,
  entity,
  jccList,
  ...props
}: CrudEntityModalProps<IReconcileSettingsUpdateSovc> & {
  jccList: TReconcileSettingsJCC[];
}) {
  const [formData, setFormData] = useState(entity);
  return (
    <CrudModal
      entityName={sovcsCrudApi.entityName}
      onSubmit={() => onSubmit?.(formData)}
      {...props}
    >
      <SovcFields
        jccList={jccList}
        formData={formData}
        setFormData={setFormData}
      />
    </CrudModal>
  );
}

export default SovcCrudModal;

import {
  TFilterCardType,
  TContingencyCategory,
} from 'bundles/Construction/types';

export const CONTINGENCY_CATEGORY_SETTINGS: Record<
  TContingencyCategory,
  {
    color: string;
    label: string;
  }
> = {
  [TContingencyCategory.Used]: {
    label: 'Used',
    color: 'var(--green)',
  },
  [TContingencyCategory.Pending]: {
    label: 'Pending',
    color: 'var(--yellow-dark-2)',
  },
  [TContingencyCategory.IRC]: {
    label: 'IRC',
    color: 'var(--primary-light-4)',
  },
  [TContingencyCategory.Remaining]: {
    label: 'Remaining',
    color: 'var(--light-20)',
  },
};

export const CHANGE_ORDER_STATUS_TO_CONTINGENCY_CATEGORY_MAPPING: Record<
  TFilterCardType,
  TContingencyCategory
> = {
  approved: TContingencyCategory.Used,
  completed: TContingencyCategory.Used,
  irc: TContingencyCategory.IRC,
  pending: TContingencyCategory.Pending,
};

import { getInitialReportBuilderTemplateWidgetState } from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { resolveWidgetSectionPositionClassName } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { REPORT_BUILDER_WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import {
  isWidgetHasNoDataAndHiddenWidgetPlaceholders,
  widgetHasData,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import type { WidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery } from '@/entities/report/reportBuilder/api';
import type {
  AssetsReportBuilderReportGroupDto,
  GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdEvaluateQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { safelyParseJSON } from '@/lib/typeHelpers/safelyParseJSON';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useParams } from '@reach/router';
import { useMemo, type FC } from 'react';
import type { UnknownRecord } from 'type-fest';

export const useIsReportBuilderWidgetHidden = ({
  data,
  Component,
  hidePlaceholders,
  isError,
  deps = [],
}: {
  data: UnknownRecord | null;
  Component: FC;
  isError: boolean;
  hidePlaceholders: boolean;
  deps?: React.DependencyList;
}) => {
  return useMemo(() => {
    if (isError || !Component) return true;

    if (
      data !== null &&
      isWidgetHasNoDataAndHiddenWidgetPlaceholders(hidePlaceholders, data)
    ) {
      return true;
    }

    if (data !== null && !widgetHasData(data)) return true;

    return false;
  }, [isError, data, Component, ...deps]);
};

export const ReportBuilderWidget = ({
  reportData,
  pageOrientation,
  widgetSection,
  ...props
}: {
  reportData: GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse['report'];
  pageOrientation: AssetsReportBuilderReportGroupDto['pageOrientation'];
} & Omit<
  WidgetProps,
  | 'data'
  | 'isLoading'
  | 'isFetching'
  | 'isError'
  | 'mode'
  | 'state'
  | 'dashboardId'
  | 'widgetId'
  | 'context'
>) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const hidePlaceholders = searchParams.get('hidePlaceholders') ?? 'false';
  const assetId = useAssetIdSearchParam();

  const { data, isLoading, isFetching, isError } =
    usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery(
      {
        assetId: Number(assetId),
        reportId: params.id,
        id: widgetSection.id,
        body: {
          context: {},
        },
      },
    );

  const { Component } =
    REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const isWidgetHidden = useIsReportBuilderWidgetHidden({
    Component,
    data,
    hidePlaceholders: Boolean(safelyParseJSON(hidePlaceholders)),
    isError,
    deps: [reportData],
  });

  if (isWidgetHidden) return null;

  return (
    <Component
      mode="pdf"
      pageOrientation={pageOrientation}
      state={(widgetSection.snapshotContext ?? {}) as UnknownRecord}
      onStateChange={() => {}}
      data={data?.widget}
      isLoading={isLoading}
      isError={isError}
      isFetching={isFetching}
      widgetSection={widgetSection}
      dashboardId={''}
      widgetId={''}
      context={{}}
      className={resolveWidgetSectionPositionClassName(widgetSection.position)}
      {...props}
    />
  );
};

export const ReportBuilderTemplateWidget = ({
  pageOrientation,
  widgetSection,
  ...props
}: {
  pageOrientation: AssetsReportBuilderReportGroupDto['pageOrientation'];
} & Omit<
  WidgetProps,
  | 'data'
  | 'isLoading'
  | 'isFetching'
  | 'isError'
  | 'mode'
  | 'state'
  | 'dashboardId'
  | 'widgetId'
  | 'context'
>) => {
  const params = useParams();
  const { templateId } = params;
  const [searchParams] = useSearchParams();
  const hidePlaceholders = searchParams.get('hidePlaceholders') ?? 'false';
  const selectedDate =
    formatToDateStringForRequest(searchParams.get('date') ?? new Date()) ??
    formatToDateStringForRequest(new Date());
  const assetId = useAssetIdSearchParam();

  const state = getInitialReportBuilderTemplateWidgetState(
    widgetSection,
    selectedDate,
  );

  const { data, isLoading, isFetching, isError } =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdEvaluateQuery(
      {
        id: widgetSection.id,
        groupId: widgetSection.groupId,
        templateId,
        body: {
          asset_id: assetId,
          context: {
            date: selectedDate,
            date_from: state.dateFrom,
            date_to: state.dateTo,
            granularity: state.granularity,
          },
        },
      },
    );

  const { Component } =
    REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const isWidgetHidden = useIsReportBuilderWidgetHidden({
    Component,
    data,
    hidePlaceholders: Boolean(safelyParseJSON(hidePlaceholders)),
    isError,
  });

  if (isWidgetHidden) return null;

  return (
    <Component
      mode="pdf"
      pageOrientation={pageOrientation}
      state={
        {
          date: selectedDate,
        } as UnknownRecord
      }
      onStateChange={() => {}}
      data={data?.widget}
      isLoading={isLoading}
      isError={isError}
      isFetching={isFetching}
      widgetSection={widgetSection}
      dashboardId={''}
      widgetId={''}
      context={{}}
      className={resolveWidgetSectionPositionClassName(widgetSection.position)}
      {...props}
    />
  );
};

import http from 'lib/http';

export const FETCH_REALLOCATIONS = 'symmetre-client-api/FETCH_REALLOCATIONS';
export const CREATE_REALLOCATION = 'symmetre-client-api/CREATE_REALLOCATION';

export function fetchReallocations(legalEntityCode) {
  return async function (dispatch) {
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/reallocations`,
    );
    const payload = await res.json();

    dispatch({ type: FETCH_REALLOCATIONS, payload });
  };
}

interface ISovcForReallocationData {
  schedule_of_value_code_id: Integer;
  amount: Float;
}

export interface ICreateReallocationRequest {
  value: Float;
  contingency_impact: string;
  moved_from_id: Integer;
  moved_to_id: Integer;
  description: string;
  input_type: string;
  change_order_id?: Integer;
  sovc_sources: ISovcForReallocationData[];
  sovc_destinations: ISovcForReallocationData[];
  sub_reallocations: Pick<
    ICreateReallocationRequest,
    'moved_from_id' | 'moved_to_id' | 'value'
  >[];
}

export function createReallocation(legalEntityCode, data) {
  return async function (dispatch) {
    const res = await http.post(
      `/reconcile/legal_entities/${legalEntityCode}/reallocations`,
      data,
    );
    const responseData = await res.json();

    if (res.status >= 400) {
      toastr.error('There is a validation issue, please check input');
      throw new Error(responseData);
    } else {
      toastr.success('Reallocation has been successfully created');

      dispatch({
        type: CREATE_REALLOCATION,
        payload: responseData,
      });
    }
  };
}

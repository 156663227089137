import React from 'react';
import { DEFAULT_DROPDOWN_OFFSET, Popover } from 'stories/Popover/Popover';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import CheckList from 'stories/Checkbox/CheckList';
import {
  LE_CLASSIFICATION_OPTIONS,
  LeClassification,
} from 'bundles/Shared/entities/leClasssification';
import { getOptionsValues } from '@/shared/lib/listHelpers';

interface Props {
  onChange: (objectIds: LeClassification[]) => void;
  value: LeClassification[];
}

export function FilterByLeClassification({ onChange, value }: Props) {
  return (
    <Popover
      offset={DEFAULT_DROPDOWN_OFFSET}
      hiddenArrow
      trigger="click"
      placement="bottom-end"
      className="p-m"
      maxWidth="none"
      classes={{
        spanContainer: 'ml-s',
      }}
      template={
        <CheckList
          items={LE_CLASSIFICATION_OPTIONS}
          value={LE_CLASSIFICATION_OPTIONS.filter((option) =>
            value.includes(option.value),
          )}
          onChange={(items) => onChange(getOptionsValues(items))}
        />
      }
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={value.length > 0}
          onClose={() => onChange([])}
        />
      )}
    </Popover>
  );
}

import { HighlightedText } from '@/bundles/Settings/components/REport/CategoriesList/SearchableTree/HighlightedText';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import {
  ReportDashboardEagleEyeAsset,
  ReportDashboardLegalEntity,
} from '@/bundles/Shared/entities/dashboard';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import {
  Button,
  Field,
  Icon,
  IconButton,
  SearchInput,
  ThinTabGroup,
} from '@/stories';
import { SidePanel } from '@/stories/Modals/Modal/Modal';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { useTabs } from 'stories/Tabs/useTabs';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { PropsWithChildren, useState } from 'react';

const DICT = {
  excluded: {
    firstForm: 'exclude',
    thirdForm: 'excluded',
  },
  included: {
    firstForm: 'include',
    thirdForm: 'included',
  },
} as const;

export function LegalEntitiesSelectionModal({
  assets,
  onChangeAll,
  onIncludeAsset,
  onIncludeLegalEntity,
  onExcludeAsset,
  onExcludeLegalEntity,
  includedLegalEntities,
  excludedLegalEntityIds,
  onClose,
  disableAllButtons,
}: {
  onChangeAll: (type: 'included' | 'excluded') => void;
  onIncludeAsset: (
    assetId: ReportDashboardEagleEyeAsset['id'],
    assetExcludedLegalEntityIds: ReportDashboardLegalEntity['id'][],
  ) => void;
  onIncludeLegalEntity: (
    legalEntityId: ReportDashboardLegalEntity['id'],
  ) => void;
  onExcludeAsset: (assetId: ReportDashboardEagleEyeAsset['id']) => void;
  onExcludeLegalEntity: (
    legalEntityId: ReportDashboardLegalEntity['id'],
  ) => void;
  assets: ReportDashboardEagleEyeAsset[];
  excludedLegalEntityIds: ReportDashboardLegalEntity['id'][];
  includedLegalEntities: ReportDashboardLegalEntity[];
  onClose: () => void;
  disableAllButtons?: boolean;
}) {
  const [query, setSearchText] = useState('');

  const tabs = [
    {
      counter: excludedLegalEntityIds.length,
      id: 'excluded',
      label: `Excluded`,
    },
    {
      counter: includedLegalEntities.length,
      id: 'included',
      label: 'Included',
    },
  ] as const;

  const { tab, thinTabGroupProps } = useTabs(tabs, tabs[1]);

  const isExcludedTab = tab!.id === 'excluded';
  const dict = DICT[tab!.id];
  const opositeDict = DICT[isExcludedTab ? 'included' : 'excluded'];

  const filterLE = (le: ReportDashboardLegalEntity) => {
    if (isExcludedTab) {
      return excludedLegalEntityIds.includes(le.id);
    }
    return !excludedLegalEntityIds.includes(le.id);
  };

  const filterAsset = (asset: ReportDashboardEagleEyeAsset) => {
    const filteredByTab = asset.legalEntities.filter(filterLE);
    if (filteredByTab.length === 0) return false;

    return (
      includesInLowerCase(asset.name, query) ||
      filteredByTab.some((le) => {
        return includesInLowerCase(le.name, query);
      })
    );
  };

  const filteredAssets = assets.filter(filterAsset);

  return (
    <SidePanel
      closeOnOutsideClick
      header="Legal Entities"
      toggle={onClose}
      classes={{
        body: 'flex flex-col gap-tw-4 bg-neutral-050',
      }}
    >
      <div className="flex flex-col gap-tw-4">
        <p className="inline-regular text-neutral-550">
          You can manage which entities are displayed on the dashboard via the
          Excluded and Included tab.
        </p>
        <ThinTabGroup
          {...thinTabGroupProps}
          classes={{
            item: 'grow justify-center',
            itemContainer: 'grow',
          }}
        />
        <div className="flex items-center justify-between gap-tw-4">
          <SearchInput
            className="grow"
            size="s"
            placeholder="Asset of Legal Entity"
            value={query}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button
            variant="secondary"
            size="s"
            onClick={() => {
              onChangeAll(tab!.id);
            }}
            loading={disableAllButtons}
            disabled={disableAllButtons}
          >
            {capitalize(opositeDict.firstForm)} All
          </Button>
        </div>
        {filteredAssets.length > 0 ? (
          filteredAssets.map((asset) => {
            const filteredLegalEntities = asset.legalEntities.filter(filterLE);

            return (
              <div
                className="group/object flex flex-col gap-tw-2 rounded-[8px] bg-neutral-000 p-tw-2"
                key={asset.id}
              >
                <div className="flex items-start justify-between p-tw-1">
                  <div className="gap-tw-0 5 flex flex-col">
                    <div className="flex items-center gap-tw-2">
                      <Icon className="text-neutral-500" iconName="asset" />
                      <p className="inline-semibold text-neutral-999">
                        <HighlightedText
                          {...HighlightedText.getProps({
                            query,
                            text: asset.name,
                          })}
                        />
                      </p>
                    </div>
                    <p className="secondary-semibold text-neutral-550">
                      {filteredLegalEntities.length} of{' '}
                      {asset.legalEntities.length}{' '}
                      {pluralize('Legal Entity', asset.legalEntities.length)}{' '}
                      {pluralize('is', asset.legalEntities.length)}{' '}
                      {dict.thirdForm}
                    </p>
                  </div>
                  {filteredLegalEntities.length > 0 && (
                    <Button
                      disabled={disableAllButtons}
                      className="opacity-0 transition-opacity group-hover/object:opacity-100"
                      onClick={() => {
                        if (isExcludedTab) {
                          onIncludeAsset(
                            asset.id,
                            mapListToIds(filteredLegalEntities),
                          );
                          return;
                        }
                        onExcludeAsset(asset.id);
                      }}
                      variant="secondary"
                      size="xs"
                    >
                      {capitalize(opositeDict.firstForm)} Asset
                    </Button>
                  )}
                </div>
                {filteredLegalEntities.length > 0 && (
                  <div className="flex flex-col">
                    {filteredLegalEntities.map((le, _, self) => (
                      <div
                        key={le.id}
                        className="group inline-regular flex items-center justify-between rounded-[10px] py-tw-1 pl-tw-2 pr-tw-1 text-neutral-800 hover:bg-neutral-100"
                      >
                        <p>
                          <HighlightedText
                            {...HighlightedText.getProps({
                              query,
                              text: le.name,
                            })}
                          />
                        </p>
                        <Button
                          disabled={disableAllButtons}
                          size="xs"
                          variant="danger"
                          className="opacity-0 transition-opacity group-hover:opacity-100"
                          onClick={() => {
                            if (isExcludedTab) {
                              onIncludeLegalEntity(le.id);
                              return;
                            }
                            if (self.length === 1) {
                              onExcludeAsset(asset.id);
                              return;
                            }
                            onExcludeLegalEntity(le.id);
                          }}
                        >
                          {capitalize(opositeDict.firstForm)}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <NoDataOverlay title="No Assets Found" />
        )}
      </div>
    </SidePanel>
  );
}

LegalEntitiesSelectionModal.Wrapper = ({
  legalEntitiesCounter,
  children,
  isLoading,
  toggleLegalEntitiesModalOpened,
}: {
  legalEntitiesCounter: number;
  toggleLegalEntitiesModalOpened: () => void;
  isLoading?: boolean;
} & PropsWithChildren) => {
  return (
    <div className="col-span-3 flex flex-col gap-tw-4 rounded-[8px] bg-neutral-100 p-tw-4">
      <div className="flex flex-col gap-tw-2">
        <Field.Label required>Access check of Legal Entities</Field.Label>
        <p className="secondary-regular text-neutral-550">
          This list of legal entities based on the objects selected above
        </p>
      </div>

      <div className="relative flex items-center justify-start gap-tw-3">
        {isLoading ? (
          <SkeletonBlock className="h-[40px] w-full" />
        ) : (
          <>
            <div className="flex h-tw-8 w-tw-8 items-center justify-center rounded-lg bg-neutral-000">
              <Icon iconName="entities" />
            </div>
            <p className="inline-semibold text-neutral-800">
              {legalEntitiesCounter} Legal{' '}
              {pluralize('Entity', legalEntitiesCounter)}
            </p>
            <IconButton
              onClick={toggleLegalEntitiesModalOpened}
              iconName="edit"
            />
          </>
        )}
      </div>
      {children}
    </div>
  );
};

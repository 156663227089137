import { Button } from 'stories';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import {
  LE_CLASSIFICATION_LABEL_MAP,
  LeClassification,
} from 'bundles/Shared/entities/leClasssification';
import React from 'react';

export const LeClassificationFormatter = ({
  classification,
  showFireIcon,
  onClick,
}: {
  classification: LeClassification | null | undefined;
  onClick?: VoidFunction;
  showFireIcon?: boolean;
}) => (
  <div className="flex items-center gap-tw-1">
    {classification == null ? (
      <Button iconName="link" variant="secondary" size="xs" onClick={onClick}>
        Set Class
      </Button>
    ) : (
      LE_CLASSIFICATION_LABEL_MAP[classification]
    )}
    {showFireIcon && <FireIcon priority="medium" />}
  </div>
);

/* eslint-disable no-confusing-arrow */
import FromToFilter from 'bundles/REport/components/financials/filter/FromToFilter';
import {
  CapitalInvestmentEntryType,
  ReportLineItem,
} from 'bundles/REturn/actions/types';
import {
  FilterModel,
  PageParams,
} from 'bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/types';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import GeneralLedgersFilterBar from 'bundles/Shared/components/Filters/GeneralLedgersFilterBar';
import Table from 'bundles/Shared/components/Table/Table';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { createMonthPeriodItem } from 'stories/FlexibleFilterByPeriods/utils';
import { InlineAlert, SearchInput } from 'stories/index';
import { TSort } from 'types/Sort';
import { useGeneralLedgersAsSources } from '../../../Ownership/modals/linkTxModals/linkTxToManuallyAdded/hooks/useGeneralLedgersAsSources';
import { useTransactions } from '../../../Ownership/modals/linkTxModals/linkTxToManuallyAdded/hooks/useTransactions';
import { useTransactionColumns } from '../hooks/columns/useTransactionColumns';
import pluralize from 'pluralize';

export function SelectTransactionStep({
  entryType,
  selectedTransactions,
  setSelectedTransactions,
  setTxsLen,
  periods,
  setPeriods,
  possiblePeriods,
}: {
  entryType: CapitalInvestmentEntryType;
  selectedTransactions: ReportLineItem[];
  setSelectedTransactions: (rows: ReportLineItem[]) => void;
  setTxsLen: (len: number) => void;
  periods: IPeriodItem['period'][];
  possiblePeriods: IPeriodItem['period'][];
  setPeriods: React.Dispatch<React.SetStateAction<IPeriodItem['period'][]>>;
}) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [pageParams, setPageParams] = useState<PageParams>({
    page: 1,
    per_page: 50,
    vendorEntityIds: [],
    sort: {
      order: TSort.ASC,
      field: 'date',
    },
  });
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );
  const debouncedPeriods = useDebounce(periods, 1000);
  const { data: investmentObjData } = useLoadedInvestmentObject();

  const generalLedgers = useGeneralLedgersAsSources({
    kind: pluralize(entryType, 1) as 'contribution' | 'distribution',
  });
  const [selectedGLs, setSelectedGL] = useState(generalLedgers);
  const { loading, paginationMeta, transactions } = useTransactions({
    periods: debouncedPeriods,
    query: debouncedQuery,
    pageParams,
    selectedGLs,
    selectedLegalEntities: investmentObjData?.legalEntities,
  });

  useEffect(() => {
    setTxsLen(paginationMeta.totalSize);
  }, [paginationMeta.totalSize]);

  const columns = useTransactionColumns({ transactions });

  const handleFilterModelChange = (filterModel: FilterModel) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      type: filterModel.type,
      vendorEntityIds:
        filterModel.vendorEntities?.map(({ code }) => code) ?? [],
    }));
  };

  return (
    <div className="mr-[480px] flex w-full flex-col gap-tw-4 overflow-x-auto bg-light-10 p-tw-6">
      <div className="flex flex-col gap-tw-2.5">
        <h6 className="header6-regular">Transactions</h6>
        {entryType === 'distributions' && (
          <AnimateHeight
            duration={300}
            height={selectedTransactions.length > 1 ? 'auto' : 0}
          >
            <InlineAlert
              status="info"
              className="w-full"
              message="Selecting more than one Transaction will require you to select the same Category for both of them"
            />
          </AnimateHeight>
        )}
      </div>

      <GeneralLedgersFilterBar
        generalLedgers={generalLedgers}
        selectedGeneralLedgers={selectedGLs}
        selectedChange={setSelectedGL}
        selectionMode="exclude"
      />
      <div className="flex flex-col gap-tw-2">
        <div className="flex items-center justify-end gap-tw-2">
          <TablePagination
            className="mr-auto"
            loading={loading}
            setCurrentPage={(page) => {
              setPageParams((prevParams) => ({
                ...prevParams,
                page,
              }));
            }}
            currentPage={pageParams.page}
            totalSize={paginationMeta.totalSize}
            sizePerPage={paginationMeta.perPage}
          />
          <FromToFilter
            filterByPeriodsType="mtd"
            periodItems={periods.map(createMonthPeriodItem)}
            possiblePeriods={possiblePeriods}
            initialFrom={[createMonthPeriodItem(periods[0])]}
            initialTo={[createMonthPeriodItem(periods.at(-1)!)]}
            onUpdatePeriodItems={(newPeriodItems) => {
              const newPeriods = newPeriodItems.map(({ period }) => period);
              setPeriods(newPeriods);
            }}
          />
          <VerticalSeparator />
          <SearchInput
            placeholder="Search"
            value={query}
            size="s"
            resetValue={() => setQuery('')}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <Table
          loading={loading}
          items={transactions}
          onFilterModelChange={handleFilterModelChange}
          selectedRows={selectedTransactions}
          setSelectedRows={setSelectedTransactions}
          settings={settings}
          setSettings={setSettings}
          columns={columns}
        />
      </div>
    </div>
  );
}

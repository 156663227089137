import React from 'react';

const CircleProgress = ({
  progress = 0, size = 24, strokeWidth = 3, showProgress = false,
}) => {
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = Math.PI * (radius * 2);
  const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    <div className="flex relative">
      <svg height={size} width={size} style={{ transform: 'rotate(-90deg)' }}>
        <circle
          cx={center}
          cy={center}
          r={radius}
          stroke="#E0E3E4"
          strokeWidth={`${strokeWidth}`}
          fill="transparent"
        />
        <circle
          cx={center}
          cy={center}
          r={radius}
          stroke="#0092EE"
          strokeWidth={`${strokeWidth}`}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
        />
      </svg>
      {showProgress && (
        <div className="text-sm absolute flex items-center justify-center h-full w-full top-[1px] left-0">
          <span>{progress}%</span>
        </div>
      )}
    </div>
  );
};

export default CircleProgress;

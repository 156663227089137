import React, { useState, useEffect, useContext } from 'react';
import {
  currentUserAllowedTo,
  ASSET_PORTAL_PRODUCT_NAME,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import pluralize from 'pluralize';
import { navigate } from '@reach/router';
import { AnimationLoader, Button } from 'stories';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import EntityContext from 'bundles/Shared/EntityContext';
import NewAlbumModal from './NewAlbumModal';
import AlbumsList, { IFolder } from './AlbumsList';

interface Props {
  folders: IFolder[];
  foldersLoaded: boolean;
  fetchAlbums: () => void;
  onDelete: (folder: IFolder) => void;
  selectedItems: IFolder[];
  setSelectedItems: (items: IFolder[]) => void;
}

const Albums = ({
  folders,
  foldersLoaded,
  fetchAlbums,
  onDelete,
  selectedItems,
  setSelectedItems,
}: Props) => {
  const investmentEntity = useContext(EntityContext);
  const [newAlbumModalOpened, setNewAlbumModalOpened] = useState(false);
  const [folderToEdit, setFolderToEdit] = useState(null);

  useEffect(() => {
    setSelectedItems([]);
  }, [document.location.href]);

  const toggleSelected = (folder) => {
    if (selectedItems.find(({ id }) => id === folder.id)) {
      setSelectedItems(selectedItems.filter(({ id }) => id !== folder.id));
    } else {
      setSelectedItems(selectedItems.concat(folder));
    }
  };

  const openNewFolderModal = (folder) => {
    setFolderToEdit(folder);
    setNewAlbumModalOpened(true);
  };

  // todo: use context for it?
  const currentUserCanManage = currentUserAllowedTo(
    'manage',
    investmentEntity.type === 'Asset'
      ? ASSET_PORTAL_PRODUCT_NAME
      : FUND_PORTAL_PRODUCT_NAME,
    investmentEntity.type,
    investmentEntity.entity.id,
  );

  if (!foldersLoaded)
    return <AnimationLoader className="static min-h-[50vh]" />;

  const compare = (a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  };

  const rootFolders = folders.filter((item) => !item.parentId);

  return (
    <>
      <div className="w-full px-l">
        <div className="mb-m flex items-center justify-between md:mb-l">
          <h6 className="header-6 light-90">
            {rootFolders.length} {pluralize('album', rootFolders.length)}
          </h6>
          {currentUserCanManage && (
            <Button
              variant="primary"
              size="s"
              onClick={() => openNewFolderModal(null)}
            >
              Create Album
            </Button>
          )}
        </div>
        {folders.length === 0 && <NoDataOverlay title="No albums yet" />}
        <AlbumsList
          folders={folders.filter(({ parentId }) => !parentId).sort(compare)}
          selectedFolders={selectedItems}
          toggleSelected={toggleSelected}
          canManage={currentUserCanManage}
          onEdit={openNewFolderModal}
          onDelete={onDelete}
          onClick={(id) => {
            navigate(`./albums/${id}`);
            return null;
          }}
        />
      </div>

      {newAlbumModalOpened && (
        <NewAlbumModal
          setOpened={setNewAlbumModalOpened}
          folder={folderToEdit}
          fetchAlbums={fetchAlbums}
          parentId={undefined}
        />
      )}
    </>
  );
};

export default Albums;

// (!) Approximate behavior

import { useCallback, useLayoutEffect, useRef } from 'react';

// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
export default function useEvent<T extends (...args: any[]) => any>(
  handler: T,
) {
  const handlerRef = useRef<T | null>(null);

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback<T>(
    ((...args) => {
      // In a real implementation, this would throw if called during render
      const fn = handlerRef.current!;
      return fn(...args);
    }) as T,
    [],
  );
}

import StatisticsRow from 'bundles/Construction/components/cards/StatisticsRow/StatisticsRow';
import React, { FC } from 'react';
import { PercentFormatter } from 'stories';
import { FUNDING_SOURCES_KIND_CONFIG } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/consts';
import { FundingSource } from '@/shared/types/reconcile/FundingSource';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  typeKind: FundingSource['kind'];
  value?: number;
}

export const PercentFundingStatisticRow: FC<Props> = ({ typeKind, value }) => {
  const { bgColor, title } = FUNDING_SOURCES_KIND_CONFIG[typeKind];
  return (
    <StatisticsRow
      label={
        <div className="flex items-center gap-tw-2">
          <div className={cn('h-[8px] w-[8px] rounded-[4px]', bgColor)} />
          <div>{title}</div>
        </div>
      }
      value={
        <PercentFormatter
          value={value ?? 0}
          classes={{
            endSymbol: 'text-light-60 label-regular',
            value: 'text-dark-90 label-regular',
          }}
        />
      }
    />
  );
};

import { BaseFinancialTableWidgetConfigBaseColumnDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { FinancialTableSinglePeriodColumnAdjustmentFields } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/FinancialTableSinglePeriodColumnAdjustmentFields';
import {
  DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN,
  FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA,
  FINANCIAL_TABLE_SINGLE_DATE_WIDGET_TRAILING_PERIOD_TYPES,
  FinancialTableSingleDateWidgetConfigColumnForm,
  transformFinancialTableSingleDateWidgetColumnConfigToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/column.form';
import { financialTableSingleDateUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/updaters';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { yupResolver } from '@hookform/resolvers/yup';
import { COMPARISON_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  CreateNewForm,
  EditPanelActions,
  PeriodShiftField,
  PeriodTypeField,
  useUpdateWidgetConfig,
  useWidgetConfig,
  ValueDisplayOptionsFields,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { FormProvider, useForm } from 'react-hook-form';
import { Field, Input } from 'stories';
import { FieldsContainer } from 'stories/Field/Field';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { ColumnHeaderField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';

interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  groupId: string;
  columnConfig?: BaseFinancialTableWidgetConfigBaseColumnDto;
}

export function FinancialTableSingleDateWidgetConfigColumnPanel({
  type,
  columnConfig,
  groupId,
  onClose,
  onSubmit,
}: Props) {
  const resolver = yupResolver(
    FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA,
  );
  const methods = useForm<
    FinancialTableSingleDateWidgetConfigColumnForm & { createNew?: boolean }
  >({
    resolver,
    mode: 'all',
    defaultValues: {
      ...DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN,
      ...(columnConfig && {
        ...transformFinancialTableSingleDateWidgetColumnConfigToForm(
          columnConfig,
        ),
      }),
    },
  });
  const { control, register, handleSubmit } = methods;
  const { widget } =
    useWidgetConfig<
      (typeof COMPARISON_DASHBOARD_SECTION_TYPE)['FINANCIAL_TABLE_SINGLE_DATE']
    >();
  const [updateConfig] = useUpdateWidgetConfig(
    COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE,
  );

  const handleRemove = () => {
    updateConfig({
      config: financialTableSingleDateUpdaters.removeColumn(
        {
          columnId: columnConfig!.key.toString(),
          groupId,
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit((values) => {
    const config = financialTableSingleDateUpdaters.upsertColumn(
      { columnForm: values, columnId: columnConfig?.key.toString(), groupId },
      widget.widgetConfig,
    );
    updateConfig({ config });
    onSubmit?.({ createNew: values.createNew ?? false });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
            <Input
              placeholder="Enter Primary Title"
              className="w-full"
              {...register('label')}
            />
          </Field>
          <FinancialTableSinglePeriodColumnAdjustmentFields control={control} />
          <SectionField labelText="Specify Period">
            <PeriodTypeField
              availableTrailingPeriodTypes={
                FINANCIAL_TABLE_SINGLE_DATE_WIDGET_TRAILING_PERIOD_TYPES
              }
              control={control}
              name="period_type"
            />
            <Field labelText="Period shift">
              <PeriodShiftField control={control} name="period_shift" />
            </Field>
          </SectionField>
          <ColumnHeaderField name="header" control={control} />
          <ValueDisplayOptionsFields
            control={control}
            name="value_display_options"
          />
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}

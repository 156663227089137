import React, { FC, ReactNode } from 'react';

interface IKeyDetailsItemProps {
  title: string;
  children: ReactNode;
}

const KeyDetailsItem: FC<IKeyDetailsItemProps> = ({ title, children }) => (
  <div className="inline-regular flex justify-between border-b border-solid border-white px-tw-4 py-tw-2">
    <div className="text-light-60">{title}</div>
    <div className="text-dark-60">{children}</div>
  </div>
);

export default KeyDetailsItem;

import { TGeneralLedgerType } from '/types/GeneralLedger';

export function getExpenseTypeByGlType(
  type: 'debit' | 'credit',
  glType: TGeneralLedgerType,
) {
  /*
   *  Account   | Debit       | Credit
   *  _____________________________________
   *  asset     | income      | expenditure
   *  expense   | expenditure | income
   *  liability | income      | expenditure
   *  equity    | expenditure | income
   *  revenue   | expenditure | income
   */

  switch (glType) {
    case 'asset':
      return type === 'debit' ? 'income' : 'expenditure';
    case 'expense':
      return type === 'debit' ? 'expenditure' : 'income';
    case 'liability':
      return type === 'debit' ? 'income' : 'expenditure';
    case 'equity':
      return type === 'debit' ? 'expenditure' : 'income';
    case 'revenue':
      return type === 'debit' ? 'expenditure' : 'income';
    default:
      return null;
  }
}

import ReviewAction from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/ReviewAction';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { ReviewInfoText } from './components/reviewStep/ReviewInfoText';
import { formatDate } from '@/shared/lib/formatting/dates';
import { InvestmentEntityClassesBadges } from '../../Ownership/ownershipTable/formatters/InvestmentEntityColumn';

interface Props {
  sourceInvestment: ICapitalInvestment;
  transferDate: Date;
  amountToTransfer: number;
  receivers: ICapitalInvestment[];
}

export function TransferReviewStep({
  sourceInvestment,
  transferDate,
  amountToTransfer,
  receivers,
}: Props) {
  return (
    <div className="flex h-full grow flex-col items-center bg-light py-tw-6">
      <div className="flex max-w-[550px] flex-col gap-tw-6">
        <ReviewInfoText investment={sourceInvestment.investmentEntity.name} />

        <ReviewAction.Item
          title="Source Investment Entity"
          variant="with-shadows"
        >
          <ReviewAction.SubItem
            name={
              <div className="secondary-regular text-neutral-550">
                {formatDate(transferDate, 'MMM DD, YYYY')}
              </div>
            }
            subtitle={
              <div className="flex gap-s text-neutral-650">
                {sourceInvestment.investmentEntity.name}
                <div className="flex gap-s">
                  <InvestmentEntityClassesBadges
                    capitalInvestmentClasses={
                      sourceInvestment.investmentClasses
                    }
                  />
                </div>
              </div>
            }
          >
            <ReviewAction.AmountComparison
              past={sourceInvestment.totalContributionsDollars}
              present={
                sourceInvestment.totalContributionsDollars - amountToTransfer
              }
            />
          </ReviewAction.SubItem>
        </ReviewAction.Item>

        <ReviewAction.Item title="Receivers" variant="with-shadows">
          {receivers.map((receiver) => (
            <ReviewAction.SubItem
              key={receiver.id}
              name={
                <div className="secondary-regular text-neutral-550">
                  {formatDate(receiver.date, 'MMM DD, YYYY')}
                </div>
              }
              subtitle={
                <div className="flex gap-s text-neutral-650">
                  {receiver.investmentEntity.name}
                  <div className="flex gap-s">
                    <InvestmentEntityClassesBadges
                      capitalInvestmentClasses={receiver.investmentClasses}
                    />
                  </div>
                </div>
              }
            >
              <ReviewAction.AmountComparison
                past={receiver.totalContributionsDollars}
                present={receiver.totalContributionsDollars + receiver.amount}
              />
            </ReviewAction.SubItem>
          ))}
        </ReviewAction.Item>
      </div>
    </div>
  );
}

import {
  EntityTag,
  EntityTagKind,
  SystemEntityTag,
  TaggedEntity,
  UserEntityTag,
} from 'bundles/Shared/entities/tag/model';
import { IS_UNIFORM } from 'lib/http';
import { Formula } from 'bundles/Shared/entities/formula';
import { isEmpty } from 'lodash-es';

export const isEntityTagged = <
  T extends {
    tags: EntityTag[];
  },
>(
  entity: T,
) => entity.tags.length > 0;
export const isTagSystem = (
  tag: UserEntityTag | SystemEntityTag,
): tag is SystemEntityTag => tag.kind === EntityTagKind.System;

export const isEntitySystem = <T extends TaggedEntity>(entity: T): boolean =>
  !isEmpty(entity.tags) && entity.tags.every(isTagSystem);

export const isSystemTagAndNotUniform = (tag: EntityTag) =>
  isTagSystem(tag) && !IS_UNIFORM;

export const isSystemFormulaAndNotUniform = (formula: Formula) =>
  isEntitySystem(formula) && !IS_UNIFORM;

import * as yup from 'yup';
import {
  TableVizConfigColumn,
  TableVizConfigRow,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  FONT_STYLES,
  SORTING_VALUES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { COMPARE_TO_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/CompareToField';
import { VALUE_DISPLAY_OPTIONS_SCHEMA } from '@/shared/lib/formatting/displayOptions';
import { HIGHLITHING_RULES_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HighlightingRulesField';
import { convertDecimalToPercentage } from '@/shared/lib/converters';

export const COLUMN_SETTINGS_FORM_SCHEMA = yup.object().shape({
  header: yup
    .object()
    .shape({
      hide_title: yup.boolean().optional().default(undefined),
      hide_subtitle: yup.boolean().optional().default(undefined),
    })
    .optional()
    .default(undefined),
  sort: yup
    .string()
    .oneOf([...SORTING_VALUES])
    .optional()
    .default(undefined)
    .nullable(),
  value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  font_weight: yup.string().oneOf(FONT_STYLES).optional().default(undefined),
  comparison: COMPARE_TO_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  border_color: yup.string().optional().nullable(),
  highlighting_rules: HIGHLITHING_RULES_SCHEMA.optional()
    .nullable()
    .default(undefined),
  hide_negative_values: yup.boolean().optional().default(undefined),
});

export type ColumnSettingsForm = yup.InferType<
  typeof COLUMN_SETTINGS_FORM_SCHEMA
>;

export const transformHighlightingRulesToForm = ({
  background,
  comparison,
}: {
  comparison: TableVizConfigColumn['comparison'];
  background: TableVizConfigColumn['background'];
}): ColumnSettingsForm['highlighting_rules'] => {
  if (comparison?.to_col_id) {
    return null;
  }
  if (comparison?.rule) {
    return {
      type: 'font',
      color_rule: comparison.rule,
    };
  }
  if (background && background.type === 'gradient') {
    return {
      type: 'column',
      gradient_ignore_zeros: background.ignore_zeros,
      gradient_thresholds: background.threshold.map((t) =>
        t.type === 'percentage'
          ? {
              ...t,
              value: convertDecimalToPercentage(t.value),
            }
          : t,
      ),
    };
  }
  return null;
};

export const transformColumnSettingsToForm = (
  columnSettings: TableVizConfigColumn,
): ColumnSettingsForm => {
  return {
    value_display_options: columnSettings.value_display_options,
    sort: columnSettings.initial_sort,
    font_weight: columnSettings.font_weight ?? 'normal',
    header: columnSettings.header,
    border_color: columnSettings.border_color,
    highlighting_rules: transformHighlightingRulesToForm({
      background: columnSettings.background,
      comparison: columnSettings.comparison,
    }),
    hide_negative_values: columnSettings.comparison?.hide_negative_values,
    comparison: columnSettings.comparison?.to_col_id
      ? {
          rule: columnSettings.comparison.rule,
          compareTo: columnSettings.comparison.to_col_id,
        }
      : null,
  };
};

export const transformRowSettingsToForm = (
  rowSettings: TableVizConfigRow,
): ColumnSettingsForm => {
  return {
    value_display_options: rowSettings.value_display_options,
    font_weight: rowSettings.font_weight ?? 'normal',
    highlighting_rules: transformHighlightingRulesToForm({
      background: rowSettings.background,
      comparison: rowSettings.comparison,
    }),
  };
};

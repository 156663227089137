/* eslint-disable @typescript-eslint/no-unsafe-return */
import { navigate, useParams } from '@reach/router';
import { useMarkAsFundedRequisitionsMutation } from 'bundles/Construction/api/invoices';
import {
  addRequisitionComment,
  openComment,
} from 'bundles/REconcile/reducers/developmentBudgetVarianceCommentSlice';
import { useGetFundingSourcesQuery } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import { ROUTES_ROOT, useNavigation } from '@/shared/lib/hooks/useNavigation';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import RequisitionCell from '../requisitionCell/RequisitionCell';
import VarianceHeader, {
  VarianceHeaderParams,
} from '../../../headers/VarianceHeader';
import {
  getFundedRequisitionsValueGetter,
  getRemoveRequisitionConfirmationParams,
  getRequisitionValueGetter,
} from '../utils';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import CountBadge from '../../../headers/CountBadge/CountBadge';
import { replacePathParams } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/crudApi';
import { BasicHeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import SpecifySourcesModal from 'bundles/Shared/components/SpecifySourcesModal/SpecifySourcesModal';
import { amountSum } from '@/shared/lib/deprecated/amount';
import { ContextMenuCell } from 'bundles/Shared/components/AgGrid/Table/cellComponents/ContextMenuCell';
import { ContextActionsMenu } from '../../../widgets/RequisitionsTable';
import { useModal } from '@/shared/lib/hooks/useModal';
import { selectFundingSources } from 'bundles/DrawPackage/slices/ReconcileDrawSlice';
import { LegalEntity } from '@/entities/core/legalEntity';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import {
  selectExpandFundedRequisitions,
  setReqTableExpandFundedRequisitions,
} from '../slices/RequisitionsTableSlice';
import { Icon } from 'stories/index';
import pluralize from 'pluralize';
import {
  isVarianceJccRow,
  isVarianceSovcRow,
  IVarianceJccRow,
  IVarianceSovcRow,
} from 'bundles/REconcile/types/VarianceRows';
import { CustomCurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CustomCurrencyCellRenderer';
import { ICellRendererParams } from 'ag-grid-community';
import { generateRequisitionDates } from 'bundles/DrawPackage/utils';
import { ICommentAttachment } from 'bundles/REconcile/actions/developmentBudgetVarianceComments';
import { ICommentSubmitBody } from 'bundles/REconcile/components/comments/CommentEditor/CommentEditor';
import {
  deleteDevelopmentBudgetRequisition,
  fetchDevelopmentBudgetVariance,
} from 'bundles/REconcile/reducers/developmentBudgetVarianceSlice';

const RequisitionsContextActionsMenu = ({
  requisition,
  index,
  legalEntityCode,
}) => {
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );

  const dispatch = useAppDispatch();

  const selectedFundingSources = useAppSelector(selectFundingSources);

  const params = useParams();

  const { confirm, openModal } = useModal();

  const { data: fundingSources } = useGetFundingSourcesQuery({
    legalEntityCode,
    pageParams: {},
  });

  const [markAsFunded] = useMarkAsFundedRequisitionsMutation();

  const handleFundedRequisition = async (
    requisition: IRequisition,
    selectedFS: {
      id: number;
      reallocation: RawFloat;
    }[],
  ) => {
    const res = await markAsFunded({
      legalEntityCode: params.legalEntityCode,
      requisitionId: requisition.id,
      body: {
        funding_sources: selectedFS.map((item) => ({
          id: item.id,
          amount: item.reallocation,
        })),
      },
    });
    if (!res?.error) {
      await dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
    }
  };

  const openModalFundedRequisition = async (requisition: IRequisition) => {
    const sumLineItems = amountSum(requisition.lineItems);

    const res = await openModal(SpecifySourcesModal, {
      initialEntities: fundingSources?.items ?? [],
      revisedAmount: sumLineItems,
      editedEntities: selectedFundingSources ?? [],
      entityName: 'Funding Source',
      entityConfig: {
        columns: {
          nameColumn: {
            dataField: 'name',
            text: 'Name',
          },
          currentAmountColumn: {
            dataField: 'balance',
            text: 'Current',
          },
          amountColumn: {
            dataField: 'reallocation',
            text: 'Amount',
          },
        },
      },
    });
    if (res) {
      handleFundedRequisition(requisition, res);
    }
  };

  const handleRequisitionRemove = async () => {
    const { id: requisitionId } = requisition;
    const result = await confirm(
      getRemoveRequisitionConfirmationParams(index + 1),
    );

    if (result) {
      dispatch(
        deleteDevelopmentBudgetRequisition({
          legalEntityId: legalEntity.id,
          requisitionId,
        }),
      );
    }
  };

  return (
    <ContextActionsMenu
      actions={[
        {
          label: 'Mark as Funded',
          onClick: () => openModalFundedRequisition(requisition),
          hidden: requisition.funded,
        },
        {
          label: 'Remove',
          onClick: handleRequisitionRemove,
          hidden:
            requisition.invoicesCount > 0 || requisition.lineItems.length > 0,
        },
      ]}
    />
  );
};

const TotalFundedRequisitionsContextActionsMenu = () => {
  const expandRequisition = useAppSelector(selectExpandFundedRequisitions);
  const dispatch = useAppDispatch();

  return (
    <ContextActionsMenu
      actions={[
        {
          label: expandRequisition
            ? 'Collapse to Funded Requistions'
            : 'Expand Funded Requisitions',
          onClick: () =>
            dispatch(setReqTableExpandFundedRequisitions(!expandRequisition)),
        },
      ]}
    />
  );
};

const RequisitionColumnRenderer = (cellParams: ICellRendererParams) => {
  const { requisition, legalEntityCode, data } = cellParams;

  const { getUrl } = useNavigation();
  const dispatch = useAppDispatch();

  const mentionableUsers = useAppSelector(
    (state) => state.developmentBudgetVariance.comments.mentionableUsers,
  );
  let codeObject: LineItemsModalState['codeObject'] | null = null;
  if (isVarianceSovcRow(data)) {
    codeObject = {
      ...data?.scheduleOfValueCode,
      type: 'ScheduleOfValueCode',
    };
  }
  if (isVarianceJccRow(data)) {
    codeObject = {
      ...data?.jobCostCode,
      type: 'JobCostCode',
    };
  }

  const handleLinkTransactionClick = (
    row: IVarianceJccRow | IVarianceSovcRow,
  ) => {
    const [type, id] = row.key.split('-');

    const url = getUrl(
      'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS',
      {
        legalEntityCode,
        requisitionId: requisition.id,
        categoryId: id,
        categoryType: type === 'jcc' ? 'JobCostCode' : 'ScheduleOfValueCode',
        generalLedgers: Boolean(row.generalLedgers.length),
      },
    );
    navigate(url);
  };

  const handleCommentSubmit = async (comment: ICommentSubmitBody) => {
    // todo move to utils
    let attachments: ICommentAttachment[] = [];
    if (comment.files.length > 0) {
      const presignedFields = await presignFiles(comment.files);
      await Promise.all(presignedFields.map((f) => directUpload(f)));
      attachments = presignedFields.map((f) => ({
        key: f.signedData.fields.key,
        filename: f.file.name,
        content_type: f.file.type,
        size: f.file.size,
      }));
    }

    await dispatch(
      addRequisitionComment({
        legalEntityCode,
        requisition_id: requisition.id,
        development_categorizable_id: codeObject.id,
        development_categorizable_type: codeObject.type,
        attachments,
        message: comment.text,
      }),
    );
    await dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
  };

  const handleCommentOpen = (commentId: string) => {
    dispatch(
      openComment({
        commentId,
        typeCommentsPanel: 'one',
      }),
    );
  };

  return (
    <RequisitionCell
      requisition={requisition}
      mentionableUsers={mentionableUsers}
      onCommentSubmit={handleCommentSubmit}
      onLinkClick={handleLinkTransactionClick}
      onCommentOpen={handleCommentOpen}
      {...cellParams}
    />
  );
};

export const useRequisitionColumns = (
  requisitions: IRequisition[],
  legalEntityCode: LegalEntity['code'],
) => {
  // const handleEditRequisition = async (requisition: IRequisition) => {
  //   const { beginningDate, endingDate } = requisition;
  //   const res = await openModal(RequisitionModal, {
  //     beginningDate: new Date(beginningDate),
  //     endingDate: new Date(endingDate),
  //   });
  // }

  const requisitionColumnDefs = () =>
    requisitions.map((r, i) => ({
      colId: r.id,
      headerName: 'Requisition',
      headerComponent: (params) => (
        <ContextMenuCell
          contextMenu={
            r.funded
              ? TotalFundedRequisitionsContextActionsMenu
              : RequisitionsContextActionsMenu
          }
          requisition={r}
          index={i}
          legalEntityCode={legalEntityCode}
        >
          <BasicHeaderComponent>
            <VarianceHeader {...params} />
          </BasicHeaderComponent>
        </ContextMenuCell>
      ),
      cellRenderer: RequisitionColumnRenderer,
      cellRendererParams: {
        legalEntityCode,
        requisition: r,
        index: i + 1,
        classes: {
          inner: `${CELL_CLASS_NAMES.CommonCell.inner.basic} !justify-end`,
        },
      },
      valueGetter: getRequisitionValueGetter(r),
      aggFunc: 'sum',
      minWidth: 235,
      headerComponentParams: {
        funded: r.funded,
        haveActions: true,
        subHeaderName: generateRequisitionDates(r.beginningDate, r.endingDate),
        rightBar: Boolean(r.invoicesCount) && (
          <CountBadge
            count={r.invoicesCount}
            iconName="log"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                replacePathParams(
                  // eslint-disable-next-line max-len
                  ROUTES_ROOT.reconcile.development.legalEntity
                    .requisitionInvoices.requisition.fullPath,
                  {
                    legalEntityCode,
                    requisitionId: r.id,
                  },
                ),
              );
            }}
          />
        ),
      } as VarianceHeaderParams,
    }));
  return requisitionColumnDefs();
};

export const getTotalFundedRequisitionColumn = () => {
  const requisitions = useAppSelector(
    (state) => state.developmentBudgetVariance.data.requisitions,
  );
  const fundedRequisitions = requisitions.filter(
    (requisition) => requisition.funded,
  );
  return {
    headerName: 'Requisition',
    headerComponent: (params) => (
      <ContextMenuCell contextMenu={TotalFundedRequisitionsContextActionsMenu}>
        <BasicHeaderComponent {...params}>
          <div className="flex w-full items-end justify-between">
            <div>
              <div>Funded</div>
              <div className="secondary-regular text-neutral-500">
                {fundedRequisitions.length}{' '}
                {pluralize('Requisition', fundedRequisitions.length)}
              </div>
            </div>
            <Icon iconName="moreVertical" />
          </div>
        </BasicHeaderComponent>
      </ContextMenuCell>
    ),
    cellRenderer: CustomCurrencyCellRenderer,
    valueGetter: getFundedRequisitionsValueGetter(fundedRequisitions),
    aggFunc: 'sum',
    minWidth: 235,
  };
};

import React, { useCallback } from 'react';
import SearchInput from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import { IPaginationParams, IQueryParams } from 'types/Pagination';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams/PageParamsContext';
import { debounce } from 'lodash-es';

interface Props
  extends Omit<
    React.ComponentProps<typeof SearchInput>,
    'onChange' | 'resetValue' | 'value'
  > {
  debounceDelay?: number;
}

export function PageParamsSearch(props: Props) {
  const { setPageParams } = usePageParamsContext<
    IQueryParams & IPaginationParams
  >();

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPageParams((prevState) => ({
      ...prevState,
      query: e.target.value,
      page: 1,
    }));
  };
  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, props.debounceDelay ?? 300),
    [props.debounceDelay],
  );

  return (
    <SearchInput
      placeholder="Search"
      onChange={debouncedChangeHandler}
      resetValue={() =>
        setPageParams((prevState) => ({
          ...prevState,
          query: '',
        }))
      }
      size="s"
      {...props}
    />
  );
}

export default PageParamsSearch;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { IReportBudgetOnShow } from 'types/ReportBudget';
import {
  AnimationLoader,
  Badge,
  Breadcrumbs,
  Button,
  IconButton,
} from 'stories';
import {
  fetchReconcileOperationalTableData,
  ReconcileOperationalTableData,
  updateReportBudget,
} from 'bundles/Shared/actions/report_budgets';
import BUDGET_STATES from 'bundles/Settings/components/REport/ReportBudgets/states';
import { useModal } from '@/shared/lib/hooks/useModal';
import { OperationBudgetEditorTable } from './editor/OperationBudgetEditorTable';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { replacePathParams } from '../development/home/legalEntitySettingsModal/crud/crudApi';
import { BudgetLegalEntity } from './types';
import { ImportBudgetReq, useExportBudgetMutation } from './api/legalEntities';
import { exists } from 'lib/typeHelpers';
import { ImportBudgetResultModal } from './ReconcileOperational/ImportBudgetResultModal';
import { buildButtonProps, createImportResultsNotification } from './utils';
import { ACCEPT_BUDGET_FILE_EXTENSION } from '@/shared/lib/browser/config';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useDeleteApiReconcileOperationalBudgetsById } from '@/features/reconcile/operational/deleteBudget';
import { importFile } from '@/shared/lib/importFile';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { reconcileOperationalEnhancedApi } from '@/bundles/REconcile/components/operational/api/reconcileOperationalEnhancedApi';
import { SaveStatus } from '@/shared/ui/SaveStatus';

interface Props {
  id: BudgetLegalEntity['budgetId'];
}

export interface IAutoSavingStatus {
  value: 'idle' | 'saving' | 'saved' | 'edited' | 'error';
}

export default function OperationalBudget({ id }: RouteComponentProps<Props>) {
  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState<IReportBudgetOnShow | null>(null);
  const [meta, setMeta] = useState<
    ReconcileOperationalTableData['meta'] | null
  >(null);
  const [tableData, setTableData] = useState<
    ReconcileOperationalTableData['meta']['data'] | null
  >(null);
  const [autoSavingStatus, setAutoSavingStatus] = useState<IAutoSavingStatus>({
    value: 'idle',
  });
  const dispatch = useAppDispatch();
  const [exportBudget] = useExportBudgetMutation();

  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.reconcile.operational.fullPath,
  });
  const { confirm, openModal } = useModal();
  const { openFileDialog } = useFileDialog({
    multiple: false,
    accept: ACCEPT_BUDGET_FILE_EXTENSION,
  });

  const [deleteBudget, { isLoading: isDeletingBudget }] =
    useDeleteApiReconcileOperationalBudgetsById();

  const getBudgetTableData = async () => {
    if (!exists(id)) return;

    try {
      const data = await fetchReconcileOperationalTableData(id);
      setBudget(data.reportBudget);
      setMeta(data.meta);
      setTableData(data.meta.data);
    } catch (err) {
      console.error(err);
      navigateBack();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBudgetTableData();
  }, []);

  const update = async (newBudgetState: string) => {
    if (!exists(id)) return;

    setLoading(true);

    const payload = { state: newBudgetState };

    try {
      const res = await updateReportBudget(id, payload);
      if (!res.errors) {
        setBudget(res as IReportBudgetOnShow);
        dispatch(
          reconcileOperationalEnhancedApi.util.invalidateTags([
            'budget',
            'budgetLegalEntities',
            'budgetYears',
          ]),
        );
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const updateWithConfirmation = (budgetState: keyof typeof BUDGET_STATES) => {
    confirm({
      title: 'Update Budget',
      subtitle: `This will update the budget and move it to ${BUDGET_STATES[budgetState].label} state. Are you sure?`,
      actions: {
        primaryButton: {
          variant: 'success',
          text: 'Yes',
        },
        secondaryButton: {
          variant: 'secondary',
          text: 'Cancel',
        },
      },
    }).then((result) => {
      if (result) update(budgetState);
    });
  };

  const renderConditionalButtons = () => {
    if (budget === null) return null;

    return budget.state === 'published' ? (
      <Button
        variant="secondary"
        size="s"
        onClick={() => updateWithConfirmation('in_progress')}
      >
        Back in progress
      </Button>
    ) : (
      <Button
        variant="success"
        size="s"
        onClick={() => updateWithConfirmation('published')}
      >
        Publish
      </Button>
    );
  };

  const handleImportBudget = async (type: ImportBudgetReq['type']) => {
    if (!exists(budget)) return;

    const fileList = await openFileDialog();

    if (fileList === null || fileList.length < 1) return;

    const [file] = fileList;

    const axiosResponse = await importFile({
      file,
      url: `api/reconcile/operational/budgets/${budget.id}/import`,
      args: {
        type,
      },
    });
    const importResponse = axiosResponse?.data;
    createImportResultsNotification(importResponse);

    await openModal(ImportBudgetResultModal, {
      importResponse,
    });

    await getBudgetTableData();
  };

  const handleDeleteBudget = async () => {
    const res = await deleteBudget(id!);

    if (res == null) return;

    navigateBack();
  };

  return (
    <>
      {loading && <AnimationLoader />}
      {budget && meta && (
        <div className="flex flex-col">
          <div className="flex w-full items-center gap-tw-2 bg-light p-tw-2 pl-tw-4">
            <Breadcrumbs
              backgroundColor="transparent"
              breadcrumbs={[
                {
                  label: 'Reconcile',
                  to: replacePathParams(
                    ROUTES_ROOT.reconcile.operational.fullPath,
                  ),
                },
                {
                  label: 'Operational',
                  to: replacePathParams(
                    ROUTES_ROOT.reconcile.operational.fullPath,
                  ),
                },
                {
                  label: (
                    <>
                      {budget.legalEntity.name}
                      {' • '}
                      <span className="text-neutral-500">{budget.year}</span>
                    </>
                  ),
                  to: '',
                  isCurrent: true,
                },
              ]}
              onBackClick={navigateBack}
            />
            <Badge
              backgroundColor={BUDGET_STATES[budget.state].backgroundColor}
            >
              {BUDGET_STATES[budget.state].label}
            </Badge>
            <SaveStatus
              saved={autoSavingStatus.value === 'saved'}
              saving={
                autoSavingStatus.value === 'saving' ||
                autoSavingStatus.value === 'edited'
              }
            />
            <div className="flex-grow" />
            <IconButton
              iconName="trash"
              size="l"
              onClick={handleDeleteBudget}
              disabled={isDeletingBudget}
            />
            <Button
              {...buildButtonProps('upload')}
              onClick={() => handleImportBudget('symmetre')}
            >
              Import Budget
            </Button>
            <Button
              {...buildButtonProps('uploadCloud')}
              onClick={() => handleImportBudget('symmetre_raw')}
            >
              Import Raw Budget
            </Button>
            <Button
              {...buildButtonProps('download')}
              onClick={() => {
                exportBudget({
                  budgetId: budget.id,
                  name: budget.legalEntity.name,
                  year: budget.year,
                });
              }}
            >
              Export Budget
            </Button>
            {renderConditionalButtons()}
          </div>
          <div
            className="w-full overflow-auto"
            style={{ height: 'calc(100vh - 54px)' }}
          >
            {exists(tableData) && !loading && (
              <OperationBudgetEditorTable
                budget={budget}
                rows={tableData.rows}
                columns={tableData.columns}
                setAutoSavingStatus={setAutoSavingStatus}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

import { WindowLocation } from '@reach/router';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { ILegalEntitiable } from 'types/LegalEntitiable';

export const STATE_COLORS: Record<ILegalEntitiable['aasmState'], string> = {
  published: '--gr',
  draft: '--dark-60',
  archived: '--light-30',
};

export const OPEN_OBJECT_MODAL_SEARCH_PARAM_KEY = 'openObjectModal';

export const OPEN_OBJECT_MODAL_SEARCH_PARAM = `${OPEN_OBJECT_MODAL_SEARCH_PARAM_KEY}=true`;

export function doesObjectModalTriggered(search: WindowLocation['search']) {
  return search.includes(OPEN_OBJECT_MODAL_SEARCH_PARAM);
}

export function getStateColor(aasmState: ILegalEntitiable['aasmState']) {
  return STATE_COLORS[aasmState] || '--light-30';
}

export function checkIsAsset(object: IAsset | IFund): object is IAsset {
  return object.objectType === 'asset';
}

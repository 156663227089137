import { INTERNAL_PAGES, Page } from '@/widgets/spotlight';
import http from '@/lib/http';
import fuzzysort from 'fuzzysort';
import { groupBy } from 'lodash-es';
import { useState, useEffect, useMemo } from 'react';

export const useSpotlightGroupedResults = ({ query }: { query: string }) => {
  const [defaultResults, setDefaultResults] = useState<Page[]>([]);

  const getResults = async () => {
    const res = await http.get('/quick_search');
    const items = (await res.json()) as Page[];
    setDefaultResults([...items, ...INTERNAL_PAGES]);
  };

  useEffect(() => {
    getResults();
  }, []);

  const results =
    query !== ''
      ? fuzzysort.go(query, defaultResults, { key: 'label' }).map((r) => r.obj)
      : defaultResults;

  const [groups, maxIndex, mapOfResults] = useMemo(() => {
    let i = 0;
    const map = new Map<number, Page>();

    const res = Object.entries(groupBy(results, 'category')).map(
      ([key, values]) => {
        return [
          key,
          values.slice(0, 5).map((v) => {
            const indexInGroups = i++;
            map.set(indexInGroups, v);
            return {
              ...v,
              indexInGroups,
            };
          }),
        ] as const;
      },
    );
    return [res, i - 1, map] as const;
  }, [results]);

  return {
    groups,
    maxIndex,
    mapOfResults,
  };
};

import {
  FinancialTableWidgetConfigColumnGroup,
  FinancialTableWidgetConfigColumnGroupPropsProps,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/group';

export type FinancialTableSingleDateWidgetConfigColumnGroupPropsProps =
  FinancialTableWidgetConfigColumnGroupPropsProps;

export const FinancialTableSingleDateWidgetConfigColumnGroup =
  FinancialTableWidgetConfigColumnGroup;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  createFeeInReconcileSettings,
  deleteFeeInReconcileSettings,
  FEE_ENTITY_NAME,
  fetchFeesForReconcileBudgetSettings,
  TFetchFeesForReconcileBudgetSettingsRequest,
  TReconcileBudgetSettingsFee,
  updateFeeInReconcileSettings,
} from 'bundles/REconcile/actions/feesConfig';
import ChangeOrderConfigSelect from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/changeOrderConfigs/ChangeOrderConfigSelect';
import { useModal } from '@/shared/lib/hooks/useModal';
import FeeCrudModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fees/FeeCrudModal';
import useEvent from '@/shared/lib/hooks/useEvent';
import { useFeesColumns } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fees/useColumns';
import {
  fetchChangeOrderConfigs,
  fetchJccForReconcileSettings,
} from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import {
  CrudTable,
  CrudContextProvider,
  CrudPagination,
  CrudSearch,
  CrudCreateButton,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

interface Props {
  legalEntityId: string;
}

function FeesSettings({ legalEntityId }: Props) {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const selectedChangeOrderConfig = useAppSelector(
    (state) => state.developmentLegalEntity.selectedChangeOrderConfig,
  );
  const [fees, setFees] = useState<TReconcileBudgetSettingsFee[]>([]);
  const [totalSize, setTotalSize] = useState<number | null>(null);
  const [pageParams, setPageParams] =
    useState<TFetchFeesForReconcileBudgetSettingsRequest>({});

  const loadFees = async () => {
    const data = await fetchFeesForReconcileBudgetSettings(
      {
        legalEntityId: legalEntityId.toString(),
      },
      pageParams,
    );
    setFees(data);
    if (totalSize === null) {
      setTotalSize(data.length);
    }
  };

  const handleCreate = useEvent(async () => {
    const res = await openModal(FeeCrudModal, {
      entity: {
        name: '',
        code: '',
        percent: 0,
      },
      type: 'create',
    });
    if (res) {
      await createFeeInReconcileSettings(
        {
          legalEntityId: legalEntityId.toString(),
        },
        res,
      );
      loadFees();
    }
  });

  const handleRowEdit = useEvent(async (row: TReconcileBudgetSettingsFee) => {
    const res = await openModal(FeeCrudModal, {
      entity: {
        ...row,
      },
      type: 'edit',
    });
    if (res) {
      await updateFeeInReconcileSettings(
        { legalEntityId: legalEntityId.toString(), entityId: row.id },
        res,
      );
      loadFees();
    }
  });

  const handleRowRemove = useEvent(async (row: TReconcileBudgetSettingsFee) => {
    await deleteFeeInReconcileSettings({
      legalEntityId: legalEntityId.toString(),
      entityId: row.id,
    });
    loadFees();
  });

  const columns = useFeesColumns();

  useEffect(() => {
    if (selectedChangeOrderConfig !== null) {
      loadFees();
    }
  }, [pageParams, selectedChangeOrderConfig]);

  useEffect(() => {
    dispatch(fetchChangeOrderConfigs(legalEntityId));
    dispatch(fetchJccForReconcileSettings(legalEntityId));
  }, []);

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entities={fees}
      entityName={FEE_ENTITY_NAME}
      handlers={{
        create: handleCreate,
        delete: handleRowRemove,
        update: handleRowEdit,
      }}
    >
      <div>
        <ChangeOrderConfigSelect
          legalEntityId={legalEntityId}
          className="mb-m"
        />
        <div className="flex justify-between items-center mb-l">
          <div>
            <CrudPagination />
          </div>
          <div className="flex items-center gap-s">
            <CrudSearch />
            {!selectedChangeOrderConfig?.default && <CrudCreateButton />}
          </div>
        </div>
        <CrudTable columns={columns} />
      </div>
    </CrudContextProvider>
  );
}

export default FeesSettings;

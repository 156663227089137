import { cn } from '@/shared/lib/css/cn';
import { Permitted } from 'bundles/Shared/entities/permissions';
import {
  currentUserAllowedTo,
  currentUserIsSuperAdmin,
  TProductNames,
} from 'lib/permissions';
import { Icon, IconButton } from 'stories/index';
import { PopoverProps } from 'stories/Popover/Popover';
import { ClassNameProps } from 'types/Props';
import { ICON_MAP } from './const';
import { useAccessList } from './hooks/useAccessList';
import { LockedTooltip } from './LockedTooltip';
import { PermissionListPopover } from './PermissionListPopover';

const EmptyAccessList = () => <>-</>;

export function PermissionListLine({
  onClick,
  onEdit,
  permissions,
  productName,
  popoverProps,
  showEmptyFallback = true,
  emptyFallback = <EmptyAccessList />,
  lockedToEdit = false,
  hideActions = false,
  hidePublicIcon = false,
  pseudoLinkClassName = '',
  className,
}: {
  onClick: () => Promise<void> | void;
  permissions: Permitted;
  productName?: TProductNames;
  lockedToEdit?: boolean;
  popoverProps?: Pick<PopoverProps, 'placement'>;
  onEdit?: () => Promise<void> | void;
  hideActions?: boolean;
  hidePublicIcon?: boolean;
  showEmptyFallback?: boolean;
  emptyFallback?: React.ReactNode;
  pseudoLinkClassName?: string;
} & ClassNameProps) {
  const { isPublic } = permissions;

  const { accessList } = useAccessList({ permissions });

  if (accessList.length === 0 && showEmptyFallback) return emptyFallback;

  const isUserAllowedToEdit =
    !hideActions &&
    !lockedToEdit &&
    (!productName || currentUserAllowedTo('manage', productName));

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-tw-2 text-dark-60',
        className,
      )}
    >
      <div className="flex gap-tw-2">
        {isPublic && !hidePublicIcon && (
          <span className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-primary-light-5 text-dark-60">
            <Icon iconName={ICON_MAP.public} />
          </span>
        )}
        <PermissionListPopover
          popoverProps={popoverProps}
          isPublic={isPublic}
          accessList={accessList}
          onClick={onClick}
          classes={{
            pseudoLink: `inline-regular h-max ${pseudoLinkClassName}`,
          }}
        />
      </div>

      {!hideActions && lockedToEdit && (
        <LockedTooltip canUnlock={currentUserIsSuperAdmin()} />
      )}

      {isUserAllowedToEdit && onEdit && (
        <IconButton
          variant="white"
          className="self-start"
          iconName="edit"
          onClick={onEdit}
        />
      )}
    </div>
  );
}

import { kebabCase } from 'lodash-es';
import { KebabCase } from 'type-fest';

export class CssCustomProperty<
  const N extends string,
  const FB extends string | undefined = undefined,
> {
  static EMPTY_CASE_RESULT = 'empty-string' as const;

  #initialName: N;

  constructor(initialName: N, fallbackValue?: FB) {
    this.#initialName = initialName;
    this.#fbName = fallbackValue;
  }

  get name() {
    return CssCustomProperty.getName(this.#initialName);
  }

  get ref() {
    return CssCustomProperty.getRef<N, FB>(this.#initialName, this.#fbName);
  }

  static getName = <const SN extends string>(name: SN) => {
    return `--${kebabCase(name || this.EMPTY_CASE_RESULT)}` as `--${KebabCase<
      SN extends '' ? typeof this.EMPTY_CASE_RESULT : SN
    >}`;
  };

  static getRef = <
    const VarName extends string,
    const FallBack extends string | undefined = undefined,
  >(
    name: VarName,
    fb?: FallBack,
  ) => {
    return `var(${CssCustomProperty.getName(
      name,
    )}${CssCustomProperty.getFallback<FallBack>(fb as FallBack)})` as const;
  };

  private static getFallback = <
    const FallBack extends string | undefined = undefined,
  >(
    fb: FallBack,
  ) => {
    return (
      fb == null ? '' : (`, ${fb}` as const)
    ) as FallBack extends undefined ? '' : `, ${NonNullable<FallBack>}`;
  };

  #fbName?: FB;
}

import SetFilter from '@/bundles/REconcile/components/operational/table/SetFilter';
import GroupByPopover from '@/bundles/Settings/components/REport/GeneralLedgers/GroupByPopover';
import GeneralLedgersTable from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GeneralLedgersTable';
import GroupRowInnerRenderer from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GroupRowInnerRenderer';
import { useTableRefCallback } from '@/bundles/Settings/components/REport/GeneralLedgers/hooks/useTableRefCallback';
import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import {
  filterWithLEClass,
  useLEClassUpdateAndInvalidateEntity,
} from '@/bundles/Settings/components/REport/Operational/lib';
import { getErpColumn } from '@/bundles/Settings/components/REport/Operational/lib/getErpColumn';
import { getLegalEntityColumn } from '@/bundles/Settings/components/REport/Operational/lib/getLegalEntityColumn';
import { getPmcColumn } from '@/bundles/Settings/components/REport/Operational/lib/getPmcColumn';
import { getSreUnitStatusColumn } from '@/bundles/Settings/components/REport/Operational/lib/getSreUnitStatusColumn';
import { useSettingReportOperationTabs } from '@/bundles/Settings/components/REport/Operational/lib/useSettingReportOperationTabs';
import { useSettingReportOperationalPageParams } from '@/bundles/Settings/components/REport/Operational/lib/useSettingReportOperationalPageParams';
import { useSettingsReportOperationalLEOptions } from '@/bundles/Settings/components/REport/Operational/lib/useSettingsReportOperationalLEOptions';
import { BulkSetUnitStatusModal } from '@/bundles/Settings/components/REport/Operational/ui/BulkSetUnitStatusModal';
import { getCheckboxAggridTableColumn } from '@/bundles/Settings/shared/lib/getCheckboxAggridTableColumn';
import { getLEClassAggridTableColumn } from '@/bundles/Settings/shared/lib/getLEClassAggridTableColumn';
import BulkActionsPanel from '@/bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  SourceUnitStatus,
  SreUnitStatusFormatter,
  generateUrlSettingsUnitStatuses,
} from '@/bundles/Shared/entities/sreUnitStatuses';
import { OPERATIONAL_MAPPING_GROUP_BY_LIST } from '@/bundles/Shared/entities/sreUnitType';
import {
  useGetApiSettingsReportRentRollSourceUnitStatusesQuery,
  usePutApiSettingsReportRentRollSourceUnitStatusesSetUnitStatusMutation,
} from '@/entities/report/unitStatuses/api';
import {
  SettingsReportUnitStatusesExportButton,
  SettingsReportUnitStatusesImportButton,
} from '@/features/report/unitStatuses';
import useGridSelectedRows from '@/lib/ag-grid/useGridSelectedRows';
import { SOURCE_TYPE_SETTINGS } from '@/lib/sourceType';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { GeneralSettingsNavigationSection } from '@/stories';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { omit, startCase } from 'lodash-es';
import pluralize from 'pluralize';
import { FC, useMemo, useRef } from 'react';

export const UnitStatusesPage: FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const { openModal, confirm } = useModal();
  const isMappeUnitStatusesTab = useSettingReportOperationTabs();
  const {
    pageParams,
    setPageParams,
    setSortParams,
    setGroupByParam,
    setLegalEntityIds,
    setSreUnitStatusIds,
    setPmcIds,
    setErps
  } = useSettingReportOperationalPageParams();

  const tabParams = {
    withUnitStatus: isMappeUnitStatusesTab,
    withoutUnitStatus: !isMappeUnitStatusesTab,
  };
  const { data, isFetching, isLoading } =
    useGetApiSettingsReportRentRollSourceUnitStatusesQuery({
      ...tabParams,
      ...pageParams,
    });

  const exportParams = {
    ...tabParams,
    ...omit(pageParams, ['page', 'perPage']),
  };
  const onSetClassification =
    useLEClassUpdateAndInvalidateEntity('unit-statuses');
  const [updateUnitStatuses] =
    usePutApiSettingsReportRentRollSourceUnitStatusesSetUnitStatusMutation();

  const items = (data?.items ?? []) as SourceUnitStatus[];
  const totalSize = data?.meta.totalSize ?? 0;
  const mappedSourceUnitStatusesSize = data?.meta.withUnitStatusSize ?? 0;
  const unmappedSourceUnitStatusesSize = data?.meta.withoutUnitStatusSize ?? 0;
  const usedSreUnitStatusesSize = data?.meta.usedSreUnitStatusesSize ?? 0;

  const { allLEOptions, selectedLEOptions } =
    useSettingsReportOperationalLEOptions({
      legalEntities: data?.meta.legalEntities ?? [],
      selectedLEIds: pageParams.legalEntityIds,
      deps: [pageParams, data],
    });

  const sourceUnitStatusesMap = useMemo(() => {
    return new Map(items.map((i) => [i.id, i] as const));
  }, [items]);

  const allSreUnitStatusOptions = useMemo(() => {
    return data?.meta?.sreUnitStatuses?.map((ut) => ({ ...ut, value: ut.label, id: ut.label, label: startCase(ut.label) })) ?? [];
  }, [data]);

  const allErpsOptions = useMemo(() => {
    return data?.meta?.erps?.map((ut) => ({ id: ut, value: ut, label: SOURCE_TYPE_SETTINGS[ut].title })) ?? [];
  }, [data]);

  const allPmcOptions = useMemo(() => {
    return data?.meta?.pmcs?.map((ut) => ({ ...ut, value: ut.id, label: ut.name })) ?? [];
  }, [data]);

  const tableRef = useRef<AgGridReact>();

  const {
    selectedRows: selectedUnitStatuses,
    deselectAll: deselectAllUnitStatuses,
    handleRowCheck,
    allShownRowsChecked,
    handleAllShownRowsCheck,
    handleGroupCheck,
    resolveGroupCheck,
  } = useGridSelectedRows<SourceUnitStatus>(tableRef, items);

  const refCallBack = useTableRefCallback({
    setSortParams,
    tableRef,
  });

  const columnDefsAggrid: ColDef[] = useMemo(() => {
    const columns = [
      getCheckboxAggridTableColumn({
        allChecked: allShownRowsChecked,
        checked: selectedUnitStatuses,
        onClickAll: handleAllShownRowsCheck,
        onClick: (item, options) => handleRowCheck(item, options?.withShift),
      }),
      {
        cellClass: 'first',
        colId: 'parsedUnitStatus',
        headerName: `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}`,
        field: 'name',
        flex: 1,
        rowGroup: false,
      },
      {
        colId: 'sreUnitStatus',
        headerName: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}`,
        field: 'unitStatus',
        flex: 1,
        rowGroup: false,
        cellRenderer: ({ value, data }) => {
          const sourceUnitStatus = sourceUnitStatusesMap.get(data.id)!;

          return (
            <SreUnitStatusFormatter
              value={value}
              disabled={sourceUnitStatus?.legalEntity?.classification == null}
              onSubmit={(unitStatus) => {
                updateUnitStatuses({
                  body: {
                    unit_status: unitStatus,
                    source_unit_status_ids: [sourceUnitStatus.id],
                  },
                });
              }}
            />
          );
        },
        headerComponent: allSreUnitStatusOptions.length && isMappeUnitStatusesTab ? SetFilter : null,
        headerComponentParams: {
          filterName: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}`,
          items: allSreUnitStatusOptions,
          value: pageParams.sreUnitStatusIds,
          onChange: setSreUnitStatusIds,
          label: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}`,
          appendTo: () => document.body,
        },
      },
      getErpColumn({
        group: pageParams.group,
        options: allErpsOptions,
        onChange: setErps,
        value: pageParams.erps,
      }),
      getPmcColumn({
        group: pageParams.group,
        options: allPmcOptions,
        onChange: setPmcIds,
        value: pageParams.pmcIds,
      }),
      getLegalEntityColumn({
        allLEOptions,
        selectedLEOptions,
        setLegalEntityIds,
      }),
      getLEClassAggridTableColumn({
        isLastColumn: !isMappeUnitStatusesTab,
        actions: {
          onSetClassification,
        },
      }),
    ];
    if (isMappeUnitStatusesTab) {
      columns.push({
        cellClass: 'last',
        colId: 'actions',
        headerName: '',
        field: 'unitStatus',
        cellRenderer: ({ value, data }) => {
          return (
            <SreUnitStatusFormatter
              onReset={async () => {
                const res = await confirm();
                if (!res) return;

                updateUnitStatuses({
                  body: {
                    unit_status: null,
                    source_unit_status_ids: [data.id],
                  },
                });
              }}
              showEditButton
              initialValue={value}
              value={value}
              onSubmit={(unitStatus) => {
                updateUnitStatuses({
                  body: {
                    unit_status: unitStatus,
                    source_unit_status_ids: [data.id],
                  },
                });
              }}
            />
          );
        },
        width: 40,
      });
    }

    return columns;
  }, [
    isMappeUnitStatusesTab,
    items,
    allLEOptions,
    selectedLEOptions,
    selectedUnitStatuses,
    allShownRowsChecked,
  ]);

  const selectedUnitStatusesWithLEClass = useMemo(() => {
    return filterWithLEClass(selectedUnitStatuses);
  }, [selectedUnitStatuses]);

  const handleSetBulkUnitStatus = async () => {
    const res = await openModal(BulkSetUnitStatusModal, {
      sourceUnitStatuses: selectedUnitStatusesWithLEClass,
    });

    if (res == null) return;

    updateUnitStatuses({
      body: {
        unit_status: res,
        source_unit_status_ids: mapListToIds(selectedUnitStatusesWithLEClass),
      },
    });
    deselectAllUnitStatuses();
  };

  const handleResetBulk = async () => {
    const res = await confirm();

    if (!res) return;

    updateUnitStatuses({
      body: {
        unit_status: null,
        source_unit_status_ids: mapListToIds(selectedUnitStatuses),
      },
    });
    deselectAllUnitStatuses();
  };
  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex flex-grow flex-col gap-tw-4">
        <div className="flex gap-tw-4">
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${mappedSourceUnitStatusesSize} ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm,
              mappedSourceUnitStatusesSize,
            )}`}
            subtitle={`Mapped with ${usedSreUnitStatusesSize} sRE ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm,
              usedSreUnitStatusesSize,
            )}`}
            active={isMappeUnitStatusesTab}
            onClick={() => {
              navigate(generateUrlSettingsUnitStatuses());
            }}
          />
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${unmappedSourceUnitStatusesSize} Active ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm,
              unmappedSourceUnitStatusesSize,
            )}`}
            subtitle="Not Mapped"
            active={!isMappeUnitStatusesTab}
            onClick={() => {
              navigate(generateUrlSettingsUnitStatuses(false));
              setSreUnitStatusIds([]);
            }}
          />
        </div>
        <div className="flex flex-grow flex-col gap-tw-4">
          <div className="flex flex-col gap-tw-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <PageParamsPagination
                  loading={isFetching}
                  totalSize={totalSize}
                />
              </div>
              <div className="flex items-center gap-tw-4">
                <GroupByPopover
                  items={OPERATIONAL_MAPPING_GROUP_BY_LIST}
                  value={OPERATIONAL_MAPPING_GROUP_BY_LIST.filter((c) => {
                    return pageParams.group.includes(c.value);
                  })}
                  onChange={setGroupByParam}
                />
                <PageParamsSearch
                  suggestions={[
                    `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm} name`,
                  ]}
                  size="m"
                  placeholder={
                    SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus
                      .singularForm
                  }
                />
                <SettingsReportUnitStatusesImportButton />
                <SettingsReportUnitStatusesExportButton params={exportParams} />
              </div>
            </div>
          </div>

          <GeneralLedgersTable
            className="ag-theme-light_small-padding h-[50vh] flex-grow"
            ref={refCallBack}
            loading={isLoading}
            fetching={isFetching}
            columnDefs={columnDefsAggrid}
            rowData={items}
            onRowClicked={(e) => {
              const event = e.event as MouseEvent;
              if (!event.shiftKey) return;
              handleRowCheck(e.data, true);
            }}
            groupRowRendererParams={{
              suppressCount: true,
              innerRenderer: GroupRowInnerRenderer,
              handleGroupCheck,
              resolveGroupCheck,
            }}
          />
          {selectedUnitStatuses.length > 0 && (
            <BulkActionsPanel
              selectedRows={selectedUnitStatuses}
              setSelectedRows={deselectAllUnitStatuses}
              actions={[
                {
                  title: `Set ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}`,
                  icon: 'edit',
                  handleClick: handleSetBulkUnitStatus,
                  hidden: selectedUnitStatusesWithLEClass.length === 0,
                },
                {
                  title: `Reset ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.singularForm}`,
                  icon: 'reset',
                  handleClick: handleResetBulk,
                  hidden:
                    selectedUnitStatuses.length === 0 ||
                    !isMappeUnitStatusesTab,
                },
              ]}
            />
          )}
        </div>
      </div>
    </PageParamsProvider>
  );
};

import {
  isVarianceCategoryRow,
  isVarianceJccRow,
  isVarianceSovcRow,
} from 'bundles/REconcile/types/VarianceRows';
import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import {
  CellClassRules,
  ColDef,
  GridApi,
  RowHeightParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { isNodeTotalFooter } from 'lib/ag-grid/utils';
import { TConfirmFuncProps } from '@/shared/lib/hooks/useModal';
import { sum } from 'lodash-es';
import { AUTO_GROUP_COLUMN_KEY } from 'lib/ag-grid/constants';

export const getRequisitionValueGetter =
  (requisition: IRequisition) =>
  ({ data: row }: ValueGetterParams) => {
    if (row && (isVarianceJccRow(row) || isVarianceSovcRow(row))) {
      const value = row.data.requisitions[requisition.id];
      if (value == null) {
        return value;
      }
      return parseFloat(value);
    }
    return undefined;
  };

export const getFundedRequisitionsValueGetter =
  (requisitions: IRequisition[]) =>
  ({ data: row }: ValueGetterParams) => {
    if (row && (isVarianceJccRow(row) || isVarianceSovcRow(row))) {
      let sumValue = 0;
      requisitions.forEach((requisition) => {
        const value = row.data.requisitions[requisition.id];
        if (value !== null) {
          sumValue += Number(value);
        }
      });
      if (sumValue === 0) {
        return sumValue;
      }
      return parseFloat(String(sumValue));
    }
    return undefined;
  };

export const budgetValueGetter = ({ data: row }: ValueGetterParams) => {
  if (
    row &&
    (isVarianceJccRow(row) || isVarianceSovcRow(row)) &&
    row.data.budget != null
  ) {
    return parseFloat(row.data.budget);
  }
};

export const accountPayableValueGetter = ({ data: row }: ValueGetterParams) => {
  if (
    row &&
    (isVarianceJccRow(row) || isVarianceSovcRow(row)) &&
    row.data.budget
  ) {
    const acoountPayableData = Object.values(row.data.accounts_payable)
      .filter((v) => v != null)
      .map((v) => parseFloat(v));

    return sum(acoountPayableData);
  }
};

export const varianceValueGetter = ({
  data: row,
  showAccountPayable,
}: ValueGetterParams) => {
  if (
    row &&
    (isVarianceJccRow(row) || isVarianceSovcRow(row)) &&
    row.data.budget
  ) {
    const requisitionData = Object.values(row.data.requisitions)
      .filter((v) => v != null)
      .map((v) => parseFloat(v));

    const acoountPayableData = Object.values(row.data.accounts_payable)
      .filter((v) => v != null)
      .map((v) => parseFloat(v));

    if (showAccountPayable) {
      return (
        parseFloat(row.data.budget) -
        sum(requisitionData) -
        sum(acoountPayableData)
      );
    }
    return parseFloat(row.data.budget) - sum(requisitionData);
  }
};

export const autoGroupValueFormatter = ({
  data: row,
  node,
  value,
}: ValueFormatterParams) => {
  if (isNodeTotalFooter(node)) {
    return 'TOTALS';
  }
  if (row) {
    let label = null;
    if (isVarianceSovcRow(row)) {
      label = row.scheduleOfValueCode.name;
    }
    if (isVarianceJccRow(row)) {
      label = row.jobCostCode.name;
    }
    if (isVarianceCategoryRow(row)) {
      label = row.label;
    }
    if (node.footer) {
      return `Totals ${label}`;
    }
    return label;
  }
  return value;
};

export const getRowHeight = ({ node }: RowHeightParams) => {
  if (node.footer && node.level !== -1) {
    return 36;
  }
  if (node.footer && node.level === -1) {
    return 24;
  }
  return 32;
};

export const getRemoveRequisitionConfirmationParams = (
  idx: number,
): TConfirmFuncProps => ({
  title: `Remove Requisition ${idx}`,
  subtitle: 'Are you sure you want to remove this requisition?',
  size: 'm',
  actions: {
    secondaryButton: {
      text: 'Cancel',
      variant: 'secondary',
    },
    primaryButton: {
      text: 'Remove',
      variant: 'danger',
    },
  },
});

export const scrollToLastRequisition = (
  api: GridApi,
  requisitionColumnDefs: ColDef[],
) => {
  api.ensureColumnVisible(requisitionColumnDefs.at(-1).colId);
};

export const DEFAULT_CELL_CLASS_RULES = {
  'light-70 secondary-semibold': ({ node }) => node.footer && node.level !== -1,
  'bg-green white secondary-semibold': ({ node }) =>
    node.footer && node.level === -1,
  'dark-60 secondary-semibold': ({ colDef, node }) =>
    !node.footer &&
    colDef.colId === AUTO_GROUP_COLUMN_KEY &&
    node.hasChildren(),
  'text-right': () => true,
  'cursor-pointer': ({ node }) => node.hasChildren() && !node.footer,
} satisfies CellClassRules;

import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import AwaitingApprovalPopover from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/checkboxes/waitingLabel/AwaitingApprovalPopover';
import React from 'react';
import { LinkButton } from 'stories/index';

export const WaitingLabel = ({
  items,
  legalEntityCode,
}: {
  legalEntityCode: string;
  items: { id: number; displayNumber: string }[];
}) => (
  <div className="flex items-center justify-center">
    <FireIcon className="mr-0375rem" priority="high" />
    <span className="secondary-regular">
      <AwaitingApprovalPopover legalEntityCode={legalEntityCode} items={items}>
        <LinkButton className="secondary-semibold">
          {items.length} CORs
        </LinkButton>
      </AwaitingApprovalPopover>
      <span className="ml-xs">are still awaiting aproval</span>
    </span>
  </div>
);

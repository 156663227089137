import React, { createContext, useContext } from 'react';

interface ViewFormContext {
  viewMode?: boolean;
  setViewMode: (value: boolean) => void;
}

const ViewFormContext = createContext<ViewFormContext | null>(null);

export const ViewFormProvider = ({
  children,
  viewMode,
  setViewMode,
}: React.PropsWithChildren<ViewFormContext>) => (
  <ViewFormContext.Provider
    value={React.useMemo(
      () => ({
        viewMode,
        setViewMode,
      }),
      [viewMode, setViewMode],
    )}
  >
    {children}
  </ViewFormContext.Provider>
);

export function useViewFormContext() {
  const context = useContext(ViewFormContext);

  if (context === undefined || context === null) {
    throw new Error('useViewFormContext was used outside of its Provider');
  }

  return context;
}

import { IExtendedUser } from 'bundles/UserManagement/components/EditUserModalComponents/types';

const userFormDefaultValues = ({
  user,
}: {
  user?: Partial<IExtendedUser>;
}) => ({
  id: user?.id,
  firstName: user?.firstName ?? '',
  lastName: user?.lastName ?? '',
  email: user?.email ?? '',
  phone: user?.phone ?? '',
  userRole: user?.role,
  info: user?.info ?? null,
  canViewAll: user?.userAccessLevel?.canViewAll,
  canManageAll: user?.userAccessLevel?.canManageAll,
  canViewAllFunds: user?.userAccessLevel?.canViewAllFunds,
  canManageAllFunds: user?.userAccessLevel?.canManageAllFunds,
  canViewAssetIds: user?.userAccessLevel?.canViewObjectIds?.Asset ?? [],
  canViewFundIds: user?.userAccessLevel?.canViewObjectIds?.Fund ?? [],
  canManageAssetIds: user?.userAccessLevel?.canManageObjectIds?.Asset ?? [],
  canManageFundIds: user?.userAccessLevel?.canManageObjectIds?.Fund ?? [],
  userTags: user?.tags?.map(({ id, name }) => ({ id, label: name })) ?? [],
  investmentEntities:
    user?.investmentEntities?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [],
  adviseeIds: user?.advisees?.map((a) => a.id) ?? [],
  advisorIds: user?.advisors?.map((a) => a.id) ?? [],
});

export default userFormDefaultValues;

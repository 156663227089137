import { TInvestmentObjectClass } from 'types/IInvestmentObject';

type TPossibleProperties = 'type' | 'klass';

export type TLegalEntitableConstructor<P extends TPossibleProperties> = {
  [K in P]: 'Asset' | 'Fund';
} & {
  id: number;
};

export const mapLegalEntitiablesToRequestParams = <
  P extends TPossibleProperties,
>(
  property: P,
  legalEntitiables: TLegalEntitableConstructor<P>[],
): Record<TInvestmentObjectClass, number[]> | undefined =>
  legalEntitiables.length === 0
    ? undefined
    : {
        Asset: legalEntitiables
          .filter((so) => so[property] === 'Asset')
          .map((so) => so.id),
        Fund: legalEntitiables
          .filter((so) => so[property] === 'Fund')
          .map((so) => so.id),
      };

export const mapLegalEntitiablesFromRequest = <
  P extends TPossibleProperties,
  LE extends TLegalEntitableConstructor<P>[],
>(
  property: P,
  request: Record<TInvestmentObjectClass, number[]> | undefined,
  legalEntitiables: LE,
): LE =>
  Object.entries(request ?? {})
    .map(([key, ids]) =>
      ids.map(
        (id) =>
          legalEntitiables.find((o) => o.id === id && o[property] === key)!,
      ),
    )
    .flat() as LE;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { capitalize } from 'lodash-es';
import imagePlaceholder from 'images/building.svg';

import {
  ASSET_PORTAL_PRODUCT_NAME,
  FUND_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
} from 'lib/permissions';
import { Popover } from 'stories';

const ObjectImage = (name, pictureUrl) => {
  if (pictureUrl) {
    return (
      <img
        src={pictureUrl}
        alt={name}
        className="w-160p h-80p rounded-lg object-fit-cover"
      />
    );
  }

  return (
    <img
      src={imagePlaceholder}
      alt={name}
      className="w-160p h-80p rounded-lg object-fit-contain"
    />
  );
};

const PopoverObjectPreview = ({
  investmentObjectType,
  name,
  pictureUrl,
  stage,
  status,
}) => {
  let allowedToManageProduct = false;
  if (investmentObjectType === 'Asset') {
    allowedToManageProduct = currentUserAllowedTo('manage', ASSET_PORTAL_PRODUCT_NAME);
  } else if (investmentObjectType === 'Fund') {
    allowedToManageProduct = currentUserAllowedTo('manage', FUND_PORTAL_PRODUCT_NAME);
  }

  return (
    <div className="p-2">
      <div className="text-dark-60 weight-500 mb-1">{name}</div>
      {ObjectImage(name, pictureUrl)}
      <div className="flex mt-1">
        { stage && <span className="border rounded mr-tw-2 px-1">{stage}</span> }
        { status && allowedToManageProduct && (
          <span className="border rounded capitalize-first-letter px-1">
            {status}
          </span>
        )}
      </div>
    </div>
  );
};
PopoverObjectPreview.propTypes = {
  investmentObjectType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  stage: PropTypes.string,
  status: PropTypes.string,
};
PopoverObjectPreview.defaultProps = {
  pictureUrl: null,
  stage: null,
  status: null,
};

const InvestmentObjectHoverable = ({ investmentObjectType, investmentObject, forcedKey }) => {
  let objectData;

  if (investmentObjectType === 'Asset') {
    objectData = {
      key: forcedKey || `ioh-a-${investmentObject.id}`,
      icon: 'building',
      href: `/assets/${investmentObject.slug}`,
      name: investmentObject.name,
      status: investmentObject.status,
      stage: capitalize(investmentObject.aasmState),
      pictureUrl: investmentObject.pictureUrl,
    };
  } else if (investmentObjectType === 'Fund') {
    objectData = {
      key: forcedKey || `ioh-f-${investmentObject.id}`,
      icon: 'funds',
      href: `/funds/${investmentObject.slug}`,
      name: investmentObject.name,
      status: investmentObject.status,
      stage: capitalize(investmentObject.aasmState),
      pictureUrl: investmentObject.pictureUrl,
    };
  }

  if (!objectData) return <span className="grey-badge">Unknown Object</span>;

  return (
    <Popover
      className="!p-0"
      template={(
        <PopoverObjectPreview
          investmentObjectType={investmentObjectType}
          name={objectData.name}
          status={objectData.status}
          stage={objectData.stage}
          pictureUrl={objectData.pictureUrl}
        />
      )}
    >
      <Link
        to={objectData.href}
        className="grey-badge" id={objectData.key}>
        <i className={`sre-icon-${objectData.icon} mr-1`} />{objectData.name}
      </Link>
    </Popover>
  );
};
InvestmentObjectHoverable.propTypes = {
  investmentObjectType: PropTypes.string.isRequired,
  investmentObject: PropTypes.shape().isRequired,
  forcedKey: PropTypes.string,
};
InvestmentObjectHoverable.defaultProps = {
  forcedKey: null,
};

export default InvestmentObjectHoverable;

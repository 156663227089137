import dayjs from 'dayjs';

type BasicDateUnit = 'day' | 'month' | 'year' | 'quarter' | 'week' | 'date';
type DateUnit = Exclude<BasicDateUnit, 'quarter'>;

export const shiftDateBackward = (
  date: Date,
  duration: number,
  unit: BasicDateUnit,
): Date => dayjs(date).subtract(duration, unit).toDate();

export const shiftDateForward = (
  date: Date,
  duration: number,
  unit: BasicDateUnit,
): Date => dayjs(date).add(duration, unit).toDate();

export const todayDate = (): Date => dayjs().toDate();

export const shiftTodayBackward = (
  duration: number,
  unit: BasicDateUnit,
): Date => shiftDateBackward(todayDate(), duration, unit);

export const shiftTodayForward = (
  duration: number,
  unit: BasicDateUnit,
): Date => shiftDateForward(todayDate(), duration, unit);

export const yearAgoDate = (): Date => shiftTodayBackward(1, 'year');

export const yesterdayDate = (): Date => shiftTodayBackward(1, 'day');

export const get90DaysAgoDate = (): Date => shiftTodayBackward(90, 'day');

export const get60DaysAgoDate = (): Date => shiftTodayBackward(60, 'day');

export const get30DaysAgoDate = (): Date => shiftTodayBackward(30, 'day');

export const get6MonthsAgoDate = (): Date => shiftTodayBackward(6, 'month');

export const get1YearAgoDate = (): Date => shiftTodayBackward(1, 'year');
export const shiftDateYearBackward = (date: Date): Date =>
  shiftDateBackward(date, 1, 'year');

export const shiftDateSixMonthsBackward = (date: Date): Date =>
  shiftDateBackward(date, 6, 'month');

export const tomorrowDate = (): Date => shiftTodayForward(1, 'day');

export const isSameDate = (
  date1: Date,
  date2: Date,
  unit?: BasicDateUnit,
): boolean => dayjs(date1).isSame(date2, unit);

export const isAfterDate = (
  date1: Date,
  date2: Date,
  unit?: BasicDateUnit,
): boolean => dayjs(date1).isAfter(date2, unit);

export const isBeforeDate = (
  date1: Date,
  date2: Date,
  unit?: BasicDateUnit,
): boolean => dayjs(date1).isBefore(date2, unit);

export const isSameOrBeforeDate = (
  date1: Date,
  date2: Date,
  unit: DateUnit,
): boolean => dayjs(date1).isSameOrBefore(date2, unit);

export const isSameDay = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'day');

export const isSameMonth = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'month');

export const isSameYear = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'year');

export const isSameQuarter = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'quarter');

export const endOf = (date: Date, unit: DateUnit): Date =>
  dayjs(date).endOf(unit).toDate();

export const startOf = (date: Date, unit: DateUnit): Date =>
  dayjs(date).startOf(unit).toDate();

export const startOfMonth = (date: Date): Date => startOf(date, 'month');

export const endOfMonth = (date: Date): Date => endOf(date, 'month');

export const endOfMonthRespectNow = (date: Date): Date => {
  const endOfMonthDate = endOfMonth(date);
  return dayjs().isAfter(endOfMonthDate) ? endOfMonthDate : dayjs().toDate();
};

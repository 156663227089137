import { createAction } from '@reduxjs/toolkit';
import {
  EagleEyeBoard,
  ReportDashboardSectionPositionWithId,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard';

export const dashboardLayoutChanged = createAction<
  {
    layout: ReportDashboardSectionPositionWithId[];
    dashboardId: ReportEagleEyeDashboard['id'];
    allLayouts: Record<string, ReportDashboardSectionPositionWithId[]>;
    boardId?: EagleEyeBoard['id'];
  },
  'dashboardLayoutChanged'
>('dashboardLayoutChanged');

export const dashboardSectionExpanded = createAction<
  {
    sectionId: ReportDashboardSectionPositionWithId['i'];
    dashboardId: ReportEagleEyeDashboard['id'];
    boardId?: EagleEyeBoard['id'];
  },
  'dashboardSectionExpanded'
>('dashboardSectionExpanded');

export const updateSectionColor = createAction<
  {
    sectionId: ReportDashboardSectionPositionWithId['i'];
    dashboardId: ReportEagleEyeDashboard['id'];
    boardId?: EagleEyeBoard['id'];
    color: string | null;
  },
  'updateSectionColor'
>('updateSectionColor');

import React, { FC, ReactNode, useState } from 'react';
import { HugeModalHeaderForCe, MultilineAlert } from 'stories';
import { TStatusAlert } from 'stories/MultilineAlert/MultilineAlert';
import { IHistoryItemProps } from 'stories/HistoryItem/HistoryItem';
import { IconsId } from 'types/sre-icons';
import { ISharedDocument } from 'types/SharedDocument';
import { removeSharedFiles } from 'bundles/Assets/actions';
import { connect, ConnectedProps } from 'react-redux';
import HistoryItem from './components/HistoryItem';
import InfoBadge, { IInfoBadgeProps } from './components/InfoBadge';
import DocumentsUpload from '../../DocumentsUpload';
import AvailableActions, {
  IReconcileAction,
  IRemoveFlow,
} from './components/AvailableActions/AvailableActions';
import SnapshotModal from './../../ChangeOrderEvent/SnapshotModal';

interface IReconcileModalPageProps extends PropsFromRedux {
  header: {
    title: string;
    onCloseModal: () => void;
    subtitle?: string;
  };
  histories: IHistoryItemProps[];
  historyCustom: ReactNode;

  availableActions: {
    actionsData: IReconcileAction[];
    removeFlow?: IRemoveFlow;
    revertFlow?: IRemoveFlow;
  };
  infoBadge: IInfoBadgeProps;
  children: ReactNode;
  legalEntityCode: string;
  currentItem: object;
  alertMessage?: {
    title: string;
    status: TStatusAlert;
    icon: IconsId;
    message?: string;
  };
  containerClassName?: string;
  sidebarContent?: ReactNode;
  documents?: {
    sharedFiles: ISharedDocument[];
    isReadOnly: boolean;
    finalFile: string;
    uploadFiles: () => Promise<void>;
    refresh: () => Promise<void>;
  };
}

const ReconcileModalPage: FC<IReconcileModalPageProps> = ({
  header,
  histories = [],
  historyCustom,
  documents,
  availableActions,
  infoBadge,
  children,
  legalEntityCode,
  currentItem,
  removeItems,
  alertMessage,
  containerClassName,
  sidebarContent,
}) => {
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);

  return (
    <div className={containerClassName}>
      <div className="bg-white px-l py-m">
        <HugeModalHeaderForCe
          iconName="documentAlt"
          title={header.title}
          subtitle={header?.subtitle}
          onClose={header.onCloseModal}
        />
      </div>
      <div className="ce-modal-wrapper px-l pt-l">
        <div className="row bg-light-10">
          <div className="col-3">
            <InfoBadge
              title={infoBadge.title}
              status={infoBadge.status}
              amount={infoBadge.amount}
              onIconClick={() => setShowSnapshotModal(true)}
            />
            {alertMessage && (
              <MultilineAlert
                title={alertMessage.title}
                message={alertMessage.message}
                status={alertMessage.status}
                flexibleWidth
                icon={alertMessage.icon}
              />
            )}
            {sidebarContent}
            {Boolean(
              availableActions?.actionsData.length ||
                availableActions.removeFlow ||
                availableActions.revertFlow,
            ) && (
              <AvailableActions
                actionsData={availableActions?.actionsData.filter(
                  (action) => action.show,
                )}
                removeFlow={availableActions.removeFlow}
                revertFlow={availableActions.revertFlow}
              />
            )}
          </div>
          <div className="col-6 change-event-scroll">
            {children}
            {documents && (
              <div className="change-card">
                <div className="change-card__header">
                  <div className="change-card__title header6-bold">
                    Documents
                  </div>
                </div>
                <DocumentsUpload
                  uploadFiles={documents?.uploadFiles}
                  documents={documents?.sharedFiles}
                  isReadOnly={documents?.isReadOnly}
                  removeItems={removeItems}
                  refresh={documents?.refresh}
                  finalFile={documents?.finalFile}
                  supportSelect
                />
              </div>
            )}
          </div>
          <div className="col-3">
            <div className="header6-bold mb-m">History</div>
            <div className="change-event-scroll change-event-scroll_history">
              {histories &&
                histories.map((item, idx) => (
                  <HistoryItem
                    lastItem={idx === histories.length - 1}
                    key={item.id}
                    logItem={item}
                    currentObjectState={currentItem}
                  />
                ))}
              {historyCustom}
            </div>
          </div>
        </div>
      </div>
      {showSnapshotModal && (
        <SnapshotModal
          legalEntityCode={legalEntityCode}
          onClose={() => setShowSnapshotModal(false)}
        />
      )}
    </div>
  );
};

const mapActionCreators = {
  removeItems: removeSharedFiles,
};

const connector = connect(null, mapActionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ReconcileModalPage);

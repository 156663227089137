import { SelectedGeneralLedger } from '@/bundles/REturn/components/Ownership/ownershipsHeader/sourcesModal/SourcesModal';
import { IGeneralLedgerTransactionSource } from '@/bundles/REturn/types';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import Table from 'bundles/Shared/components/Table/Table';
import QuickFilterCheckList, {
  TQuickFilterCheckListProps,
  getDefaultMappingToOption,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { IColumn, ISortSettings } from 'bundles/Shared/components/Table/types';
import { useLocalTableSorting } from '@/shared/lib/hooks/useLocalTableSorting';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { uniqBy } from 'lodash-es';
import pluralize from 'pluralize';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { SearchInput } from 'stories/index';

type TLegalEntity = NonNullable<IGeneralLedgerTransactionSource['legalEntity']>;

interface Props {
  selectedGeneralLedgers: SelectedGeneralLedger[];
  generalLedgers: IGeneralLedgerTransactionSource[];
  onSelectedChange: Dispatch<SetStateAction<SelectedGeneralLedger[]>>;
}

function GeneralLedgersTable({
  generalLedgers,
  selectedGeneralLedgers,
  onSelectedChange,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterModel, setFilterModel] = useState<{
    legalEntityName?: TLegalEntity[];
  }>({});
  const selectedLegalEntities = filterModel.legalEntityName ?? [];
  const [sortSettings, setSortSettings] = useState<ISortSettings<'accTitle'>>(
    {},
  );
  const legalEntities = useMemo(
    () =>
      uniqBy(
        generalLedgers.filter((gl) => gl.legalEntity != null),
        (gl) => gl.legalEntity?.id,
      ).map((gl) => gl.legalEntity),
    [generalLedgers],
  );
  const sortedItems = useLocalTableSorting(generalLedgers, sortSettings, {
    accTitle: (gl) => gl.accTitle,
  });
  // todo DRY copied from `SelectInvestmentEntity`
  const filteredItems = useMemo(
    () =>
      sortedItems.filter(
        (gl) =>
          (selectedLegalEntities.length === 0 ||
            selectedLegalEntities
              .map((le) => le.id)
              .includes(gl.legalEntity.id)) &&
          (includesInLowerCase(gl.accTitle, searchQuery) ||
            includesInLowerCase(gl.legalEntity?.name ?? '', searchQuery)),
      ),
    [sortedItems, selectedLegalEntities, searchQuery],
  );

  const columns = useMemo<IColumn<TransactionSource>[]>(
    () => [
      {
        text: 'General Ledger',
        dataField: 'accTitle',
        formatter: ({ row }) => <span className="dark-60">{row.accTitle}</span>,
        sortable: true,
        headerStyle: {
          width: '50%',
        },
      },
      {
        text: 'Legal Entity',
        dataField: 'legalEntityName',
        formatter: ({ row }) => (
          <span className="light-60">{row.legalEntity?.name}</span>
        ),
        filterComponent: QuickFilterCheckList,
        filterComponentParams: {
          ...getDefaultMappingToOption<TLegalEntity>((le) => le.name),
          items: legalEntities,
        } as TQuickFilterCheckListProps<TLegalEntity>,
        headerStyle: {
          width: '50%',
        },
      },
    ],
    [legalEntities],
  );

  return (
    <div className="flex flex-col gap-m">
      <div className="flex flex-col gap-s">
        <span className="light-60 secondary-semibold font-semibold">
          {filteredItems.length}{' '}
          {pluralize('General Ledger', filteredItems.length)}
        </span>
        <SearchInput
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search"
          value={searchQuery}
          resetValue={() => setSearchQuery('')}
          size="s"
          className="mb-m"
        />
      </div>

      <Table
        borderLessOutside
        items={filteredItems}
        columns={columns}
        selectedRows={selectedGeneralLedgers}
        setSelectedRows={onSelectedChange}
        settings={sortSettings}
        setSettings={setSortSettings}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
      />
    </div>
  );
}

export default GeneralLedgersTable;

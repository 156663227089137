import React, { useRef, useState } from 'react';
import { AnimationLoader, Popover } from 'stories';
import { IUser } from '../../../types/User';
import UserFilterButton from './Filters/buttons/userFilterButton/UserFilterButton';
import ItemFilterList from './Filters/items/ItemFilterList';
import { PopoverRef } from 'stories/Popover/Popover';

interface Props {
  users: IUser[];
  shownAsUser: IUser;
  setShownAsUser: (user: IUser | undefined) => void;
  onSearch?: (value: string) => void;
}

const ShownAsButton = ({
  users,
  shownAsUser,
  setShownAsUser,
  onSearch,
}: Props) => {
  const popoverRef = useRef<PopoverRef>(null);
  const [itemFilterQuery, setItemFilterQuery] = useState('');

  const onQueryChange = (query: string) => {
    setItemFilterQuery(query);
    onSearch?.(query);
  };

  return (
    <Popover
      ref={popoverRef}
      template={
        users ? (
          <div className="list-card">
            <ItemFilterList
              className="max-h-[21.875rem] w-[21.25rem]"
              items={users}
              selectedItems={shownAsUser ? [shownAsUser] : []}
              onSelectedItemChange={(user) => {
                setShownAsUser(user);
                popoverRef.current?.hide();
              }}
              getMainFieldTitle={(item) => item.fullName}
              getSecondaryFieldTitle={(item) => item?.role?.name ?? ''}
              query={itemFilterQuery}
              onQueryChange={onQueryChange}
              loading={!users}
            />
          </div>
        ) : (
          <AnimationLoader className="static h-[350px] w-[270px] rounded-lg bg-white" />
        )
      }
      placement="bottom-end"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
      className="p-0 border border-light-20 bg-white"
      maxWidth="21.25rem"
    >
      {({ triggered }) => (
        <UserFilterButton
          onClose={() => {
            setShownAsUser(undefined);
            popoverRef.current?.hide();
          }}
          clicked={triggered}
          filtered={shownAsUser !== undefined}
        >
          {shownAsUser?.fullName}
        </UserFilterButton>
      )}
    </Popover>
  );
};

export default ShownAsButton;

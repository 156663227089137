import React, { useState } from 'react';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import { Modal } from 'stories';
import { optionsFor } from 'lib/helpers';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { updateDealPathPropertyType } from '../../actions/dealPathSettings';
import CardWithToggle from './CardWithToggle';
import getItemsAppModal from './initData';

interface IPropertyType {
  value: string;
  label: string;
}

interface FormData {
  propertyType: IPropertyType[];
}

const DealPropertyTypes = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formData, setFormData] = useState<FormData>({});

  const propertyTypes = useAppSelector(
    (state) => state.dealPathSettings.propertyTypes,
  );
  const types = useAppSelector((state) => state.dealPathSettings.types);
  const dispatch = useAppDispatch();

  const initGroupFormItems = getItemsAppModal(types);

  const submit = async () => {
    const payload = {
      aliases: formData.propertyType.map((item) => item.value),
    };

    await dispatch(updateDealPathPropertyType(selectedCategory.id, payload));
    setSelectedCategory(null);
  };

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="px-tw-5 py-tw-3">
          <h3>Property Types</h3>
        </div>
        <div className="px-tw-5 py-tw-5">
          <div className="flex flex-wrap">
            {propertyTypes.map((propertyType) => (
              <CardWithToggle
                key={propertyType.id}
                item={propertyType}
                onToggle={() =>
                  dispatch(
                    updateDealPathPropertyType(propertyType.id, {
                      active: !propertyType.active,
                    }),
                  )
                }
                onEdit={() => {
                  setSelectedCategory(propertyType);
                  setFormData({
                    propertyType: optionsFor(propertyType.aliases),
                  });
                }}
                footer={() => (
                  <div className="font-12">
                    <span className="dark-60 mr-1">Linked with:</span>
                    <span>{propertyType.aliases?.join(', ')}</span>
                  </div>
                )}
              />
            ))}
          </div>
        </div>
      </CardWrapper>
      {selectedCategory && (
        <Modal
          header="Map property types"
          toggle={() => setSelectedCategory(null)}
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
        >
          <GroupForm
            groupFormItems={initGroupFormItems}
            onSubmit={submit}
            formData={formData}
            setFormData={setFormData}
            buttonConfig={{
              titleBtn: 'Submit',
              iconName: 'null',
              withoutCancel: true,
              width: '100%',
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default DealPropertyTypes;

import React, { useRef } from 'react';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { PseudoLink, Tooltip } from 'stories';

export const RemarkCell = ({
  text,
  maxWidth = 'auto',
}: {
  text?: string | null;
  maxWidth?: number | 'auto';
}) => {
  // todo move in separate component TruncatedText
  if (!text) return <>-</>;
  const container = useRef(null);
  const isOverflowed = useIsTextTruncated(container, text);

  return (
    <div
      style={{
        width: maxWidth,
      }}
      ref={container}
    >
      <Tooltip
        classes={{
          spanContainer: 'd-inline',
        }}
        mainText={<span className="break-all">{text}</span>}
        disabled={!isOverflowed}
        maxWidth={300}
        style={{
          overflow: 'hidden',
        }}
      >
        {isOverflowed ? (
          <PseudoLink className="overflow-hidden text-ellipsis whitespace-nowrap">
            {text}
          </PseudoLink>
        ) : (
          <>{text}</>
        )}
      </Tooltip>
    </div>
  );
};

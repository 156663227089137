import React, { useEffect, useState } from 'react';
import {
  createSovcInReconcileSettings,
  deleteSovcInReconcileSettings,
  fetchSOVCsForReconcileBudgetSettings,
  IFetchSOVCsForReconcileBudgetSettingsRequest,
  SOVC_ENTITY_NAME,
  TReconcileBudgetSettingsSoVC,
  updateSovcInReconcileSettings,
} from 'bundles/REconcile/actions/sovcConfig';
import { useModal } from '@/shared/lib/hooks/useModal';
import SovcCrudModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/sovc/SovcCrudModal';
import useEvent from '@/shared/lib/hooks/useEvent';
import {
  INIT_SOVC,
  mapSovcFromRequest,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/sovc/utils';
import { useSovcTableColumns } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/sovc/useColumns';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  fetchChangeOrderConfigs,
  fetchJccForReconcileSettings,
} from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import ChangeOrderConfigSelect from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/changeOrderConfigs/ChangeOrderConfigSelect';
import {
  CrudContextProvider,
  CrudTable,
  CrudTablePanel,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { useParams } from '@reach/router';

interface Props {
  legalEntityId: string;
}

function SovcConfig({ legalEntityId }: Props) {
  const params = useParams();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const selectedChangeOrderConfig = useAppSelector(
    (state) => state.developmentLegalEntity.selectedChangeOrderConfig,
  );
  const [softValueCodes, setSoftValueCodes] = useState<
    TReconcileBudgetSettingsSoVC[]
  >([]);
  const [pageParams, setPageParams] =
    useState<IFetchSOVCsForReconcileBudgetSettingsRequest>({});

  useEffect(() => {
    dispatch(fetchChangeOrderConfigs(legalEntityId));
    dispatch(fetchJccForReconcileSettings(legalEntityId));
  }, []);

  const loadSovcs = async () => {
    const data = await fetchSOVCsForReconcileBudgetSettings(
      {
        legalEntityId,
      },
      pageParams,
    );
    setSoftValueCodes(data);
  };

  useEffect(() => {
    if (selectedChangeOrderConfig !== null) {
      loadSovcs();
    }
  }, [selectedChangeOrderConfig, pageParams]);

  const handleRowRemove = useEvent(
    async (row: TReconcileBudgetSettingsSoVC) => {
      const res = await deleteSovcInReconcileSettings({
        entityId: row.id,
        legalEntityId,
      });
      loadSovcs();
    },
  );

  const handleRowEdit = useEvent(async (row: TReconcileBudgetSettingsSoVC) => {
    const result = await openModal(SovcCrudModal, {
      legalEntityCode: params.legalEntityCode,
      entity: mapSovcFromRequest(row),
      type: 'edit',
    });
    if (result) {
      await updateSovcInReconcileSettings(
        {
          legalEntityId,
          entityId: row.id,
        },
        result,
      );
      loadSovcs();
    }
  });

  const handleCreate = useEvent(async () => {
    const result = await openModal(SovcCrudModal, {
      legalEntityCode: params.legalEntityCode,
      entity: INIT_SOVC,
      type: 'create',
    });
    if (result) {
      await createSovcInReconcileSettings(
        {
          legalEntityId,
        },
        result,
      );
      loadSovcs();
    }
  });

  const columns = useSovcTableColumns(params?.legalEntityCode);

  const handleFilterModelChange = (filterModel: { jcc?: { id: number }[] }) =>
    setPageParams((prevParams) => ({
      ...prevParams,
      job_cost_code_ids: filterModel.jcc?.map(({ id }) => id),
    }));

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entities={softValueCodes}
      entityName={SOVC_ENTITY_NAME}
      handlers={{
        create: handleCreate,
        delete: handleRowRemove,
        update: handleRowEdit,
      }}
    >
      <div>
        <ChangeOrderConfigSelect
          legalEntityId={legalEntityId}
          className="mb-m"
        />
        <CrudTablePanel />
        <CrudTable
          onFilterModelChange={handleFilterModelChange}
          columns={columns}
        />
      </div>
    </CrudContextProvider>
  );
}

export default SovcConfig;

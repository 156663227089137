import { useMemo } from 'react';
import {
  DEFAULT_CODE_COLUMN,
  DEFAULT_NAME_COLUMN,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants/columns';
import {
  IReconcileSettingsContact,
  TGetContactsRequestParams,
} from 'bundles/REconcile/actions/contacts';
import { IUseColumnsParams } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { IColumn } from 'bundles/Shared/components/Table/types';

export function useContactsCrudColumns({
  setParams,
  params,
}: IUseColumnsParams<TGetContactsRequestParams>) {
  return useMemo<IColumn<IReconcileSettingsContact>[]>(
    () => [DEFAULT_CODE_COLUMN, DEFAULT_NAME_COLUMN],
    [],
  );
}

/* eslint-disable react/jsx-wrap-multilines */
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { ReportLineItem } from 'bundles/REturn/actions/types';
import ReviewAction from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/ReviewAction';
import { CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP } from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/consts';
import { sumBy } from 'lodash-es';
import { EntyTxWithReviewInfo } from '../../types';
import { ReviewDistributionKinds } from './ReviewDistributionKinds';

import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props {
  entryType: 'contribution' | 'distribution';
  capitalInvestments: InvestmentIndex[];
  transactions: ReportLineItem[];
  uniqEntryTxWithReviewInfoItems: EntyTxWithReviewInfo[];
}

export function ReviewBulkLinking({
  entryType,
  capitalInvestments,
  transactions,
  uniqEntryTxWithReviewInfoItems,
}: Props) {
  const allEntriesTotalAmountKeyName =
    CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP[entryType];

  const totalAmountPast = Number(
    capitalInvestments.length > 0
      ? capitalInvestments[0][allEntriesTotalAmountKeyName]
      : 0,
  );

  const totalTransactionsAmount =
    transactions.length > 0
      ? sumBy(transactions, (transaction) =>
          convertCentsToDollars(Number(transaction.amountCents)),
        )
      : 0;

  const totalAmountPresent = totalAmountPast + totalTransactionsAmount;

  return (
    <ReviewAction.Item title="Investment Entity" variant="with-shadows">
      <ReviewAction.SubItem
        name={capitalInvestments[0].investmentEntity.name}
        subtitle={
          uniqEntryTxWithReviewInfoItems[0].kindReviewInfo && (
            <ReviewDistributionKinds
              kindReviewInfo={uniqEntryTxWithReviewInfoItems[0].kindReviewInfo}
            />
          )
        }
      >
        <ReviewAction.AmountComparison
          past={totalAmountPast}
          present={totalAmountPresent}
        />
      </ReviewAction.SubItem>
    </ReviewAction.Item>
  );
}

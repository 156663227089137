import React from 'react';
import { capitalize } from 'lodash-es';
import { Badge } from 'stories';
import { getAssetStageColor, getAssetStatusColors } from './utils';
import { ObjectType } from './types';

export const tableColumns = (type: ObjectType) => [
  {
    dataField: 'name',
    text: `${type.toUpperCase()} NAME`,
    headerStyle: { width: type === 'fund' ? '80%' : 'auto' },
  },
  {
    dataField: 'aasmState',
    text: 'STATUS',
    formatter: ({ row: object }) => (
      <Badge
        backgroundColor={getAssetStatusColors(object.aasmState).background}
        textColor={getAssetStatusColors(object.aasmState).color}
      >
        {capitalize(object.aasmState)}
      </Badge>
    ),
    headerStyle: { width: type === 'fund' ? '20%' : 'auto' },
  },
  {
    dataField: 'stage',
    text: 'STAGE',
    formatter: ({ row: object }) => {
      if (object?.stage?.name) {
        return (
          <Badge backgroundColor={getAssetStageColor(object)}>
            {object?.stage?.name}
          </Badge>
        );
      }

      return '-';
    },
    hidden: type === 'fund',
  },
];

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '@/app/stores';
import { typedLocalStorage } from '@/shared/lib/localStorage';

interface LeftSidebarState {
  isPinned: boolean;
  expanded?: boolean;
}

const initialState: LeftSidebarState = {
  isPinned: typedLocalStorage.getItem('isLeftSidebarPinned') !== null,
  expanded: typedLocalStorage.getItem('isLeftSidebarPinned') !== null,
};

export const leftSidebarSlice = createSlice({
  name: 'leftSidebar',
  initialState,
  reducers: {
    expandSidebar: (state) => {
      state.expanded = true;
    },
    collapseSidebar: (state) => {
      state.expanded = false;
    },
    toggleSidebar: (state) => {
      state.expanded = !state.expanded;
    },
    togglePinStateOfLeftSidebar: (state) => {
      state.isPinned
        ? typedLocalStorage.removeItem('isLeftSidebarPinned')
        : typedLocalStorage.setItem('isLeftSidebarPinned', true);
      state.isPinned = !state.isPinned;
    },
  },
});

export const {
  togglePinStateOfLeftSidebar,
  collapseSidebar,
  expandSidebar,
  toggleSidebar,
} = leftSidebarSlice.actions;

export const isLeftSidebarPinned = (state: TRootState) =>
  state.leftSidebar.isPinned;
export const isLeftSidebarExpanded = (state: TRootState) =>
  state.leftSidebar.expanded || state.leftSidebar.isPinned;

export default leftSidebarSlice.reducer;

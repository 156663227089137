import { useSettingsReportChargeCodesImport } from '@/features/report/chargeCodes/settingsImport/lib';
import { ImportButton } from '@/shared/ui/ImportButton';

export const SettingsReportChargeCodesImportButton = () => {
  const [sendFile, options] = useSettingsReportChargeCodesImport();

  return (
    <ImportButton
      onClick={sendFile}
      loading={options.isLoading}
      disabled={options.isLoading}
    />
  );
};

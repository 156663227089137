import * as React from 'react';
import { ComponentProps, useMemo } from 'react';
import { CONTINGENCY_CATEGORY_SETTINGS } from 'bundles/Construction/constants/contingencyCategory';
import {
  accountingNumberFormat,
  percentNumberFormat,
} from '@/shared/lib/formatting/number';
import StatisticsRow, {
  StatisticRowList,
} from 'bundles/Construction/components/cards/StatisticsRow/StatisticsRow';
import StatisticBulletItem from 'stories/filterCard/statistic/StatisticBulletItem';
import { IChangeOrderEventBages } from 'types/reconcile';
import {
  TFilterCardType,
  TContingencyCategory,
} from 'bundles/Construction/types';
import { getContingencyCategoriesPercent } from 'bundles/Construction/utils';
import { cn } from '@/shared/lib/css/cn';
import { sum } from 'lodash-es';
import { ClassNameProps } from 'types/Props';

interface Props extends ClassNameProps {
  report: IChangeOrderEventBages;
  cardType?: TFilterCardType;
  activeCategories?: Exclude<
    TContingencyCategory,
    TContingencyCategory.Remaining
  >[];
  colors?: Record<TContingencyCategory, string>;
}

const ACTIVE_STATUS_CLASSNAME = 'bg-green-light-4 green-dark-2';

const StyledStatisticBulletItem = ({
  ...props
}: ComponentProps<typeof StatisticBulletItem>) => (
  <StatisticBulletItem
    classes={{
      label: 'light-90 label-regular',
      value: 'light-90 label-regular',
    }}
    {...props}
  />
);

function ConstructionReportCardStatistics({
  cardType,
  report,
  activeCategories = [
    TContingencyCategory.Used,
    TContingencyCategory.IRC,
    TContingencyCategory.Pending,
  ],
  colors,
  className,
}: Props) {
  const categoriesPercent = getContingencyCategoriesPercent(report);

  const remaining = useMemo(
    () =>
      100 -
      sum(
        Object.entries(categoriesPercent)
          .filter(([k]) => activeCategories.includes(k))
          .map(([_, v]) => v),
      ),
    [],
  );

  return (
    <div className={cn('flex flex-col gap-m', className)}>
      <div>
        <StyledStatisticBulletItem
          className="mb-s"
          backgroundColor={
            colors?.USED ?? CONTINGENCY_CATEGORY_SETTINGS.USED.color
          }
          label={CONTINGENCY_CATEGORY_SETTINGS.USED.label}
          value={percentNumberFormat(categoriesPercent.USED)}
        />
        <StatisticRowList>
          <StatisticsRow
            className={cn(cardType === 'completed' && ACTIVE_STATUS_CLASSNAME)}
            label="Completed CEs"
            value={accountingNumberFormat(report.approvedCo)}
          />
          <StatisticsRow
            className={cn(cardType === 'approved' && ACTIVE_STATUS_CLASSNAME)}
            label="Approved CEs"
            value={accountingNumberFormat(report.approvedCor)}
          />
          <StatisticsRow
            label="Other Allocations"
            value={accountingNumberFormat(report.otherReallocations)}
          />
        </StatisticRowList>
      </div>
      {activeCategories.includes(TContingencyCategory.Pending) && (
        <div>
          <StyledStatisticBulletItem
            className="mb-s"
            backgroundColor={
              colors?.PENDING ?? CONTINGENCY_CATEGORY_SETTINGS.PENDING.color
            }
            label={CONTINGENCY_CATEGORY_SETTINGS.PENDING.label}
            value={percentNumberFormat(categoriesPercent.PENDING)}
          />
          <StatisticRowList>
            <StatisticsRow
              label="COR pending"
              value={accountingNumberFormat(report.pendingCor)}
            />
            <StatisticsRow
              label="PCOs submitted"
              value={accountingNumberFormat(report.pendingPco)}
            />
            <StatisticsRow
              label="CORs disputed"
              value={accountingNumberFormat(report.disputed)}
            />
          </StatisticRowList>
        </div>
      )}
      {activeCategories.includes(TContingencyCategory.IRC) && (
        <div>
          <StyledStatisticBulletItem
            backgroundColor={
              colors?.IRC ?? CONTINGENCY_CATEGORY_SETTINGS.IRC.color
            }
            label={CONTINGENCY_CATEGORY_SETTINGS.IRC.label}
            value={percentNumberFormat(categoriesPercent.IRC)}
          />
        </div>
      )}
      <div>
        <StyledStatisticBulletItem
          backgroundColor={
            colors?.REMAINING ?? CONTINGENCY_CATEGORY_SETTINGS.REMAINING.color
          }
          label={CONTINGENCY_CATEGORY_SETTINGS.REMAINING.label}
          value={percentNumberFormat(remaining)}
        />
      </div>
    </div>
  );
}

export default ConstructionReportCardStatistics;

import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import React from 'react';
import { ERPSystem } from 'bundles/Shared/entities/erpsystem/model';

interface Props extends ISharedSelectProps {
  options: {
    value: `${ERPSystem}`;
    label: string;
  }[];
  value?: `${ERPSystem}`;
  disabled?: boolean;
}

export const ERPSystemSelect = ({
  onChange,
  disabled,
  options,
  value,
}: Props) => (
  <SharedSelect
    placeholder="Select System"
    options={options}
    onChange={onChange}
    value={options?.filter((opt) => opt.value === value)}
    disabled={disabled}
    newGen
  />
);

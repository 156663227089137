import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from 'types/Props';
import { FormulaVariable } from 'bundles/Shared/entities/formula';
import { isEmpty } from 'lodash-es';
import { EntityTagLabel } from 'bundles/Shared/entities/tag/ui/EntityTagLabel';
import { FormulaReferenceLabel } from 'bundles/Shared/entities/formula/ui/FormulaReferenceLabel';
import { EntityTag } from 'bundles/Shared/entities/tag';

interface Props extends ClassNameProps {
  label: React.ReactNode;
  reference: FormulaVariable['reference'];
  tags?: EntityTag[];
  description?: FormulaVariable['description'];
  labelPanel?: React.ReactNode;
}

export function FormulaVariableCard({
  label,
  labelPanel,
  tags,
  reference,
  description,
  className,
}: Props) {
  return (
    <div
      className={cn(
        'flex flex-col gap-tw-2 bg-neutral-000 p-tw-4 hover:bg-neutral-100',
        className,
      )}
    >
      <div className="inline-regular flex items-center text-neutral-850">
        {label}
        {labelPanel}
      </div>
      <FormulaReferenceLabel reference={reference} />
      {!isEmpty(description) && (
        <span className="secondary-regular text-neutral-600">
          {description}
        </span>
      )}
      {!isEmpty(tags) && (
        <EntityTagLabel.List>
          {tags!.map((tag) => (
            <EntityTagLabel label={tag.name} key={tag.id} />
          ))}
        </EntityTagLabel.List>
      )}
    </div>
  );
}

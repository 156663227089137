import React, { useEffect, useState } from 'react';
import http from 'lib/http';
import { AnimationLoader, Button } from 'stories';
import { sortBy } from 'lodash-es';
import CardWithToggle from 'bundles/Settings/components/DealPath/CardWithToggle';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useModal } from '@/shared/lib/hooks/useModal';
import { TCardScope } from 'bundles/Scoreboard/Pipeline/actions';
import PipelineCardModal from './PipelineCardModal';
import GanttChartSettings from './cards/GanttChartSettings';
import SnapshotCard from './cards/SnapshotCard';

export interface IPipelineCard {
  id: string;
  title: string;
  cardType: TCardScope;
  position: number;
  schema?: object | object[];
  meta: {
    categories?: string[];
  };
}

const PipelineCards = () => {
  const [cards, setCards] = useState<IPipelineCard[]>();
  const { openModal } = useModal();

  useEffect(() => {
    http
      .get('/pipeline/pipeline_cards')
      .then((res) => res.json())
      .then((json) => setCards(json));
  }, []);

  const handleDestroy = (card: IPipelineCard) => {
    http.del(`/pipeline/pipeline_cards/${card.id}`).then((res) => {
      if (res.ok) setCards(cards?.filter((c) => card.id !== c.id));
      else window.toastr.error('Card can not be destroyed');
    });
  };

  const handleClickAddCard = async () => {
    const res = await openModal(PipelineCardModal, {});
    if (res) await createCard(res);
  };

  const handleClickEditCard = async (card) => {
    const res = await openModal(PipelineCardModal, { card });
    if (res) await onChange(res);
  };

  const createCard = (data) =>
    http
      .post('/pipeline/pipeline_cards', data)
      .then((res) => res.json())
      .then((json) => setCards([json, ...cards]));

  const onChange = (data) =>
    http
      .put(`/pipeline/pipeline_cards/${data.id}`, data)
      .then((res) => res.json())
      .then((json) =>
        setCards(cards.map((c) => (c.id === json.id ? json : c))),
      );

  const resolveFooter = (card: IPipelineCard) => {
    if (card.cardType === 'gantt-chart')
      return <GanttChartSettings card={card} />;
    if (card.cardType === 'snapshot') return <SnapshotCard card={card} />;
    if (card.cardType === 'static-card') {
      return (
        <div className="flex-1">
          The card will appear on dashboard. You can modify it there
        </div>
      );
    }
  };

  const SortableItem = SortableElement(({ card }) => (
    <CardWithToggle
      item={card}
      className="pipeline-card"
      onDelete={() => handleDestroy(card)}
      onEdit={() => handleClickEditCard(card)}
      onToggle={(e) => onChange({ id: card.id, active: e.target.checked })}
      dragHandle
      footer={() => resolveFooter(card)}
    />
  ));

  const SortableList = SortableContainer(({ cards }) => (
    <div className="mt-[3rem] grid grid-cols-4 overflow-x-auto">
      {cards
        .filter((card) => card.cardType !== 'gantt-chart')
        .map((card, index) => (
          <SortableItem key={card.id} index={index} card={card} />
        ))}
    </div>
  ));

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    // TODO: fix drag-n-drop sorting
    await onChange({ id: cards[oldIndex].id, position: newIndex + 1 });
  };

  if (!cards) return <AnimationLoader />;

  return (
    <div className="mt-xl">
      <div className="flex justify-between">
        <h3>Pipeline Cards Management</h3>
        <Button variant="primary" iconName="add" onClick={handleClickAddCard}>
          Add Card
        </Button>
      </div>

      <SortableList
        axis="x"
        cards={sortBy(cards, 'position')}
        onSortEnd={onSortEnd}
        useDragHandle
      />
      {cards
        .filter((card) => card.cardType === 'gantt-chart')
        .map((card) => (
          <CardWithToggle
            key={card.id}
            item={card}
            className="pipeline-card"
            onDelete={() => handleDestroy(card)}
            onEdit={() => handleClickEditCard(card)}
            onToggle={(e) =>
              onChange({ id: card.id, active: e.target.checked })
            }
            footer={() => resolveFooter(card)}
          />
        ))}
    </div>
  );
};

export default PipelineCards;

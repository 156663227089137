import React from 'react';
import { capitalize, startCase } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';
import { IUserFilters } from 'bundles/UserManagement/components/UserTracker/UserTracker';

interface Props {
  filters: IUserFilters;
  clearFilter: (key: keyof IUserFilters, id?: number) => void;
  putOnNewLine: boolean;
}

interface Filter {
  fullName: string;
  name: string;
}

const UserFilters = ({ filters, clearFilter, putOnNewLine }: Props) => {
  const filledFilters = Object.keys(filters).filter(
    (key) => filters[key].length > 0,
  ) as (keyof IUserFilters)[];
  const renderFilterName = (key: string, item: Filter) => {
    switch (key) {
      case 'members':
        return startCase(item.fullName);
      default:
        return startCase(item.name);
    }
  };

  return (
    <div
      id="filters"
      className={cn(
        'flex inline-regular mt-tw-4 ml-auto flex-row-reverse flex-wrap',
        { 'order-3 ml-0 mr-auto': putOnNewLine },
      )}
    >
      {filledFilters.map((key) => (
        <div
          key={key}
          className={cn(
            'flex items-center filtering-category-badge flex-wrap',
            { 'mr-auto': putOnNewLine },
          )}
        >
          {/* TODO: get rid of condition */}
          <div className="mr-[0.5rem] light-60 font-weight-600">
            {key === 'investmentEntities' ? 'Entities' : capitalize(key)}
          </div>
          {filters[key].map((i) => (
            <div className="flex items-center filtering-item-badge rounded">
              {renderFilterName(key, i)}
              <Icon
                iconName="closeSmall"
                onClick={() => clearFilter(key, i.id)}
              />
            </div>
          ))}
          <Icon iconName="closeSmall" onClick={() => clearFilter(key)} />
        </div>
      ))}
    </div>
  );
};
export default UserFilters;

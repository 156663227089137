import { useLayoutEffect, useState } from 'react';

// standardize breakpoints
export const MEDIUM_SCREEN_BREAKPOINT = 768;
export const LARGE_SCREEN_BREAKPOINT = 1024;
export const XL_SCREEN_BREAKPOINT = 1280;
export const XL_1_5_SCREEN_BREAKPOINT = 1400;
export const TW_2XL_SCREEN_BREAKPOINT = 1680;
export const XXL_SCREEN_BREAKPOINT = 1728;
export const XXXL_SCREEN_BREAKPOINT = 2560;

const getMediaQuery = <T extends 'min' | 'max', Pixels extends number>(
  type: T,
  value: Pixels,
) => `(${type}-width: ${value}px)` as const;

export const SMALL_WIDTH_MEDIA_QUERY = getMediaQuery(
  'max',
  MEDIUM_SCREEN_BREAKPOINT,
);
export const MEDIUM_WIDTH_MEDIA_QUERY = getMediaQuery(
  'min',
  MEDIUM_SCREEN_BREAKPOINT,
);
export const LARGE_WIDTH_MEDIA_QUERY = getMediaQuery(
  'min',
  LARGE_SCREEN_BREAKPOINT,
);
export const XL_WIDTH_MEDIA_QUERY = getMediaQuery('min', XL_SCREEN_BREAKPOINT);
export const XL_1_5_WIDTH_MEDIA_QUERY = getMediaQuery(
  'min',
  XL_1_5_SCREEN_BREAKPOINT,
);
export const TW_2XL_WIDTH_MEDIA_QUERY = getMediaQuery(
  'min',
  TW_2XL_SCREEN_BREAKPOINT,
);
export const XXL_WIDTH_MEDIA_QUERY = getMediaQuery(
  'min',
  XXL_SCREEN_BREAKPOINT,
);
export const XXXL_WIDTH_MEDIA_QUERY = getMediaQuery(
  'min',
  XXXL_SCREEN_BREAKPOINT,
);

function useMediaQuery(query: string): boolean {
  const getMatches = (): boolean => window.matchMedia(query).matches;

  const [matches, setMatches] = useState(getMatches());

  function handleChange() {
    setMatches(getMatches());
  }

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addEventListener('change', handleChange);

    return () => matchMedia.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
}

export default useMediaQuery;

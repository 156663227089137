import { Contribution, Distribution } from '@/entities/return';
import { useMemo } from 'react';
import { convertCentsToDollars } from '@/shared/lib/converters';

export function useTotals({
  type,
  computedItems,
}: {
  type: 'distribution' | 'contribution';
  computedItems: (Contribution | Distribution)[];
}) {
  const totals = useMemo(() => {
    let amount = 0;
    let units = 0;
    let classAContributionsCents = 0;
    let classBContributionsCents = 0;
    let classAContributionsUnits = 0;
    let classBContributionsUnits = 0;

    computedItems.forEach((item) => {
      amount +=
        'amountCents' in item
          ? convertCentsToDollars(item.amountCents)
          : item.amount;
      if (type === 'distribution') return;
      const contribution = item as Contribution;
      const contributionUnits = contribution.units ?? 0;

      units += contributionUnits;
      if (contribution.investmentClass === 'a') {
        classAContributionsCents += contribution.amountCents;
        classAContributionsUnits += contributionUnits;
      } else {
        classBContributionsCents += contribution.amountCents;
        classBContributionsUnits += contributionUnits;
      }
    });

    return {
      amount,
      units,
      classAContributions: convertCentsToDollars(classAContributionsCents),
      classBContributions: convertCentsToDollars(classBContributionsCents),
      classAContributionsUnits,
      classBContributionsUnits,
    };
  }, [computedItems, type]);

  return totals;
}

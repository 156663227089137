import { CssVar } from '@/shared/config/cssVar';
import { FUNDING_SOURCE_KINDS } from '@/shared/types/reconcile/FundingSource';

export const FUNDING_SOURCES_KIND_CONFIG = {
  [FUNDING_SOURCE_KINDS.GENERAL_PARTNER]: {
    title: 'General Partner',
    statColor: CssVar.light60,
    bgColor: 'bg-light-60',
    color: 'text-light-60',
    value: FUNDING_SOURCE_KINDS.GENERAL_PARTNER,
  },
  [FUNDING_SOURCE_KINDS.LIMITED_PARTNER]: {
    title: 'Limited Partner',
    statColor: CssVar.teal,
    bgColor: 'bg-teal',
    color: 'text-teal',
    value: FUNDING_SOURCE_KINDS.LIMITED_PARTNER,
  },
  [FUNDING_SOURCE_KINDS.LENDER]: {
    title: 'Lender',
    statColor: CssVar.violet,
    bgColor: 'bg-violet',
    color: 'text-violet',
    value: FUNDING_SOURCE_KINDS.LENDER,
  },
} as const satisfies Record<
  FUNDING_SOURCE_KINDS,
  {
    title: string;
    statColor: CssVar;
    bgColor: string;
    color: string;
    value: FUNDING_SOURCE_KINDS;
  }
>;

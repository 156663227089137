import React from 'react';
import { Input } from 'stories';
import { disableStylingKeystrokes } from '@/shared/lib/ckEditor/utils';
import { useModal } from '@/shared/lib/hooks/useModal';
import RecipientsModal from 'bundles/Shared/components/RecipientsModal/RecipientsModal';
import TemplateEditor from '../editor/TemplateEditor';
import EmailTemplateFormInputGroup from '../../../../EmailBuilder/components/EmailTemplateFormInputGroup';
import * as Type from '../editor/types';
import {
  confirmResetingReplyRecipients,
  TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS,
} from '../utils/consts';
import ReplyRecipientsSection from '../ReplyRecipientsSection';

interface Props {
  template: Type.ForgotPasswordTemplateState;
  variables: Type.ForgotPasswordTemplateVars;
  usedVariables: Type.ForgotPasswordTemplateVars;
  replyRecipients: Type.ReplyRecipient[];
  onTemplateChange: (template: Type.ForgotPasswordTemplateState) => void;
  onUsedVariablesChange: (variables: Type.ForgotPasswordTemplateVars) => void;
  isLoading?: boolean;
}

const ForgotPasswordTemplateForm = ({
  template,
  usedVariables,
  variables,
  replyRecipients,
  onTemplateChange,
  onUsedVariablesChange,
  isLoading,
}: Props) => {
  const { confirm, openModal } = useModal();
  const setTemplateField = <K extends keyof Type.ForgotPasswordTemplateState>(
    field: K,
    value: Type.ForgotPasswordTemplateState[K],
  ) => {
    onTemplateChange({
      ...template,
      [field]: value,
    });
  };

  const setVariableField = <K extends keyof Type.ForgotPasswordTemplateVars>(
    field: K,
    value: Type.ForgotPasswordTemplateVars[K],
  ) => {
    onUsedVariablesChange({
      ...usedVariables,
      [field]: value,
    });
  };

  const forceEditorHeading = (editor) => {
    editor.execute('heading', { value: 'heading1' });
  };

  const handleGreetingLineEditorReady = (editor) => {
    forceEditorHeading(editor);
    disableStylingKeystrokes(editor);
  };

  const handleSetReplyRecipients = async () => {
    const res = await openModal(RecipientsModal, {
      replyRecipientOptions: replyRecipients,
      initSelectedRecipients: template.replyRecipients ?? [],
    });
    if (!res) return;

    setTemplateField('replyRecipients', res);
  };

  const handleReset = async () => {
    const result = await confirm(confirmResetingReplyRecipients);

    if (!result) return;

    setTemplateField('replyRecipients', []);
  };

  return (
    <form>
      <div className="flex flex-col gap-tw-4">
        <div>
          <EmailTemplateFormInputGroup title="General" isLoading={isLoading}>
            <label
              className="dark-60 secondary-regular label-regular mb-tw-2 text-dark-60"
              htmlFor="subject"
            >
              Email Subject
            </label>
            <Input
              id="subject"
              onChange={(e) => setTemplateField('subject', e.target.value)}
              value={template.subject}
              placeholder="Enter Subject"
            />
            <ReplyRecipientsSection
              replyRecipients={template.replyRecipients ?? []}
              onReset={handleReset}
              onSet={handleSetReplyRecipients}
            />
          </EmailTemplateFormInputGroup>
        </div>
        <div>
          <EmailTemplateFormInputGroup
            title="Template"
            description="In this section you can use special variables to automate filling
              templates, these variables are under fields"
            isLoading={isLoading}
          >
            <div className="label-regular mb-tw-2 text-dark-60">
              Greeting Line<span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Greeting Line"
              text={template.greeting}
              setText={(text) => setTemplateField('greeting', text)}
              variables={variables.greeting}
              usedVariables={usedVariables.greeting}
              setUsedVariables={(newVariables) =>
                setVariableField('greeting', newVariables)
              }
              toolbarItems={TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS}
              onChangeStart={(editor) => forceEditorHeading(editor)}
              onReady={handleGreetingLineEditorReady}
            />
            <div className="label-regular mb-tw-2 mt-tw-4 text-dark-60">
              Body<span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Main Text"
              text={template.body}
              setText={(text) => setTemplateField('body', text)}
              variables={variables.body}
              usedVariables={usedVariables.body}
              setUsedVariables={(newVariables) =>
                setVariableField('body', newVariables)
              }
              toolbarItems={TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS}
              onReady={(editor) => disableStylingKeystrokes(editor)}
            />
            <div className="label-regular mb-tw-2 mt-tw-4 text-dark-60">
              Reset Password Button
              <span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Button Text"
              text={template.callToAction}
              setText={(text) => setTemplateField('callToAction', text)}
              variables={variables.callToAction}
              usedVariables={usedVariables.callToAction}
              setUsedVariables={(newVariables) =>
                setVariableField('callToAction', newVariables)
              }
              onReady={(editor) => disableStylingKeystrokes(editor)}
              toolbarItems={['variable']}
            />
            <div className="label-regular mb-tw-2 mt-tw-4 text-dark-60">
              Additional Info
            </div>
            <TemplateEditor
              placeholder="Enter Additional Info"
              text={template.additionalInfo}
              setText={(text) => setTemplateField('additionalInfo', text)}
              variables={variables.additionalInfo}
              usedVariables={usedVariables.additionalInfo}
              setUsedVariables={(newVariables) =>
                setVariableField('additionalInfo', newVariables)
              }
              onReady={(editor) => disableStylingKeystrokes(editor)}
              toolbarItems={TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS}
            />
          </EmailTemplateFormInputGroup>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordTemplateForm;

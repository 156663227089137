import { UNSUPPORTED_DASHBOARD_INFO_TEXT } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { useDashboardEagleEyeItems } from '@/bundles/Shared/features/dashboard/create';
import { LegalEntitiesSelectionModal } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesSelectionModal';
import { useDashboardExportSettingsFeature } from '@/bundles/Shared/features/dashboard/exportSettings/lib';
import type { ReportObjectDashboardMetaDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { LegalEntity } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { IconsId } from '@/types/sre-icons';
import {
  DashboardFilterObject,
  DashboardFilterObjectItem,
  ReportDashboardType,
  ReportObjectDashboard,
  useDashboardContext,
  usePutApiSettingsReportComparisonDashboardsByIdMutation,
  usePutApiSettingsReportObjectDashboardsByIdMutation,
  useReportComparisonDashboardAssetsQuery,
  useReportObjectDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';
import { ObjectSelectionModal } from 'bundles/Shared/features/dashboard/create/ui/ObjectSelectionModal';
import { DashboardPermissionListLine } from 'bundles/Shared/features/dashboard/updatePermissions';
import { debounce, xor } from 'lodash-es';
import React, { useMemo } from 'react';
import { Field, Icon, IconButton, Input } from 'stories';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { useObjectDashboardUpdate } from '../lib';

interface Props {
  model: ReportObjectDashboard;
}

export const ObjectItem = ({
  icon,
  label,
  disabled,
  ...props
}: {
  icon: IconsId;
  label: string;
  disabled?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'>) => {
  const textColor = disabled ? 'text-neutral-400' : 'text-neutral-550';
  return (
    <div
      className={cn(
        'flex cursor-pointer flex-col items-center justify-center gap-tw-2 rounded-[8px] border-2 border-solid border-neutral-100 p-tw-2 hover:bg-neutral-100',
        disabled && 'cursor-default bg-neutral-100',
      )}
      {...props}
    >
      <Icon className={cn('text-[24px]', textColor)} iconName={icon} />
      <span className={cn('secondary-semibold', textColor)}>{label}</span>
    </div>
  );
};

export const ReportDashboardUpdate = ({
  model,
  assetObjects,
  reportBuilderTemplates,
}: Props & {
  assetObjects: DashboardFilterObject[];
  reportBuilderTemplates?: ReportObjectDashboardMetaDto['reportBuilderTemplates'];
}) => {
  const { dashboardType } = useDashboardContext();
  const updateObjectDashboardMutation = useObjectDashboardUpdate();
  usePutApiSettingsReportObjectDashboardsByIdMutation();
  const updateComparisonDashboardMutation =
    usePutApiSettingsReportComparisonDashboardsByIdMutation();

  const [updateDashboard, { isLoading }] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return updateObjectDashboardMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return updateComparisonDashboardMutation;
      }
      default: {
        return [
          () => {
            toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
          },
          {
            isLoading: false,
          },
        ];
      }
    }
  }, []);
  const {
    value: legalEntitiesModalOpened,
    toggle: toggleLegalEntitiesModalOpened,
  } = useBoolean();
  const { value: assetModalOpen, toggle: toggleAssetModal } = useBoolean();

  const onAssetsChange = debounce((assetIds: number[]) => {
    updateDashboard({
      id: model.id,
      body: {
        asset_ids: assetIds,
        excluded_legal_entity_ids: mapListToIds(model.excludedLegalEntities),
      },
    });
  }, 600);

  const onExcludedLEChange = debounce(
    (excludedLegalEntitiesIds: LegalEntity['id'][]) => {
      updateDashboard({
        id: model.id,
        body: {
          asset_ids: mapListToIds(model.assets),
          excluded_legal_entity_ids: excludedLegalEntitiesIds,
        },
      });
    },
    600,
  );

  const assetIds = useMemo(() => {
    return mapListToIds(model.assets);
  }, [model.assets]);

  const excludedLegalEntityIds = useMemo(() => {
    return mapListToIds(model.excludedLegalEntities);
  }, [model.excludedLegalEntities]);

  const { allLegalEntities, includedLegalEntities } = useDashboardEagleEyeItems(
    {
      selectedAssetIds: assetIds,
      excludedLegalEntityIds,
    },
  );

  const dashboardExportSettingsFeature = useDashboardExportSettingsFeature({
    dashboardId: model.id,
    dashboardReportBuilderTemplate: model.reportBuilderTemplate,
    dashboardUpdateMutation: updateObjectDashboardMutation,
    reportBuilderTemplates,
  });

  return (
    <div className="flex flex-col gap-tw-4">
      <SectionField labelText="Basic">
        <Field required labelText="Dashboard Name">
          <Input
            defaultValue={model.name}
            onBlur={(e) => {
              if (e.target.value === model.name) {
                return;
              }
              updateDashboard({
                id: model.id,
                body: { name: e.target.value },
              });
            }}
          />
        </Field>
      </SectionField>
      <SectionField labelText="Objects">
        <div className="grid grid-cols-3 gap-tw-2">
          <DashboardFilterObjectItem
            onClick={toggleAssetModal}
            count={model.assets.length}
            type="asset"
          />
          <DashboardFilterObjectItem count={0} type="fund" disabled />
          <DashboardFilterObjectItem count={0} type="segment" disabled />
        </div>
      </SectionField>
      <SectionField labelText="Legal Entities">
        <LegalEntitiesSelectionModal.Wrapper
          legalEntitiesCounter={includedLegalEntities.length}
          toggleLegalEntitiesModalOpened={toggleLegalEntitiesModalOpened}
        >
          {legalEntitiesModalOpened && (
            <LegalEntitiesSelectionModal
              disableAllButtons={isLoading}
              assets={model.assets}
              excludedLegalEntityIds={excludedLegalEntityIds}
              includedLegalEntities={includedLegalEntities}
              onChangeAll={(type) => {
                onExcludedLEChange(
                  type === 'excluded' ? [] : mapListToIds(allLegalEntities),
                );
              }}
              onExcludeAsset={(assetId) => {
                onAssetsChange(assetIds.filter((id) => id !== assetId));
              }}
              onIncludeAsset={(assetId, assetExcludedLegalEntityIds) => {
                if (assetIds.includes(assetId)) {
                  onExcludedLEChange(
                    xor(assetExcludedLegalEntityIds, excludedLegalEntityIds),
                  );
                  return;
                }
                onAssetsChange([...assetIds, assetId]);
              }}
              onExcludeLegalEntity={(legalEntityId) => {
                onExcludedLEChange([...excludedLegalEntityIds, legalEntityId]);
              }}
              onIncludeLegalEntity={(legalEntityId) => {
                onExcludedLEChange(
                  excludedLegalEntityIds.filter((id) => id != legalEntityId),
                );
              }}
              onClose={toggleLegalEntitiesModalOpened}
            />
          )}
        </LegalEntitiesSelectionModal.Wrapper>
      </SectionField>
      <dashboardExportSettingsFeature.ExportSettingsField />
      <SectionField labelText="Permissions">
        <div className="flex items-center gap-tw-2 rounded-[8px] border-2 border-solid border-neutral-100 p-tw-2">
          <Icon
            className="rounded-[8px] bg-neutral-100 p-tw-1.5"
            iconName="users"
          />
          <DashboardPermissionListLine
            dashboardType={dashboardType}
            dashboardId={model.id}
            permissions={model.permitted}
            withEdit
            className="grow items-center"
            emptyFallback={
              <div className="flex w-full items-center justify-between">
                <span className="inline-semibold text-neutral-800">NOBODY</span>
                <IconButton iconName="edit" />
              </div>
            }
          />
        </div>
      </SectionField>
      {assetModalOpen && (
        <ObjectSelectionModal
          objects={assetObjects}
          onClose={toggleAssetModal}
          objectType="asset"
          defaultObjectIds={assetIds}
          onChange={onAssetsChange}
        />
      )}
    </div>
  );
};

export function ObjectDashboardUpdate(props: Props) {
  const { assetObjects, reportBuilderTemplates } =
    useReportObjectDashboardAssetsQuery();

  return (
    <ReportDashboardUpdate
      {...props}
      reportBuilderTemplates={reportBuilderTemplates}
      assetObjects={assetObjects}
    />
  );
}

export function ComparisonDashboardUpdate(props: Props) {
  const { assetObjects } = useReportComparisonDashboardAssetsQuery();

  return <ReportDashboardUpdate {...props} assetObjects={assetObjects} />;
}

import { TUserRoleCategory } from 'types/User';
import React from 'react';
import { truncate } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import { IconsId } from 'types/sre-icons';
import styles from './styles.module.scss';
import {
  ActivityLogKindTitleType,
  ActivityLogKindType,
  IUserRoleActivityLogItem,
} from './types';
import { resolveActivityLogIcon, resolveActivityLogKindTitle } from './utils';

export const INTERNAL_CATEGORY = TUserRoleCategory.INTERNAL;
export const EXTERNAL_CATEGORY = TUserRoleCategory.EXTERNAL;

export const BADGE_PROPS = {
  className: 'rounded-4 ml-xs',
  classes: { value: 'py-0 px-xs square font-weight-400' },
  textColor: 'var(--light-90)',
};

export const MAX_ROLE_NAME_CHAR_LEN = 36;

export const genHistoryItemProps = (
  logItem: IUserRoleActivityLogItem,
  action: (item: IUserRoleActivityLogItem) => void,
  lastItem: boolean,
) => ({
  lastItem,
  icon: { iconName: resolveActivityLogIcon(logItem.kind) },
  createdAt: logItem.createdAt,
  createdBy: logItem.whoDoneIt.fullName,
  notificationAction:
    logItem.kind === 'user_role_updated'
      ? {
          action,
          label: 'show details',
          sendData: logItem,
        }
      : undefined,
  label: (
    <>
      {resolveActivityLogKindTitle(logItem.kind)}{' '}
      <span className="secondary-semibold">
        “
        {truncate(logItem.newName ?? logItem.name, {
          length: MAX_ROLE_NAME_CHAR_LEN,
          omission: '',
        })}
        ”
      </span>{' '}
      role
      {logItem.group != null && (
        <>
          {' '}
          in the
          <span className="secondary-semibold">
            {' '}
            “{logItem.newGroup ?? logItem.group}”
          </span>{' '}
          group
        </>
      )}
    </>
  ),
});

export const userRoleNameTruncate = (roleName: string) =>
  truncate(roleName, { length: MAX_ROLE_NAME_CHAR_LEN });

export const PERMISSIONS_TABLE = [
  { label: 'Configure', key: 'canConfigure' },
  { label: 'Manage', key: 'canManage' },
  { label: 'View', key: 'canView' },
] as const;

export const NAME_AND_PERMISSIONS_TABLE = [
  { label: 'Module', key: 'name' },
  ...PERMISSIONS_TABLE,
] as const;

export const MAX_CHARS_IN_ROW = 52;

export const CONFIRM_DELETE_ROLE_MODAL_PROPS = {
  title: 'Remove Role',
  subtitle: 'Are you sure you want to remove this role?',
  actions: {
    primaryButton: {
      text: 'Remove',
      variant: 'danger',
    },
    secondaryButton: {
      text: 'Cancel',
      variant: 'secondary',
    },
  },
} as const;

export const NoUsersJSX = (
  <li className="flex items-center justify-between h-[3rem] light-60 inline-regular label-semibold bg-white px-m py-s">
    No users have yet been added for this role
  </li>
);

export const NoActivityLogJSX = (
  <div className={cn(styles.noActivityLog, 'no-data-overlay')}>
    <h4 className="light-60 header4-regular">No Changes yet</h4>
    <p className="light-60 body-regular">All changes will be displayed here</p>
  </div>
);

export const PermissionTableHeaderJSX = NAME_AND_PERMISSIONS_TABLE.map(
  ({ label, key }, idx) => (
    <div
      key={key}
      className={cn(
        'flex items-center light-60 label-semibold uppercase',
        {
          'pl-m': idx === 0,
          'justify-center': idx !== 0,
        },
      )}
    >
      {label}
    </div>
  ),
);

export const CATEGORIES = [
  {
    text: 'Outside the org',
    value: TUserRoleCategory.EXTERNAL,
  },
  {
    text: 'Within the org',
    value: TUserRoleCategory.INTERNAL,
  },
];

export const ACTIVITY_LOG_ICONS: Record<ActivityLogKindType, IconsId> = {
  user_role_created: 'addSmall',
  user_role_destroyed: 'trash',
  user_role_updated: 'penSmall',
};

export const ACTIVITY_LOG_KIND_TITLES: Record<
  ActivityLogKindType,
  ActivityLogKindTitleType
> = {
  user_role_created: 'Created',
  user_role_destroyed: 'Deleted',
  user_role_updated: 'Modified',
};

export const KEY_INFO_ITEMS = {
  name: {
    label: 'Role Name',
    prevFieldName: 'name',
    newFieldName: 'newName',
  },
  group: {
    label: 'Group',
    prevFieldName: 'group',
    newFieldName: 'newGroup',
  },
};

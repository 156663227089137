import * as React from 'react';
import './anchorbadge.scss';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'right';
  };
  size?: 's';
  badgeContent?: string;
  backgroundColor?: string;
  className?: string;
  classes?: {
    badgeContent?: string;
  };
}

export function AnchorBadge({
  children,
  badgeContent,
  backgroundColor,
  className,
  classes,
  size = 's',
  ...props
}: React.PropsWithChildren<Props>) {
  const anchorOrigin = props.anchorOrigin ?? {
    vertical: 'top',
    horizontal: 'right',
  };

  const baseClass = 'sre-anchor-badge';
  return (
    <div
      className={cn(
        baseClass,
        `${baseClass}_${anchorOrigin.vertical}-${anchorOrigin.horizontal}`,
        `${baseClass}_size-${size}`,
        className,
      )}
    >
      <span
        style={{
          backgroundColor,
        }}
        className={cn('sre-anchor-badge__value', classes?.badgeContent)}
      >
        {badgeContent}
      </span>
      {children}
    </div>
  );
}

export default AnchorBadge;

import { createContext, useContext } from 'react';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';

const EntityContext = createContext<
  | {
      entity: IAsset;
      type: 'Asset';
    }
  | {
      entity: IFund;
      type: 'Fund';
    }
  | undefined
>(undefined);

export const useEntityContext = () => {
  const context = useContext(EntityContext);

  if (context === undefined) {
    throw new Error('useEntityContext must be used within a EntityContext');
  }

  return context;
};

export default EntityContext;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import { stringify, IStringifyOptions } from 'qs';
import { IPageParams } from 'bundles/Construction/types';
import { ILineItem } from 'bundles/REconcile/types/ILineItem';
import { IManualLineItemCreate } from 'bundles/REconcile/components/development/lineItems/types';
import { IPaginationMeta } from 'types/Pagination';
import { TDevelopmentCategorizableType } from 'bundles/REconcile/actions/developmentBudgetVariance';

export interface ILineItemResponseMeta extends IPaginationMeta {
  requisitionNumber: number;
  categoryType: TDevelopmentCategorizableType;
  categoryName: string;
  noInvoiceCount: number;
  noSourceCount: number;
  startDate: string;
  endDate: string;
}

interface ILineItemsResponse {
  items: ILineItem[];
  meta: ILineItemResponseMeta;
}

export const reconcileLineItemApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/development/legal_entities`,
  }),
  reducerPath: 'reconcileLineItem',
  tagTypes: ['LineItem'],
  endpoints: (build) => ({
    getLineItems: build.query<
      ILineItemsResponse,
      {
        legalEntityCode: string;
        requisitionId: string;
        categoryId: string;
        categoryType: TDevelopmentCategorizableType;
        pageParams: IPageParams;
      }
    >({
      query: (params) => {
        const options: IStringifyOptions = {
          addQueryPrefix: true,
          arrayFormat: 'brackets',
        };
        return `/${params.legalEntityCode}/requisitions/${
          params.requisitionId
        }/development_line_items${stringify(
          {
            ...params.pageParams,
            'sort[order]': params.pageParams.sortOrder,
            'sort[field]': params.pageParams.sortField,
            category_id: params.categoryId,
            category_type: params.categoryType,
          },
          options,
        )}`;
      },
      providesTags: ['LineItem'],
    }),
    getLineItemsInRequisition: build.query<
      ILineItemsResponse,
      {
        legalEntityCode: string;
        requisitionId: string;
      }
    >({
      query: (params) =>
        `${API_URL}/api/reconcile/development/legal_entities/${params.legalEntityCode}/requisitions/${params.requisitionId}/suggested_line_items`,
      providesTags: ['LineItem'],
    }),
    createLineItem: build.mutation<
      ILineItem,
      {
        legalEntityCode: string;
        requisitionId: string;
        body: IManualLineItemCreate;
      }
    >({
      query(params) {
        return {
          url: `/${params.legalEntityCode}/requisitions/${params.requisitionId}/development_line_items`,
          method: 'POST',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response) => {
        toastr.success('Line Item Created');
        return response.data;
      },
      invalidatesTags: ['LineItem'],
    }),
    updateLineItem: build.mutation<
      ILineItem,
      {
        legalEntityCode: string;
        requisitionId: string;
        body: Omit<IManualLineItemCreate, 'category_id' | 'category_type'>;
        lineItemId: string;
      }
    >({
      query(params) {
        return {
          url: `/${params.legalEntityCode}/requisitions/${params.requisitionId}/development_line_items/${params.lineItemId}`,
          method: 'PUT',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response) => {
        toastr.success('Line Item Updated');
        return response.data;
      },
      invalidatesTags: ['LineItem'],
    }),
    deleteLineItem: build.mutation<
      ILineItem,
      {
        legalEntityCode: string;
        requisitionId: string;
        lineItemId: string;
      }
    >({
      query(params) {
        return {
          url: `/${params.legalEntityCode}/requisitions/${params.requisitionId}/development_line_items/${params.lineItemId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (response) => {
        toastr.success('Line Item Deleted');
        return response.data;
      },
      invalidatesTags: ['LineItem'],
    }),
  }),
});

export const {
  useGetLineItemsQuery,
  useGetLineItemsInRequisitionQuery,
  useCreateLineItemMutation,
  useUpdateLineItemMutation,
  useDeleteLineItemMutation,
} = reconcileLineItemApi;

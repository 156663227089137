import React, { useMemo } from 'react';

import { IReportLineItemOnIndex } from 'types/ReportLineItem';
import transactionTableColumns, {
  defaultColumn,
  TFilterModel,
  TSettings,
} from './transactionTableColumns';
import Table from '../../../../Shared/components/Table/Table';

interface Props {
  settings: TSettings;
  isLoading: boolean;
  setSettings: (settings: TSettings) => void;
  transactions: IReportLineItemOnIndex[];
}

const TransactionsTable = ({
  transactions,
  isLoading,
  settings,
  setSettings,
}: Props) => {
  const columns = useMemo(() => transactionTableColumns(), [settings]);

  return (
    <Table
      loading={isLoading}
      classes={{
        table: 'fixed-layout',
      }}
      onFilterModelChange={(filterModel: TFilterModel) =>
        setSettings({
          ...settings,
          transaction_type: filterModel.transaction_value,
        })
      }
      settings={settings}
      setSettings={setSettings}
      defaultColumn={defaultColumn}
      items={transactions}
      columns={columns}
    />
  );
};
export default TransactionsTable;

import { cn } from '@/shared/lib/css/cn';
import {
  get1YearAgoDate,
  get30DaysAgoDate,
  get60DaysAgoDate,
  get6MonthsAgoDate,
  get90DaysAgoDate,
  isSameDate,
  shiftDateForward,
  todayDate,
} from '@/shared/lib/date';
import { formatDate } from '@/shared/lib/formatting/dates';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { Button, Icon, Popover } from '@/stories';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'stories/Calendar/config';
import {
  CalendarRange,
  CalendarRangeProps,
  CalendarRangeRef,
  DateRangeType,
} from 'stories/Calendar/Range/CalendarRange';
import { DateRangeInput } from 'stories/DateRangeInput/DateRangeInput';

const StepIcon = ({
  direction,
  triggered,
  ...props
}: {
  direction: 'next' | 'prev';
  triggered?: boolean;
} & Omit<React.ComponentProps<typeof Icon>, 'iconName'>) => (
  <Icon
    data-testid={`step-${direction}`}
    className={cn(
      'group:hover:text-neutral-650 flex h-full items-center overflow-hidden px-tw-2 text-neutral-550 hover:bg-neutral-050 hover:text-neutral-650',
      triggered && 'text-neutral-650',
    )}
    iconName={direction === 'next' ? 'arrowRight' : 'arrowLeft'}
    {...props}
  />
);
const Separator = () => <div className="h-[24px] w-[1px] bg-neutral-100" />;

interface Props extends CalendarRangeProps {
  /** The selected date range. */
  value?: DateRangeType | undefined;

  /** If true, the step (previous/next) buttons are disabled.
   *  Step buttons are used to navigate through the date range.
   * */
  disableStepButtons?: boolean;

  /** The default date range.
   *  If provided, a button 'Back To Default' will be displayed to reset the date range to the default value.
   * */
  defaultValue?: DateRangeType;

  /** If true, the date picker will not close when a date is selected. */
  disableCloseOnSelect?: boolean;

  /** The step value for the step buttons. */
  stepValue?: number;

  /** The unit for the step value ('day', 'month', 'year', or 'week'). */
  stepUnit?: 'day' | 'month' | 'year' | 'week';
}

const PresetLabel = ({
  label,
  onClick,
  active,
}: {
  label: string;
  onClick: () => void;
  active?: boolean;
}) => (
  <span
    className={cn(
      'inline-regular flex h-[30px] w-full cursor-pointer items-center overflow-hidden whitespace-nowrap !rounded-lg px-tw-2 text-neutral-850 hover:bg-info-055 hover:text-neutral-000',
      active && 'bg-info-055 text-neutral-000',
    )}
    onClick={onClick}
  >
    {label}
    <GrowDiv />
    {active && <Icon iconName="check" className="text-neutral-000" />}
  </span>
);

const PRESETS = [
  { label: 'Last 90 days', value: [get90DaysAgoDate(), todayDate()] },
  { label: 'Last 60 days', value: [get60DaysAgoDate(), todayDate()] },
  { label: 'Last 30 days', value: [get30DaysAgoDate(), todayDate()] },
  { label: 'Last 6 months', value: [get6MonthsAgoDate(), todayDate()] },
  { label: 'Last 12 months', value: [get1YearAgoDate(), todayDate()] },
] as const satisfies { label: string; value: DateRangeType }[];

type ChangeEvent = {
  anchorDate?: Date | null;
  source?: 'preset' | 'input' | 'calendar';
};

export function CalendarRangeSelector({
  value,
  onChange,
  disableStepButtons,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  stepValue = 1,
  stepUnit = 'day',
  ...props
}: Props): React.ReactNode {
  const [internalValue, setInternalValue] = useState<DateRangeType | undefined>(
    value,
  );
  const calendarRangeRef = React.useRef<CalendarRangeRef>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const toggleCalendar = useCallback(() => {
    setPopoverVisible((prev) => !prev);
    calendarRangeRef.current?.onClose();
  }, []);

  const handleChange = useCallback(
    (newValue: DateRangeType, event?: ChangeEvent) => {
      if (event?.anchorDate) {
        setInternalValue([event.anchorDate, null]);
        return;
      }
      setInternalValue(newValue);
      onChange?.(newValue);
      if (!props.disableCloseOnSelect && event?.source === 'calendar') {
        toggleCalendar();
      }
    },
    [onChange],
  );

  const handleStepClick = useCallback(
    (direction: 'next' | 'prev') => (e: React.MouseEvent) => {
      e.stopPropagation();
      const [from, to] = value ?? [];
      const stepSign = direction === 'next' ? 1 : -1;
      const step = stepValue * stepSign;
      const fromShiftedDate = shiftDateForward(
        dayjs(from).local().toDate(),
        step,
        stepUnit,
      );
      const toShiftedDate = shiftDateForward(
        dayjs(to).local().toDate(),
        step,
        stepUnit,
      );
      const newValue = [
        new Date(
          Date.UTC(
            fromShiftedDate.getUTCFullYear(),
            fromShiftedDate.getUTCMonth(),
            fromShiftedDate.getUTCDate(),
          ),
        ),
        new Date(
          Date.UTC(
            toShiftedDate.getUTCFullYear(),
            toShiftedDate.getUTCMonth(),
            toShiftedDate.getUTCDate(),
          ),
        ),
      ];
      onChange?.(newValue as DateRangeType);
    },
    [value, onChange, stepValue, stepUnit],
  );

  const handleBackToDefault = useCallback(() => {
    onChange?.(props.defaultValue!);
    if (!props.disableCloseOnSelect) {
      toggleCalendar();
    }
  }, [props.defaultValue]);

  const isPresetActive = useCallback(
    (preset: DateRangeType) => {
      return Boolean(
        internalValue?.[0] &&
          internalValue?.[1] &&
          isSameDate(internalValue?.[0], preset[0]!, 'date') &&
          isSameDate(internalValue?.[1], preset[1]!, 'date'),
      );
    },
    [internalValue],
  );

  return (
    <Popover
      visible={popoverVisible}
      appendToBody={false}
      placement="bottom-start"
      maxWidth="min-content"
      hiddenArrow
      className="overflow-hidden rounded-2xl p-0 shadow-m"
      onClickOutside={toggleCalendar}
      template={
        <div className="flex">
          <div className="flex w-[164px] grow flex-col items-start gap-tw-2 border-r border-solid border-neutral-150 p-tw-4">
            <DateRangeInput
              value={internalValue}
              onChange={(newValue) =>
                handleChange(newValue, { source: 'input' })
              }
              minDate={minDate}
              maxDate={maxDate}
              {...props}
            />
            <div className="flex w-full flex-col gap-1">
              {PRESETS.map((preset) => (
                <PresetLabel
                  key={preset.label}
                  label={preset.label}
                  active={isPresetActive(preset.value)}
                  onClick={() =>
                    handleChange(preset.value, { source: 'preset' })
                  }
                />
              ))}
            </div>
            <GrowDiv />
            {props.defaultValue && (
              <Button
                size="xs"
                className="whitespace-nowrap"
                onClick={handleBackToDefault}
                variant="secondary"
              >
                Back To Default
              </Button>
            )}
          </div>
          <CalendarRange
            ref={calendarRangeRef}
            value={internalValue}
            onChange={(newValue, e) =>
              handleChange(newValue, { ...e, source: 'calendar' })
            }
            raiseOnChangeOnAnchorChanged
            {...props}
          />
        </div>
      }
    >
      {({ triggered }) => (
        <Button
          size="s"
          className={cn(
            'group inline-semibold gap-0 p-0',
            triggered && ' bg-neutral-200',
          )}
          variant="secondary"
          onClick={toggleCalendar}
        >
          {!disableStepButtons && (
            <>
              <StepIcon
                onClick={handleStepClick('prev')}
                triggered={triggered}
                direction="prev"
              />
              <Separator />
            </>
          )}
          {value?.[0] && value[1] ? (
            <div className="flex gap-tw-1 px-tw-2">
              <span className="text-neutral-550">From:</span>
              {formatDate(value[0], 'MM-DD-YYYY')}
              <span className="text-neutral-550">To:</span>
              {formatDate(value[1], 'MM-DD-YYYY')}
            </div>
          ) : (
            'Select Date Range'
          )}
          {!disableStepButtons && (
            <>
              <Separator />
              <StepIcon
                onClick={handleStepClick('next')}
                triggered={triggered}
                direction="next"
              />
            </>
          )}
        </Button>
      )}
    </Popover>
  );
}

import { Link, Router, useNavigate } from '@reach/router';
import {
  useGetApiRecapPagesQuery,
  usePutApiRecapPagesByIdMutation,
} from 'bundles/Shared/shared/api/documentationEnhancedApi';
import React, { useEffect, useState } from 'react';
import SortableTree, {
  getTreeFromFlatData,
  ReactSortableTreeProps,
  TreeItem,
} from 'react-sortable-tree';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { cn } from '@/shared/lib/css/cn';
import { Button, Icon, IconButton } from '@/stories';
import { currentUserIsSreAdmin } from 'lib/permissions';
import MaterialTheme from 'react-sortable-tree-theme-material-ui';
import DocumentPage from '@/pages/documentation/[id]';
import { RouterComponentWithoutWrapper } from 'lib/reach-router';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { isEqual } from 'lodash-es';
import { SyncWithClientsButton } from 'bundles/Shared/features/syncWithUniform';
import {
  findNodeIndexes,
  useDocumentationPageParams,
  useRedirectToFirstPageEffect,
} from '@/pages/documentation/lib';
import styles from './index.module.scss';
import { DocumentationPageModel } from '@/entities/docPage';
import { IS_UNIFORM } from 'lib/http';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import AnimateHeight from 'react-animate-height';

const DocumentationPage = () => {
  const params = useDocumentationPageParams();
  const navigate = useNavigate();
  const { data } = useGetApiRecapPagesQuery({});
  const { value: collapsed, toggle } = useBoolean()

  const [updatePage] = usePutApiRecapPagesByIdMutation();
  const [treeData, setTreeData] = useState<
    Array<TreeItem<DocumentationPageModel>>
  >([]);

  useRedirectToFirstPageEffect(data?.items ?? []);
  useEffect(() => {
    setTreeData(
      getTreeFromFlatData<DocumentationPageModel>({
        flatData: (data?.items ?? []).map((node) => ({
          ...node,
          expanded: true,
          title: node.name,
        })),
        getKey: (node) => node.id,
        getParentKey: (node) => node.parentId,
        rootKey: null,
      }),
    );
  }, [data]);

  const onMoveNode: ReactSortableTreeProps<DocumentationPageModel>['onMoveNode'] =
    (n) => {
      const {
        node,
        nextParentNode,
        prevTreeIndex,
        nextTreeIndex,
        prevPath,
        nextPath,
      } = n;

      if (prevTreeIndex === nextTreeIndex && isEqual(prevPath, nextPath))
        return;

      const newTreeData = n.treeData;

      const indexes = findNodeIndexes(newTreeData, node.id);
      if (!indexes) return;

      updatePage({
        id: node.id,
        body: {
          parent_id: nextParentNode ? nextParentNode.id : null,
          position: indexes.nodeIndex + 1,
        },
      });
    };
  const canDrag = IS_UNIFORM;
  const canCreate = currentUserIsSreAdmin() && IS_UNIFORM;

  return (
    <ListNavLayout className='max-[720px]:flex max-[720px]:flex-col max-[720px]:h-[initial]'>
      <ListNavLayout.Navigation className={cn('next-div-w-full relative max-[720px]:gap-0')}>
        <ListNavLayout.NavigationHeaderGroup>
          <ListNavLayout.NavigationHeader title="sRE Documentation" />
        </ListNavLayout.NavigationHeaderGroup>
        <SyncWithClientsButton
          entity="documentationPage"
          meta={data?.meta.systemSync}
        />
        <div className="sm:hidden mt-tw-6">
          <Button onClick={toggle} fluid variant='secondary'>
            {collapsed ? 'Open' : 'Collapse'} Knowledge Base
          </Button>
        </div>
        <AnimateHeight height={collapsed ? 0 : 'auto'}>
          <div
            className={cn(
              styles.documentation,
              'sre-docs bg-neutral-000 px-tw-2 py-tw-4 transition-all max-[720px]:mt-tw-6',
            )}
          >
            {canCreate && (
              <Button
                variant="success"
                className="w-full mb-tw-4 max-[720px]:hidden"
                onClick={() => {
                  navigate(
                    generateUrl(ROUTES_ROOT.documentation.doc.fullPath, {
                      pathParams: {
                        id: 'new',
                      },
                    }),
                  );
                }}
              >
                Add Page
              </Button>
            )}
            {!treeData.length && (
              <div className="py-12 text-center">
                <p>No pages found</p>
              </div>
            )}
            {treeData.length > 0 && (
              <SortableTree
                treeData={treeData}
                theme={MaterialTheme}
                onChange={(d) => {
                  setTreeData(d);
                }}
                getNodeKey={({ node }) => node.id}
                isVirtualized={false}
                canDrag={canDrag}
                onMoveNode={onMoveNode}
                generateNodeProps={(rowInfo) => {
                  const documentLink = generateUrl(
                    ROUTES_ROOT.documentation.doc.fullPath,
                    {
                      pathParams: { id: rowInfo.node.slug },
                    },
                  );
                  return {
                    buttons: [
                      <Link key={'edit'} to={documentLink}>
                        <IconButton iconName="arrowRight" />
                      </Link>,
                    ],
                    icons: canDrag && <Icon iconName="move" />,
                    title: (
                      <Link
                        className={cn(
                          rowInfo.node.slug === params.id && 'font-black',
                        )}
                        to={documentLink}
                      >
                        <span>{rowInfo.node.title}</span>
                      </Link>
                    ),
                  };
                }}
              />
            )}
          </div>
        </AnimateHeight>
      </ListNavLayout.Navigation>
      <Router component={RouterComponentWithoutWrapper}>
        <NoDataOverlay title="No document selected" default />
        <DocumentPage path={ROUTES_ROOT.documentation.doc.path} />
      </Router>
    </ListNavLayout>
  );
};
export default DocumentationPage;

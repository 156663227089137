import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { ReportTemplateCard } from '@/bundles/Shared/widgets/report/widgets/common/ui/ReportTemplateCard';
import { ReportBuilderTemplate } from '@/entities/report/reportBuilder';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { Button, Modal, SearchInput } from '@/stories';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { useState } from 'react';

export const AvailableTemplatesModal = ({
  onClose,
  onSubmit,
  reportBuilderTemplates,
  isFetching,
}: DialogProps<ReportBuilderTemplate> & {
  isFetching: boolean;
  reportBuilderTemplates: ReportBuilderTemplate[];
}) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<ReportBuilderTemplate>();

  const { inputProps, filteredItems } = useItemsFilterByText(
    reportBuilderTemplates ?? [],
    (w, searchText) => includesInLowerCase(w.name, searchText),
  );

  return (
    <Modal
      header="Available Templates"
      toggle={onClose}
      size="700"
      classes={{
        body: 'flex flex-col gap-tw-4',
      }}
      actions={
        <Modal.Actions className="justify-between">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="success"
            onClick={() => onSubmit?.(selectedTemplate)}
            disabled={selectedTemplate == null || isFetching}
          >
            Create
          </Button>
        </Modal.Actions>
      }
    >
      <SearchInput {...inputProps} placeholder="Search by Template Name" />
      <ReportTemplateCard.List>
        {!isFetching && filteredItems.length === 0 && (
          <NoDataOverlay title="No templates yet" />
        )}
        {isFetching ? (
          <>
            <SkeletonBlock className="h-[72px] w-full" />
            <SkeletonBlock className="h-[72px] w-full" />
            <SkeletonBlock className="h-[72px] w-full" />
          </>
        ) : (
          filteredItems?.map((t) => (
            <ReportTemplateCard
              onClick={() => {
                setSelectedTemplate(t);
              }}
              selected={selectedTemplate?.id === t.id}
              key={t.id}
              type="unit_mix_table"
              title={t.name}
              tags={t.tags}
            />
          ))
        )}
      </ReportTemplateCard.List>
    </Modal>
  );
};

import React from 'react';
import { useCrudContext } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { SearchInput } from 'stories';
import { usePageParamsSearch } from '@/shared/lib/hooks/usePageParamsSearch';

type Props = Omit<
  React.ComponentProps<typeof SearchInput>,
  'onChange' | 'resetValue' | 'value'
>;

export function CrudSearch({ ...props }: Props) {
  const { pageParams, setPageParams } = useCrudContext();
  const { setQuery, query } = usePageParamsSearch(pageParams, setPageParams);

  return (
    <SearchInput
      style={{
        width: '12.5rem',
      }}
      placeholder="Search"
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      resetValue={() => setQuery('')}
      size="s"
      {...props}
    />
  );
}

export default CrudSearch;

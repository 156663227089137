import { useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { cn } from '@/shared/lib/css/cn';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { CONNECTION_STATUS_UI_CONFIG } from 'bundles/Shared/entities/connection/config';
import { isEmailExtractions } from 'bundles/Shared/entities/connection/lib';
import { ConnectionReportType } from 'bundles/Shared/entities/connection/ui/ConnectionReportType';
import { CONNECTION_TYPE_OPTIONS } from 'bundles/Shared/entities/connection/ui/ConnectionTypeSelect';
import { ERPSystemLabel } from 'bundles/Shared/entities/erpsystem/ui/ERPSystemLabel';
import { formatDate } from '@/shared/lib/formatting/dates';
import React from 'react';
import { CopyTextItemIcon } from 'stories/CopyTextItem/CopyTextItem';
import { Label } from 'stories/index';
import { convertToMilliseconds } from '@/shared/lib/converters';

export const LineInfoCard = ({
  label,
  children,
  lastItem,
}: {
  label: string;
  children: React.ReactNode;
  lastItem?: boolean;
}) => (
  <div
    className={cn('inline-regular flex w-full justify-between py-tw-3', {
      ['border-b border-solid border-neutral-100']: !lastItem,
    })}
  >
    <div>{label}</div>
    <div className="text-neutral-900">{children}</div>
  </div>
);

export const ConnectionInfoCard = () => {
  const { fullPath: ROUTE } =
    ROUTES_ROOT.settings.report.connections.connection;
  const params = useParams<ExtractParams<typeof ROUTE>>();

  const { data: connectionData } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery({
      id: Number(params.connectionId),
    });

  const connection = connectionData?.item as IConnection | undefined;

  return (
    <div className="rounded-2xl bg-tw-white px-tw-4">
      <div className="flex flex-col gap-tw-4 pt-tw-4">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-neutral-800">Connection</div>
          <Label
            outline
            color={
              CONNECTION_STATUS_UI_CONFIG[
                connection?.connectionStatus ?? 'active'
              ].color
            }
            className="label-regular"
          >
            {connection?.connectionStatus}
          </Label>
        </div>
        {isEmailExtractions(connection) && (
          <div className="flex items-center justify-between gap-tw-2 rounded-lg bg-neutral-100 p-tw-2">
            <div className="inline-regular text-ellipsis text-neutral-900">
              {connection?.emailAddress}
            </div>
            <CopyTextItemIcon text={connection?.emailAddress ?? ''} />
          </div>
        )}
      </div>
      <div className="pt-tw-1">
        <ConnectionInfoCard.Line label="Created">
          {formatDate(
            convertToMilliseconds((connection?.createdAt ?? 0) as UnixTime),
            'MMMM DD, YYYY h:mm A',
          )}
        </ConnectionInfoCard.Line>
        <ConnectionInfoCard.Line label="Received">
          {formatDate(
            convertToMilliseconds(
              (connection?.lastReceivedAt ?? 0) as UnixTime,
            ),
            'MMMM DD, YYYY h:mm A',
          )}
        </ConnectionInfoCard.Line>
        <ConnectionInfoCard.Line label="Connection Type">
          {
            CONNECTION_TYPE_OPTIONS.find(
              (opt) => opt.value === connection?.type,
            )?.label
          }
        </ConnectionInfoCard.Line>
        <ConnectionInfoCard.Line label="ERP System">
          {connection && (
            <ERPSystemLabel
              system={connection.erpSystem}
              typeThemeColor="mono"
            />
          )}
        </ConnectionInfoCard.Line>
        <ConnectionInfoCard.Line label="Report Type" lastItem>
          {connection && <ConnectionReportType type={connection.reportType} />}
        </ConnectionInfoCard.Line>
      </div>
    </div>
  );
};

ConnectionInfoCard.Line = LineInfoCard;

import React, {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  Suspense,
  lazy,
} from 'react';
import { cn } from '@/shared/lib/css/cn';
import FormButtons from './FormButtons/FormButtons';
import { IFormButton, IGroupFormItem } from './types/types';
import { AnimationLoader } from 'stories';

const FormSwitcher = lazy(() => import('./FormSwitcher'));

export interface IFormConfig {
  steps?: boolean;
  withoutNumberStep?: boolean;
  reviewFlow?: boolean;
  styles?: {
    containerClassName: string;
  };
}

interface Props {
  singleFormItems: IGroupFormItem;
  onSubmit: (data?: object) => void;
  formData: object;
  setFormData: Dispatch<SetStateAction<object>>;
  buttonConfig: IFormButton;
  formConfig?: IFormConfig;
}

const SingleForm: FC<Props> = ({
  singleFormItems,
  onSubmit,
  formData,
  setFormData,
  buttonConfig,
  formConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const onLoading = (status: boolean) => {
    setLoading(status);
  };

  return (
    <div>
      <div
        className={cn(
          'singleFormContainer',
          formConfig?.styles?.containerClassName,
        )}
      >
        <Suspense fallback={<AnimationLoader />}>
          {singleFormItems.formItems.map((formItem, idx) => (
            <FormSwitcher
              formItem={formItem}
              key={`formItem${idx}`}
              formData={formData}
              value={formData[formItem.fieldName]}
              setFormData={setFormData}
              loading={loading}
              validate={validate}
              setValidate={setValidate}
            />
          ))}
        </Suspense>
      </div>
      {!buttonConfig.withoutButtons && (
        <FormButtons
          groupFormItems={[singleFormItems]}
          formData={formData}
          onSubmit={onSubmit}
          loading={loading}
          onLoading={onLoading}
          buttonConfig={buttonConfig}
          groupFormConfig={formConfig}
          validate={validate}
        />
      )}
    </div>
  );
};
export default SingleForm;

import React from 'react';

interface Props {
  width: string;
  height: string;
  backgroundColor?: string;
  borderRadius?: string;
  className?: string;
}

const PlaceholderLine = ({
  width,
  height,
  backgroundColor = 'var(--light-10)',
  borderRadius = '.5rem',
  className,
}: Props) => (
  <div
    style={{
      width,
      height,
      backgroundColor,
      borderRadius,
    }}
    className={className}
  />
);

export default PlaceholderLine;

import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Control, useFormContext } from 'react-hook-form';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { TumblerController } from 'stories/Tumbler/Tumbler';
import { InlineAlert } from '@/stories';

export function HideNegativeValuesField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  disabled,
  disabledNote,
}: {
  name: TName;
  control: Control<TFieldValues>;
  disabled?: boolean;
  disabledNote?: string;
}) {
  const { watch } = useFormContext();
  const value = watch(name);
  return (
    <SectionField
      labelText={'Hide Negative Values'}
      button={
        <TumblerController disabled={disabled} control={control} name={name} />
      }
      note={
        disabled &&
        disabledNote && (
          <InlineAlert status="secondary" message={disabledNote} />
        )
      }
    >
      {value && (
        <InlineAlert
          message="You cant set Comparison or Highlighting Rules Font"
          status="secondary"
        />
      )}
    </SectionField>
  );
}

import React, { useEffect, useState } from 'react';
import NavFireSection from 'bundles/FireStation/components/NavFireSection/NavFireSection';
import NavFireButton from 'bundles/FireStation/components/NavFireButton/NavFireButton';
import {
  mapFireStatsForSorting,
  sortFireStatsByPriority,
} from 'bundles/FireStation/components/utils/utils';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { useLocation } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  collapseSidebar,
  isLeftSidebarExpanded,
} from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';

function FireStationMenuItem() {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector(isLeftSidebarExpanded);
  const { genericData } = useGenericData();
  const { fireStationStats } = genericData;
  const location = useLocation();
  const modifiedFireStats = mapFireStatsForSorting(fireStationStats);
  const modifiedStatsAmount = modifiedFireStats?.length;
  const getMainFireStat = sortFireStatsByPriority(modifiedFireStats)[0];
  const [isOnFireStationPage, setIsOnFireStationPage] = useState(
    location.pathname.includes('fire-station'),
  );
  useEffect(() => {
    setIsOnFireStationPage(location.pathname.includes('fire-station'));
  }, [location]);

  if (expanded) {
    return (
      <div className="w-full mb-m mt-auto px-s">
        <NavFireSection
          setExpanded={() => dispatch(collapseSidebar())}
          fireStationPage={isOnFireStationPage}
          fireStationStats={fireStationStats}
        />
      </div>
    );
  }
  return (
    <NavFireButton
      className="mb-m ml-auto mr-auto mt-auto"
      priority={modifiedStatsAmount ? getMainFireStat.priority : 'none'}
      count={modifiedStatsAmount ? getMainFireStat.count : 0}
      fireStationPage={isOnFireStationPage}
    />
  );
}

export default FireStationMenuItem;

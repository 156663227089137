import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import useElementSize, {
  ElementSizeContext,
} from '@/shared/lib/hooks/useElementSize';
import { ClassNameProps } from '@/types/Props';
import { capitalize } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import pluralize from 'pluralize';
import { HTMLProps, PropsWithChildren, useRef } from 'react';
import { Icon, Label } from 'stories/index';
import { IconsId } from 'types/sre-icons';
import { Maybe } from 'yup/lib/types';

const NavigationCardList = ({
  children,
  className,
}: PropsWithChildren & ClassNameProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(listRef);

  return (
    <OverlayScrollbarsComponent className={cn('shadow-z-000', className)}>
      <div
        ref={listRef}
        className={cn('flex flex-col gap overflow-hidden', className)}
      >
        <ElementSizeContext.Provider value={size}>
          {children}
        </ElementSizeContext.Provider>
      </div>
    </OverlayScrollbarsComponent>
  );
};

export function Img({
  alt,
  fallbackNode,
  ...props
}: HTMLProps<HTMLImageElement> & {
  fallbackNode?: React.ReactNode;
}) {
  const { value: error, setTrue } = useBoolean(false);

  if (error || props.src == null) {
    return <>{fallbackNode}</>;
  }
  return <img onError={setTrue} alt={alt} {...props} />;
}

NavigationCardList.ItemImg = ({
  imgSrc,
  placeholderIcon,
}: {
  imgSrc: Maybe<string>;
  placeholderIcon: IconsId;
}) => {
  const fallBack = (
    <Icon iconName={placeholderIcon} className="header4-regular" />
  );

  return (
    <div className="flex h-[56px] w-[56px] rounded-[8px] border border-solid border-x-neutral-100 p-tw-1 shadow-z-020">
      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded bg-neutral-200 ">
        {imgSrc ? <Img src={imgSrc} fallbackNode={fallBack} /> : fallBack}
      </div>
    </div>
  );
};

NavigationCardList.Label = ({
  text,
  color,
}: {
  text?: string;
  color?: CssVar | string;
}) => (
  <Label className="!p-0" color={color ?? ''}>
    {capitalize(text)}
  </Label>
);

NavigationCardList.ShownItemsInfo = ({ length }: { length: number }) => (
  <p className="secondary-regular uppercase text-neutral-500">
    Shown <span className="text-neutral-900">{length}</span>{' '}
    {pluralize('items', length)}
  </p>
);

export default NavigationCardList;

import { isAfterDate, isBeforeDate } from '@/shared/lib/date';
import dayjs from 'dayjs';

export const relativeTimeToDate = (
  date: Date,
  date2: Date,
  withoutSuffix?: boolean,
) => dayjs(date).to(date2, withoutSuffix);

export const relativeTimeToNow = (date: Date, withoutSuffix?: boolean) =>
  dayjs(date).toNow(withoutSuffix);

export const relativeTimeFromDate = (
  date: Date,
  date2: Date,
  withoutSuffix?: boolean,
) => dayjs(date).from(date2, withoutSuffix);

export const relativeTimeFromNow = (date: Date, withoutSuffix?: boolean) =>
  dayjs(date).fromNow(withoutSuffix);

export const formatRelativeDate = (dateA: Date, dateB: Date) => {
  const isPast = isBeforeDate(dateA, dateB, 'day');

  if (isPast) {
    return relativeTimeFromDate(dateA, dateB);
  }
  const isFuture = isAfterDate(dateA, dateB, 'day');

  if (isFuture) {
    return relativeTimeToDate(dateA, dateB);
  }
  return 'Today';
};

import React, { FC, useState } from 'react';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import FormSelect from 'bundles/Shared/components/GroupForm/FormItems/FormSelect';
import { generateRequisitionDates } from 'bundles/DrawPackage/utils';
import { useParams } from '@reach/router';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';
import { useGetRequisitionsQuery } from 'bundles/Construction/api/invoices';
import SelectSource from 'bundles/DrawPackage/DrawPage/widgets/SelectSource';
import { AnimationLoader, Button, Icon, LabelTitle } from 'stories/index';
import { selectFundingSources } from 'bundles/DrawPackage/slices/ReconcileDrawSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { cn } from '@/shared/lib/css/cn';
import { FundingSourceForDraw } from '@/shared/types/reconcile/FundingSource';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { amountSum } from '@/shared/lib/deprecated/amount';

interface IDrawFundingSourceProps {
  loadingPdf: boolean;
  pdfDrawLink: Buffer | string | null;
  formData: {
    requisitionId?: UUID;
  };
  createDrawRequest: (
    selectFundingSource: FundingSourceForDraw[],
  ) => Promise<void | boolean>;
  onFormDataChange: (e: { requisitionId: UUID }) => Promise<void>;
}

const { fullPath: ROUTE } = ROUTES_ROOT.reconcile.development.legalEntity.draws;

const DrawFundingSource: FC<IDrawFundingSourceProps> = ({
  createDrawRequest,
  onFormDataChange,
  formData,
  pdfDrawLink,
  loadingPdf,
}) => {
  const [loadingDraw, setLoadingDraw] = useState(false);

  const params = useParams<ExtractParams<typeof ROUTE>>();

  const selectedFundingSources = useAppSelector(selectFundingSources);

  const { data: requisitions, isLoading } = useGetRequisitionsQuery({
    legalEntityCode: params?.legalEntityCode,
    not_funded: true,
    fully_mapped_to_invoices: true,
  });

  const lineItemsInRequisition = requisitions?.items.find(
    (req) => req.id === formData.requisitionId,
  )?.lineItems;

  const sumLineItems = amountSum(lineItemsInRequisition);

  const { allocationFlags } = useAllocation({
    total: sumLineItems,
    items: selectedFundingSources,
    sumMapper: (item) => Number(item?.amount ?? 0),
  });

  const { isNotFullyAllocated } = allocationFlags;

  if (isLoading) return <AnimationLoader />;

  return (
    <div className={styles.drawContainer}>
      <div className="flex flex-col gap-tw-6 p-tw-6">
        <div className="flex items-end justify-between gap-tw-4">
          <div className="flex-1">
            <div className="inline-semibold mb-tw-2 text-dark-60">
              <LabelTitle>Select Requisition</LabelTitle>
            </div>
            <FormSelect
              fieldName="requisitionId"
              formData={formData}
              setFormData={onFormDataChange}
              options={
                requisitions?.items.map((item) => ({
                  label: `Requisition (
                    ${generateRequisitionDates(
                      item.beginningDate,
                      item.endingDate,
                    )})`,
                  value: item.id,
                })) ?? []
              }
              disabled={loadingPdf || loadingDraw}
            />
          </div>
          <CurrencyInputNumber
            className="w-[150px]"
            value={sumLineItems ?? 0}
            disabled
          />
        </div>
        <SelectSource
          requisitionSelectedId={formData?.requisitionId}
          totalAmount={sumLineItems}
          disabled={loadingPdf || loadingDraw}
          onFormDataChange={onFormDataChange}
        />
      </div>
      <div className={cn(styles.button, 'px-tw-6')}>
        <Button
          variant="success"
          className="w-full mb-tw-4"
          onClick={async () => {
            setLoadingDraw(true);
            const res = await createDrawRequest(selectedFundingSources);
            if (!res) {
              setLoadingDraw(false);
            }
          }}
          disabled={isNotFullyAllocated || loadingDraw || !pdfDrawLink}
        >
          Generate Draw Request
          {loadingDraw && (
            <Icon className="form-button-loading mr-s" iconName="sync" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default DrawFundingSource;

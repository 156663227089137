import React from 'react';
import { Router } from '@reach/router';
import PipelineDashboard from 'bundles/Scoreboard/Pipeline/components/PipelineDashboard';
import { NotFound } from 'bundles/Errors';
import { useScoreBoardMenuItemPermissions } from 'bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import { RouterComponentWithoutWrapper } from 'lib/reach-router';
import EagleEyeDashboard from 'bundles/Scoreboard/EagleEyeDashboard';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

const ScoreboardsPage = () => {
  const { pipelineVisible } = useScoreBoardMenuItemPermissions();

  return (
    <Router component={RouterComponentWithoutWrapper}>
      <NotFound default />
      {pipelineVisible && <PipelineDashboard path="pipeline" />}
      <EagleEyeDashboard path={ROUTES_ROOT.scoreboards.scoreboard.path} />
    </Router>
  );
};

export default ScoreboardsPage;

import http from 'lib/http';

export const CREATE_ASSET_STAGE = 'symmetre-client-api/CREATE_ASSET_STAGE';
export const UPDATE_ASSET_STAGE = 'symmetre-client-api/UPDATE_ASSET_STAGE';
export const TOGGLE_ASSET_STAGE = 'symmetre-client-api/TOGGLE_ASSET_STAGE';
export const DELETE_ASSET_STAGE = 'symmetre-client-api/DELETE_ASSET_STAGE';
export const UPDATE_POSITIONS_ASSET_STAGE = 'symmetre-client-api/UPDATE_POSITIONS_ASSET_STAGE';

export function createAssetStage(data) {
  return async function (dispatch) {
    try {
      const res = await http.post('/asset_stages', data);
      const payload = await res.json();

      toastr.success('Asset stage successfully created');
      dispatch({ type: CREATE_ASSET_STAGE, payload });
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function updateAssetStage(id, data) {
  return async function (dispatch) {
    try {
      const res = await http.put(`/asset_stages/${id}`, data);
      const payload = await res.json();

      toastr.success('Asset stage was successfully updated');
      dispatch({ type: UPDATE_ASSET_STAGE, payload });
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function updatePositionsAssetStage(id, data) {
  return async function (dispatch) {
    try {
      const res = await http.put(`/asset_stages/${id}/update_positions`, data);
      const payload = await res.json();

      toastr.success('Asset stage was successfully updated');
      dispatch({ type: UPDATE_POSITIONS_ASSET_STAGE, payload });
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function deleteAssetStage(id) {
  return async function (dispatch) {
    try {
      const res = await http.del(`/asset_stages/${id}`);
      if (res.status === 403) {
        toastr.error('Asset stage assigned to some asset and can not be deleted');
      } else {
        const payload = await res.json();

        toastr.success('Asset stage was successfully deleted');
        dispatch({ type: DELETE_ASSET_STAGE, payload });
      }
    } catch {
      throw new Error(`Can not delete asset stage with id ${id}`);
    }
  };
}

export function toggleAssetStage(id) {
  return async function (dispatch) {
    try {
      const res = await http.put(`/asset_stages/${id}/toggle`);
      if (res.status === 403) throw new Error('forbidden');
      const payload = await res.json();

      toastr.success('Asset stage was successfully updated');
      dispatch({ type: TOGGLE_ASSET_STAGE, payload });
    } catch {
      toastr.error('Asset stage assigned to asset and can not be disabled');
    }
  };
}

import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import {
  changeInvoiceDocumentLink,
  IInvoiceESignData,
  selectInvoiceESignData,
  setESingData,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { useAppSelector, useAppDispatch } from '@/shared/lib/hooks/redux';
import { Tumbler } from 'stories';
import { cloneDeep } from 'lodash-es';
import ColorPicker from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/eSign/stamp/ColorPicker';

const StampControlSection = () => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const eSignData = useAppSelector(selectInvoiceESignData);
  const baseSettings = eSignData.stamp.base;
  const additionalSettings = eSignData.stamp.additional;

  const dispatch = useAppDispatch();
  const params = useParams<{
    legalEntityCode: string;
    invoiceId: string;
  }>();

  useEffect(() => {
    dispatch(
      changeInvoiceDocumentLink({
        eSignData,
        params,
      }),
    );
  }, []);

  const changeStampESignData = (
    type: keyof IInvoiceESignData['stamp'],
    field: string,
    value: string | boolean,
  ) => {
    const newESignData = cloneDeep(eSignData);

    newESignData.stamp[type][field].value = value;
    dispatch(setESingData(newESignData));
    dispatch(
      changeInvoiceDocumentLink({
        eSignData: newESignData,
        params,
      }),
    );
  };

  return (
    <div className="flex flex-col gap-tw-4">
      <div className="body-semibold text-dark-60">Stamp</div>
      <div className="flex flex-col gap-tw-6">
        <div className="flex flex-col gap-tw-2">
          <div className="secondary-regular font-semibold uppercase">Base</div>
          <div className="flex flex-col gap-tw-1.5">
            {Object.keys(baseSettings).map((keyItem) => (
              <div key={keyItem} className="w-full">
                <div className="flex justify-between">
                  <div className="inline-regular text-dark-60">
                    {baseSettings[keyItem].title}
                  </div>
                  <Tumbler
                    onChange={() => {
                      changeStampESignData(
                        'base',
                        keyItem,
                        !baseSettings[keyItem].value,
                      );
                    }}
                    checked={baseSettings[keyItem].value}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-tw-2">
          <div className="secondary-regular font-semibold uppercase">
            Additional
          </div>
          <div className="flex flex-col gap-tw-1.5">
            {Object.keys(additionalSettings).map((keyItem) => (
              <div key={keyItem} className="w-full">
                {additionalSettings[keyItem].type === 'toggle' && (
                  <div
                    key={additionalSettings[keyItem].fieldName}
                    className="w-full"
                  >
                    <div className="flex justify-between">
                      <div className="inline-regular text-dark-60">
                        {additionalSettings[keyItem].title}
                      </div>
                      <Tumbler
                        onChange={() => {
                          changeStampESignData(
                            'additional',
                            keyItem,
                            !additionalSettings[keyItem].value,
                          );
                        }}
                        checked={additionalSettings[keyItem].value}
                      />
                    </div>
                  </div>
                )}
                {additionalSettings[keyItem].type === 'colorPicker' && (
                  <div className="flex justify-between">
                    <div className="inline-regular text-dark-60">Color</div>
                    <ColorPicker
                      color={eSignData.stamp.additional.color.value}
                      handleChange={(color) => {
                        changeStampESignData('additional', keyItem, color);
                        setOpenColorPicker(false);
                      }}
                      open={openColorPicker}
                      setOpen={setOpenColorPicker}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StampControlSection;

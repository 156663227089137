import React, { useEffect } from 'react';
import { Button, Dropdown, DropdownItem, IconButton, Popover } from '@/stories';
import { DashboardFilterSetForm } from 'bundles/Shared/features/dashboard/filter/filterSet/ui/DashboardFilterSetForm';
import FilterButton from 'bundles/Shared/components/Filters/buttons/filterButton/FilterButton';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  dashboardSettingsFilterSetsEnhancedApi,
  useDeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation,
  useGetApiReportEagleEyeDashboardsFilterSetsQuery,
} from 'bundles/Shared/shared/api/dashboardSettingsFilterSetsEnhancedApi';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { setDashboardFilterSet } from 'bundles/Shared/features/dashboard/filter/filterSet/model';
import {
  FilterSetDto,
  selectReportDashboardSelectedFilterSet,
  useReportEagleEyeDashboardById,
} from 'bundles/Shared/entities/dashboard';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { addListener } from '@reduxjs/toolkit';
import { canConfigureFilterSets } from 'bundles/Shared/widgets/dashboard/filterSet/lib';

export function DashboardFilterSets({ dashboardId }: { dashboardId: string }) {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const { dashboard } = useReportEagleEyeDashboardById({
    dashboardId,
  });
  const selectedFilterSet = useAppSelector(
    selectReportDashboardSelectedFilterSet,
  );
  const { data, isLoading } = useGetApiReportEagleEyeDashboardsFilterSetsQuery(
    {},
  );

  useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        matcher:
          dashboardSettingsFilterSetsEnhancedApi.endpoints
            .putApiSettingsReportEagleEyeDashboardsFilterSetsById
            .matchFulfilled,
        effect: (action, listener) => {
          listener.dispatch(
            setDashboardFilterSet({
              filterSet: action.payload,
              dashboard,
            }),
          );
        },
      }),
    );
    return unsubscribe;
  }, [dashboard]);
  useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        matcher:
          dashboardSettingsFilterSetsEnhancedApi.endpoints
            .deleteApiSettingsReportEagleEyeDashboardsFilterSetsById
            .matchFulfilled,
        effect: (action, listener) => {
          if (
            selectedFilterSet == null ||
            action.meta.arg.originalArgs.id !== selectedFilterSet.id
          ) {
            return;
          }
          listener.dispatch(
            setDashboardFilterSet({
              filterSet: null,
              dashboard,
            }),
          );
        },
      }),
    );
    return unsubscribe;
  }, [dashboard, selectedFilterSet]);

  const [removeFilterSet] =
    useDeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation();
  const getTemplate = () => {
    if (data == null) {
      return null;
    }

    const buildFilterOptions = () => {
      const buildActions = (o: FilterSetDto) => (
        <Dropdown
          classNameContainer="h-min"
          appendTo={document.body}
          className="sre-dropdown-v2"
          items={[
            <DropdownItem
              key="edit"
              iconName="editAlt"
              onClick={() =>
                openModal(DashboardFilterSetForm, {
                  filterSet: o,
                })
              }
            >
              Edit
            </DropdownItem>,
            <DropdownItem
              key="remove"
              iconName="trash"
              onClick={() =>
                removeFilterSet({
                  id: o.id,
                })
              }
            >
              Remove
            </DropdownItem>,
          ]}
        >
          <IconButton variant="secondary" iconName="more" />
        </Dropdown>
      );
      return data.map((o) => ({
        label: (
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex w-full items-center justify-between"
          >
            {o.name}
            {canConfigureFilterSets() && buildActions(o)}
          </div>
        ),
        value: o.id,
      }));
    };
    return (
      <div className="flex flex-col gap-tw-2">
        <div className="flex items-center justify-between">
          <span className="secondary-semibold text-neutral-550">
            {data.length === 0 ? 'No Sets yet' : 'Available Sets'}{' '}
          </span>
          {canConfigureFilterSets() && (
            <Button
              onClick={() => openModal(DashboardFilterSetForm, {})}
              variant="secondary"
              size="xs"
            >
              Add
            </Button>
          )}
        </div>
        <div className="h-[1px] w-full bg-neutral-100" />
        {data?.length === 0 && (
          <div className="inline-regular h-[72px] text-center text-neutral-500">
            Once you add a new set of filters, you will be able to use them from
            this area
          </div>
        )}
        {data.length !== 0 && (
          <RadioGroup
            value={selectedFilterSet?.id}
            onChange={(o) => {
              dispatch(
                setDashboardFilterSet({
                  filterSet: data.find((f) => f.id === o.value)!,
                  dashboard,
                }),
              );
            }}
            getItemProps={() => ({
              classes: {
                text: 'grow',
              },
            })}
            options={buildFilterOptions()}
          />
        )}
      </div>
    );
  };

  return (
    <Popover
      hiddenArrow
      trigger="click"
      placement="bottom-end"
      offset={DEFAULT_DROPDOWN_OFFSET}
      className="min-w-[270px] p-tw-4"
      template={getTemplate()}
      hideOnExpandedAreaClick
    >
      {({ triggered }) => (
        <FilterButton
          size="s"
          classes={{
            container: 'h-auto',
          }}
          iconName="filtersFilled"
          clicked={triggered}
          filtered={selectedFilterSet != null}
          loading={isLoading}
          onClose={() => {
            dispatch(
              setDashboardFilterSet({
                filterSet: null,
                dashboard,
              }),
            );
          }}
          className="px-tw-2"
        >
          {selectedFilterSet?.name ?? 'Filters'}
        </FilterButton>
      )}
    </Popover>
  );
}

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import PlacementControlSection from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/eSign/placement/PlacementControlSection';
import PlacementPreviewArea from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/eSign/placement/PlacementPreviewArea';
import ViewSettings from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/eSign/ViewSettings';
import StampControlSection from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/eSign/stamp/StampControlSection';
import StampPreviewArea from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/eSign/stamp/StampPreviewArea';

const InvoiceESignature = () => (
  <div className={cn(styles.pageESignContainer, styles.tableBody)}>
    <div className="flex flex-col gap-tw-4 px-tw-6 py-tw-6">
      <div className="inline-regular text-light-90">
        Set up e-Signature Stamp and Placement for this Invoice
      </div>
      <div>
        <ViewSettings
          controlSection={<StampControlSection />}
          previewArea={<StampPreviewArea />}
        />
      </div>
      <div>
        <ViewSettings
          controlSection={<PlacementControlSection />}
          previewArea={<PlacementPreviewArea />}
        />
      </div>
    </div>
  </div>
);

export default InvoiceESignature;

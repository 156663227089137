import React, { useEffect, useState } from 'react';
import {
  CrudContextProvider,
  CrudTable,
  CrudTablePanel,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import useEvent from '@/shared/lib/hooks/useEvent';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useSovcsCrudColumns } from 'bundles/REconcile/components/development/home/settingsModal/sovcs/useColumns';
import {
  IReconcileSettingsSovc,
  sovcsCrudApi,
  TGetSovcsInReconcileSettingsParams,
} from 'bundles/REconcile/actions/sovcs';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import SovcCrudModal from 'bundles/REconcile/components/development/home/settingsModal/sovcs/SovcCrudModal';
import { fetchJCCForReallocations } from 'bundles/Construction/actions';

function SovcsCrud() {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const jobCostCodes = useAppSelector((state) => state.JCCForReallocations);
  const [totalSize, setTotalSize] = useState(0);
  const [pageParams, setPageParams] =
    useState<TGetSovcsInReconcileSettingsParams>({
      per_page: 10,
    });
  const [sovcs, setSovcs] = useState<IReconcileSettingsSovc[]>([]);

  const columns = useSovcsCrudColumns({
    params: pageParams,
    setParams: setPageParams,
    jobCostCodes: jobCostCodes ?? [],
  });

  const loadSovcs = async () => {
    const data = await sovcsCrudApi.getEntities({}, pageParams);
    setTotalSize(data.meta.totalSize);
    setSovcs(data.items);
  };

  const handleCreate = useEvent(async () => {
    const res = await openModal(SovcCrudModal, {
      type: 'create',
      entity: {
        code: '',
        name: '',
        job_cost_code_id: null,
      },
      jccList: jobCostCodes ?? [],
    });

    if (res) {
      await sovcsCrudApi.createEntity({}, res);
      loadSovcs();
    }
  });

  const handleEdit = useEvent(async (row: IReconcileSettingsSovc) => {
    const res = await openModal(SovcCrudModal, {
      type: 'edit',
      entity: {
        name: row.name,
        code: row.code,
        job_cost_code_id: row.job_cost_code?.id,
      },
      jccList: jobCostCodes ?? [],
    });

    if (res) {
      await sovcsCrudApi.updateEntity(
        {
          entityId: row.id,
        },
        res,
      );
      loadSovcs();
    }
  });

  const handleRemove = useEvent(async (row: IReconcileSettingsSovc) => {
    await sovcsCrudApi.deleteEntity({
      entityId: row.id,
    });
    loadSovcs();
  });

  useEffect(() => {
    dispatch(fetchJCCForReallocations());
  }, []);

  useEffect(() => {
    loadSovcs();
  }, [pageParams]);

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entities={sovcs}
      entityName={sovcsCrudApi.entityName}
      handlers={{
        create: handleCreate,
        update: handleEdit,
        delete: handleRemove,
      }}
    >
      <div>
        <CrudTablePanel
          paginationProps={{
            totalSize,
          }}
        />
        <CrudTable
          onFilterModelChange={(filterModel: { jcc?: { id: number }[] }) =>
            setPageParams((prevParams) => ({
              ...prevParams,
              job_cost_code_ids: filterModel.jcc?.map(({ id }) => id),
            }))
          }
          columns={columns}
        />
      </div>
    </CrudContextProvider>
  );
}

export default SovcsCrud;

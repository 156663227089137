import { CapitalInvestmentEntryBase } from 'bundles/REturn/actions/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import React from 'react';
import { CurrencyFormatter, IconButton } from 'stories/index';

type Props = DialogProps & CapitalInvestmentEntryBase;

export function CustomClose({ amount, date, onClose }: Props) {
  return (
    <div className="flex items-center gap-tw-6">
      <div className="flex items-center gap-tw-4 rounded-full bg-light-5 px-tw-3 py-tw-1">
        <span className="flex items-center gap-tw-2">
          <span className="label-regular capitalize text-light-60">
            Transaction amount:
          </span>
          <CurrencyFormatter
            classes={{ value: 'secondary-regular text-dark-60' }}
            value={amount}
          />
        </span>
        <span className="secondary-regular flex items-center gap-tw-2 text-dark-60">
          <span className="label-regular capitalize text-light-60">
            Transaction date:
          </span>
          {formatDate(date, 'DD-MM-YY')}
        </span>
      </div>
      <IconButton
        iconName="close"
        size="l"
        onClick={onClose}
        variant="secondary"
      />
    </div>
  );
}

import { EntityStatus } from '@/shared/model/entityStatus';
import { IconsId } from '@/types/sre-icons';
import { ReactComponent as PublishedReportIcon } from 'fonts/sre-icons/Icons/docPdf.svg';
import { ReactComponent as DraftReportIcon } from 'fonts/sre-icons/Icons/feedMessageAlt.svg';

export const REPORT_STATUS_MAP = {
  draft: {
    className: 'text-attention-055',
    iconName: 'draft',
    label: 'Draft',
    iconComponent: DraftReportIcon,
  },
  published: {
    className: 'text-success-055',
    iconName: 'public',
    label: 'Published',
    iconComponent: PublishedReportIcon,
  },
} as const satisfies Record<
  EntityStatus,
  {
    iconName: IconsId;
    className: string;
    label: string;
    iconComponent: React.FC<unknown>;
  }
>;

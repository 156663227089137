import React, { FC, ReactNode } from 'react';

interface ICEStatusMessageTitleProps {
  children: ReactNode;
}

const CEStatusMessageTitle: FC<ICEStatusMessageTitleProps> = ({ children }) => (
  <div className="title-multiline-alert">{children}</div>
);

export default CEStatusMessageTitle;

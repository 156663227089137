import { dashboardSettingsApi as api } from './dashboardsSettingsGeneratedApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndId:
      build.query<
        GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiResponse,
        GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/comparison_dashboards/${queryArg.comparisonDashboardId}/widget_sections/${queryArg.id}`,
          params: {
            date: queryArg.date,
            grouping_type: queryArg.groupingType,
            legal_entity_ids: queryArg.legalEntityIds,
            asset_ids: queryArg.assetIds,
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            granularity: queryArg.granularity,
            period: queryArg.period,
            period_type: queryArg.periodType,
          },
        }),
      }),
    getApiReportComparisonDashboardsById: build.query<
      GetApiReportComparisonDashboardsByIdApiResponse,
      GetApiReportComparisonDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/comparison_dashboards/${queryArg.id}`,
      }),
    }),
    getApiReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardId:
      build.query<
        GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse,
        GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}`,
        }),
      }),
    getApiReportEagleEyeDashboardsFilterSets: build.query<
      GetApiReportEagleEyeDashboardsFilterSetsApiResponse,
      GetApiReportEagleEyeDashboardsFilterSetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/eagle_eye_dashboards/filter_sets`,
        params: { eagle_eye_scoreboard_id: queryArg.eagleEyeScoreboardId },
      }),
    }),
    getApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndId:
      build.query<
        GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiResponse,
        GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/widget_sections/${queryArg.id}`,
          params: {
            date: queryArg.date,
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            granularity: queryArg.granularity,
            grouping_type: queryArg.groupingType,
            assets: queryArg.assets,
            segments: queryArg.segments,
            asset_ids: queryArg.assetIds,
            segment_ids: queryArg.segmentIds,
            min_lease_term: queryArg.minLeaseTerm,
            max_term_variance: queryArg.maxTermVariance,
            period_type: queryArg.periodType,
            filters: queryArg.filters,
            query: queryArg.query,
            page: queryArg.page,
            per_page: queryArg.perPage,
            period: queryArg.period,
          },
        }),
      }),
    getApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndId:
      build.query<
        GetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdApiResponse,
        GetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/object_dashboards/${queryArg.objectDashboardId}/widget_sections/${queryArg.id}`,
          params: {
            date: queryArg.date,
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            granularity: queryArg.granularity,
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            legal_entity_ids: queryArg.legalEntityIds,
            filters: queryArg.filters,
          },
        }),
      }),
    getApiReportObjectDashboardsById: build.query<
      GetApiReportObjectDashboardsByIdApiResponse,
      GetApiReportObjectDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/object_dashboards/${queryArg.id}`,
      }),
    }),
    getApiReportScoreboardsById: build.query<
      GetApiReportScoreboardsByIdApiResponse,
      GetApiReportScoreboardsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/report/scoreboards/${queryArg.id}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportDashboardsApi };
export type GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiResponse =
  /** status 200 success */
  | KpiTableWidgetDto
  | KpiTableSingleDateWidgetDto
  | XyChartSingleKpiWidgetDto
  | ReportScoreboardFinancialTableSingleDateWidgetDto
  | ReportScoreboardFinancialTableSinglePeriodWidgetDto;
export type GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg =
  {
    comparisonDashboardId: string;
    id: string;
    date: string;
    groupingType: 'assets' | 'segments';
    legalEntityIds?: {
      ''?: string[];
    };
    assetIds?: {
      ''?: number[];
    };
    dateFrom?: string;
    dateTo?: string;
    granularity?: string;
    /** Period object (see ReportScoreboardPeriod schema) */
    period?: {
      type?: string;
      [key: string]: any;
    };
  };
export type GetApiReportComparisonDashboardsByIdApiResponse =
  /** status 200 success */ ComparisonDashboardDto;
export type GetApiReportComparisonDashboardsByIdApiArg = {
  id: string;
};
export type GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse =
  /** status 200 Success */ ReportDashboardBoardDto;
export type GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
  };
export type GetApiReportEagleEyeDashboardsFilterSetsApiResponse =
  /** status 200 Filters returned */ FilterSetDto[];
export type GetApiReportEagleEyeDashboardsFilterSetsApiArg = {
  eagleEyeScoreboardId?: string;
};
export type GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiResponse =
  /** status 200 success */
  | KpiCardWidgetDto
  | KpiTableWidgetDto
  | KpiTableSingleDateWidgetDto
  | XyChartSingleKpiWidgetDto
  | GlobalLeaseTableWidgetDto;
export type GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg =
  {
    eagleEyeDashboardId: string;
    id: string;
    date?: string;
    dateFrom?: string;
    dateTo?: string;
    granularity?: string;
    groupingType?: string;
    assets?: {
      ''?: number[];
    };
    segments?: {
      ''?: number[];
    };
    assetIds?: {
      ''?: number[];
    };
    segmentIds?: {
      ''?: number[];
    };
    minLeaseTerm?: number;
    maxTermVariance?: number;
    filters?: string;
    query?: string;
    page?: number;
    perPage?: number;
    /** Period object (see ReportScoreboardPeriod schema) */
    period?: {
      type?: string;
      [key: string]: any;
    };
  };
export type GetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdApiResponse =
  /** status 200 success */
  | DashboardHistoricalReviewTableWidgetDto
  | DashboardDelinquencyTrackerTableWidgetDto
  | RecentRentRollTableWidgetDto
  | MasterUnitTableDto
  | UnitMixTableWidgetDto
  | UnitMixSummaryTableWidgetDto
  | AverageActualRentChartWidgetDto
  | DelinquencySnapshotColumnChartWidgetDto
  | DelinquencyAverageColumnChartWidgetDto
  | KpiCardWidgetDto
  | XyChartWidgetDto
  | ReportScoreboardFinancialTableSingleDateWidgetDto;
export type GetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdApiArg =
  {
    objectDashboardId: string;
    id: string;
    date?: string;
    dateFrom?: string;
    dateTo?: string;
    granularity?: string;
    page?: number;
    perPage?: number;
    query?: string;
    legalEntityIds?: {
      ''?: string[];
    };
    filters?: string;
  };
export type GetApiReportObjectDashboardsByIdApiResponse =
  /** status 200 success */ ObjectDashboardDto;
export type GetApiReportObjectDashboardsByIdApiArg = {
  id: string;
};
export type GetApiReportScoreboardsByIdApiResponse =
  /** status 200 scoreboard */ ReportScoreboardDto;
export type GetApiReportScoreboardsByIdApiArg = {
  id: string;
};
export type ValueDisplayOptionsDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date';
  precision: 0 | 1 | 2;
  kiloFormatting: boolean;
  hideCommaSeparator: boolean;
  dateFormat?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY';
  nowTime?: 'now' | 'to';
};
export type KpiTableWidgetDto = {
  type: 'kpi_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions: ValueDisplayOptionsDto;
  }[];
  rows: {
    type?: 'segment' | 'asset' | 'legal_entity';
    label?: string;
    path?: string;
    values?: {
      [key: string]: number | null;
    };
  }[];
  data?: {
    type?: 'segment' | 'asset' | 'legal_entity' | 'total';
    label?: string;
    path?: string;
  }[];
};
export type KpiTableSingleDateWidgetDto = {
  type: 'kpi_table_single_date';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
    valueDisplayOptions: ValueDisplayOptionsDto;
  }[];
  rows: {
    type?: 'segment' | 'asset' | 'legal_entity';
    label?: string;
    path?: string;
    values?: {
      [key: string]: number | null;
    };
  }[];
  data?: {
    type?: 'segment' | 'asset' | 'legal_entity' | 'total';
    label?: string;
    path?: string;
  }[];
};
export type XyChartSingleKpiWidgetDto = {
  type: 'xy_chart_single_kpi';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
  data: {
    objectName: string;
    objectType: 'segment' | 'asset';
    objectId: string;
    kpiKey: string;
    kpiValue: number | null;
    dateFrom: string;
    dateTo: string;
  }[];
};
export type ValueDisplayOptionsConfigDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date';
  precision: 0 | 1 | 2;
  kilo_formatting: boolean;
  hide_comma_separator?: boolean;
  date_format?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY';
  nowTime?: 'now' | 'to';
};
export type VizConfigBackgroundDto = {
  background?:
    | {
        type: 'gradient';
        ignore_zeros?: boolean;
        thresholds: {
          type: 'min' | 'max' | 'number' | 'percentage' | 'percentile';
          value?: number;
        }[];
      }
    | {
        type: 'color';
        color: string;
      };
};
export type VizConfigAutoColumnDto = {
  header?: {
    title?: string;
  };
};
export type ReportScoreboardBaseFinancialTableWidgetBaseGroupDto = {
  key: number;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type ReportScoreboardBaseFinancialTableWidgetBaseRowDto = {
  path: string;
  label: string;
  displayOptions: {
    fontWeight: string | null;
    color: string | null;
    background: string | null;
  };
  valueDisplayOptions: ValueDisplayOptionsDto;
  values: {
    columnKey: number;
    groupKey: number;
    expression: string | null;
    value: number | null;
  }[];
};
export type ReportScoreboardBaseFinancialTableWidgetDto = {
  hideHeader: boolean;
  headerBackground: string | null;
  agGridConfig: object;
  vizType?: string;
  vizConfig?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  groups: (
    | (ReportScoreboardBaseFinancialTableWidgetBaseGroupDto & {
        type: 'total';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseGroupDto & {
        type: 'asset';
        asset: {
          id: number;
          name: string;
        };
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseGroupDto & {
        type: 'segment';
        segment: {
          id: number;
          name: string;
        };
      })
  )[];
  columns: {
    key: number;
    label: string;
    subLabel: string | null;
    hideSubLabel?: boolean;
    dateFrom: string | null;
    dateTo: string | null;
    valueDisplayOptionsOverride?: ValueDisplayOptionsDto;
  }[];
  rows: (
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'blank';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'formula';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'category';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'general_ledger';
        legalEntityCode: string;
        erpSystem: string;
      })
  )[];
};
export type ReportScoreboardFinancialTableSingleDateWidgetDto =
  ReportScoreboardBaseFinancialTableWidgetDto & {
    type: 'financial_table_single_date';
  };
export type ReportScoreboardFinancialTableSinglePeriodWidgetDto =
  ReportScoreboardBaseFinancialTableWidgetDto & {
    type: 'financial_table_single_period';
  };
export type XyChartWidgetConfigDto = {
  am_chart_config: object;
};
export type WidgetColumnComparisonDto = {
  to_col_id?: string;
  rule?:
    | 'negative_red_positive_green'
    | 'negative_green_positive_red'
    | 'negative_red';
  hide_negative_values?: boolean;
};
export type AdjustmentExpressionDto = {
  name:
    | 'annualized'
    | 'op_budget_annualized'
    | 'uw_budget_annualized'
    | 'financial_t1a'
    | 'financial_t3a'
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_variance_flipped'
    | 'op_variance_flipped_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_variance_flipped'
    | 'uw_variance_flipped_percent'
    | 'uw_budget'
    | 'period_to_period_variance'
    | 'period_to_period_variance_percent'
    | 'year_to_year_variance'
    | 'year_to_year_variance_percent';
  source_overrides?: {
    source:
      | 'annualized'
      | 'op_budget_annualized'
      | 'uw_budget_annualized'
      | 'financial_t1a'
      | 'financial_t3a'
      | 'actual'
      | 'beginning_balance'
      | 'ending_balance'
      | 'debit'
      | 'credit'
      | 'budget'
      | 'underwriting_budget';
    override_to: string;
  }[];
};
export type KpiTableWidgetConfigDto = {
  viz_type: string;
  viz_config: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hidden?: boolean;
      value_display_options: {
        type?:
          | 'number'
          | 'percentage'
          | 'currency'
          | 'metric'
          | 'sqft'
          | 'bps'
          | 'date';
        precision?: 0 | 1 | 2;
        kilo_formatting?: boolean;
        hide_comma_separator?: boolean;
      };
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      align?: 'left' | 'center' | 'right';
      background?: VizConfigBackgroundDto;
    }[];
  };
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    adjustment_expression?: AdjustmentExpressionDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    total_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  period_types: ('t3' | 't6' | 't12' | 'week' | 'mtd' | 'month' | 'ytd')[];
  default_options: {
    grouping_type: 'segments' | 'assets';
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    period_type: 't3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'week';
  };
  ag_grid_config?: object;
};
export type TrailingPeriodTypeDto = {
  type: 'day' | 'month' | 'quarter';
  amount: number;
};
export type KpiTableSingleDateWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hide_dates?: boolean;
      hidden?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options: {
        type?:
          | 'number'
          | 'percentage'
          | 'currency'
          | 'metric'
          | 'sqft'
          | 'bps'
          | 'date';
        precision?: 0 | 1 | 2;
        kilo_formatting?: boolean;
        hide_comma_separator?: boolean;
      };
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
    }[];
  };
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    period_type:
      | ('day' | 'week' | 'month' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    value_display_options: {
      type?: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
      precision?: 0 | 1 | 2;
      kilo_formatting?: boolean;
      hide_comma_separator?: boolean;
    };
    adjustment_expression?: AdjustmentExpressionDto;
    total_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  default_options: {
    grouping_type: 'segments' | 'assets';
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
  ag_grid_config?: object;
};
export type BaseFinancialTableWidgetConfigBaseColumnDto = {
  key: number;
  label: string;
  sub_label?: string;
  hide_sub_label?: boolean;
  adjustment_expression_name?:
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_budget'
    | 'financial_t1a'
    | 'financial_t3a';
  adjustment_average_per?:
    | 'unit'
    | 'unit_occupied'
    | 'unit_vacant'
    | 'unit_available'
    | 'unit_preleased'
    | 'sqft';
  value_display_options_override?: ValueDisplayOptionsConfigDto;
  period_shift?: {
    months?: number;
    quarters?: number;
    years?: number;
  };
};
export type BaseFinancialTableWidgetConfigRowBaseRowDto = {
  path: string;
  label?: string;
  children?: BaseFinancialTableWidgetConfigRowDto[];
  display_options?: {
    font_weight?: string;
    color?: string;
    background?: string;
  };
  value_display_options?: ValueDisplayOptionsConfigDto;
};
export type BaseFinancialTableWidgetConfigRowDto =
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'blank';
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'formula';
      expression: string;
      negate_variance?: boolean;
      adjustment_expression_source_overrides?: {
        source:
          | 'financial_beginning_balance'
          | 'financial_ending_balance'
          | 'financial_debit'
          | 'financial_credit'
          | 'op_variance'
          | 'op_variance_percent'
          | 'op_variance_flipped'
          | 'op_variance_flipped_percent'
          | 'op_budget'
          | 'uw_variance'
          | 'uw_variance_percent'
          | 'uw_variance_flipped'
          | 'uw_variance_flipped_percent'
          | 'uw_budget'
          | 'financial_t1a'
          | 'financial_t3a';
        override_to: string;
      }[];
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'category';
      category_path: string;
      hide_general_ledgers?: boolean;
    });
export type FinancialTableSingleDateWidgetConfigDto = {
  hide_header?: boolean;
  header_background?: string;
  ag_grid_config: object;
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  columns: (BaseFinancialTableWidgetConfigBaseColumnDto & {
    period_type:
      | ('month' | 'quarter' | 'year' | 'mtd' | 'qtd' | 'ytd' | 'itd')
      | TrailingPeriodTypeDto;
  })[];
  rows: BaseFinancialTableWidgetConfigRowDto[];
  default_options: {
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type FinancialTableSinglePeriodWidgetConfigDto = {
  period_types: ('t3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'itd')[];
  hide_header?: boolean;
  header_background?: string;
  ag_grid_config: object;
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  columns: BaseFinancialTableWidgetConfigBaseColumnDto[];
  rows: BaseFinancialTableWidgetConfigRowDto[];
  default_options: {
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    period_type: 't3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'itd';
  };
};
export type ComparisonDashboardDto = {
  id: string;
  name: string;
  slug: string;
  assets: {
    id: number;
    name: string;
    slug: string;
    pictureUrl: string | null;
    legalEntities: {
      id: string;
      name: string;
      code: string;
    }[];
  }[];
  boards: {
    id: string;
    name: string;
    order: number;
    slug: string;
    sections: {
      id: string;
      title: string;
      widgetType:
        | 'kpi_table'
        | 'kpi_table_single_date'
        | 'xy_chart_single_kpi'
        | 'financial_table_single_date'
        | 'financial_table_single_period';
      widgetConfig:
        | (
            | XyChartWidgetConfigDto
            | KpiTableWidgetConfigDto
            | KpiTableSingleDateWidgetConfigDto
            | FinancialTableSingleDateWidgetConfigDto
            | FinancialTableSinglePeriodWidgetConfigDto
          )
        | null;
      position: {
        x: number;
        y: number;
        w: number;
        h: number;
        maxW: number;
        maxH: number;
        minW: number;
        minH: number;
      };
      defaultOptions: {
        [key: string]: any;
      } | null;
    }[];
  }[];
};
export type ReportScorecardGraphedColumnDto = {
  key: string;
  label: string;
  valueDisplayOptions: {
    type: 'number' | 'percentage' | 'currency' | 'sqft' | 'metric';
    precision: 0 | 1 | 2;
  } | null;
};
export type ReportScorecardDto = {
  id: string;
  title: string;
  withMtdYtd: boolean;
  defaultPeriodShift: number;
  graphedColumns: ReportScorecardGraphedColumnDto[];
  assets: {
    id: number;
    name: string;
  }[];
  segments: {
    id: number;
    name?: string;
  }[];
};
export type ReportScoreboardScorecardSectionDto = {
  id: number;
  sectionId: string;
  scorecard: ReportScorecardDto;
  defaultPresentation: 'chart' | 'table';
  defaultView: 'asset' | 'segment';
  chartPresentation: {
    id: number;
    assets: {
      id: number;
      assetId: number;
    }[];
    reportTableSegments: {
      id: number;
      tableSegmentId?: number;
    }[];
    kpiKey: string;
  } | null;
  tablePresentation: {
    id: number;
    periodType: 'mtd' | 'ytd';
  } | null;
  kpiGoals?: {
    id: number;
    kpiKey: string;
    scorecardSectionId: number;
    value: number;
  }[];
  sectionType: 'scorecard';
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
  color: string | null;
};
export type ReportScoreboardDividerSectionDto = {
  id: number;
  sectionId: string;
  title: string;
  description: string | null;
  sectionType: 'divider';
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
  color: string | null;
};
export type HistoricalReviewTableWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    rows: {
      key: string;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    columns: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      initial_sort?: string;
      hidden?: boolean;
      hide_dates?: boolean;
      value_display_options?: ValueDisplayOptionsDto;
      font_weight?: string;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      align?: 'left' | 'center' | 'right';
    }[];
    column_groups: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
  };
  columns: {
    key: number;
    label: string;
    period_type:
      | ('day' | 'week' | 'month' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
  rows: {
    key: number;
    label: string;
    expression: string;
    adjustment_expression?: AdjustmentExpressionDto;
    value_display_options: ValueDisplayOptionsConfigDto;
  }[];
  default_options?: {
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type DashboardWidgetSectionDto = {
  id: string;
  widgetType:
    | 'historical_review_table'
    | 'unit_mix_table'
    | 'delinquency_tracker_table'
    | 'xy_chart'
    | 'kpi_table'
    | 'kpi_table_single_date';
  widgetConfig:
    | (
        | XyChartWidgetConfigDto
        | object
        | HistoricalReviewTableWidgetConfigDto
        | FinancialTableSingleDateWidgetConfigDto
        | FinancialTableSinglePeriodWidgetConfigDto
      )
    | null;
  defaultOptions: {
    [key: string]: any;
  } | null;
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
};
export type ReportDashboardBoardDto = {
  id: string;
  name: string;
  sections: (
    | ReportScoreboardScorecardSectionDto
    | ReportScoreboardDividerSectionDto
    | DashboardWidgetSectionDto
  )[];
};
export type FilterSetDto = {
  id: string;
  name: string;
  assets: {
    id: number;
    name: string;
    legalEntities: {
      id: string;
      name: string;
      classification: string;
    }[];
  }[];
  segments: {
    id: number;
    title: string;
    legalEntities: {
      id: string;
      name: string;
      classification: string;
    }[];
  }[];
  excludedLegalEntities: {
    id: string;
    name: string;
  }[];
  legalEntities: {
    id: string;
    name: string;
  }[];
};
export type KpiCardWidgetDto = {
  type: 'kpi_card';
  kpis: {
    key: number;
    label: string;
    valueDisplayOptions: ValueDisplayOptionsDto;
    value: number | null;
  }[];
};
export type GlobalLeaseTableWidgetDto = {
  type: 'global_lease_table';
  columns: {
    key: number;
    viz_config_key?: string;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    unit_id?: string;
    values: {
      key: number;
      value: (string | number | boolean) | null;
    }[];
  }[];
  filterOptions: {
    column?: string;
    options?: string[];
  }[];
  config: {
    viz_type?: string;
    viz_config?: {
      columns: {
        col_id: string;
        key:
          | 'tradeout'
          | 'prior_rent'
          | 'current_rent'
          | 'lease_type'
          | 'lease_status'
          | 'unit_type'
          | 'asset_name'
          | 'unit_label'
          | 'unit_sqft'
          | 'lease_signed_date'
          | 'lease_start'
          | 'lease_end'
          | 'term_months'
          | 'filtered_tradeout'
          | 'previous_lease_resident_name'
          | 'previous_lease_type'
          | 'previous_lease_start_date'
          | 'previous_lease_end_date';
        order: number;
        group_id?: string | null;
        column_group_show?: string;
        initial_sort?: string;
        hidden?: boolean;
        hide_dates?: boolean;
        value_display_options?: ValueDisplayOptionsDto;
        comparison?: {
          rule?:
            | 'negative_red_positive_green'
            | 'negative_red'
            | 'negative_green_positive_red';
          hide_negative_values?: boolean;
        };
        font_weight?: string;
        header?: {
          hide_title?: boolean;
          hide_subtitle?: boolean;
        };
        min_width?: number;
        align?: 'left' | 'center' | 'right';
      }[];
      column_groups: {
        group_id: string;
        header_name: string;
        icon: string | null;
        background: string;
        border_color: string;
        order: number;
      }[];
      auto_column?: VizConfigAutoColumnDto;
      header_background?: string;
    };
  };
  totalSize: number;
};
export type DashboardHistoricalReviewTableWidgetDto = {
  type: 'historical_review_table';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data: object[];
};
export type DashboardDelinquencyTrackerTableWidgetDto = {
  type: 'delinquency_tracker_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type RecentRentRollTableWidgetDto = {
  type: 'recent_rent_roll_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    values: {
      key: number;
      value: number | null;
    }[];
    label: string;
    path: string;
  }[];
  total: {
    values: {
      key: number;
      value: number | null;
    }[];
    path: string;
  };
  psf: {
    values: {
      key: number;
      value: number | null;
    }[];
    path: string;
  };
  latestParsings?: {
    legalEntityId: string;
    date: string;
  }[];
};
export type MasterUnitTableDto = {
  type: 'master_unit_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    label: string;
    unitId?: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  config?: {
    viz_type?: string;
    viz_config?: {
      columns: {
        col_id: string;
        key:
          | 'sqft'
          | 'source_unit_type_name'
          | 'unit_type_name'
          | 'leased_status'
          | 'occupied_status'
          | 'occupied_in_30_days_status'
          | 'occupied_in_60_days_status'
          | 'occupied_in_90_days_status'
          | 'current_lease_resident_name'
          | 'current_lease_move_in_date'
          | 'current_lease_end_date'
          | 'current_lease_move_out_date'
          | 'market_rent'
          | 'asking_rent'
          | 'current_lease_actual_rent'
          | 'current_lease_tradeout_rent_diff'
          | 'current_lease_rent_psf'
          | 'current_lease_balance'
          | 'current_lease_parking_fee'
          | 'current_lease_pet_fee'
          | 'current_lease_storage_fee'
          | 'current_lease_laundry_fee'
          | 'current_lease_utilities_fee'
          | 'current_lease_leasing_fee'
          | 'current_lease_penalty_fee'
          | 'current_lease_misc_fee'
          | 'current_lease_concessions_fee'
          | 'future_lease_resident_name'
          | 'future_lease_actual_rent'
          | 'future_lease_move_in_date';
        order: number;
        group_id?: string | null;
        column_group_show?: string;
        initial_sort?: string;
        hidden?: boolean;
        hide_dates?: boolean;
        value_display_options?: ValueDisplayOptionsDto;
        comparison?: {
          rule?:
            | 'negative_red_positive_green'
            | 'negative_red'
            | 'negative_green_positive_red';
          hide_negative_values?: boolean;
        };
        font_weight?: string;
        header?: {
          hide_title?: boolean;
          hide_subtitle?: boolean;
        };
        min_width?: number;
        align?: 'left' | 'center' | 'right';
      }[];
      column_groups: {
        group_id: string;
        header_name: string;
        icon: string | null;
        background: string;
        border_color: string;
        order: number;
      }[];
      auto_column?: VizConfigAutoColumnDto;
      header_background?: string;
    };
  };
  totalSize?: number;
  filterOptions?: {
    column?: string;
    values?: string[];
  }[];
};
export type UnitMixTableWidgetDto = {
  type: 'unit_mix_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type UnitMixSummaryTableWidgetDto = {
  type: 'unit_mix_summary_table';
  headerBackground: string | null;
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  };
};
export type AverageActualRentChartWidgetDto = {
  type: 'average_actual_rent_chart';
  items: {
    key: string;
    label: string;
    value: number;
    dateFrom: string;
    dateTo: string;
    type: 'unit_type' | 'source_unit_type' | 'average';
  }[];
};
export type DelinquencySnapshotColumnChartWidgetDto = {
  type: 'delinquency_snapshot_column_chart';
  columns: {
    label: string;
    '30days': number | null;
    delinquentBalance: number | null;
    percent: number | null;
  }[];
};
export type DelinquencyAverageColumnChartWidgetDto = {
  type: 'delinquency_average_column_chart';
  columns: {
    label: string;
    delinquentTenantsCount: number | null;
    delinquentBalance: number | null;
    averageDelinquentBalance: number | null;
  }[];
};
export type XyChartWidgetDto = {
  type: 'xy_chart';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
};
export type ObjectDashboardAssetDto = {
  id: number;
  name: string;
  pictureUrl: string | null;
  slug: string;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type ObjectDashboardDto = {
  id: string;
  name: string;
  slug: string;
  assets: ObjectDashboardAssetDto[];
  widgetSections: DashboardWidgetSectionDto[];
  excludedLegalEntities: {
    id: string;
  }[];
  reportBuilderTemplate: {
    id: string;
    name: string;
  } | null;
};
export type ReportScoreboardDto = {
  item: {
    id: string;
    name: string;
    assets: {
      id: number;
      name: string;
      legalEntities: {
        id: string;
        name: string;
      }[];
    }[];
    segments: {
      id: number;
      title: string;
      legalEntities: {
        id: string;
        name: string;
      }[];
    }[];
    excludedLegalEntities: {
      id?: string;
      name?: string;
    }[];
    boards?: {
      id: string;
      name: string;
      order: number;
      sections: (
        | ReportScoreboardScorecardSectionDto
        | ReportScoreboardDividerSectionDto
        | DashboardWidgetSectionDto
      )[];
    }[];
  };
  meta: {
    periods: string[];
    actualAt: number;
    assetStages: {
      id: number;
      name: string;
    }[];
  };
};

import React from 'react';
import { Icon, Input, ReturnableTag } from 'stories';
import AddItemFromList from 'bundles/Shared/components/AddItemFromList';
import { IUser } from 'types/User';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { useCustomer } from 'lib/customers';

interface Props {
  investmentEntity: IInvestmentEntity;
  setInvestmentEntity: (investmentEntity: IInvestmentEntity) => void;
  allUsers: IUser[];
  allVendorEntities: VendorEntity[];
}

const InvestmentEntityForm = ({
  investmentEntity,
  setInvestmentEntity,
  allUsers,
  allVendorEntities,
}: Props) => {
  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);

  const users = investmentEntity?.users || [];
  const setUsers = (newUsers) =>
    setInvestmentEntity({ ...investmentEntity, users: newUsers });

  const vendorEntities = investmentEntity?.vendorEntities || [];
  const setVendorEntities = (newVendorEntities) =>
    setInvestmentEntity({
      ...investmentEntity,
      vendorEntities: newVendorEntities,
    });

  const setInvestmentEntityAttribute = (attribute: string, value: any) => {
    setInvestmentEntity({ ...investmentEntity, [attribute]: value });
  };

  const onUserSelect = (user) =>
    users.some((u) => u.id === user.id)
      ? setUsers([...users].filter(({ id }) => id !== user.id))
      : setUsers([...users, user]);

  const onInvestmentEntitySelect = (vendor) =>
    vendorEntities.some((v) => v.id === vendor.id)
      ? setVendorEntities(
          [...vendorEntities].filter(({ id }) => id !== vendor.id),
        )
      : setVendorEntities([...vendorEntities, vendor]);

  return (
    <>
      <h6 className="header6-regular mb-tw-4">Basic</h6>
      <label htmlFor="le-name" className="mb-tw-2">
        Name <span className="red">*</span>
      </label>
      <Input
        onChange={(e) => setInvestmentEntityAttribute('name', e.target.value)}
        placeholder="Enter Entity Name"
        id="le-name"
        defaultValue={investmentEntity?.name || ''}
        className="mb-m"
      />

      <div className="mb-tw-2 flex flex-wrap justify-between">
        <h6 className="header6-regular text-neutral-900">Members</h6>
        <AddItemFromList
          className="cursor-pointer p-0"
          allItems={allUsers}
          selectedItems={users}
          onSelect={onUserSelect}
          mask="members"
          getMainFieldTitle={(vendor) => vendor.fullName}
          getSecondaryFieldTitle={(vendor) => vendor.role?.name}
        >
          <Icon iconName="add" />
        </AddItemFromList>
      </div>
      <div className="mb-tw-6 flex flex-wrap gap-tw-2">
        {users.map(({ id, fullName }) => (
          <ReturnableTag
            key={id}
            onExclude={() => setUsers(users.filter((user) => user.id !== id))}
            onInclude={() => {}}
            label={fullName}
            onClick={() => setUsers(users.filter((user) => user.id !== id))}
          />
        ))}
        {users.length === 0 && (
          <div className="label-regular w-full rounded-[8px] bg-light-5 p-tw-2 text-center text-neutral-450">
            No selected members yet
          </div>
        )}
      </div>

      {currentCustomerHasReportProduct && (
        <>
          <div className="mb-tw-2 flex justify-between">
            <h6 className="dark-60 header6-regular">Vendors</h6>
            <AddItemFromList
              className="p-0"
              allItems={allVendorEntities}
              selectedItems={vendorEntities}
              onSelect={onInvestmentEntitySelect}
              mask="vendors"
              getMainFieldTitle={(vendor) => vendor.name}
              getSecondaryFieldTitle={(vendor) => vendor.code}
            >
              <Icon iconName="add" />
            </AddItemFromList>
          </div>
          <div className="flex gap-s">
            {vendorEntities.map(({ id, name }) => (
              <ReturnableTag
                key={id}
                onExclude={() =>
                  setVendorEntities(
                    vendorEntities.filter((vendor) => vendor.id !== id),
                  )
                }
                onInclude={() => {}}
                label={name}
                onClick={() =>
                  setVendorEntities(
                    vendorEntities.filter((vendor) => vendor.id !== id),
                  )
                }
              />
            ))}
            {vendorEntities.length === 0 && (
              <div className="label-regular w-full rounded-[8px] bg-light-5 p-tw-2 text-center text-neutral-450">
                No selected vendors yet
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default InvestmentEntityForm;

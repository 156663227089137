import React from 'react';
import { useGetApiSettingsReportDashboardsByIdWithScreenParams } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  buildLayoutsId,
  DashboardHasChangesButton,
  DashboardIcon,
  DashboardSettingsToggle,
  DashboardStatusDropdown,
  ReportDashboardType,
  selectReportDashboardSettingsDirty,
  selectReportEagleEyeDashboardSettingsById,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import IconButton from 'stories/IconButton/IconButton';
import { Link, useNavigate } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useEditEagleEyeDashboard } from 'bundles/Shared/features/dashboard/update/eagleEye/lib';
import { DashboardLayout } from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { useUpdateDashboardStatus } from 'bundles/Shared/features/dashboard/updateStatus';
import { DashboardPermissionListLine } from 'bundles/Shared/features/dashboard/updatePermissions';
import { useDeleteDashboard } from 'bundles/Shared/features/dashboard/delete';
import { useDashboardUpdateLayout } from 'bundles/Shared/features/dashboard/sectionActions';

function SettingsReportDashboardHeader() {
  const { dashboardId, boardId } = useDashboardContext();
  const { dashboard } = useGetApiSettingsReportDashboardsByIdWithScreenParams();
  const dashboardSettings = useAppSelector((state) =>
    selectReportEagleEyeDashboardSettingsById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  );
  const navigate = useNavigate();
  const isDirty = useAppSelector(
    selectReportDashboardSettingsDirty({
      dashboardId,
      boardId,
    }),
  );
  const editDashboard = useEditEagleEyeDashboard();
  const moveLayout = useDashboardUpdateLayout();
  const [removeDashboard] = useDeleteDashboard(ReportDashboardType.EAGLE_EYE);
  const updateDashboardStatus = useUpdateDashboardStatus();
  const handleScoreboardUpdate = async () => {
    await moveLayout({
      boardId,
      layout: dashboardSettings!.layouts!.lg,
    });
  };

  const handleRemoveScoreboard = async () => {
    const res = await removeDashboard(dashboard.id);
    if (!res) return;
    navigate(ROUTES_ROOT.settings.report.dashboards.fullPath);
  };

  return (
    <DashboardLayout.Header className="h-auto">
      <DashboardLayout.Header.Title
        title={dashboard.name}
        subtitle={
          <>
            <DashboardIcon iconName="eagleEyeDashboard" />
            Eagle Eye
          </>
        }
      />

      <IconButton onClick={() => editDashboard(dashboard)} iconName="edit" />
      <DashboardPermissionListLine
        dashboardType={ReportDashboardType.EAGLE_EYE}
        permissions={dashboard.permitted}
        dashboardId={dashboard.id}
      />
      <IconButton onClick={handleRemoveScoreboard} iconName="trash" />
      <DashboardStatusDropdown
        onChange={(status) => {
          updateDashboardStatus({
            dashboardId: dashboard.id,
            status,
          });
        }}
        value={dashboard.status}
      />
      <div className="grow" />
      <DashboardHasChangesButton
        isDirty={isDirty}
        onClick={handleScoreboardUpdate}
      />
      <Link
        to={generateUrl(ROUTES_ROOT.scoreboards.scoreboard.fullPath, {
          pathParams: {
            slug: dashboard?.slug ?? '',
          },
          queryParams: {
            boardId,
          },
        })}
      >
        <DashboardSettingsToggle isActive />
      </Link>
    </DashboardLayout.Header>
  );
}

export default SettingsReportDashboardHeader;

import React from 'react';
import Select, {
  ClearIndicatorProps,
  components,
  GroupBase,
  Props as SelectProps,
} from 'react-select';
import { CssVar } from '@/shared/config/cssVar';
import CreatableSelect from 'react-select/creatable';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { Control, FieldValues, useController } from 'react-hook-form';
import { ListOption } from 'stories/Checkbox/CheckList';

export interface ISharedSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends SelectProps<Option, IsMulti, Group> {
  disabled?: boolean;
  newGen?: boolean;
  creatable?: boolean;
  value: IsMulti extends true ? Option[] : Option;
}

type ClearableProps = {
  isClearable?: boolean;
};

const NEW_STYLE = {
  minHeight: 32,
  height: 32,
  backgroundColor: 'white',
  borderColor: CssVar.neutral200,
  borderWidth: 2,
  borderRadius: 8,
  fontSize: 14,
};

export const SharedSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  disabled,
  newGen,
  creatable,
  ...props
}: ISharedSelectProps<Option, IsMulti, Group>) => {
  const SelectComponent = creatable ? CreatableSelect : Select;

  return (
    // TODO delete form-item classes
    <div className="form-item form-item__select w-full">
      <SelectComponent
        classNamePrefix={newGen ? 'new-gen-react-select' : 'react-select'}
        isDisabled={disabled}
        menuPlacement="bottom"
        menuPosition="fixed"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            ...(newGen ? NEW_STYLE : {}),
          }),
        }}
        components={{
          ClearIndicator: (
            compProps: ClearIndicatorProps<Option, IsMulti, Group>,
          ) => {
            return props.isClearable ? (
              <components.ClearIndicator {...compProps} />
            ) : null;
          },
          MultiValueRemove: (compProps) => {
            const { isClearable } = compProps.data as ClearableProps;
            return isClearable == null || isClearable ? (
              <components.MultiValueRemove {...compProps} />
            ) : null;
          },
        }}
        {...props}
      />
    </div>
  );
};

export const SharedSelectController = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  onChange,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & Omit<ISharedSelectProps<Option, IsMulti, Group>, 'value'>) => {
  const { field } = useController({ name, control });
  return (
    <SharedSelect
      value={
        props.options?.find((o) => (o as ListOption).value === field.value) ??
        null
      }
      onChange={(o, actionMeta) => {
        if (onChange) {
          onChange(o, actionMeta);
          return;
        }
        field.onChange(
          Array.isArray(o) ? Array.from(o.values()) : (o as ListOption).value,
        );
      }}
      {...props}
    />
  );
};

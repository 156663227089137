import { OBJECT_STATUSES_LABELS } from '@/lib/asset';
import { currentUserIsExternal } from 'lib/permissions';
import React from 'react';
import { Icon } from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import { IAssetStage } from 'types/AssetStage';

const primaryColor = '#0D1C3C';

export const BadgeSwitch = ({
  color,
  label,
  active,
  onClick,
  disabled,
}: {
  label: React.ReactNode;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  color?: React.CSSProperties['backgroundColor'];
  disabled?: boolean;
}) => {
  const bgColorStyle = { backgroundColor: color ?? primaryColor };

  return (
    <button
      type="button"
      className={cn(
        'border-1 label-semibold flex cursor-default items-center gap-[4.5px] rounded-[16px] border-solid border-neutral-100 bg-neutral-000 px-[8px] py-[3.5px] text-neutral-900 shadow-z-020 transition-colors disabled:bg-neutral-200',
        onClick && 'cursor-pointer hover:bg-neutral-100',
        active && 'border-info-055 bg-info-055 text-white hover:bg-info-060',
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {active ? (
        <Icon iconName="check" className="text-white" />
      ) : (
        <span className="h-[8px] w-[8px] rounded-full" style={bgColorStyle} />
      )}
      {label}
    </button>
  );
};

interface Props {
  selectedStages: IAssetStage['name'][];
  onChange: (stages: IAssetStage['name'][]) => void;
  displayedStages: IAssetStage[];
  multiple: boolean;
  disabled: boolean;
  required?: boolean;
}

export const StagesSwitch = ({
  selectedStages = [],
  onChange,
  displayedStages,
  multiple = false,
  required = false,
  disabled,
}: Props) => {
  const handleSelect = (name: IAssetStage['name']) => {
    let newStages;

    if (multiple) {
      newStages = selectedStages.includes(name)
        ? selectedStages.filter((s) => s !== name)
        : [...selectedStages, name];
    } else {
      newStages = selectedStages.includes(name) && !required ? null : name;
    }

    onChange(newStages);
  };

  return (
    <div className="flex flex-wrap gap-tw-1">
      {displayedStages.map(({ color, name }) => (
        <BadgeSwitch
          disabled={disabled}
          key={name}
          color={color}
          label={name}
          onClick={() => handleSelect(name)}
          active={selectedStages.includes(name)}
        />
      ))}
    </div>
  );
};

export const StatusesSwitch = ({
  selectedStatuses = [],
  onChange,
  required = false,
  disabled,
}: {
  selectedStatuses: string[];
  onChange: (statuses: string | null) => void;
  required?: boolean;
  disabled?: boolean;
}) => {
  const handleSelect = (key: string) => {
    onChange(selectedStatuses.includes(key) && !required ? null : key);
  };
  const filteredStatuses = () =>
    OBJECT_STATUSES_LABELS.filter(
      (st) => !currentUserIsExternal() || st.label !== 'Draft',
    );

  return (
    <div className="flex flex-wrap gap-tw-1">
      {filteredStatuses().map((status) => (
        <BadgeSwitch
          disabled={disabled}
          key={status.key}
          label={status.label}
          onClick={() => handleSelect(status.key)}
          active={selectedStatuses.includes(status.key)}
        />
      ))}
    </div>
  );
};

export default BadgeSwitch;

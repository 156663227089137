import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { IUserTag } from 'types/IUserTag';
import { IUser, IUserRole } from 'types/User';

import { Permitted } from 'bundles/Shared/entities/permissions';

export interface ISharedFilePermissions {
  directly_permitted_user_ids: IUser['id'][];
  directly_permitted_user_role_ids: IUserRole['id'][];
  directly_permitted_user_tag_ids: IUserTag['id'][];
  directly_permitted_investment_entity_ids: IInvestmentEntity['id'][];
  public_permission_attributes: {
    _destroy?: boolean;
  };
}

const PUBLIC_SHARED_FILE_PERMISSIONS = {
  directly_permitted_user_ids: [],
  directly_permitted_user_role_ids: [],
  directly_permitted_user_tag_ids: [],
  directly_permitted_investment_entity_ids: [],
  public_permission_attributes: {},
} satisfies ISharedFilePermissions;

export type SharedFilePermittedState = {
  public: boolean;
  users: IUser[];
  roles: IUserRole[];
  tags: IUserTag[];
  investmentEntities: IInvestmentEntity[];
};

export const sharedFilePermissions = (
  permissions: SharedFilePermittedState,
): ISharedFilePermissions =>
  permissions.public === true
    ? PUBLIC_SHARED_FILE_PERMISSIONS
    : {
        directly_permitted_user_ids: permissions.users.map((user) => user.id),
        directly_permitted_user_role_ids: permissions.roles.map(
          (role) => role.id,
        ),
        directly_permitted_user_tag_ids: permissions.tags.map((tag) => tag.id),
        directly_permitted_investment_entity_ids:
          permissions.investmentEntities.map((ie) => ie.id),
        public_permission_attributes: { _destroy: true },
      };

export const typedSharedFilePermissions = (
  perms: Permitted,
): ISharedFilePermissions =>
  perms.isPublic === true
    ? PUBLIC_SHARED_FILE_PERMISSIONS
    : {
        directly_permitted_user_ids: perms.directUsers.map((user) => user.id),
        directly_permitted_user_role_ids: perms.directRoles.map(
          (role) => role.id,
        ),
        directly_permitted_user_tag_ids: perms.directTags.map((tag) => tag.id),
        directly_permitted_investment_entity_ids:
          perms.directInvestmentEntities.map((ie) => ie.id),
        public_permission_attributes: { _destroy: true },
      };

import React, { FC, ReactNode } from 'react';
import { Dropdown, DropdownItem } from 'stories';
import { cn } from '@/shared/lib/css/cn';

export enum EventStatusKey {
  'Pending — Not Proceeding',
  'Pending — Proceeding',
  'Draft',
  'Approved',
  'Completed',
  'Rejected',
  'Disputed',
  'Void',
}

export type TStatusOption = {
  key: EventStatusKey | string;
  label: string;
  color: string;
};

interface IStatusOptionsDropdownProps {
  classes?: {
    itemWrapperActive?: string;
    item: string;
    circle?: string;
    itemWrapper?: string;
    dropdown?: string;
  };
  selected: TStatusOption;
  options: TStatusOption[];
  handleItemClick: (st: TStatusOption) => void;
  children: ReactNode;
}

const StatusOptionsDropdown: FC<IStatusOptionsDropdownProps> = ({
  classes,
  selected,
  handleItemClick,
  children,
  options,
}) => {
  return (
    <Dropdown
      items={
        <>
          {options.map((o) => (
            <DropdownItem
              key={o.key}
              active={selected.key === o.key}
              onClick={() => handleItemClick(o)}
              className={classes?.item}
              activeClassName={classes?.itemWrapperActive}
            >
              <div className={classes?.itemWrapper}>
                <div className="inline-regular">{o.label}</div>
                <div className={cn(classes.circle, `bg-${o.color}`)} />
              </div>
            </DropdownItem>
          ))}
        </>
      }
      className={classes?.dropdown}
      maxWidth="25rem"
      hideOnExpandedAreaClick
    >
      {children}
    </Dropdown>
  );
};

export default StatusOptionsDropdown;

import {
  plainCreateReportTableSegment,
  plainFetchLegalEntities,
  plainUpdateReportTableSegment,
} from 'bundles/Settings/actions/report_table_segments';

import FilterBar from 'bundles/Shared/components/Filters/common/filterBar/FilterBar';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  OverlaySpinner,
  Textarea,
  Tumbler,
} from 'stories';
import { isEqual, sortBy } from 'lodash-es';

import { InvestmentObjectWithLegalEntitiesSelect } from 'bundles/Shared/components/Filters/assets/investmentObjectWithLegalEntitiesSelect/investmentObjectWithLegalEntitiesSelect';
import {
  ILegalEntitiableWithLegalEntities,
  ILegalEntity,
  TLegalEntitiableType,
} from 'types/ReportTableSegment';
import { getLegalEntitiables } from './utils/getLegalEntitiables';
import styles from './FilterLegalEntitiable/FilterLegalEntitiable.module.scss';
import { FilterLegalEntitiable } from './FilterLegalEntitiable/FilterLegalEntitiable';
import { getLegalEntityIds } from './utils/getLegalEntityIds';
import {
  EntitiablesLabelJSX,
  SegmentFormModalBottomJSX,
  SegmentFormModalTopJSX,
} from './utils/dumbJSX';
import { hasEntitiableWithoutEntities } from './utils/hasEntitiableWithoutEntities';
import { useItems } from '@/shared/lib/hooks/items/useItems';
import { exists, mustFind } from 'lib/typeHelpers';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  segment?: ReportSegment;
}

export enum SEGMENT_FORM_MODAL_TEST_IDS {
  submit = 'submit-button',
  titleInput = 'title-input',
  searchEntitiesInput = 'search-entities-input',
}

const SegmentFormModal = ({ segment, onClose, onSubmit }: Props) => {
  const {
    assets: initialSelectedAssets,
    funds: initialSelecteFunds,
    legalEntitiables: initialLegalEntitiables,
  } = exists(segment)
    ? getLegalEntitiables(segment.legalEntities)
    : ({
        assets: [],
        funds: [],
        legalEntitiables: [],
        legalEntitiesFromAsset: [],
        legalEntitiesFromFund: [],
      } satisfies ReturnType<typeof getLegalEntitiables>);

  const [formData, setFormData] = useState({
    title: segment?.title ?? '',
    reason: segment?.reason ?? '',
    published: segment?.state === 'published' ?? false,
  });

  const [legalEntities, setLegalEntities] = useState<ILegalEntity[]>([]);

  const {
    assets: allAssets,
    funds: allFunds,
    legalEntitiesFromAsset,
    legalEntitiesFromFund,
  } = useMemo(() => getLegalEntitiables(legalEntities), [legalEntities]);

  const [uploading, setUploading] = useState(false);

  const [
    selectedAssets,
    setSelectedAssets,
    { onRemoveItemById: unselectAssetById, onReset: resetAssets },
  ] = useItems(initialSelectedAssets);

  const [
    selectedFunds,
    setSelectedFunds,
    { onRemoveItemById: unselectFundById, onReset: resetFunds },
  ] = useItems(initialSelecteFunds);

  useEffect(() => {
    const reloadInvestmentObjects = async () => {
      const { items } = await plainFetchLegalEntities();
      setLegalEntities(items);
    };
    reloadInvestmentObjects();
  }, []);

  const handleSelectLegalEntity = (
    legalEntitiableId: ILegalEntitiableWithLegalEntities['id'],
    type: TLegalEntitiableType,
    newLegalEntityIds: string[],
  ) => {
    const setItems = type === 'Asset' ? setSelectedAssets : setSelectedFunds;

    const newLegalEntities = newLegalEntityIds.map((id) =>
      mustFind(legalEntities, (entity) => entity.id === id),
    );

    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id !== legalEntitiableId) return item;
        return {
          ...item,
          legalEntities: newLegalEntities,
        } satisfies ILegalEntitiableWithLegalEntities;
      }),
    );
  };

  const isEntitialbeUpdated = (entitiableId: number, currentIds: string[]) => {
    const entitiable = initialLegalEntitiables.find(
      ({ id }) => id === entitiableId,
    );
    if (!entitiable) return false;

    const initialIds = entitiable.legalEntities.map(({ id }) => id).sort();
    const sortedCurrentIds = [...currentIds].sort();

    return !isEqual(initialIds, sortedCurrentIds);
  };

  const removeLegalEntitiablesByType = (type: TLegalEntitiableType) => {
    const reserAction = type === 'Asset' ? resetAssets : resetFunds;
    reserAction();
  };

  const onRemoveEntitiable = (
    legalEntitiable: ILegalEntitiableWithLegalEntities,
  ) => {
    const unselectById =
      legalEntitiable.type === 'Asset' ? unselectAssetById : unselectFundById;
    unselectById(legalEntitiable.id);
  };

  const isSuccess = (data) => !data?.errors;
  const buttonName = exists(segment) ? 'Update' : 'Submit';

  const changeFormData = (fieldName: string, value: string | boolean) => {
    const newFormData = { ...formData, [fieldName]: value };
    setFormData(newFormData);
  };

  type HandleChangePrimitiveValue = React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  const handleChangePrimitiveValue: HandleChangePrimitiveValue = ({
    target,
  }) => {
    changeFormData(target.name, target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setUploading(true);
    const allSelectedLegalEntitiables = [...selectedAssets, ...selectedFunds];
    const payload = {
      ...formData,
      legal_entity_ids: getLegalEntityIds(allSelectedLegalEntitiables),
    };
    const result = exists(segment)
      ? await plainUpdateReportTableSegment(segment.id, payload)
      : await plainCreateReportTableSegment(payload);
    if (isSuccess(result)) {
      onSubmit();
    }
    setUploading(false);
  };

  const resolveSubmitBtnEnabled = () => {
    const allSelectedLegalEntitiables = [...selectedAssets, ...selectedFunds];
    if (uploading) return false;
    if (!formData.title) return false;
    if (!allSelectedLegalEntitiables.length) return false;
    if (hasEntitiableWithoutEntities(allSelectedLegalEntitiables)) return false;
    return true;
  };

  const selectedAllEntitiables = [
    {
      selectedEntitiables: selectedAssets,
      entitialbeTitle: 'Assets',
      legalEntitiesByType: legalEntitiesFromAsset,
      type: 'Asset',
    },
    {
      selectedEntitiables: selectedFunds,
      entitialbeTitle: 'Funds',
      legalEntitiesByType: legalEntitiesFromFund,
      type: 'Fund',
    },
  ] as const;

  const header = (
    <h5 className="font-bold">
      {exists(segment) ? 'Update Segment' : 'Adding New Segment'}
    </h5>
  );

  const submitButtonLabel = uploading ? (
    <>
      <OverlaySpinner size="small" inline /> Loading...
    </>
  ) : (
    buttonName
  );

  const actions = (
    <div className="flex w-full justify-between">
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        data-testid={SEGMENT_FORM_MODAL_TEST_IDS.submit}
        disabled={!resolveSubmitBtnEnabled()}
        variant="success"
        form="reportTableSegment"
        type="submit"
      >
        {submitButtonLabel}
      </Button>
    </div>
  );

  return (
    <Modal
      toggle={onClose}
      header={header}
      actions={actions}
      size="lg"
      classes={{ body: 'bg-white' }}
    >
      <form id="reportTableSegment" onSubmit={handleSubmit}>
        <div className="mb-tw-4">
          {SegmentFormModalTopJSX}
          <Input
            onChange={handleChangePrimitiveValue}
            placeholder="Enter Segment Name"
            className="input-light"
            data-testid={SEGMENT_FORM_MODAL_TEST_IDS.titleInput}
            name="title"
            value={formData.title}
            size="l"
          />
          <label className="mb-s mt-m">Reason</label>
          <Textarea
            onChange={handleChangePrimitiveValue}
            name="reason"
            placeholder="Describe why this segment is needed?"
            value={formData.reason}
          />
          <div className="mt-s">
            <label htmlFor="status-tumbler">Status</label>
            <div className="my-s">
              <Tumbler
                name="published"
                id="status-tumbler"
                onChange={({ target }) =>
                  changeFormData(target.name, target.checked)
                }
                checked={formData.published}
              >
                Published
              </Tumbler>
            </div>
          </div>
          <hr className="mt-xs" />
        </div>
        <div className="mb-tw-4">
          {EntitiablesLabelJSX}

          <InvestmentObjectWithLegalEntitiesSelect
            searchInputDataTestId={
              SEGMENT_FORM_MODAL_TEST_IDS.searchEntitiesInput
            }
            assets={allAssets}
            funds={allFunds}
            selectedAssets={selectedAssets}
            selectedFunds={selectedFunds}
            setSelectedAssets={setSelectedAssets}
            setSelectedFunds={setSelectedFunds}
          />
          {selectedAllEntitiables.map(
            ({
              selectedEntitiables,
              entitialbeTitle,
              type,
              legalEntitiesByType,
            }) =>
              selectedEntitiables.length > 0 && (
                <FilterBar
                  key={type}
                  className="my-tw-2 bg-light-5"
                  onClose={() => removeLegalEntitiablesByType(type)}
                  title={entitialbeTitle}
                >
                  {sortBy(selectedEntitiables, 'id').map((legalEntitiable) => (
                    <FilterLegalEntitiable
                      key={legalEntitiable.id}
                      onClose={() => onRemoveEntitiable(legalEntitiable)}
                      label={legalEntitiable.name}
                      className={styles.filterItem}
                      legalEntitiable={legalEntitiable}
                      legalEntities={legalEntitiesByType}
                      handleSelectLegalEntity={handleSelectLegalEntity}
                      isEntitialbeUpdated={isEntitialbeUpdated}
                    />
                  ))}
                </FilterBar>
              ),
          )}
          {SegmentFormModalBottomJSX}
        </div>
      </form>
    </Modal>
  );
};

export default SegmentFormModal;

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { TRootState } from '@/app/stores';
import { formatToDateStringForRequest as formatToDateString } from '@/shared/lib/converters';

interface InitialState {
  fromDate: DateString;
  toDate: DateString;
  roleIds?: string[];
}
const initialState: InitialState = {
  fromDate: formatToDateString(dayjs().subtract(6, 'day').startOf('day')),
  toDate: formatToDateString(dayjs().startOf('day')),
  roleIds: [],
};

const userActivitySlice = createSlice({
  name: 'userActivity',
  initialState,
  reducers: {
    setUserActivityChangingDate: (
      state,
      action: {
        payload: Pick<InitialState, 'fromDate' | 'toDate'>;
      },
    ) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    setUserActivityFilterRoles: (
      state,
      action: {
        payload: string[];
      },
    ) => {
      state.roleIds = action.payload;
    },
  },
});

export const { setUserActivityChangingDate, setUserActivityFilterRoles } =
  userActivitySlice.actions;

export const selectUserActivitySummary = (state: TRootState) =>
  state.userActivity;

export default userActivitySlice.reducer;

import { useModal } from '@/shared/lib/hooks/useModal';
import React from 'react';
import { Button, Dropdown, DropdownItem } from 'stories';
import {
  ImportREturnModal,
  IMPORT_RETURN_BUTTONS,
  IMPORT_RETURN_TEMPLATES,
} from '../';

interface Props {
  refetch: () => void;
}

export function ExportREturnButton({ refetch }: Props) {
  const { openModal } = useModal();
  const downloadTemplateDropdownItems = IMPORT_RETURN_TEMPLATES.map(
    (template) => (
      <DropdownItem className="w-full" onClick={() => {}} key={template.label}>
        <a href={template.href} download={template.download}>
          <div className="flex gap-xs">{template.label}</div>
        </a>
      </DropdownItem>
    ),
  );

  const importDropdownItems = IMPORT_RETURN_BUTTONS.map((button) => (
    <DropdownItem
      key={button.type}
      onClick={() =>
        openModal(ImportREturnModal, {
          refetch,
          importType: button.type,
        })
      }
      className="w-full"
    >
      <div className="flex gap-xs">{button.label}</div>
    </DropdownItem>
  ));

  return (
    <Dropdown
      maxWidth={400}
      items={
        <>
          {downloadTemplateDropdownItems}
          {importDropdownItems}
        </>
      }
    >
      <Button
        variant="secondary"
        size="s"
        iconPosition="right"
        iconName="bottom"
      >
        REturn Import
      </Button>
    </Dropdown>
  );
}

import { treeDF } from 'lib/treeHelpers';
import { useMemo } from 'react';
import { IRow, isRowCategory, TRow, isRowGl } from '../../types';

export function useReportTableRowData(rows: TRow[]) {
  return useMemo<IRow[]>(() => {
    const data: IRow[] = [];

    treeDF(rows, (row, path) => {
      const children: IRow[] = isRowGl(row) ? [...(row?.children ?? [])] : [];
      const rowCopy = { ...row, children };
      data.push({
        ...rowCopy,
        path: path.map((p) => p.key),
      });
      if (isRowCategory(rowCopy) && rowCopy.children.length > 0) {
        rowCopy.children.push({
          type: 'totals',
          label: `Totals ${rowCopy.label}`,
          data: rowCopy.data,
          key: `${rowCopy.key}-TOTALS`,
          display: {},
        });
      }
    });
    return data;
  }, [rows]);
}

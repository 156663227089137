import React, { FC, Dispatch, SetStateAction } from 'react';
import FormTumbler from 'bundles/Shared/components/GroupForm/FormItems/FormTumbler';
import FormFiles from './FormItems/files/FormFiles';
import FormDate from './FormItems/FormDate';
import FormInput from './FormItems/FormInput';
import FormInputNumber from './FormItems/FormInputNumber';
import FormOptionalSection from './FormItems/FormOptionalSection';
import FormSelect from './FormItems/FormSelect';
import FormTextArea from './FormItems/FormTextArea';
import FormSelectedButtons from './FormItems/selected-buttons/FormSelectedButtons';
import { FieldType, IFormItem } from './types/types';
import FormCustom from './FormItems/FormCustom';
import FormListSelect from './FormItems/FormListSelect';
import FormTreeSelect from './FormItems/FormTreeSelect';
import FormCreatableSelect from 'bundles/Shared/components/GroupForm/FormItems/FormCreatableSelect';

interface Props {
  formItem: IFormItem;
  value: any;
  formData: object;
  setFormData: Dispatch<SetStateAction<object>>;
  loading: string;
  validate: object;
  setValidate: SetStateAction<object>;
}

const FormSwitcher: FC<Props> = ({
  formItem,
  value,
  formData,
  setFormData,
  loading,
  validate,
  setValidate,
}) => {
  const configFormItem = {
    ...formItem,
    value,
    formData,
    setFormData,
    loading,
    validate,
    setValidate,
  };
  switch (formItem.type) {
    case FieldType.INPUT_TEXT:
      return <FormInput {...configFormItem} />;

    case FieldType.INPUT_TEXTAREA:
      return <FormTextArea {...configFormItem} />;

    case FieldType.INPUT_NUMBER:
      return <FormInputNumber {...configFormItem} />;

    case FieldType.INPUT_DATE:
      return <FormDate {...configFormItem} />;

    case FieldType.LIST_SELECT:
      return <FormListSelect {...configFormItem} />;

    case FieldType.SELECT:
      return <FormSelect {...configFormItem} />;

    case FieldType.TREE_SELECT:
      return <FormTreeSelect {...configFormItem} />;

    case FieldType.CREATABLE_SELECT:
      return <FormCreatableSelect {...configFormItem} />;

    case FieldType.FILES:
      return <FormFiles {...configFormItem} />;

    case FieldType.OPTIONAL_SECTION:
      return <FormOptionalSection {...configFormItem} />;

    case FieldType.SELECTED_BUTTONS:
      return <FormSelectedButtons {...configFormItem} />;

    case FieldType.TUMBLER:
      return <FormTumbler {...configFormItem} />;

    case FieldType.CUSTOM:
      return <FormCustom {...configFormItem} />;
    default:
      return <div>Unknow type of item</div>;
  }
};
export default FormSwitcher;

export const enum CssVar {
  attention100 = 'var(--attention-100)',
  attention000 = 'var(--attention-000)',
  attention020 = 'var(--attention-020)',
  attention030 = 'var(--attention-030)',
  attention040 = 'var(--attention-040)',
  attention050 = 'var(--attention-050)',
  attention055 = 'var(--attention-055)',
  attention060 = 'var(--attention-060)',
  attention070 = 'var(--attention-070)',
  attention080 = 'var(--attention-080)',
  attention090 = 'var(--attention-090)',

  danger100 = 'var(--danger-100)',
  danger000 = 'var(--danger-000)',
  danger020 = 'var(--danger-020)',
  danger030 = 'var(--danger-030)',
  danger040 = 'var(--danger-040)',
  danger050 = 'var(--danger-050)',
  danger055 = 'var(--danger-055)',
  danger060 = 'var(--danger-060)',
  danger070 = 'var(--danger-070)',
  danger080 = 'var(--danger-080)',
  danger090 = 'var(--danger-090)',

  info100 = 'var(--info-100)',
  info000 = 'var(--info-000)',
  info020 = 'var(--info-020)',
  info030 = 'var(--info-030)',
  info040 = 'var(--info-040)',
  info050 = 'var(--info-050)',
  info055 = 'var(--info-055)',
  info060 = 'var(--info-060)',
  info070 = 'var(--info-070)',
  info080 = 'var(--info-080)',
  info090 = 'var(--info-090)',

  neutral100 = 'var(--neutral-100)',
  neutral150 = 'var(--neutral-150)',
  neutral200 = 'var(--neutral-200)',
  neutral250 = 'var(--neutral-250)',
  neutral300 = 'var(--neutral-300)',
  neutral400 = 'var(--neutral-400)',
  neutral450 = 'var(--neutral-450)',
  neutral500 = 'var(--neutral-500)',
  neutral550 = 'var(--neutral-550)',
  neutral600 = 'var(--neutral-600)',
  neutral650 = 'var(--neutral-650)',
  neutral700 = 'var(--neutral-700)',
  neutral750 = 'var(--neutral-750)',
  neutral800 = 'var(--neutral-800)',
  neutral850 = 'var(--neutral-850)',
  neutral900 = 'var(--neutral-900)',
  neutral950 = 'var(--neutral-950)',
  neutral999 = 'var(--neutral-999)',
  'neutral999/020' = 'var(--neutral-999-020)',
  neutral000 = 'var(--neutral-000)',
  neutral050 = 'var(--neutral-050)',

  primary100 = 'var(--primary-100)',
  primary000 = 'var(--primary-000)',
  primary020 = 'var(--primary-020)',
  primary030 = 'var(--primary-030)',
  primary040 = 'var(--primary-040)',
  primary050 = 'var(--primary-050)',
  primary055 = 'var(--primary-055)',
  primary060 = 'var(--primary-060)',
  primary070 = 'var(--primary-070)',
  primary080 = 'var(--primary-080)',
  primary090 = 'var(--primary-090)',

  pumpkin100 = 'var(--pumpkin-100)',
  pumpkin000 = 'var(--pumpkin-000)',
  pumpkin020 = 'var(--pumpkin-020)',
  pumpkin030 = 'var(--pumpkin-030)',
  pumpkin040 = 'var(--pumpkin-040)',
  pumpkin050 = 'var(--pumpkin-050)',
  pumpkin055 = 'var(--pumpkin-055)',
  pumpkin060 = 'var(--pumpkin-060)',
  pumpkin070 = 'var(--pumpkin-070)',
  pumpkin080 = 'var(--pumpkin-080)',
  pumpkin090 = 'var(--pumpkin-090)',

  success100 = 'var(--success-100)',
  success000 = 'var(--success-000)',
  success020 = 'var(--success-020)',
  success030 = 'var(--success-030)',
  success040 = 'var(--success-040)',
  success050 = 'var(--success-050)',
  success055 = 'var(--success-055)',
  success060 = 'var(--success-060)',
  success070 = 'var(--success-070)',
  success080 = 'var(--success-080)',
  success090 = 'var(--success-090)',

  violet100 = 'var(--violet-100)',
  violet000 = 'var(--violet-000)',
  violet020 = 'var(--violet-020)',
  violet030 = 'var(--violet-030)',
  violet040 = 'var(--violet-040)',
  violet050 = 'var(--violet-050)',
  violet055 = 'var(--violet-055)',
  violet060 = 'var(--violet-060)',
  violet070 = 'var(--violet-070)',
  violet080 = 'var(--violet-080)',
  violet090 = 'var(--violet-090)',

  indigo100 = 'var(--indigo-100)',
  indigo000 = 'var(--indigo-000)',
  indigo020 = 'var(--indigo-020)',
  indigo030 = 'var(--indigo-030)',
  indigo040 = 'var(--indigo-040)',
  indigo050 = 'var(--indigo-050)',
  indigo055 = 'var(--indigo-055)',
  indigo060 = 'var(--indigo-060)',
  indigo070 = 'var(--indigo-070)',
  indigo080 = 'var(--indigo-080)',
  indigo090 = 'var(--indigo-090)',

  teal000 = 'var(--teal-000)',
  teal020 = 'var(--teal-020)',
  teal030 = 'var(--teal-030)',
  teal040 = 'var(--teal-040)',
  teal050 = 'var(--teal-050)',
  teal055 = 'var(--teal-055)',
  teal060 = 'var(--teal-060)',
  teal070 = 'var(--teal-070)',
  teal080 = 'var(--teal-080)',
  teal090 = 'var(--teal-090)',
  teal100 = 'var(--teal-100)',

  pink000 = 'var(--pink-000)',
  pink100 = 'var(--pink-100)',
  pink020 = 'var(--pink-020)',
  pink030 = 'var(--pink-030)',
  pink040 = 'var(--pink-040)',
  pink050 = 'var(--pink-050)',
  pink055 = 'var(--pink-055)',
  pink060 = 'var(--pink-060)',
  pink070 = 'var(--pink-070)',
  pink080 = 'var(--pink-080)',

  white = 'var(--white)',
  black = 'var(--black)',
  light10 = 'var(--light-10)',
  light = 'var(--light)',
  light5 = 'var(--light-5)',
  light60 = 'var(--light-60)',
  light50 = 'var(--light-50)',
  light40 = 'var(--light-40)',
  light70 = 'var(--light-70)',
  light80 = 'var(--light-80)',
  light15 = 'var(--light-15)',
  light90 = 'var(--light-90)',
  light20 = 'var(--light-20)',
  light30 = 'var(--light-30)',
  dark = 'var(--dark)',
  dark20 = 'var(--dark-20)',
  dark40 = 'var(--dark-40)',
  dark60 = 'var(--dark-60)',
  dark80 = 'var(--dark-80)',
  dark100 = 'var(--dark-100)',
  primaryLight5 = 'var(--primary-light-5)',
  primaryLight3 = 'var(--primary-light-3)',
  primaryLight2 = 'var(--primary-light-2)',
  primaryLight1 = 'var(--primary-light-1)',
  primary = 'var(--tw-primary)',
  primaryDark1 = 'var(--primary-dark-1)',
  primaryLight4 = 'var(--primary-light-4)',
  primaryDark2 = 'var(--primary-dark-2)',
  primaryDark3 = 'var(--primary-dark-3)',
  primaryDark4 = 'var(--primary-dark-4)',
  primaryDark5 = 'var(--primary-dark-5)',
  blueLight5 = 'var(--blue-light-5)',
  blueLight4 = 'var(--blue-light-4)',
  blueLight3 = 'var(--blue-light-3)',
  blueLight2 = 'var(--blue-light-2)',
  blueLight1 = 'var(--blue-light-1)',
  blue = 'var(--bl)',
  blueDark1 = 'var(--blue-dark-1)',
  blueDark2 = 'var(--blue-dark-2)',
  blueDark3 = 'var(--blue-dark-3)',
  blueDark4 = 'var(--blue-dark-4)',
  blueDark5 = 'var(--blue-dark-5)',
  greenLight5 = 'var(--green-light-5)',
  greenLight4 = 'var(--green-light-4)',
  greenLight3 = 'var(--green-light-3)',
  greenLight2 = 'var(--green-light-2)',
  greenLight1 = 'var(--green-light-1)',
  green = 'var(--gr)',
  greenDark1 = 'var(--green-dark-1)',
  greenDark2 = 'var(--green-dark-2)',
  greenDark3 = 'var(--green-dark-3)',
  greenDark4 = 'var(--green-dark-4)',
  greenDark5 = 'var(--green-dark-5)',
  redLight5 = 'var(--red-light-5)',
  redLight4 = 'var(--red-light-4)',
  redLight3 = 'var(--red-light-3)',
  redLight2 = 'var(--red-light-2)',
  redLight1 = 'var(--red-light-1)',
  red = 'var(--red)',
  redDark1 = 'var(--red-dark-1)',
  redDark2 = 'var(--red-dark-2)',
  redDark3 = 'var(--red-dark-3)',
  redDark4 = 'var(--red-dark-4)',
  redDark5 = 'var(--red-dark-5)',
  teal = 'var(--teal)',
  yellowLight5 = 'var(--yellow-light-5)',
  yellowLight4 = 'var(--yellow-light-4)',
  yellowLight3 = 'var(--yellow-light-3)',
  yellowLight2 = 'var(--yellow-light-2)',
  yellowLight1 = 'var(--yellow-light-1)',
  yellow = 'var(--ye)',
  yellowDark1 = 'var(--yellow-dark-1)',
  yellowDark2 = 'var(--yellow-dark-2)',
  yellowDark3 = 'var(--yellow-dark-3)',
  yellowDark4 = 'var(--yellow-dark-4)',
  yellowDark5 = 'var(--yellow-dark-5)',
  violetDark5 = 'var(--violet-dark-5)',
  violetDark3 = 'var(--violet-dark-3)',
  violetDark2 = 'var(--violet-dark-2)',
  violetDark4 = 'var(--violet-dark-4)',
  violetLight2 = 'var(--violet-light-2)',
  violetLight1 = 'var(--violet-light-1)',
  violetLight3 = 'var(--violet-light-3)',
  violetLight4 = 'var(--violet-light-4)',
  violet = 'var(--violet)',
  violetLight5 = 'var(--violet-light-5)',
  violetDark1 = 'var(--violet-dark-1)',
  pumpkinDark5 = 'var(--pumpkin-dark-5)',
  pumpkinDark3 = 'var(--pumpkin-dark-3)',
  pumpkinDark4 = 'var(--pumpkin-dark-4)',
  pumpkinLight2 = 'var(--pumpkin-light-2)',
  pumpkinLight1 = 'var(--pumpkin-light-1)',
  pumpkinLight3 = 'var(--pumpkin-light-3)',
  pumpkinLight4 = 'var(--pumpkin-light-4)',
  pumpkinLight5 = 'var(--pumpkin-light-5)',
  pumpkinDark2 = 'var(--pumpkin-dark-2)',
  pumpkin = 'var(--pumpkin)',
  pumpkinDark1 = 'var(--pumpkin-dark-1)',
  spacingXXS = 'var(--spacing-xxs)',
  spacingXS = 'var(--spacing-xs)',
  spacingS = 'var(--spacing-s)',
  spacingM = 'var(--spacing-m)',
  spacingL = 'var(--spacing-l)',
  spacingXL = 'var(--spacing-xl)',
  spacingXXL = 'var(--spacing-xxl)',
  spacingXXL2 = 'var(--spacing-xxl-2)',
  spacingXXL3 = 'var(--spacing-xxl-3)',
  twPX = 'var(--tw-px)',
  'tw0.5' = 'var(--tw-0-5)',
  tw1 = 'var(--tw-1)',
  'tw1.5' = 'var(--tw-1-5)',
  tw2 = 'var(--tw-2)',
  'tw2.5' = 'var(--tw-2-5)',
  tw3 = 'var(--tw-3)',
  'tw3.5' = 'var(--tw-3-5)',
  tw4 = 'var(--tw-4)',
  'tw-4.5' = 'var(--tw-4-5)',
  tw5 = 'var(--tw-5)',
  tw6 = 'var(--tw-6)',
  tw7 = 'var(--tw-7)',
  tw8 = 'var(--tw-8)',
  tw9 = 'var(--tw-9)',
  'tw9.5' = 'var(--tw-9-5)',
  tw10 = 'var(--tw-10)',
  tw11 = 'var(--tw-11)',
  tw12 = 'var(--tw-12)',
  tw14 = 'var(--tw-14)',
  tw16 = 'var(--tw-16)',
  tw18 = 'var(--tw-18)',
  tw20 = 'var(--tw-20)',
  shadow = 'var(--shadow)',
  shadowSHover = 'var(--shadow-s-hover)',
}

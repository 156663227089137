import {
  TBudgetActivityLogEvents,
  TEventTypes,
} from 'bundles/REconcile/types/ActivityLog';
import { assertUnreachable } from 'types/utils';
import React from 'react';
import { IconsId } from 'types/sre-icons';

export const getActivityLogEventIconParams = (
  eventType: TEventTypes,
): {
  iconName: IconsId;
  iconColor: string;
} => {
  switch (eventType) {
    case TEventTypes.BUDGET_LINE_ITEM_ADDED:
    case TEventTypes.REQUISITION_ADDED:
      return {
        iconColor: 'green',
        iconName: 'nodeClose',
      };
    case TEventTypes.REQUISITION_TRANSACTION_CATEGORY_CHANGED:
    case TEventTypes.BUDGET_PUBLISHED:
    case TEventTypes.BUDGET_LINE_ITEM_VALUE_CHANGED:
      return {
        iconColor: 'text-light-60',
        iconName: 'edit',
      };
    case TEventTypes.REQUISITION_REMOVED:
    case TEventTypes.BUDGET_LINE_ITEM_REMOVED:
      return {
        iconColor: 'text-light-60',
        iconName: 'trash',
      };
    case TEventTypes.BUDGET_CREATED:
      return {
        iconColor: 'text-bootstrap-blue',
        iconName: 'addSmall',
      };
    case TEventTypes.COMMENT_ADDED_TO_REQUISITION_CATEGORY:
    case TEventTypes.REPLY_ADDED_TO_REQUISITION_CATEGORY_COMMENT:
      return {
        iconColor: 'text-light-60',
        iconName: 'comments',
      };
    case TEventTypes.REQUISITION_TRANSACTION_LINKED_TO_CATEGORY:
    case TEventTypes.REQUISITION_TRANSACTION_UNLINKED_FROM_CATEGORY:
      return {
        iconColor: 'text-bootstrap-blue',
        iconName: 'link',
      };
    default:
      return assertUnreachable(eventType);
    // return 'none';
  }
};

const Bold = ({ children }) => (
  <span className="secondary-semibold">{children}</span>
);

const getCategoryFromBrief = <
  T extends { categoryCode: string; categoryName: string },
>(
  brief: T,
) => (
  <Bold>
    {brief.categoryCode} - {brief.categoryName}
  </Bold>
);

const getRequisitionFromBrief = <
  T extends {
    requisitionBeginningDate: string;
    requisitionEndingDate: string;
  },
>(
  brief: T,
) => (
  <>
    period from <Bold>{brief.requisitionBeginningDate}</Bold> to{' '}
    <Bold>{brief.requisitionEndingDate}</Bold>
  </>
);

export const activityLogEventFormatter = (event: TBudgetActivityLogEvents) => {
  const eventKind = event.kind;
  switch (eventKind) {
    case TEventTypes.COMMENT_ADDED_TO_REQUISITION_CATEGORY:
      return (
        <>
          Left comment on{' '}
          <Bold>
            {event.brief.categoryCode} - {event.brief.categoryName}, requisition{' '}
            {event.brief.requisitionBeginningDate}
          </Bold>
        </>
      );
    case TEventTypes.BUDGET_CREATED:
      return 'Budget has been created';
    case TEventTypes.BUDGET_PUBLISHED:
      return 'Budget has been published';
    case TEventTypes.BUDGET_LINE_ITEM_REMOVED:
      return <>Line removed for {getCategoryFromBrief(event.brief)}</>;
    case TEventTypes.BUDGET_LINE_ITEM_ADDED:
      return (
        <>
          Line <Bold>{event.brief.newValue}</Bold> added for{' '}
          {getCategoryFromBrief(event.brief)}
        </>
      );
    case TEventTypes.BUDGET_LINE_ITEM_VALUE_CHANGED:
      return (
        <>
          Line changed on <Bold>{event.brief.newValue}</Bold> for{' '}
          {getCategoryFromBrief(event.brief)}
        </>
      );
    case TEventTypes.REPLY_ADDED_TO_REQUISITION_CATEGORY_COMMENT:
      return (
        <>
          Reply added to requisition comment on{' '}
          {getCategoryFromBrief(event.brief)}
        </>
      );
    case TEventTypes.REQUISITION_ADDED:
    case TEventTypes.REQUISITION_REMOVED: {
      const action =
        eventKind === TEventTypes.REQUISITION_ADDED ? 'added' : 'removed';
      return (
        <>
          Requisition {action} for {getRequisitionFromBrief(event.brief)}
        </>
      );
    }
    case TEventTypes.REQUISITION_TRANSACTION_CATEGORY_CHANGED:
      return (
        <>
          Transaction category changed to {getCategoryFromBrief(event.brief)} in
          Requisition for {getRequisitionFromBrief(event.brief)}
        </>
      );
    case TEventTypes.REQUISITION_TRANSACTION_LINKED_TO_CATEGORY:
    case TEventTypes.REQUISITION_TRANSACTION_UNLINKED_FROM_CATEGORY: {
      const action =
        eventKind === TEventTypes.REQUISITION_TRANSACTION_UNLINKED_FROM_CATEGORY
          ? 'unlinked'
          : 'linked';
      return (
        <>
          Transaction {action} to {getCategoryFromBrief(event.brief)} in
          Requisition for {getRequisitionFromBrief(event.brief)}
        </>
      );
    }

    default:
      return assertUnreachable(eventKind);
    // return () => {};
  }
};

import { Field, ThinTabGroup } from '@/stories';
import {
  COMPARISON_DASHBOARD_SECTION_TYPE,
  ComparisonDashboardSectionTypes,
  PeriodShiftConfig,
} from 'bundles/Shared/entities/dashboard';
import {
  PeriodShiftField,
  transformPeriodShiftDtoToForm,
  UpdateWidgetTitle,
  useUpdateWidgetConfig,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { FINANCIAL_TABLE_CONFIGURATION_TABS } from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/config';
import { FinancialTableWidgetColumnGroups } from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/component';
import { PeriodTypesField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodTypesField';
import { FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod';
import { FinancialTableSinglePeriodWidgetConfigColumnPanel } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/column';
import { FinancialTableSinglePeriodWidgetConfigColumnGroup } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/group';
import { financialTableSinglePeriodUpdaters } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/updaters';
import { FinancialTableSinglePeriodPeriodType } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { ComponentProps, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTabs } from 'stories/Tabs/useTabs';

type BasicTabForm = {
  availablePeriodTypes: FinancialTableSinglePeriodPeriodType[];
  defaultPeriodType: FinancialTableSinglePeriodPeriodType;
  period_shift: PeriodShiftConfig;
};
const BasicTab = () => {
  const { widget } =
    useWidgetConfig<
      (typeof COMPARISON_DASHBOARD_SECTION_TYPE)['FINANCIAL_TABLE_SINGLE_PERIOD']
    >();

  const methods = useForm<BasicTabForm>({
    defaultValues: {
      availablePeriodTypes: widget.defaultOptions.availablePeriodTypes,
      defaultPeriodType: widget.defaultOptions.periodType,
      period_shift: transformPeriodShiftDtoToForm(
        widget.widgetConfig.default_options.date?.period_shift,
      ),
    },
  });
  const { control } = methods;

  useUpdateWidgetConfigEffect<
    BasicTabForm,
    typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD
  >(methods, financialTableSinglePeriodUpdaters.updateDefaultOptions);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-tw-4">
        <UpdateWidgetTitle />
        <PeriodTypesField
          control={control}
          options={FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES_OPTIONS}
        />
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </Field>
      </div>
    </FormProvider>
  );
};
const ColumnsTab = () => {
  const { widget } = useWidgetConfig<ComparisonDashboardSectionTypes>();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);
  const [columnGroupModalState, setColumnGroupModalState] = useState<Omit<
    ComponentProps<typeof FinancialTableSinglePeriodWidgetConfigColumnGroup>,
    'onClose'
  > | null>(null);
  const [columnModalState, setColumnModalState] = useState<Omit<
    React.ComponentProps<
      typeof FinancialTableSinglePeriodWidgetConfigColumnPanel
    >,
    'onClose'
  > | null>(null);

  return (
    <>
      <FinancialTableWidgetColumnGroups
        actions={{
          column: {
            onHideAll: (newValue) => {
              const config =
                financialTableSinglePeriodUpdaters.toggleAllColumnsHidden(
                  newValue,
                  widget.widgetConfig,
                );
              updateConfig({ config });
            },
            onHide: (args) => {
              const config =
                financialTableSinglePeriodUpdaters.toggleColumnHidden(
                  args,
                  widget.widgetConfig,
                );
              updateConfig({ config });
            },
            onAdd: (args) => {
              setColumnModalState({
                type: 'create',
                ...args,
              });
            },
            onClone: (args) => {
              updateConfig({
                config: financialTableSinglePeriodUpdaters.cloneColumn(
                  args,
                  widget.widgetConfig,
                ),
              });
            },
            onEdit: (args) => {
              setColumnModalState({
                type: 'edit',
                ...args,
              });
            },
            onRemove: (args) => {
              updateConfig({
                config: financialTableSinglePeriodUpdaters.removeColumn(
                  args,
                  widget.widgetConfig,
                ),
              });
            },
          },
          group: {
            onHide: (args) => {
              const config =
                financialTableSinglePeriodUpdaters.toggleGroupHidden(
                  args,
                  widget.widgetConfig,
                );
              updateConfig({ config });
            },
            onAdd: () => {
              setColumnGroupModalState({
                type: 'create',
              });
            },
            onEdit: (args) => {
              setColumnGroupModalState({
                type: 'edit',
                group: {
                  group_id: args.groupId,
                  header_name: args.headerName,
                },
              });
            },
            onRemove: (args) => {
              updateConfig({
                config: financialTableSinglePeriodUpdaters.removeGroup(
                  args,
                  widget.widgetConfig,
                ),
              });
            },
          },
        }}
      />
      {columnModalState != null && (
        <FinancialTableSinglePeriodWidgetConfigColumnPanel
          {...columnModalState}
          onClose={() => setColumnModalState(null)}
          onSubmit={(res) => {
            const { groupId } = columnModalState;
            setColumnModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setColumnModalState({
                  type: 'create',
                  groupId,
                });
              });
            }
          }}
        />
      )}
      {columnGroupModalState != null && (
        <FinancialTableSinglePeriodWidgetConfigColumnGroup
          {...columnGroupModalState}
          onClose={() => setColumnGroupModalState(null)}
        />
      )}
    </>
  );
};

export function FinancialTableSinglePeriodWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(
    FINANCIAL_TABLE_CONFIGURATION_TABS,
    0,
  );

  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && <ColumnsTab />}
      </div>
    </div>
  );
}

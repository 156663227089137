import React, { FC } from 'react';
import { IHeaderParams } from 'ag-grid-community';

interface Props extends IHeaderParams {
  title: string;
  description: string;
  formula: string;
}

const CustomHeader: FC<Props> = ({ title, description, formula }) => (
  //* error realize of the next tasks
  <div className="w-full white header__item text-center">
    <div className="text-uppercase header__title py-1 font-semibold">
      <div>{title}</div>
      {/* <div className="header__error">1</div> */}
    </div>
    <div className="font-weight-normal header__description px-4 pt-1">
      <div>{description}</div>
      {formula && <div>{formula}</div>}
    </div>
  </div>
);
export default CustomHeader;

import React, { useMemo, useState } from 'react';
import { Button, Field, Input, Modal, ModalActions } from 'stories';
import ModalHeaderWithSubtitle from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import ReactDatePicker from 'react-datepicker';
import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import { STATIC_DATE_PICKER_PROPS } from 'bundles/REturn/components/Ownership/modals/consts';
import {
  dateRangesCrossing,
  generateDates,
} from 'bundles/REconcile/components/development/requisitions/utils';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface Props {
  onClose: () => void;
  onSubmit: (
    requisitionDate: Pick<IRequisition, 'beginningDate' | 'endingDate'>,
  ) => void;
  beginningDate: Date;
  endingDate: Date;
  excludeDates: Date[];
}

function RequisitionModal({
  onClose,
  onSubmit,
  beginningDate,
  endingDate,
  excludeDates,
}: Props) {
  const [dateRange, setDateRange] = useState<{
    beginningDate?: Date;
    endingDate?: Date;
  }>({ beginningDate, endingDate });

  const handleSubmit = () => {
    onSubmit({
      endingDate: formatToDateStringForRequest(dateRange.endingDate),
      beginningDate: formatToDateStringForRequest(dateRange.beginningDate),
    });
  };

  const datesOverlaps = useMemo(() => {
    if (!dateRange.beginningDate || !dateRange.endingDate) return false;

    const range = generateDates(dateRange.beginningDate, dateRange.endingDate);

    return dateRangesCrossing(range, excludeDates);
  }, [dateRange]);

  const canSubmit = () => {
    return (
      dateRange.beginningDate != null &&
      dateRange.endingDate != null &&
      !datesOverlaps
    );
  };

  return (
    <Modal
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          order="subtitle-title"
          subtitle="Construction"
          title="Add New Requisition"
        />
      }
      actions={
        <ModalActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!canSubmit()}
            variant="success"
            onClick={handleSubmit}
          >
            Add Requisition
          </Button>
        </ModalActions>
      }
    >
      <div className="flex flex-col gap-s">
        <Field
          labelText="Requisition Period"
          error={
            datesOverlaps
              ? 'You selected dates which overlaps another requisition'
              : undefined
          }
          required
        >
          <ReactDatePicker
            {...STATIC_DATE_PICKER_PROPS}
            selected={dateRange.beginningDate}
            startDate={dateRange.beginningDate}
            endDate={dateRange?.endingDate}
            dateFormat="dd.MM.yy"
            selectsRange
            onChange={(date) => {
              setDateRange({ beginningDate: date[0], endingDate: date[1] });
            }}
            wrapperClassName="w--75rem"
            excludeDates={excludeDates}
            customInput={<Input leftIcon="calendar" rightIcon="bottom" />}
          />
        </Field>
      </div>
    </Modal>
  );
}

export default RequisitionModal;

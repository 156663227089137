import FullWhiteTable from '@/bundles/Shared/components/Table/variants/FullWhiteTable';
import {
  filterByFullPeriod,
  ReportManualVariable,
  ReportManualVariableLegalEntity,
  ReportManualVariableLegalEntityValue,
  someValueHasDateTo,
  useGetApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesQuery,
} from '@/entities/report/manualVariable';
import {
  useCreateReportManualVariableValue,
  useRemoveReportManualVariableValue,
  useUpdateReportManualVariableValue,
} from '@/features/report/manualVariable';
import { ItemTableCounter } from '@/shared/ui/ItemTableCounter';
import { AnimationLoader, Button, MultilineAlert } from '@/stories';
import { SidePanel } from '@/stories/Modals/Modal/Modal';
import {
  useManualVariablesLEValuesColumnDefs,
  useNextValueDateFrom,
} from '@/widgets/report/manualVariable/settings';
import { isEqual } from 'lodash-es';
import { useMemo, useRef } from 'react';
import AnimateHeight from 'react-animate-height';

export const ManageManualVariableLegalEntityValues = ({
  legalEntity,
  manualVariable,
  onClose,
}: {
  manualVariable: ReportManualVariable;
  legalEntity: ReportManualVariableLegalEntity;
  onClose: VoidFunction;
}) => {
  const { data, isFetching, isLoading } =
    useGetApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesQuery(
      {
        legalEntityId: legalEntity.id,
        manualVariableId: manualVariable.id,
      },
    );
  const [createValue, createOptions] = useCreateReportManualVariableValue();
  const [updateValue, updateOptions] = useUpdateReportManualVariableValue();
  const [removeValue, removeOptions] = useRemoveReportManualVariableValue();
  const items = data?.items ?? [];

  const isActionLoading =
    updateOptions.isLoading ||
    createOptions.isLoading ||
    removeOptions.isLoading;

  const ref = useRef(null);

  const itemsWithFullPeriods = useMemo(() => {
    return items.filter(filterByFullPeriod);
  }, [items]);

  const nextDateFrom = useNextValueDateFrom({
    items,
  });

  const handleAdd = () => {
    createValue({
      dateFrom: nextDateFrom,
      legalEntityId: legalEntity.id,
      manualVariableId: manualVariable.id,
      valueType: manualVariable.valueType,
    });
  };

  const handleRemove = (id: string) => {
    removeValue({
      id,
      legalEntityId: legalEntity.id,
      manualVariableId: manualVariable.id,
    });
  };

  const handleUpdateValue = (v: ReportManualVariableLegalEntityValue) => {
    const prevItem = items.find((item) => item.id === v.id);

    if (isEqual(prevItem, v)) return;

    updateValue({
      ...v,
      legalEntityId: legalEntity.id,
      manualVariableId: manualVariable.id,
    });
  };

  const isAddDataDisabled = useMemo(() => {
    return isActionLoading || items.some(someValueHasDateTo);
  }, [items, isActionLoading]);

  const isEnoughDataForChart = useMemo(() => {
    return itemsWithFullPeriods.length > 1;
  }, [itemsWithFullPeriods]);

  const columns = useManualVariablesLEValuesColumnDefs({
    valueType: manualVariable.valueType,
    actions: {
      onRemove: handleRemove,
      onUpdate: handleUpdateValue,
    },
    deps: [items],
  });

  return (
    <SidePanel
      toggle={onClose}
      header={
        <p className="header6-bold text-neutral-850">{legalEntity.name}</p>
      }
      position="right"
      classes={{
        body: 'flex flex-col',
      }}
    >
      <AnimateHeight
        duration={300}
        className="relative"
        height={isEnoughDataForChart && ref.current ? 'auto' : 0}
      >
        {isActionLoading && (
          <AnimationLoader className="z-10 h-[calc(100%_-_16px)] w-full bg-neutral-000/50" />
        )}
      </AnimateHeight>
      <AnimateHeight duration={300} height={isAddDataDisabled ? 'auto' : 0}>
        <MultilineAlert
          className="mb-tw-4"
          message="Do not forget to specify the end date of the latest period when adding a new value"
        />
      </AnimateHeight>
      <div className="flex flex-col gap-tw-2">
        <div className="flex items-center justify-between">
          <ItemTableCounter count={items.length} word="value" />
          <Button
            onClick={() => {
              handleAdd();
            }}
            disabled={isAddDataDisabled}
            variant="secondary"
            size="xs"
          >
            Add Data
          </Button>
        </div>
        {isLoading ? (
          <AnimationLoader className="static min-h-[100px]" />
        ) : (
          <FullWhiteTable
            loading={isFetching}
            columns={columns}
            items={items}
          />
        )}
      </div>
    </SidePanel>
  );
};

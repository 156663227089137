import React from 'react';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import { optionFor } from 'lib/helpers';
import { ICompany } from 'bundles/Construction/types';
import { round, sumBy } from 'lodash-es';
import { creatableSelectCreateVendorMessage } from 'bundles/REconcile/components/development/lineItems/utils';
import { ILineItem } from 'bundles/REconcile/types/ILineItem';

export const invoiceStatuses = {
  created: {
    color: 'light-60',
  },
  verified: {
    color: 'violet-dark-3',
  },
  approved: {
    color: 'green',
  },
};

export const getItemsInvoiceBlock = (
  formData: Invoice,
  companies: ICompany,
  type: 'edit' | null,
): Invoice[] => {
  const groupFormItems = {
    title: 'Key Info',
    required: true,
    activated: true,
    open: true,
    formItems: [
      {
        fieldName: 'number',
        fieldNameSnap: 'number',
        type: FieldType.INPUT_TEXT,
        title: 'Number',
        placeholder: '0',
        required: true,
        styles: {
          wrapper: 'w-50 form-item_white',
          size: 'm',
        },
      },
      {
        fieldName: 'date',
        type: FieldType.INPUT_DATE,
        title: 'Invoice Date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-50 form-item_white',
          size: 'm',
        },
      },
      {
        fieldName: 'vendor_name',
        type: FieldType.CREATABLE_SELECT,
        title: 'Vendor (Company)',
        placeholder: 'Enter or Select Vendor',
        formatCreateLabel: creatableSelectCreateVendorMessage,
        menuPosition: 'fixed',
        required: true,
        optionalView: true,
        options: companies.map((c) => optionFor(c.name)),
        styles: {
          wrapper: 'w-full',
        },
      },
      {
        fieldName: 'amount',
        type: FieldType.INPUT_NUMBER,
        title: 'Amount',
        leftIcon: 'paid',
        placeholder: '0',
        required: true,
        styles: {
          wrapper: 'w-50 form-item_white',
          size: 'm',
        },
        thousandSeparator: true,
      },
    ],
  };

  return groupFormItems;
};

export const getItemsInvoiceModal = (
  formData: Invoice,
  companies: ICompany,
  type: 'edit' | null,
): Invoice[] => {
  const groupFormItems = [
    {
      title: 'Key Info',
      required: true,
      activated: true,
      open: true,
      formItems: [
        {
          fieldName: 'number',
          fieldNameSnap: 'number',
          type: FieldType.INPUT_TEXT,
          title: 'Number',
          placeholder: '0',
          required: true,
          styles: {
            wrapper: 'w-33 form-item_white',
            size: 'm',
          },
        },
        {
          fieldName: 'amount',
          type: FieldType.INPUT_NUMBER,
          title: 'Amount',
          leftIcon: 'paid',
          placeholder: '0',
          required: true,
          styles: {
            wrapper: 'w-33 form-item_white',
            size: 'm',
          },
          thousandSeparator: true,
        },
        {
          fieldName: 'date',
          type: FieldType.INPUT_DATE,
          title: 'Invoice Date',
          placeholder: 'Date',
          required: true,
          format: 'MM-DD-YYYY',
          leftIcon: 'calendar',
          styles: {
            wrapper: 'w-33 form-item_white',
            size: 'm',
          },
        },
        {
          fieldName: 'vendor_name',
          type: FieldType.CREATABLE_SELECT,
          title: 'Vendor (Company)',
          placeholder: 'Enter or Select Vendor',
          formatCreateLabel: creatableSelectCreateVendorMessage,
          menuPosition: 'fixed',
          required: true,
          optionalView: true,
          options: companies.map((c) => optionFor(c.name)),
          styles: {
            wrapper: 'w-66',
          },
        },
      ],
    },
    {
      title: type === 'edit' ? 'Invoice' : 'Add Invoice',
      required: true,
      activated: formData?.document,
      open: true,
      formItems: [
        {
          fieldName: 'document',
          type: FieldType.FILES,
          required: true,
          enabled: true,
          multifile: false,
          multiple: false,
          config: {
            label: 'Drag your Invoice here to start uploading',
            buttonText: 'Browse Invoice',
          },
        },
      ],
      styles: {
        containerClassName: formData?.document ? 'p-0' : 'p-0 pb-s pt-xs',
        transparent: true,
      },
    },
  ];

  return groupFormItems;
};

export const calcTotal = (lineItems: ILineItem[]) =>
  round(
    sumBy(lineItems, (item) => Number(item.amount)),
    2,
  );

import React from 'react';

interface Props {
  icons?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  // remove this after replace modals
  close?: () => void;
}

export const ModalHeaderWithIcon = ({
  icons,
  title,
  subtitle,
  close,
}: Props) => (
  <div className="flex justify-between">
    <div className="flex items-center">
      {icons}
      <div>
        <div className="dark-60 word-break header6-bold">{title}</div>
        <div className="dark-60 secondary-regular mt-xs">{subtitle}</div>
      </div>
    </div>
    {/* remove this after replace modals */}
    {close && (
      <div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={close}
        >
          <span aria-hidden="true" className="sre-icon-close" />
        </button>
      </div>
    )}
  </div>
);

export default ModalHeaderWithIcon;

import { startCase } from 'lodash-es';
import pluralize from 'pluralize';

type ActionType =
  | 'update'
  | 'remove'
  | 'delete'
  | 'create'
  | 'move'
  | 'complete'
  | 'duplicate'
  | 'publish';

const VERB_DICT = {
  update: { secondForm: 'updated' },
  remove: { secondForm: 'removed' },
  delete: { secondForm: 'deleted' },
  create: { secondForm: 'created' },
  move: { secondForm: 'moved' },
  complete: { secondForm: 'completed' },
  duplicate: { secondForm: 'duplicated' },
  publish: { secondForm: 'published' },
} as const satisfies Record<
  ActionType,
  {
    secondForm: string;
  }
>;

export class EntityToastrCreator {
  static #EMPTY_ENTITY_NAME = 'Item';

  #entityName: string;
  #actionType: ActionType = 'update' as const;

  constructor(entityName: string) {
    this.#entityName = startCase(
      entityName || EntityToastrCreator.#EMPTY_ENTITY_NAME,
    );
  }

  update() {
    this.#actionType = 'update';
    return this;
  }
  remove() {
    this.#actionType = 'remove';
    return this;
  }
  delete() {
    this.#actionType = 'delete';
    return this;
  }
  create() {
    this.#actionType = 'create';
    return this;
  }
  move() {
    this.#actionType = 'move';
    return this;
  }
  publish() {
    this.#actionType = 'publish';
    return this;
  }
  duplicate() {
    this.#actionType = 'duplicate';
    return this;
  }
  complete() {
    this.#actionType = 'complete';
    return this;
  }

  singular() {
    this.#entityName = pluralize(this.#entityName, 1);
    return this;
  }

  pluralize() {
    this.#entityName = pluralize(this.#entityName, 0);
    return this;
  }

  toString() {
    return `${this.#entityName} ${
      VERB_DICT[this.#actionType].secondForm
    }` as const;
  }
}

import { TUserInviteStatus } from 'types/User';

const STATUS_COLOR: Record<TUserInviteStatus | 'active', string> = {
  [TUserInviteStatus.INVITED_LONG_TIME_AGO]: '#FDAB32',
  [TUserInviteStatus.CREATED]: '#848D91',
  [TUserInviteStatus.DEACTIVATED]: '#F6413E',
  [TUserInviteStatus.INVITED]: '#7B61FF',
  [TUserInviteStatus.ACTIVE]: '#2CAE4C',
};

const getStatusColor = (inviteStatus: TUserInviteStatus) =>
  STATUS_COLOR[inviteStatus] || 'lightgreen';

export default getStatusColor;

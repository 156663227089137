import { Dispatch, SetStateAction, useState } from 'react';

interface UseCounterOutput {
  count: number;
  increment: (max?: number) => void;
  decrement: (min?: number) => void;
  reset: () => void;
  setCount: Dispatch<SetStateAction<number>>;
}

export function useCounter(initialValue?: number): UseCounterOutput {
  const [count, setCount] = useState(initialValue ?? 0);

  const increment = (max?: number) => {
    if (max === count) return;
    setCount((x) => x + 1);
  };
  const decrement = (min?: number) => {
    if (min === count) return;
    setCount((x) => x - 1);
  };
  const reset = () => setCount(initialValue ?? 0);

  return {
    count,
    increment,
    decrement,
    reset,
    setCount,
  };
}

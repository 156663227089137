import { ReportPeriodsType } from 'bundles/Shared/entities/periodsType/model';

export const PERIODS_TYPES = [
  { id: 'mtd', value: 'mtd', label: 'MTD' },
  { id: 'all_time_to_date', value: 'all_time_to_date', label: 'Rolling MTD' },
  { id: 'mtd_and_total', value: 'mtd_and_total', label: 'MTD + Total' },
  {
    id: 'single_mtd_and_ytd',
    value: 'single_mtd_and_ytd',
    label: 'Single MTD + YTD',
  },
  { id: 'total', value: 'total', label: 'Total' },
  {
    id: 'single_mtd_qtd_and_ytd',
    value: 'single_mtd_qtd_and_ytd',
    label: 'Single MTD + QTD + YTD',
  },
  {
    id: 'single_mtd_qtd_and_itd',
    value: 'single_mtd_qtd_and_itd',
    label: 'Single MTD + QTD + Inception To Date',
  },
  {
    id: 'single_mtd_qtd_and_uw_itd',
    value: 'single_mtd_qtd_and_uw_itd',
    label: 'Single MTD + QTD + Inception To Date (UW)',
  },
  {
    id: 'single_qtd_and_ytd',
    value: 'single_qtd_and_ytd',
    label: 'Single QTD + YTD',
  },
  {
    id: 'single_qtd_ytd_and_itd',
    value: 'single_qtd_ytd_and_itd',
    label: 'Single QTD + YTD + Inception To Date',
  },
  {
    id: 'single_qtd_ytd_and_uw_itd',
    value: 'single_qtd_ytd_and_uw_itd',
    label: 'Single QTD + YTD + Inception To Date (UW)',
  },
  { id: 'single_mtd', value: 'single_mtd', label: 'Single MTD' },
  { id: 'single_t3_and_t12', value: 'single_t3_and_t12', label: 'T3 + T12' },
  { id: 'year_mtd', value: 'year_mtd', label: 'Year MTD' },
  { id: 'variance_report', value: 'variance_report', label: 'Variance Report' },
] as const satisfies ReadonlyArray<{
  id: ReportPeriodsType;
  value: ReportPeriodsType;
  label: string;
}>;

export const LEGAL_ENTITIABLES_TABS = [
  {
    id: 'assets',
    label: 'Assets',
  },
  {
    id: 'funds',
    label: 'Funds',
  },
] as const;

export const DRAFT_OPTION_MAP = {
  draft: {
    label: 'Draft',
    value: 'draft',
    iconName: 'draft',
    className: 'text-yellow-dark-2',
  },
} as const;

export const PUBLISHED_OPTION_MAP = {
  published: {
    label: 'Publish',
    value: 'published',
    iconName: 'public',
    className: 'text-green-dark-2',
  },
} as const;

export const ARCHIVED_OPTION_MAP = {
  archived: {
    label: 'Archive',
    iconName: 'archive',
    value: 'archived',
    className: 'text-neutral-500',
  },
} as const;

export const STATUS_OPTIONS_MAP = {
  ...PUBLISHED_OPTION_MAP,
  ...ARCHIVED_OPTION_MAP,
} as const;

export const STATUS_OPTIONS_FOR_DRAFTS = {
  ...DRAFT_OPTION_MAP,
  ...PUBLISHED_OPTION_MAP,
};

export const ALL_STATUS_OPTIONS_MAP = {
  ...DRAFT_OPTION_MAP,
  ...PUBLISHED_OPTION_MAP,
  ...ARCHIVED_OPTION_MAP,
} as const;

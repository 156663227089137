import {
  ResponsePresignFile,
  SubmitFile,
} from 'bundles/Shared/shared/UploadFilesModal';
import { presignFile } from './uploadFiles';

export const presignFileWithFolder = async ({
  file,
  documentable_id,
  documentable_type,
  folder_id,
  folder_type,
}) =>
  presignFile(file, {
    documentable_id,
    documentable_type,
    folder_id,
    folder_type,
  });

export const presignFilesWithFolder = async (
  files: SubmitFile[],
  customPresignFile?: (file: SubmitFile) => ResponsePresignFile,
) => {
  const presignedDocumentFiles = [];
  await Promise.all(
    files.map(async (fileData) => {
      presignedDocumentFiles.push(
        customPresignFile
          ? await customPresignFile(fileData)
          : await presignFileWithFolder(fileData),
      );
    }),
  );
  return presignedDocumentFiles;
};

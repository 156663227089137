import http from 'lib/http';

export const FETCH_USER_STATISTICS = 'symmetre-client-api/FETCH_USER_STATISTICS';

export function fetchUserStatistics() {
  return async (dispatch) => {
    const res = await http.get('/users/statistics');
    const responseData = await res.json();

    dispatch({
      type: FETCH_USER_STATISTICS,
      payload: responseData,
    });
  };
}

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { round, sumBy } from 'lodash-es';
import { getAllocationFlags } from 'bundles/REconcile/components/AllocationStatistics';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';

type UnknownRecord = Record<string, unknown>;

interface AllocationContextParams<T extends UnknownRecord> {
  items: T[];
  total: number | undefined;
  sumMapper: (item: T) => number;
  updateItem?: (index: number, value: number) => void;
}

interface AllocationContext {
  total: number | undefined;
  setTotal: React.Dispatch<React.SetStateAction<number | undefined>>;
  allocatedAmount: number;
  notAllocatedAmount: number;
  allocationFlags: ReturnType<typeof getAllocationFlags>;
  allItemsAreAllocated: boolean;
  totalValid: boolean;
  inputProps: {
    handleBlur: (
      index: number,
    ) => Pick<React.ComponentProps<typeof CurrencyInputNumber>, 'onBlur'>;
    error: Pick<React.ComponentProps<typeof CurrencyInputNumber>, 'error'>;
  };
}

export const useAllocation = <T extends UnknownRecord>({
  total: initialTotal,
  updateItem,
  items,
  sumMapper,
}: AllocationContextParams<T>): AllocationContext => {
  const [total, setTotal] = useState(initialTotal);
  const allocatedAmount = round(sumBy(items, sumMapper), 2);
  const notAllocatedAmount = round((total ?? 0) - allocatedAmount, 2);
  const allItemsAreAllocated = !items.some((item) => sumMapper(item) === 0);
  const totalValid = total !== undefined && total > 0;

  const handleBlur = useCallback<AllocationContext['inputProps']['handleBlur']>(
    (index) => (value) => {
      updateItem?.(index, value);
    },
    [updateItem],
  );

  const error = useCallback<AllocationContext['inputProps']['error']>(
    (value, { isDirty }) => isDirty && value === 0,
    [],
  );

  useEffect(() => {
    setTotal(initialTotal);
  }, [initialTotal]);

  return {
    total,
    setTotal,
    allocatedAmount,
    notAllocatedAmount,
    allItemsAreAllocated,
    totalValid,
    allocationFlags: getAllocationFlags({
      allocatedAmount,
      totalAmount: total ?? 0,
    }),
    inputProps: {
      handleBlur,
      error,
    },
  };
};

export const AllocationContext = createContext<AllocationContext | null>(null);

function AllocationProvider<T extends UnknownRecord>({
  items,
  updateItem,
  total,
  sumMapper,
  children,
}: React.PropsWithChildren<AllocationContextParams<T>>) {
  const allocation = useAllocation({
    total,
    sumMapper,
    items,
    updateItem,
  });
  return (
    <AllocationContext.Provider value={allocation}>
      {children}
    </AllocationContext.Provider>
  );
}

function useAllocationContext() {
  // get the context
  const context = useContext(AllocationContext);

  return context;
}

export { AllocationProvider, useAllocationContext };

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';
import dayjs from 'dayjs';

interface Props {
  iconName: string;
  iconColor: string;
  title: string;
  user: string;
  date: number;
  className?: string;
}

function HistoryDetailsModalHeaderContent({
  className,
  iconName,
  iconColor,
  title,
  user,
  date,
  ...props
}: Props) {
  return (
    <div className={cn('flex gap-m pr-m', className)} {...props}>
      <div className="flex h-xxl w-xxl flex-[0_0_40px]  items-center justify-center rounded-[0.5rem] bg-light">
        <Icon iconName={iconName} className={cn('text-[1.5rem]', iconColor)} />
      </div>
      <div>
        <h3 className="dark-60 header6-bold">{title}</h3>
        <p className="light-60 inline-regular">
          by {user} • {dayjs.unix(date).format('MMM DD, YYYY')} at{' '}
          {dayjs.unix(date).format('h:mm A')}
        </p>
      </div>
    </div>
  );
}

export default HistoryDetailsModalHeaderContent;

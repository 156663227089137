import React, { FC } from 'react';
import { CurrencyFormatter, StatusItem } from 'stories';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import { generateTypeStatusItem } from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/utils';
import BoardCard from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/cards/BoardCard';

interface Props extends Invoice {
  className?: string;
}

const ApproveInvoiceCard: FC<Props> = ({
  number,
  amount,
  date,
  vendor,
  funding,
  payment,
  className,
}) => {
  const paymentSettings = generateTypeStatusItem({
    type: 'payment',
    payment,
  });

  const fundingSettings = generateTypeStatusItem({
    type: 'funding',
    funding,
  });

  return (
    <BoardCard className={className} number={number}>
      <div className="flex flex-col">
        <div className="mb-tw-2">
          <CurrencyFormatter
            classes={{
              wrapper: 'header5-regular',
            }}
            value={amount}
          />
        </div>
        <div className="mb-tw-3 flex gap-tw-1">
          <span className="light-90 label-regular">{date}</span>
          <span className="light-60 secondary-regular">•</span>
          <span className="light-90 label-regular">{vendor.name}</span>
        </div>
        <div className="flex">
          <StatusItem
            title="Payment"
            subTitle={paymentSettings.title}
            type={paymentSettings.type}
          />
          <StatusItem
            title="Funding"
            subTitle={fundingSettings.title}
            type={fundingSettings.type}
          />
        </div>
      </div>
    </BoardCard>
  );
};

export default ApproveInvoiceCard;

import { cn } from '@/shared/lib/css/cn';
import { RouteComponentProps, navigate } from '@reach/router';
import { fetchUserRoles } from 'bundles/Assets/actions';
import { fetchFunds } from 'bundles/Funds/actions';
import { FetchUsersResponse, fetchUsers } from '@/entities/impersonation/api';
import Table from 'bundles/Shared/components/Table/Table';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import UsersFilterModal from 'bundles/Shared/components/UsersFilterModal';
import CreateUserModal from 'bundles/UserManagement/components/CreateUserModal';
import EditUserModal from 'bundles/UserManagement/components/EditUserModal';
import UserFilters from 'bundles/UserManagement/components/UserFilters';
import ExportUsersButton from 'bundles/UserManagement/components/UserTracker/ExportUsersButton';
import { getInviteConfirmationParams } from 'bundles/UserManagement/components/UserTracker/InviteConfirmation';
import {
  getDefaultFilterFromLocationState,
  getParamsFromPageParams,
} from 'bundles/UserManagement/components/UserTracker/utils';
import userColumns, {
  IUserTableColumnsActions,
  TFilterModel,
} from 'bundles/UserManagement/components/table/userTableColumns';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import {
  USER_MANAGEMENT_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsSreAdmin,
} from 'lib/permissions';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {
  AnimationLoader,
  Button,
  Icon,
  IconButton,
  SearchInput,
} from 'stories';
import { ILocationState } from 'types/LocationState';
import {
  IUser,
  IUserRole,
  TUserInviteStatus,
  USER_FIRE_STATUSES,
} from 'types/User';
import { createUser, deactivateUser, inviteUser } from '../../actions/User';
import { fetchUserStatistics } from '../../actions/UserStatistics';
import ImportUsersButton from './ImportUsersButton';

const SIZE_PER_PAGE = 25;

interface FilterEntry {
  id: number;
  name: string;
}
export interface IUserFilters {
  assets: FilterEntry[];
  funds: FilterEntry[];
  roles: Pick<IUserRole, 'id' | 'name'>[];
  members: FilterEntry[];
  tags: FilterEntry[];
  investmentEntities: FilterEntry[];
}
export interface IPageParams {
  currentPage: number;
  sizePerPage: number;
  sortField: string;
  sortOrder: 'asc' | 'desc';
  searchQuery: string;
  filters: IUserFilters;
  inviteStatus: string;
  fire_invite_status: TUserInviteStatus[];
  filter_by_fire_latest_invitation?: boolean;
}

interface Props extends PropsFromRedux, RouteComponentProps {}

export type TUserTrackerTab =
  | 'info'
  | 'role'
  | 'editUser'
  | 'assets'
  | 'funds'
  | 'advisees'
  | 'advisors';

const UserTracker = ({
  fetchStatistics,
  userRoles,
  location = {},
  ...props
}: Props) => {
  const locationState = location.state;
  const [pageParams, setPageParams] = useState<IPageParams>({
    currentPage: 1,
    sizePerPage: SIZE_PER_PAGE,
    sortField: 'updated_at',
    sortOrder: 'desc',
    searchQuery: '',
    filters: getDefaultFilterFromLocationState(locationState),
    inviteStatus: 'all',
    fire_invite_status: [],
  });
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<FetchUsersResponse>();
  const [expandedUserId, setExpandedUserId] = useState<IUser['id'] | null>(
    null,
  );
  const handleToggleExpand = (userId: IUser['id']) => {
    setExpandedUserId((prev) => (prev === userId ? null : userId));
  };
  const isUserExpanded = useCallback(
    (userId: IUser['id']) => userId === expandedUserId,
    [expandedUserId],
  );

  const { confirm, openModal } = useModal();

  const fetchAndSetUsers = async () => {
    // todo: improve loading with some lib
    setLoading(true);
    const data = await fetchUsers(getParamsFromPageParams(pageParams));
    setUsers(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchAndSetUsers();
  }, [pageParams]);

  useEffect(() => {
    if (currentUserAllowedTo('view', USER_MANAGEMENT_PRODUCT_NAME)) {
      props.fetchUserRoles();
    }
  }, []);

  const setQuery = (searchQuery: string) => {
    setPageParams({ ...pageParams, searchQuery, currentPage: 1 });
  };

  const { currentPage } = pageParams;
  const setCurrentPage = (page: number) =>
    setPageParams({ ...pageParams, currentPage: page });

  const [createModalOpened, setCreateModalOpened] = useState(false);
  const toggleCreateModal = () => setCreateModalOpened(!createModalOpened);
  const [filterOpened, setFilterOpened] = useState(false);

  const [putOnNewLine, setPutOnNewLine] = useState(false);
  const checkFilterWidth = () => {
    const commonWidth =
      document.getElementById('common-manageTags-bar')?.offsetWidth ?? 0;
    const paginationWidth =
      document.getElementById('pagination')?.offsetWidth ?? 0;
    const filtersWidth = document.getElementById('filters')?.offsetWidth ?? 0;
    const searchWidth = document.getElementById('search')?.offsetWidth ?? 0;

    const spaceBetweenPaginationAndSearch =
      commonWidth - paginationWidth - searchWidth;
    setPutOnNewLine(filtersWidth > spaceBetweenPaginationAndSearch);
  };

  const selectedFilters = pageParams.filters;
  const setSelectedFilters = (newFilters: IUserFilters) => {
    setPageParams({ ...pageParams, currentPage: 1, filters: newFilters });
    checkFilterWidth();
  };

  const clearFilter: ComponentProps<typeof UserFilters>['clearFilter'] = (
    filterName,
    targetItemId,
  ) => {
    const newFilterListIds = targetItemId
      ? selectedFilters[filterName]
          .filter((filter) => filter.id !== targetItemId)
          .map(({ id }) => id)
      : [];

    const newFilterList = selectedFilters[filterName].filter((item) =>
      newFilterListIds.includes(item.id),
    );

    const newFilters = { ...selectedFilters, [filterName]: newFilterList };
    setSelectedFilters(newFilters);
  };

  const selectedFiltersLength = Object.entries(selectedFilters)
    .map((item) => item[1])
    .filter((arr) => arr.length > 0).length;
  const isFilterSelected = selectedFiltersLength > 0;

  if (!users?.items)
    return <AnimationLoader className="static min-h-[360px]" />;

  const actions: IUserTableColumnsActions = {
    edit: (user, initialTab) => {
      openModal(EditUserModal, {
        user,
        initialTab,
        afterUpdateCallback: fetchAndSetUsers,
      });
    },
    filterByFireStatus: () => {
      setPageParams({
        ...pageParams,
        currentPage: 1,
        inviteStatus: 'all',
        fire_invite_status:
          pageParams.fire_invite_status.length > 0 ? [] : USER_FIRE_STATUSES,
      });
    },
    invite: async (user) => {
      const result = await confirm(getInviteConfirmationParams(user));
      if (result) {
        await inviteUser({ id: user.id });
        fetchStatistics();
        fetchAndSetUsers();
      }
    },
    deactivate: async (user) => {
      await deactivateUser({ id: user.id });
      fetchStatistics();
      fetchAndSetUsers();
    },
  };

  const handleFilterModelChange = (filterModel: TFilterModel) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      filter_by_fire_latest_invitation: filterModel.emailMessagesCount_fire,
      currentPage: 1,
      inviteStatus: filterModel.inviteStatus ?? 'all',
      fire_invite_status: filterModel.inviteStatus_fire
        ? USER_FIRE_STATUSES
        : [],
    }));
  };

  const moveToUserActivity = () => {
    navigate(ROUTES_ROOT.userManagement.activity.fullPath);
  };

  return (
    <div>
      <div id="common-manage-bar" className="flex flex-wrap text-sm">
        <div
          id="pagination"
          className="mt-tw-4 flex items-center justify-between"
        >
          <div>
            <div className="flex items-center">
              <TablePagination
                loading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalSize={users.meta.totalSize}
                sizePerPage={SIZE_PER_PAGE}
              />
            </div>
          </div>
        </div>
        <UserFilters
          filters={selectedFilters}
          clearFilter={clearFilter}
          putOnNewLine={putOnNewLine}
        />
        <div
          id="search"
          className={cn('mt-tw-4 flex items-center gap-tw-4', {
            'ml-auto': putOnNewLine,
          })}
        >
          <Button
            variant="secondary"
            onClick={() => setFilterOpened(true)}
            iconName="filtersFilledAlt"
            size="s"
            className="text-neutral-450"
          >
            <span className="text-info-055">
              {isFilterSelected ? 'Filters' : 'Add Filter'}
            </span>
            {isFilterSelected && (
              <span className="light rounded-[8px] bg-info-055 px-tw-2">
                {selectedFiltersLength}
              </span>
            )}
            <Icon iconName="arrowBottom" />
          </Button>
          <IconButton
            variant="secondary"
            iconName="clock"
            size="l"
            onClick={moveToUserActivity}
          />
          <SearchInput
            className="flex-grow"
            size="m"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            delay={600}
          />
          {currentUserIsSreAdmin() && (
            <ImportUsersButton fetchAndSetUsers={fetchAndSetUsers} />
          )}
          <ExportUsersButton />
          <Button onClick={toggleCreateModal} variant="primary" size="s">
            New User
          </Button>
        </div>
        {filterOpened && (
          <UsersFilterModal
            onClose={() => setFilterOpened(false)}
            tabs={{
              assets: 'Assets',
              roles: 'Roles',
              tags: 'Tags',
              investmentEntities: 'Entities',
              funds: 'Funds',
            }}
            onSubmit={(data) => setSelectedFilters(data)}
            initialState={selectedFilters}
          />
        )}
      </div>

      <div className="mt-5">
        <Table
          items={users.items}
          columns={userColumns({
            isUserExpanded,
            onToggleExpand: handleToggleExpand,
            actions,
            userAccessLevels: users.meta.userAccessLevels,
            fireStationOptions: {
              totals: {
                inviteStatuses: users.meta.totalFireStationInviteStatuses,
                failedInvites: users.meta.totalFireStationFailedInvites,
              },
            },
            userRoles,
          })}
          onFilterModelChange={handleFilterModelChange}
          settings={pageParams}
          setSettings={setPageParams}
          loading={loading}
          emptyMembersSubLabel="Try selecting less filters to find members"
        />
      </div>

      {createModalOpened && (
        <CreateUserModal
          onSubmit={async (data) => {
            await createUser(data);
            fetchAndSetUsers();
            fetchStatistics();
            toggleCreateModal();
          }}
          toggleCreateUserModal={toggleCreateModal}
        />
      )}
    </div>
  );
};

const mapActionCreators = {
  fetchStatistics: fetchUserStatistics,
  fetchFunds,
  fetchUserRoles,
};

const mapStateToProps = ({ users: { userRoles } }) => ({
  userRoles,
});

const connector = connect(mapStateToProps, mapActionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserTracker);

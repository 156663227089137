import { emptySplitApi as api } from '../../../../../app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsUserManagementUserActivitiesSummary: build.query<
      GetApiSettingsUserManagementUserActivitiesSummaryApiResponse,
      GetApiSettingsUserManagementUserActivitiesSummaryApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/user_management/user_activities/summary',
        params: {
          from_date: queryArg.fromDate,
          to_date: queryArg.toDate,
          user_role_ids: queryArg.userRoleIds,
        },
      }),
    }),
    getApiSettingsUserManagementUserActivitiesUsers: build.query<
      GetApiSettingsUserManagementUserActivitiesUsersApiResponse,
      GetApiSettingsUserManagementUserActivitiesUsersApiArg
    >({
      query: (queryArg) => ({
        url: '/api/settings/user_management/user_activities/users',
        params: {
          sort: {
            field: queryArg.sortField,
            order: queryArg.sortOrder,
          },
          from_date: queryArg.fromDate,
          to_date: queryArg.toDate,
          user_role_ids: queryArg.userRoleIds,
        },
      }),
    }),
    getApiSettingsUserManagementUserActivitiesReport: build.query<
      GetApiSettingsUserManagementUserActivitiesReportApiResponse,
      GetApiSettingsUserManagementUserActivitiesReportApiArg
    >({
      query: () => ({
        url: '/api/settings/user_management/user_activities/report',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userActivityApi };
export type GetApiSettingsUserManagementUserActivitiesSummaryApiResponse =
  /** status 200 success */ UserActivitySummary;
export type GetApiSettingsUserManagementUserActivitiesSummaryApiArg = {
  fromDate?: string;
  toDate?: string;
  userRoleIds?: {
    ''?: string[];
  };
};
export type GetApiSettingsUserManagementUserActivitiesUsersApiResponse =
  /** status 200 success */ UserActivityUsers;
export type GetApiSettingsUserManagementUserActivitiesUsersApiArg = {
  sortField?: string;
  sortOrder?: string;
  fromDate?: string;
  toDate?: string;
  userRoleIds?: {
    ''?: string[];
  };
};
export type GetApiSettingsUserManagementUserActivitiesReportApiResponse =
  /** status 200 success */ UserActivityReport;
export type GetApiSettingsUserManagementUserActivitiesReportApiArg = void;
export type UserActivitySummary = {
  totalSessions: number;
  sessionsTrend: number;
  totalDuration: number;
  durationTrend: number;
  totalInternalUsers: number;
  internalUsersTrend: number;
  totalExternalUsers: number;
  externalUsersTrend: number;
  totalDesktopUsers: number;
  desktopUsersTrend: number;
  totalMobileUsers: number;
  mobileUsersTrend: number;
  sessions: {
    period: string;
    external: number;
    internal: number;
  }[];
};
export type UserActivityUsers = {
  items: {
    id: string;
    fullName: string;
    userRole: {
      id: string;
      name: string;
    };
    sessions: number;
    duration: number;
    lastSeen: string;
    mobile: number;
    desktop: number;
    trend: number;
  }[];
  meta: {
    totalSize: number;
    overallTrend: number;
    totalPages: number;
  };
};
export type UserActivityReport = {
  items: {
    dateFrom: number;
    dateTo: number;
    users: number;
    sessions: number;
    duration: number;
  }[];
};
export const {
  useGetApiSettingsUserManagementUserActivitiesSummaryQuery,
  useGetApiSettingsUserManagementUserActivitiesUsersQuery,
  useGetApiSettingsUserManagementUserActivitiesReportQuery,
} = injectedRtkApi;

import React, { SetStateAction } from 'react';
import {
  IChangeOrder,
  IHardCostCode,
  IReallocation,
} from 'bundles/Construction/types';
import {
  FieldType,
  FieldTypeView,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { formatAmount } from '@/shared/lib/formatting/number';
import { treeJCCToOption } from 'bundles/Shared/helpers/job_cost_code';
import SelectCO from './components/SelectCO';
import SubReallocation from './components/SubReallocations';
import SubReallocationView from './components/SubReallocationsView';
import { generateHCC, transformIdToJCC } from './components/utils';
import { IJobCostCode } from 'types/JobCostCode';
import {
  getDocumentsFormField,
  getSetterForSharedFilesLoad,
} from 'bundles/Construction/components/Reallocation/Modals/utils';
import { ICreateReallocationFormData } from 'bundles/Construction/components/Reallocation/Modals/types';
import { transformCategoriesForTree } from 'bundles/DrawPackage/InvoicesFlow/Invoices/utils';

export const subReallocationsFulfilled = (
  subReallocations: IReallocation[] | undefined,
) =>
  !subReallocations ||
  subReallocations.every(
    (sub) => sub.moved_from_id && sub.moved_to_id && sub.value > 0,
  );

const getItemsReallocationModal = (
  formData: ICreateReallocationFormData,
  setFormData: React.Dispatch<SetStateAction<ICreateReallocationFormData>>,
  approvedChangeOrders: IChangeOrder[],
  hardConstructionCost: IHardCostCode,
  hardCostContingency: IHardCostCode,
  JCCForReallocations: IJobCostCode[],
  JCCTree,
): IGroupFormItem[] => {
  const approvedFiles = [formData.sharedFiles || []]
    .flat()
    .filter((file) => file.id);

  const qaItems = {
    yes: {
      active: formData?.sharedFilesLoad === 'YES',
      handle: () =>
        formData.sharedFiles?.length <= approvedFiles.length &&
        getSetterForSharedFilesLoad(setFormData)('YES'),
      disabled: !formData?.change_order_id,
    },
    no: {
      active:
        formData.sharedFiles?.length === approvedFiles.length &&
        formData?.sharedFilesLoad !== 'YES',
      handle: () =>
        getSetterForSharedFilesLoad(setFormData)('NO', approvedFiles),
      disabled: !formData?.change_order_id,
    },
  };
  const groupFormItems = [
    {
      title: 'Select Change Order',
      titleView: 'Selected Change Order Requests',
      required: true,
      activated: formData.value || formData?.changeOrderEventIds?.length,
      open: true,
      formItems: [
        {
          fieldName: 'change_order_id',
          fieldNameSnap: 'change_order_id',
          type: FieldType.CUSTOM,
          typeView: FieldTypeView.VIEW_CUSTOM,
          content: (
            <SelectCO
              data={approvedChangeOrders}
              formData={formData}
              setFormData={setFormData}
              selectable
              hardConstructionCost={hardConstructionCost}
              hardCostContingency={hardCostContingency}
            />
          ),
          contentView: (
            <SelectCO
              data={approvedChangeOrders.filter(
                (aco) => aco.id === formData.change_order_id,
              )}
              formData={formData}
              setFormData={setFormData}
              selectable={false}
              hardConstructionCost={hardConstructionCost}
              hardCostContingency={hardCostContingency}
            />
          ),
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_nomb',
            size: 'm',
          },
        },
      ],
      styles: {
        containerClassName: 'px-0 py-0',
      },
    },
    {
      title: 'REallocation Details',
      required: true,
      activated: subReallocationsFulfilled(formData.subReallocations),
      open: false,
      formItems: [],
    },
    {
      ...getDocumentsFormField(formData),
      activated:
        formData.change_order_id &&
        (formData.sharedFiles?.length > approvedFiles.length ||
          formData?.sharedFilesLoad !== 'YES'),
      qaItems,
    },
  ];

  if (formData.change_order_id) {
    const [, details] = groupFormItems;
    details.formItems.push(
      {
        fieldName: 'description',
        fieldNameSnap: 'description',
        type: FieldType.INPUT_TEXTAREA,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Detailed Description',
        titleView: 'Detailed Description',
        placeholder: 'Enter detailed description or other relevant data',
        required: true,
        optionalView: true,
        styles: {
          wrapper: 'w-full form-item_white',
        },
      },
      {
        fieldName: 'moved_from_id',
        fieldNameSnap: 'moved_from_id',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Moved From',
        titleView: 'Moved From',
        placeholder: 'Select',
        required: true,
        optionalView: true,
        transformerView: (id) => transformIdToJCC(id, JCCForReallocations),
        disabled: true,
        options: [
          Number(formData.value) < 0
            ? {
                label: generateHCC(hardConstructionCost),
                value: hardConstructionCost.id,
              }
            : {
                label: generateHCC(hardCostContingency),
                value: hardCostContingency.id,
              },
        ],
        styles: {
          wrapper: 'w-50',
        },
      },
      {
        fieldName: 'moved_to_id',
        fieldNameSnap: 'moved_to_id',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Moved To',
        titleView: 'Moved To',
        placeholder: 'Select',
        required: true,
        optionalView: true,
        transformerView: (id) => transformIdToJCC(id, JCCForReallocations),
        disabled: true,
        options: [
          Number(formData.value < 0)
            ? {
                label: generateHCC(hardCostContingency),
                value: hardCostContingency.id,
              }
            : {
                label: generateHCC(hardConstructionCost),
                value: hardConstructionCost.id,
              },
        ],
        styles: {
          wrapper: 'w-50',
        },
      },
      {
        fieldName: 'value',
        fieldNameSnap: 'value',
        type: FieldType.INPUT_NUMBER,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Final Amount',
        titleView: 'Final Amount',
        disabled: true,
        transformedValue: (value) => Math.abs(value),
        transformerView: (value) => formatAmount(Math.abs(value)),
        leftIcon: 'paid',
        placeholder: '0',
        required: true,
        optionalView: true,
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        thousandSeparator: true,
      },
    );

    Number(formData.value) > 0 &&
      details.formItems.push({
        fieldName: 'change_order_id',
        fieldNameSnap: 'change_order_id',
        type: FieldType.CUSTOM,
        typeView: FieldTypeView.VIEW_CUSTOM,
        content: (
          <SubReallocation
            formData={formData}
            setFormData={setFormData}
            hardConstructionCost={hardConstructionCost}
            hardCostContingency={hardCostContingency}
            JCCForReallocations={JCCForReallocations}
            JCCTree={JCCTree}
          />
        ),
        contentViewIndepented: true,
        contentView: (
          <SubReallocationView
            formData={formData}
            hardConstructionCost={hardConstructionCost}
            hardCostContingency={hardCostContingency}
            JCCForReallocations={JCCForReallocations}
            JCCTree={JCCTree}
          />
        ),
        required: true,
        optionalView: true,
        styles: {
          wrapper: 'w-full form-item_nomb',
          size: 'm',
        },
      });
  }

  return groupFormItems;
};
export default getItemsReallocationModal;

export const getItemsSubReallocationModal = (
  hardCostContingency: IHardCostCode,
  JCCForReallocations,
  JCCTree,
) => [
  {
    fieldName: 'moved_from_id',
    fieldNameSnap: 'moved_from_id',
    type: FieldType.TREE_SELECT,
    typeView: FieldTypeView.VIEW_TEXT,
    title: 'Moved From',
    titleView: 'Moved From',
    placeholder: 'Select',
    required: true,
    optionalView: true,
    transformerView: (id) => transformIdToJCC(id, JCCForReallocations),
    options: transformCategoriesForTree({
      categories: treeJCCToOption(JCCTree),
    }),
    styles: {
      wrapper: 'w-50',
      inputBackLayer: 'bg-white',
      size: 'm',
    },
  },
  {
    fieldName: 'moved_to_id',
    fieldNameSnap: 'moved_to_id',
    type: FieldType.SELECT,
    typeView: FieldTypeView.VIEW_TEXT,
    title: 'Moved To',
    titleView: 'Moved To',
    placeholder: 'Select',
    required: true,
    optionalView: true,
    transformerView: (id) => transformIdToJCC(id, JCCForReallocations),
    disabled: true,
    options: [
      {
        label: generateHCC(hardCostContingency),
        value: hardCostContingency.id,
      },
    ],
    styles: {
      wrapper: 'w-50',
    },
  },
  {
    fieldName: 'value',
    fieldNameSnap: 'value',
    type: FieldType.INPUT_NUMBER,
    typeView: FieldTypeView.VIEW_TEXT,
    title: 'Sub Amount',
    titleView: 'Sub Amount',
    transformerView: formatAmount,
    leftIcon: 'paid',
    placeholder: '0',
    required: true,
    optionalView: true,
    styles: {
      wrapper: 'w-25 form-item_white',
      size: 'm',
    },
    thousandSeparator: true,
  },
];

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { fetchUsersForReconcileSettings } from 'bundles/REconcile/actions/users';
import ThresholdCrud from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/cruds/ThresholdCrud';
import { fetchWorkflowThresholds } from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import { TWorkflowThresholdType } from 'bundles/REconcile/actions/workflowThresholds';

interface Props {
  legalEntityId: string;
}

function ThresholdsCruds({ legalEntityId }: Props) {
  const dispatch = useAppDispatch();
  const thresholds = useAppSelector(
    (state) => state.developmentLegalEntity.workflowThresholds,
  );
  const workflowSettings = useAppSelector(
    (state) => state.developmentLegalEntity.workflow,
  );

  const loadThresholds = async () => {
    dispatch(
      fetchWorkflowThresholds({
        legalEntityId,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchUsersForReconcileSettings());
  }, []);

  const getThresholdCrud = (thresholdType: TWorkflowThresholdType) => (
    <ThresholdCrud
      thresholdType={thresholdType}
      legalEntityId={legalEntityId}
      thresholds={thresholds}
      onThresholdsChanged={loadThresholds}
    />
  );

  return (
    <div className="flex flex-col gap-xl">
      {workflowSettings?.individualCorAmountThresholdEnabled &&
        getThresholdCrud(TWorkflowThresholdType.COR)}
      {workflowSettings?.contingencyThresholdEnabled &&
        getThresholdCrud(TWorkflowThresholdType.AGG)}
      {workflowSettings?.timingImpactThresholdEnabled &&
        getThresholdCrud(TWorkflowThresholdType.TIM)}
    </div>
  );
}

export default ThresholdsCruds;

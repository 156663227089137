import CustomPreview from 'bundles/Shared/components/FilePreviews/CustomPreview';
import React, { useEffect } from 'react';
import ApproversTable from './widgets/ApproversTable/ApproversTable';
import ApprovalNotification from './widgets/ApprovalNotification/ApprovalNotification';
import { useParams } from '@reach/router';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { fetchReconcileDevelopmentLegalEntity } from 'bundles/REconcile/reducers/developmentLegalEntitySlice';

const LegalEntityReconcileSettingsWorkflowApproversPage = () => {
  const params =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']>();
  const dispatch = useAppDispatch();
  const legalEntity = useAppSelector(
    (state) => state.developmentLegalEntity.data,
  );

  useEffect(() => {
    dispatch(fetchReconcileDevelopmentLegalEntity(params.legalEntityCode));
  }, []);

  return (
    <CustomPreview
      leftBlock={<ApproversTable legalEntityId={legalEntity?.id} />}
      classes={{
        contentContainer: 'w-[480px]',
        fileContainer: 'flex-1',
      }}
      withoutHeader
    >
      <ApprovalNotification legalEntityId={legalEntity?.id} />
    </CustomPreview>
  );
};

export default LegalEntityReconcileSettingsWorkflowApproversPage;

import { useDeleteApiSettingsReportBuilderTemplatesByIdMutation } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplatePreviewDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { TemplateAssetsInUse } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateAssetsInUse';
import {
  useModal,
  useResetAllModalsOnUnmount,
} from '@/shared/lib/hooks/useModal';
import { useCallback } from 'react';

export const useReportBuilderTemplateDelete = () => {
  const [deleteTemplate, options] =
    useDeleteApiSettingsReportBuilderTemplatesByIdMutation();
  const { confirm } = useModal();

  useResetAllModalsOnUnmount();

  return [
    useCallback(async (template: ReportBuilderTemplatePreviewDto) => {
      const res = await confirm({
        title: (
          <>
            <p>Delete template</p>
          </>
        ),
        subtitle: (
          <div className="flex flex-col gap-tw-4">
            {template.assets.length > 0 && (
              <p className="inline-regular">
                This Template is used in{' '}
                <TemplateAssetsInUse assets={template.assets} />
              </p>
            )}
            <p>Are you sure you want to delete this template?</p>
          </div>
        ),
      });
      if (!res) return;
      return deleteTemplate({ id: template.id });
    }, []),
    options,
  ] as const;
};

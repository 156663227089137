import http from 'lib/http';

export const FETCH_INVESTMENT_ENTITIES = 'symmetre-client-api/FETCH_INVESTMENT_ENTITIES';
export const UPDATE_INVESTMENT_ENTITY = 'symmetre-client-api/UPDATE_INVESTMENT_ENTITY';
export const CREATE_INVESTMENT_ENTITY = 'symmetre-client-api/CREATE_INVESTMENT_ENTITY';
export const DELETE_INVESTMENT_ENTITY = 'symmetre-client-api/DELETE_INVESTMENT_ENTITY';

export function fetchInvestmentEntities() {
  return async (dispatch) => {
    const res = await http.get('/investment_entities');
    const responseData = await res.json();

    dispatch({
      type: FETCH_INVESTMENT_ENTITIES,
      payload: responseData,
    });
  };
}

export function createInvestmentEntity(data) {
  return async (dispatch) => {
    const res = await http.post('/investment_entities', data);

    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Entity has been successfully created');

      dispatch({
        type: CREATE_INVESTMENT_ENTITY,
        payload: responseData,
      });
    }
  };
}

export async function createEntity(data) {
  const res = await http.post('/investment_entities', data);
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Entity has been successfully created');
  }
  return responseData;
}

export function editInvestmentEntity(data) {
  return async (dispatch) => {
    const res = await http.put(`/investment_entities/${data.id}`, data);

    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Entity has been successfully updated');

      dispatch({
        type: UPDATE_INVESTMENT_ENTITY,
        payload: responseData,
      });
    }
  };
}

export function deleteInvestmentEntity(investmentEntity) {
  return async (dispatch) => {
    const res = await http.del(`/investment_entities/${investmentEntity.id}`);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Entity has been successfully removed');

      dispatch({
        type: DELETE_INVESTMENT_ENTITY,
        payload: investmentEntity,
      });
    }
  };
}

import http from 'lib/http';
import { TDevelopmentLegalEntityReconcileSettings } from 'bundles/REconcile/types/TDevelopmentLegalEntityReconcileSettings';
import { PropertiesCamelToSnakeCase } from 'types/StringUtilityTypes';
import { IJobCostCode } from 'types/JobCostCode';

export const fetchLegalEntityLegacySettings = async (legalEntityId: number) => {
  const res = await http.get(
    `/settings/reconcile/legal_entities/${legalEntityId}/legacy`,
  );
  const json = await res.json();

  return json as TDevelopmentLegalEntityReconcileSettings;
};

type TUpdatableFields = Pick<
  TDevelopmentLegalEntityReconcileSettings,
  | 'hardConstructionCost'
  | 'hardCostContingency'
  | 'siteworkContingency'
  | 'completedAndStored'
  | 'retainage'
  | 'previousCertifiedWorks'
  | 'hardConstructionCostCodeId'
  | 'hardCostContingencyCodeId'
  | 'softCostContingencyCodeId'
  | 'initialEstimatedCompletionDate'
  | 'contractorRetainageValue'
>;

export type TUpdateLegalEntityLegacySettingsRequest =
  PropertiesCamelToSnakeCase<TUpdatableFields>;

export const updateLegalEntityLegacySettings = async (
  legalEntityId: string,
  config: TUpdateLegalEntityLegacySettingsRequest,
) => {
  const res = await http.put(
    `/settings/reconcile/legal_entities/${legalEntityId}/legacy/`,
    config,
  );
  const json = await res.json();

  return json as TDevelopmentLegalEntityReconcileSettings;
};

export type TReconcileSettingsJCC = Pick<IJobCostCode, 'id' | 'name' | 'code'>;

export const plainFetchJccForReconcileSettings = async (
  legalEntityId: string,
) => {
  const res = await http.get(
    `/settings/reconcile/legal_entities/${legalEntityId}/job_cost_codes`,
  );
  const json = await res.json();

  return json as { items: TReconcileSettingsJCC[] };
};

import { useState } from 'react';
import { TSort } from 'types/Sort';
import { GENERAL_LEDGERS_COLUMN_IDS } from 'bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import type * as Local from '../types';
import { GetApiSettingsReportGeneralLedgersApiArg } from 'bundles/Settings/actions/report/generalLedgersApiGeneratedApi';

const DEFAULT_SIZE_PER_PAGE = 50;
const DEFAULT_SCOPE: Local.GLScope = 'active-and-not-mapped';
const DEFAULT_SORT_FIELD: Local.GLSortFields = 'line_items_count';

export function useGLPageParams() {
  const [pageParams, setPageParams] =
    useState<GetApiSettingsReportGeneralLedgersApiArg>({
      page: 1,
      perPage: DEFAULT_SIZE_PER_PAGE,
      sortField: DEFAULT_SORT_FIELD,
      sortOrder: TSort.DESC,
      searchQuery: '',
      scope: DEFAULT_SCOPE,
      balanceType: 'All',
      reportType: 'All',
      legalEntityIds: [],
      sources: [],
    });

  const setGLScope = (
    scope: GetApiSettingsReportGeneralLedgersApiArg['scope'],
  ) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      group:
        scope === 'active-and-mapped'
          ? [GENERAL_LEDGERS_COLUMN_IDS.categories]
          : undefined,
      scope,
      page: 1,
    }));
  };

  const setLegalEntityIds = (
    legalEntityIds: GetApiSettingsReportGeneralLedgersApiArg['legalEntityIds'],
  ) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      legalEntityIds,
      page: 1,
    }));
  };

  const setSources = (sources: string[]) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      sources,
      page: 1,
    }));
  };

  const setSearchQuery = (query: string) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      query,
      page: 1,
    }));
  };

  const setCurrentPage = (page: number) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page,
    }));
  };

  const setSortParams = (
    sortObj: GetApiSettingsReportGeneralLedgersApiArg['sort'],
  ) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      sort: sortObj,
    }));
  };

  const setGroup = (
    group: GetApiSettingsReportGeneralLedgersApiArg['group'],
  ) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      group,
    }));
  };

  const toggleFlagged = () => {
    setPageParams((prevParams) => ({
      ...prevParams,
      flagged: !prevParams.flagged,
      page: 1,
    }));
  };
  const toggleMisclassified = () => {
    setPageParams((prevParams) => ({
      ...prevParams,
      misclassified: !prevParams.misclassified,
      page: 1,
    }));
  };
  const disableMisclassified = () => {
    setPageParams((prevParams) => ({
      ...prevParams,
      misclassified: false,
      page: 1,
    }));
  };

  return {
    pageParams,
    setGLScope,
    setLegalEntityIds,
    setSources,
    setSearchQuery,
    setCurrentPage,
    setSortParams,
    setGroup,
    toggleFlagged,
    toggleMisclassified,
    disableMisclassified,
  };
}

import { capitalInvestmentObjectsEnhancedApi } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  PutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleApiResponse,
  capitalLegalEntitiesApi,
} from '@/entities/return/api/capitalLegalEntitiesGeneratedApi';

export const capitalLegalEntitiesEnhancedApi =
  capitalLegalEntitiesApi.enhanceEndpoints({
    endpoints: {
      putApiCapitalLegalEntitiesByLegalEntityIdPublishToggle: {
        transformResponse: (
          response: PutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleApiResponse,
        ) => {
          toastr.success('Legal Entity updated');
          return response;
        },
        async onQueryStarted(
          { legalEntityId, capitalInvestmentObjectId },
          { dispatch, queryFulfilled },
        ) {
          const patchResult = dispatch(
            capitalInvestmentObjectsEnhancedApi.util.updateQueryData(
              'getApiCapitalInvestmentObjectsById',
              {
                id: capitalInvestmentObjectId,
              },
              (draft) => {
                Object.assign(draft, {
                  legalEntities: draft.legalEntities.map((le) => {
                    if (le.id === legalEntityId) {
                      return {
                        ...le,
                        isPublished: !le.isPublished,
                      };
                    }
                    return le;
                  }),
                });
              },
            ),
          );
          await queryFulfilled.catch(patchResult.undo);
          dispatch(
            capitalInvestmentObjectsEnhancedApi.util.invalidateTags([
              'CapitalInvestmentObject',
            ]),
          );
        },
      },
    },
  });

export const {
  usePutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleMutation,
} = capitalLegalEntitiesApi;

import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { TSort } from '@/types/Sort';

export const useSortSearchParams = ({
  defaultSortOrder,
  defaultSortField,
}: {
  defaultSortOrder: FlattenEnum<TSort>;
  defaultSortField: string;
}) => {
  const sortOrderKey = 'sortOrder';
  const sortFieldKey = 'sortField';

  const [searchParams, setSearchParams] = useSearchParams();

  const sortOrder = (searchParams.get(sortOrderKey) ??
    defaultSortOrder) as TSort;

  const sortField = searchParams.get(sortFieldKey) ?? defaultSortField;

  const handleToggleSort = () => {
    setSearchParams((prev) => {
      const newSortOrderYear = sortOrder === TSort.ASC ? TSort.DESC : TSort.ASC;

      prev.set(sortOrderKey, newSortOrderYear);

      return prev;
    });
  };

  return {
    sortField,
    sortOrder,
    onSortToggle: handleToggleSort,
  };
};

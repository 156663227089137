import React from 'react';
import { Icon, IconButton } from 'stories';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';
import { PhoneLink } from 'bundles/Shared/shared/PhoneLink';

interface Props {
  onDelete: () => void;
  onEdit: () => void;
  canManage: boolean;
  keyDealTeamContact: {
    name: string;
    role: string;
    email?: string;
    phone?: string;
  };
}

export const AssetKeyDealTeamContactCard = ({
  keyDealTeamContact,
  canManage,
  onEdit,
  onDelete,
}: Props) => (
  <div className="border-1 rounded-2xl border-solid border-neutral-050 bg-neutral-100 p-tw-4">
    <div className="flex justify-between">
      <div>
        <div className="body-semibold text-neutral-900">
          {keyDealTeamContact.name}
        </div>
        <div className="inline-regular text-neutral-450">
          {keyDealTeamContact.role}
        </div>
      </div>
      {canManage && (
        <div>
          <IconButton onClick={onEdit} variant="secondary" iconName="edit" />
          <IconButton
            onClick={onDelete}
            className="ml-s"
            variant="secondary"
            iconName="trash"
          />
        </div>
      )}
    </div>
    {(keyDealTeamContact.email || keyDealTeamContact.phone) && (
      <div className="mt-tw-2 flex flex-col gap-tw-2">
        {keyDealTeamContact.email && (
          <div className="flex items-center">
            <div className="inline-flex h-[24px] w-[24px] items-center justify-center rounded-lg bg-neutral-000 p-tw-1 text-center text-neutral-450">
              <Icon iconName="email" />
            </div>
            <EmailLink
              className="inline-regular ml-s"
              email={keyDealTeamContact.email}
            >
              {keyDealTeamContact.email}
            </EmailLink>
          </div>
        )}
        {keyDealTeamContact.phone && (
          <div className="flex items-center">
            <div className="inline-flex h-[24px] w-[24px] items-center justify-center rounded-lg bg-neutral-000 p-tw-1 text-center text-neutral-450">
              <Icon iconName="phone" />
            </div>
            <PhoneLink
              phone={keyDealTeamContact.phone}
              className="inline-regular ml-s"
            >
              {keyDealTeamContact.phone}
            </PhoneLink>
          </div>
        )}
      </div>
    )}
  </div>
);

export default AssetKeyDealTeamContactCard;

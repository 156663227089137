import { IBudgetVarianceComment } from 'bundles/REconcile/types/Comments';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as commentsApi from 'bundles/REconcile/actions/developmentBudgetVarianceComments';
import { LegalEntity } from '@/entities/core/legalEntity';
import { TRootState } from '@/app/stores';

interface DevelopmentBudgetVarianceCommentState {
  items: IBudgetVarianceComment[];
  openedComment: IBudgetVarianceComment | null;
  openedReplies: boolean;
  mentionableUsers: { id: number; fullName: string }[];
  typeCommentsPanel: 'one' | 'many';
}

const initialState: DevelopmentBudgetVarianceCommentState = {
  items: [],
  mentionableUsers: [],
  openedComment: null,
  openedReplies: false,
  typeCommentsPanel: 'many',
};

export const fetchDevelopmentBudgetVarianceComments = createAsyncThunk(
  'developmentBudgetVariance/comments/items',
  async (legalEntityCode: LegalEntity['code']) =>
    commentsApi.getDevelopmentBudgetVarianceComments({
      legalEntityCode,
    }),
);

export const fetchMentionableUsers = createAsyncThunk(
  'developmentBudgetVariance/comments/users',
  (legalEntityCode: LegalEntity['code']) =>
    commentsApi.fetchMentionableUsers({ legalEntityCode }),
);

export const replyDevelopmentBudgetVarianceComment = createAsyncThunk(
  'developmentBudgetVariance/replyComment',
  async (
    request: commentsApi.IReplyToDevelopmentBudgetVarianceCommentRequest,
  ) => commentsApi.replyToDevelopmentBudgetVarianceComment(request),
);

export const addRequisitionComment = createAsyncThunk(
  'developmentBudgetVariance/comments/addRequistionComment',
  async (request: commentsApi.IAddRequisitionCommentRequest) => {
    const comment = await commentsApi.addRequisitionComment(request);
    const { development_categorizable_id, development_categorizable_type } =
      request;
    return {
      ...comment,
      development_categorizable_id,
      development_categorizable_type,
    };
  },
);

export const developmentBudgetVarianceCommentsSlice = createSlice({
  name: 'developmentBudgetVariance/comments',
  initialState,
  reducers: {
    openComment: (
      state,
      action: PayloadAction<{
        typeCommentsPanel: DevelopmentBudgetVarianceCommentState['typeCommentsPanel'];
        commentId?: string;
      }>,
    ) => {
      state.typeCommentsPanel = action.payload.typeCommentsPanel;
      state.openedComment = action.payload?.commentId
        ? state.items.find((c) => c.id === action.payload?.commentId)
        : null;
    },
    openReplies: (state, action: PayloadAction<boolean>) => {
      state.openedReplies = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchDevelopmentBudgetVarianceComments.fulfilled,
      (state, action) => {
        state.items = action.payload;
      },
    );

    builder.addCase(
      replyDevelopmentBudgetVarianceComment.fulfilled,
      (state, action) => {
        const newComment = action.payload;
        const parentComment = state.items.find(
          (c) => c.id === newComment.parentId,
        );

        parentComment.replies.push(newComment);
      },
    );

    builder.addCase(addRequisitionComment.fulfilled, (state, action) => {
      const newComment = action.payload;

      state.items.push(newComment);
    });

    builder.addCase(fetchMentionableUsers.fulfilled, (state, action) => {
      state.mentionableUsers = action.payload;
    });
  },
});

export const { openComment, openReplies } =
  developmentBudgetVarianceCommentsSlice.actions;

export const selectTypeCommentsPanel = (state: TRootState) =>
  state.developmentBudgetVariance.comments.typeCommentsPanel;
export const selectOpenedComment = (state: TRootState) =>
  state.developmentBudgetVariance.comments.openedComment;
export const selectCommentsItems = (state: TRootState) =>
  state.developmentBudgetVariance.comments.items;
export const selectOpenedReplies = (state: TRootState) =>
  state.developmentBudgetVariance.comments.openedReplies;

export default developmentBudgetVarianceCommentsSlice.reducer;

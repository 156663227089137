import { useCallback, useState } from 'react';

interface ItemBase {
  id: string | number;
}

export function useItems<T extends ItemBase>(
  initialValue?: T[],
): [
  T[],
  React.Dispatch<React.SetStateAction<T[]>>,
  {
    onAddItem: (item: T) => void;
    onAddBulkItems: (newItems: T[]) => void;
    onRemoveItemById: (id: T['id']) => void;
    onReset: VoidFunction;
    onChangeItemById: (id: T['id'], item: Partial<T>) => void;
  },
] {
  const [items, setItems] = useState<T[]>(initialValue ?? []);

  const onRemoveItemById = useCallback((id: T['id']) => {
    setItems((prev) => prev.filter((tx) => tx.id !== id));
  }, []);

  const onAddItem = useCallback((item: T) => {
    setItems((prev) => [...prev, item]);
  }, []);

  const onAddBulkItems = useCallback((newItems: T[]) => {
    setItems((prev) => [...prev, ...newItems]);
  }, []);

  const onReset = useCallback(() => {
    setItems([]);
  }, []);

  const onChangeItemById = useCallback((id: T['id'], item: Partial<T>) => {
    setItems((prev) =>
      prev.map((tx) => {
        if (tx.id !== id) return tx;

        return {
          ...tx,
          ...item,
        };
      }),
    );
  }, []);

  const actions = {
    onAddItem,
    onAddBulkItems,
    onRemoveItemById,
    onReset,
    onChangeItemById,
  };

  return [items, setItems, actions];
}

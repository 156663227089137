import React, { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '..';
import styles from './StepperItem.module.scss';

export interface IStepperItemProps {
  done: boolean;
  title: string;
  value: string;
  activeStep: string;
  firstItem: boolean;
  lastItem: boolean;
}

export const StepperItem: FC<IStepperItemProps> = ({
  done,
  title,
  value,
  activeStep,
  firstItem,
  lastItem,
}) => (
  <div className="relative flex flex-1 flex-col items-center gap-tw-0.5">
    {!firstItem && <div className={cn(styles.line, styles.lineLeft)} />}
    {!lastItem && <div className={cn(styles.line, styles.lineRight)} />}
    <Icon
      iconName="checkSmall"
      color="white"
      className={cn('h-[16px] w-[16px] bg-light-20', {
        'green-dark-1 bg-green-light-4': done && activeStep !== value,
        'bg-light-90 text-white': activeStep === value,
      })}
    />
    <div
      className={cn('light-60 inline-semibold', {
        'light-90': done,
      })}
    >
      {title}
    </div>
  </div>
);

import React, { useState } from 'react';
import { Button, Modal, ModalActions } from 'stories';
import { IUser } from 'types/User';
import InvestmentEntityForm from './InvestmentEntityForm';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  InvestmentEntity,
  VendorEntity,
} from 'bundles/Settings/actions/entities';
import { InvestmentEnitityMutationRequest } from 'bundles/Settings/components/Portal/InvestmentEntities/api/investmentEntitiesApi';
import { CoreFiltersUsers } from 'bundles/UserManagement/api/settingsCoreLegalEntitiesApi';

interface Props extends DialogProps<InvestmentEnitityMutationRequest> {
  allUsers: CoreFiltersUsers[];
  allVendorEntities: VendorEntity[];
  currentItem?: InvestmentEntity;
}

const InvestmentEntityModal = ({
  onSubmit,
  onClose,
  currentItem,
  allUsers,
  allVendorEntities,
}: Props) => {
  const [investmentEntity, setInvestmentEntity] = useState(currentItem);

  const action = currentItem ? 'Edit' : 'Create';

  return (
    <Modal
      header={`${action} Investment Entity`}
      toggle={onClose}
      classes={{ body: 'pb-m' }}
      actions={
        <ModalActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!investmentEntity?.name}
            variant="success"
            onClick={() => {
              onSubmit?.({
                id: investmentEntity?.id,
                investment_entity: {
                  name: investmentEntity?.name,
                  user_ids: investmentEntity?.users?.map(({ id }) => id),
                  vendor_entity_ids: investmentEntity?.vendorEntities?.map(
                    ({ id }) => id,
                  ),
                },
              });
            }}
          >
            {action} Investment Entity
          </Button>
        </ModalActions>
      }
    >
      <InvestmentEntityForm
        allUsers={allUsers}
        allVendorEntities={allVendorEntities}
        investmentEntity={investmentEntity}
        setInvestmentEntity={setInvestmentEntity}
      />
    </Modal>
  );
};

export default InvestmentEntityModal;

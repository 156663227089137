import React, { useMemo } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { useDrag } from 'react-dnd';
import {
  AllWidgetTypes,
  ReportDashboardSectionPositionLayouts,
  ReportDashboardType,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import {
  COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
  EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP,
  OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP,
  WIDGETS_CONFIG_MAP,
} from '../../widgets/config';
import { Tooltip } from 'stories';
import { AvailableWidgetsModal } from './AvailableWidgetsModal';
import {
  WidgetPreview,
  WidgetSearchPreview,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import useBoolean from '@/shared/lib/hooks/useBoolean';

const DraggableWidgetContainer = ({
  children,
  className,
  id,
  ...props
}: React.PropsWithChildren<
  React.AllHTMLAttributes<HTMLDivElement> & {
    id: string;
  }
>) => {
  const [_, drag] = useDrag({
    item: {
      type: 'dashboard-widget',
      id,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
      draggable
      className={cn('inline-flex cursor-pointer', className)}
      {...props}
    >
      {children}
    </div>
  );
};

const MAX_DISPLAYED_WIDGETS = 3;

export function DashboardWidgetsBar({
  ...props
}: Pick<React.AllHTMLAttributes<HTMLDivElement>, 'onDragStart'> & {
  layout: ReportDashboardSectionPositionLayouts;
}) {
  const { dashboardType: type } = useDashboardContext();
  const { toggle: toggleModal, value: isModalOpen } = useBoolean();

  const widgetsMap = useMemo(() => {
    switch (type) {
      case ReportDashboardType.OBJECT: {
        return OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
    }
  }, [type]);

  return (
    <div className="fixed bottom-tw-6 left-1/2 flex justify-center" {...props}>
      <div
        style={{
          backdropFilter: 'blur(8px)',
        }}
        className="flex gap-tw-4 rounded-[32px] bg-[rgba(226,226,226,0.29)] p-tw-4  shadow-[0px_4px_16px_0px_rgba(144,144,144,0.25)]"
      >
        {/* <DragWidgetLayer />*/}
        {Object.keys(widgetsMap)
          .slice(0, MAX_DISPLAYED_WIDGETS)
          .map((widgetKey: AllWidgetTypes) => (
            <DraggableWidgetContainer key={widgetKey} id={widgetKey}>
              <Tooltip mainText={WIDGETS_CONFIG_MAP[widgetKey]?.title}>
                <WidgetPreview type={widgetKey} />
              </Tooltip>
            </DraggableWidgetContainer>
          ))}

        <WidgetSearchPreview onClick={toggleModal} />
        {isModalOpen && (
          <AvailableWidgetsModal
            layout={props.layout}
            onClose={toggleModal}
            type={type}
          />
        )}
      </div>
    </div>
  );
}

import { stringify } from 'qs';
import http from 'lib/http';

type TEntityIdPathParameters = {
  entityId: number | string;
};

function getPathWithEntityId(path: string) {
  return `${path}/:entityId`;
}

export function replacePathParams(
  path: string,
  parameters: Record<string, string> = {},
) {
  return Object.keys(parameters).reduce(
    (finalPath, paramKey) =>
      finalPath.replace(`:${paramKey}`, parameters[paramKey]),
    path,
  );
}

export function checkErrors(result: Record<string, unknown>) {
  if (result.errors) {
    return true;
  }

  return false;
}

export function toastErrors(
  success: boolean,
  {
    successMessage,
    errorMessage,
  }: {
    successMessage: string;
    errorMessage: string;
  },
) {
  if (!success) {
    toastr.error(errorMessage);
    return;
  }
  toastr.success(successMessage);
}

function checkCrudErrors(
  operation: 'create' | 'update' | 'delete',
  result: Record<string, unknown>,
  entityName: string,
) {
  const success = !checkErrors(result);
  toastErrors(success, {
    errorMessage: Array.isArray(result.errors)
      ? result.errors.join('\n')
      : result.errors,
    successMessage: `${entityName} has been ${operation}d`,
  });
  return !success;
}

/**
 *
 * @param path
 * path string with params or without
 * format '/crud/:param/entities'
 * @param entityName
 * will be displayed in errors
 */
export function createCrudApi<
  PathParameters extends Record<string, string>,
  GetEntitiesRequestParams,
  Entity,
  EntityUpdate,
  GetEntitiesResponse = Entity[],
>(path: string, entityName: string) {
  const getEntities = async (
    pathParameters: PathParameters,
    request: GetEntitiesRequestParams,
  ) => {
    const getEntitiesPath = replacePathParams(path, pathParameters);
    const params = stringify(request, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    });
    const result = await http.get(`${getEntitiesPath}${params}`);
    const json = await result.json();
    return json as GetEntitiesResponse;
  };

  const createEntity = async (
    pathParameters: PathParameters,
    request: EntityUpdate,
  ) => {
    const createEntityPath = replacePathParams(path, pathParameters);
    const result = await http.post(createEntityPath, request);
    const json = await result.json();

    if (checkCrudErrors('create', json, entityName)) {
      return undefined;
    }

    return json as Entity;
  };

  const updateEntity = async (
    pathParameters: PathParameters & TEntityIdPathParameters,
    request: EntityUpdate,
  ) => {
    const updatePath = replacePathParams(
      getPathWithEntityId(path),
      pathParameters,
    );
    const result = await http.put(updatePath, request);
    const json = await result.json();

    if (checkCrudErrors('update', json, entityName)) {
      return undefined;
    }

    return json as Entity;
  };

  const deleteEntity = async (
    pathParameters: PathParameters & TEntityIdPathParameters,
  ) => {
    const deletePath = replacePathParams(
      getPathWithEntityId(path),
      pathParameters,
    );
    const result = await http.del(deletePath);
    let json;
    try {
      json = await result.json();
    } catch (e) {
      json = {};
      if (!result.ok) {
        json.errors = ['Something went wrong'];
      }
    }

    return !checkCrudErrors('delete', json, entityName);
  };

  return {
    getEntities,
    createEntity,
    updateEntity,
    deleteEntity,
    entityName,
  };
}

import { stringify } from 'qs';
import http from '../../../lib/http';

export const FETCH_USER_ROLES = 'symmetre-client-api/FETCH_USER_ROLES';

export function fetchUserRoles(data = {}) {
  return async (dispatch) => {
    const res = await http.get(`/user_roles${stringify(data, { addQueryPrefix: true })}`);
    const responseData = await res.json();

    dispatch({
      type: FETCH_USER_ROLES,
      payload: responseData,
    });
  };
}

export async function plainFetchUserRoles(data = {}) {
  const res = await http.get(`/user_roles${stringify(data, { addQueryPrefix: true })}`);
  const responseData = await res.json();
  return responseData;
}

import React, { FC, SetStateAction, useState, useCallback } from 'react';
import { useGetNoPaidInvoicesQuery } from 'bundles/Construction/api/invoices';
import { Modal, AnimationLoader, Button } from 'stories';
import Table from 'bundles/Shared/components/Table/Table';
import {
  PageParamsSearch,
  PageParamsProvider,
} from 'bundles/Shared/components/pageParams';
import { TSort } from 'types/Sort';
import {
  useNoPaidInvoicesColumns,
  TFilterModelNoPaidInvoices,
  TColumnIdsNoPaidInvoices,
} from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/useColumns';
import {
  Invoice,
  PageParamsDrawPackage,
  Vendor,
} from '@/shared/types/reconcile/Invoice';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props extends DialogProps {
  legalEntityCode: LegalEntity['code'];
  selectedInvoices: Invoice[];
  setSelectedInvoices: SetStateAction<Invoice[]>;
  allocatedAmount: number;
}

export interface IPageParamsNoPaidInvoices
  extends PageParamsDrawPackage<
    | TColumnIdsNoPaidInvoices.DATE
    | TColumnIdsNoPaidInvoices.NUMBER
    | TColumnIdsNoPaidInvoices.AMOUNT
  > {
  vendor_ids?: Vendor['id'][];
}

const AddInvoicesModal: FC<Props> = ({
  onClose,
  legalEntityCode,
  selectedInvoices,
  setSelectedInvoices,
  allocatedAmount,
}) => {
  const [pageParams, setPageParams] = useState<IPageParamsNoPaidInvoices>({
    page: 1,
    per_page: 50,
    vendor_ids: [],
    sort: {
      order: TSort.ASC,
      field: TColumnIdsNoPaidInvoices.DATE,
    },
    amount: {
      from: '',
      to: String(allocatedAmount),
    },
  });

  const [localSelectInvoices, setLocalSelectedInvoices] =
    useState<Invoice[]>(selectedInvoices);

  const {
    total,
    allocatedAmount: allocatedAmountInvoices,
    allocationFlags,
  } = useAllocation({
    total: allocatedAmount,
    items: localSelectInvoices,
    sumMapper: ({ amount }) => Number(amount),
  });

  const { isFullyAllocated } = allocationFlags;

  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const { data: invoices, isLoading } = useGetNoPaidInvoicesQuery({
    legalEntityCode,
    pageParams,
  });

  const { columns } = useNoPaidInvoicesColumns();

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModelNoPaidInvoices) =>
      setPageParams((prevParams) => ({
        ...prevParams,
        vendor_ids: filterModel.vendor_ids?.map((item): number => item.id),
        amount: filterModel.amount,
      })),
    [],
  );

  const addInvoices = () => {
    setSelectedInvoices(localSelectInvoices);
    onClose();
  };
  return (
    <Modal
      size="900"
      classes={{
        footerAdditionalActions: 'bg-white',
      }}
      header={<div className="dark-60 header6-bold">Add Invoices</div>}
      toggle={() => onClose()}
      additionalActions={
        <div className="flex w-full justify-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={addInvoices}
            disabled={!isFullyAllocated}
          >
            Add Invoices
          </Button>
        </div>
      }
      bodyPadding="0"
    >
      {isLoading ? (
        <div className="h-[400px]">
          <AnimationLoader />
        </div>
      ) : (
        <PageParamsProvider
          pageParams={pageParams}
          setPageParams={setPageParams}
        >
          <div className="flex flex-col gap-tw-6 bg-light px-tw-6 py-tw-4">
            <AllocationStatistics
              className="px-tw-4 pt-tw-4"
              totalAmount={total}
              allocatedAmount={allocatedAmountInvoices}
            />
            <PageParamsSearch className="w-full" />
            <Table
              settings={settings}
              setSettings={setSettings}
              loading={isLoading}
              columns={columns}
              onFilterModelChange={handleFilterModelChange}
              items={invoices?.items ?? []}
              borderLessColumns
              borderLessOutside
              classes={{
                th: 'bg-light',
              }}
              selectedRows={localSelectInvoices}
              setSelectedRows={setLocalSelectedInvoices}
            />
          </div>
        </PageParamsProvider>
      )}
    </Modal>
  );
};

export default AddInvoicesModal;

import { useGetFeedMessageQuery } from 'bundles/InvestmentObjects/components/Overview/Posts/api/feedMessagesApi';
import { AnimationLoader, Button, Modal } from '@/stories';
import Post from 'bundles/InvestmentObjects/components/Overview/Posts/Post';
import React from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useNavigate } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { capitalize } from 'lodash-es';

interface Props extends DialogProps {
  id: number;
}

export const FeedMessageModal = ({ id, onClose }: Props) => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetFeedMessageQuery({
    id,
  });

  return (
    <Modal
      header="Feed Message Post"
      toggle={onClose}
      size="lg"
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              if (data?.feedMessageable.objectType == 'asset') {
                navigate(
                  generateUrl(ROUTES_ROOT.assets.asset.fullPath, {
                    pathParams: {
                      assetSlug: data!.feedMessageable.slug,
                    },
                  }),
                );
              }

              if (data?.feedMessageable.objectType == 'fund') {
                navigate(
                  generateUrl(ROUTES_ROOT.funds.fund.fullPath, {
                    pathParams: {
                      fundSlug: data!.feedMessageable.slug,
                    },
                  }),
                );
              }
              onClose();
            }}
          >
            Open in {capitalize(data?.feedMessageable.objectType)}
          </Button>
        </>
      }
      classes={{
        header: 'bg-light',
        body: 'bg-light min-h-[300px]',
        footer: 'bg-light',
      }}
    >
      {isLoading && <AnimationLoader />}
      {!isLoading && !data && <div>Feed message not found</div>}
      {data && <Post feedMessage={data} expanded />}
    </Modal>
  );
};

import React from 'react';
import { Button } from 'stories';
import { useCrudContext } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

type Props = Omit<React.ComponentProps<typeof Button>, 'onClick'>;

export function CrudCreateButton({ ...props }: Props) {
  const { entityName, handlers } = useCrudContext();
  return (
    <Button onClick={handlers.create} variant="primary" size="s" {...props}>
      Add {entityName}
    </Button>
  );
}

export default CrudCreateButton;

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '@/app/stores';
import { IStepperItemProps } from 'stories/StepperItem/StepperItem';
import { clearInvoiceLineItems } from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import { stringify } from 'qs';
import { API_URL } from 'lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import { PayableTeamContact } from '@/shared/types/reconcile/Invoice';

export interface IInvoiceDetails {
  number: string;
  date: string;
  vendor_name: {
    value: string;
  };
  amount: string;
}

export type TESignTypeStamp = 'toggle' | 'colorPicker';
export type TESignInvolved = 'first' | 'all';
export type TESignPlacement =
  | 'center_center'
  | 'left_center'
  | 'right_center'
  | 'center_top'
  | 'left_top'
  | 'right_top'
  | 'center_bottom'
  | 'left_bottom'
  | 'right_bottom';

interface StampSettings<TValue> {
  type: TESignTypeStamp;
  value: TValue;
  fieldName: string;
  title: string;
}

export interface IInvoiceESignData {
  stamp: {
    base: {
      name: StampSettings<boolean>;
      date: StampSettings<boolean>;
      signature: StampSettings<boolean>;
    };
    additional: {
      border: StampSettings<boolean>;
      header: StampSettings<boolean>;
      color: StampSettings<string>;
    };
  };
  involved_pages: TESignInvolved;
  placement: TESignPlacement;
}

export interface IMarkForPaymentData {
  subject: string;
  body: string;
  saveAsTemplate: boolean;
  payableTeam?: PayableTeamContact;
}

export interface IMarkForPaymentDataSlice {
  subject?: string;
  body?: string;
  saveAsTemplate?: boolean;
  payableTeam?: PayableTeamContact;
}

interface IInitialState {
  invoiceDocumentLink: string | null;
  lineItemSelectText: string;
  activeStep: 'details' | 'lineItems' | 'eSign' | 'review';
  verifySteps: IStepperItemProps[];
  invoiceData: {
    details: IInvoiceDetails;
    lineItems: null;
  };
  eSignData: IInvoiceESignData;
  eSignDataDocument: boolean;
  editChanging: boolean;
  markForPaymentData: IMarkForPaymentData;
}

const VERIFY_STEPS = [
  {
    done: true,
    title: 'Invoice Details',
    value: 'details',
  },
  {
    done: false,
    title: 'Line Items',
    value: 'lineItems',
  },
  {
    done: false,
    title: 'e-Signature',
    value: 'eSign',
  },
  {
    done: false,
    title: 'Review',
    value: 'review',
  },
];

const initialState: IInitialState = {
  invoiceDocumentLink: null,
  lineItemSelectText: 'Create Manually',
  activeStep: 'details',
  verifySteps: VERIFY_STEPS,
  invoiceData: {
    details: {
      amount: '',
      date: '',
      number: '',
      vendor_name: '',
    },
    lineItems: null,
  },
  eSignData: {
    stamp: {
      base: {
        name: {
          type: 'toggle',
          value: true,
          fieldName: 'show_name',
          title: 'Name',
        },
        date: {
          type: 'toggle',
          value: true,
          fieldName: 'show_date',
          title: 'Date',
        },
        signature: {
          type: 'toggle',
          value: true,
          fieldName: 'show_signature',
          title: 'Signature',
        },
      },
      additional: {
        border: {
          type: 'toggle',
          value: true,
          fieldName: 'show_border',
          title: 'Border',
        },
        header: {
          type: 'toggle',
          value: true,
          fieldName: 'show_header',
          title: 'Header',
        },
        color: {
          type: 'colorPicker',
          value: '#F6413E',
          fieldName: 'color',
          title: 'Color',
        },
      },
    },
    involved_pages: 'first',
    placement: 'center_top',
  },
  useESignDataDocument: false,
  editChanging: false,
  markForPaymentData: {
    body: '',
    subject: '',
    saveAsTemplate: false,
  },
};

const reconcileInvoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    initInvoiceDocumentLink: (state, action) => {
      state.invoiceDocumentLink = action.payload;
    },
    changeInvoiceDocumentLink: (
      state,
      action: {
        payload: {
          eSignData: IInvoiceESignData;
          params: {
            legalEntityCode: string;
            invoiceId: string;
          };
        };
      },
    ) => {
      const { eSignData, params } = action.payload;
      const baseSettings = eSignData.stamp.base;
      const additionalSettings = eSignData.stamp.additional;
      const paramsInvoice = {
        page_position: eSignData.placement,
        involved_pages: eSignData.involved_pages,
        show_border: additionalSettings.border.value,
        show_date: baseSettings.date.value,
        show_header: additionalSettings.header.value,
        show_name: baseSettings.name.value,
        show_signature: baseSettings.signature.value,
        color: additionalSettings.color.value.slice(1),
      };
      const qs = stringify(paramsInvoice, DEFAULT_STRINGIFY_OPTIONS);
      const URL = `${API_URL}/api/reconcile/development/legal_entities/${params.legalEntityCode}/invoices/${params.invoiceId}/approval_stamp_preview${qs}`;
      state.invoiceDocumentLink = URL;
      state.eSignDataDocument = true;
    },
    setActiveVerifyStep: (state, action) => {
      state.verifySteps = state.verifySteps.map((step) => {
        if (action.payload === step.value) {
          return {
            ...step,
            done: true,
          };
        }
        return step;
      });
      state.activeStep = action.payload;
    },
    backToPreviuosVerifyStep: (state, action) => {
      state.verifySteps = state.verifySteps.map((step) => {
        if (action.payload.nextStep === step.value) {
          return {
            ...step,
            done: false,
          };
        }
        return step;
      });
      state.activeStep = action.payload.step;
    },
    setInvoiceDetails: (state, action) => {
      state.invoiceData.details = action.payload;
      state.editChanging = false;
    },
    clearInvoiceDetails: (state) => {
      state.invoiceData.details = initialState.invoiceData.details;
      state.verifySteps = initialState.verifySteps;
    },
    changeLineItemSelectText: (state, action) => {
      state.lineItemSelectText = action.payload;
      state.editChanging = false;
    },
    setESignDataDocument: (state, action) => {
      state.eSignDataDocument = action.payload;
    },
    setESingData: (
      state,
      action: {
        payload: IInvoiceESignData;
      },
    ) => {
      state.eSignData = action.payload;
    },
    clearESignData: (state) => {
      state.eSignData = initialState.eSignData;
      state.eSignDataDocument = false;
    },
    resetInvoiceState: (state) => {
      clearInvoiceLineItems();
      state.activeStep = 'details';
      state.eSignData = initialState.eSignData;
      state.eSignDataDocument = false;
      state.invoiceData.details = initialState.invoiceData.details;
      state.verifySteps = initialState.verifySteps;
      state.markForPaymentData = initialState.markForPaymentData;
    },
    setEditInvoiceChanging: (
      state,
      action: {
        payload: boolean;
      },
    ) => {
      state.editChanging = action.payload;
    },
    setMarkForPaymentData: (
      state,
      action: {
        payload: IMarkForPaymentDataSlice;
      },
    ) => {
      state.markForPaymentData = {
        ...state.markForPaymentData,
        ...action.payload,
      };
    },
  },
});

export const {
  setActiveVerifyStep,
  backToPreviuosVerifyStep,
  setInvoiceDetails,
  clearInvoiceDetails,
  changeLineItemSelectText,
  initInvoiceDocumentLink,
  changeInvoiceDocumentLink,
  setESignDataDocument,
  setESingData,
  clearESignData,
  resetInvoiceState,
  setEditInvoiceChanging,
  setMarkForPaymentData,
} = reconcileInvoicesSlice.actions;

export const selectInvoiceDocumnentLink = (state: TRootState) =>
  state.invoices.invoiceDocumentLink;
export const selectVerifySteps = (state: TRootState) =>
  state.invoices.verifySteps;
export const selectActiveVerifySteps = (state: TRootState) =>
  state.invoices.activeStep;
export const selectInvoiceDetails = (state: TRootState) =>
  state.invoices.invoiceData.details;
export const selectLineItemSelectText = (state: TRootState) =>
  state.invoices.lineItemSelectText;
export const selectInvoiceESignData = (state: TRootState) =>
  state.invoices.eSignData;
export const selectESignDataDocument = (state: TRootState) =>
  state.invoices.eSignDataDocument;
export const selectEditInvoiceChanging = (state: TRootState) =>
  state.invoices.editChanging;
export const selectMarkForPaymentData = (state: TRootState) =>
  state.invoices.markForPaymentData;

export default reconcileInvoicesSlice.reducer;

import { QuickFilterByObjectType } from '@/bundles/REturn/components/Home/filters/QuickFilterByObjectType';
import {
  DETAIL_CARD_PROPS_BY_RETURN_COLUMN,
  TReturnDetailsColumns,
} from 'bundles/REturn/components/Home/consts';
import DetailsCard from 'bundles/REturn/components/Home/detailsCard/DetailsCard';
import ObjectInformationCell from 'bundles/REturn/components/Home/formatters/assetInformation/ObjectInformationCell';
import OwnershipsCell from 'bundles/REturn/components/Home/formatters/ownerships/OwnershipsCell';
import { IReturnInvestmentObject } from 'bundles/REturn/types';
import PublicFilterButton from 'bundles/Shared/components/Filters/buttons/publicFilterButton/PublicFilterButton';
import { IColumn } from 'bundles/Shared/components/Table/types';
import useMediaQuery, {
  XL_1_5_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import {
  formatAmount,
  percentNumberFormat,
} from '@/shared/lib/formatting/number';
import { useMemo } from 'react';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { RETURN_ENTITY_KEYS } from '@/bundles/REturn/components/Home/investmentObjectDetailsModal/utils';
import { startCase } from 'lodash-es';

export type TTotalFields = keyof Pick<
  IReturnInvestmentObject,
  'totalContributions' | 'totalCommitments' | 'totalDistributions' | 'yield'
>;

const RETURN_COLUMN_TO_AMOUNT_FIELD = {
  contributions: 'totalContributionsAmountCents',
  distributions: 'totalDistributions',
  commitments: 'totalCommitmentsAmountCents',
  accrued_preferred: 'totalAccruedEndingBalanceCents',
  yields: 'yield',
} as const satisfies Record<
  TReturnDetailsColumns,
  keyof IReturnInvestmentObject
>;

const RETURN_COLUMN_TO_DATA_FIELD = {
  contributions: 'totalContributions',
  distributions: 'totalDistributions',
  commitments: 'totalCommitments',
  accrued_preferred: 'totalAccruedEndingBalanceCents',
  yields: 'yield',
} as const satisfies Record<
  TReturnDetailsColumns,
  keyof IReturnInvestmentObject
>;

const getTotalColumn = (
  key: TReturnDetailsColumns,
): IColumn<IReturnInvestmentObject> => ({
  text: startCase(key),
  dataField: RETURN_COLUMN_TO_DATA_FIELD[key],
  classes: 'p-0 pt-l pb-l bg-light',
  headerStyle: {
    width: 230,
  },
  formatter: ({ row }) => {
    const amount = row[RETURN_COLUMN_TO_AMOUNT_FIELD[key]];
    const value =
      key === 'distributions' ? amount : convertCentsToDollars(amount);

    return (
      <DetailsCard
        {...DETAIL_CARD_PROPS_BY_RETURN_COLUMN[key]}
        investmentObjectId={row.id}
        value={value}
        formatter={key === 'yields' ? percentNumberFormat : formatAmount}
      />
    );
  },
  sortable: true,
});

export const useReturnColumns = ({ pageParams, setPageParams }) => {
  const isXlWidth = useMediaQuery(XL_1_5_WIDTH_MEDIA_QUERY);

  return useMemo<IColumn<IReturnInvestmentObject>[]>(
    () => [
      {
        text: 'Object Info',
        dataField: 'objectName',
        classes: 'bg-light !pr-0 h-full',
        headerClasses: 'min-w-[380px] w-[380px]',
        formatter: ({ row }) => (
          <ObjectInformationCell
            entity={row.object}
            legalEntities={row.legalEntities}
            yieldValue={row.yield}
          />
        ),
        quickFilter: (
          <div className="ml-xs flex gap-tw-1">
            <PublicFilterButton
              filtered={pageParams.published}
              onClick={() =>
                setPageParams({
                  ...pageParams,
                  page: 1,
                  published: !pageParams.published,
                })
              }
              onClose={() =>
                setPageParams({ ...pageParams, page: 1, published: false })
              }
            />
            <QuickFilterByObjectType
              checkedItem={pageParams.objectType}
              onCheckItemClick={(objectType) => {
                setPageParams({ ...pageParams, page: 1, objectType });
              }}
            />
          </div>
        ),
        sortable: true,
      },
      {
        text: 'Ownership',
        dataField: 'ownership',
        formatter: OwnershipsCell,
        classes: 'bg-light !pl-0 h-full',
        headerClasses: 'min-w-[200px] w-[200px]',
      },
      ...RETURN_ENTITY_KEYS.map((key) => getTotalColumn(key)),
      {
        ...getTotalColumn('yields'),
        hidden: !isXlWidth,
      },
    ],
    [pageParams],
  );
};

import React, { FC, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import { AnimationLoader, Modal } from 'stories';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  useGetDevelopmentCategoryFlatQuery,
  useGetDevelopmentCategoryQuery,
} from 'bundles/Construction/api/core';
import { getItemsReallocationManualModal } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/formItems';
import { ICreateReallocationFormData } from 'bundles/Construction/components/Reallocation/Modals/types';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { mapFormDataToRequest } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/utils';
import { ICreateReallocationRequest } from 'bundles/Construction/actions/reallocations';
import { useJCCsForLegalEntityConfig } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/hooks';

type Props = DialogProps<ICreateReallocationRequest>;

export const CreateManualReallocationModal: FC<Props> = ({ onSubmit }) => {
  const params = useParams();
  const { confirm } = useModal();
  const [disabledCloseIcon, setDisabledCloseIcon] = useState(false);
  const developmentLegalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );

  const { data: JCCTree, isLoading: isTreeLoading } =
    useGetDevelopmentCategoryQuery({
      legalEntityCode: params.legalEntityCode,
      without_sovcs: 'true',
    });

  const { data: JCCForReallocations, isLoading: isFlatLoading } =
    useGetDevelopmentCategoryFlatQuery({
      legalEntityCode: params.legalEntityCode,
      without_sovcs: 'true',
    });
  const dataLoaded = !(isTreeLoading || isFlatLoading);

  const { hardConstructionCost, softCostContingency, hardCostContingency } =
    useJCCsForLegalEntityConfig(params.legalEntityCode);

  const [formData, setFormData] = useState<ICreateReallocationFormData>({
    subReallocations: [],
  });

  const initGroupFormItems = dataLoaded
    ? getItemsReallocationManualModal({
        formData,
        setFormData,
        softCostContingency,
        hardConstructionCost,
        hardCostContingency,
        JCCForReallocations: JCCForReallocations ?? [],
        JCCTree: JCCTree ?? [],
      })
    : [];
  const handleSubmit = async () => {
    const request = await mapFormDataToRequest(formData);
    return onSubmit?.(request);
  };

  const handleClose = async () => {
    const res = await confirm({
      title: 'Are you sure you want to cancel?',
      subtitle: 'All changes will be lost.',
    });
    if (res) {
      navigate('./');
    }
  };

  const disabledCloseIconFunc = () => {
    setDisabledCloseIcon(true);
  };
  return (
    <>
      <Modal
        header={
          <div>
            <div className="label-regular">
              {developmentLegalEntity!.assetName}
            </div>
            <div className="dark header6-bold">Create Manual REallocation</div>
          </div>
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={handleClose}
        disabledClose={disabledCloseIcon}
      >
        {!dataLoaded && <AnimationLoader />}
        {dataLoaded && (
          <GroupForm
            groupFormItems={initGroupFormItems}
            onSubmit={handleSubmit}
            formData={formData}
            setFormData={setFormData}
            buttonConfig={{
              titleBtn: 'Review & Confirm',
              titleView: 'Create Manual REallocation',
              titleLoading: 'Creating Manual REallocation',
              width: '100%',
              withoutCancel: true,
              funcSendForm: disabledCloseIconFunc,
            }}
            groupFormConfig={{
              reviewFlow: true,
              steps: true,
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default CreateManualReallocationModal;

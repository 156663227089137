// todo: rename to NoItems
import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { RouteComponentProps } from '@reach/router';

interface Props {
  title: string;
  subTitle?: string;
  customDocumentsComponent?: React.ReactNode;
  className?: string;
}

function NoDataOverlay({
  title,
  subTitle = '',
  customDocumentsComponent,
  className,
}: Props & RouteComponentProps) {
  return (
    <div className={cn('no-data-overlay w-full text-center', className)}>
      <p className="light-60 header4-regular mb-s">{title}</p>
      <p className="light-60 body-regular">{subTitle}</p>
      {customDocumentsComponent && customDocumentsComponent}
    </div>
  );
}

export default NoDataOverlay;

import React from 'react';
import { Button, Tooltip } from 'stories';
import { exportUsers } from 'bundles/UserManagement/actions/User';

function ExportUsersButton() {
  return (
    <Tooltip mainText="Export as xlsx" arrowPosition="center">
      <Button
        onClick={() => exportUsers()}
        variant="secondary"
        iconName="docExcel"
        iconClasses="text-neutral-450"
        size="s"
      >
        Export
      </Button>
    </Tooltip>
  );
}

export default ExportUsersButton;

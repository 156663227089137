import { IUserRole } from '@/types/User';
import {
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';

export type UserFormTabs = Array<
  | 'Info'
  | 'Role'
  | 'Entities'
  | 'Advisees'
  | 'Advisors'
  | 'Tags'
  | 'Funds'
  | 'Assets'
>;

export default function userFormTabs(userRole: IUserRole): UserFormTabs {
  const tabs: UserFormTabs = ['Info', 'Role'];
  // NOTE: role-specific tabs
  if (['Equity Partner', 'Lender'].includes(userRole?.group ?? '')) {
    tabs.push('Entities');
  }

  // NOTE: advisor-advisee association
  if (userRole?.name === 'Advisor') {
    tabs.push('Advisees');
  } else if (userRole?.category === 'external') {
    tabs.push('Advisors');
  }

  // NOTE: advisor will inherit those form another user, no need to specify
  if (userRole?.name === 'Advisor') return tabs;

  tabs.push('Tags');

  if (currentUserAllowedTo('view', FUND_PORTAL_PRODUCT_NAME)) {
    tabs.push('Funds');
  }

  tabs.push('Assets');

  return tabs;
}

import { HUGE_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import { Contribution } from '@/entities/return';
import {
  InvestmentIndex,
  InvestmentObjectUnitPrice,
  PostApiCapitalInvestmentContributionsBulkApiArg,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import EnterContibutionsStep from 'bundles/REturn/components/Ownership/modals/addContributionsModal/EnterContibutionsStep';
import { TEditableCapitalInvestments } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitalInvestmentsEditor';
import SelectInvestmentEntity from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalActions, ModalHeaderWithSubtitle } from 'stories';
import { convertDollarsToCents } from '@/shared/lib/converters';

interface Props
  extends DialogProps<PostApiCapitalInvestmentContributionsBulkApiArg['body']> {
  capitalInvestments: InvestmentIndex[];
  unitPrices: InvestmentObjectUnitPrice[];
}

const AddContributionsModal = ({
  onClose,
  onSubmit,
  capitalInvestments,
  unitPrices,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedInvestmentEntities, setSelectedInvestmentEntities] = useState<
    ICapitalInvestment[]
  >([]);
  const [data, setData] = useState<TEditableCapitalInvestments[]>([]);
  const [step2Valid, setStep2Valid] = useState(false);

  const [investmentClass, setInvestmentClass] =
    useState<Contribution['investmentClass']>('b');
  const handleToggleInvestmentClass = () => {
    setInvestmentClass((prev) => (prev === 'b' ? 'a' : 'b'));
  };

  const step1Content = useMemo(
    () => (
      <SelectInvestmentEntity
        capitalInvestments={capitalInvestments}
        selectedItems={selectedInvestmentEntities}
        setSelectedItems={(newEntities) => {
          setSelectedInvestmentEntities(newEntities);
          setData(
            newEntities.map((item) => ({
              id: item.id,
              investmentEntity: item.investmentEntity,
              legalEntity: item.legalEntity,
              amount: 0,
            })),
          );
        }}
      />
    ),
    [selectedInvestmentEntities],
  );

  const step2Content = useMemo(
    () => (
      <EnterContibutionsStep
        unitPrices={unitPrices}
        capitalInvestments={data}
        onCapitalInvestmentsChange={setData}
        onValidChange={setStep2Valid}
        investmentClass={investmentClass}
        onToggleInvestmentClass={handleToggleInvestmentClass}
      />
    ),
    [data, investmentClass],
  );

  const steps: Record<string, React.ReactNode> = {
    1: step1Content,
    2: step2Content,
  };

  const stepsCount = Object.keys(steps).length;

  const stepsValid: Record<string, boolean> = {
    1: selectedInvestmentEntities.length !== 0,
    2: step2Valid,
  };

  const handleSubmit =
    currentStep === stepsCount
      ? () =>
          onSubmit?.(
            data.map((ci) => ({
              investment_id: ci.id,
              amount_cents: ci.amount ? convertDollarsToCents(ci.amount) : 0,
              commitment_id: ci.commitmentId,
              signed_date: investmentClass === 'a' ? undefined : ci.signedDate,
              date: ci.date!,
              investment_class: investmentClass,
              units_quantity: ci.unitsQuantity,
            })),
          )
      : () => setCurrentStep(currentStep + 1);

  const pluralizedSelectedInvestmentEntities = `${
    selectedInvestmentEntities.length
  } ${pluralize('Investment Entity', selectedInvestmentEntities.length)}`;
  const stepsSubmitText: Record<string, string> = {
    1: `Continue ${
      selectedInvestmentEntities.length > 0
        ? `with ${pluralizedSelectedInvestmentEntities}`
        : ''
    }`,
    2: `Add Contributions for ${pluralizedSelectedInvestmentEntities}`,
  };

  return (
    <Modal
      toggle={onClose}
      size="1000"
      bodyHeight={600}
      classes={{
        body: '!p-0',
      }}
      zIndex={HUGE_MODAL_Z_INDEX + 2}
      header={
        <ModalHeaderWithSubtitle
          title="Bulk Adding of Contributions Values"
          subtitle={`Step ${currentStep} of ${stepsCount}`}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button
            variant="secondary"
            onClick={
              currentStep === 1
                ? onClose
                : () => setCurrentStep(currentStep - 1)
            }
          >
            {currentStep === 1 ? 'Cancel' : 'Back to Selection'}
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!stepsValid[currentStep]}
          >
            {stepsSubmitText[currentStep]}
          </Button>
        </ModalActions>
      }
    >
      {steps[currentStep]}
    </Modal>
  );
};

export default AddContributionsModal;

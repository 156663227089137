import { useFinancialsDatePickerFullHistory } from '@/bundles/REport/components/financials/widgets/fullHistory/lib';
import { IconButton } from '@/stories';

export const FinancialsFullHistoryButton = () => {
  const fullHistory = useFinancialsDatePickerFullHistory();

  if (fullHistory.isButtonHidden) return null;

  return (
    <IconButton
      tooltipProps={{
        mainText: fullHistory.isActive
          ? 'Unselect all available periods'
          : 'When enabled, all available periods will be selected',
        placement: 'top',
        delay: 300,
      }}
      size="l"
      isActive={fullHistory.isActive}
      variant="secondary"
      iconName="historical"
      onClick={fullHistory.handleToggle}
    />
  );
};

import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportFormulaTags: build.query<
      GetApiSettingsReportFormulaTagsApiResponse,
      GetApiSettingsReportFormulaTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formula_tags`,
        params: { query: queryArg.query },
      }),
    }),
    postApiSettingsReportFormulaTags: build.mutation<
      PostApiSettingsReportFormulaTagsApiResponse,
      PostApiSettingsReportFormulaTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formula_tags`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiSettingsReportFormulaTagsSync: build.mutation<
      PostApiSettingsReportFormulaTagsSyncApiResponse,
      PostApiSettingsReportFormulaTagsSyncApiArg
    >({
      query: () => ({
        url: `/api/settings/report/formula_tags/sync`,
        method: 'POST',
      }),
    }),
    putApiSettingsReportFormulaTagsById: build.mutation<
      PutApiSettingsReportFormulaTagsByIdApiResponse,
      PutApiSettingsReportFormulaTagsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formula_tags/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportFormulaTagsById: build.mutation<
      DeleteApiSettingsReportFormulaTagsByIdApiResponse,
      DeleteApiSettingsReportFormulaTagsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formula_tags/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSettingsReportFormulas: build.query<
      GetApiSettingsReportFormulasApiResponse,
      GetApiSettingsReportFormulasApiArg
    >({
      query: () => ({ url: `/api/settings/report/formulas` }),
    }),
    postApiSettingsReportFormulas: build.mutation<
      PostApiSettingsReportFormulasApiResponse,
      PostApiSettingsReportFormulasApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formulas`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportFormulasById: build.mutation<
      PutApiSettingsReportFormulasByIdApiResponse,
      PutApiSettingsReportFormulasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formulas/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportFormulasById: build.mutation<
      DeleteApiSettingsReportFormulasByIdApiResponse,
      DeleteApiSettingsReportFormulasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formulas/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    deleteApiSettingsReportFormulasBulkDestroy: build.mutation<
      DeleteApiSettingsReportFormulasBulkDestroyApiResponse,
      DeleteApiSettingsReportFormulasBulkDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formulas/bulk_destroy`,
        method: 'DELETE',
        params: { tag_ids: queryArg.tagIds, untagged: queryArg.untagged },
      }),
    }),
    putApiSettingsReportFormulasReplaceAllReferences: build.mutation<
      PutApiSettingsReportFormulasReplaceAllReferencesApiResponse,
      PutApiSettingsReportFormulasReplaceAllReferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/formulas/replace_all_references`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportVariables: build.query<
      GetApiSettingsReportVariablesApiResponse,
      GetApiSettingsReportVariablesApiArg
    >({
      query: () => ({ url: `/api/settings/report/variables` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportFormulasApiGenerated };
export type GetApiSettingsReportFormulaTagsApiResponse =
  /** status 200 List of Formula Tags */ {
    items?: ReportFormulaTagDto[];
    meta?: {
      systemSync?: ReportCustomerBridgeSyncDto | null;
    };
  };
export type GetApiSettingsReportFormulaTagsApiArg = {
  /** Search by name */
  query?: string;
};
export type PostApiSettingsReportFormulaTagsApiResponse = unknown;
export type PostApiSettingsReportFormulaTagsApiArg = {
  body: {
    name: string;
    kind: string;
  };
};
export type PostApiSettingsReportFormulaTagsSyncApiResponse =
  /** status 200 success */ undefined;
export type PostApiSettingsReportFormulaTagsSyncApiArg = void;
export type PutApiSettingsReportFormulaTagsByIdApiResponse = unknown;
export type PutApiSettingsReportFormulaTagsByIdApiArg = {
  id: string;
  body: {
    name: string;
    kind: string;
  };
};
export type DeleteApiSettingsReportFormulaTagsByIdApiResponse = unknown;
export type DeleteApiSettingsReportFormulaTagsByIdApiArg = {
  id: string;
};
export type GetApiSettingsReportFormulasApiResponse =
  /** status 200 List of Formulas */ ReportFormulaDto[];
export type GetApiSettingsReportFormulasApiArg = void;
export type PostApiSettingsReportFormulasApiResponse =
  /** status 200 Formula created */ ReportFormulaDto;
export type PostApiSettingsReportFormulasApiArg = {
  body: {
    label?: string;
    description?: string | null;
    reference?: string;
    expression?: string;
    tags?: {
      name?: string;
      id?: string;
    }[];
  };
};
export type PutApiSettingsReportFormulasByIdApiResponse =
  /** status 200 Formula updated */ ReportFormulaDto;
export type PutApiSettingsReportFormulasByIdApiArg = {
  id: string;
  body: {
    label?: string;
    description?: string | null;
    reference?: string;
    expression?: string;
    tags?: {
      name?: string;
      id?: string;
    }[];
  };
};
export type DeleteApiSettingsReportFormulasByIdApiResponse =
  /** status 200 Formula destroyed */ undefined;
export type DeleteApiSettingsReportFormulasByIdApiArg = {
  id: string;
};
export type DeleteApiSettingsReportFormulasBulkDestroyApiResponse =
  /** status 200 Formulas destroyed */ undefined;
export type DeleteApiSettingsReportFormulasBulkDestroyApiArg = {
  tagIds?: {
    ''?: any;
  };
  untagged?: boolean;
};
export type PutApiSettingsReportFormulasReplaceAllReferencesApiResponse =
  unknown;
export type PutApiSettingsReportFormulasReplaceAllReferencesApiArg = {
  body: {
    old_reference: string;
    new_reference: string;
  };
};
export type GetApiSettingsReportVariablesApiResponse =
  /** status 200 List of Variables */ ReportVariableDto[];
export type GetApiSettingsReportVariablesApiArg = void;
export type ReportFormulaTagDto = {
  id: string;
  name: string;
  kind: 'system' | 'other';
};
export type ReportCustomerBridgeSyncDto = {
  id: string;
  status: 'in_progress' | 'completed' | 'failed';
  kind:
    | 'report_financial_category'
    | 'report_system_manual_variables'
    | 'reckoner_system_formulas';
  memos: string[];
  syncedAt: number | null;
  createdAt: number;
  initiator: {
    id: string;
    name: string;
  };
};
export type ForbiddenDto = {
  errors?: string[];
};
export type ReportScorecardReferencingFormulaDto = {
  id: string;
  label: string;
};
export type ReportScorecardReferencingScorecardDto = {
  id: string;
  title: string;
};
export type ReportScorecardReferencingTableDto = {
  id: number;
  name: string;
};
export type ReportFormulaInvalidReferenceDto = {
  reference: string;
  name: string;
  namespace: string;
};
export type ReportReferencingWidgetDto = {
  id: string;
  title: string;
  widgetTpe?:
    | 'historical_review_table'
    | 'delinquency_tracker_table'
    | 'recent_rent_roll_table'
    | 'delinquency_snapshot_column_chart'
    | 'delinquency_average_column_chart';
};
export type ReportFormulaDto = {
  id: string;
  reference: string;
  expression: string;
  label: string;
  description: string | null;
  tags: ReportFormulaTagDto[];
  referencingFormulas: ReportScorecardReferencingFormulaDto[];
  referencingScorecards: ReportScorecardReferencingScorecardDto[];
  referencingTables: ReportScorecardReferencingTableDto[];
  invalidReferences?: ReportFormulaInvalidReferenceDto[];
  referencingScoreboardWidgets: ReportReferencingWidgetDto[];
};
export type ReportVariableDto = {
  reference: string;
  label: string;
  namespace: string;
};

import React from 'react';
import { IconButton } from 'stories';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { ROUTES } from '@/shared/lib/hooks/useNavigation';

const OwnershipBreadcrumbs = () => {
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES.root.return.fullPath,
  });

  return (
    <IconButton
      onClick={navigateBack}
      className="min-h-[56px]"
      iconName="arrowLeft"
      variant="secondary"
    />
  );
};

export default OwnershipBreadcrumbs;

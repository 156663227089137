import { LegalEntityActivityLog } from 'bundles/Shared/entities/legalEntity/model';
import { objectEntries } from 'lib/typeHelpers';

export function getLEActivityLogKeyInfoFromDetails(
  details: LegalEntityActivityLog['details'],
) {
  const { new: newChanges, old: oldValues } = details;
  const res = objectEntries(newChanges ?? {}).map(
    ([key, newValue]) =>
      [key, { newValue, oldValue: oldValues?.[key] }] as const,
  );
  return res;
}

import { FormulaInUse } from '@/bundles/Shared/entities/formula';
import { EntityTagLabel } from '@/bundles/Shared/entities/tag';
import type { ReportBuilderTemplatePreviewDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportBuilderTemplateDelete } from '@/features/report/report/deleteReportBuilderTemplate/lib';
import { useReportBuilderTemplateDuplicate } from '@/features/report/report/duplicateReportBuilderTemplate/lib';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { BaseCard } from '@/shared/ui/BaseCard';
import type { LayoutProps } from '@/types/Props';
import { useNavigate } from '@reach/router';
import { ReactComponent as FileIcon } from 'fonts/sre-icons/Icons/file.svg';

export const ReportBuilderTemplateCard = ({
  children,
  template,
  ...props
}: LayoutProps & {
  template: Pick<
    ReportBuilderTemplatePreviewDto,
    'id' | 'name' | 'templateTags' | 'assets'
  >;
}) => {
  const [duplicateTemplate, duplicateOptions] =
    useReportBuilderTemplateDuplicate();
  const [deleteTemplate, deleteOptions] = useReportBuilderTemplateDelete();
  const isLoading = duplicateOptions.isLoading || deleteOptions.isLoading;
  const navigate = useNavigate();

  return (
    <BaseCard
      iconSlot={<FileIcon className="h-[48px] w-[48px]" />}
      imgWrapperProps={{
        onClick: () => {
          navigate(
            generateUrl(
              ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.fullPath,
              {
                pathParams: {
                  templateId: template.id,
                },
              },
            ),
          );
        },
      }}
      {...props}
    >
      <div className="flex flex-col justify-between gap-tw-2">
        <BaseCard.Title className="inline-flex flex-wrap items-center gap-tw-2">
          <p>{template.name}</p>
          {template.assets.length > 0 && (
            <div className="flex gap-tw-2">
              <FormulaInUse.UsedSvg used className="h-tw-4 w-tw-4 min-w-tw-4" />
            </div>
          )}
        </BaseCard.Title>
        <BaseCard.OverlayActions isLoading={isLoading}>
          <BaseCard.DropdownActions
            isLoading={isLoading}
            items={[
              {
                text: 'Duplicate',
                onAction: () => {
                  duplicateTemplate(template);
                },
              },
              {
                text: 'Delete',
                onAction: () => {
                  deleteTemplate(template);
                },
              },
            ]}
          />
        </BaseCard.OverlayActions>
        <EntityTagLabel.List>
          {template.templateTags.map((tag) => (
            <EntityTagLabel label={tag.name} key={tag.id} />
          ))}
        </EntityTagLabel.List>
      </div>
      {children}
    </BaseCard>
  );
};

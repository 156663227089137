import { useFormContext, UseFormReturn } from 'react-hook-form';
import {
  ICreateReallocationFormData,
  TSubReallocationFormData,
} from 'bundles/Construction/components/Reallocation/Modals/types';
import { usePrevious } from '@/shared/lib/hooks/usePrevious';
import { useEffect, useMemo } from 'react';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';
import {
  IHardCostCode,
  IReconcileCategorizedEntity,
  IReconcileDevelopmentCategory,
} from 'bundles/Construction/types';
import { findJCC } from 'bundles/Shared/helpers/job_cost_code';
import { IJobCostCode } from 'types/JobCostCode';
import { transformJccIdToKey } from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { treeDFS } from 'lib/treeHelpers';

type DependentFields = keyof Pick<
  ICreateReallocationFormData,
  // todo figure out how field level validation works
  | 'description'
  | 'subReallocations'
  | 'moved_from_id'
  | 'moved_to_id'
  | 'movedFromSovcs'
  | 'movedToSovcs'
  | 'value'
>;

const VALIDATION_DEPENDENT_FIELDS = [
  'description',
  'moved_from_id',
  'moved_to_id',
  'movedFromSovcs',
  'movedToSovcs',
  'value',
  'subReallocations',
] as const satisfies readonly DependentFields[];

export const useReallocationDependableFieldsEffect = (
  methods?: UseFormReturn<ICreateReallocationFormData>,
  { withSubReallocations } = {
    withSubReallocations: false,
  },
) => {
  const contextMethods = useFormContext<ICreateReallocationFormData>();
  const { watch, reset, trigger, setValue } = methods ?? contextMethods;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // reset sovcs when jcc changes
      if (name === 'moved_from_id' || name === 'moved_to_id') {
        reset((values) => ({
          ...values,
          movedFromSovcs: null,
          movedToSovcs: null,
        }));
      }
      // when moved from jcc changes, update sub reallocations
      if (name === 'moved_from_id' && withSubReallocations) {
        setValue(
          'subReallocations',
          value.subReallocations!.map((sub) => ({
            ...sub,
            moved_to_id: value[name]!,
          })),
        );
      }
      if (
        VALIDATION_DEPENDENT_FIELDS.includes(name as DependentFields) &&
        name !== 'description'
      ) {
        trigger(VALIDATION_DEPENDENT_FIELDS.filter((field) => field !== name));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
};

export const useReallocationEffect = (
  methods?: UseFormReturn<ICreateReallocationFormData>,
) => {
  useReallocationDependableFieldsEffect(methods, {
    withSubReallocations: true,
  });
};

export const useSubReallocationEffect = (
  methods?: UseFormReturn<TSubReallocationFormData>,
) => {
  useReallocationDependableFieldsEffect(methods);
};

export const useTriggerOnMount = ({ trigger }: UseFormReturn<any, any>) => {
  useEffect(() => {
    trigger();
  }, [trigger]);
};

export const useResetReallocationOnContingencyImpact = () => {
  const { watch, reset, trigger } =
    useFormContext<ICreateReallocationFormData>();
  const watchContingencyImpact = watch('contingencyImpact');
  const prevContingencyImpact = usePrevious(watchContingencyImpact);

  useEffect(() => {
    if (prevContingencyImpact != null) {
      reset((values) => ({
        moved_from_id: null,
        moved_to_id: null,
        sharedFiles: null,
        value: 0 as Float,
        movedToSovcs: null,
        movedFromSovcs: null,
        description: values.description,
        contingencyImpact: values.contingencyImpact,
        subReallocations: [],
      }));
      trigger();
    }
  }, [watchContingencyImpact]);
};
export const useJCCsForLegalEntityConfig = (legalEntityCode: string) => {
  const developmentLegalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const legalEntityConfig = developmentLegalEntity!.config;
  const { data: JCCForReallocations, isLoading } =
    useGetDevelopmentCategoryFlatQuery({
      legalEntityCode,
      without_sovcs: 'true',
    });

  const hardConstructionCost: IHardCostCode = findJCC(
    legalEntityConfig.hardConstructionCostCode,
    JCCForReallocations,
  ) as IJobCostCode;
  const hardCostContingency = findJCC(
    legalEntityConfig.hardCostContingencyCode,
    JCCForReallocations,
  ) as IJobCostCode;

  const softCostContingency = findJCC(
    legalEntityConfig.softCostContingencyCode,
    JCCForReallocations,
  ) as IJobCostCode;

  return {
    isLoading,
    hardConstructionCost,
    hardCostContingency,
    softCostContingency,
  };
};
export const useMovedFromIdsToJCCs = (
  formData: Pick<ICreateReallocationFormData, 'moved_to_id' | 'moved_from_id'>,
  jccTree: IReconcileDevelopmentCategory[],
) => {
  const searchCallback = (key: keyof typeof formData) => (category) => {
    const possibleJcc = category as unknown as IReconcileCategorizedEntity;
    // todo workaround for tree missing original id
    return possibleJcc.key === transformJccIdToKey(formData[key]);
  };
  return useMemo(
    () => ({
      movedFromJcc: treeDFS(jccTree, searchCallback('moved_from_id'))?.at(-1),
      movedToJcc: treeDFS(jccTree, searchCallback('moved_to_id'))?.at(-1),
    }),
    [formData.moved_from_id, formData.moved_to_id, jccTree],
  );
};

import { range } from 'lodash-es';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';

export default function ObjectCardSkeletonBlock() {
  return (
    <div className="flex flex-col gap-tw-4">
      <SkeletonBlock className="h-[24px] w-full" />
      <div className="flex flex-col gap">
        {range(0, 6).map((i) => (
          <div
            key={i}
            className="grid grid-cols-[140px,auto] gap-tw-4 bg-light-5 p-tw-6"
          >
            <SkeletonBlock className="h-[88px]" />
            <SkeletonBlock className="h-[88px]" />
          </div>
        ))}
      </div>
    </div>
  );
}

import { useMemo } from 'react';
import {
  DEFAULT_CODE_COLUMN,
  DEFAULT_NAME_COLUMN,
  getJccColumn,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants/columns';
import { IUseColumnsParams } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import {
  IReconcileSettingsSovc,
  TGetSovcsInReconcileSettingsParams,
} from 'bundles/REconcile/actions/sovcs';
import { TReconcileSettingsJCC } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntitiesSettings';
import { IColumn } from 'bundles/Shared/components/Table/types';

export function useSovcsCrudColumns({
  jobCostCodes,
}: IUseColumnsParams<TGetSovcsInReconcileSettingsParams> & {
  jobCostCodes: TReconcileSettingsJCC[];
}) {
  return useMemo<IColumn<IReconcileSettingsSovc>[]>(
    () => [
      DEFAULT_CODE_COLUMN,
      DEFAULT_NAME_COLUMN,
      getJccColumn({
        jobCostCodes,
      }),
    ],
    [jobCostCodes],
  );
}

import React from 'react';
import CategoryPathBadge from 'bundles/Settings/components/REport/GeneralLedgers/Table/CategoryPath/CategoryPathBadge';

import { useCategoryPathFromFinancialCategoriesById } from 'bundles/Shared/entities/finanicalCategory/lib';

export function CategoryPath({ categoryId }: { categoryId: number }) {
  const path = useCategoryPathFromFinancialCategoriesById(categoryId);

  return (
    <div className="flex items-center gap-s">
      <span className="dark-60 inline-semibold">{path.at(-1)?.code}</span>
      <span className="light-60 secondary-regular">from</span>
      <CategoryPathBadge path={path.map((c) => c.code)} />
    </div>
  );
}

import { Contribution, Distribution } from '@/entities/return';
import { formatUnixDate } from '@/shared/lib/formatting/dates';

export function InfoOfCreation({
  createdAt,
  createdBy,
  updatedBy,
  updatedAt,
}: Contribution | Distribution) {
  const isUpdated = Boolean(updatedBy);
  const label = isUpdated ? 'Updated by' : 'by';
  const fullName = isUpdated ? updatedBy.fullName : createdBy.fullName;
  const time = isUpdated ? updatedAt : createdAt;

  return (
    <div className="secondary-regular">
      <span className="text-light-60">{label} </span>
      <span className="text-dark-60">{fullName} </span>
      <span className="text-light-60">at </span>
      <span className="text-dark-60">{formatUnixDate(time, 'LLL')}</span>
    </div>
  );
}

import { IColumn } from 'bundles/Shared/components/Table/types';
import { TSovcForReallocation } from 'bundles/Construction/components/Reallocation/Modals/types';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import React from 'react';
import {
  FieldType,
  FieldTypeView,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import ApprovedReallocation from 'bundles/Construction/components/Reallocation/Modals/components/ApprovedReallocation';
import { ISharedDocument } from 'types/SharedDocument';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import Tooltip from 'stories/Tooltip/Tooltip';
import { getDestinationReallocationAmount } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/utils';

export const getSovcRevised = (
  row: TSovcForReallocation,
  destination: 'from' | 'to',
) =>
  Number(row.amount) +
  getDestinationReallocationAmount(destination, row.reallocation);
export const isSovcRevisedValid = (revised: number) => revised >= 0;

export const SOVC_AMOUNT_COLUMN: IColumn<TSovcForReallocation> = {
  text: 'Current',
  dataField: 'amount',
  formatter: ({ row }) => (
    <CurrencyFormatter
      classes={{ wrapper: 'inline-regular text-dark-60' }}
      value={row.amount}
    />
  ),
  headerStyle: {
    width: 160,
  },
};

export const SOVC_NAME_COLUMN: IColumn<TSovcForReallocation> = {
  text: 'Schedule of Value Code',
  dataField: 'name',
};

export const getSovcRevisedColumn = (
  destination: 'from' | 'to',
): IColumn<TSovcForReallocation> => ({
  text: 'Revised',
  dataField: 'revised',
  formatter: ({ row }) => {
    const revisedValue = getSovcRevised(row, destination);
    return (
      <div className="flex items-center">
        <CurrencyFormatter
          classes={{ wrapper: 'inline-regular text-dark-60' }}
          value={revisedValue}
        />
        {!isSovcRevisedValid(revisedValue) && (
          <Tooltip mainText="You can't reallocate more than SoVC contains">
            <FireIcon priority="high" />
          </Tooltip>
        )}
      </div>
    );
  },
  headerStyle: {
    width: 160,
  },
});

type TSharedFilesLoad = 'NO' | 'YES';
export type TSharedFilesLoadData = {
  sharedFilesLoad?: TSharedFilesLoad;
  sharedFiles?: ISharedDocument[] | undefined | null;
};

export const getSetterForSharedFilesLoad =
  (setFormData: React.Dispatch<React.SetStateAction<TSharedFilesLoadData>>) =>
  (value: 'YES' | 'NO', defaultFilesIfChooseVariantNo = null) => {
    setFormData((formData) => ({
      ...formData,
      sharedFiles:
        value === 'NO' ? defaultFilesIfChooseVariantNo : formData.sharedFiles,
      sharedFilesLoad: value,
    }));
  };

export const getDocumentsFormField = (
  formData: TSharedFilesLoadData,
): IGroupFormItem => ({
  title: 'Would you like to add documents?',
  titleView: formData?.sharedFiles
    ? 'Supporting Documents'
    : 'No Supporting Documents',
  disabledTitleView: !formData.sharedFiles,
  required: false,
  activated:
    formData.sharedFiles?.length ??
    formData.sharedFiles?.name ??
    formData?.sharedFilesLoad === 'NO',
  open: false,
  hasQA: true,
  formItems: [
    {
      fieldName: 'sharedFiles',
      type: FieldType.FILES,
      typeView: FieldTypeView.VIEW_FILES,
      optional: formData.sharedFilesLoad === 'YES' || formData.sharedFiles,
      optionalView: true,
      actions: <ApprovedReallocation />,
      actionsView: (file) => Boolean(file?.approved),
      enabled: formData.sharedFilesLoad === 'YES',
      multifile: true,
    },
  ],
  styles: {
    transparent: true,
  },
});

export const getReallocationTextColor = (value: Float) =>
  value > 0 ? '!text-green-dark-2' : '!text-red';

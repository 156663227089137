import {
  UpdateWidgetTitle,
  useUpdateListPropertyWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import React, { useState } from 'react';
import {
  IThinTabItem,
  ThinTabGroup,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import Button from 'stories/Button/Button';
import { DragDropContext } from '@hello-pangea/dnd';
import { IconButton } from '@/stories';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import { useTabs } from 'stories/Tabs/useTabs';
import { KpiCardWidgetConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/kpiCard/config/row';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'data',
    label: 'Data',
  },
] as const satisfies readonly IThinTabItem[];

// todo DRY historical table
const DataTab = () => {
  const [modalState, setModalState] = useState<'create' | null>(null);
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD>();
  const { kpis = [] } = widget.widgetConfig;
  const {
    removeItem: removeRow,
    moveItem: moveRow,
    cloneItem: cloneRow,
  } = useUpdateListPropertyWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD,
    'kpis',
  );
  return (
    <WidgetSettingsListLayout>
      <WidgetSettingsListLayout.Header
        label={kpis.length === 0 ? 'No Items Yet' : `${kpis.length} ITEMS`}
      >
        <Button
          onClick={() => {
            setModalState('create');
          }}
          size="xs"
          variant="secondary"
        >
          Add
        </Button>
      </WidgetSettingsListLayout.Header>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (!destination) return;
          moveRow(source.index, destination.index);
        }}
      >
        <WidgetSettingsListLayout.SortableList>
          {kpis.map((row, index) => (
            <WidgetSettingsListLayout.SortableListItem
              index={index}
              draggableId={row.key.toString()}
              key={row.key}
              listIndex={index}
              className="group/item"
            >
              <WidgetSettingsListLayout.ListItem.Text>
                {row.label}
              </WidgetSettingsListLayout.ListItem.Text>
              <div className="grow" />
              <WidgetSettingsListLayout.ListItem.ActionsPanel>
                <IconButton
                  iconName="copy"
                  onClick={() => {
                    cloneRow(row.key);
                  }}
                />
                {isFormulaByExpression(row.expression) && (
                  <InspectFormulaButton reference={row.expression} />
                )}
                <IconButton
                  onClick={() => {
                    setModalState(row);
                  }}
                  iconName="edit"
                />
                <IconButton
                  onClick={() => {
                    removeRow(row.key);
                  }}
                  iconName="trash"
                />
              </WidgetSettingsListLayout.ListItem.ActionsPanel>
            </WidgetSettingsListLayout.SortableListItem>
          ))}
        </WidgetSettingsListLayout.SortableList>
      </DragDropContext>
      {modalState != null && (
        <KpiCardWidgetConfigRow
          type={modalState === 'create' ? 'create' : 'edit'}
          rowConfig={modalState === 'create' ? null : modalState}
          onClose={() => setModalState(null)}
          onSubmit={(res) => {
            setModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setModalState('create');
              });
            }
          }}
        />
      )}
    </WidgetSettingsListLayout>
  );
};
const BasicTab = () => {
  return <UpdateWidgetTitle labelText="Card Label" />;
};

export function KpiCardWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);

  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'data' && <DataTab />}
      </div>
    </div>
  );
}

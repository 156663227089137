import { AgGridReactProps } from 'ag-grid-react';

export const SHARED_REPORT_TABLE_STATIC_PROPS: AgGridReactProps = {
  alwaysShowHorizontalScroll: true,
  stopEditingWhenCellsLoseFocus: true,
  treeData: true,
  suppressContextMenu: true,
  singleClickEdit: true,
  suppressMovableColumns: true,
  suppressCellFocus: true,
  groupSuppressBlankHeader: true,
  headerHeight: 24,
  groupHeaderHeight: 20,
  defaultColDef: {
    resizable: true,
  },
};

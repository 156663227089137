import http from 'lib/http';
import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

type TCrudPathParameters = {
  legalEntityId: string;
};

interface IConfigPathParameters {
  configId: number;
}

export interface IReconcileBudgetsSettingsChangeOrderConfig {
  id: number;
  name: string;
  default: boolean;
  setForLegalEntity: boolean;
}

export interface IReconcileSettingsChangeOrderConfigUpdate {
  name: string;
}

export const {
  getEntities: fetchChangeOrderConfigsForReconcileSettings,
  createEntity: createChangeOrderConfigInReconcileSettings,
  updateEntity: updateChangeOrderConfigInReconcileSettings,
  deleteEntity: deleteChangeOrderConfigInReconcileSettings,
} = createCrudApi<
  TCrudPathParameters,
  {},
  IReconcileBudgetsSettingsChangeOrderConfig,
  IReconcileSettingsChangeOrderConfigUpdate
>(
  '/settings/reconcile/legal_entities/:legalEntityId/configs',
  'Change Order Config',
);

export const setChangeOrderConfigForAsset = async ({
  legalEntityId,
  configId,
}: TCrudPathParameters & IConfigPathParameters) => {
  const result = await http.put(
    `/settings/reconcile/legal_entities/${legalEntityId}/configs/${configId}/set_for_legal_entity`,
    {},
  );
  const json = await result.json();

  if (json.errors) {
    toastr.error(json.errors);
    return false;
  }

  toastr.success('Change Order Config has been set');

  return json as IReconcileBudgetsSettingsChangeOrderConfig;
};

import { transformPeriodShiftFormToDto } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { produce } from 'immer';
import { XYChartWidgetConfig } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { XYChartWidgetConfigForm } from '@/bundles/Shared/widgets/dashboard/widgets/xyChart/config/component.form';

export const updateDefaultOptions = (
  values: XYChartWidgetConfigForm,
  widgetConfig: XYChartWidgetConfig,
) => {
  return produce(widgetConfig, (draft) => {
    draft.default_options.date_to = {
      period_type: 'month',
      period_shift: transformPeriodShiftFormToDto({
        key: 'months',
        ...values.periodShiftTo,
      }),
    };
    draft.default_options.date_from = {
      period_type: 'month',
      period_shift: transformPeriodShiftFormToDto(values.periodShiftFrom),
    };
    draft.default_options.granularity = values.granularity!;
  });
};

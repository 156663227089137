import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories';
import { SortableHandle } from 'react-sortable-hoc';
import { IFieldCard } from 'types/FieldCard';
import styles from './assetFieldCard.module.scss';

interface Props {
  fieldCard: IFieldCard;
  onDelete: (fieldCard: IFieldCard) => void;
  onEdit: (fieldCard: IFieldCard) => void;
  className?: string;
  canManage?: boolean;
  hideMove?: boolean;
  onHide?: (fieldCard: IFieldCard) => void;
  expandedActions?: boolean;
}

export const AssetFieldCard = ({
  fieldCard,
  hideMove = false,
  canManage = false,
  className,
  onDelete,
  onEdit,
  onHide,
  expandedActions = false,
}: Props) => {
  const DragHandle = SortableHandle(() => (
    <IconButton
      className={cn(styles.icon_move, 'mr-s')}
      variant="secondary"
      iconName="move"
      aria-hidden="true"
    />
  ));
  return (
    <div
      className={cn({
        [styles.fieldWrapper]: !expandedActions,
      })}
    >
      <div
        className={cn(
          styles.field,
          {
            [styles.fieldHoverable]: canManage && !expandedActions,
          },
          className,
        )}
      >
        <div className={cn('text-center', styles.title)}>{fieldCard.value}</div>
        <div className={cn('text-center', styles.description)}>
          {fieldCard.measure_name}
        </div>
        {canManage && (
          <div className={cn({ [styles.actions]: !expandedActions })}>
            <div className="semi-transparent-items w-full flex justify-between">
              <div>
                {!expandedActions && hideMove && <DragHandle />}
                <IconButton
                  iconName={fieldCard.visible ? 'eyeSlash' : 'eye'}
                  variant="secondary"
                  onClick={() => {
                    onHide(fieldCard);
                  }}
                />
              </div>
              <div>
                <IconButton
                  className="mr-s"
                  iconName="edit"
                  variant="secondary"
                  onClick={() => {
                    onEdit(fieldCard);
                  }}
                />
                <IconButton
                  iconName="trash"
                  variant="secondary"
                  onClick={() => {
                    onDelete(fieldCard);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetFieldCard;

import React, { useState } from 'react';
import { IFetchDataPeriodBatchGroupsParams } from 'bundles/Settings/actions/report/dataPeriodBulks';
import ReportDataPeriodBulkList from 'bundles/Settings/components/REport/DataPeriods/ReportDataPeriodBulkList';
import { AnimationLoader } from 'stories';
import { useGetApiSettingsReportDataPeriodBatchGroupsQuery } from 'bundles/Settings/actions/report/dataPeriodsApiExtended';
import {
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import ReportSettingsScreenLayout from 'bundles/Settings/shared/ReportSettingsScreenLayout';

export default function ReportDataPeriodBulks() {
  const [params, setParams] = useState<IFetchDataPeriodBatchGroupsParams>({
    sort: {
      field: 'period',
      order: 'desc',
    },
  });
  const {
    data: { items, meta } = {
      items: [],
      meta: { totalSize: 0, perPage: 0 },
    },
    isLoading,
  } = useGetApiSettingsReportDataPeriodBatchGroupsQuery({
    ...params,
  });

  return (
    <ReportSettingsScreenLayout.Content>
      <PageParamsProvider pageParams={params} setPageParams={setParams}>
        <div className="flex items-center">
          <div className="flex-grow" />
          <PageParamsSearch />
        </div>
        {isLoading ? (
          <AnimationLoader className="static h-[360px]" />
        ) : (
          <ReportDataPeriodBulkList items={items} />
        )}
      </PageParamsProvider>
    </ReportSettingsScreenLayout.Content>
  );
}

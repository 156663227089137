import React from 'react';
import { Dropdown, DropdownItem } from '@/stories';
import {
  BoardModal,
  useDeleteBoard,
} from 'bundles/Shared/features/dashboard/boardCRUD';
import { EagleEyeBoard } from 'bundles/Shared/entities/dashboard';
import { useModal } from '@/shared/lib/hooks/useModal';

interface Props extends Omit<React.ComponentProps<typeof Dropdown>, 'items'> {
  board: Pick<EagleEyeBoard, 'id' | 'name'>;
  onBoardDelete: () => void;
}

export function DashboardBoardActions({
  children,
  board,
  onBoardDelete,
  ...props
}: React.PropsWithChildren<Props>) {
  const { openModal, modalList } = useModal();
  const [deleteBoard] = useDeleteBoard();

  return (
    <>
      <Dropdown
        className="sre-dropdown-v2"
        items={
          <>
            <DropdownItem
              iconName="edit"
              onClick={() =>
                openModal(
                  BoardModal,
                  {
                    board,
                  },
                  { topLevelRender: false },
                )
              }
            >
              Rename
            </DropdownItem>
            <DropdownItem
              iconName="trash"
              onClick={async () => {
                await deleteBoard({
                  boardId: board.id,
                });
                onBoardDelete();
              }}
            >
              Delete
            </DropdownItem>
          </>
        }
        {...props}
      />
      {modalList}
    </>
  );
}

import { useCapitalUnitPricesByInvestmentClass } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import {
  AllocationProvider,
  useAllocationContext,
} from 'bundles/REconcile/components/AllocationProvider';
import styles from 'bundles/REturn/components/Ownership/modals/bulkAddModal.module.scss';
import CapitalInvestmentsEditor, {
  TEditableCapitalInvestments,
} from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitalInvestmentsEditor';
import { capitalInvestmentSumMapper } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import EnterEntriesStep from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/EnterEntriesStep';
import EnterTotalFormStep from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/EnterTotalFormStep';
import ProgressDivider from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/ProgressDivider';
import React, { useCallback, useEffect } from 'react';

interface Props {
  capitalInvestments: TEditableCapitalInvestments[];
  onCapitalInvestmentsChange: React.Dispatch<
    React.SetStateAction<TEditableCapitalInvestments[]>
  >;
  onValidChange: (valid: boolean) => void;
}

const DEFAULT_COMMITMENT_INVESTMENT_CLASS: InvestmentIndex['investmentClass'] =
  'b';

function EnterCommitmentsStep({
  capitalInvestments,
  onValidChange,
  onCapitalInvestmentsChange,
}: Props) {
  const { capitalUnitPricesPartitionByInvestmentClass } =
    useCapitalUnitPricesByInvestmentClass({
      investmentClass: DEFAULT_COMMITMENT_INVESTMENT_CLASS,
    });

  const [unitPricesClassB] = capitalUnitPricesPartitionByInvestmentClass;

  const {
    total,
    setTotal,
    totalValid,
    allocationFlags,
    allItemsAreAllocated,
    allocatedAmount,
  } = useAllocationContext()!;

  const valid = !total || (totalValid && allocationFlags.isFullyAllocated);

  useEffect(() => {
    onValidChange(valid);
  }, [capitalInvestments, total]);

  return (
    <>
      <div className="bg-neutral-000 px-tw-6 pt-tw-4">
        <EnterTotalFormStep
          title="Enter Total Commitment"
          stepNumber={1}
          activated={totalValid}
          setTotal={setTotal}
        />
        <EnterEntriesStep
          title="Enter your Commitment below"
          stepNumber={2}
          activated={allItemsAreAllocated && allocationFlags.isFullyAllocated}
          total={total ?? 0}
          currentTotal={allocatedAmount ?? 0}
        />
      </div>
      <ProgressDivider total={total ?? 0} currentTotal={allocatedAmount} />
      <div className={cn('gap-tw-2 p-tw-6', styles.bulkAddModalFields)}>
        <CapitalInvestmentsEditor
          commitmentsMode
          withDateColumn
          capitalInvestments={capitalInvestments}
          onCapitalInvestmentsChange={onCapitalInvestmentsChange}
          investmentClass={DEFAULT_COMMITMENT_INVESTMENT_CLASS}
          unitPrices={unitPricesClassB}
        />
      </div>
    </>
  );
}

export default (props: Props) => {
  const updateItem = useCallback(
    (index: number, value: number) => {
      props.onCapitalInvestmentsChange((prevState) =>
        prevState.map((ci, i) => {
          if (index === i) {
            return {
              ...ci,
              amount: value,
            };
          }
          return ci;
        }),
      );
    },
    [props.onCapitalInvestmentsChange],
  );

  return (
    <AllocationProvider
      items={props.capitalInvestments}
      total={undefined}
      updateItem={updateItem}
      sumMapper={capitalInvestmentSumMapper}
    >
      <EnterCommitmentsStep {...props} />
    </AllocationProvider>
  );
};

import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button, Field, ModalHeaderWithSubtitle } from '@/stories';
import { ResizableSidePanel } from '@/stories/Modals/Modal/ResizableSidePanel';
import { yupResolver } from '@hookform/resolvers/yup';
import TextEditor from 'bundles/Shared/components/TextEditor';
import {
  DEFAULT_REPORT_BUILDER_TEXTAREA_FORM,
  REPORT_BUILDER_TEXTAREA_SCHEMA,
  ReportBuilderTextAreaForm,
} from 'bundles/Shared/widgets/dashboard/widgets/textArea';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';

export const TextAreaWidgetSettingsModal = ({
  onClose,
  onSubmit,
  ...props
}: DialogProps<ReportBuilderTextAreaForm> &
  Partial<ReportBuilderTextAreaForm>) => {
  const methods = useForm<ReportBuilderTextAreaForm>({
    defaultValues: {
      ...DEFAULT_REPORT_BUILDER_TEXTAREA_FORM,
      ...props,
    },
    resolver: yupResolver(REPORT_BUILDER_TEXTAREA_SCHEMA),
  });
  const {
    watch,
    setValue,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;

  const handleSubmit = methods.handleSubmit((values) => onSubmit?.(values));

  return (
    <ResizableSidePanel
      onClose={onClose}
      onSubmit={handleSubmit}
      position="right"
      actions={[
        <Button
          key="1"
          variant="secondary"
          onClick={onClose}
          children="Cancel"
        />,
        <Button
          key="2"
          variant="success"
          onClick={handleSubmit}
          children="Submit"
          disabled={!isValid || isSubmitting || !isDirty}
        />,
      ]}
      header={<ModalHeaderWithSubtitle title="TextArea" subtitle="Widgets" />}
    >
      <div className="flex h-full flex-col gap-tw-4 p-tw-4">
        <FormProvider {...methods}>
          <FieldsContainer className="h-full">
            <Field
              className="h-full"
              labelText="What would you like to write about?"
              required
            >
              <TextEditor
                className="text-editor-h-full"
                text={watch('html')!}
                setText={(text) => {
                  setValue('html', text, {
                    shouldDirty: true,
                  });
                }}
              />
            </Field>
          </FieldsContainer>
        </FormProvider>
      </div>
    </ResizableSidePanel>
  );
};

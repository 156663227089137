import { GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiArg } from '@/entities/report/chargeCodes/api/settingsReportOperationalChargeCodesGeneratedApi';
import { useSettingsReportChargeCodesExport } from '@/features/report/chargeCodes/settingsExport/lib';
import { ExportButton } from '@/shared/ui/ExportButton';

export const SettingsReportChargeCodesExportButton = ({
  params,
}: {
  params: GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiArg;
}) => {
  const [getFile, options] = useSettingsReportChargeCodesExport();

  return (
    <ExportButton
      onClick={() => getFile(params)}
      disabled={options.isLoading}
      loading={options.isLoading}
    />
  );
};

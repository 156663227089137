import http from 'lib/http';
import { stringify } from 'qs';
import { AnyAction } from 'redux';
import { IReportBudget, TBudgetState } from 'types/ReportBudget';
import { LegalEntity } from '@/entities/core/legalEntity';
import { ISortParams } from 'types/Sort';
import { TInvestmentObjectClass } from 'types/IInvestmentObject';
import { IPaginationParams } from 'types/Pagination';
import { TOperationalBudgetYears } from '../reducers/budgetsReducer';
import { ILegalEntitiable } from '../../../types/LegalEntitiable';
import {
  IUpdateOperationalBudgetsLineItemBulkRequest,
  IUpdateOperationalBudgetsLineItemBulkResponse,
} from './operationalBudgetsTypes';

export interface IOperationalBudgetFilter {
  years?: number[];
  states?: TBudgetState[];
  legal_entity_ids?: number[];
  legal_entitiables?: Record<TInvestmentObjectClass, number[]>;
  query?: string;
}

export type TOperationalBudgetSortFields =
  | 'id'
  | 'year'
  | 'state'
  | 'updated_at'
  | 'legal_entity_name'
  | 'legal_entitiable';
export type TOperationalBudgetSort = ISortParams<TOperationalBudgetSortFields>;

export type TOperationalBudgetFetchArgs = IOperationalBudgetFilter &
  IPaginationParams & { sort?: TOperationalBudgetSort };

export const OPERATIONAL_BUDGETS_LOADED = 'budgets/operational/budgetsLoaded';
export const OPERATIONAL_BUDGETS_LOADING = 'budgets/operational/budgetsLoading';
export const OPERATIONAL_BUDGETS_YEARS_LOADED =
  'budgets/operational/yearsLoaded';
export const OPERATIONAL_BUDGETS_YEARS_FILTER =
  'budgets/operational/yearsFilter';

interface IOperationalBudgetsLoaded extends AnyAction {
  type: typeof OPERATIONAL_BUDGETS_LOADED;
  payload: {
    budgets: IReportBudget[];
    meta: {
      perPage: number;
      totalSize: number;
    };
  };
}

export const operationalBudgetsLoaded = (
  payload: IOperationalBudgetsLoaded['payload'],
): IOperationalBudgetsLoaded => ({
  type: OPERATIONAL_BUDGETS_LOADED,
  payload,
});

export function matchOperationalBudgetsLoaded(
  action: AnyAction,
): action is IOperationalBudgetsLoaded {
  return action.type === OPERATIONAL_BUDGETS_LOADED;
}

interface IOperationalBudgetsLoading extends AnyAction {
  type: typeof OPERATIONAL_BUDGETS_LOADING;
}

export const operationalBudgetsLoading = (): IOperationalBudgetsLoading => ({
  type: OPERATIONAL_BUDGETS_LOADING,
});

export function matchOperationalBudgetsLoading(
  action: AnyAction,
): action is IOperationalBudgetsLoading {
  return action.type === OPERATIONAL_BUDGETS_LOADING;
}

interface IBudgetYearsLoaded extends AnyAction {
  type: typeof OPERATIONAL_BUDGETS_YEARS_LOADED;
  payload: {
    years: TOperationalBudgetYears;
    legalEntities: LegalEntity[];
    legalEntitiables: ILegalEntitiable[];
  };
}
export const operationalBudgetYearsLoaded = (
  payload: IBudgetYearsLoaded['payload'],
): IBudgetYearsLoaded => ({
  type: OPERATIONAL_BUDGETS_YEARS_LOADED,
  payload,
});

export function matchOperationalBudgetYearsLoaded(
  action: AnyAction,
): action is IBudgetYearsLoaded {
  return action.type === OPERATIONAL_BUDGETS_YEARS_LOADED;
}

interface IBudgetYearsFilter extends AnyAction {
  type: typeof OPERATIONAL_BUDGETS_YEARS_FILTER;
  payload: number[];
}

export const operationalBudgetYearsFilter = (
  payload: IBudgetYearsFilter['payload'],
): IBudgetYearsFilter => ({
  type: OPERATIONAL_BUDGETS_YEARS_FILTER,
  payload,
});

export function matchOperationalBudgetYearsFilter(
  action: AnyAction,
): action is IBudgetYearsFilter {
  return action.type === OPERATIONAL_BUDGETS_YEARS_FILTER;
}

// thunks

export function fetchOperationalBudgetYears(data) {
  return async (dispatch) => {
    // todo move to report_budget.js
    const res = await http.get(
      `/report_budgets/years${stringify(data, { addQueryPrefix: true })}`,
    );
    const responseData = await res.json();

    dispatch(
      operationalBudgetYearsLoaded({
        years: responseData.years,
        legalEntities: responseData.meta.legalEntities,
        legalEntitiables: responseData.meta.legalEntitiables,
      }),
    );
  };
}

export function fetchOperationalBudgets(data: TOperationalBudgetFetchArgs) {
  return async (dispatch) => {
    dispatch(operationalBudgetsLoading());

    // todo move to report_budget.js
    const res = await http.get(
      `/report_budgets${stringify(data, {
        addQueryPrefix: true,
        arrayFormat: 'brackets',
      })}`,
    );
    const responseData = await res.json();

    dispatch(
      operationalBudgetsLoaded({
        budgets: responseData.reportBudgets,
        meta: responseData.meta,
      }),
    );
  };
}

export async function updateOperationalBudgetsLineItemBulk(
  budgetId: number,
  bulk: IUpdateOperationalBudgetsLineItemBulkRequest,
) {
  const res = await http.put(
    `/report_budgets/${budgetId}/line_items/bulk`,
    bulk,
  );
  const data: IUpdateOperationalBudgetsLineItemBulkResponse = await res.json();

  if (data.errors) {
    toastr.error(data.errors);
    return;
  }

  return data;
}

import React from 'react';
import { Icon } from 'stories';
import './multipleItemsIcons.css';

interface Props {
  itemType?: 'documents' | 'media' | 'folder';
  items: any[];
}

export const MultipleItemsIcons = ({ itemType, items }: Props) => {
  const resolveIcon = () => {
    const icon = () => {
      switch (itemType) {
        case 'documents':
          return 'file';
        case 'media':
          return 'media';
        case 'folder':
          return 'folder';
        default:
          return 'file';
      }
    };

    const mainIcon = (
      <div className="documents-icon">
        <Icon iconName={icon()} className="documents-icon__icon-component" />
      </div>
    );

    switch (items.length) {
      case 1:
        return mainIcon;
      case 2:
        return (
          <>
            {mainIcon}
            <div className="documents-icon documents-icon_second" />
          </>
        );
      default:
        return (
          <>
            {mainIcon}
            <div className="documents-icon documents-icon_second" />
            <div className="documents-icon documents-icon_third" />
          </>
        );
    }
  };

  return <div className="documents-icon__container">{resolveIcon()}</div>;
};

export default MultipleItemsIcons;

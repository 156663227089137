import { ILegalEntitiableWithLegalEntities } from 'types/ReportTableSegment';
import { LegalEntity } from '@/entities/core/legalEntity';

export function getLegalEntityOptionsByLegalEntitiable(
  { id: entitiableId, type }: ILegalEntitiableWithLegalEntities,
  entities: LegalEntity[],
) {
  const filteredEntities = entities.filter(
    (le) =>
      le.legalEntitiableType === type && le.legalEntitiableId === entitiableId,
  );

  return (
    filteredEntities?.map(({ id, name, code }) => ({
      id,
      label: `${name} [${code}]`,
    })) ?? []
  );
}

export function getLegalEntityOptions(
  entitialbe: ILegalEntitiableWithLegalEntities,
) {
  return (
    entitialbe?.legalEntities.map((entity) => ({
      id: entity.id,
      value: entity.id,
      label: entity.name,
    })) ?? []
  );
}

import React, { useMemo } from 'react';
import { Icon, Popover, PseudoLink } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import pluralize from 'pluralize';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import styles from './PeriodLimitation.module.scss';
import {
  PeriodLimitationModal,
  PeriodsLimitationsValue,
} from './PeriodLimitationModal';
import {
  createRulesStateFromLimitation,
  preparePeriodLimitationRules,
  resolvePeriodLimitRoleLabel,
  resolvePeriodLimitText,
} from '../helpers/periodLimitationUtils';

interface Props {
  periodLimitations: PeriodsLimitationsValue;
  onChange?: (newValue: PeriodsLimitationsValue) => void;
  readOnlyMode?: boolean;
  pseudoLinkClassName?: string;
}
export const PeriodLimitations = ({
  periodLimitations,
  onChange,
  readOnlyMode,
  pseudoLinkClassName,
}: Props) => {
  const usersStore = useAppSelector((state) => state.users);
  const { userRoles, userRolesConnected } = usersStore;
  const { openModal } = useModal();

  const rules = useMemo(
    () => createRulesStateFromLimitation(periodLimitations),
    [periodLimitations],
  );

  const handleOpen = async () => {
    if (!userRolesConnected) return;

    const res = await openModal(PeriodLimitationModal, {
      periodLimitations,
    });

    if (res) {
      onChange(preparePeriodLimitationRules(res));
    }
  };

  const buttonText = () => {
    if (rules.length === 0) return <div className="text-neutral-500">None</div>;
    return (
      <div className="text-neutral-900">
        {rules.length} {pluralize('Rule', rules.length)}
      </div>
    );
  };

  const content = rules.map((rule) => (
    <div className={cn(styles.popoverItem)} key={rule.id}>
      <p className="light-60 secondary-regular">Role</p>
      <p className="dark-60 secondary-semibold">
        {resolvePeriodLimitRoleLabel(userRoles, rule)}
      </p>
      <p className="light-60 secondary-regular">Period</p>
      <p className="dark-60 secondary-regular">
        {resolvePeriodLimitText(rule.limit)}
      </p>
    </div>
  ));

  const link = (
    <PseudoLink
      className={cn('inline-semibold text-ellipsis', pseudoLinkClassName)}
    >
      {buttonText()}
    </PseudoLink>
  );

  const popover = (
    <Popover
      template={content}
      maxWidth="400px"
      trigger="mouseenter"
      touch={false}
      hideOnExpandedAreaClick
      appendToBody
      placement="bottom"
      arrowPosition="center"
      classes={{
        spanContainer: 'd-inline-flex mnw-0',
      }}
    >
      {link}
    </Popover>
  );

  if (readOnlyMode) {
    return <div className="w-fit">{Boolean(rules.length) && popover}</div>;
  }

  return (
    <div className="flex h-[32px] w-fit items-center gap-tw-1.5 !rounded-lg bg-white px-tw-2 shadow-z-020">
      <Icon iconName="lockedByTime" />
      <p className="inline-semibold text-info-055">Period Limitation:</p>
      {rules.length ? popover : link}
      <Icon className="cursor-pointer" iconName="edit" onClick={handleOpen} />
    </div>
  );
};

import { useParams } from '@reach/router';
import { useGetInvoiceDocumentQuery } from 'bundles/Construction/api/core';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import prettyBytes from 'pretty-bytes';
import React, { FC } from 'react';
import { CurrencyFormatter, Icon } from 'stories';
import { selectInvoiceDetails } from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { formatDate } from '@/shared/lib/formatting/dates';
import { InvoiceLineItem } from '@/shared/types/reconcile/Invoice';
import pluralize from 'pluralize';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { cn } from '@/shared/lib/css/cn';
import LineItemCard from '../lineItems/LineItemCard';

interface IReviewInvoiceProps {
  lineItems: InvoiceLineItem[];
}

const ReviewInvoice: FC<IReviewInvoiceProps> = ({ lineItems }) => {
  const params = useParams();
  const invoiceDetails = useAppSelector(selectInvoiceDetails);

  const { data: invoiceDocument } = useGetInvoiceDocumentQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });
  return (
    <div className="bg-light px-tw-6 py-tw-6">
      <div
        className={cn(
          'flex flex-col gap-tw-6',
          styles.reviewContainer,
          styles.tableBody,
        )}
      >
        <div className="flex flex-col gap-tw-2">
          <div className="body-semibold text-light-90">Invoice</div>
          <div className="flex flex-[1] gap-[10px] rounded-2xl border border-solid border-light-10 bg-white p-tw-2">
            <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-light-5">
              <Icon className="text-2xl" color="red" iconName="docPdf" />
            </div>
            <div>
              <div className="dark-60 inline-semibold">
                {invoiceDocument?.title}
              </div>
              <div className="light-60 secondary-regular flex gap-tw-1">
                <span>{prettyBytes(invoiceDocument?.size || 0)}</span>
                <span>•</span>
                <span>{invoiceDocument?.extension}</span>
              </div>
            </div>
          </div>
          <div className="secondary-regular flex gap-tw-1 rounded-lg bg-light p-tw-2">
            <span>Uploaded by</span>
            <span className="text-light-90">
              {invoiceDocument?.source?.email ?? invoiceDocument?.source?.name}
            </span>
            <span>on</span>
            <span className="text-light-90">{invoiceDocument?.uploadedAt}</span>
          </div>
        </div>
        <div className="flex flex-col gap-tw-4">
          <div className="body-semibold text-light-90">Invoice Details</div>
          <div className="rounded-lg bg-white">
            <div className="inline-regular flex justify-between border-b border-solid border-light px-tw-4 py-tw-2">
              <div className="text-light-60">Number</div>
              <div className="text-dark-60">{invoiceDetails.number}</div>
            </div>
            <div className="inline-regular flex justify-between border-b border-solid border-light px-tw-4 py-tw-2">
              <div className="text-light-60">Invoice Date</div>
              <div className="text-dark-60">
                {formatDate(invoiceDetails.date, 'MMM DD, YYYY')}
              </div>
            </div>
            <div className="inline-regular flex justify-between border-b border-solid border-light px-tw-4 py-tw-2">
              <div className="text-light-60">Vendor</div>
              <div className="text-dark-60">
                {invoiceDetails.vendor_name.value}
              </div>
            </div>
            <div className="inline-regular flex justify-between border-b border-solid border-light px-tw-4 py-tw-2">
              <div className="text-light-60">Amount</div>
              <div className="text-dark-60">
                <CurrencyFormatter value={invoiceDetails.amount} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-tw-2">
          <div className="light-90 body-semibold">
            {lineItems.length} Development Line{' '}
            {pluralize('item', lineItems.length)}
          </div>
          <div className="label-regular">Created Manually</div>
          <div className="flex flex-col gap-tw-1.5">
            {lineItems.map((item, idx) => (
              <LineItemCard key={`lineItem${idx}`} {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewInvoice;

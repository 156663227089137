import React from 'react';
import { FUNDING_SOURCES_KIND_CONFIG } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/consts';
import { FUNDING_SOURCE_KINDS } from '@/shared/types/reconcile/FundingSource';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';

type FundingSourceTypeSelectProps = {
  onChange: (value: `${FUNDING_SOURCE_KINDS}`) => void;
  value: `${FUNDING_SOURCE_KINDS}`;
};

export const FundingSourceTypeSelect = ({
  onChange,
  value,
}: FundingSourceTypeSelectProps) => (
  <RadioGroup
    value={value}
    onChange={(o) => onChange(o.value as `${FUNDING_SOURCE_KINDS}`)}
    options={Object.values(FUNDING_SOURCE_KINDS).map((key) => ({
      label: FUNDING_SOURCES_KIND_CONFIG[key].title,
      value: key,
    }))}
  />
);

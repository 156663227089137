import { settingsReportOperationalChargeCodesApi } from '@/entities/report/chargeCodes/api/settingsReportOperationalChargeCodesGeneratedApi';

export const settingsReportOperationalChargeCodesEnhancedApi =
  settingsReportOperationalChargeCodesApi.enhanceEndpoints({
    addTagTypes: ['SourceChargeCodes'],
    endpoints: {
      getApiSettingsReportRentRollSourceChargeCodes: {
        providesTags: ['SourceChargeCodes'],
      },
      putApiSettingsReportRentRollSourceChargeCodesSetChargeCode: {
        invalidatesTags: ['SourceChargeCodes'],
        transformResponse: (res) => {
          toastr.success('Source Charge Code(s) updated');
          return res;
        },
      },
    },
  });

export const {
  useGetApiSettingsReportRentRollSourceChargeCodesQuery,
  usePutApiSettingsReportRentRollSourceChargeCodesSetChargeCodeMutation,

  // import/export doesn't work properly with rtk-query approach
  useGetApiSettingsReportRentRollSourceChargeCodesExportCsvQuery,
  usePostApiSettingsReportRentRollSourceChargeCodesImportMutation,
} = settingsReportOperationalChargeCodesEnhancedApi;

import { useState } from 'react';

type WrapperClasses =
  | 'd-none'
  | 'drap-and-drop-overlay drap-and-drop-overlay_can-drop'
  | 'drap-and-drop-overlay';

export default function useDropWrapperClasses() {
  const [dropWrapperClasses, setDropWrapperClasses] =
    useState<WrapperClasses>('d-none');

  const onDrop = () => {
    setDropWrapperClasses('d-none');
  };

  const onDragOver = () => {
    setDropWrapperClasses(
      'drap-and-drop-overlay drap-and-drop-overlay_can-drop',
    );
  };

  const onDragExit = () => {
    setDropWrapperClasses('drap-and-drop-overlay');
  };

  return {
    dropWrapperClasses,
    setDropWrapperClasses,
    onDrop,
    onDragOver,
    onDragExit,
  };
}

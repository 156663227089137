import { Link, RouteComponentProps } from '@reach/router';
import {
    resolveAddress,
    resolveInfoFieldEntries,
} from 'bundles/Scoreboard/Pipeline/components/DealItem/helpers/utils';
import { IDeal } from 'bundles/Scoreboard/Pipeline/components/DealItem/types';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import http from 'lib/http';
import { capitalize } from 'lodash-es';
import { useEffect, useState } from 'react';
import {
    AssetFieldCard,
    Badge,
    CollapsableSection,
    Icon,
    IconButton,
    LocationCollapsableSection,
    Tooltip,
} from 'stories';

interface Props extends RouteComponentProps {
  dealId?: string;
}

export function DealItem({ dealId }: Props) {
  const [deal, setDeal] = useState<IDeal | null>(null);

  useEffect(() => {
    async function fetchDeal() {
      const res = await http.get(`/pipeline/deals/${dealId}`);
      const data: IDeal = await res.json();
      setDeal(data);
    }

    fetchDeal();
  }, []);

  if (deal === null) return <LoadingPanel />;

  const { address, data } = deal;

  const fields = resolveInfoFieldEntries(data);

  const content = fields.map(([k, v]) => (
    <AssetFieldCard
      key={k}
      hideMove
      expandedActions
      canManage={false}
      fieldCard={{
        value: v,
        measure_name: k,
        visible: true,
      }}
    />
  ));

  const header = (
    <header className="p-m flex items-center gap-m bg-white">
      <Link to={ROUTES_ROOT.scoreboards.pipeline.fullPath}>
        <IconButton variant="white" iconName="arrowLeft" />
      </Link>
      <p className="dark-60 header6-regular">{deal.name}</p>
      {data.deal_status && (
        <Badge label="Status">{capitalize(data.deal_status)}</Badge>
      )}
      {data.stage && <Badge label="Stage">{capitalize(data.stage)}</Badge>}
      {data.deal_type && (
        <Badge label="Type">{capitalize(data.deal_type)}</Badge>
      )}
      {data.deal_members && (
        <Tooltip
          className="flex"
          arrowPosition="start"
          placement="top-start"
          maxWidth="auto"
          mainText={`This deal has ${
            data.deal_members.split(', ').length
          } members`}
        >
          <Badge
            classes={{ value: 'font-weight-600 blue' }}
            label={<Icon iconName="users" />}
            backgroundColor="var(--blue-light-5)"
          >
            {data.deal_members.split(', ').length}
          </Badge>
        </Tooltip>
      )}
    </header>
  );

  return (
    <div>
      {header}
      <div className="flex mx-auto my-m w-max gap-m">
        {Boolean(fields.length) && (
          <div className="rounded-[16px] w-600p h-max overflow-hidden">
            <CollapsableSection
              canManage={false}
              count={fields.length}
              title="Deal Info"
              content={content}
            />
          </div>
        )}
        {address && (
          <div className="rounded-[16px] w-600p h-max overflow-hidden">
            <LocationCollapsableSection
              address={resolveAddress(address)}
              latitude={address.lat}
              longitude={address.lng}
              canManage={false}
            />
          </div>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import LinkButton from 'stories/LinkButton/LinkButton';
import { IFireStationStats } from 'types/FireStationStats';
import { isLeftSidebarPinned } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { navigate } from '@reach/router';
import { FireIcon } from '../FireIcon/FireIcon';
import { NavFireButton } from '../NavFireButton/NavFireButton';
import {
  mapFireStatsForSorting,
  sortFireStatsByPriority,
} from '../utils/utils';
import './NavFireSection.css';

interface Props {
  fireStationStats: IFireStationStats;
  fireStationPage?: boolean;
  className?: string;
  setExpanded: (expanded: boolean) => void;
}

export const NavFireSection = ({
  fireStationPage,
  fireStationStats,
  className,
  setExpanded,
  ...props
}: Props) => {
  const modifiedFireStationStats = mapFireStatsForSorting(fireStationStats);
  const leftSidebarPinned = useAppSelector(isLeftSidebarPinned);
  return (
    <div
      className={cn(
        'sre-nav-fire-section',
        fireStationStats &&
          fireStationPage &&
          `sre-nav-fire-section_${
            sortFireStatsByPriority(modifiedFireStationStats)[0]?.priority ||
            'none'
          }`,
        !modifiedFireStationStats?.length && 'sre-nav-fire-section_none',
        fireStationPage && '_onPage',
        className,
      )}
      {...props}
    >
      <div className="flex justify-between mb-s gap-m px-xs">
        <h5
          className={cn(
            'secondary-semibold',
            fireStationPage ? 'white' : 'dark-60',
          )}
        >
          Fire Station
        </h5>
        {!fireStationPage && (
          <LinkButton
            className="secondary-semibold"
            onClick={() => {
              navigate('/fire-station');
              if (!leftSidebarPinned) setExpanded(false);
            }}
          >
            All
          </LinkButton>
        )}
      </div>
      <div className="flex gap-s">
        {(modifiedFireStationStats?.length &&
          sortFireStatsByPriority(modifiedFireStationStats).map((task) => (
            <NavFireButton
              className="flex-grow bg-white"
              key={task.priority}
              priority={task.priority}
              count={task.count}
            />
          ))) || (
          <div className="sre-nav-fire-section__no-tasks fire-sect-no-tasks flex w-full justify-center items-center p-xs rounded-[0.5rem] light-60 secondary-regular gap-xs bg-white">
            <FireIcon priority="none" />
            <div className="fire-sect-no-tasks__text">
              No important tasks yet
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavFireSection;

import React, { useMemo } from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import Table from 'bundles/Shared/components/Table/Table';
import {
  useGetApiSettingsUserManagementUserActivitiesReportQuery,
  UserActivityReport,
} from 'bundles/Shared/entities/user/api/userActivityApi';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { nameOf } from 'types/utils';
import dayjs from 'dayjs';
import { formatDate } from '@/shared/lib/formatting/dates';
import { resloveClassesFor } from 'bundles/Shared/widgets/user/activity/lib';
import { formatTimeFromSeconds } from 'bundles/Shared/widgets/user/activity/ui/useColumns';
import { USER_ACTIVITY_REPORT_MODAL_TEXT } from '../config';
import { orderBy } from 'lodash-es';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps;
type UserActivityReportRecord = UserActivityReport['items'][number];

export function UserActivityReportModal({ onClose }: Props) {
  const { data, isLoading } =
    useGetApiSettingsUserManagementUserActivitiesReportQuery();
  const items = orderBy(data?.items ?? [], (item) => item.dateFrom, 'desc');
  const columns = useMemo<IColumn<UserActivityReportRecord>[]>(
    () => [
      {
        dataField: nameOf<UserActivityReportRecord>('dateFrom'),
        text: 'Period',
        formatter: ({ row }) => {
          const dateFrom = dayjs.unix(row.dateFrom);
          const dateTo = dayjs.unix(row.dateTo);
          const isSameYear = dateFrom.isSame(dateTo, 'year');
          const yearFromLabel = isSameYear ? null : `, ${dateFrom.year()}`;
          const yearToLabel = `, ${dateTo.year()}`;
          return (
            <>
              {formatDate(dateFrom, 'MMM DD')}
              {yearFromLabel} - {formatDate(dateTo, 'MMM DD')}
              {yearToLabel}
            </>
          );
        },
      },
      {
        dataField: nameOf<UserActivityReportRecord>('users'),
        text: 'Users',
        classes: resloveClassesFor('users'),
      },
      {
        dataField: nameOf<UserActivityReportRecord>('sessions'),
        text: 'Visits',
        classes: resloveClassesFor('sessions'),
      },
      {
        dataField: nameOf<UserActivityReportRecord>('duration'),
        text: 'Duration',
        classes: resloveClassesFor('duration'),
        formatter: ({ row }) => formatTimeFromSeconds(row.duration),
      },
    ],
    [],
  );
  return (
    <SidePanel
      header="Summary Report"
      classes={{
        body: 'gap-tw-4 flex flex-col',
      }}
      toggle={onClose}
    >
      {USER_ACTIVITY_REPORT_MODAL_TEXT}
      {isLoading && <LoadingTable rows={5} />}
      {!isLoading && (
        <Table
          classes={{
            container: 'table-gen-2',
            th: 'bg-neutral-900 !text-neutral-000',
            table: '!rounded-lg overflow-hidden',
          }}
          columns={columns}
          items={items}
        />
      )}
    </SidePanel>
  );
}

import React, { useEffect, useState } from 'react';
import { useVerifyInvoiceMutation } from 'bundles/Construction/api/invoices';
import { navigate, useParams } from '@reach/router';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import { fetchCompanies } from 'bundles/Construction/reducers/ReconcileSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  clearInvoiceLineItems,
  selectInvoiceLineItems,
} from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import {
  useGetDevelopmentCategoryFlatQuery,
  useGetInvoiceDocumentQuery,
} from 'bundles/Construction/api/core';
import { AnimationLoader, Button, IconButton, Stepper } from 'stories';
import { replacePathParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { cn } from '@/shared/lib/css/cn';
import styles from '../invoice/Invoice.module.scss';
import { calcTotal } from 'bundles/DrawPackage/InvoicesFlow/Invoices/initData';
import {
  backToPreviuosVerifyStep,
  clearInvoiceDetails,
  initInvoiceDocumentLink,
  resetInvoiceState,
  selectActiveVerifySteps,
  selectESignDataDocument,
  selectInvoiceDetails,
  selectInvoiceDocumnentLink,
  selectInvoiceESignData,
  selectVerifySteps,
  setActiveVerifyStep,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import InvoiceDetails from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/steps/InvoiceDetails';
import LineItemsInvoices from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/steps/LineItemsInvoices';
import ReviewInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/steps/ReviewInvoice';
import Errors from 'bundles/Errors';
import InvoiceESignature from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/steps/InvoiceESignature';
import { transformLineSelectedItems } from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/utils';
import { transformCategorizedIdToKey } from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export type TLineItemFlowType = 'manually' | 'select';

const BUTTON_SETTINGS = {
  details: {
    title: 'Save Invoice Details',
    subtitle: 'You need to fill in all the details',
    nextStep: 'lineItems',
    prevStep: null,
  },
  lineItems: {
    title: 'Create Line Items',
    subtitle:
      'The Sum of Development Line Items has to Match the Invoice’s Amount',
    nextStep: 'eSign',
    prevStep: 'details',
  },
  eSign: {
    title: 'Set e-Signature Placement',
    subtitle: '',
    nextStep: 'review',
    prevStep: 'lineItems',
  },
  review: {
    title: 'Verify Invoice',
    subtitle: 'Please check the data is correct before verifying',
    nextStep: 'final',
    prevStep: 'eSign',
  },
};

const VerifyInvoice = () => {
  const verifySteps = useAppSelector(selectVerifySteps);
  const activeStep = useAppSelector(selectActiveVerifySteps);
  const dispatch = useAppDispatch();
  const lineItems = useAppSelector(selectInvoiceLineItems);
  const invoiceDetails = useAppSelector(selectInvoiceDetails);
  const invoiceDocumentLink = useAppSelector(selectInvoiceDocumnentLink);
  const useESignDocument = useAppSelector(selectESignDataDocument);
  const eSignData = useAppSelector(selectInvoiceESignData);

  const [flow, setFlow] = useState<'manually' | 'select'>('manually');

  const params = useParams();

  const {
    data: invoiceDocument,
    isLoading,
    error: isErrorInvoiceDocument,
  } = useGetInvoiceDocumentQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const { data: categoriesFlat, error: isErrorCategoriesFlat } =
    useGetDevelopmentCategoryFlatQuery({
      legalEntityCode: params?.legalEntityCode,
      without_sovcs: 'false',
    });

  const [verifyInvoice] = useVerifyInvoiceMutation();

  const transformLineItems = lineItems.map((item) => {
    if (item.developmentCategory) {
      return item;
    }
    // todo standardize category_key
    const categoryItem = categoriesFlat?.find(
      (category) =>
        category.key ===
          transformCategorizedIdToKey(item.category_id, item.category_type) ||
        category.key === item.category_id,
    );

    return {
      amount: item.amount,
      developmentCategory: {
        name: categoryItem?.name ?? '',
        path: categoryItem?.categoryPath ?? '',
      },
      selected: item?.selected,
      description: item?.description ?? item?.remark,
    };
  });

  const selectedLineItems = lineItems.filter(
    (lineItem) => flow !== 'select' || lineItem.selected,
  );

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(resetInvoiceState());
  }, []);

  useEffect(() => {
    dispatch(initInvoiceDocumentLink(invoiceDocument?.fileUrl));
  }, [invoiceDocument]);

  const handleClose = () => {
    navigate(
      replacePathParams(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
        },
      ),
    );
  };

  if (isLoading) return <AnimationLoader />;

  const nextStepFunc = async (step: string) => {
    if (activeStep === 'review') {
      const res = await verifyInvoice({
        legalEntityCode: params?.legalEntityCode,
        invoiceId: params.invoiceId,
        body: {
          ...invoiceDetails,
          date: formatToDateStringForRequest(invoiceDetails.date),
          vendor_name: invoiceDetails.vendor_name.value,
          line_items: transformLineSelectedItems(
            selectedLineItems,
            invoiceDetails.vendor_name.value,
            categoriesFlat,
          ),
          selected_line_items: selectedLineItems
            .filter((lineItem) => lineItem.id)
            .map((lineItem) => lineItem.id),
          approval_stamp: {
            name: eSignData.stamp.base.name.value,
            date: eSignData.stamp.base.date.value,
            signature: eSignData.stamp.base.signature.value,
            border: eSignData.stamp.additional.border.value,
            header: eSignData.stamp.additional.header.value,
            color: eSignData.stamp.additional.color.value.slice(1),
            involved_pages: eSignData.involved_pages,
            page_position: eSignData.placement,
          },
        },
      });
      if (res.error) return false;
      dispatch(setActiveVerifyStep('details'));
      dispatch(clearInvoiceLineItems());
      dispatch(clearInvoiceDetails());
      navigate(
        replacePathParams(
          ROUTES_ROOT.reconcile.development.legalEntity.invoices.fullPath,
          {
            legalEntityCode: params.legalEntityCode,
          },
        ),
      );
    } else {
      dispatch(setActiveVerifyStep(step));
    }
  };

  const prevStepFunc = (step: string, nextStep: string) => {
    dispatch(
      backToPreviuosVerifyStep({
        step,
        nextStep,
      }),
    );
  };

  const canNextStep = () => {
    if (activeStep === 'details') {
      return !Object.keys(invoiceDetails).every((key) =>
        Boolean(invoiceDetails[key]),
      );
    }
    if (activeStep === 'lineItems') {
      return Boolean(
        calcTotal(selectedLineItems) !== Number(invoiceDetails?.amount),
      );
    }
  };

  const changeFlow = (flowType: TLineItemFlowType) => {
    setFlow(flowType);
  };

  return (
    <div>
      <FilePreview
        file={{
          url: invoiceDocumentLink,
        }}
        handleClose={handleClose}
        header={
          <div className="word-break header6-bold text-center text-dark-60">
            Verify Invoice
          </div>
        }
        withRequestHeader={useESignDocument}
        withoutHeader
      >
        <div className="absolute right-tw-5 top-tw-5 flex gap-tw-2">
          <IconButton
            iconName="close"
            size="l"
            onClick={handleClose}
            variant="secondary"
          />
        </div>
        {(isErrorInvoiceDocument || isErrorCategoriesFlat) && (
          <Errors
            code="not_found"
            actionButton={{
              text: 'Back to Invoices',
              onClick: handleClose,
            }}
          />
        )}
        {!(isErrorInvoiceDocument || isErrorCategoriesFlat) && (
          <div
            className={cn('h-screen', {
              'bg-white': activeStep === 'details' || activeStep === 'eSign',
            })}
          >
            <div className="bg-white">
              <div className="header5-bold py-tw-6 text-center text-dark-60">
                Verify Invoice
              </div>
              <div className="border-b border-solid border-light-15 bg-white">
                <Stepper
                  steps={verifySteps}
                  className="pb-tw-4"
                  activeStep={activeStep}
                />
              </div>
            </div>
            <div className={cn('flex flex-col', styles.pageContainer)}>
              <div className="flex-1">
                {activeStep === 'details' && <InvoiceDetails />}
                {activeStep === 'lineItems' && (
                  <LineItemsInvoices flow={flow} changeFlow={changeFlow} />
                )}
                {activeStep === 'eSign' && <InvoiceESignature />}
                {activeStep === 'review' && (
                  <ReviewInvoice
                    lineItems={transformLineItems.filter((lineItem) =>
                      flow === 'select' ? lineItem.selected : lineItem,
                    )}
                  />
                )}
              </div>
              <div className="bg-white px-tw-6 pb-tw-6 pt-tw-4">
                <div className="flex flex-col gap-tw-3">
                  <div
                    className={cn(
                      'light-60 label-regular w-full',
                      styles.textButton,
                    )}
                  >
                    {BUTTON_SETTINGS[activeStep]?.subtitle}
                  </div>
                  <div className="flex gap-tw-4">
                    {activeStep !== 'details' && (
                      <Button
                        variant="secondary"
                        className="w-full"
                        onClick={() =>
                          prevStepFunc(
                            BUTTON_SETTINGS[activeStep].prevStep,
                            activeStep,
                          )
                        }
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="success"
                      className="w-full"
                      onClick={() =>
                        nextStepFunc(BUTTON_SETTINGS[activeStep].nextStep)
                      }
                      disabled={canNextStep()}
                    >
                      {BUTTON_SETTINGS[activeStep]?.title}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </FilePreview>
    </div>
  );
};

export default VerifyInvoice;

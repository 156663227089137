import React from 'react';
import Popover from 'stories/Popover/Popover';
import { cn } from '@/shared/lib/css/cn';

type Props = React.ComponentProps<typeof Popover>;

function MenuItemPopover({ className, ...props }: Props, ref) {
  return (
    <Popover
      trigger="mouseenter"
      className={cn(
        'shadow-m-light overflow-hidden rounded-2xl border border-light-30 bg-light p-0',
        className,
      )}
      appendTo={document.body}
      offset={[-10, 2]}
      hiddenArrow
      hideOnClick={false}
      ref={ref}
      {...props}
    />
  );
}

export default React.forwardRef(MenuItemPopover);

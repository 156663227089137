import http from 'lib/http';
import { stringify } from 'qs';

export const FETCH_USER_TAGS = 'symmetre-client-api/FETCH_USER_TAGS';
export const DELETE_USER_TAG = 'symmetre-client-api/DELETE_USER_TAG';
export const UPDATE_USER_TAG = 'symmetre-client-api/UPDATE_USER_TAG';
export const CREATE_USER_TAG = 'symmetre-client-api/CREATE_USER_TAG';

export function fetchUserTags() {
  return async (dispatch) => {
    const res = await http.get('/user_tags');
    const responseData = await res.json();

    dispatch({
      type: FETCH_USER_TAGS,
      payload: responseData,
    });
  };
}
export async function plainFetchUserTags(data) {
  const res = await http.get(`/user_tags${stringify(data, { addQueryPrefix: true })}`);
  const responseData = await res.json();
  return responseData;
}

export function deleteUserTag(tag) {
  return async (dispatch) => {
    const res = await http.del(`/user_tags/${tag.id}`);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('User Tag has been successfully removed');

      dispatch({
        type: DELETE_USER_TAG,
        payload: tag,
      });
    }
  };
}

export function editUserTag(data) {
  return async (dispatch) => {
    const res = await http.put(`/user_tags/${data.id}`, data);

    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('User Tag has been successfully updated');

      dispatch({
        type: UPDATE_USER_TAG,
        payload: responseData,
      });
    }
  };
}
export function createUserTag(data) {
  return async (dispatch) => {
    const res = await http.post('/user_tags', data);

    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('User Tag has been successfully created');

      dispatch({
        type: CREATE_USER_TAG,
        payload: responseData,
      });
    }
  };
}

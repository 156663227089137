import React, { ComponentProps, useRef } from 'react';
import { IconButton, PseudoLink, Popover } from 'stories';
import { formatDate, toDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import { PopoverRef } from 'stories/Popover/Popover';
import { LegalEntity } from '@/entities/core/legalEntity';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  MAP_KINDS_TO_TEXT,
  MAP_KINDS_TO_ICON_NAME,
} from './LEActionsDictionary';
import ActivityLogListModal from './ActivityLogListModal';

interface Props extends Omit<ComponentProps<typeof Popover>, 'template'> {
  legalEntity: LegalEntity;
}

function LastActivityOfLEPopover({ legalEntity, ...props }: Props) {
  const popoverRef = useRef<PopoverRef>();
  const { openModal } = useModal();
  return (
    <Popover
      maxWidth="auto"
      className="p-tw-2"
      template={
        <div className="flex gap-tw-4">
          <IconButton
            size="l"
            iconName={MAP_KINDS_TO_ICON_NAME[legalEntity.lastActivity.kind]}
          />
          <div>
            <div className="flex flex-col mb-s gap-xs">
              <div className="light-60 secondary-regular">
                {formatUnixDate(
                  legalEntity.lastActivity.updatedAt,
                  'LL [at] LT',
                )}
              </div>
              <div className="secondary-regular">
                {MAP_KINDS_TO_TEXT[legalEntity.lastActivity.kind] ||
                  'unknown action'}
              </div>
              <div className="light-60 secondary-regular">
                by {legalEntity.lastActivity.whoDoneIt.fullName}
              </div>
            </div>
            <div
              className="blue secondary-regular cursor-pointer"
              onClick={() => {
                openModal(ActivityLogListModal, { legalEntity });
                popoverRef.current.hide();
              }}
            >
              See Other Activities
            </div>
          </div>
        </div>
      }
      ref={popoverRef}
      {...props}
    >
      <PseudoLink>
        {formatDate(
          toDate(legalEntity.lastActivity.updatedAt),
          'MMMM DD, YYYY',
        )}
      </PseudoLink>
    </Popover>
  );
}

export default LastActivityOfLEPopover;

import { CurrencyCellRendererProps } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponentProps } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import { HeaderGroupComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { ColDef, ColGroupDef } from 'ag-grid-community';

export const PLACEHOLDER_COL_DEF: ColDef & { placeholder?: boolean } = {
  placeholder: true,
  flex: 1,
  width: 30,
  suppressMenu: true,
};

const WIDTH = 32;

export const createPlaceholderColGroupDef = (
  args: Partial<ColDef> = {},
  groupArgs: Partial<ColGroupDef> = {},
): ColGroupDef => ({
  ...groupArgs,
  headerGroupComponent: HeaderGroupComponent,
  children: [
    {
      flex: 0,
      width: args.width ?? WIDTH,
      minWidth: args.width ?? WIDTH,
      initialWidth: args.width ?? WIDTH,
      suppressMenu: true,
      resizable: false,
      headerComponentParams: {} satisfies Partial<HeaderComponentProps>,
      cellRendererParams: {
        formatterParams: {
          fallbackValue: '',
        },
      } satisfies CurrencyCellRendererProps,
      ...args,
    },
  ],
});

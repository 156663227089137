import React from 'react';
import { IconButton } from '@/stories';
import { useNavigate } from '@reach/router';
import { IAsset } from 'types/Asset';
import { getObjectLink } from '../utils';
import { capitalize } from 'lodash-es';

interface Props {
  items: Pick<IAsset, 'id' | 'name' | 'slug'>[];
  type: 'asset' | 'fund';
  title?: string;
}

const ObjectList = ({ items, title, type }: Props) => {
  const navigate = useNavigate();
  if (items.length === 0) return null;

  return (
    <div className="flex flex-col gap-tw-2">
      {title && (
        <span className="label-semibold px-tw-2 uppercase text-neutral-500">
          {title}
        </span>
      )}

      <div className="flex flex-col">
        {items.map((item) => (
          <span
            className="inline-semibold flex items-center justify-between px-tw-2 py-tw-1 text-neutral-700"
            key={item.id}
          >
            {item.name}
            <IconButton
              tooltipProps={{
                mainText: `Go To ${capitalize(type)}`,
              }}
              onClick={() =>
                navigate(
                  getObjectLink({
                    type,
                    slug: item.slug,
                  }),
                )
              }
              className="border-neutral-100 text-neutral-200"
              iconName="externalLinkSquare"
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default ObjectList;

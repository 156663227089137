import React, { ReactNode } from 'react';
import VerticalSeparator from '@/bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import {
  CurrencyFormatter,
  Dropdown,
  DropdownItem,
  IconButton,
} from '@/stories';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EditAccruedModal } from './modals/EditAccruedModal';
import { AccruedPreferredReturn } from '@/entities/return/model/types/accruedPreferredReturn';
import { formatDate } from '@/shared/lib/formatting/dates';
import dayjs from 'dayjs';
import {
  useDeleteApiCapitalInvestmentAccruedPreferredReturnsByIdMutation,
  usePutApiCapitalInvestmentAccruedPreferredReturnsByIdMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { capitalize } from 'lodash-es';

export const nextAccrualDate = (
  period: string,
  endDate: string | undefined,
) => {
  if (endDate && new Date(endDate) <= new Date()) {
    return '-';
  }
  const currentDate = dayjs();

  if (period === 'monthly') {
    const endOfMonthDate = currentDate.endOf('month');
    return formatDate(endOfMonthDate, 'MMM DD, YYYY');
  }

  if (period === 'quarterly') {
    const endOfQuarterDate = currentDate.endOf('quarter');
    return formatDate(endOfQuarterDate, 'MMM DD, YYYY');
  }
};

export const statusClass = (status: StringAndOptions<'active'>) =>
  status === 'active' ? 'text-success-070' : 'text-attention-070';

export const AccruedCards = ({
  items,
  investmentEntityName,
}: {
  items: AccruedPreferredReturn[];
  investmentEntityName: string;
}) => {
  const { openModal } = useModal();

  const openEditAccruedModal = (row: AccruedPreferredReturn) => {
    openModal(EditAccruedModal, {
      row,
      investmentEntityName,
    });
  };

  const [updateAccruedEntry] =
    usePutApiCapitalInvestmentAccruedPreferredReturnsByIdMutation();
  const { confirm } = useModal();

  const handleStop = async (item: AccruedPreferredReturn) => {
    const res = await confirm({
      title: 'Stop automatic Accrued entries',
      size: '400',
      subtitle: (
        <>
          Are you sure you want to stop the automatic Accrued entries for{' '}
          {investmentEntityName}?
          <div className="pt-m">
            Note will not be possible to re-start it later, you will have&nbsp;
            to create a new accrued
          </div>
        </>
      ),
      actions: {
        primaryButton: {
          text: 'Stop Entries',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });

    if (!res) return;

    updateAccruedEntry({
      id: item.id,
      body: {
        end_date: formatDate(new Date(), 'YYYY-MM-DD'),
      },
    });
  };

  const [deleteAccruedPreferredReturn] =
    useDeleteApiCapitalInvestmentAccruedPreferredReturnsByIdMutation();

  const handleRemove = async (item: AccruedPreferredReturn) => {
    const res = await confirm({
      title: 'Remove Accrued',
      size: '400',
      subtitle: `Are you sure you want to remove Accrued for ${investmentEntityName}?`,
      actions: {
        primaryButton: {
          text: 'Remove',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });

    if (!res) return;

    await deleteAccruedPreferredReturn({
      id: item.id,
    });
  };

  const dropdownActions = (item: AccruedPreferredReturn) => {
    const dropdownItems: ReactNode[] = [];

    dropdownItems.push(
      <DropdownItem
        key="edit"
        iconName="editAlt"
        onClick={() => openEditAccruedModal(item)}
      >
        Edit
      </DropdownItem>,
    );

    if (item.status === 'active') {
      dropdownItems.push(
        <DropdownItem
          key="edit"
          iconName="archived"
          onClick={() => handleStop(item)}
        >
          Stop Accrued
        </DropdownItem>,
      );
    }

    dropdownItems.push(
      <DropdownItem
        key="remove"
        iconName="trash"
        onClick={() => handleRemove(item)}
      >
        Remove
      </DropdownItem>,
    );

    return dropdownItems;
  };

  return items.map((item) => (
    <div
      className="flex justify-between rounded-[8px] border bg-white p-16"
      key={item.id}
    >
      <div className="flex gap-m">
        <div>
          <div className="text-xs font-medium text-neutral-500">AMOUNT</div>
          <div className="pt-2 text-sm">
            <CurrencyFormatter value={item.amount} />
          </div>
        </div>
        <VerticalSeparator className="w-tw-6" />
        <div>
          <div className="text-xs font-medium text-neutral-500">PERIOD</div>
          <div className="pt-2 text-sm">{capitalize(item.periodType)}</div>
        </div>
        <div>
          <div className="text-xs font-medium text-neutral-500">START DAY</div>
          <div className="pt-2 text-sm">
            {formatDate(item.startDate, 'MMM DD, YYYY')}
          </div>
        </div>
        <div>
          <div className="text-xs font-medium text-neutral-500">
            NEXT ACCRUAL DATE
          </div>
          <div className="pt-2 text-sm">
            {nextAccrualDate(item.periodType, item.endDate)}
          </div>
        </div>
        <div>
          <div className="text-xs font-medium text-neutral-500">STATUS</div>
          <div className={`pt-2 text-sm ${statusClass(item.status)}`}>
            {capitalize(item.status)}
          </div>
        </div>
      </div>
      <div className="self-center">
        <Dropdown
          classNameContainer="h-min"
          appendTo={document.body}
          items={dropdownActions(item)}
        >
          <IconButton variant="secondary" iconName="more" />
        </Dropdown>
      </div>
    </div>
  ));
};

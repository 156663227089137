import { TRootState } from '@/app/stores';
import { InvestmentObjectShow } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IBulkUpdateCapitalInvestmentRequest,
  ICreateCapitalInvestmentRequest,
  IUpdateCapitalInvestmentRequest,
  bulkUpdateCapitalInvestment as plainBulkUpdateCapitalInvestment,
  createCapitalInvestment as plainCreateCapitalInvestment,
  updateCapitalInvestment as plainUpdateCapitalInvestment,
} from 'bundles/REturn/actions/capitalInvestments';
import {
  fetchInvestmentObject as plainFetchInvestmentObject,
  publishLegalEntity as plainPublishLegalEntity,
} from 'bundles/REturn/actions/capitalLegalEntities';
import {
  ICreateCapitalTransactionSourceRequest,
  createCapitalTransactionSource as plainCreateCapitalTransactionSource,
} from 'bundles/REturn/actions/capitalTransactionSources';
import {
  TBulkCreateManualContributionsRequest,
  bulkCreateManualContributions as plainBulkCreateManualContributions,
} from 'bundles/REturn/actions/manualContributions';
import {
  TBulkCreateManualDistributionsRequest,
  bulkCreateManualDistributions as plainBulkCreateManualDistributions,
} from 'bundles/REturn/actions/manualDistributions';
import { TReturnInvestmentTransactionDirection } from 'bundles/REturn/types';
import { partition } from 'lodash-es';

const initialState: {
  data: InvestmentObjectShow | null;
} = {
  data: null,
};

export const createCapitalTransactionSource = createAsyncThunk(
  'returnInvestmentObject/createSource',
  async ({
    enableToastr,
    ...data
  }: ICreateCapitalTransactionSourceRequest & {
    enableToastr: boolean;
  }) => {
    const res = await plainCreateCapitalTransactionSource(data, enableToastr);
    if (!res) {
      return undefined;
    }
    return res;
  },
);

export const updateCapitalInvestment = createAsyncThunk(
  'returnInvestmentObject/updateCapitalInvestment',
  async (data: IUpdateCapitalInvestmentRequest) =>
    plainUpdateCapitalInvestment(data),
);

export const bulkUpdateCapitalInvestment = createAsyncThunk(
  'returnInvestmentObject/bulkUpdateCapitalInvestment',
  async (data: IBulkUpdateCapitalInvestmentRequest) => {
    const res = await plainBulkUpdateCapitalInvestment(data);
    if (!res) {
      return undefined;
    }
    // todo replace with response from BE
    return {
      data,
    };
  },
);

export const createCapitalInvestment = createAsyncThunk(
  'returnInvestmentObject/createCapitalInvestment',
  async ({
    investmentObjectId,
    ...data
  }: ICreateCapitalInvestmentRequest & { investmentObjectId: string }) => {
    const res = await plainCreateCapitalInvestment(data);
    if (!res) {
      return undefined;
    }
    const investmentEntity = await plainFetchInvestmentObject(
      investmentObjectId,
      {},
    );
    return investmentEntity.capitalInvestments;
  },
);
export const bulkCreateManualContributions = createAsyncThunk(
  'returnInvestmentObject/bulkCreateManualContributions',
  async ({
    investmentObjectId,
    items,
  }: {
    items: TBulkCreateManualContributionsRequest;
    investmentObjectId: string;
  }) => {
    const res = await plainBulkCreateManualContributions(items);
    if (!res) {
      return undefined;
    }
    const investmentEntity = await plainFetchInvestmentObject(
      investmentObjectId,
      {},
    );
    return {
      createdEntries: res.items,
      capitalInvestments: investmentEntity.capitalInvestments,
    };
  },
);

export const bulkCreateManualDistributions = createAsyncThunk(
  'returnInvestmentObject/bulkCreateManualDistributions',
  async ({
    investmentObjectId,
    items,
  }: {
    items: TBulkCreateManualDistributionsRequest;
    investmentObjectId: string;
  }) => {
    const res = await plainBulkCreateManualDistributions(items);
    if (!res) {
      return undefined;
    }
    const investmentEntity = await plainFetchInvestmentObject(
      investmentObjectId,
      {},
    );
    return {
      createdEntries: res.items,
      capitalInvestments: investmentEntity.capitalInvestments,
    };
  },
);

export const toggleLegalEntityPublished = createAsyncThunk(
  'returnInvestmentObject/publishLegalEntity',
  async (id: string) => {
    const legalEntity = await plainPublishLegalEntity(id);
    return legalEntity;
  },
);

const returnInvestmentObjectSlice = createSlice({
  name: 'returnInvestmentObject',
  initialState,
  reducers: {
    resetInvestmentObject: (state) => {
      state.data = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createCapitalTransactionSource.fulfilled, (state, action) => {
        if (!state.data || action.payload == null) {
          return;
        }
        state.data.capitalTransactionSources = action.payload;
      })
      .addCase(updateCapitalInvestment.fulfilled, (state, action) => {
        if (!state.data || action.payload == null) {
          return;
        }
        const newCapitalInvestment = action.payload;
        const index = state.data.capitalInvestments.findIndex(
          (ci) => ci.id === newCapitalInvestment.id,
        );

        state.data.capitalInvestments[index] = newCapitalInvestment;
      })
      .addCase(bulkUpdateCapitalInvestment.fulfilled, (state, action) => {
        if (!state.data || action.payload == null) {
          return;
        }
        const { data } = action.payload;
        data.investment.data.forEach((ci) => {
          const index = state.data.capitalInvestments.findIndex(
            (cix) => cix.id === ci.id,
          );
          state.data.capitalInvestments[index].commitmentAmount = Number(
            ci.commitment_amount,
          );
        });
      })
      .addCase(toggleLegalEntityPublished.fulfilled, (state, action) => {
        if (!state.data) {
          return;
        }
        const updated = action.payload;
        const index = state.data.legalEntities.findIndex(
          (le) => le.id === updated.id,
        );

        state.data.legalEntities[index].isPublished = updated.isPublished;
      });
  },
});

export const selectCapitalInvestment = (state: TRootState) =>
  state.returnInvestmentObject.data!;

export const selectReturnInvestmentObject = (state: TRootState) =>
  state.returnInvestmentObject.data!.object;
export const selectReturnInvestmentLegalEntities = (state: TRootState) =>
  state.returnInvestmentObject.data!.legalEntities;

export const selectReturnInvestmentSources = (state: TRootState) =>
  partition(
    state.returnInvestmentObject.data!.capitalTransactionSources,
    (source) =>
      source.kind === TReturnInvestmentTransactionDirection.CONTRIBUTION,
  );

export const { resetInvestmentObject } = returnInvestmentObjectSlice.actions;
export default returnInvestmentObjectSlice.reducer;

import React from 'react';
import styles from 'bundles/REturn/components/Ownership/modals/bulkAddModal.module.scss';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  total: number;
  currentTotal: number;
  className?: string;
}

function ProgressDivider({ total, currentTotal, className }: Props) {
  return (
    <div className="w-full">
      <div
        className={cn(styles.divider, className, {
          [styles.dividerStarted]: total && currentTotal > 0,
          [styles.dividerSuccess]:
            currentTotal > 0 && total > 0 && currentTotal === total,
          [styles.dividerError]: total && currentTotal > Number(total),
        })}
        style={{
          maxWidth: '100%',
          width: currentTotal
            ? `calc(${(currentTotal / Number(total)) * 100}%)`
            : '100%',
          position: 'relative',
        }}
      />
    </div>
  );
}

export default ProgressDivider;

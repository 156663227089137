import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import IconButton from 'stories/IconButton/IconButton';
import { Link } from '@reach/router';
import './breadcrumbs.scss';

interface CrumbSeparatorProps {
  className?: string;
  isVertical?: boolean;
}

export const CrumbSeparator = ({
  className,
  isVertical = false,
}: CrumbSeparatorProps) => (
  <div
    className={cn('breadcrumbs__separator', className, {
      'breadcrumbs__separator-vertical': isVertical,
    })}
  />
);

interface Breadcrumb {
  label: React.ReactNode;
  to: string;
  isCurrent?: boolean;
}

interface CrumbLinkProps {
  children: React.ReactNode;
  to: string;
  isCurrentLocation?: boolean;
  onClick?: () => void;
  className: string;
}

const CrumbLink = ({
  children,
  to,
  isCurrentLocation,
  onClick,
  className,
}: CrumbLinkProps) => (
  <Link
    to={to || './'}
    className={cn(className, 'breadcrumbs__link', {
      'light-90 cursor-default': isCurrentLocation,
      blue: !isCurrentLocation,
    })}
    onClick={onClick}
  >
    {children}
  </Link>
);

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  onBackClick: () => void;
  onClick?: (path: string) => void;
  size?: 'small' | 'medium';
  backgroundColor?: 'light-15' | 'white' | 'transparent';
  className?: string;
}

export const Breadcrumbs = ({
  breadcrumbs,
  onBackClick,
  onClick,
  size = 'medium',
  backgroundColor = 'light-15',
  className,
}: BreadcrumbsProps) => (
  <div
    className={cn(
      `breadcrumbs__container breadcrumbs_${backgroundColor} break-all`,
      className,
    )}
  >
    <IconButton
      iconName="arrowLeft"
      onClick={onBackClick}
      className="breadcrumbs__back-button"
    />
    <CrumbSeparator isVertical />
    {breadcrumbs.map((crumb, i) => (
      <React.Fragment key={i}>
        <CrumbLink
          key={crumb.to}
          to={crumb.to}
          isCurrentLocation={crumb.isCurrent}
          onClick={() => onClick?.(crumb.to)}
          className={
            size === 'small' ? 'secondary-semibold' : 'inline-semibold'
          }
        >
          {crumb.label}
        </CrumbLink>
        {!crumb.isCurrent && <CrumbSeparator />}
      </React.Fragment>
    ))}
  </div>
);

export default Breadcrumbs;

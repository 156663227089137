import React, { ComponentProps } from 'react';
import CommentsList from 'bundles/REconcile/components/comments/CommentsList/CommentsList';
import CommentSection from 'bundles/REconcile/components/comments/CommentSection/CommentSection';
import CommentsPanel from 'bundles/REconcile/components/comments/CommentsPanel/CommentsPanel';
import { Button } from 'stories';
import CommentEditor from 'bundles/REconcile/components/comments/CommentEditor/CommentEditor';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  openReplies,
  replyDevelopmentBudgetVarianceComment,
  selectOpenedComment,
} from 'bundles/REconcile/reducers/developmentBudgetVarianceCommentSlice';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import { ICommentAttachment } from 'bundles/REconcile/actions/developmentBudgetVarianceComments';

import { convertToMilliseconds } from '@/shared/lib/converters';

interface Props {
  parentCommentId: string;
}

function BudgetCommentsRepliesPanel({ parentCommentId }: Props) {
  const dispatch = useAppDispatch();
  const openedComment = useAppSelector(selectOpenedComment);
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const mentionableUsers = useAppSelector(
    (state) => state.developmentBudgetVariance.comments.mentionableUsers,
  );
  const comments = useAppSelector(
    (state) =>
      state.developmentBudgetVariance.comments.items.find(
        (c) => c.id === parentCommentId,
      )?.replies ?? [],
  );

  const handleSubmit: ComponentProps<
    typeof CommentEditor
  >['onSubmit'] = async ({ text, files }) => {
    let attachments: ICommentAttachment[] = [];
    if (files.length > 0) {
      const presignedFileds = await presignFiles(files);
      await Promise.all(presignedFileds.map((f) => directUpload(f)));
      attachments = presignedFileds.map((f) => ({
        key: f.signedData.fields.key,
        filename: f.file.name,
        content_type: f.file.type,
        size: f.file.size,
      }));
    }

    dispatch(
      replyDevelopmentBudgetVarianceComment({
        legalEntityCode: legalEntity.code,
        commentId: parentCommentId,
        message: text,
        attachments,
      }),
    );
  };

  const onBack = () => {
    dispatch(openReplies(false));
  };

  return (
    <CommentsPanel
      header={
        <>
          <Button size="s" variant="secondary" onClick={onBack}>
            Back
          </Button>
          <div className="flex flex-col items-center">
            <span className="dark-60 inline-semibold">Replies</span>
            <span className="inline-regular">{openedComment.categoryName}</span>
          </div>
          <div className="w-[1rem]" />
        </>
      }
      comments={
        <CommentsList>
          {comments.map((c) => (
            <CommentSection
              key={c.id}
              createdAt={convertToMilliseconds(c.createdAt)}
              message={c.message}
              fullName={c.author.fullName}
              attachments={c.attachments ?? []}
              mentionedUsers={c.mentionedUsers}
            />
          ))}
        </CommentsList>
      }
      editor={
        <CommentEditor
          resetOnSubmit
          onSubmit={handleSubmit}
          variant="light"
          mentionableUsers={mentionableUsers}
        />
      }
    />
  );
}

export default BudgetCommentsRepliesPanel;

import React from 'react';
import USER_STATUS_LABELS from 'bundles/UserManagement/components/helpers/userStatusLabels';
import { TUserInviteStatus } from 'types/User';
import getStatusColor from 'bundles/UserManagement/components/helpers/getStatusColor';
import QuickFilterColorDropdown from 'bundles/Shared/components/Table/filters/QuickFilterColorDropdown';
import QuickFilterFireButton, {
  TQuickFilterFireButtonProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterFireButton';
import { IFilterComponentProps } from 'bundles/Shared/components/Table/types';

function StatusFilter({
  count,
  ...filterProps
}: IFilterComponentProps & TQuickFilterFireButtonProps) {
  return (
    <>
      <QuickFilterColorDropdown
        {...filterProps}
        setFilterModel={(filterModel) =>
          filterProps.setFilterModel({
            ...filterModel,
            [`${filterProps.column.dataField}_fire`]: undefined,
          })
        }
        options={Object.keys(USER_STATUS_LABELS).map(
          (k: TUserInviteStatus | 'all') => ({
            key: k,
            color: k === 'all' ? '' : getStatusColor(k),
            label: USER_STATUS_LABELS[k],
          }),
        )}
      />
      <QuickFilterFireButton
        {...filterProps}
        count={count}
        setFilterModel={(filterModel) =>
          filterProps.setFilterModel({
            ...filterModel,
            [filterProps.column.dataField]: undefined,
          })
        }
      />
    </>
  );
}

export default StatusFilter;

import React, { useEffect, useState } from 'react';
import { ICellRendererParams, RowGroupOpenedEvent } from 'ag-grid-community';
import { cn } from '@/shared/lib/css/cn';
import { getConnectorType } from 'lib/connectors';
import styles from './GroupWithConnector.module.scss';

type Props = ICellRendererParams & { children?: React.ReactNode };

export const BASE_PADDING = 24;
const PATH_PADDING = 20;
const TRIANGLE_WIDTH = 2;
const TRIANGLE_MARGIN = 8;
export const DEFAULT_CONNECTOR_GROUP_CELL_STYLE = {
  borderTop: 0,
  borderBottom: 0,
  paddingLeft: BASE_PADDING,
};

function GroupWithConnector({ node, valueFormatted, children, api }: Props) {
  const [isExpanded, setIsExpanded] = useState(node.expanded);

  const connectorsCount =
    node.level +
    1 +
    (node.footer || !node.hasChildren() || !isExpanded ? -1 : 0);
  const isLeaf = node.level > 0 && !node.hasChildren();

  useEffect(() => {
    const handleExpandEvent = (e: RowGroupOpenedEvent) => {
      if (e.node.id === node.id && !node.footer) {
        setIsExpanded(node.expanded);
      }
    };

    api.addEventListener('rowGroupOpened', handleExpandEvent);

    return () => {
      api.removeEventListener('rowGroupOpened', handleExpandEvent);
    };
  }, []);

  const getConnectorStyle = (i: number) => {
    const connectorHeightNearArrow = 8;
    const isConnectorExpander = i === node.level;

    let height = 0;

    if (isConnectorExpander) {
      height = connectorHeightNearArrow;
    } else if (
      i + 1 === node.level &&
      node.lastChild &&
      (node.footer || !isExpanded)
    ) {
      height = node.rowHeight / 2 - 5;
    } else {
      height = '100%';
    }

    return {
      height,
      left: BASE_PADDING + i * PATH_PADDING + TRIANGLE_WIDTH,
      top: isConnectorExpander ? 'auto' : 0,
    };
  };

  const getPadding = () => {
    const addLevelIfInternalFooter = node.footer ? 1 : 0;
    const triangleOffset = -TRIANGLE_MARGIN + TRIANGLE_WIDTH;
    const alignByParentLevel = isLeaf || node.footer ? triangleOffset : 0;
    return (
      (node.level + addLevelIfInternalFooter) * PATH_PADDING +
      alignByParentLevel
    );
  };

  return (
    <div
      className="flex items-center"
      style={{
        paddingLeft: getPadding(),
      }}
    >
      {node.hasChildren() && !node.footer && (
        <span className={cn('mr-s', styles.arrow)} />
      )}
      {(node.hasChildren() || node.level > 0) && (
        <>
          {Array.from({ length: connectorsCount }, (_, i) => (
            <>
              <span
                key={`connector_${i}`}
                style={getConnectorStyle(i)}
                className={styles.connector}
              />
              {['|_', '|-'].includes(
                getConnectorType({
                  startDepth: 0,
                  index: i,
                  depth: node.level,
                  lastChild: node.lastChild,
                }),
              ) && (
                <span
                  style={{
                    left:
                      (node.level - 1) * PATH_PADDING +
                      BASE_PADDING +
                      TRIANGLE_WIDTH,
                    top: 2,
                  }}
                  className={styles.connector_horizontal}
                />
              )}
            </>
          ))}
        </>
      )}
      {valueFormatted}
      {children}
    </div>
  );
}

export default GroupWithConnector;

import { IUser } from 'types/User';
import { FETCH_USERS_FOR_RECONCILE_SETTINGS } from '../actions';

const initialState: IUser[] | null = null;

export default function (
  state = initialState,
  {
    type,
    payload,
  }: {
    type: typeof FETCH_USERS_FOR_RECONCILE_SETTINGS;
    payload: IUser[];
  },
) {
  switch (type) {
    case FETCH_USERS_FOR_RECONCILE_SETTINGS:
      return payload;

    default:
      return state;
  }
}

import {
  useGetApiSettingsSreColorsQuery,
  usePostApiSettingsSreColorsBulkMutation,
} from '@/entities/sreColors/model/sreColorsEnhancedApi';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ColorPickerList } from '@/shared/widgets/colorPicker/widget';
import { sreColorsTransformer } from '@/shared/widgets/colorPicker/lib';
import { IAssetStage } from '@/types/AssetStage';
import { arrayMoveImmutable } from 'array-move';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button, Input, LinkButton, Modal } from 'stories';
import {
  createAssetStage,
  deleteAssetStage,
  toggleAssetStage,
  updateAssetStage,
  updatePositionsAssetStage,
} from '../../actions/assetStage';
import CardWithToggle from '../DealPath/CardWithToggle';
import { ColorPickerWidget } from '@/widgets/core/sreColors/settings/ui/ColorPickerWidget';

const AssetStages = ({ assetStages }: { assetStages: IAssetStage[] }) => {
  const dispatch = useAppDispatch();
  const [selectedStage, setSelectedStage] = useState<IAssetStage>();
  const assetStageColor = selectedStage?.color;

  const { confirm } = useModal();

  const handleChangeColor = (color: React.CSSProperties['color']) => {
    setSelectedStage((prev) => ({ ...prev!, color }));
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (selectedStage?.id) {
      await dispatch(updateAssetStage(selectedStage.id, selectedStage));
    } else {
      await dispatch(createAssetStage(selectedStage));
    }
    setSelectedStage(undefined);
  };

  const handleDelete = async (id: IAssetStage['id']) => {
    const result = await confirm({
      title: 'Remove asset stage',
      subtitle: 'Are you sure you want to remove asset stage?',
    });
    if (result) dispatch(deleteAssetStage(id));
  };

  const [items, setItems] = useState<{ id: string }[]>([]);

  useEffect(() => {
    setItems(assetStages);
  }, [assetStages]);

  const SortableItem = SortableElement(({ value }) => (
    <CardWithToggle
      key={value.id}
      item={value}
      onToggle={() => dispatch(toggleAssetStage(value.id))}
      onEdit={() => setSelectedStage(value)}
      onDelete={() => handleDelete(value.id)}
      footer={() =>
        value.color && (
          <div className="secondary-regular">
            <span className="dark-60 mr-1">Color:</span>
            <span style={{ color: value.color }}>{value.color}</span>
          </div>
        )
      }
      disabled={!value.toggle}
      tumblerText="Asset Stage is currently in use and cannot be disabled"
      trashText="Asset Stage is currently in use and cannot be deleted"
      dragHandle
    />
  ));

  const SortableList = SortableContainer(
    ({ items }: { items: { id: string }[] }) => (
      <div className="flex flex-wrap">
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
      </div>
    ),
  );

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
    dispatch(
      updatePositionsAssetStage(items[oldIndex].id, {
        new_position: newIndex + 1,
      }),
    );
  };

  return (
    <div>
      <CardWrapper className="mt-50 bg-light">
        <div className="px-tw-5 py-tw-3">
          <div className="flex justify-between">
            <h3>Asset Stages</h3>
            <LinkButton onClick={() => setSelectedStage('new')} uppercase>
              Add New Asset Stage
            </LinkButton>
          </div>
        </div>
        <div className="px-tw-5 py-tw-5">
          <SortableList
            axis="xy"
            items={items}
            onSortEnd={onSortEnd}
            useDragHandle
          />
        </div>
      </CardWrapper>
      {selectedStage && (
        <Modal
          header={selectedStage?.id ? 'Edit Stage' : 'Create Stage'}
          toggle={() => setSelectedStage(null)}
          actions={
            <Button
              disabled={!assetStageColor || !selectedStage.name}
              variant="success"
              fluid
              type="submit"
              form="assetStageForm"
            >
              {selectedStage?.id ? 'Submit' : 'Create'}
            </Button>
          }
          classes={{
            body: 'bg-neutral-100',
          }}
        >
          <form id="assetStageForm" onSubmit={handleSubmit}>
            <div className="from-group mb-tw-2">
              <label htmlFor="name">Name</label>
              <Input
                defaultValue={selectedStage.name}
                id="name"
                onChange={(e) =>
                  setSelectedStage({
                    ...selectedStage,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="from-group mb-tw-2">
              <label htmlFor="color">Color</label>
              <ColorPickerWidget
                onChange={handleChangeColor}
                value={selectedStage.color}
              />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default AssetStages;

import { useModal } from '@/shared/lib/hooks/useModal';
import React, { useState } from 'react';
import { IconButton } from 'stories';
import { IReportTableSegment } from 'types/ReportTableSegment';
import { IReportTableSegmentActions } from '../reportTableSegmentColumns';
import SegmentFormModal from '../SegmentFormModal';

interface Props {
  segment: IReportTableSegment;
  actions: IReportTableSegmentActions;
}

const ActionsCell = ({ segment, actions }: Props) => {
  const [isSegmentModalOpen, setIsSegmentModalOpen] = useState(false);

  const { confirm } = useModal();

  const handleDelete = async () => {
    const result = await confirm({
      title: 'Remove Segment',
      subtitle: 'Are you sure you want to remove segment?',
      actions: {
        primaryButton: {
          text: 'Remove',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });
    if (result) {
      actions.destroyReportTableSegment(segment.id);
    }
  };

  const handleEdit = () => {
    setIsSegmentModalOpen(true);
  };

  return (
    <div className="flex">
      <div className="mr-s">
        <IconButton iconName="edit" onClick={handleEdit} />
      </div>
      <div>
        <IconButton iconName="trash" onClick={handleDelete} />
      </div>

      {isSegmentModalOpen && (
        <SegmentFormModal
          segment={segment}
          onClose={() => {
            setIsSegmentModalOpen(false);
          }}
          onSubmit={(payload) => {
            setIsSegmentModalOpen(false);
            actions.editReportTableSegment(payload);
          }}
        />
      )}
    </div>
  );
};

export default ActionsCell;

import React from 'react';
import { IFilterComponentProps } from 'bundles/Shared/components/Table/types';
import ColorOptionsDropdown, {
  ColorOptionsDropdownProps,
} from 'bundles/Shared/components/Filters/common/dropdown/ColorOptionsDropdown';

interface Props
  extends IFilterComponentProps,
    Omit<ColorOptionsDropdownProps<string>, 'selected' | 'onSelectedChange'> {}

export type TQuickFilterColorDropdownProps = Omit<
  Props,
  keyof IFilterComponentProps
>;

function QuickFilterColorDropdown({
  filterModel,
  setFilterModel,
  column,
  options,
  ...props
}: Props) {
  return (
    <div className="ml-s">
      <ColorOptionsDropdown
        options={options}
        selected={filterModel[column.dataField] ?? 'all'}
        onSelectedChange={(option) =>
          setFilterModel({
            ...filterModel,
            [column.dataField]: option === 'all' ? undefined : option,
          })
        }
        {...props}
      />
    </div>
  );
}

export default QuickFilterColorDropdown;

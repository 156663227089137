import React from 'react';
import { Button, Field, Icon, Input, Modal, Tumbler } from '@/stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { FieldsContainer } from 'stories/Field/Field';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  getColumnGroupColors,
  KPI_COLUMN_GROUP_ICONS,
  KPI_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA,
  KpiTableWidgetColumnGroupForm,
  transformColGroupToGroupForm,
  upsertColumnGroup,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { capitalize } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import { TableVizConfigColumnGroup } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { ColorField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';

type Props = DialogProps<KpiTableWidgetColumnGroupForm> & {
  type: 'create' | 'edit';
  widgetType:
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE;
  group?: TableVizConfigColumnGroup;
};

export function KpiTableWidgetConfigColumnGroup({
  onClose,
  group,
  widgetType,
  ...props
}: Props) {
  const { widget } = useWidgetConfig<typeof widgetType>();
  const [updateConfig, { isLoading: isSubmitting }] =
    useUpdateWidgetConfig(widgetType);

  const methods = useForm<KpiTableWidgetColumnGroupForm>({
    resolver: yupResolver(KPI_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA),
    defaultValues: {
      color: getColumnGroupColors()[0].value,
      ...(group && transformColGroupToGroupForm(group)),
    },
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    updateConfig({
      config: upsertColumnGroup(data, widget.widgetConfig),
    });
    onClose();
  });

  return (
    <Modal
      header={`${capitalize(props.type)} Group`}
      toggle={onClose}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isValid || isSubmitting || !isDirty}
            variant="success"
            onClick={onSubmit}
          >
            Save Updates
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field required labelText="Group Label">
            <Input {...register('label')} placeholder="Group Label" />
          </Field>

          <SectionFieldsContainer>
            <SectionField
              button={
                <Button
                  onClick={() => {
                    methods.reset();
                  }}
                  variant="secondary"
                  size="xs"
                >
                  Reset All
                </Button>
              }
              labelText="Appearance"
            />
            <ColorField
              note="You can customize the color of the headers for the table"
              name="color"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <SectionField
                  labelText="Icon"
                  button={
                    <Tumbler
                      onChange={(e) => {
                        e.target.checked
                          ? field.onChange(KPI_COLUMN_GROUP_ICONS[0])
                          : field.onChange(null);
                      }}
                      checked={field.value != null}
                    />
                  }
                >
                  {field.value != null && (
                    <div className="grid grid-cols-12 gap-tw-0.5 !rounded-lg border border-solid border-neutral-100 p-tw-2">
                      {KPI_COLUMN_GROUP_ICONS.map((icon) => (
                        <Icon
                          className={cn(
                            'cursor-pointer border-2 border-solid border-transparent p-tw-2 text-[16px] hover:text-neutral-850',
                            field.value === icon && 'border-info-055',
                          )}
                          onClick={() => field.onChange(icon)}
                          key={icon}
                          iconName={icon}
                        />
                      ))}
                    </div>
                  )}
                </SectionField>
              )}
              name="icon"
              control={control}
            />
          </SectionFieldsContainer>
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
}

import { useMemo } from 'react';

export default function useAlphabetGroups<T = unknown>(
  items: T[],
  fieldGetter: (item: T) => string,
  comparator: (a: T, b: T) => number,
): Record<string, T[]> {
  return useMemo(() => {
    const groups: Record<string, T[]> = {};
    const copy = [...items];

    for (const item of copy.sort(comparator)) {
      const firstLetter = fieldGetter(item).charAt(0);
      if (groups[firstLetter] === undefined) {
        groups[firstLetter] = [];
      }
      groups[firstLetter].push(item);
    }
    return groups;
  }, [items]);
}

import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const getObjectLink = ({
  type,
  slug,
}: {
  type: 'asset' | 'fund';
  slug: string;
}) => {
  const path =
    type === 'asset'
      ? ROUTES_ROOT.assets.asset.fullPath
      : ROUTES_ROOT.funds.fund.fullPath;
  return generateUrl(path, {
    pathParams: {
      assetSlug: slug,
      fundSlug: slug,
    },
  });
};

import React from 'react';
import { Tumbler } from 'stories';
import {
  IUpdateSnapshotSettingsRequest,
  useGetSnapshotSettingsQuery,
  useUpdateSnapshotSettingsMutation,
} from 'bundles/REconcile/components/development/api/snapthotSettings';
import { camelKeysToSnake } from 'bundles/UserManagement/components/roles/utils';

interface Props {
  legalEntityId: string;
}

const SettingsCard = ({
  title,
  status,
  onChange,
  value,
  disabled,
}: {
  value: boolean;
  title: string;
  status: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}) => (
  <div className="flex w-[340px] flex-col gap-tw-2 bg-white p-tw-4">
    <div className="flex justify-between ">
      <label htmlFor={status} className={'inline-semibold text-light-60'}>
        {title}
      </label>
      <Tumbler
        disabled={disabled}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        id={status}
      />
    </div>

    <p className="secondary-regular text-light-90">
      {/* eslint-disable-next-line max-len */}
      When enabled, the Project's Snapshot will include Change Orders and Change
      Order Requests in{' '}
      <span className="secondary-semibold">{status} Statuses</span>.
    </p>
  </div>
);

function SnapshotSettings({ legalEntityId }: Props) {
  const { data, isLoading } = useGetSnapshotSettingsQuery({ legalEntityId });
  const [updateSnapshotSettings] = useUpdateSnapshotSettingsMutation();

  const updateSettings = (
    key: keyof IUpdateSnapshotSettingsRequest,
    value: boolean,
  ) => {
    updateSnapshotSettings({
      ...camelKeysToSnake(data!),
      legalEntityId,
      [key]: value,
    });
  };

  return (
    <div className="flex gap self-start overflow-hidden rounded-2xl">
      <SettingsCard
        title="Display Projected"
        status="Pending"
        value={data?.displayProjected ?? false}
        onChange={(value) => updateSettings('display_projected', value)}
        disabled={isLoading}
      />
      <SettingsCard
        title="Display Internal"
        status="Draft"
        value={data?.displayInternal ?? false}
        onChange={(value) => updateSettings('display_internal', value)}
        disabled={isLoading}
      />
    </div>
  );
}

export default SnapshotSettings;

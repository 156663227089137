import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from 'types/Props';

export const ActionsContainer = ({
  className,
  children,
}: React.PropsWithChildren<ClassNameProps>) => (
  <div
    className={cn(
      'flex flex-col gap-tw-1 rounded-lg bg-white py-tw-1',
      className,
    )}
  >
    {children}
  </div>
);

import React from 'react';
import { Control, Controller, Path, useWatch } from 'react-hook-form';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { startCase } from 'lodash-es';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import InputNumber from 'bundles/Shared/components/GroupForm/FormItems/InputNumber';
import {
  PERIOD_SHIFT_OPTIONS,
  PeriodShiftForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';

export function PeriodShiftField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  hideKey,
  disabled,
}: {
  control: Control<TFieldValues>;
  name: TName;
  hideKey?: boolean;
  disabled?: boolean;
}) {
  const getFieldName = (key: keyof PeriodShiftForm) =>
    `${name}.${key}` as Path<TFieldValues>;
  const key = useWatch({
    control,
    name: getFieldName('key'),
  });
  const options = PERIOD_SHIFT_OPTIONS.map((o) => ({
    label: startCase(o),
    value: o,
  }));

  return (
    <div className="flex items-center gap-tw-2">
      {!hideKey && (
        <Controller
          control={control}
          name={getFieldName('key')}
          render={({ field }) => (
            <SharedSelect
              isMulti={false}
              value={options.find((o) => o.value === field.value)}
              onChange={(o) => field.onChange(o?.value)}
              options={options}
              menuPortalTarget={document.body}
              disabled={disabled}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name={getFieldName('value')}
        render={({ field }) => (
          <InputNumber
            onBlur={() => {
              if (field.value === '') field.onChange(0);
            }}
            disabled={(!hideKey && key == null) || disabled}
            size="m"
            value={field.value}
            onValueChange={(e) => field.onChange(e.floatValue ?? null)}
          />
        )}
      />
    </div>
  );
}

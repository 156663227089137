import { useManualVariableSettingsModal } from '@/entities/report/manualVariable/lib/useManualVariableSettingsModal';
import { ReportManualVariable } from '@/entities/report/manualVariable/model/types';
import { editReportManualVariable } from '@/features/report/manualVariable/editVariable/lib';
import { IconButton } from '@/stories';

export const EditManualVariable = ({
  manualVariable,
}: {
  manualVariable: ReportManualVariable;
}) => {
  const openManVarModal = useManualVariableSettingsModal();
  const [editManVar, options] = editReportManualVariable();

  const handleEditManVar = async () => {
    const res = await openManVarModal({
      defaultValues: {
        ...manualVariable,
        reference: manualVariable.name,
        name: manualVariable.label,
      },
    });

    if (res == null) return;

    editManVar({ id: manualVariable.id, ...res });
  };

  return (
    <IconButton
      isLoading={options.isLoading}
      onClick={handleEditManVar}
      size="m"
      iconName="edit"
    />
  );
};

import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { PDFLightMediumTitle } from 'bundles/Shared/components/PDFLibrary/ui/LightSmallTitle';
import {
  DEFAULT_VAR_PDF,
  uiPDFStyles,
} from 'bundles/Shared/components/PDFLibrary/ui/uiPDFStyles';
import React, { FC } from 'react';

interface IHeaderTFProps {}

const HeaderTF: FC<IHeaderTFProps> = ({}) => {
  const styles = StyleSheet.create({
    header: {
      flexDirection: 'row',
      width: '100%',
    },
    bigHeightCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '20%',
      borderTopWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
    },
    mediumHeightCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '12%',
      borderTopWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
    },
    smallHeightCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      borderTopWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flex: 1,
    },
    leftCol: {
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      width: '60%',
    },
    rightCol: {
      width: '40%',
      backgroundColor: DEFAULT_VAR_PDF.backgroundHeader,
      textAlign: 'center',
      alignItems: 'center',
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderColor,
      borderStyle: 'solid',
      paddingVertical: 3,
    },
  });
  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <View style={styles.header}>
        <View style={styles.leftCol} />
        <View style={styles.rightCol}>
          <PDFLightMediumTitle>SubCategory</PDFLightMediumTitle>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Reallocation: #</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Status</Text>
        </View>
        <View style={styles.mediumHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Requisition</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Description</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Reallocation</Text>
          <Text style={uiPDFStyles.lightSmallTitle}>Approved Date</Text>
        </View>
        <View style={styles.smallHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>Value</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>MOVE FROM:</Text>
        </View>
        <View style={styles.bigHeightCol}>
          <Text style={uiPDFStyles.lightSmallTitle}>MOVE TO:</Text>
        </View>
      </View>
    </View>
  );
};

export default HeaderTF;

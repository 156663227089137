import { fetchReportTableConfigs } from 'bundles/Settings/actions/report/tableConfigs';
import { useEffect, useState } from 'react';
import { IReportTableConfig } from 'types/ReportTableConfig';

export function useReportTableConfigs() {
  const [loading, setLoading] = useState(true);
  const [reportTableConfigs, setReportTableConfigs] = useState<
    IReportTableConfig[]
  >([]);

  const enableLoader = () => setLoading(true);
  const disableLoader = () => setLoading(false);

  const loadTableConfigs = async () => {
    const result = await fetchReportTableConfigs();
    setReportTableConfigs(result.items);
    disableLoader();
  };

  useEffect(() => {
    loadTableConfigs();
  }, []);

  return {
    loading,
    reportTableConfigs,
    enableLoader,
    disableLoader,
    loadTableConfigs,
  };
}

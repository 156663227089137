import { ComparisonDashboardDto } from '@/bundles/Shared/entities/dashboard';
import { yesterdayDate } from '@/shared/lib/date';
import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';
import { isEqual } from 'lodash-es';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export const useFavoriteComparisonDashboardAssets = () => {
  const [favouriteItems, setFavouriteItems] = useLocalStorageValue(
    'reportComparisonDashboard',
    [],
  );

  const toggleItemFavorite = (favItem: {
    dashboardId: string;
    assetId: number;
  }) => {
    setFavouriteItems(
      favouriteItems.some((f) => isEqual(f, favItem))
        ? favouriteItems.filter((itemX) => !isEqual(itemX, favItem))
        : [...favouriteItems, favItem],
    );
  };

  const isItemFavorite = (favItem: { dashboardId: string; assetId: number }) =>
    favouriteItems.some((f) => isEqual(f, favItem));

  return {
    toggleItemFavorite,
    isItemFavorite,
    favouriteItems,
  };
};

export const getInitialFinancialWidgetSingleDateComparisonDashboardState = <
  WT extends
    ComparisonDashboardDto['boards'][number]['sections'][number]['widgetType'],
>(
  widgetType: WT,
) => {
  switch (widgetType) {
    case 'kpi_table_single_date': {
      return {
        date: formatToDateStringForRequest(new Date()),
        groupingType: 'assets',
      };
    }
    case 'kpi_table': {
      return {
        dateFrom: formatToDateStringForRequest(yesterdayDate()),
        dateTo: formatToDateStringForRequest(new Date()),
        groupingType: 'assets',
        periodType: 'week',
      };
    }
    case 'xy_chart_single_kpi': {
      return {
        dateFrom: formatToDateStringForRequest(yesterdayDate()),
        dateTo: formatToDateStringForRequest(new Date()),
        granularity: 'day',
        groupingType: 'assets',
      };
    }
    case 'financial_table_single_date': {
      return {
        date: formatToDateStringForRequest(new Date()),
        groupingType: 'assets',
      };
    }
    default: {
      return {
        groupingType: 'assets',
      };
    }
  }
};

import { AnimationLoader, LinkButton } from 'stories';
import pluralize from 'pluralize';
import TagModal from '../TagModal';
import { IUserTag } from 'types/IUserTag';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  useCreateTagOptionMutation,
  useGetTagOptionsQuery,
} from 'bundles/UserManagement/api/settingsCoreUsersApi';

const SelectUserTag = ({
  fields,
  setValue,
}: {
  fields: {
    userTags: { id: IUserTag['id']; label: string }[];
  };
  setValue: (
    name: string,
    value: { id: IUserTag['id']; label: string }[],
  ) => void;
}) => {
  const { openModal } = useModal();

  const { data: tagOptionsData, isFetching } = useGetTagOptionsQuery();
  const [createTagOption] = useCreateTagOptionMutation();

  const handleCreateTag = async () => {
    const data = await openModal(TagModal, {});
    if (!data) return;

    createTagOption(data);
  };

  if (isFetching) return <AnimationLoader className="static min-h-[120px]" />;

  if (!tagOptionsData) return null;

  const { items: tagOptions } = tagOptionsData;

  return (
    <div className="flex flex-col gap-tw-2">
      <div className="flex items-center justify-between gap-tw-4">
        <div className="custom-control checkbox p-0">
          <input
            id="allTags"
            type="checkbox"
            className="custom-checkbox"
            checked={fields.userTags.length === tagOptions.length}
            onChange={(e) =>
              setValue(
                'userTags',
                e.target.checked
                  ? tagOptions.map((tag) => ({ id: tag.id, label: tag.name }))
                  : [],
              )
            }
          />
          <label
            htmlFor="allTags"
            className="inline-regular inline-flex items-center gap-tw-1"
          >
            {tagOptions.length} {pluralize('Tag', tagOptions.length)}
          </label>
        </div>
        <LinkButton className="pr-tw-4" onClick={handleCreateTag}>
          Add New Tag
        </LinkButton>
      </div>
      {tagOptions.map((userTag) => (
        <label key={userTag.id} className="flex items-center gap-tw-2">
          <input
            type="checkbox"
            id={`parentId${userTag.id}`}
            className="custom-checkbox"
            checked={fields.userTags.map(({ id }) => id).includes(userTag.id)}
            onChange={(e) => {
              setValue(
                'userTags',
                !e.target.checked
                  ? fields.userTags.filter(
                      ({ label }) => label !== userTag.name,
                    )
                  : [
                      ...fields.userTags,
                      { id: userTag.id, label: userTag.name },
                    ],
              );
            }}
          />
          <label
            className="secondary-regular inline-flex items-center gap-tw-1"
            htmlFor={`parentId${userTag.id}`}
          >
            <span className="sre-icon-tag header6-regular leading-none text-neutral-500" />
            {userTag.name}
          </label>
        </label>
      ))}
    </div>
  );
};

export default SelectUserTag;

import { AgGridReact } from 'ag-grid-react';
import { ComponentProps } from 'react';
import {
  IRowBase,
  isRowDivider,
} from 'bundles/Shared/components/ReportTable/types';
import { NEXT_GEN_TABLE_CONFIG } from '../consts';
import { GetDataPath, GetRowIdFunc } from 'ag-grid-community';

export const getRowHeight: ComponentProps<
  typeof AgGridReact
>['getRowHeight'] = (params) => {
  if (params.data && isRowDivider(params.data)) {
    return NEXT_GEN_TABLE_CONFIG.row.divider.minHeight;
  }

  return NEXT_GEN_TABLE_CONFIG.row.default.minHeight;
};

export const getRowId: GetRowIdFunc = (row) => (row.data as IRowBase).key;

export const getDataPath: GetDataPath = (row: IRowBase) => row.path ?? [];

import { FieldTypeView } from 'bundles/Shared/components/GroupForm/types/types';
import { formatAmount } from '@/shared/lib/formatting/number';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import pluralize from 'pluralize';
import { IChangeOrder } from 'bundles/Construction/types';

export const getDataCOKeyinfo = (changeOrder: IChangeOrder) => [
  {
    titleView: 'Number',
    fieldName: 'number',
    typeView: FieldTypeView.VIEW_TEXT,
    value: changeOrder?.number,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    fieldName: 'date',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'CO Date',
    transformerView: () => formatUnixDate(changeOrder?.date, 'MMM DD, YYYY'),
  },
  {
    titleView: 'Detailed Description',
    fieldName: 'description',
    typeView: FieldTypeView.VIEW_TEXT,
    value: changeOrder?.description,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
];

export const getDataCOSubstantialDate = (changeOrder: IChangeOrder) => [
  {
    fieldName: 'substantialCompletionDate',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'Substantial Completion Date',
    transformerView: () =>
      formatDate(changeOrder?.substantialCompletionDate, 'MMM DD, YYYY'),
  },
  {
    titleView: 'Timing Impact',
    fieldName: 'timingImpactDays',
    typeView: FieldTypeView.VIEW_TEXT,
    transformerView: () =>
      `${changeOrder?.timingImpactDays} ${pluralize(
        'day',
        changeOrder?.timingImpactDays,
      )}`,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
];

export const getDataCOFinancialDetails = () => [
  {
    fieldName: 'priorContractSum',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'Prior Contract Sum',
    transformerView: formatAmount,
  },
  {
    fieldName: 'amount',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'Pending CO amount',
    transformerView: formatAmount,
  },
  {
    fieldName: 'newContractSum',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'New Contract Sum',
    transformerView: formatAmount,
  },
];

import { IAsset } from 'types/Asset';
import { TAssetStatuses } from 'types/AssetStatus';
import { CssVar } from '@/shared/config/cssVar';

export const getAssetStageColor = (
  asset: IAsset,
  defaultColor = CssVar.primaryDark2,
) => asset?.stage?.color || defaultColor;

export const getAssetStatusColors = (status: `${TAssetStatuses}`) => {
  const colors = {
    background: {
      draft: CssVar.dark60,
      archived: CssVar.light30,
      published: CssVar.green,
    },
    color: {
      draft: CssVar.light,
      archived: CssVar.light90,
      published: CssVar.light,
    },
  };

  return {
    background: colors.background[status],
    color: colors.color[status],
  };
};

import { CapitalInvestment } from '@/entities/return';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { TReturnInvestmentTransactionDirection } from 'bundles/REturn/types';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';

export const CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP = {
  contribution: 'totalContributionsDollars',
  distribution: 'totalDistributions',
} as const satisfies Record<TransactionSource['kind'], keyof CapitalInvestment>;

const CAPITAL_INVESTMENT_ENTRY_TYPE_KEYS = [
  `${TReturnInvestmentTransactionDirection.CONTRIBUTION}`,
  `${TReturnInvestmentTransactionDirection.DISTRIBUTION}`,
] as const;

export const ENTRY_THIN_TAB_ITEMS = (() => {
  const items = (
    ['commitment', ...CAPITAL_INVESTMENT_ENTRY_TYPE_KEYS] as const
  ).map((item) => ({
    id: item,
    label: pluralize(capitalize(item), 0),
  }));

  items.push({
    id: 'accrued',
    label: 'Accrued Preferred',
  });

  return items;
})();

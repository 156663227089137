import { IColumn } from 'bundles/Shared/components/Table/types';
import { useMemo } from 'react';
import { ISelectOption } from 'stories/FormControls/Select/Select';
import { assertIsSelectOption } from 'stories/FormControls/Select/utils';
import { IconButton, Select } from 'stories/index';
import { DISTRIBUTION_KIND_SELECT_OPTIONS } from '../../consts';
import { EntryAmountAndKind } from '../../splitModal/SplitTxBetweenKinds';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { useAllocationContext } from 'bundles/REconcile/components/AllocationProvider';

export function useSplitTxBetweenKindsColumns({
  entryAmountAndKindItems,
  actions: { onKindChange, onRemove },
}: {
  entryAmountAndKindItems: EntryAmountAndKind[];
  actions: {
    onKindChange: (id: string, kind: string | undefined) => void;
    onRemove: (id: string | number) => void;
  };
}) {
  const { inputProps } = useAllocationContext()!;
  const distributionKindSelectOptions = (
    kind: string,
  ): ISelectOption<string>[] =>
    DISTRIBUTION_KIND_SELECT_OPTIONS.filter(
      (option) =>
        option.id === kind ||
        !entryAmountAndKindItems
          .map(({ kind: innerKind }) => innerKind)
          .includes(option.id),
    );

  const columns = useMemo<IColumn<EntryAmountAndKind>[]>(
    () => [
      {
        text: 'Amount',
        dataField: 'amount',
        headerStyle: {
          width: 240,
        },
        formatter: ({ rowIndex, row: item }) => (
          <CurrencyInputNumber
            allowNegative={false}
            defaultValue={item.amount ? item.amount : undefined}
            onBlur={inputProps.handleBlur(rowIndex)}
            error={inputProps.error}
          />
        ),
      },
      {
        text: 'Category',
        dataField: 'category',
        headerStyle: {
          width: 240,
        },
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            <Select
              inputType="text"
              onSelectedChange={(selected) => {
                assertIsSelectOption(selected);
                onKindChange(item.id, selected.value);
              }}
              selected={DISTRIBUTION_KIND_SELECT_OPTIONS.find(
                ({ value }) => value === item.kind,
              )}
              placeholder="Select Category"
              options={distributionKindSelectOptions(item.kind)}
              popoverProps={{
                appendToBody: true,
              }}
            />
          </span>
        ),
      },
      {
        text: '',
        dataField: 'actions',
        headerStyle: {
          width: 'max-content',
        },
        formatter: ({ row: item }) => (
          <div className="sre-input sre-input_size-m">
            <IconButton
              onClick={() => onRemove(item.id)}
              iconName="closeSmall"
            />
          </div>
        ),
      },
    ],
    [distributionKindSelectOptions],
  );

  return columns;
}

type TItemWithIdProp = {
  id: string | number;
};
type TGetIdType<T extends TItemWithIdProp> = T extends { id: infer ID }
  ? ID
  : unknown;

export const getItemId = <T extends TItemWithIdProp>(item: T): TGetIdType<T> =>
  item.id as TGetIdType<T>;

export const mapListToIds = <T extends TItemWithIdProp>(
  items: T[],
): TGetIdType<T>[] => items.map(getItemId);

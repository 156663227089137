import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import { ISharedDocument } from 'types/SharedDocument';
import {
  ICOContractFile,
  TApprover,
} from 'bundles/Construction/components/eSignature/ESignPlacementModalV2';
import { IESignCoordinate } from 'bundles/Construction/types';

type TEsignMarkupsRequestBody = {
  approvers: {
    user_id: TApprover['userId'];
    user_role_id: TApprover['userRoleId'];
    company_id: TApprover['companyId'];
    priority: TApprover['priority'];
    markups: IESignCoordinate[];
  }[];
};

export const coContractFileApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/reconcile/legal_entities`,
  }),
  reducerPath: 'reconcileCOContractFile',
  tagTypes: ['COContractFile'],
  endpoints: (build) => ({
    getContractFile: build.query<
      ICOContractFile,
      {
        legalEntityCode: string;
        changeOrderId: number;
        contractFileId: string;
      }
    >({
      query: ({ legalEntityCode, changeOrderId, contractFileId }) =>
        `/${legalEntityCode}/change_orders/${changeOrderId}/documents/${contractFileId}/esign_placements`,
      providesTags: ['COContractFile'],
    }),
    updateEsignPlacements: build.mutation<
      TEsignMarkupsRequestBody,
      {
        legalEntityCode: string;
        changeOrderId: number;
        contractFileId: string;
        body: ISharedDocument;
      }
    >({
      query: ({ legalEntityCode, changeOrderId, contractFileId, body }) => ({
        url: `/${legalEntityCode}/change_orders/${changeOrderId}/documents/${contractFileId}/define_esign_placements`,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      transformResponse: (response) => {
        toastr.success('E-signature placements have been defined');
        return response.data;
      },
      invalidatesTags: ['COContractFile'],
    }),
  }),
});

export const { useGetContractFileQuery, useUpdateEsignPlacementsMutation } =
  coContractFileApi;

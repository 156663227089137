import { ReportManualVariable } from '@/entities/report/manualVariable';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { exportFile } from '@/shared/lib/exportFile';

export function useExportMVLegalEntities() {
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleExport = ({ mvId }: { mvId: ReportManualVariable['id'] }) => {
    exportFile({
      url: `/report/manual_variables/${mvId}/export`,
      filePostfix: 'manual-variable-legal-entities',
      enableLoading,
      disableLoading,
    });
  };

  return [handleExport, { isLoading }] as const;
}

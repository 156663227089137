/* eslint-disable react/self-closing-comp */
import { ERPSystem } from 'bundles/Shared/entities/erpsystem/model';
import { ReactComponent as app_folio } from './svgs/app_folio.svg';
import { ReactComponent as app_folio_mono } from './svgs/app_folio_mono.svg';
import { ReactComponent as cyma } from './svgs/cyma.svg';
import { ReactComponent as cyma_mono } from './svgs/cyma_mono.svg';
import { ReactComponent as entrata } from './svgs/entrata.svg';
import { ReactComponent as entrata_mono } from './svgs/entrata_mono.svg';
import { ReactComponent as fortress } from './svgs/fortress.svg';
import { ReactComponent as fortress_mono } from './svgs/fortress_mono.svg';
import { ReactComponent as m3 } from './svgs/m3.svg';
import { ReactComponent as m3_mono } from './svgs/m3_mono.svg';
import { ReactComponent as mri } from './svgs/mri.svg';
import { ReactComponent as mri_mono } from './svgs/mri_mono.svg';
import { ReactComponent as net_suite } from './svgs/net_suite.svg';
import { ReactComponent as net_suite_mono } from './svgs/net_suite_mono.svg';
import { ReactComponent as quickbooks_desktop } from './svgs/quickbooks_desktop.svg';
import { ReactComponent as quickbooks_desktop_mono } from './svgs/quickbooks_desktop_mono.svg';
import { ReactComponent as quickbooks_online } from './svgs/quickbooks_online.svg';
import { ReactComponent as quickbooks_online_mono } from './svgs/quickbooks_online_mono.svg';
import { ReactComponent as real_page } from './svgs/real_page.svg';
import { ReactComponent as real_page_mono } from './svgs/real_page_mono.svg';
import { ReactComponent as resman } from './svgs/resman.svg';
import { ReactComponent as resman_mono } from './svgs/resman_mono.svg';
import { ReactComponent as sage } from './svgs/sage.svg';
import { ReactComponent as sage_mono } from './svgs/sage_mono.svg';
import { ReactComponent as yardi } from './svgs/yardi.svg';
import { ReactComponent as yardi_mono } from './svgs/yardi_mono.svg';
import { ReactComponent as rentegi } from './svgs/rentegi.svg';
import { ReactComponent as rentegi_mono } from './svgs/rentegi_mono.svg';

const COMPONENT_MAP: Record<
  ERPSystem | `${ERPSystem}_mono`,
  typeof app_folio
> = {
  app_folio,
  app_folio_mono,
  cyma,
  cyma_mono,
  entrata,
  entrata_mono,
  fortress,
  fortress_mono,
  m3,
  m3_mono,
  mri,
  mri_mono,
  net_suite,
  net_suite_mono,
  quickbooks_desktop,
  quickbooks_desktop_mono,
  quickbooks_online,
  quickbooks_online_mono,
  real_page,
  real_page_mono,
  resman,
  resman_mono,
  sage,
  sage_mono,
  yardi,
  yardi_mono,
  rentegi_mono,
  rentegi,
};

export interface IERPSystemIcon {
  system: ERPSystem;
  config?: {
    width: number;
    height: number;
  };
  typeThemeColor?: 'mono' | 'colorful';
}

const DEFAULT_CONFIG = {
  width: 16,
  height: 16,
};

export const ERPSystemIcon = ({
  system,
  typeThemeColor,
  config = DEFAULT_CONFIG,
}: IERPSystemIcon) => {
  const Component =
    COMPONENT_MAP[`${system}${typeThemeColor === 'mono' ? '_mono' : ''}`] ??
    (() => null);

  return <Component {...config} />;
};

import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import {
  COLOR_BOX_SIZE_CLASSNAME,
  ColorButtonSizeProp as Size,
} from '@/shared/widgets/colorPicker/config';
import { Dropdown, DropdownItem, Icon, Tooltip } from '@/stories';
import { ComponentProps, PropsWithChildren } from 'react';

const Switcher = ({
  color,
  size = '24',
  selected,
  className,
  disabled,
  showSelectedIcon,
  ...rest
}: {
  size?: Size;
  color?: React.CSSProperties['color'];
  showSelectedIcon?: boolean;
  selected?: boolean;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>) => (
  <button
    type="button"
    className={cn(
      'flex items-center justify-center rounded-[8px] border-[2px] border-solid border-neutral-000 hover:border-[2px] hover:border-neutral-100',
      {
        'border-[2px] border-neutral-250 hover:border-neutral-300': selected,
        '!cursor-not-allowed': disabled,
      },
      COLOR_BOX_SIZE_CLASSNAME[size],
      className,
    )}
    style={{
      background: color,
    }}
    {...rest}
  >
    {showSelectedIcon && selected && (
      <Icon
        iconName="check"
        color="white"
        style={{
          filter: color?.startsWith('#f') ? 'invert(1)' : undefined,
        }}
      />
    )}
    {rest.children}
  </button>
);

const Cell = ({
  color,
  size = '24',
  className,
  selected,
  disabled,
  onRemoveCell,
  tooltipProps = {
    disabled: true,
  },
  isRemovable,
  ...rest
}: {
  color: React.CSSProperties['color'];
  selected?: boolean;
  disabled?: boolean;
  onRemoveCell?: () => void;
  size?: Size;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  isRemovable?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>) => {
  const { value, setFalse, setTrue } = useBoolean();

  const rawButton = (
    <>
      <button
        className={cn(
          'relative border-[1px] border-solid border-neutral-100 ',
          {
            'z-40 border-[2px] border-solid border-info-055 shadow-md hover:outline-neutral-300':
              selected,
            '!cursor-not-allowed': disabled,
          },
          COLOR_BOX_SIZE_CLASSNAME[size],
          className,
        )}
        style={{
          background: color,
          color,
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          if (onRemoveCell) {
            setTrue();
          }
        }}
        {...rest}
      />
      <Dropdown
        className="sre-dropdown-v2"
        visible={value}
        onClickOutside={setFalse}
        onHidden={setFalse}
        placement="bottom-end"
        items={
          <>
            <DropdownItem
              disabled={!isRemovable}
              tooltipProps={{
                mainText: 'This color is standart and cannot be removed',
                disabled: isRemovable,
              }}
              iconName="trash"
              onClick={() => {
                onRemoveCell?.();
              }}
            >
              Remove
            </DropdownItem>
          </>
        }
      />
    </>
  );

  if (tooltipProps.disabled) return rawButton;

  return <Tooltip {...tooltipProps}>{rawButton}</Tooltip>;
};

const List = (props: PropsWithChildren) => (
  <div className="flex flex-wrap">{props.children}</div>
);

export default {
  Cell,
  Switcher,
  List,
};

import { prepareItemNameForDuplicate } from '@/bundles/Shared/widgets/dashboard/card/lib';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/reportBuilderTemplatesGeneratedApi';
import { usePostApiSettingsReportBuilderTemplatesByIdDuplicateMutation } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { CreateReportBuilderTemplateModal } from '@/entities/report/reportBuilder/ui/CreateReportBuilderTemplateModal';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useReportBuilderTemplateDuplicate = () => {
  const [duplicateTemplate, options] =
    usePostApiSettingsReportBuilderTemplatesByIdDuplicateMutation();
  const { openModal } = useModal();

  return [
    async (template: Pick<ReportBuilderTemplateDto, 'id' | 'name'>) => {
      const res = await openModal(CreateReportBuilderTemplateModal, {
        template: {
          name: prepareItemNameForDuplicate(template.name),
        },
      });
      if (!res) {
        return;
      }
      return duplicateTemplate({ id: template.id, body: { name: res.name } });
    },
    options,
  ] as const;
};

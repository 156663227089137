import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import {
  IPaginationMeta,
  IPaginationParams,
  IQueryParams,
} from 'types/Pagination';
import { ISortParamsField } from 'types/Sort';

export interface IReconcileSettingsChangeOrderReason {
  id: number;
  name: string;
  category: string;
  active: boolean;
}

export type TGetReconcileSettingsChangeOrderReasonParams = IQueryParams &
  IPaginationParams &
  ISortParamsField<'field' | 'order'> & {
    categories?: string[];
    active?: boolean;
  };

export interface IReconcileSettingsChangeOrderReasonUpdate {
  name: string;
  category: string;
  active: boolean;
}

export const {
  getEntities: getChangeOrderReasonsInReconcileSettings,
  createEntity: createChangeOrderReasonsInReconcileSettings,
  updateEntity: updateChangeOrderReasonsInReconcileSettings,
  deleteEntity: deleteChangeOrderReasonsInReconcileSettings,
  entityName: CHANGE_ORDER_REASON_ENTITY_NAME,
} = createCrudApi<
  {},
  TGetReconcileSettingsChangeOrderReasonParams,
  IReconcileSettingsChangeOrderReason,
  IReconcileSettingsChangeOrderReasonUpdate,
  {
    items: IReconcileSettingsChangeOrderReason[];
    meta: IPaginationMeta & { categories: string[] };
  }
>('/settings/reconcile/change_order_reasons', 'Change Order Reason');

import React, { FC, useMemo, useState } from 'react';
import { Button, Modal, UnitNum } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { IChangeOrderReason } from 'bundles/Construction/types';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { updateCostBreakDownChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useParams } from '@reach/router';
import MarkupModal from './MarkupModal';
import WorkCostModal from './WorkCostModal';
import FeeModal from './FeeModal';
import { IWorkCost } from './components/CostOfWork';
import { validateCostBreakDown } from './utils';
import {
  IFee,
  IMarkup,
} from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';

interface ICostBreakDownModalProps {
  showModalEdit: string;
  setShowModalEdit: () => void;
  workCosts: IWorkCost[];
  markups: IMarkup[];
  fees: IFee[];
  scheduleOfValueCodes: {
    codeName: string | number;
  }[];
  editOnly: boolean;
  recommendedReasonNames: IChangeOrderReason[];
  allReasons: IChangeOrderReason[];
  changeOrderMarkups: IMarkup[];
  changeOrderFees: IFee[];
}

const CostBreakDownModal: FC<ICostBreakDownModalProps> = ({
  showModalEdit,
  setShowModalEdit,
  workCosts,
  markups,
  fees,
  scheduleOfValueCodes,
  editOnly,
  recommendedReasonNames,
  allReasons,
  changeOrderMarkups,
  changeOrderFees,
}) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const costBreakdownItems = [
    {
      value: 'workCosts',
      title: 'Cost of Work',
    },
    {
      value: 'markups',
      title: 'Contractor Markups',
    },
    {
      value: 'fees',
      title: 'Contractor Fees',
    },
  ];
  const [workCostData, setWorkCostData] = useState(() => {
    if (workCosts && workCosts.length) {
      return workCosts.map((workCostItem) => ({
        ...workCostItem,
        changeOrderReason: {
          label: `${workCostItem?.reason?.category} - ${workCostItem?.reason?.name}`,
          value: Number(workCostItem?.reason?.id),
        },
        scheduleOfValueCode: Number(workCostItem?.costCode?.id),
      }));
    }
    return [
      {
        changeOrderReason: '',
        scheduleOfValueCode: '',
        amount: '',
        description: '',
      },
    ];
  });
  const [markupData, setMarkupData] = useState(() => {
    if (markups.length) {
      return markups.map((markupItem) => ({
        ...markupItem,
        changeOrderMarkup: Number(markupItem?.costCode?.id),
      }));
    }
    return [];
  });
  const [feeData, setFeeData] = useState(() => {
    if (fees.length) {
      return fees.map((feeItem) => ({
        ...feeItem,
        changeOrderFee: Number(feeItem?.costCode.id),
      }));
    }
    return [];
  });
  const [activeField, setActiveField] = useState(showModalEdit);
  const saveUpdates = async () => {
    const payloadData = {
      work_costs: workCostData.map((workCost) => ({
        amount: workCost.amount,
        change_order_reason_id:
          workCost.changeOrderReason?.value || workCost.changeOrderReason,
        schedule_of_value_code_id: workCost.scheduleOfValueCode,
      })),
      markups: markupData[0]?.changeOrderMarkup
        ? markupData.map((markup) => ({
            ...markup,
            amount: markup.amount,
            change_order_markup_id: markup?.changeOrderMarkup,
          }))
        : [],
      fees: feeData[0]?.changeOrderFee
        ? feeData.map((fee) => ({
            ...fee,
            amount: fee.amount,
            change_order_fee_id: fee?.changeOrderFee,
          }))
        : [],
      status: null,
    };
    await dispatch(
      updateCostBreakDownChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
        data: payloadData,
      }),
    );
    setShowModalEdit(false);
  };

  const invalidAmount = useMemo(
    () =>
      workCostData.reduce(
        (acc, workCost) => acc + Number(workCost.amount ? workCost.amount : 0),
        0,
      ),
    [workCostData],
  );

  const invalidFeeAmount = useMemo(
    () =>
      invalidAmount +
      markupData
        .filter(
          (markup) =>
            changeOrderMarkups.find(
              (markupItem) => markupItem.id === markup.changeOrderMarkup,
            )?.includedInFee,
        )
        .reduce(
          (acc, markup) => acc + Number(markup.amount ? markup.amount : 0),
          0,
        ),
    [workCostData, markupData],
  );
  const validCostBreakDown = validateCostBreakDown(
    workCostData,
    markupData,
    feeData,
    changeOrderMarkups,
    changeOrderFees,
    invalidAmount,
    invalidFeeAmount,
  );

  return (
    <Modal
      header="Edit Cost Breakdown"
      size="lg"
      classes={{
        body: 'reconcile-common-modal-create',
      }}
      maxHeight
      toggle={setShowModalEdit}
      additionalActions={
        <div className="flex justify-between w-full">
          <Button variant="secondary" onClick={() => setShowModalEdit(false)}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={saveUpdates}
            disabled={!validCostBreakDown}
          >
            Save Updates
          </Button>
        </div>
      }
      bodyPadding="0"
    >
      <div className="choose-cost-breakdown">
        {costBreakdownItems.map((item, idx) => (
          <div
            key={`cost${idx}`}
            className={cn(
              'choose-cost-breakdown__item',
              'inline-regular',
              activeField === item.value &&
                'choose-cost-breakdown__item_active',
            )}
            onClick={() => setActiveField(item.value)}
          >
            <UnitNum className="mr-m" activated={activeField === item.value}>
              {idx + 1}
            </UnitNum>
            <div>{item.title}</div>
          </div>
        ))}
      </div>
      <div>
        <div
          className={cn(
            'cost-break-down-modal',
            activeField === 'workCosts' && 'cost-break-down-modal_active',
          )}
        >
          <div className="dark-60 body-semibold mb-s">Cost of Work</div>
          <WorkCostModal
            scheduleOfValueCodes={scheduleOfValueCodes}
            editOnly={editOnly}
            recommendedReasonNames={recommendedReasonNames}
            allReasons={allReasons}
            workCostData={workCostData}
            setWorkCostData={setWorkCostData}
            validCostBreakDown={validCostBreakDown}
            markupData={markupData}
            setMarkupData={setMarkupData}
            feeData={feeData}
            setFeeData={setFeeData}
          />
        </div>
        <div
          className={cn(
            'cost-break-down-modal',
            activeField === 'markups' && 'cost-break-down-modal_active',
          )}
        >
          <div className="dark-60 body-semibold mb-s">Contractor Markups</div>
          <MarkupModal
            changeOrderMarkups={changeOrderMarkups}
            invalidAmount={invalidAmount}
            markupData={markupData}
            setMarkupData={setMarkupData}
            feeData={feeData}
            setFeeData={setFeeData}
          />
        </div>
        <div
          className={cn(
            'cost-break-down-modal',
            activeField === 'fees' && 'cost-break-down-modal_active',
          )}
        >
          <div className="dark-60 body-semibold mb-s">Contractor Fees</div>
          <FeeModal
            changeOrderFees={changeOrderFees}
            invalidAmount={invalidFeeAmount}
            feeData={feeData}
            setFeeData={setFeeData}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CostBreakDownModal;

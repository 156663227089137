import { navigate } from '@reach/router';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { FC, PropsWithChildren } from 'react';
import Tooltip, { DEFAULT_TOOLTIP_OFFSET } from 'stories/Tooltip/Tooltip';

export const TOOLTIP_FOR_UNPUBLISHED_DATA_JSX = (
  <Tooltip
    variant="explanation"
    titleText="Unpublished Data"
    mainText="This month contains unpublished data"
    actionText="Open Data Periods"
    arrowPosition="start"
    placement="top-start"
    offset={[-3, DEFAULT_TOOLTIP_OFFSET[1]]}
    actionHandler={() =>
      navigate(ROUTES_ROOT.settings.report.dataPeriods.fullPath)
    }
    maxWidth="12.5rem"
    className="pr-s"
  >
    <FireIcon priority="high" />
  </Tooltip>
);

export const ClickForDetailsTooltip: FC<PropsWithChildren> = ({ children }) => (
  <Tooltip
    mainText="Click for Details"
    arrowPosition="end"
    placement="top-end"
    classes={{ spanContainer: 'grid h-full cursor-pointer' }}
    className="cursor-pointer items-center justify-end"
  >
    {children}
  </Tooltip>
);

import { cn } from '@/shared/lib/css/cn';
import { useState } from 'react';

type TDirection =
  | 'rightdown'
  | 'leftup'
  | 'rightup'
  | 'leftbottom'
  | 'down'
  | 'up'
  | 'right'
  | 'left';

export function useHoverSaveArea({
  popoverPlacement = 'end',
}: {
  popoverPlacement?: 'start' | 'end';
} = {}) {
  const [direction, setDirection] = useState<TDirection>();
  const onMouseMove: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (e.movementY > 0 && e.movementX > 0) {
      if (direction !== 'rightdown') setDirection('rightdown');
    } else if (e.movementY < 0 && e.movementX < 0) {
      if (direction !== 'leftup') setDirection('leftup');
    } else if (e.movementY < 0 && e.movementX > 0) {
      if (direction !== 'rightup') setDirection('rightup');
    } else if (e.movementY > 0 && e.movementX < 0) {
      if (direction !== 'leftbottom') setDirection('leftbottom');
    } else if (e.movementY > 0 && e.movementX === 0) {
      if (direction !== 'down') setDirection('down');
    } else if (e.movementY < 0 && e.movementX === 0) {
      if (direction !== 'up') setDirection('up');
    } else if (e.movementX > 0 && e.movementY === 0) {
      if (direction !== 'right') setDirection('right');
    } else if (e.movementX < 0 && e.movementY === 0) {
      if (direction !== 'left') setDirection('left');
    }
  };
  const classes = cn(
    'left-sidebar__save-area',
    popoverPlacement === 'start' && 'startPopoverPlacement',
    {
      'left-sidebar__save-area-none': !(
        direction === 'right' ||
        direction === 'rightup' ||
        direction === 'rightdown'
      ),
    },
  );

  return {
    onMouseMove,
    classes,
  };
}

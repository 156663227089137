import React from 'react';

export const CONFIRM_APPROVE_CHANGE_ORDER_PROPS = {
  title: 'Approve Change Order',
  subtitle: (
    <p>
      Are you sure you want to Approve Change Order? <br />
      <strong>This can’t be undone</strong>
    </p>
  ),
  actions: {
    primaryButton: {
      text: 'Approve',
      variant: 'success',
    },
    secondaryButton: {
      text: 'Cancel',
      variant: 'secondary',
    },
  },
} as const;

import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { ExcelDataType, ExcelStyle } from 'ag-grid-community';

export const getPrecisionFormat = (precision?: number) => {
  if (!precision || precision === 0) {
    return '0';
  }
  return `0.${'0'.repeat(precision)}`;
};

export const getColumnExcelFormat = (format: ValueDisplayOptions): string => {
  if (format.type === 'currency') {
    return `$ #,##${getPrecisionFormat(format.precision)}`;
  }
  if (format.type === 'percentage') {
    return `${getPrecisionFormat(format.precision)} %;(${getPrecisionFormat(
      format.precision,
    )} %)`;
  }
  return getPrecisionFormat(format.precision);
};

export const getExcelStyleNumberFormat = (
  options: ValueDisplayOptions,
): Pick<ExcelStyle, 'numberFormat' | 'dataType'> => {
  const getDataType = (): ExcelDataType => {
    if (
      ['bps', 'currency', 'percentage', 'number', 'metric', 'sqft'].includes(
        options.type,
      )
    ) {
      return 'Number';
    }
    return 'String';
  };
  return {
    dataType: getDataType(),
    numberFormat: {
      format: getColumnExcelFormat(options),
    },
  };
};

import React, { useState } from 'react';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import { Button, Modal, MultilineAlert } from 'stories';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { fetchDealPathSettings, updateDealPathCategory } from '../../actions/dealPathSettings';
import { optionsFor } from '../../../../lib/helpers';
import CardWithToggle from './CardWithToggle';

const DealStages = (props) => {
  const [selectedCategory, setSelectedCategory] = useState();

  const { setValue, handleSubmit } = useForm({ defaultValues: selectedCategory });

  const submit = async (data) => {
    await props.update(selectedCategory.id, data);
    setSelectedCategory(null);
  };

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="py-tw-3 px-tw-5">
          <h3>Stages</h3>
        </div>
        <div className="px-tw-5 py-tw-5">
          <div className="flex flex-wrap">
            {props.categories.map((category) => (
              <CardWithToggle
                key={category.id}
                item={category}
                onToggle={() => props.update(category.id, { active: !category.active }, fetchDealPathSettings)}
                onEdit={() => setSelectedCategory(category)}
                footer={() => (
                  <div className="font-12">
                    <span className="dark-60 mr-1">Linked with:</span>
                    <span>{category.deal_path_statuses?.join(', ')}</span>
                  </div>
                )}
              />
            ))}
          </div>
          <MultilineAlert
            message="By enabling/disabling stage(s) you acknowledge the need of changing underlying
            Dashboard Settings"
          />
        </div>
      </CardWrapper>
      {selectedCategory && (
        <Modal
          header="Assign statuses to stage"
          toggle={() => setSelectedCategory(null)}
          actions={(
            <Button form="dealStagesForm" variant="success" fluid type="submit">
              Submit
            </Button>
          )}
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
        >
          <form id="dealStagesForm" onSubmit={handleSubmit(submit)}>
            <Select
              menuPosition="fixed"
              menuShouldBlockScroll
              defaultValue={optionsFor(selectedCategory.deal_path_statuses || [])}
              onChange={(values) => setValue('deal_path_statuses', values?.map((v) => v.value))}
              isMulti
              placeholder="Select Related Group Statuses"
              options={props.availableStatuses}
            />
          </form>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ dealPathSettings }) => ({
  categories: dealPathSettings.categories,
});

const mapActionCreators = {
  update: updateDealPathCategory,
  fetchDealPathSettings,
};

export default connect(mapStateToProps, mapActionCreators)(DealStages);

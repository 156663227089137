import { cn } from '@/shared/lib/css/cn';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';
import { FC } from 'react';
import Fractions from 'stories/ValueFormatters/components/Fractions';
import SignWrapper from 'stories/ValueFormatters/components/SignWrapper';
import {
  GetClassesObject,
  GetStylesObject,
  NumberFormatterProps,
} from 'stories/ValueFormatters/types';
import {
  getFormattedValueParts,
  getMergedClasses,
  getMergedStyles,
} from 'stories/ValueFormatters/utils';
import {
  OUTPUT_DEFAULT_CLASSES,
  OutputFormatter,
  OutputFormatterClassKeys,
} from './OutputFormatter';

export type IPercentFormatterClasses = GetClassesObject<
  OutputFormatterClassKeys | 'fractions' | 'brackets'
>;
export type IPercentFormatterStyles = GetStylesObject<
  OutputFormatterClassKeys | 'fractions' | 'brackets'
>;

export type IPercentFormatterProps = NumberFormatterProps<
  IPercentFormatterClasses,
  IPercentFormatterStyles
>;

const DEFAULT_CLASSES = {
  ...OUTPUT_DEFAULT_CLASSES,
  fractions: 'text-neutral-850',
  value: '',
} as const satisfies IPercentFormatterClasses;

export const PercentFormatter: FC<IPercentFormatterProps> = ({
  value,
  hideFractions,
  toLocalStringOptions,
  fallbackValue = DEFAULT_NUMBER_FALLBACK,
  ...props
}) => {
  if (value === null || value === undefined || isNaN(value))
    return <span>{fallbackValue}</span>;

  const formattedAmount = Math.abs(value).toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    ...toLocalStringOptions,
  });

  const classes = getMergedClasses({
    classes: props.classes,
    allParts: props.classes?.allParts,
    defaultClasses: DEFAULT_CLASSES,
    value,
  });

  const styles = getMergedStyles({
    ...(props.styles ?? {}),
    // @ts-expect-error
    partNames: [
      'brackets',
      'endSymbol',
      'fractions',
      'partNames',
      'startSymbol',
      'value',
      'wrapper',
    ] as const,
  });

  const { displayValue } = getFormattedValueParts(formattedAmount);

  return (
    <SignWrapper
      negative={value < 0}
      className={classes?.brackets}
      styles={styles as { brackets: React.CSSProperties }}
    >
      <OutputFormatter
        classes={{
          ...classes,
          value: cn(value === 0 && 'text-neutral-500', classes?.value),
        }}
        styles={styles}
        endSymbol="%"
      >
        {displayValue}
        {!hideFractions && value !== 0 && (
          <Fractions
            style={styles.fractions}
            formattedValue={formattedAmount}
            className={classes.fractions}
          />
        )}
      </OutputFormatter>
    </SignWrapper>
  );
};

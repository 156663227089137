import React from 'react';
import {
  findFormulaOrVariableByReference,
  Formula,
  openFormula,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import IconButton from 'stories/IconButton/IconButton';
import { useAppDispatch } from '@/shared/lib/hooks/redux';

interface Props {
  reference: Formula['reference'];
  onClick?: VoidFunction;
}

export function InspectFormulaButton({ reference }: Props) {
  const dispatch = useAppDispatch();
  const { formulas, isLoading } = useReportFormulasQuery();

  const formula = findFormulaOrVariableByReference(formulas, reference);

  const handleOpenFormula = () => {
    if (!formula) {
      toastr.error(`Formula with reference ${reference} not found`);
      return;
    }
    dispatch(openFormula(formula));
  };

  return (
    <IconButton
      variant="white"
      disabled={isLoading}
      iconName="inspectFunction"
      onClick={handleOpenFormula}
    />
  );
}

import React from 'react';
import StepsFormStepv2 from 'stories/StepsFormStep/StepsFormStepv2';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';

interface Props {
  activated: boolean;
  setTotal: (value: number | undefined) => void;
  title: string;
  stepNumber: number;
  slot?: React.ReactNode;
}

function EnterTotalFormStep({
  activated,
  setTotal,
  stepNumber,
  title,
  slot,
}: Props) {
  return (
    <StepsFormStepv2
      title={title}
      required
      stepNumber={stepNumber}
      activated={activated}
    >
      <div className="flex items-center gap-tw-4">
        <CurrencyInputNumber
          classes={{
            outer: 'w-[284px]',
          }}
          selectOnFocus
          placeholder="Enter Value"
          allowNegative={false}
          onValueChange={(value) => setTotal(value)}
        />
        {slot}
      </div>
    </StepsFormStepv2>
  );
}

export default EnterTotalFormStep;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props extends React.AllHTMLAttributes<HTMLElement> {
  email: string;
}

export const EmailLink = ({ email, children, className, ...props }: Props) => (
  <a
    href={`mailto:${email}`}
    className={cn('whitespace-nowrap text-info-055', className)}
    {...props}
  >
    {children}
  </a>
);

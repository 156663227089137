import {
  GENERAL_LEDGERS_COLUMN_IDS,
  GroupedByTableActions,
} from '@/bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import { ClassificationHeaderColumnComponent } from '@/bundles/Settings/components/REport/GeneralLedgers/ui/LegalEntityColumns';
import {
  LeClassification,
  LeClassificationFormatter,
  LeClassificationModal,
} from 'bundles/Shared/entities/leClasssification';
import { useModal } from '@/shared/lib/hooks/useModal';
import { LegalEntity } from '@/entities/core/legalEntity';
import { ColDef } from 'ag-grid-community';

export function getLEClassAggridTableColumn<
  T extends Pick<LegalEntity, 'classification'> & {
    misclassified?: boolean;
  },
>({
  actions,
  misclassified = false,
  isLastColumn = false,
  misclassifiedSize = 0,
}: {
  actions: Pick<
    GroupedByTableActions<T>,
    | 'onMisclassifiedFilterChange'
    | 'onDisableMisclassified'
    | 'onSetClassification'
  >;
  misclassified?: boolean;
  misclassifiedSize?: number;
  isLastColumn?: boolean;
}): ColDef {
  const { openModal } = useModal();
  return {
    colId: GENERAL_LEDGERS_COLUMN_IDS.legelEntityClassification,
    headerName: 'Class',
    cellClass: isLastColumn ? 'last' : '',
    field: 'legalEntity.classification',
    headerComponent: () => (
      <ClassificationHeaderColumnComponent
        actions={actions}
        misclassified={misclassified}
        misclassifiedSize={misclassifiedSize}
      />
    ),
    cellRenderer: ({ value, data }: { value: LeClassification; data: T }) => {
      const row = data;
      return (
        <LeClassificationFormatter
          showFireIcon={row.misclassified}
          classification={value}
          onClick={async () => {
            const res = await openModal(LeClassificationModal, {});
            if (!res) {
              return;
            }
            actions.onSetClassification?.(res, row);
          }}
        />
      );
    },
  };
}

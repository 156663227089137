import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCacheFileStoragePresign: build.query<
      GetApiCacheFileStoragePresignApiResponse,
      GetApiCacheFileStoragePresignApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cache_file_storage/presign`,
        params: { filename: queryArg.filename, type: queryArg['type'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as rootCacheFileStorageApi };
export type GetApiCacheFileStoragePresignApiResponse = unknown;
export type GetApiCacheFileStoragePresignApiArg = {
  filename: string;
  type: string;
};
export const { useGetApiCacheFileStoragePresignQuery, useLazyGetApiCacheFileStoragePresignQuery } = injectedRtkApi;

import { AnimationLoader, HistoryItem } from 'stories';
import { plainFecthUserRoleActivityLogItem } from 'bundles/UserManagement/actions/UserRole';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import React, { useEffect, useState } from 'react';
import { genHistoryItemProps, NoActivityLogJSX } from '../constants';
import { IUserRoleActivityLogItem, ActivityLogTabType } from '../types';
import { LogItemShowDetailsModal } from './ActivityLog/LogItemShowDetailsModal';

export function ActivityLogTab() {
  const [activityLogTabState, setActivityLogTabState] =
    useState<ActivityLogTabType>('idle');
  const [showLogDetails, setShowLogDetails] =
    useState<IUserRoleActivityLogItem | null>(null);
  const [activityLogList, setActivityLogList] = useState<
    IUserRoleActivityLogItem[]
  >([]);
  const activeRoleId = useAppSelector((state) => state.userRoles.activeRoleId);
  const roles = useAppSelector((state) => state.userRoles.roles);

  useEffect(() => {
    if (activeRoleId) {
      const fetch = async () => {
        setActivityLogTabState('loading');
        const activityLog =
          await plainFecthUserRoleActivityLogItem(activeRoleId);
        setActivityLogList(activityLog);
        setActivityLogTabState(
          activityLog.length === 0 ? 'no-activity-log' : 'loaded',
        );
      };
      fetch();
    }
  }, [activeRoleId, roles]);

  const handleToggleShowDetailsModal = (opened: boolean) => {
    setShowLogDetails(null);
    setActivityLogTabState(opened ? 'idle' : 'show-details');
  };

  const handleShowDetailsModal = (logItem: IUserRoleActivityLogItem) => {
    setShowLogDetails(logItem);
    setActivityLogTabState('show-details');
  };

  if (activityLogTabState === 'loading') {
    return <AnimationLoader />;
  }

  if (activityLogTabState === 'no-activity-log') {
    return NoActivityLogJSX;
  }

  return (
    <div className="flex flex-col">
      {[...activityLogList].reverse().map((logItem, idx, arr) => (
        <HistoryItem
          key={`${logItem.name}-${logItem.createdAt}`}
          {...genHistoryItemProps(
            logItem,
            handleShowDetailsModal,
            idx === arr.length - 1,
          )}
        />
      ))}

      {activityLogTabState === 'show-details' && showLogDetails && (
        <LogItemShowDetailsModal
          logItem={showLogDetails}
          toggle={handleToggleShowDetailsModal}
        />
      )}
    </div>
  );
}

import { CurrencyFormatter, IconButton, ConfirmationModal } from 'stories';
import {
  FUNDING_SOURCE_KINDS,
  FundingSource,
} from '@/shared/types/reconcile/FundingSource';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useParams } from '@reach/router';
import { useDeleteFundingSourceMutation } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import FundingSourceModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/features/FundingSourceModal';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { KindFSFormatter } from 'bundles/Shared/components/Table/formatters/KindFSFormatter';

export enum TColumnIdsFundingSources {
  NAME = 'name',
  KIND = 'kind',
  AMOUNT = 'amount',
  FUNDING_TO_DATE = 'fundedToDate',
  BALANCE = 'balance',
}

export type TFilterModelFundingSources = {
  [TColumnIdsFundingSources.AMOUNT]?: RawFloat;
  [TColumnIdsFundingSources.BALANCE]?: RawFloat;
  [TColumnIdsFundingSources.KIND]?: `${FUNDING_SOURCE_KINDS}`;
};

const ActionsCell = ({ row }: { row: FundingSource }) => {
  const params = useParams();
  const [deleteFundingSource, { isSuccess }] = useDeleteFundingSourceMutation();
  const destroyHandle = (status: boolean) => {
    if (status) {
      deleteFundingSource({
        legalEntityCode: params.legalEntityCode,
        fundingSourceId: row.id,
      });
    }
  };
  const { openModal, resetAllModals } = useModal();

  const openFundingSourceModal = () => {
    openModal(FundingSourceModal, {
      type: 'edit',
      legalEntityCode: params.legalEntityCode,
      header: (
        <span className="header6-bold text-dark-60">Update Funding Source</span>
      ),
      submitText: 'Update Source',
      fundingSource: row,
    });
  };

  if (isSuccess) {
    resetAllModals();
  }
  return (
    <div className="flex justify-center gap-tw-2">
      <IconButton
        iconName="edit"
        variant="white"
        onClick={openFundingSourceModal}
      />
      <IconButton
        iconName="trash"
        variant="white"
        onClick={() => {
          openModal(ConfirmationModal, {
            title: 'Delete Funding Source',
            subtitle: 'Are you sure you want to delete this Funding Source?',
            handleResolve: destroyHandle,
          });
        }}
      />
    </div>
  );
};

export const useFundingSourcesColumns = (): {
  columns: IColumn<FundingSource>[];
} => ({
  columns: [
    {
      sortable: true,
      dataField: TColumnIdsFundingSources.NAME,
      text: 'Name',
      formatter: ({ row }) => row.name,
      headerClasses: 'min-w-[110px]',
    },
    {
      dataField: TColumnIdsFundingSources.KIND,
      text: 'Type',
      headerStyle: {
        width: 160,
      },
      formatter: ({ row }) => <KindFSFormatter value={row.kind} />,
    },
    {
      sortable: true,
      dataField: TColumnIdsFundingSources.AMOUNT,
      text: 'Commitment',
      formatter: ({ row }) => (
        <div className="flex items-center justify-between">
          <CurrencyFormatter
            classes={{ value: 'inline-regular text-dark-60' }}
            value={row.amount}
          />
        </div>
      ),
      headerClasses: 'min-w-[120px]',
    },
    {
      sortable: true,
      dataField: TColumnIdsFundingSources.FUNDING_TO_DATE,
      text: 'Funded to date',
      formatter: ({ row }) => (
        <div className="flex items-center justify-between">
          <CurrencyFormatter
            classes={{ value: 'inline-regular text-dark-60' }}
            value={row.fundedToDate}
          />
        </div>
      ),
      headerClasses: 'min-w-[120px]',
    },
    {
      sortable: true,
      dataField: TColumnIdsFundingSources.BALANCE,
      text: 'Balance',
      formatter: ({ row }) => (
        <div className="flex items-center justify-between">
          <CurrencyFormatter
            classes={{ value: 'inline-regular text-dark-60' }}
            value={row.balance}
          />
        </div>
      ),
      headerClasses: 'min-w-[120px]',
    },
    {
      text: 'Actions',
      dataField: 'actions',
      formatter: ({ row }) => <ActionsCell row={row} />,
      headerStyle: {
        width: 50,
      },
    },
  ],
});

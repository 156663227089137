import React, { useState } from 'react';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { sortBy, uniqBy } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import pluralize from 'pluralize';
import { Badge, Icon, TagWithIcon } from 'stories';
import { CssVar } from '@/shared/config/cssVar';
import AnimateHeight from 'react-animate-height';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import CampaignEmailCard from '../EmailCampaign/CampaignEmailCard';
import { getFailedEmailMsgs } from '../utils/utils';

const collectRecipients = (emailMessages) =>
  uniqBy(
    emailMessages.map((em) => em.recipient),
    'id',
  );

const generateInvestmentObjectLink = (investmentObject) =>
  `/${pluralize(
    investmentObject?.objectType?.toLowerCase(),
  )}/${investmentObject?.slug}`;

const mapCWEEventTypeToText = (eventType) => {
  switch (eventType) {
    case 'verify':
      return 'COR Approval Notice';
    case 'start_approval':
      return 'COR Approval Required';
    case 'approve':
      return 'COR Approval Complete';
    default:
      return '';
  }
};

const mapEmailsToCampaignEmailCard = ({
  feedMessages,
  invitationEmails,
  resetPasswordEmails,
  constructionWorkflowEvents,
  emailBuilderCustomTemplates,
}) => {
  const mappedFeedMessages = feedMessages.map((fm) => ({
    id: fm.id,
    key: `feedMessage-${fm.id}`,
    createdAt: fm.createdAt,
    iconClass: 'category',
    color: 'violet',
    emailMessages: fm.emailMessages,
    title: 'Feed Notification',
    recipients: collectRecipients(fm.emailMessages),
    subject: fm.subject,
    type: 'grouped',
    emailText: fm.content,
    totalRecipientsCount: fm.emailMessages.length,
    linkedWithHref: `${generateInvestmentObjectLink(
      fm.feedMessageable,
    )}?feed_message_id=${fm.id}`,
    linkedWithTitle: 'Feed Message',
    investmentObjectHref: generateInvestmentObjectLink(fm.feedMessageable),
    investmentObjectTitle: fm.feedMessageable.name,
  }));

  const mappedInvitationEmails = invitationEmails.map((ie) => ({
    id: ie.id,
    key: `invitationEmail-${ie.id}`,
    createdAt: ie.createdAt,
    iconClass: 'role',
    color: 'green',
    emailMessages: [ie.emailMessage],
    title: 'Invitation Email',
    recipients: [ie.emailMessage.recipient],
    subject: ie.emailMessage.subject,
    type: 'personal',
  }));

  const mappedResetPasswordEmails = resetPasswordEmails.map((rpe) => ({
    id: rpe.id,
    key: `resetPasswordEmail-${rpe.id}`,
    createdAt: rpe.createdAt,
    iconClass: 'access',
    color: 'yellow',
    emailMessages: [rpe.emailMessage],
    title: 'Reset Password Email',
    recipients: [rpe.emailMessage.recipient],
    subject: rpe.emailMessage.subject,
    type: 'personal',
  }));

  const mappedConstructionWorkflowEvents = constructionWorkflowEvents.map(
    (cwe) => ({
      id: cwe.id,
      key: `constructionEmail-${cwe.id}`,
      createdAt: cwe.createdAt,
      iconClass: 'documentAlt',
      color: 'light',
      emailMessages: cwe.emailMessages,
      title: mapCWEEventTypeToText(cwe.eventType),
      recipients: collectRecipients(cwe.emailMessages),
      subject: cwe.subject,
      type: 'grouped',
      linkedWithHref: `${generateInvestmentObjectLink(
        cwe.asset,
      )}/construction/change-management/${cwe.targetable.id}`,
      linkedWithTitle: cwe.targetable.title,
      investmentObjectHref: generateInvestmentObjectLink(cwe.asset),
      investmentObjectTitle: cwe.asset.name,
    }),
  );

  const mappedEmailBuilderCustomTemplates = emailBuilderCustomTemplates.map(
    (cte) => ({
      id: cte.id,
      key: `customTemplateEmail-${cte.id}`,
      createdAt: cte.createdAt,
      iconClass: 'email',
      color: 'pumpkin',
      emailMessages: cte.emailMessages,
      title: 'Custom Email',
      recipients: collectRecipients(cte.emailMessages),
      subject: cte?.emailMessages[0]?.subject ?? '',
      type: 'grouped',
    }),
  );

  return sortBy(
    [
      ...mappedFeedMessages,
      ...mappedInvitationEmails,
      ...mappedResetPasswordEmails,
      ...mappedConstructionWorkflowEvents,
      ...mappedEmailBuilderCustomTemplates,
    ],
    'createdAt',
  );
};
export function TimelineDateCampaingsGroup({ timelineDate }) {
  const [isOpened, setIsOpened] = useState(true);
  const {
    date,
    feedMessages,
    invitationEmails,
    resetPasswordEmails,
    constructionWorkflowEvents,
    emailBuilderCustomTemplates,
  } = timelineDate;
  const campaingsAmountPerDate =
    (feedMessages?.length || 0) +
    (invitationEmails?.length || 0) +
    (resetPasswordEmails?.length || 0) +
    (constructionWorkflowEvents?.length || 0) +
    (emailBuilderCustomTemplates?.length || 0);
  const extractEmailMsgsFromInvitationOrResetPassEmails = (data) =>
    data?.length ? data.map(({ emailMessage }) => emailMessage) : [];
  const extractEmailMsgsFromNotInvitationOrResetPassEmails = (data) =>
    data?.length ? data.map(({ emailMessages }) => emailMessages).flat(1) : [];

  const [failedEmailMsgs] = useState(
    getFailedEmailMsgs([
      ...extractEmailMsgsFromNotInvitationOrResetPassEmails(feedMessages),
      ...extractEmailMsgsFromInvitationOrResetPassEmails(invitationEmails),
      ...extractEmailMsgsFromInvitationOrResetPassEmails(resetPasswordEmails),
      ...extractEmailMsgsFromNotInvitationOrResetPassEmails(
        constructionWorkflowEvents,
      ),
      ...extractEmailMsgsFromNotInvitationOrResetPassEmails(
        emailBuilderCustomTemplates,
      ),
    ]),
  );
  const failedEmailMsgsAmount = failedEmailMsgs.length;
  return (
    <div className="transition-03 rounded-[0.75rem] overflow-hidden bg-light-20 px-s">
      <div
        className="flex p-s cursor-pointer"
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className="flex flex-grow items-center gap-s">
          <Icon
            className="dark-60 transition-03"
            iconName="arrowRight"
            style={isOpened ? { transform: 'rotate(90deg)' } : {}}
          />
          <span
            className={cn(
              'transition-03 inline-semibold',
              isOpened ? 'dark-60' : 'light-90',
            )}
          >
            {formatUnixDate(Date.parse(date) / 1000, 'LL')}
          </span>
          <Badge
            backgroundColor={isOpened ? CssVar.dark60 : CssVar.light30}
            textColor={isOpened ? CssVar.light : CssVar.light90}
            classes={{ value: 'transition-03' }}
          >
            {campaingsAmountPerDate}
            {pluralize(' Campaigns', campaingsAmountPerDate)}
          </Badge>
        </div>
        {failedEmailMsgsAmount > 0 && (
          <TagWithIcon
            startIcon={<FireIcon priority="high" />}
            label={`${failedEmailMsgsAmount} ${pluralize(
              'email',
              failedEmailMsgsAmount,
            )} with an error`}
            className="rounded-[0.5rem] cursor-pointer"
            disabled
          />
        )}
      </div>
      <AnimateHeight duration={300} height={isOpened ? 'auto' : 0}>
        <div
          className={cn('flex flex-col gap', isOpened ? 'pb-s' : 'pb-0')}
        >
          {mapEmailsToCampaignEmailCard({
            feedMessages: feedMessages || [],
            invitationEmails: invitationEmails || [],
            resetPasswordEmails: resetPasswordEmails || [],
            constructionWorkflowEvents: constructionWorkflowEvents || [],
            emailBuilderCustomTemplates: emailBuilderCustomTemplates || [],
          }).map((item, idx, array) => (
            <CampaignEmailCard
              className={cn('rounded-0 pr-l', {
                'rounded-bottom-s': idx + 1 === array.length,
                'rounded-top-s': idx === 0,
              })}
              size="lg"
              title={item.title}
              feedMessageId={item?.id}
              feedMessageable={item?.feedMessageable}
              key={item?.key}
              date={formatUnixDate(item.createdAt, 'LL')}
              time={formatUnixDate(item.createdAt, 'hh:mm A')}
              subject={item?.subject}
              emailText={item?.content}
              recipients={item?.recipients || { users: [] }}
              totalRecipientsCount={item?.emailMessages?.length}
              emailMessages={item?.emailMessages || []}
              iconClass={item.iconClass}
              color={item.color}
              type={item.type}
              linkedWithHref={item.linkedWithHref}
              linkedWithTitle={item.linkedWithTitle}
              investmentObjectHref={item.investmentObjectHref}
              investmentObjectTitle={item.investmentObjectTitle}
              bgColorClassName="bg-white"
            />
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
}

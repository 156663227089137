import React from 'react';
import InputNumber, {
  InputNumberProps,
} from 'bundles/Shared/components/GroupForm/FormItems/InputNumber';

type Props = Omit<InputNumberProps, 'onValueChange' | 'leftIcon' | 'onBlur'> & {
  onValueChange?: (value: number | '' | undefined) => void;
  onBlur?: (value: number) => void;
};

function CurrencyInputNumber({ onValueChange, ...props }: Props) {
  return (
    <InputNumber
      decimalScale={2}
      size="m"
      thousandSeparator
      leftIcon="symbol-usd"
      placeholder="0"
      onValueChange={(e) =>
        onValueChange?.(
          e.value !== '' && e.value !== undefined ? e.floatValue : undefined,
        )
      }
      {...props}
    />
  );
}

export default CurrencyInputNumber;

import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import { convertToChatDate } from 'lib/amcharts/utils';
import * as am5 from '@amcharts/amcharts5';

export function useChartSeriesData(
  chartData: Record<string, string | number>[],
  keyListToRemove: string[] = [],
) {
  const keyListToRemoveWithDate = [...keyListToRemove, 'date'];

  return useMemo(() => {
    const keys = Object.keys(chartData[0]).filter(
      (k) => !keyListToRemoveWithDate.includes(k),
    );
    const res = keys.map((k) => ({
      name: capitalize(k),
      valueYField: k,
    }));
    return res;
  }, [chartData]);
}

export const colors = [
  'green',
  'primary',
  'pumpkin',
  'violet',
  'yellow',
  'yellow-dark-5',
  'red',
];

export const PIPELINE_CHART_COLOR_SET = (
  [
    '#003f5c',
    '#20617c',
    '#3f869b',
    '#60acb9',
    '#37ac94',
    '#1b976c',
    '#128141',
  ] as const
).map((color) => am5.color(color));

function formatChartsData(chartsData: Record<string, string | number>[] = []) {
  return chartsData.map((d) => ({
    ...d,
    date: convertToChatDate(d.date),
  }));
}

export function useFormattedChartData(
  chartData: Record<string, string | number>[],
) {
  return useMemo(() => formatChartsData(chartData), [chartData]);
}

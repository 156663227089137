import { Icon } from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import {
  IConnection,
  TConnectionReportType,
} from 'bundles/Shared/entities/connection/model';

export const ConnectionReportType = ({
  type,
}: {
  type: IConnection['reportType'];
}) => (
  <div className="flex items-center gap-tw-2">
    <Icon
      iconName="bag"
      className={cn(
        cn('text-neutral-150', {
          ['text-neutral-500']:
            type === TConnectionReportType.OPERATIONAL ||
            type === TConnectionReportType.COMBINED,
        }),
      )}
    />
    <Icon
      iconName="roundSumbolUSD"
      className={cn(
        cn('text-neutral-150', {
          ['text-neutral-500']:
            type === TConnectionReportType.FINANCIAL ||
            type === TConnectionReportType.COMBINED,
        }),
      )}
    />
    <Icon
      iconName="analys"
      className={cn(
        cn('text-neutral-150', {
          ['text-neutral-500']: type === TConnectionReportType.MARKET_DATA,
        }),
      )}
    />
    <Icon
      iconName="extension"
      className={cn(
        cn('text-neutral-150', {
          ['text-neutral-500']: type === TConnectionReportType.CRM,
        }),
      )}
    />
  </div>
);

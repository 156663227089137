import {
  ColumSizeWidgetState,
  DisplayedGroupsWidgetState,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/model';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button, Checkbox, Icon, ModalHeaderWithSubtitle } from '@/stories';
import { SidePanel } from '@/stories/Modals/Modal/Modal';
import { useMemo } from 'react';

type Group = {
  id: string;
  name: string;
  hide: boolean;
  allColDefIds: string[];
  children?: Omit<Group, 'children'>[];
};

type ColumSize = null | 'default' | 'compact';
export type ColumnSizeChangeHandler = (c: ColumSize) => void;

const WidgetStateColumnsSidePanel = ({
  onColumnSizeChange,
  onColumnStateChange,
  onClose,
  groups,
}: DialogProps & {
  groups: Group[];
  onColumnSizeChange: ColumnSizeChangeHandler;
  onColumnStateChange: (groupId: string, colId?: string) => void;
}) => {
  return (
    <SidePanel
      size="380"
      header={<ModalHeaderWithSubtitle title="Displayed columns" />}
      toggle={onClose}
      classes={{
        overlay: 'max-w-[380px]',
        body: 'flex flex-col gap-tw-4 !pb-[90px]',
      }}
    >
      <div className="flex flex-col gap-tw-2 rounded-[8px] bg-neutral-000 p-tw-4">
        <p className="secondary-semibold text-neutral-800">Column size</p>
        <p className="secondary-regular text-neutral-550">
          You can control the size of the columns of the widget
        </p>
        <div className="flex items-center gap-tw-2">
          <Button
            onClick={() => {
              onColumnSizeChange('compact');
            }}
            size="s"
            fluid
          >
            Compact
          </Button>
          <Button
            onClick={() => {
              onColumnSizeChange('default');
            }}
            size="s"
            fluid
          >
            Default
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-tw-4">
        {groups.map((g) => (
          <div
            key={g.id}
            className="flex flex-col rounded-[8px] bg-neutral-000 p-tw-2"
          >
            <label className="flex items-center gap-tw-1 p-tw-1 pr-tw-2">
              <Icon iconName="groupByFilled" />
              <p className="inline-semibold text-neutral-800">{g.name}</p>
              <div className="grow" />
              <Checkbox
                indeterminate={g.children?.some((i) => !i.hide)}
                checked={!g.hide && g.children?.every((c) => !c.hide)}
                onChange={() => onColumnStateChange(g.id)}
              />
            </label>
            <div className="flex flex-col gap-tw-0.5 p-tw-1 pr-tw-2">
              {g.children?.map((item, idx) => (
                <label key={item.id} className="flex items-center gap-tw-1">
                  <p className="inline-regular flex w-[12px] items-center justify-center text-neutral-400">
                    {idx + 1}
                  </p>
                  <p className="inline-regular text-neutral-700">{item.name}</p>
                  <div className="grow" />
                  <Checkbox
                    checked={!item.hide}
                    onChange={() => onColumnStateChange(g.id, item.id)}
                  />
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </SidePanel>
  );
};

type State = ColumSizeWidgetState & DisplayedGroupsWidgetState;

export const WidgetStateColumns = ({
  onColumnStateChange,
  isDataLoading,
  onColumnSizeChange,
  state,
}: {
  state: State;
  onColumnSizeChange: ColumnSizeChangeHandler;
  onColumnStateChange: (groupId: string, colId?: string) => void;
  isDataLoading?: boolean;
}) => {
  const { value: sidePanelOpen, toggle: toggleSidePanelOpen } = useBoolean();

  const noColumns = (state.displayedGroups?.length ?? 0) === 0;

  const columnsMeta = useMemo(() => {
    return {
      totalLength: (state.displayedGroups ?? []).flatMap(
        (g) => g.children ?? [],
      ).length,
      activeLength: (state.displayedGroups ?? []).flatMap(
        (g) => g.children?.filter((i) => !i.hide) ?? [],
      ).length,
    };
  }, [state.displayedGroups]);

  const buttonLabel = useMemo(() => {
    if (isDataLoading) {
      return 'Loading';
    }

    if (noColumns) {
      return 'No columns';
    }

    return (
      <>
        {columnsMeta.activeLength} of {columnsMeta.totalLength}
      </>
    );
  }, [isDataLoading, noColumns, columnsMeta]);

  return (
    <>
      <Button
        loading={isDataLoading}
        disabled={noColumns || isDataLoading}
        size="s"
        iconName="columnsAlt"
        onClick={toggleSidePanelOpen}
      >
        {buttonLabel}
      </Button>

      {sidePanelOpen && (
        <WidgetStateColumnsSidePanel
          onColumnSizeChange={onColumnSizeChange}
          onColumnStateChange={onColumnStateChange}
          onClose={toggleSidePanelOpen}
          groups={state.displayedGroups}
        />
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { useNavigate } from '@reach/router';
import { plainFetchReplyRecipients } from 'bundles/Settings/actions/customTemplates';
import TemplateFormHeader from './TemplateFormHeader';
import {
  createForgotPasswordTemplate,
  forgotPasswordTemplatePreviews,
  getForgotPasswordTemplate,
  getForgotPasswordTemplateVariables,
  IForgotPasswordTemplateParams,
  updateForgotPasswordTemplate,
} from '../../../actions/forgotPasswordTemplates';
import TemplatePreview from '../TemplatePreview';
import TemplateEditorContainer from './TemplateEditorContainer';
import ForgotPasswordTemplateForm from '../forms/ForgotPasswordTemplateForm';
import { initForgotPasswordTemplateVars } from '../utils/consts';
import type * as Type from './types';
import { canSaveTemplate } from '../utils/canSaveTemplate';
import { AnimationLoader } from 'stories/index';

interface Props {
  forgotPasswordTemplateId?: number;
}

const ForgotPasswordTemplateEdit = ({ forgotPasswordTemplateId }: Props) => {
  const navigate = useNavigate();
  const [template, setTemplate] =
    useState<Type.ForgotPasswordTemplateState | null>(null);
  const [variables, setVariables] = useState(initForgotPasswordTemplateVars);
  const [usedVariables, setUsedVariables] = useState(
    initForgotPasswordTemplateVars,
  );
  const [replyRecipients, setReplyRecipients] = useState<Type.ReplyRecipient[]>(
    [],
  );
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [templatePreview, setTemplatePreview] = useState();
  const [isDefault, setIsDefault] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedTemplate = useDebounce(template, 500);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const [templateJson, variablesJson, replyRecipientsJson] =
        await Promise.all([
          forgotPasswordTemplateId
            ? getForgotPasswordTemplate(forgotPasswordTemplateId)
            : null,
          getForgotPasswordTemplateVariables(),
          plainFetchReplyRecipients(),
        ]);

      setReplyRecipients(replyRecipientsJson);

      setTemplate({
        subject: templateJson?.subject,
        greeting: templateJson?.rawGreeting,
        body: templateJson?.rawBody,
        callToAction: templateJson?.rawCallToAction,
        additionalInfo: templateJson?.rawAdditionalInfo,
        replyRecipients: templateJson?.replyRecipients,
      });
      setUsedVariables({
        additionalInfo: templateJson?.additionalInfoVariables ?? [],
        body: templateJson?.bodyVariables ?? [],
        callToAction: templateJson?.callToActionVariables ?? [],
        greeting: templateJson?.greetingVariables ?? [],
      });
      setVariables({
        greeting: variablesJson.greeting,
        body: variablesJson.body,
        callToAction: variablesJson.call_to_action,
        additionalInfo: variablesJson.additional_info,
      });
      setIsDefault(Boolean(templateJson?.default));

      setIsLoading(false);
    };

    fetch();
  }, []);

  useEffect(() => {
    if (template === null) return;

    const fetchPreview = async () => {
      setIsPreviewLoading(true);

      const previewJson = await forgotPasswordTemplatePreviews({
        forgotPasswordTemplateId,
        template,
        usedVariables,
      });

      setTemplatePreview(previewJson.body);

      setIsPreviewLoading(false);
    };
    fetchPreview();
  }, [debouncedTemplate]);

  const handleSubmit = async () => {
    if (template === null) return;

    const params: IForgotPasswordTemplateParams = {
      template,
      forgotPasswordTemplateId,
      usedVariables,
    };
    const forgotPasswordTemplate = forgotPasswordTemplateId
      ? await updateForgotPasswordTemplate(params)
      : await createForgotPasswordTemplate(params);

    if (forgotPasswordTemplate.errors === undefined) {
      navigate(-1);
    }
  };

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (forgotPasswordTemplateId) {
      return debouncedTemplate !== null;
    }

    return true;
  };

  const saveButtonEnabled = canSaveTemplate({
    isLoading,
    template,
    initialFieldsToCheck: ['subject', 'greeting', 'callToAction'],
  });

  return (
    <TemplateEditorContainer
      header={
        <TemplateFormHeader
          title="Forgot Password"
          saveButtonEnabled={saveButtonEnabled}
          isDefault={isDefault}
          onSave={handleSubmit}
        />
      }
      form={
        <ForgotPasswordTemplateForm
          template={template ?? {}}
          variables={variables}
          replyRecipients={replyRecipients}
          usedVariables={usedVariables}
          onTemplateChange={(newTemplate) => setTemplate(newTemplate)}
          onUsedVariablesChange={(newVariables) =>
            setUsedVariables(newVariables)
          }
          isLoading={isLoading}
        />
      }
      preview={
        isPreviewReady() ? (
          <TemplatePreview
            subject={debouncedTemplate?.subject ?? 'Forgot Password'}
            templatePreview={templatePreview}
          />
        ) : (
          <AnimationLoader />
        )
      }
    />
  );
};

export default ForgotPasswordTemplateEdit;

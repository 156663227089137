import RolesFilter from 'bundles/UserManagement/components/table/filters/RolesFilter';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  ChangeEvent,
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AnimationLoader, Modal, SearchInput } from 'stories/index';
import SetFilter from 'bundles/REconcile/components/operational/table/SetFilter';
import Table from 'bundles/Shared/components/Table/Table';
import { filterSortUsers } from 'bundles/Shared/components/SharedWithModalUtils';
import {
  prepareSelectedFilters,
  resetFilter,
} from 'bundles/Shared/components/Table/filters/helpers';
import TableAppliedFilters from 'bundles/Shared/components/Table/filters/TableAppliedFilters';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { tagsColumnContent } from 'bundles/Shared/components/SharedWithTableColumns';
import {
  FetchPermissionedUsersMeta,
  FetchPermissionedUsersWithMetaResponse,
  LightUser,
  ViewPermissionsTableSettings,
} from './types';
import { mustFind } from 'lib/typeHelpers';
import { mapItemToOption } from '@/shared/lib/listHelpers';
import pluralize from 'pluralize';
import { fullNameColumn, roleNameColumn } from './columns';
import { tableHeaderStyle } from './consts';
import { FALLBACK_HEADER_TITLE } from './dumbJSX';

interface Props<U extends LightUser> extends DialogProps {
  fetchPermissionedUsersWithMeta: () => Promise<
    FetchPermissionedUsersWithMetaResponse<U>
  >;
  customColumns?: IColumn<U>[];
  headerTitle?: React.ReactNode;
  showViewsColumn?: boolean;
  modalProps?: Partial<ComponentProps<typeof Modal>>;
}

export function ViewPermissionedUsersModal<U extends LightUser>({
  onClose,
  headerTitle,
  customColumns = [],
  fetchPermissionedUsersWithMeta,
  modalProps,
}: Props<U>) {
  const [allUsers, setAllUsers] = useState<U[]>([]);
  const [meta, setMeta] = useState<FetchPermissionedUsersMeta>({
    tags: [],
    roles: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [sortSettings, setSortSettings] =
    useState<ViewPermissionsTableSettings>({
      filters: {
        tags: [],
        roles: [],
      },
    });
  const selectedFilters = sortSettings.filters;

  const setSelectedFilters = (
    filters: ViewPermissionsTableSettings['filters'],
  ) =>
    setSortSettings({
      ...sortSettings,
      filters: {
        ...selectedFilters,
        ...filters,
      },
    });

  const columns = useMemo<IColumn<U>[]>(
    () => [
      fullNameColumn,
      {
        ...roleNameColumn,
        filter: (settings, setSettings) => (
          <RolesFilter
            roles={meta.roles}
            settings={settings}
            setSettings={setSettings}
          />
        ),
      },
      {
        dataField: 'tags',
        text: 'Tags',
        headerStyle: tableHeaderStyle,
        formatter: ({ row }) => tagsColumnContent(row),
        quickFilter: (
          <SetFilter
            placement="bottom-start"
            appendTo={() => document.body}
            items={meta.tags.map((item) => mapItemToOption(item, 'name'))}
            label="Tags"
            value={(selectedFilters.tags ?? [])
              .map((settingsTag) =>
                mustFind(meta.tags, (tag) => tag.id === settingsTag.id),
              )
              .map((item) => item && mapItemToOption(item, 'name'))}
            onChange={(items) =>
              setSelectedFilters({
                tags: items,
              })
            }
          />
        ),
      },
      ...customColumns,
    ],
    [sortSettings, meta],
  );

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const localResetFilter = (
    key: Parameters<typeof resetFilter>[2],
    id: Parameters<typeof resetFilter>[3],
  ) => resetFilter(setSelectedFilters, selectedFilters, key, id);

  const filteredSortedUsers = useMemo(
    () => filterSortUsers(allUsers, searchValue, sortSettings),
    [sortSettings, debouncedSearchValue, allUsers],
  );

  useEffect(() => {
    const asyncFetch = async () => {
      try {
        setIsLoading(true);
        const res = await fetchPermissionedUsersWithMeta();
        setAllUsers(res.users);
        setMeta(res.meta);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    asyncFetch();
  }, []);

  return (
    <Modal
      header={
        <div className="flex items-center justify-between pr-l">
          {headerTitle ?? FALLBACK_HEADER_TITLE}
        </div>
      }
      size="900"
      toggle={onClose}
      classes={{ body: 'bg-light relative', headerContent: 'flex-grow' }}
      {...modalProps}
    >
      <div>
        {isLoading && <AnimationLoader className="static min-h-[320px]" />}
        {!isLoading && (
          <>
            <SearchInput
              value={searchValue}
              onChange={onSearch}
              placeholder="Search by name"
              resetValue={() => setSearchValue('')}
            />
            <TableAppliedFilters
              className="mt-tw-2 flex flex-col gap-tw-2"
              filters={prepareSelectedFilters(selectedFilters)}
              resetFilter={localResetFilter}
            />
            <Table
              columns={columns}
              items={filteredSortedUsers}
              settings={sortSettings}
              setSettings={setSortSettings}
              borderLessOutside
              classes={{ container: 'mt-tw-4' }}
              emptyDocumentsLabel="No such users"
              emptyDocumentsSubLabel={`Out of ${allUsers.length} ${pluralize(
                'user',
                allUsers.length,
              )}, no users were found with the current filter parameters`}
            />
          </>
        )}
      </div>
    </Modal>
  );
}

import ReportSettingsScreenLayout from '@/bundles/Settings/shared/ReportSettingsScreenLayout';
import { isThresholdSpecified } from '@/entities/report/thresholds/lib';
import { Threshold } from '@/entities/report/thresholds/model';
import { ThresholdGlobalValue } from '@/entities/report/thresholds/ui/ThresholdGlobalValue';
import { TitledCard } from '@/stories/TitledCard/TitledCard';
import { NoThreshold } from '@/widgets/report/thresholds/ui/NoThreshold';
import { ThresholdsActionsDropdown } from '@/widgets/report/thresholds/ui/ThresholdsActionsDropdown';

const SettingsReportThresholdsThreshold = ({
  threshold,
}: {
  threshold: Threshold;
}) => {
  return (
    <ReportSettingsScreenLayout title={threshold.title} subTitle="Thresholds">
      <div className="flex grow p-tw-6">
        {!isThresholdSpecified(threshold) && (
          <NoThreshold threshold={threshold} />
        )}
        {isThresholdSpecified(threshold) && (
          <TitledCard
            classes={{
              children: 'py-tw-4',
            }}
            className="relative min-w-[300px] self-start justify-self-start"
            title="Threshold"
          >
            <ThresholdsActionsDropdown threshold={threshold} />
            <ThresholdGlobalValue
              value={threshold.globalValue}
              valueType={threshold.valueType}
              classes={{
                allParts: 'header5-regular',
                value: 'text-neutral-850',
              }}
            />
          </TitledCard>
        )}
      </div>
    </ReportSettingsScreenLayout>
  );
};

export default SettingsReportThresholdsThreshold;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'lib/http';
import { camelCase } from 'lodash-es';

export type TGetSnapshotSettingsResponse = {
  displayProjected: boolean;
  displayInternal: boolean;
};

export interface IUpdateSnapshotSettingsRequest {
  display_projected: boolean;
  display_internal: boolean;
}

export interface ISnapshotSettingsRequestParams {
  legalEntityId: string;
}

export const reconcileDevelopmentSnapshotSettingsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/settings/reconcile/legal_entities/`,
  }),
  reducerPath: 'reconcileDevelopmentSnapshotSettings',
  endpoints: (build) => ({
    getSnapshotSettings: build.query<
      TGetSnapshotSettingsResponse,
      ISnapshotSettingsRequestParams
    >({
      query({ legalEntityId }) {
        return `${legalEntityId}/snapshot`;
      },
    }),
    updateSnapshotSettings: build.mutation<
      TGetSnapshotSettingsResponse,
      IUpdateSnapshotSettingsRequest & ISnapshotSettingsRequestParams
    >({
      query: ({ legalEntityId, ...body }) => ({
        url: `${legalEntityId}/snapshot`,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      async onQueryStarted(
        { legalEntityId, ...patch },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          reconcileDevelopmentSnapshotSettingsApi.util.updateQueryData(
            'getSnapshotSettings',
            { legalEntityId },
            (draft) => {
              Object.assign(
                draft,
                Object.fromEntries(
                  Object.entries(patch).map(([key, value]) => [
                    camelCase(key),
                    value,
                  ]),
                ),
              );
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetSnapshotSettingsQuery,
  useUpdateSnapshotSettingsMutation,
} = reconcileDevelopmentSnapshotSettingsApi;

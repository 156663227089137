import {
  ICapitalTransactionSource,
  TReturnInvestmentTransactionDirection,
} from 'bundles/REturn/types';
import http from 'lib/http';

export interface ICreateCapitalTransactionSourceRequest {
  kind: `${TReturnInvestmentTransactionDirection}`;
  investment_object_id: number;
  investment_object_type: 'asset' | 'fund';
  data: {
    legal_entity_id: string;
    general_ledger_id: number;
  }[];
}

export const createCapitalTransactionSource = async (
  data: ICreateCapitalTransactionSourceRequest,
  enableToastr = true,
) => {
  const res = await http.post('/capital/transaction_sources', data);

  if (res.status === 200) {
    if (enableToastr) {
      toastr.success(`REturn transaction sources successfully updated`);
    }
  } else {
    toastr.error('Something went wrong');
    return undefined;
  }
  const json = await res.json();

  return json as ICapitalTransactionSource[];
};

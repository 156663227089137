import React from 'react';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';

export function WidgetErrorBoundary({ title }: { title: string }) {
  return (
    <DashboardWidgetCard className={'h-full'}>
      <DashboardWidgetCard.Header>
        <span className="header5-bold text-neutral-850">{title}</span>
      </DashboardWidgetCard.Header>
      <div className="flex grow items-center justify-center p-tw-6">
        Something went wrong. Please try change configuration.
      </div>
    </DashboardWidgetCard>
  );
}

import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import {
  getOptionsValues,
  includesInLowerCase,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';
import {
  DashboardFilterObject,
  DashboardFilterObjectTypes,
  ReportDashboardAsset,
} from 'bundles/Shared/entities/dashboard';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { SearchInput } from '@/stories';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { ShownInfoLine } from 'bundles/Shared/components/Table/pagination/TablePagination';
import CheckList from 'stories/Checkbox/CheckList';
import { FilterByLeClassification } from '@/features/leClassification/filter';

type Props = DialogProps & {
  onChange: (objectIds: ReportDashboardAsset['id'][]) => void;
  objectType: DashboardFilterObjectTypes;
  objects: DashboardFilterObject[];
  defaultObjectIds?: ReportDashboardAsset['id'][];
  objectIds?: ReportDashboardAsset['id'][];
};

export function ObjectSelectionModal({
  onClose,
  objectIds = [],
  defaultObjectIds,
  onChange,
  objectType,
  objects,
}: Props) {
  const [internalAssetIds, setInternalCheckedIds] = useState(defaultObjectIds);
  const [leClassifications, setLeClassifications] = useState<
    LeClassification[]
  >([]);
  const items = useMemo(
    () =>
      objects.map((object) => ({
        id: object.id,
        label: object.name,
        leClassification: object.leClassification,
      })),
    [objects],
  );

  const filterBy = useMemo(
    () =>
      (
        item: {
          id: number;
          label: string;
          leClassification: LeClassification[];
        },
        searchText: string,
      ) => {
        const byName = includesInLowerCase(item.label, searchText);
        const byLeClassification = leClassifications.length
          ? item.leClassification.some((lc) => leClassifications.includes(lc))
          : true;
        return byName && byLeClassification;
      },
    [leClassifications],
  );

  const { filteredItems, inputProps } = useItemsFilterByText(items, filterBy, {
    skipWhenTextIsEmpty: false,
  });

  const handleCheckedItemsChanged = (newIds: number[]) => {
    if (defaultObjectIds) {
      setInternalCheckedIds(newIds);
    }
    onChange(newIds);
  };

  const finalObjectIds = internalAssetIds ?? objectIds ?? [];
  const finalSetObjectIds = defaultObjectIds
    ? handleCheckedItemsChanged
    : onChange;

  const checkedItems =
    objects.length === 0
      ? []
      : finalObjectIds
          .map((id) => items.find((o) => o.id === id)!)
          .filter(Boolean);

  return (
    <SidePanel
      closeOnOutsideClick
      header={pluralize(capitalize(objectType))}
      toggle={onClose}
      classes={{
        body: 'flex flex-col gap-tw-4 bg-neutral-050',
      }}
    >
      <SearchInput placeholder="Search by name" {...inputProps} />
      <div className="flex items-center justify-between">
        <ShownInfoLine size="m" totalSize={filteredItems.length} name="Items" />
        <FilterByLeClassification
          value={leClassifications}
          onChange={setLeClassifications}
        />
      </div>
      <CheckList
        selectAll
        selectAllLabel="All"
        className="rounded-[8px] bg-neutral-000 p-tw-4 shadow-z-080"
        items={mapItemsToListOption(filteredItems, 'label')}
        value={mapItemsToListOption(checkedItems, 'label')}
        onChange={(items) => finalSetObjectIds(getOptionsValues(items))}
      />
    </SidePanel>
  );
}

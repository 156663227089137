import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';

import styles from './StatisticBulletItem.module.scss';

interface Props {
  backgroundColor: string;
  label: string;
  value: string;
  className?: string;
  classes?: {
    label?: string;
    value?: string;
  };
}

export function StatisticBulletItem({
  backgroundColor,
  label,
  value,
  className,
  classes,
}: Props) {
  return (
    <div
      className={cn(
        'flex justify-between items-center',
        className,
      )}
    >
      <div className="flex items-center">
        <div
          className={cn(styles.stateBullet, 'ml-xs mr-s')}
          style={{
            backgroundColor,
          }}
        />
        <span className={cn('secondary-regular', styles.label, classes?.label)}>
          {label}
        </span>
      </div>
      <span className={cn('secondary-regular', styles.value, classes?.value)}>
        {value}
      </span>
    </div>
  );
}

export default StatisticBulletItem;

import { cn } from '@/shared/lib/css/cn';
import React, { ComponentProps } from 'react';
import { Icon, Tooltip } from 'stories';
import { IconsId } from 'types/sre-icons';
import './iconButton.css';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  iconName: IconsId;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  variant?: 'default' | 'primary' | 'secondary' | 'white' | 'dark' | 'ghost';
  size?: 'xs' | 's' | 'm' | 'l';
  disabled?: boolean;
  offHoverStyles?: boolean;
  classes?: {
    icon?: string;
  };
  isActive?: boolean;
  isLoading?: boolean;
}

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      size = 'm',
      variant = 'secondary',
      className,
      iconName,
      tooltipProps = {
        disabled: true,
      },
      disabled,
      offHoverStyles,
      onClick,
      classes,
      children,
      isActive,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const rawButton = (
      <button
        ref={ref}
        type="button"
        className={cn(
          cn(
            'sre-icon-button',
            'outline-3 !outline-info-055 focus-within:outline',
            `sre-icon-button_${variant}`,
            {
              [`sre-icon-button_size-${size}`]: size !== 'm',
              'min-w-tw-6': size === 'm',
              '_off-hover-styles': offHoverStyles,
              rounded: size === 'xs',
              'border-[1px] border-solid border-neutral-100/20 bg-transparent text-neutral-100 hover:!text-neutral-200':
                variant === 'ghost',
            },
            variant === 'secondary' &&
              'border-1 border-solid border-neutral-100 bg-neutral-000 text-neutral-450 shadow-z-020',
            variant === 'secondary' &&
              !offHoverStyles &&
              'hover:border-neutral-200 hover:bg-neutral-100',
            variant === 'secondary' &&
              isActive &&
              'border-neutral-999 bg-neutral-999 text-neutral-000',
            className,
          ),
        )}
        disabled={isLoading || disabled}
        onClick={onClick}
        {...props}
      >
        {children ?? (
          <Icon
            className={cn(classes?.icon, isLoading && 'animate-spin')}
            iconName={isLoading ? 'loader' : iconName}
          />
        )}
      </button>
    );

    if (tooltipProps.disabled) return rawButton;

    return <Tooltip {...tooltipProps}>{rawButton}</Tooltip>;
  },
);

export default IconButton;

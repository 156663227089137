interface ICustomer {
  emailLogoUrl: string;
  logoUrl: string;
  initials: {
    label: string;
    color: string;
  };
  name: string;
  products: string[];
  subdomain: string;
}

export const useCustomer = (): ICustomer => window.symmetreConfig?.customer;

import React from 'react';
import { IUserRole } from 'types/User';
import { IPageParams } from 'bundles/UserManagement/components/UserTracker/UserTracker';
import { RolesFilterList } from 'bundles/Shared/features/user/filter/byRole';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import Popover from 'stories/Popover/Popover';

interface Props {
  roles: Pick<IUserRole, 'id' | 'name'>[];
  settings: IPageParams;
  setSettings: (settings: IPageParams) => void;
}

function RolesFilter({ roles, settings, setSettings }: Props) {
  return (
    <Popover
      appendToBody
      trigger="click"
      placement="bottom-start"
      maxWidth="fit-content"
      className="p-m"
      classes={{
        spanContainer: 'ml-s',
      }}
      hiddenArrow
      offset={[0, 2]}
      template={
        <RolesFilterList
          columns
          records={roles}
          selectedIds={settings.filters.roles.map((r) => r.id)}
          onChange={(newRoles) =>
            setSettings({
              ...settings,
              filters: {
                ...settings.filters,
                roles: newRoles,
              },
            })
          }
        />
      }
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={settings.filters.roles.length > 0}
          onClose={() =>
            setSettings({
              ...settings,
              filters: {
                ...settings.filters,
                roles: [],
              },
            })
          }
        />
      )}
    </Popover>
  );
}

export default RolesFilter;

import React from 'react';
import { CAPITAL_INVESTMENT_CLASS_COLOR_MAP } from 'bundles/REturn/components/Ownership/modals/consts';
import { capitalize } from 'lodash-es';
import { Label } from '@/stories';
import { CapitalInvestmentClass } from '@/entities/return';

interface Props
  extends Omit<React.ComponentProps<typeof Label>, 'color' | 'children'> {
  investmentClass: CapitalInvestmentClass;
  withClassPrefix?: boolean;
}

export function InvestmentClassLabel({
  investmentClass,
  withClassPrefix,
  ...props
}: Props) {
  return (
    <Label
      outline
      size="s"
      className="secondary-regular"
      color={CAPITAL_INVESTMENT_CLASS_COLOR_MAP[investmentClass]}
      {...props}
    >
      {withClassPrefix && 'Class '}
      {capitalize(investmentClass)}
    </Label>
  );
}

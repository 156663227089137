import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { TitledCard } from 'stories/TitledCard/TitledCard';
import { PropsWithChildren } from 'react';
import { AnimationLoader, Icon, IconButton } from 'stories/index';

export function CapitalInvestmentCard({
  title,
  fireEnabled,
  onSettings,
  children,
  isLoading = false,
  isDisabled = false,
}: {
  title: string;
  fireEnabled?: boolean;
  onSettings?: VoidFunction;
  isLoading?: boolean;
  isDisabled?: boolean;
} & PropsWithChildren) {
  const resolveTemplate = () => {
    switch (true) {
      case isDisabled:
        return (
          <div className="flex flex-col items-center gap-tw-0.5">
            <Icon iconName="lock" className="text-[22px]" />
            <p className="label-semibold text-neutral-500">Disabled</p>
          </div>
        );
      case isLoading:
        return <AnimationLoader className="mt-tw-3" />;
      default:
        return (
          <>
            {onSettings && (
              <IconButton
                onClick={onSettings}
                iconName="settings"
                className="absolute right-tw-4 top-tw-2"
                variant="secondary"
              />
            )}
            {fireEnabled && (
              <FireIcon
                priority="high"
                className="absolute left-tw-4 top-tw-2"
              />
            )}
            {children}
          </>
        );
    }
  };

  return (
    <TitledCard
      title={title}
      className="relative h-[100px] w-full"
      classes={{ children: 'justify-evenly' }}
    >
      {resolveTemplate()}
    </TitledCard>
  );
}

import React from 'react';
import styles from './commentBadge.module.scss';
import { cn } from '@/shared/lib/css/cn';

const Container = ({
  children,
  onClick,
}: React.PropsWithChildren<{ onClick?: () => void }>) => (
  <div onClick={onClick} className={styles.container}>
    {children}
  </div>
);

export const AddCommentBadge = ({ onClick }) => (
  <Container onClick={onClick}>
    <div className={styles.small_comment}>
      <div className={styles.small_comment_inner} />
      <div className={styles.circle}>
        <div className={styles.pluse}>
          <div className={styles.fragment_vertical}>
            <div className={styles.fragment_horizontal} />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export const AvatarCommentBadge = ({
  text,
  onClick,
}: {
  text?: string;
  onClick: () => void;
}) => (
  <Container onClick={onClick}>
    <div className={cn(styles.green_comment)}>
      <div className={cn(styles.green_comment_inner, 'bg-success-050')}>
        <div className={styles.value}>{text}</div>
      </div>
    </div>
  </Container>
);

export const CommentBadge = () => (
  <Container>
    <div className={styles.small_comment}>
      <div className={styles.small_comment_inner} />
      <div className={styles.circle}>
        <div className={styles.inner_circle} />
      </div>
    </div>
  </Container>
);

import { OBJECT_STATUSES_OPTIONS as OBJECT_STATUSES_LABELS_WITH_IDS } from '@/lib/asset';
import { DropdownWithCheckboxes } from '@/pages/assets/page';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useDebouncedQuery } from '@/shared/lib/hooks/useDebouncedQuery';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import {
  allSubPathMatches,
  generateUrl,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import {
  getOptionsValues,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';
import { LinkWrapper } from '@/shared/ui/LinkWrapper';
import { RouteComponentProps, useMatch } from '@reach/router';
import {
  useGetFundsQuery,
  useHeavyUpdateFundWithInvalidationMutation,
} from 'bundles/Settings/components/Portal/FundDashboard/api/coreFundsApi';
import { ALL_STATUS_OPTIONS_MAP } from 'bundles/Settings/components/Portal/shared/consts';
import { useGenerateUrlForCard } from 'bundles/Settings/components/Portal/shared/hooks/useGenerateUrlForCard';
import { useGoToFirstObjectFromDataEffect } from 'bundles/Settings/components/Portal/shared/hooks/useGoToFirstObjectFromDataEffect';
import ExternalUsersIcon from 'bundles/Settings/components/Portal/shared/ui/ExternalUsersIcon';
import { LegalEntitiablesThinTabGroup } from 'bundles/Settings/components/Portal/shared/ui/LegalEntitiablesThinTabGroup';
import ObjectCardSkeletonBlock from 'bundles/Settings/components/Portal/shared/ui/ObjectCardSkeletonBlock';
import StatusDropdown from 'bundles/Settings/components/Portal/shared/ui/StatusDropdown';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { useMenuItemsPermissions } from 'bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import { filterByExternal } from 'bundles/Shared/entities/user/lib';
import {
  currentUserAllowedTo,
  currentUserIsExternal,
  TProductNames,
} from 'lib/permissions';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { ComponentProps, FC, PropsWithChildren, useState } from 'react';
import { ListOption } from 'stories/Checkbox/CheckList';
import { Icon, IconButton, SearchInput, Tooltip } from 'stories/index';
import FavoriteCardIcon from 'stories/ProjectCard/FavoriteCardIcon';
import ProjectCard from 'stories/ProjectCard/ProjectCard';
import { IFund } from 'types/Fund';

const prepareUrl = (fundSlug: IFund['slug']) =>
  generateUrl(ROUTES_ROOT.funds.fund.fullPath, {
    pathParams: {
      fundSlug,
    },
  });

export const FUND_DASHBOARD_PAGE_TABS_MAP = [
  ['overview', { to: ROUTES_ROOT.funds.fund.fullPath }],
  ['documents', { to: ROUTES_ROOT.funds.fund.documents.fullPath }],
] as const;

const FundDashboardPage: FC<RouteComponentProps & PropsWithChildren> = ({
  children,
}) => {
  const objectStatuses = OBJECT_STATUSES_LABELS_WITH_IDS.filter(
    (st) => !currentUserIsExternal() || st.label !== 'Draft',
  );
  const dashboardExpanded = useAppSelector(
    (state) => state.coreEntity.dashboardExpanded,
  );
  const { query, debouncedQuery, handleChange, resetQuery } =
    useDebouncedQuery('');
  const menuItemsPermissions = useMenuItemsPermissions();
  const [statuses, setStatuses] = useState<string[]>([]);

  const [heavyUpdateWithInvalidate] =
    useHeavyUpdateFundWithInvalidationMutation();
  const generateUrlForCard = useGenerateUrlForCard(
    ROUTES_ROOT.funds.fund.fullPath,
  );

  const match = useMatch(allSubPathMatches(ROUTES_ROOT.funds.fund.fullPath));
  const slugFromQuery = match?.fundSlug;
  const { isItemFavorite, toggleItemFavorite, favouriteItems } =
    useFavoriteItemIds('coreFundsFavoriteIds');

  const { data: fundsData, isFetching } = useGetFundsQuery({
    search_by_query: debouncedQuery,
    page: 1,
    favorite_ids: favouriteItems,
    statuses,
  });
  const funds = fundsData?.funds ?? [];
  const totalSize = fundsData?.meta.totalSize ?? null;

  useGoToFirstObjectFromDataEffect(
    {
      objects: funds,
      slugFromQuery,
      prepareUrl,
    },
    [fundsData],
  );

  const navHeaderProps: ComponentProps<typeof ListNavLayout.NavigationHeader> =
    menuItemsPermissions.fundsVisible
      ? {
          title: 'coRE',
        }
      : {
          title: 'Funds',
          subtitle: 'coRE',
        };

  const handleCheckStatus = (options: ListOption<string>[]) => {
    setStatuses(getOptionsValues(options));
  };

  const isCurrentUserExternal = currentUserIsExternal();

  return (
    <ListNavLayout className={cn(dashboardExpanded && 'flex h-[initial]')}>
      <ListNavLayout.Navigation
        className={cn(
          'next-div-w-full relative',
          dashboardExpanded && 'hidden',
        )}
      >
        <ListNavLayout.NavigationHeaderGroup>
          <ListNavLayout.NavigationHeader {...navHeaderProps} />
        </ListNavLayout.NavigationHeaderGroup>
        {menuItemsPermissions.fundsVisible && (
          <LegalEntitiablesThinTabGroup selectedItemId="funds" />
        )}

        <div className="grid grid-cols-[1fr_1fr] items-center gap-tw-2">
          <div className="flex">
            {!isCurrentUserExternal && (
              <DropdownWithCheckboxes
                label="Statuses"
                disabled={isFetching}
                items={objectStatuses}
                value={mapItemsToListOption(statuses)}
                onChange={handleCheckStatus}
              />
            )}
          </div>
          <SearchInput
            size="s"
            placeholder="Search"
            suggestions={['Fund name']}
            value={query}
            onChange={handleChange}
            className="ml-auto w-full"
            resetValue={resetQuery}
          />
        </div>

        {!isFetching && (
          <TablePagination
            currentPage={1}
            totalSize={totalSize ?? 0}
            sizePerPage={totalSize ?? 0}
            loading={isFetching}
          />
        )}

        {!isFetching && funds && funds.length === 0 && (
          <NoDataOverlay title="Nothing Found" />
        )}

        {isFetching && <ObjectCardSkeletonBlock />}

        {!isFetching && funds && (
          <ListNavLayout.NavigationCardList>
            {funds?.map((fund) => {
              const externalUsers = filterByExternal(fund.users ?? []);
              const statusOption = ALL_STATUS_OPTIONS_MAP[fund.aasmState];
              const isUserAllowedToManageFund = currentUserAllowedTo(
                'manage',
                TProductNames.FUND_PORTAL,
                'Fund',
                fund.id,
              );
              const isUserAllowedToViewFund = currentUserAllowedTo(
                'view',
                TProductNames.FUND_PORTAL,
                'Fund',
                fund.id,
              );

              const isUserAllowedToViewFundStatus =
                !isCurrentUserExternal &&
                !isUserAllowedToManageFund &&
                isUserAllowedToViewFund;

              return (
                <LinkWrapper
                  to={generateUrlForCard(fund.slug, ':fundSlug')}
                  className="grid"
                  key={fund.id}
                >
                  <ProjectCard
                    imagePlaceholder=""
                    fallbackNode={
                      <Icon
                        iconName="funds"
                        className="header1-regular text-neutral-550"
                      />
                    }
                    selected={fund.slug === slugFromQuery}
                    pictureUrl={fund.smallPictureUrl}
                    header={
                      <div className="flex items-center justify-between gap-tw-2">
                        <p className="body-semibold text-neutral-800">
                          {fund.name}
                        </p>
                        <div className="flex items-center gap-tw-2">
                          {externalUsers.length > 0 && (
                            <ExternalUsersIcon
                              externalUsersLen={externalUsers.length}
                            />
                          )}
                          {fund.assets != null && fund.assets.length > 0 && (
                            <Tooltip
                              arrowPosition="start"
                              placement="right-start"
                              mainText={
                                <div className="flex flex-col gap-tw-1">
                                  <span className="leading-1 text-[12px] font-normal">
                                    {pluralize('Asset', fund.assets.length)}
                                  </span>
                                  <span>
                                    {fund.assets.map((a) => a.name).join(', ')}
                                  </span>
                                </div>
                              }
                            >
                              <div className="flex items-center gap-tw-1 text-neutral-450">
                                <Icon iconName="asset" />
                                <p className="secondary-regular">
                                  {fund.assets.length}
                                </p>
                              </div>
                            </Tooltip>
                          )}

                          {isUserAllowedToManageFund && (
                            <StatusDropdown
                              object={fund}
                              heavyUpdate={heavyUpdateWithInvalidate}
                              status={fund.aasmState}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                iconName={statusOption.iconName}
                                classes={{ icon: statusOption.className }}
                                variant="secondary"
                              />
                            </StatusDropdown>
                          )}
                          {isUserAllowedToViewFundStatus && (
                            <IconButton
                              tooltipProps={{
                                mainText: capitalize(statusOption.value),
                              }}
                              iconName={statusOption.iconName}
                              className="!cursor-default"
                              classes={{ icon: statusOption.className }}
                              variant="secondary"
                            />
                          )}
                          <FavoriteCardIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              toggleItemFavorite(fund.id);
                            }}
                            selected={isItemFavorite(fund.id)}
                          />
                        </div>
                      </div>
                    }
                  />
                </LinkWrapper>
              );
            })}
          </ListNavLayout.NavigationCardList>
        )}
      </ListNavLayout.Navigation>
      {children}
    </ListNavLayout>
  );
};

export default FundDashboardPage;

import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  Button,
  Icon,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from 'stories/index';
import {
  ImportBudgetItemInfo,
  ImportBudgetResponse,
} from '../api/legalEntities';
import FileExtensionIcon from 'bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import AnimateHeight from 'react-animate-height';
import { useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { resolveIconProps } from '../utils';

interface Props extends DialogProps<'success'> {
  importResponse: ImportBudgetResponse;
}

const ROW_NUM_SUB_STR = '(row ';

const getRowNum = (msg: string) => {
  const idx = msg.indexOf(ROW_NUM_SUB_STR);
  return msg.slice(idx + 5, msg.length - 1);
};

const cutRowFromMsg = (msg: string) => {
  const idx = msg.indexOf(ROW_NUM_SUB_STR);
  return msg.slice(0, idx);
};

function RowMsg(props: { msg: string; type: 'warnings' | 'errors' }) {
  return (
    <p
      className={cn(
        'secondary-regular text-attention-100',
        props.type === 'warnings' && 'text-attention-100',
        props.type === 'errors' && 'text-danger-070',
      )}
    >
      <span>Row: {getRowNum(props.msg)}</span> {cutRowFromMsg(props.msg)}
    </p>
  );
}

const MIN_INFO_LEN = 2;
const MIN_MSG_LIST_LEN_FOR_SECOND_COLLAPSE_BTN = 64;

function TextButton({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) {
  return (
    <button
      className={cn(
        'secondary-regular cursor-pointer border-none bg-transparent p-0 text-info-055',
        className,
      )}
      {...props}
    >
      {props.children}
    </button>
  );
}

function FileInfoMessages({
  msgList,
  type,
}: {
  msgList: string[];
  type: 'warnings' | 'errors';
}) {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => setExpanded((prev) => !prev);

  const warningLen = msgList.length;
  const isMoreThanMinInfoLen = warningLen > MIN_INFO_LEN;
  const minMsgList = msgList.slice(0, MIN_INFO_LEN);
  const restMsgList = msgList.slice(MIN_INFO_LEN);
  const largeMsgListLen =
    restMsgList.length > MIN_MSG_LIST_LEN_FOR_SECOND_COLLAPSE_BTN;

  return (
    <>
      {minMsgList.map((msg) => (
        <RowMsg type={type} key={msg} msg={msg} />
      ))}
      {isMoreThanMinInfoLen && (
        <>
          {!expanded && (
            <span className="secondary-regular text-neutral-850">
              and {warningLen} more{' '}
            </span>
          )}
          {!expanded && <TextButton onClick={toggle}>expand</TextButton>}
          <div className="relative flex flex-col gap-tw-0.5">
            <AnimateHeight duration={400} height={expanded ? 'auto' : 0}>
              {expanded && largeMsgListLen && (
                <TextButton onClick={toggle} className="absolute right-0 top-0">
                  collapse
                </TextButton>
              )}
              {restMsgList.map((msg) => (
                <RowMsg type={type} key={msg} msg={msg} />
              ))}
            </AnimateHeight>
          </div>
          {expanded && <TextButton onClick={toggle}>collapse</TextButton>}
        </>
      )}
    </>
  );
}

function FileInfo({ name, obj }: { name: string; obj: ImportBudgetItemInfo }) {
  const ignoredMsg =
    obj.warnings.length === 0 &&
    obj.errors.length === 0 &&
    obj.status === 'ignored';

  return (
    <div className="grid grid-cols-[1fr_56px] gap overflow-hidden rounded-lg">
      <div className="flex w-full gap-tw-2.5 bg-neutral-050 p-tw-4">
        <FileExtensionIcon className="bg-neutral-000" extension="xlsx" />
        <div className="w-full">
          <p className="inline-semibold text-neutral-850">{name}.xlsx</p>

          <FileInfoMessages msgList={obj.warnings} type="warnings" />
          <FileInfoMessages msgList={obj.errors} type="errors" />

          {ignoredMsg && (
            <p className="secondary-regular text-attention-100">
              This budget data appears to be wrong
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-center gap-tw-2 bg-neutral-050 p-tw-4">
        <Icon {...resolveIconProps(obj.status)} />
      </div>
    </div>
  );
}

export function ImportBudgetResultModal({
  importResponse,
  onClose,
  onSubmit,
}: Props) {
  const entries = Object.entries(importResponse);

  const notSuccessful = entries.some(([, obj]) => obj.status !== 'success');

  const actions = (
    <ModalActions>
      <Button onClick={onClose} variant="secondary">
        Cancel
      </Button>
      <Button onClick={onClose} variant="success">
        Try again
      </Button>
    </ModalActions>
  );

  return (
    <Modal
      classes={{ body: '!bg-neutral-150 flex flex-col gap-tw-4' }}
      size="700"
      toggle={onClose}
      header={<ModalHeaderWithSubtitle title="Import Budget" />}
      actions={notSuccessful && actions}
    >
      <div className="flex flex-col gap">
        {entries.map(([name, obj]) => (
          <FileInfo key={name} name={name} obj={obj} />
        ))}
      </div>
      {!notSuccessful && (
        <Button
          variant="success"
          fluid
          onClick={() => {
            onSubmit?.('success');
          }}
        >
          Open Budget
        </Button>
      )}
    </Modal>
  );
}

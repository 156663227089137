import React, { FC, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  actions?: ReactNode;
  subtitle?: string;
  title: string;
  classes?: {
    title?: string;
    subtitle?: string;
  };
}

const StreamlinedContainerHeader: FC<Props> = ({
  actions,
  title,
  subtitle,
  classes,
}) => (
  <div className="sre-container__header">
    <div>
      {subtitle && <div>{subtitle}</div>}
      <div
        className={cn(
          'dark-60',
          subtitle ? 'header5-bold' : 'header4-bold',
          classes?.title,
        )}
      >
        {title}
      </div>
    </div>
    <div>{actions && actions}</div>
  </div>
);

export default StreamlinedContainerHeader;

import React from 'react';
import { Button, Field, Input, Modal } from '@/stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { EntityTag, isTagSystem, TagForm } from 'bundles/Shared/entities/tag';
import { useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { TumblerController } from 'stories/Tumbler/Tumbler';
import { IS_UNIFORM } from 'lib/http';

interface Props extends DialogProps<TagForm> {
  tag?: EntityTag;
}

export function TagModal({ tag, onClose, onSubmit }: Props) {
  const methods = useForm<TagForm>({
    defaultValues: {
      name: tag?.name,
      isSystem: tag && isTagSystem(tag),
    },
  });
  const {
    control,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;
  const handleSubmit = methods.handleSubmit((values) => onSubmit?.(values));
  return (
    <Modal
      toggle={onClose}
      header={tag?.id ? 'Edit Tag' : 'Add Tag'}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={!isValid || !isDirty || isSubmitting}
            onClick={handleSubmit}
          >
            {tag?.id ? 'Save' : 'Add'}
          </Button>
        </>
      }
    >
      <FieldsContainer>
        <Field labelText="Name">
          <Input placeholder="Enter tag name" {...methods.register('name')} />
        </Field>
        <SectionField
          labelText="System Tag"
          note="It will be possible to synchronize with clients"
          button={
            <TumblerController
              disabled={!IS_UNIFORM}
              classes={{
                input: 'self-center',
              }}
              control={control}
              name="isSystem"
            />
          }
        />
      </FieldsContainer>
    </Modal>
  );
}

import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';
import React from 'react';

export const REQUEST_APPROVAL_NOTES = (
  <>
    <MultilineAlert
      message="Before sending an Approval Request, we recommend discussing this Change Order with your Capital Partner(s)"
      flexibleWidth
    />
  </>
);

import * as yup from 'yup';

export const FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA = yup
  .object()
  .shape({
    key: yup.string(),
    label: yup.string().required(),
  });

export type FinancialTableWidgetColumnGroupForm = yup.InferType<
  typeof FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA
>;

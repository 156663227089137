import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IAssetStage } from 'types/AssetStage';
import './stagePopover.scss';
import { Popover } from 'stories';
import { ClassNameProps } from 'types/Props';

interface Props extends React.PropsWithChildren, ClassNameProps {
  stages?: IAssetStage[];
  activeStage?: string;
}

export function StagePopover({
  children,
  stages,
  activeStage,
  className,
}: React.PropsWithChildren<Props>) {
  return (
    <Popover
      disabled={activeStage === undefined}
      maxWidth="12rem"
      placement="bottom-start"
      appendTo={() => document.body}
      offset={[0, 2]}
      hiddenArrow
      style={{ padding: '0px' }}
      template={
        <div
          className={cn('sre-badge-popover', className)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <ul className="sre-badge-popover__list">
            {stages &&
              stages.map((stage, idx) => (
                <li
                  className={cn('sre-badge-popover__item', {
                    _active: stage.name === activeStage,
                  })}
                  key={idx}
                >
                  <div className="sre-badge-popover__item-content sre-badge-popover-item-content">
                    <span className="sre-badge-popover-item-content__number">
                      {idx + 1}
                    </span>
                    <span className="sre-badge-popover-item-content__text">
                      {stage.name}
                    </span>
                    <div
                      className="sre-badge-popover-item-content__circle"
                      style={
                        (stage.name === activeStage && {
                          backgroundColor: stage.color,
                        }) ||
                        {}
                      }
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>
      }
    >
      {children}
    </Popover>
  );
}

export default StagePopover;

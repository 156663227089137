import { coreAssetsReportBuilderReportsEnhancedApi as api } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsEnhancedApi';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

interface State {
  pendingRequestIds: string[];
}

/**
 * @property pendingRequestIds - If there are any "snapshot" or "report" requests are pending then "preview" request waits until they are all resolved
 */
const initialState: State = {
  pendingRequestIds: [],
};

const reportBuilderSlice = createSlice({
  name: 'report-builder',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        api.endpoints.getApiCoreAssetsByAssetIdReportBuilderReportsAndId
          .matchPending,
        api.endpoints
          .postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot
          .matchPending,
      ),
      (state, { meta }) => {
        state.pendingRequestIds.push(meta.requestId);
      },
    );
    builder.addMatcher(
      isAnyOf(
        api.endpoints.getApiCoreAssetsByAssetIdReportBuilderReportsAndId
          .matchFulfilled,
        api.endpoints.getApiCoreAssetsByAssetIdReportBuilderReportsAndId
          .matchRejected,

        api.endpoints
          .postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot
          .matchFulfilled,
        api.endpoints
          .postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot
          .matchRejected,
      ),
      (state, { meta }) => {
        state.pendingRequestIds = state.pendingRequestIds.filter(
          (id) => id !== meta.requestId,
        );
      },
    );
  },
});

export default reportBuilderSlice.reducer;

import { capitalize } from 'lodash-es';
import { ListOption } from 'stories/Checkbox/CheckList';

export function mapItemToOption<T extends { id: string | number }>(
  item: T,
  keyForLabel: keyof T | undefined,
): ListOption<T['id']>;
export function mapItemToOption<T extends string | number>(
  item: T,
): ListOption<T>;

export function mapItemToOption(
  item: string | number | { id: string | number; [key: string]: unknown },
  keyForLabel?: string,
): ListOption {
  if (
    keyForLabel === undefined ||
    typeof item === 'string' ||
    typeof item === 'number'
  ) {
    return {
      id: item as string | number,
      value: item as string | number,
      label: capitalize(item as string),
    };
  }
  return {
    id: item.id,
    value: item.id,
    label: item[keyForLabel] as string,
  };
}

export function mapItemsToListOption<T extends { id: string | number }>(
  items: T[],
  keyForLabel: keyof T | undefined,
): ListOption<T['id']>[];
export function mapItemsToListOption<T extends string | number>(
  items: T[] | readonly T[],
): ListOption<T>[];

export function mapItemsToListOption(
  items: (string | number | { id: string | number; [key: string]: unknown })[],
  keyForLabel?: string,
): ListOption[] {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return items.map((item) => mapItemToOption(item, keyForLabel));
}

export const getOptionsValues = <T extends number | string>(
  options: ListOption<T>[] | readonly ListOption<T>[],
): T[] => options.map((option) => option.value);

export function mapOptionsToItems<
  T extends { id: string | number } | number | string,
>(items: T[], options: ListOption[]): T[] {
  const optionValues = getOptionsValues(options);
  return items.filter((item) =>
    optionValues.includes(typeof item === 'object' ? item.id : item),
  );
}

export const getMapOptionsToItemsHandler =
  <T extends { id: string | number } | number | string>(
    items: T[],
    handler: (items: T[]) => void,
  ) =>
  (options: ListOption[]) => {
    handler(mapOptionsToItems(items, options));
  };

import React from 'react';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsSuperAdmin,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import canManageObject from 'lib/InvestmentObject';
import {
  Dropdown,
  DropdownItem,
  Icon,
  IconButton,
  Label,
  Tooltip,
} from 'stories';
import { IColumn } from 'bundles/Shared/components/Table/types';
import PublicFilterButton from 'bundles/Shared/components/Filters/buttons/publicFilterButton/PublicFilterButton';
import FileExtensionIcon from 'bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import { IDocumentType } from 'types/DocumentType';
import { ISharedDocument } from 'types/SharedDocument';
import { IInvestmentObject } from 'types/IInvestmentObject';
import FileInfo from '../FileInfo';
import InlineFolder from '../../Folders/InlineFolder';
import DocumentLink from './fieldRenderers/DocumentLink';
import UploadDate from './fieldRenderers/UploadDate';
import ExtendedDetails from './formatters/extendedDetails/ExtendedDetails';
import QuickFilterByObject from './Filters/QuickFilterByObject';
import DownloadButton from './buttons/DownloadButton';
import PrintButton, { DropdownItemPrintButton } from './buttons/PrintButton';
import DocumentableAndFolder from './fieldRenderers/DocumentableAndFolder';
import PermissionedUsers from './formatters/PermissionedUsers';
import QuickFilterByDocumentType from './Filters/QuickFilterByDocumentType';
import { CssVar } from '@/shared/config/cssVar';
import useMediaQuery from '@/shared/lib/hooks/useMediaQuery';

const ALLOWED_FORMATS = ['png', 'jpeg', 'jpg', 'pdf'];

export const canManageRow = (row: ISharedDocument) =>
  canManageObject({
    id: row.documentable.id,
    objectType: row.documentable.objectType,
  });

export const canPrintRow = ({ extension, isMobile }) =>
  ALLOWED_FORMATS.includes(extension) && !isMobile;

const sortOptions = {
  sortable: true,
} satisfies Pick<IColumn, 'sortable'>;

const details = (
  sort: Pick<IColumn<ISharedDocument>, 'sortable'>,
): IColumn<ISharedDocument> => ({
  dataField: 'name',
  text: 'Details',
  classes: 'mnw-200p',
  formatter: ({ row }) => (
    <div>
      <DocumentLink row={row}>{row.filename}</DocumentLink>
      <FileInfo size={row.size} extension={row.extension} />
    </div>
  ),
  ...sort,
});

const detailsWithSupportedDocumentCheck = (
  sort: Pick<IColumn<ISharedDocument>, 'sortable'>,
) =>
  ({
    dataField: 'name',
    text: 'Details',
    classes: 'mnw-200p',
    formatter: ({ row }) => (
      <div className="flex">
        <div className="mr-s">
          <FileExtensionIcon extension={row.extension} />
        </div>
        <div>
          <DocumentLink row={row}>{row.filename}</DocumentLink>
          {row.isFinal && (
            <Tooltip mainText="Final version of supporting documentation (per verification)">
              <Icon iconName="check" className="green-dark-1 font-20 ml-tw-2" />
            </Tooltip>
          )}
          <FileInfo size={row.size} extension={row.extension} />
        </div>
      </div>
    ),
    ...sort,
  }) as IColumn<ISharedDocument>;

const uploadDateColumn = (
  sort: Pick<IColumn<ISharedDocument>, 'sortable'>,
  classes = 'd-none d-xl-table-cell',
): IColumn<ISharedDocument> => ({
  dataField: 'createdAt',
  text: 'Upload Date',
  headerStyle: { width: 150 },
  headerClasses: `!px-tw-4 !py-tw-2 ${classes}`,
  classes: `${classes} bg-light`,
  formatter: ({ row }) => <UploadDate row={row} />,
  ...sort,
});

interface Filters {
  selectedFilters: {
    documentTypes: IDocumentType[];
    investmentObjects: IInvestmentObject[];
  };
}
export interface Actions {
  setSelectedFilters: (filters: Partial<Filters['selectedFilters']>) => void;
  resetFilter: (key: keyof Filters) => void;
  remove: (ids: string[]) => void;
}

const assetAndFolderColumn = (
  actions: Actions,
  filters: Filters,
  investmentObjects: IInvestmentObject[],
): IColumn<ISharedDocument> => ({
  dataField: 'folder.title',
  text: 'Asset · Folder',
  headerStyle: { width: 230 },
  headerClasses: 'hidden lg:table-cell !px-tw-4 !py-tw-2',
  classes: 'hidden lg:table-cell bg-light',
  quickFilter: (
    <div className="ml-s">
      <QuickFilterByObject
        investmentObjects={investmentObjects}
        selectedObjects={filters.selectedFilters.investmentObjects}
        setSelectedObjects={(items) => {
          actions.setSelectedFilters({ investmentObjects: items });
        }}
        resetFilter={() => actions.resetFilter('investmentObjects')}
      />
    </div>
  ),
  formatter: ({ row }) => <DocumentableAndFolder row={row} />,
});

const folderColumn = (actions) => ({
  dataField: 'folder',
  text: 'Folder',
  headerClasses: 'hidden 2xl:table-cell min-w-[240px] !px-tw-4 !py-tw-2',
  classes: 'hidden 2xl:table-cell bg-light',
  formatter: ({ row }) =>
    actions.handleFolderClick && (
      <InlineFolder
        folder={row.folder}
        onClick={() => {
          actions.handleFolderClick(row.folder);
        }}
      />
    ),
});

const documentType = (
  actions: Actions,
  filters: {
    selectedFilters: {
      documentTypes: IDocumentType[];
    };
  },
  documentTypes: IDocumentType[],
) => ({
  dataField: 'documentType.name',
  text: 'Type',
  headerStyle: { width: '20%' },
  headerClasses: 'hidden 3xl:table-cell !px-tw-4 !py-tw-2',
  classes: 'hidden 3xl:table-cell bg-light',
  quickFilter: (
    <div className="ml-s">
      <QuickFilterByDocumentType
        items={documentTypes}
        value={filters.selectedFilters.documentTypes}
        onChange={(types) =>
          actions.setSelectedFilters({ documentTypes: types })
        }
        resetFilter={() => actions.resetFilter('documentTypes')}
      />
    </div>
  ),
  formatter: ({ row }) => (
    <Label size="s" outline color={CssVar.light60}>
      {row.documentType.name}
    </Label>
  ),
  ...sortOptions,
});

const permissionedUsers = (
  actions: Actions,
  hideActions = false,
  object,
  filters,
) => ({
  dataField: 'members',
  text: 'Permissioned Users',
  hidden: !(
    (object && canManageObject(object)) ||
    currentUserAllowedTo('manage', ASSET_PORTAL_PRODUCT_NAME) ||
    currentUserAllowedTo('manage', FUND_PORTAL_PRODUCT_NAME)
  ),
  headerStyle: { width: 240 },
  headerClasses: 'hidden lg:table-cell !px-tw-4 !py-tw-2',
  classes: 'hidden lg:table-cell bg-light',
  formatter: ({ row }) => (
    <PermissionedUsers
      row={row}
      actions={actions}
      hideActions={hideActions}
      isLocked={row.permissionsLocked}
      showViewCounter
    />
  ),
  quickFilter: (
    <span className="ml-s">
      <PublicFilterButton
        filtered={filters.selectedFilters?.onlyPublic}
        onClick={
          filters?.selectedFilters.onlyPublic
            ? undefined
            : () =>
                actions.setSelectedFilters({
                  ...filters?.selectedFilters,
                  onlyPublic: !filters.selectedFilters.onlyPublic,
                })
        }
        onClose={() =>
          actions.setSelectedFilters({
            ...filters?.selectedFilters,
            onlyPublic: false,
          })
        }
      />
    </span>
  ),
});

const documentsActions = (
  actions: {
    move: (rows: ISharedDocument[]) => void;
    remove: (rowIds: ISharedDocument['id'][]) => void;
    edit: (row: ISharedDocument) => void;
  },
  resourceName = 'shared-files',
): IColumn<ISharedDocument> => ({
  dataField: 'actions',
  text: 'Actions',
  headerStyle: { width: 80 },
  headerClasses: 'hidden lg:table-cell !px-tw-4 !py-tw-2',
  classes: 'hidden lg:table-cell bg-light',
  formatter: ({ row }) => {
    const isMobile = useMediaQuery('(pointer:coarse)');

    const canMove =
      canManageRow(row) && !row.documentType?.internal && actions.move;
    const canRemove =
      !row.documentType?.internal && canManageRow(row) && actions.remove;
    const canPrint = !row.confidential && canPrintRow({ ...row, isMobile });
    const canEdit =
      canManageRow(row) && !row.documentType.internal && actions.edit;
    const showDropdown = canMove || canRemove || canEdit || canPrint;

    return (
      <div className="flex gap-tw-2">
        {!(isMobile && row.extension == 'pdf') && (
          <Tooltip
            placement="top"
            mainText={row.confidential ? "You can't view this file" : 'View'}
          >
            <a
              href={`/${resourceName}/${row.id}?disposition=inline`}
              target="_blank"
              rel="noreferrer"
              style={
                row.confidential
                  ? {
                      pointerEvents: 'none',
                      opacity: 0.5,
                    }
                  : {}
              }
            >
              <IconButton
                iconName="eye"
                size="m"
                className="sre-icon-button_size-mobile-l bg-white"
              />
            </a>
          </Tooltip>
        )}
        <DownloadButton row={row} />
        {showDropdown && (
          <Dropdown
            items={
              <>
                {canMove && (
                  <DropdownItem
                    iconName="move"
                    onClick={() => actions.move([row])}
                  >
                    Move File
                  </DropdownItem>
                )}
                {canPrint && <DropdownItemPrintButton row={row} />}
                {canEdit && (
                  <IconButton
                    tooltipProps={{
                      mainText: 'Edit',
                    }}
                    variant="white"
                    onClick={() => actions.edit(row)}
                    iconName="edit"
                  />
                )}
                {canRemove && (
                  <DropdownItem
                    iconName="trash"
                    onClick={() => actions.remove([row.id])}
                  >
                    Remove File
                  </DropdownItem>
                )}
              </>
            }
          >
            <IconButton variant="white" iconName="more" />
          </Dropdown>
        )}
      </div>
    );
  },
});

const documentsActionsWithDots = (actions) => ({
  dataField: 'actionsWithDots',
  text: 'Actions',
  isDummyField: true,
  headerStyle: { width: 100 },
  classes: 'text-center hidden lg:table-cell bg-light',
  headerClasses: 'hidden lg:table-cell',
  formatter: ({ row }) => {
    const isMobile = useMediaQuery('(pointer:coarse)');
    return (
      <div className="d-lg-flex d-md-none gap-tw-2">
        <Tooltip
          mainText={
            row.confidential ? "You can't download this file" : 'Download'
          }
        >
          <div>
            <DownloadButton row={row} />
          </div>
        </Tooltip>
        {!row.confidential && canPrintRow({ ...row, isMobile }) && (
          <PrintButton row={row} />
        )}
        <Dropdown
          items={
            <>
              <DropdownItem
                href={`/shared-files/${row.id}?disposition=inline`}
                target="_blank"
                rel="noreferrer"
                iconName="eye"
                tag="a"
                disabled={row.confidential}
              >
                View
              </DropdownItem>
              {canManageRow(row) &&
                !row.documentType?.internal &&
                actions.edit && (
                  <DropdownItem
                    iconName="edit"
                    onClick={() => actions.edit(row)}
                    className="flex items-center"
                  >
                    Edit file
                  </DropdownItem>
                )}
              {canManageRow(row) &&
                !row.documentType?.internal &&
                actions.move && (
                  <DropdownItem
                    iconName="transferFile"
                    onClick={() => actions.move([row])}
                    className="flex items-center"
                  >
                    Move File
                  </DropdownItem>
                )}
              {canManageRow(row) &&
                currentUserIsSuperAdmin() &&
                !row.documentType?.internal &&
                actions.toggleLockPermissions && (
                  <DropdownItem
                    iconName={row.permissionsLocked ? 'lock' : 'unlock'}
                    onClick={() => actions.toggleLockPermissions(row.id)}
                    className="flex items-center"
                  >
                    {row.permissionsLocked ? 'Unlock' : 'Lock'} permissions
                  </DropdownItem>
                )}
              {!row.documentType?.internal &&
                canManageRow(row) &&
                actions.remove && (
                  <DropdownItem
                    iconName="trash"
                    onClick={() => actions.remove([row.id])}
                    className="flex items-center"
                  >
                    Remove File
                  </DropdownItem>
                )}
            </>
          }
        >
          <IconButton variant="white" iconName="more" />
        </Dropdown>
      </div>
    );
  },
});

const extendedDetailsColumn = (
  sort: Pick<IColumn<ISharedDocument>, 'sortable'>,
  actions: unknown,
): IColumn<ISharedDocument> => ({
  dataField: 'name',
  text: 'Name',
  headerClasses: 'hidden lg:table-cell !px-tw-4 !py-tw-2',
  classes: 'relative min-w-[180px] bg-light',
  formatter: ({ row }) => <ExtendedDetails actions={actions} row={row} />,
  ...sort,
});

export const assetDocumentsColumns = (
  actions: Actions,
  hideActions: true,
  object: {
    id: number | string;
    objectType: 'Asset' | 'Fund';
  },
  filters: {
    selectedFilters: {
      documentTypes: IDocumentType[];
    };
  },
  allFiltersData: {
    documentTypes: IDocumentType[];
  },
) => {
  const columns = [
    extendedDetailsColumn(sortOptions, actions),
    folderColumn(actions),
    documentType(actions, filters, allFiltersData.documentTypes),
    uploadDateColumn(sortOptions, 'hidden xl:table-cell'),
    permissionedUsers(actions, hideActions, object, filters),
  ];

  if (!hideActions) columns.push(documentsActionsWithDots(actions));
  return columns;
};

export const documentsColumns = (
  actions: Actions,
  filters: Filters,
  allFiltersData: {
    investmentObjects: IInvestmentObject[];
  },
) => [
  extendedDetailsColumn(sortOptions, actions),
  uploadDateColumn(sortOptions, 'hidden xl:table-cell'),
  assetAndFolderColumn(actions, filters, allFiltersData.investmentObjects),
  documentType(actions, filters, allFiltersData.documentTypes),
  permissionedUsers(actions, false, null, filters),
  documentsActions(actions),
];

export const constructionDocumentsColumns = (actions = {}) => [
  detailsWithSupportedDocumentCheck({}),
  uploadDateColumn({}),
  documentsActions(actions),
];

export const trashDocumentsColumns = (
  actions: Actions,
  filters: Filters,
  allFiltersData: {
    documentTypes: IDocumentType[];
  },
) => {
  return [
    extendedDetailsColumn(sortOptions, actions),
    uploadDateColumn(sortOptions),
    documentType(actions, filters, allFiltersData.documentTypes),
    permissionedUsers(actions, false, null, filters),
    documentsActions(actions, 'shared-files/trash'),
  ];
};

export const payAppDocumentsColumns = (): IColumn<ISharedDocument>[] => [
  details({}),
  uploadDateColumn({}),
];

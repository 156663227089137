import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '@/app/stores';

interface InitialState {
  expandBudget: boolean;
  expandAccountsPayable: boolean;
  expandFundedRequisitions: boolean;
}

const initialState: InitialState = {
  expandBudget: false,
  expandAccountsPayable: false,
  expandFundedRequisitions: false,
};

const requisitionsTableSlice = createSlice({
  name: 'requisitionsTable',
  initialState,
  reducers: {
    setReqTableExpandBudget: (
      state,
      action: {
        payload: boolean;
      },
    ) => {
      state.expandBudget = action.payload;
    },
    setReqTableExpandAccountsPayable: (
      state,
      action: {
        payload: boolean;
      },
    ) => {
      state.expandAccountsPayable = action.payload;
    },
    setReqTableExpandFundedRequisitions: (
      state,
      action: {
        payload: boolean;
      },
    ) => {
      state.expandFundedRequisitions = action.payload;
    },
  },
});

export const {
  setReqTableExpandBudget,
  setReqTableExpandAccountsPayable,
  setReqTableExpandFundedRequisitions,
} = requisitionsTableSlice.actions;

export const selectExpandBudget = (state: TRootState) =>
  state.requisitionsTable.expandBudget;
export const selectExpandAccountsPayable = (state: TRootState) =>
  state.requisitionsTable.expandAccountsPayable;
export const selectExpandFundedRequisitions = (state: TRootState) =>
  state.requisitionsTable.expandFundedRequisitions;

export default requisitionsTableSlice;

import QuickFilterCheckList, {
  TQuickFilterCheckListProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import React from 'react';
import { IColumn } from 'bundles/Shared/components/Table/types';

export const DEFAULT_CODE_COLUMN: IColumn<any> = {
  dataField: 'code',
  text: 'CODE',
  classes: 'light-60',
  sortable: true,
  headerStyle: {
    width: '10%',
  },
};

export const DEFAULT_NAME_COLUMN: IColumn<any> = {
  dataField: 'name',
  text: 'NAME',
  sortable: true,
};

export const JCC_COLUMN_DATAFIELD = 'jcc';

export const getJccColumn = <
  T extends { jobCostCode: { id: number; name: string } },
>(
  {
    jobCostCodes,
  }: {
    jobCostCodes: { id: number; name: string }[];
  },
  settings?: IColumn<T>,
): IColumn<T> => ({
  dataField: JCC_COLUMN_DATAFIELD,
  text: 'JOB COST CODE',
  cellClassRules: {
    'light-60': (row) => row.jobCostCode === null,
  },
  formatter: ({ row }) => row.jobCostCode?.name ?? 'No Code Yet',
  sortable: true,
  filterComponent: QuickFilterCheckList,
  filterComponentParams: {
    items: jobCostCodes,
    getLabel: (item) => item.name,
    getId: (item) => item.id,
  } as TQuickFilterCheckListProps<{ id: number; name: string }>,
  ...settings,
});

import http from 'lib/http';

export const CREATE_FEED_TYPE = 'symmetre-client-api/CREATE_FEED_TYPE';
export const UPDATE_FEED_TYPE = 'symmetre-client-api/UPDATE_FEED_TYPE';
export const DELETE_FEED_TYPE = 'symmetre-client-api/DELETE_FEED_TYPE';

export function createFeedType(data) {
  return async function (dispatch) {
    try {
      const res = await http.post('/feed_types', data);
      const payload = await res.json();

      toastr.success('Feed type was successfully created');
      dispatch({ type: CREATE_FEED_TYPE, payload });
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function updateFeedType(id, data) {
  return async function (dispatch) {
    try {
      const res = await http.put(`/feed_types/${id}`, data);
      const payload = await res.json();

      toastr.success('Feed type was successfully updated');
      dispatch({ type: UPDATE_FEED_TYPE, payload });
    } catch {
      toastr.error('Something went wrong');
    }
  };
}

export function deleteFeedType(id) {
  return async function (dispatch) {
    try {
      const res = await http.del(`/feed_types/${id}`);
      if (res.status === 403) {
        toastr.error('Feed type assigned to some feed message and can not be deleted');
      } else {
        const payload = await res.json();

        toastr.success('Feed type was successfully deleted');
        dispatch({ type: DELETE_FEED_TYPE, payload });
      }
    } catch {
      throw new Error(`Can not delete feed type with id ${id}`);
    }
  };
}

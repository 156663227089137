import { stringify } from 'qs';
import pluralize from 'pluralize';
import http from 'lib/http';
import _ from 'lodash-es';

export const FETCH_FOLDERS = 'symmetre-client-api/FETCH_FOLDERS';
export const FETCH_FOLDER = 'symmetre-client-api/FETCH_FOLDER';
export const CREATE_FOLDER = 'symmetre-client-api/CREATE_FOLDER';
export const UPDATE_FOLDER = 'symmetre-client-api/UPDATE_FOLDER';
export const DELETE_FOLDERS = 'symmetre-client-api/DELETE_FOLDER';
export const MOVE_FOLDERS = 'symmetre-client-api/MOVE_FOLDERS';
export const FETCH_FOLDER_ITEMS = 'symmetre-client-api/FETCH_FOLDER_ITEMS';
export const LOADING_FOLDERS = 'symmetre-client-api/LOADING_FOLDERS';

export async function plainFetchFolders(data) {
  const res = await http.get(`/folders?${stringify(data)}`);
  const responseData = await res.json();
  return responseData;
}

export function fetchFolders(data) {
  return async (dispatch) => {
    const res = await http.get(`/folders?${stringify(data)}`);
    const responseData = await res.json();

    dispatch({
      type: FETCH_FOLDERS,
      payload: responseData,
    });
  };
}

export function fetchFolder(data) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_FOLDERS });
    const res = await http.get(`/folders/${data.id}?${stringify(data)}`);
    const responseData = await res.json();
    const folders = [...getState().folders];
    const newFolders = _.unionBy([responseData], folders, 'id');

    dispatch({
      type: FETCH_FOLDER,
      payload: newFolders,
    });
  };
}

export async function plainCreateFolder(data) {
  const res = await http.post('/folders', data);
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success('Folder has been successfully created');
    return responseData;
  }
}

export function createFolder(data) {
  return async (dispatch) => {
    const res = await http.post(`/assets/${data.folder.asset_id}/folders`, data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Folder has been successfully created');

      dispatch({ type: CREATE_FOLDER, payload: responseData });
    }
  };
}

export function updateFolder(data) {
  return async (dispatch) => {
    const res = await http.put(`/assets/${data.folder.asset_id}/folders/${data.folder.id}`, data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Folder has been successfully updated');

      dispatch({ type: UPDATE_FOLDER, payload: responseData });
    }
  };
}


export async function plainDeleteFolders(data) {
  const res = await http.del('/folders/bulk', data);
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(`${responseData.errors}\n`);
  } else {
    toastr.success(`${pluralize('Folder', data.ids.length)} ${pluralize(
      'have',
      data.ids.length,
    )} been successfully removed`);
  }

  return responseData;
}

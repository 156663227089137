import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { plainFetchReconcileDevelopmentLegalEntity } from 'bundles/REconcile/actions/reconcileDevelopmentLegalEntities';
import { IReconcileDevelopmentLegalEnitity } from 'bundles/REconcile/types/IReconcileDevelopmentLegalEnitity';

interface IDevelopmentBudgetState {
  legalEntity: IReconcileDevelopmentLegalEnitity | null;
  state: 'idle' | 'loading' | 'success';
}

const initialState: IDevelopmentBudgetState = {
  legalEntity: null,
  state: 'idle',
};

export const fetchDevelopmentLegalEntity = createAsyncThunk(
  'developmentLegalEntityProject/fetch',
  async (code: string) => {
    const developmentLegalEntity =
      await plainFetchReconcileDevelopmentLegalEntity(code);

    return {
      developmentLegalEntity,
    };
  },
);

const developmentBudgetSlice = createSlice({
  name: 'developmentLegalEntityProject',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDevelopmentLegalEntity.pending, (state) => {
      state.state = 'loading';
    });
    builder.addCase(fetchDevelopmentLegalEntity.fulfilled, (state, action) => {
      const { developmentLegalEntity } = action.payload;
      state.legalEntity = developmentLegalEntity;
      state.state = 'success';
    });
  },
});

export default developmentBudgetSlice.reducer;

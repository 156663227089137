import currency from 'currency.js';
import dayjs from 'dayjs';

export const convertToBps = (value: number): number => value * 10000;

export const convertDecimalToPercentage = (value: number): number =>
  currency(value, {
    precision: 4,
  }).multiply(100).value;
export const convertPercentageToDecimal = (value: number): number =>
  currency(value, {
    precision: 4,
  }).divide(100).value;

export const convertCentsToDollars = (value: number): number =>
  currency(value).divide(100).value;
export const convertDollarsToCents = (value: number): number =>
  currency(value).multiply(100).value;

export const convertToMilliseconds = (date: UnixTime) =>
  currency(date).multiply(1000).value;
export const convertToSeconds = (date: UnixTime) =>
  currency(date).divide(1000).value;

export const convertToTimestamp = (date: dayjs.ConfigType) =>
  convertToMilliseconds(dayjs(date).unix() as UnixTime);

export const DATE_STRING_FORMAT = 'YYYY-MM-DD';

export function formatToDateStringForRequest(
  date: dayjs.ConfigType,
): DateString {
  return dayjs(date).format(DATE_STRING_FORMAT) as DateString;
}

import http from 'lib/http';
import * as Sentry from '@sentry/react';
import { stringify, IStringifyOptions } from 'qs';
import type { IFetchChangeOrderEventsParams } from 'bundles/Construction/types';

export const FETCH_CHANGE_ORDER_EVENTS =
  'symmetre-client-api/FETCH_CHANGE_ORDER_EVENTS';
export const FETCH_CHANGE_ORDER_EVENT =
  'symmetre-client-api/FETCH_CHANGE_ORDER_EVENT';
export const CREATE_CHANGE_ORDER_EVENT =
  'symmetre-client-api/CREATE_CHANGE_ORDER_EVENT';
export const UPDATE_CHANGE_ORDER_EVENT =
  'symmetre-client-api/UPDATE_CHANGE_ORDER_EVENT';
export const UPLOAD_CHANGE_ORDER_EVENT_SHARED_FILES =
  'symmetre-client-api/UPLOAD_CHANGE_ORDER_EVENT_SHARED_FILES';
export const REVISE_CHANGE_ORDER_EVENT =
  'symmetre-client-api/REVISE_CHANGE_ORDER_EVENT';
export const DELETE_CHANGE_ORDER_EVENT =
  'symmetre-client-api/DELETE_CHANGE_ORDER_EVENT';
export const FETCH_CHANGE_ORDER_BADGES =
  'symmetre-client-api/FETCH_CHANGE_ORDER_BADGES';

export function plainFetchChangeOrderEvents(
  legalEntityCode: string,
  params: IFetchChangeOrderEventsParams = {},
) {
  const options: IStringifyOptions = {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
  };
  return http.get(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events${stringify(
      params,
      options,
    )}`,
  );
}

export function fetchChangeOrderEvents(
  legalEntityCode: string,
  params: IFetchChangeOrderEventsParams = {},
) {
  return async function (dispatch) {
    const res = await plainFetchChangeOrderEvents(legalEntityCode, params);
    const payload = await res.json();

    dispatch({ type: FETCH_CHANGE_ORDER_EVENTS, payload });
  };
}

export function fetchChangeOrderBadges(legalEntityCode: string) {
  return async function (dispatch) {
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/change_order_badges`,
    );
    const payload = await res.json();
    dispatch({ type: FETCH_CHANGE_ORDER_BADGES, payload });
  };
}

export function fetchChangeOrderEvent(id, legalEntityCode) {
  return async function (dispatch) {
    const res = await http.get(`/reconcile/change_order_events/${id}`);
    const payload = await res.json();
    if (res.status === 404) {
      Sentry.captureMessage(
        `Change Event (${id}) with LE (${legalEntityCode}) code not found`,
      );
    }
    dispatch({ type: FETCH_CHANGE_ORDER_EVENT, payload });
  };
}

export function fetchMobileOrderEvent(id) {
  return async function (dispatch) {
    const res = await http.get(`/reconcile/change_order_events/${id}/mobile`);
    const payload = await res.json();

    dispatch({ type: FETCH_CHANGE_ORDER_EVENT, payload });
  };
}

export function createChangeOrderEvent(legalEntityCode, data) {
  return async function (dispatch) {
    const res = await http.post(
      `/reconcile/legal_entities/${legalEntityCode}/change_order_events`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Change Event has been successfully created');
      dispatch({
        type: CREATE_CHANGE_ORDER_EVENT,
        payload: { ...responseData, legalEntityCode },
      });
    }
  };
}

export function updateChangeEventsAfterCreating(data) {
  return async function (dispatch) {
    dispatch({
      type: CREATE_CHANGE_ORDER_EVENT,
      payload: data.payload,
    });
  };
}

export function updateChangeOrderEvent(id, data) {
  return async function (dispatch) {
    const res = await http.put(`/reconcile/change_order_events/${id}`, data);
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Change Event has been successfully updated');
      dispatch({
        type: UPDATE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function destroyChangeOrderEvent({
  legalEntityCode,
  id,
}: {
  legalEntityCode: string;
  id: string;
}) {
  return async function (dispatch) {
    const res = await http.del(
      `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}`,
      {},
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Change Event has been successfully deleted');
      dispatch({
        type: DELETE_CHANGE_ORDER_EVENT,
        payload: { id: Number(id), responseData },
      });
    }
  };
}

export function reviseChangeOrderEvent(id, data) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/change_order_events/${id}/revise`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Change Event has been successfully revised');
      dispatch({
        type: REVISE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function storeChangeOrderEventVerification(
  id,
  data,
  { skipNotify = false },
) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/change_order_events/${id}/store_verification`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      if (!skipNotify) toastr.error(`${responseData.errors}\n`);
    } else {
      if (!skipNotify) toastr.success('Cost Breakdown has been updated');
      dispatch({
        type: UPDATE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function dateSubstantialChangeOrderEvent(
  id,
  data,
  { skipNotify = false },
) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/change_order_events/${id}/store_verification`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      if (!skipNotify) toastr.error(`${responseData.errors}\n`);
    } else {
      if (!skipNotify)
        toastr.success('Substantial Completion Date has been updated');
      dispatch({
        type: UPDATE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function selectFileChangeOrderEvent(id, data, { skipNotify = false }) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/change_order_events/${id}/store_verification`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      if (!skipNotify) toastr.error(`${responseData.errors}\n`);
    } else {
      if (!skipNotify) toastr.success('File has been selected');
      dispatch({
        type: UPDATE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function verifyChangeOrderEvent(id, data) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/change_order_events/${id}/verify`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Change Event has been successfully verified');
      dispatch({
        type: UPDATE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function startApprovalChangeOrderEvent(id) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/change_order_events/${id}/start_approval`,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Change Event is now ready for approval');
      dispatch({
        type: UPDATE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function approveChangeOrderEvent(id, data) {
  return async function (dispatch) {
    const res = await http.put(
      `/reconcile/change_order_events/${id}/approve`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Change Event has been successfully approved');
      dispatch({
        type: UPDATE_CHANGE_ORDER_EVENT,
        payload: { id, responseData },
      });
    }
  };
}

export function uploadChangeOrderEventSharedFiles(legalEntityCode, id, data) {
  return async function (dispatch) {
    const res = await http.post(
      `/reconcile/change_order_events/${id}/upload`,
      data,
    );
    const responseData = await res.json();

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
    } else {
      toastr.success('Documents have been successfully uploaded');
      dispatch({
        type: UPLOAD_CHANGE_ORDER_EVENT_SHARED_FILES,
        payload: { id, responseData },
      });
    }
  };
}

import React from 'react';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard';
import {
  IThinTabItem,
  ThinTabGroup,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { currentUserCanSeeComparisonPage } from '@/pages/comparisonDashboards/permissions';

interface Props {
  type: ReportDashboardType | `${ReportDashboardType}` | null;
  onChange: (status: ReportDashboardType | null) => void;
}

const TABS = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: ReportDashboardType.EAGLE_EYE,
    label: 'Eagle Eye',
  },
  {
    id: ReportDashboardType.OBJECT,
    label: 'Object',
  },
  {
    id: ReportDashboardType.COMPARISON_MODE,
    label: 'Compare',
    resolveHidden: () => !currentUserCanSeeComparisonPage(),
  },
] as const satisfies readonly IThinTabItem[];

type TabId = (typeof TABS)[number]['id']

export function DashboardFilterByTypeTabs({ type, onChange }: Props) {
  return (
    <div className="flex items-center gap-tw-2">
      <span className="inline-regular text-neutral-500">Type</span>
      <ThinTabGroup<TabId>
        items={TABS}
        selectedItem={TABS.find((tab) => tab.id === type) ?? TABS[0]}
        onSelectedItemChange={(tab) =>
          onChange(tab.id === 'all' ? null : (tab.id as ReportDashboardType))
        }
      />
    </div>
  );
}

import React from 'react';

export const LabelTitle = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    {children}
    <span className="text-red">*</span>
  </div>
);

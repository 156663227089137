import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { RecentRentrollWidget } from 'bundles/Shared/widgets/dashboard/widgets/recentRentroll/ui/RecentRentrollWidget';
import { WidgetContextDateConfigCard } from '../common/ui/state/WidgetContextDateConfigCard';

export const RECENT_RENTROLL_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 6,
    maxH: 8,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Recent Rentroll',
  icon: 'table',
  Component: RecentRentrollWidget,
  CardConfigComponent: WidgetContextDateConfigCard,
} as const satisfies WidgetConfiguration;

export const UNIT_MIX_WIDGET_CONFIG = {
  ...RECENT_RENTROLL_WIDGET_CONFIG,
  title: 'Unit Mix',
} as const satisfies WidgetConfiguration;

export const UNIT_MIX_SUMMARY_TABLE_CONFIG = {
  ...RECENT_RENTROLL_WIDGET_CONFIG,
  title: 'Unit Mix Summary Table',
} as const satisfies WidgetConfiguration;

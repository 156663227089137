import { startCase } from 'lodash-es';
import { currentUserIsSreAdmin } from 'lib/permissions';

export const formatUnitStatus = (s: string) => {
  if (s === '' || !s) return 'Unknown';
  if (s === 'occupied_ntv') return 'Occupied NTV';
  if (s === 'occupied_ntvl') return 'Occupied NTVL';
  return startCase(s);
};
export const currentUserAllowedToConfigureUnitTypes = () => {
  return currentUserIsSreAdmin();
};

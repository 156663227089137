import ItemFilterListWithInfiniteScroll, {
  ItemFilterListWithInfiniteScrollProps,
} from '@/bundles/Shared/components/Filters/items/ItemFilterListWithInfiniteScroll';
import { IUser } from '@/types/User';
import { useModal } from '@/shared/lib/hooks/useModal';
import { typedLocalStorage } from '@/shared/lib/localStorage';
import ItemFilterList, {
  ItemFilterListProps,
} from '../../Filters/items/ItemFilterList';
import impersonationConfirm from '@/entities/impersonation/ui/impersonationConfirm';

type Props = {
  users: IUser[];
  maxHeight?: string;
  onAction?: () => void;
  withInfiniteScroll?: boolean;
} & (
  | ({
      withInfiniteScroll?: boolean;
    } & Pick<
      ItemFilterListWithInfiniteScrollProps,
      'onIntersectionLastItem' | 'loadingMoreActive' | 'loading'
    >)
  | ItemFilterListProps
);

const MirrorModeCard = ({
  users,
  onAction,
  maxHeight = '21.875rem',
  withInfiniteScroll,
  ...itemFilterListProps
}: Props) => {
  const { confirm } = useModal();

  const onSelectedUserChange = (user: IUser) => {
    impersonationConfirm({ user, confirm });
    user.role
      ? typedLocalStorage.setItem('currentMirrorModeRole', user.role)
      : typedLocalStorage.removeItem('currentMirrorModeRole');
    onAction?.();
  };

  const Component = withInfiniteScroll
    ? ItemFilterListWithInfiniteScroll
    : ItemFilterList;

  return (
    <div className="list-card">
      <Component
        items={users}
        loading={!users}
        selectedItems={[]}
        onSelectedItemChange={onSelectedUserChange}
        mask="user"
        getMainFieldTitle={(item) => item.fullName}
        getSecondaryFieldTitle={(item) => item?.role?.name}
        maxHeight={maxHeight}
        searchInputProps={{
          placeholder: 'Search Member',
        }}
        classes={{ itemTitle: 'blue font-weight-600' }}
        {...itemFilterListProps}
      />
    </div>
  );
};

export default MirrorModeCard;

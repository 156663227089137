import { sreColorsGeneratedApi } from '@/entities/sreColors/model/sreColorsGeneratedApi';

export const sreColorsEnhancedApi = sreColorsGeneratedApi.enhanceEndpoints({
  addTagTypes: ['Colors'],
  endpoints: {
    getApiSettingsSreColors: {
      providesTags: ['Colors'],
    },
    deleteApiSettingsSreColorsById: {
      invalidatesTags: ['Colors'],
    },
    postApiSettingsSreColors: {
      invalidatesTags: ['Colors'],
    },
    putApiSettingsSreColorsById: {
      invalidatesTags: ['Colors'],
    },
    postApiSettingsSreColorsBulk: {
      invalidatesTags: ['Colors'],
    },
  },
});

export const {
  useGetApiSettingsSreColorsQuery,
  usePostApiSettingsSreColorsMutation,
  usePutApiSettingsSreColorsByIdMutation,
  useDeleteApiSettingsSreColorsByIdMutation,
  usePostApiSettingsSreColorsBulkMutation,
} = sreColorsEnhancedApi;

import { KpiTableSingleDateWidget } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/widget';
import { KpiTableSingleDateWidgetConfigComponent } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/component';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';

export const KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Kpi Table Single Date',
  icon: 'table',
  Component: KpiTableSingleDateWidget,
  ConfigComponent: KpiTableSingleDateWidgetConfigComponent,
} as const satisfies WidgetConfiguration;

export * from './model';
export * from './config';
export * from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/widget';
export * from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/component';

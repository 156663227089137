import http from 'lib/http';
import { stringify } from 'qs';

export const fetchTrashDocuments = async (filters) => {
  const dataParams = { indices: false, arrayFormat: 'brackets', encode: false };
  const res = await http.get(`/trash_documents?${stringify(filters, dataParams)}`);

  return res.json();
};

export const deleteTrashDocuments = async (ids) => {
  const res = await http.del('/trash_documents/bulk_destroy', { ids });
  return res.json();
};

import { ReportLineItem } from 'bundles/REturn/actions/types';
import ReviewAction from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/ReviewAction';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { uniqBy } from 'lodash-es';
import { ReviewBulkLinking } from '../components/reviewStep/ReviewBulkLinking';
import { ReviewDistributionKinds } from '../components/reviewStep/ReviewDistributionKinds';
import { ReviewInfoText } from '../components/reviewStep/ReviewInfoText';
import { ReviewTotal } from '../components/reviewStep/ReviewTotal';
import { EntyTxWithReviewInfo } from '../types';

interface Props {
  entryType: 'contribution' | 'distribution';
  capitalInvestments: ICapitalInvestment[];
  transactions: ReportLineItem[];
  entryTxWithReviewInfoItems: EntyTxWithReviewInfo[];
  bulkLinkingReview: boolean;
}

export function ReviewStep({
  entryType,
  capitalInvestments,
  transactions,
  entryTxWithReviewInfoItems,
  bulkLinkingReview,
}: Props) {
  const uniqEntryTxWithReviewInfoItems = uniqBy(
    entryTxWithReviewInfoItems,
    'investmentId',
  );

  return (
    <div className="flex h-full grow flex-col items-center bg-light py-tw-6">
      <div className="flex max-w-[440px] flex-col gap-tw-6">
        <ReviewInfoText transactions={transactions} />

        {bulkLinkingReview && (
          <ReviewBulkLinking
            entryType={entryType}
            capitalInvestments={capitalInvestments}
            transactions={transactions}
            uniqEntryTxWithReviewInfoItems={uniqEntryTxWithReviewInfoItems}
          />
        )}

        {!bulkLinkingReview && uniqEntryTxWithReviewInfoItems.length > 0 && (
          <ReviewAction.Item title="Investment Entity" variant="with-shadows">
            {uniqEntryTxWithReviewInfoItems.map((entryTx) => (
              <ReviewAction.SubItem
                key={entryTx.investmentId}
                name={entryTx.investmentName}
                subtitle={
                  entryTx.kindReviewInfo && (
                    <ReviewDistributionKinds
                      kindReviewInfo={entryTx.kindReviewInfo}
                    />
                  )
                }
              >
                <ReviewAction.AmountComparison
                  past={entryTx.totalAmount ?? 0}
                  present={
                    Number(entryTx.totalAmount ?? 0) +
                    Number(entryTx.totalTransactionsAmount ?? entryTx.amount)
                  }
                />
              </ReviewAction.SubItem>
            ))}
          </ReviewAction.Item>
        )}

        <ReviewTotal
          entryType={entryType}
          capitalInvestments={capitalInvestments}
          transactions={transactions}
        />
      </div>
    </div>
  );
}

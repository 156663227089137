import * as yup from 'yup';
import { VALUE_DISPLAY_OPTIONS_SCHEMA } from '@/shared/lib/formatting/displayOptions';
import {
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { KpiCardWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/kpiCard';
import {
  transformPeriodShiftDtoToForm,
  transformPeriodShiftFormToDto,
  transformPeriodTypeDtoToForm,
  transformPeriodTypeFormToDto,
} from 'bundles/Shared/widgets/dashboard/widgets/common';

export const KPI_CARD_WIDGET_CONFIG_ROW_SCHEMA = yup.object().shape({
  key: yup.number(),
  label: yup.string().required(),
  expression: yup.string().required(),
  value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
  period_type: PERIOD_TYPE_SCHEMA.required(),
  period_shift: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
});
export type KpiCardWidgetRowForm = yup.InferType<
  typeof KPI_CARD_WIDGET_CONFIG_ROW_SCHEMA
>;
export const transformKpiCardRowConfigToForm = (
  rowConfig: KpiCardWidgetConfig['kpis'][number],
): KpiCardWidgetRowForm => {
  return {
    key: rowConfig.key,
    label: rowConfig.label,
    period_shift: transformPeriodShiftDtoToForm(rowConfig.period_shift),
    period_type: transformPeriodTypeDtoToForm(rowConfig.period_type),
    value_display_options: rowConfig.value_display_options,
    expression: rowConfig.expression,
  };
};
export const transformKpiCardRowFormToConfig = (
  rowForm: KpiCardWidgetRowForm,
): KpiCardWidgetConfig['kpis'][number] => {
  return {
    key: rowForm.key!,
    label: rowForm.label,
    period_shift: transformPeriodShiftFormToDto(rowForm.period_shift),
    period_type: transformPeriodTypeFormToDto(rowForm.period_type),
    value_display_options: rowForm.value_display_options,
    expression: rowForm.expression,
  };
};

import http from 'lib/http';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { stringify } from 'qs';

export const fetchInvestmentEntitiesByObject = async (
  objectId: number,
  objectType: 'asset' | 'fund',
) => {
  const objectParam = objectType === 'asset' ? 'asset_id' : 'fund_id';
  const params = {
    [objectParam]: objectId,
  };
  const res = await http.get(
    `/capital/${objectType}_investment_entities?${stringify(params)}`,
  );
  const investmentEntities = await res.json();

  return investmentEntities as IInvestmentEntity[];
};

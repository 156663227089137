import React, { createContext, useContext } from 'react';

interface CrudContextParams<Params, Entity> {
  pageParams?: Params;
  setPageParams?: React.Dispatch<React.SetStateAction<Params>>;
  entities: Entity[];
  entityName: string;
  handlers?: {
    create: () => void;
    update: (row: Entity) => void;
    delete: (row: Entity) => void;
  };
}

// create context
const CrudContext = createContext<CrudContextParams<any, any> | null>(null);

const CrudContextProvider = <Params, Entity>({
  children,
  pageParams,
  setPageParams,
  entities,
  entityName,
  handlers,
}: React.PropsWithChildren<CrudContextParams<Params, Entity>>) => (
  <CrudContext.Provider
    value={{
      pageParams,
      setPageParams,
      entities,
      entityName,
      handlers,
    }}
  >
    {children}
  </CrudContext.Provider>
);

const useCrudContext = () => {
  // get the context
  const context = useContext(CrudContext);

  // if `undefined`, throw an error
  if (context === undefined || context === null) {
    throw new Error('useCrudContext was used outside of its Provider');
  }

  return context;
};

export { CrudContext, CrudContextProvider, useCrudContext };

import React from 'react';
import pluralize from 'pluralize';
import { round } from 'lodash-es';

type Props = {
  units: number | undefined;
  withBrackets?: boolean;
};

function UnitQuantity({ units, withBrackets = true }: Props) {
  if (!units || units === 0) return null;

  return (
    <span className="secondary-regular whitespace-nowrap text-xs font-normal text-neutral-500">
      {' '}
      {withBrackets && '('}
      {round(units, 2)} {pluralize('unit', units)}
      {withBrackets && ')'}
    </span>
  );
}

export default UnitQuantity;

import { emptySplitApi as api } from '@/app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiReportFinancialCategoriesTree: build.query<
      GetApiReportFinancialCategoriesTreeApiResponse,
      GetApiReportFinancialCategoriesTreeApiArg
    >({
      query: () => ({ url: '/api/report/financial_categories/tree' }),
    }),
    putApiReportFinancialCategoriesTree: build.mutation<
      PutApiReportFinancialCategoriesTreeApiResponse,
      PutApiReportFinancialCategoriesTreeApiArg
    >({
      query: (queryArg) => ({
        url: '/api/report/financial_categories/tree',
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiReportFinancialCategoriesById: build.mutation<
      PutApiReportFinancialCategoriesByIdApiResponse,
      PutApiReportFinancialCategoriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/financial_categories/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    postApiReportFinancialCategoriesSyncWithSource: build.mutation<
      PostApiReportFinancialCategoriesSyncWithSourceApiResponse,
      PostApiReportFinancialCategoriesSyncWithSourceApiArg
    >({
      query: () => ({
        url: '/api/report/financial_categories/sync_with_source',
        method: 'POST',
      }),
    }),
    postApiReportFinancialCategoriesSync: build.mutation<
      PostApiReportFinancialCategoriesSyncApiResponse,
      PostApiReportFinancialCategoriesSyncApiArg
    >({
      query: () => ({
        url: '/api/report/financial_categories/sync',
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportFinancialCategoriesApi };

export type PutApiReportFinancialCategoriesTreeApiResponse =
  /** status 200 success */ {
    tree?: ReportFinancialCategoryTree;
  };

export type PutApiReportFinancialCategoriesTreeApiArg = {
  body: {
    tree: ReportFinancialCategoryTree;
  };
};

export type GetApiReportFinancialCategoriesTreeApiResponse =
  /** status 200 success */ {
    tree?: ReportFinancialCategoryTree;
  };
export type GetApiReportFinancialCategoriesTreeApiArg = void;
export type PutApiReportFinancialCategoriesByIdApiResponse =
  /** status 200 success */ {
    tree?: ReportFinancialCategory;
  };
export type PutApiReportFinancialCategoriesByIdApiArg = {
  id: string;
  body: {
    code?: string;
    parent_id?: number;
    classifications?: string[];
  };
};
export type PostApiReportFinancialCategoriesSyncWithSourceApiResponse =
  /** status 200 success */ object;
export type PostApiReportFinancialCategoriesSyncWithSourceApiArg = void;
export type PostApiReportFinancialCategoriesSyncApiResponse = object;
export type PostApiReportFinancialCategoriesSyncApiArg = void;
export type ReportFinancialCategoryTreeNode = {
  id: number;
  code: string;
  classifications: (
    | 'multifamily'
    | 'retail'
    | 'hospitality'
    | 'office'
    | 'senior_living'
    | 'student'
    | 'mixed_use'
    | 'spv'
    | 'op_co'
    | 'commercial'
  )[];
  linkedItems: {
    label: string;
  }[];
  parentId: number | null;
  children: ReportFinancialCategoryTreeNode[];
};
export type ReportFinancialCategoryTree = ReportFinancialCategoryTreeNode[];
export type ReportFinancialCategory = {
  id: number;
  code: string;
  classifications: (
    | 'multifamily'
    | 'retail'
    | 'hospitality'
    | 'office'
    | 'senior_living'
    | 'student'
    | 'mixed_use'
    | 'spv'
    | 'op_co'
    | 'commercial'
  )[];
  parentId: number | null;
};

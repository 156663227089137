import { useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation } from "@/entities/report/reportBuilder";
import { useModal } from "@/shared/lib/hooks/useModal";
import { IAsset } from "@/types/Asset";

export const useDeleteReport = ({ assetId }: { assetId: IAsset['id']}) => {
  const [deleteReport, options] =
    useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation();
  const { confirm } = useModal();

  const handleDelete = async (reportId: string) => {
    const answer = await confirm({
      title: 'Are you sure you want to delete this report?',
      subtitle: 'Deleting the report will remove the file from the reports and documents section of the portal.',
    });

    if (!answer) return;

    const response = await deleteReport({
      id: reportId,
      assetId,
    });

    return response;
  }

  return [
    handleDelete,
    options,
  ] as const
}

import React, { useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import useElementSize from '@/shared/lib/hooks/useElementSize';
import { Button } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './ContentOverflow.module.scss';

interface Props {
  maxHeight: number;
  defaultExpanded?: boolean;
}

function ContentOverflow({
  maxHeight,
  defaultExpanded,
  children,
}: React.PropsWithChildren<Props>) {
  const contentRef = useRef(null);
  const [isContentExpanded, setIsContentExpanded] = useState(defaultExpanded);
  const { height: contentHeight } = useElementSize(contentRef);

  return (
    <div
      style={{
        maxHeight: isContentExpanded ? 9999 : maxHeight,
      }}
      className={cn(styles.container, {
        [styles.container_expanded]: isContentExpanded,
      })}
    >
      <AnimateHeight
        id="content-overflow"
        duration={300}
        delay={100}
        height={
          isContentExpanded || contentHeight < maxHeight ? 'auto' : maxHeight
        }
      >
        <div ref={contentRef}>{children}</div>
        {contentHeight > maxHeight ? (
          <div
            className={cn(styles.overlay, {
              [styles.overlay_expanded]: isContentExpanded,
            })}
          >
            <Button
              className={cn(styles.button, {
                [styles.button_secondary]: isContentExpanded,
                [styles.button_primary]: !isContentExpanded,
              })}
              size="xs"
              iconPosition="right"
              iconClasses={cn('text-[1.5rem]-[1rem]', {
                'light-60': isContentExpanded,
                white: !isContentExpanded,
              })}
              iconName={isContentExpanded ? 'top' : 'bottom'}
              onClick={() => setIsContentExpanded(!isContentExpanded)}
            >
              {isContentExpanded ? 'Collapse' : 'Read more'}
            </Button>
          </div>
        ) : null}
      </AnimateHeight>
    </div>
  );
}

export default ContentOverflow;

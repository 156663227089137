import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Button, Modal, SearchInput } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { fetchCustomerConfig } from 'bundles/Assets/actions';
import { IDocumentType } from 'types/DocumentType';
import styles from './TypeModal.module.scss';
import DocumentTypeFields from '../../../../Settings/components/Portal/documentTypes/DocumentTypeFields';
import { createDocumentType } from 'bundles/Settings/actions/documentType';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';

interface Props {
  documentType: IDocumentType;
  onSubmit: (documentType: IDocumentType) => void;
  onClose: () => void;
  customHeader?: any;
}

const TypeModal = ({
  documentType,
  onSubmit,
  onClose,
  customHeader,
}: Props) => {
  const dispatch = useAppDispatch();
  const customerConfig = useAppSelector((state) => state.customerConfig);
  const [currentDocumentType, setCurrentDocumentType] = useState(documentType);
  const [filterText, setFilterText] = useState('');
  const [isDocumentTypeModalOpen, setIsDocumentTypeModalOpen] = useState(false);

  const documentTypes = useMemo<IDocumentType[]>(
    () =>
      customerConfig.documentTypes.filter(
        (type) => type.active && !type.internal,
      ),
    [customerConfig.documentTypes],
  );

  // TOOD: replace with GroupedCheckboxes
  const sorted = useMemo(
    () => documentTypes.sort((a, b) => a.name.localeCompare(b.name)),
    [documentTypes],
  );

  // TOOD: replace with GroupedCheckboxes
  const filtered = useMemo(
    () =>
      sorted.filter(
        (dt) =>
          !filterText ||
          dt.name.toLowerCase().includes(filterText.toLowerCase()),
      ),
    [sorted, filterText],
  );
  // todo replaec with useAlphabetGroup
  const sortedGroups = useMemo(() => {
    const groups: Record<string, IDocumentType[]> = {};

    for (const dt of filtered) {
      const firstLetter = dt.name.charAt(0);
      if (groups[firstLetter] === undefined) {
        groups[firstLetter] = [];
      }
      groups[firstLetter].push(dt);
    }
    return groups;
  }, [filtered]);

  useEffect(() => {
    // todo remove when configs will be fetched from root
    dispatch(fetchCustomerConfig());
  }, []);

  const handleSubmit = async (newDocumentType) => {
    await dispatch(createDocumentType(newDocumentType));
    setIsDocumentTypeModalOpen(false);
  };

  return (
    <>
      <Modal
        size="lg"
        toggle={onClose}
        header="Set Document Type"
        actions={
          <div className="w-full flex justify-between">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => {
                onSubmit(currentDocumentType);
                onClose();
              }}
              disabled={currentDocumentType == null}
            >
              Set Document Type
            </Button>
          </div>
        }
        classes={{
          header: 'bg-light',
          footer: 'bg-light',
        }}
      >
        <>
          {/* TOOD: replace with GroupedCheckboxes */}
          <div className="mb-l mt-m flex items-center">
            <SearchInput
              className="flex-grow"
              size="m"
              placeholder="Search"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              resetValue={(text) => setFilterText(text)}
            />
            <Button
              variant="primary"
              iconName="addSmall"
              size="m"
              iconPosition="left"
              className="inline-semibold ml-m"
              onClick={() => setIsDocumentTypeModalOpen(true)}
            >
              Add Document Type
            </Button>
          </div>
          <div className={styles.alphabetContainer}>
            {Object.entries(sortedGroups).map(([group, dtArray]) => (
              <div className="flex flex-col items-start gap-tw-4" key={group}>
                <Badge
                  classes={{
                    value: cn('header6-bold', styles.badge),
                  }}
                >
                  {group}
                </Badge>
                <RadioGroup
                  options={mapItemsToListOption(dtArray, 'name')}
                  value={currentDocumentType?.id}
                  onChange={(o) =>
                    setCurrentDocumentType(
                      dtArray.find((dt) => dt.id === o.value)!,
                    )
                  }
                />
              </div>
            ))}
          </div>
        </>
      </Modal>

      {isDocumentTypeModalOpen && (
        <Modal
          header="New Document Type"
          toggle={() => setIsDocumentTypeModalOpen(false)}
        >
          <DocumentTypeFields onSubmit={handleSubmit} documentType={{}} />
        </Modal>
      )}
    </>
  );
};

export default TypeModal;

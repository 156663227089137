import React from 'react';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { IReportTableSegment } from 'types/ReportTableSegment';

interface Props {
  segment: IReportTableSegment;
}

const CreatedAtCell = ({ segment }: Props) => (
  <div>{formatUnixDate(segment.createdAt, 'LL')}</div>
);

export default CreatedAtCell;

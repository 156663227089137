import React from 'react';
import FilterBar from 'bundles/Shared/components/Filters/common/filterBar/FilterBar';
import { IGeneralLedger } from 'types/GeneralLedger';
import FilterItem from 'bundles/Shared/components/Filters/common/filterItem/FilterItem';

type TDisplayedGeneralLedger = Pick<IGeneralLedger, 'id' | 'accTitle'>;

interface Props<T extends TDisplayedGeneralLedger> {
  generalLedgers: T[];
  selectedGeneralLedgers: T[];
  selectedChange: (newGeneralLedgers: T[]) => void;
  selectionMode?: 'exclude' | 'include';
  getLabel?: (gl: T) => string;
}

function GeneralLedgersFilterBar<T extends TDisplayedGeneralLedger>({
  selectedGeneralLedgers,
  selectedChange,
  generalLedgers,
  getLabel,
  selectionMode = 'include',
}: Props<T>) {
  const getFilterItemProps = (
    gl: T,
  ): Pick<React.ComponentProps<typeof FilterItem>, 'hidden' | 'onHide'> => {
    const isSelected =
      selectedGeneralLedgers.find((sge) => gl.id === sge.id) != null;
    const onHide = () =>
      selectedChange(
        isSelected
          ? selectedGeneralLedgers.filter((sge) => sge.id !== gl.id)
          : [...selectedGeneralLedgers, gl],
      );

    return {
      onHide,
      hidden: selectionMode === 'include' ? isSelected : !isSelected,
    };
  };

  return (
    <FilterBar title="General Ledgers">
      {generalLedgers.map((gl) => (
        <FilterItem
          key={gl.id}
          label={getLabel?.(gl) ?? gl.accTitle}
          {...getFilterItemProps(gl)}
        />
      ))}
    </FilterBar>
  );
}

export default GeneralLedgersFilterBar;

import { IUser } from 'types/User';

export const userOption = (user: IUser) => {
  const label = user ? `${user.fullName} (${user.role.name})` : '';
  return { value: user?.id ?? '', label };
};

export const getUsersByIds = (users: IUser[], ids: number[]) =>
  users?.filter((u) => ids.includes(u.id));

export const userOptions = (allUsers: IUser[]) =>
  allUsers?.map((u) => userOption(u));

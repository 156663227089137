import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  confirmValueChangeEvent,
  selectChangeEvent,
  updateChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useParams } from '@reach/router';
import { formatAmount } from '@/shared/lib/formatting/number';
import CostOfWork from './components/CostOfWork';
import Markups from './components/Markups';
import ContractorFee from './components/ContractorFee';
import CostBreakDownModal from './CostBreakDownModal';
import { IActionsHints, IVerifySteps } from '..';
import { withinCushionedAmount } from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/utils';
import { useModal } from '@/shared/lib/hooks/useModal';
import { selectReasons } from 'bundles/Construction/reducers/ReconcileSlice';
import { CssVar } from '@/shared/config/cssVar';

export type TTypeCostBreakdown = 'workCosts' | 'markups' | 'fees' | false;

interface Props {
  showModalEdit: boolean;
  setShowModalEdit: SetStateAction<boolean>;
  verifyStep: IVerifySteps;
  actionsHints: IActionsHints;
}

function CostBreakdownDetails({
  showModalEdit,
  setShowModalEdit,
  verifyStep,
  actionsHints,
}: Props) {
  const params = useParams();
  const changeEvent = useAppSelector(selectChangeEvent);
  const changeOrderReasons = useAppSelector(selectReasons);
  const dispatch = useAppDispatch();
  const editCost = verifyStep.confirmValue;
  const editOnly = changeEvent.verified;
  const weightedAmount = changeEvent?.weightedAmount;
  const { confirm } = useModal();

  const [loading, setLoading] = useState(false);

  const constructionConfig = useAppSelector(
    (state) => state.developmentBudget?.legalEntity?.config,
  );
  const allReasons = changeOrderReasons?.filter((r) => r.active);
  const corReasons =
    allReasons &&
    changeEvent?.changeOrderReasons.map((reasonCor) =>
      allReasons.find((r) => r.id === reasonCor.id),
    );
  const workCosts =
    changeEvent?.workCosts && changeEvent.workCosts.length
      ? changeEvent.workCosts
      : changeEvent?.changeOrderReasons.map((reason) => ({ reason }));
  const markups = changeEvent?.markups || [];
  const fees = changeEvent?.fees || [];

  const workCostsSubtotal = () =>
    workCosts && workCosts.reduce((acc, wc) => acc + Number(wc.amount), 0);
  const markupsIncludedInFeeSubtotal = () =>
    markups
      .filter((m) => m.changeOrderMarkup?.includedInFee)
      .reduce((acc, m) => acc + Number(m.amount ?? 0), 0);
  const markupsNotIncludedInFeeSubtotal = () =>
    markups
      .filter((m) => !m.changeOrderMarkup?.includedInFee)
      .reduce((acc, m) => acc + Number(m.amount ?? 0), 0);
  const markupsSubtotal = () =>
    markupsIncludedInFeeSubtotal() + markupsNotIncludedInFeeSubtotal();
  const feesSubtotal = () => fees.reduce((acc, f) => acc + Number(f.amount), 0);
  const total = markupsSubtotal() + workCostsSubtotal() + feesSubtotal();

  const isValidTotal = () =>
    workCosts.length > 0 &&
    withinCushionedAmount(total, Number(weightedAmount));

  const workCostsValid = () =>
    workCosts &&
    workCosts.length > 0 &&
    workCosts.every((m) => m.costCode && m.reason && !isNaN(m.amount));

  const canSubmit = () =>
    workCostsValid() &&
    !changeEvent.costBreakdownConfirmed &&
    (markups.length === 0 ||
      markups.every((m) => m.costCode && !isNaN(m.amount))) &&
    (fees.length === 0 || fees.every((m) => m.costCode && !isNaN(m.amount)));

  const overrideRequired = Number(total) !== Number(weightedAmount);

  const confirmValueHandle = async () => {
    if (overrideRequired) {
      const confirmed = await confirm({
        title: 'Are you sure?',
        subtitle: "Your Verified Cost doesn't match the Original Amount.",
      });

      if (!confirmed) return;
    }

    setLoading(true);

    await dispatch(
      confirmValueChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
      }),
    );

    setLoading(false);
  };

  const invalidAmount = useMemo(
    () =>
      workCosts?.reduce(
        (acc, workCost) => acc + Number(workCost.amount ? workCost.amount : 0),
        0,
      ),
    [workCosts],
  );

  const invalidFeeAmount = useMemo(
    () =>
      invalidAmount +
      markups
        .filter((markup) => markup.costCode.includedInFee)
        .reduce(
          (acc, markup) => acc + Number(markup.amount ? markup.amount : 0),
          0,
        ),
    [workCosts, markups],
  );

  const removeReason = async (id: number) => {
    const newDataReasons = changeEvent?.changeOrderReasons
      .filter((reason) => reason.id !== id)
      .map((r) => r.id);
    await dispatch(
      updateChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
        data: {
          change_order_reason_ids: newDataReasons,
        },
      }),
    );
  };

  return (
    <div>
      {Boolean(!editCost || workCosts.length) && (
        <CostOfWork
          workCosts={workCosts}
          editOnly={editOnly}
          workCostsSubtotal={workCostsSubtotal}
          workCostsValid={workCostsValid}
          setShowModal={setShowModalEdit}
          editCost={editCost}
          actionsHints={actionsHints}
          removeReason={removeReason}
        />
      )}
      {Boolean((!editCost && !editOnly) || markups.length) && (
        <Markups
          markups={markups}
          editOnly={editOnly}
          markupsSubtotal={markupsSubtotal}
          setShowModal={setShowModalEdit}
          editCost={editCost}
          invalidAmount={invalidAmount}
        />
      )}
      {Boolean((!editCost && !editOnly) || fees.length) && (
        <ContractorFee
          fees={fees}
          editOnly={editOnly}
          feesSubtotal={feesSubtotal}
          setShowModal={setShowModalEdit}
          editCost={editCost}
          invalidAmount={invalidFeeAmount}
        />
      )}
      {workCosts && workCosts[0]?.amount && (
        <div>
          <div className="card-cost-breakdown-total">
            <div className="dark-60 inline-semibold">Total Cost Breakdown</div>
            <div>
              <div className="card-cost-breakdown-total__weighted">
                {formatAmount(weightedAmount)}
              </div>
              <div
                className={cn('card-cost-breakdown-total__price', {
                  'card-cost-breakdown-total__price_valid': !isValidTotal(),
                })}
              >
                {formatAmount(total)}
              </div>
            </div>
          </div>
          {canSubmit() && (
            <div className="flex items-center justify-between gap-tw-2 bg-light-5 px-tw-6 py-tw-4">
              <div className="flex">
                <Icon
                  iconName="info"
                  color={overrideRequired ? CssVar.red : CssVar.green}
                />
                <div className="dark-60 secondary-regular ml-tw-1 mt-tw-0.5">
                  {overrideRequired
                    ? 'Verified value does not match the value of COR. Please adjust or override the value'
                    : 'Verified value matches the value of COR'}
                </div>
              </div>
              <Button
                size="s"
                variant={overrideRequired ? 'danger' : 'success'}
                onClick={confirmValueHandle}
                disabled={loading}
                iconName={loading ? 'sync' : undefined}
                iconClasses={loading ? 'form-button-loading' : undefined}
              >
                {overrideRequired ? 'Override' : 'Confirm'}
              </Button>
            </div>
          )}
        </div>
      )}
      {showModalEdit && (
        <CostBreakDownModal
          showModalEdit={showModalEdit}
          setShowModalEdit={setShowModalEdit}
          workCosts={workCosts}
          markups={markups}
          fees={fees}
          scheduleOfValueCodes={
            constructionConfig.changeOrderConfig.scheduleOfValueCodes
          }
          editOnly={editOnly}
          recommendedReasonNames={corReasons}
          allReasons={allReasons}
          changeOrderMarkups={constructionConfig.changeOrderMarkups}
          changeOrderFees={constructionConfig.changeOrderFees}
          weightedAmount={weightedAmount}
        />
      )}
    </div>
  );
}

export default CostBreakdownDetails;

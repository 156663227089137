import { IMetaFundingSources } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/types/meta';
import { FUNDING_SOURCES_KIND_CONFIG } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/consts';
import { CssVar } from '@/shared/config/cssVar';
import { camelCase, sum } from 'lodash-es';

type Params = {
  sources?: IMetaFundingSources['stats']['sources'];
};

const FULL_PERCENT = 100;

export const useFundingStatistic = ({
  sources,
}: Params): {
  statistics: {
    color: string;
    value: number;
  }[];
  sumKinds: number;
} => {
  if (sources) {
    const sumKinds = sum(Object.values(sources).map((value) => Number(value)));
    const remainingValue = FULL_PERCENT - sumKinds;

    const statistics = Object.keys(FUNDING_SOURCES_KIND_CONFIG).map((key) => ({
      color: FUNDING_SOURCES_KIND_CONFIG[key].statColor,
      value: Number(sources[camelCase(key)]),
    }));

    statistics.push({
      color: CssVar.light10,
      value: remainingValue,
    });
    return {
      statistics,
      sumKinds,
    };
  }
  return {
    statistics: [
      {
        color: CssVar.light10,
        value: FULL_PERCENT,
      },
    ],
    sumKinds: 0,
  };
};

import React, { useEffect, useState } from 'react';
import http from 'lib/http';
import EmailTemplateCard from '../EmailTemplateCard';

const FeedMessageTemplatesTab = ({ children }) => {
  const [feedMessageTemplates, setFeedMessageTemplates] = useState([]);

  const fetchFeedMessageTemplates = () => http.get('/feed_message_templates').then((res) => res.json());

  useEffect(() => {
    fetchFeedMessageTemplates()
      .then((feedMessageTemplatesJson) => setFeedMessageTemplates(feedMessageTemplatesJson));
  }, []);

  const defaultTemplate = feedMessageTemplates.find((it) => it.default);

  return (
    <div>
      <h5 className="header5-regular light-60 font-weight-500 mt-[1.5rem] mb-tw-4">
        Default Template
      </h5>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-30">
          {defaultTemplate && (
            <EmailTemplateCard
              isDefault
              id={defaultTemplate.id}
              name={defaultTemplate.name}
              editPath={`./feed-messages/${defaultTemplate.id}/edit`}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default FeedMessageTemplatesTab;

import { AnimationLoader } from '@/stories';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { PropsWithChildren } from 'react';

const EntityItemListSkeletons = () =>
  Array.from({ length: 5 }, (_, idx) => (
    <div key={idx} className="flex bg-neutral-000 p-tw-4">
      <SkeletonBlock className="h-[52px] w-full" />
    </div>
  ));

export function EntityItemList({
  children,
  isFetching,
  isLoading,
}: PropsWithChildren & {
  isLoading: boolean;
  isFetching: boolean;
}) {
  return (
    <div className="relative flex flex-col gap bg-neutral-100 py-[1px]">
      {isFetching && !isLoading && <AnimationLoader withBg />}
      {isLoading ? <EntityItemListSkeletons /> : children}
    </div>
  );
}

import { useLoadedInvestmentObject } from '@/bundles/REturn/hooks/useLoadedInvestmentObject';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import {
  plainFetchCapitalInvestmentContributionTransactions as fetchContributionTransactions,
  plainFetchCapitalInvestmentDistributionTransactions as fetchDistributionTransactions,
} from 'bundles/REturn/actions/capitalInvestments';
import { CapitalEntry, ReportLineItem } from 'bundles/REturn/actions/types';
import { useEffect, useState } from 'react';

interface Params {
  type: TransactionSource['kind'];
  declaredAmount: ReportLineItem['amount'];
  effectDeps: unknown[];
}

export function useSuggestedEntries({
  type,
  declaredAmount,
  effectDeps,
}: Params) {
  const [loading, setLoading] = useState(false);
  const [suggestedEntries, setSuggestedEntries] = useState<CapitalEntry[]>([]);
  const {
    data: { id: investmentObjectId },
  } = useLoadedInvestmentObject();

  useEffect(() => {
    if (investmentObjectId === undefined) return;

    const loadTransactions = async () => {
      setLoading(true);
      const fetchEntries =
        type === 'contribution'
          ? fetchContributionTransactions
          : fetchDistributionTransactions;

      const data = await fetchEntries({
        investmentObjectId,
        maxAmount: Number(declaredAmount),
        notMapped: true,
      });
      setSuggestedEntries(data.items);
      setLoading(false);
    };
    loadTransactions();
  }, effectDeps);

  return {
    loading,
    suggestedEntries,
  };
}

import React, { createContext, useContext } from 'react';
import Field from 'stories/Field/Field';
import FormViewItem from 'bundles/Shared/components/GroupForm/FormViewItem/FormViewItem';
import { useViewFormContext } from 'bundles/Shared/components/GroupForm/new/ViewFormProvider';

type Props = React.ComponentProps<typeof Field>;

interface FormFieldContext {
  title: string;
}

const FormFieldContext = createContext<FormFieldContext | null>(null);

export const FormFieldProvider = ({
  title,
  children,
}: React.PropsWithChildren<FormFieldContext>) => (
  <FormFieldContext.Provider
    value={{
      title,
    }}
  >
    {children}
  </FormFieldContext.Provider>
);

export function useFormFieldContext() {
  const context = useContext(FormFieldContext);

  if (context === undefined || context === null) {
    throw new Error('useFormFieldContext was used outside of its Provider');
  }

  return context;
}

function FormField({ children, ...props }: Props) {
  const { viewMode } = useViewFormContext();

  return (
    <FormFieldProvider title={props.labelText}>
      {!viewMode ? <Field {...props}>{children}</Field> : children}
    </FormFieldProvider>
  );
}

const Editor = ({ children }: React.PropsWithChildren) => {
  const { viewMode } = useViewFormContext();
  return !viewMode ? <>{children}</> : null;
};

const View = ({
  children,
  ...props
}: React.ComponentProps<typeof FormViewItem>) => {
  const { viewMode } = useViewFormContext();
  const { title } = useFormFieldContext();

  return viewMode ? (
    <FormViewItem title={title} {...props}>
      {children}
    </FormViewItem>
  ) : null;
};

FormField.Editor = Editor;

FormField.View = View;

export default FormField;

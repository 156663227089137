import FundAssetList from 'bundles/Settings/components/Portal/FundDashboard/ui/FundAssetList';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { ModalHeaderWithIcon } from 'stories/index';
import { IFund } from 'types/Fund';
import { SidePanel } from 'stories/Modals/Modal/Modal';

function FundAssetsPanel({ fund, onClose }: { fund: IFund } & DialogProps) {
  return (
    <SidePanel
      toggle={onClose}
      maxHeight
      classes={{
        body: 'bg-neutral-150 !p-0 flex flex-col',
      }}
      zIndex={HUGE_MODAL_Z_INDEX - 1}
      header={<ModalHeaderWithIcon title="Assets" />}
    >
      <FundAssetList assets={fund?.assets ?? []} />
    </SidePanel>
  );
}
export default FundAssetsPanel;

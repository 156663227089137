import http from 'lib/http';
import { stringify } from 'qs';
import { IReportLineItemOnIndex } from 'types/ReportLineItem';
import { getOptionsForEmptyArray } from 'lib/stringify';

export type TSortFields =
  | 'id'
  | 'date'
  | 'balance'
  | 'transaction_value'
  | 'period'
  | 'legal_entity_code'
  | 'vendor_entity_code';
export type TFilterFields = {
  transaction_type?: 'debit' | 'credit';
  only_published?: boolean;
  general_ledger_id?: number[];
  legal_entity_id?: number[];
  report_data_import_id?: number[];
  periods?: string[];
};

export interface IFetchArgs extends TFilterFields {
  page?: number;
  per_page?: number;
  query?: string;
  report_table_config_id?: number;
  sort: {
    field?: TSortFields;
    order?: 'asc' | 'desc' | null;
  };
}

interface IFetchResponse {
  items: IReportLineItemOnIndex[];
  meta: {
    perPage: number;
    totalSize: number;
  };
}

export async function fetchReportLineItems(data: IFetchArgs) {
  const params = stringify(data, {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    ...getOptionsForEmptyArray(),
  });
  const res = await http.get(`/report/line_items${params}`);
  const responseData = await res.json();

  return responseData as IFetchResponse;
}

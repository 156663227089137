import { emptySplitApi as api } from '@/app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValues:
      build.query<
        GetApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiResponse,
        GetApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/manual_variables/${queryArg.manualVariableId}/legal_entities/${queryArg.legalEntityId}/values`,
        }),
      }),
    postApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValues:
      build.mutation<
        PostApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiResponse,
        PostApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/manual_variables/${queryArg.manualVariableId}/legal_entities/${queryArg.legalEntityId}/values`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesId:
      build.mutation<
        PutApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiResponse,
        PutApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/manual_variables/${queryArg.manualVariableId}/legal_entities/${queryArg.legalEntityId}/values/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesId:
      build.mutation<
        DeleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiResponse,
        DeleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report/manual_variables/${queryArg.manualVariableId}/legal_entities/${queryArg.legalEntityId}/values/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    getApiReportManualVariablesByManualVariableIdLegalEntities: build.query<
      GetApiReportManualVariablesByManualVariableIdLegalEntitiesApiResponse,
      GetApiReportManualVariablesByManualVariableIdLegalEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/manual_variables/${queryArg.manualVariableId}/legal_entities`,
        params: {
          query: queryArg.query,
          sort: queryArg.sort,
          legal_entities_kind: queryArg.legalEntitiesKind,
          page: queryArg.page,
          size_per_page: queryArg.sizePerPage,
        },
      }),
    }),
    getApiReportManualVariables: build.query<
      GetApiReportManualVariablesApiResponse,
      GetApiReportManualVariablesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/manual_variables`,
        params: {
          query: queryArg.query,
          filter_by_not_configured: queryArg.filterByNotConfigured,
        },
      }),
    }),
    postApiReportManualVariables: build.mutation<
      PostApiReportManualVariablesApiResponse,
      PostApiReportManualVariablesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/manual_variables`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiReportManualVariablesByIdExport: build.query<
      GetApiReportManualVariablesByIdExportApiResponse,
      GetApiReportManualVariablesByIdExportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/manual_variables/${queryArg.id}/export`,
      }),
    }),
    putApiReportManualVariablesById: build.mutation<
      PutApiReportManualVariablesByIdApiResponse,
      PutApiReportManualVariablesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/manual_variables/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReportManualVariablesById: build.mutation<
      DeleteApiReportManualVariablesByIdApiResponse,
      DeleteApiReportManualVariablesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/manual_variables/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiReportManualVariablesByIdImport: build.mutation<
      PostApiReportManualVariablesByIdImportApiResponse,
      PostApiReportManualVariablesByIdImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/manual_variables/${queryArg.id}/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportManualVariablesApi };
export type GetApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiResponse =
  /** status 200 success */ {
    items: ReportManualVariableLegalEntityValueDto[];
  };
export type GetApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiArg =
  {
    manualVariableId: string;
    legalEntityId: string;
  };
export type PostApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiResponse =
  unknown;
export type PostApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesApiArg =
  {
    manualVariableId: string;
    legalEntityId: string;
    body: {
      legal_entity_id?: string;
      date_from?: string;
      date_to?: string;
      value?: number;
    };
  };
export type PutApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiResponse =
  unknown;
export type PutApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiArg =
  {
    manualVariableId: string;
    legalEntityId: string;
    id: string;
    body: {
      date_from?: string;
      date_to?: string;
      value?: number;
    };
  };
export type DeleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiResponse =
  unknown;
export type DeleteApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdApiArg =
  {
    manualVariableId: string;
    legalEntityId: string;
    id: string;
  };
export type GetApiReportManualVariablesByManualVariableIdLegalEntitiesApiResponse =
  /** status 200 success */ {
    items: ReportManualVariableLegalEntityDto[];
    meta: {
      totalSize: number;
      configuredLegalEntitiesCount: number;
      notConfiguredLegalEntitiesCount: number;
    };
  };
export type GetApiReportManualVariablesByManualVariableIdLegalEntitiesApiArg = {
  manualVariableId: string;
  query?: string;
  sort?: {
    field?: 'actial_value';
    order?: 'asc' | 'desc';
  };
  legalEntitiesKind: 'configured' | 'not_configured';
  page?: number;
  sizePerPage?: number;
};
export type GetApiReportManualVariablesApiResponse = /** status 200 success */ {
  items: ReportManualVariableDto[];
  meta: {
    notFullyConfiguredVariables: number;
  };
};
export type GetApiReportManualVariablesApiArg = {
  query?: string;
  /** Pass true to filter */
  filterByNotConfigured?: boolean;
};
export type PostApiReportManualVariablesApiResponse = unknown;
export type PostApiReportManualVariablesApiArg = {
  body: {
    name: string;
    label: string;
    value_type: string;
    period_aggregation_strategy: string;
    description?: string;
    tags: {
      id?: string;
      name?: string;
    }[];
  };
};
export type GetApiReportManualVariablesByIdExportApiResponse = unknown;
export type GetApiReportManualVariablesByIdExportApiArg = {
  id: string;
};
export type PutApiReportManualVariablesByIdApiResponse = unknown;
export type PutApiReportManualVariablesByIdApiArg = {
  id: string;
  body: {
    label?: string;
    value_type?: string;
    period_aggregation_strategy?: string;
    description?: string;
    tags?: {
      id?: string;
      name?: string;
    }[];
  };
};
export type DeleteApiReportManualVariablesByIdApiResponse = unknown;
export type DeleteApiReportManualVariablesByIdApiArg = {
  id: string;
};
export type PostApiReportManualVariablesByIdImportApiResponse = unknown;
export type PostApiReportManualVariablesByIdImportApiArg = {
  id: string;
  body: {
    file: Blob;
  };
};
export type ReportManualVariableLegalEntityValueDto = {
  id: string;
  dateFrom: string | null;
  dateTo: string | null;
  value: number;
  valueType: 'number' | 'percentage' | 'currency' | 'sqft' | 'metric';
};
export type ReportManualVariableLegalEntityDto = {
  id: string;
  name: string;
  dateFrom: string | null;
  dateTo: string | null;
  value: number;
  valueType: 'number' | 'percentage' | 'currency' | 'sqft' | 'metric';
};
export type ReportManualVariableReferencingFormulaDto = {
  id: string;
  label: string;
};
export type ReportManualVariabledReferencingScorecardDto = {
  id: string;
  title: string;
};
export type ReportManualVariableReferencingTableDto = {
  id: number;
  name: string;
};
export type ReportManualVariableReferencingWidgetDto = {
  id: string;
  title: string;
  widgetTpe?:
    | 'historical_review_table'
    | 'kpi_table_single_date'
    | 'kpi_table'
    | 'kpi_card';
};
export type ReportManualVariableTagDto = {
  id: string;
  name: string;
  kind: 'system' | 'other';
};
export type ReportManualVariableDto = {
  name: string;
  label: string;
  configuredLegalEntitiesCount: number;
  notConfiguredLegalEntitiesCount: number;
  valueType: 'number' | 'percentage' | 'currency' | 'sqft' | 'metric';
  periodAggregationStrategy:
    | 'sum'
    | 'first'
    | 'last'
    | 'avg'
    | 'avg_time_weighted'
    | 'min'
    | 'max';
  description: string;
  canDestroy: boolean;
  referencingFormulas: ReportManualVariableReferencingFormulaDto[];
  referencingScorecards: ReportManualVariabledReferencingScorecardDto[];
  referencingTables: ReportManualVariableReferencingTableDto[];
  referencingScoreboardWidgets: ReportManualVariableReferencingWidgetDto[];
  tags: ReportManualVariableTagDto[];
};

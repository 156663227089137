import { UnderwritingBudget } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { STATUS_OPTIONS_FOR_DRAFTS } from 'bundles/Settings/components/Portal/shared/consts';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { values } from 'lodash-es';
import { ComponentProps } from 'react';
import {
  GroupContainerItem,
  Icon,
  Popover,
  SelectableItem,
} from 'stories/index';
import { cn } from '@/shared/lib/css/cn';

function StatusDropdown({
  status,
  onStatusSelect,
  children,
  dropdownDisabled,
}: {
  status: UnderwritingBudget['state'];
  onStatusSelect: (s: UnderwritingBudget['state']) => void;
  dropdownDisabled?: boolean;
} & Pick<ComponentProps<typeof Popover>, 'children'>) {
  const options = STATUS_OPTIONS_FOR_DRAFTS;

  const handleUpdateStatus = (option: Values<typeof options>) => {
    onStatusSelect(option.value);
  };
  return (
    <Popover
      appendToBody
      placement="bottom-end"
      offset={[0, 0]}
      className="w-[184px] p-0 !pt-tw-1"
      trigger="click"
      disabled={dropdownDisabled}
      hiddenArrow
      zIndex={HUGE_MODAL_Z_INDEX - 1}
      template={
        <GroupContainerItem title="Options:" className="py-tw-1">
          {values(options).map((option) => (
            <SelectableItem
              key={option.value}
              id={option.value}
              text={
                <div className="flex items-center gap-tw-0.5">
                  <Icon
                    iconName={option.iconName}
                    className={option.className}
                  />
                  <p className={cn('secondary-regular text-neutral-900')}>
                    {option.label}
                  </p>
                </div>
              }
              onClick={() => handleUpdateStatus(option)}
              selected={option.value === status}
              className="min-h-fit"
            />
          ))}
        </GroupContainerItem>
      }
    >
      {children}
    </Popover>
  );
}

export default StatusDropdown;

import { emptySplitApi as api } from '../../../../app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiReconcileBudgetMetrics: build.mutation<
      PostApiReconcileBudgetMetricsApiResponse,
      PostApiReconcileBudgetMetricsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/budget_metrics`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiReconcileBudgetMetricsById: build.mutation<
      PutApiReconcileBudgetMetricsByIdApiResponse,
      PutApiReconcileBudgetMetricsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/budget_metrics/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategories:
      build.query<
        GetApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiResponse,
        GetApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/financial_categories`,
        }),
      }),
    putApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategories:
      build.mutation<
        PutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiResponse,
        PutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/financial_categories`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    getApiReconcileUnderwritingBudgetsLegalEntities: build.query<
      GetApiReconcileUnderwritingBudgetsLegalEntitiesApiResponse,
      GetApiReconcileUnderwritingBudgetsLegalEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/legal_entities`,
        params: {
          query: queryArg.query,
          state: queryArg.state,
          per_page: queryArg.perPage,
          page: queryArg.page,
        },
      }),
    }),
    getApiReconcileUnderwritingBudgetsByBudgetIdMetrics: build.query<
      GetApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiResponse,
      GetApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/metrics`,
        params: { query: queryArg.query },
      }),
    }),
    postApiReconcileUnderwritingBudgetsByBudgetIdMetrics: build.mutation<
      PostApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiResponse,
      PostApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/metrics`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricId:
      build.mutation<
        DeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdApiResponse,
        DeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/metrics/${queryArg.budgetMetricId}`,
          method: 'DELETE',
        }),
      }),
    putApiReconcileUnderwritingBudgetsByBudgetIdValuesBulk: build.mutation<
      PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiResponse,
      PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/values/bulk`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    postApiReconcileUnderwritingBudgets: build.mutation<
      PostApiReconcileUnderwritingBudgetsApiResponse,
      PostApiReconcileUnderwritingBudgetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiReconcileUnderwritingBudgetsSourceLegalEntities: build.query<
      GetApiReconcileUnderwritingBudgetsSourceLegalEntitiesApiResponse,
      GetApiReconcileUnderwritingBudgetsSourceLegalEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/source_legal_entities`,
        params: {
          query: queryArg.query,
          per_page: queryArg.perPage,
          page: queryArg.page,
        },
      }),
    }),
    postApiReconcileUnderwritingBudgetsCreateFromSource: build.mutation<
      PostApiReconcileUnderwritingBudgetsCreateFromSourceApiResponse,
      PostApiReconcileUnderwritingBudgetsCreateFromSourceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/create_from_source`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiReconcileUnderwritingBudgetsByBudgetId: build.query<
      GetApiReconcileUnderwritingBudgetsByBudgetIdApiResponse,
      GetApiReconcileUnderwritingBudgetsByBudgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}`,
      }),
    }),
    putApiReconcileUnderwritingBudgetsByBudgetId: build.mutation<
      PutApiReconcileUnderwritingBudgetsByBudgetIdApiResponse,
      PutApiReconcileUnderwritingBudgetsByBudgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReconcileUnderwritingBudgetsByBudgetId: build.mutation<
      DeleteApiReconcileUnderwritingBudgetsByBudgetIdApiResponse,
      DeleteApiReconcileUnderwritingBudgetsByBudgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}`,
        method: 'DELETE',
      }),
    }),
    getApiReconcileUnderwritingBudgetsByBudgetIdExport: build.query<
      GetApiReconcileUnderwritingBudgetsByBudgetIdExportApiResponse,
      GetApiReconcileUnderwritingBudgetsByBudgetIdExportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/export`,
      }),
    }),
    postApiReconcileUnderwritingBudgetsByBudgetIdImport: build.mutation<
      PostApiReconcileUnderwritingBudgetsByBudgetIdImportApiResponse,
      PostApiReconcileUnderwritingBudgetsByBudgetIdImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/underwriting/budgets/${queryArg.budgetId}/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as underwritingBudgetGeneratedApi };
export type PostApiReconcileBudgetMetricsApiResponse =
  /** status 200 success */ BudgetMetric;
export type PostApiReconcileBudgetMetricsApiArg = {
  body: {
    label: string;
    name: string;
    value_type:
      | 'number'
      | 'percentage'
      | 'currency'
      | 'sqft'
      | 'metric'
      | 'date';
    period_aggregation_strategy:
      | 'first'
      | 'last'
      | 'sum'
      | 'avg'
      | 'avg_time_weighted'
      | 'min'
      | 'max';
    description: string | null;
  };
};
export type PutApiReconcileBudgetMetricsByIdApiResponse =
  /** status 200 success */ BudgetMetric;
export type PutApiReconcileBudgetMetricsByIdApiArg = {
  id: string;
  body: {
    label?: string;
    name?: string;
    value_type?:
      | 'number'
      | 'percentage'
      | 'currency'
      | 'sqft'
      | 'metric'
      | 'date';
    period_aggregation_strategy?:
      | 'first'
      | 'last'
      | 'sum'
      | 'avg'
      | 'avg_time_weighted'
      | 'min'
      | 'max';
    description?: string | null;
  };
};
export type GetApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiResponse =
  /** status 200 success */ {
    tree: UnderwritingBudgetFinancialCategoriesTreeNode[];
  };
export type GetApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiArg =
  {
    budgetId: string;
  };
export type PutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiResponse =
  unknown;
export type PutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesApiArg =
  {
    budgetId: string;
    body: {
      financial_category_ids: number[];
    };
  };
export type GetApiReconcileUnderwritingBudgetsLegalEntitiesApiResponse =
  /** status 200 success */ {
    items: LegalEntityUnderwritingBudget[];
    meta: Pagination;
  };
export type GetApiReconcileUnderwritingBudgetsLegalEntitiesApiArg = {
  query?: string;
  state?: 'no_budget' | 'draft' | 'published';
  perPage?: number;
  page?: number;
};
export type GetApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiResponse =
  /** status 200 success */ {
    items: UnderwritingBudgetMetric[];
  };
export type GetApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiArg = {
  budgetId: string;
  query?: string;
};
export type PostApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiResponse =
  unknown;
export type PostApiReconcileUnderwritingBudgetsByBudgetIdMetricsApiArg = {
  budgetId: string;
  body: {
    metric_id: string;
  };
};
export type DeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdApiResponse =
  unknown;
export type DeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdApiArg =
  {
    budgetId: string;
    budgetMetricId: string;
  };
export type PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiResponse =
  /** status 200 success */ UnderwritingBudget;
export type PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiArg = {
  budgetId: string;
  body: (
    | {
        period_key: string;
        budget_financial_category_id: string;
        cents: number | null;
      }
    | {
        period_key: string;
        budget_metric_id: string;
        value: number | null;
      }
  )[];
};
export type PostApiReconcileUnderwritingBudgetsApiResponse =
  /** status 200 success */ UnderwritingBudget;
export type PostApiReconcileUnderwritingBudgetsApiArg = {
  body: {
    legal_entity_id: string;
    date_from: string;
    date_to: string;
  };
};
export type GetApiReconcileUnderwritingBudgetsSourceLegalEntitiesApiResponse =
  /** status 200 success */ {
    items: {
      id: string;
      code: string;
      name: string;
    }[];
    meta: {
      perPage: number | null;
      totalSize: number;
    };
  };
export type GetApiReconcileUnderwritingBudgetsSourceLegalEntitiesApiArg = {
  query?: string;
  perPage?: number;
  page?: number;
};
export type PostApiReconcileUnderwritingBudgetsCreateFromSourceApiResponse =
  /** status 200 success */ UnderwritingBudget;
export type PostApiReconcileUnderwritingBudgetsCreateFromSourceApiArg = {
  body: {
    legal_entity_id: string;
    source_legal_entity_id: string;
  };
};
export type GetApiReconcileUnderwritingBudgetsByBudgetIdApiResponse =
  /** status 200 success */ UnderwritingBudget;
export type GetApiReconcileUnderwritingBudgetsByBudgetIdApiArg = {
  budgetId: string;
};
export type PutApiReconcileUnderwritingBudgetsByBudgetIdApiResponse =
  /** status 200 success */ UnderwritingBudget;
export type PutApiReconcileUnderwritingBudgetsByBudgetIdApiArg = {
  budgetId: string;
  body: {
    date_from?: string;
    date_to?: string;
    state?: 'draft' | 'published';
  };
};
export type DeleteApiReconcileUnderwritingBudgetsByBudgetIdApiResponse =
  unknown;
export type DeleteApiReconcileUnderwritingBudgetsByBudgetIdApiArg = {
  budgetId: string;
};
export type GetApiReconcileUnderwritingBudgetsByBudgetIdExportApiResponse =
  unknown;
export type GetApiReconcileUnderwritingBudgetsByBudgetIdExportApiArg = {
  budgetId: string;
};
export type PostApiReconcileUnderwritingBudgetsByBudgetIdImportApiResponse =
  /** status 200 success */ UnderwritingBudget;
export type PostApiReconcileUnderwritingBudgetsByBudgetIdImportApiArg = {
  budgetId: string;
  body: {
    file: Blob;
  };
};
export type BudgetMetric = {
  id: string;
  label: string;
  name: string;
  valueType: 'number' | 'percentage' | 'currency' | 'sqft' | 'metric' | 'date';
  periodAggregationStrategy: 'first' | 'last' | 'sum';
  description: string | null;
};
export type UnderwritingBudgetFinancialCategoriesTreeNode = {
  id: number;
  code: string;
  parentId: number | null;
  classifications: (
    | 'multifamily'
    | 'retail'
    | 'hospitality'
    | 'office'
    | 'senior_living'
    | 'student'
    | 'mixed_use'
    | 'spv'
    | 'op_co'
    | 'commercial'
  )[];
  classificationMismatch: boolean;
  budgetFinancialCategory: {
    id: string;
  } | null;
  children: UnderwritingBudgetFinancialCategoriesTreeNode[];
};
export type LegalEntityUnderwritingBudget = {
  id: string;
  code?: string;
  name?: string;
  state: 'no_budget' | 'draft' | 'published';
  budget: {
    id: string;
    dateFrom: DateString;
  } | null;
  financial: {
    firstPeriod: DateString;
  } | null;
  asset: {
    id: number;
    name: string;
  };
};
export type Pagination = {
  perPage: number;
  totalSize: number;
};
export type UnderwritingBudgetMetric = BudgetMetric & {
  budgetMetric: {
    id: string;
  } | null;
};
export type UnderwritingBudgetFinancialCategoryRow = {
  id: string | null;
  categoryId: number;
  label: string;
  values: {
    [key: string]: number | null;
  };
  children: UnderwritingBudgetFinancialCategoryRow[];
};
export type UnderwritingBudget = {
  id: string;
  legalEntityId: string;
  legalEntity?: {
    id: string;
    code: string;
    name: string;
    assetName: string;
  };
  dateFrom: string;
  dateTo: string;
  state: 'draft' | 'published';
  periods: {
    key: string;
    date: string;
  }[];
  budgetFinancialCategoryRows: UnderwritingBudgetFinancialCategoryRow[];
  budgetMetricRows: {
    id: string;
    label: string;
    valueType:
      | 'number'
      | 'percentage'
      | 'currency'
      | 'sqft'
      | 'metric'
      | 'date';
    values: {
      [key: string]: number | null;
    };
  }[];
};
export type UnprocessableEntity = {
  errors?: string[];
};

import { stringify } from 'qs';
import http from 'lib/http';
import { IPipelineCard } from 'bundles/Settings/components/DealPath/PipelineCards';

export const FETCH_DASHBOARD = 'symmetre-client-api/FETCH_DASHBOARD';
export const UPDATE_DASHBOARD = 'symmetre-client-api/UPDATE_DASHBOARD';
export const FETCH_PIPELINE_CARDS = 'symmetre-client-api/FETCH_PIPELINE_CARDS';
export const FETCH_PIPELINE_CARD = 'symmetre-client-api/FETCH_PIPELINE_CARD';
export const UPDATE_PIPELINE_CARD = 'symmetre-client-api/UPDATE_PIPELINE_CARD';
export const FETCH_SNAPSHOT = 'symmetre-client-api/FETCH_SNAPSHOT';
export const FETCH_EMPLOYEE_UTILIZATION =
  'symmetre-client-api/FETCH_EMPLOYEE_UTILIZATION';
export const FETCH_PIPELINE_VELOCITY =
  'symmetre-client-api/FETCH_PIPELINE_VELOCITY';
export const FETCH_PIPELINE_GOALS_SUMMARY =
  'symmetre-client-api/FETCH_PIPELINE_GOALS_SUMMARY';
export const FETCH_PROPERTY_TYPES = 'symmetre-client-api/FETCH_PROPERTY_TYPES';

export type TCardScope = 'gantt-chart' | 'static-card' | 'snapshot';

export function fetchDashboard(dealSubType) {
  return async function (dispatch) {
    const res = await http.get(`/deals/${dealSubType}/dashboard`);
    const responseData = await res.json();

    dispatch({
      type: FETCH_DASHBOARD,
      payload: responseData,
    });
  };
}

export function fetchEmployeeUtilization(params) {
  return async function (dispatch) {
    const query = stringify(params);
    const res = await http.get(`/deals/employee_utilization?${query}`);
    const responseData = await res.json();

    dispatch({
      type: FETCH_EMPLOYEE_UTILIZATION,
      payload: responseData,
    });
  };
}

export function fetchVelocityData(dealSubType) {
  return async function (dispatch) {
    const res = await http.get(`/deals/velocity?scope=${dealSubType}`);
    const responseData = await res.json();

    dispatch({
      type: FETCH_PIPELINE_VELOCITY,
      payload: responseData,
    });
  };
}

export function fetchGoalsSummary(dealSubType) {
  return async function (dispatch) {
    const res = await http.get(`/deals/goals_summary?scope=${dealSubType}`);
    const responseData = await res.json();

    dispatch({
      type: FETCH_PIPELINE_GOALS_SUMMARY,
      payload: responseData,
    });
  };
}

export function pureFetchPipelineCards(scope: TCardScope[]) {
  const query = {
    active: true,
    view: 'with_data',
    by_card_type: scope,
  };

  return http.get(
    `/pipeline/pipeline_cards${stringify(query, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    })}`,
  );
}

export function fetchPipelineCards(scope: TCardScope[]) {
  return async function (dispatch) {
    const res = await pureFetchPipelineCards(scope);
    const responseData = await res.json();

    dispatch({ type: FETCH_PIPELINE_CARDS, payload: responseData });
  };
}

export function fetchPipelineCard(card: IPipelineCard, propertyType: string) {
  return async function (dispatch) {
    const query = stringify(
      { scope: propertyType },
      {
        addQueryPrefix: true,
      },
    );

    const res = await http.get(`/pipeline/pipeline_cards/${card.id}${query}`);
    const responseData = await res.json();

    dispatch({ type: FETCH_PIPELINE_CARD, payload: responseData });
  };
}

export function updateStaticCard(params, reqBody) {
  return async function (dispatch) {
    const path = `/pipeline/pipeline_cards/${params.id}`;
    const res = await http.put(path, reqBody);
    const data = await res.json();

    if (data.errors) return;

    window.toastr.success('The Static card has been updated');

    dispatch({ type: UPDATE_PIPELINE_CARD, payload: data });
  };
}

export function fetchPropertyTypes() {
  return async function (dispatch) {
    const res = await http.get('/pipeline/property_types');
    const payload = await res.json();

    dispatch({ type: FETCH_PROPERTY_TYPES, payload });
  };
}

export function fetchSnapshot(snapshot, scope) {
  return async function (dispatch) {
    const res = await http.get(`/deals/snapshot/${snapshot}?scope=${scope}`);
    const data = await res.json();

    dispatch({ type: FETCH_SNAPSHOT, payload: { [snapshot]: data } });
  };
}

import { Contribution } from '@/entities/return';
import { useGetApiCapitalInvestmentContributionsQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  GetApiCapitalInvestmentContributionsApiArg,
  GetApiCapitalInvestmentContributionsApiResponse,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { convertCentsToDollars } from '@/shared/lib/converters';

export function useCapitalContributions(
  arg: GetApiCapitalInvestmentContributionsApiArg,
) {
  const prepareData = useMemo(() => {
    const emptyArray: GetApiCapitalInvestmentContributionsApiResponse['items'] =
      [];
    return createSelector(
      (data: GetApiCapitalInvestmentContributionsApiResponse | undefined) => {
        return data?.items ?? emptyArray;
      },
      (contributions) => {
        return contributions.map(
          (item) =>
            ({
              ...item,
              amount: convertCentsToDollars(item.amountCents),
              _type: 'contribution',
            }) as Contribution,
        );
      },
    );
  }, []);

  return useGetApiCapitalInvestmentContributionsQuery(arg, {
    selectFromResult: (res) => {
      return {
        ...res,
        contributions: prepareData(res.data),
      };
    },
  });
}

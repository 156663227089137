import React from 'react';
import { Link } from '@reach/router';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/InvestmentObjects/components/menu/ReconcileMenu/ReconcileMenu.module.scss';

const ReconcileMenuItemLink = ({
  children,
  className,
  isRoot,
  to,
  ...props
}: React.ComponentProps<typeof Link> & { isRoot: boolean }) => (
  <Link
    to={to}
    getProps={({ [isRoot ? 'isCurrent' : 'isPartiallyCurrent']: active }) => ({
      className: cn(
        styles.reconcileMenuItem,
        active && styles.reconcileMenuItem_active,
        'inline-regular',
        className,
      ),
    })}
    {...props}
  >
    {children}
  </Link>
);

export default ReconcileMenuItemLink;

import React from 'react';
import { InlineObject, Popover, PseudoLink } from 'stories';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props {
  title: StringAndOptions<'Set object for'>;
  currentLegalEntities: LegalEntity[];
}

export function ModalHeaderForUpdatingLE({
  currentLegalEntities,
  title,
}: Props) {
  return currentLegalEntities.length > 1 ? (
    <div className="header6-bold text-neutral-900">
      {title}{' '}
      <Popover
        template={
          <div>
            <div className="secondary-semibold mb-tw-2 text-neutral-900">
              Selected Legal Entities
            </div>
            <div className="flex flex-wrap gap-tw-1">
              {currentLegalEntities.map((legalEntity) => (
                <InlineObject key={legalEntity.id} object={legalEntity.name} />
              ))}
            </div>
          </div>
        }
        maxWidth="27.5rem"
        appendTo={() => document.body}
      >
        <PseudoLink>{currentLegalEntities.length} Legal Entities</PseudoLink>
      </Popover>
    </div>
  ) : (
    <span className="dark-60 header6-bold">
      {title} {currentLegalEntities[0]?.name}
    </span>
  );
}

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories';
import styles from './Chip.module.scss';

interface Props {
  label: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

function Chip({ leftIcon, rightIcon, onClose, label, className }: Props) {
  const canClose = onClose != null;

  return (
    <div
      className={cn(
        styles.chip,
        {
          [styles.chip_closable]: canClose,
          [styles.chip_withRightIcon]: rightIcon != null && rightIcon !== false,
        },
        className,
      )}
    >
      {leftIcon}
      <span
        className={cn('inline-semibold', {
          'mr-xs': rightIcon != null && rightIcon !== false,
          'ml-xs': leftIcon != null && leftIcon !== false,
        })}
      >
        {label}
      </span>
      {rightIcon}
      {canClose && (
        <div className={styles.close}>
          <IconButton
            className="border-0 bg-white"
            onClick={onClose}
            iconName="closeSmall"
          />
        </div>
      )}
    </div>
  );
}

export default Chip;

import React, { useState } from 'react';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import Field, { FieldsContainer } from 'stories/Field/Field';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import Button from 'stories/Button/Button';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  useUpdateListPropertyWidgetConfig,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib';
import { IconButton } from 'stories';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import {
  PeriodShiftField,
  UpdateWidgetTitle,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { useTabs } from 'stories/Tabs/useTabs';
import { DragDropContext } from '@hello-pangea/dnd';
import { DefaultColumnsTab } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { HistoricalTableWidgetConfigColumn } from './column';
import { HistoricalTableWidgetConfigRow } from './row';
import { useForm } from 'react-hook-form';
import {
  HistoricalTableWidgetConfigForm,
  transformHistoricalTableWidgetConfigToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/component.form';
import {
  moveColumn,
  updateDefaultOptions,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/updaters';
import { RecapPageField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/RecapPageField';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
  {
    id: 'rows',
    label: 'Rows',
  },
] as const satisfies readonly IThinTabItem[];

const RowsTab = () => {
  const [modalState, setModalState] = useState<'create' | null>(null);
  const { widget } =
    useWidgetConfig<
      typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    >();
  const { rows = [] } = widget.widgetConfig;
  const {
    removeItem: removeRow,
    moveItem: moveRow,
    cloneItem: cloneRow,
  } = useUpdateListPropertyWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE,
    'rows',
  );

  return (
    <Field
      labelText="Explore Computed Rows"
      note="This interactive panel allows users to add rows for various financial formulas and variables."
    >
      <WidgetSettingsListLayout>
        <WidgetSettingsListLayout.Header
          label={rows.length === 0 ? 'No Rows Yet' : `${rows.length} ROWS`}
        >
          <Button
            onClick={() => {
              setModalState('create');
            }}
            size="xs"
            variant="secondary"
          >
            Add
          </Button>
        </WidgetSettingsListLayout.Header>
        <DragDropContext
          onDragEnd={({ source, destination }) => {
            if (destination == null) {
              return;
            }
            moveRow(source.index, destination.index);
          }}
        >
          <WidgetSettingsListLayout.SortableList>
            {rows.map((row, index) => (
              <WidgetSettingsListLayout.SortableListItem
                index={index}
                key={row.key}
                draggableId={row.key.toString()}
                listIndex={index}
                className="group/item"
              >
                <WidgetSettingsListLayout.ListItem.Text>
                  {row.label}
                </WidgetSettingsListLayout.ListItem.Text>
                <div className="grow" />
                <WidgetSettingsListLayout.ListItem.ActionsPanel>
                  {isFormulaByExpression(row.expression) && (
                    <InspectFormulaButton reference={row.expression} />
                  )}
                  <IconButton
                    iconName="copy"
                    onClick={() => {
                      cloneRow(row.key);
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      setModalState({
                        rowConfig: row,
                        rowSettings: widget.widgetConfig.viz_config?.rows.find(
                          (r) => r.key === row.key.toString(),
                        ),
                      });
                    }}
                    iconName="edit"
                  />
                  <IconButton
                    onClick={() => {
                      removeRow(row.key);
                    }}
                    iconName="trash"
                  />
                </WidgetSettingsListLayout.ListItem.ActionsPanel>
              </WidgetSettingsListLayout.SortableListItem>
            ))}
          </WidgetSettingsListLayout.SortableList>
        </DragDropContext>
      </WidgetSettingsListLayout>
      {modalState != null && (
        <HistoricalTableWidgetConfigRow
          type={modalState === 'create' ? 'create' : 'edit'}
          {...modalState}
          onClose={() => setModalState(null)}
          onSubmit={(res) => {
            setModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setModalState('create');
              });
            }
          }}
        />
      )}
    </Field>
  );
};

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<
      typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    >();
  const methods = useForm<HistoricalTableWidgetConfigForm>({
    defaultValues: {
      ...transformHistoricalTableWidgetConfigToForm(widget.widgetConfig),
    },
  });
  const { control } = methods;

  useUpdateWidgetConfigEffect<
    HistoricalTableWidgetConfigForm,
    typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
  >(methods, updateDefaultOptions);

  return (
    <FieldsContainer>
      <UpdateWidgetTitle />
      <Field labelText="Period Shift">
        <PeriodShiftField control={control} name="period_shift" />
      </Field>
      <RecapPageField />
    </FieldsContainer>
  );
};

export function HistoricalTableWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);

  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            ColumnModal={HistoricalTableWidgetConfigColumn}
            widgetType={OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE}
            actionsOverrides={{
              moveColumn,
            }}
          />
        )}
        {tab?.id === 'rows' && <RowsTab />}
      </div>
    </div>
  );
}

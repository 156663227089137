import React, { useState } from 'react';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import { connect, ConnectedProps } from 'react-redux';
import { Badge, LinkButton, Modal } from 'stories';
import { useModal } from '@/shared/lib/hooks/useModal';
import CardWithToggle from '../../DealPath/CardWithToggle';
import {
  createDocumentType,
  deleteDocumentType,
  updateDocumentType,
} from '../../../actions/documentType';
import DocumentTypeFields from './DocumentTypeFields';
import { IDocumentType } from '../../../../../types/DocumentType';

interface Props extends PropsFromRedux {
  documentTypes: IDocumentType[];
}

const DocumentTypes = ({ documentTypes, ...props }: Props) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    IDocumentType | 'new' | null
  >(null);

  const { confirm } = useModal();

  const handleSubmit = async (newDocumentType: IDocumentType) => {
    if (selectedDocumentType === 'new') {
      await props.create(newDocumentType);
    } else {
      await props.update(selectedDocumentType.id, newDocumentType);
    }
    setSelectedDocumentType(null);
  };

  const handleDelete = async (id) => {
    const result = await confirm({
      title: 'Remove document type',
      subtitle: 'Are you sure you want to remove document type?',
    });
    if (result) props.remove(id);
  };

  return (
    <div>
      <CardWrapper className="mt-50 bg-light">
        <div className="px-tw-5 py-tw-3">
          <div className="flex justify-between">
            <h3>Document Types</h3>
            <LinkButton
              onClick={() => setSelectedDocumentType('new')}
              uppercase
            >
              Add New Document Type
            </LinkButton>
          </div>
        </div>
        <div className="px-tw-5 py-tw-5">
          <div className="flex flex-wrap">
            {documentTypes.map((documentType) =>
              documentType.internal ? (
                <CardWithToggle key={documentType.id} item={documentType} />
              ) : (
                <CardWithToggle
                  key={documentType.id}
                  item={documentType}
                  onToggle={() =>
                    props.update(documentType.id, {
                      active: !documentType.active,
                    })
                  }
                  onEdit={() => setSelectedDocumentType(documentType)}
                  onDelete={() => handleDelete(documentType.id)}
                  footer={() => (
                    <div>
                      {!documentType.inUse ? (
                        <Badge
                          classes={{ value: 'font-weight-300 py-xxs' }}
                          backgroundColor="#6645B0"
                        >
                          Currently in use
                        </Badge>
                      ) : (
                        <Badge
                          classes={{ value: 'light-60 font-weight-300 py-xxs' }}
                          backgroundColor="#E0E3E4"
                        >
                          Unused yet
                        </Badge>
                      )}
                    </div>
                  )}
                  disabled={!documentType.inUse}
                  tumblerText="Document Type is currently in use and cannot be disabled"
                  trashText="Document Type is currently in use and cannot be deleted"
                />
              ),
            )}
          </div>
        </div>
      </CardWrapper>
      {selectedDocumentType && (
        <Modal
          header={`${
            selectedDocumentType === 'new' ? 'New' : 'Edit'
          } Document Type`}
          toggle={() => setSelectedDocumentType(null)}
          classes={{
            header: 'bg-light',
            body: 'bg-light',
          }}
        >
          <DocumentTypeFields
            onSubmit={handleSubmit}
            documentType={
              selectedDocumentType === 'new' ? {} : selectedDocumentType
            }
          />
        </Modal>
      )}
    </div>
  );
};

const mapActionCreators = {
  update: updateDocumentType,
  create: createDocumentType,
  remove: deleteDocumentType,
};

const connector = connect(null, mapActionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DocumentTypes);

import { cn } from '@/shared/lib/css/cn';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { groupBy, uniq } from 'lodash-es';
import { useMemo } from 'react';
import { Checkbox, Tooltip } from 'stories';
import { IUserRole, TUserRoleCategory } from 'types/User';
import { TOOLTIP_DICTIONARY } from './dictionary';

export type LightUserRole = Pick<
  IUserRole,
  'id' | 'name' | 'category' | 'group' | 'undeselectable'
>;

interface Props<R extends LightUserRole> {
  records: R[];
  selectedIds: R['id'][];
  onChange: (records: R[]) => void;
  undeselectableRoles?: R[];
  columns?: boolean;
  term?: string;
}

const ROLE_CATEGORIES_LABELS: Record<TUserRoleCategory, string> = {
  external: 'External',
  internal: 'Internal',
  sre_admin: 'sRE ADMINS',
  [TUserRoleCategory.SYMMETRE]: 'Symmetre',
};

const GROUP_DELIMITER = '$';
const NULL_GROUP = 'NULL_GROUP';

const getGroupKey = (
  category: TUserRoleCategory | string,
  group: string | undefined,
) => `${category}${GROUP_DELIMITER}${group ?? NULL_GROUP}`;

export function RolesFilterList<R extends LightUserRole>({
  term,
  records,
  onChange,
  selectedIds,
  columns = false,
  undeselectableRoles,
}: Props<R>) {
  const filteredRoles = term
    ? records.filter((item) =>
        item.name.toLowerCase().includes(term.toLowerCase()),
      )
    : records;
  const groupedRoles = useMemo(
    () =>
      groupBy(filteredRoles, (role) =>
        getGroupKey(role.category, role.group ?? undefined),
      ),
    [filteredRoles],
  );

  const undeselectableRoleIds: LightUserRole['id'][] = mapListToIds(
    undeselectableRoles ?? [],
  );
  const groupKeyPaths = useMemo(
    () =>
      Object.keys(groupedRoles).map((groupKey) =>
        groupKey.split(GROUP_DELIMITER),
      ),
    [groupedRoles],
  );

  const categories = useMemo(
    () =>
      uniq(groupKeyPaths.map(([category]) => category)) as TUserRoleCategory[],
    [groupKeyPaths],
  );

  const onRoleSelectedChange = (role: R, selected: boolean) => {
    onChange(
      selected
        ? [
            ...filteredRoles.filter((roleObj) =>
              selectedIds.includes(roleObj.id),
            ),
            role,
          ]
        : filteredRoles
            .filter((roleObj) => selectedIds.includes(roleObj.id))
            .filter((roleObj) => roleObj.id !== role.id),
    );
  };

  const resolveIsRoleSelected = (role: R) => {
    const roleIdsList = [...selectedIds, ...(undeselectableRoleIds ?? [])];
    return roleIdsList.some((rId) => rId === role.id);
  };

  const renderGroup = (category: string, group: string) => (
    <div className="flex flex-col gap-tw-2" key={`${category}-${group}`}>
      {group !== NULL_GROUP && (
        <span className="light-60 label-regular">{group}</span>
      )}
      {groupedRoles[getGroupKey(category, group)].map((role) => (
        <Tooltip
          key={role.id}
          mainText={role.undeselectable && TOOLTIP_DICTIONARY.Undeselectable}
          classes={{
            spanContainer: 'w-max',
          }}
          disabled={!undeselectableRoleIds?.includes(role.id)}
        >
          <Checkbox
            labelClassName="flex inline-regular"
            onChange={(e) => onRoleSelectedChange(role, e.target.checked)}
            checked={resolveIsRoleSelected(role)}
            disabled={undeselectableRoleIds?.includes(role.id)}
          >
            {role.name}
          </Checkbox>
        </Tooltip>
      ))}
    </div>
  );

  return (
    <div className={cn('flex gap-tw-4', !columns && 'flex-col')}>
      {categories.map((categoryKey) => (
        <div className="flex flex-col gap-tw-4" key={categoryKey}>
          <span className="header6-regular text-light-90">
            {ROLE_CATEGORIES_LABELS[categoryKey]}
          </span>
          <div className="flex flex-col gap-tw-4 whitespace-nowrap">
            {groupKeyPaths
              .filter(([category]) => categoryKey === category)
              .map(([category, group]) => renderGroup(category, group))}
          </div>
        </div>
      ))}
    </div>
  );
}

import { SETTINGS_REPORT_OPERATIONAL_PAGES_TABS } from '@/bundles/Settings/components/REport/Operational/config';
import ReportSettingsScreenLayout from '@/bundles/Settings/shared/ReportSettingsScreenLayout';
import { RouterComponentWithoutWrapper } from '@/lib/reach-router';
import { ChargeCodesPage } from '@/pages/settings/report/operational/chargeCodes';
import { UnitStatusesPage } from '@/pages/settings/report/operational/unitStatues';
import { currentUserAllowedToSeeUnitStatusesPage } from '@/pages/settings/report/operational/unitStatues/permissions';
import { UnitTypesPage } from '@/pages/settings/report/operational/unitTypes/page';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { ThinTabGroup } from '@/stories';
import { Router, useLocation, useNavigate } from '@reach/router';

export const SettingsOperational = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <ReportSettingsScreenLayout.Content className="flex-grow gap-tw-6">
      <ThinTabGroup
        className="w-max"
        onSelectedItemChange={(item) => {
          navigate(item.value as string);
        }}
        selectedItem={SETTINGS_REPORT_OPERATIONAL_PAGES_TABS.find((t) => {
          return pathname.includes(t.value);
        })}
        items={SETTINGS_REPORT_OPERATIONAL_PAGES_TABS}
      />
      <Router component={RouterComponentWithoutWrapper}>
        <UnitTypesPage
          path={ROUTES_ROOT.settings.report.operational.unitTypes.path}
        />
        {currentUserAllowedToSeeUnitStatusesPage() && (
          <UnitStatusesPage
            path={ROUTES_ROOT.settings.report.operational.unitStatuses.path}
          />
        )}
        <ChargeCodesPage
          path={ROUTES_ROOT.settings.report.operational.chargeCodes.path}
        />
      </Router>
    </ReportSettingsScreenLayout.Content>
  );
};

import { IUser, IUserRole } from 'types/User';
import { LegalEntity } from '@/entities/core/legalEntity';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { IInvestmentEntity } from 'bundles/Portfolio/components/Dashboard/types';
import { IUserTag } from 'types/IUserTag';

import { FETCH_USERS, UPDATE_USER } from '../actions/User';

import { FETCH_USER_STATISTICS } from '../actions/UserStatistics';

import { FETCH_USER_ROLES } from '../actions/UserRole';

import {
  FETCH_USER_TAGS,
  CREATE_USER_TAG,
  UPDATE_USER_TAG,
  DELETE_USER_TAG,
} from '../actions/UserTag';

import {
  FETCH_INVESTMENT_ENTITIES,
  CREATE_INVESTMENT_ENTITY,
  UPDATE_INVESTMENT_ENTITY,
  DELETE_INVESTMENT_ENTITY,
} from '../actions/InvestmentEntity';
import { FETCH_USER_MANAGEMENT_FILTERS } from '../actions/Filters';

interface State {
  allUsers: IUser[];
  allUsersConnected: boolean;
  assetUsers: IUser[];
  assetUsersConnected: boolean;
  userRoles: IUserRole[];
  userRolesConnected: boolean;
  userTags: any[];
  userTagsConnected: boolean;
  investmentEntities: LegalEntity[];
  investmentEntitiesConnected: boolean;
  statistics: any;
  filters: {
    assets: IAsset[];
    funds: IFund[];
    entities: IInvestmentEntity[];
    tags: IUserTag[];
  };
}

const initialState: State = {
  allUsers: [],
  allUsersConnected: false,
  assetUsers: [],
  assetUsersConnected: false,
  userRoles: [],
  userRolesConnected: false,
  userTags: [],
  userTagsConnected: false,
  investmentEntities: [],
  investmentEntitiesConnected: false,
  statistics: null,
  filters: {
    assets: [],
    funds: [],
    entities: [],
    tags: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        allUsers: action.payload as IUser[],
        allUsersConnected: true,
      };
    case UPDATE_USER: {
      const updatedUser: IUser = action.payload;
      const allUsers = [...state.allUsers];
      const updatedList = allUsers.map((u) =>
        u.id === updatedUser.id ? updatedUser : u,
      );

      return {
        ...state,
        allUsers: updatedList,
      };
    }
    case FETCH_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload as IUserRole[],
        userRolesConnected: true,
      };
    case FETCH_USER_TAGS:
      return {
        ...state,
        userTags: action.payload,
        userTagsConnected: true,
      };

    case CREATE_USER_TAG: {
      const userTags = [...state.userTags];
      const newUserTags = [...userTags, action.payload];

      return {
        ...state,
        userTags: newUserTags,
      };
    }

    case DELETE_USER_TAG: {
      const userTags = [...state.userTags];
      const newUserTags = userTags.filter(
        (tag) => tag.id !== action.payload.id,
      );

      return {
        ...state,
        userTags: newUserTags,
      };
    }

    case FETCH_INVESTMENT_ENTITIES: {
      return {
        ...state,
        investmentEntities: action.payload,
        investmentEntitiesConnected: true,
      };
    }

    case CREATE_INVESTMENT_ENTITY: {
      const investmentEntities = [...state.investmentEntities];
      const newInvestmentEntities = [...investmentEntities, action.payload];

      return {
        ...state,
        investmentEntities: newInvestmentEntities,
      };
    }

    case UPDATE_INVESTMENT_ENTITY: {
      const updatedEntity = action.payload;
      const investmentEntities = [...state.investmentEntities];
      const updatedList = investmentEntities.map((ie) =>
        ie.id === updatedEntity.id ? updatedEntity : ie,
      );

      return {
        ...state,
        investmentEntities: updatedList,
      };
    }

    case UPDATE_USER_TAG: {
      const updatedTag = action.payload;
      const userTags = [...state.userTags];
      const updatedList = userTags.map((ut) =>
        ut.id === updatedTag.id ? updatedTag : ut,
      );

      return {
        ...state,
        userTags: updatedList,
      };
    }

    case DELETE_INVESTMENT_ENTITY: {
      const investmentEntities = [...state.investmentEntities];
      const newInvestmentEntities = investmentEntities.filter(
        (ie) => ie.id !== action.payload.id,
      );

      return {
        ...state,
        investmentEntities: newInvestmentEntities,
      };
    }

    case FETCH_USER_STATISTICS: {
      return {
        ...state,
        statistics: action.payload,
      };
    }

    case FETCH_USER_MANAGEMENT_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    default:
      return state;
  }
};

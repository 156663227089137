import { generateConnectionEmailPostfix } from 'bundles/Shared/entities/connection/lib';
import { CrudConnectionForm } from 'bundles/Shared/features/connection/crud/model';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const useErpSystemWatchEffect = (
  methods: UseFormReturn<CrudConnectionForm>,
) => {
  useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      const { isDirty } = methods.getFieldState('emailPostfix');
      const { erpSystem } = values;

      if (!isDirty && erpSystem != null && name === 'erpSystem') {
        methods.setValue('emailPostfix', generateConnectionEmailPostfix());
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
};

export const useReportTypeWatchEffect = (
  methods: UseFormReturn<CrudConnectionForm>,
) => {
  useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      const { erpSystem, reportType } = values;

      if (name !== 'reportType') return;

      if (reportType === 'market_data') {
        methods.resetField('erpSystem');
      }

      if (reportType !== 'market_data' && erpSystem === 'radix') {
        methods.resetField('erpSystem');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
};

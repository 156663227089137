import { GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';
import { useSettingsReportUnitTypesExport } from '@/features/report/unitTypes/settingsExport/lib';
import { ExportButton } from '@/shared/ui/ExportButton';

export const SettingsReportUnitTypesExportButton = ({
  params,
}: {
  params: GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg;
}) => {
  const [getFile, options] = useSettingsReportUnitTypesExport();

  return (
    <ExportButton
      onClick={() => getFile(params)}
      disabled={options.isLoading}
      loading={options.isLoading}
    />
  );
};

import React from 'react';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';
import { ExternalSourceType } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import SourceTypeImage from 'bundles/Shared/components/SourceType/SourceTypeImage';
import styles from './SourceTypeColumn.module.scss';

interface Props {
  sourceType: ExternalSourceType;
}

function SourceTypeColumn({ sourceType }: Props) {
  const sourceSettings = SOURCE_TYPE_SETTINGS[sourceType];
  return (
    <div
      className={cn(
        styles.container,
        'secondary-regular flex items-center gap-tw-2',
      )}
    >
      <SourceTypeImage sourceType={sourceType} className={styles.image} />
      {sourceSettings.title}
    </div>
  );
}

export default SourceTypeColumn;

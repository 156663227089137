import {
  ReportManualVariable,
  ReportManualVariableLegalEntity,
  ReportManualVariableLegalEntityValue,
  usePutApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdMutation,
} from '@/entities/report/manualVariable';

export const useUpdateReportManualVariableValue = () => {
  const [update, options] =
    usePutApiReportManualVariablesByManualVariableIdLegalEntitiesAndLegalEntityIdValuesIdMutation();
  return [
    ({
      legalEntityId,
      manualVariableId,
      ...args
    }: {
      manualVariableId: ReportManualVariable['id'];
      legalEntityId: ReportManualVariableLegalEntity['id'];
    } & Pick<
      ReportManualVariableLegalEntityValue,
      'dateFrom' | 'dateTo' | 'value' | 'id'
    >) =>
      update({
        id: args.id,
        body: {
          date_from: args.dateFrom ?? null,
          date_to: args.dateTo ?? null,
          value: args.value,
        },
        legalEntityId,
        manualVariableId,
      }),
    options,
  ] as const;
};

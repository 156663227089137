import React, { useMemo } from 'react';
import { SectionField } from 'stories/Field/FieldsWrappers';
import {
  findFormulaOrVariableByReference,
  FormulaCard,
  isFormula,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { Button, IconButton, InlineAlert } from '@/stories';
import { Control, useController } from 'react-hook-form';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useGetApiSettingsReportVariablesQuery } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import { FormulaAndVariablesPanel } from 'bundles/Shared/widgets/formula/panel';
import { isEmpty } from 'lodash-es';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';

const useSelectedFormulaOrVariable = (reference: string) => {
  const { data: variablesData = [], isLoading: isLoadingVariables } =
    useGetApiSettingsReportVariablesQuery();

  const { formulas, isLoading: isFormulasLoading } = useReportFormulasQuery();
  const formulaOrVariable = useMemo(() => {
    return findFormulaOrVariableByReference(
      [...formulas, ...variablesData] ?? [],
      reference,
    );
  }, [formulas, variablesData, reference]);

  return [
    formulaOrVariable,
    {
      isLoading: isFormulasLoading || isLoadingVariables,
    },
  ] as const;
};

const EmptyAlert = ({
  value,
  required,
}: {
  value: string;
  required?: boolean;
}) => {
  const { isLoading } = useReportFormulasQuery();

  return !isLoading && isEmpty(value) && !required ? (
    <InlineAlert
      className="w-full"
      status="info"
      message="Don't worry! You can choose your formula anytime later!"
    />
  ) : null;
};

const NotFoundAlert = ({ value }: { value: string }) => {
  const formulaReference = value;
  const [selectedFormulaOrVariable, { isLoading }] =
    useSelectedFormulaOrVariable(formulaReference);
  if (isLoading || isEmpty(formulaReference) || selectedFormulaOrVariable) {
    return null;
  }
  return (
    <InlineAlert
      className="h-auto w-full"
      classes={{
        body: 'py-tw-1',
      }}
      status="danger"
      message={
        <>
          Can't find formula or variable with reference{' '}
          <span className="secondary-semibold">{formulaReference}</span>
        </>
      }
    />
  );
};

const Card = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [selectedFormulaOrVariable] = useSelectedFormulaOrVariable(value);
  return (
    value &&
    !isEmpty(selectedFormulaOrVariable) && (
      <FormulaCard
        reference={selectedFormulaOrVariable.reference}
        label={selectedFormulaOrVariable.label}
        description={
          isFormula(selectedFormulaOrVariable)
            ? selectedFormulaOrVariable.description
            : undefined
        }
        tags={
          isFormula(selectedFormulaOrVariable)
            ? selectedFormulaOrVariable.tags
            : undefined
        }
        className="!rounded-lg bg-neutral-100"
        labelPanel={
          <>
            {isFormula(selectedFormulaOrVariable) && (
              <InspectFormulaButton
                reference={selectedFormulaOrVariable.reference}
              />
            )}
            <div className="grow" />
            <IconButton
              iconName="closeSmall"
              size="m"
              variant="white"
              onClick={() => {
                onChange('');
              }}
            />
          </>
        }
      />
    )
  );
};

const SelectButton = ({ onChange }: { onChange: (value: string) => void }) => {
  const { openModal } = useModal();
  return (
    <Button
      variant="primary"
      onClick={async () => {
        const res = await openModal(FormulaAndVariablesPanel, {});
        if (!res) {
          return;
        }

        onChange(res);
      }}
    >
      Select
    </Button>
  );
};

export function FormulaField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  required,
  children,
}: React.PropsWithChildren<{
  name: TName;
  control: Control<TFieldValues>;
  required?: boolean;
}>) {
  const { field } = useController({
    control,
    name,
  });
  const formulaReference = field.value;
  const [selectedFormulaOrVariable, { isLoading }] =
    useSelectedFormulaOrVariable(formulaReference);

  const showSelectButton =
    (!isLoading && isEmpty(formulaReference)) || !selectedFormulaOrVariable;

  return (
    <SectionField
      className="gap-tw-4"
      button={showSelectButton && <SelectButton onChange={field.onChange} />}
      labelText={
        <div className="flex flex-col gap-tw-1">
          Value
          <span className="secondary-regular text-neutral-550">
            Formula or Variable
          </span>
        </div>
      }
    >
      <EmptyAlert value={field.value} required={required} />
      <NotFoundAlert value={field.value} />
      <Card value={field.value} onChange={field.onChange} />
      {children}
    </SectionField>
  );
}

FormulaField.EmptyAlert = EmptyAlert;
FormulaField.NotFoundAlert = NotFoundAlert;
FormulaField.Card = Card;
FormulaField.SelectButton = SelectButton;

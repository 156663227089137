import React, { useEffect, useState } from 'react';
import {
  CrudContextProvider,
  CrudTable,
  CrudTablePanel,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/index';
import useEvent from '@/shared/lib/hooks/useEvent';
import {
  COMPANIES_ENTITY_NAME,
  createCompanyInReconcileSettings,
  deleteCompanyInReconcileSettings,
  getCompaniesInReconcileSettings,
  IReconcileSettingsCompany,
  TGetCompaniesRequestParams,
  updateCompanyInReconcileSettings,
} from 'bundles/REconcile/actions/companies';
import { useCompaniesCrudColumns } from 'bundles/REconcile/components/development/home/settingsModal/companies/useColumns';
import { useModal } from '@/shared/lib/hooks/useModal';
import CompanyCrudModal from 'bundles/REconcile/components/development/home/settingsModal/companies/CompanyCrudModal';

function CompaniesCrud() {
  const { openModal } = useModal();
  const [totalSize, setTotalSize] = useState(0);
  const [pageParams, setPageParams] = useState<TGetCompaniesRequestParams>({
    per_page: 10,
  });
  const [companies, setCompanies] = useState<IReconcileSettingsCompany[]>([]);
  const columns = useCompaniesCrudColumns({
    params: pageParams,
    setParams: setPageParams,
  });

  const loadCompanies = async () => {
    const data = await getCompaniesInReconcileSettings({}, pageParams);
    setTotalSize(data.meta.totalSize);
    setCompanies(data.items);
  };

  const handleCreate = useEvent(async () => {
    const res = await openModal(CompanyCrudModal, {
      type: 'create',
      entity: {
        code: '',
        name: '',
      },
    });

    if (res) {
      await createCompanyInReconcileSettings({}, res);
      loadCompanies();
    }
  });

  const handleEdit = useEvent(async (row: IReconcileSettingsCompany) => {
    const res = await openModal(CompanyCrudModal, {
      type: 'edit',
      entity: {
        name: row.name,
        code: row.code,
      },
    });

    if (res) {
      await updateCompanyInReconcileSettings(
        {
          entityId: row.id,
        },
        res,
      );
      loadCompanies();
    }
  });

  const handleRemove = useEvent(async (row: IReconcileSettingsCompany) => {
    await deleteCompanyInReconcileSettings({
      entityId: row.id,
    });
    loadCompanies();
  });

  useEffect(() => {
    loadCompanies();
  }, [pageParams]);

  return (
    <CrudContextProvider
      pageParams={pageParams}
      setPageParams={setPageParams}
      entities={companies}
      entityName={COMPANIES_ENTITY_NAME}
      handlers={{
        create: handleCreate,
        update: handleEdit,
        delete: handleRemove,
      }}
    >
      <div>
        <CrudTablePanel
          paginationProps={{
            totalSize,
          }}
        />
        <CrudTable columns={columns} />
      </div>
    </CrudContextProvider>
  );
}

export default CompaniesCrud;

import {
  MENU_GROUPS,
  ROUTES,
  ScreenSettings,
} from '@/bundles/Settings/components/REport/config';
import { cn } from '@/shared/lib/css/cn';
import { allSubPathMatches } from '@/shared/lib/hooks/useNavigation';
import { Link, RouteComponentProps, Router, useMatch } from '@reach/router';
import ReportSettingsNavigation from 'bundles/Settings/shared/ReportSettingsNavigation';
import ReportSettingsScreenLayout from 'bundles/Settings/shared/ReportSettingsScreenLayout';
import React from 'react';

const BORDER_CLASSNAMES = 'border-r border-solid border-neutral-200';

function LayoutWrapper({
  subtitle,
  title,
  Component,
}: RouteComponentProps & {
  title: string;
  subtitle: string;
  Component: React.FC;
}) {
  // todo problem with ag-grid
  const isGL = useMatch(ROUTES.financial.fullPath);

  return (
    <ReportSettingsScreenLayout
      className={cn(isGL && 'h-screen', 'grow')}
      title={title}
      subTitle={subtitle}
    >
      <Component />
    </ReportSettingsScreenLayout>
  );
}

const filterVisible = ({ isVisible }: ScreenSettings) =>
  typeof isVisible === 'function' ? isVisible() : isVisible;

function ReportSettings() {
  const isActive = (path: string) =>
    useMatch(ROUTES.fullPath.concat('/', path, '/*')) != null;

  const menuGroupEntries = Object.entries(MENU_GROUPS);

  return (
    <div className={cn('grid grid-cols-[248px,auto] bg-neutral-100')}>
      <div
        className={cn(
          'sticky top-0 flex max-h-screen flex-col gap-tw-6 overflow-y-auto px-tw-3 py-tw-6 pt-tw-5',
          BORDER_CLASSNAMES,
        )}
      >
        <div className="flex flex-col px-tw-3">
          <span className="inline-semibold text-neutral-500">SETTINGS</span>
          <span className="header4-bold text-neutral-800">REport</span>
        </div>
        <ReportSettingsNavigation>
          {menuGroupEntries.map(([group, items]) => (
            <ReportSettingsNavigation.List key={group} title={group}>
              {(items as readonly ScreenSettings[])
                .filter(filterVisible)
                .map(({ name, path, linkPath }) => (
                  <Link key={name} to={linkPath ?? path}>
                    <ReportSettingsNavigation.Item active={isActive(path)}>
                      {name}
                    </ReportSettingsNavigation.Item>
                  </Link>
                ))}
            </ReportSettingsNavigation.List>
          ))}
        </ReportSettingsNavigation>
      </div>
      <Router primary={false}>
        {menuGroupEntries
          .map(([group, arr]) =>
            (arr as readonly ScreenSettings[])
              .filter(filterVisible)
              .map(({ name, path, Component, ignoreLayoutWrapper }) => {
                if (ignoreLayoutWrapper) {
                  return (
                    <Component key={name} path={allSubPathMatches(path)} />
                  );
                }
                return (
                  <LayoutWrapper
                    key={name}
                    path={allSubPathMatches(path)}
                    title={name}
                    subtitle={group}
                    Component={Component}
                  />
                );
              }),
          )
          .flat()}
      </Router>
    </div>
  );
}

export default ReportSettings;

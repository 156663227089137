import React, { ComponentProps, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Tooltip, Icon } from 'stories';

interface Props
  extends Pick<ComponentProps<typeof Tooltip>, 'style' | 'maxWidth'> {
  classes?: {
    icon?: string;
    spanContainer?: string;
  };
  hint: {
    content: ReactNode | ReactNode[];
    visibleTrigger?: boolean;
  };
  hovered: boolean;
}

export const FormItemHintTooltip = ({
  classes,
  hint,
  hovered,
  ...props
}: Props) => {
  const hintContent = hint && (
    <div className="font-weight-400">{hint.content}</div>
  );
  return (
    <Tooltip
      arrowPosition="center"
      placement="top"
      mainText={hintContent}
      offsetByBorderRadius={6.5}
      classes={{ spanContainer: classes?.spanContainer }}
      {...props}
    >
      <Icon
        iconName="info"
        className={cn(
          'transition-03 light-60',
          classes?.icon,
          !hint.visibleTrigger && (hovered ? 'opacity-100' : 'opacity-0'),
        )}
      />
    </Tooltip>
  );
};

export default FormItemHintTooltip;

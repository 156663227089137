import React, { useRef, useState } from 'react';
import { GroupContainerItem, Icon, Popover, SelectableItem } from 'stories';
import { PopoverRef } from 'stories/Popover/Popover';

type ConnectionStatusFilterItem = {
  value: 'true' | 'false' | null;
  label: 'All' | 'Active' | 'Deactivated';
};

const CONNECTION_STATUS: ConnectionStatusFilterItem[] = [
  {
    value: null,
    label: 'All',
  },
  {
    value: 'true',
    label: 'Active',
  },
  {
    value: 'false',
    label: 'Deactivated',
  },
];

type Props = {
  onChange: (status: ConnectionStatusFilterItem['value']) => void;
};

const ConnectionStatusFilter = ({ onChange }: Props) => {
  const popoverRef = useRef<PopoverRef>(null);
  const [, ACTIVE_CONNECTIONS_STATUS] = CONNECTION_STATUS;
  const [status, setStatus] = useState(ACTIVE_CONNECTIONS_STATUS);

  const onChangeFilter = (newStatus: ConnectionStatusFilterItem) => {
    onChange(newStatus.value);
    setStatus(newStatus);
    popoverRef.current?.hide();
  };

  return (
    <Popover
      template={
        <GroupContainerItem title="Displayed Connections:" className="py-tw-1">
          {CONNECTION_STATUS.map((statusItem) => (
            <SelectableItem
              key={statusItem.label}
              id={statusItem.value}
              text={statusItem.label}
              onClick={() => onChangeFilter(statusItem)}
              selected={status?.value === statusItem.value}
              className="min-h-fit"
            />
          ))}
        </GroupContainerItem>
      }
      placement="bottom-end"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
      className="p-0 border border-light-20 bg-white"
      maxWidth="21.25rem"
    >
      <div className="flex h-[32px] max-h-fit cursor-pointer items-center gap-tw-2 !rounded-lg bg-info-080 px-tw-2 py-tw-1.5 text-white">
        <div className="flex gap-tw-1">
          <div className="inline-regular text-info-020">Show:</div>
          <div className="inline-semibold">{status?.label} Connections</div>
        </div>
        <Icon iconName="arrowBottom" />
      </div>
    </Popover>
  );
};

export default ConnectionStatusFilter;

import React, { FC, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';

interface IFormViewCustom {
  contentView: ReactNode;
  stylesView: {
    wrapper: string;
  };
}

const FormViewCustom: FC<IFormViewCustom> = ({ contentView, stylesView }) => (
  <div className={cn(stylesView?.wrapper ?? 'w-full')}>{contentView}</div>
);

export default FormViewCustom;

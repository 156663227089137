import React, { FC, ReactNode, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { OptionalFormSection } from 'stories';
import { IFormItem } from '../types/types';

interface Props extends IFormItem {
  value: string;
  leftIcon?: string;
  content?: ReactNode;
  expanded: boolean;
}

const FormOptionalSection: FC<Props> = ({
  title,
  styles,
  content,
  formData,
  hint,
  setFormData,
  fieldName,
  viewComponent,
}) => {
  const [expanded, setExpanded] = useState(
    Boolean(formData[fieldName] || formData[fieldName] === 0),
  );
  const expandedHandle = (status: boolean) => {
    if (!status) {
      const newFormData = { ...formData };
      if (viewComponent) {
        viewComponent.forEach((item) => {
          delete newFormData[item.fieldName];
        });
      }
      setFormData({
        ...newFormData,
        [fieldName]: null,
      });
    }
    setExpanded(status);
  };
  return (
    <div
      className={cn(
        'form-item',
        styles?.wrapper ?? 'w-full',
        styles?.nomb && 'form-item_nomb',
      )}
    >
      <OptionalFormSection
        title={title}
        content={content}
        expanded={expanded}
        expandedHandle={expandedHandle}
        hint={hint}
      />
    </div>
  );
};
export default FormOptionalSection;

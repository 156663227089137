import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import hint from 'images/report/financials/select-objects-hint.png';

export const REPORT_FINANCIALS_CARD_LIST_JSX = (
  <>
    <div className="flex justify-between">
      <SkeletonBlock className="h-[32px] w-full" />
    </div>
    <div className="flex gap-tw-2">
      <SkeletonBlock className="h-[26px] w-[250px]" />
    </div>
    <div className="flex justify-between">
      <SkeletonBlock className="h-[21px] w-[120px]" />
    </div>
    <div className="flex flex-col gap-tw-0.5">
      {Array.from({ length: 8 }).map((_, i) => (
        <SkeletonBlock className="h-[96px] w-full" key={i} />
      ))}
    </div>
  </>
);

export const REPORT_FINANCIALS_DASHBOARD_HEADER_LOADING_JSX = (
  <div className="flex w-full items-center gap-tw-4">
    <SkeletonBlock className="h-[72px] w-[112px]" />
    <div className="flex flex-col gap-tw-1">
      <SkeletonBlock className="h-[16px] w-[80px]" />
      <SkeletonBlock className="h-[24px] w-[240px]" />
    </div>
  </div>
);

export const SELECT_OBJECTS_HINT_JSX = (
  <div className="flex flex-col items-center justify-center gap-tw-6">
    <p className="header5-regular font-normal text-neutral-500">
      Select an Asset, Fund, or Segment on the left to continue
    </p>
    <img className="h-[348px] w-[348px]" src={hint} alt="hint" />
  </div>
);

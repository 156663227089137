import { FORMULA_REFERENCE_PREFIX } from 'bundles/Shared/entities/formula/lib';
import {
  EntityTag,
  SystemEntityTag,
  UserEntityTag,
} from 'bundles/Shared/entities/tag';

export type Formula = {
  id: string;
  reference: string;
  label: string;
  expression: string;
  tags: EntityTag[];
  referencedInEntities: FormulaReferencedInEntity[];
  invalidReferences: FormulaInvalidReference[];
  description?: string | null;
};

export type SystemFormula = Formula & {
  tags: SystemEntityTag[];
};

export type UserFormula = Formula & {
  tags: UserEntityTag[];
};

export type FormulaInvalidReference = {
  reference: string;
  name: string;
  namespace: string;
};

export type FormulaReferences = {
  referencingFormulas: Pick<Formula, 'id' | 'label'>[];
  referencingScoreboardWidgets: {
    id: string;
    title: string;
    boardName: string;
    scoreboardName: string;
  }[];
  referencingTables: { id: number; name: string }[];
  referencingReportBuilderTemplateWidgets: { id: string; title: string }[];
};

export const FORMULA_REFERENCING_ENTITY_TYPES = [
  'formula',
  'table',
  'dashboardWidget',
  'pdf',
] as const;

export type FormulaReferencingEntityType =
  (typeof FORMULA_REFERENCING_ENTITY_TYPES)[number];

export type FormulaReferencedInEntity = {
  id: string;
  name: string;
  type: FormulaReferencingEntityType;
};

export type FormulaVariable = {
  reference: string;
  label: string;
  namespace: string;
  description?: string | null;
};

export const isFormula = (
  formula: Formula | FormulaVariable,
): formula is Formula => 'expression' in formula;
export const isFormulaByExpression = (expression: string) =>
  expression.includes(FORMULA_REFERENCE_PREFIX);
export const isFormulaVariable = (
  formula: Formula | FormulaVariable,
): formula is FormulaVariable => 'namespace' in formula;

export type VariableValueType =
  | 'number'
  | 'percentage'
  | 'currency'
  | 'sqft'
  | 'metric'
  | 'date';

import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { useGetReportTableConfigQuery } from 'bundles/REport/api/financialsApi';
import { resolveDefaultPeriodItems } from 'bundles/REport/components/financials/utils';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import { useMemo } from 'react';
import * as Types from 'stories/FlexibleFilterByPeriods/types';

export const useDefaultPeriodItemsFromPeriodType = (
  possiblePeriods: Types.IPeriodItem['period'][],
) => {
  return useMemo(() => {
    const groupedPossiblePeriods = groupBy(possiblePeriods, (period) =>
      dayjs(period).year(),
    ) as Types.PossiblePeriodsGroupedByYear;

    return resolveDefaultPeriodItems({
      periodsType: 'mtd',
      groupedPeriods: groupedPossiblePeriods,
    });
  }, [possiblePeriods]);
};

export const useReportFinancialsScreenReportTableConfig = () => {
  const { reportName } =
    useParams<ExtractParams<typeof ROUTES_ROOT.report.financials.fullPath>>();

  return useGetReportTableConfigQuery(reportName);
};

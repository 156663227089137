import React from 'react';
import { IconsId } from 'types/sre-icons';
import { ReactComponent as EagleEyeDashboardGreyscale } from 'fonts/sre-icons/Icons/eagleEyeDashboardGreyscale.svg';
import { ReactComponent as EagleEyeDashboard } from 'fonts/sre-icons/Icons/eagleEyeDashboard.svg';
import { ReactComponent as ObjectLevelDashboardGreyscale } from 'fonts/sre-icons/Icons/objectLevelDashboardGreyscale.svg';
import { ReactComponent as ObjectLevelDashboard } from 'fonts/sre-icons/Icons/objectLevelDashboard.svg';
import { ReactComponent as Compare } from 'fonts/sre-icons/Icons/compare.svg';
import { ClassNameProps } from 'types/Props';

interface Props extends ClassNameProps {
  iconName: Extract<
    IconsId,
    | 'eagleEyeDashboardGreyscale'
    | 'eagleEyeDashboard'
    | 'objectLevelDashboardGreyscale'
    | 'objectLevelDashboard'
    | 'compare'
  >;
}

const ICONS = {
  eagleEyeDashboardGreyscale: EagleEyeDashboardGreyscale,
  eagleEyeDashboard: EagleEyeDashboard,
  objectLevelDashboardGreyscale: ObjectLevelDashboardGreyscale,
  objectLevelDashboard: ObjectLevelDashboard,
  compare: Compare,
} as const;

export function DashboardIcon({ iconName, className }: Props) {
  const Component = ICONS[iconName];

  return <Component className={className} />;
}

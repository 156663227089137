import { compareDroppedFiles } from 'lib/uploadFiles';
import {
  Invoice,
  TInvoiceStatusItem,
  TypeApprovalStatusItem,
  TypeFundingStatusItem,
  TypePaymentStatusItem,
} from '@/shared/types/reconcile/Invoice';

const approvalType = (item?: TypeApprovalStatusItem): TInvoiceStatusItem => {
  switch (item) {
    case 'waiting_approval':
      return {
        type: 'initial',
        title: 'Requested',
      };
    case 'approved':
      return {
        type: 'final',
        title: 'Approved',
      };
    default:
      return {
        type: 'empty',
        title: 'Not Requested',
      };
  }
};

const fundingType = (item?: TypeFundingStatusItem): TInvoiceStatusItem => {
  switch (item) {
    case 'in_requisition':
      return {
        type: 'initial',
        title: 'In Requisition',
      };
    case 'in_draw':
      return {
        type: 'final',
        title: 'In Draw',
      };
    default:
      return {
        type: 'empty',
        title: 'Not Funded',
      };
  }
};

const paymentType = (item?: TypePaymentStatusItem): TInvoiceStatusItem => {
  switch (item) {
    case 'marked_for_payment':
      return {
        type: 'initial',
        title: 'Marked for Payment',
      };
    case 'paid':
      return {
        type: 'final',
        title: 'Paid',
      };
    default:
      return {
        type: 'empty',
        title: 'Not Paid',
      };
  }
};

export const generateTypeStatusItem = ({
  type,
  approval,
  funding,
  payment,
}: {
  type: 'approval' | 'funding' | 'payment';
  approval?: TypeApprovalStatusItem;
  funding?: TypeFundingStatusItem;
  payment?: TypePaymentStatusItem;
}): TInvoiceStatusItem => {
  switch (type) {
    case 'approval':
      return approvalType(approval);
    case 'funding':
      return fundingType(funding);
    case 'payment':
      return paymentType(payment);
    default:
      return {
        type: 'empty',
        title: 'Unknown',
      };
  }
};

export const transformerDataInvoices = (
  data,
  isLoading: boolean,
): {
  title: string;
  status: Invoice['status'];
  badgeType: 'auto-uploading' | 'total';
  invoices?: {
    items: Invoice[];
    meta: {
      count: number;
      totalAmount?: string;
    };
  };
}[] => {
  if (!isLoading) {
    return [
      {
        title: 'To Verify',
        status: 'created',
        badgeType: 'auto-uploading',
        invoices: {
          items: data?.created.items,
          meta: {
            count: data?.created.meta.count,
          },
        },
      },
      {
        title: 'To Approve',
        status: 'verified',
        badgeType: 'total',
        invoices: {
          items: data?.verified.items,
          meta: {
            count: data?.verified.meta.count,
            totalAmount: data?.verified.meta.totalAmount,
          },
        },
      },
      {
        title: 'To Pay',
        status: 'approved',
        badgeType: 'total',
        invoices: {
          items: data?.approved.items,
          meta: {
            count: data?.approved.meta.count,
            totalAmount: data?.approved.meta.totalAmount,
          },
        },
      },
    ];
  }
  return [];
};

export const onUploadProgress = (file, signedData, progressEvent, setFiles) => {
  setFiles((f) => {
    const updatedFiles = [...f];
    const currentFileIndex = updatedFiles.findIndex((indexFile) =>
      compareDroppedFiles(indexFile, file),
    );

    updatedFiles[currentFileIndex] = {
      ...updatedFiles[currentFileIndex],
      key: signedData.fields.key,
      progress: Number(
        Math.round((progressEvent.loaded * 100) / progressEvent.total),
      ),
    };

    return updatedFiles;
  });
};

import * as React from 'react';
import { useState } from 'react';
import { Button, Input, Modal, ModalActions } from 'stories/index';

interface Props {
  onClose: (name: string | null) => void;
  current: string;
}

function EditCategoryModal({ onClose, current }: Props) {
  const [name, setName] = useState(current);
  return (
    <Modal
      header="Edit category"
      toggle={() => onClose(null)}
      actions={
        <ModalActions>
          <Button variant="success" fluid onClick={() => onClose(name)}>
            Update Category
          </Button>
        </ModalActions>
      }
      classes={{
        body: 'bg-white',
        header: 'border-bottom',
      }}
      size="sm"
    >
      {/* todo replace with storybook */}
      <span className="dark-60 d-block label-regular mb-s">Category Name</span>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
    </Modal>
  );
}

export default EditCategoryModal;

import { Distribution } from '@/entities/return';
import { useGetApiCapitalInvestmentDistributionsQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  GetApiCapitalInvestmentDistributionsApiArg,
  GetApiCapitalInvestmentDistributionsApiResponse,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';

export function useCapitalDistributions(
  arg: GetApiCapitalInvestmentDistributionsApiArg,
) {
  const prepareData = useMemo(() => {
    const emptyArray: GetApiCapitalInvestmentDistributionsApiResponse['items'] =
      [];
    return createSelector(
      (data: GetApiCapitalInvestmentDistributionsApiResponse | undefined) => {
        return data?.items ?? emptyArray;
      },
      (distributions) => {
        return distributions.map(
          (item) =>
            ({
              ...item,
              _type: 'distribution',
            }) as Distribution,
        );
      },
    );
  }, []);

  return useGetApiCapitalInvestmentDistributionsQuery(arg, {
    selectFromResult: (res) => {
      return {
        ...res,
        distributions: prepareData(res.data),
      };
    },
  });
}

import { bulkCreateManualContributions } from '@/bundles/REturn/actions/manualContributions';
import { bulkCreateManualDistributions } from '@/bundles/REturn/actions/manualDistributions';
import { useCapitalInvestments } from '@/bundles/REturn/hooks/useCapitalInvestments';
import { useLoadedCapitalPreferredReturn } from '@/bundles/REturn/hooks/useLoadedCapitalPreferredReturn';
import { useLoadedCapitalUnitPrices } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import { CapitalEntry, ReportLineItem } from 'bundles/REturn/actions/types';
import FullWhiteTable from 'bundles/Shared/components/Table/variants/FullWhiteTable';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { useItems } from '@/shared/lib/hooks/items/useItems';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { capitalize, xorBy } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { Button, ModalHeaderWithSubtitle } from 'stories/index';
import AddContributionsModal from '../../addContributionsModal/AddContributionsModal';
import AddDistributionsModal from '../../addDistributionsModal/AddDistributionsModal';
import ReviewAction from '../../manageCapitalInvestmentModal/ReviewAction';
import { ConfirmTransactionLink } from './components/ConfirmTransactionLink';
import { TransactionHeaderInfo } from './components/TransactionHeaderInfo';
import { useEntryColumnsForSplitting } from './hooks/tableColumns/useEntryColumns';
import { useSuggestedEntries } from './hooks/useSuggestedEntries';

import { convertCentsToDollars } from '@/shared/lib/converters';

export type SharedProps = {
  accountingTransaction: ReportLineItem;
  entry: CapitalEntry;
  type: TransactionSource['kind'];
} & DialogProps<CapitalEntry[]>;

export function SplitTxModal({
  onClose,
  onSubmit,
  accountingTransaction,
  entry: initialEntry,
  type,
}: SharedProps) {
  const [
    selectedEntries,
    _,
    { onAddBulkItems, onAddItem: onAddEntry, onRemoveItemById: onRemoveEntry },
  ] = useItems([initialEntry]);

  const entriesToLink = selectedEntries.length;
  const { openModal } = useModal();
  const { data: capitalInvestmentsData } = useCapitalInvestments();
  const capitalInvestments = capitalInvestmentsData ?? [];

  const { data: preferredReturn } = useLoadedCapitalPreferredReturn();
  const { data: unitPrices } = useLoadedCapitalUnitPrices();

  const handleConfirm = async () => {
    const res = await openModal(
      ReviewAction.Modal,
      {
        children: (
          <ConfirmTransactionLink
            entries={selectedEntries}
            transaction={accountingTransaction}
          />
        ),
      },
      {
        topLevelRender: false,
      },
    );

    if (res) {
      onSubmit?.(selectedEntries);
    }
  };
  const { total, allocationFlags, allocatedAmount } = useAllocation({
    items: selectedEntries.map((entry) => ({
      ...entry,
      amount: entry.amount,
    })),
    total: accountingTransaction.amountCents,
    sumMapper: ({ amount, amountCents }) => Number(amountCents ?? amount * 100),
  });
  const { isFullyAllocated } = allocationFlags;

  const { loading, suggestedEntries } = useSuggestedEntries({
    type,
    declaredAmount: accountingTransaction.amountCents,
    effectDeps: [selectedEntries, capitalInvestments],
  });

  const handleAddEntry = async () => {
    if (type === 'distribution') {
      const res = await openModal(
        AddDistributionsModal,
        {
          capitalInvestments,
          preferredReturn,
        },
        {
          topLevelRender: false,
        },
      );

      if (res == null) return;

      const response = await bulkCreateManualDistributions(res);

      onAddBulkItems(response.items);
      return;
    }
    const res = await openModal(
      AddContributionsModal,
      {
        capitalInvestments,
        unitPrices,
      },
      {
        topLevelRender: false,
      },
    );

    if (res == null) return;

    const response = await bulkCreateManualContributions(res);

    onAddBulkItems(response.items);
  };

  const columns = useEntryColumnsForSplitting({
    type,
    initialEntryId: initialEntry.id,
    deps: [selectedEntries, type, suggestedEntries],
    selectedEntryIds: selectedEntries.map(({ id }) => id),
    actions: {
      onAddEntry,
      onRemoveEntry,
    },
  });

  const notSelectedEntries = useMemo(
    () =>
      xorBy(suggestedEntries, selectedEntries, 'id').filter(
        ({ id }) => initialEntry.id !== id,
      ),
    [suggestedEntries, selectedEntries],
  );

  return (
    <SidePanel
      toggle={onClose}
      maxHeight
      zIndex={HUGE_MODAL_Z_INDEX + 1}
      header={<ModalHeaderWithSubtitle title="Split & Link Transaction" />}
      classes={{
        header: 'bg-white',
        body: 'bg-light flex flex-col gap-tw-4',
      }}
    >
      <TransactionHeaderInfo transaction={accountingTransaction} />
      <AllocationStatistics
        allocatedAmount={convertCentsToDollars(allocatedAmount)}
        totalAmount={total ? convertCentsToDollars(total) : 0}
      />
      <div className="flex items-center justify-between">
        <span className="inline-semibold text-dark-60">
          {entriesToLink} {pluralize(capitalize(type), entriesToLink)}
        </span>
        <Button
          variant="secondary"
          size="s"
          onClick={handleAddEntry}
          disabled={isFullyAllocated}
        >
          Add {capitalize(type)}
        </Button>
      </div>
      <FullWhiteTable columns={columns} items={selectedEntries} />

      {notSelectedEntries.length > 0 && (
        <>
          <div className="flex flex-col gap-tw-1">
            <span className="inline-semibold text-dark-60">
              {pluralize('Suggestion', suggestedEntries.length)}
            </span>
            <span className="secondary-regular text-light-70">
              You can include line items from other Investment Entities
            </span>
          </div>
          <FullWhiteTable
            loading={loading}
            columns={columns}
            items={notSelectedEntries}
          />
        </>
      )}
      <Button
        disabled={!isFullyAllocated}
        onClick={handleConfirm}
        className="mt-auto"
        variant="success"
      >
        Split & Link Transaction
      </Button>
    </SidePanel>
  );
}

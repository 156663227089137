import { ConnectionAddressSelect } from 'bundles/Shared/entities/connection/ui/ConnectionAddressSelect';
import React from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { EditableConnectionProps } from 'bundles/Settings/components/REport/Connections/widgets/ConnectionModal';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { CrudConnectionForm } from 'bundles/Shared/features/connection/crud';

type ConnectionModalEmailFlowProps = {
  control: Control<EditableConnectionProps, any>;
  connectionId?: IConnection['id'];
};

export const ConnectionModalEmailFlow = ({
  control,
  connectionId,
}: ConnectionModalEmailFlowProps) => {
  const { watch } = useFormContext<CrudConnectionForm>();
  const erpSystem = watch('erpSystem');

  return (
    <Controller
      control={control}
      name="emailPostfix"
      render={({ field }) => (
        <ConnectionAddressSelect
          erpSystem={erpSystem}
          emailPostfix={field.value}
          onChange={(e) => field.onChange(e)}
          hideControls={Boolean(connectionId)}
        />
      )}
    />
  );
};

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import UnitNum from '../UnitNum/UnitNum';
import styles from './stepsFormStep.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  title: string;
  stepNumber: number;
  afterTitle?: React.ReactNode;
  required?: boolean;
  activated?: boolean;
  disabled?: boolean;
  lineForStep?: boolean;
  classes?: {
    title?: string;
    content?: string;
    header?: string;
    stepNumber?: string;
    body?: string;
  };
}

export const QAItemsContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => <div className="flex items-center gap-tw-2">{children}</div>;

// NOTE: if you want to hide last line you will need to wrap all steps to another DOM element
export const StepsFormStepv2: React.FC<Props> = ({
  children,
  stepNumber,
  required,
  activated,
  title,
  className,
  disabled,
  lineForStep = true,
  classes,
  afterTitle,
  ...props
}) => (
  <div className={cn('flex', [styles.item], className)} {...props}>
    {stepNumber && (
      <div
        className={cn(
          'flex flex-col items-center',
          [styles.leftColumn],
          required && lineForStep && [styles.leftColumn_required],
          classes?.stepNumber,
        )}
      >
        <UnitNum required={required} activated={activated}>
          {stepNumber}
        </UnitNum>
      </div>
    )}
    <div
      className={cn(
        'flex grow flex-col gap-tw-2',
        [styles.rightSide],
        classes?.body,
      )}
    >
      {title && (
        <div
          className={cn(
            'flex items-center gap-tw-2',
            required && 'min-h-[1.5rem]',
            classes?.header,
          )}
        >
          <h4
            className={cn(
              'body-semibold',
              required && [styles.stepTitle],
              disabled && 'light-60',
              classes?.title,
            )}
          >
            {title}
          </h4>
          {afterTitle}
        </div>
      )}
      <div className={cn([styles.content], classes?.content)}>{children}</div>
    </div>
  </div>
);

export default StepsFormStepv2;

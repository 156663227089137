import { TreeNodeProps } from 'rc-tree';

export const Arrow = ({ expanded }: TreeNodeProps) => (
  <div className="flex w-[16px] items-center justify-center">
    <svg
      style={{ transform: expanded ? 'rotate(90deg)' : 'initial' }}
      width="4px"
      height="8px"
      viewBox="0 0 4 8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Body/_blocks/Connector/Pointer/End"
          transform="translate(-6.000000, -12.000000)"
          fill="#848DA0"
        >
          <polygon
            id="connect-pointer-end"
            transform="translate(8.000000, 16.000000) rotate(-270.000000) translate(-8.000000, -16.000000) "
            points="8 14 12 18 4 18"
          />
        </g>
      </g>
    </svg>
  </div>
);

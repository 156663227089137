import { Icon, Tooltip } from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import { TooltipProps } from 'stories/Tooltip/Tooltip';
import {
  CONNECTION_PARSER_DOCUMENT_STATUS_UI_CONFIG,
  TParserDocumentStatus,
} from 'bundles/Shared/entities/connection/config';

interface IConnectionParserDocumentStatus
  extends Partial<React.PropsWithChildren<TooltipProps>> {
  status: TParserDocumentStatus;
  description?: string;
}

const ConnectionIcon = ({ status }: { status: TParserDocumentStatus }) => (
  <div className="flex items-center justify-center">
    <Icon
      iconName={CONNECTION_PARSER_DOCUMENT_STATUS_UI_CONFIG[status].iconName}
      className={cn(
        'flex h-[16px] w-[16px] items-center justify-center rounded-2xl text-tw-white',
        status === 'error' && 'text-[12px]',
        CONNECTION_PARSER_DOCUMENT_STATUS_UI_CONFIG[status].bgColor,
      )}
    />
  </div>
);

export const ConnectionParserDocumentStatus = ({
  status,
  description,
  ...props
}: IConnectionParserDocumentStatus) => (
  <div className="label-regular flex items-center gap-tw-2">
    {status === 'error' && <div>Parser: Failed</div>}
    {description ? (
      <Tooltip titleText={description} {...props}>
        <ConnectionIcon status={status} />
      </Tooltip>
    ) : (
      <ConnectionIcon status={status} />
    )}
  </div>
);

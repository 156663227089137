import { PropsWithChildren } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from 'types/Props';

export default function Banner({
  children,
  className,
}: PropsWithChildren & ClassNameProps) {
  return (
    <div
      className={cn(
        'secondary-regular flex w-full border border-solid border-attention-055 bg-attention-020 px-tw-6 py-tw-2 text-neutral-900',
        className,
      )}
    >
      {children}
    </div>
  );
}

import React from 'react';
import { TProductNames, currentUserAllowedTo } from 'lib/permissions';
import { NavigateFn } from '@reach/router';
import { AnimationLoader, StreamlinedContainer, IconButton } from 'stories';
import { PayAppSummary } from '@/shared/types/reconcile/PayApp';
import PayAppSummaryTable from './PayAppSummaryTable';

interface Props {
  payAppSummary: PayAppSummary;
  navigate: NavigateFn;
}

const PayAppSummaryComp = ({ payAppSummary, navigate }: Props) => {
  const canEdit = currentUserAllowedTo(
    'configure',
    TProductNames.CHANGE_MANAGEMENT,
  );

  return (
    <StreamlinedContainer
      title="Pay App Summary"
      subtitle="Overview"
      actions={
        canEdit ? (
          <IconButton
            iconName="edit"
            onClick={() => navigate('./edit')}
            size="l"
          />
        ) : undefined
      }
    >
      {Object.keys(payAppSummary).length ? (
        <PayAppSummaryTable payAppSummary={payAppSummary} />
      ) : (
        <AnimationLoader className="relative" />
      )}
    </StreamlinedContainer>
  );
};

export default PayAppSummaryComp;

export const rangePercentile = ({
  rangeMin,
  rangeMax,
  value,
}: {
  value: number;
  rangeMin: number;
  rangeMax: number;
}) => {
  return rangeMin + (value / 100) * (rangeMax - rangeMin);
};

import { ILocationState } from 'types/LocationState';
import {
  IPageParams,
  IUserFilters,
} from 'bundles/UserManagement/components/UserTracker/UserTracker';
import { snakeCase } from 'lodash-es';

export const getDefaultFilterFromLocationState = (
  locationState: ILocationState | undefined,
): IUserFilters => ({
  assets: [
    {
      id: locationState?.filterAsset?.id,
      name: locationState?.filterAsset?.name,
    },
  ].filter((value) => value.id),
  funds: [
    {
      id: locationState?.filterFund?.id,
      name: locationState?.filterFund?.name,
    },
  ].filter((value) => value.id),
  roles: [
    {
      id: locationState?.filterRole?.id,
      name: locationState?.filterRole?.name,
    },
  ].filter((value) => value.id),
  members: [],
  tags: [
    {
      id: locationState?.filterTag?.id,
      name: locationState?.filterTag?.name,
    },
  ].filter((value) => value.id),
  investmentEntities: [
    {
      id: locationState?.filterInvestmentEntity?.id,
      name: locationState?.filterInvestmentEntity?.name,
    },
  ].filter((value) => value.id),
});

const getTableFilters = (data: IUserFilters) => ({
  user_role_ids: data.roles.map(({ id }) => id),
  user_tag_ids: data.tags.map(({ id }) => id),
  investment_entity_ids: data.investmentEntities.map(({ id }) => id),
  asset_ids: data.assets.map(({ id }) => id),
  fund_ids: data?.funds?.map(({ id }) => id) || [],
});

export const getParamsFromPageParams = (pageParams: IPageParams) => ({
  page: pageParams.currentPage,
  size_per_page: pageParams.sizePerPage,
  sort_field: snakeCase(pageParams.sortField),
  sort_order: snakeCase(pageParams.sortOrder),
  search_query: pageParams.searchQuery,
  filters: {
    ...getTableFilters(pageParams.filters),
  },
  invite_status: [pageParams.inviteStatus],
  fire_invite_status: pageParams.fire_invite_status,
  filter_by_fire_latest_invitation: pageParams.filter_by_fire_latest_invitation,
});

import React, { useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import { TRouteParams, useNavigation } from '@/shared/lib/hooks/useNavigation';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import http from 'lib/http';
import {
  CHANGE_MANAGEMENT_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
} from 'lib/permissions';
import withAccess from 'bundles/Shared/components/withAccess';
import Errors from 'bundles/Errors';
import ReconcileModalPage from 'bundles/Construction/components/Shared/ReconcileModalPage/ReconcileModalPage';
import IncludedChangeOrder from 'bundles/Construction/components/Reallocation/IncludedChangeOrder/IncludedChangeOrder';
import ReallocationDetails from 'bundles/Construction/components/Reallocation/ReallocationDetails/ReallocationDetails';
import {
  deleteReallocation,
  fetchReallocation,
  selectReallocation,
  uploadFilesReallocation,
} from 'bundles/Construction/reducers/ReconcileReallocationSlice';
import { generateNumberRE } from 'bundles/Construction/components/Reallocation/ReallocationDetails/initData';
import ApprovalForm from 'bundles/Construction/components/Reallocation/Modals/ApprovalForm';
import { fetchReallocations } from 'bundles/Construction/actions/reallocations';
import { usePreventBodyScrollingEffect } from '@/shared/lib/hooks/usePreventBodyScollingEffect';
import { AnimationLoader } from 'stories/index';

const Reallocation = ({ containerClassName = '' }) => {
  const [history, setHistory] = useState();

  const reallocation = useAppSelector(selectReallocation);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const params =
    useParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATION']
    >();
  const legalEntityCode =
    reallocation?.legalEntityCode || params.legalEntityCode;

  const isCODrivenRE = reallocation?.changeOrder;

  const actionsData = [
    {
      title: 'Approve',
      description: '',
      iconName: 'acceptAlt',
      show: reallocation?.status === 'PENDING',
      content: <ApprovalForm />,
    },
  ];
  usePreventBodyScrollingEffect();
  useEffect(() => {
    dispatch(
      fetchReallocation({
        reallocationId: params.reallocationId,
      }),
    );
  }, []);

  useEffect(() => {
    http
      .get(
        `/reconcile/legal_entities/${legalEntityCode}/reallocations/${params.reallocationId}/logs`,
      )
      .then((res) => res.json())
      .then((data) => setHistory(data));
  }, [reallocation]);

  if (!reallocation)
    return <AnimationLoader className="static min-h-[360px]" />;

  const onCloseModal = () => {
    navigate(
      navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATIONS', {
        legalEntityCode: reallocation.legalEntityCode || params.legalEntityCode,
      }),
    );
  };

  const uploadFiles = async (idAsset, id, data) => {
    const res = await dispatch(
      uploadFilesReallocation({
        reallocationId: params.reallocationId,
        data,
      }),
    );
    return res.payload;
  };

  const refresh = () => {
    dispatch(
      fetchReallocation({
        reallocationId: params.reallocationId,
      }),
    );
  };

  const deleteRE = async (status: boolean) => {
    if (status) {
      const res = await dispatch(
        deleteReallocation({
          reallocationId: params.reallocationId,
        }),
      );
      if (!res.payload.errors) {
        await dispatch(fetchReallocations(params?.legalEntityCode));
        navigate(
          navigation.getUrl(
            'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATIONS',
            {
              legalEntityCode: params.legalEntityCode,
            },
          ),
        );
        return true;
      }
    }
  };

  if (reallocation?.errors?.length) {
    return (
      <div className={containerClassName}>
        <Errors
          code="not_found"
          actionButton={{
            text: 'Back to REallocations',
            onClick: onCloseModal,
          }}
        />
      </div>
    );
  }
  return (
    <ReconcileModalPage
      containerClassName={containerClassName}
      header={{
        title: isCODrivenRE ? 'CO-Driven REallocation' : 'Manual REallocation',
        subtitle: generateNumberRE(reallocation.status, reallocation?.number),
        onCloseModal,
      }}
      infoBadge={{
        title: 'REallocation Info',
        status: reallocation?.status,
        amount: reallocation?.value,
      }}
      documents={
        !reallocation?.changeOrderEvent && {
          sharedFiles: [
            ...(reallocation?.changeOrder?.sharedFiles || []),
            ...(reallocation?.sharedFiles || []),
          ],
          isReadOnly: true,
          uploadFiles,
          refresh,
          finalFile: reallocation?.changeOrder?.contractFile?.id,
        }
      }
      availableActions={{
        actionsData: actionsData.filter((action) => action.show),
        removeFlow: reallocation?.status !== 'COMPLETED' && {
          handler: deleteRE,
          modal: {
            title: 'Remove Reallocation',
            subtitle:
              "Are you sure you want to remove Reallocation? This can't be undone",
          },
        },
      }}
      histories={history}
      currentItem={reallocation}
      alertMessage={
        reallocation?.status === 'COMPLETED' && {
          title: `${
            !isCODrivenRE ? 'Manual' : 'CO-Driven'
          } Reallocation has been Approved`,
          icon: 'acceptAlt',
          status: 'success',
        }
      }
      legalEntityCode={params.legalEntityCode}
    >
      <div>
        <ReallocationDetails
          reallocation={reallocation}
          canEdit={reallocation?.status === 'PENDING'}
        />
      </div>
      {reallocation?.changeOrder && (
        <div>
          <IncludedChangeOrder changeOrder={reallocation?.changeOrder} />
        </div>
      )}
    </ReconcileModalPage>
  );
};

export default withAccess(Reallocation, {
  hasAccess: () =>
    currentUserIsSreAdmin() ||
    currentUserIsSuperAdmin() ||
    currentUserAllowedTo('manage', CHANGE_MANAGEMENT_PRODUCT_NAME),
});

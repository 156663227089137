import React, { useMemo, useState } from 'react';
import { isEmpty, snakeCase } from 'lodash-es';
import { useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  IconButton,
  Modal,
  SearchInput,
  Tumbler,
} from 'stories';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import {
  syncDealPathField,
  updateDealPathField,
} from '../../actions/dealPathSettings';
import Table from 'bundles/Shared/components/Table/Table';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import { IColumn } from 'bundles/Shared/components/Table/types';

interface DealPathField {
  active: boolean;
  dp_id: number;
  dp_name: string;
  id: number;
  key: string;
  name: string;
  type: string;
  values: string[];
  category: string;
}

const tableColumns = ({
  toggleActive,
  onEditClick,
  onSyncClick,
}: {
  toggleActive: (row: DealPathField) => void;
  onEditClick: (row: DealPathField) => void;
  onSyncClick: (row: DealPathField) => void;
}): IColumn<DealPathField>[] => [
  {
    dataField: 'active',
    text: 'Active',
    formatter: ({ row }) => (
      <Tumbler
        className="mr-10"
        defaultChecked={row.active}
        disabled={!row.key}
        onChange={() => toggleActive(row)}
      />
    ),
  },
  {
    dataField: 'dp_id',
    text: 'DP ID',
    headerStyle: { minWidth: '80px', textAlign: 'center' },
  },
  {
    dataField: 'name',
    text: 'Name',
    headerStyle: { minWidth: '280px', textAlign: 'center' },
  },
  {
    dataField: 'dp_name',
    text: 'DP name',
    headerStyle: { minWidth: '280px', textAlign: 'center' },
  },
  {
    dataField: 'key',
    text: 'symmetRE Field Key',
    headerStyle: { minWidth: '180px', textAlign: 'center' },
  },
  {
    dataField: 'category',
    text: 'category',
    headerStyle: { minWidth: '120px', textAlign: 'center' },
  },
  {
    dataField: 'values',
    text: 'Values',
    headerStyle: { maxWidth: '200px', textAlign: 'center' },
    formatter: ({ row }) =>
      row.values.length > 10 ? row.values.length : row.values.join(', '),
  },
  {
    dataField: 'actions',
    text: 'Actions',
    headerStyle: { minWidth: '90px', textAlign: 'center' },
    formatter: ({ row }) => (
      <>
        <IconButton
          iconName="edit"
          onClick={() => onEditClick(row)}
          className="mr-tw-2"
        />
        {row.active && onSyncClick && (
          <IconButton iconName="sync" onClick={() => onSyncClick(row)} />
        )}
      </>
    ),
  },
];

const DealFields = () => {
  const [selectedField, setSelectedField] = useState<DealPathField | null>(
    null,
  );
  const [active, setActive] = useState(false);
  const [term, setTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const dealPathFields: DealPathField[] = useAppSelector(
    (state) => state.dealPathSettings.dealPathFields,
  );
  const dispatch = useAppDispatch();
  const { handleSubmit, setValue } = useForm();

  const matchField = (field: DealPathField) =>
    `${field.name} ${field.dp_name}`.toLowerCase().includes(term);

  const filteredItems = useMemo(() => {
    const fields = isEmpty(term)
      ? dealPathFields
      : dealPathFields.filter(matchField);
    return active ? fields.filter((field) => field.active) : fields;
  }, [dealPathFields, term, active]);

  const paginatedItems = useMemo(
    () => filteredItems.slice((currentPage - 1) * 50, currentPage * 50),
    [currentPage, filteredItems],
  );

  const submit = async (data) => {
    await dispatch(updateDealPathField(selectedField?.id, data));
    setSelectedField(null);
  };

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="px-tw-5 py-tw-3">
          <div>
            <div className="flex justify-between">
              <h3>Pipeline Fields</h3>
              <TablePagination
                loading={false}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalSize={filteredItems.length}
                sizePerPage={50}
              />
              <div className="flex">
                <Checkbox
                  labelClassName="mr-m align-self-center"
                  checked={active}
                  onChange={() => {
                    setActive(!active);
                    setCurrentPage(1);
                  }}
                >
                  Active
                </Checkbox>
                <SearchInput
                  resetValue={setTerm}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setTerm(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-tw-5 py-tw-5">
          <div className="table-wrap relative">
            <div className="table-container deal-fields-table table-responsive">
              <Table
                items={paginatedItems}
                columns={tableColumns({
                  toggleActive: (row) =>
                    dispatch(
                      updateDealPathField(row.id, { active: !row.active }),
                    ),
                  onSyncClick: (row) => dispatch(syncDealPathField(row)),
                  onEditClick: setSelectedField,
                })}
              />
            </div>
          </div>
        </div>
      </CardWrapper>
      {selectedField && (
        <Modal
          header="Modify field"
          toggle={() => setSelectedField(null)}
          actions={
            <Button form="dielFieldsForm" variant="success" fluid type="submit">
              Submit
            </Button>
          }
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
        >
          <form id="dielFieldsForm" onSubmit={handleSubmit(submit)}>
            <div className="from-group mb-tw-2">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                defaultValue={selectedField.name}
                id="name"
                onChange={(e) => setValue('name', e.target.value)}
              />
            </div>
            <div className="from-group mb-tw-2">
              <label htmlFor="category">Category</label>
              <input
                className="form-control"
                defaultValue={selectedField.category}
                id="category"
                placeholder="role, team or something else"
                onChange={(e) => setValue('category', e.target.value)}
              />
              <small>This field useful for combining fields to a groups</small>
            </div>
            <div className="from-group mb-tw-4">
              <label htmlFor="key">symmetRE field key</label>
              <input
                className="form-control"
                id="key"
                name="key"
                placeholder={snakeCase(selectedField.dp_name)}
                onChange={(e) => setValue('key', e.target.value)}
              />
              <small>
                Please use{' '}
                <strong>
                  snake_case_format like {snakeCase(selectedField.dp_name)}
                </strong>{' '}
                for saving to the database
              </small>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default DealFields;

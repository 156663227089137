import { ICreateReallocationRequest } from 'bundles/Construction/actions/reallocations';
import {
  transformJccIdToKey,
  transformJCCKeyToId,
} from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { ICreateReallocationFormData } from 'bundles/Construction/components/Reallocation/Modals/types';
import { directUpload, IPresignedFile, presignFiles } from 'lib/uploadFiles';
import { IReconcileJCC } from 'bundles/Construction/types';
import { sumBy } from 'lodash-es';
import { treeDFS } from 'lib/treeHelpers';
import { useGetDevelopmentCategoryQuery } from 'bundles/Construction/api/core';
import { transformCategoriesForTree } from 'bundles/DrawPackage/InvoicesFlow/Invoices/utils';
import { treeJCCToOption } from 'bundles/Shared/helpers/job_cost_code';
import { useCallback } from 'react';
import { useParams } from '@reach/router';

export const mapFormDataToRequest = async (
  formData: ICreateReallocationFormData,
): Promise<ICreateReallocationRequest> => {
  let presignedFiles: IPresignedFile[] | null = null;
  if (formData.sharedFiles != null) {
    const filesToUpload = !Array.isArray(formData.sharedFiles)
      ? [formData.sharedFiles]
      : formData.sharedFiles.filter((file) => !file.id);
    if (filesToUpload?.length) {
      presignedFiles = await presignFiles(filesToUpload);
      await Promise.all(presignedFiles.map((file) => directUpload(file)));
    }
  }

  return {
    value: formData.value!,
    input_type: 'manual',
    contingency_impact: formData.contingencyImpact!,
    sovc_sources:
      formData?.movedFromSovcs?.map((sovc) => ({
        schedule_of_value_code_id: sovc.id as Integer,
        amount: sovc.reallocation,
      })) ?? [],
    sovc_destinations:
      formData?.movedToSovcs?.map((sovc) => ({
        schedule_of_value_code_id: sovc.id as Integer,
        amount: sovc.reallocation,
      })) ?? [],
    moved_from_id: transformJCCKeyToId(formData.moved_from_id!) as Integer,
    moved_to_id: transformJCCKeyToId(formData.moved_to_id!) as Integer,
    description: formData.description!,
    sub_reallocations: formData?.subReallocations.map((subr) => ({
      moved_from_id: transformJCCKeyToId(subr.moved_from_id!) as Integer,
      moved_to_id: transformJCCKeyToId(subr.moved_to_id!) as Integer,
      value: subr.value as Float,
      sovc_sources:
        subr?.movedFromSovcs?.map((sovc) => ({
          schedule_of_value_code_id: sovc.id as Integer,
          amount: sovc.reallocation,
        })) ?? [],
    })),
    change_order_id: formData.change_order_id as Integer,
    files: presignedFiles?.map((f) => ({
      key: f.signedData.fields.key,
      filename: f.file.name,
      content_type: f.file.type,
      size: f.file.size,
    })),
  };
};

export const isJccValidForReallocation = (jcc: IReconcileJCC) =>
  jcc.sovcs.length > 1;

export const getDestinationReallocationAmount = (
  destination: 'from' | 'to',
  reallocationAmount: Float,
): Float =>
  (destination === 'from' ? -reallocationAmount : reallocationAmount) as Float;

type CreateReallocationJCCFormData = Pick<
  ICreateReallocationFormData,
  'moved_from_id' | 'moved_to_id' | 'movedFromSovcs' | 'movedToSovcs' | 'value'
>;

export const useAmountAndJccIsValidInReallocation = () => {
  const params = useParams();
  const { data: JCCTree } = useGetDevelopmentCategoryQuery({
    legalEntityCode: params.legalEntityCode,
    without_sovcs: 'false',
  });
  const tree = JCCTree
    ? transformCategoriesForTree({
        categories: treeJCCToOption(JCCTree),
      })
    : [];

  return useCallback(
    (values: CreateReallocationJCCFormData) => {
      const errors: Partial<
        Record<keyof CreateReallocationJCCFormData, string>
      > = {};
      if (!values.moved_from_id) {
        errors.moved_from_id = 'Moved from is required';
      }
      if (!values.moved_to_id) {
        errors.moved_to_id = 'Moved to is required';
      }
      if (values.value == null || values.value === 0) {
        errors.value = 'Amount is required';
      }

      const isJccValid = (
        key: keyof Pick<
          ICreateReallocationFormData,
          'moved_from_id' | 'moved_to_id'
        >,
      ) => {
        const jcc = treeDFS(
          tree,
          (node) => node.id === transformJccIdToKey(values[key]!),
        ).at(-1) as IReconcileJCC;
        const sovcKey =
          key === 'moved_from_id' ? 'movedFromSovcs' : 'movedToSovcs';
        return (
          !isJccValidForReallocation(jcc) ||
          sumBy(values[sovcKey], 'reallocation') === values.value
        );
      };

      if (values.moved_from_id && values.moved_to_id) {
        if (!isJccValid('moved_from_id')) {
          errors.movedFromSovcs =
            'Moved from SOVCs amount should be equal to moved from JCC amount';
        }
        if (!isJccValid('moved_to_id')) {
          errors.movedToSovcs =
            'Moved to SOVCs amount should be equal to moved to JCC amount';
        }
      }

      return errors;
    },
    [tree],
  );
};

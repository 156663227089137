import { TRootState } from '@/app/stores';
import { ReportScoreboardWidgetSectionDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { ObjectDashboardWidgetStateMap } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { yesterdayDate } from '@/shared/lib/date';
import { mapListToIds } from '@/shared/lib/listHelpers';
import {
  createEntityAdapter,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  buildLayoutsId,
  dashboardSettingsApiEnhanced,
  filterIncludedLegalEntities,
  getInitialStateForReportChartWidget,
  getUpdatedWidgetStateForReportChartWidget,
  layoutsIdHasBoard,
  loadInitialWidgetState,
  loadInitialWidgetStateReducer,
  OBJECT_DASHBOARD_SECTION_TYPE,
  ObjectDashboardSectionTypes,
  ReportObjectDashboardSections,
  updateDashboardWidgetState,
  updateWidgetStateReducer,
  WidgetDateGranularity,
} from 'bundles/Shared/entities/dashboard';
import {
  addLayoutsReducersToSlice,
  buildLayout,
  LayoutsState,
} from 'bundles/Shared/entities/dashboard/model/slices/layouts';
import {
  isAverageActualRentChartWidgetSection,
  isXYChartWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import dayjs, { Dayjs } from 'dayjs';
import { UnionToIntersection, ValueOf } from 'type-fest';
import { UnknownRecord } from 'type-fest/source/internal';

export type ObjectDashboardState = LayoutsState<{
  id: string;
  objectLegalEntityIds: Record<number, string[]>;
  date: string;
  widgetsState: Record<
    string,
    {
      type: ObjectDashboardSectionTypes;
    } & ObjectDashboardWidgetStateMap[keyof ObjectDashboardWidgetStateMap]
  >;
  widgetsStateInitial?: Record<string, UnknownRecord>;
}>;

const dashboardsAdapter = createEntityAdapter<ObjectDashboardState>();

const initialState = dashboardsAdapter.getInitialState();

export const getDateWithGranularityOffset = (
  date: Dayjs,
  granularity: WidgetDateGranularity,
) => {
  const offsets = {
    week: 12,
    month: 12,
    day: 30,
  };

  return date.subtract(offsets[granularity], granularity);
};

export const getInitialStateForObjectDashboardWidget = (
  widgetSection: ReportObjectDashboardSections,
  dashboardDate: string | Dayjs,
) => {
  if (
    widgetSection.widgetType ===
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
  ) {
    return {
      date: formatToDateStringForRequest(
        widgetSection.defaultOptions.date ?? dashboardDate,
      ),
    };
  }
  if (
    isXYChartWidgetSection(widgetSection) ||
    isAverageActualRentChartWidgetSection(widgetSection)
  ) {
    return getInitialStateForReportChartWidget(widgetSection, dashboardDate);
  }
  return {
    page: 1,
    perPage: 10,
    date: formatToDateStringForRequest(dashboardDate),
  };
};
export const objectDashboardSlice = createSlice({
  name: 'objectDashboard',
  initialState,
  reducers: {
    updateObjectLegalEntityIds: (
      state,
      action: PayloadAction<{
        objectId: number;
        legalEntityIds: string[];
        dashboardId: string;
      }>,
    ) => {
      const { dashboardId, objectId, legalEntityIds } = action.payload;
      if (!state.entities[dashboardId]) {
        return;
      }
      state.entities[dashboardId].objectLegalEntityIds[objectId] =
        legalEntityIds;
    },
    updateReportObjectDashboardDate: (
      state,
      action: PayloadAction<{
        date: string;
        dashboardId: string;
      }>,
    ) => {
      const { dashboardId, date } = action.payload;
      if (state.entities[dashboardId] == null) {
        return;
      }

      state.entities[dashboardId].date = date;

      const getUpdatedWidgetState = (
        widgetState: UnionToIntersection<
          ValueOf<ObjectDashboardWidgetStateMap>
        > & {
          type: ObjectDashboardSectionTypes;
        },
      ): ValueOf<ObjectDashboardWidgetStateMap> => {
        if (
          widgetState.type === OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART ||
          widgetState.type ===
            OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART
        ) {
          return getUpdatedWidgetStateForReportChartWidget(widgetState, date);
        }
        return {
          date,
        };
      };

      Object.entries(state.entities)
        .filter(([id]) => layoutsIdHasBoard(id))
        .forEach(([_, board]) => {
          // eslint-disable-next-line no-param-reassign
          board!.widgetsState = Object.fromEntries(
            Object.entries(board!.widgetsState).map(
              ([widgetId, widgetState]) => {
                return [
                  widgetId,
                  {
                    ...widgetState,
                    ...getUpdatedWidgetState(widgetState),
                  },
                ];
              },
            ),
          );
        });
    },
  },
  extraReducers(builder) {
    addLayoutsReducersToSlice(builder);
    builder.addCase(loadInitialWidgetState, loadInitialWidgetStateReducer);
    builder.addCase(updateDashboardWidgetState, updateWidgetStateReducer);
    builder.addMatcher(
      dashboardSettingsApiEnhanced.endpoints
        .getApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsId
        .matchFulfilled,
      (state, action) => {
        const { id: widgetId } = action.payload;
        const { objectDashboardId: dashboardId, boardId } =
          action.meta.arg.originalArgs;
        const dashboard = state.entities[dashboardId];
        const board = state.entities[buildLayoutsId({ boardId, dashboardId })];
        if (dashboard != null || board != null) {
          return;
        }
        const yesterday = dayjs(yesterdayDate());

        dashboardsAdapter.upsertOne(state, {
          id: dashboardId,
          date: formatToDateStringForRequest(yesterday),
          objectLegalEntityIds: {},
          widgetsState: {},
          layouts: { lg: [] },
          initialLayouts: { lg: [] },
        } as ObjectDashboardState);
        const widgetsState = {
          [widgetId]: {
            type: action.payload.widgetType,
            ...getInitialStateForObjectDashboardWidget(
              action.payload,
              yesterday,
            ),
          },
        };
        dashboardsAdapter.upsertOne(state, {
          id: buildLayoutsId({ boardId, dashboardId }),
          objectLegalEntityIds: {},
          date: formatToDateStringForRequest(yesterday),
          widgetsState,
          widgetsStateInitial: widgetsState,
          layouts: { lg: [] },
          initialLayouts: { lg: [] },
        } as ObjectDashboardState);
      },
    );
    builder.addMatcher(
      isAnyOf(
        dashboardSettingsApiEnhanced.endpoints.getApiReportObjectDashboardsById
          .matchFulfilled,
        dashboardSettingsApiEnhanced.endpoints
          .getApiSettingsReportObjectDashboardsById.matchFulfilled,
      ),
      (state, action) => {
        const dashboardId = action.meta.arg.originalArgs.id;
        const { assets, excludedLegalEntities, boards } = action.payload;

        const dashboardState = state.entities[dashboardId];
        const yesterday = dayjs(yesterdayDate());

        const excludedLegalEntityIds = mapListToIds(excludedLegalEntities);

        dashboardsAdapter.upsertOne(state, {
          id: dashboardId,
          objectLegalEntityIds: Object.fromEntries(
            assets.map((asset) => [
              asset.id,
              asset.legalEntities
                ?.filter((le) =>
                  filterIncludedLegalEntities(le, excludedLegalEntityIds),
                )
                ?.map((legalEntity) => legalEntity.id) ?? [],
            ]),
          ),
          date: dashboardState?.date ?? formatToDateStringForRequest(yesterday),
        } as ObjectDashboardState);

        boards.forEach((board) => {
          const { sections } = board;
          const boardId = board.id;
          const boardLayoutsId = buildLayoutsId({ boardId, dashboardId });
          const boardLayouts = buildLayout(
            (sections ?? []) as ReportScoreboardWidgetSectionDto[],
          );
          const widgetsState = state.entities[boardLayoutsId]?.widgetsState;
          const getWidgetsState = (type: 'initial' | 'state') => {
            return Object.fromEntries(
              sections.map((section) => [
                section.id,
                {
                  ...getInitialStateForObjectDashboardWidget(
                    section,
                    dashboardState?.date ??
                      formatToDateStringForRequest(yesterday),
                  ),
                  ...(type === 'state'
                    ? widgetsState?.[section.id]
                    : undefined),
                },
              ]),
            );
          };
          dashboardsAdapter.upsertOne(state, {
            id: boardLayoutsId,
            objectLegalEntityIds: {},
            date: '',
            widgetsState: getWidgetsState('state'),
            widgetsStateInitial: getWidgetsState('initial'),
            layouts: boardLayouts,
            initialLayouts: boardLayouts,
          } as ObjectDashboardState);
        });
      },
    );
  },
});

export const { updateObjectLegalEntityIds, updateReportObjectDashboardDate } =
  objectDashboardSlice.actions;

export const { selectById: selectReportObjectDashboardMetadataById } =
  dashboardsAdapter.getSelectors((state: TRootState) => state.reportDashboard);

export const selectReportDashboardSelectedObjectLegalEntityIds = (
  state: TRootState,
  {
    dashboardId,
    selectedObjectId,
  }: {
    dashboardId: string;
    selectedObjectId: number | null | undefined;
  },
) => {
  const dashboard = selectReportObjectDashboardMetadataById(state, dashboardId);
  if (dashboard == null || selectedObjectId == null) {
    return [];
  }
  return dashboard.objectLegalEntityIds[selectedObjectId] ?? [];
};

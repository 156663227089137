import http from 'lib/http';
import { stringify } from 'qs';
import { IReconcileReport } from 'types/Snapshot';

export const FETCH_CONSTRUCTION_SNAPSHOT =
  'symmetre-client-api/FETCH_CONSTRUCTION_SNAPSHOT';
export const FETCH_CONSTRUCTION_REPORT =
  'symmetre-client-api/FETCH_CONSTRUCTION_REPORT';
export const FETCH_CHANGE_ORDER_EVENT_BADGES =
  'symmetre-client-api/FETCH_CHANGE_ORDER_EVENT_BADGES';

export type TFundView = 'construction' | 'contingency';

export const resolveFund = (view: TFundView) => {
  if (view === 'construction') return 'hard_construction_cost_code';
  if (view === 'contingency') return 'hard_cost_contingency_code';
  return '';
};

export async function fetchReconcileSnapshot({ legalEntityCode, view }) {
  const params = { allocation_fund: resolveFund(view) };
  const res = await http.get(
    `/reconcile/legal_entities/${legalEntityCode}/snapshots?${stringify(
      params,
    )}`,
  );
  const json = await res.json();

  return json.report as IReconcileReport;
}

export async function plainFetchConstructionSnapshot(
  legalEntityCode: string,
  view: TFundView,
) {
  const params = { allocation_fund: resolveFund(view) };
  const res = await http.get(
    `/reconcile/legal_entities/${legalEntityCode}/snapshots?${stringify(
      params,
    )}`,
  );
  const responseData = await res.json();

  return responseData;
}

export function fetchConstructionSnapshot(
  legalEntityCode: string,
  view: TFundView,
) {
  return async function (dispatch) {
    const params = { allocation_fund: resolveFund(view) };
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/snapshots?${stringify(
        params,
      )}`,
    );
    const responseData = await res.json();

    dispatch({
      type: FETCH_CONSTRUCTION_SNAPSHOT,
      payload: responseData,
    });
  };
}

export function fetchConstructionReport(assetId: number, view: TFundView) {
  return async function (dispatch) {
    const params = { allocation_fund: resolveFund(view) };
    const res = await http.get(
      `/assets/${assetId}/construction_report?${stringify(params)}`,
    );
    const responseData = await res.json();

    dispatch({
      type: FETCH_CONSTRUCTION_REPORT,
      payload: responseData,
    });
  };
}

export function fetchChangeOrderEventbadges(legalEntityCode: string) {
  return async function (dispatch) {
    const res = await http.get(
      `/reconcile/legal_entities/${legalEntityCode}/change_order_event_badges`,
    );
    const responseData = await res.json();

    dispatch({
      type: FETCH_CHANGE_ORDER_EVENT_BADGES,
      payload: responseData,
    });
  };
}

import { cn } from '@/shared/lib/css/cn';
import { IPagination } from '@/shared/lib/hooks/usePagination';
import pluralize from 'pluralize';
import React from 'react';
import { Button, Dropdown, DropdownItem, IconButton } from 'stories';

const PER_PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100] as const;

export type TPerPageSizeOptions = (typeof PER_PAGE_SIZE_OPTIONS)[number];

interface Props {
  loading: boolean;
  currentPage: number;
  totalSize: number;
  sizePerPage: number;
  setCurrentPage?: (page: number, size?: number) => void;
  className?: string;
  onSizePerPageChange?: (size: number, page: number) => void;
  onPaginationChange?: (pagination: IPagination) => void;
  includeTotalSizeInPerPage?: boolean;
}

const Line = (
  <div
    className="bg-light-30"
    style={{
      width: 2,
      height: 8,
    }}
  />
);

export const ShownInfoLine = ({
  from,
  to,
  totalSize,
  name,
  size = 's',
}: {
  totalSize: number;
  size?: 's' | 'm';
  from?: number;
  to?: number;
  name?: string;
}) => {
  return (
    <span
      className={cn('uppercase text-neutral-500', {
        'secondary-regular': size === 's',
        'label-regular': size === 'm',
      })}
    >
      Shown{' '}
      {from && to && (
        <>
          <span className="text-neutral-900">
            {from}-{to}
          </span>
          &nbsp;of&nbsp;
        </>
      )}
      <span className="text-neutral-900">{totalSize}</span>
      {name && <span> {pluralize(name, totalSize)}</span>}
    </span>
  );
};

const TablePagination = ({
  loading,
  currentPage,
  setCurrentPage,
  totalSize,
  sizePerPage,
  className,
  onSizePerPageChange,
  onPaginationChange,
  includeTotalSizeInPerPage,
}: Props) => {
  const canChangeSizePerPage =
    onPaginationChange != null || onSizePerPageChange != null;
  const perPageSizeOptions = React.useMemo(
    () =>
      (canChangeSizePerPage ? PER_PAGE_SIZE_OPTIONS : [])
        .filter((s) => s <= totalSize)
        .map((s) => ({
          value: s as number,
          label: s.toString(),
        }))
        .concat(
          includeTotalSizeInPerPage
            ? {
                value: totalSize,
                label: 'All',
              }
            : [],
        ),

    [canChangeSizePerPage, totalSize],
  );

  const from = 1 + (currentPage - 1) * sizePerPage;
  const to = Math.min(currentPage * sizePerPage, totalSize);
  const totalPages = Math.ceil(totalSize / sizePerPage);
  const setPage = (page) => {
    if (!loading && (setCurrentPage != null || onPaginationChange != null)) {
      if (setCurrentPage != null) {
        setCurrentPage(page);
      }
      if (onPaginationChange != null) {
        onPaginationChange({
          currentPage: page,
          sizePerPage,
        });
      }
    }
  };

  const handleSizePerPageChange = (value: TPerPageSizeOptions) => {
    if (onPaginationChange != null || onSizePerPageChange != null) {
      const newSize = value;
      const newCurrentPage = Math.ceil(to / newSize);

      // todo get rid of this, move state to separate hook
      if (setCurrentPage != null) {
        setCurrentPage(newCurrentPage, newSize);
      }
      if (onSizePerPageChange != null) {
        onSizePerPageChange(newSize, newCurrentPage);
      }
      if (onPaginationChange != null) {
        onPaginationChange({
          sizePerPage: newSize,
          currentPage: newCurrentPage,
        });
      }
    }
  };

  const canGoBack = currentPage > 1 && !loading;
  const canGoNext =
    Math.ceil(totalSize / sizePerPage) !== currentPage && !loading;

  if (!totalSize) return null;
  const isPerPagePresent =
    canChangeSizePerPage && perPageSizeOptions.length > 1;
  return (
    <div
      className={cn(
        'secondary-regular flex items-center gap-tw-2 uppercase text-neutral-500',
        className,
      )}
    >
      {isPerPagePresent && (
        <div className="flex items-center gap-tw-2">
          <div>Per page</div>
          <Dropdown
            className="ml-s"
            items={perPageSizeOptions.map((o) => (
              <DropdownItem
                key={o.value}
                active={o.value === sizePerPage}
                onClick={() => handleSizePerPageChange(o.value)}
              >
                {o.label}
              </DropdownItem>
            ))}
          >
            <Button
              variant="secondary"
              iconName="arrowBottom"
              iconPosition="right"
              size="xs"
              disabled={loading}
            >
              {sizePerPage}
            </Button>
          </Dropdown>
          {Line}
        </div>
      )}
      <ShownInfoLine totalSize={totalSize} from={from} to={to} />
      {totalPages > 1 && (
        <div className="flex items-center">
          <IconButton
            disabled={!canGoBack || loading}
            iconName="arrowLeft"
            className="mr-s border-0 bg-white shadow-z-020"
            onClick={() => setPage(currentPage - 1)}
          />
          <IconButton
            disabled={!canGoNext || loading}
            iconName="arrowRight"
            className="border-0 bg-white shadow-z-020"
            onClick={() => setPage(currentPage + 1)}
          />
        </div>
      )}
    </div>
  );
};

export default TablePagination;

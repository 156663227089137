import React from 'react';
import Dropzone, { Accept, DropzoneOptions } from 'react-dropzone';
import { Button } from 'stories';

interface Props {
  onChange?: DropzoneOptions['onDrop'];
  buttonText?: string;
  label?: string;
  accept?: Accept;
}

const SharedFileDropzone = ({ onChange, buttonText, label, accept }: Props) => (
  <section className="bulk-uploader-dropzone">
    <Dropzone accept={accept} onDrop={onChange} useFsAccessApi={false}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="dz-default dz-message">
            <div className="dz-icon">
              <i className="demo-pli-upload-to-cloud icon-5x" />
            </div>
            <div>
              <span className="dz-text inline-regular">
                {label ?? 'Drag your documents here to start uploading'}
              </span>
              <p className="inline-regular light-60 mt-[0.5rem] mb-[0.5rem]">or</p>
              <Button variant="primary">
                {buttonText ?? 'Browse Documents'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  </section>
);

export default SharedFileDropzone;

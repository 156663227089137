import React from 'react';
import { Field } from 'stories';

import { LE_CLASSIFICATION_OPTIONS } from '../config';
import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { LeClassification } from '../types';
import { GroupBase } from 'react-select';

type OptionType = (typeof LE_CLASSIFICATION_OPTIONS)[number];
type GroupOption = GroupBase<OptionType>;

type Props<IsMulti extends boolean> = Omit<
  ISharedSelectProps<OptionType, IsMulti, GroupOption>,
  'onChange' | 'options'
> & {
  onChange: (
    value: IsMulti extends true ? LeClassification[] : LeClassification | null,
  ) => void;
} & {
  options?: ISharedSelectProps<OptionType, IsMulti, GroupOption>['options'];
};

export function AssetClassSelect<IsMulti extends boolean = false>({
  onChange,
  options,
  ...props
}: Props<IsMulti>) {
  return (
    <SharedSelect
      {...props}
      menuShouldBlockScroll
      options={options ?? LE_CLASSIFICATION_OPTIONS}
      onChange={(option) =>
        onChange(props.isMulti ? option?.map((o) => o.value) : option?.value)
      }
      placeholder="Select Class"
    />
  );
}

export function LeClassificationSelectField({
  ...props
}: React.ComponentProps<typeof Field>) {
  return <Field {...props} labelText="Classification" required />;
}

export default LeClassificationSelectField;

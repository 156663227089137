import { formatAmount } from '@/shared/lib/formatting/number';
import React, { FC } from 'react';

interface IContractColumnProps {
  payAppSummary: any;
}

const ContractColumn: FC<IContractColumnProps> = ({
  payAppSummary: { contractSumToDate },
}) => (
  <div className="inline-semibold text-right">
    {formatAmount(contractSumToDate ?? 0)}
  </div>
);

export default ContractColumn;

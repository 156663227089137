import { cn } from '@/shared/lib/css/cn';
import { getAmchartNumberFormatForByDisplayOptions } from '@/shared/lib/formatting/charts';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { FlexibleFilterByPeriods, ThinTabGroup } from '@/stories';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { ColumnSeries, XYChart } from '@amcharts/amcharts5/xy';
import { PIPELINE_CHART_COLOR_SET } from 'bundles/Scoreboard/Pipeline/components/chartUtils';
import SecondaryDropdown from 'bundles/Shared/components/SecondaryDropdown';
import {
  ReportDashboardAsset,
  ReportDashboardSegment,
  WidgetDateGranularity,
} from 'bundles/Shared/entities/dashboard';
import { XyChartSingleKpiWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import {
  DashboardWidgetCard,
  DateRangeWidgetState,
  WidgetStateGranularity,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import {
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { XYChartSingleKpiWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { EagleEyeDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { produce } from 'immer';
import { useAmchart } from 'lib/amcharts/useAmchart';
import { chartDateMapper, getReturnDashboardTheme } from 'lib/amcharts/utils';
import { camelCase, groupBy, intersection, orderBy, uniqBy } from 'lodash-es';
import { useMemo, useRef } from 'react';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import { MixedDropdown } from './ui/MixedDropdown';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { generatePrevPeriods } from '@/stories/FlexibleFilterByPeriods/consts';
import dayjs from 'dayjs';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { ChartContainer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/ChartContainer';
import { addExportToXySingleKpiChart } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/export';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import {
  addAdaptersToColumnSeries,
  findTemplateSeriesRef,
  GROUPING_TYPE_TO_OBJECT_TYPE,
  GroupingType,
  idToObjectMapper,
  isChartCategorical,
  ObjectType,
  orderItems,
  sleepUntilSeriesAreReady,
  TAB_ITEMS,
  DEFAULT_GRID_INVERVALS,
  findXAxisRef,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';

export type XYChartSingleKpiWidgetState = DateRangeWidgetState & {
  granularity: WidgetDateGranularity;
  assets: ReportDashboardAsset['id'][];
  segments: ReportDashboardSegment['id'][];
  groupingType: GroupingType;
  kpi?: number;
};

export function EagleEyeXYChartWidget(
  props: WidgetProps<XyChartSingleKpiWidgetDto, XYChartSingleKpiWidgetSection> &
    WidgetStateProps<XYChartSingleKpiWidgetState> &
    WidgetContextProps<EagleEyeDashboardWidgetContext>,
) {
  const {
    data,
    isFetching,
    state,
    onStateChange,
    context,
    widgetSection,
    mode,
  } = props;

  const isCategorical = isChartCategorical(
    widgetSection.widgetConfig.am_chart_config,
  );
  const selectedKpi = isCategorical
    ? null
    : widgetSection.widgetConfig.kpis.find((kpi) => kpi.key === state.kpi);
  const ref = useRef<{ exporting: am5plugins_exporting.Exporting }>(null);
  const chartRef = useRef<XYChart>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);
  const objectList = useMemo(() => {
    const getObjectList = (
      key: GroupingType,
    ): {
      id: number;
      type: ObjectType;
    }[] => {
      if (key === 'mixed') {
        return [
          ...state.assets.map(idToObjectMapper('asset')),
          ...state.segments.map(idToObjectMapper('segment')),
        ];
      }
      const objectMapper = idToObjectMapper(GROUPING_TYPE_TO_OBJECT_TYPE[key]);
      const contextObjectIds = mapListToIds(context[key] ?? []);
      if (state[key] != null) {
        // filter set object can include extra objects, which are not in the dashboard context
        // TODO: move to slice
        return intersection(state[key], contextObjectIds).map(objectMapper);
      }
      return contextObjectIds.map(
        idToObjectMapper(GROUPING_TYPE_TO_OBJECT_TYPE[key]),
      );
    };
    return getObjectList(state.groupingType);
  }, [state.groupingType, state.assets, state.segments, context]);

  const items = useMemo(() => {
    const mappedItems = data?.data.map(chartDateMapper('dateFrom'));
    const filteredItems = mappedItems?.filter(
      (item) =>
        (selectedKpi == null || item.kpiKey === selectedKpi?.key.toString()) &&
        objectList.some(
          (object) =>
            object.type === item.objectType &&
            object.id === Number(item.objectId),
        ),
    );
    return orderBy(filteredItems ?? [], ['dateFrom', 'asc']);
  }, [data, selectedKpi, objectList]);

  useAmchart(
    ref,
    (root) => {
      if (
        context.assets == null ||
        context.segments == null ||
        items.length === 0
      ) {
        return;
      }
      const objectNames = objectList
        .map((object) =>
          object.type === 'asset'
            ? context.assets.find((item) => item.id === object.id)?.name ?? null
            : context.segments.find((item) => item.id === object.id)?.title ??
              null,
        )
        .filter(Boolean)
        .toSorted();

      const { exportingSettings, onWorkbookReady } =
        addExportToXySingleKpiChart({
          state,
          widgetConfig: widgetSection.widgetConfig,
          widgetTitle: widgetSection.title,
          widgetId: widgetSection.id,
          groupingObjects: objectList,
          context,
          items,
        });
      ref.current!.exporting = am5plugins_exporting.Exporting.new(
        root,
        exportingSettings,
      );
      ref.current!.exporting.events.on('workbookready', onWorkbookReady);
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', PIPELINE_CHART_COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      const config = widgetSection.widgetConfig.am_chart_config;
      const orderedByKpiAndValue = orderItems(items);
      const assetRefs = groupBy(items, (item) => camelCase(item.objectName));
      const kpiRefs = groupBy(orderedByKpiAndValue, (item) =>
        item.kpiKey.toString(),
      );

      const dataRef = {
        data: items,
        ...assetRefs,
        ...kpiRefs,
        xAxisData: uniqBy(orderedByKpiAndValue, 'objectName'),
        selectedKpi,
      };
      const chartConfig = produce(config, (draft) => {
        draft.refs.unshift(dataRef);
        const templateSeriesRef = findTemplateSeriesRef(draft);
        if (templateSeriesRef != null) {
          const [template] = templateSeriesRef.templateSeries;
          templateSeriesRef.templateSeries = objectNames.map((s) => ({
            ...template,
            settings: {
              ...template.settings,
              name: s,
            },
            properties: {
              ...template.properties,
              data: `#${camelCase(s)}`,
            },
          }));
        }
        if (!isCategorical) {
          const xAxisRef = findXAxisRef(draft);
          if (xAxisRef != null) {
            xAxisRef.xAxis.settings.baseInterval = {
              timeUnit: state.granularity,
              count: 1,
            };
            xAxisRef.xAxis.settings.gridIntervals =
              DEFAULT_GRID_INVERVALS.filter(
                (interval) => interval.timeUnit === state.granularity,
              );
          }
        }
      });
      parser
        .parse(chartConfig, {
          parent: root.container,
        })
        .then(async function (chart: XYChart) {
          chartRef.current = chart;
          await sleepUntilSeriesAreReady();
          chart.series.each((series) => {
            if (series.isType('ColumnSeries')) {
              addAdaptersToColumnSeries(series as ColumnSeries);
            }
          });

          chart.root.numberFormatter.setAll({
            numberFormat: getAmchartNumberFormatForByDisplayOptions(
              selectedKpi?.value_display_options ?? null,
              {
                abbreviate: false,
              },
            ),
          });
        });
    },
    [items, state.granularity],
  );

  const handleGroupingTypeChange = (newGroupingType: GroupingType) => {
    onStateChange({
      ...state,
      groupingType: newGroupingType,
      assets:
        newGroupingType === 'mixed' || newGroupingType === 'assets'
          ? mapListToIds(
              widgetSection.defaultOptions.objects?.filter(
                (obj) => obj.type === 'asset',
              ) ?? context.assets,
            )
          : [],
      segments:
        newGroupingType === 'mixed' || newGroupingType === 'segments'
          ? mapListToIds(
              widgetSection.defaultOptions.objects?.filter(
                (obj) => obj.type === 'segment',
              ) ?? context.segments,
            )
          : [],
    });
  };

  const renderDatePicker = () => {
    if (
      isCategorical &&
      (state.granularity === 'year' || state.granularity === 'month')
    ) {
      const periodItems = [
        {
          type: state.granularity === 'year' ? 'ytd' : 'mtd',
          period: formatToDateStringForRequest(state.dateFrom),
        },
      ];

      const handleUpdatePeriodItems = (newItems: IPeriodItem[]) => {
        onStateChange({
          ...state,
          dateFrom: formatToDateStringForRequest(
            dayjs(newItems[0].period).startOf(state.granularity),
          ),
          dateTo: formatToDateStringForRequest(
            dayjs(newItems[0].period).endOf(state.granularity),
          ),
        });
      };
      return (
        <FlexibleFilterByPeriods
          filterByPeriodsType={state.granularity === 'year' ? 'ytd' : 'mtd'}
          isSingleSelection
          possiblePeriods={generatePrevPeriods()}
          periodItems={periodItems}
          onUpdatePeriodItems={handleUpdatePeriodItems}
        />
      );
    }
    return (
      <WidgetStateCalendarRangeSelector
        state={state}
        onStateChange={onStateChange}
      />
    );
  };

  return (
    <DashboardWidgetCard
      {...props}
      className={cn(mode === 'edit' && 'h-[500px]')}
      ref={wrapperDivRef}
    >
      <DashboardWidgetCard.Header className="gap-tw-2">
        <div className="flex">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          <RecapPageButton
            name={widgetSection.recapPage?.name}
            slug={widgetSection.recapPage?.slug}
          />
        </div>
        <GrowDiv />
        <ThinTabGroup
          selectedItem={state.groupingType}
          onSelectedItemChange={(newItem) => {
            handleGroupingTypeChange(newItem.id);
          }}
          items={TAB_ITEMS.filter(
            (item) =>
              widgetSection.defaultOptions.groupingTypes == null ||
              widgetSection.defaultOptions.groupingTypes.includes(item.id),
          )}
        />
        <widgetStateFullScreenFeature.IconButton />
      </DashboardWidgetCard.Header>
      <DashboardWidgetCard.Panel>
        {!isCategorical && (
          <SecondaryDropdown
            maxWidth="max-content"
            items={
              widgetSection.widgetConfig.kpis?.map((kpi) => ({
                label: kpi.label,
                id: kpi.key,
              })) ?? []
            }
            selectedItem={state.kpi}
            onSelectedChange={(key) =>
              onStateChange({
                ...state,
                kpi: key,
              })
            }
          />
        )}
        <MixedDropdown
          context={context}
          state={state}
          onStateChange={onStateChange}
        />
        {!isCategorical && (
          <>
            <WidgetStateGranularity
              state={state}
              onStateChange={onStateChange}
              chartRef={chartRef}
              granularities={widgetSection.defaultOptions.granularities}
            />
          </>
        )}
        {renderDatePicker()}
        <div className="grow" />
        <ExportChartButton chartRef={ref} widgetSectionId={widgetSection.id} />
      </DashboardWidgetCard.Panel>
      {isFetching && <AnimationLoader />}
      <ChartContainer ref={ref} />
    </DashboardWidgetCard>
  );
}

import React, { useMemo } from 'react';
import PermissionList from 'bundles/Shared/components/PermissionList';
import { getLegalEntitiables } from '../utils/getLegalEntitiables';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';

interface Props {
  segment: ReportSegment;
}

const CellTitle = ({ segment }: Props) => {
  const { assets, funds } = useMemo(
    () => getLegalEntitiables(segment.legalEntities),
    [segment],
  );

  return (
    <div>
      <h6>{segment.title}</h6>
      <div className="light-60 my-s">{segment.reason}</div>
      <div className="dashed-border-b cursor-default">
        <PermissionList
          placeholderRenderer=""
          withoutSharedModal
          permissions={{
            assets,
            funds,
            roles: [],
            tags: [],
            investmentEntities: [],
            users: [],
            autoSelectedUsers: [],
          }}
        />
      </div>
    </div>
  );
};

export default CellTitle;

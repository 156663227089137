import { RefObject, useCallback } from 'react';
import { OverlayScrollbars } from 'overlayscrollbars';
import { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';

const BOTTOM_SHADOW_CLASS = 'sidebar-menu-scrollable-area_bottom';
const TOP_SHADOW_CLASS = 'sidebar-menu-scrollable-area_top';

const NEXT_TO_BOTTOM_SCROLL_RATIO = 0.99;
const NEXT_TO_TOP_SCROLL_RATIO = 0.01;
const TOP_SCROLL_RATIO = 0;

const getScrollableFlags = (instance: OverlayScrollbars) => {
  const scrollElement = instance.elements().viewport;
  const hasOverflow = instance.state().hasOverflow.y;
  const { scrollTop, scrollHeight, clientHeight } = scrollElement;
  const scrollRatio = (scrollTop + clientHeight) / scrollHeight;
  const isInBottom = scrollRatio > NEXT_TO_BOTTOM_SCROLL_RATIO;
  const isInTop = scrollTop === TOP_SCROLL_RATIO;
  const isInMiddle =
    scrollRatio > NEXT_TO_TOP_SCROLL_RATIO &&
    scrollRatio < NEXT_TO_BOTTOM_SCROLL_RATIO;
  return {
    scrollRatio,
    scrollTop,
    clientHeight,
    scrollHeight,
    isInBottom,
    isInTop,
    isInMiddle,
    hasOverflow,
  };
};

const shadowModifier =
  (modifier: 'add' | 'remove') =>
  (
    classList: Element['classList'],
    position: 'top' | 'bottom' | 'top-bottom',
  ) => {
    const modifierMethod =
      modifier === 'add'
        ? (...args) => classList.add(...args)
        : (...args) => classList.remove(...args);

    if (position === 'top') {
      modifierMethod(TOP_SHADOW_CLASS);
    } else if (position === 'bottom') {
      modifierMethod(BOTTOM_SHADOW_CLASS);
    } else {
      modifierMethod(BOTTOM_SHADOW_CLASS, TOP_SHADOW_CLASS);
    }
  };

const addShadow = shadowModifier('add');
const removeShadow = shadowModifier('remove');

export const useScrollBarOverflowShadows = (
  ref: RefObject<OverlayScrollbarsComponentRef>,
) => {
  const onScroll = useCallback(
    (instance: OverlayScrollbars) => {
      const { isInBottom, isInTop } = getScrollableFlags(instance);
      const scrollableAreaStyleClasses = instance.elements().host.classList;
      if (!scrollableAreaStyleClasses) {
        return;
      }
      if (isInBottom) {
        removeShadow(scrollableAreaStyleClasses, 'bottom');
      } else if (isInTop) {
        removeShadow(scrollableAreaStyleClasses, 'top');
      } else {
        addShadow(scrollableAreaStyleClasses, 'top-bottom');
      }
    },
    [ref.current],
  );

  const onOverflowChanged = useCallback((instance: OverlayScrollbars) => {
    const { isInBottom, hasOverflow, isInTop } = getScrollableFlags(instance);
    const scrollableAreaStyleClasses = instance.elements().host.classList;
    if (!scrollableAreaStyleClasses) {
      return;
    }
    if (!hasOverflow) {
      removeShadow(scrollableAreaStyleClasses, 'top-bottom');
      return;
    }
    if (isInBottom) {
      addShadow(scrollableAreaStyleClasses, 'bottom');
      removeShadow(scrollableAreaStyleClasses, 'top');
    }
    if (isInTop) {
      addShadow(scrollableAreaStyleClasses, 'top');
      removeShadow(scrollableAreaStyleClasses, 'bottom');
    }
  }, []);

  return { onOverflowChanged, onScroll };
};

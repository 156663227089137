import React, { createContext, useContext } from 'react';
import { EmptyObject } from 'type-fest';

interface PageParamsContextParams<Params = any> {
  pageParams: Params;
  setPageParams: React.Dispatch<React.SetStateAction<Params>>;
}

const PageParamsContext = createContext<PageParamsContextParams | null>(null);

export const PageParamsProvider = <Params,>({
  children,
  pageParams,
  setPageParams,
}: React.PropsWithChildren<PageParamsContextParams<Params>>) => (
  <PageParamsContext.Provider
    value={{
      pageParams,
      setPageParams,
    }}
  >
    {children}
  </PageParamsContext.Provider>
);

export function usePageParamsContext<Params = EmptyObject>() {
  const context = useContext(PageParamsContext);

  if (context == null) {
    throw new Error('usePageParamsContext was used outside of its Provider');
  }

  return context as PageParamsContextParams<Params>;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import http from 'lib/http';
import { IGenericData } from 'bundles/Shared/GenericContext';

export const fetchGenericData = createAsyncThunk(
  'generic/fetchGenericData',
  async () => {
    const res = await http.get('/users/current');
    const data: IGenericData = await res.json();
    window.symmetreConfig = { ...window.symmetreConfig, ...data };
    return data;
  },
);
export const fetchCustomerData = createAsyncThunk(
  'generic/fetchCustomerData',
  async () => {
    const res = await http.get('/customers/current');
    const data: IGenericData['customer'] = await res.json();
    return data;
  },
);

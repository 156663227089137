import { cn } from '@/shared/lib/css/cn';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Link } from '@reach/router';
import { ReturnInvestmentObjectTab } from 'bundles/REturn/components/Home/investmentObjectDetailsModal/utils';
import React from 'react';
import { Icon } from 'stories';
import { IconsId } from 'types/sre-icons';
import styles from './DetailsCard.module.scss';

interface Props {
  iconName: IconsId;
  iconColor: string;
  title: string;
  value: number;
  formatter: (value: number) => React.ReactNode;
  investmentObjectId: string;
  subtitle?: string;
  tab?: ReturnInvestmentObjectTab;
}

function DetailsCard({
  iconName,
  iconColor,
  title,
  subtitle,
  formatter,
  value,
  investmentObjectId,
  tab,
}: Props) {
  const hasData = value !== 0;

  return (
    <div className={styles.container}>
      <div className={cn(styles.card)}>
        <div className="flex items-center gap-s">
          <Icon
            className={cn('text-[1.5rem]', styles.icon)}
            color={iconColor}
            iconName={iconName}
          />
          <div className="flex flex-col">
            <span className="light-60 secondary-semibold">
              {subtitle ?? 'Total'}
            </span>
            <span className={cn('secondary-semibold', styles.title)}>
              {title}
            </span>
          </div>
        </div>
        <span
          className={cn(
            'header5-regular text-nowrap',
            hasData ? 'dark-60' : 'light-60',
          )}
        >
          {formatter(value)}
        </span>
        <Link
          className={cn(
            styles.button,
            'secondary-semibold',
            !hasData && styles.button_disabled,
          )}
          to={
            hasData
              ? generateUrl(ROUTES_ROOT.return.fullPath, {
                  queryParams: { detailsId: investmentObjectId, tab },
                  pathParams: {},
                })
              : ''
          }
        >
          {hasData ? 'Open Details' : 'No Data Yet'}
        </Link>
      </div>
    </div>
  );
}

export default DetailsCard;

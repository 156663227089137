import {
  usePostApiReconcileUnderwritingBudgetsCreateFromSourceMutation,
  usePostApiReconcileUnderwritingBudgetsMutation,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import { LegalEntityUnderwritingBudgetModel } from 'bundles/REconcile/underwritting/model';
import { CreateBudgetModal } from 'bundles/Shared/features/underwritingBudget/budget/create/ui';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useCallback } from 'react';

export function useCreateUnderwritingBudget() {
  const { openModal } = useModal();
  const [createBudgetFromSource, optionsFromSource] =
    usePostApiReconcileUnderwritingBudgetsCreateFromSourceMutation();
  const [createBudget, options] =
    usePostApiReconcileUnderwritingBudgetsMutation();

  const handleCreateBudgetModal = useCallback(
    async ({
      legalEntityId,
    }: {
      legalEntityId: LegalEntityUnderwritingBudgetModel['id'];
    }) => {
      const res = await openModal(CreateBudgetModal, {
        legalEntityId,
      });

      if (res == null) return;

      if ('source_legal_entity_id' in res) {
        await createBudgetFromSource({
          body: {
            legal_entity_id: legalEntityId,
            source_legal_entity_id: res.source_legal_entity_id,
          },
        });
        return;
      }

      await createBudget({
        body: {
          ...res,
          legal_entity_id: legalEntityId,
        },
      });
    },
    [],
  );

  return [
    handleCreateBudgetModal,
    {
      options,
      optionsFromSource,
    },
  ] as const;
}

import React from 'react';
import { ReportEagleEyeDashboard } from '../../model';
import { Icon } from 'stories/index';
import { capitalize } from 'lodash-es';
import { DashboardCardLabel } from '../card/DashboardCardLabel';
import { STATUS_OPTIONS_SETTINGS } from 'bundles/Shared/entities/dashboard';

interface Props {
  status: ReportEagleEyeDashboard['status'];
}

export function DashboardCardStatusLabel({ status }: Props) {
  return (
    <DashboardCardLabel>
      <Icon
        className={STATUS_OPTIONS_SETTINGS[status].color}
        iconName={STATUS_OPTIONS_SETTINGS[status].icon}
      />
      <span className="secondary-semibold text-neutral-900">
        {capitalize(status)}
      </span>
    </DashboardCardLabel>
  );
}

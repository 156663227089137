import React from 'react';
import pluralize from 'pluralize';
import AlbumCard from 'stories/AlbumCard/AlbumCard';

export interface IFolder {
  id: number;
  sharedFilesCount: number;
  childrenCount: number;
  description: string;
  title: string;
  coverImageUrl: string;
  parentId?: string | number;
}

interface Props {
  folders: IFolder[];
  selectedFolders?: IFolder[];
  canManage?: boolean;
  toggleSelected?: (folder: IFolder) => void;
  onEdit?: (folder: IFolder) => void;
  onClick: (id: IFolder['id']) => void;
  onDelete?: (folder: IFolder) => void;
  checkboxDisabled?: boolean;
  lgSize?: number;
}

const AlbumsList = ({
  folders,
  selectedFolders,
  toggleSelected,
  canManage,
  onClick,
  onEdit,
  onDelete,
  checkboxDisabled = false,
}: Props) => (
  <div className="grid gap-l md:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
    {folders.map((folder) => (
      <div key={folder.id}>
        <AlbumCard
          key={folder.id}
          imageCover={folder.coverImageUrl}
          selected={
            selectedFolders?.find(({ id }) => id === folder.id) !== undefined
          }
          onSelectToggle={canManage ? () => toggleSelected?.(folder) : undefined}
          title={folder.title}
          description={folder.description}
          onClick={() => onClick(folder.id)}
          checkboxDisabled={checkboxDisabled}
          actions={
            canManage
              ? [
                  {
                    icon: 'edit',
                    onClick: () => onEdit?.(folder),
                  },
                  {
                    icon: 'trash',
                    onClick: () => onDelete?.(folder),
                  },
                ]
              : []
          }
          imagePlaceholder="media"
          items={
            <>
              {folder.sharedFilesCount + folder.childrenCount}&nbsp;
              {pluralize(
                'item',
                Number(folder.sharedFilesCount) + Number(folder.childrenCount),
              )}
            </>
          }
        />
      </div>
    ))}
  </div>
);

export default AlbumsList;

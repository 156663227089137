import Table from 'bundles/Shared/components/Table/Table';
import FileExtensionIcon from 'bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import { importUsers } from 'bundles/UserManagement/actions/User';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  Button,
  IconButton,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
  MultilineAlert,
  OverlaySpinner,
} from 'stories';
import FileInfo from 'stories/DocumentCard/components/FileInfo';

interface Props {
  onClose: () => void;
  fetchAndSetUsers: () => void;
}

const ImportUsersModal = ({ onClose, fetchAndSetUsers }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [result, setResult] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const onDrop = (file: File[]) => {
    setSelectedFile(file[0]);
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      setLoading(true);
      const response = await importUsers(formData);
      setLoading(false);
      setResult(response);
      fetchAndSetUsers();
    }
  };

  return (
    <Modal
      toggle={onClose}
      size="600"
      header={
        <ModalHeaderWithSubtitle title="Import Users" order="subtitle-title" />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          {!result && (
            <Button
              variant="success"
              onClick={handleFileUpload}
              disabled={selectedFile === null && !loading}
            >
              Import Users
            </Button>
          )}
        </ModalActions>
      }
    >
      {loading && <OverlaySpinner size="small" />}
      {!selectedFile && !result && (
        <div className="align-center flex h-full w-full justify-center">
          <Dropzone
            onDrop={onDrop}
            accept={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
            }}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: 'dropzone rounded-[0.5rem] w-full',
                })}
              >
                <input {...getInputProps()} multiple={false} />
                <div className="dz-default dz-message">
                  <div className="dz-icon">
                    <i className="demo-pli-upload-to-cloud icon-5x" />
                  </div>
                  <div>
                    <span className="text-sm">
                      Drag a file to start uploading
                    </span>
                    <p className="light-60 m-2 text-sm">or</p>
                    <Button variant="primary">Browse files</Button>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}

      {selectedFile && !result && (
        <div className="flex flex-col">
          <i className="demo-pli-file-excel icon-5x" />
          <div className="text-md dark-60 mb-3">Selected file for import:</div>
          <div className="flex gap-s">
            <FileExtensionIcon className="bg-light-10" extension="xlsx" />
            <div className="text-sm">
              {selectedFile.name}
              <FileInfo size={selectedFile.size} extension={'xlsx'} />
            </div>
            <div>
              <IconButton
                iconName="closeSmall"
                onClick={() => setSelectedFile(null)}
              />
            </div>
          </div>
        </div>
      )}

      {result && (
        <div>
          {result.successCount > 0 && (
            <div className="green-dark-2 mb-4 flex items-center gap-xs">
              <MultilineAlert
                status="success"
                message={
                  <>
                    <span className="font-semibold">{result.successCount}</span>{' '}
                    {pluralize('user', result.successCount)} has been
                    successfully imported
                  </>
                }
              />
            </div>
          )}

          {result.invalidUsers.length > 0 && (
            <div>
              <MultilineAlert
                status="danger"
                message={
                  <>
                    <span className="font-semibold">
                      {result.invalidUsers.length}
                    </span>{' '}
                    {pluralize('user', result.invalidUsers.length)} were not
                    imported
                  </>
                }
                className="mb-4"
              />
              <Table
                items={result.invalidUsers}
                columns={[
                  {
                    text: 'User',
                    dataField: 'user',
                    formatter: ({ row }) => (
                      <div>
                        <div className="dark-60 text-sm">{row.email}</div>
                        <div className="light-60 text-sm">{row.fullName}</div>
                      </div>
                    ),
                  },
                  {
                    text: 'Errors',
                    dataField: 'errors',
                    formatter: ({ row }) => (
                      <div className="flex">
                        <div>
                          {row.errors.map((error: string, i: number) => (
                            <div className="text-sm" key={i}>
                              {error}
                            </div>
                          ))}
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ImportUsersModal;

import { TUserInviteStatus } from 'types/User';

const USER_STATUS_LABELS: Record<TUserInviteStatus | 'all', string> = {
  all: 'All',
  [TUserInviteStatus.ACTIVE]: 'Active',
  [TUserInviteStatus.DEACTIVATED]: 'Deactivated',
  [TUserInviteStatus.INVITED]: 'Invited',
  [TUserInviteStatus.INVITED_LONG_TIME_AGO]: 'Expired',
  [TUserInviteStatus.CREATED]: 'Created',
};

export default USER_STATUS_LABELS;

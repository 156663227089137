import React, { useEffect, useState } from 'react';
import {
  useGetInvoiceQuery,
  useVerifyInvoiceMutation,
} from 'bundles/Construction/api/invoices';
import { navigate, useParams } from '@reach/router';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import { fetchCompanies } from 'bundles/Construction/reducers/ReconcileSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  addListInvoiceLineItem,
  selectInvoiceLineItems,
} from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';
import { AnimationLoader, Button } from 'stories';
import { replacePathParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { calcTotal } from 'bundles/DrawPackage/InvoicesFlow/Invoices/initData';
import {
  resetInvoiceState,
  selectEditInvoiceChanging,
  selectInvoiceDetails,
  setEditInvoiceChanging,
  setInvoiceDetails,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import Errors from 'bundles/Errors';
import InvoiceCreateBlock from 'bundles/DrawPackage/InvoicesFlow/Invoices/InvoiceCreateBlock';
import LineItemsInvoices from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/steps/LineItemsInvoices';
import { toDateSeconds } from '@/shared/lib/formatting/dates';
import { transformLineItem } from 'bundles/DrawPackage/InvoicesFlow/Invoices/edit/utils';
import { transformLineSelectedItems } from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/utils';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export type TLineItemFlowType = 'manually' | 'select';

const EditInvoice = () => {
  const dispatch = useAppDispatch();
  const lineItems = useAppSelector(selectInvoiceLineItems);
  const invoiceDetails = useAppSelector(selectInvoiceDetails);
  const editChanging = useAppSelector(selectEditInvoiceChanging);

  const [flow, setFlow] = useState<'manually' | 'select'>('manually');

  const params = useParams();

  const { data: invoice, isLoading } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const { data: categoriesFlat, error: isErrorCategoriesFlat } =
    useGetDevelopmentCategoryFlatQuery({
      legalEntityCode: params?.legalEntityCode,
      without_sovcs: 'false',
    });

  const [verifyInvoice] = useVerifyInvoiceMutation();

  const selectedLineItems = lineItems.filter(
    (lineItem) => flow !== 'select' || lineItem.selected,
  );

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  useEffect(() => {
    if (invoice) {
      dispatch(
        setInvoiceDetails({
          amount: invoice.amount,
          vendor_name: {
            label: invoice.vendor.name,
            value: invoice.vendor.name,
          },
          number: invoice.number,
          date: toDateSeconds(invoice.date),
        }),
      );
      dispatch(setEditInvoiceChanging(true));
      dispatch(
        addListInvoiceLineItem(
          invoice.lineItems.map((lineItem) =>
            transformLineItem(lineItem, invoice),
          ),
        ),
      );
    }
  }, [invoice]);

  const handleClose = () => {
    navigate(
      replacePathParams(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
        },
      ),
    );
  };

  const saveUpdates = async () => {
    if (invoice) {
      const res = await verifyInvoice({
        legalEntityCode: params?.legalEntityCode,
        invoiceId: params.invoiceId,
        body: {
          ...invoiceDetails,
          date: formatToDateStringForRequest(invoiceDetails.date),
          vendor_name: invoiceDetails.vendor_name.value,
          line_items: transformLineSelectedItems(
            selectedLineItems,
            invoiceDetails.vendor_name.value,
            categoriesFlat,
          ),
          selected_line_items: selectedLineItems
            .filter((lineItem) => lineItem.id)
            .map((lineItem) => lineItem.id),
          approval_stamp: {
            ...invoice.approvalStamp,
            involved_pages: invoice.approvalStamp.involvedPages,
            page_position: invoice.approvalStamp.pagePosition,
          },
        },
        customNotify: 'Updates Saved',
      });
      if (res.error) return false;
      dispatch(resetInvoiceState());
      navigate(
        replacePathParams(
          ROUTES_ROOT.reconcile.development.legalEntity.invoices.approve
            .fullPath,
          {
            legalEntityCode: params.legalEntityCode,
            invoiceId: params.invoiceId,
          },
        ),
      );
    }
  };

  const backHandle = () => {
    navigate(
      replacePathParams(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.approve.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  };

  const changeFlow = (flowType: TLineItemFlowType) => {
    setFlow(flowType);
  };

  const canEdit = () =>
    Boolean(calcTotal(selectedLineItems) !== Number(invoiceDetails?.amount)) ||
    editChanging ||
    !Object.keys(invoiceDetails).every((key) => Boolean(invoiceDetails[key]));

  if (isLoading) return <AnimationLoader />;

  return (
    <div>
      <FilePreview
        file={{
          url: invoice?.document.url,
        }}
        handleClose={handleClose}
        header={
          <div className="word-break header6-bold text-center text-dark-60">
            Edit Invoice
          </div>
        }
      >
        {isErrorCategoriesFlat && (
          <Errors
            code="not_found"
            actionButton={{
              text: 'Back to Invoices',
              onClick: handleClose,
            }}
          />
        )}
        {!isErrorCategoriesFlat && (
          <div className={cn('flex flex-col', styles.pageEditContainer)}>
            <div className="flex flex-1 flex-col gap-tw-4">
              <div className="flex flex-col gap-tw-6 bg-tw-white pt-tw-6">
                <div className="flex flex-col gap-tw-4 px-tw-6">
                  <div className="body-semibold text-dark-60">
                    Invoice Details
                  </div>
                  <InvoiceCreateBlock />
                </div>
                <div>
                  <LineItemsInvoices
                    flow={flow}
                    changeFlow={changeFlow}
                    editFlow
                  />
                </div>
              </div>
            </div>
            <div className="bg-white px-tw-6 pb-tw-6 pt-tw-4">
              <div className="flex flex-col gap-tw-3">
                <div className="flex gap-tw-4">
                  <Button
                    variant="secondary"
                    className="w-full"
                    onClick={backHandle}
                  >
                    Back
                  </Button>
                  <Button
                    variant="success"
                    className="w-full"
                    onClick={saveUpdates}
                    disabled={canEdit()}
                  >
                    Save Updates
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </FilePreview>
    </div>
  );
};

export default EditInvoice;

import { TextAreaWidgetConfigCard } from '@/bundles/Shared/widgets/dashboard/widgets/textArea/ui/TextAreaWidgetConfigCard';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { TextAreaWidget } from 'bundles/Shared/widgets/dashboard/widgets/textArea/ui/TextAreaWidget';
import * as yup from 'yup';

export const TEXT_AREA_WIDGET_CONFIG = {
  icon: 'newIconText',
  title: 'Text Area',
  Component: TextAreaWidget,
  CardConfigComponent: TextAreaWidgetConfigCard,
  layout: {
    minW: 1,
    maxW: 2,
    minH: 12,
    maxH: 12,
    w: 1,
    h: 12,
  },
} as const satisfies WidgetConfiguration;

export const REPORT_BUILDER_TEXTAREA_SCHEMA = yup.object().shape({
  html: yup.string(),
});
export type ReportBuilderTextAreaForm = yup.InferType<
  typeof REPORT_BUILDER_TEXTAREA_SCHEMA
>;
export const DEFAULT_REPORT_BUILDER_TEXTAREA_FORM = {
  html: '',
} as const satisfies ReportBuilderTextAreaForm;

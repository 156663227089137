import React from 'react';
import { Badge, Icon, LinkButton } from 'stories';
import { Link } from '@reach/router';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { useModal } from '@/shared/lib/hooks/useModal';
import NotificationsModal from 'bundles/Shared/components/Nav/Modals/NotificationsModal';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { toggleSidebar } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import { CssVar } from '@/shared/config/cssVar';

const MobileNav = () => {
  const dispatch = useAppDispatch();
  const unreadNotifications = useAppSelector(
    (state) => state.notifications.unread,
  );
  const { genericData } = useGenericData();
  const { customer } = genericData;
  const { openModal } = useModal();

  if (!unreadNotifications) return null;

  return (
    <div className="mobile-nav p-s">
      <div className="flex w-full items-center gap-m p-s">
        <LinkButton
          className="h-l w-l md:h-xl md:w-xl"
          id="mobileNav"
          onClick={() => dispatch(toggleSidebar())}
        >
          <Icon iconName="navBurger" className="" />
        </LinkButton>
        <Link to="/" className="flex h-l">
          <img src={customer.logoUrl} className="h-l" alt="logo" />
        </Link>
        {unreadNotifications.length > 0 && (
          <Badge
            backgroundColor={CssVar.yellow}
            textColor={CssVar.white}
            className="ml-auto"
            onClick={() => openModal(NotificationsModal, {})}
          >
            <span className="light">
              <div className="flex items-center">
                <span className="sre-icon-bell mr-025rem" />+
                {unreadNotifications.length}
              </div>
            </span>
          </Badge>
        )}
      </div>
    </div>
  );
};

export default MobileNav;

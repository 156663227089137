import http from 'lib/http';
import { IPipelineCard } from 'bundles/Settings/components/DealPath/PipelineCards';

export function updatePipelineCard(
  card: IPipelineCard,
  data: Omit<IPipelineCard, 'id'>,
) {
  return http
    .put(`/pipeline/pipeline_cards/${card.id}`, { pipeline_card: data })
    .then((res) => res.json());
}

import { createContext, useContext } from 'react';

type ReportBuilderTemplateContextType = {
  groupId: string;
  widgetId: string;
  templateId: string;
};
export const ReportBuilderTemplateContext =
  createContext<ReportBuilderTemplateContextType | null>(null);

export const useReportBuilderTemplateContext = () => {
  return useContext(ReportBuilderTemplateContext);
};

import React, { useCallback, useEffect } from 'react';
import {
  canCloneFormula,
  canUpdateFormula,
  cloneFormula,
  EditFormulaForm,
  FormulaDescriptionField,
  FormulaExpressionField,
  FormulaLabelField,
  FormulaReferenceField,
  isFormulaFormEquals,
  isNewFormula,
  prepareFormulaFormValuesForRequest,
  useFormulaFields,
  useFormulaLabelToReferenceEffect,
} from 'bundles/Shared/features/formula/editFormula';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import {
  canRemoveFormula,
  FormulaErrors,
  formulaExpressionIncludesInvalidReference,
  formulaHasInvalidReference,
  FormulaInUse,
  FormulaReferenceLabel,
  openFormula,
  useReportFormulasQueryById,
} from 'bundles/Shared/entities/formula';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  useDeleteApiSettingsReportFormulasByIdMutation,
  usePostApiSettingsReportFormulasMutation,
  usePutApiSettingsReportFormulasByIdMutation,
  usePutApiSettingsReportFormulasReplaceAllReferencesMutation,
} from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { ReplaceWithFormulaModal } from 'bundles/Shared/features/formula/replaceInvalidReference';
import { ReplaceWithConfirmationChoiseModal } from 'bundles/Shared/features/formula/replaceInvalidReference/ui/ReplaceWithConfirmationChoiseModal';
import { Button, Icon, PseudoLink } from '@/stories';
import pluralize from 'pluralize';
import { cn } from '@/shared/lib/css/cn';
import { FormProvider } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import {
  EntityTagField,
  isSystemFormulaAndNotUniform,
  TAG_ENTITIES,
} from 'bundles/Shared/entities/tag';

export const EditFormula = React.memo(
  ({
    formula,
    update,
    index,
    visible,
  }: {
    formula: EditFormulaForm;
    update: (index: number, form: EditFormulaForm) => void;
    index: number;
    visible?: boolean;
  }) => {
    const dispatch = useAppDispatch();
    const { formula: initialFormula } = useReportFormulasQueryById(
      formula.id ?? null,
    );
    const { openModal } = useModal();
    const [createFormula, { isLoading: isCreateLoading }] =
      usePostApiSettingsReportFormulasMutation();
    const [updateFormula, { isLoading: isUpdateLoading }] =
      usePutApiSettingsReportFormulasByIdMutation();
    const [deleteFormula, { isLoading: isDeleteLoading }] =
      useDeleteApiSettingsReportFormulasByIdMutation();

    const methods = useFormulaFields({
      defaultValues: {
        ...formula,
      },
    });

    const {
      reset,
      handleSubmit,
      watch,
      control,
      setValue,
      getValues,
      formState: { isDirty, isValid },
    } = methods;

    const isSubmitting = isCreateLoading || isUpdateLoading || isDeleteLoading;
    const isNew = isNewFormula(formula);
    const initialFormulaExists = initialFormula != null;
    const canUpdate =
      isNew || (initialFormulaExists && canUpdateFormula(initialFormula));
    const canRemove =
      !isNew && initialFormulaExists && canRemoveFormula(initialFormula);
    const isUpdateButtonDisabled =
      !isDirty || isSubmitting || !canUpdate || !isValid;
    const isDeleteButtonDisabled = isSubmitting || !canRemove;
    const isCloneButtonDisabled =
      isSubmitting ||
      isNew ||
      (initialFormulaExists && !canCloneFormula(initialFormula));

    useFormulaLabelToReferenceEffect(methods);
    useEffect(() => {
      const sub = watch((values) => {
        update(index, {
          ...(values as EditFormulaForm),
        });
      });
      return () => {
        sub.unsubscribe();
      };
    }, []);
    // formula has been changed from outside (sync with api cache)
    useEffect(() => {
      if (
        initialFormula == null ||
        isFormulaFormEquals(initialFormula, getValues())
      ) {
        return;
      }
      reset({
        ...initialFormula,
      });
    }, [initialFormula]);

    const onSubmit = handleSubmit(async (values) => {
      const mutate = formula.id == null ? createFormula : updateFormula;
      const res = await mutate(
        prepareFormulaFormValuesForRequest(values, formula.id),
      );
      if ('error' in res) {
        return;
      }
      reset({
        ...res.data,
        id: res.data.id,
      });
    });

    const handleClone = () => {
      dispatch(
        openFormula({
          ...cloneFormula(formula),
        }),
      );
    };

    const Errors = useCallback(() => {
      if (initialFormula == null) {
        return null;
      }
      const { value: opened, toggle: toggleOpened } = useBoolean(true);
      const hasErrors = formulaHasInvalidReference(initialFormula);
      const errorsCount = initialFormula.invalidReferences?.length;
      const [replaceAll] =
        usePutApiSettingsReportFormulasReplaceAllReferencesMutation();
      const handleReplace = async (reference: string) => {
        const res = await openModal(ReplaceWithFormulaModal, {});
        if (!res) {
          return;
        }
        const mode = await openModal(ReplaceWithConfirmationChoiseModal, {
          referenceSource: formula.reference,
          referenceTarget: res,
        });
        if (!mode) {
          return;
        }
        const replacedExpression = getValues('expression').replaceAll(
          reference,
          res,
        );
        if (mode === 'current') {
          setValue('expression', replacedExpression, {
            shouldDirty: true,
          });
          return;
        }
        await replaceAll({
          body: {
            old_reference: reference,
            new_reference: res,
          },
        });
      };

      return (
        hasErrors && (
          <>
            <div className="flex items-center gap-tw-2">
              <Icon
                className="rounded-full bg-danger-000 px-tw-0.5 text-danger-055"
                iconName="attention"
              />
              <span className="inline-semibold text-neutral-700">
                Formula has {errorsCount}{' '}
                <span className="danger-055">
                  {pluralize('error', errorsCount)}
                </span>
              </span>
              <Icon iconName="bottom" onClick={toggleOpened} />
            </div>
            <FormulaErrors.CardList className={cn(!opened && 'hidden')}>
              {initialFormula.invalidReferences?.map((error, errorIndex) => (
                <FormulaErrors.Card
                  key={error.reference}
                  index={errorIndex}
                  error={error}
                  replaced={
                    !formulaExpressionIncludesInvalidReference(
                      formula.expression,
                      error,
                    )
                  }
                >
                  {formulaExpressionIncludesInvalidReference(
                    formula.expression,
                    error,
                  ) && (
                    <Button
                      disabled={!canUpdateFormula(initialFormula)}
                      className="invisible group-hover/item:!visible"
                      onClick={() => handleReplace(error.reference)}
                      variant="secondary"
                      size="xs"
                    >
                      Replace
                    </Button>
                  )}
                </FormulaErrors.Card>
              ))}
            </FormulaErrors.CardList>
          </>
        )
      );
    }, [formula.expression, initialFormula]);

    return (
      <div
        className={cn(
          'grid grow grid-cols-[372px,minmax(0,auto)] gap-tw-4 rounded-2xl rounded-tl-none bg-neutral-000 p-tw-2',
          !visible && 'hidden',
        )}
      >
        <FormProvider {...methods}>
          <div className="flex flex-col gap-tw-2 p-tw-4">
            <div className="flex flex-col gap-tw-4 pb-tw-4">
              <div className="flex flex-col gap-tw-2">
                <FormulaReferenceLabel active reference={formula.reference} />
                <span className="header5-bold text-neutral-800">
                  {formula.label}
                </span>
                {initialFormula != null &&
                  initialFormula?.referencedInEntities?.length > 0 && (
                    <div className="flex items-center gap-tw-1">
                      <FormulaInUse.UsedSvg
                        used={initialFormula.referencedInEntities.length > 0}
                      />
                      <span className="secondary-semibold text-neutral-550">
                        In Use:{' '}
                        <FormulaInUse.Popover
                          referencedInEntities={
                            initialFormula.referencedInEntities
                          }
                        >
                          <PseudoLink className="secondary-semibold text-neutral-800 ">
                            {initialFormula.referencedInEntities.length} places
                          </PseudoLink>
                        </FormulaInUse.Popover>
                      </span>
                    </div>
                  )}
              </div>
              <div className="flex gap-tw-2">
                <Button
                  disabled={isCloneButtonDisabled}
                  onClick={handleClone}
                  size="xs"
                  variant="secondary"
                >
                  Clone
                </Button>
                <Button
                  disabled={isDeleteButtonDisabled}
                  onClick={() =>
                    deleteFormula({
                      id: formula.id!,
                    })
                  }
                  size="xs"
                  variant="danger"
                >
                  Remove
                </Button>
              </div>
            </div>
            <hr className="m-0" />
            <div className="flex flex-col gap-tw-4">
              <span className="inline-semibold text-neutral-800">Details</span>
              <FieldsContainer>
                <FormulaLabelField disabled={!canUpdate} control={control} />
                <FormulaReferenceField
                  disabled={!canUpdate}
                  control={control}
                />
                <EntityTagField
                  entity={TAG_ENTITIES.FORMULA}
                  name="tags"
                  disabled={!canUpdate}
                  control={control}
                  isNewEntity={isNew}
                />
                <FormulaDescriptionField
                  disabled={!canUpdate}
                  control={control}
                />
              </FieldsContainer>
            </div>
          </div>
        </FormProvider>
        <div className="flex flex-col gap-tw-4">
          <div className="grid grid-cols-[auto,min-content] gap-tw-4">
            <Errors />
            <Button
              style={{
                gridColumn: 2,
                gridRow: 1,
              }}
              className="justify-self-end whitespace-nowrap"
              onClick={onSubmit}
              disabled={isUpdateButtonDisabled}
              variant="success"
            >
              {formula.id == null ? 'Create Formula' : 'Save Updates'}
            </Button>
          </div>
          {initialFormulaExists &&
            isSystemFormulaAndNotUniform(initialFormula) && (
              <span className="label-regular text-danger-055">
                * System formulas cannot be updated on a client portal.
              </span>
            )}
          <FormulaExpressionField
            disabled={!canUpdate}
            errors={initialFormula?.invalidReferences}
            control={control}
          />
        </div>
      </div>
    );
  },
);

import { PermissionsSideTab } from '@/bundles/Shared/components/Permissions/EditPermissionsModal/components/PermissionsSideTab';
import { SideTabKey } from '@/bundles/Shared/components/Permissions/EditPermissionsModal/types';
import {
  concatUndeselectableRoleOptions,
  filterRoleByUndeselectable,
} from '@/bundles/Shared/components/Permissions/helpers';
import { cn } from '@/shared/lib/css/cn';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  Permitted,
  UserForPermitted,
} from 'bundles/Shared/entities/permissions';
import { useLazyGetPermissionModalSubjectablesQuery } from 'bundles/Shared/shared/api/pemissionModalApi';
import { concat, isEqual, omit, uniqBy } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import pluralize from 'pluralize';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  AnimationLoader,
  Button,
  LinkButton,
  Modal,
  ThinTabGroup,
} from 'stories';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { IUserTag } from 'types/IUserTag';
import { IUser, IUserRole } from 'types/User';
import {
  InvestmentEntitiesList,
  RolesList,
  TagsList,
  UsersList,
} from '../ModalWithSideTabs/Lists';
import { EDIT_PERMISSIONS_MODAL_TAB_ITEMS } from './helpers/constants';
import { ProceedWithCautionJSX } from './helpers/dumbJSX';

interface Props extends DialogProps<Permitted & { allUsers: IUser[] }> {
  initialState: Permitted & { public: boolean };
  title?: string;
  productName?: string;
  investmentObject?: {
    type: string;
    entity: {
      id: number;
    };
  };
  customHeader?: ReactNode;
  objectableId?: number;
  objectableType?: string;
  tabsIsHidden?: boolean;
  isEmail?: boolean;
  whiteListedTabs?: ('tags' | 'users' | 'roles')[];
  itemType?: string;
}

export const PermissionListModal = ({
  onClose,
  onSubmit,
  initialState,
  title = 'Configure permissions',
  productName,
  investmentObject,
  customHeader,
  objectableId,
  objectableType,
  tabsIsHidden,
  whiteListedTabs,
  isEmail = false,
  itemType = 'item',
}: Props) => {
  const [sideTab, setSideTab] = useState<SideTabKey>('directRoles');
  const [permittedState, setPermittedState] = useState(initialState);
  const [userOptions, setUserOptions] = useState<IUser[]>([]);
  const [tagOptions, setTagOptions] = useState<IUserTag[]>([]);
  const [roleOptions, setRoleOptions] = useState<IUserRole[]>([]);
  const [investementEntityOptions, setInvestmentEntityOptions] = useState<
    IInvestmentEntity[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [fetchPermissionModalSubjectables] =
    useLazyGetPermissionModalSubjectablesQuery();
  const mapTabsWithLists = () => {
    const filteredSideTabs: {
      directRoles?: 'Roles';
      directTags?: 'Tags';
      directUsers?: 'Members';
      directInvestmentEntities?: 'Entities';
    } = {};
    if (roleOptions.length > 0) filteredSideTabs.directRoles = 'Roles';
    if (tagOptions.length > 0) filteredSideTabs.directTags = 'Tags';
    if (userOptions.length > 0) filteredSideTabs.directUsers = 'Members';
    if (investementEntityOptions.length > 0)
      filteredSideTabs.directInvestmentEntities = 'Entities';
    return filteredSideTabs;
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPermissionModalSubjectables({
      only_product: productName,
      asset_id:
        investmentObject?.type === 'Asset'
          ? investmentObject?.entity?.id
          : undefined,
      fund_id:
        investmentObject?.type === 'Fund'
          ? investmentObject?.entity?.id
          : undefined,
      objectable_id: objectableId,
      objectable_type: objectableType,
    })
      .then(({ data: result }) => {
        const wlTabsPresent = whiteListedTabs && whiteListedTabs.length > 0;
        if (!wlTabsPresent || whiteListedTabs.includes('roles'))
          setRoleOptions([...result!.userRoles] as IUserRole[]);
        if (!wlTabsPresent || whiteListedTabs.includes('users'))
          setUserOptions(result!.users as IUser[]);
        if (!wlTabsPresent || whiteListedTabs.includes('tags'))
          setTagOptions(result!.userTags as IUserTag[]);
        if (!wlTabsPresent || whiteListedTabs.includes('entities'))
          setInvestmentEntityOptions(
            result!.investmentEntities as IInvestmentEntity[],
          );

        setPermittedState((prev) => ({
          ...prev,
          directRoles: concatUndeselectableRoleOptions(
            result?.userRoles ?? [],
            prev.directRoles,
          ),
        }));
      })
      .finally(() => setIsLoading(false));
  }, []);

  const isPublicTab = permittedState.public;
  const unselectableRoleOptions = roleOptions.filter(
    filterRoleByUndeselectable,
  );

  const usersList = isPublicTab
    ? userOptions
    : userOptions.filter((u) =>
        permittedState.directUsers.some(({ id }) => id === u.id),
      );

  const rolesList = isPublicTab
    ? roleOptions
    : uniqBy(
        concat(
          roleOptions.filter(
            (r) => permittedState.directRoles?.some(({ id }) => id === r.id),
          ),
          unselectableRoleOptions,
        ),
        'id',
      );

  const tagsList = isPublicTab
    ? tagOptions
    : tagOptions.filter(
        (t) => permittedState.directTags?.some(({ id }) => id === t.id),
      );

  const investmentEntitiesList = isPublicTab
    ? investementEntityOptions
    : investementEntityOptions.filter(
        (t) =>
          permittedState.directInvestmentEntities?.some(
            ({ id }) => id === t.id,
          ),
      );

  const getAutoSelectedUsersList = (currentState: typeof permittedState) =>
    userOptions.filter((u) => {
      const includeRole = currentState.directRoles?.some(
        ({ id }) => id === u.role?.id,
      );
      if (includeRole) return true;

      const includeTag = currentState.directTags?.some(({ id }) =>
        u.tags.map((tag) => tag.id).includes(id),
      );

      if (includeTag) return true;

      const includeInvEntity = currentState.directInvestmentEntities?.some(
        ({ id }) =>
          u.investmentEntities?.map((entity) => entity.id).includes(id),
      );

      return includeInvEntity;
    });

  const autoSelectedUsersFromRolesList =
    getAutoSelectedUsersList(permittedState);

  type PermittedArrayOnly = Omit<
    typeof permittedState,
    'public' | 'allUsers' | 'isPublic'
  >;
  type PermittedArrayOnlyValue = PermittedArrayOnly[keyof PermittedArrayOnly];

  const update = (
    key: keyof Omit<typeof permittedState, 'public' | 'allUsers'>,
    newValue: PermittedArrayOnlyValue | PermittedArrayOnlyValue[number],
  ) => {
    const currentValue = permittedState[key] ?? [];
    let newValues: PermittedArrayOnlyValue | PermittedArrayOnlyValue[number];
    if (Array.isArray(newValue)) {
      newValues = newValue;
    } else {
      newValues = (currentValue as PermittedArrayOnlyValue)
        .map(({ id }) => id)
        .includes(newValue.id)
        ? ((currentValue as PermittedArrayOnlyValue).filter(
            // @ts-ignore
            (v) => v?.id !== newValue.id,
          ) as PermittedArrayOnlyValue)
        : ([
            ...(currentValue as PermittedArrayOnlyValue),
            newValue,
          ] as PermittedArrayOnlyValue);
    }

    setPermittedState((prevState) => ({
      ...prevState,
      [key]: newValues,
    }));
  };

  const isEmpty = Object.values(
    omit(permittedState, 'public', 'allUsers'),
  ).every((arr) => (arr as unknown[]).length === 0);

  const buttonTitle = useMemo(() => {
    if (isEmail) return 'Set Recipients';
    return isPublicTab ? 'Set Public Access' : 'Set Restricted Access';
  }, [isEmail, isPublicTab]);

  const handleSubmit = () => {
    const allUsers = uniqBy(
      [
        ...permittedState.directUsers,
        ...getAutoSelectedUsersList(permittedState),
      ],
      'id',
    );
    onSubmit?.({
      ...permittedState,
      allUsers: allUsers as IUser[],
    });
    onClose();
  };

  const countSideTabItems = (permittedKey: SideTabKey) => {
    if (permittedKey !== 'directUsers') {
      return permittedState[permittedKey]?.length ?? 0;
    }

    return uniqBy(
      [...permittedState.directUsers, ...autoSelectedUsersFromRolesList],
      'id',
    ).length;
  };

  const isNotChanged = () => isEqual(initialState, permittedState);

  return (
    <Modal
      toggle={onClose}
      header={customHeader ?? title}
      classes={{
        body: '!p-0 bg-light relative',
        footer: 'justify-end pt-tw-4 modal-footer_shadow',
      }}
      bodyPadding="0px"
      contentClassName="modal-content-h-700"
      actions={
        !isLoading && (
          <>
            <Button onClick={onClose} className="mr-s" variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={!isPublicTab && (isEmpty || isNotChanged())}
              variant="success"
            >
              {buttonTitle}
            </Button>
          </>
        )
      }
      scrollable={false}
      size={isPublicTab ? '700' : 'permission-lg'}
    >
      {isLoading && <AnimationLoader />}
      {!isLoading && (
        <div className="modal-with-side-tabs mx-0">
          {!tabsIsHidden && (
            <div
              className={cn(
                'flex items-center justify-center gap-m border bg-light-5 p-tw-3',
              )}
            >
              <span className="light-90 inline-regular">
                What rights do you want to set for these{' '}
                {pluralize(itemType, 2)}?
              </span>
              <ThinTabGroup
                onSelectedItemChange={(selected) =>
                  setPermittedState((prev) => ({
                    ...prev,
                    indirectUsers: [],
                    public: selected.id === 'public',
                  }))
                }
                selectedItem={EDIT_PERMISSIONS_MODAL_TAB_ITEMS.find(({ id }) =>
                  permittedState.public ? id === 'public' : id === 'restricted',
                )}
                items={EDIT_PERMISSIONS_MODAL_TAB_ITEMS}
              />
            </div>
          )}
          <div className="flex h-[500px]">
            {!isPublicTab && (
              <section className="border-right flex">
                <aside className="flex flex-col" style={{ width: '6.125rem' }}>
                  <ul>
                    {Object.entries(mapTabsWithLists()).map(
                      ([localTab, localTitle]) => (
                        <li
                          key={localTab}
                          className={cn({ active: localTab === sideTab })}
                        >
                          <LinkButton
                            onClick={() => setSideTab(localTab as SideTabKey)}
                            className="light-60 block"
                          >
                            <div className="mb-tw-2">
                              {countSideTabItems(localTab as SideTabKey)}
                            </div>
                            {localTitle}
                          </LinkButton>
                        </li>
                      ),
                    )}
                  </ul>
                </aside>
                <OverlayScrollbarsComponent
                  className="permissions-modal-body-part border-left w-full"
                  options={{
                    paddingAbsolute: true,
                  }}
                >
                  <PermissionsSideTab
                    computedIndirectUsers={autoSelectedUsersFromRolesList}
                    metaState={{
                      initialState: permittedState,
                      investementEntityOptions,
                      roleOptions,
                      tagOptions,
                      userOptions,
                    }}
                    permittedState={permittedState}
                    handleUpdate={update}
                    currentSideTab={sideTab}
                  />
                </OverlayScrollbarsComponent>
              </section>
            )}
            <div className="mnw-0 w-full">
              <OverlayScrollbarsComponent
                className={cn(
                  'permissions-modal-body-part flex h-full w-full flex-col gap-m p-tw-4',
                  { 'pr-0': !isPublicTab && isEmpty },
                )}
              >
                {!isPublicTab && (
                  <>
                    <h5 className="header5-regular mb-m">
                      {isEmail ? 'Email to' : 'Share with'}
                    </h5>
                    {isEmpty ? (
                      <p className="white-space-nw">
                        You haven&apos;t chosen anyone yet
                      </p>
                    ) : (
                      <div className="flex flex-col gap-m">
                        {(permittedState.directRoles?.length ||
                          isPublicTab) && (
                          <RolesList
                            items={rolesList}
                            closable={!isPublicTab}
                            onClose={(id) => update('directRoles', id)}
                          />
                        )}
                        {(permittedState.directTags?.length || isPublicTab) && (
                          <TagsList
                            items={tagsList}
                            closable={!isPublicTab}
                            onClose={(id) => update('directTags', id)}
                          />
                        )}
                        {(permittedState.directInvestmentEntities?.length ||
                          isPublicTab) && (
                          <InvestmentEntitiesList
                            items={investmentEntitiesList}
                            closable={!isPublicTab}
                            onClose={(id) =>
                              update('directInvestmentEntities', id)
                            }
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
                {isPublicTab && <ProceedWithCautionJSX />}
                {(isPublicTab || !isEmpty) &&
                  (usersList.length ||
                    autoSelectedUsersFromRolesList.length ||
                    isPublicTab) && (
                    <UsersList
                      members={usersList}
                      autoSelectedMembers={autoSelectedUsersFromRolesList}
                      closable={!isPublicTab}
                      onClose={(user) =>
                        update('directUsers', user as UserForPermitted)
                      }
                      displayTagsColumn={!isPublicTab}
                    />
                  )}
              </OverlayScrollbarsComponent>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

import { ICellRendererParams } from 'ag-grid-community';
import { CellRendererClasses } from 'bundles/Shared/components/AgGrid/Table/types';
import { ComponentProps } from 'react';
import { CurrencyFormatter } from 'stories/index';
import {
  BasicCellRenderer,
  CellRendererStylesProps,
} from './BasicCellRenderer';

export interface CurrencyCellRendererProps extends CellRendererClasses {
  formatterParams?: Partial<ComponentProps<typeof CurrencyFormatter>>;
}

type Props = ICellRendererParams &
  CurrencyCellRendererProps &
  CellRendererStylesProps;

export function CurrencyCellRenderer({
  classes,
  formatterParams,
  ...params
}: Props) {
  const value = params.value as ComponentProps<
    typeof CurrencyFormatter
  >['value'];

  return (
    <BasicCellRenderer classes={classes} {...params}>
      <CurrencyFormatter
        value={value}
        negativeGray={false}
        fallbackValue="-"
        toLocalStringOptions={{ minimumFractionDigits: 2 }}
        {...formatterParams}
      />
    </BasicCellRenderer>
  );
}

import {
  OBJECT_DASHBOARD_SECTION_TYPE,
  ObjectDashboardSectionTypes,
} from 'bundles/Shared/entities/dashboard/model';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import Field from 'stories/Field/Field';
import Input from 'stories/FormControls/Inputs/Input/Input';

export function UpdateWidgetTitle({ labelText }: { labelText?: string }) {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD>();
  const [title, setTitle] = useState(widget.title);

  const [updateConfig] = useUpdateWidgetConfig(
    widget.widgetType as ObjectDashboardSectionTypes,
  );

  useEffect(() => {
    setTitle(widget.title);
  }, [widget.title]);

  const handleOnBlur = useCallback(
    debounce((e) => {
      updateConfig({
        title: e.target.value,
        config: widget.widgetConfig,
      });
    }, 200),
    [widget],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    },
    [],
  );

  return (
    <Field labelText={labelText ?? 'Title'} required>
      <Input value={title} onBlur={handleOnBlur} onChange={handleOnChange} />
    </Field>
  );
}

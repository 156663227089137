import React from 'react';
import { Modal } from 'stories';
import FeedMessageForm from './FeedMessageForm';

const FeedMessageFormModal = ({
  onClose, updateFeedMessagesList, object, setCurrentFeedMessage, feedMessage,
}) => (
  <Modal
    toggle={onClose}
    size="lg"
    header={(
      <div>
        <div className="light-60 label-regular">
          {object.name}
        </div>
        <h6 className="header6-bold dark-60">
          New Feed Message
        </h6>
      </div>
    )}
  >
    <FeedMessageForm
      onClose={onClose}
      updateFeedMessagesList={updateFeedMessagesList}
      feedMessage={feedMessage}
      setCurrentFeedMessage={setCurrentFeedMessage}
    />
  </Modal>
);

export default FeedMessageFormModal;

import {
  GetApiReconcileUnderwritingBudgetsLegalEntitiesApiResponse,
  LegalEntityUnderwritingBudget,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { PropsWithChildren, createContext, useContext } from 'react';

type BudgetContext = {
  items: GetApiReconcileUnderwritingBudgetsLegalEntitiesApiResponse['items'];
  selectedLegalEntity: LegalEntityUnderwritingBudget | null;
};

const BudgetContext = createContext<BudgetContext>({
  items: [],
  selectedLegalEntity: null,
} satisfies BudgetContext);

export const BudgetContextProvider = ({
  children,
  ctxParams,
}: { ctxParams: BudgetContext } & PropsWithChildren) => {
  return (
    <BudgetContext.Provider value={ctxParams}>
      {children}
    </BudgetContext.Provider>
  );
};

export const useBudgetContext = () => {
  const context = useContext(BudgetContext);

  if (context == null) {
    throw new Error('useCrudContext was used outside of its Provider');
  }

  return context;
};

import React, { FC, ReactNode } from 'react';
import { IconsId } from 'types/sre-icons';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';
import AvailableActionsItem from './AvailableActionsItem';
import { Icon, ConfirmationModal } from 'stories';
import { useModal } from '@/shared/lib/hooks/useModal';

export interface IReconcileAction {
  title: string;
  description: string;
  iconName: IconsId;
  show: boolean;
  content: ReactNode;
}

export interface IRemoveFlow {
  modal: {
    title: string;
    subtitle: string;
  };
  handler: (status: boolean, item: object) => void;
}

interface IAvailableActionsProps {
  actionsData: IReconcileAction[];
  removeFlow?: IRemoveFlow;
  revertFlow?: IRemoveFlow;
}

const AvailableActions: FC<IAvailableActionsProps> = ({
  actionsData,
  removeFlow,
  revertFlow,
}) => {
  const activeAction = useAppSelector(selectReconcileActiveAction);
  const actionsWithoutActive = actionsData.filter(
    (action) => action.title !== activeAction,
  );

  const { openModal } = useModal();

  const openRemoveModal = (item: object) => {
    if (removeFlow) {
      openModal(ConfirmationModal, {
        title: removeFlow.modal.title,
        subtitle: removeFlow.modal.subtitle,
        handleResolve: (status) => removeFlow.handler(status, item),
      });
    }
  };

  const openRevertModal = (item: object) => {
    if (revertFlow) {
      openModal(ConfirmationModal, {
        title: revertFlow.modal.title,
        subtitle: revertFlow.modal.subtitle,
        actions: revertFlow.modal.actions,
        handleResolve: (status) => revertFlow.handler(status, item),
      });
    }
  };

  return (
    <>
      {!activeAction && (
        <div className="inline-semibold mb-s">Available Actions</div>
      )}
      <div className="change-event-scroll change-event-scroll_actions">
        {activeAction && (
          <div>
            {actionsData
              .filter((action) => action.title === activeAction)
              .map((item, idx) => (
                <AvailableActionsItem key={`action${idx}`} {...item} />
              ))}
          </div>
        )}
        {!activeAction ? (
          <div>
            {actionsData.map((item, idx) => (
              <AvailableActionsItem key={`action${idx}`} {...item} />
            ))}
          </div>
        ) : (
          activeAction &&
          Boolean(actionsWithoutActive.length) && (
            <div>
              <div className="inline-semibold mb-s">Other Actions</div>
              {actionsWithoutActive.map((item, idx) => (
                <AvailableActionsItem key={`action${idx}`} {...item} />
              ))}
            </div>
          )
        )}
        {removeFlow && (
          <div
            className="inline-semibold mt-s flex cursor-pointer  items-center gap-tw-2 text-neutral-550"
            onClick={openRemoveModal}
          >
            <Icon
              className="bg-neutral-250 p-tw-1 text-neutral-500"
              iconName="trash"
            />
            Revert
          </div>
        )}
        {revertFlow && (
          <div
            className="inline-semibold mt-tw-2 flex cursor-pointer  items-center gap-tw-2 text-neutral-550"
            onClick={openRevertModal}
          >
            <Icon
              className="bg-neutral-250 p-tw-1 text-neutral-500"
              iconName="reset"
            />
            Revert
          </div>
        )}
      </div>
    </>
  );
};

export default AvailableActions;

import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { useMemo } from 'react';

/**
 * Returns the elements of an array that meet the condition specified in a callback function.
 * @param array An array from which an array of options should be created.
 * @param keyForLabel a key of array item's field to use as a label of option.
 * @param selectedArray An array of currently selected options.
 * @param selectedIdKey a key of selectedArray item's field to identify selected options in array
 */

export function useRuleOptions<T extends { id: string | number }, K>(
  array: T[],
  keyForLabel: keyof T,
  selectedArray: K[],
  selectedIdKey: keyof K,
) {
  const result = useMemo(() => {
    const selectedIds = selectedArray.map((item) => item[selectedIdKey]);

    const notSelectedItems = array.filter(
      (item) => !selectedIds.includes(item.id),
    );

    const allOptions = mapItemsToListOption(array, keyForLabel);

    const notSelectedOptions = mapItemsToListOption(
      notSelectedItems,
      keyForLabel,
    );

    return [allOptions, notSelectedOptions];
  }, [array, selectedArray]);

  return result;
}

import { ERPSystem } from 'bundles/Shared/entities/erpsystem/model';
import { ERPSystemIcon } from 'bundles/Shared/entities/erpsystem/ui/svgSprite';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';

interface IERPSystem {
  system: ERPSystem;
  typeThemeColor?: 'mono' | 'colorful';
}

export const getNameERPSystem = (system: ERPSystem): string =>
  SOURCE_TYPE_SETTINGS[system]?.title;

export const ERPSystemLabel = ({
  system,
  typeThemeColor = 'colorful',
}: IERPSystem) => (
  <div className="flex items-center gap-tw-1">
    <ERPSystemIcon system={system} typeThemeColor={typeThemeColor} />
    <div className="inline-regular text-neutral-900">
      {getNameERPSystem(system)}
    </div>
  </div>
);

import React from 'react';
import { CurrencyFormatter, Icon } from 'stories/index';

export function Total({ amount }: { amount: number }) {
  return (
    <div className="grid grid-cols-[100px_56px_100px] items-center gap-x-tw-6 gap-y-tw-0.5 pr-tw-6">
      <span className="inline-regular mb-tw-1.5 text-right leading-5 text-dark-60">
        <CurrencyFormatter
          classes={{
            wrapper: 'justify-end',
            value: 'font-semibold text-dark-60',
          }}
          value={amount}
        />
      </span>

      <div className="my-tw-0.5 flex items-center justify-center gap-tw-1 self-start">
        <Icon
          iconName="equalValided"
          className="leading-none text-green-dark-2"
        />
      </div>

      <span className="inline-regular mb-tw-1.5 leading-5 text-dark-60">
        <CurrencyFormatter
          classes={{ value: 'font-semibold text-dark-60' }}
          value={amount}
        />
      </span>

      <span className="secondary-regular col-span-3 text-center text-green">
        The values match
      </span>
    </div>
  );
}

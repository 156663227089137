import { ReportLineItem } from 'bundles/REturn/actions/types';
import { CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP } from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/consts';
import ReviewAction from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/ReviewAction';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { capitalize, sumBy } from 'lodash-es';
import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props {
  entryType: 'contribution' | 'distribution';
  capitalInvestments: ICapitalInvestment[];
  transactions: ReportLineItem[];
}
export function ReviewTotal({
  entryType,
  capitalInvestments,
  transactions,
}: Props) {
  const allEntriesTotalAmountKeyName =
    CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP[entryType];

  const totalTransactionsAmount =
    transactions.length > 0
      ? sumBy(transactions, (tx) =>
          convertCentsToDollars(Number(tx.amountCents)),
        )
      : 0;

  const allEntriesTotalAmount =
    capitalInvestments.length > 0
      ? sumBy(capitalInvestments, (ci) =>
          Number(ci[allEntriesTotalAmountKeyName]),
        )
      : 0;

  const allEntriesTotalAmountAfter =
    allEntriesTotalAmount + totalTransactionsAmount;

  return (
    <ReviewAction.Item title={capitalize(entryType)} variant="with-shadows">
      <ReviewAction.SubItem name="Total">
        <ReviewAction.AmountComparison
          past={allEntriesTotalAmount}
          present={allEntriesTotalAmountAfter}
        />
      </ReviewAction.SubItem>
    </ReviewAction.Item>
  );
}

import AttachedItemsPanel from '@/bundles/InvestmentObjects/components/Overview/Posts/AttachedItemsPanel';
import EmailCampaignModal from '@/bundles/InvestmentObjects/components/Overview/Posts/Post/EmailCampaignModal';
import PostContent from '@/bundles/InvestmentObjects/components/Overview/Posts/PostContent';
import PostHeader from '@/bundles/InvestmentObjects/components/Overview/Posts/PostHeader';
import BadgeSwitch from '@/bundles/Shared/components/BadgeSwitch';
import { useModal } from '@/shared/lib/hooks/useModal';
import { FeedMessage } from 'bundles/InvestmentObjects/components/Overview/Posts/api/feedMessagesApi';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { ViewPermissionedUsersModal } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import {
  FetchPermissionedUsersWithMetaResponse,
  LightUser,
} from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal/types';
import canManageObject from 'lib/InvestmentObject';
import http from 'lib/http';
import { useState } from 'react';

const Post = ({
  feedMessage,
  updateFeedMessagesList,
  deleteFeedMessageFromList,
  setCurrentFeedMessage,
  setSendEmailModalOpened,
  expanded,
}: {
  feedMessage: FeedMessage;
  updateFeedMessagesList?: (feedMessage: FeedMessage) => void;
  deleteFeedMessageFromList?: (feedMessage: FeedMessage) => void;
  setCurrentFeedMessage?: (feedMessage: FeedMessage) => void;
  setSendEmailModalOpened?: (opened: boolean) => void;
  expanded?: boolean;
}) => {
  const [emailCampaignModalOpened, setEmailCampaignModalOpened] =
    useState(false);
  const investmentEntity = feedMessage.feedMessageable;
  const { openModal } = useModal();
  const canManage =
    canManageObject(investmentEntity) &&
    updateFeedMessagesList &&
    deleteFeedMessageFromList;

  const handleViewPermissions = async () => {
    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: async () => {
        const res = await http.get(
          `/feed_messages/${feedMessage.id}/permissions`,
        );
        const data = await res.json();

        return data as FetchPermissionedUsersWithMetaResponse<LightUser>;
      },
    });
  };

  return (
    <div className="radius016 flex w-full flex-wrap p-tw-6 pb-tw-4">
      <div className="flex w-full flex-col gap-tw-4">
        <PostHeader
          updateFeedMessagesList={updateFeedMessagesList}
          deleteFeedMessageFromList={deleteFeedMessageFromList}
          setCurrentFeedMessage={setCurrentFeedMessage}
          feedMessage={feedMessage}
          setSendEmailModalOpened={setSendEmailModalOpened}
        />
        <PostContent text={feedMessage.text} expanded={expanded} />
        {(feedMessage.media.length > 0 || feedMessage.documents.length > 0) && (
          <AttachedItemsPanel
            media={feedMessage.media}
            documents={feedMessage.documents}
            expanded={expanded}
          />
        )}
        <div className="flex items-center gap-tw-4">
          <div className="flex items-center gap-tw-2">
            <p className="label-regular text-neutral-400">Topic:</p>
            <BadgeSwitch
              color={feedMessage.feedType.color}
              label={feedMessage.feedType.title}
            />
          </div>

          {canManage && (
            <div className="flex items-center gap-tw-2">
              <p className="label-regular text-neutral-400">Who can see:</p>
              <PermissionListLine
                hidePublicIcon
                onClick={handleViewPermissions}
                permissions={{
                  directInvestmentEntities:
                    feedMessage.permitted?.investmentEntities ?? [],
                  directRoles: feedMessage.permitted?.roles ?? [],
                  directTags: feedMessage.permitted?.tags ?? [],
                  directUsers: feedMessage.permitted?.users ?? [],
                  indirectUsers: feedMessage.permitted?.autoSelectedUsers ?? [],
                  isPublic: feedMessage.permitted?.public ?? false,
                }}
              />
            </div>
          )}
        </div>
      </div>
      {emailCampaignModalOpened && (
        <EmailCampaignModal
          feedMessageId={feedMessage.id}
          headerSubtitle={investmentEntity.name}
          onClose={() => setEmailCampaignModalOpened(false)}
        />
      )}
    </div>
  );
};

export default Post;

import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalHeaderWithSubtitle } from 'stories';
import { IReportLineItemOnIndex } from 'types/ReportLineItem';
import { LegalEntity } from '@/entities/core/legalEntity';
import { IReportTableConfig } from 'types/ReportTableConfig';
import usePagination from '@/shared/lib/hooks/usePagination';
import { IGeneralLedgerOnTransactionsIndex } from 'types/GeneralLedger';
import TransactionsTable from './TransactionsTable';
import LegalEntitiesFilter from '../filter/LegalEntitiesFilter';
import TablePagination from '../../../../Shared/components/Table/pagination/TablePagination';
import {
  fetchReportLineItems,
  IFetchArgs,
} from '../../../../Shared/actions/report_line_items';
import { TSettings } from './transactionTableColumns';
import { TSort } from 'types/Sort';

interface Props {
  generalLedger: Pick<
    IGeneralLedgerOnTransactionsIndex,
    'id' | 'accTitle' | 'accNumber'
  >;
  onClose: () => void;
  legalEntities: LegalEntity[];
  showUnpublished?: boolean;
  periods?: string[];
  reportTableConfigId?: IReportTableConfig['id'];
}

function TransactionDetailsModal({
  legalEntities,
  generalLedger,
  onClose,
  showUnpublished,
  periods,
  reportTableConfigId,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<IReportLineItemOnIndex[]>(
    [],
  );
  const [totalSize, setTotalSize] = useState(0);
  const [selectedLegalEntities, setSelectedLegalEntities] =
    useState<LegalEntity[]>(legalEntities);
  const [settings, setSettings] = useState<TSettings>({
    sortOrder: TSort.DESC,
    sortField: 'date',
  });
  const { setPagination, pagination } = usePagination({
    currentPage: 1,
    sizePerPage: 10,
  });

  const fetchParams = useMemo<IFetchArgs>(
    () => ({
      general_ledger_id: [generalLedger.id],
      page: pagination.currentPage,
      per_page: pagination.sizePerPage,
      legal_entity_id: selectedLegalEntities.map((le) => le.id),
      transaction_type: settings.transaction_type,
      only_published: !showUnpublished,
      periods,
      report_table_config_id: reportTableConfigId,
      sort: {
        field: settings.sortField,
        order: settings.sortOrder,
      },
      sort_order: settings.sortOrder,
    }),
    [
      generalLedger,
      pagination,
      settings,
      selectedLegalEntities,
      periods,
      reportTableConfigId,
    ],
  );

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);

      const res = await fetchReportLineItems(fetchParams);
      setTransactions(res.items);
      setTotalSize(res.meta.totalSize);
      setIsLoading(false);
    };

    fetchTransactions();
  }, [fetchParams]);

  return (
    <Modal
      header={
        <ModalHeaderWithSubtitle
          order="title-subtitle"
          title={generalLedger.accTitle}
          subtitle={generalLedger.accNumber}
        />
      }
      size="huge"
      toggle={onClose}
      backdrop={false}
      classes={{
        body: 'bg-light-10',
      }}
    >
      <div className="flex flex-col gap-m">
        <div className="inline-flex">
          <LegalEntitiesFilter
            selectedLegalEntities={selectedLegalEntities}
            onSelectedChange={setSelectedLegalEntities}
            legalEntities={legalEntities}
          />
        </div>

        <div>
          <h6 className="header-6 mb-s">Transactions</h6>
          <TablePagination
            loading={isLoading}
            setCurrentPage={(page, size) =>
              setPagination({
                currentPage: page,
                sizePerPage: size ?? pagination.sizePerPage,
              })
            }
            onSizePerPageChange={(size, page) =>
              setPagination({
                currentPage: page,
                sizePerPage: size,
              })
            }
            currentPage={pagination.currentPage}
            totalSize={totalSize}
            sizePerPage={pagination.sizePerPage}
          />
          <div className="mt-m">
            <TransactionsTable
              isLoading={isLoading}
              setSettings={setSettings}
              settings={settings}
              transactions={transactions}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TransactionDetailsModal;

import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { useState } from 'react';
import { ThinTabGroupWithAmount } from 'stories/index';
import { IPaginationParams, IQueryParams } from 'types/Pagination';
import { createBudgetTabs } from '../utils';
import { ISortParamsField } from 'types/Sort';
import {
  BudgetLegalEntity,
  BudgetLegalEntityState,
  BudgetYear,
  OperationalBudgetsYearsResponse,
  SortField,
} from '../types';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import Table from 'bundles/Shared/components/Table/Table';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import { RECONCILE_OPERATIONAL_TABLE_SKELETON_JSX } from '../dumbJSX';
import { useColumnDefs } from './hooks/useColumnDefs';
import { IAsset } from 'types/Asset';
import { useGetApiReconcileOperationalBudgetsYearsByYearLegalEntitiesQuery } from '@/bundles/REconcile/components/operational/api/reconcileOperationalEnhancedApi';

type PageParams = IQueryParams &
  ISortParamsField<SortField> &
  IPaginationParams & {
    totalSize: number;
  } & {
    assetIds: IAsset['id'][];
    legalEntityIds: BudgetLegalEntity['id'][];
  };

export function Content({
  currentBudget,
  ...meta
}: { currentBudget: BudgetYear } & OperationalBudgetsYearsResponse['meta']) {
  const [selectedTab, setSelectedTab] = useState(
    createBudgetTabs(currentBudget)[0],
  );

  const [pageParams, setPageParams] = useState<PageParams>({
    query: '',
    page: 1,
    perPage: 10,
    totalSize: 0,
    sort: {
      field: 'asset',
      order: 'asc',
    },
    assetIds: [],
    legalEntityIds: [],
  });
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );
  const { data, isLoading, isFetching } = useGetApiReconcileOperationalBudgetsYearsByYearLegalEntitiesQuery({
    year: currentBudget.year,
    query: pageParams.query,
    page: pageParams.page,
    perPage: pageParams.perPage,
    sort: pageParams.sort,
    assetIds: pageParams.assetIds,
    legalEntityIds: pageParams.legalEntityIds,
    state:
      selectedTab.id === 'all'
        ? undefined
        : (selectedTab.id as BudgetLegalEntityState),
  })
  const legalEntities = data?.items ?? [];
  const totalSize = data?.meta.totalSize ?? 0;

  const columnDefs = useColumnDefs({
    currentBudget,
    ...meta,
  });

  if (isLoading) return RECONCILE_OPERATIONAL_TABLE_SKELETON_JSX;

  return (
    <>
      <ListNavLayout.ContentHeader>
        <p className="header5-bold text-dark-60">{currentBudget.year} year</p>
      </ListNavLayout.ContentHeader>
      <ThinTabGroupWithAmount
        className="w-max"
        selectedItem={selectedTab}
        items={createBudgetTabs(currentBudget)}
        onSelectedItemChange={(tab) => {
          setPageParams((prev) => ({ ...prev, page: 1 }));
          setSelectedTab(tab);
        }}
      />
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div className="flex items-center justify-between gap-tw-2">
          <PageParamsPagination
            loading={isFetching}
            sizePerPage={pageParams.perPage ?? 10}
            totalSize={totalSize}
            onPaginationChange={({ currentPage, sizePerPage }) => {
              setPageParams((prev) => ({
                ...prev,
                page: currentPage,
                per_page: sizePerPage,
              }));
            }}
            onSizePerPageChange={(sizePerPage, page) => {
              setPageParams((prev) => ({
                ...prev,
                perPage: sizePerPage,
                page,
              }));
            }}
          />
          <PageParamsSearch
            className="ml-auto"
            suggestions={['Legal Entity']}
          />
        </div>
        <Table<BudgetLegalEntity>
          items={legalEntities}
          loading={isFetching}
          settings={settings}
          getRowId={(item) => `${item.asset.id}_${item.id}_${item.budgetId}}`}
          setSettings={setSettings}
          columns={columnDefs}
          filterModel={{
            assetIds: pageParams.assetIds,
            legalEntityIds: pageParams.legalEntityIds,
          }}
          onFilterModelChange={(model) => {
            setPageParams((prev) => ({
              ...prev,
              page: 1,
              assetIds: model.assetIds ?? [],
              legalEntityIds: model.legalEntityIds ?? [],
            }));
          }}
        />
      </PageParamsProvider>
    </>
  );
}

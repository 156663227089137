import {
  Logo,
  SymmetreLogoIcon,
} from 'bundles/Shared/components/LeftSidebar/Logo/Logo';
import React from 'react';

const AuthContainer = ({ children }: React.PropsWithChildren) => {
  const copyRight = `© Copyright ${new Date().getFullYear()} symmetRE, Inc.`;

  return (
    <div className="auth">
      <div className="auth__container">
        <div className="row w-full">
          <div className="col-sm-12 col-lg-6">
            <div className="auth__form">
              <Logo
                className="h-[3.5rem] max-w-full object-contain"
                variant="large"
              />
              <div>{children}</div>
              <div className="auth__copyright">
                <div className="d-lg-block hidden">{copyRight}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 auth__bg">
            <div className="auth__bg-gradient" />
            <div className="auth__bg-content">
              <SymmetreLogoIcon
                theme="grayscale"
                className="m-[115px_auto_57px] block"
              />
              <h4 className="light text-center text-[1.5rem] font-medium">
                Synchronize your data, teams, and partners across the Real
                Estate life cycle
              </h4>
              <div className="flex items-center justify-center">
                <a
                  href="https://symmetre.com/"
                  target="_blank"
                  className="auth__bg-content-btn"
                  rel="noreferrer"
                >
                  About us
                </a>
              </div>
              <div
                className="d-lg-none w-fullvw light mt-auto block bg-dark-60 py-tw-2 text-center"
                style={{ marginBottom: '-2.5rem' }}
              >
                {copyRight}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;

import { cn } from '@/shared/lib/css/cn';
import * as React from 'react';
import { Button, IconButton } from 'stories';
import { DEFAULT_DROPDOWN_OFFSET } from '../../../../../../stories/Popover/Popover';
import styles from './FilterButton.module.scss';

interface Props extends React.ComponentProps<typeof Button> {
  onClose: () => void;
  filtered?: boolean;
  clicked?: boolean;
  classes?: {
    container?: string;
    containerClicked?: string;
    filtered?: string;
    clicked?: string;
    close?: string;
    disabled?: string;
  };
}

export const getPopoverOffset = (filtered: boolean) =>
  [filtered ? -30 : DEFAULT_DROPDOWN_OFFSET[0], DEFAULT_DROPDOWN_OFFSET[1]] as [
    number,
    number,
  ];

function FilterButton({
  onClose,
  children,
  className,
  classes,
  filtered,
  clicked,
  ...props
}: Props) {
  return (
    <div
      className={cn(
        styles.container,
        {
          [styles.container_filtered]: filtered,
        },
        classes?.containerClicked && {
          [classes.containerClicked]: clicked,
        },
        classes?.container,
      )}
    >
      <Button
        size="xs"
        {...props}
        className={cn(
          styles.button,
          classes?.filtered && {
            [classes.filtered]: filtered,
          },
          classes?.clicked && {
            [classes.clicked]: clicked,
          },
          classes?.disabled && {
            [classes.disabled]: props.disabled,
          },
          className,
        )}
      >
        {children}
      </Button>
      {!props.disabled && (
        <IconButton
          variant="default"
          iconName="closeSmall"
          className={cn(styles.close, classes?.close)}
          onClick={() => onClose()}
          offHoverStyles
        />
      )}
    </div>
  );
}

export default FilterButton;

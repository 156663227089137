import React from 'react';

export const ProceedWithCautionJSX = () => (
  <div className="rounded-[0.5rem] proceed-with-caution-alert bg-light text-center">
    <h5 className="header5-regular mb-m">Proceed with caution!</h5>
    <p className="proceed-with-caution-alert__body">
      Selecting <b className="font-weight-600">All Users</b> access will include
      <b className="font-weight-600">
        <u> ANY</u>
      </b>{' '}
      user with permission to manage and/or view, as detailed below:
    </p>
  </div>
);

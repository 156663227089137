import React from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Modal, Button } from 'stories';

export interface CrudModalProps extends DialogProps {
  type: 'edit' | 'create';
  entityName: string;
  valid?: boolean;
  header?: string;
}

export type CrudEntityModalProps<T> = Omit<CrudModalProps, 'entityName'> &
  DialogProps<T> & {
    entity: T;
  };

export function CrudModal({
  valid,
  type,
  entityName,
  children,
  onSubmit,
  onClose,
  header,
}: React.PropsWithChildren<CrudModalProps>) {
  return (
    <Modal
      toggle={onClose}
      header={header || `${type === 'edit' ? 'Edit' : 'New'} ${entityName}`}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-testid="crud-submit"
            variant="success"
            onClick={onSubmit}
            disabled={valid !== undefined && !valid}
          >
            {type === 'edit' ? 'Save Changes' : `Create ${entityName}`}
          </Button>
        </>
      }
    >
      {children}
    </Modal>
  );
}

export default CrudModal;

import { Monaco } from '@monaco-editor/react';
import {
  addPrefixToFormulaReference,
  findFormulaOrVariableByReference,
  FORMULA_REFERENCE_PREFIX,
  isFormulaByExpression,
  openFormula,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { useLayoutEffect } from 'react';
import { Uri } from 'monaco-editor';
import { clipboardWriteText } from '@/shared/lib/browser/clipboardApi';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { providerRegistry } from '@/bundles/Shared/entities/formula/lib/providerRegistry';

const FORMULA_COPY_PREFIX = '/formula-copy-';
const FORMULA_OPEN_PREFIX = '/formula-';

const buildLink = ({ href, label }: { href: string; label: string }) => {
  return `<a href="${href}">${label}</a>`;
};

export const useFormulaLinkHoverProviderEffect = (
  codeEditorRef: React.RefObject<Monaco>,
  language: string = 'json',
) => {
  const dispatch = useAppDispatch();
  const { formulas } = useReportFormulasQuery();

  useLayoutEffect(() => {
    if (providerRegistry.get({ language, providerType: 'hover' }) != null) {
      return;
    }
    const linkOpener = codeEditorRef.current?.editor.registerLinkOpener({
      open(resource: Uri): boolean | Promise<boolean> {
        if (resource.path.startsWith(FORMULA_COPY_PREFIX)) {
          const formulaReference = resource.path.replace(
            FORMULA_COPY_PREFIX,
            '',
          );
          clipboardWriteText(formulaReference);
          return true;
        }
        const formulaId = resource.path.replace(FORMULA_OPEN_PREFIX, '');
        const formula = formulas.find((f) => f.id === formulaId);
        if (formula == null) {
          toastr.error(
            `Formula ${formulaId} not found. Please check the reference`,
          );
          return false;
        }
        dispatch(openFormula(formula));
        return true;
      },
    });
    const hoverProviderHandle =
      codeEditorRef.current?.languages.registerHoverProvider(language, {
        provideHover: (model, position) => {
          const referenceWord = model.getWordAtPosition(position);
          if (referenceWord?.word == null) {
            return { contents: [] };
          }
          const lastColumnBeforeReferenceDotSeparator =
            referenceWord.startColumn - 1;

          const prefixWord = model.getWordUntilPosition({
            lineNumber: position.lineNumber,
            column: lastColumnBeforeReferenceDotSeparator,
          });

          const reference =
            prefixWord?.word === FORMULA_REFERENCE_PREFIX
              ? addPrefixToFormulaReference(referenceWord.word)
              : referenceWord.word;

          if (!isFormulaByExpression(reference)) {
            return { contents: [] };
          }

          const formula = findFormulaOrVariableByReference(formulas, reference);
          if (formula == null) {
            return { contents: [] };
          }
          return {
            contents: [
              {
                value: `${formula.reference} ${buildLink({
                  href: `${FORMULA_COPY_PREFIX}${formula.reference}`,
                  label: 'Copy',
                })}`,
                supportHtml: true,
                isTrusted: true,
              },
              {
                value: formula.label,
              },
              {
                value: formula.description ?? '',
              },
              {
                value: buildLink({
                  href: `${FORMULA_OPEN_PREFIX}${formula.id}`,
                  label: 'Open',
                }),
                supportHtml: true,
                isTrusted: true,
              },
            ],
          };
        },
      });
    if (hoverProviderHandle != null) {
      providerRegistry.set(
        {
          language,
          providerType: 'hover',
        },
        hoverProviderHandle,
      );
    }

    return () => {
      linkOpener?.dispose();
      hoverProviderHandle?.dispose();
      providerRegistry.delete({
        language,
        providerType: 'hover',
      });
    };
  }, [formulas, codeEditorRef.current]);
};

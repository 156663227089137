import { usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseMutation } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { Button } from 'stories';

interface Props {
  connectionId: IConnection['id'];
  manualAttachments?: boolean;
}

export function ParseStoredExtractionsButton({
  connectionId,
  manualAttachments,
}: Props) {
  const { confirm } = useModal();
  // eslint-disable-next-line max-len
  const [parse, { isLoading }] =
    usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseMutation();
  const handleParse = async () => {
    const res = await confirm({
      title: 'Parse Stored Extractions',
      subtitle: 'Are you sure you want to parse stored extractions?',
    });
    if (!res) {
      return;
    }
    parse({
      connectionId,
      body: {
        attachments_scope: 'not_parsed',
        manual_attachments: manualAttachments,
      },
    });
  };

  return (
    <Button
      size="s"
      iconPosition="right"
      disabled={isLoading}
      variant="secondary"
      iconName="longArrowDown"
      onClick={handleParse}
    >
      Parse Stored
    </Button>
  );
}

import { useParams } from '@reach/router';
import SubReallocationForm from 'bundles/Construction/components/Reallocation/Modals/components/SubReallocationForm';
import { useAmountAndJccIsValidInReallocation } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/utils';
import {
    ICreateReallocationFormData,
    TSubReallocationFormData,
} from 'bundles/Construction/components/Reallocation/Modals/types';
import { IHardCostCode, IReallocation } from 'bundles/Construction/types';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { formatAmount } from '@/shared/lib/formatting/number';
import { isEmpty, sumBy } from 'lodash-es';
import { FC, SetStateAction, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Icon,
    IconButton,
    InlineAlert,
    SmallAddingButton,
    StepsFormStepv2,
} from 'stories';
import { IJobCostCode } from 'types/JobCostCode';
import { getItemsSubReallocationModal } from '../initData';

interface ISubReallocationProps {
  formData: IReallocation;
  setFormData: SetStateAction<IReallocation>;
  hardCostContingency: IHardCostCode;
  JCCForReallocations: IReallocation[];
  JCCTree: IJobCostCode[];
}

export const SubReallocationNew = () => {
  const { legalEntityCode } = useParams();
  const { watch, setValue, trigger, getValues } =
    useFormContext<ICreateReallocationFormData>();
  const reallocationAmount = watch('value');
  const subReallocations = watch('subReallocations');
  const movedFromId = watch('moved_from_id');
  const subAmount = sumBy(subReallocations, 'value');
  const deltaSubAmount = Number(reallocationAmount ?? 0) - subAmount;
  const amountJccIsValid = useAmountAndJccIsValidInReallocation();

  const addReallocation = () => {
    setValue('subReallocations', [
      ...subReallocations,
      {
        moved_to_id: getValues('moved_from_id'),
      },
    ]);
    trigger('subReallocations');
  };
  const updateReallocation = (data: TSubReallocationFormData, idx: number) => {
    const newSubReallocation = subReallocations.map(
      (subReallocation, idItem) => (idItem === idx ? data : subReallocation),
    );
    setValue('subReallocations', newSubReallocation);
    trigger('subReallocations');
  };
  const removeReallocation = (idx: number) => {
    const newSubReallocation = subReallocations.filter(
      (_, idItem) => idx !== idItem,
    );
    setValue('subReallocations', newSubReallocation);
    trigger('subReallocations');
  };

  return (
    <div className="flex flex-col gap-tw-2">
      {subReallocations.map((subReallocation, idx) => (
        <StepsFormStepv2
          key={idx + 1}
          lineForStep={false}
          activated={isEmpty(Object.keys(amountJccIsValid(subReallocation)))}
          className="flex-col rounded-2xl bg-light p-tw-4"
          classes={{
            // todo move into steps component
            stepNumber: 'absolute',
            header: 'pl-tw-8',
            body: 'pl-0',
            content: 'flex flex-col gap-tw-2',
          }}
          title="SubREallocation"
          afterTitle={
            <IconButton
              variant="white"
              className="ml-auto"
              onClick={() => removeReallocation(idx)}
              iconName={'trash'}
            />
          }
          stepNumber={idx + 1}
        >
          <SubReallocationForm
            legalEntityCode={legalEntityCode}
            subReallocation={subReallocation}
            onChange={(data) => updateReallocation(data, idx)}
          />
        </StepsFormStepv2>
      ))}
      {subAmount > 0 && (
        <InlineAlert
          className="mb-m w-full"
          message={
            <div className="flex w-full items-center">
              <div className="mr-xs">
                Sub Amount: {formatAmount(reallocationAmount)}
              </div>
              {Boolean(deltaSubAmount) && (
                <div className="flex">
                  <Icon className="dark" iconName="arrowRightAlt" />
                  <div>
                    Reallocation amount is off for{' '}
                    {formatAmount(deltaSubAmount)}
                  </div>
                </div>
              )}
            </div>
          }
        />
      )}
      <SmallAddingButton
        disabled={
          reallocationAmount === 0 ||
          reallocationAmount == null ||
          movedFromId == null
        }
        className="self-start"
        onClick={addReallocation}
      >
        Add SubREallocation
      </SmallAddingButton>
    </div>
  );
};

const SubReallocation: FC<ISubReallocationProps> = ({
  formData,
  setFormData,
  hardCostContingency,
  JCCForReallocations,
  JCCTree,
}) => {
  const subAmount = formData?.subReallocations?.reduce(
    (acc: number, item: { value: string }) =>
      Number(item.value ? item.value : 0) + acc,
    0,
  );
  const deltaSubAmount = Number(formData.value || 0) - subAmount;
  const formItems = useMemo(
    () =>
      getItemsSubReallocationModal(
        hardCostContingency,
        JCCForReallocations,
        JCCTree,
      ),
    [formData, setFormData],
  );

  const addReallocation = () => {
    setFormData({
      ...formData,
      subReallocations: [
        ...formData.subReallocations,
        {
          moved_to_id: hardCostContingency.id,
        },
      ],
    });
  };
  const updateReallocation = (data, idx) => {
    const newSubReallocation = formData.subReallocations.map(
      (subReallocation, idItem) => {
        if (idItem === idx) return data;
        return subReallocation;
      },
    );
    setFormData({
      ...formData,
      subReallocations: newSubReallocation,
    });
  };
  const removeReallocation = (idx) => {
    const newSubReallocation = formData.subReallocations.filter(
      (_, idItem) => idx !== idItem,
    );
    setFormData({
      ...formData,
      subReallocations: newSubReallocation,
    });
  };
  return (
    <div>
      {formData.subReallocations?.map((subReallocation, idx) => (
        <FormComponent
          key={`subRealllocation${idx}`}
          formData={subReallocation}
          setFormData={(newData) => updateReallocation(newData, idx)}
          formItems={formItems}
          styles={{
            wrapper: 'group-form__step-item bg-light',
          }}
          unitNum={idx + 1}
          unitNumTitle="SubREallocation"
          actions={
            <IconButton
              iconName="trash"
              onClick={() => removeReallocation(idx)}
            />
          }
        />
      ))}
      {Boolean(formData?.subReallocations?.length) && (
        <InlineAlert
          className="mb-m w-full"
          message={
            <div className="flex w-full items-center">
              <div className="mr-xs">
                Sub Amount: {formatAmount(formData.value)}
              </div>
              {Boolean(deltaSubAmount) && (
                <div className="flex">
                  <Icon className="dark" iconName="arrowRightAlt" />
                  <div>
                    Reallocation amount is off for{' '}
                    {formatAmount(deltaSubAmount)}
                  </div>
                </div>
              )}
            </div>
          }
        />
      )}
      <SmallAddingButton onClick={addReallocation}>
        Add SubREallocation
      </SmallAddingButton>
    </div>
  );
};

export default SubReallocation;

import React, { useContext, useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { fetchSharedFilesByParams } from 'lib/sharedFile';
import PropTypes from 'prop-types';
import { Icon, LinkButton, Badge, AnimationLoader } from 'stories';
import { connect } from 'react-redux';
import MediaList from 'bundles/Assets/components/SharedFiles/MediaList';
import AlbumsList from 'bundles/Assets/components/Folders/AlbumsList';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import EntityContext from '../../../../Shared/EntityContext';
import { plainFetchFolders } from '../../../../Assets/actions/folders';
import NewAlbumModal from '../../../../Assets/components/Folders/NewAlbumModal';
import BulkUploaderModal from '../../../../Assets/components/SharedFiles/BulkUploaderModal';
import { newCreateSharedFiles } from '../../../../Assets/actions';
import { CssVar } from '@/shared/config/cssVar';

const AttachMedia = ({ selected, setSelected, bulkUploadSharedFiles }) => {
  const DEFAULT_VISIBLE_ITEMS = 6;
  const SIZE_PER_PAGE = 6;
  const VISIBLE_SIZE_PER_PAGE = 6;
  const investmentEntity = useContext(EntityContext);
  const [visibleFoldersCount, setVisibleFoldersCount] = useState(
    DEFAULT_VISIBLE_ITEMS,
  );
  const [visibleSharedFilesCount, setVisibleSharedFilesCount] = useState(
    DEFAULT_VISIBLE_ITEMS,
  );
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const [folders, setFolders] = useState();
  const [sharedFiles, setSharedFiles] = useState();
  const [sharedFilesTotalSize, setSharedFilesTotalSize] = useState();
  const [newAlbumModalOpened, setNewAlbumModalOpened] = useState();
  const [uploadMediaModalOpened, setUploadMediaModalOpened] = useState();

  let currentSharedFiles = [];
  if (sharedFiles) {
    currentSharedFiles = currentAlbum
      ? sharedFiles.filter(({ folderId }) => folderId === currentAlbum?.id)
      : sharedFiles;
  }
  const entityId = investmentEntity.entity.id;
  const entityType = investmentEntity.type;

  const defaultFoldersParams = {
    folder_type: 'media',
    page: 1,
    size_per_page: SIZE_PER_PAGE,
    folderable_id: entityId,
    folderable_type: entityType,
    parent_id: 'root',
  };

  const defaultSharedFilesParams = {
    entity_id: entityId,
    entity_type: entityType,
    document_types: 'media',
    page: 1,
    size_per_page: SIZE_PER_PAGE,
    sort_order: 'desc',
    sort_field: 'created_at',
  };

  const addMoreSharedFiles = (responseData) => {
    setSharedFiles((oldSharedFiles) => {
      if (oldSharedFiles) return [...oldSharedFiles, ...responseData];
      return [...responseData];
    });
  };

  useEffect(() => {
    plainFetchFolders({ ...defaultFoldersParams }).then((data) => setFolders(data));
    fetchSharedFilesByParams(
      defaultSharedFilesParams,
      addMoreSharedFiles,
      setSharedFilesTotalSize,
      () => {},
    );
  }, []);

  useEffect(() => {
    setSharedFiles(null);
    setFolders(null);
    if (currentAlbum) {
      fetchSharedFilesByParams(
        { ...defaultSharedFilesParams, folder_id: currentAlbum?.id, page: 1 },
        addMoreSharedFiles,
        setSharedFilesTotalSize,
        () => {},
      );
      setVisibleSharedFilesCount(DEFAULT_VISIBLE_ITEMS);
    }
    plainFetchFolders({ ...defaultFoldersParams, parent_id: currentAlbum?.id || 'root' }).then((data) => {
      setFolders(data);
    });
  }, [currentAlbum]);

  const showMoreSharedFiles = () => {
    const page = Math.ceil(visibleSharedFilesCount / VISIBLE_SIZE_PER_PAGE) + 1;
    setVisibleSharedFilesCount(visibleSharedFilesCount + VISIBLE_SIZE_PER_PAGE);
    fetchSharedFilesByParams(
      { ...defaultSharedFilesParams, folder_id: currentAlbum?.id, page },
      addMoreSharedFiles,
      setSharedFilesTotalSize,
      () => {},
    );
  };

  const showMoreFolders = () => {
    const page = Math.ceil(visibleFoldersCount / VISIBLE_SIZE_PER_PAGE) + 1;
    setVisibleFoldersCount(visibleFoldersCount + VISIBLE_SIZE_PER_PAGE);
    plainFetchFolders({ ...defaultFoldersParams, page })
      .then((data) => setFolders((f) => [...f, ...data]));
  };

  const handleCreate = async (data) => {
    await bulkUploadSharedFiles(data);
    await fetchSharedFilesByParams(
      { ...defaultSharedFilesParams, folder_id: currentAlbum.id, page: 1 },
      setSharedFiles,
      setSharedFilesTotalSize,
      () => {},
    );
    setVisibleSharedFilesCount(VISIBLE_SIZE_PER_PAGE);
    setUploadMediaModalOpened(false);
  };

  const toggleSelectMedia = (item) => {
    if (selected.map(({ id }) => id).includes(item.id)) {
      setSelected(selected.filter(({ id }) => id !== item.id));
    } else {
      setSelected([...selected, item]);
    }
  };

  if (!folders || !currentSharedFiles) return <AnimationLoader className="relative h-full" />
  ;

  return (
    <div className="position-relative mb-tw-8 flex-1 flex gap-tw-4">
      <div className="flex justify-between">
        {!currentAlbum && folders && (
          <>
            {`${folders.length} ${pluralize('album', folders.length)}`}
            <LinkButton
              className="flex items-center"
              onClick={() => setNewAlbumModalOpened(true)}
            >

              <Badge
                className="mr-tw-2 border border-solid border-light-30"
                textColor={CssVar.light60}
                backgroundColor={CssVar.light}
              >
                <Icon iconName="add" className="light-60 inline-regular" />
              </Badge>
              Create Album
            </LinkButton>
          </>
        )}
      </div>
      {currentAlbum && (
        <div className="flex justify-between">
          <h6 className="header-6">
            <LinkButton
              onClick={() => {
                setCurrentAlbum(null);
                setVisibleSharedFilesCount(DEFAULT_VISIBLE_ITEMS);
                fetchSharedFilesByParams(
                  defaultSharedFilesParams,
                  addMoreSharedFiles,
                  setSharedFilesTotalSize,
                  () => {},
                );
              }}
            >
              All Albums
            </LinkButton>
          </h6>
          <LinkButton
            className="flex items-center mb-tw-4"
            onClick={() => setUploadMediaModalOpened(true)}
          >
            <Badge
              className="mr-tw-2 border border-solid border-light-30"
              textColor={CssVar.light60}
              backgroundColor={CssVar.light}
            >
              <Icon iconName="upload" className="light-60 inline-regular" />
            </Badge>
            Upload Media
          </LinkButton>
        </div>
      )}
      {folders && (
        <AlbumsList
          folders={folders.slice(0, visibleFoldersCount)}
          onClick={(id) => {
            setCurrentAlbum(folders.find((f) => f.id === id));
            return null;
          }}
        />
      )}
      {folders && folders.length === 0 && currentSharedFiles.length === 0 && (
        <NoDataOverlay title="No media yet" />
      )}
      {folders && visibleFoldersCount <= folders.length && !currentAlbum && (
        <div className="text-center">
          <LinkButton onClick={showMoreFolders}>Show More</LinkButton>
        </div>
      )}
      {currentAlbum && folders.length > 0 && currentSharedFiles.length > 0 && (
        <div
          style={{ height: '1px', width: '100%', backgroundColor: 'var(--light-30)' }}
          className="my-l"
        />
      )}
      {currentSharedFiles.length > 0 && !currentAlbum && (
        <h6 className="text-light-90">Last Uploaded</h6>
      )}
      <MediaList
        items={currentSharedFiles}
        toggleSelectItem={(item) => toggleSelectMedia(item)}
        selectedMedia={selected}
        actionsVisabilityMode="always"
        lgSize={4}
        canSelect
      />
      {sharedFiles && visibleSharedFilesCount < sharedFilesTotalSize && (
        <div className="text-center my-[1rem]">
          <LinkButton onClick={showMoreSharedFiles}>Show More</LinkButton>
        </div>
      )}

      {newAlbumModalOpened && (
        <NewAlbumModal
          setOpened={setNewAlbumModalOpened}
          fetchAlbums={() => plainFetchFolders({ ...defaultFoldersParams })
            .then((data) => setFolders(data))}
        />
      )}

      {uploadMediaModalOpened && (
        <BulkUploaderModal
          setOpened={setUploadMediaModalOpened}
          defaultFolderId={currentAlbum.id}
          setSharedFiles={setSharedFiles}
          folderType="media"
          investmentObject={investmentEntity}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

const mapActionCreators = {
  bulkUploadSharedFiles: newCreateSharedFiles,
};

AttachMedia.propTypes = {
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default connect(null, mapActionCreators)(AttachMedia);

import {
  ReportDashboardSectionPosition,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import {
  ColumSizeWidgetState,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
  isWidgetSectionPositionWidthFullSize,
  WidgetStateColumns,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/column.form';
import {
  showLegalEntitiesDropdownByWidgetContext,
  useFinancialTableWidgetAutoGroupColumDef,
  useFinancialTableWidgetColDef,
  useFinancialTableWidgetDefaultColDef,
  useFinancialTableWidgetRowData,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { useFinancialTableWidgetsRowsDisplayOptionsExcelStyles } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib/useFinancialTableWidgetsRowsDisplayOptionsExcelStyles';
import { FinancialTableWidgetStateDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/state/FinancialTableWidgetStateDate';
import {
  useWidgetFullScreen,
  WidgetStateFullScreenIconButton,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { WidgetStateLegalEntities } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateLegalEntities';
import { ExcelStyleBuilder } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { WidgetTable } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { useFinancialWidgetColumnsVisibilityState } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useColumnsVisibilityState';
import { useWidgetTableColumnSize } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useWidgetTableColumnSize';
import { FinancialTableSingeDateWidgetNS } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import {
  WidgetConfigProps,
  WidgetProps,
  WidgetStateProps,
} from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { LegalEntity } from '@/entities/core/legalEntity';
import { useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { addOrRemoveItemInList } from '@/shared/lib/listHelpers/addOrRemoveItemInList';
import { IAsset } from '@/types/Asset';
import { AgGridReact } from 'ag-grid-react';
import { useObjectDashboardWidgetTableExport } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { useRef } from 'react';

type FinancialTableSingleDateWidgetProps = {
  id: string;
  position: ReportDashboardSectionPosition;
  widgetType: 'financial_table_single_date';
  title: string;
  widgetConfig: {
    ag_grid_config: {
      columnDefs?: [];
    };
    viz_config: TableVizConfig;
  };
  fontSize?: number | null;
};

export type FinancialTableSingeDateWidgetContext =
  DisplayedGroupsWidgetContext & {
    assets?: IAsset[];
  };
export type FinancialTableSingeDateWidgetState = DateWidgetState &
  DisplayedGroupsWidgetState &
  ColumSizeWidgetState & {
    excludedLegalEntityIds?: LegalEntity['id'][];
  };

export const FinancialTableSingeDateWidget = (
  props: WidgetProps<
    FinancialTableSingeDateWidgetNS.SnapshotData,
    FinancialTableSingleDateWidgetProps
  > &
    WidgetStateProps<FinancialTableSingeDateWidgetState> &
    WidgetConfigProps,
) => {
  const {
    data,
    mode,
    isError,
    isLoading,
    isFetching,
    widgetSection,
    state,
    onStateChange,
    dashboardType,
    context,
  } = props;

  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact>(null);

  const widgetStateFullScreenProps = useWidgetFullScreen(wrapperDivRef);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });

  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  const vizConfig = widgetSection.widgetConfig.viz_config;
  const headerBackground = vizConfig?.header_background;
  const columns = data?.columns ?? [];
  const rows = data?.rows ?? [];
  const hideHeader = data?.hideHeader ?? false;
  const widgetSectionFontSize = widgetSection.fontSize ?? undefined;
  const excludedLegalEntityIds = state?.excludedLegalEntityIds ?? [];

  const columnVisibilityFeature = useFinancialWidgetColumnsVisibilityState({
    data,
    grid: gridRef.current,
    state,
    onStateChange,
  });

  const exportFeature = useObjectDashboardWidgetTableExport({
    mode,
    gridRef,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
    context,
  });

  const { rowsDisplayOptionsExcelStyles: dynamicExcelStyles, cellClassRules } =
    useFinancialTableWidgetsRowsDisplayOptionsExcelStyles({
      headerBackground,
      rows,
    });

  const autoColumnDef = useFinancialTableWidgetAutoGroupColumDef({
    headerBackground,
    widgetSectionFontSize,
    data,
    cellClassRules,
    cellClass: exportFeature.autoGroupColumnDef.cellClass,
  });

  const columnDefs = useFinancialTableWidgetColDef({
    data,
    mode,
    widgetSection,
    cellClassRules,
    vizConfig,
    dashboardType,
  });

  const defaultColDef = useFinancialTableWidgetDefaultColDef({
    isCompactSelected: state.selectedColumnSize === 'compact',
    deps: [state.displayedGroups],
  });

  const rowData = useFinancialTableWidgetRowData({
    data,
  });
  const excelStyleBuilder = new ExcelStyleBuilder({
    vizConfig,
    mode,
  });
  const excelStyles = excelStyleBuilder
    .withIndentationStyles()
    .withDefaultValueDisplayOptionsStyles(
      DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN.value_display_options,
    )
    .buildExcelStyles()
    .concat(dynamicExcelStyles);

  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header className="justify-normal gap-tw-4">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          <div className="grow" />
          <FinancialTableWidgetStateDate
            state={state}
            onStateChange={onStateChange}
          />
          {!widgetStateFullScreenProps.isFullscreen &&
            context.columnVisibilityEnabled && (
              <WidgetStateColumns
                onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
                isDataLoading={Boolean(isLoading)}
                state={state}
                vizConfig={vizConfig}
                onColumnStateChange={columnVisibilityFeature.onChange}
              />
            )}
          {showLegalEntitiesDropdownByWidgetContext({
            ...context,
            dashboardType,
          }) && (
            <WidgetStateLegalEntities
              context={context}
              state={state}
              onStateChange={(leId) => {
                onStateChange({
                  excludedLegalEntityIds: addOrRemoveItemInList(
                    leId,
                    excludedLegalEntityIds,
                  ),
                });
              }}
            />
          )}
          <exportFeature.ExportButtonComponent />
          <WidgetStateFullScreenIconButton {...widgetStateFullScreenProps} />
        </DashboardWidgetCard.Header>
      )}
      {mode === 'pdf' &&
        widgetSection.title &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {!isLoading && !isError && (
        <WidgetTable
          defaultColGroupDef={undefined}
          domLayout={
            dashboardType === ReportDashboardType.OBJECT || mode === 'pdf'
              ? 'autoHeight'
              : 'normal'
          }
          ref={gridRef}
          headerBackground={headerBackground}
          hideHeader={hideHeader}
          mode={mode}
          columnDefs={columnDefs}
          autoGroupColumnDef={autoColumnDef}
          rowData={rowData}
          defaultColDef={defaultColDef}
          excelStyles={excelStyles}
          expensivePropSuppressColumnVirtualisation
          defaultParams={{
            sizeColumnsToFit: true,
          }}
          onRowDataUpdated={columnSizeFeature.handleRowDataUpdated}
        />
      )}
    </DashboardWidgetTableCard>
  );
};

import * as am5 from '@amcharts/amcharts5';
import { useLayoutEffect } from 'react';
import { getAmchartNumberFormatForByDisplayOptions } from '@/shared/lib/formatting/charts';

export function useAmchart<T extends HTMLElement>(
  element: string | React.RefObject<T>,
  init: (root: am5.Root) => void,
  deps: React.DependencyList = [],
) {
  useLayoutEffect(() => {
    if (typeof element !== 'string' && element.current == null) return;

    const root = am5.Root.new(
      typeof element === 'string' ? element : element.current!,
    );
    root.numberFormatter.setAll({
      numberFormat: getAmchartNumberFormatForByDisplayOptions({
        type: 'number',
        precision: 0,
      }),
    });
    init(root);

    return () => {
      root.dispose();
    };
  }, deps);
}

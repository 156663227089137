import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagsPageParams } from 'bundles/UserManagement/components/TagTracker';
import { API_URL } from 'lib/http';
import { IUserTag } from 'types/IUserTag';

export const TAGS_TAG = 'TagsTag';

export type MutateTagParams = {
  name: string;
  reason: string;
  user_ids: string[];
  id?: number;
};

export const settingsCoreTagsApi = createApi({
  reducerPath: 'settingsCoreTagsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/user_tags`,
  }),
  tagTypes: [TAGS_TAG],
  endpoints: (build) => ({
    getTags: build.query<
      {
        items: IUserTag[];
        meta: {
          totalSize: number;
        };
      },
      TagsPageParams
    >({
      providesTags: [TAGS_TAG],
      query: (params) => ({
        url: '',
        params: {
          sort_order: params.sortOrder,
          sort_field: params.sortField,
          search_query: params.query,
          page: params.page,
          size_per_page: params.perPage,
        },
      }),
    }),

    destroyTag: build.mutation<unknown, IUserTag>({
      invalidatesTags: [TAGS_TAG],
      query: (data) => ({
        method: 'DELETE',
        url: `/${data.id}`,
      }),
      transformResponse: (response: { errors: string[] | null } | []) => {
        if ('errors' in response) {
          toastr.error(`${(response.errors ?? []).toString()}\n`);
        } else {
          toastr.success('User Tag has been successfully deleted');
        }
        return response;
      },
    }),

    updateTag: build.mutation<unknown, Required<MutateTagParams>>({
      invalidatesTags: [TAGS_TAG],
      query: (data) => ({
        method: 'PUT',
        url: `/${data.id}`,
        body: data,
      }),
      transformResponse: (response: { errors: string[] | null } | []) => {
        if ('errors' in response) {
          toastr.error(`${(response.errors ?? []).toString()}\n`);
        } else {
          toastr.success('User Tag has been successfully updated');
        }
        return response;
      },
    }),

    createTag: build.mutation<unknown, MutateTagParams>({
      invalidatesTags: [TAGS_TAG],
      query: (data) => ({
        method: 'POST',
        url: '/',
        body: data,
      }),
      transformResponse: (response: { errors: string[] | null } | []) => {
        if ('errors' in response) {
          toastr.error(`${(response.errors ?? []).toString()}\n`);
        } else {
          toastr.success('User Tag has been successfully created');
        }
        return response;
      },
    }),
  }),
});

export const {
  useGetTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDestroyTagMutation,
} = settingsCoreTagsApi;

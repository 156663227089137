import * as React from 'react';
import { NodeRendererProps, TreeItem } from 'react-sortable-tree';
import { Icon, RadioButton, Tooltip } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { getConnectorType } from 'lib/connectors';
import { ReactComponent as LongLine } from 'images/treeConnectors/longLine.svg';
import { ReactComponent as LastLine } from 'images/treeConnectors/lastLine.svg';
import { ReactComponent as InnerLine } from 'images/treeConnectors/innerLine.svg';
import { FAKE_ROOT_NODE_ID } from 'stories/TreeFolderNavigation/TreeFolderNavigation';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import styles from './TreeFolderItem.module.scss';

export type AdditionalProps<T> = {
  selectable?: boolean;
  selected?: boolean;
  current?: boolean;
  onSelectedChange?: (node: TreeItem<T>) => void;
  onNodeClick?: (node: TreeItem<T>) => void;
  isClickable?: boolean;
  actions?: React.ReactNode;
  isDisabled?: boolean;
  disableIcons?: boolean;
  classes?: {
    radio?: string;
    item?: string;
    focus?: string;
  };
};

type Props<T> = NodeRendererProps<T> & AdditionalProps<T>;

const START_DEPTH = 1;
const NODE_LEFT_PADDING_BASE = 23;
const OFFSET_TO_CENTER_EXPANDER = 1;
const OFFSET_VERTICAL_TO_EXPANDER = 3;

function TreeFolderItem<T extends { id: number | string } = any>(
  props: Props<T>,
) {
  const {
    path,
    treeIndex,
    toggleChildrenVisibility,
    node,
    parentNode,
    lowerSiblingCounts,
    selectable,
    onSelectedChange,
    onNodeClick,
    isClickable,
    isDisabled,
    disableIcons,
    selected,
    current,
    actions,
    classes,
  } = props;
  const hasParent = parentNode !== null;
  const lastChild = hasParent && parentNode.children?.at(-1) === node;
  const depth = path.length;
  const connectorsCount = depth - 1;
  const isRoot = node.id === FAKE_ROOT_NODE_ID;
  const textRef = React.useRef<HTMLSpanElement>(null);
  const overflowedByWidth = useIsTextTruncated(textRef, node.title);
  const getConnectorComponent = (i: number) => {
    const connectorType = getConnectorType({
      index: i,
      depth,
      lastChild,
      startDepth: START_DEPTH,
    });
    const style: React.CSSProperties = {
      position: 'absolute',
      left:
        (i + 1) * NODE_LEFT_PADDING_BASE -
        NODE_LEFT_PADDING_BASE -
        OFFSET_TO_CENTER_EXPANDER,
      bottom: OFFSET_VERTICAL_TO_EXPANDER,
    };

    let Component = LongLine;

    if (connectorType === '|_') {
      Component = LastLine;
    }
    if (connectorType === '|-') {
      Component = InnerLine;
    }

    return <Component style={style} />;
  };

  const hasMoreThanOneChildrenAtDepth = (i: number) =>
    depth !== i + 1 + START_DEPTH && lowerSiblingCounts[i + 1] < 1;

  const parentIdNull = node.parentId === null;

  const resolveBoldText = () => {
    if (parentIdNull) return true;

    return current;
  };

  return (
    <div
      key={node.id}
      style={{
        paddingLeft: (depth - 1) * NODE_LEFT_PADDING_BASE,
      }}
      className={cn(styles.item, classes?.item, classes?.focus)}
    >
      <div className="flex w-full min-w-0 items-center gap-tw-1">
        {Array.from({ length: connectorsCount }).map(
          (_, i) =>
            !hasMoreThanOneChildrenAtDepth(i) && getConnectorComponent(i),
        )}
        {node.children && node.children.length > 0 && !isRoot && (
          <Icon
            iconName={node.expanded ? 'bottom' : 'right'}
            className="mr-tw-1"
            onClick={() =>
              toggleChildrenVisibility?.({
                node,
                path,
                treeIndex,
              })
            }
          />
        )}
        {selectable && (
          <RadioButton
            key={`${node.id}-radio`}
            classes={{
              label: cn('d-inline-flex', classes?.radio, {
                'opacity-100': selected,
              }),
            }}
            disabled={isDisabled}
            checked={selected}
            onClick={() => onSelectedChange?.(node)}
          />
        )}
        <div
          className={cn('flex w-full min-w-0 items-center gap-tw-1.5', {
            'cursor-pointer': isClickable,
          })}
        >
          {!disableIcons && <Icon iconName={isRoot ? 'home' : 'folder'} />}
          <span
            ref={textRef}
            onClick={() => onNodeClick?.(node)}
            className={cn(
              'text-ellipsis',
              {
                'dark-60 secondary-semibold': resolveBoldText(),
                'secondary-regular': !current,
              },
              styles.label,
              styles.itemWidth,
            )}
          >
            {node.title}
          </span>
          <Tooltip
            mainText={node.title}
            arrowPosition="center"
            reference={textRef}
            disabled={!overflowedByWidth}
            classes={{
              spanContainer: `d-inline ${styles.itemWidth}`,
            }}
          />
        </div>
      </div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
}

export default TreeFolderItem;

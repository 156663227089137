import { useNavigate } from '@reach/router';
import { useEffect, useRef } from 'react';

export function useNavigateBack({ fallbackUrl }: { fallbackUrl: string }) {
  const navigate = useNavigate();

  const fromUrlRef = useRef<string>();

  useEffect(() => {
    fromUrlRef.current = window.history.state?.from;
  }, []);

  const navigateBack = () => {
    const fromUrl = fromUrlRef.current;

    if (fromUrl) {
      navigate(fromUrl);
      return;
    }
    navigate(fallbackUrl);
  };

  return {
    navigateBack,
  };
}

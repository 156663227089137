import FlyBottomPanelBar from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelBar';
import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { IconButton, Tooltip } from 'stories';
import styles from './PathBar.module.scss';

interface Props {
  path: string[] | null;
  onClose?: () => void;
  noSelectedPlaceholder?: string;
}

function PathBar({
  path,
  onClose,
  noSelectedPlaceholder = 'No Selected',
}: Props) {
  const [tooltipPath, setTooltipPath] = useState<(string | null | undefined)[]>(
    [],
  );
  const pathsRef = useRef<{
    children: (HTMLSpanElement | null)[];
    container: HTMLDivElement | null;
  }>({
    children: [],
    container: null,
  });

  const handleCopyPath = async () => {
    try {
      if (path == null) throw new Error();

      await window.navigator.clipboard.writeText(path.join('_'));
      toastr.success('The path was copied');
    } catch (e) {
      toastr.error('Unable to write to clipboard');
    }
  };

  useLayoutEffect(() => {
    // todo with resize observer
    const { container } = pathsRef.current;
    const { offsetWidth = 0, scrollWidth = 0 } = container ?? {};

    if (offsetWidth < scrollWidth) {
      const containerEndPosition =
        container?.getBoundingClientRect().right ?? 0;
      const pathTooltip: (string | null | undefined)[] = [];
      pathsRef.current.children.forEach((n) => {
        const nodeEndPosition = n?.getBoundingClientRect().right ?? 0;

        if (nodeEndPosition > containerEndPosition) {
          pathTooltip.push(n?.textContent);
        }
      });

      setTooltipPath(pathTooltip);
    }
    if (offsetWidth === scrollWidth) {
      setTooltipPath([]);
    }
  }, [path]);

  return (
    <FlyBottomPanelBar onClose={onClose}>
      <Tooltip
        mainText={tooltipPath.join(' / ')}
        maxWidth="none"
        disabled={tooltipPath.length === 0}
        className={styles.tooltip}
      >
        <span className={styles.pathLabel}>
          {path == null ? noSelectedPlaceholder : 'Selected:'}
        </span>
        {path?.length === 0 && 'None'}
        <div
          ref={(node) => {
            pathsRef.current.container = node;
          }}
          className={styles.path}
        >
          {path?.map((p, i, arr) => (
            <React.Fragment key={i}>
              <span
                ref={(el) => {
                  pathsRef.current.children[i] = el;
                }}
              >
                {p}
              </span>
              {i !== arr.length - 1 && arr.length > 1 && (
                <span className={styles.pathDivider} />
              )}
            </React.Fragment>
          ))}
        </div>
      </Tooltip>
      {path && (
        <>
          <span className={styles.buttonDivider} />
          <IconButton
            className={styles.copy}
            offHoverStyles
            iconName="copy"
            onClick={handleCopyPath}
          />
        </>
      )}
    </FlyBottomPanelBar>
  );
}

export default PathBar;

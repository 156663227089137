import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'lib/http';
import { IQueryParams } from 'types/Pagination';
import { stringify } from 'qs';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import {
  IDevelopmentProject,
  IDevelopmentProjectDetailed,
} from 'bundles/REconcile/types/IDevelopmentProject';

export type IGetProjectsRequest = IQueryParams;

interface IGetProjectsResponse {
  items: IDevelopmentProject[];
  meta: {
    periods: string[];
  };
}

export const reconcileDevelopmentProjectsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/reconcile/development/projects`,
  }),
  reducerPath: 'reconcileDevelopmentProjects',
  endpoints: (build) => ({
    getProjects: build.query<IGetProjectsResponse, IGetProjectsRequest>({
      query(request) {
        return stringify(request, DEFAULT_STRINGIFY_OPTIONS);
      },
    }),
    getProject: build.query<IDevelopmentProjectDetailed, string>({
      query(id) {
        return `/${id}`;
      },
    }),
  }),
});

export const { useGetProjectsQuery, useGetProjectQuery } =
  reconcileDevelopmentProjectsApi;

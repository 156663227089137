import { ICreatableSelectOption } from 'bundles/Shared/components/GroupForm/FormItems/FormCreatableSelect';
import {
  ISharedSelectProps,
  SharedSelect,
} from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { createNewTagFromName } from 'bundles/Shared/entities/formula';
import {
  EntityTag,
  NewEntityTag,
  TagEntity,
  useEntityTagsQuery,
} from 'bundles/Shared/entities/tag';
import { IS_UNIFORM } from 'lib/http';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import { GroupBase } from 'react-select';

type FormulaTagsValue = EntityTag | NewEntityTag;

type Props = Omit<
  ISharedSelectProps<FormulaTagsValue, true, GroupBase<any>>,
  'isMulti' | 'onChange'
> & {
  entity: TagEntity;
  onChange?: (value: FormulaTagsValue[], actionMeta: any) => void;
};
export const RawEntityTagsSelect = ({
  onChange,
  value,
  options = [],
  ...props
}: Omit<Props, 'entity'> & {
  options?: {
    id: string;
    name: string;
  }[];
}) => {
  return (
    <SharedSelect
      creatable
      isClearable={false}
      newGen
      options={options.map((tag) => ({
        label: tag.name,
        value: tag.id,
      }))}
      isMulti
      placeholder="Search Tag By Name"
      menuPortalTarget={document.body}
      components={{
        MultiValue: () => <></>,
        ClearIndicator: () => <></>,
      }}
      value={
        value?.map((tag) => ({
          label: tag.name,
          value: tag.id,
        })) ?? null
      }
      onChange={(newValue: ICreatableSelectOption<string>[], actionMeta) => {
        onChange?.(
          newValue?.map(
            (tag) =>
              options.find((t) => t.id === tag.value) ??
              createNewTagFromName(tag.label),
          ),
          actionMeta,
        );
      }}
      {...props}
    />
  );
};

// todo unify creatable multi select
export function EntityTagsSelect({ onChange, value, entity, ...props }: Props) {
  const { systemTagsPartition, tags, isLoading } = useEntityTagsQuery(entity);
  const [systemFormulaTags, nonSystemTags] = systemTagsPartition;

  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    const finalTags = IS_UNIFORM
      ? [...systemFormulaTags, ...nonSystemTags]
      : nonSystemTags;

    setOptions(
      finalTags.map((tag) => ({
        label: tag.name,
        value: tag.id,
      })),
    );
  }, [systemTagsPartition]);

  return (
    <SharedSelect
      creatable
      isClearable={false}
      newGen
      isLoading={isLoading}
      isMulti
      placeholder="Search Tag By Name"
      options={options}
      menuPortalTarget={document.body}
      components={{
        MultiValue: () => <></>,
        ClearIndicator: () => <></>,
      }}
      value={
        value?.map((tag) => ({
          label: tag.name,
          value: tag.id,
        })) ?? null
      }
      onChange={(newValue: ICreatableSelectOption<string>[], actionMeta) => {
        onChange?.(
          newValue?.map(
            (tag) =>
              tags.find((t) => t.id === tag.value) ??
              createNewTagFromName(tag.label),
          ),
          actionMeta,
        );
      }}
      {...omit(props, 'options')}
    />
  );
}

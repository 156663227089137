import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  IDevelopmentLegalEntityRequisitionTransaction,
  ILineItem,
} from 'bundles/REconcile/types/ILineItem';
import React, { useMemo } from 'react';
import {
  getAmountFormatter,
  getRemarkFormatter,
  getTransactionDateFormatter,
  getVendorEntityFormatter,
} from 'bundles/Shared/components/lineItems/formatters';
import { TRANSACTION_TYPE_FILTER } from 'bundles/Shared/components/lineItems/filters';
import { Label, Tooltip, Button } from 'stories';
import { TTransactionType } from 'types/TransactionType';
import QuickFilterAmount from 'bundles/Shared/components/Table/filters/QuickFilterAmount';
import QuickFilterCheckList, {
  getDefaultMappingToOption,
  TQuickFilterCheckListProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import { IQuickFilterAmountModel } from 'stories/QuickFilterByAmount/QuickFilterByAmount';
import { formatDate } from '@/shared/lib/formatting/dates';
import { transformTransactionAmountForFilter } from './bisUtils';
import { getValueTypeTransaction } from 'bundles/Shared/components/Table/formatters/drawPackage';

enum ColumnIds {
  DATE = 'date',
  BALANCE = 'balance',
  TRANSACTION_VALUE = 'transaction_value',
  TRANSACTION_TYPE = 'transaction_type',
  PERIOD = 'period',
  VENDOR_ENTITY_NAME = 'vendor_entity_name',
  NOTE = 'note',
}

export type TFilterModel = {
  [ColumnIds.TRANSACTION_VALUE]: IQuickFilterAmountModel;
  [ColumnIds.VENDOR_ENTITY_NAME]?: VendorEntity[];
  [ColumnIds.TRANSACTION_TYPE]?: TTransactionType;
};

export const useColumns = ({
  vendors,
  lineItem,
  handleLinkTransactionClick,
  categoryText,
  createFromSource,
}: {
  vendors: VendorEntity[];
  handleLinkTransactionClick: (
    row: IDevelopmentLegalEntityRequisitionTransaction,
    type?: 'split' | 'link',
  ) => void;
  categoryText: string;
  lineItem?: ILineItem;
  createFromSource?: boolean;
}) =>
  useMemo<IColumn<IDevelopmentLegalEntityRequisitionTransaction>[]>(
    () => [
      {
        text: 'Tx Date',
        dataField: ColumnIds.DATE,
        sortable: true,
        formatter: getTransactionDateFormatter((item) => item.date),
        headerStyle: {
          width: 160,
        },
      },
      {
        text: 'Period',
        dataField: ColumnIds.PERIOD,
        sortable: true,
        formatter: ({ row }) => formatDate(row.period, 'MMM-YYYY'),
        headerStyle: {
          width: 174,
        },
      },
      {
        text: 'Vendor',
        dataField: ColumnIds.VENDOR_ENTITY_NAME,
        headerStyle: {
          width: 274,
        },
        classes: 'max-w-[15%]',
        formatter: getVendorEntityFormatter((item) => item.vendorEntity),
        filterComponent: QuickFilterCheckList,
        filterComponentParams: {
          ...getDefaultMappingToOption((item) => item.name),
          items: vendors,
          hideWhenItemsLessThanOne: true,
          search: vendors.length > 5,
        } as TQuickFilterCheckListProps<VendorEntity>,
      },
      {
        text: 'Type',
        sortable: createFromSource,
        dataField: ColumnIds.TRANSACTION_TYPE,
        headerStyle: {
          width: 140,
        },
        formatter: ({ row: transaction }) =>
          getValueTypeTransaction(transaction.valueType),
        ...(createFromSource ? TRANSACTION_TYPE_FILTER : {}),
      },
      {
        text: 'Amount',
        dataField: ColumnIds.TRANSACTION_VALUE,
        sortable: true,
        headerStyle: {
          minWidth: 220,
        },
        classes: 'text-right',
        headerAlign: 'end',
        formatter: ({ row }) => (
          <div className="flex justify-between">
            <div>
              {Number(lineItem?.amount) === Number(row.value) && (
                <Label color="var(--green)" outline>
                  EXACT MATCH
                </Label>
              )}
            </div>
            <div>{getAmountFormatter((item) => item.value)({ row })}</div>
          </div>
        ),
        filterComponent: QuickFilterAmount,
        filterComponentParams: {
          transformValue: transformTransactionAmountForFilter,
        },
      },
      {
        text: 'Description',
        dataField: ColumnIds.NOTE,
        formatter: getRemarkFormatter((item) => item.note),
      },
      {
        text: 'Action',
        dataField: 'action',
        headerStyle: {
          width: lineItem ? 200 : 210,
        },
        formatter: ({ row }) => {
          if (lineItem) {
            return (
              <Button
                variant="primary"
                size="s"
                iconName="link"
                iconPosition="right"
                onClick={() => handleLinkTransactionClick(row)}
                className="whitespace-nowrap"
              >
                Link Transaction
              </Button>
            );
          }

          return (
            <div className="flex">
              <Tooltip titleText={categoryText}>
                <Button
                  variant="primary"
                  size="s"
                  className="mr-tw-2 whitespace-nowrap"
                  onClick={() => handleLinkTransactionClick(row, 'link')}
                >
                  Link to JCC
                </Button>
              </Tooltip>
              <Button
                className="whitespace-nowrap"
                variant="primary"
                size="s"
                onClick={() => handleLinkTransactionClick(row, 'split')}
              >
                Split TX
              </Button>
            </div>
          );
        },
      },
    ],
    [vendors],
  );

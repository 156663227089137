import {
  FinancialTableSingeDateWidget,
  FinancialTableSingleDateWidgetConfigCard,
  FinancialTableSingleDateWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/ui/';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';

export const FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Financial Table Single Date',
  icon: 'table',
  Component: FinancialTableSingeDateWidget,
  ConfigComponent: FinancialTableSingleDateWidgetConfig,
  CardConfigComponent: FinancialTableSingleDateWidgetConfigCard,
} as const satisfies WidgetConfiguration;

export const REPORT_BUILDER_ORIENTATION_CLASSES = {
  ['portrait']: {
    className: 'w-[1170px] max-w-[1170px]',
    mediaWidgetClassName: 'flex flex-wrap justify-center',
    mediaWidgetImgClassName: 'h-[220px] w-[32.3%]',
  },
  ['landscape']: {
    className: 'w-[1680px] max-w-[1680px]',
    mediaWidgetClassName: 'flex flex-wrap justify-center',
    mediaWidgetImgClassName: 'h-[260px] w-[24.2%]',
  },
} as const;

export type BASE_WIDGET_CONFIG_CARD_INFO_LINE_TYPE = 'error' | 'no-data';

export const BASE_WIDGET_CONFIG_CARD_INFO_LINE_CONFIG = {
  error: {
    defaultMessage: 'Error',
    iconClassName: 'text-danger-055',
  },
  'no-data': {
    defaultMessage: 'No data, try choosing a different date',
    iconClassName: 'text-attention-055',
  },
} as const satisfies Record<
  BASE_WIDGET_CONFIG_CARD_INFO_LINE_TYPE,
  {
    defaultMessage: string;
    iconClassName: string;
  }
>;

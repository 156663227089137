import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { EditableConnectionProps } from 'bundles/Settings/components/REport/Connections/widgets/ConnectionModal';
import { ConnectionSFTPAttr } from 'bundles/Shared/entities/connection/model';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';
import { Field } from 'stories/index';

type ConnectionModalSFTPFlowProps = {
  control: Control<EditableConnectionProps, any>;
};

export const ConnectionModalSFTPFlow = ({
  control,
}: ConnectionModalSFTPFlowProps) => (
  <>
    <Field labelText="Connection address" required>
      <Controller
        control={control}
        name="host"
        render={({ field }) => (
          <SharedInput<ConnectionSFTPAttr['host']>
            value={field.value}
            onChange={field.onChange}
            placeholder="Enter host"
            newGen
          />
        )}
      />
    </Field>
    <Field labelText="Username" required>
      <Controller
        control={control}
        name="username"
        render={({ field }) => (
          <SharedInput<ConnectionSFTPAttr['username']>
            value={field.value}
            onChange={field.onChange}
            placeholder="Enter username"
            newGen
          />
        )}
      />
    </Field>
    <Field labelText="Password" required>
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <SharedInput<ConnectionSFTPAttr['password']>
            value={field.value}
            onChange={field.onChange}
            placeholder="Enter password"
            type="password"
            newGen
          />
        )}
      />
    </Field>
  </>
);

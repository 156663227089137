import * as React from 'react';
import { ISharedDocument } from 'types/SharedDocument';

interface Props {
  row: ISharedDocument;
  mobile?: boolean;
}

function DocumentType({ row, mobile }: Props) {
  return !mobile ? (
    <span className="document-type-badge-outline secondary-regular">
      {row.documentType.name}
    </span>
  ) : (
    <div>
      <span className="text-light-60">Type: </span>
      <span className="font-medium text-dark-60">{row.documentType.name}</span>
    </div>
  );
}

export default DocumentType;

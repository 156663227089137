import { useEffect, useState } from 'react';
import { IHeaderParams } from 'ag-grid-community';

type SortState = 'asc' | 'desc' | null;

export default function useHeaderSorting(headerProps: IHeaderParams) {
  const { column } = headerProps;

  const [activeSort, setActiveSort] = useState<SortState>(null);

  const onSortChanged = () => {
    setActiveSort(column.getSort());
  };

  const onSortRequested = (event) => {
    headerProps.progressSort(event.shiftKey);
  };

  useEffect(() => {
    headerProps.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
    return () => {
      headerProps.column.removeEventListener('sortChanged', onSortChanged);
    };
  }, []);

  return {
    onSortRequested,
    activeSort,
  };
}

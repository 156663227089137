import React, { useState, useMemo } from 'react';
import { Button, Collapse } from 'stories';
import pluralize from 'pluralize';
import ViewMediaModal from 'bundles/Shared/components/ViewMediaModal';
import { ISharedDocument } from 'types/SharedDocument';
import AttachedItemsList from './AttachedItemsList';

interface Props {
  media?: Media[];
  documents?: ISharedDocument[];
  expanded?: boolean;
}

export interface Media {
  id: string;
  extension: string;
  size: number;
  title: string;
  url: string;
  smallImageUrl: string;
  smallPlaceholderUrl: string;
  largeImageUrl: string;
  videoUrl: string;
}

function AttachedItemsPanel({
  media = [],
  documents = [],
  expanded = false,
}: Props) {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImageId, setCurrentImageId] = useState('0');
  const [isOpen, setIsOpen] = useState(expanded);

  const filteredMedia = useMemo(
    () => media.filter((item) => item.largeImageUrl !== undefined),
    [media],
  );

  const currentIndex = useMemo(
    () => filteredMedia.findIndex((item) => item.id === currentImageId),
    [currentImageId, filteredMedia],
  );

  const views = useMemo(
    () =>
      filteredMedia.map((x) => ({
        source: { regular: x.largeImageUrl },
        caption: x.description,
      })),
    [filteredMedia],
  );

  const headerLabel = [
    media.length > 0
      ? `${media.length} Media ${pluralize('File', media.length)}`
      : null,
    documents.length > 0
      ? `${documents.length} ${pluralize('Document', documents.length)}`
      : null,
  ]
    .filter((label) => label !== null)
    .join(', ');

  const hideCollapsePanel =
    (media.length === 1 && documents.length === 0) ||
    (documents.length === 1 && media.length === 0);

  return (
    <div className="rounded-[1rem] bg-light">
      {!hideCollapsePanel && (
        <div className="items-center p-m flex">
          <div className="w-40p h-40p circle text-[1.5rem] justify-center items-center mr-m flex bg-white">
            <span className="sre-icon-attach blue" />
          </div>
          <span className="header-6">{headerLabel}</span>
          <div className="flex-grow" />
          <Button
            onClick={() => setIsOpen(!isOpen)}
            variant="secondary"
            iconPosition="right"
            iconName={isOpen ? 'top' : 'bottom'}
            size="s"
          >
            {isOpen ? 'Hide' : 'Show'} all
          </Button>
        </div>
      )}
      <Collapse expanded={hideCollapsePanel || isOpen}>
        <AttachedItemsList
          attachedMedia={media}
          attachedDocuments={documents}
          openLightbox={(id) => {
            setCurrentImageId(id);
            setViewerIsOpen(true);
          }}
        />
      </Collapse>
      <ViewMediaModal
        opened={viewerIsOpen}
        onClose={() => setViewerIsOpen(false)}
        currentIndex={currentIndex}
        views={views}
      />
    </div>
  );
}

export default AttachedItemsPanel;

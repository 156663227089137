/* eslint-disable no-console */
import DownloadButton from 'bundles/Assets/components/SharedFiles/Table/buttons/DownloadButton';
import { bulkDownload } from 'lib/sharedFile';
import React, { useMemo } from 'react';
import { Badge, DocumentCard, IconButton, LinkButton, Popover } from 'stories';
import type { ISharedDocument } from 'types/SharedDocument';
import pluralize from 'pluralize';
import { sumBy } from 'lodash-es';
import styles from '../styles.module.scss';

interface Props {
  sharedFiles: ISharedDocument[];
}

function DocumentsPreview({ sharedFiles }: Props) {
  const sharedFilesSize = useMemo(
    () => sumBy(sharedFiles, 'size'),
    [sharedFiles],
  );

  const DISALED_STYLES = { pointerEvents: 'none', opacity: 0.5 };

  const template = (
    <div
      className={styles.documentsPreview}
      onClick={(e) => e.stopPropagation()}
    >
      {sharedFiles.map((file) => (
        <DocumentCard
          key={file.id}
          className={styles.documentCard}
          filename={file.name}
          fileSize={file.size ?? null}
          fileExtension={file.extension ?? null}
          actions={[
            <DownloadButton row={file} />,
            <a
              href={`/shared-files/${file.id}?disposition=inline`}
              target="_blank"
              rel="noreferrer"
              className="mr-tw-2"
              data-tip={file.confidential ? "You can't view this file" : 'View'}
              style={file.confidential ? DISALED_STYLES : {}}
            >
              <IconButton
                iconName="eye"
                size="m"
                className="sre-icon-button_size-mobile-l"
              />
            </a>,
          ]}
        />
      ))}
      <div className="flex justify-between items-center mt-s">
        {sharedFilesSize > 0 && (
          <p className="light-60 font-12">{sharedFilesSize}</p>
        )}
        <LinkButton
          className="font-12 font-weight-600 ml-auto"
          onClick={() => bulkDownload(sharedFiles)}
        >
          Download All
        </LinkButton>
      </div>
    </div>
  );
  return (
    <Popover
      appendTo={() => document.body}
      template={template}
      placement="left-end"
      style={{ borderRadius: 'var(--spacing-m)' }}
      touch={false}
      hiddenArrow
      trigger="mouseenter"
      maxWidth="initial"
      offset={[10, 2]}
      sticky
    >
      <Badge
        backgroundColor="var(--light)"
        textColor="var(--dark-60)"
        className="ml-s"
        classes={{ value: 'font-weight-400' }}
      >
        {sharedFiles.length} {pluralize('file', sharedFiles.length)}
      </Badge>
    </Popover>
  );
}

export default DocumentsPreview;

import React, { ReactNode } from 'react';
import { formatDate } from '@/shared/lib/formatting/dates';
import { IFormatterProps } from 'bundles/Shared/components/Table/types';
import VendorPopover from 'bundles/REport/components/financials/modal/VendorPopover';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import { Icon, InlineObject } from 'stories';
import { RemarkCell } from 'bundles/Shared/components/lineItems/RemarkCell';
import {
  DISABLE_CURRENCY_SYMBOL_OPTIONS,
  formatAmount,
} from '@/shared/lib/formatting/number';
import { capitalize } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import { CLASS_NAMES_BY_LINE_ITEM_TYPE } from 'bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/consts';

export const getTransactionDateFormatter =
  <T,>(dateGetter: (item: T) => string | number) =>
  ({ row }: IFormatterProps<T>) =>
    formatDate(dateGetter(row), 'LL');

export const getAmountFormatter =
  <T,>(amountGetter: (item: T) => string | number) =>
  ({ row }: IFormatterProps<T>) => (
    <>
      <span className="light-60 inline-regular mr-1">$</span>
      {formatAmount(amountGetter(row), DISABLE_CURRENCY_SYMBOL_OPTIONS)}
    </>
  );

export const getVendorEntityFormatter =
  <T,>(vendorGetter: (item: T) => Pick<VendorEntity, 'name' | 'code'> | null) =>
  ({ row }: IFormatterProps<T>) =>
    vendorGetter(row) ? (
      <VendorPopover vendor={vendorGetter(row)}>
        <InlineObject
          iconName="bag"
          object={vendorGetter(row)!.name}
          hideTooltipWhenOverflow
        />
      </VendorPopover>
    ) : (
      '-'
    );

export const getRemarkFormatter =
  <T,>(remarkGetter: (item: T) => ReactNode | undefined | null) =>
  ({ row }: IFormatterProps<T>) => (
    <RemarkCell text={remarkGetter(row)} maxWidth="auto" />
  );

export const getLineItemTypeFormatter = (lineItem) => (
  <div className="flex items-center gap-tw-2">
    <Icon
      className={cn(
        'rounded-lg p-tw-1',
        CLASS_NAMES_BY_LINE_ITEM_TYPE[lineItem.type],
      )}
      iconName={lineItem.type}
    />
    <span className="secondary-regular text-light-60">
      {capitalize(lineItem.type)}
    </span>
  </div>
);

import React, { FC } from 'react';
import { Field, Textarea } from 'stories';
import { IFormItem } from '../types/types';
import { cn } from '@/shared/lib/css/cn';

interface IFormTextAreaProps extends IFormItem {
  value: string;
}

const FormTextArea: FC<IFormTextAreaProps> = ({
  title,
  placeholder,
  formData,
  setFormData,
  fieldName,
  required,
  value,
  loading,
  styles,
  disabled,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
  };
  return (
    <div
      className={cn(
        'form-item',
        styles?.wrapper ?? 'w-full',
        styles?.nomb && 'form-item_nomb',
      )}
    >
      <Field labelFor={fieldName} labelText={title} required={required}>
        <Textarea
          data-testid={fieldName}
          id={fieldName}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          disabled={loading || disabled}
        />
      </Field>
    </div>
  );
};

export default FormTextArea;

import * as React from 'react';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import './LoadMoreButton.css';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading?: boolean;
  size?: 's' | 'm' | 'l';
  fluid?: boolean;
}

export function LoadMoreButton({
  isLoading,
  size = 'm',
  fluid,
  children,
  className,
  ...props
}: Props) {
  return (
    <button
      type="button"
      className={cn(
        'sre-loadmore-button',
        `sre-loadmore-button_size-${size}`,
        {
          'sre-loadmore-button_loading': isLoading,
          _fullwidth: fluid,
        },
        className,
      )}
      disabled={isLoading}
      {...props}
    >
      <Icon className="sre-loadmore-button__icon" iconName="sync" />
      {children}
    </button>
  );
}

export default LoadMoreButton;

import React from 'react';
import { navigate, useParams } from '@reach/router';
import { Button, Tooltip } from 'stories';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';

const SetGeneralLedgerTooltip = ({ children }) => {
  const navigation = useNavigation();
  const params = useParams();

  return (
    <Tooltip
      theme="light"
      variant="explanation"
      arrowPosition="start"
      placement="right-start"
      style={{
        backgroundColor: 'white',
      }}
      actionText={
        <Button
          variant="secondary"
          size="xs"
          className="mt-tw-2"
          onClick={() => {
            navigate(
              navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS', {
                legalEntityCode: params.legalEntityCode,
                tab: 'jcc',
              }),
            );
          }}
        >
          Set General Ledger
        </Button>
      }
      titleText={
        <span className="dark-60 secondary-semibold">No Available Source</span>
      }
      mainText={
        <p>
          You should set General Ledger for this JCC to unlock an ability to
          create line items from source
        </p>
      }
    >
      {children}
    </Tooltip>
  );
};

export default SetGeneralLedgerTooltip;

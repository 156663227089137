import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TRootState } from '@/app/stores';
import { IReconcileReport } from 'types/Snapshot';
import {
  fetchReconcileCompanies,
  fetchReconcileContacts,
  fetchReconcileReasons,
} from '../actions/changeEvent';
import { fetchReconcileSnapshot, TFundView } from '../actions/report';
import { ICompany, IContact, IReason } from 'bundles/Construction/types';

export interface IParamsReconcile {
  report: {
    legalEntityCode: string;
    view: TFundView;
  };
}

interface IChangeManagmentState {
  modalPage: {
    activeAction: string | null;
  };
  report: IReconcileReport | null;
  reasons: IReason[] | null;
  companies: ICompany[];
  contacts: IContact[];
}

const initialState: IChangeManagmentState = {
  modalPage: {
    activeAction: null,
  },
  report: null,
  reasons: null,
  companies: [],
  contacts: [],
};

const fetchShapshot = createAsyncThunk<
  IReconcileReport,
  IParamsReconcile['report']
>('developmentReallocation/report', async (params) => {
  const documents = await fetchReconcileSnapshot(params);
  return documents;
});

const fetchReasons = createAsyncThunk<IReconcileReport>(
  'developmentReallocation/reasons',
  async () => {
    const documents = await fetchReconcileReasons();
    return documents;
  },
);

const fetchCompanies = createAsyncThunk<IReconcileReport>(
  'developmentReallocation/companies',
  async () => {
    const documents = await fetchReconcileCompanies();
    return documents;
  },
);

const fetchContacts = createAsyncThunk<IReconcileReport>(
  'developmentReallocation/contacts',
  async () => {
    const documents = await fetchReconcileContacts();
    return documents;
  },
);

const reconcileSlice = createSlice({
  name: 'reconcile',
  initialState,
  reducers: {
    changeReconcileActiveAction: (state, action) => {
      state.modalPage.activeAction = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchShapshot.fulfilled, (state, action) => {
      const report = action.payload;
      state.report = report;
    });

    builder.addCase(fetchReasons.fulfilled, (state, action) => {
      const reasons = action.payload;
      state.reasons = reasons;
    });

    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      const companies = action.payload;
      state.companies = companies;
    });

    builder.addCase(fetchContacts.fulfilled, (state, action) => {
      const contacts = action.payload;
      state.contacts = contacts;
    });
  },
});

export const { changeReconcileActiveAction } = reconcileSlice.actions;

export { fetchShapshot, fetchReasons, fetchCompanies, fetchContacts };

export const selectReconcileActiveAction = (state: TRootState) =>
  state.reconcile.modalPage.activeAction;
export const selectReport = (state: TRootState) => state.reconcile.report;
export const selectReasons = (state: TRootState) => state.reconcile.reasons;
export const selectCompanies = (state: TRootState) => state.reconcile.companies;
export const selectContacts = (state: TRootState) => state.reconcile.contacts;

export default reconcileSlice.reducer;

export default function saveFileOnUserDevice(file, fileName) {
  if (navigator.msSaveBlob) { // For ie and Edge
    navigator.msSaveBlob(file, fileName);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = fileName;
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    link.remove();
    window.URL.revokeObjectURL(link.href);
  }
}

import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { IUser } from 'types/User';
import { ISortParamsField } from 'types/Sort';

export enum TWorkflowThresholdType {
  COR = 'COR',
  AGG = 'AGG',
  TIM = 'TIM',
}

type TPathParams = {
  legalEntityId: string;
};

export type TFetchThresholdsRequest = ISortParamsField<'number' | 'value'>;

export interface IWorkflowThreshold {
  id: number;
  number: number;
  value: number;
  kind: TWorkflowThresholdType;
  externalApprovers: Pick<IUser, 'id' | 'fullName' | 'role' | 'tags'>[];
  internalApprover: Pick<IUser, 'id' | 'fullName' | 'role' | 'tags'>;
}

export interface IWorkflowThresholdUpdate {
  value: number;
  external_approver_ids: string[];
  internal_approver_id: string;
  category: TWorkflowThresholdType;
  number: number;
}

export const {
  getEntities: fetchThresholdsInReconcileSettings,
  createEntity: createThresholdInReconcileSettings,
  updateEntity: updateThresholdInReconcileSettings,
  deleteEntity: deleteThresholdInReconcileSettings,
} = createCrudApi<
  TPathParams,
  {},
  IWorkflowThreshold,
  IWorkflowThresholdUpdate,
  {
    items: IWorkflowThreshold[];
    meta: {
      totalSize: number;
      perPage: number;
    };
  }
>('/settings/reconcile/legal_entities/:legalEntityId/thresholds', 'Threshold');

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '@/app/stores';
import { ILineItem } from 'bundles/REconcile/types/ILineItem';

export interface IInvoiceState {
  lineItems: ILineItem[];
}

const initialState: IInvoiceState = {
  lineItems: [],
};

const reconcileInvoiceStateSlice = createSlice({
  name: 'reconcileInvoiceState',
  initialState,
  reducers: {
    addInvoiceLineItem: (state, action) => {
      state.lineItems = [...state.lineItems, action.payload];
    },
    addListInvoiceLineItem: (state, action) => {
      state.lineItems = action.payload;
    },
    selectInvoiceLineItem: (state, action) => {
      state.lineItems = state.lineItems.map((item, idItem) => {
        const { selected } = item;
        if (idItem === action.payload) {
          return {
            ...item,
            selected: !selected,
          };
        }
        return item;
      });
    },
    selectAllInvoiceLineItem: (state) => {
      const allItemsSelected = !state.lineItems.find((item) => !item.selected);

      state.lineItems = state.lineItems.map((item) => ({
        ...item,
        selected: !allItemsSelected,
      }));
    },
    removeInvoiceLineItem: (state, action) => {
      const newLineItemData = [...state.lineItems];
      state.lineItems = newLineItemData.filter(
        (item, idItem) => idItem !== action.payload,
      );
    },
    clearInvoiceLineItems: (state) => {
      state.lineItems = [];
    },
  },
});

export const {
  addInvoiceLineItem,
  addListInvoiceLineItem,
  removeInvoiceLineItem,
  clearInvoiceLineItems,
  selectInvoiceLineItem,
  selectAllInvoiceLineItem,
} = reconcileInvoiceStateSlice.actions;

export const selectInvoiceLineItems = (state: TRootState) =>
  state.reconcileInvoiceState.lineItems;

export default reconcileInvoiceStateSlice.reducer;

import { ReportManualVariable } from '@/entities/report/manualVariable/model';
import { useExportMVLegalEntities } from '@/features/report/manualVariable/exportMVLegalEntities/lib';
import { ExportButton } from '@/shared/ui/ExportButton';

export const ExportMVLegalEntitiesButton = ({
  manualVariableId,
}: {
  manualVariableId: ReportManualVariable['id'];
}) => {
  const [getFile, options] = useExportMVLegalEntities();
  return (
    <ExportButton
      loading={options.isLoading}
      disabled={options.isLoading}
      onClick={() => {
        getFile({
          mvId: manualVariableId,
        });
      }}
    />
  );
};

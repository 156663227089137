import React from 'react';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { nameOf } from 'types/utils';
import { IReconcileSettingsMarkupUpdate } from 'bundles/REconcile/actions/markupsConfig';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import {
  DEFAULT_CODE_FIELD,
  DEFAULT_NAME_FIELD,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IFieldsProps } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

function MarkupFields({
  formData,
  setFormData,
}: IFieldsProps<IReconcileSettingsMarkupUpdate>) {
  return (
    <FormComponent
      styles={{
        wrapper__item: 'mb-0',
      }}
      formData={formData}
      setFormData={setFormData}
      formItems={[
        DEFAULT_CODE_FIELD,
        DEFAULT_NAME_FIELD,
        {
          type: FieldType.INPUT_NUMBER,
          title: 'Percent',
          fieldName: nameOf<IReconcileSettingsMarkupUpdate>('percent'),
          required: true,
          placeholder: 'Percent',
          leftIcon: 'percent',
          min: 0,
          max: 0,
        },
        {
          type: FieldType.TUMBLER,
          title: 'Include in fee',
          fieldName: nameOf<IReconcileSettingsMarkupUpdate>('included_in_fee'),
          required: true,
          styles: {
            nomb: true,
          },
        },
      ]}
    />
  );
}

export default MarkupFields;

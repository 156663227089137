import { UserActivityReport } from 'bundles/Shared/entities/user/api/userActivityApi';
import { IFormatterProps } from 'bundles/Shared/components/Table/types';

type UserActivityReportRecord = UserActivityReport['items'][number];
type TrendableColumns = keyof Pick<
  UserActivityReportRecord,
  'users' | 'sessions' | 'duration'
>;
type TrendType = 'up' | 'down' | 'equals';

export const resolveUserActivityTrend = <T extends TrendableColumns>({
  key,
  currentRow,
  previousRow,
}: {
  previousRow: UserActivityReportRecord;
  currentRow: UserActivityReportRecord;
  key: T;
}): TrendType => {
  const previousValue = previousRow[key];
  const currentValue = currentRow[key];

  if (previousValue === currentValue) {
    return 'equals';
  }
  if (previousValue > currentValue) {
    return 'down';
  }
  return 'up';
};

export const resolveBgColorByTrend = (trendType: TrendType) =>
  (
    ({
      up: '!bg-success-020',
      down: '!bg-danger-020',
      equals: '!bg-neutral-100',
    }) as const satisfies Record<TrendType, string>
  )[trendType];

export const resloveClassesFor =
  (key: TrendableColumns, order: 'asc' | 'desc' = 'desc') =>
  ({ row, rowIndex, rows }: IFormatterProps<UserActivityReportRecord>) =>
    resolveBgColorByTrend(
      resolveUserActivityTrend({
        key,
        currentRow: row,
        previousRow:
          (order === 'desc' ? rows[rowIndex + 1] : rows[rowIndex - 1]) ?? row,
      }),
    );

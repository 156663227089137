import {
  ReportManualVariable,
  useDeleteApiReportManualVariablesByIdMutation,
} from '@/entities/report/manualVariable';

export const deleteReportManualVariable = () => {
  const [del, options] = useDeleteApiReportManualVariablesByIdMutation();
  return [
    (args: Pick<ReportManualVariable, 'id'>) =>
      del({
        id: args.id,
      }),
    options,
  ] as const;
};

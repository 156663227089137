import AssetsTable from 'bundles/Assets/components/Table/AssetsTable';
import React from 'react';
import { useGetApiPortfolioDetailsQuery } from 'bundles/Shared/shared/api/portfolioEnhancedApi';

const Assets = () => {
  const { data, isLoading } = useGetApiPortfolioDetailsQuery();
  const assets = data?.assets ?? [];
  return (
    <div>
      {assets.length !== 0 && (
        <>
          <h5 className="light-60 header5-regular mb-m">Assets</h5>
          <AssetsTable assets={assets} />
        </>
      )}
    </div>
  );
};

export default Assets;

import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { getCssVariableByAccessor } from 'lib/helpers';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { updateSectionColor } from 'bundles/Shared/features/dashboard/sectionActions/model';
import {
  ReportEagleEyeDashboard,
  ReportEagleEyeDashboardSection,
} from 'bundles/Shared/entities/dashboard';
import Icon from 'stories/Icon/Icon';
import {
  DASHBOARD_SECTION_COLORS,
  DASHBOARD_SECTION_NULL_COLOR,
} from '../config';
import { CssVar } from '@/shared/config/cssVar';

interface Props {
  activeColor: string | null;
  sectionId: ReportEagleEyeDashboardSection['id'];
  dashboardId: ReportEagleEyeDashboard['id'];
}

export const ColorPicker = ({
  activeColor,
  colors,
  onChange,
}: {
  activeColor: string | null;
  colors: {
    value: string;
    checkColor?: string;
  }[];
  onChange: (color: string) => void;
}) => {
  return (
    <div className="flex w-full justify-between gap-tw-2">
      {colors.map((c) => (
        <div
          key={c.value}
          style={{ backgroundColor: c.value }}
          className={cn(
            'h-[32px] w-[32px] cursor-pointer !rounded-lg border-4 border-solid',
            'flex items-center justify-center border-neutral-100',
          )}
          onClick={() => onChange(c.value)}
        >
          <Icon
            style={{
              color:
                c.checkColor ?? getCssVariableByAccessor(CssVar.neutral000),
            }}
            className={cn(activeColor === c.value ? 'block' : 'hidden')}
            iconName="check"
          />
        </div>
      ))}
    </div>
  );
};

export function DashboardSectionColorPicker({
  sectionId,
  activeColor,
  dashboardId,
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <div className="flex w-full justify-between gap-tw-2">
      {(
        [DASHBOARD_SECTION_NULL_COLOR, ...DASHBOARD_SECTION_COLORS] as const
      ).map((c) => (
        <div
          key={c}
          style={{ backgroundColor: c }}
          className={cn(
            'h-[32px] w-[32px] cursor-pointer !rounded-lg border-4 border-solid',
            'flex items-center justify-center border-neutral-100',
          )}
          onClick={() => {
            dispatch(
              updateSectionColor({
                dashboardId,
                color:
                  c === DASHBOARD_SECTION_NULL_COLOR
                    ? null
                    : getCssVariableByAccessor(c),
                sectionId,
              }),
            );
          }}
        >
          <Icon
            className={cn(
              c === DASHBOARD_SECTION_NULL_COLOR
                ? 'text-neutral-700'
                : 'text-neutral-000',
              (activeColor == null && c === DASHBOARD_SECTION_NULL_COLOR) ||
                activeColor === getCssVariableByAccessor(c)
                ? 'block'
                : 'hidden',
            )}
            iconName="check"
          />
        </div>
      ))}
    </div>
  );
}

export default DashboardSectionColorPicker;

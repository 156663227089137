import {
  CapitalInvestment,
  Distribution,
  ReturnInfoCard,
} from '@/entities/return';
import FullWhiteTable from 'bundles/Shared/components/Table/variants/FullWhiteTable';
import {
  formatDate,
  formatDateToQuarterWithPrefix,
} from '@/shared/lib/formatting/dates';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { CurrencyFormatter, ModalHeaderWithSubtitle } from '@/stories';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { sumBy } from 'lodash-es';
import { distributionKindFormatter } from '@/entities/return/ui/formatters';
import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props {
  investmentEntityName: CapitalInvestment['investmentEntity']['name'];
  date: Distribution['date'];
  splitItems: Distribution['splitItems'];
  onClose: DialogProps['onClose'];
}

export const SplitTransactionListModal = ({
  investmentEntityName,
  date,
  splitItems,
  onClose,
}: Props) => {
  return (
    <SidePanel
      toggle={onClose}
      size="700"
      classes={{
        body: 'flex flex-col gap-tw-2',
      }}
      header={
        <ModalHeaderWithSubtitle
          title={investmentEntityName}
          subtitle="Investment Entity"
          order="subtitle-title"
        />
      }
    >
      <ReturnInfoCard>
        <ReturnInfoCard.Part label="Date">
          {formatDate(date as DateString, 'MMM DD, YYYY')}
        </ReturnInfoCard.Part>
        <ReturnInfoCard.Part label="Quarter">
          {formatDateToQuarterWithPrefix(date as DateString)}
        </ReturnInfoCard.Part>
        <ReturnInfoCard.Part label="Total Amount">
          <CurrencyFormatter
            value={sumBy(splitItems, (item) => item.amountCents)}
          />
        </ReturnInfoCard.Part>
      </ReturnInfoCard>

      <FullWhiteTable<Distribution>
        columns={[
          {
            dataField: 'kind',
            text: 'Types',
            formatter: distributionKindFormatter,
          },
          {
            dataField: 'amountCents',
            text: 'Amount',
            formatter: (value) => (
              <CurrencyFormatter value={value.row.amount} />
            ),
          },
        ]}
        items={splitItems.map((item) => ({
          kind: item.kind,
          amount: convertCentsToDollars(item.amountCents),
        }))}
      />
    </SidePanel>
  );
};

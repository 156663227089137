/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMemo } from 'react';
import type { IReallocation, IPageParams } from 'bundles/Construction/types';

export function useItems(items: IReallocation[], deps: [IPageParams]) {
  const [pageParams] = deps;

  return useMemo(() => {
    let newItems = [...items];

    if (pageParams.sortField !== 'updated_at') {
      const numberFields = [
        'id',
        'number',
        'requisitionNumber',
        'value',
        'createdAt',
        'changeOrderNumber',
      ];

      const comparator = (a: IReallocation, b: IReallocation) => {
        const aField = a[pageParams.sortField];
        const bField = b[pageParams.sortField];

        if (!aField) return 1;

        if (numberFields.includes(pageParams.sortField)) {
          return parseInt(bField, 10) - parseInt(aField, 10);
        }

        if (pageParams.sortField === 'sharedFiles') {
          return aField.length - bField.length;
        }

        if (aField > bField) return 1;

        if (aField === bField) return 0;

        return -1;
      };

      newItems.sort(comparator);

      if (pageParams.sortOrder === 'desc') {
        newItems = newItems.reverse();
      }
    }
    return newItems;
  }, [...deps, items]);
}

import { REPORT_STATUS_MAP } from '@/entities/core/assets/reports/config';
import { EntityStatus } from '@/shared/model/entityStatus';
import { BaseCard } from '@/shared/ui/BaseCard';
import { IconButton, Tooltip } from '@/stories';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { ComponentProps, ReactNode } from 'react';

export const REPORTS_CARDS_SKELETON_JSX = Array.from(
  { length: 3 },
  (_, idx) => <SkeletonBlock className="h-[238px] rounded-2xl" key={idx} />,
);

export const ReportCard = (props: {
  subtitle: string;
  label: string;
  status: EntityStatus;
  permissionLine: ReactNode;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  onClick?: VoidFunction;
  onDelete?: VoidFunction;
  isAllowedToManage?: boolean;
}) => {
  const status = REPORT_STATUS_MAP[props.status];
  const ReportIcon = status.iconComponent;

  return (
    <BaseCard
      iconSlot={<ReportIcon className="h-[48px] w-[48px]" />}
      imgWrapperProps={{
        onClick: props.onClick,
      }}
      tooltipProps={props.tooltipProps}
    >
      <div className="flex flex-col gap-tw-2 px-tw-2">
        <BaseCard.Status
          statusLabel={status.label}
          iconName={status.iconName}
          iconClassName={status.className}
        />
        <BaseCard.Title>{props.label}</BaseCard.Title>
        {props.isAllowedToManage && (
          <BaseCard.OverlayActions>
            <IconButton iconName="trash" onClick={props.onDelete} />
          </BaseCard.OverlayActions>
        )}
        <BaseCard.Subtitle>{props.subtitle}</BaseCard.Subtitle>
        {props.status === 'published' &&
          props.isAllowedToManage &&
          props.permissionLine}
      </div>
    </BaseCard>
  );
};

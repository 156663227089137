import { clone, groupBy } from 'lodash-es';
import {
  XYChartWidgetConfig,
  XYChartWidgetConfigKpi,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { WidgetDateGranularity } from 'bundles/Shared/entities/dashboard';
import {
  IExportingEvents,
  IExportingSettings,
} from '@amcharts/amcharts5/.internal/plugins/exporting/Exporting';
import { sanitizeFileName } from 'lib/uploadFiles';
import { DEFAULT_AMCHART_DATE_FORMATS } from '@/shared/lib/formatting/charts';
import { UnknownRecord } from 'type-fest/source/internal';
import {
  createMapBy,
  createMapByKey,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { XYChartSingleKpiWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/widget';
import {
  EagleEyeDashboardWidgetContext,
  ObjectDashboardWidgetContext,
} from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { XYSeries } from '@amcharts/amcharts5/xy';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { getColumnExcelFormat } from '@/shared/lib/formatting/excel';
import {
  GroupingObject,
  isChartCategorical,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import {
  resolveExportFileNamePrefix,
  resolveExportSheetAndFileName,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { DateWidgetStates } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import * as xlsx from './xslx';

export const buildDataFieldsFromSeries = (series: XYSeries[]) => {
  return Object.fromEntries(
    series.map((s) => {
      return [s.get('valueYField')!, s.get('name')!];
    }),
  );
};
export const DEFAULT_AMCHART_EXCEL_DATE_FIELD = 'dateFrom';
export const DEFAULT_AMCHART_EXCEL_DATE_LABEL = 'Date';
export const DEFAULT_AMCHART_EXCEL_OBJECT_NAME_FIELD = 'objectName';
export const DEFAULT_AMCHART_EXCEL_OBJECT_NAME_LABEL = 'Object Name';
const DEFAULT_SHEET_NAME = 'Data';

export const sanitizeCells = (sheet: xlsx.WorkSheet): string[] => {
  // result cells in excel sheet has extra ref key
  return Object.keys(sheet).filter((k) => k !== '!ref');
};
export const getColumnLetterFromCellKey = (cellKey: string) => {
  return cellKey.at(0);
};
export const getHeaderCellKey = (columnLetter: string) => {
  return `${columnLetter}1`;
};
export const filterColumn = (
  data: Record<string, unknown>,
  columnLabel: string,
) => {
  const dataClone = clone(data);
  const cellKey = Object.entries(data).find(([_, value]) => {
    return (value as { v: unknown }).v === columnLabel;
  })?.[0];
  if (!cellKey) {
    return data;
  }
  const columnLetter = getColumnLetterFromCellKey(cellKey);
  Object.keys(dataClone).forEach((key) => {
    if (columnLetter && key.includes(columnLetter)) {
      delete dataClone[key];
    }
  });
  return dataClone;
};
export const filterDateColumn = (data: Record<string, unknown>) => {
  return filterColumn(data, DEFAULT_AMCHART_EXCEL_DATE_LABEL);
};
export const filterObjectNameColumn = (data: Record<string, unknown>) => {
  return filterColumn(data, DEFAULT_AMCHART_EXCEL_OBJECT_NAME_LABEL);
};
export const getFirstSheetFromWorkbook = (workbook: xlsx.WorkBook) => {
  return workbook.Sheets.Data;
};
export const applyFormatToCell = (
  cell: UnknownRecord,
  format: ValueDisplayOptions,
) => {
  // eslint-disable-next-line no-param-reassign
  cell.z = getColumnExcelFormat(format);
};
const iterateCellKeys = (
  cellKeys: string[],
  callback: (cellKey: string, columnLetter: string) => void,
) => {
  let columnLetter = getColumnLetterFromCellKey(cellKeys[0]);
  cellKeys.forEach((cellKey) => {
    callback(cellKey, columnLetter!);
    const newColumnLetter = getColumnLetterFromCellKey(cellKey);
    if (columnLetter !== newColumnLetter) {
      columnLetter = newColumnLetter;
    }
  });
};
const formatCellsByKpiLabels = ({
  cellKeys,
  dataSheet,
  kpiMapByLabel,
}: {
  cellKeys: string[];
  dataSheet: xlsx.Sheet;
  kpiMapByLabel: Map<string, XYChartWidgetConfigKpi>;
}) => {
  iterateCellKeys(cellKeys, (cellKey, columnLetter) => {
    const kpiLabel = dataSheet[getHeaderCellKey(columnLetter)].v;
    const kpi = kpiMapByLabel.get(kpiLabel);
    // eslint-disable-next-line no-param-reassign
    applyFormatToCell(
      dataSheet[cellKey],
      kpi?.value_display_options ?? {
        type: 'number',
        precision: 2,
      },
    );
  });
};

const replaceSheetName = (workbook: xlsx.WorkBook, newSheetName: string) => {
  // eslint-disable-next-line no-param-reassign
  workbook.SheetNames[0] = newSheetName;
  const oldSheet = workbook.Sheets[DEFAULT_SHEET_NAME];
  // eslint-disable-next-line no-param-reassign
  workbook.Sheets[newSheetName] = oldSheet;
  // eslint-disable-next-line no-param-reassign
  delete workbook[DEFAULT_SHEET_NAME];
};

export const addExportToChart = ({
  widgetTitle,
  widgetId,
  data,
  granularity,
  dataFields,
  withoutDate,
  widgetConfig,
  state,
  context,
}: {
  dataFields: Record<string, string>;
  widgetTitle: string;
  widgetId: string;
  data: unknown[];
  withoutDate?: boolean;
  widgetConfig?: XYChartWidgetConfig;
  granularity?: WidgetDateGranularity;
  context: ObjectDashboardWidgetContext;
  state?: DateWidgetStates;
}) => {
  const { fileName, sheetName } = resolveExportSheetAndFileName({
    state,
    widgetTitle,
    fileName: resolveExportFileNamePrefix(context),
  });
  const exportingSettings: IExportingSettings = {
    filePrefix: sanitizeFileName(fileName),
    dataSource: data,
    dataFields,
    dataFieldsOrder: Object.keys(dataFields),
    numericFields: Object.keys(dataFields),
  };
  if (!withoutDate) {
    exportingSettings.dateFields = [DEFAULT_AMCHART_EXCEL_DATE_FIELD];
    exportingSettings.dataFields!.dateFrom = DEFAULT_AMCHART_EXCEL_DATE_LABEL;
    exportingSettings.dataFieldsOrder!.unshift(
      DEFAULT_AMCHART_EXCEL_DATE_FIELD,
    );
    exportingSettings.dateFormat =
      granularity && DEFAULT_AMCHART_DATE_FORMATS[granularity];
  }

  const onWorkbookReady = (event: IExportingEvents['workbookready']) => {
    if (widgetConfig == null) {
      return;
    }

    const dataSheet = getFirstSheetFromWorkbook(event.workbook);
    const cellKeys = sanitizeCells(
      filterDateColumn(dataSheet as UnknownRecord),
    ).toSorted();
    const kpiMapByLabel = createMapBy(widgetConfig.kpis, 'label');

    formatCellsByKpiLabels({
      cellKeys,
      dataSheet,
      kpiMapByLabel,
    });
    replaceSheetName(event.workbook, sheetName);
  };
  return { exportingSettings, onWorkbookReady };
};
export const addExportToXySingleKpiChart = ({
  state,
  context,
  widgetConfig,
  widgetTitle,
  widgetId,
  items,
  groupingObjects,
}: {
  state: XYChartSingleKpiWidgetState;
  widgetConfig: XYChartWidgetConfig;
  widgetTitle: string;
  widgetId: string;
  items: UnknownRecord[];
  groupingObjects: GroupingObject[];
  context: EagleEyeDashboardWidgetContext;
}) => {
  const isCategorical = isChartCategorical(widgetConfig.am_chart_config);
  const { kpis } = widgetConfig;
  const kpiMap = createMapByKey(kpis);
  const selectedKpi =
    isCategorical || state.kpi == null
      ? null
      : kpiMap.get(state.kpi.toString());

  let dataSource: UnknownRecord[] = [];

  if (isCategorical) {
    dataSource = Object.entries(groupBy(items, 'objectName')).map(
      ([objectName, values]) => {
        const objectEntries = Object.fromEntries(
          values.map((value) => [value.kpiKey, value.kpiValue]),
        );
        return {
          objectName,
          ...objectEntries,
        };
      },
    );
  } else {
    dataSource = Object.entries(
      groupBy(items, DEFAULT_AMCHART_EXCEL_DATE_FIELD),
    ).map(([dateFrom, values]) => {
      const objectEntries = Object.fromEntries(
        values.map((value) => [value.objectName, value.kpiValue]),
      );
      return {
        dateFrom: Number(dateFrom),
        ...objectEntries,
      };
    });
  }

  const { fileName, sheetName } = resolveExportSheetAndFileName({
    state,
    widgetTitle,
  });
  let exportingSettings: IExportingSettings = {
    filePrefix: sanitizeFileName(fileName),
    dataSource,
  };

  if (!isCategorical) {
    const objectNames = groupingObjects.map((object) =>
      object.type === 'asset'
        ? context.assets.find((item) => item.id === object.id)!.name
        : context.segments.find((item) => item.id === object.id)!.title,
    );
    exportingSettings = {
      ...exportingSettings,
      dateFields: [DEFAULT_AMCHART_EXCEL_DATE_FIELD],
      dateFormat: DEFAULT_AMCHART_DATE_FORMATS[state.granularity],
      numericFields: objectNames,
      dataFields: {
        [DEFAULT_AMCHART_EXCEL_DATE_FIELD]: DEFAULT_AMCHART_EXCEL_DATE_LABEL,
        ...Object.fromEntries(
          objectNames.map((seriesName) => [seriesName, seriesName]),
        ),
      },
    };
  } else {
    const kpiKeys = Array.from(kpiMap.keys());
    exportingSettings = {
      ...exportingSettings,
      numericFields: kpiKeys,
      dataFieldsOrder: ['objectName', ...kpiKeys],
      dataFields: {
        objectName: 'Object Name',
        ...Object.fromEntries(
          kpis.map((kpi) => [kpi.key.toString(), kpi.label]),
        ),
      },
    };
  }

  const onWorkbookReady = (event: IExportingEvents['workbookready']) => {
    if (!isCategorical) {
      if (selectedKpi?.value_display_options == null) {
        return;
      }
      const dataSheet = getFirstSheetFromWorkbook(event.workbook);
      const cellKeys = sanitizeCells(dataSheet);
      cellKeys.forEach((cellKey) => {
        // eslint-disable-next-line no-param-reassign
        applyFormatToCell(
          dataSheet[cellKey],
          selectedKpi?.value_display_options,
        );
      });
    } else {
      const dataSheet = getFirstSheetFromWorkbook(event.workbook);
      const cellKeys = sanitizeCells(
        filterObjectNameColumn(dataSheet as UnknownRecord),
      ).toSorted();
      const kpiMapByLabel = createMapBy(kpis, 'label');

      formatCellsByKpiLabels({
        cellKeys,
        dataSheet,
        kpiMapByLabel,
      });
    }

    replaceSheetName(event.workbook, sheetName);
  };

  // eslint-disable-next-line no-param-reassign
  return {
    exportingSettings,
    onWorkbookReady,
  };
};

import { IJobCostCode } from 'types/JobCostCode';

const CATEGORY_SEPARATOR = '-';
// todo move to enum and replace all over the project
// https://linear.app/symmetre/issue/FE-1424/[chore]-jccsovc-enum
const JCC_PREFIX = 'jcc';
const SOVC_PREFIX = 'sovc';
type TCategoryType = typeof JCC_PREFIX | typeof SOVC_PREFIX;

export const transformCategorizedIdToKey = (
  id: number,
  prefix: typeof JCC_PREFIX | typeof SOVC_PREFIX,
): string => `${prefix}${CATEGORY_SEPARATOR}${id}`;

export const transformJccIdToKey = (id: number): string =>
  transformCategorizedIdToKey(id, JCC_PREFIX);
export const getJccTypeFromKey = (key: string): TCategoryType =>
  key.split(CATEGORY_SEPARATOR).at(0)! as TCategoryType;

export const transformJCCKeyToId = (id: number | string): number => {
  let idJCC = id;
  if (typeof idJCC === 'string') {
    idJCC = idJCC.split(CATEGORY_SEPARATOR).at(-1)!;
  }
  return Number(idJCC);
};

export const transformIdToJCC = (
  id: number | string,
  JCCForReallocations: IJobCostCode[],
) => {
  const idJCC = transformJCCKeyToId(id);
  const jcc = JCCForReallocations.find((item) => item.id === idJCC)!;
  return `${jcc.code} - ${jcc.name}`;
};

export const generateHCC = ({
  code,
  name,
}: {
  code: string;
  name: string;
}): string => `${code} ... ${name}`;

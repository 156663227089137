import {
  IDevelopmentBudget,
  IDevelopmentBudgetOnIndex,
  IDevelopmentBudgetOnShow,
} from 'types/DevelopmentBudget';
import http from 'lib/http';
import { stringify } from 'qs';
import { IReconcileDevelopmentCategoryTreeNode } from 'types/ReconcileDevelopmentCategory';
import {
  IDevelopmentBudgetData,
  IDevelopmentBudgetDataJccRow,
} from 'bundles/REconcile/types/BudgetRows';

export const BUDGETS_LOADED = 'budgets/budgetsLoaded';
export const BUDGETS_LOADING = 'budgets/budgetsLoading';
export const BUDGETS_CREATE = 'budgets/createBudget';

export const budgetsLoaded = (payload: {
  budgets: IDevelopmentBudgetOnIndex[];
  meta: {
    perPage: number;
    totalSize: number;
  };
}) => ({
  type: BUDGETS_LOADED,
  payload,
});

export const budgetsLoading = () => ({
  type: BUDGETS_LOADING,
});

export const createBudget = (budget: IDevelopmentBudgetOnShow) => ({
  type: BUDGETS_CREATE,
  payload: budget,
});

export interface IFetchArgs {
  page?: number;
  per_page?: number;
  query?: string;
  sort_field?: Pick<IDevelopmentBudget, 'id'>;
  sort_order?: 'asc' | 'desc';
}

interface IFetchResponse {
  items: IDevelopmentBudgetOnIndex[];
  meta: {
    perPage: number;
    totalSize: number;
  };
}

export function fetchDevelopmentBudgets(data: IFetchArgs) {
  return async (dispath, getState) => {
    dispath(budgetsLoading());

    const res = await http.get(
      `/development_budgets${stringify(data, { addQueryPrefix: true })}`,
    );
    const responseData: IFetchResponse = await res.json();

    dispath(
      budgetsLoaded({
        budgets: responseData.items,
        meta: responseData.meta,
      }),
    );
  };
}

interface ICreateArgs {
  legal_entity_id: number;
}

export function createDevelopmentBudget(data: ICreateArgs) {
  return async (dispath, getState) => {
    dispath(budgetsLoading());

    const res = await http.post('/development_budgets', data);
    const responseData: IDevelopmentBudgetOnShow = await res.json();

    createBudget(responseData);
  };
}

interface IShowResponse {
  item: IDevelopmentBudgetOnShow;
  meta: {
    categoriesTree: IReconcileDevelopmentCategoryTreeNode[];
    data: IDevelopmentBudgetData;
  };
}

export async function fetchDevelopmentBudget(id: string) {
  const res = await http.get(`/development_budgets/${id}`);
  const responseData = await res.json();

  return responseData as IShowResponse;
}

// PUT api/development_budgets/{developmentBudgetId}/line_items
// Request
interface ILineItemData {
  id: number;
  value: number | null;
}
interface IRequest {
  column_key: string;
  job_cost_code: ILineItemData;
  schedule_of_value_codes: Array<ILineItemData> | null;
}

export async function updateDevelopmentBudgetsLineItems(
  budgetId: string,
  request: IRequest,
) {
  const res = await http.put(
    `/development_budgets/${budgetId}/line_items`,
    request,
  );
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(responseData.errors);
    return undefined;
  }

  return responseData as IDevelopmentBudgetDataJccRow;
}

export async function publishDevelopmentBudget(budgetId: string) {
  const res = await http.post(`/development_budgets/${budgetId}/publish`, {});
  const responseData = await res.json();

  if (responseData.errors) {
    toastr.error(responseData.errors);
    return false;
  }

  return true;
}

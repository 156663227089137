import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { MenuItem } from 'bundles/InvestmentObjects/components/menu/InvestmentObjectMenu';
import { currentUserAllowedTo, TProductNames } from 'lib/permissions';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import ReconcileMenuItemGroup from 'bundles/InvestmentObjects/components/menu/ReconcileMenu/ReconcileMenuItemGroup';
import styles from 'bundles/InvestmentObjects/components/menu/ReconcileMenu/ReconcileMenu.module.scss';

function DevelopmentLegalEntityMenu({
  legalEntityCode,
}: {
  legalEntityCode: string;
}) {
  const { getUrl } = useNavigation();
  const canManageCM = currentUserAllowedTo(
    'manage',
    TProductNames.CHANGE_MANAGEMENT,
  );
  const menuItems: {
    title: string;
    children: MenuItem[];
  }[] = [
    {
      title: 'Overview',
      children: [
        {
          label: 'Snapshot',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SNAPSHOT', {
            legalEntityCode,
          }),
          isRoot: true,
          isHidden: !currentUserAllowedTo(
            'view',
            TProductNames.CHANGE_MANAGEMENT,
          ),
        },
        {
          label: 'Summary',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SUMMARY', {
            legalEntityCode,
          }),
          isRoot: true,
          isHidden: !canManageCM,
        },
      ],
    },
    {
      title: 'Change Management',
      children: [
        {
          label: 'Change Events',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENTS', {
            legalEntityCode,
          }),
          isHidden: !canManageCM,
        },
        {
          label: 'Change Orders',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDERS', {
            legalEntityCode,
          }),
          isHidden: !canManageCM,
        },
        {
          label: 'Reallocations',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATIONS', {
            legalEntityCode,
          }),
          isHidden: !canManageCM,
        },
      ],
    },
    {
      title: 'Draw Package',
      children: [
        {
          label: 'Invoices',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES', {
            legalEntityCode,
          }),
          isHidden: !canManageCM,
        },
        {
          label: 'PayApps',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAY_APPS', {
            legalEntityCode,
          }),
          isHidden: !canManageCM,
        },
        {
          label: 'Requisitions',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS', {
            legalEntityCode,
          }),
          isHidden: !canManageCM,
        },
        {
          label: 'Draw Requests',
          to: getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS', {
            legalEntityCode,
          }),
          isHidden: !canManageCM,
        },
      ],
    },
  ];

  return (
    <div className={styles.reconcileMenu}>
      {menuItems.map((menuItem) => (
        <ReconcileMenuItemGroup
          key={menuItem.title}
          items={menuItem.children.filter((item) => !item.isHidden)}
          title={menuItem.title}
        />
      ))}
      <div className="flex-1">
        <div
          className={cn(
            styles.reconcileMenuTitle,
            'dark label-regular select-none',
          )}
        >
          Empty
        </div>
        <div
          className={cn(
            styles.reconcileMenuItem_empty,
            'light-5 inline-regular',
          )}
        >
          Empty
        </div>
      </div>
    </div>
  );
}

export default DevelopmentLegalEntityMenu;

import React, { FC, useState } from 'react';
import { CollapsedCard } from 'stories/index';
import { formatUnixDate, formatUnixDateFromNow } from '@/shared/lib/formatting/dates';
import { sum } from 'lodash-es';
import SelectCOItemSubtitle from './SelectCOItemSubtitle';
import { IChangeOrder, IChangeOrderEvent } from 'bundles/Construction/types';

interface ISelectCOItemProps {
  id: number;
  title: string;
  description: string;
  category: string;
  displayNumber: string;
  formData: IChangeOrder;
  setFormData: any;
  COE: IChangeOrderEvent[];
  selectable: boolean;
  approvedDate: string;
  receivedDate: string;
  value: string;
}

const SelectCOItem: FC<ISelectCOItemProps> = ({
  id,
  title,
  displayNumber,
  category,
  description,
  value,
  receivedDate,
  formData,
  setFormData,
  COE,
  selectable,
  approvedDate,
}) => {
  const [activated, setActivated] = useState(false);
  const checkHandle = () => {
    let newValue = formData.changeOrderEvents;
    if (formData?.changeOrderEvents?.length) {
      const flagCheck = newValue.find((i) => i.id === id);
      if (flagCheck) {
        newValue = newValue.filter((v) => v.id !== id);
      } else {
        newValue = [...newValue, COE.find((v) => v.id === id)];
      }
    } else {
      newValue = [COE.find((v) => v.id === id)];
    }
    let amount = 0;
    if (newValue?.length) {
      amount = sum(newValue.map((i) => Number(i.value)));
    }
    setFormData({
      ...formData,
      changeOrderEvents: newValue,
      value: amount,
    });
  };

  return (
    <div className="select-change-order">
      <CollapsedCard
        checked={formData?.changeOrderEvents?.find((v) => v.id === id)}
        checkHandle={checkHandle}
        activated={activated}
        activatedHandle={() => setActivated(!activated)}
        approvalStatus={{
          iconName: 'acceptAlt',
          text: formatUnixDateFromNow(approvedDate),
          tooltipText: `Approved: ${formatUnixDate(
            approvedDate,
            'MM-DD-YYYY',
          )}`,
        }}
        selectable={selectable}
        title={title}
        content={description}
        subtitle={
          <SelectCOItemSubtitle
            number={displayNumber}
            category={category}
            value={value}
            receivedDate={receivedDate}
          />
        }
      />
    </div>
  );
};

export default SelectCOItem;

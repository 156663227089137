import { useDeleteApiReconcileOperationalBudgetsByIdMutation } from "@/bundles/REconcile/components/operational/api/reconcileOperationalEnhancedApi";
import { useModal } from "@/shared/lib/hooks/useModal";

export const useDeleteApiReconcileOperationalBudgetsById = () => {
  const [deleteBudget, options] = useDeleteApiReconcileOperationalBudgetsByIdMutation();
  const { confirm } = useModal()

  const handleDelete = async (budgetId: number) => {
    const res = await confirm()

    if (res === false) return;

    return await deleteBudget({
      id: budgetId,
    })
  }

  return [handleDelete, options] as const
}

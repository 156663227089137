import { sortBy } from 'lodash-es';
import type { IUserRole, IProduct, IProductPermissionKeys } from 'types/User';

type KeyType = IProductPermissionKeys;
type CaseType = {
  canView: boolean;
  canManage: boolean;
  canConfigure: boolean;
};

interface IPossibleCaseValue {
  disabled: CaseType;
  enabled: CaseType;
}

const NO_PERMISSIONS_CASE = {
  canView: false,
  canManage: false,
  canConfigure: false,
};
const ONLY_VIEW_PERMISSION_CASE = {
  canView: true,
  canManage: false,
  canConfigure: false,
};
const VIEW_AND_MANAGE_PERMISSIONS_CASE = {
  canView: true,
  canManage: true,
  canConfigure: false,
};
const ALL_PERMISSIONS_CASE = {
  canView: true,
  canManage: true,
  canConfigure: true,
};

export const POSSIBLE_PERMISSIONS_CASES: Record<KeyType, IPossibleCaseValue> = {
  canView: {
    enabled: NO_PERMISSIONS_CASE,
    disabled: ONLY_VIEW_PERMISSION_CASE,
  },
  canManage: {
    enabled: ONLY_VIEW_PERMISSION_CASE,
    disabled: VIEW_AND_MANAGE_PERMISSIONS_CASE,
  },
  canConfigure: {
    enabled: VIEW_AND_MANAGE_PERMISSIONS_CASE,
    disabled: ALL_PERMISSIONS_CASE,
  },
};

export function genUpdatedRole(
  prevRole: IUserRole,
  key: KeyType,
  productId: IProduct['id'],
): IUserRole {
  const prevProductList = prevRole.products ?? [];
  const prevProduct = prevProductList?.find((pr) => pr.id === productId);

  const updatedPermissions = prevProduct[key]
    ? POSSIBLE_PERMISSIONS_CASES[key].enabled
    : POSSIBLE_PERMISSIONS_CASES[key].disabled;

  const updatedProduct = { ...prevProduct, ...updatedPermissions };

  const products = sortBy(
    [...prevProductList.filter((pr) => pr.id !== productId), updatedProduct],
    ['name'],
  );

  return {
    ...prevRole,
    products,
  };
}

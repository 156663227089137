import React, { ComponentProps } from 'react';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './UserFilterButton.module.scss';
import FilterButton from '../filterButton/FilterButton';

type Props = Omit<
  ComponentProps<typeof FilterButton>,
  'iconName' | 'iconPosition' | 'size'
>;

function UserFilterButton({ className, children, ...props }: Props) {
  return (
    <FilterButton
      className={cn('inline-semibold', styles.button, className)}
      classes={{
        container: styles.container,
        filtered: styles.button_filtered,
        clicked: styles.button_clicked,
        close: styles.close,
        containerClicked: styles.container_clicked,
      }}
      {...props}
    >
      <Icon className={cn('mr-s', styles.leftIcon)} iconName="role" />
      <span className={cn(props.filtered && 'mr-xs', styles.label)}>
        Show{props.filtered && 'n'} as{props.filtered && ':'}&nbsp;
      </span>
      {children}
      <Icon className={cn(styles.rightIcon, 'ml-s')} iconName="arrowBottom" />
    </FilterButton>
  );
}

export default UserFilterButton;

import {
  currentUserAllowedTo,
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
} from '@/lib/permissions';
import { IAsset } from '@/types/Asset';

export const currentUserAllowedToManageThisAssetAndReport = (
  assetId: IAsset['id'],
) =>
  currentUserIsSuperAdmin() ||
  currentUserIsSreAdmin() ||
  currentUserAllowedTo('manage', 'report', 'Asset', assetId);

export const currentUserAllowedToSeeReportsPageAndThisAsset = (
  assetId: IAsset['id'],
) =>
  currentUserIsSuperAdmin() ||
  currentUserIsSreAdmin() ||
  currentUserAllowedTo('manage', 'report', 'Asset', assetId);

import { IQueryParams } from 'types/Pagination';
import { ISortParamsField } from 'types/Sort';
import { createCrudApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';

type TCrudPathParameters = {
  legalEntityId: string;
};

export interface IReconcileBudgetsSettingsMarkup {
  id: number;
  code: string;
  name: string;
  includedInFee: boolean;
  percent: string;
}

export interface IFetchMarkupsForReconcileBudgetSettingsRequest
  extends IQueryParams,
    ISortParamsField<'id' | 'name' | 'code'> {
  filter_by_included?: boolean;
  filter_by_not_included?: boolean;
}

export interface IReconcileSettingsMarkupUpdate {
  code: string;
  name: string;
  included_in_fee: boolean;
  percent: number;
}

export const {
  getEntities: fetchMarkupsForReconcileSettings,
  createEntity: createMarkupInReconcileSettings,
  updateEntity: updateMarkupInReconcileSettings,
  deleteEntity: deleteMarkupInReconcileSettings,
  entityName: MARKUP_ENTITY_NAME,
} = createCrudApi<
  TCrudPathParameters,
  IFetchMarkupsForReconcileBudgetSettingsRequest,
  IReconcileBudgetsSettingsMarkup,
  IReconcileSettingsMarkupUpdate
>('/settings/reconcile/legal_entities/:legalEntityId/markups', 'Markup');

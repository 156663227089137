import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams/PageParamsContext';
import React from 'react';
import { IPaginationParams } from 'types/Pagination';

type Props = Omit<
  React.ComponentProps<typeof TablePagination>,
  'currentPage' | 'sizePerPage'
> & {
  sizePerPage?: number;
};

export function PageParamsPagination(props: Props) {
  const { pageParams, setPageParams } =
    usePageParamsContext<IPaginationParams>();

  return (
    <TablePagination
      {...props}
      setCurrentPage={(page) => setPageParams({ ...pageParams, page })}
      currentPage={pageParams.page ?? 1}
      sizePerPage={
        pageParams.per_page ?? props?.sizePerPage ?? pageParams?.perPage ?? 0
      } // Standardize on "perPage"!
      onSizePerPageChange={(perPage) =>
        setPageParams({ ...pageParams, perPage })
      }
    />
  );
}

export default PageParamsPagination;

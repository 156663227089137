import React from 'react';
import { Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';
import styles from './CategoryPath.module.scss';

interface Props {
  path: string[];
}

function CategoryPathBadge({ path }: Props) {
  return (
    <div className={cn(styles.container, 'secondary-regular')}>
      <Icon iconName="category" className="light-60 mr-xxs" />
      {path.map((pathItem, currentIndex) => (
        <>
          {pathItem}
          {currentIndex + 1 !== path.length && (
            <span className={styles.separator} />
          )}
        </>
      ))}
    </div>
  );
}

export default CategoryPathBadge;

import React, { CSSProperties } from 'react';
import {
  getFormattedValueParts,
  getMergedClasses,
  getMergedStyles,
} from 'stories/ValueFormatters/utils';
import {
  OUTPUT_DEFAULT_CLASSES,
  OutputFormatter,
  OutputFormatterClassKeys,
} from 'stories/ValueFormatters/OutputFormatter';
import Fractions from 'stories/ValueFormatters/components/Fractions';
import {
  GetClassesObject,
  GetStylesObject,
  NumberFormatterProps,
} from 'stories/ValueFormatters/types';
import SignWrapper from 'stories/ValueFormatters/components/SignWrapper';

type SqftNumberFormatterClasses = GetClassesObject<
  OutputFormatterClassKeys | 'fractions'
>;
type SqftNumberFormatterStyles = GetStylesObject<
  OutputFormatterClassKeys | 'fractions' | 'brackets'
>;

type Props = NumberFormatterProps<
  SqftNumberFormatterClasses,
  SqftNumberFormatterStyles
>;

const DEFAULT_CLASSES = {
  ...OUTPUT_DEFAULT_CLASSES,
  endSymbol: 'text-neutral-500',
  fractions: 'text-neutral-850',
} as const satisfies SqftNumberFormatterClasses;

function SqftNumberFormatter({
  value,
  hideFractions,
  fallbackValue = 0,
  toLocalStringOptions,
  ...props
}: Props) {
  if (value === null || value === undefined || isNaN(value))
    return <span>{fallbackValue}</span>;

  const formattedValue = Math.abs(value).toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...toLocalStringOptions,
  });

  const { displayValue } = getFormattedValueParts(formattedValue);

  const classes = getMergedClasses({
    classes: props.classes,
    allParts: props.classes?.allParts,
    defaultClasses: DEFAULT_CLASSES,
    value,
  });

  const styles = getMergedStyles({
    ...(props.styles ?? {}),
    // @ts-expect-error
    partNames: [
      'brackets',
      'endSymbol',
      'fractions',
      'partNames',
      'startSymbol',
      'value',
      'wrapper',
    ] as const,
  });

  return (
    <SignWrapper
      negative={value < 0}
      className={classes?.brackets}
      styles={styles as { brackets: CSSProperties }}
      variant="brackets"
    >
      <OutputFormatter
        classes={{
          ...classes,
          value: value === 0 ? 'text-neutral-500' : classes?.value,
        }}
        endSymbol="ft²"
        styles={styles}
      >
        {displayValue}
        {!hideFractions && value !== 0 && (
          <Fractions
            formattedValue={formattedValue}
            className={classes.fractions}
            style={styles.fractions}
          />
        )}
      </OutputFormatter>
    </SignWrapper>
  );
}

export default SqftNumberFormatter;

import React, { FC } from 'react';
import {
  OUTPUT_DEFAULT_CLASSES,
  OutputFormatter,
  OutputFormatterClassKeys,
} from './OutputFormatter';
import Fractions from 'stories/ValueFormatters/components/Fractions';
import {
  getFormattedValueParts,
  getMergedClasses,
  getMergedStyles,
} from 'stories/ValueFormatters/utils';
import {
  GetClassesObject,
  GetStylesObject,
  NumberFormatterProps,
} from 'stories/ValueFormatters/types';

export type MetricNumberFormatterClasses = GetClassesObject<
  OutputFormatterClassKeys | 'fractions'
>;
export type MetricNumberFormatterStyles = GetStylesObject<
  OutputFormatterClassKeys | 'fractions' | 'brackets'
>;

type MetricNumberFormatterProps = NumberFormatterProps<
  MetricNumberFormatterClasses,
  MetricNumberFormatterStyles
>;

const DEFAULT_CLASSES = {
  ...OUTPUT_DEFAULT_CLASSES,
  fractions: 'text-neutral-850',
} as const satisfies MetricNumberFormatterClasses;

export const MetricNumberFormatter: FC<MetricNumberFormatterProps> = ({
  value,
  hideFractions,
  toLocalStringOptions,
  fallbackValue = 0,
  ...props
}) => {
  if (value === null || value === undefined || isNaN(value))
    return <span>{fallbackValue}</span>;

  const formattedValue = Math.abs(value).toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...toLocalStringOptions,
  });

  const { displayValue } = getFormattedValueParts(formattedValue);

  const classes = getMergedClasses({
    classes: props.classes,
    allParts: props.classes?.allParts,
    defaultClasses: DEFAULT_CLASSES,
    value,
  });

  const styles = getMergedStyles({
    ...(props.styles ?? {}),
    // @ts-expect-error
    partNames: [
      'brackets',
      'endSymbol',
      'fractions',
      'partNames',
      'startSymbol',
      'value',
      'wrapper',
    ] as const,
  });

  return (
    <OutputFormatter
      classes={{
        ...classes,
        value: value === 0 ? 'text-neutral-500' : classes?.value,
      }}
      endSymbol="x"
      styles={styles}
    >
      {displayValue}
      {!hideFractions && value !== 0 && (
        <Fractions
          formattedValue={formattedValue}
          className={classes.fractions}
          style={styles.fractions}
        />
      )}
    </OutputFormatter>
  );
};

import React, {
  ChangeEvent,
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AnimationLoader, LinkButton, Modal, SearchInput } from 'stories';
import { mapItemToOption } from '@/shared/lib/listHelpers';
import {
  prepareSelectedFilters,
  resetFilter,
} from 'bundles/Shared/components/Table/filters/helpers';
import SetFilter from 'bundles/REconcile/components/operational/table/SetFilter';
import { IUser, IUserRole } from 'types/User';
import { fetchPermissionModalSubjectables } from 'bundles/Shared/actions/permission_modal';
import { IUserTag } from 'types/IUserTag';
import RolesFilter from 'bundles/UserManagement/components/table/filters/RolesFilter';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { IColumn, ISortSettings } from 'bundles/Shared/components/Table/types';
import SharedWithTable from './SharedWithTable';
import TableAppliedFilters from './Table/filters/TableAppliedFilters';
import {
  fullNameColumnContent,
  roleColumnContent,
  tagsColumnContent,
} from './SharedWithTableColumns';
import { filterSortUsers } from './SharedWithModalUtils';

interface Props extends Pick<ComponentProps<typeof Modal>, 'toggle'> {
  allUsers: IUser[];
  hideActions?: boolean;
  setModalOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  showViewsColumn?: boolean;
  modalProps?: Partial<ComponentProps<typeof Modal>>;
}

export interface SharedWithTableSettings extends ISortSettings<IUser> {
  filters: {
    tags: IUserTag[];
    roles: IUserRole[];
  };
}

const tableHeaderStyle = { width: '25%' };

export function SharedWithModal({
  toggle,
  allUsers,
  hideActions = false,
  setModalOpened,
  modalProps,
}: Props) {
  const [allTags, setAllTags] = useState<IUserTag[]>([]);
  const [allRoles, setAllRoles] = useState<IUserRole[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [sortSettings, setSortSettings] = useState<SharedWithTableSettings>({
    filters: {
      tags: [],
      roles: [],
    },
  });
  const selectedFilters = sortSettings.filters;

  const columns = useMemo<IColumn<IUser>[]>(
    () => [
      {
        dataField: 'fullName',
        headerStyle: tableHeaderStyle,
        text: 'Name',
        formatter: ({ row }) => fullNameColumnContent(row),
        sortable: true,
      },
      {
        dataField: 'role.name',
        headerStyle: tableHeaderStyle,
        filter: (settings, setSettings) => (
          <RolesFilter
            roles={allRoles}
            settings={settings}
            setSettings={setSettings}
          />
        ),
        text: 'Role',
        formatter: ({ row }) => roleColumnContent(row),
        sortable: true,
      },
      {
        dataField: 'tags',
        text: 'Tags',
        headerStyle: tableHeaderStyle,
        formatter: ({ row }) => tagsColumnContent(row),
        quickFilter: (
          <SetFilter
            placement="bottom-start"
            appendTo={() => document.body}
            maxHeight="200px"
            items={allTags.map((item) => mapItemToOption(item, 'name'))}
            label="Tags"
            value={(selectedFilters.tags ?? [])
              .map((settingsTag) =>
                allTags.find((tag) => tag.id === settingsTag.id),
              )
              .map((item) => item && mapItemToOption(item, 'name'))}
            onChange={(items) =>
              setSelectedFilters({
                tags: items,
              })
            }
          />
        ),
      },
    ],
    [sortSettings, allTags],
  );

  const filteredSortedUsers = useMemo<IUser[]>(
    () => filterSortUsers(allUsers, searchValue, sortSettings),
    [sortSettings, debouncedSearchValue, allUsers],
  );
  const setSortSettingCallback = useCallback(
    (settings) => setSortSettings(settings),
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    fetchPermissionModalSubjectables()
      .then((result) => {
        setAllRoles(
          result.userRoles.filter((role) => role.category !== 'symmetre'),
        );
        setAllTags(result?.userTags || result?.tags);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const setSelectedFilters = (filters: SharedWithTableSettings) =>
    setSortSettings({
      ...sortSettings,
      filters: {
        ...selectedFilters,
        ...filters,
      },
    });

  const localResetFilter = (key, id) =>
    resetFilter(setSelectedFilters, selectedFilters, key, id);
  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  return (
    <Modal
      header={
        <div className="flex items-center justify-between pr-l">
          <h5 className="header6-bold">Shared With</h5>
          <div className="flex items-center">
            {!hideActions && (
              <div className="flex items-center">
                <i className="sre-icon-edit mr-[0.5rem]" />
                <LinkButton
                  fontWeight="bold"
                  onClick={() => setModalOpened?.(true)}
                >
                  Edit permissions
                </LinkButton>
              </div>
            )}
          </div>
        </div>
      }
      toggle={toggle}
      size="lg"
      classes={{ body: 'bg-light relative', headerContent: 'flex-grow' }}
      bodyPadding="var(--spacing-m) var(--spacing-l) var(--spacing-xl)"
      contentClassName="modal-content-h-700"
      {...modalProps}
    >
      <div>
        {isLoading && <AnimationLoader />}
        {!isLoading && (
          <>
            <SearchInput
              value={searchValue}
              onChange={onSearch}
              placeholder="Search by name"
              resetValue={() => setSearchValue('')}
            />
            <TableAppliedFilters
              className="mt-tw-2 flex flex-col gap-tw-2"
              filters={prepareSelectedFilters(selectedFilters)}
              resetFilter={localResetFilter}
            />
            <SharedWithTable
              allUsers={filteredSortedUsers}
              allUsersAmount={allUsers.length}
              settings={sortSettings}
              setSettings={setSortSettingCallback}
              tags={allTags}
              roles={allRoles}
              columns={columns}
            />
          </>
        )}
      </div>
    </Modal>
  );
}

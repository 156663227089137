import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from './commentsPanel.module.scss';

interface Props {
  header: React.ReactNode;
  subPanel?: React.ReactNode;
  comments: React.ReactNode;
  editor?: React.ReactNode;
  classes?: {
    comments?: string;
  };
}

export const CommentsPanel = ({
  header,
  subPanel,
  comments,
  editor,
  classes,
}: Props) => (
  <div className={styles.panel}>
    <div>
      <div className={styles.header}>{header}</div>
      <hr className="m-0" />
      {subPanel && (
        <div className="px-s pt-s">
          <div className="flex justify-between px-s">{subPanel}</div>
        </div>
      )}
    </div>
    <div className={cn(styles.comments, classes?.comments)}>{comments}</div>
    {editor && <div className={cn(styles.editor)}>{editor}</div>}
  </div>
);

export default CommentsPanel;

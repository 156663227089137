import { RouteComponentProps, Router } from '@reach/router';
import { IFolder } from 'bundles/Assets/components/Folders/AlbumsList';
import { useGetObjectFoldersQuery } from 'bundles/Assets/components/Media/api/objectFoldersApi';
import BulkActionsPanel from 'bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import { useModal } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import { FC, useContext, useState } from 'react';
import EntityContext from '../../Shared/EntityContext';
import { plainDeleteFolders } from '../actions/folders';
import Albums from './Folders/Albums';
import Album from './SharedFiles/Album';
import SharedFileTreeItem from './SharedFiles/SharedFileTreeItem';

export const Media: FC<RouteComponentProps> = () => {
  const [selectedFolders, setSelectedFolders] = useState<IFolder[]>([]);
  const [selectedMedia, setSelectedMedia] = useState([]);

  const investmentEntity = useContext(EntityContext)!;

  const { data: foldersData, refetch } = useGetObjectFoldersQuery({
    entityId: investmentEntity.entity.id,
    entityType: investmentEntity.type,
    folderType: 'media',
  });

  const { confirm } = useModal();
  const entityId = investmentEntity.entity.id;

  const onFolderDelete = async (item: IFolder | IFolder['id'][]) => {
    const items = selectedFolders.length > 0 ? [...selectedFolders] : [item];
    const nonEmptyAlbums = items.filter(
      (i) => i.sharedFilesCount > 0 || i.childrenCount > 0,
    );

    const result = await confirm({
      title: `Remove ${pluralize('album', items.length)}`,
      subtitle:
        (nonEmptyAlbums.length > 0 && (
          <div className="text-left">
            <p>
              {`The following ${pluralize(
                'album',
                nonEmptyAlbums.length,
              )} ${pluralize('has', nonEmptyAlbums.length)} items:`}
            </p>
            <div className="mt-tw-4">
              {nonEmptyAlbums.map((item) => (
                <div className="mt-tw-2" key={item.id}>
                  <SharedFileTreeItem folder={item} />
                </div>
              ))}
            </div>
            <p className="mt-tw-4">
              {`Do you want to remove ${pluralize(
                'this',
                nonEmptyAlbums.length,
              )} ${pluralize('album', nonEmptyAlbums.length)}?`}
            </p>
          </div>
        )) ||
        (nonEmptyAlbums.length === 0 && <p> Are you sure? </p>),
    });

    if (!result) return;

    await plainDeleteFolders({ ids: items.map((i) => i.id) });
    refetch();
    setSelectedFolders([]);
  };

  return (
    <section className="min-h-full">
      <Router primary={false} className="mt-tw-6 min-h-full">
        <Albums
          path="albums/*"
          folders={foldersData}
          foldersLoaded={foldersData}
          fetchAlbums={refetch}
          onDelete={onFolderDelete}
          selectedItems={selectedFolders}
          setSelectedItems={setSelectedFolders}
        />
        <Album
          path="albums/:id"
          folders={foldersData}
          albumAssetId={entityId}
          fetchAlbums={refetch}
          onFolderDelete={onFolderDelete}
          selectedFolders={selectedFolders}
          setSelectedFolders={setSelectedFolders}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
        />
      </Router>

      {selectedFolders.length > 0 && (
        <BulkActionsPanel
          selectedRows={selectedFolders}
          setSelectedRows={setSelectedFolders}
          actions={[
            {
              title: 'Remove',
              icon: 'trash',
              handleClick: () =>
                onFolderDelete(selectedFolders.map((item) => item.id)),
            },
          ]}
        />
      )}
    </section>
  );
};

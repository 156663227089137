import React from 'react';
import InputNumber, {
  InputNumberProps,
} from 'bundles/Shared/components/GroupForm/FormItems/InputNumber';

type Props = Omit<InputNumberProps, 'onValueChange' | 'leftIcon' | 'onBlur'> & {
  onValueChange: (value: string | undefined) => void;
};

export function PercentageInputNumber({ onValueChange, ...props }: Props) {
  return (
    <InputNumber
      rightIcon="percent"
      max={100}
      min={0}
      decimalScale={2}
      onValueChange={(e) => onValueChange(e.value)}
      {...props}
    />
  );
}

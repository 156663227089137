import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories';
import {
  DASHBOARD_FILTER_OBJECT_ICONS,
  DashboardFilterObjectTypes,
} from 'bundles/Shared/entities/dashboard';
import pluralize from 'pluralize';
import { capitalize } from 'lodash-es';

export const DashboardFilterObjectItem = ({
  disabled,
  type,
  count,
  ...props
}: {
  type: DashboardFilterObjectTypes;
  count: number;
  disabled?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'>) => {
  const textColor = disabled ? 'text-neutral-400' : 'text-neutral-550';
  return (
    <div
      className={cn(
        'flex cursor-pointer flex-col items-center justify-center gap-tw-2 !rounded-lg border-2 border-solid border-neutral-100 p-tw-2 hover:bg-neutral-100',
        disabled && 'cursor-default bg-neutral-100',
      )}
      {...props}
    >
      <Icon
        className={cn('text-[24px]', textColor)}
        iconName={DASHBOARD_FILTER_OBJECT_ICONS[type]}
      />
      <span className={cn('secondary-semibold', textColor)}>
        {count} {pluralize(capitalize(type), count)}
      </span>
    </div>
  );
};

import { IApproverThreshold } from 'bundles/Construction/types';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import pluralize from 'pluralize';
import { IUser } from 'types/User';
import { TWorkflowThresholdType } from 'bundles/REconcile/actions/workflowThresholds';

export const latestThresholdNumber = (allThresholds: IApproverThreshold[]) =>
  allThresholds.length === 0
    ? 0
    : Math.max(...allThresholds.map((t) => t.number));

export const previousThresholdValue = (
  thresholdNumber: IApproverThreshold['number'],
  allThresholds: IApproverThreshold[],
) => {
  const prev = allThresholds.find((t) => t.number === thresholdNumber);
  if (prev) {
    return Number(prev.value);
  }
  return 0;
};

export const nextThreshold = (
  threshold: IApproverThreshold,
  allThresholds: IApproverThreshold[],
) => allThresholds.find((t) => t.number === threshold.number + 1);

export const nextThresholdValue = (
  thresholdNumber: IApproverThreshold['number'],
  allThresholds: IApproverThreshold[],
) => {
  const prev = allThresholds.find((t) => t.number === thresholdNumber + 1);
  if (prev) {
    return Number(prev.value);
  }
  return Infinity;
};

export const userFullName = (allUsers: IUser[], userId: IUser['id']) => {
  const user = allUsers?.find((u) => userId === u.id);
  return user?.fullName;
};

export const userFullNames = (allUsers: IUser[], userIds: IUser['id'][]) => {
  const users = allUsers?.filter((u) => userIds?.includes(u.id));
  return users?.map((u) => u.fullName)?.join(', ');
};

export const allUserFullNames = (
  allUsers: IUser[],
  externalApproverIds: IUser['id'][],
  internalApproverId: IUser['id'][],
) => {
  const externalUsers = allUsers?.filter(
    (u) => externalApproverIds?.includes(u.id),
  );
  const internalUsers = allUsers?.filter(
    (u) => internalApproverId?.includes(u.id),
  );
  return {
    externalUsers: externalUsers?.map((u) => u.fullName)?.join(', '),
    internalUsers: internalUsers?.map((u) => u.fullName)?.join(', '),
  };
};

type FormatterType = (val: number) => string;

export const thresholdRangeText = (
  fromValue: number,
  toValue: number,
  formatter: FormatterType,
) => {
  const fromText = fromValue && `from ${formatter(fromValue)}`;
  const toText = toValue !== Infinity ? `up to ${formatter(toValue)}` : null;

  return [fromText ?? null, toText ?? null].filter((v) => v != null).join(' ');
};

export const daysNumberFormat: FormatterType = (value) =>
  value ? `${value} ${pluralize('day', value)}` : '';

export const resolveFormatter = (category: TWorkflowThresholdType) => {
  if (category === 'tim') return daysNumberFormat;
  return accountingNumberFormat;
};

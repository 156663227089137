import React, { useMemo } from 'react';
import { Button, InlineObject } from 'stories';
import { formatDate } from '@/shared/lib/formatting/dates';
import { formatAmount } from '@/shared/lib/formatting/number';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import {
  ILineItem,
  ITransactionDetails,
} from 'bundles/REconcile/types/ILineItem';
import VendorPopover from 'bundles/REport/components/financials/modal/VendorPopover';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import pluralize from 'pluralize';
import { getRemarkFormatter } from 'bundles/Shared/components/lineItems/formatters';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';
import { sumBy } from 'lodash-es';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps & ITransactionDetails & Pick<ILineItem, 'vendor'>;

const TransactionUnlinkModal = ({
  onClose,
  onSubmit,
  vendor,
  linkedLineItems,
  period,
  date,
  note,
  source,
  generalLedger,
}: Props) => {
  const { confirm } = useModal();
  const columns: IColumn<ILineItem>[] = useMemo(
    () => [
      {
        text: 'JCC/SOVC',
        dataField: 'development_category_id',
        formatter: ({ row }) => <div>{row.category!.name}</div>,
      },
      {
        text: 'Remark',
        dataField: 'description',
        formatter: getRemarkFormatter((item) => item.remark),
      },
      {
        text: 'Amount',
        dataField: 'amount',
        formatter: ({ row }) => formatAmount(row.amount),
        classes: 'text-right whitespace-nowrap',
        headerAlign: 'end',
      },
    ],
    [],
  );

  const handleClick = async () => {
    const confirmed = await confirm({
      title: 'Unlink Transaction',
      subtitle: 'Are you sure you want to unlink this transaction?',
      actions: {
        primaryButton: {
          text: 'Unlink',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });

    if (confirmed) {
      onSubmit?.(true);
    }
  };

  const total = sumBy(linkedLineItems, (i: ILineItem) => Number(i.amount));

  return (
    <div>
      <SidePanel toggle={onClose} header="Transaction Details" bodyPadding="0">
        <div className="flex h-full flex-col">
          <div className="px-tw-6 py-tw-4">
            <div className="mb-tw-4 flex">
              <div className="w-[100px]">
                <small className="mb-tw-1.5 text-light-60">TX DATE</small>
                <div className="light-90 inline-regular">
                  {formatDate(date, 'MMM DD, YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-tw-1 text-light-60">VENDOR</small>
                <div>
                  {vendor ? (
                    <VendorPopover vendor={vendor}>
                      <InlineObject
                        iconName="bag"
                        object={vendor.name}
                        hideTooltipWhenOverflow
                      />
                    </VendorPopover>
                  ) : (
                    <> - </>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-tw-4 flex">
              <div className="w-[100px]">
                <small className="mb-tw-1.5 text-light-60">PERIOD</small>
                <div className="light-90 inline-regular">
                  {formatDate(period, 'MMM-YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-tw-1.5 text-light-60">DESCRIPTION</small>
                <div className="light-90 inline-regular">{note}</div>
              </div>
            </div>
            <div className="mb-tw-4">
              <small className="mb-tw-1.5 text-light-60">GENERAL LEDGER</small>
              <div className="light-90 inline-regular">
                {generalLedger.number} - {generalLedger.title}
              </div>
            </div>
            <div className="mb-tw-4">
              <small className="mb-tw-1.5 text-light-60">SOURCE</small>
              <div className="light-90 inline-regular">
                {SOURCE_TYPE_SETTINGS[source].title}
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col bg-light px-tw-6 py-tw-4">
            <div className="flex-1">
              <div className="mb-tw-1.5">
                <h6 className="dark-60">
                  {linkedLineItems.length}{' '}
                  {pluralize('Line Item', linkedLineItems.length)}
                </h6>
              </div>
              <Table columns={columns} items={linkedLineItems} />
              <div className="pr-tw-4 pt-tw-2 text-right">
                <span className="light-60 mr-tw-1 text-[14px]">Total:</span>
                <span className="dark-60 text-[14px]">
                  {formatAmount(total)}
                </span>
              </div>
            </div>
            <Button
              variant="danger"
              className="mt-tw-4 w-full"
              onClick={handleClick}
            >
              Unlink
            </Button>
          </div>
        </div>
      </SidePanel>
    </div>
  );
};

export default TransactionUnlinkModal;

import { useLayoutEffect, useState } from 'react';

// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/5047712#5047712
function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ??
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

const DIFF_THRESHOLD = 0.1;

// todo split hook to 2 useIsTranceted useTextWidth
export const useIsTextTruncated = (
  ref: React.RefObject<HTMLElement>,
  text: string,
  diffThreshold = DIFF_THRESHOLD,
) => {
  const compareText = () => {
    if (!ref.current) {
      return false;
    }
    const textWidth = getTextWidth(text, getCanvasFont(ref.current));
    const elementWidth = ref.current.getBoundingClientRect().width;

    return textWidth > elementWidth + diffThreshold;
  };
  const [isTruncated, setIsTruncated] = useState(() => {
    if (!ref.current) {
      return false;
    }
    return compareText();
  });
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    setIsTruncated(compareText());
  }, []);

  return isTruncated;
};

import { cn } from '@/shared/lib/css/cn';
import { ComponentProps } from 'react';
import FilterButton from '../filterButton/FilterButton';
import styles from './PublicFilterButton.module.scss';

type Props = Omit<ComponentProps<typeof FilterButton>, 'children' | 'clicked'>;

function PublicFilterButton({ className, filtered, ...props }: Props) {
  return (
    <FilterButton
      className={cn(styles.button, className)}
      classes={{
        filtered: styles.button_filtered,
        container: styles.container,
      }}
      filtered={filtered}
      iconName="public"
      {...props}
    />
  );
}

export default PublicFilterButton;

import {
  AllTrailingPeriod,
  AttdPeriod,
  CalendarPeriod,
  DayPeriod,
  DtdPeriod,
  ItdPeriod,
  MonthPeriod,
  MtdPeriod,
  MtmPeriod,
  QtdPeriod,
  QuarterPeriod,
  RangePeriod,
  ReckonerPeriod,
  T12Period,
  T3Period,
  T6Period,
  ToDatePeriod,
  TrailingDays,
  TrailingMonths,
  TrailingPeriod,
  TrailingPeriodAlias,
  TrailingQuarters,
  TrailingYears,
  WeekPeriod,
  YearPeriod,
  YtdPeriod,
} from '@/shared/lib/reckoner/model';

const calendarPeriod =
  <P extends CalendarPeriod>(type: P['type']) =>
  (date: P['date']) =>
    ({
      type,
      date,
    }) as P;

const toDatePeriod =
  <P extends ToDatePeriod>(type: P['type']) =>
  (last_date: P['last_date']) =>
    ({
      type,
      last_date,
    }) as P;

const rangePeriod =
  <P extends RangePeriod>(type: P['type']) =>
  (from_date: P['from_date'], to_date: P['to_date']) =>
    ({
      type,
      from_date,
      to_date,
    }) as P;

const trailingPeriod =
  <P extends TrailingPeriod>(type: P['type']) =>
  (date: P['date'], count: P['count']) =>
    ({
      type,
      count,
      date,
    }) as P;

const trailingPeriodAlias =
  <P extends TrailingPeriodAlias>(type: P['type']) =>
  (date: P['date']): P =>
    ({
      type,
      date,
    }) as P;

const isPeriod =
  <P extends ReckonerPeriod>(types: P['type'][]) =>
  (period: ReckonerPeriod): period is P =>
    types.includes(period.type);

const CALENDAR_PERIOD_TYPES = [
  'day',
  'month',
  'quarter',
  'week',
  'year',
] as const satisfies CalendarPeriod['type'][];

const RANGE_PERIOD_TYPES = [
  'dtd',
  'mtm',
] as const satisfies RangePeriod['type'][];

const TO_DATE_PERIOD_TYPES = [
  'attd',
  'itd',
  'mtd',
  'qtd',
  'ytd',
] as const satisfies ToDatePeriod['type'][];

const TRAILING_PERIOD_TYPES = [
  'trailing_days',
  'trailing_months',
  'trailing_quarters',
  'trailing_years',
] as const satisfies TrailingPeriod['type'][];

const TRAILING_ALIAS_PERIOD_TYPES = [
  't3',
  't6',
  't12',
] as const satisfies TrailingPeriodAlias['type'][];

const ALL_TRAILING_PERIOD_TYPES = [
  ...TRAILING_PERIOD_TYPES,
  ...TRAILING_ALIAS_PERIOD_TYPES,
] as const satisfies AllTrailingPeriod['type'][];

export const reckonerPeriod = {
  getRaw: <const T extends ReckonerPeriod>(config: T): T => {
    return config;
  },

  day: calendarPeriod<DayPeriod>('day'),
  week: calendarPeriod<WeekPeriod>('week'),
  month: calendarPeriod<MonthPeriod>('month'),
  quarter: calendarPeriod<QuarterPeriod>('quarter'),
  year: calendarPeriod<YearPeriod>('year'),
  CalendarPeriodTypes: CALENDAR_PERIOD_TYPES,
  isCalendarPeriod: isPeriod<CalendarPeriod>(CALENDAR_PERIOD_TYPES),
  isMonthPeriod: isPeriod<MonthPeriod>(['month']),

  dtd: rangePeriod<DtdPeriod>('dtd'),
  mtm: rangePeriod<MtmPeriod>('mtm'),
  RangePeriodTypes: RANGE_PERIOD_TYPES,
  isRangePeriod: isPeriod<RangePeriod>(RANGE_PERIOD_TYPES),

  mtd: toDatePeriod<MtdPeriod>('mtd'),
  qtd: toDatePeriod<QtdPeriod>('qtd'),
  ytd: toDatePeriod<YtdPeriod>('ytd'),
  attd: toDatePeriod<AttdPeriod>('attd'),
  itd: toDatePeriod<ItdPeriod>('itd'),
  ToDatePeriodTypes: TO_DATE_PERIOD_TYPES,
  isToDatePeriod: isPeriod<ToDatePeriod>(TO_DATE_PERIOD_TYPES),

  trailing_days: trailingPeriod<TrailingDays>('trailing_days'),
  trailing_months: trailingPeriod<TrailingMonths>('trailing_months'),
  trailing_quarters: trailingPeriod<TrailingQuarters>('trailing_quarters'),
  trailing_years: trailingPeriod<TrailingYears>('trailing_years'),
  TrailingPeriodTypes: TRAILING_PERIOD_TYPES,
  isTrailingPeriod: isPeriod<TrailingPeriod>(TRAILING_PERIOD_TYPES),

  t3: trailingPeriodAlias<T3Period>('t3'),
  t6: trailingPeriodAlias<T6Period>('t6'),
  t12: trailingPeriodAlias<T12Period>('t12'),
  TrailingAliasPeriodTypes: TRAILING_ALIAS_PERIOD_TYPES,
  isTrailingAliasPeriod: isPeriod<TrailingPeriodAlias>(
    TRAILING_ALIAS_PERIOD_TYPES,
  ),

  AllTrailingPeriodTypes: ALL_TRAILING_PERIOD_TYPES,
  isAllTrailingPeriod: isPeriod<AllTrailingPeriod>(ALL_TRAILING_PERIOD_TYPES),
};

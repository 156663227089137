import {
  capitalInvestmentObjectsEnhancedApi,
  useGetApiCapitalTransactionSourcesQuery,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { assertsQueryDataLoadedBefore } from '@/lib/typeHelpers/redux/rtkApiHelpers';
import { useParams } from '@reach/router';

export function useInvalidateCapitalTransactionSources() {
  const dispatch = useAppDispatch();
  const invalidateCapitalInvestmentObject = () => {
    dispatch(
      capitalInvestmentObjectsEnhancedApi.util.invalidateTags([
        'TransactionSources',
      ]),
    );
  };

  return invalidateCapitalInvestmentObject;
}

export function useLoadedCapitalTransactionSources() {
  const params = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();
  const { objectId } = params;

  if (!objectId) throw new Error('"objectId" must exist in params');

  const res = useGetApiCapitalTransactionSourcesQuery({
    investmentObjectId: objectId,
  });
  assertsQueryDataLoadedBefore(res.data);

  return {
    ...res,
    data: res.data,
  };
}

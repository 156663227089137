import { transformFormulaReferencingEntities } from '@/bundles/Shared/entities/formula';
import {
  GetApiReportManualVariablesApiArg,
  GetApiReportManualVariablesApiResponse,
  ReportManualVariable,
  useGetApiReportManualVariablesQuery,
} from '@/entities/report/manualVariable';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { partition } from 'lodash-es';
import { isEntityTagged } from 'bundles/Shared/entities/tag/lib';

const useSelectVariables = () =>
  useMemo(() => {
    const emptyArray: ReportManualVariable[] = [];
    return createSelector(
      (res: { data: GetApiReportManualVariablesApiResponse | undefined }) =>
        res.data?.items ?? emptyArray,
      (data) =>
        data.map((v) => ({
          ...v,
          referencedInEntities: transformFormulaReferencingEntities(v),
        })) as ReportManualVariable[],
    );
  }, []);

export const useReportManualVariableQuery = (
  args: GetApiReportManualVariablesApiArg,
  options?: Parameters<typeof useGetApiReportManualVariablesQuery>[1],
) => {
  const selectVars = useSelectVariables();
  const selectUntaggedVariables = useMemo(() => {
    return createSelector(selectVars, (variables) =>
      partition(variables, (variable) => isEntityTagged(variable)),
    );
  }, []);
  return useGetApiReportManualVariablesQuery(args, {
    ...options,
    selectFromResult: ({ data, ...params }) => ({
      manualVariables: selectVars({ data }),
      tagVariablesPartition: selectUntaggedVariables({ data }),
      meta: data?.meta ?? {
        notFullyConfiguredVariables: 0,
      },
      ...params,
    }),
  });
};

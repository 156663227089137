import {
  ADJUSTMENT_EXPRESSION_OPTIONS,
  AdjustmentExpressionForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { Tumbler } from '@/stories';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import {
  FormulaField,
  isUnderwritingAdjustment,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { isEmpty } from 'lodash-es';
import { Control, useController } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { SectionField } from 'stories/Field/FieldsWrappers';

export function AdjustmentField<
  TFieldValues extends Record<string, any> = Record<string, any>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name }: { control: Control<TFieldValues>; name: TName }) {
  const { field } = useController<TFieldValues, TName>({ name, control });
  const formulaReference = field.value?.source_overrides?.[0]?.override_to;

  const handleChange = (value: string) => {
    field.onChange({
      ...field.value,
      source_overrides: isEmpty(value)
        ? []
        : [
            {
              source: 'underwriting_budget',
              override_to: value,
            },
          ],
    } satisfies AdjustmentExpressionForm);
  };

  return (
    <SectionField
      className="gap-tw-4"
      button={
        <Tumbler
          checked={field.value != null}
          onChange={(e) =>
            field.onChange(
              e.target.checked
                ? ({
                    name: null,
                    source_overrides: [],
                  } satisfies AdjustmentExpressionForm)
                : null,
            )
          }
        />
      }
      labelText="Adjustment"
    >
      {field.value != null && (
        <div className="flex flex-col gap-tw-4">
          <SharedSelect
            isMulti={false}
            menuPortalTarget={document.body}
            value={ADJUSTMENT_EXPRESSION_OPTIONS.find(
              (o) => o.value === field.value?.name,
            )}
            onChange={(e) => {
              field.onChange(
                e?.value
                  ? ({
                      name: e?.value,
                      source_overrides: isUnderwritingAdjustment(e?.value)
                        ? field.value.source_overrides
                        : [],
                    } satisfies AdjustmentExpressionForm)
                  : null,
              );
            }}
            options={ADJUSTMENT_EXPRESSION_OPTIONS}
          />
          {field.value.name != null &&
            isUnderwritingAdjustment(field.value.name) && (
              <>
                <div className="h-[1px] rounded-lg bg-neutral-100" />
                <div className="flex items-center justify-between gap-tw-4">
                  <div className="flex flex-col gap-tw-1">
                    <span className="inline-semibold text-neutral-999">
                      Budget Value Override
                    </span>
                    <span className="secondary-regular text-neutral-550">
                      Select Budget Value Override (optional)
                    </span>
                  </div>
                  {isEmpty(formulaReference) && (
                    <FormulaField.SelectButton onChange={handleChange} />
                  )}
                </div>
              </>
            )}
          <FormulaField.NotFoundAlert value={formulaReference} />
          <FormulaField.Card value={formulaReference} onChange={handleChange} />
        </div>
      )}
    </SectionField>
  );
}

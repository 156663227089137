import React, { useCallback, useState, useEffect } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import { useParams, navigate } from '@reach/router';
import { IPageParams } from 'bundles/Construction/types';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { HugeModalHeaderForCe, AnimationLoader } from 'stories';
import { TStatusInvoice } from '@/shared/types/reconcile/Invoice';
import { useGetPayableInvoicesQuery } from 'bundles/Construction/api/invoices';
import {
  fetchCompanies,
  selectCompanies,
} from 'bundles/Construction/reducers/ReconcileSlice';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import {
  PageParamsProvider,
  PageParamsPagination,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import {
  payableInvoicesColumns,
  TFilterModelPayableInvoices,
} from './components/useColumns';
import TotalInfoBlock from '../components/TotalInfoBlock.tsx/TotalInfoBlock';
import { getTotalData } from './components/utils';
import BadgesBar from './components/BadgesBar';

export interface IPageParamsInvoices extends IPageParams {
  vendor_ids: string[];
  status: 'all' | TStatusInvoice;
}

const PayableInvoices = ({ children }) => {
  const companies = useAppSelector(selectCompanies);
  const legalEntity = useAppSelector(
    (store) => store.developmentBudget.legalEntity,
  );
  const dispatch = useAppDispatch();

  const params = useParams();
  const { getUrl } = useNavigation();

  const [pageParams, setPageParams] = useState({
    status: 'all',
    amount: {
      from: '',
      to: '',
    },
    vendor_ids: [],
    page: 1,
  });

  const { data, isLoading } = useGetPayableInvoicesQuery({
    legalEntityCode: params?.legalEntityCode || legalEntity.code,
    pageParams,
  });

  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModelPayableInvoices) =>
      setPageParams((prevParams) => {
        return {
          ...prevParams,
          status: filterModel.status,
          vendor_ids: filterModel.vendor_ids?.map((item) => item.id),
          amount: filterModel.amount,
        };
      }),
    [],
  );

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  if (isLoading) return <AnimationLoader />;

  const onCloseModal = () => {
    navigate(
      getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS', {
        legalEntityCode: params.legalEntityCode,
      }),
    );
  };

  const showDetails = (id: string) => {
    navigate(`payable_invoices/${id}`);
  };
  return (
    <div>
      <div className="bg-white px-l py-m">
        <HugeModalHeaderForCe
          title="Accounts Payable"
          onClose={onCloseModal}
          content={<TotalInfoBlock metaData={getTotalData(data?.meta || [])} />}
        />
      </div>
      <div className="custom-container">
        <h2 className="font-weight-700 mb-l mt-xl">Invoices</h2>
        <PageParamsProvider
          pageParams={pageParams}
          setPageParams={setPageParams}
        >
          <BadgesBar
            pageParams={pageParams}
            setPageParams={setPageParams}
            handleFilterModelChange={handleFilterModelChange}
          />
          <div>
            <div className="flex items-center justify-between mb-m">
              <div>
                <PageParamsPagination
                  loading={isLoading}
                  totalSize={data?.meta.totalSize || 1}
                  sizePerPage={data?.meta.perPage || 1}
                />
              </div>
              <PageParamsSearch />
            </div>
            <div>
              <Table
                loading={isLoading}
                columns={payableInvoicesColumns(companies, showDetails)}
                settings={settings}
                setSettings={setSettings}
                filterModel={{
                  ...pageParams,
                  vendor_ids: pageParams.vendor_ids?.map((id) => ({
                    id,
                    name: companies.find((company) => company.id === id)?.name,
                  })),
                }}
                onFilterModelChange={handleFilterModelChange}
                items={data?.items || []}
              />
            </div>
          </div>
        </PageParamsProvider>
      </div>
      {params['*'] && children}
    </div>
  );
};

export default PayableInvoices;

import { CssVar } from '@/shared/config/cssVar';
import { TRentrollUnitStatus } from '@/entities/report/unitTypes/model';

export const RENTROLL_UNIT_STATUS_COLORS: Record<TRentrollUnitStatus, string> =
  {
    [TRentrollUnitStatus.APPLICANT]: CssVar.violet,
    [TRentrollUnitStatus.OCCUPIED]: CssVar.green,
    [TRentrollUnitStatus.OCCUPIED_NTV]: CssVar.yellow,
    [TRentrollUnitStatus.OCCUPIED_NTVL]: CssVar.blue,
    [TRentrollUnitStatus.OCCUPIED_NO_NTV]: CssVar.green,
    [TRentrollUnitStatus.VACANT]: CssVar.red,
    [TRentrollUnitStatus.VACANT_LEASED]: CssVar.blue,
  };

import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Control, useController } from 'react-hook-form';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import {
  TOTAL_CALCULATION_STRATEGIES,
  TOTAL_CALCULATION_STRATEGY_OPTIONS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';

export function TotalCalculationStrategyField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name }: { control: Control<TFieldValues>; name: TName }) {
  const { field } = useController({ name, control });
  return (
    <SectionField
      labelText="Total Calculation Settings"
      button={
        <Tumbler
          checked={field.value}
          onChange={(e) =>
            field.onChange(
              e.target.checked ? TOTAL_CALCULATION_STRATEGIES.EVALUATE : null,
            )
          }
        />
      }
    >
      {field.value && (
        <SharedSelect
          isMulti={false}
          menuPortalTarget={document.body}
          value={TOTAL_CALCULATION_STRATEGY_OPTIONS.find(
            (option) => option.value === field.value,
          )}
          options={TOTAL_CALCULATION_STRATEGY_OPTIONS}
          onChange={(o) => field.onChange(o?.value)}
        />
      )}
    </SectionField>
  );
}

import React, { ComponentProps } from 'react';
import { Popover } from 'stories';
import AssetFilterItem from './AssetFilterItem';

interface Props extends ComponentProps<typeof AssetFilterItem> {
  className?: string;
}

function AssetFilterItemDropdown({
  children,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <Popover
      className="shadow-m-light min-w-max rounded-[8px] border border-light-30 bg-white p-0"
      template={children}
      placement="top-start"
      trigger="mouseenter"
      arrow={false}
      offset={[0, 2]}
      hiddenArrow
    >
      <AssetFilterItem {...props} />
    </Popover>
  );
}

export default AssetFilterItemDropdown;

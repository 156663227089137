import NavigationCardList from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import { CssVar } from '@/shared/config/cssVar';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { Badge, Button, IconButton } from '@/stories';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import {
  FormulaVariable,
  FormulaVariableCard,
  getHumanReadableVariableNameSpace,
} from 'bundles/Shared/entities/formula';
import { useGetApiSettingsReportVariablesQuery } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import { debounce, groupBy, isEmpty, orderBy, uniq } from 'lodash-es';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import { InputActionMeta } from 'react-select';
import Icon from 'stories/Icon/Icon';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';

const selectStyles: React.ComponentProps<typeof SharedSelect>['styles'] = {
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base, { hasValue }) => ({
    ...base,
    paddingLeft: hasValue ? CssVar['tw0.5'] : CssVar.tw2,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: CssVar.info055,
    borderRadius: CssVar.tw1,
    ':hover': {
      borderRadius: CssVar.tw1,
    },
    height: 28,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: CssVar.neutral000,
    fontWeight: 600,
    fontSize: 14,
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: CssVar.neutral000,
    ':hover': {
      backgroundColor: CssVar.info055,
      color: CssVar.neutral000,
    },
  }),
};

export function VariableList({
  onSelect,
}: {
  onSelect?: (variable: FormulaVariable['reference']) => void;
}) {
  const { data, isLoading, isFetching, refetch } =
    useGetApiSettingsReportVariablesQuery();
  const [selectInputValue, setSelectInputValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  const variableGroups = useMemo(
    () => groupBy(data, (variable) => variable.namespace),
    [data],
  );

  const filteredVariableGroups = useMemo(() => {
    const filtered = (data ?? []).filter((variable) => {
      const textToSearch = [variable.label, variable.reference];
      if (selectedGroup) {
        return (
          variable.namespace === selectedGroup &&
          includesInLowerCase(textToSearch, searchText)
        );
      }
      return includesInLowerCase(textToSearch, searchText);
    });
    return groupBy(filtered, (variable) => variable.namespace);
  }, [data, searchText]);

  const variableOptions = useMemo(
    () =>
      uniq(data?.map((variable) => variable.namespace) ?? []).map(
        (namespace) => ({
          label: getHumanReadableVariableNameSpace(namespace),
          value: namespace,
        }),
      ),
    [data],
  );

  const isGroupExpandedCB = useCallback(
    (group: string) => {
      return expandedGroups.includes(group) || selectedGroup === group;
    },
    [selectedGroup, expandedGroups],
  );

  const getVariableLabel = (varable: FormulaVariable) => {
    if (searchText) {
      const startIndexOfSearchText = varable.label
        .toLowerCase()
        .indexOf(searchText.toLowerCase());
      const endIndexOfSearchText = startIndexOfSearchText + searchText.length;
      return (
        <span>
          {varable.label.slice(0, startIndexOfSearchText)}
          <span className="inline-semibold">
            {varable.label.slice(startIndexOfSearchText, endIndexOfSearchText)}
          </span>
          {varable.label.slice(endIndexOfSearchText)}
        </span>
      );
    }
    return varable.label;
  };

  const debouncedSearchChangeHandler = debounce((value, actionMeta) => {
    if (
      actionMeta.action === 'input-blur' ||
      actionMeta.action === 'menu-close'
    ) {
      return;
    }
    setSearchText(value);
  }, 500);

  const searchChangeHandler = (value: string, actionMeta: InputActionMeta) => {
    if (
      actionMeta.action === 'input-blur' ||
      actionMeta.action === 'menu-close'
    ) {
      return;
    }
    setSelectInputValue(value);
    debouncedSearchChangeHandler(value, actionMeta);
  };

  return (
    <>
      <SharedSelect
        styles={selectStyles}
        placeholder="Search By Variable Name"
        isMulti
        options={variableOptions}
        menuPortalTarget={document.body}
        menuIsOpen={false}
        value={variableOptions.find((option) => option.value === selectedGroup)}
        inputValue={selectInputValue}
        onChange={() => {
          setSelectedGroup(null);
        }}
        onInputChange={searchChangeHandler}
      />
      <div className="flex flex-col gap-tw-2">
        <div className="flex items-center justify-between">
          <span className="label-semibold text-neutral-500">
            by Data Source
          </span>
          <Button
            onClick={refetch}
            size="xs"
            variant="secondary"
            iconName="sync"
            loading={isFetching}
            disabled={isFetching}
          >
            Refresh
          </Button>
        </div>

        <div className="h-[1px] w-full bg-neutral-200" />
      </div>
      <NavigationCardList>
        {isLoading &&
          Array.from({ length: 5 }).map((_, i) => (
            <SkeletonBlock className="h-[40px] w-full" key={i} />
          ))}
        {Object.entries(variableGroups).map(([group, variables]) => {
          const groupExpanded = isGroupExpandedCB(group);

          return (
            <div
              key={group}
              className="group flex flex-col gap-tw-2 rounded-xl bg-neutral-250 p-tw-2"
            >
              <div
                className="flex cursor-pointer items-center gap-tw-2 px-tw-2"
                onClick={() => {
                  setExpandedGroups((prev) => {
                    if (groupExpanded) {
                      return prev.filter((eGroup) => eGroup !== group);
                    }
                    return [...prev, group];
                  });
                }}
              >
                <Icon iconName={groupExpanded ? 'arrowBottom' : 'arrowRight'} />
                <span className="inline-semibold text-neutral-850">
                  {getHumanReadableVariableNameSpace(group)}
                </span>
                <Badge
                  className="shrink-0 whitespace-nowrap"
                  backgroundColor={CssVar.dark}
                >
                  {!isEmpty(searchText) &&
                    `Found ${filteredVariableGroups[group]?.length ?? 0} of `}
                  {variables.length}{' '}
                  {pluralize('Variable', variables.length ?? 0)}
                </Badge>
                <div className="grow" />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedGroup(group);
                  }}
                  iconName="searchListAlt"
                  className="invisible group-hover/:!visible"
                  size="m"
                />
              </div>
              {groupExpanded && filteredVariableGroups[group] && (
                <div className="flex flex-col gap-1 overflow-auto rounded-xl">
                  {orderBy(filteredVariableGroups[group], 'label').map(
                    (variable) => (
                      <FormulaVariableCard
                        key={variable.reference}
                        reference={variable.reference}
                        className="group/variable"
                        label={getVariableLabel(variable)}
                        labelPanel={
                          <>
                            <div className="grow" />
                            {onSelect && (
                              <Button
                                className="invisible group-hover/variable:!visible"
                                size="xs"
                                variant="secondary"
                                onClick={() => onSelect(variable.reference)}
                              >
                                Select
                              </Button>
                            )}
                          </>
                        }
                      />
                    ),
                  )}
                </div>
              )}
            </div>
          );
        })}
      </NavigationCardList>
    </>
  );
}

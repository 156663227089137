import { useReportBuilderTemplateScreenParams } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { plainPreviewReportTemplatePdf } from '@/entities/report/reportBuilder/lib/plainPreviewReportTemplatePdf';
import {
  useFetchPreviewPdf,
  usePdfBlobCreateObjectURL,
} from '@/entities/report/reportBuilder/lib/previewPdf';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppSelector } from '@/shared/lib/hooks/redux';

export const useGetApiReportBuilderTemplatePreviewPdfQuery = () => {
  const params = useReportBuilderTemplateScreenParams();
  const pendingRequestIds = useAppSelector(
    (s) => s.reportBuilderTemplate.pendingRequestIds,
  );
  const assetId = useAssetIdSearchParam();
  const tempateCtx = useReportBuilderTemplateContext();
  const templateStateDate = useAppSelector(
    (state) =>
      state.reportBuilderTemplate.entities[tempateCtx?.templateId ?? '']?.date,
  );

  const { isLoading, pdfBlob } = useFetchPreviewPdf({
    effectDeps: [pendingRequestIds, templateStateDate, assetId],
    waitIfCallback: () => {
      return (
        pendingRequestIds.length !== 0 ||
        assetId == null ||
        params.templateId == null
      );
    },
    fetchCallback: async () => {
      return await plainPreviewReportTemplatePdf({
        templateId: params.templateId,
        date: templateStateDate as DateString,
        assetId: assetId!,
      });
    },
  });

  const dataObjectURL = usePdfBlobCreateObjectURL(pdfBlob);

  return {
    dataObjectURL,
    data: pdfBlob,
    isLoading,
  };
};

import React, { useRef, useState } from 'react';
import { Link } from '@reach/router';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import {
  CurrencyFormatter,
  Icon,
  IconButton,
  PercentFormatter,
  Popover,
} from 'stories';
import { cn } from '@/shared/lib/css/cn';
import { TitledCard } from 'stories/TitledCard/TitledCard';
import useElementSize from '@/shared/lib/hooks/useElementSize';
import StatisticsRow, {
  StatisticRowList,
} from 'bundles/Construction/components/cards/StatisticsRow/StatisticsRow';
import {
  replacePathParams,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { IDevelopmentProjectDetailed } from 'bundles/REconcile/types/IDevelopmentProject';
import StatisticLine from 'stories/filterCard/statistic/StatisticLine';
import {
  CARD_ACTIVE_CATEGORIES,
  CATEGORY_COLORS,
  getContingencyRemaining,
  getContingencyStatistics,
  getRemainingColors,
} from 'bundles/Construction/utils';
import { mapChangeManagementDataToCard } from 'bundles/REconcile/components/development/home/dashboard/utils';
import { TContingencyCategory } from 'bundles/Construction/types';
import ConstructionReportCardStatistics from 'bundles/Construction/components/cards/ConstructionReportCardStatistics';

export const NavSection = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col gap-tw-4">{children}</div>
);

export const NavLink = ({
  className,
  ...props
}: React.ComponentProps<typeof Link>) => (
  <Link className="inline-regular text-light-90 hover:text-blue" {...props} />
);

export const NavLinkList = ({ children }: React.PropsWithChildren) => (
  <div className="flex items-center gap-tw-4">{children}</div>
);

export const NavSectionHeader = ({
  children,
  title,
}: React.PropsWithChildren<{ title: string }>) => (
  <div className="flex items-center gap-tw-4">
    <span className="header6-bold text-dark-60">{title}</span>
    <VerticalSeparator />
    {children}
  </div>
);

export const SeparatorLine = ({ children }: React.PropsWithChildren) => (
  <div className="h-1 w-full bg-light-10">{children}</div>
);

const BUTTON_POSITION_CLASSES = 'absolute right-tw-4 top-[10px]';

export const InspectButton = (
  props: Omit<React.ComponentProps<typeof Icon>, 'iconName'>,
) => (
  <div
    className={cn(
      'group flex cursor-pointer flex-col items-end gap-tw-0.5',
      BUTTON_POSITION_CLASSES,
    )}
  >
    <Icon
      id="inspect-button"
      className="text-blue group-hover:text-blue"
      iconName="externalLinkSquare"
      {...props}
    />
    <span
      onClick={props.onClick}
      className="secondary-regular text-light-60 group-hover:text-blue"
    >
      Inspect
    </span>
  </div>
);

export const TitleCardWithDetails = ({
  children,
  hoverContent,
  className,
  ...props
}: React.ComponentProps<typeof TitledCard> & {
  hoverContent?: React.ReactNode;
}) => {
  const ref = useRef(null);
  const { width, height } = useElementSize(ref);
  const [opened, setOpened] = useState(false);

  const template = (
    <TitledCard
      style={{
        width,
        minHeight: height,
      }}
      className={cn(
        'border-5 relative border-solid border-[rgba(51,65,72,0.2)]',
        className,
      )}
      {...props}
    >
      <IconButton
        className={BUTTON_POSITION_CLASSES}
        iconName="closeSmall"
        variant="white"
        onClick={() => setOpened(false)}
      />
      <div className="flex w-full flex-col items-center gap-tw-6">
        {children}
        {hoverContent}
      </div>
    </TitledCard>
  );

  return (
    <Popover
      className="rounded-2xl p-0"
      offset={[0, -height]}
      hiddenArrow
      visible={opened}
      classes={{ spanContainer: 'w-full' }}
      maxWidth="max-content"
      template={template}
    >
      <TitledCard
        ref={ref}
        className={cn('relative w-full', className)}
        {...props}
      >
        <InspectButton onClick={() => setOpened(true)} />
        {children}
      </TitledCard>
    </Popover>
  );
};

export const TimingImpactCard = ({
  timingImpact,
  legalEntityCode,
}: Pick<IDevelopmentProjectDetailed['changeManagement'], 'timingImpact'> & {
  legalEntityCode: string;
}) => {
  const hoverContent = timingImpact.changeOrders.length > 0 && (
    <StatisticRowList>
      {timingImpact.changeOrders.map((item) => (
        <Link
          key={item.id}
          to={replacePathParams(
            ROUTES_ROOT.reconcile.development.legalEntity.changeOrder.fullPath,
            {
              legalEntityCode,
              changeOrderId: item.id.toString(),
            },
          )}
        >
          <StatisticsRow
            key={item.id}
            label={
              <span className="blue cursor-pointer">CO {item.number}</span>
            }
            value={`${item.timingImpactDays}d`}
          />
        </Link>
      ))}
    </StatisticRowList>
  );
  return (
    <TitleCardWithDetails title="Timing Impact" hoverContent={hoverContent}>
      <span className="header2-bold flex items-baseline gap-tw-2 text-dark-60">
        {timingImpact.days}
        <span className="header4-regular text-light-60">Days</span>
      </span>
    </TitleCardWithDetails>
  );
};

export const ProjectedContingencyCard = ({
  changeManagement,
}: Pick<IDevelopmentProjectDetailed, 'changeManagement'>) => {
  const dataForContingency = mapChangeManagementDataToCard(changeManagement);
  const activeCategories = CARD_ACTIVE_CATEGORIES.projectedContingency;
  const remaining = getContingencyRemaining(
    dataForContingency,
    'projectedContingency',
  );
  const remainingColors = getRemainingColors(remaining);

  const hoverContent = (
    <ConstructionReportCardStatistics
      className="w-full"
      activeCategories={activeCategories}
      colors={Object.fromEntries([
        ...Object.entries(CATEGORY_COLORS).map(([k, colors]) => [
          k,
          colors.hoverColor,
        ]),
        [TContingencyCategory.Remaining, remainingColors.hoverColor],
      ])}
      report={dataForContingency}
    />
  );

  return (
    <TitleCardWithDetails
      title="Projected Contingency"
      hoverContent={hoverContent}
    >
      <div className="flex flex-col items-center gap-tw-4">
        <CurrencyFormatter
          classes={{ wrapper: 'header3-bold', value: 'text-dark-60' }}
          abbreviate
          value={changeManagement.projectedContingency.amount}
        />
        <StatisticLine
          className="w-[150px]"
          statistics={getContingencyStatistics(
            dataForContingency,
            'projectedContingency',
          )}
        />
        <div className="flex gap-tw-1">
          <PercentFormatter
            value={getContingencyRemaining(
              dataForContingency,
              'projectedContingency',
            )}
            classes={{
              value: 'label-regular text-light-60',
              fractions: 'text-light-60',
              endSymbol: 'label-regular',
            }}
          />
          <span className="label-regular text-light-60">remaining</span>
        </div>
      </div>
    </TitleCardWithDetails>
  );
};

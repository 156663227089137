import React, { ReactNode } from 'react';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { getDestinationReallocationAmount } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/utils';
import { CurrencyFormatter, Tooltip } from 'stories';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import {
  TEntity,
  TEntityWithReallocation,
} from 'bundles/Shared/components/SpecifySourcesModal/SpecifySourcesModal';

export const getEntityRevised = (
  amount: RawFloat,
  reallocationAmount: Float,
  destination: 'from' | 'to',
) =>
  Number(amount) +
  getDestinationReallocationAmount(destination, reallocationAmount);
export const isRevisedValid = (revised: number) => revised >= 0;

export const getCurrentAmountColumn = <T,>(
  text: ReactNode,
  dataField: string,
): IColumn<TEntity<T>> => ({
  text,
  dataField,
  formatter: ({ row }) => (
    <CurrencyFormatter
      classes={{ wrapper: 'inline-regular text-dark-60' }}
      value={row[dataField]}
    />
  ),
  headerStyle: {
    width: 160,
  },
});

export const getRevisedColumn = <T,>(
  destination: 'from' | 'to',
  entityName: string,
  currentName: string,
): IColumn<TEntityWithReallocation<T>> => ({
  text: 'Revised',
  dataField: 'revised',
  formatter: ({ row }) => {
    const revisedValue = getEntityRevised(
      row[currentName],
      row.reallocation,
      destination,
    );
    return (
      <div className="flex items-center">
        <CurrencyFormatter
          classes={{ wrapper: 'inline-regular text-dark-60' }}
          value={revisedValue}
        />
        {!isRevisedValid(revisedValue) && (
          <Tooltip
            mainText={`You can't reallocate more than ${entityName} contains`}
          >
            <FireIcon priority="high" />
          </Tooltip>
        )}
      </div>
    );
  },
  headerStyle: {
    width: 160,
  },
});

import React, { useMemo } from 'react';
import SegmentFilterItem from 'bundles/Shared/components/Filters/segment/SegmentFilterItem';
import SegmentModal from 'bundles/REport/components/financials/filter/SegmentModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import { IFinancialsTableSegment } from 'types/ReportTableSegment';
import { getLegalEntitiables } from 'bundles/Settings/components/REport/TableSegment/utils/getLegalEntitiables';

interface Props {
  segment: IFinancialsTableSegment;
  onClose?: () => void;
}

function FinancialFilterSegmentFilterItem({ segment, onClose }: Props) {
  const { openModal } = useModal();

  const { segmentAssets, segmentFunds } = useMemo(() => {
    const { assets, funds } = getLegalEntitiables(segment.legalEntities);
    return {
      segmentAssets: assets,
      segmentFunds: funds,
    };
  }, [segment]);

  const resolveSegmentLabel = () => {
    const assetsLen = segmentAssets.length;
    const fundsLen = segmentFunds.length;

    const assetLabel = assetsLen
      ? `${assetsLen} ${pluralize('Asset', assetsLen)}`
      : undefined;
    const fundLabel = fundsLen
      ? `${fundsLen} ${pluralize('Fund', fundsLen)}`
      : undefined;
    if (fundLabel && assetLabel) {
      return `${assetLabel}, ${fundLabel}`;
    }
    return fundLabel ?? assetLabel;
  };

  return (
    <div>
      <SegmentFilterItem
        onClick={() => {
          openModal(SegmentModal, {
            segment,
            segmentAssets,
            segmentFunds,
          });
        }}
        label={segment.title}
        segmentLabel={resolveSegmentLabel()}
        onClose={onClose}
      />
    </div>
  );
}

export default FinancialFilterSegmentFilterItem;

import http from 'lib/http';
import { CapitalEntry } from './types';

// TODO: remove and use postApiCapitalInvestmentContributionsBulk instead
export type TBulkCreateManualContributionsRequest = {
  investment_id: number;
  amount: number;
  date: string;
}[];

export const bulkCreateManualContributions = async (
  data: TBulkCreateManualContributionsRequest,
) => {
  const res = await http.post('/capital/investment/contributions/bulk', data);

  if (res.status === 200) {
    toastr.success('Successfully updated');
  } else {
    toastr.error('Something went wrong');
    return undefined;
  }
  const json = await res.json();
  return json as { items: CapitalEntry };
};

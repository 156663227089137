import React from 'react';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { Popover } from 'stories';
import CheckList from 'stories/Checkbox/CheckList';
import styles from './styles.module.scss';
import {
  getMapOptionsToItemsHandler,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';

interface Item {
  name: string;
  id: number;
}

interface Props {
  items: Item[];
  value: Item[];
  onChange: (s: Item[]) => void;
  resetFilter: () => void;
}

const QuickFilterByReasons = ({
  items,
  value,
  onChange,
  resetFilter,
}: Props) => {
  return (
    <Popover
      appendTo={document.body}
      className={styles.filterByReasonsPopover}
      template={
        items ? (
          <CheckList
            items={mapItemsToListOption(items, 'name')}
            value={mapItemsToListOption(value, 'name')}
            onChange={getMapOptionsToItemsHandler(items, onChange)}
          />
        ) : (
          <LoadingPanel styles={{ minHeight: '15rem', minWidth: '15rem' }} />
        )
      }
      maxWidth="37.5rem"
      placement="bottom-start"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={value.length > 0}
          onClose={resetFilter}
        />
      )}
    </Popover>
  );
};

export default QuickFilterByReasons;

import { AgGridReact } from 'ag-grid-react';
import { useCallback } from 'react';

export function useTableRefCallback({
  tableRef,
  setSortParams,
}: {
  tableRef: React.MutableRefObject<AgGridReact | undefined>;
  setSortParams: (sort: { field: string; order: string }) => void;
}) {
  const refCallback = useCallback((node: AgGridReact) => {
    tableRef.current = node;
    // listener to fix row heights after grouping
    node?.api?.addEventListener('columnRowGroupChanged', () => {
      node.api.resetRowHeights();
      node.api.redrawRows();
    });
    node?.api?.addEventListener('sortChanged', () => {
      const sortColumns = node.columnApi.getColumnState().filter((c) => c.sort);
      const [sortColumn] = sortColumns;
      if (!sortColumn) return;
      setSortParams({
        order: sortColumn.sort ?? undefined,
        field: sortColumn.colId,
      });
    });
  }, []);
  return refCallback;
}

import { currentUserAllowedToSeeUnitStatusesPage } from '@/pages/settings/report/operational/unitStatues/permissions';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

export const SETTINGS_REPORT_OPERATIONAL_DICTIONARY = {
  ChargeCodes: {
    singularForm: 'Charge Code',
    pluralForm: 'Charge Codes',
  },
  UnitTypes: {
    singularForm: 'Unit Type',
    pluralForm: 'Unit Types',
  },
  UnitStatus: {
    singularForm: 'Unit Status',
    pluralForm: 'Unit Statuses',
  },
} as const;

export const SETTINGS_REPORT_OPERATIONAL_PAGES_TABS = [
  {
    id: 'unit-types',
    label: SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.pluralForm,
    value: ROUTES_ROOT.settings.report.operational.unitTypes.fullPath,
  },
  {
    id: 'unit-statuses',
    label: SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitStatus.pluralForm,
    value: ROUTES_ROOT.settings.report.operational.unitStatuses.fullPath,
    resolveHidden: () => !currentUserAllowedToSeeUnitStatusesPage(),
  },
  {
    id: 'charge-codes',
    label: SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.pluralForm,
    value: ROUTES_ROOT.settings.report.operational.chargeCodes.fullPath,
  },
] as const satisfies readonly IThinTabItem[];

import React from 'react';

interface Props {
  investment: string;
}

export function ReviewInfoText({ investment }: Props) {
  return (
    <div className="flex flex-col gap-tw-2">
      <h6 className="header6-bold text-dark-60">Review action</h6>
      <p className="inline-regular text-neutral-650">
        By creating this Transfer of ownership from{' '}
        <span className="inline-semibold">{investment}</span>, you will update
        the following amounts:
      </p>
    </div>
  );
}

/* eslint-disable */
import React, { useState } from "react";
import TreeView, { TreeViewProps } from "stories/TreeView/TreeView";
import type { NodeType } from "stories/TreeView/types";
import { DatumNode } from "stories/TreeView/types";
import styles from "./TreeWithNavigation.module.scss";
import RelatedGLModal from "../RelatedGLModal/RelatedGLModal";
import { SearchableTree } from "bundles/Settings/components/REport/CategoriesList/SearchableTree/SearchableTree";

export interface IRelatedGLState {
  active: boolean;
  linkedItems: {
    label: string;
  }[];
}

function TreeViewWithNavigation<T extends DatumNode = DatumNode>({ readonlyNavigation, ...props }: { readonlyNavigation?: boolean } & TreeViewProps<T>) {
  const [relatedGLState, setRelatedGLState] = useState<IRelatedGLState>({
    active: false,
    linkedItems: [],
  });

  const handleToggle = (active: boolean) => setRelatedGLState((prev) => ({ ...prev, active }));

  const handleOpenMoreLinkedItems = (node: NodeType) => {
    setRelatedGLState({
      active: true,
      linkedItems: node?.data?.linkedItems ?? [],
    });
  };

  return (
    <>
      <div className={styles.treeContainer}>
        <SearchableTree
          data={props.data}
          selectedId={props.selected?.id}
          handleManageAssetClasses={props.handleManageAssetClasses}
          onSelect={(id) => props.onSelectedChange?.(id ? { id } : undefined)}
          readonly={readonlyNavigation}
        />
        <TreeView {...props} handleOpenMoreLinkedItems={handleOpenMoreLinkedItems} />
      </div>
      {relatedGLState.active && (
        <RelatedGLModal toggle={handleToggle} relatedGLState={relatedGLState} />
      )}
    </>
  );
}

export default TreeViewWithNavigation;

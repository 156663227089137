import { UNSUPPORTED_DASHBOARD_INFO_TEXT } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import {
  DEFAULT_CONFIG_MAP,
  WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import {
  addSectionsToLayout,
  AllWidgetTypes,
  mapLayoutToLayoutDto,
  ReportDashboardSectionPositionWithId,
  ReportDashboardType,
  useDashboardContext,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
} from 'bundles/Shared/entities/dashboard';
import { useMemo } from 'react';

export const useAddDashboardWidgetSection = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const createEagleEyeWidgetMutation =
    usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation();
  const createObjectWidgetMutation =
    usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation();
  const createComparisonWidgetMutation =
    usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation();

  const createReportBuilderTemplateWidgetMutation =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation();
  const [createReportBuilderTemplateWidget] =
    createReportBuilderTemplateWidgetMutation;

  const [createWidget, options] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return createObjectWidgetMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return createEagleEyeWidgetMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return createComparisonWidgetMutation;
      }
      case ReportDashboardType.REPORT_BUILDER_TEMPLATE: {
        return createReportBuilderTemplateWidgetMutation;
      }
      default: {
        return [
          () => {
            toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
          },
          { isLoading: false },
        ];
      }
    }
  }, [dashboardType]);

  const handleCreateWidget = async <T extends AllWidgetTypes>(
    params:
      | {
          type: T | T[];
          layout: ReportDashboardSectionPositionWithId[];
          layoutItem?: ReportDashboardSectionPositionWithId;
          boardId?: string;
        }
      | {
          type: T[];
          groupId: string;
          templateId: string;
        },
  ) => {
    if (params.templateId) {
      const { templateId, groupId, type } = params;
      const [oneType] = type;
      if (dashboardType === ReportDashboardType.REPORT_BUILDER_TEMPLATE) {
        return await createReportBuilderTemplateWidget({
          groupId,
          templateId,
          body: {
            widget_type: oneType,
            width_percent: 100,
            config: DEFAULT_CONFIG_MAP[oneType],
            title: `${WIDGETS_CONFIG_MAP[oneType].title}`,
          },
        });
      }
      return;
    }
    const { layout, layoutItem, boardId, type } = params;

    if (boardId == null) return;

    const types = Array.isArray(type) ? type : [type];
    const newLayout = addSectionsToLayout(
      layout,
      types.map((t) => ({
        i: t,
        type: t,
        ...WIDGETS_CONFIG_MAP[t].layout,
        ...layoutItem,
      })),
      { toTheEnd: layoutItem == null },
    );

    const dashboard_layout = mapLayoutToLayoutDto(
      layout.filter((l) => !types.includes(l.i as unknown as T)),
    );

    return await createWidget({
      objectDashboardId: dashboardId,
      eagleEyeDashboardId: dashboardId,
      comparisonDashboardId: dashboardId,
      boardId,
      body: {
        // @ts-expect-error
        widget_sections: types.map((t) => ({
          title: `${WIDGETS_CONFIG_MAP[t].title}`,
          widget_type: t,
          position: newLayout.find((l) => l.i === t)!,
          // @ts-expect-error
          config: DEFAULT_CONFIG_MAP[t],
        })),
        dashboard_layout,
      },
    });
  };

  return [handleCreateWidget, options] as const;
};

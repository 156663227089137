import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { CssVar } from '@/shared/config/cssVar';
import { TFireStationPriority } from 'bundles/FireStation/types';

interface Props {
  priority: 'none' | TFireStationPriority | `${TFireStationPriority}`;
  className?: string;
}

export const ICON_COLORS: Record<
  TFireStationPriority | 'none',
  { color: CssVar; textClassname: string }
> = {
  none: { textClassname: 'text-light-40', color: CssVar.light40 },
  low: { textClassname: 'text-yellow', color: CssVar.yellow },
  medium: { textClassname: 'text-pumpkin', color: CssVar.pumpkin },
  high: { textClassname: 'text-red-dark-3', color: CssVar.redDark3 },
};

export const FireIconAlternative = ({
  withMarker,
  classes,
}: {
  withMarker?: boolean;
  classes?: {
    fire?: string;
    marker?: string;
  };
}) => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes?.fire}
        d="M0 5.40404C0 7.95455 1.99432 10 4.5 10C6.98011 10 9 7.95455 9 5.40404C9 4.62121 8.18182 2.77778 7.00568 1.33838L6.54545 1.86869L5.5483 3.08081L4.60227 1.81818C4.14205 1.21212 3.63068 0.580808 3.06818 0C1.125 2.04545 0 4.26768 0 5.40404Z"
        fill="#FCC5C4"
      />
      {withMarker && (
        <circle
          className={classes?.marker}
          cx="9"
          cy="7.5"
          r="2.5"
          fill="#F6413E"
          stroke="white"
        />
      )}
    </svg>
  );
};

export const FireIcon = ({ priority, className, ...props }: Props) => (
  <div className={cn('inline-flex', className)} {...props}>
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={ICON_COLORS[priority].color}
        stroke="white"
        d="M10.9936 3.057L10.6542 2.73899L10.3125 3.05457C9.90385 3.43204 9.51957 3.82626 9.17056 4.23744C8.5 3.35 7.70937 2.47401 6.84759 1.64058L6.50333 1.30765L6.15588 1.63726C4.79678 2.92657 3.64159 4.39944 2.82314 5.80956C2.01324 7.20496 1.5 8.59584 1.5 9.71094C1.5 13.4368 4.38008 16.5 8 16.5C11.5917 16.5 14.5 13.4383 14.5 9.71094C14.5 8.86749 14.109 7.66807 13.5046 6.48281C12.8921 5.28182 12.0228 4.02127 10.9936 3.057Z"
      />
      <path
        d="M9.01786 10.0078L10.3839 8.42188C10.4911 8.58594 10.5714 8.75 10.6518 8.88672C11.2946 10.1445 11.0268 11.7305 9.875 12.5508C9.36607 12.9336 8.72321 13.125 8.05357 13.125C6.36607 13.125 5 12.0039 5 10.1445C5 9.21484 5.5625 8.39453 6.6875 7C6.84821 7.19141 9.01786 10.0078 9.01786 10.0078Z"
        fill="white"
      />
    </svg>
  </div>
);

export default FireIcon;

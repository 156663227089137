import React from 'react';
import './footer.css';
import { SymmetreLogo } from 'bundles/Shared/components/LeftSidebar/Logo/Logo';

export const Footer = () => (
  <footer className="footer flex flex-col items-center justify-center gap-tw-6 md:flex-row md:justify-between">
    <div className="flex items-center">
      <span className="mr-s">Powered by</span>
      <a href="https://symmetre.com" target="blank" className="footer_logo">
        <SymmetreLogo variant="large" />
      </a>
    </div>
    <div>
      <a href="https://symmetre.com/" target="blank" className="blue mr-m">
        About us
      </a>
      <span>© symmetRE, Inc., {new Date().getFullYear()}</span>
    </div>
  </footer>
);

export default Footer;

export const statuses = [
  { label: 'Draft', key: 'draft', color: '#E99023' },
  { label: 'Published', key: 'published', color: '#21913C' },
  { label: 'Archived', key: 'archived', color: '#47545A' },
];

export const getFundStatusColor = (value, defaultColor = '#0D1C3C') => {
  const status = statuses.find((s) => s.key === value);
  return status?.color || defaultColor;
};

export const getNextStatusAction = (value) => {
  if (value === 'draft') return { label: 'Publish fund', status: 'published' };
  if (value === 'published') return { label: 'Archive fund', status: 'archived' };
  if (value === 'archived') return { label: 'Unarchive fund', status: 'published' };
  return null;
};

import useBoolean from '@/shared/lib/hooks/useBoolean';
import { useEffect, useMemo, useState } from 'react';

export const usePdfBlobCreateObjectURL = (pdfBlob: Blob | null) => {
  return useMemo(() => pdfBlob && URL.createObjectURL(pdfBlob), [pdfBlob]);
};

export const useFetchPreviewPdf = ({
  fetchCallback,
  effectDeps,
  waitIfCallback,
}: {
  fetchCallback: () => Promise<{ data: Blob } | object>;
  waitIfCallback: () => boolean;
  effectDeps: React.DependencyList;
}) => {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  useEffect(() => {
    if (waitIfCallback()) return;

    const fetchData = async () => {
      enableLoading();

      try {
        const res = await fetchCallback();

        if (!('data' in res)) throw new Error('No data in response');

        setPdfBlob(res.data);
      } catch {
        setPdfBlob(null);
      } finally {
        disableLoading();
      }
    };
    fetchData();
  }, effectDeps);

  return {
    isLoading,
    pdfBlob,
  };
};

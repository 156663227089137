import * as React from 'react';
import { Children, cloneElement } from 'react';
import { TreeRendererProps } from 'react-sortable-tree';
import LongLine from '/images/treeConnectors/longLine.svg?component';
import s from '/images/treeConnectors/lastLine.svg';

type Props = TreeRendererProps;

function TreeNodeRenderer(props: Props) {
  const {
    children,
    listIndex,
    swapFrom,
    swapLength,
    swapDepth,
    lowerSiblingCounts,
    connectDropTarget,
    isOver,
    draggedNode,
    canDrop,
    style,
  } = props;
  return connectDropTarget(
    <div style={style}>
      {Children.map(children, (child) =>
        cloneElement(child, {
          isOver,
          canDrop,
          draggedNode,
          lowerSiblingCounts,
          listIndex,
          swapFrom,
          swapLength,
          swapDepth,
        }),
      )}
    </div>,
  );
}

export default TreeNodeRenderer;

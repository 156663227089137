import { CssVar } from '@/shared/config/cssVar';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { startCase } from 'lodash-es';
import pluralize from 'pluralize';
import { Icon, PercentFormatter, ProjectCard, StatisticLine } from 'stories';
import { useProjectCardResponsive } from 'stories/ProjectCard/hooks';
import { BUDGET_STATUSES } from '../consts';
import { BudgetYear } from '../types';

const YearImage = (props: { year: number }) => (
  <div className="header5-bold flex h-full w-full items-center justify-center rounded-lg bg-light-10 text-light-90">
    {props.year}
  </div>
);

export function BudgetYearCard({
  budget,
  currentYearId,
  total,
  totalPublishedPercentage,
  onClick,
}: {
  totalPublishedPercentage: number;
  total: number;
  budget: BudgetYear;
  currentYearId: string | undefined;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) {
  const globalYear = new Date().getFullYear();
  const { mediumWidth } = useProjectCardResponsive();

  if (globalYear > budget.year) {
    return (
      <ProjectCard
        imagePlaceholder=""
        fallbackNode={<YearImage year={budget.year} />}
        header={null}
        className="cursor-pointer"
        selected={budget.year === Number(currentYearId)}
        onClick={onClick}
      >
        <div className="flex min-h-[56px] flex-col justify-center gap-tw-3">
          <div className="flex flex-col gap-tw-1">
            <div className="flex items-center justify-between">
              <span className="body-semibold font-semibold text-neutral-850">
                <span>
                  {mediumWidth ? budget.year : budget.stateCounts.published}
                </span>
              </span>
              <div className="flex h-full items-center gap-tw-3 text-light-60">
                <div className="flex h-full items-center gap-tw-1">
                  <Icon iconName="entities" />
                  <span className="secondary-regular ">{total}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-tw-2">
              <span className="label-regular text-neutral-600">
                Published {pluralize('Budgets', budget.stateCounts.published)}
              </span>
            </div>
          </div>
        </div>
      </ProjectCard>
    );
  }

  return (
    <ProjectCard
      imagePlaceholder=""
      fallbackNode={<YearImage year={budget.year} />}
      header={null}
      className="cursor-pointer"
      selected={budget.year === Number(currentYearId)}
      onClick={onClick}
    >
      <div className="flex max-h-[102px] flex-col gap-tw-3">
        <div className="flex flex-col gap-tw-0.5">
          <div className="flex items-center justify-between">
            <span className="body-semibold text-neutral-850">
              <span>
                {mediumWidth ? (
                  budget.year
                ) : (
                  <PercentFormatter
                    value={totalPublishedPercentage}
                    classes={{
                      value: 'body-semibold text-neutral-850',
                      endSymbol: 'body-semibold text-neutral-600',
                    }}
                  />
                )}
              </span>
            </span>
            <div className="flex h-full items-center gap-tw-3 text-light-60">
              <div className="flex h-full items-center gap-tw-1">
                <Icon iconName="entities" />
                <span className="secondary-regular ">{total}</span>
              </div>
              {budget.stateCounts.no_budget > 0 && <FireIcon priority="high" />}
            </div>
          </div>
          <div className="flex flex-col gap-tw-1">
            <span className="label-regular text-neutral-600">
              Published Budgets
            </span>
            <StatisticLine
              statistics={[
                { value: budget.stateCounts.published, color: CssVar.green },
                {
                  value: budget.stateCounts.in_progress,
                  color: CssVar.info055,
                },
                { value: budget.stateCounts.draft, color: CssVar.neutral300 },
              ]}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 grid-rows-2 gap-x-tw-18 gap-y-tw-1">
          {BUDGET_STATUSES.map((stateKey) => (
            <div key={stateKey} className="flex items-center gap-tw-2">
              <span className="flex h-tw-2 w-tw-2 rounded-full bg-light-20" />
              <span className="secondary-regular text-neutral-850">
                {startCase(stateKey)}
              </span>
              <span className="secondary-regular ml-auto text-light-60">
                {budget.stateCounts[stateKey]}
              </span>
            </div>
          ))}
        </div>
      </div>
    </ProjectCard>
  );
}

import React, { useEffect, useState } from 'react';
import { Button } from 'stories';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import BudgetsCommentsPanel from 'bundles/REconcile/components/development/requisitions/comments/BudgetsCommentsPanel';
import {
  fetchDevelopmentBudgetVarianceComments,
  fetchMentionableUsers,
  openComment,
  selectCommentsItems,
  selectOpenedComment,
} from 'bundles/REconcile/reducers/developmentBudgetVarianceCommentSlice';
import ActivityLogPanel from 'bundles/REconcile/components/development/requisitions/activityLog/ActivityLogPanel';
import RequisitionModal from 'bundles/REconcile/components/development/requisitions/modals/RequisitionModal';
import { addDevelopmentBudgetRequisition } from 'bundles/REconcile/reducers/developmentBudgetVarianceSlice';
import { useModal } from '@/shared/lib/hooks/useModal';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import dayjs from 'dayjs';
import { maxBy } from 'lodash-es';
import { generateDates } from 'bundles/REconcile/components/development/requisitions/utils';

function RequisitionsHeader() {
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const comments = useAppSelector(selectCommentsItems);
  const openedComment = useAppSelector(selectOpenedComment);
  const requisitions = useAppSelector(
    (state) => state.developmentBudgetVariance.data.requisitions,
  );

  const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false);
  const [activityLogPanelOpen, setActivityLogPanelOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  useEffect(() => {
    if (legalEntity?.id != null) {
      dispatch(fetchDevelopmentBudgetVarianceComments(legalEntity.code));
      dispatch(fetchMentionableUsers(legalEntity.code));
    }
  }, [legalEntity?.id]);

  useEffect(() => {
    if (openedComment != null && !isCommentsPanelOpen) {
      setIsCommentsPanelOpen(true);
    }
  }, [openedComment]);

  useEffect(() => {
    setIsCommentsPanelOpen(false);
  }, []);

  const addRequisition = async () => {
    const latestRequisition = maxBy(requisitions, 'endingDate');
    const beginningDate = latestRequisition
      ? dayjs(latestRequisition.endingDate).add(1, 'd').toDate()
      : new Date();
    const endingDate = dayjs(beginningDate).add(1, 'w').toDate();

    const excluded = requisitions
      .map((r) => generateDates(r.beginningDate, r.endingDate))
      .flat();
    const res = await openModal(RequisitionModal, {
      beginningDate,
      endingDate,
      excludeDates: excluded,
    });
    if (!res) return;

    dispatch(
      addDevelopmentBudgetRequisition({
        legalEntityId: legalEntity.id,
        ending_date: res.endingDate,
        beginning_date: res.beginningDate,
      }),
    );
  };

  return (
    <>
      <div className="m-tw-6 flex items-center justify-between">
        <h2 className=" font-weight-700">Requisitions</h2>
        <Button
          variant="secondary"
          iconName="reload"
          size="s"
          onClick={() => setActivityLogPanelOpen(true)}
          className="ml-auto mr-s"
        >
          Activity Log
        </Button>
        <Button
          variant="secondary"
          iconName="comments"
          size="s"
          className="mr-s"
          onClick={() => {
            dispatch(
              openComment({
                commentId: null,
                typeCommentsPanel: 'many',
              }),
            );
            setIsCommentsPanelOpen(true);
          }}
        >
          {comments.length} Comments
        </Button>
        <VerticalSeparator />
        <Button
          variant="primary"
          iconName="add"
          size="s"
          className="ml-s"
          onClick={addRequisition}
        >
          Add Requisition
        </Button>
      </div>
      {activityLogPanelOpen && (
        <ActivityLogPanel onClose={() => setActivityLogPanelOpen(false)} />
      )}
      {isCommentsPanelOpen && (
        <BudgetsCommentsPanel onClose={() => setIsCommentsPanelOpen(false)} />
      )}
    </>
  );
}

export default RequisitionsHeader;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { FetchAssetsWithMetaResponse } from 'bundles/Assets/reducers/AssetsSlice';
import { IVisibleReportTableConfigsData } from 'bundles/REport/actions';
import { snakeKeysToCamel } from 'bundles/UserManagement/components/roles/utils';
import { API_URL, apiClient, requireAuthorizationHeaders } from 'lib/http';
import { stringify } from 'qs';
import { IFund } from 'types/Fund';
import { PropertiesCamelToSnakeCase } from 'types/StringUtilityTypes';

export const FUND_LIST_TAG = 'Funds';
export const FUND_TAG = 'Fund';

type UpdatedFund = {
  id: number;
  slug: string;
  fund: PropertiesCamelToSnakeCase<Partial<IFund>>;
};

export type CoreFund = IFund & {
  hasOperationalData?: boolean;
  hasReturnData?: boolean;
  capitalInvestmentObjectId?: string;
};

export const coreFundsApi = createApi({
  reducerPath: 'coreFunds',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
    paramsSerializer: (params) =>
      stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      }),
  }),
  tagTypes: [FUND_LIST_TAG, FUND_TAG],
  endpoints: (build) => ({
    getFunds: build.query<
      {
        funds: IFund[];
        meta: {
          totalSize: number;
        };
      },
      {
        search_by_query?: string;
        favorite_ids?: string[];
        statuses?: string[];
        page?: number;
      }
    >({
      providesTags: [FUND_LIST_TAG],
      query: (params) => ({
        url: '/funds',
        params,
      }),
    }),

    getFund: build.query<CoreFund, IFund['slug']>({
      providesTags: [FUND_TAG],
      query: (slug) => ({
        url: `/funds/${slug}`,
      }),
    }),

    getVisibleReportTableConfigs: build.query<
      IVisibleReportTableConfigsData,
      IFund['slug']
    >({
      query: (slug) => ({
        url: `/funds/${slug}/visible_report_table_configs`,
      }),
    }),

    getFundAssets: build.query<
      FetchAssetsWithMetaResponse,
      {
        fund_id: IFund['id'];
      }
    >({
      query: (params) => ({
        url: '/assets',
        params,
      }),
    }),

    simpleUpdateFund: build.mutation<Partial<IFund>, UpdatedFund>({
      query: (data) => ({
        url: `/funds/${data.id}/simple_update`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Fund has been updated');
        return (res as { data: Partial<IFund> })?.data;
      },

      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          coreFundsApi.util.updateQueryData('getFund', patch.slug, (draft) => {
            Object.assign(draft, snakeKeysToCamel(patch.fund));
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    heavyUpdateFund: build.mutation<Partial<IFund>, UpdatedFund>({
      query: (data) => ({
        url: `/funds/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (res) => {
        toastr.success('Fund has been updated');
        return (res as { data: Partial<IFund> })?.data;
      },
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          coreFundsApi.util.updateQueryData('getFund', patch.slug, (draft) => {
            Object.assign(draft, snakeKeysToCamel(patch.fund));
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    heavyUpdateFundWithInvalidation: build.mutation<
      Partial<IFund>,
      UpdatedFund
    >({
      query: (data) => ({
        url: `/funds/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [FUND_LIST_TAG],
      transformResponse: (res) => {
        toastr.success('Fund has been updated');
        return (res as { data: Partial<IFund> })?.data;
      },
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          coreFundsApi.util.updateQueryData('getFund', patch.slug, (draft) => {
            Object.assign(draft, snakeKeysToCamel(patch.fund));
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    heavyUpdateFundWithForm: build.mutation<Partial<IFund>, FormData>({
      invalidatesTags: ['Funds'],
      queryFn: async (formData) => {
        try {
          const res = await apiClient.put(
            `/api/funds/${formData.get('id') as string}`,
            formData,
            {
              headers: requireAuthorizationHeaders({
                'Content-Type': 'multipart/form-data',
              }),
            },
          );
          toastr.success('Fund has been updated');

          return { data: res.data };
        } catch (e) {
          throw new Error(e);
        }
      },
    }),
  }),
});

export const {
  useGetFundsQuery,
  useGetFundQuery,
  useHeavyUpdateFundMutation,
  useHeavyUpdateFundWithInvalidationMutation,
  useSimpleUpdateFundMutation,
  useGetVisibleReportTableConfigsQuery,
  useHeavyUpdateFundWithFormMutation,
} = coreFundsApi;
